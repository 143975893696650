import { string } from 'prop-types';

const COMMON_INTL_KEY = 'MarketplaceConfig.filters.category';

const LISTING_CATEGORIES = {
  FIXED: {
    id: 'fixed',
    label: 'Fixed Office',
    labelId: `${COMMON_INTL_KEY}.fixed`,
  },
  COWORKING: {
    id: 'coworking',
    label: 'Coworking',
    labelId: `${COMMON_INTL_KEY}.coworking`,
  },
  MEETING: {
    id: 'meeting',
    label: 'Meeting space',
    labelId: `${COMMON_INTL_KEY}.meeting`,
  },
  PRIVATE: {
    id: 'private',
    label: 'Office Space',
    labelId: `${COMMON_INTL_KEY}.private`,
  },
  STUDIO: {
    id: 'studio',
    label: 'Studios',
    labelId: `${COMMON_INTL_KEY}.studio`,
  },
};

LISTING_CATEGORIES.LIST = [
  LISTING_CATEGORIES.FIXED,
  LISTING_CATEGORIES.COWORKING,
  LISTING_CATEGORIES.MEETING,
  LISTING_CATEGORIES.STUDIO,
  LISTING_CATEGORIES.PRIVATE,
];

LISTING_CATEGORIES.ID_TO_DATA = {
  [LISTING_CATEGORIES.COWORKING.id]: LISTING_CATEGORIES.COWORKING,
  [LISTING_CATEGORIES.MEETING.id]: LISTING_CATEGORIES.MEETING,
  [LISTING_CATEGORIES.STUDIO.id]: LISTING_CATEGORIES.STUDIO,
  [LISTING_CATEGORIES.PRIVATE.id]: LISTING_CATEGORIES.PRIVATE,
  [LISTING_CATEGORIES.FIXED.id]: LISTING_CATEGORIES.FIXED,
};

LISTING_CATEGORIES.SHAPE = {
  id: string,
  label: string,
  labelId: string,
};

export default LISTING_CATEGORIES;
