import React from 'react';
import { DeleteTeamMemberModal, EditTeamMemberAccessModal, OutsideClickHandler } from '..';
import { useBoolean } from '../../util/hooks';
import { SubscriptionManagementIcons as Icons } from '..';
import { CREDITS_TYPE_UNLIMITED, STATUS_REGISTERED } from '../../util/types';

const TeamAccessMember = (props) => {
  const {
    member,
    css,
    isMobileLayout,
    intl,
    onDeleteMember,
    onEditMemberAccess,
    deleteMemberInProgress,
    deleteMemberError,
    editMemberAccessInProgress,
    editMemberAccessError,
  } = props;
  const { firstName, lastName, email, usedCredits, id, credits, status, creditsDistributionType } =
    member;
  const [isActionButtonsOpen, setActionButtonOpen] = useBoolean();
  const [isActionButtonWrapperOpen, setActionButtonWrapperOpen] = useBoolean();
  const [isDeleteMemberModalOpen, setDeleteMemberModalOpen] = useBoolean();
  const [isEditMemberAccessModalOpen, setEditMemberAccessModalOpen] = useBoolean();

  const isCreditUnlimited = creditsDistributionType === CREDITS_TYPE_UNLIMITED;
  const creditLabel = isCreditUnlimited
    ? intl.formatMessage({ id: 'TeamAccessMember.creditLabelUnlimited' })
    : intl.formatMessage({ id: 'TeamAccessMember.creditLabel' }, { credits });
  const handleRowMouseLeave = () => {
    if (isActionButtonWrapperOpen) {
      return;
    }
    setActionButtonOpen.off();
  };

  const handleActionsButtonWrapperOutsideClick = () => {
    setActionButtonWrapperOpen.off();
    setActionButtonOpen.off();
  };
  if (status === STATUS_REGISTERED) {
    return (
      <div
        key={id}
        className={css.row}
        onMouseOver={setActionButtonOpen.on}
        onMouseLeave={handleRowMouseLeave}
      >
        <div className={css.member}>
          <div className={css.memberIconAccepted}>M</div>
          <div className={css.memberUserWrapper}>
            <span className={css.memberUserName}>{`${firstName} ${lastName}`}</span>
            <span className={css.memberEmail}>{email}</span>
          </div>
        </div>
        <div className={css.access}>
          <div className={css.accessLabel} onClick={setEditMemberAccessModalOpen.on}>
            {creditLabel}
          </div>
        </div>
        {!isMobileLayout && (
          <>
            <div className={css.creditsUsed}>{usedCredits}</div>
            {isActionButtonsOpen && (
              <div className={css.actionsButtonOpener} onClick={setActionButtonWrapperOpen.on}>
                <Icons.ActionsButtonIcon className={css.actionsButtonIcon} />
                {isActionButtonWrapperOpen && (
                  <OutsideClickHandler
                    className={css.actionsButtonWrapper}
                    onOutsideClick={handleActionsButtonWrapperOutsideClick}
                  >
                    <div className={css.actionButton} onClick={setEditMemberAccessModalOpen.on}>
                      <Icons.EditIcon />
                      <span>{intl.formatMessage({ id: 'TeamAccessMember.editAccess' })}</span>
                    </div>
                    <div className={css.actionButton} onClick={setDeleteMemberModalOpen.on}>
                      <Icons.DeleteIcon />
                      <span>{intl.formatMessage({ id: 'TeamAccessMember.deleteUser' })}</span>
                    </div>
                  </OutsideClickHandler>
                )}
              </div>
            )}
          </>
        )}
        {isMobileLayout && (
          <>
            <div className={css.deleteButton} onClick={setDeleteMemberModalOpen.on}>
              <Icons.DeleteIcon />
            </div>
            <div className={css.editButton} onClick={setEditMemberAccessModalOpen.on}>
              <Icons.EditIcon />
            </div>
          </>
        )}
        {isDeleteMemberModalOpen && (
          <DeleteTeamMemberModal
            isOpen={isDeleteMemberModalOpen}
            onClose={setDeleteMemberModalOpen.off}
            memberId={id}
            memberEmail={email}
            intl={intl}
            onDeleteMember={onDeleteMember}
            inProgress={deleteMemberInProgress}
            error={deleteMemberError}
          />
        )}
        {isEditMemberAccessModalOpen && (
          <EditTeamMemberAccessModal
            isOpen={isEditMemberAccessModalOpen}
            onClose={setEditMemberAccessModalOpen.off}
            memberId={id}
            memberEmail={email}
            intl={intl}
            onEditMemberAccess={onEditMemberAccess}
            inProgress={editMemberAccessInProgress}
            error={editMemberAccessError}
            credits={credits}
          />
        )}
      </div>
    );
  } else {
    return (
      <div className={css.row}>
        <div className={css.member}>
          <div className={css.memberIconPending}>I</div>
          <div className={css.memberUserWrapper}>
            <span className={css.memberUserName}>
              {intl.formatMessage({
                id: 'TeamAccessTab.invited',
              })}
            </span>
            <span className={css.memberEmail}>{email}</span>
          </div>
        </div>
        <div className={css.access}>
          <div className={css.accessLabel}>{creditLabel}</div>
        </div>
        {!isMobileLayout && <div className={css.creditsUsed}>{usedCredits}</div>}
      </div>
    );
  }
};

export default TeamAccessMember;
