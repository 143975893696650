import classNames from 'classnames';
import { number } from 'prop-types';
import React from 'react';
import { useIntl } from 'react-intl';
import { IconEditSubscriptionSuccessModal, Modal, NamedLink } from '..';
import { formatMoneyWithSpace, SUBSCRIPTION_VAT } from '../../util/currency';
import { MY_SUBSCRIPTION, PAYMENT_HISTORY } from '../../util/types';
import css from './EditSubscriptionSuccessModal.module.scss';

const EditSubscriptionSuccessModal = (props) => {
  const { isOpen, onClose, creditsNumber, totalPrice } = props;
  const intl = useIntl();

  const totalPriceWithVat = (totalPrice * (1 + SUBSCRIPTION_VAT)).toFixed(2);
  return (
    <Modal
      id="EditSubscriptionSuccessModal"
      isOpen={isOpen}
      onClose={onClose}
      openClassName={css.openModal}
      scrollLayerClassName={css.scrollableLayer}
      containerClassName={css.modalContainer}
      hideCloseButton
    >
      <NamedLink
        name="SubscriptionManagementPage"
        params={{ tab: MY_SUBSCRIPTION }}
        className={css.closeButton}
      >
        <svg
          width="19"
          height="19"
          viewBox="0 0 19 19"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M1 1L18 18" stroke="black" />
          <path d="M18 1L1 18" stroke="black" />
        </svg>
      </NamedLink>
      <div className={css.container}>
        <IconEditSubscriptionSuccessModal className={css.iconSuccess} />
        <h2 className={css.modalTitle}>
          {intl.formatMessage({ id: 'EditSubscriptionSuccessModal.title' })}
        </h2>
        <p className={css.modalDesc}>
          {intl.formatMessage({ id: 'EditSubscriptionSuccessModal.description' })}
        </p>
        <div className={css.row}>
          <div className={classNames(css.rowLabel, css.boldText)}>
            {intl.formatMessage({ id: 'EditSubscriptionSuccessModal.orderDetailLabel' })}
          </div>
          <NamedLink
            name="SubscriptionManagementPage"
            params={{ tab: PAYMENT_HISTORY }}
            className={css.viewDetail}
          >
            {intl.formatMessage({ id: 'EditSubscriptionSuccessModal.viewDetail' })}
          </NamedLink>
        </div>
        <div className={css.row}>
          <div className={css.rowLabel}>
            {intl.formatMessage({ id: 'EditSubscriptionSuccessModal.creditsLabel' })}
          </div>
          <div className={css.rowValue}>{creditsNumber}</div>
        </div>
        <div className={css.row}>
          <div className={css.rowLabel}>
            {intl.formatMessage({ id: 'EditSubscriptionSuccessModal.subTotalLabel' })}
          </div>
          <div className={css.rowValue}>
            {intl.formatMessage(
              { id: 'EditSubscriptionSuccessModal.subTotal' },
              {
                totalPrice: formatMoneyWithSpace(totalPrice),
              }
            )}
          </div>
        </div>
        <div className={css.row}>
          <div className={css.rowLabel}>
            {intl.formatMessage({ id: 'EditSubscriptionSuccessModal.vatLabel' })}
          </div>
          <div className={css.rowValue}>
            {intl.formatMessage({ id: 'EditSubscriptionSuccessModal.vat' })}
          </div>
        </div>
        <div className={css.horizontalLine}></div>
        <div className={classNames(css.row, css.totalRow)}>
          <div className={classNames(css.rowLabel, css.boldText)}>
            {intl.formatMessage({ id: 'EditSubscriptionSuccessModal.totalLabel' })}
          </div>
          <div className={classNames(css.rowValue, css.boldText)}>
            {intl.formatMessage(
              { id: 'EditSubscriptionSuccessModal.total' },
              {
                totalPrice: formatMoneyWithSpace(totalPriceWithVat),
              }
            )}
          </div>
        </div>
        <NamedLink
          name="SubscriptionManagementPage"
          params={{ tab: MY_SUBSCRIPTION }}
          className={css.doneBtn}
        >
          {intl.formatMessage({ id: 'EditSubscriptionSuccessModal.done' })}
        </NamedLink>
      </div>
    </Modal>
  );
};

EditSubscriptionSuccessModal.defaultProps = {
  creditsNumber: 0,
};

EditSubscriptionSuccessModal.propTypes = {
  creditsNumber: number.isRequired,
  totalPrice: number.isRequired,
};
export default EditSubscriptionSuccessModal;
