// seems as those these first vars aren't used anywhere? old code maybe?
export const coworking = [
  {
    key: 'coworkingsurroundings',
    label: 'Changing surroundings',
    labelId: 'EditListingFeaturesForm.coworking.coworkingsurroundings',
  },
  {
    key: 'coworkingdone',
    label: 'Getting things done',
    labelId: 'EditListingFeaturesForm.coworking.coworkingdone',
  },
  {
    key: 'coworkinginspired',
    label: 'Get inspiried',
    labelId: 'EditListingFeaturesForm.coworking.coworkinginspired',
  },
  {
    key: 'coworkingfocus',
    label: 'Focus without interruptions',
    labelId: 'EditListingFeaturesForm.coworking.coworkingfocus',
  },
];
export const meetingRoom = [
  {
    key: 'meetingintimate',
    label: 'Small and intimate meetings',
    labelId: 'EditListingFeaturesForm.meeting.meetingintimate',
  },
  {
    key: 'meetinginterviews',
    label: 'Interviews',
    labelId: 'EditListingFeaturesForm.meeting.meetinginterviews',
  },
  {
    key: 'meetingcalls',
    label: 'Video calls',
    labelId: 'EditListingFeaturesForm.meeting.meetingcalls',
  },
  {
    key: 'meetingfocus',
    label: 'Individual focus',
    labelId: 'EditListingFeaturesForm.meeting.meetingfocus',
  },
  {
    key: 'meetingteam',
    label: 'Team meetings',
    labelId: 'EditListingFeaturesForm.meeting.meetingteam',
  },
  {
    key: 'meetingworkshop',
    label: 'Workshops',
    labelId: 'EditListingFeaturesForm.meeting.meetingworkshop',
  },
  {
    key: 'meetingmeeting',
    label: 'Video meetings',
    labelId: 'EditListingFeaturesForm.meeting.meetingmeeting',
  },
  {
    key: 'meetingpresentation',
    label: 'Presentations',
    labelId: 'EditListingFeaturesForm.meeting.meetingpresentation',
  },
  {
    key: 'meetingconference',
    label: 'Conference',
    labelId: 'EditListingFeaturesForm.meeting.meetingconference',
  },
  {
    key: 'meetingbig',
    label: 'Big meeting',
    labelId: 'EditListingFeaturesForm.meeting.meetingbig',
  },
  {
    key: 'meetingcreative',
    label: 'Meetings in a creative environment',
    labelId: 'EditListingFeaturesForm.meeting.meetingcreative',
  },
  {
    key: 'meetingrare',
    label: 'All-day meetings in a rare space',
    labelId: 'EditListingFeaturesForm.meeting.meetingrare',
  },
];
export const privateOffice = [
  {
    key: 'officeFocus',
    label: 'Days when you need to focus',
    labelId: 'EditListingFeaturesForm.privateOffice.officeFocus',
  },
  {
    key: 'officePrivacy',
    label: 'When you need privacy',
    labelId: 'EditListingFeaturesForm.privateOffice.officePrivacy',
  },
  {
    key: 'smallMeetings',
    label: 'Small meetings',
    labelId: 'EditListingFeaturesForm.privateOffice.smallMeetings',
  },
  {
    key: 'workshops',
    label: 'Workshops',
    labelId: 'EditListingFeaturesForm.privateOffice.workshops',
  },
];

export const amenitiesOptionList = {
  coworking: [
    'freeCoffee',
    'wifi',
    'meetingRoom',
    'phoneBooth',
    'parking',
    'bicycleParking',
    'foodSales',
    'pets',
    'quietArea',
    'digitalKey',
  ],
  meeting: ['freeCoffee', 'wifi', 'parking', 'bicycleParking', 'pets', 'foodSales', 'digitalKey'],
  studio: ['freeCoffee', 'wifi', 'parking', 'bicycleParking', 'pets', 'foodSales'],
  private: [
    'freeCoffee',
    'wifi',
    'meetingRoom',
    'phoneBooth',
    'parking',
    'bicycleParking',
    'foodSales',
    'pets',
    'digitalKey',
  ],
  fixed: [
    'freeCoffee',
    'wifi',
    'meetingRoom',
    'phoneBooth',
    'parking',
    'bicycleParking',
    'foodSales',
    'pets',
    'digitalKey',
  ],
};

export const equiqmentOptionList = {
  coworking: [
    'printer',
    'dedicatedDesk',
    'monitor',
    'chair',
    'fridge',
    'micro',
    'sitStandDesk',
    'shower',
  ],
  meeting: [
    'micro',
    'fridge',
    'flexibleDesk',
    'printer',
    'apple',
    'chromecast',
    'screencast',
    'whiteboard',
    'phone',
    'tv',
  ],
  private: ['micro', 'fridge', 'printer', 'shower'],
  studio: [
    'micro',
    'printer',
    'monitor',
    'dedicatedDesk',
    'sitStandDesk',
    'fridge',
    'shower',
    'podcast',
  ],
  fixed: [
    'micro',
    'printer',
    'shower',
    'cleaning',
    'kitchen',
    'gym',
    'lockeres',
    'inventory',
    'roofTerrace',
    'alarm',
    'flexibleTerms',
    'tax',
    'officeAddress',
    'storageSpace',
    'wheelchair',
    'furnished',
    'electricity',
    'heating',
    '24hAccess',
    'reception',
    'postage',
  ],
};

export const addonsOptionList = {
  coworking: [
    'breakfast',
    'breakfastBuffet',
    'lunch',
    'carte',
    'lunchBuffet',
    'parking',
    'fika',
    'gym',
    'spa',
    'gymAndSpa',
    'sauna',
  ],
  meeting: [
    'breakfast',
    'breakfastBuffet',
    'lunch',
    'carte',
    'lunchBuffet',
    'parking',
    'fika',
    'gym',
    'spa',
    'gymAndSpa',
    'sauna',
  ],
  studio: [
    'breakfast',
    'breakfastBuffet',
    'lunch',
    'carte',
    'lunchBuffet',
    'parking',
    'fika',
    'gym',
    'spa',
    'gymAndSpa',
    'sauna',
  ],
  private: [
    'breakfast',
    'breakfastBuffet',
    'lunch',
    'carte',
    'lunchBuffet',
    'parking',
    'fika',
    'gym',
    'spa',
    'gymAndSpa',
    'sauna',
  ],
  fixed: [
    'breakfast',
    'breakfastBuffet',
    'lunch',
    'carte',
    'lunchBuffet',
    'parking',
    'fika',
    'gym',
    'spa',
    'gymAndSpa',
    'sauna',
  ],
};
