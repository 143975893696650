import React from 'react';
import { useIntl } from 'react-intl';
import { IconEditSubscriptionSuccessModal, Modal, NamedLink } from '..';
import { MY_SUBSCRIPTION } from '../../util/types';
import css from './ChangePaymentInfoSuccessModal.module.scss';

const ChangePaymentInfoSuccessModal = (props) => {
  const { isOpen, onClose } = props;
  const intl = useIntl();

  return (
    <Modal
      id="ChangePaymentInfoSuccessModal"
      isOpen={isOpen}
      onClose={onClose}
      openClassName={css.openModal}
      scrollLayerClassName={css.scrollableLayer}
      containerClassName={css.modalContainer}
      hideCloseButton
    >
      <div className={css.closeButton} onClick={onClose}>
        <svg
          width="19"
          height="19"
          viewBox="0 0 19 19"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M1 1L18 18" stroke="black" />
          <path d="M18 1L1 18" stroke="black" />
        </svg>
      </div>
      <div className={css.container}>
        <IconEditSubscriptionSuccessModal className={css.iconSuccess} />
        <h2 className={css.modalTitle}>
          {intl.formatMessage({ id: 'ChangePaymentInfoSuccessModal.title' })}
        </h2>
        <p className={css.modalDesc}>
          {intl.formatMessage({ id: 'ChangePaymentInfoSuccessModal.description' })}
        </p>
        <NamedLink
          name="SubscriptionManagementPage"
          params={{ tab: MY_SUBSCRIPTION }}
          className={css.doneBtn}
        >
          {intl.formatMessage({ id: 'ChangePaymentInfoSuccessModal.done' })}
        </NamedLink>
      </div>
    </Modal>
  );
};

ChangePaymentInfoSuccessModal.defaultProps = {};

ChangePaymentInfoSuccessModal.propTypes = {};
export default ChangePaymentInfoSuccessModal;
