import React from 'react';
import moment from 'moment';
import classNames from 'classnames';
import { useHistory } from 'react-router-dom';

import routeConfiguration from '../../routeConfiguration';
import { Button, PrimaryButton } from '../../components';
import { createResourceLocatorString } from '../../util/routes';

import PackageModel from '../../models/PackageModel';
import CouponModel from '../../models/CouponModel';

import css from './CompanyAccountPage.module.scss';
import { SUBSCRIPTION_OPTIONS } from '../../util/types';

const SummaryTab = ({
  intl,
  setCurrentTab,
  isMobileLayout = false,
  isCurrentTab,
  isPassed,
  quoteInformation,
  processStartDate,
  retrievedCoupon,
  totalCredits,
  calculatedPrices,
  selectedPackage,
}) => {
  const { id: couponCode } = retrievedCoupon || {};
  const { couponDiscount, originalPrice, priceWithDiscount } = calculatedPrices || {};
  const { period } = retrievedCoupon || {};
  const isMonthlySubscription = SUBSCRIPTION_OPTIONS.includes(selectedPackage);

  const history = useHistory();

  const handleNextTab = () => setCurrentTab(1);

  const handleBackToQuotePage = () => {
    history.push(createResourceLocatorString('QuotePage', routeConfiguration()), {
      from: 'CompanyAccountPage',
      quoteInformation,
      processStartDate,
    });
  };

  const formattedStartDate = moment(processStartDate.date).format('YYYY-MM-DD');

  const tabIconClass = classNames(css.tabIcon, {
    [css.current]: isCurrentTab,
    [css.passed]: isPassed,
  });

  const vat = couponCode ? priceWithDiscount * 0.25 : originalPrice * 0.25;

  const finalPrice =
    priceWithDiscount === null || priceWithDiscount === undefined
      ? originalPrice
      : priceWithDiscount;

  const finalPriceWithVat = finalPrice + vat;

  const packageData = PackageModel.currentPackage({ selectedPackage });
  const bindingPeriodValue =
    packageData?.value &&
    intl.formatMessage(
      { id: 'SubscriptionStartDateForm.bindingPeriodValue' },
      { months: packageData.value }
    );

  return (
    <div className={css.tabContainer}>
      <div className={css.sectionTitleWrapper}>
        <h2 className={css.sectionTitle}>{intl.formatMessage({ id: 'SummaryTab.title' })}</h2>
        {!isMobileLayout && !isCurrentTab && (
          <span className={css.editButton} onClick={handleBackToQuotePage}>
            {intl.formatMessage({ id: 'SummaryTab.editDetails' })}
          </span>
        )}
      </div>
      {!isMobileLayout && <div className={tabIconClass}>1</div>}

      <div>
        <p className={css.sectionContent}>{intl.formatMessage({ id: 'SummaryTab.content' })}</p>
        <div className={css.quoteInformationWrapper}>
          {isMonthlySubscription && (
            <div className={css.quoteInformationItem}>
              <span className={css.itemTitle}>
                {intl.formatMessage({ id: 'SummaryTab.startDate' })}
              </span>
              <span className={css.itemValue}>{formattedStartDate}</span>
            </div>
          )}
          {packageData?.value > 1 && (
            <div className={css.quoteInformationItem}>
              <span className={css.itemTitle}>
                {intl.formatMessage({ id: 'SubscriptionStartDateForm.bindingPeriod' })}
              </span>
              <span className={css.itemValue}>{bindingPeriodValue}</span>
            </div>
          )}
          <div className={css.quoteInformationItem}>
            <span className={css.itemTitle}>
              {intl.formatMessage({ id: 'SummaryTab.totalCredits' })}
            </span>
            <span className={css.itemValue}>{totalCredits}</span>
          </div>

          <div className={css.quoteInformationItem}>
            <span className={css.itemTitle}>
              {intl.formatMessage({ id: 'SummaryTab.exclVat' })}
            </span>
            <span className={css.itemValue}>{CouponModel.formatQuotePrice(originalPrice)}</span>
          </div>
          {/* todo - sort the coupon line showing - 0 */}
          {!!retrievedCoupon && (
            <>
              <div className={css.quoteInformationItem}>
                <span className={css.itemTitle}>
                  {intl.formatMessage({ id: 'SummaryTab.couponDiscount' })}
                </span>
                <span className={css.itemValue}>
                  {'- '}
                  {CouponModel.formatQuotePrice(couponDiscount)}
                </span>
              </div>

              <div className={css.quoteInformationItem}>
                <span className={css.itemTitle}>
                  {intl.formatMessage({ id: 'SummaryTab.priceWithDiscount' })}
                </span>
                <span className={css.itemValue}>{CouponModel.formatQuotePrice(finalPrice)}</span>
              </div>
            </>
          )}

          <div className={css.quoteInformationItem}>
            <span className={css.itemTitle}>{intl.formatMessage({ id: 'SummaryTab.vat' })}</span>
            <span className={css.itemValue}>{CouponModel.formatQuotePrice(vat)}</span>
          </div>

          {!!retrievedCoupon && (
            <div className={css.quoteInformationItem}>
              <span className={css.itemTitle}>
                {intl.formatMessage({ id: 'SummaryTab.couponCode' })}
              </span>
              <span className={css.itemValue}>{couponCode}</span>
            </div>
          )}

          <div className={css.lastQuoteInformationItem}>
            <span className={css.itemTitle}>
              {intl.formatMessage({ id: 'SummaryTab.totalPrice' })}
            </span>
            <div className={css.itemValueWrapper}>
              <span className={css.value}>
                <span>{CouponModel.formatQuotePrice(finalPriceWithVat)}</span>
                &nbsp;
                {isMonthlySubscription && intl.formatMessage({ id: 'SummaryTab.currencyPerMonth' })}
              </span>
            </div>
          </div>
        </div>

        {!!retrievedCoupon?.period && (
          <p className={css.discountDuration}>
            {intl.formatMessage({ id: 'SummaryTab.discountDuration' }, { count: period })}
          </p>
        )}
      </div>

      <span className={css.fixedContent}>
        {intl.formatMessage({ id: 'SummaryTab.notChargedYet' })}
      </span>
      {!isMobileLayout && isCurrentTab ? (
        <div className={css.bottomButtonWrapper}>
          <Button type="button" className={css.backButton}>
            {intl.formatMessage({ id: 'SummaryTab.backButton' })}
          </Button>
          <PrimaryButton type="button" className={css.submitButton} onClick={handleNextTab}>
            {intl.formatMessage({ id: 'SummaryTab.looksGoodButton' })}
          </PrimaryButton>
        </div>
      ) : isMobileLayout ? (
        <div className={css.bottomButtonWrapper}>
          <Button type="button" className={css.backButton} onClick={handleBackToQuotePage}>
            {intl.formatMessage({ id: 'SummaryTab.backButton' })}
          </Button>
          <PrimaryButton type="button" className={css.submitButton} onClick={handleNextTab}>
            {intl.formatMessage({ id: 'SummaryTab.looksGoodButton' })}
          </PrimaryButton>
        </div>
      ) : null}
    </div>
  );
};

SummaryTab.defaultProps = {
  quoteInformation: {
    monthPrice: 0,
    employeeNumber: 0,
    totalCredits: 0,
  },
  processStartDate: {
    date: new Date(),
  },
};
export default SummaryTab;
