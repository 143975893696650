import React from 'react';
import { injectIntl } from 'react-intl';

import { MediaAndTextSection } from '../../../../components_new';
import {
  BUTTON_SIZES,
  BUTTON_TYPES,
  LINK_COLORS,
} from '../../../../components_new/ButtonNew/ButtonNew';
import {
  TEXT_ALIGN,
  TEXT_ALIGN_MOBILE,
} from '../../../../components_new/MediaAndTextSection/MediaAndTextSection';
import { intlShape } from '../../../../util/reactIntl';
import css from './SectionTagline.module.scss';
import image from './image.png';

const SectionTagline = ({ intl }) => {
  const SECTION_NAME = 'LandingPage.SectionTagline';
  return (
    <div className={css.section}>
      <MediaAndTextSection
        title={{
          content: intl.formatMessage({ id: `${SECTION_NAME}.title` }),
          showMobile: true,
        }}
        subtitle={{
          content: intl.formatMessage({ id: `${SECTION_NAME}.subtitle` }),
          showMobile: true,
        }}
        caption={{
          text: intl.formatMessage({ id: `${SECTION_NAME}.text` }),
          showMobile: true,
        }}
        buttonsList={[
          {
            linkText: intl.formatMessage({ id: `${SECTION_NAME}.link` }),
            name: 'OnboardingPage',
            type: BUTTON_TYPES.WHITE,
            size: BUTTON_SIZES.SMALL,
            linkColor: LINK_COLORS.GREEN,
          },
        ]}
        textAlignMobile={TEXT_ALIGN_MOBILE.TOP}
        textAlign={TEXT_ALIGN.RIGHT}
        media={{
          content: image,
          showMobile: true,
        }}
      />
    </div>
  );
};

SectionTagline.propTypes = {
  intl: intlShape,
};

export default injectIntl(SectionTagline);
