import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconPeople.module.scss';

const IconPeople = (props) => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);
  return (
    <svg
      className={classes}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="7.125" cy="8.625" r="3.375" />
      <path d="M10.9688 13.875C9.64875 13.2047 8.19187 12.9375 7.125 12.9375C5.03531 12.9375 0.75 14.2191 0.75 16.7812V18.75H7.78125V17.9967C7.78125 17.1061 8.15625 16.2131 8.8125 15.4688C9.33609 14.8744 10.0692 14.3227 10.9688 13.875Z" />
      <path d="M15.9375 13.5C13.4967 13.5 8.625 15.0075 8.625 18V20.25H23.25V18C23.25 15.0075 18.3783 13.5 15.9375 13.5Z" />
      <circle cx="15.9375" cy="7.875" r="4.125" />
    </svg>
  );
};

IconPeople.defaultProps = { rootClassName: null, className: null };

const { string } = PropTypes;

IconPeople.propTypes = { rootClassName: string, className: string };

export default IconPeople;
