import {
  addConnectionHazura,
  getConnectionsHazura,
  getConnectionsWithEmailHazura,
  getRequestsHazura,
  getUsersHazura,
  requestTeamScheduleHazura,
  updateConnectionHazura,
} from '../util/api';
import { getLocale } from '../util/localeHelper';

export const UPDATE_TEAM_SCHEDULE = 'app/b2b/UPDATE_TEAM_SCHEDULE';
export const UPDATE_CONNECTIONS = 'app/b2b/UPDATE_CONNECTIONS';
export const UPDATE_REQUESTS = 'app/b2b/UPDATE_REQUESTS';
export const TOGGLE_TEAM_SCHEDULE_LOADING = 'app/b2b/TOGGLE_TEAM_SCHEDULE_LOADING';
export const TOGGLE_CONNECTIONS_LOADING = 'app/b2b/TOGGLE_CONNECTIONS_LOADING';
export const TOGGLE_REQUESTS_LOADING = 'app/b2b/TOGGLE_REQUESTS_LOADING';
export const TOGGLE_INVITE_LOADING = 'app/b2b/TOGGLE_INVITE_LOADING';
export const TOGGLE_INVITE_SUCCESS = 'app/b2b/TOGGLE_INVITE_SUCCESS';
export const TOGGLE_INVITE_ERROR = 'app/b2b/TOGGLE_INVITE_ERROR';
export const TOGGLE_ALREADY_HAVE_CONNECTION = 'app/b2b/TOGGLE_ALREADY_HAVE_CONNECTION';

const initialState = {
  teamSchedule: [],
  connections: [], // My own sent invitations + invitations from another users, which I accepted
  requests: [], // Pending requests from another users
  teamScheduleLoading: true,
  connectionsLoading: true,
  requestsLoading: true,
  inviteLoading: false,
  isInviteSuccessful: false,
  isInviteFailed: false,
  alreadyHaveConnection: false,
};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;

  switch (type) {
    case UPDATE_TEAM_SCHEDULE:
      return {
        ...state,
        teamSchedule: payload,
      };

    case UPDATE_CONNECTIONS:
      return {
        ...state,
        connections: payload,
      };

    case UPDATE_REQUESTS:
      return {
        ...state,
        requests: payload,
      };

    case TOGGLE_TEAM_SCHEDULE_LOADING:
      return {
        ...state,
        teamScheduleLoading: payload,
      };

    case TOGGLE_CONNECTIONS_LOADING:
      return {
        ...state,
        connectionsLoading: payload,
      };

    case TOGGLE_REQUESTS_LOADING:
      return {
        ...state,
        requestsLoading: payload,
      };

    case TOGGLE_INVITE_LOADING:
      return {
        ...state,
        inviteLoading: payload,
      };

    case TOGGLE_INVITE_SUCCESS:
      return {
        ...state,
        isInviteSuccessful: payload,
      };

    case TOGGLE_INVITE_ERROR:
      return {
        ...state,
        isInviteFailed: payload,
      };

    case TOGGLE_ALREADY_HAVE_CONNECTION:
      return {
        ...state,
        alreadyHaveConnection: payload,
      };

    default:
      return state;
  }
}

export const updateTeamScheduleAction = (payload) => ({
  type: UPDATE_TEAM_SCHEDULE,
  payload,
});

export const updateConnectionsAction = (payload) => ({
  type: UPDATE_CONNECTIONS,
  payload,
});

export const updateRequestsAction = (payload) => ({
  type: UPDATE_REQUESTS,
  payload,
});

const toggleTeamScheduleLoading = (payload) => ({
  type: TOGGLE_TEAM_SCHEDULE_LOADING,
  payload,
});

const toggleConnectionsLoading = (payload) => ({
  type: TOGGLE_CONNECTIONS_LOADING,
  payload,
});

const toggleRequestsLoading = (payload) => ({
  type: TOGGLE_REQUESTS_LOADING,
  payload,
});

export const toggleInviteLoading = (payload) => ({
  type: TOGGLE_INVITE_LOADING,
  payload,
});

export const toggleInviteSuccess = (payload) => ({
  type: TOGGLE_INVITE_SUCCESS,
  payload,
});

export const toggleInviteError = (payload) => ({
  type: TOGGLE_INVITE_ERROR,
  payload,
});

export const toggleAlreadyHaveConnection = (payload) => ({
  type: TOGGLE_ALREADY_HAVE_CONNECTION,
  payload,
});

export const updateTeamSchedule = (payload) => (dispatch) =>
  dispatch(updateTeamScheduleAction(payload));

export const getTeamSchedule = (companyAccountId) => (dispatch, getState) => {
  dispatch(toggleTeamScheduleLoading(true));
  const userId = getState().user?.currentUser?.id?.uuid;

  userId &&
    requestTeamScheduleHazura({ companyAccountId: companyAccountId || userId, userId }).then(
      (res) => {
        dispatch(updateTeamScheduleAction(res));
        dispatch(toggleTeamScheduleLoading(false));
      }
    );
};

export const updateConnections = (payload) => (dispatch) =>
  dispatch(updateConnectionsAction(payload));

export const getConnections = () => async (dispatch, getState) => {
  dispatch(toggleConnectionsLoading(true));
  const state = getState();

  if (state.user?.currentUser?.id?.uuid) {
    const response = await getConnectionsHazura({
      userId: state.user?.currentUser?.id?.uuid,
    });

    if ('connections' in response?.data) {
      dispatch(updateConnections(response?.data?.connections));
    }
    dispatch(toggleConnectionsLoading(false));
  }
};

export const updateRequests = (payload) => (dispatch) => dispatch(updateRequestsAction(payload));

export const getRequests = () => async (dispatch, getState) => {
  dispatch(toggleRequestsLoading(true));
  const state = getState();
  const { email } = state.user?.currentUser?.attributes;

  if (email) {
    const response = await getRequestsHazura({
      email,
    });

    if ('connections' in response?.data) {
      dispatch(updateRequests(response?.data?.connections));
    }
    dispatch(toggleRequestsLoading(false));
  }
};

const getUsers = async (email) => {
  const response = await getUsersHazura({ email });

  if ('users' in response?.data) {
    return response?.data?.users[0];
  }
};

const getConnectionsWithEmail = async ({ email, currentUserId }) => {
  const response = await getConnectionsWithEmailHazura({ currentUserId, email });

  if ('connections' in response?.data) {
    return response?.data?.connections;
  }
};

export const inviteUser = (email) => async (dispatch, getState) => {
  dispatch(toggleAlreadyHaveConnection(false));
  dispatch(toggleInviteError(false));
  dispatch(toggleInviteSuccess(false));
  dispatch(toggleInviteLoading(true));

  const existingUser = await getUsers(email);
  const existingConnections = await getConnectionsWithEmail({
    email,
    currentUserId: getState()?.user?.currentUser?.id?.uuid,
  });

  if (existingConnections.length) {
    dispatch(toggleInviteLoading(false));
    dispatch(toggleAlreadyHaveConnection(true));
    return;
  }

  const currentUserId = getState().user?.currentUser?.id?.uuid;
  const locale = getLocale();

  const response = await addConnectionHazura({
    currentUserId,
    email,
    existingUserId: existingUser?.sharetribe_id || null,
    locale,
  });

  dispatch(toggleInviteLoading(false));

  if ('data' in response && 'insert_connections_one' in response?.data) {
    const hazuraResponse = response?.data?.insert_connections_one;
    const stateConnections = [...getState().TeamSchedule.connections];
    stateConnections.push(hazuraResponse);
    dispatch(updateConnections(stateConnections));
    dispatch(toggleInviteSuccess(true));
  } else {
    dispatch(toggleInviteError(true));
  }
};

export const updateConnection =
  ({ newStatus, connectionId }) =>
  async (dispatch, getState) => {
    const userId = getState().user?.currentUser?.id?.uuid;
    const locale = getLocale();

    const response = await updateConnectionHazura({
      userId,
      connectionId,
      newStatus,
      locale,
    });

    if ('update_connections' in response?.data) {
      const hazuraResponse = response?.data?.update_connections?.returning[0];
      const stateConnections = [...getState().TeamSchedule.connections];
      const stateRequests = [...getState().TeamSchedule.requests];
      if (hazuraResponse.status === 'request_cancelled') {
        dispatch(updateConnections(stateConnections.filter((item) => item.id !== connectionId)));
        dispatch(updateRequests(stateRequests.filter((item) => item.id !== connectionId)));
      } else if (hazuraResponse.status === 'request_accepted') {
        dispatch(updateRequests(stateRequests.filter((item) => item.id !== connectionId)));
        stateConnections.push(hazuraResponse);
        dispatch(updateConnections(stateConnections));
      }
    }
  };
