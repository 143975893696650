import React from 'react';
import classNames from 'classnames';
import { createSlug, stringify } from '../../util/urlHelpers';
import { NamedLink, ResponsiveImage } from '../../components';

import css from './TransactionPanel.module.scss';

// Functional component as a helper to build AddressLinkMaybe
const DetailCardImage = (props) => {
  const { className, rootClassName, listingTitle, locationName, locationId, image } = props;

  const classes = classNames(rootClassName || css.detailCardImageWrapper, className);
  let searchParams = {};
  const params =
    locationId && locationName
      ? {
          id: locationId ? locationId : null,
          slug: createSlug(locationName ? locationName : 'name'),
        }
      : { id: '', slug: '' };
  const to = { search: stringify(searchParams) };

  return !!locationId && !!locationName ? (
    <React.Fragment>
      <div className={classes}>
        <div className={css.aspectWrapper}>
          <NamedLink className={className} name="LocationPage" params={params} to={to}>
            <ResponsiveImage
              rootClassName={css.rootForImage}
              alt={listingTitle}
              image={image}
              variants={['landscape-crop', 'landscape-crop2x']}
            />
          </NamedLink>
        </div>
      </div>
    </React.Fragment>
  ) : null;
};

export default DetailCardImage;
