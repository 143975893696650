import React from 'react';

const CheckmarkIcon = () => (
  <svg width="9" height="8" viewBox="0 0 9 8" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8.56118 1.10829C8.64604 1.1838 8.69745 1.28991 8.70412 1.4033C8.71079 1.51669 8.67217 1.62809 8.59676 1.71303L4.02542 6.85611C3.98521 6.90135 3.93587 6.93757 3.88065 6.96238C3.82543 6.98718 3.76558 7 3.70505 7C3.64452 7 3.58467 6.98718 3.52945 6.96238C3.47423 6.93757 3.42489 6.90135 3.38468 6.85611L1.09901 4.28457C1.02792 4.19905 0.992849 4.08924 1.00122 3.97834C1.00958 3.86744 1.06073 3.76414 1.14385 3.69025C1.22697 3.61637 1.33556 3.57769 1.44667 3.58238C1.55779 3.58707 1.66273 3.63477 1.73932 3.7154L3.70484 5.9265L7.95644 1.14344C8.03201 1.05864 8.13815 1.00731 8.25154 1.00072C8.36493 0.994133 8.4763 1.03282 8.56118 1.10829Z"
      fill="#74AF84"
      stroke="#74AF84"
      strokeWidth="0.5"
    />
  </svg>
);

export default CheckmarkIcon;
