import React, { useState, useEffect } from 'react';
import classNames from 'classnames';

import { ButtonNew } from '../../components_new';
import { BUTTON_TYPES } from '../../components_new/ButtonNew/ButtonNew';
import { isMobile } from '../../util/device';

import AvatarSecondary from './AvatarSecondary';

import sortByEmailFunc from './model/sortByEmail';
import prepareDisplayData from './model/prepareDisplayData';
import {
  PendingPlaceholder,
  PendingMobilePlaceholder,
  ConnectionsPlaceholder,
  ConnectionsMobilePlaceholder,
} from './ui/Loaders';

import css from './ConnectionsTable.module.scss';
import AcceptedIcon from './ui/icons/AcceptedIcon';
import SortByIcon from './ui/icons/SortByIcon';

const ConnectionsTable = ({
  items,
  type,
  isLoading,
  currentUserId,
  onAccept,
  onDecline,
  acceptedRequests,
  intl,
}) => {
  const [sortByEmail, setSortByEmail] = useState('');
  const [preparedData, setPreparedData] = useState([]);

  const toggleSortByEmail = () => {
    setSortByEmail(['', 'asc'].includes(sortByEmail) ? 'desc' : 'asc');
  };

  const COLUMNS = {
    pending: [
      { id: 'pending', text: intl.formatMessage({ id: 'InboxPage.pendingRequestsTable.title' }) },
    ],
    connections: [
      { id: 'user', text: intl.formatMessage({ id: 'InboxPage.connectionsTable.contacts' }) },
      { id: 'status', text: intl.formatMessage({ id: 'InboxPage.connectionsTable.status' }) },
      {
        id: 'controls',
        text: (
          <button onClick={toggleSortByEmail} className={css.sortByEmailBtn}>
            {isMobile ? (
              <>
                <svg
                  style={{ marginRight: '2px' }}
                  className={sortByEmail === 'desc' && css.rotated}
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M14.6666 10.3337L14.6666 1.00039"
                    stroke="#999999"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M10 5.66669L14.6666 0.999987L19.3333 5.66669"
                    stroke="#999999"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M6 9.33301L6 18.6663"
                    stroke={sortByEmail === '' ? '#999999' : '#222222'}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M10.6666 14L5.99998 18.6667L1.33331 14"
                    stroke={sortByEmail === '' ? '#999999' : '#222222'}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                {'@'}
              </>
            ) : (
              <>
                <SortByIcon
                  className={css.sortByEmailIcon}
                  rotated={sortByEmail === 'desc'}
                  rotatedClassName={css.rotated}
                />
                {intl.formatMessage(
                  { id: 'InboxPage.connectionsTable.sortByEmail' },
                  { bold: (text) => <strong>{text}</strong> }
                )}
              </>
            )}
          </button>
        ),
      },
    ],
  };

  const STATUSES = {
    SENT: {
      id: 'request_sent',
      title: intl.formatMessage({ id: 'InboxPage.connectionsTable.status.requested' }),
    },
    ACCEPTED: {
      id: 'request_accepted',
      title: intl.formatMessage({ id: 'InboxPage.connectionsTable.status.accepted' }),
    },
  };

  STATUSES.ID_TO_DATA = {
    [STATUSES.SENT.id]: STATUSES.SENT,
    [STATUSES.ACCEPTED.id]: STATUSES.ACCEPTED,
  };

  useEffect(() => {
    if (type === 'connections') {
      !isLoading &&
        !!currentUserId &&
        setPreparedData(
          sortByEmailFunc({
            data: prepareDisplayData({ data: items, currentUserId }),
            sortBy: sortByEmail,
          })
        );
    } else {
      setPreparedData(prepareDisplayData({ data: items, currentUserId }));
    }
  }, [sortByEmail, isLoading, items, items.length]);

  const Row = ({ first, only, data, accepted }) => (
    <div className={classNames(css.row, first && css.first, only && css.only)}>
      <div className={classNames(css.cell, css.user)}>
        {!!data.personData && (
          <div className={css.avatarWrapper}>
            <AvatarSecondary
              first_name={data.personData?.first_name || data.personData?.email}
              last_name={data.personData?.last_name}
            />
          </div>
        )}
        <div className={css.userData}>
          {!!data.personData?.first_name && (
            <p
              className={css.fullName}
            >{`${data.personData?.first_name} ${data.personData?.last_name}`}</p>
          )}
          <span className={css.email}>{data.personData?.email || data.person_b_email}</span>
        </div>
      </div>
      {type === 'connections' ? (
        <>
          <div className={classNames(css.cell, css.status)}>
            <div
              className={classNames(
                css.circle,
                data.status === STATUSES.SENT.id ? css.inactive : css.active
              )}
            />
            <span>{STATUSES.ID_TO_DATA[data.status]?.title}</span>
          </div>
          <div className={classNames(css.cell, css.controls)}>
            <button onClick={() => onDecline(data.id)} className={css.deleteButton}>
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1 4.60002H19"
                  stroke="#E8909C"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M6.40002 4.6V2.8C6.40002 1.80589 7.20591 1 8.20002 1H11.8C12.7941 1 13.6 1.80589 13.6 2.8V4.6"
                  stroke="#E8909C"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M7.29998 8.19995V15.4"
                  stroke="#E8909C"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M12.7 8.19995V15.4"
                  stroke="#E8909C"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M17.2 4.59998V17.2C17.2 18.1941 16.3942 19 15.4 19H4.60005C3.60594 19 2.80005 18.1941 2.80005 17.2V4.59998"
                  stroke="#E8909C"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>

              <span>{intl.formatMessage({ id: 'InboxPage.connectionsTable.delete' })}</span>
            </button>
          </div>
        </>
      ) : accepted ? (
        <AcceptedIcon className={css.acceptedIcon} />
      ) : (
        <div className={classNames(css.cell, css.controls)}>
          <button className={css.ignoreButton} onClick={() => onDecline(data.id)}>
            {intl.formatMessage({ id: 'InboxPage.pendingRequestsTable.ignore' })}
          </button>
          <ButtonNew
            className={css.acceptButton}
            type={BUTTON_TYPES.GREEN}
            onClick={() => onAccept(data.id)}
          >
            {intl.formatMessage({ id: 'InboxPage.pendingRequestsTable.accept' })}
          </ButtonNew>
        </div>
      )}
    </div>
  );

  return (
    <div className={classNames(css.table, css[type])}>
      <div className={css.header}>
        {COLUMNS[type].map((c) => (
          <div
            className={classNames(css.headerCell, css[c.id])}
            key={`table_column_${c.id}_${c.text}`}
          >
            {c.text}
          </div>
        ))}
      </div>
      {isLoading ? (
        type === 'connections' ? (
          isMobile ? (
            <ConnectionsMobilePlaceholder />
          ) : (
            <ConnectionsPlaceholder />
          )
        ) : isMobile ? (
          <PendingMobilePlaceholder />
        ) : (
          <PendingPlaceholder />
        )
      ) : (
        preparedData.map((row, i) => (
          <Row
            key={`table_row_${row.id}`}
            data={row}
            first={i === 0}
            only={items.length === 1}
            accepted={acceptedRequests?.includes(row.id)}
          />
        ))
      )}
    </div>
  );
};

ConnectionsTable.defaultProps = {
  type: 'connections',
};

export default ConnectionsTable;
