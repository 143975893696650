import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { connect } from 'react-redux';

import { TopbarContainer } from '..';
import {
  LayoutSideNavigation,
  LayoutWrapperMain,
  LayoutWrapperSideNav,
  LayoutWrapperTopbar,
  Page,
  TabNav,
} from '../../components';
import { isScrollingDisabled } from '../../ducks/UI.duck';

import SectionAvailableCredit from './SectionAvailableCredit';
import SectionBookings from './SectionBookings';

import css from './CompanyBookingsPage.module.scss';

const CompanyBookingsPageComponent = ({
  scrollingDisabled,
  upcomingRefsLoading,
  previousRefsLoading,
}) => {
  const intl = useIntl();
  const title = intl.formatMessage({ id: 'CompanyBookingsPage.title' });

  const tabs = [
    {
      text: <FormattedMessage id="CompanyBookingsPageComponent.companyTabTitle" />,
      linkProps: {
        name: 'CompanyBookingsPage',
      },
    },
    {
      text: <FormattedMessage id="InboxPage.ordersTabTitle" />,
      linkProps: {
        name: 'InboxPage',
        params: { tab: 'orders' },
      },
    },
    {
      text: intl.formatMessage({ id: 'InboxPage.scheduleTabTitle' }),
      linkProps: {
        name: 'InboxPage',
        params: { tab: 'schedule' },
      },
    },
  ].map((tab, i) => ({
    ...tab,
    selected: i === 0,
    linkClassName: css.tabLabel,
    linkSelectedClassName: css.tabLabelSelected,
  }));

  const nav = <TabNav rootClassName={css.tabs} tabRootClassName={css.tab} tabs={tabs} />;
  return (
    <Page title={title} scrollingDisabled={scrollingDisabled}>
      <LayoutSideNavigation containerClassName={css.container}>
        <LayoutWrapperTopbar>
          <TopbarContainer
            className={css.topbar}
            mobileRootClassName={css.mobileTopbar}
            desktopClassName={css.desktopTopbar}
            currentPage="InboxPage"
          />
        </LayoutWrapperTopbar>
        <LayoutWrapperSideNav className={css.navigation}>
          {/* TODO: add company logo here */}
          {nav}
        </LayoutWrapperSideNav>
        <LayoutWrapperMain className={css.main} hideTopbarSpacer={true}>
          <SectionAvailableCredit />
          <SectionBookings
            upcomingRefsLoading={upcomingRefsLoading}
            previousRefsLoading={previousRefsLoading}
          />
        </LayoutWrapperMain>
      </LayoutSideNavigation>
    </Page>
  );
};

const mapStateToProps = (state) => {
  const { upcomingRefsLoading, previousRefsLoading } = state.CompanyBookingsPage;

  return {
    scrollingDisabled: isScrollingDisabled(state),
    upcomingRefsLoading,
    previousRefsLoading,
  };
};

const CompanyBookingsPage = connect(mapStateToProps)(CompanyBookingsPageComponent);

export default CompanyBookingsPage;
