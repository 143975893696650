import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';
import { useIntl } from 'react-intl';

import { LISTING_STATE_DRAFT } from '../../util/types';
import { ensureListing } from '../../util/data';
import { EditListingFeaturesForm } from '../../forms';
import { ListingLink } from '../../components';

import css from './EditListingFeaturesPanel.module.scss';
import { modernMTTranslate } from '../../util/modernmt';

const FEATURES_NAME = 'ameneties';

const EditListingFeaturesPanel = (props) => {
  const {
    rootClassName,
    className,
    listing,
    disabled,
    ready,
    onSubmit,
    onChange,
    submitButtonText,
    panelUpdated,
    updateInProgress,
    errors,
    extraInfoPlaceholderLabel,
  } = props;

  const intl = useIntl();
  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureListing(listing);
  const { publicData } = currentListing.attributes;

  const isPublished = currentListing.id && currentListing.attributes.state !== LISTING_STATE_DRAFT;
  const panelTitle = isPublished ? (
    <FormattedMessage
      id="EditListingFeaturesPanel.title"
      values={{
        listingTitle: (
          <ListingLink listing={listing}>{listing.attributes.publicData.title}</ListingLink>
        ),
      }}
    />
  ) : (
    <FormattedMessage id="EditListingFeaturesPanel.createListingTitle" />
  );

  const ameneties = publicData && publicData.ameneties;
  const amenetiesInfo = publicData && publicData.amenetiesInfo;
  const offer = publicData && publicData.offer;
  const category = publicData && publicData.category;
  const environment = publicData && publicData.environment;
  const noiselevel = publicData && publicData.noiselevel;
  const vibe = publicData && publicData.vibe;
  const perfectFor = publicData && publicData.perfectFor;

  const initialValues = {
    ameneties,
    amenetiesInfo,
    offer,
    perfectFor,
    environment,
    noiselevel,
    vibe,
  };

  return (
    <div className={classes}>
      <h2 className={css.title}>{panelTitle}</h2>
      <p>
        <FormattedMessage id="EditListingFeaturesPanel.subTitle" />
      </p>

      <EditListingFeaturesForm
        className={css.form}
        name={FEATURES_NAME}
        extraInfoPlaceholderLabel={extraInfoPlaceholderLabel}
        initialValues={initialValues}
        onSubmit={async (values) => {
          const { originLang, translationLang } = publicData;
          const {
            ameneties = [],
            amenetiesInfo = {},
            offer = [],
            environment = [],
            noiselevel = '',
            vibe = [],
            perfectFor = [],
          } = values;

          const updatedValues = {
            publicData: {
              ameneties,
              amenetiesInfo,
              offer,
              environment,
              noiselevel,
              vibe,
              perfectFor,
              [originLang]: {
                ...listing.attributes.publicData[originLang],
                ameneties: { ...amenetiesInfo },
              },
              [translationLang]: {
                ...listing.attributes.publicData[translationLang],
                ameneties: {},
              },
            },
          };

          if (updatedValues.publicData.ameneties.length) {
            const textsForTranslation = [];
            const indexList = {};
            updatedValues.publicData.ameneties.forEach((item, i) => {
              textsForTranslation.push(updatedValues.publicData.amenetiesInfo[item]);
              indexList[item] = i;
            });

            const translatedAmenities =
              translationLang &&
              (await modernMTTranslate({
                source: originLang,
                target: translationLang,
                text: textsForTranslation,
              }));

            updatedValues.publicData.ameneties.forEach((item) => {
              updatedValues.publicData[translationLang].ameneties[item] =
                translatedAmenities && translatedAmenities[indexList[item]].translation;
            });
          }
          onSubmit(updatedValues);
        }}
        onChange={onChange}
        saveActionMsg={submitButtonText}
        disabled={disabled}
        ready={ready}
        updated={panelUpdated}
        updateInProgress={updateInProgress}
        fetchErrors={errors}
        category={category}
        intl={intl}
      />
    </div>
  );
};

EditListingFeaturesPanel.defaultProps = {
  rootClassName: null,
  className: null,
  listing: null,
};

const { bool, func, object, string } = PropTypes;

EditListingFeaturesPanel.propTypes = {
  rootClassName: string,
  className: string,

  // We cannot use propTypes.listing since the listing might be a draft.
  listing: object,

  disabled: bool.isRequired,
  ready: bool.isRequired,
  onSubmit: func.isRequired,
  onChange: func.isRequired,
  submitButtonText: string.isRequired,
  panelUpdated: bool.isRequired,
  updateInProgress: bool.isRequired,
  errors: object.isRequired,
};

export default EditListingFeaturesPanel;
