import classNames from 'classnames';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import moment from 'moment';
import { shallowEqual, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import { IconCard, NamedLink, ReactivateSubscriptionSuccessModal } from '../../../components';
import ReactivateSubscriptionModal from '../../../components/ReactivateSubscriptionModal/ReactivateSubscriptionModal';
import PackageModel from '../../../models/PackageModel';
import routeConfiguration from '../../../routeConfiguration';
import { calcCompanyUsedCredits, calcRenewalDate } from '../../../util/b2bHelper';
import { useBoolean } from '../../../util/hooks';
import { createResourceLocatorString } from '../../../util/routes';
import {
  MY_SUBSCRIPTION,
  MY_SUBSCRIPTION_ADD_ONE_TIME_CREDITS_ACTION,
  MY_SUBSCRIPTION_CHANGE_PAYMENT_INFO_ACTION,
  MY_SUBSCRIPTION_EDIT_ACTION,
  SUBSCRIPTION_STATUS_ACTIVE,
  SUBSCRIPTION_STATUS_CANCELLED,
  SUBSCRIPTION_STATUS_PAUSED,
  PACKAGE_FREE,
} from '../../../util/types';
import { formatSubscriptionStatus } from '../utils/subscription';
import AddOneTimeCredits from './AddOneTimeCredits';
import ChangePaymentInfo from './ChangePaymentInfo';
import EditSubscription from './EditSubscription';
import css from './MySubscriptionTab.module.scss';
import ReactivateBox from './ReactivateBox';

const MySubscriptionTab = (props) => {
  const params = useParams();
  const {
    intl,
    selectedPackage,
    earliestAllowableCancelDate,
    isMonthlySubscription,
    selectedCreditAmount,
    usedCredits,
    totalCredits,
    previousUnusedCredits,
    expiryDate,
    freeTrialExpired,
    hasChargebeePaymentSource,
  } = props;
  const history = useHistory();
  const [isResumeModalOpen, setResumeModalOpen] = useBoolean();
  const [isResumeSuccess, setResumeSuccess] = useBoolean();
  const companyAccount = useSelector((state) => state.user.companyAccount, shallowEqual);
  const primaryPaymentSource =
    useSelector((state) => state.SubscriptionManagementPage.primaryPaymentSource, shallowEqual) ||
    {};
  const nextBillingDate = calcRenewalDate(companyAccount, 'MMM DD, YYYY');
  const resumeDate = useSelector(
    (state) => state.SubscriptionManagementPage.resumeDate,
    shallowEqual
  );
  const { action } = params;

  const totalCreditsWithPreviousCredits = totalCredits + previousUnusedCredits;
  const remainingCredits = totalCreditsWithPreviousCredits - usedCredits;

  const credits = get(companyAccount, 'attributes.profile.metadata.totalCredits', 0);
  const renewalDate = calcRenewalDate(companyAccount, 'DD-MM-YYYY');
  const creditsLeft = Math.max(credits - usedCredits, 0);
  const { last4: lastDigits, brand } = primaryPaymentSource;

  const currentPackage = PackageModel.currentPackage({ selectedPackage });
  const subscriptionStatus = get(
    companyAccount,
    'attributes.profile.metadata.subscriptionStatus',
    SUBSCRIPTION_STATUS_ACTIVE
  );
  const isSubscriptionActive = subscriptionStatus === SUBSCRIPTION_STATUS_ACTIVE;
  const isSubscriptionPaused = subscriptionStatus === SUBSCRIPTION_STATUS_PAUSED;
  const isSubscriptionCancelled = subscriptionStatus === SUBSCRIPTION_STATUS_CANCELLED;
  const formattedSubscriptionStatus = formatSubscriptionStatus(subscriptionStatus, intl);
  const subscriptionStatusClasses = classNames(css.subscriptionStatus, {
    [css.active]: isSubscriptionActive,
    [css.cancel]: isSubscriptionCancelled,
    [css.pause]: isSubscriptionPaused,
  });

  const paymentType = get(companyAccount, 'attributes.profile.metadata.paymentType');

  const totalPrice =
    currentPackage && credits
      ? parseFloat((currentPackage.pricePerCredit * credits).toFixed(2))
      : 0;
  const reactivateBoxDate = isSubscriptionCancelled
    ? renewalDate
    : moment(new Date(resumeDate * 1000)).format('DD-MM-YYYY');
  const onReactivateAction = () => {
    if (isSubscriptionCancelled) {
      const to = createResourceLocatorString(
        'SubscriptionManagementPageMySubscription',
        routeConfiguration(),
        { tab: MY_SUBSCRIPTION, action: MY_SUBSCRIPTION_EDIT_ACTION },
        {}
      );
      history.push(to);
    } else if (isSubscriptionPaused) {
      setResumeModalOpen.on();
    }
  };
  if (action === MY_SUBSCRIPTION_EDIT_ACTION) {
    return (
      <EditSubscription
        credits={credits}
        totalPrice={totalPrice}
        renewalDate={renewalDate}
        creditsLeft={creditsLeft}
        packageName={currentPackage?.id}
        subscriptionStatus={subscriptionStatus}
        isResumeSuccess={isResumeSuccess}
        setResumeSuccess={setResumeSuccess}
        selectedPackage={selectedPackage}
        earliestAllowableCancelDate={earliestAllowableCancelDate}
        selectedCreditAmount={selectedCreditAmount}
      />
    );
  } else if (action === MY_SUBSCRIPTION_ADD_ONE_TIME_CREDITS_ACTION) {
    return (
      <AddOneTimeCredits
        selectedCreditAmount={selectedCreditAmount}
        hasChargebeePaymentSource={hasChargebeePaymentSource}
      />
    );
  } else if (action === MY_SUBSCRIPTION_CHANGE_PAYMENT_INFO_ACTION) {
    return <ChangePaymentInfo />;
  } else {
    return (
      <div className={css.tabContainer}>
        <div className={css.tabTitleDesktop}>
          <div>
            <h2 className={css.tabTitleContent}>
              {intl.formatMessage({ id: 'MySubscriptionTab.titleDesktop' })}
            </h2>
            <p>{intl.formatMessage({ id: 'MySubscriptionTab.subTitle' })}</p>
          </div>
          <span className={subscriptionStatusClasses}>{formattedSubscriptionStatus}</span>
        </div>
        <div className={css.reactiveBox}>
          {!isSubscriptionActive && (
            <ReactivateBox
              date={reactivateBoxDate}
              subscriptionStatus={subscriptionStatus}
              onReactivate={onReactivateAction}
            />
          )}
        </div>
        <div className={css.subscriptionInfo}>
          <div className={css.rowDesktop}>
            {selectedPackage === PACKAGE_FREE && expiryDate ? (
              <div>{intl.formatMessage({ id: 'MySubscriptionTab.freeTrial' })}</div>
            ) : isMonthlySubscription ? (
              <div className={css.subscriptionType}>
                {intl.formatMessage({ id: 'MySubscriptionTab.subscriptionTypeSmall' })}
              </div>
            ) : (
              <div className={css.tabTitle}>
                {intl.formatMessage({ id: 'MySubscriptionTab.oneTimeCredits' })}
              </div>
            )}
            {/* <NamedLink
              className={css.editSubscriptionBtnDesktop}
              name={
                isMonthlySubscription ? 'SubscriptionManagementPageMySubscription' : 'RespacesPass'
              }
              to={{ hash: 'section_pricing' }}
              params={
                isMonthlySubscription
                  ? { tab: MY_SUBSCRIPTION, action: MY_SUBSCRIPTION_EDIT_ACTION }
                  : ''
              }
            >
              {intl.formatMessage({
                id: isMonthlySubscription
                  ? 'MySubscriptionTab.editSubcriptionBtn'
                  : 'MySubscriptionTab.buySubcriptionBtn',
              })}
            </NamedLink> */}
          </div>

          <div className={css.creditsWrapperDesktop}>
            {intl.formatMessage(
              { id: 'MySubscriptionTab.credits' },
              {
                span: (msg) => <span className={css.boldText}>{msg}</span>,
                totalCreditsWithPreviousCredits,
              }
            )}
            <br />
          </div>

          <div className={css.creditsWrapperDesktop}>
            {selectedPackage === PACKAGE_FREE && expiryDate && !freeTrialExpired
              ? intl.formatMessage(
                  { id: 'MySubscriptionTab.creditsExpires' },
                  { expiryDate, remainingCredits }
                )
              : freeTrialExpired
              ? intl.formatMessage({
                  id: 'MySubscriptionTab.freeTrialExpired',
                })
              : !isMonthlySubscription
              ? intl.formatMessage(
                  { id: 'MySubscriptionTab.creditPerMonthDesktop' },
                  {
                    span: (msg) => <span className={css.boldText}>{msg}</span>,
                    credits,
                  }
                )
              : null}
          </div>

          {isMonthlySubscription && (
            <>
              <div className={css.renewalDesktop}>
                {intl.formatMessage(
                  { id: 'MySubscriptionTab.creditPerMonthDesktop' },
                  {
                    span: (msg) => <span className={css.boldText}>{msg}</span>,
                    credits,
                  }
                )}
                {intl.formatMessage(
                  { id: 'MySubscriptionTab.renewalDateDesktop' },
                  { renewalDate }
                )}
              </div>
            </>
          )}

          {/* <NamedLink
            className={css.editSubscriptionBtn}
            name="SubscriptionManagementPageMySubscription"
            params={{ tab: MY_SUBSCRIPTION, action: MY_SUBSCRIPTION_EDIT_ACTION }}
          >
            {intl.formatMessage({
              id: isMonthlySubscription
                ? 'MySubscriptionTab.editSubcriptionBtn'
                : 'MySubscriptionTab.buySubcriptionBtn',
            })}
          </NamedLink> */}

          {/* <NamedLink
            className={css.oneTimeCreditsBtn}
            name="SubscriptionManagementPageMySubscription"
            params={{ tab: MY_SUBSCRIPTION, action: MY_SUBSCRIPTION_ADD_ONE_TIME_CREDITS_ACTION }}
          >
            {intl.formatMessage({ id: 'MySubscriptionTab.oneTimeCreditsBtn' })}
          </NamedLink> */}
        </div>
        {isMonthlySubscription && (
          <div className={css.paymentInfo}>
            <div className={css.paymentDetail}>
              {!isEmpty(primaryPaymentSource) && paymentType === 'card' && (
                <div className={css.paymentMethod}>
                  <IconCard brand={brand} className={css.cardIcon} />
                  {`**** **** **** ${lastDigits}`}
                </div>
              )}
              <div className={css.nextBilling}>
                {intl.formatMessage({ id: 'MySubscriptionTab.nextBilling' }, { nextBillingDate })}
              </div>
            </div>
            <NamedLink
              className={css.changePaymentInfo}
              name="SubscriptionManagementPageMySubscription"
              params={{ tab: MY_SUBSCRIPTION, action: MY_SUBSCRIPTION_CHANGE_PAYMENT_INFO_ACTION }}
            >
              {intl.formatMessage({ id: 'MySubscriptionTab.changePayment' })}
            </NamedLink>
          </div>
        )}
        <ReactivateSubscriptionModal
          isOpen={isResumeModalOpen}
          onClose={setResumeModalOpen.off}
          creditsNumber={credits}
          totalPrice={totalPrice}
          renewalDate={renewalDate}
          setResumeSuccess={setResumeSuccess}
        />
        <ReactivateSubscriptionSuccessModal
          isOpen={isResumeSuccess}
          onClose={setResumeSuccess.off}
          isResumeSuccess={isResumeSuccess}
        />
      </div>
    );
  }
};

export default MySubscriptionTab;
