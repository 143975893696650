import debounce from 'lodash/debounce';
import React, { memo, useCallback, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';

import { ButtonNew, CustomCategorySelectFieldMaybe, HintModal } from '../../components_new';
import { BUTTON_TYPES } from '../../components_new/ButtonNew/ButtonNew';
import {
  TABS,
  setLocations,
  toggleCreateLocationLoading,
  updateCreateLocationFields,
  updateCurrentTab,
  updateSelectedLocation,
} from '../../ducks/DashboardPage.duck';
import { filters } from '../../marketplace-custom-config';
import routeConfiguration from '../../routeConfiguration';
import { createLocationHazura } from '../../util/api';
import { inBrowser } from '../../util/device';
import { useBoolean } from '../../util/hooks';
import { getLocale } from '../../util/localeHelper';
import { LANGUAGE_CODES, modernMTTranslate } from '../../util/modernmt';
import { FormattedMessage } from '../../util/reactIntl';
import { createResourceLocatorString } from '../../util/routes';
import { findOptionsForSelectFilter } from '../../util/search';
import { HOST_TYPE_OTHER_COMPANY } from '../../util/types';
import GeocoderGoogleMaps from '../ListingSearchForm/model/GeocoderGoogleMaps';
import css from './CreateLocationForm.module.scss';
import { DayAvailabilityPlan, FieldHeader, LanguageToggle, LocationInput, Textarea } from './ui';
import ImageInput from './ui/ImageInput/ImageInput';

const geocoder = new GeocoderGoogleMaps();

const CreateLocationForm = memo(() => {
  const intl = useIntl();
  const history = useHistory();
  const dispatch = useDispatch();
  const { createLocationFields, createLocationLoading, currentUser, locations } = useSelector(
    (state) => {
      const {
        Dashboard: { createLocationFields, createLocationLoading, locations },
        user: { currentUser },
      } = state;

      return {
        createLocationFields,
        createLocationLoading,
        currentUser,
        locations,
      };
    }
  );

  const currentLocale = getLocale();
  const [isHintModalOpen, setIsHintModalOpen] = useBoolean();
  const [predictions, setPredictions] = useState([]);
  const [currentLang, updateCurrentLang] = useState(currentLocale);
  const [translationLoading, toggleTranslationLoading] = useState(false);

  const getOppositeLocale = useCallback(() => {
    return currentLang === LANGUAGE_CODES.SWEDISH ? LANGUAGE_CODES.ENGLISH : LANGUAGE_CODES.SWEDISH;
  }, [currentLang]);

  if (!createLocationFields.id || createLocationFields.id === '') {
    dispatch(updateCreateLocationFields({ id: uuidv4() }));
  }

  const translate = useCallback(
    async ({ text, target }) => {
      toggleTranslationLoading(true);
      const response = await modernMTTranslate({
        target,
        text,
      });
      const { translation } = response ? response : {};
      const descriptionKey = `description_${target}`;

      dispatch(updateCreateLocationFields({ [descriptionKey]: translation }));

      updateCurrentLang(target);
      toggleTranslationLoading(false);
    },
    [getOppositeLocale, updateCreateLocationFields]
  );

  const translateAuto = useCallback(async ({ text, target }) => {
    toggleTranslationLoading(true);
    const response = await modernMTTranslate({
      target,
      text,
    });
    const { translation } = response ? response : {};
    toggleTranslationLoading(false);
    return translation;
  }, []);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const getPredictions = useCallback(
    debounce(async (value) => {
      const result = await geocoder.getPlacePredictions(value);
      setPredictions(result.predictions);
    }, 500),
    []
  );

  const handleSelectPrediction = useCallback(
    async (prediction) => {
      dispatch(
        updateCreateLocationFields({
          address: prediction.description,
          name: prediction?.structured_formatting?.main_text,
        })
      );

      const { origin: geolocation } = await geocoder.getPlaceDetails(prediction);
      dispatch(updateCreateLocationFields({ geolocation }));
      setPredictions([]);
    },
    [updateCreateLocationFields]
  );

  const handleInput = useCallback(
    async ({ id, value }) => {
      if (id === 'address' && !!value) {
        await getPredictions(value);
      }
      dispatch(updateCreateLocationFields({ [id]: value }));
    },
    [getPredictions, updateCreateLocationFields]
  );

  const clearForm = useCallback(() => {
    dispatch(
      updateCreateLocationFields({
        id: uuidv4(),
        address: '',
        geolocation: {},
        name: '',
        host: '',
        description_en: '',
        description_sv: '',
        opening_hours: {},
        images: [],
      })
    );
  }, [updateCreateLocationFields]);

  const onSubmit = async (e) => {
    e.preventDefault();
    dispatch(toggleCreateLocationLoading(true));
    const { description_en, description_sv, id, ...rest } = createLocationFields;
    let autoTranslationEn = '',
      autoTranslationSv = '';

    if (!!description_en && !description_sv) {
      const translateResponse = await translateAuto({
        text: description_en,
        target: LANGUAGE_CODES.SWEDISH,
      });
      autoTranslationSv = translateResponse;
    } else if (!!description_sv && !description_en) {
      console.log('translating swedish to english');
      const translateResponse = await translateAuto({
        text: description_sv,
        target: LANGUAGE_CODES.ENGLISH,
      });
      autoTranslationEn = translateResponse;
    }

    const translations = {
      description_en: !!description_en ? description_en : autoTranslationEn,
      description_sv: !!description_sv ? description_sv : autoTranslationSv,
    };

    const locationResponse = await createLocationHazura({
      locationData: {
        id,
        owner_id: currentUser?.id?.uuid,
        status: 'published',
        updated_at: new Date(Date.now()),
        ...translations,
        ...rest,
      },
    });
    dispatch(toggleCreateLocationLoading(false));
    const isUpdate = 'update_locations' in locationResponse?.data;
    if (isUpdate || 'insert_locations_one' in locationResponse?.data) {
      locations.push({ ...createLocationFields });
      dispatch(setLocations(locations));
      dispatch(updateSelectedLocation({ ...createLocationFields }));
      updateCreateLocationFields({
        id: '',
        address: '',
        geolocation: {},
        name: '',
        host: '1',
        description_en: '',
        description_sv: '',
        opening_hours: {},
        images: [],
      });
      if (inBrowser()) {
        const url = isUpdate
          ? createResourceLocatorString('DashboardPageTargetedListing', routeConfiguration(), {
              view: 'locations',
              tab: 'listings',
              id: createLocationFields.id,
            })
          : createResourceLocatorString('NewListingPage', routeConfiguration(), {});
        history.push(url);
      }
    } else if (locationResponse && locationResponse.errors) {
      console.log(locationResponse.error);
      // todo - handle error
    }
  };

  useEffect(() => {
    return () => {
      clearForm();
    };
  }, []);

  const hostOptions = findOptionsForSelectFilter('host', filters);
  const handleHostChange = (value) => {
    handleInput({ id: 'host', value });
  };

  return (
    <form onSubmit={onSubmit} className={css.form}>
      <fieldset className={css.fieldset}>
        <FieldHeader label="CreateLocationForm.host.label" />
        <CustomCategorySelectFieldMaybe
          id="hostType"
          name="hostType"
          categoryOptions={hostOptions}
          intl={intl}
          categoryInitialOption={createLocationFields.host}
          onCategoryChange={handleHostChange}
        />
      </fieldset>
      <fieldset className={css.fieldset}>
        <FieldHeader label="CreateLocationForm.address.label" />
        <LocationInput
          address={createLocationFields.address}
          geolocation={createLocationFields.geolocation}
          onInput={(value) => handleInput({ id: 'address', value })}
          onSelectPrediction={handleSelectPrediction}
          predictions={predictions}
          placeholder={intl.formatMessage({ id: 'CreateLocationForm.address.placeholder' })}
          id="address"
        />
      </fieldset>
      {/* Remove !== '3' after migrating data in database */}
      {createLocationFields.host !== '3' &&
        createLocationFields.host !== HOST_TYPE_OTHER_COMPANY && (
          <fieldset className={css.fieldset}>
            <FieldHeader
              label="CreateLocationForm.name.label"
              caption="CreateLocationForm.name.caption"
            />
            <input
              value={createLocationFields.name}
              onInput={({ currentTarget: { value } }) => handleInput({ id: 'name', value })}
              type="text"
              name="name"
              id="name"
              placeholder={intl.formatMessage({ id: 'CreateLocationForm.name.placeholder' })}
              className={css.input}
            />
          </fieldset>
        )}
      <fieldset className={css.fieldset}>
        <FieldHeader
          label="CreateLocationForm.description.label"
          caption="CreateLocationForm.description.caption"
          hintHeader="CreateLocationForm.description.hintHeader"
          onClickHint={setIsHintModalOpen.on}
        />
        <LanguageToggle onChangeLang={updateCurrentLang} currentLang={currentLang} />
        <Textarea
          value={createLocationFields[`description_${currentLang}`]}
          onInput={(value) => handleInput({ id: `description_${currentLang}`, value })}
          charLimit={1500}
          placeholder={intl.formatMessage({ id: 'CreateLocationForm.description.placeholder' })}
          id="description"
        />
        <div className={css.translateBtnWrapper}>
          <ButtonNew
            type={BUTTON_TYPES.GREEN}
            inProgress={translationLoading}
            onClick={() =>
              translate({
                text: createLocationFields[`description_${getLocale()}`],
                target: getOppositeLocale(),
              })
            }
            disabled={
              createLocationFields?.[`description_${getLocale()}`]?.length === 0 ||
              currentLang !== currentLocale
            }
            nativeType="button"
          >
            {intl.formatMessage({ id: 'CreateLocationForm.description.translateBtn' })}
          </ButtonNew>
        </div>
      </fieldset>
      {/* Remove !== '3' after migrating data in database */}
      {createLocationFields.host !== '3' &&
        createLocationFields.host !== HOST_TYPE_OTHER_COMPANY && (
          <fieldset className={css.fieldset}>
            <FieldHeader
              label="CreateLocationForm.opening_hours.label"
              caption="CreateLocationForm.opening_hours.caption"
            />
            <DayAvailabilityPlan
              values={createLocationFields.opening_hours}
              onUpdateValues={(value) => handleInput({ id: 'opening_hours', value })}
            />
          </fieldset>
        )}
      <fieldset className={css.fieldset}>
        <FieldHeader
          label="CreateLocationForm.PhotosLabel"
          caption="CreateLocationForm.PhotosCaption"
        />
        <p className={css.caption}>
          {intl.formatMessage({ id: 'CreateLocationForm.PhotosCaption.second' })}
        </p>
        <ImageInput
          images={createLocationFields.images}
          updateImages={(value) => handleInput({ id: 'images', value })}
          intl={intl}
          locationID={createLocationFields.id}
        />
      </fieldset>
      <fieldset className={css.buttonsWrapper}>
        <button
          onClick={(e) => {
            e.preventDefault();
            clearForm();
            dispatch(updateCurrentTab(TABS.LOCATIONS));
          }}
          type="button"
          className={css.discardBtn}
        >
          {intl.formatMessage({ id: 'CreateLocationForm.discard' })}
        </button>
        <ButtonNew
          inProgress={createLocationLoading}
          type={BUTTON_TYPES.BLUE}
          disabled={
            !(createLocationFields.name && createLocationFields.address) ||
            createLocationFields.images?.length === 0
          }
        >
          {intl.formatMessage({ id: 'CreateLocationForm.save' })}
        </ButtonNew>
      </fieldset>
      <HintModal
        isOpen={isHintModalOpen}
        onClose={setIsHintModalOpen.off}
        contentHeader={intl.formatMessage({
          id: 'CreateLocationForm.description.hintContentHeader',
        })}
        contentBody={
          <FormattedMessage
            id={`CreateLocationForm.description.hintContent${
              createLocationFields.host === HOST_TYPE_OTHER_COMPANY ? 'OtherCompany' : ''
            }`}
            values={{ p: (msg) => <p>{msg}</p> }}
          />
        }
      />
    </form>
  );
});

export default CreateLocationForm;
