import React, { memo, useRef, useEffect, useCallback } from 'react';
import { func, number, string } from 'prop-types';

import css from './Textarea.module.scss';

const Textarea = memo(({ id, placeholder, onInput, value, charLimit }) => {
  const formRef = useRef(null);

  const handleInput = (val) => {
    adjustHeight(val);
    onInput(val);
  };

  const adjustHeight = useCallback((val) => {
    const { scrollHeight, clientHeight } = formRef.current;
    if (scrollHeight > clientHeight) {
      formRef.current.style.height = `${scrollHeight}px`;
    } else if (val.length === 0) {
      formRef.current.style.height = '82px';
    }
  }, []);

  useEffect(() => {
    !!value && adjustHeight(value);
  }, []);

  return (
    <div className={css.wrapper}>
      <textarea
        ref={formRef}
        id={id}
        value={value}
        placeholder={placeholder}
        maxLength={charLimit}
        onInput={({ currentTarget: { value: val } }) => handleInput(val)}
        className={css.textarea}
      />
      <span className={css.charCount}>
        {value?.length}/{charLimit}
      </span>
    </div>
  );
});

Textarea.propTypes = {
  id: string,
  value: string,
  onInput: func,
  charLimit: number,
  placeholder: string,
};

export default Textarea;
