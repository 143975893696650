import React, { memo } from 'react';
import { bool, func, string } from 'prop-types';

import { ListingTableAction, LocationTableAction } from '../index';

const TableAction = memo(({ id, action, status, isUnassigned, onInitAction }) =>
  action === 'locationsTableAction' ? (
    <LocationTableAction id={id} onInitAction={onInitAction} />
  ) : action === 'locationDetailsTableAction' ? (
    <ListingTableAction
      id={id}
      status={status}
      onInitAction={onInitAction}
      isUnassigned={isUnassigned}
    />
  ) : null
);

TableAction.propTypes = {
  id: string,
  action: string,
  status: string,
  isUnassigned: bool,
  onInitAction: func,
};

export default TableAction;
