import React from 'react';
import { Form as FinalForm, FormSpy } from 'react-final-form';
import { Form, FieldMonthInput, FieldNumberInput, IconSpinner } from '../../components';
import moment from 'moment';
import css from './BookingMonthForm.module.scss';
import EstimatedBreakdownMaybe from './EstimatedBreakdownMaybe';
import { FormattedMessage } from 'react-intl';
import { MONTHLY_BOOKING } from '../../util/types';
import classNames from 'classnames';
import { composeValidators, minimumValue, required, maximumValue } from '../../util/validators';
import { getMonthStartInTimeZone } from '../../util/dates';
import { ButtonNew } from '../../components_new';
import { BUTTON_TYPES, BUTTON_SIZES } from '../../components_new/ButtonNew/ButtonNew';

const BookingMonthForm = ({
  onFetchTransactionLineItems,
  listingId,
  isOwnListing,
  onSubmit,
  onBackPress,
  bookingData,
  ...props
}) => {
  const handleOnChange = (values) => {
    const { month = {}, seats, numberOfMonths = 1, additionalServices = [] } = values;
    const { start, end } = month;
    if (start && end && seats && numberOfMonths) {
      const endMaybe =
        numberOfMonths > 1
          ? moment(end)
              .add(numberOfMonths - 1, 'month')
              .toDate()
          : end;
      onFetchTransactionLineItems({
        bookingData: {
          startDate: start,
          endDate: endMaybe,
          seats,
          bookingType: 'monthly',
          additionalServices,
        },
        listingId,
        isOwnListing,
      });
    }
  };

  return (
    <FinalForm
      {...props}
      onSubmit={(values) => {
        const { month, seats, numberOfMonths } = values;
        const monthValueMaybe =
          numberOfMonths > 1
            ? { ...month, end: moment(month.end).add(numberOfMonths - 1, 'month') }
            : month;
        onSubmit({
          month: monthValueMaybe,
          seats,
        });
      }}
      initialValues={bookingData}
      render={(formRenderProps) => {
        const {
          handleSubmit,
          pristine,
          timeSlots,
          values,
          lineItems,
          fetchLineItemsInProgress,
          fetchLineItemsError,
          rootClassName,
          className,
          submitButtonWrapperClassName,
          intl,
          form,
          invalid,
          additionalServices,
          listing,
          defaultSearch,
          additionalServicesSection,
        } = formRenderProps;

        // check if user searched for seats - in that case, fill the information into the booking form
        if (defaultSearch && defaultSearch.seats && pristine && !values.seats) {
          values.seats = defaultSearch.seats;
        }

        if (defaultSearch && defaultSearch.dates && pristine) {
          const startDate = defaultSearch.dates.split(',')[0];
          const startMonth = getMonthStartInTimeZone(startDate);
          const endMonth = moment(startMonth).endOf('month').toDate();
          const searchDates = {
            start: startMonth,
            end: endMonth,
          };
          values.month = searchDates;
        }
        const searchFormDate = values?.month?.start;

        const { seats, month = {}, numberOfMonths } = values;
        const filterSlots = seats ? timeSlots.filter((slot) => slot.attributes.seats >= seats) : [];
        const { start: startDate, end: endDate } = month;
        const bookingData =
          startDate && endDate && !invalid
            ? {
                startDate,
                endDate: moment(endDate)
                  .add(numberOfMonths - 1, 'month')
                  .toDate(),
              }
            : null;
        const showEstimatedBreakdown =
          bookingData && lineItems && !fetchLineItemsInProgress && !fetchLineItemsError;
        const bookingInfoMaybe = showEstimatedBreakdown ? (
          <div className={css.priceBreakdownContainer}>
            <EstimatedBreakdownMaybe
              bookingData={bookingData}
              lineItems={lineItems}
              bookingType={MONTHLY_BOOKING}
              listing={listing}
            />
          </div>
        ) : null;

        const loadingSpinnerMaybe = fetchLineItemsInProgress ? (
          <IconSpinner className={css.spinner} />
        ) : null;

        const bookingInfoErrorMaybe = fetchLineItemsError ? (
          <span className={css.sideBarError}>
            <FormattedMessage id="BookingDatesForm.fetchLineItemsError" />
          </span>
        ) : null;

        const classes = classNames(rootClassName || css.root, className);
        const submitButtonClasses = classNames(
          submitButtonWrapperClassName || css.submitButtonWrapper
        );

        const seatsLabel = intl.formatMessage({
          id: 'BookingDatesForm.seatsLabel',
        });

        const handleSeatsChange = () => {
          form.batch(() => form.change('month', {}));
        };

        const handleMonthChange = () => {
          form.batch(() => form.change('numberOfMonths', 1));
        };

        const selectedMonthSlot =
          month && month.start
            ? filterSlots.find(
                (t) =>
                  moment(month.start).startOf('month').valueOf() ===
                  moment(t.attributes.start).startOf('month').valueOf()
              )
            : null;

        const slotsMaybe = selectedMonthSlot
          ? findMaxSlotsCanBeBook(filterSlots, selectedMonthSlot)
          : [];

        const maxNumberOfMonth = slotsMaybe.length || 1;
        const startMonthLabel = intl.formatMessage({
          id: 'BookingDatesForm.startMonthLabel',
        });

        const numberOfMonthLabel = intl.formatMessage({
          id: 'BookingDatesForm.numberOfMonthLabel',
        });

        const minimumNumberOfMonthMessage = intl.formatMessage({
          id: 'BookingDatesForm.minimumNumberOfMonthMessage',
        });

        const maximumNumberOfMonthMessage = intl.formatMessage(
          {
            id: 'BookingDatesForm.maximumNumberOfMonthMessage',
          },
          {
            maxNumberOfMonth,
          }
        );

        const maxNumberOfMonthValidator = maximumValue(
          maximumNumberOfMonthMessage,
          maxNumberOfMonth
        );

        return (
          <Form className={classes} onSubmit={handleSubmit}>
            <FormSpy
              subscription={{ values: true, valid: true }}
              onChange={({ values, valid }) => {
                if (valid) handleOnChange(values);
              }}
            />
            <FieldNumberInput
              name="seats"
              label={seatsLabel}
              id="seats"
              onChange={handleSeatsChange}
              validate={required('this field is required')}
            />
            <div>
              {seats >= 0 && (
                <FieldMonthInput
                  name="month"
                  placeholder={moment().format('MMM YYYY')}
                  label={startMonthLabel}
                  timeSlots={filterSlots}
                  onChange={handleMonthChange}
                  validate={required('this field is required')}
                  searchFormDate={searchFormDate}
                />
              )}
              {month && month.start && (
                <FieldNumberInput
                  name="numberOfMonths"
                  label={numberOfMonthLabel}
                  id="numberOfMonths"
                  autoComplete="off"
                  min={1}
                  max={maxNumberOfMonth}
                  validate={composeValidators(
                    required(' '),
                    minimumValue(minimumNumberOfMonthMessage, 1),
                    maxNumberOfMonthValidator
                  )}
                />
              )}
              <div className={css.desktopButton}>
                <ButtonNew
                  disabled={invalid}
                  type={BUTTON_TYPES.GREEN}
                  size={BUTTON_SIZES.LARGE}
                  block
                >
                  <FormattedMessage id="BookingDatesForm.proceed" />
                </ButtonNew>
              </div>
              <p className={css.smallPrint}>
                <FormattedMessage
                  id={
                    isOwnListing
                      ? 'BookingDatesForm.ownListing'
                      : 'BookingDatesForm.youWontBeChargedInfo'
                  }
                />
              </p>
              {additionalServices && additionalServices.length > 0 && additionalServicesSection()}
            </div>
            {bookingInfoMaybe}
            {loadingSpinnerMaybe}
            {bookingInfoErrorMaybe}
            <div className={css.openBookingFormWrapper}>
              <p className={`${css.smallPrint}, ${css.smallPrintMobile}`}>
                <FormattedMessage
                  id={
                    isOwnListing
                      ? 'BookingDatesForm.ownListing'
                      : 'BookingDatesForm.youWontBeChargedInfo'
                  }
                />
              </p>

              <div className={css.openBookingForm}>
                <div className={css.backButton} onClick={onBackPress}>
                  <svg
                    width="16"
                    height="13"
                    viewBox="0 0 16 13"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M16 6.5L0.999999 6.5M0.999999 6.5L6.45454 12M0.999999 6.5L6.45455 1"
                      stroke="#222222"
                    />
                  </svg>{' '}
                  <FormattedMessage id="BookingPanel.back" />
                </div>
                <div className={submitButtonClasses}>
                  <ButtonNew
                    disabled={invalid}
                    type={BUTTON_TYPES.GREEN}
                    size={BUTTON_SIZES.LARGE}
                    block
                  >
                    <FormattedMessage id="BookingDatesForm.proceed" />
                  </ButtonNew>
                </div>
              </div>
            </div>
          </Form>
        );
      }}
    />
  );
};

export default BookingMonthForm;

const findMaxSlotsCanBeBook = (timeSlots = [], selectedSlot) => {
  const slots = [selectedSlot];
  for (let index = 1; ; index++) {
    let nextStart = moment(selectedSlot.attributes.start).add(index, 'month').startOf('month');
    const slot = timeSlots.find(
      (s) => moment(s.attributes.start).startOf('month').valueOf() === nextStart.valueOf()
    );
    if (slot) {
      slots.push(slot);
      continue;
    } else {
      break;
    }
  }

  return slots;
};
