import React, { useEffect } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'redux';
import classNames from 'classnames';
import { Page, TabNav } from '../../components';
import { TopbarContainer } from '..';
import { MAX_MOBILE_SCREEN_WIDTH } from '../../util/device';

import {
  MY_SUBSCRIPTION,
  TEAM_ACCESS,
  USAGE_LOG,
  PAYMENT_HISTORY,
  SETTINGS,
  SUBSCRIPTION_OPTIONS,
  SUBSCRIPTION_STATUS_ACTIVE,
} from '../../util/types';
import css from './SubscriptionManagementPage.module.scss';
import { withViewport } from '../../util/contextHelpers';
import { withRouter } from 'react-router';
import TeamAccessTab from './Tabs/TeamAccessTab';
import PaymentHistoryTab from './Tabs/PaymentHistoryTab';
import { useModal } from './hooks';
import {
  addTeamMemberList,
  companyLogoUploadImage,
  deleteMember,
  editMemberAccess,
  updateCompanyName,
  downloadInvoicePDF,
} from './SubscriptionManagementPage.duck';
import get from 'lodash/get';
import routeConfiguration from '../../routeConfiguration';
import { createResourceLocatorString } from '../../util/routes';
import UsageLogTab from './UsageLogTab';
import SettingsTab from './SettingsTab';
import { getMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import MySubscriptionTab from './MySubscriptionTab/MySubscriptionTab';
import { useSelector } from 'react-redux';
import { isScrollingDisabled } from '../../ducks/UI.duck';

const SubscriptionManagementPageComponent = (props) => {
  const {
    intl,
    viewport,
    params,
    location,
    history,
    currentUser,
    companyAccount,
    earliestAllowableCancelDate,
    selectedCreditAmount,
    hasChargebeePaymentSource,

    // Props for Team Access Tab
    members,
    fetchTeamAccessInProgress,
    onAddTeamMemberList,
    addTeamMembersInProgress,
    onDeleteMember,
    onEditMemberAccess,
    deleteMemberInProgress,
    deleteMemberError,
    editMemberAccessInProgress,
    editMemberAccessError,

    // Props for Usage Log Tab
    fetchUsageLogInProgress,
    totalCredits,
    usedCredits,
    usageLogBooking,
    teamMembersNumber,
    previousUnusedCredits,
    nextTotalCredits,

    // Props for Payment History Tab
    paymentHistory,
    fetchPaymentHistoryInProgress,
    onDownloadInvoicePDF,
    getPDFInvoiceInProgress,

    // Props for Settings Tab
    onCompanyLogoUploadImage,
    uploadCompanyLogoInProgress,
    uploadCompanyLogoError,
    logoImageUrl,
    onUpdateCompanyName,
    updateCompanyNameInProgress,
  } = props;
  const {
    modalOpen: contactModalOpen,
    openModal: openContactModal,
    closeModal: closeContactModal,
  } = useModal();

  const { isCompanyAccount, selectedPackage } = get(
    currentUser,
    'attributes.profile.protectedData',
    {}
  );
  const subscriptionStatus = currentUser?.attributes.profile.metadata.subscriptionStatus;
  const isMonthlySubscription =
    (subscriptionStatus && subscriptionStatus === SUBSCRIPTION_STATUS_ACTIVE) ||
    (!subscriptionStatus && selectedPackage && SUBSCRIPTION_OPTIONS.includes(selectedPackage));

  const scrollingDisabled = useSelector((state) => isScrollingDisabled(state));

  const freeTrialStartTimestamp = get(
    companyAccount,
    'attributes.profile.metadata.freeTrialStartTimestamp',
    null
  );

  const todayInMilliseconds = Date.now();
  const differenceInDays = freeTrialStartTimestamp
    ? Math.ceil((todayInMilliseconds - freeTrialStartTimestamp) / (1000 * 60 * 60 * 24))
    : null;
  const freeTrialExpired = differenceInDays > 30 ? true : false;

  const date = !!freeTrialStartTimestamp ? new Date(freeTrialStartTimestamp) : null;
  date && date.setDate(date.getDate() + 30);
  const expiryDate = !!date ? date.toISOString().slice(0, 10) : null;

  useEffect(() => {
    if (currentUser && !isCompanyAccount) {
      history.push(createResourceLocatorString('NotFoundPage', routeConfiguration()));
    }
  }, [currentUser, history, isCompanyAccount]);

  const isMobileLayout = viewport.width < MAX_MOBILE_SCREEN_WIDTH;
  const { tab } = params;
  let tabs = [
    {
      text: (
        <span>
          {intl.formatMessage({
            id: isMonthlySubscription
              ? 'SubscriptionManagementPage.mySubscription'
              : 'SubscriptionManagementPage.manageCredits',
          })}
        </span>
      ),
      selected: tab === MY_SUBSCRIPTION,
      linkProps: {
        name: 'SubscriptionManagementPage',
        params: { tab: MY_SUBSCRIPTION },
      },
    },
    {
      text: <span>{intl.formatMessage({ id: 'SubscriptionManagementPage.teamAccess' })}</span>,
      selected: tab === TEAM_ACCESS,
      linkProps: {
        name: 'SubscriptionManagementPage',
        params: { tab: TEAM_ACCESS },
      },
    },
    {
      text: <span>{intl.formatMessage({ id: 'SubscriptionManagementPage.usageLog' })}</span>,
      selected: tab === USAGE_LOG,
      linkProps: {
        name: 'SubscriptionManagementPage',
        params: { tab: USAGE_LOG },
      },
    },
    {
      text: <span>{intl.formatMessage({ id: 'SubscriptionManagementPage.paymentHistory' })}</span>,
      selected: tab === PAYMENT_HISTORY,
      linkProps: {
        name: 'SubscriptionManagementPage',
        params: { tab: PAYMENT_HISTORY },
      },
    },
    {
      text: <span>{intl.formatMessage({ id: 'SubscriptionManagementPage.settings' })}</span>,
      selected: tab === SETTINGS,
      linkProps: {
        name: 'SubscriptionManagementPage',
        params: { tab: SETTINGS },
      },
    },
  ];

  const schemaTitle = intl.formatMessage({ id: 'SubscriptionManagementPage.schemaTitle' });
  return (
    <Page title={schemaTitle} className={css.page} scrollingDisabled={scrollingDisabled}>
      <TopbarContainer
        currentPage="SubscriptionManagementPage"
        className={classNames({ [css.topBarContainer]: isMobileLayout })}
      />
      <div className={css.pageContainer}>
        <TabNav
          rootClassName={css.tabs}
          tabRootClassName={css.tab}
          tabs={tabs}
          isSubscriptionManagement={!isMobileLayout}
        >
          <>
            {!isMobileLayout && logoImageUrl ? (
              <div className={css.companyLogo}>
                <img src={logoImageUrl} alt="" />
              </div>
            ) : null}
          </>
        </TabNav>
        {tab === MY_SUBSCRIPTION && (
          <MySubscriptionTab
            intl={intl}
            isMobileLayout={isMobileLayout}
            selectedPackage={selectedPackage}
            earliestAllowableCancelDate={earliestAllowableCancelDate}
            isMonthlySubscription={isMonthlySubscription}
            selectedCreditAmount={selectedCreditAmount}
            usedCredits={usedCredits}
            totalCredits={totalCredits}
            previousUnusedCredits={previousUnusedCredits}
            expiryDate={expiryDate}
            freeTrialExpired={freeTrialExpired}
            hasChargebeePaymentSource={hasChargebeePaymentSource}
          />
        )}
        {tab === TEAM_ACCESS && (
          <TeamAccessTab
            intl={intl}
            members={members}
            fetchInProgress={fetchTeamAccessInProgress}
            isMobileLayout={isMobileLayout}
            params={params}
            contactModalOpen={contactModalOpen}
            openContactModal={openContactModal}
            closeContactModal={closeContactModal}
            location={location}
            onAddTeamMemberList={onAddTeamMemberList}
            addTeamMembersInProgress={addTeamMembersInProgress}
            onDeleteMember={onDeleteMember}
            onEditMemberAccess={onEditMemberAccess}
            deleteMemberInProgress={deleteMemberInProgress}
            deleteMemberError={deleteMemberError}
            editMemberAccessInProgress={editMemberAccessInProgress}
            editMemberAccessError={editMemberAccessError}
            currentUser={currentUser}
          />
        )}
        {tab === USAGE_LOG && (
          <UsageLogTab
            intl={intl}
            isMobileLayout={isMobileLayout}
            fetchInProgress={fetchUsageLogInProgress}
            totalCredits={totalCredits}
            usedCredits={usedCredits}
            usageLogBooking={usageLogBooking}
            teamMembersNumber={teamMembersNumber}
            params={params}
            companyAccount={companyAccount}
            previousUnusedCredits={previousUnusedCredits}
            nextTotalCredits={nextTotalCredits}
            members={members}
            isMonthlySubscription={isMonthlySubscription}
            expiryDate={expiryDate}
          />
        )}
        {tab === PAYMENT_HISTORY && (
          <PaymentHistoryTab
            intl={intl}
            isMobileLayout={isMobileLayout}
            params={params}
            location={location}
            currentUser={currentUser}
            paymentHistory={paymentHistory}
            fetchInProgress={fetchPaymentHistoryInProgress}
            onDownloadInvoicePDF={onDownloadInvoicePDF}
            getPDFInvoiceInProgress={getPDFInvoiceInProgress}
          />
        )}
        {tab === SETTINGS && (
          <SettingsTab
            intl={intl}
            currentUser={currentUser}
            onCompanyLogoUploadImage={onCompanyLogoUploadImage}
            uploadCompanyLogoInProgress={uploadCompanyLogoInProgress}
            uploadCompanyLogoError={uploadCompanyLogoError}
            onUpdateCompanyName={onUpdateCompanyName}
            updateCompanyNameInProgress={updateCompanyNameInProgress}
            logoImageUrl={logoImageUrl}
          />
        )}
      </div>
    </Page>
  );
};

const mapStateToProps = (state) => {
  const {
    members,
    fetchTeamAccessInProgress,
    addTeamMembersInProgress,
    deleteMemberInProgress,
    deleteMemberError,
    editMemberAccessInProgress,
    editMemberAccessError,
    fetchUsageLogInProgress,
    totalCredits,
    usedCredits,
    usageLogBookingRefs,
    teamMembersNumber,
    uploadCompanyLogoInProgress,
    uploadCompanyLogoError,
    logoImageUrl,
    updateCompanyNameInProgress,
    paymentHistory,
    fetchPaymentHistoryInProgress,
    getPDFInvoiceInProgress,
    previousUnusedCredits,
    nextTotalCredits,
    earliestAllowableCancelDate,
    hasChargebeePaymentSource,
  } = state.SubscriptionManagementPage;
  const usageLogBooking = getMarketplaceEntities(state, usageLogBookingRefs);

  const { currentUser, companyAccount } = state.user;
  const { totalCredits: selectedCreditAmount } = state.b2b;

  return {
    members,
    fetchTeamAccessInProgress,
    addTeamMembersInProgress,
    deleteMemberInProgress,
    deleteMemberError,
    editMemberAccessInProgress,
    editMemberAccessError,
    currentUser,
    fetchUsageLogInProgress,
    totalCredits,
    usedCredits,
    usageLogBooking,
    teamMembersNumber,
    companyAccount,
    uploadCompanyLogoInProgress,
    uploadCompanyLogoError,
    logoImageUrl,
    updateCompanyNameInProgress,
    paymentHistory,
    fetchPaymentHistoryInProgress,
    getPDFInvoiceInProgress,
    previousUnusedCredits,
    nextTotalCredits,
    earliestAllowableCancelDate,
    selectedCreditAmount,
    hasChargebeePaymentSource,
  };
};

const mapDispatchToProps = (dispatch) => ({
  onAddTeamMemberList: (emailList, credit, locale) =>
    dispatch(addTeamMemberList(emailList, credit, locale)),
  onCompanyLogoUploadImage: (data) => dispatch(companyLogoUploadImage(data)),
  onUpdateCompanyName: ({ companyName }) => dispatch(updateCompanyName({ companyName })),
  onDeleteMember: ({ memberId, memberEmail }) => dispatch(deleteMember({ memberId, memberEmail })),
  onEditMemberAccess: ({ memberId, memberEmail, creditsValue }) =>
    dispatch(editMemberAccess({ memberId, memberEmail, creditsValue })),
  onDownloadInvoicePDF: (invoiceId) => dispatch(downloadInvoicePDF(invoiceId)),
});

const SubscriptionManagementPage = compose(
  withViewport,
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl
)(SubscriptionManagementPageComponent);

export default SubscriptionManagementPage;
