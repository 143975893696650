import React, { useState } from 'react';
import config from '../../config';
import {
  isInclusivelyAfterDay,
  isInclusivelyBeforeDay,
  DayPickerSingleDateController,
} from 'react-dates';
import moment from 'moment';
import css from './FieldSingleCalendarInput.module.scss';
import { dateIsAfter, getMonthStartInTimeZone, nextMonthFn, prevMonthFn } from '../../util/dates';
import {
  endOfAvailabilityExceptionRange,
  onMonthClick,
  TODAY,
} from '../../forms/EditListingAvailabilityPlanFormNew/EditListingAvailabilityPlanForm.helper';
import {
  EditListingIcons as Icons,
  FieldTextInput,
  IconCheronDown,
  OutsideClickHandler,
} from '../../components';
import classNames from 'classnames';
import { useBoolean } from '../../util/hooks';
import { func, string, any } from 'prop-types';

export const singleCalendarDefaultProps = {
  noBorder: true,
  hideKeyboardShortcutsPanel: true,
  daySize: 36,

  transitionDuration: 200, // milliseconds between next month changes etc.
  isOutsideRange: (day) => {
    const endOfRange = config.dayCountAvailableForBooking - 1;
    return (
      !isInclusivelyAfterDay(day, moment()) ||
      !isInclusivelyBeforeDay(day, moment().add(endOfRange, 'days'))
    );
  },
  weekDayFormat: 'ddd',
};

// Components for the react-dates calendar
const Next = (props) => {
  const { currentMonth, timeZone } = props;
  const nextMonthDate = nextMonthFn(currentMonth, timeZone);

  return dateIsAfter(nextMonthDate, endOfAvailabilityExceptionRange(timeZone, TODAY)) ? null : (
    <Icons.CheronRightIcon className={css.cheronRight} />
  );
};
const Prev = (props) => {
  const { currentMonth, timeZone } = props;
  const prevMonthDate = prevMonthFn(currentMonth, timeZone);
  const currentMonthDate = getMonthStartInTimeZone(TODAY, timeZone);

  return dateIsAfter(prevMonthDate, currentMonthDate) ? (
    <Icons.CheronRightIcon className={css.cheronLeft} />
  ) : (
    <Icons.CheronRightIcon className={classNames(css.cheronLeft, css.disable)} />
  );
};

const FieldSingleCalendarInput = (props) => {
  const {
    id,
    name,
    className,
    inputClassname,
    date,
    setDate,
    timeZone,
    onMonthChanged,
    values,
    form,
    initialDate,
    validate,
    ...datePickerProps
  } = props;
  const [currentMonth, setCurrentMonth] = useState(getMonthStartInTimeZone(TODAY, timeZone));
  const [showCalendar, setShowCalendar] = useBoolean();

  const calendarWrapperClasses = classNames(css.calendarWrapper, className);
  const inputClasses = classNames(css.inputField, inputClassname);

  const handleMonthClick = onMonthClick(currentMonth, setCurrentMonth, timeZone, onMonthChanged);

  const initialMoment = initialDate ? moment(initialDate) : null;
  const calendarDate =
    values && values.date instanceof Date && values.date.toString() !== 'Invalid Date'
      ? moment(values.date)
      : initialMoment;

  const onDateChange = (date) => {
    setDate(date);
    form.change(name, date.format('DD-MM-YYYY'));
  };
  const onInputClick = () => {
    setShowCalendar.on();
  };
  const handleOutsideClick = () => {
    setShowCalendar.off();
  };
  return (
    <>
      <div className={css.iconFieldInput} onClick={onInputClick}>
        <FieldTextInput
          id={id}
          name={name}
          type="text"
          className={inputClasses}
          disabled
          validate={validate}
        />
        <IconCheronDown className={css.icon} />
      </div>

      {showCalendar && (
        <OutsideClickHandler onOutsideClick={handleOutsideClick}>
          <div className={calendarWrapperClasses}>
            <DayPickerSingleDateController
              {...datePickerProps}
              onDateChange={onDateChange}
              navNext={<Next currentMonth={currentMonth} timeZone={timeZone} />}
              navPrev={<Prev currentMonth={currentMonth} timeZone={timeZone} />}
              onPrevMonthClick={() => handleMonthClick(prevMonthFn)}
              onNextMonthClick={() => handleMonthClick(nextMonthFn)}
              date={calendarDate}
              isDayHighlighted={(d1) => d1.isSame(date, 'day')}
            />
          </div>
        </OutsideClickHandler>
      )}
    </>
  );
};

FieldSingleCalendarInput.defaultProps = {
  ...singleCalendarDefaultProps,
};

FieldSingleCalendarInput.propTypes = {
  id: string.isRequired,
  name: string.isRequired,
  classNames: string,
  inputClasses: string,
  date: any,
  setDate: func,
  timeZone: any,
  onMonthChanged: func,
  values: any,
  form: any,
  initialDate: any,
  validate: any,
};
export default FieldSingleCalendarInput;
