import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';

import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { propTypes } from '../../util/types';
import { isScrollingDisabled } from '../../ducks/UI.duck';
import {
  ManageListingCard,
  Page,
  PaginationLinks,
  UserNav,
  LayoutSingleColumn,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  Footer,
  CreateListingIntroductionModal,
} from '../../components';
import { TopbarContainer } from '../../containers';
import { isEmployeeUser, isCompanyUser } from '../../util/b2bHelper';

import { closeListing, openListing, getOwnListingsById } from './ManageListingsPage.duck';
import { createListingURL } from '../../components/ManageListingCard/ManageListingCard';
import { routeConfiguration } from '../../index';

import css from './ManageListingsPage.module.scss';
import { useBoolean } from '../../util/hooks';

const sharetribeSdk = require('sharetribe-flex-sdk');
const SHARETRIBE_SDK_CLIENT_ID = process.env.REACT_APP_SHARETRIBE_SDK_CLIENT_ID;

const sdk = sharetribeSdk.createInstance({
  clientId: SHARETRIBE_SDK_CLIENT_ID,
});

// Pagination page size might need to be dynamic on responsive page layouts
// Current design has max 3 columns 42 is divisible by 2 and 3
// So, there's enough cards to fill all columns on full pagination pages
const RESULT_PAGE_SIZE = 42;

const ManageListingsPageComponent = ({
  closingListing,
  closingListingError,
  currentUser,
  currentUserHasListings,
  listings,
  onCloseListing,
  onOpenListing,
  openingListing,
  openingListingError,
  pagination,
  queryInProgress,
  queryListingsError,
  queryParams,
  scrollingDisabled,
  intl,
}) => {
  const isB2B = isEmployeeUser(currentUser) || isCompanyUser(currentUser);
  const [isIntroduceModalOpen, setIntroduceModalOpen] = useBoolean();

  const hasPaginationInfo = !!pagination && pagination.totalItems != null;
  const listingsAreLoaded = !queryInProgress && hasPaginationInfo;

  const loadingResults = (
    <h2>
      <FormattedMessage id="ManageListingsPage.loadingOwnListings" />
    </h2>
  );

  const queryError = (
    <h2 className={css.error}>
      <FormattedMessage id="ManageListingsPage.queryError" />
    </h2>
  );

  const noResults =
    listingsAreLoaded && pagination.totalItems === 0 ? (
      <h1 className={css.title}>
        <FormattedMessage id="ManageListingsPage.noResults" />
      </h1>
    ) : null;

  const heading =
    listingsAreLoaded && pagination.totalItems > 0 ? (
      <h1 className={css.title}>
        <FormattedMessage
          id="ManageListingsPage.youHaveListings"
          values={{ count: pagination.totalItems }}
        />
      </h1>
    ) : (
      noResults
    );

  const page = queryParams ? queryParams.page : 1;
  const paginationLinks =
    listingsAreLoaded && pagination && pagination.totalPages > 1 ? (
      <PaginationLinks
        className={css.pagination}
        pageName="ManageListingsPage"
        pageSearchParams={{ page }}
        pagination={pagination}
      />
    ) : null;

  const closingErrorListingId = !!closingListingError && closingListingError.listingId;
  const openingErrorListingId = !!openingListingError && openingListingError.listingId;

  const title = intl.formatMessage({ id: 'ManageListingsPage.title' });

  const panelWidth = 62.5;
  // Render hints for responsive image
  const renderSizes = [
    `(max-width: 767px) 100vw`,
    `(max-width: 1920px) ${panelWidth / 2}vw`,
    `${panelWidth / 3}vw`,
  ].join(', ');

  const handleDuplicateListing = async (listing) => {
    const {
      attributes: { title, description, availabilityPlan, geolocation, price, publicData },
    } = listing;

    const payload = {
      title: `Copy of ${title}`,
      description,
      publicData,
    };

    const queryParams = {
      expand: true,
      include: ['author', 'images'],
      'fields.image': ['variants.landscape-crop', 'variants.landscape-crop2x'],
    };

    const response = await sdk.ownListings.createDraft(payload, queryParams);

    const { data: savedDraft } = response.data;

    const dataForCreatingListing = {
      id: savedDraft.id,
      attributes: {
        title: savedDraft.attributes.title,
        state: savedDraft.attributes.state,
      },
    };

    if (response.status === 200) {
      const updateResponse = await sdk.ownListings.update({
        id: response.data.data.id.uuid,
        availabilityPlan,
        geolocation,
        price,
      });

      const listingURL = createListingURL(routeConfiguration(), dataForCreatingListing);

      if (updateResponse.status === 200) {
        window.location = `${listingURL}/description`;
      }
    }
  };

  return (
    <Page title={title} scrollingDisabled={scrollingDisabled}>
      <LayoutSingleColumn>
        <LayoutWrapperTopbar>
          <TopbarContainer currentPage="ManageListingsPage" />
          <UserNav
            selectedPageName="ManageListingsPage"
            hasListings={currentUserHasListings}
            isB2B={isB2B}
          />
        </LayoutWrapperTopbar>
        <LayoutWrapperMain>
          {queryInProgress ? loadingResults : null}
          {queryListingsError ? queryError : null}
          <div className={css.listingPanel}>
            {heading}
            <h3 className={css.addSpace} onClick={setIntroduceModalOpen.on}>
              <FormattedMessage id="TopbarDesktop.createListing" />
            </h3>
            <div className={css.listingCards}>
              {listings.map((l) => (
                <ManageListingCard
                  className={css.listingCard}
                  key={l.id.uuid}
                  listing={l}
                  actionsInProgressListingId={openingListing || closingListing}
                  onCloseListing={onCloseListing}
                  onOpenListing={onOpenListing}
                  onDuplicateListing={handleDuplicateListing}
                  hasOpeningError={openingErrorListingId.uuid === l.id.uuid}
                  hasClosingError={closingErrorListingId.uuid === l.id.uuid}
                  renderSizes={renderSizes}
                />
              ))}
            </div>
            {paginationLinks}
            <CreateListingIntroductionModal
              isOpen={isIntroduceModalOpen}
              onClose={setIntroduceModalOpen.off}
            />
          </div>
        </LayoutWrapperMain>
        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </LayoutSingleColumn>
    </Page>
  );
};

ManageListingsPageComponent.defaultProps = {
  listings: [],
  pagination: null,
  queryListingsError: null,
  queryParams: null,
  closingListing: null,
  closingListingError: null,
  openingListing: null,
  openingListingError: null,
};

const { arrayOf, bool, func, object, shape, string } = PropTypes;

ManageListingsPageComponent.propTypes = {
  closingListing: shape({ uuid: string.isRequired }),
  closingListingError: shape({
    listingId: propTypes.uuid.isRequired,
    error: propTypes.error.isRequired,
  }),
  listings: arrayOf(propTypes.ownListing),
  onCloseListing: func.isRequired,
  onOpenListing: func.isRequired,
  openingListing: shape({ uuid: string.isRequired }),
  openingListingError: shape({
    listingId: propTypes.uuid.isRequired,
    error: propTypes.error.isRequired,
  }),
  pagination: propTypes.pagination,
  queryInProgress: bool.isRequired,
  queryListingsError: propTypes.error,
  queryParams: object,
  scrollingDisabled: bool.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = (state) => {
  const { currentUser, currentUserHasListings } = state.user;
  const {
    currentPageResultIds,
    pagination,
    queryInProgress,
    queryListingsError,
    queryParams,
    openingListing,
    openingListingError,
    closingListing,
    closingListingError,
  } = state.ManageListingsPage;
  const listings = getOwnListingsById(state, currentPageResultIds);
  return {
    currentPageResultIds,
    currentUser,
    currentUserHasListings,
    listings,
    pagination,
    queryInProgress,
    queryListingsError,
    queryParams,
    scrollingDisabled: isScrollingDisabled(state),
    openingListing,
    openingListingError,
    closingListing,
    closingListingError,
  };
};

const mapDispatchToProps = (dispatch) => ({
  onCloseListing: (listingId) => dispatch(closeListing(listingId)),
  onOpenListing: (listingId) => dispatch(openListing(listingId)),
});

const ManageListingsPage = compose(
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl
)(ManageListingsPageComponent);

export default ManageListingsPage;
