export const CURRENT_LOCATION_ID = 'current-location';

export const GENERATED_BOUNDS_DEFAULT_DISTANCE = 500;

export const PLACE_TYPE_BOUNDS_DISTANCES = {
  address: 500,
  country: 2000,
  region: 2000,
  postcode: 2000,
  district: 2000,
  place: 2000,
  locality: 2000,
  neighborhood: 2000,
  poi: 2000,
  'poi.landmark': 2000,
};

export const GEOCODER_TYPES = {
  GOOGLE: 'google',
  MAPBOX: 'mapbox',
};

GEOCODER_TYPES.LIST = [GEOCODER_TYPES.GOOGLE, GEOCODER_TYPES.MAPBOX];
