import React from 'react';
import { Modal } from '..';
import { EditMemberAccessForm } from '../../forms';
import { CREDITS_TYPE_LIMITED, CREDITS_TYPE_UNLIMITED } from '../../util/types';
import css from './EditTeamMemberAccessModal.module.scss';

const EditTeamMemberAccessModal = (props) => {
  const {
    intl,
    memberId,
    memberEmail,
    isOpen,
    onClose,
    onEditMemberAccess,
    inProgress,
    error,
    credits,
  } = props;

  const initialFormValues = {
    editMemberAccessType: credits > 0 ? CREDITS_TYPE_LIMITED : CREDITS_TYPE_UNLIMITED,
    creditsValue: credits > 0 ? credits : 0,
  };
  const handleSubmit = async (values) => {
    const { creditsValue, editMemberAccessType } = values;
    const finalCreditsValue = editMemberAccessType === CREDITS_TYPE_LIMITED ? creditsValue : -1;
    await onEditMemberAccess({ memberId, memberEmail, creditsValue: finalCreditsValue });
    await onClose();
  };
  return (
    <Modal
      id={`EditTeamMemberAccessModal-${memberId}`}
      isOpen={isOpen}
      onClose={onClose}
      className={css.modal}
      containerClassName={css.modalContainer}
      scrollLayerClassName={css.scrollLayer}
      hideCloseButton
    >
      <div className={css.container}>
        <div className={css.title}>
          {intl.formatMessage({ id: 'EditTeamMemberAccessModal.title' })}
        </div>
        <div className={css.subTitle}>
          {intl.formatMessage({ id: 'EditTeamMemberAccessModal.subTitle' })}
        </div>
        {error && (
          <div className={css.error}>
            {intl.formatMessage({ id: 'EditTeamMemberAccessModal.error' })}
          </div>
        )}
        <EditMemberAccessForm
          intl={intl}
          inProgress={inProgress}
          onClose={onClose}
          onSubmit={handleSubmit}
          initialValues={initialFormValues}
        />
      </div>
    </Modal>
  );
};

EditTeamMemberAccessModal.defaultProps = {
  error: null,
  inProgress: false,
  isOpen: false,
  memberId: null,
};

export default EditTeamMemberAccessModal;
