import React from 'react';
import { Form as FinalForm } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import { Button, FieldNumberFixedPlaceholder, FieldRadioButton, Form } from '../../components';
import css from './EditMemberAccessForm.module.scss';
import { CREDITS_TYPE_LIMITED, CREDITS_TYPE_UNLIMITED } from '../../util/types';

const EditMemberAccessForm = (props) => {
  return (
    <FinalForm
      {...props}
      mutators={{
        ...arrayMutators,
      }}
      render={(fieldRenderProps) => {
        const { handleSubmit, onClose, inProgress, values, intl } = fieldRenderProps;
        const isCustomType = values.editMemberAccessType === CREDITS_TYPE_LIMITED;
        return (
          <Form className={css.formContainer} onSubmit={handleSubmit}>
            <div className={css.radioButtonsWrapper}>
              <FieldRadioButton
                id="editMemberAccessType-customize"
                name="editMemberAccessType"
                className={css.radioButton}
                label={intl.formatMessage({ id: 'EditMemberAccessForm.customizeLabel' })}
                value={CREDITS_TYPE_LIMITED}
              />
              <FieldRadioButton
                id="editMemberAccessType-unlimited"
                name="editMemberAccessType"
                className={css.radioButton}
                label={intl.formatMessage({ id: 'EditMemberAccessForm.unlimitedLabel' })}
                value={CREDITS_TYPE_UNLIMITED}
              />
            </div>
            {isCustomType && (
              <FieldNumberFixedPlaceholder
                id="creditsValue"
                name="creditsValue"
                inputPlaceholder="1"
                min={0}
                max={1000}
                inputWrapperClassname={css.rootInput}
                className={css.rootField}
                isButtonInput
                canModify
                readOnly={false}
              />
            )}
            <div className={css.buttonsWrapper}>
              <Button type="button" onClick={onClose} className={css.cancelButton}>
                {intl.formatMessage({ id: 'EditMemberAccessForm.cancelButton' })}
              </Button>
              <Button
                type="submit"
                className={css.editButton}
                disabled={inProgress}
                inProgress={inProgress}
              >
                {intl.formatMessage({ id: 'EditMemberAccessForm.saveButton' })}
              </Button>
            </div>
          </Form>
        );
      }}
    />
  );
};

export default EditMemberAccessForm;
