import React from 'react';
import classNames from 'classnames';

import { ButtonNew } from '../../components_new';

import css from './contentWithBtnLink.module.scss';

const ContentWithBtnLink = ({ header, description, btnText, linkPage, bgDark }) => {
  const wrapperClassList = classNames(css.wrapper, {
    [css.darkBg]: bgDark,
  });
  return (
    <div className={wrapperClassList}>
      <div className={css.header}>{header}</div>
      <div className={css.text}>{description}</div>
      <div className={css.linkWrapper}>
        <ButtonNew name={linkPage}>{btnText}</ButtonNew>
      </div>
    </div>
  );
};

export default ContentWithBtnLink;
