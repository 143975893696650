import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { NamedLink } from '../../components';

import css from './TabNav.module.scss';

const Tab = (props) => {
  const {
    className,
    id,
    disabled,
    text,
    selected,
    linkProps,
    linkClassName,
    linkSelectedClassName,
    lastTab,
  } = props;
  const linkClasses = classNames(
    css.link,
    {
      [css.selectedLink]: selected,
      [css.disabled]: disabled,
    },
    linkClassName,
    {
      [linkSelectedClassName]: selected,
    },
    {
      [css.lastTab]: lastTab,
    }
  );

  return (
    <div id={id} className={className}>
      <NamedLink className={linkClasses} {...linkProps}>
        {text}
      </NamedLink>
    </div>
  );
};

Tab.defaultProps = { className: null, disabled: false, selected: false };

const { arrayOf, bool, node, object, string } = PropTypes;

Tab.propTypes = {
  id: string.isRequired,
  className: string,
  text: node.isRequired,
  disabled: bool,
  selected: bool,
  linkProps: object.isRequired,
};

export const TabNavWithContainer = (props) => {
  const {
    className,
    rootClassName,
    tabRootClassName,
    tabs,
    isSubscriptionManagement: isSM = false,
    hasListings,
    isB2B,
    selectedLocation,
  } = props;
  const classes = classNames(rootClassName || css.root, className);
  const tabClasses = isSM
    ? css.subscriptionManagementTab
    : tabRootClassName
    ? tabRootClassName
    : css.tab;

  return (
    <nav className={classes}>
      <div className={css.tabsContainer}>
        {!!selectedLocation && Object.keys(selectedLocation).length > 0 && (
          <div className={css.location}>
            <h2>{selectedLocation.name}</h2>
            <p>{selectedLocation.address}</p>
            <img
              className={css.locationImage}
              src={selectedLocation?.images?.length > 0 && selectedLocation?.images[0]?.medium}
              alt="location"
            />
          </div>
        )}
        <div className={css.selections}>
          {tabs.map((tab, index) => {
            const id = typeof tab.id === 'string' ? tab.id : `${index}`;
            if (id === 'StripePayoutPageTab') {
              return (
                hasListings && (
                  <Tab
                    key={id}
                    id={id}
                    className={tabClasses}
                    {...tab}
                    lastTab={index === tabs.length - 1}
                  />
                )
              );
            } else if (id === 'PaymentMethodsPageTab') {
              return (
                !isB2B && (
                  <Tab
                    key={id}
                    id={id}
                    className={tabClasses}
                    {...tab}
                    lastTab={index === tabs.length - 1}
                  />
                )
              );
            } else {
              return (
                <Tab
                  key={id}
                  id={id}
                  className={tabClasses}
                  {...tab}
                  lastTab={index === tabs.length - 1}
                />
              );
            }
          })}
        </div>
      </div>
    </nav>
  );
};

const TabNav = (props) => {
  const {
    className,
    rootClassName,
    tabRootClassName,
    tabs,
    isSubscriptionManagement: isSM = false,
    children,
    hasListings,
    isB2B,
  } = props;
  const classes = classNames(rootClassName || css.root, className);
  const tabClasses = isSM
    ? css.subscriptionManagementTab
    : tabRootClassName
    ? tabRootClassName
    : css.tab;

  return (
    <nav className={classes}>
      {tabs.map((tab, index) => {
        const id = typeof tab.id === 'string' ? tab.id : `${index}`;
        if (id === 'StripePayoutPageTab') {
          return hasListings && <Tab key={id} id={id} className={tabClasses} {...tab} />;
        } else if (id === 'PaymentMethodsPageTab') {
          return !isB2B && <Tab key={id} id={id} className={tabClasses} {...tab} />;
        } else {
          return <Tab key={id} id={id} className={tabClasses} {...tab} />;
        }
      })}
      {children}
    </nav>
  );
};

TabNav.defaultProps = {
  className: null,
  rootClassName: null,
  tabRootClassName: null,
  tabClassName: null,
  selectedLocation: null,
};

TabNav.propTypes = {
  className: string,
  rootClassName: string,
  tabRootClassName: string,
  tabs: arrayOf(object).isRequired,
  selectedLocation: object,
};

export default TabNav;
