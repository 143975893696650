import React, { useState, useEffect } from 'react';
import moment from 'moment';
import classNames from 'classnames';
import { func, string } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { withRouter } from 'react-router-dom';
import SwiperCore, { Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import { NamedLink } from '../../components';
import { InviteModal } from '..';
import { inBrowser, isMobile } from '../../util/device';
import { requestMoreWeeks } from '../../util/api';

import { Day, Sidebar, WeeksSlider, TableLoader, TableLoaderMobile } from './ui';
import left from './ui/icons/left.svg';
import right from './ui/icons/right.svg';

import { mapDispatchToProps, mapStateToProps } from './model/store';
import { FULL_DATE_WITH_MONTH_NAME, WEEK_NUMBER } from './config/constants';

import SettingsIcon from './ui/icons/SettingsIcon';
import AddNewUserIcon from './ui/icons/AddNewUserIcon';

import css from './SectionTeamScheduling.module.scss';

SwiperCore.use([Navigation]);

const getPlaceholderDates = () => {
  const currentWeekMonday = moment().startOf('week').add(1, 'days');

  const [mondayDate, currentMonth] = currentWeekMonday.format('DD MMMM').split(' ');
  const fridayDate = currentWeekMonday.add(4, 'days').format('DD');

  return {
    monday: {
      month: currentMonth,
      date: mondayDate,
    },
    friday: {
      month: currentMonth,
      date: fridayDate,
    },
  };
};

const SectionTeamSchedulingComponent = ({
  teamSchedule,
  getTeamSchedule,
  updateTeamSchedule,
  updateCurrentBookingId,
  isCompanyAccount,
  companyAccountId,
  currentUserId,
  currentBookingId,
  teamScheduleLoading,
  intl,
  history,
}) => {
  const [isSidebarOpen, setSidebarOpen] = useState(false);
  const [showJoinSpaceButton, setShowJoinSpaceButton] = useState(false);
  const [isBottomSheetOpen, setBottomSheetOpen] = useState(false);
  const [bottomSheetData, setBottomSheetData] = useState({});
  const [sidebarBookings, setSidebarBookings] = useState([]);
  const [sidebarDate, setSidebarDate] = useState(null);
  const [weeks, setWeeks] = useState([]);
  const [currentWeekDates, setCurrentWeekDates] = useState(
    weeks[0]?.weekTitle || getPlaceholderDates()
  );
  const [daysSlider, setDaysSlider] = useState(null);
  const [dayIndex, setDayIndex] = useState(0);
  const [weekIndex, setWeekIndex] = useState(0);
  const [weekSliderData, setWeekSliderData] = useState([]);
  const [scheduleUpdate, setScheduleUpdate] = useState(0);
  const [isInviteModalOpen, setInviteModalOpen] = useState(false);

  useEffect(() => {
    const dataForWeekSlider = weeks.map(({ data }) => {
      return data.map((d) => ({
        date: d.date,
        isFilled: d.bookings.some((booking) => {
          return booking.users.some(
            (b) => b.authorId === currentUserId || b.userId === currentUserId
          );
        }),
      }));
    });
    setWeekSliderData(dataForWeekSlider);
  }, [weeks.length]);

  useEffect(() => {
    /* TODO: For future - double getTeamSchedule call in all cases, because initially both ids are empty */
    if (isCompanyAccount && currentUserId) {
      getTeamSchedule(currentUserId);
    } else if (companyAccountId) {
      getTeamSchedule(companyAccountId);
    } else {
      getTeamSchedule();
    }
  }, [companyAccountId, currentUserId, scheduleUpdate]);

  useEffect(() => {
    setWeeks(teamSchedule);
  }, [teamSchedule]);

  useEffect(() => {
    // Sort the days as they sometimes are in the wrong order
    weeks.forEach((week) => {
      week.data.sort(function (a, b) {
        return new Date(a.date) - new Date(b.date);
      });
    });
  }, [weeks]);

  useEffect(() => {
    if (inBrowser()) {
      isSidebarOpen
        ? document.documentElement.classList.add('blockScroll')
        : document.documentElement.classList.remove('blockScroll');
    }
  }, [isSidebarOpen]);

  const requestMore = () => {
    const lastWeekInArray = weeks[weeks.length - 1]?.week;
    requestMoreWeeks({ lastWeek: lastWeekInArray }).then((res) => {
      const updatedWeeks = [...weeks, ...res];
      updateTeamSchedule(updatedWeeks);
    });
  };

  const slideToWeek = (w) => {
    setWeekIndex(w);
    setCurrentWeekDates(weeks[w].weekTitle);
    setDayIndex(0);
    daysSlider && daysSlider.slideTo(0, 300);

    weeks.length - w === 2 && requestMore();
  };

  const slideToDay = (d) => {
    setDayIndex(d);
    daysSlider && daysSlider.slideTo(d, 300);
  };

  const goToSearchPage = (category) =>
    history.push(
      `/s?address=Sweden&bounds=69.0599269995724%2C24.1933684832876%2C55.280224001785%2C10.8383668128319&category=${category}`
    );

  const todayWeek = +moment(new Date()).format('W');
  const todayWeekIndex = weeks.findIndex((w) => w.week === todayWeek);

  const params = {
    simulateTouch: isMobile,
    spaceBetween: isMobile ? 15 : 0,
    slidesPerView: 1,
    initialSlide: todayWeekIndex,
    onRealIndexChange: isMobile
      ? ({ activeIndex }) => setDayIndex(activeIndex)
      : ({ activeIndex }) => {
          setCurrentWeekDates(weeks[activeIndex].weekTitle);

          weeks.length - activeIndex === 2 && requestMore();
        },
    ...(!isMobile && {
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
    }),
  };

  /**
   * @param weekIndex {Number}
   * @param dayIndex {Number}
   * @param b {Object} */
  const openSidebar = ({ weekIndex, dayIndex, b }) => {
    setSidebarDate(weeks[weekIndex].data?.[dayIndex].date);
    setSidebarBookings(weeks[weekIndex].data?.[dayIndex]?.bookings);
    setSidebarOpen(true);

    !!b.bookingId && updateCurrentBookingId(b.bookingId);

    if (!!b.listingId) {
      setBottomSheetOpen(true);
      onOpenBottomSheet(b);
    }
  };

  const onOpenBottomSheet = (b) => {
    setBottomSheetOpen(true);
    setBottomSheetData(b);
  };

  const onCloseBottomSheet = () => {
    setBottomSheetOpen(false);
    setBottomSheetData({});
  };

  return (
    <section className={classNames(css.section, weeks.length > 0 && css.loaded)}>
      <div className={css.container}>
        {!teamScheduleLoading ? (
          <>
            <div className={css.sliderHeader}>
              <h3 className={css.title}>
                {intl.formatMessage({ id: 'TeamSchedule.currentWeekTitle' })}
                <span>
                  {`${intl.formatMessage({
                    id: `MarketplaceConfig.month.${currentWeekDates?.monday?.month}`,
                  })} ${currentWeekDates?.monday?.date}`}{' '}
                  -{' '}
                  {`${intl.formatMessage({
                    id: `MarketplaceConfig.month.${currentWeekDates?.friday?.month}`,
                  })} ${currentWeekDates?.friday?.date}`}
                </span>
              </h3>
              <div className={css.sliderControls}>
                <NamedLink name="InboxPage" params={{ tab: 'schedule' }}>
                  <button className={css.settingsButton}>
                    <SettingsIcon />
                  </button>
                </NamedLink>
                <button onClick={() => setInviteModalOpen(true)} className={css.openInviteModalBtn}>
                  <AddNewUserIcon />
                </button>
                {!isMobile && (
                  <>
                    <button className={classNames('swiper-button-prev', css.sliderButton)}>
                      <img src={left} alt="" />
                    </button>
                    <button className={classNames('swiper-button-next', css.sliderButton)}>
                      <img src={right} alt="" />
                    </button>
                  </>
                )}
                <InviteModal
                  className={css.inviteModal}
                  isOpen={isInviteModalOpen}
                  onClose={() => setInviteModalOpen(false)}
                />
              </div>
            </div>
            {isMobile ? (
              <>
                <WeeksSlider
                  weeks={weekSliderData}
                  dayIndex={dayIndex}
                  weekIndex={todayWeekIndex}
                  onUpdateDay={slideToDay}
                  onUpdateWeek={slideToWeek}
                />
                <Swiper {...params} className={css.swiperMobile} onSwiper={setDaysSlider}>
                  {weeks[weekIndex]?.data.map((day, dayIndex) => (
                    <SwiperSlide key={dayIndex}>
                      <Day
                        {...day}
                        currentUserId={currentUserId}
                        onOpenSidebar={(b) => openSidebar({ weekIndex, dayIndex, b })}
                        setShowJoinSpaceButton={setShowJoinSpaceButton}
                        scheduleUpdate={scheduleUpdate}
                        setScheduleUpdate={setScheduleUpdate}
                        key={`Day_${dayIndex}`}
                      />
                    </SwiperSlide>
                  ))}
                </Swiper>
              </>
            ) : (
              <Swiper {...params} className={css.swiperDesktop}>
                {weeks.map((week, weekIndex) => (
                  <SwiperSlide className={css.week} key={weekIndex}>
                    {week.data.map((day, dayIndex) => (
                      <Day
                        {...day}
                        currentUserId={currentUserId}
                        onOpenSidebar={(b) => openSidebar({ weekIndex, dayIndex, b })}
                        setShowJoinSpaceButton={setShowJoinSpaceButton}
                        scheduleUpdate={scheduleUpdate}
                        setScheduleUpdate={setScheduleUpdate}
                        key={`Day_${dayIndex}`}
                      />
                    ))}
                  </SwiperSlide>
                ))}
              </Swiper>
            )}
          </>
        ) : isMobile ? (
          <TableLoaderMobile />
        ) : (
          <TableLoader />
        )}

        <Sidebar
          currentUserId={currentUserId}
          onGoToSearchPage={goToSearchPage}
          isSidebarOpen={isSidebarOpen}
          onCloseSidebar={() => {
            setSidebarOpen(false);
          }}
          isBottomSheetOpen={isBottomSheetOpen}
          bottomSheetData={bottomSheetData}
          onOpenBottomSheet={onOpenBottomSheet}
          onCloseBottomSheet={onCloseBottomSheet}
          currentDate={sidebarDate}
          bookings={sidebarBookings}
          currentBookingId={currentBookingId}
          updateCurrentBookingId={updateCurrentBookingId}
          onDateChange={({ startDate }) => {
            const weekOfDate = startDate.format(WEEK_NUMBER);
            const startDateFormatted = startDate.format(FULL_DATE_WITH_MONTH_NAME);

            const { bookings } =
              weeks
                .find((w) => w?.week === weekOfDate)
                ?.data.find((day) => day?.date === startDateFormatted) || {};

            setSidebarDate(startDateFormatted);
            setSidebarBookings(bookings);
          }}
          showJoinSpaceButton={showJoinSpaceButton}
          setShowJoinSpaceButton={setShowJoinSpaceButton}
        />
      </div>
    </section>
  );
};

SectionTeamSchedulingComponent.propTypes = {
  getTeamSchedule: func,
  companyAccountId: string,
  currentUserId: string,
};

const SectionTeamScheduling = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(SectionTeamSchedulingComponent);

export default injectIntl(SectionTeamScheduling);
