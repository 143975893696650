import classNames from 'classnames';
import get from 'lodash/get';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { compose } from 'redux';
import { TopbarContainer } from '..';
import { Page } from '../../components';
import DropDownList from '../../components_new/DropdownList/DropDownList';
import { signupCompany, updateCompanyAccount } from '../../ducks/Auth.duck';
import { updateHubspotPipeline } from '../../util/api';
import { withViewport } from '../../util/contextHelpers';
import { HubspotController } from '../../util/hubspot';
import { SUBSCRIPTION_OPTIONS } from '../../util/types';
import css from './CompanyAccountPage.module.scss';
import OrganizationDetailTab from './OrganizationDetailTab';
import SummaryTab from './SummaryTab';

const MAX_MOBILE_SCREEN_WIDTH = 768;

const CompanyAccountPageComponent = ({
  totalPrice,
  totalCredits,
  totalUsers,
  intl,
  onCompanySignup,
  isAuthenticated,
  signupInProgress,
  viewport,
  onCompanyUpdate,
  currentUser,
  signupError,
  retrievedCoupon,
  calculatedPrices,
  processStartDate,
  selectedPackage,
}) => {
  const history = useHistory();
  const [currentTab, setCurrentTab] = useState(0);
  const [additionalCompanyDetails, setAdditionalCompanyDetails] = useState({});
  const isMobileLayout = viewport.width < MAX_MOBILE_SCREEN_WIDTH;

  const hubspotController = new HubspotController();

  useEffect(() => {
    hubspotController.updateAppearance().then((methods) => methods.raise());

    return () => {
      hubspotController.updateAppearance().then((methods) => methods.decrease());
    };
  }, []);

  useEffect(() => {
    if (currentUser) {
      const { phone, businessId } = get(currentUser, 'attributes.profile.protectedData', {});
      const { organization } = get(currentUser, 'attributes.profile.publicData', {});
      setAdditionalCompanyDetails({ phone, businessId, organization });
    }
  }, [currentUser]);

  const onSubmit = async (values) => {
    try {
      const { signupParams, rest } = values;
      const { businessId, organization, phone, invoiceEmail, cardToken } = signupParams || {};
      const { invoiceMethod, saveAfterOnetimePayment } = rest || {};
      const paymentOption = invoiceMethod === 'creditCardMethod' ? 'card' : 'invoice';
      const { id: couponCode } = retrievedCoupon || {};

      if (isAuthenticated) {
        await onCompanyUpdate({
          businessId: additionalCompanyDetails.businessId || businessId,
          organization: additionalCompanyDetails.organization || organization,
          phone: additionalCompanyDetails.phone || phone,
          invoiceEmail,
          totalCredits,
          cardToken,
          saveCard: !!saveAfterOnetimePayment,
          subscriptionStartTimestamp: moment(processStartDate.date).toDate().getTime(),
          selectedPackage,
          totalPrice: calculatedPrices.originalPrice * 100,
          paymentOption,
          ...(couponCode && { couponCode }),
        });
      } else {
        await onCompanySignup({
          ...signupParams,
          totalCredits,
          invoiceEmail,
          cardToken,
          saveCard: !!saveAfterOnetimePayment,
          subscriptionStartTimestamp: moment(processStartDate.date).toDate().getTime(),
          selectedPackage,
          totalPrice: calculatedPrices.originalPrice * 100,
          paymentOption,
          ...(couponCode && { couponCode }),
        });
      }
      const hubspotParams = {
        user: currentUser ? currentUser : signupParams,
        amount: calculatedPrices.originalPrice,
        phone,
        organization,
        credits: totalCredits,
        invitedUsers: totalUsers,
      };
      await updateHubspotPipeline(hubspotParams);

      history.push('/subscription-confirmation');
    } catch (e) {
      console.log(e);
    }
  };

  const progressList = {
    [css.step1]: currentTab >= 0,
    [css.step2]: currentTab >= 1,
    [css.step3]: currentTab >= 2,
    [css.step4]: currentTab >= 3,
  };

  const FAQs = [
    {
      question: intl.formatMessage({ id: 'CheckoutPage.faqQuestion1' }),
      answer: intl.formatMessage({ id: 'CheckoutPage.faqAnswer1' }),
    },
    {
      question: intl.formatMessage({ id: 'CheckoutPage.faqQuestion2' }),
      answer: intl.formatMessage({ id: 'CheckoutPage.faqAnswer2' }),
    },
    {
      question: intl.formatMessage({ id: 'CheckoutPage.faqQuestion3' }),
      answer: intl.formatMessage({ id: 'CheckoutPage.faqAnswer3' }),
    },
  ];

  return (
    <Page className={css.page}>
      <TopbarContainer
        currentPage="CompanyAccountPage"
        className={classNames({ [css.topBarContainer]: isMobileLayout })}
      />
      <div className={css.pageContainer}>
        {isMobileLayout ? (
          <div className={css.tabHeaderWrapper}>
            <div className={classNames(css.tabHeader, progressList)} />
          </div>
        ) : null}
        {currentTab !== 2 && (
          <>
            <SummaryTab
              intl={intl}
              totalPrice={totalPrice}
              totalCredits={totalCredits}
              retrievedCoupon={retrievedCoupon}
              processStartDate={processStartDate}
              setCurrentTab={setCurrentTab}
              isCurrentTab={currentTab === 0}
              isPassed={currentTab > 0}
              calculatedPrices={calculatedPrices}
              selectedPackage={selectedPackage}
            />
            <OrganizationDetailTab
              intl={intl}
              onSubmit={onSubmit}
              signupInProgress={signupInProgress}
              isCurrentTab={currentTab === 1}
              isMobileLayout={isMobileLayout}
              isAuthenticated={isAuthenticated}
              signupError={signupError}
              selectedPackage={selectedPackage}
              additionalCompanyDetails={additionalCompanyDetails}
            />
          </>
        )}
        {currentTab !== 2 && (
          <section className={css.faqSection}>
            <FormattedMessage id="CheckoutPage.faq">
              {(id) => <h3 className={css.faqHeader}>{id}</h3>}
            </FormattedMessage>
            <DropDownList
              menuList={FAQs.map((faq) => ({
                header: faq.question,
                content: faq.answer,
              }))}
              indent
            />
          </section>
        )}
      </div>
    </Page>
  );
};

const mapStateToProps = (state) => {
  const {
    Auth: { isAuthenticated, signupError, signupInProgress },
    user: { currentUser },
    b2b: {
      retrievedCoupon,
      calculatedPrices,
      totalPrice,
      totalCredits,
      totalUsers,
      pricePerCredit,
      processStartDate,
      selectedPackage,
    },
  } = state;

  return {
    totalPrice,
    totalCredits,
    totalUsers,
    pricePerCredit,
    isAuthenticated,
    signupError,
    signupInProgress,
    currentUser,
    retrievedCoupon,
    calculatedPrices,
    processStartDate,
    selectedPackage,
  };
};
const mapDispatchToProps = (dispatch) => ({
  onCompanySignup: (params) => dispatch(signupCompany(params)),
  onCompanyUpdate: (params) => dispatch(updateCompanyAccount(params)),
});

const CompanyAccountPage = compose(
  injectIntl,
  withViewport,
  connect(mapStateToProps, mapDispatchToProps)
)(CompanyAccountPageComponent);

export default CompanyAccountPage;
