const KEY_CODES = {
  DOWN: 'ArrowDown',
  UP: 'ArrowUp',
  TAB: 'Tab',
  ENTER: 'Enter',
  ESCAPE: 'Escape',
};

KEY_CODES.LIST = [KEY_CODES.DOWN, KEY_CODES.UP, KEY_CODES.TAB, KEY_CODES.ENTER, KEY_CODES.ESCAPE];

export default KEY_CODES;
