/**
 * This component will show the booking info and calculated total price.
 * I.e. dates and other details related to payment decision in receipt format.
 */
import classNames from 'classnames';
import get from 'lodash/get';
import { oneOf, string } from 'prop-types';
import React from 'react';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { txIsCanceled, txIsDeclined } from '../../util/transaction';
import { LINE_ITEM_PROVIDER_COMMISSION, propTypes } from '../../util/types';
import css from './BookingBreakdown.module.scss';
import LineItemBookingPeriod from './LineItemBookingPeriod';
import LineItemCouponMaybe from './LineItemCouponMaybe';
import LineItemCustomerInfor from './LineItemCustomerInfor';
import LineItemListingTitle from './LineItemListingTitle';
import LineItemListingType from './LineItemListingType';
import LineItemProviderCommissionMaybe from './LineItemProviderCommissionMaybe';
import LineItemTotalPrice from './LineItemTotalPrice';
import LineItemUnitPriceMaybe from './LineItemUnitPriceMaybe';
import LineItemUnknownItemsMaybe from './LineItemUnknownItemsMaybe';
import LineItemVAT from './LineItemVAT';

export const BookingBreakdownComponent = (props) => {
  const {
    rootClassName,
    className,
    userRole,
    unitType,
    transaction,
    locationName,
    booking,
    intl,
    dateType,
    timeZone,
    bookingType,
    isEstimate,
    currentUser,
    hasAdditionalServices,
    seats,
    units,
    estimateAndCredit,
  } = props;
  // console.log('userRole', userRole);
  const isCustomer = userRole === 'customer';
  const isProvider = userRole === 'provider';

  const showCommissionLineItem = transaction.attributes.lineItems.find((item) => {
    const hasProviderCommission = isProvider && item.code === LINE_ITEM_PROVIDER_COMMISSION;
    return hasProviderCommission && !item.reversal && isProvider;
  });
  // const showCommission = true;
  const isCancelledOrDeclined = txIsDeclined(transaction) || txIsCanceled(transaction);
  const classes = classNames(rootClassName || css.root, className);

  const isCreditTx = !isEstimate
    ? get(transaction, 'attributes.protectedData.isCreditTx', false)
    : estimateAndCredit;
  const category = get(transaction, 'listing.attributes.publicData.category');
  // console.log('currentUser', currentUser);
  // console.log('hasAdditionalServices', hasAdditionalServices);
  const vatPercentage = get(transaction, 'listing.attributes.publicData.vatPercentage');
  /**
   * BookingBreakdown contains different line items:
   *
   * LineItemBookingPeriod: prints booking start and booking end types. Prop dateType
   * determines if the date and time or only the date is shown
   *
   * LineItemUnitsMaybe: if he unitType is line-item/unit print the name and
   * quantity of the unit
   * This line item is not used by default in the BookingBreakdown.
   *
   * LineItemUnitPriceMaybe: prints just the unit price, e.g. "Price per night $32.00".
   *
   * LineItemBasePriceMaybe: prints the base price calculation for the listing, e.g.
   * "$150.00 * 2 nights $300"
   *
   *
   * LineItemUnknownItemsMaybe: prints the line items that are unknown. In ideal case there
   * should not be unknown line items. If you are using custom pricing, you should create
   * new custom line items if you need them.
   *
   * LineItemSubTotalMaybe: prints subtotal of line items before possible
   * commission or refunds
   *
   * LineItemRefundMaybe: prints the amount of refund
   *
   * LineItemCustomerCommissionMaybe: prints the amount of customer commission
   * The default transaction process used by FTW doesn't include the customer commission.
   *
   * LineItemCustomerCommissionRefundMaybe: prints the amount of refunded customer commission
   *
   * LineItemProviderCommissionMaybe: prints the amount of provider commission
   *
   * LineItemProviderCommissionRefundMaybe: prints the amount of refunded provider commission
   *
   * LineItemTotalPrice: prints total price of the transaction
   *
   */
  return (
    <div className={classes}>
      {!isEstimate && (
        <>
          <LineItemListingTitle transaction={transaction} locationName={locationName} />
          <LineItemListingType transaction={transaction} />
          <LineItemUnitPriceMaybe
            transaction={transaction}
            unitType={unitType}
            intl={intl}
            dateType={dateType}
            bookingType={bookingType}
            vatPercentage={vatPercentage}
            isProvider={isProvider}
          />
          {isCreditTx && units && (
            <LineItemUnitPriceMaybe
              transaction={transaction}
              unitType={unitType}
              intl={intl}
              dateType={dateType}
              bookingType={bookingType}
              seats={seats}
              units={units}
              category={category}
              isProvider={isProvider}
            />
          )}
          <LineItemBookingPeriod
            booking={booking}
            unitType={unitType}
            dateType={dateType}
            timeZone={timeZone}
            bookingType={bookingType}
          />
          {isCreditTx && currentUser && !isProvider && (
            <LineItemCustomerInfor
              transaction={transaction}
              currentUser={currentUser}
              isProvider={isProvider}
            />
          )}
        </>
      )}

      {isEstimate && <hr className={css.totalDivider} />}
      <LineItemUnknownItemsMaybe transaction={transaction} isProvider={isProvider} intl={intl} />
      {/* todo add in below for transaction page? */}
      {/* <LineItemSubTotalMaybe
        transaction={transaction}
        unitType={unitType}
        userRole={userRole}
        intl={intl}
        dateType={dateType}
      /> */}
      {/* <LineItemRefundMaybe transaction={transaction} intl={intl} /> */}

      {/* <LineItemCustomerCommissionMaybe
         transaction={transaction}
         isCustomer={isCustomer}
         intl={intl}
       /> */}
      {/* <LineItemCustomerCommissionRefundMaybe
         transaction={transaction}
         isCustomer={isCustomer}
         intl={intl}
       /> */}

      {/* can uncomment below for commission/respace's fee line item */}
      {/* todo add in below for transaction page? */}
      {showCommissionLineItem && !isCancelledOrDeclined && (
        <LineItemProviderCommissionMaybe
          transaction={transaction}
          isProvider={isProvider}
          intl={intl}
          isCreditTx={isCreditTx}
        />
      )}

      {/* <LineItemProviderCommissionRefundMaybe
         transaction={transaction}
         isProvider={isProvider}
         intl={intl}
       /> */}
      {!isCreditTx || (isCreditTx && (hasAdditionalServices || isProvider)) ? (
        <>
          <LineItemTotalPrice
            transaction={transaction}
            isCreditTx={isCreditTx}
            isProvider={isProvider}
            intl={intl}
          />
          {!isCancelledOrDeclined && (
            <LineItemVAT
              transaction={transaction}
              isCreditTx={isCreditTx}
              isProvider={isProvider}
            />
          )}
        </>
      ) : null}
      <LineItemCouponMaybe transaction={transaction} />

      {showCommissionLineItem && !isCancelledOrDeclined ? (
        <span className={css.feeInfo}>
          <FormattedMessage id="BookingBreakdown.commissionFeeNote" />
        </span>
      ) : null}
    </div>
  );
};

BookingBreakdownComponent.defaultProps = {
  rootClassName: null,
  className: null,
  dateType: null,
  timeZone: null,
};

BookingBreakdownComponent.propTypes = {
  rootClassName: string,
  className: string,

  userRole: oneOf(['customer', 'provider']).isRequired,
  unitType: propTypes.bookingUnitType.isRequired,
  transaction: propTypes.transaction.isRequired,
  booking: propTypes.booking.isRequired,
  dateType: propTypes.dateType,
  timeZone: string,

  // from injectIntl
  intl: intlShape.isRequired,
};

const BookingBreakdown = injectIntl(BookingBreakdownComponent);

BookingBreakdown.displayName = 'BookingBreakdown';

export default BookingBreakdown;
