import React from 'react';
import PropTypes from 'prop-types';

const IconLogo = (props) => {
  const { className, format, color, ...rest } = props;

  if (format === 'desktop') {
    return (
      <svg
        className={className}
        {...rest}
        id="Layer_1"
        data-name="Layer 1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 963.58 292.22"
      >
        <defs>
          <style>
            {`.cls-1 {
                font-size: 147.91px;
            font-family: MarkPro, Mark Pro;
          }`}
          </style>
        </defs>
        <title>logo black</title>
        <path
          d="M342.76,498.27c-4.36-7.34-8.78-14.64-13.09-22-5-8.56-9.89-17.18-14.88-25.74-3.71-6.34-7.51-12.63-11.26-18.95-2.92-4.94-5.85-9.87-8.73-14.83-1-1.73-.69-2.23,1.27-2.23l52.81,0h3.89a7.82,7.82,0,0,0-.57-1l-10.56-11.25c-5.72-6.1-11.4-12.23-17.15-18.31-3.28-3.46-6.36-7.22-10.06-10.17C305.81,367,291,367.59,283,375.46c-4.86,4.78-7.48,10.42-7.39,17.26.06,5.18,1.95,9.72,4.67,14.08,3.16,5.06,5.95,10.34,8.94,15.51,3.69,6.35,7.45,12.65,11.13,19,5.69,9.82,11.32,19.67,17,29.5,3.16,5.48,6.38,10.94,9.54,16.42a4.92,4.92,0,0,1,.29,1.47c-.45,0-4.79-.44-6.51-.44q-35,0-70.05,0c-6.28,0-12.57,0-18.85-.07h-.36a19.92,19.92,0,0,0,1.46,1.66l.34.35c3.17,3.49,6.27,7.05,9.47,10.51,5.92,6.41,11.93,12.73,17.84,19.15,4.77,5.19,9.45,10.47,14.18,15.7,3.36,3.73,6.6,7.58,10.14,11.12,3.24,3.23,6.71,6,6.71,6L263.21,581l1,1-1,1,44.22,44.22,6.54-6.55-38.72-38.71,21.58-21.58V661.31h9.24V560.5a127.2,127.2,0,0,0,12.7.92c6.69-.13,12.73-2.66,18.11-6.55a36,36,0,0,0,11.5-13.26c3.82-7.71,5.11-15.62,3.12-24-1.66-7-5.13-13.21-8.76-19.33m-52.07-106a8.83,8.83,0,1,1,8.92,9.08c-4.33.34-9-4-8.92-9.08"
          fill={color}
          transform="translate(-231.42 -369.09)"
        />
        <text className="cls-1" transform="translate(158.21 192.33)" fill={color}>
          RESPACES
        </text>
      </svg>
    );
  }

  return (
    <svg
      className={className}
      {...rest}
      id="Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      width="100"
      height="50"
      viewBox="0 0 963.58 292.22"
    >
      <defs>
        <style>
          {`.cls-1 {
            font-size: 147.91px;
            font-family: MarkPro, Mark Pro;
          }`}
        </style>
      </defs>
      <title>logo black</title>
      <path
        d="M342.76,498.27c-4.36-7.34-8.78-14.64-13.09-22-5-8.56-9.89-17.18-14.88-25.74-3.71-6.34-7.51-12.63-11.26-18.95-2.92-4.94-5.85-9.87-8.73-14.83-1-1.73-.69-2.23,1.27-2.23l52.81,0h3.89a7.82,7.82,0,0,0-.57-1l-10.56-11.25c-5.72-6.1-11.4-12.23-17.15-18.31-3.28-3.46-6.36-7.22-10.06-10.17C305.81,367,291,367.59,283,375.46c-4.86,4.78-7.48,10.42-7.39,17.26.06,5.18,1.95,9.72,4.67,14.08,3.16,5.06,5.95,10.34,8.94,15.51,3.69,6.35,7.45,12.65,11.13,19,5.69,9.82,11.32,19.67,17,29.5,3.16,5.48,6.38,10.94,9.54,16.42a4.92,4.92,0,0,1,.29,1.47c-.45,0-4.79-.44-6.51-.44q-35,0-70.05,0c-6.28,0-12.57,0-18.85-.07h-.36a19.92,19.92,0,0,0,1.46,1.66l.34.35c3.17,3.49,6.27,7.05,9.47,10.51,5.92,6.41,11.93,12.73,17.84,19.15,4.77,5.19,9.45,10.47,14.18,15.7,3.36,3.73,6.6,7.58,10.14,11.12,3.24,3.23,6.71,6,6.71,6L263.21,581l1,1-1,1,44.22,44.22,6.54-6.55-38.72-38.71,21.58-21.58V661.31h9.24V560.5a127.2,127.2,0,0,0,12.7.92c6.69-.13,12.73-2.66,18.11-6.55a36,36,0,0,0,11.5-13.26c3.82-7.71,5.11-15.62,3.12-24-1.66-7-5.13-13.21-8.76-19.33m-52.07-106a8.83,8.83,0,1,1,8.92,9.08c-4.33.34-9-4-8.92-9.08"
        transform="translate(-231.42 -369.09)"
        fill={color}
      />
      <text className="cls-1" transform="translate(158.21 192.33)" fill={color}>
        RESPACES
      </text>
    </svg>
  );
};

const { string } = PropTypes;

IconLogo.defaultProps = {
  className: null,
  color: 'black',
};

IconLogo.propTypes = {
  className: string,
  color: string,
};

export default IconLogo;
