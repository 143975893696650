import React from 'react';
import { injectIntl } from 'react-intl';

import { SectionCtaWithBg } from '../../../../components_new';
import { CTA_WITH_BG_COLORS } from '../../../../components_new/SectionCtaWithBg/SectionCtaWithBg';
import {
  BUTTON_SIZES,
  BUTTON_TYPES,
  LINK_COLORS,
  MOBILE_POSITION,
} from '../../../../components_new/ButtonNew/ButtonNew';
import salesTeamImg from '../../../../assets/sales_team.png';

const SectionCta = ({ onModalOpen, intl }) => (
  <SectionCtaWithBg
    title={intl.formatMessage({ id: 'LandingPage.SectionCta.title' })}
    caption={intl.formatMessage({ id: 'LandingPage.SectionCta.caption' })}
    image={salesTeamImg}
    imageAltText="Sales team"
    links={[
      {
        text: intl.formatMessage({ id: 'LandingPage.SectionCta.btn1Text' }),
        // onClick: onModalOpen,
        name: 'OnboardingPage',
        type: BUTTON_TYPES.WHITE,
        linkColor: LINK_COLORS.GREEN,
        size: BUTTON_SIZES.MEDIUM,
        mobileButtonPosition: MOBILE_POSITION.CENTER,
      },
      {
        text: intl.formatMessage({ id: 'LandingPage.SectionCta.btn2Text' }),
        name: 'Partner',
        type: BUTTON_TYPES.GREENWITHWHITEBORDER,
        linkColor: LINK_COLORS.WHITE,
        size: BUTTON_SIZES.MEDIUM,
        mobileButtonPosition: MOBILE_POSITION.CENTER,
      },
    ]}
    bgColor={CTA_WITH_BG_COLORS.GREEN}
  />
);

export default injectIntl(SectionCta);
