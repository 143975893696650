import React, { useEffect, useState } from 'react';
import css from './EditListingAvailabilityPanel.module.scss';
import { FormattedMessage } from 'react-intl';
import {
  FieldSelect,
  FieldRadioButton,
  FieldCurrencyInput,
  Form,
  SaveAndExitButton,
  FieldBoolean,
  FieldCheckbox,
  FieldTextInput,
} from '..';
import { DAYS_OF_WEEK, HOURLY_BOOKING, DAILY_BOOKING, MONTHLY_BOOKING } from '../../util/types';
import {
  EditListingAvailabilityPlanFormNew,
  EditListingAvailabilityPaddingTimeForm,
} from '../../forms';
import config from '../../config';
import * as validators from '../../util/validators';
import get from 'lodash/get';
import { types as sdkTypes } from '../../util/sdkLoader';
import { formatMoney } from '../../util/currency';
import { array, func, string } from 'prop-types';
import { bool } from 'prop-types';
import { object } from 'prop-types';
import { FormSpy } from 'react-final-form';
import classNames from 'classnames';

const { Money } = sdkTypes;

const SectionNew = ({
  availabilityPlan,
  intl,
  sortedAvailabilityExceptions,
  onDeleteAvailabilityException,
  timeZone,
  disabled,
  ready,
  errors,
  listingTitle,
  handleSubmit,
  initialValues = {},
  updateInProgress,
  saveException,
  isPrivate,
  operationHours,
  values,
  addExceptionInProgress,
  form,
  onSaveAndExitHandlers,
  isPublished,
  submittedValues,
  paddingTime = {},
  isFixedOffice,
  comission = -20,
}) => {
  const [priceVAT, setPriceVAT] = useState(0);
  const [priceIncVAT, setPriceIncVAT] = useState(0);
  const [priceRespacesComission, setPriceRespacesComission] = useState(0);

  const bookingType = get(values, 'bookingType', null);
  const vatRequiredMessage = intl.formatMessage({
    id: 'EditListingPricingForm.vatRequiredMessage',
  });
  const getPriceLabel = () => {
    switch (bookingType) {
      case HOURLY_BOOKING:
        return intl.formatMessage({ id: 'SectionNew.priceLabel.hour' });
      case DAILY_BOOKING:
        return intl.formatMessage({ id: 'SectionNew.priceLabel.day' });
      case MONTHLY_BOOKING:
        return intl.formatMessage({ id: 'SectionNew.priceLabel.month' });
      default:
        return intl.formatMessage({ id: 'SectionNew.priceLabel.hour' });
    }
  };

  const requireShowingLabel = intl.formatMessage({
    id: 'EditListingPricingForm.requireShowingLabel',
  });
  const requireShowingPlaceholder = intl.formatMessage({
    id: 'EditListingPricingForm.requireShowingPlaceholder',
  });

  const priceLabel = getPriceLabel();
  const priceSubLabel = intl.formatMessage({
    id: 'SectionNew.priceSubLabel',
  });

  const priceMinPlaceholder = !isFixedOffice
    ? '0'
    : intl.formatMessage({
        id: 'EditListingPricingForm.priceMinPlaceholder',
      });
  const priceMaxPlaceholder = intl.formatMessage({
    id: 'EditListingPricingForm.priceMaxPlaceholder',
  });
  const requestPriceLabel = intl.formatMessage({
    id: 'EditListingPricingForm.requestPriceLabel',
  });

  const priceRequired = validators.required(
    intl.formatMessage({
      id: 'EditListingPricingForm.priceRequired',
    })
  );

  const minPrice = new Money(config.listingMinimumPriceSubUnits, config.currency);
  const minPriceRequired = validators.moneySubUnitAmountAtLeast(
    intl.formatMessage(
      { id: 'EditListingPricingForm.priceTooLow' },
      { minPrice: formatMoney(intl, minPrice) }
    ),
    config.listingMinimumPriceSubUnits
  );

  const priceValidators = config.listingMinimumPriceSubUnits
    ? validators.composeValidators(priceRequired, minPriceRequired)
    : priceRequired;

  const availabilityPlanTitle = intl.formatMessage({ id: 'SectionNew.availabilityPlanTitle' });
  const availabilityPlanSubTitle = intl.formatMessage({
    id: 'SectionNew.availabilityPlanSubTitle',
  });

  return (
    <Form id={'SectionNew'} onSubmit={handleSubmit}>
      <FormSpy
        onChange={({ values }) => {
          submittedValues.current = values;
          // Calculate prices for breakdown. Cannot use one variable from state to calculate the other
          // as it might not update before rendering the other.
          const price = values?.price?.amount / 100;
          setPriceVAT((price * parseInt(values?.vatPercentage)) / 100);
          setPriceIncVAT(
            parseFloat((price + (price * parseInt(values?.vatPercentage)) / 100).toFixed(2))
          );
          setPriceRespacesComission(parseFloat((price * (comission / 100)).toFixed(2)));
        }}
      />

      <SaveAndExitButton onClick={onSaveAndExitHandlers(values)} />
      {!isFixedOffice && (
        <section className={css.bookingTypeWrapper}>
          <div className={css.sectionTitle}>
            {intl.formatMessage({ id: 'SectionNew.bookingType' })}
          </div>
          {config.custom.bookingTypes.map((b) => (
            <FieldRadioButton
              key={b.key}
              id={`bookingType-${b.key}`}
              name="bookingType"
              value={b.key}
              label={intl.formatMessage({ id: b.labelId })}
              labelClassName={css.labelWrapper}
              textClassName={css.labelText}
              svgClassName={css.radioIcon}
            />
          ))}
        </section>
      )}
      {!isFixedOffice && !!values.category && (
        <>
          <div className={css.priceContainer}>
            <section className={css.priceWrapper}>
              <FieldCurrencyInput
                id="price"
                name="price"
                className={css.priceInput}
                label={priceLabel}
                labelClassName={css.label}
                subLabel={priceSubLabel}
                subLabelClassName={css.subLabel}
                placeholder={priceMinPlaceholder}
                currencyConfig={config.currencyConfig}
                inline
                validate={priceValidators}
                maxlength="8"
                // disabled={values.requestPrice}
              />
              {isFixedOffice && (
                <FieldCurrencyInput
                  id="priceMax"
                  name="priceMax"
                  className={classNames(css.priceInput, css.priceInputMax)}
                  subLabel={'-'}
                  placeholder={priceMaxPlaceholder}
                  currencyConfig={config.currencyConfig}
                  inline
                  // disabled={values.requestPrice}
                />
              )}
            </section>
            {isFixedOffice && (
              <FieldCheckbox
                id="requestPrice"
                name="requestPrice"
                // value="requestPrice" // warning that value is required
                label={requestPriceLabel}
                className={css.weekdayFieldCheckbox}
                textClassName={css.dayLabel}
                svgClassName={css.checkboxIcon}
              />
            )}
          </div>
          <section className={css.vatPercentageWrapper}>
            <FieldSelect
              id="vatPercentage"
              name="vatPercentage"
              selectClassName={css.fieldSelect}
              className={css.fieldWrapper}
              label={intl.formatMessage({ id: 'SectionNew.vat' })}
              labelClassName={css.fieldTitle}
              inline
              validate={validators.required(vatRequiredMessage)}
            >
              <option disabled value="">
                {intl.formatMessage({ id: 'SectionNew.vatPlaceholder' })}
              </option>
              {config.custom.VAT_VALUES.map((opt) => (
                <option value={opt.key} key={`vat_${opt.key}`}>
                  {opt.label}
                </option>
              ))}
            </FieldSelect>
          </section>
          {!isFixedOffice && (
            <section className={css.priceCalculation}>
              <div>
                <p className={css.calcRow}>
                  <span>{intl.formatMessage({ id: 'SectionNew.breakdown.vat' })}</span>
                  <span>
                    {values?.vatPercentage === '0' ? '0' : priceVAT.toFixed(2) || 'X'}
                    {intl.formatMessage({ id: 'SectionNew.breakdown.currency' })}
                  </span>
                </p>
                <p className={css.calcRow}>
                  <span>{intl.formatMessage({ id: 'SectionNew.breakdown.priceIncVat' })}</span>
                  <span>
                    {priceIncVAT || 'X'}
                    {intl.formatMessage({ id: 'SectionNew.breakdown.currency' })}
                  </span>
                </p>
                <p className={css.calcRow}>
                  <span>
                    {intl.formatMessage({ id: 'SectionNew.breakdown.respacesFee' })}({-comission}%)
                  </span>
                  <span>
                    {priceRespacesComission || 'X'}
                    {intl.formatMessage({ id: 'SectionNew.breakdown.currency' })}
                  </span>
                </p>
                <p className={css.calcRow}>
                  <span>{intl.formatMessage({ id: 'SectionNew.breakdown.vatFee' })}(25%)</span>
                  <span>
                    {(priceRespacesComission * 0.25).toFixed(2) || 'X'}
                    {intl.formatMessage({ id: 'SectionNew.breakdown.currency' })}
                  </span>
                </p>
                <p className={classNames(css.calcRow, css.summaryRow)}>
                  <span className={css.boldText}>
                    {intl.formatMessage({ id: 'SectionNew.breakdown.payout' })}
                  </span>
                  <span>
                    {(priceIncVAT + priceRespacesComission * 1.25).toFixed(2) || 'X'}
                    {intl.formatMessage({ id: 'SectionNew.breakdown.currency' })}
                  </span>
                </p>
              </div>
            </section>
          )}
          {bookingType && bookingType === 'monthly' && !isFixedOffice && (
            <section className={css.directBookingWrapper}>
              <FieldBoolean
                name="requireShowing"
                id="requireShowing"
                inline
                selectClassName={css.fieldSelect}
                className={css.fieldWrapper}
                label={requireShowingLabel}
                labelClassName={css.fieldTitle}
                placeholder={requireShowingPlaceholder}
              />
            </section>
          )}
        </>
      )}
      <section className={css.availabilityPlanWrapper}>
        {!isFixedOffice && (
          <>
            <div className={css.fieldTitle}>{availabilityPlanTitle}</div>
            <p className={css.subTitle}>{availabilityPlanSubTitle}</p>
          </>
        )}
        <div className={css.availabilityPlanTable}>
          <EditListingAvailabilityPlanFormNew
            formId="EditListingAvailabilityPlanFormNew"
            listingTitle={listingTitle}
            availabilityPlan={availabilityPlan}
            weekdays={DAYS_OF_WEEK}
            onSubmit={handleSubmit}
            initialValues={initialValues}
            inProgress={updateInProgress}
            fetchErrors={errors}
            bookingType={bookingType}
            isPrivate={isPrivate}
            operationHours={operationHours}
            values={values}
            saveException={saveException}
            availabilityExceptions={sortedAvailabilityExceptions}
            addExceptionInProgress={addExceptionInProgress}
            timeZone={timeZone}
            form={form}
            onDeleteAvailabilityException={onDeleteAvailabilityException}
            ready={ready}
            disabled={disabled}
            isPublished={isPublished}
          />
        </div>
      </section>
      {!!(bookingType === DAILY_BOOKING || bookingType === HOURLY_BOOKING) && (
        <section className={css.paddingTimeWrapper}>
          <h2 className={css.fieldTitle}>
            {intl.formatMessage({
              id: 'EditListingAvailabilityPanel.paddingTimeTitle',
            })}
          </h2>
          {values.category === 'meeting' && (
            <div className={css.acceptBookingOnRequestWrapper}>
              <FieldCheckbox
                id="acceptBookingOnRequest"
                name="acceptBookingOnRequest"
                label={intl.formatMessage({
                  id: 'EditListingAvailabilityPanel.acceptBookingOnRequest',
                })}
                textClassName={css.acceptBookingOnRequestLabel}
              />
            </div>
          )}
          <p className={css.subTitle}>
            {intl.formatMessage({
              id: 'EditListingAvailabilityPanel.paddingTimeSubTitle',
            })}
          </p>
          <EditListingAvailabilityPaddingTimeForm
            values={values}
            fieldClassName={css.fieldWrapper}
            selectClassName={css.fieldSelect}
            priceInputClassName={css.priceInput}
            bookingType={bookingType}
            initialValues={{
              unit: bookingType === HOURLY_BOOKING ? 'hour' : 'day',
              ...paddingTime,
            }}
          />
        </section>
      )}

      {errors.showListingsError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingAvailabilityPanel.showListingFailed" />
        </p>
      ) : null}
    </Form>
  );
};

SectionNew.defaultProps = {
  sortedAvailabilityExceptions: [],
  onDeleteAvailabilityException: null,
  timeZone: null,
  disabled: false,
  ready: false,
  errors: null,
  handleSubmit: null,
  updateInProgress: false,
  saveException: false,
  addExceptionInProgress: false,
};

SectionNew.propTypes = {
  sortedAvailabilityExceptions: array,
  onDeleteAvailabilityException: func,
  timeZone: string,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  errors: object.isRequired,
  handleSubmit: func.isRequired,
  updateInProgress: bool.isRequired,
  saveException: func.isRequired,
  addExceptionInProgress: bool.isRequired,
};

export default SectionNew;
