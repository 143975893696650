import React from 'react';
import ContentLoader from 'react-content-loader';

const PendingPlaceholder = () => (
  <ContentLoader viewBox="0 0 756 192" width="756" height="192">
    <rect x="49" y="36" width="90" height="10" rx="2" />
    <rect x="49" y="18" width="130" height="14" rx="2" />
    <circle cx="24" cy="32" r="14" />
    <rect x="619" y="13" width="125" height="38" rx="19" />
    <rect x="49" y="100" width="90" height="10" rx="2" />
    <rect x="49" y="82" width="130" height="14" rx="2" />
    <circle cx="24" cy="96" r="14" />
    <rect x="619" y="77" width="125" height="38" rx="19" />
    <rect x="49" y="164" width="90" height="10" rx="2" />
    <rect x="49" y="146" width="130" height="14" rx="2" />
    <circle cx="24" cy="160" r="14" />
    <rect x="619" y="141" width="125" height="38" rx="19" />
  </ContentLoader>
);

const PendingMobilePlaceholder = () => (
  <ContentLoader viewBox="0 0 375 232" width="375" height="232">
    <rect x="192" y="66" width="184" height="38" rx="19" />
    <rect x="35" y="30" width="90" height="10" rx="2" />
    <rect x="35" y="12" width="120" height="14" rx="2" />
    <rect y="66" width="184" height="38" rx="19" />
    <circle cx="14" cy="26" r="14" />
    <rect x="192" y="182" width="184" height="38" rx="19" />
    <rect x="35" y="146" width="90" height="10" rx="2" />
    <rect x="35" y="128" width="120" height="14" rx="2" />
    <rect y="182" width="184" height="38" rx="19" />
    <circle cx="14" cy="142" r="14" />
  </ContentLoader>
);

const ConnectionsPlaceholder = () => (
  <ContentLoader viewBox="0 0 756 150" width="756" height="150">
    <rect x="330" y="20" width="75" height="10" rx="2" />
    <rect x="669" y="18" width="75" height="14" rx="2" />
    <rect x="49" y="11" width="130" height="14" rx="2" />
    <rect x="49" y="29" width="90" height="10" rx="2" />
    <circle cx="24" cy="25" r="14" />
    <rect x="330" y="70" width="75" height="10" rx="2" />
    <rect x="669" y="68" width="75" height="14" rx="2" />
    <rect x="49" y="61" width="130" height="14" rx="2" />
    <rect x="49" y="79" width="90" height="10" rx="2" />
    <circle cx="24" cy="75" r="14" />
    <rect x="330" y="120" width="75" height="10" rx="2" />
    <rect x="669" y="118" width="75" height="14" rx="2" />
    <rect x="49" y="111" width="130" height="14" rx="2" />
    <rect x="49" y="129" width="90" height="10" rx="2" />
    <circle cx="24" cy="125" r="14" />
  </ContentLoader>
);

const ConnectionsMobilePlaceholder = () => (
  <ContentLoader viewBox="0 0 375 150" width="375" height="150">
    <circle cx="14" cy="125" r="14" />
    <rect x="40" y="111" width="120" height="14" rx="2" />
    <rect x="200" y="119" width="100" height="12" rx="2" />
    <rect x="319" y="119" width="44" height="12" rx="2" />
    <rect x="40" y="129" width="90" height="10" rx="2" />
    <circle cx="14" cy="75" r="14" />
    <rect x="40" y="61" width="120" height="14" rx="2" />
    <rect x="200" y="69" width="100" height="12" rx="2" />
    <rect x="319" y="69" width="44" height="12" rx="2" />
    <rect x="40" y="79" width="90" height="10" rx="2" />
    <circle cx="14" cy="25" r="14" />
    <rect x="40" y="11" width="120" height="14" rx="2" />
    <rect x="200" y="19" width="100" height="12" rx="2" />
    <rect x="319" y="19" width="44" height="12" rx="2" />
    <rect x="40" y="29" width="90" height="10" rx="2" />
  </ContentLoader>
);

export {
  PendingPlaceholder,
  PendingMobilePlaceholder,
  ConnectionsPlaceholder,
  ConnectionsMobilePlaceholder,
};
