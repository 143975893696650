import React, { useEffect, useState } from 'react';
import moment from 'moment';
import classNames from 'classnames';
import { arrayOf, bool, shape, string } from 'prop-types';
import { injectIntl } from 'react-intl';

import { Avatars, ButtonNew } from '../../../index';
import { BUTTON_TYPES } from '../../../ButtonNew/ButtonNew';

import { OutsideClickHandler, NamedLink } from '../../../../components';
import { createSlug } from '../../../../util/urlHelpers';

import { PAGE_NAMES } from '../../config/constants';

import { updateBookingHazura } from '../../../../util/api';
import { BOOKING_STATE_CANCELLED } from '../../../../util/types';
import IconSpinner from '../../../../components/IconSpinner/IconSpinner';

import BookingCardTitle from './BookingCardTitle';

import css from './BookingCard.module.scss';

const BookingCard = ({
  authorId,
  bookingId,
  currentUserId,
  listingId,
  listingName,
  listingCategory,
  date,
  times,
  onOpenSidebar,
  users,
  isFilled,
  intl,
  setShowJoinSpaceButton,
  scheduleUpdate,
  setScheduleUpdate,
  locationId,
  locationName,
}) => {
  //Take the date and make a string that can be used to create a slug for the link to the listing page
  //Format for date search: 2022-06-22 00:00,2022-06-22 00:00
  const dateSearch = moment(date).format('YYYY-MM-DD') + ' ' + moment(date).format('HH:mm');
  const searchParams = new URLSearchParams({
    dates: `${dateSearch},${dateSearch}`,
    ...(!!bookingId && { bookingId }),
  });

  const isOwnBooking = currentUserId === authorId;
  const { transactionId } = users.find((u) => u.authorId === currentUserId) || {};
  const [isMenuOpen, setMenuOpen] = useState(false);
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(false);
  }, [users]);

  const goToMyBookings = () => {
    setMenuOpen(false);
    window.location = '/inbox/orders';
  };

  const listingTitleSlug = createSlug(listingName);

  /**
   * @param page {String}
   * @param id {String}
   * */
  const goToPage = (page, id) => {
    setMenuOpen(false);
    const routeString =
      page === PAGE_NAMES.LISTING_PAGE
        ? `/l/${listingTitleSlug}/${listingId}`
        : `/order/${id}/details`;
    window.open(routeString, '_blank').focus();
  };

  const formattedStartTime =
    times.startTime === null
      ? null
      : typeof times.startTime === 'string'
      ? times.startTime
      : moment(new Date(times.startTime * 1000)).format('HH:mm');
  const formattedEndTime =
    times.endTime === null
      ? null
      : typeof times.endTime === 'string'
      ? times.endTime
      : moment(new Date(times.endTime * 1000)).format('HH:mm');

  const cancelBooking = (transactionId) => {
    setLoading(true);
    setMenuOpen(false);
    updateBookingHazura({
      bookingId: transactionId,
      newStatus: BOOKING_STATE_CANCELLED,
    })
      .then((res) => setScheduleUpdate(scheduleUpdate + 1))
      // .then(setLoading(false))
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  const isNotSchedule = listingCategory !== 'schedule';

  return (
    <div className={classNames(css.card, isFilled && css.filled)}>
      <div
        onClick={() => {
          onOpenSidebar();
          setShowJoinSpaceButton(!(isOwnBooking || listingCategory === 'schedule'));
        }}
        className={css.overlayForClick}
      />
      <div
        className={classNames(
          css.listingInfo,
          listingCategory === 'schedule' && css.listingInfoSchedule
        )}
      >
        <BookingCardTitle
          locationId={locationId}
          locationName={locationName}
          listingId={listingId}
          listingName={listingName}
          searchParams={searchParams}
          isNotSchedule={isNotSchedule}
        />
        {listingCategory !== 'schedule' && (
          <p className={css.listingType}>
            {intl.formatMessage({ id: `MarketplaceConfig.filters.category.${listingCategory}` })}
          </p>
        )}
      </div>
      <div className={css.bottom}>
        {isLoading ? (
          <IconSpinner className={css.iconSpinner} />
        ) : (
          <Avatars avatars={users} avatarClassName={css.avatar} />
        )}
        {isFilled && formattedStartTime && formattedEndTime ? (
          <span className={css.times}>
            {formattedStartTime}-{formattedEndTime}
          </span>
        ) : (
          listingCategory === 'coworking' && (
            <NamedLink
              name="ListingPage"
              params={{ id: listingId, slug: createSlug(listingName) }}
              to={{ search: searchParams.toString() }}
              target="_blank"
              rel="noopener noreferrer"
              className={css.noUnderline}
            >
              <ButtonNew className={css.aboveOverlayForClick} type={BUTTON_TYPES.GREEN}>
                {intl.formatMessage({ id: 'TeamSchedule.joinButton' })}
              </ButtonNew>
            </NamedLink>
          )
        )}
      </div>
      {isFilled && (
        <button
          onClick={() => setMenuOpen(true)}
          className={classNames(css.menuToggle, css.aboveOverlayForClick)}
        >
          <svg
            width="20"
            height="4"
            viewBox="0 0 20 4"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="2" cy="2" r="2" fill="white" />
            <circle cx="10" cy="2" r="2" fill="white" />
            <circle cx="18" cy="2" r="2" fill="white" />
          </svg>
        </button>
      )}
      {isMenuOpen && (
        <OutsideClickHandler className={css.menu} onOutsideClick={() => setMenuOpen(false)}>
          {isNotSchedule && (
            <button onClick={goToMyBookings} className={css.menuItem}>
              {intl.formatMessage({ id: 'TeamSchedule.dropdown.myBookings' })}
            </button>
          )}
          {isOwnBooking && isNotSchedule && (
            <button
              onClick={() => goToPage(PAGE_NAMES.ORDER, transactionId)}
              className={css.menuItem}
            >
              {intl.formatMessage({ id: 'TeamSchedule.dropdown.editBooking' })}
            </button>
          )}
          {isNotSchedule && (
            <button
              onClick={() => goToPage(PAGE_NAMES.LISTING_PAGE, listingName)}
              className={css.menuItem}
            >
              {intl.formatMessage({ id: 'TeamSchedule.dropdown.goToListing' })}
            </button>
          )}
          {listingCategory === 'schedule' && (
            <button onClick={() => cancelBooking(transactionId)} className={css.menuItem}>
              {intl.formatMessage({ id: 'TeamSchedule.dropdown.remove' })}
            </button>
          )}
        </OutsideClickHandler>
      )}
    </div>
  );
};

BookingCard.propTypes = {
  id: string.isRequired,
  listingTitle: string.isRequired,
  listingCategory: string.isRequired,
  users: arrayOf(
    shape({
      abbreviatedName: string,
      avatarURL: string,
      firstName: string,
      surname: string,
      userId: string,
    })
  ).isRequired,
  isFilled: bool.isRequired,
};

export default injectIntl(BookingCard);
