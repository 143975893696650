import React from 'react';
import { NoMemberImage } from '../../../components';
import { SubscriptionManagementIcons as Icons } from '../../../components';
import css from './TeamAccessTab.module.scss';
import classNames from 'classnames';

export const ContactButton = ({ openContactModal, createContactText }) => (
  <div className={css.contactItem} onClick={openContactModal}>
    <Icons.ContactIcon className={css.contactIcon} />
    <span className={css.contactText}>{createContactText}</span>
  </div>
);

export const ImportButton = ({ importContactText }) => (
  <div className={css.contactItem}>
    <Icons.ImportIcon className={css.contactIcon} />
    <span className={css.contactText}>{importContactText}</span>
  </div>
);

export const ContactButtons = (props) => {
  const {
    openContactModal,
    isNavContained = false,
    isInline = false,
    createContactText,
    importContactText,
  } = props;
  const wrapperClass = classNames(
    css.contactWrapper,
    isNavContained && css.column,
    isInline && css.inline
  );
  return (
    <div className={wrapperClass}>
      <ContactButton openContactModal={openContactModal} createContactText={createContactText} />
      {/* <ImportButton importContactText={importContactText} /> */}
    </div>
  );
};

const TeamAccessTabNoMember = (props) => {
  const { openContactModal, intl } = props;

  const imageText = intl.formatMessage({
    id: 'TeamAccessTabNoMember.imageText',
  });

  const createContactText = intl.formatMessage({
    id: 'TeamAccessTabNoMember.createContact',
  });

  const importContactText = intl.formatMessage({
    id: 'TeamAccessTabNoMember.importContact',
  });

  return (
    <>
      <div className={css.noMemberImgWrapper}>
        <NoMemberImage />
        <span className={css.noMemberImgText}>{imageText}</span>
      </div>
      <ContactButtons
        openContactModal={openContactModal}
        createContactText={createContactText}
        importContactText={importContactText}
      />
    </>
  );
};

export default TeamAccessTabNoMember;
