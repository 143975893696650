import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconCalendar.module.scss';

const IconCalendar = (props) => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);
  return (
    <svg
      className={classes}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M21.5 4H19.3333V5.33333H21.3333V20H2.66663V5.33333H4.66663V4H2.49997C2.18523 4.00526 1.88548 4.13536 1.66668 4.36166C1.44787 4.58796 1.32795 4.89193 1.3333 5.20667V20.1267C1.32795 20.4414 1.44787 20.7454 1.66668 20.9717C1.88548 21.198 2.18523 21.3281 2.49997 21.3333H21.5C21.8147 21.3281 22.1145 21.198 22.3333 20.9717C22.5521 20.7454 22.672 20.4414 22.6666 20.1267V5.20667C22.672 4.89193 22.5521 4.58796 22.3333 4.36166C22.1145 4.13536 21.8147 4.00526 21.5 4Z" />
      <path d="M5.33337 9.33398H6.66671V10.6673H5.33337V9.33398Z" />
      <path d="M9.33337 9.33398H10.6667V10.6673H9.33337V9.33398Z" />
      <path d="M13.3334 9.33398H14.6667V10.6673H13.3334V9.33398Z" />
      <path d="M17.3334 9.33398H18.6667V10.6673H17.3334V9.33398Z" />
      <path d="M5.33337 12.666H6.66671V13.9993H5.33337V12.666Z" />
      <path d="M9.33337 12.666H10.6667V13.9993H9.33337V12.666Z" />
      <path d="M13.3334 12.666H14.6667V13.9993H13.3334V12.666Z" />
      <path d="M17.3334 12.666H18.6667V13.9993H17.3334V12.666Z" />
      <path d="M5.33337 16H6.66671V17.3333H5.33337V16Z" />
      <path d="M9.33337 16H10.6667V17.3333H9.33337V16Z" />
      <path d="M13.3334 16H14.6667V17.3333H13.3334V16Z" />
      <path d="M17.3334 16H18.6667V17.3333H17.3334V16Z" />
      <path d="M6.66667 6.66732C7.03486 6.66732 7.33333 6.36884 7.33333 6.00065V2.00065C7.33333 1.63246 7.03486 1.33398 6.66667 1.33398C6.29848 1.33398 6 1.63246 6 2.00065V6.00065C6 6.36884 6.29848 6.66732 6.66667 6.66732Z" />
      <path d="M17.3333 6.66732C17.7015 6.66732 18 6.36884 18 6.00065V2.00065C18 1.63246 17.7015 1.33398 17.3333 1.33398C16.9651 1.33398 16.6666 1.63246 16.6666 2.00065V6.00065C16.6666 6.36884 16.9651 6.66732 17.3333 6.66732Z" />
      <path d="M8.66663 4H15.3333V5.33333H8.66663V4Z" />
    </svg>
  );
};

IconCalendar.defaultProps = { rootClassName: null, className: null };

const { string } = PropTypes;

IconCalendar.propTypes = { rootClassName: string, className: string };

export default IconCalendar;
