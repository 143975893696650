import React from 'react';
import loadable from '@loadable/component';

import getPageDataLoadingAPI from './containers/pageDataLoadingAPI';
import { NamedRedirect } from './components';
import OrderConfirmationPage from './containers/OrderConfirmationPage/OrderConfirmationPage';

const pageDataLoadingAPI = getPageDataLoadingAPI();

const AboutPage = loadable(() =>
  import(/* webpackChunkName: "AboutPage" */ './containers/AboutPage/AboutPage')
);
const AuthenticationPage = loadable(() =>
  import(/* webpackChunkName: "AboutPage" */ './containers/AuthenticationPage/AuthenticationPage')
);
const BlogPage = loadable(() =>
  import(/* webpackChunkName: "BlogPage" */ './containers/BlogPage/BlogPage')
);
const CareersPage = loadable(() =>
  import(/* webpackChunkName: "CareersPage" */ './containers/CareersPage/CareersPage')
);
const CheckoutPage = loadable(() =>
  import(/* webpackChunkName: "CheckoutPage" */ './containers/CheckoutPage/CheckoutPage')
);
const ContactDetailsPage = loadable(() =>
  import(
    /* webpackChunkName: "ContactDetailsPage" */ './containers/ContactDetailsPage/ContactDetailsPage'
  )
);
const DashboardPage = loadable(() =>
  import(/* webpackChunkName: "DashboardPage" */ './containers/DashboardPage/DashboardPage')
);
const EditListingPage = loadable(() =>
  import(/* webpackChunkName: "EditListingPage" */ './containers/EditListingPage/EditListingPage')
);
const EmailVerificationPage = loadable(() =>
  import(
    /* webpackChunkName: "EmailVerificationPage" */ './containers/EmailVerificationPage/EmailVerificationPage'
  )
);
const FAQPage = loadable(() =>
  import(/* webpackChunkName: "FAQPage" */ './containers/FAQPage/FAQPage')
);
const InboxPage = loadable(() =>
  import(/* webpackChunkName: "InboxPage" */ './containers/InboxPage/InboxPage')
);
const JobPost = loadable(() =>
  import(/* webpackChunkName: "JobPost" */ './containers/CareersPage/JobPost')
);
const LandingPage = loadable(() =>
  import(/* webpackChunkName: "LandingPage" */ './containers/LandingPage/LandingPage')
);
const LandingPageLoggedIn = loadable(() =>
  import(
    /* webpackChunkName: "LandingPageLoggedIn" */ './containers/LandingPageLoggedIn/LandingPageLoggedIn'
  )
);
const ListingPage = loadable(() =>
  import(/* webpackChunkName: "ListingPage" */ './containers/ListingPage/ListingPage')
);
const LocationPage = loadable(() =>
  import(/* webpackChunkName: "LocationPage" */ './containers/LocationPage/LocationPage')
);
const ManageListingsPage = loadable(() =>
  import(
    /* webpackChunkName: "ManageListingsPage" */ './containers/ManageListingsPage/ManageListingsPage'
  )
);
const NotFoundPage = loadable(() =>
  import(/* webpackChunkName: "NotFoundPage" */ './containers/NotFoundPage/NotFoundPage')
);
const PasswordRecoveryPage = loadable(() =>
  import(
    /* webpackChunkName: "PasswordRecoveryPage" */ './containers/PasswordRecoveryPage/PasswordRecoveryPage'
  )
);
const PasswordResetPage = loadable(() =>
  import(
    /* webpackChunkName: "PasswordResetPage" */ './containers/PasswordResetPage/PasswordResetPage'
  )
);
const StripePayoutPage = loadable(() =>
  import(
    /* webpackChunkName: "StripePayoutPage" */ './containers/StripePayoutPage/StripePayoutPage'
  )
);
const PaymentMethodsPage = loadable(() =>
  import(
    /* webpackChunkName: "PaymentMethodsPage" */ './containers/PaymentMethodsPage/PaymentMethodsPage'
  )
);
const PrivacyPolicyPage = loadable(() =>
  import(
    /* webpackChunkName: "PrivacyPolicyPage" */ './containers/PrivacyPolicyPage/PrivacyPolicyPage'
  )
);
const CookiePolicyPage = loadable(() =>
  import(
    /* webpackChunkName: "CookiePolicyPage" */ './containers/CookiePolicyPage/CookiePolicyPage'
  )
);
const ProfilePage = loadable(() =>
  import(/* webpackChunkName: "ProfilePage" */ './containers/ProfilePage/ProfilePage')
);
const SearchPageNew = loadable(() =>
  import(/* webpackChunkName: "SearchPage" */ './containers/SearchPageNew/SearchPageNew')
);
const SEOLandingPage = loadable(() =>
  import(/* webpackChunkName: "SEOLandingPage" */ './containers/SEOLandingPage/SEOLandingPage')
);
const SinglePost = loadable(() =>
  import(/* webpackChunkName: "SinglePost" */ './containers/BlogPage/SinglePost')
);
const PartnerProffessional = loadable(() =>
  import(
    /* webpackChunkName: "PartnerProffessional" */ './containers/PartnerProffessional/PartnerProffessional'
  )
);
const Partner = loadable(() =>
  import(/* webpackChunkName: "Partner" */ './containers/Partner/PartnerChoice')
);
const PartnerSubletting = loadable(() =>
  import(/* webpackChunkName: "Partner" */ './containers/PartnerSubletting/PartnerSubletting')
);
const StyleguidePage = loadable(() =>
  import(/* webpackChunkName: "StyleguidePage" */ './containers/StyleguidePage/StyleguidePage')
);
const TermsOfServicePage = loadable(() =>
  import(
    /* webpackChunkName: "TermsOfServicePage" */ './containers/TermsOfServicePage/TermsOfServicePage'
  )
);
const TransactionPage = loadable(() =>
  import(/* webpackChunkName: "TransactionPage" */ './containers/TransactionPage/TransactionPage')
);
const ContactUs = loadable(() =>
  import(/* webpackChunkName: "ContactUs" */ './containers/ContactUs/ContactUs')
);
const RespacesPass = loadable(() =>
  import(/* webpackChunkName: "RespacesPass" */ './containers/RespacesPass/RespacesPass')
);

const CompanyAccountPage = loadable(() =>
  import(
    /* webpackChunkName: "CompanyAccountPage" */ './containers/CompanyAccountPage/CompanyAccountPage'
  )
);

const SubscriptionManagementPage = loadable(() =>
  import(
    /* webpackChunkName: "SubscriptionManagementPage" */ './containers/SubscriptionManagementPage/SubscriptionManagementPage'
  )
);

const SubscriptionConfirmationPage = loadable(() =>
  import(
    /* webpackChunkName: "SubscriptionConfirmationPage" */ './containers/SubscriptionConfirmationPage/SubscriptionConfirmationPage'
  )
);

const InvitationPage = loadable(() =>
  import(/* webpackChunkName: "InvitationPage" */ './containers/InvitationPage/InvitationPage')
);

const QuotePage = loadable(() =>
  import(/* webpackChunkName: "QuotePage" */ './containers/QuotePage/QuotePage')
);

const CompanyBookingsPage = loadable(() =>
  import(
    /* webpackChunkName: "CompanyBookingsPage" */ './containers/CompanyBookingsPage/CompanyBookingsPage'
  )
);

const EditListingFinishPage = loadable(() =>
  import(
    /* webpackChunkName: "EditListingFinishPage" */ './containers/EditListingFinishPage/EditListingFinishPage'
  )
);

const OnboardingPage = loadable(() =>
  import(/* webpackChunkName: "OnboardingPage" */ './containers/OnboardingPage/OnboardingPage')
);

const FindOfficeMatchPage = loadable(() =>
  import(
    /* webpackChunkName: "FindOfficeMatch" */ './containers/FindOfficeMatchPage/FindOfficeMatchPage'
  )
);

const OnboardingPartnersPage = loadable(() =>
  import(
    /* webpackChunkName: "OnboardingPartnersPage" */ './containers/OnboardingPartnersPage/OnboardingPartnersPage'
  )
);

const FormSubmissionConfirmation = loadable(() =>
  import(
    /* webpackChunkName: "FormSubmissionConfirmation" */ './containers/FormSubmissionConfirmation/FormSubmissionConfirmation'
  )
);

// routeConfiguration needs to initialize containers first
// Otherwise, components will import form container eventually and
// at that point css bundling / imports will happen in wrong order.

export const ACCOUNT_SETTINGS_PAGES = [
  'ContactDetailsPage',
  'StripePayoutPage',
  'PaymentMethodsPage',
];

// https://en.wikipedia.org/wiki/Universally_unique_identifier#Nil_UUID
const draftId = '00000000-0000-0000-0000-000000000000';
const draftSlug = 'draft';

const RedirectToLandingPage = () => <NamedRedirect name="LandingPage" />;

const redirectToRespacesPass = () => <NamedRedirect name="RespacesPass" />;

// NOTE: Most server-side endpoints are prefixed with /api. Requests to those
// endpoints are indended to be handled in the server instead of the browser and
// they will not render the application. So remember to avoid routes starting
// with /api and if you encounter clashing routes see server/index.js if there's
// a conflicting route defined there.

// Our routes are exact by default.
// See behaviour from Routes.js where Route is created.
const routeConfiguration = () => {
  return [
    {
      path: '/',
      name: 'LandingPage',
      component: LandingPage,
      loadData: pageDataLoadingAPI.LandingPage.loadData,
    },
    {
      path: '/app',
      name: 'LandingPageLoggedIn',
      auth: true,
      authPage: 'LoginPage',
      component: LandingPageLoggedIn,
    },
    {
      path: '/about',
      name: 'AboutPage',
      component: AboutPage,
    },
    {
      path: '/supplier',
      name: 'SpaceSupplierOld',
      component: Partner,
    },
    {
      path: '/partner',
      name: 'Partner',
      component: Partner,
    },
    {
      path: '/partner/professional',
      name: 'PartnerProffessional',
      component: PartnerProffessional,
    },
    {
      path: '/partner/subletting',
      name: 'PartnerSubletting',
      component: PartnerSubletting,
    },
    {
      path: '/s',
      name: 'SearchPage',
      component: SearchPageNew,
    },
    {
      path: '/l',
      name: 'ListingBasePage',
      component: RedirectToLandingPage,
    },
    {
      path: '/l/:slug/:id',
      name: 'ListingPage',
      component: ListingPage,
      loadData: pageDataLoadingAPI.ListingPage.loadData,
    },
    {
      path: '/location/:slug/:id',
      name: 'LocationPage',
      component: LocationPage,
      loadData: pageDataLoadingAPI.LocationPage.loadData,
    },
    {
      path: '/l/:slug/:id/checkout',
      name: 'CheckoutPage',
      auth: true,
      component: CheckoutPage,
      setInitialValues: pageDataLoadingAPI.CheckoutPage.setInitialValues,
    },
    {
      path: '/l/:slug/:id/:variant',
      name: 'ListingPageVariant',
      auth: true,
      authPage: 'LoginPage',
      component: ListingPage,
      loadData: pageDataLoadingAPI.ListingPage.loadData,
    },
    {
      path: '/l/new',
      name: 'NewListingPage',
      auth: true,
      component: () => (
        <NamedRedirect
          name="EditListingPage"
          params={{ slug: draftSlug, id: draftId, type: 'new', tab: 'description' }}
        />
      ),
    },
    {
      path: '/l/:slug/:id/:type/:tab',
      name: 'EditListingPage',
      auth: true,
      component: EditListingPage,
      loadData: pageDataLoadingAPI.EditListingPage.loadData,
    },
    {
      path: '/finish-listing/:slug/:id/:type',
      name: 'EditListingFinishPage',
      auth: true,
      component: EditListingFinishPage,
      loadData: pageDataLoadingAPI.EditListingPage.loadData,
    },
    {
      path: '/finish-listing/:slug/:id/:type/:returnURLType',
      name: 'EditListingStripeOnboardingPage',
      auth: true,
      component: EditListingFinishPage,
      loadData: pageDataLoadingAPI.EditListingPage.loadData,
    },

    // Canonical path should be after the `/l/new` path since they
    // conflict and `new` is not a valid listing UUID.
    {
      path: '/l/:id',
      name: 'ListingPageCanonical',
      component: ListingPage,
      loadData: pageDataLoadingAPI.ListingPage.loadData,
    },
    {
      path: '/u',
      name: 'ProfileBasePage',
      component: RedirectToLandingPage,
    },
    {
      path: '/u/:id',
      name: 'ProfilePage',
      component: ProfilePage,
      loadData: pageDataLoadingAPI.ProfilePage.loadData,
    },
    {
      path: '/dashboard/:view/:tab',
      name: 'DashboardPage',
      auth: true,
      authPage: 'LoginPage',
      component: DashboardPage,
    },
    {
      path: '/dashboard/:view/:tab/:id',
      name: 'DashboardPageTargetedListing',
      auth: true,
      authPage: 'LoginPage',
      component: DashboardPage,
    },

    // Note: authenticating with IdP (e.g. Facebook) expects that /login path exists
    // so that in the error case users can be redirected back to the LoginPage
    // In case you change this, remember to update the route in server/api/auth/loginWithIdp.js
    {
      path: '/login',
      name: 'LoginPage',
      component: AuthenticationPage,
      extraProps: { tab: 'login' },
    },
    {
      path: '/signup',
      name: 'SignupPage',
      component: AuthenticationPage,
      extraProps: { tab: 'signup' },
    },
    {
      path: '/confirm',
      name: 'ConfirmPage',
      component: AuthenticationPage,
      extraProps: { tab: 'confirm' },
    },
    {
      path: '/recover-password',
      name: 'PasswordRecoveryPage',
      component: PasswordRecoveryPage,
    },
    {
      path: '/inbox',
      name: 'InboxBasePage',
      auth: true,
      authPage: 'LoginPage',
      component: () => <NamedRedirect name="InboxPage" params={{ tab: 'sales' }} />,
    },
    {
      path: '/inbox/company',
      name: 'CompanyBookingsPage',
      auth: true,
      authPage: 'LoginPage',
      component: CompanyBookingsPage,
      loadData: pageDataLoadingAPI.CompanyBookingsPage.loadData,
    },
    {
      path: '/inbox/:tab',
      name: 'InboxPage',
      auth: true,
      authPage: 'LoginPage',
      component: InboxPage,
      loadData: pageDataLoadingAPI.InboxPage.loadData,
    },
    {
      path: '/order/:id',
      name: 'OrderPage',
      auth: true,
      authPage: 'LoginPage',
      component: (props) => <NamedRedirect name="OrderDetailsPage" params={{ ...props.params }} />,
    },
    {
      path: '/order/:id/details',
      name: 'OrderDetailsPage',
      auth: true,
      authPage: 'LoginPage',
      component: TransactionPage,
      extraProps: { transactionRole: 'customer' },
      loadData: (params) =>
        pageDataLoadingAPI.TransactionPage.loadData({ ...params, transactionRole: 'customer' }),
      setInitialValues: pageDataLoadingAPI.TransactionPage.setInitialValues,
    },
    {
      path: '/orderconfirmation/:id/details',
      name: 'OrderConfirmationPage',
      auth: true,
      authPage: 'LoginPage',
      component: OrderConfirmationPage,
      extraProps: { transactionRole: 'customer' },
      loadData: (params) =>
        pageDataLoadingAPI.TransactionPage.loadData({ ...params, transactionRole: 'customer' }),
      setInitialValues: pageDataLoadingAPI.TransactionPage.setInitialValues,
    },
    {
      path: '/sale/:id',
      name: 'SalePage',
      auth: true,
      authPage: 'LoginPage',
      component: (props) => <NamedRedirect name="SaleDetailsPage" params={{ ...props.params }} />,
    },
    {
      path: '/sale/:id/details',
      name: 'SaleDetailsPage',
      auth: true,
      authPage: 'LoginPage',
      component: (props) => <TransactionPage {...props} transactionRole="provider" />,
      loadData: (params) =>
        pageDataLoadingAPI.TransactionPage.loadData({ ...params, transactionRole: 'provider' }),
    },
    {
      path: '/listings',
      name: 'ManageListingsPage',
      auth: true,
      authPage: 'LoginPage',
      component: ManageListingsPage,
      loadData: pageDataLoadingAPI.ManageListingsPage.loadData,
    },
    {
      path: '/account',
      name: 'AccountSettingsPage',
      auth: true,
      authPage: 'LoginPage',
      component: () => <NamedRedirect name="ContactDetailsPage" />,
    },
    {
      path: '/account/contact-details',
      name: 'ContactDetailsPage',
      auth: true,
      authPage: 'LoginPage',
      component: ContactDetailsPage,
      loadData: pageDataLoadingAPI.ContactDetailsPage.loadData,
    },
    {
      path: '/account/payments',
      name: 'StripePayoutPage',
      auth: true,
      authPage: 'LoginPage',
      component: StripePayoutPage,
      loadData: pageDataLoadingAPI.StripePayoutPage.loadData,
    },
    {
      path: '/account/payments/:returnURLType',
      name: 'StripePayoutOnboardingPage',
      auth: true,
      authPage: 'LoginPage',
      component: StripePayoutPage,
      loadData: pageDataLoadingAPI.StripePayoutPage.loadData,
    },
    {
      path: '/account/payment-methods',
      name: 'PaymentMethodsPage',
      auth: true,
      authPage: 'LoginPage',
      component: PaymentMethodsPage,
      loadData: pageDataLoadingAPI.PaymentMethodsPage.loadData,
    },
    {
      path: '/terms-of-service',
      name: 'TermsOfServicePage',
      component: TermsOfServicePage,
    },
    {
      path: '/privacy-policy',
      name: 'PrivacyPolicyPage',
      component: PrivacyPolicyPage,
    },
    {
      path: '/cookie-policy',
      name: 'CookiePolicyPage',
      component: CookiePolicyPage,
    },
    {
      path: '/styleguide',
      name: 'Styleguide',
      component: StyleguidePage,
    },
    {
      path: '/styleguide/g/:group',
      name: 'StyleguideGroup',
      component: StyleguidePage,
    },
    {
      path: '/styleguide/c/:component',
      name: 'StyleguideComponent',
      component: StyleguidePage,
    },
    {
      path: '/styleguide/c/:component/:example',
      name: 'StyleguideComponentExample',
      component: StyleguidePage,
    },
    {
      path: '/styleguide/c/:component/:example/raw',
      name: 'StyleguideComponentExampleRaw',
      component: StyleguidePage,
      extraProps: { raw: true },
    },
    {
      path: '/notfound',
      name: 'NotFoundPage',
      component: NotFoundPage,
    },

    // Do not change this path!
    //
    // The API expects that the application implements /reset-password endpoint
    {
      path: '/reset-password',
      name: 'PasswordResetPage',
      component: PasswordResetPage,
    },

    // Do not change this path!
    //
    // The API expects that the application implements /verify-email endpoint
    {
      path: '/verify-email',
      name: 'EmailVerificationPage',
      auth: true,
      authPage: 'LoginPage',
      component: EmailVerificationPage,
      loadData: pageDataLoadingAPI.EmailVerificationPage.loadData,
    },
    {
      path: '/faq',
      name: 'FAQPage',
      component: FAQPage,
    },
    {
      path: '/respaces-pass',
      name: 'RespacesPass',
      component: RespacesPass,
    },
    {
      path: '/companies',
      name: 'RespacesPass',
      component: redirectToRespacesPass,
    },
    {
      path: '/b2b',
      name: 'RespacesPassOld',
      component: redirectToRespacesPass,
    },
    {
      path: '/contactus',
      name: 'ContactUs',
      component: ContactUs,
    },

    {
      path: '/blog',
      name: 'BlogPage',
      component: BlogPage,
      loadData: pageDataLoadingAPI.BlogPage.loadData,
    },
    {
      path: '/blog/:slug',
      name: 'BlogPost',
      component: SinglePost,
      loadData: pageDataLoadingAPI.SinglePostPage.loadData,
    },
    {
      path: '/careers',
      name: 'CareersPage',
      component: CareersPage,
    },
    {
      path: '/careers/:slug',
      name: 'JobPost',
      component: JobPost,
    },
    {
      path: '/company-account',
      name: 'CompanyAccount',
      component: CompanyAccountPage,
      loadData: pageDataLoadingAPI.CompanyAccountPage.loadData,
    },
    {
      path: '/subscription-confirmation',
      name: 'SubscriptionConfirmationPage',
      component: SubscriptionConfirmationPage,
      auth: true,
    },
    {
      path: '/subscription-management/:tab',
      name: 'SubscriptionManagementPage',
      auth: true,
      authPage: 'LoginPage',
      component: SubscriptionManagementPage,
      loadData: pageDataLoadingAPI.SubscriptionManagementPage.loadData,
    },
    {
      path: '/subscription-management/:tab/:action',
      name: 'SubscriptionManagementPageMySubscription',
      auth: true,
      authPage: 'LoginPage',
      component: SubscriptionManagementPage,
      loadData: pageDataLoadingAPI.SubscriptionManagementPage.loadData,
    },
    {
      path: '/invitation',
      name: 'InvitationPage',
      component: InvitationPage,
    },
    {
      path: '/companies-quote',
      name: 'QuotePage',
      component: RespacesPass,
    },
    {
      path: '/onboarding',
      name: 'OnboardingPage',
      component: OnboardingPage,
    },
    {
      path: '/onboarding/completed',
      name: 'OnboardingCompleted',
      component: FormSubmissionConfirmation,
    },
    {
      path: '/findOfficeMatch',
      name: 'FindOfficeMatch',
      component: FindOfficeMatchPage,
    },
    {
      path: '/partneronboarding',
      name: 'OnboardingPartnersPage',
      component: OnboardingPartnersPage,
    },
    {
      path: '/:id',
      name: 'SEOLandingPage',
      component: SEOLandingPage,
      loadData: pageDataLoadingAPI.SEOLandingPage.loadData,
    },
  ];
};

export default routeConfiguration;
