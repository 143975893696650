import React from 'react';
import { useIntl } from 'react-intl';
import {
  Page,
  LayoutSingleColumn,
  LayoutWrapperMain,
  LayoutWrapperTopbar,
  LayoutWrapperFooter,
  Footer,
  NamedLink,
} from '../../components';

import { TopbarContainer } from '../../containers';
import proffessionalImg from './Assets/professional.png';
import sublettingImg from './Assets/subletting.png';

import css from './PartnerChoice.module.scss';

const PartnerCoice = () => {
  const intl = useIntl();

  return (
    <Page
      title="Respaces partner Onboarding"
      description={intl.formatMessage({ id: 'PartnerChoice.schemaDescription' })}
      schema={{
        '@context': 'http://schema.org',
        '@type': 'WebPage',
        description: 'Choose what type of partner you are, and get started with Respaces',
        name: 'Respaces Partner type Page',
      }}
    >
      <LayoutSingleColumn>
        <LayoutWrapperTopbar>
          <TopbarContainer currentPage="Partner" />
        </LayoutWrapperTopbar>
        <LayoutWrapperMain className={css.layoutWrapperMain}>
          <div className={css.root}>
            <h1 className={css.title}>{intl.formatMessage({ id: 'PartnerChoice.title' })}</h1>
            <h2 className={css.subTitle}>{intl.formatMessage({ id: 'PartnerChoice.subTitle' })}</h2>
            <NamedLink name="PartnerSubletting" className={css.linkCard}>
              <img src={sublettingImg} alt="Subleasing" />
              <div className={css.cardText}>
                <h3>{intl.formatMessage({ id: 'PartnerChoice.subletting.title' })}</h3>
                <p>{intl.formatMessage({ id: 'PartnerChoice.subletting.description' })}</p>
              </div>
            </NamedLink>
            <NamedLink name="PartnerProffessional" className={css.linkCard}>
              <img src={proffessionalImg} alt="proffessional" />
              <div className={css.cardText}>
                <h3>{intl.formatMessage({ id: 'PartnerChoice.proffessional.title' })}</h3>
                <p>{intl.formatMessage({ id: 'PartnerChoice.proffessional.description' })}</p>
              </div>
            </NamedLink>
          </div>
        </LayoutWrapperMain>
        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </LayoutSingleColumn>
    </Page>
  );
};

export default PartnerCoice;
