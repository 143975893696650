import { findOptionsForSelectFilter } from '../util/search';
import { formatLocation } from '../util/data';
import moment from 'moment';

const SendEmail = ({
  pageData,
  user,
  filters = [],
  recipient,
  b2bCustomer = false,
  isAccepted = false,
  isDeclined = false,
  transactionURL = '',
  fetchCustomerData = false,
  locale = 'sv',
}) => {
  const CUSTOMER_IO_APP_API_KEY = process.env.REACT_APP_CUSTOMER_IO_APP_API_KEY;

  //Sends an email to the provider with all order details. Uses Customer IO API
  // and sends all data points as an object. This objects needs to be built and
  // the data needs to be calculated before sending.

  const sendProviderEmail = async (ensuredCurrentUser) => {
    const { bookingData, bookingDates, listing, locationName, coupon } = pageData;
    const { publicData } = listing.attributes || {};
    const isAcceptOrDeclineRequest = isAccepted || isDeclined;
    const isRequest = !!publicData.acceptBookingOnRequest;
    const { accessProvider, digitalKeyAccess } = publicData.digitalKey || {};
    const couponMultiplier = coupon && coupon.valid ? 1 - coupon.discount.percent_off / 100 : 1;
    const nameToShow = locationName || listing.attributes.title;
    // Make an objec with an array of all booked additional services
    const additionalServices = (bookingData, listingServices) => {
      let services = [];
      let key = 1;
      //For each additional service bought, find the service booked in the list
      // of services offered and store the data in constants
      if (bookingData.additionalServices) {
        bookingData.additionalServices.forEach((service) => {
          const name = listingServices.find((x) => x.serviceId === service).name;
          const unitPrice = (
            listingServices.find((x) => x.serviceId === service).price.amount * couponMultiplier
          )
            .toString()
            .slice(0, -2);
          const vatPercentage = listingServices.find((x) => x.serviceId === service).vatPercentage;
          const priceBeforeVAT = parseInt(unitPrice) / (1 + parseInt(vatPercentage) / 100);
          const VATamount = parseInt(unitPrice) - priceBeforeVAT;

          const multiply = listingServices.find((x) => x.serviceId === service).multiplySeats;

          // Check if the price should be multiplied with number of seats to make correct
          // number of units bought and later calculate the total price.
          // For daily listings (coworking) it is also multiplied with number of days (units)
          let unitsBooked = 1;
          if (multiply === true && publicData.bookingType === 'daily') {
            unitsBooked = bookingData.seats * bookingData.units;
          } else if (multiply === true) {
            unitsBooked = bookingData.seats;
          }

          const totalAddPrice = parseInt(unitPrice) * unitsBooked;
          totalPrice = totalPrice + totalAddPrice;
          totalBeforeVAT = totalBeforeVAT + priceBeforeVAT * unitsBooked;
          totalVAT = totalVAT + VATamount * unitsBooked;

          // Store the VAT value in the correct VAT class
          // TODO make this generic for all tax classes, not only the Swedish ones
          if (vatPercentage === '25') {
            VAT25 = VAT25 + VATamount * unitsBooked;
          } else if (vatPercentage === '12') {
            VAT12 = VAT12 + VATamount * unitsBooked;
          } else if (vatPercentage === '6') {
            VAT6 = VAT6 + VATamount * unitsBooked;
          }

          services.push({
            name: name,
            unitPrice: unitPrice,
            vatPercentage: vatPercentage,
            priceBeforeVat: priceBeforeVAT.toFixed(2),
            VatAmount: VATamount.toFixed(2),
            unitsBooked: unitsBooked,
            totalPrice: totalAddPrice,
          });
          key = key + 1;
        });
      }
      return services;
    };

    // Declare variables for total price to get the summary at end of booking reciept.
    // We need to add to these if additional services was bought.
    const getListingUnitsBought = () => {
      if (
        publicData.bookingType === 'daily' &&
        publicData.category !== 'private' &&
        publicData.category !== 'meeting'
      ) {
        return bookingData.seats * bookingData.units;
      } else {
        return bookingData.units;
      }
    };

    const listingUnitsBought = getListingUnitsBought();
    const listingVATPercentage = parseInt(publicData.vatPercentage) / 100;

    let totalPrice = !b2bCustomer
      ? ((listing.attributes.price.amount * listingUnitsBought) / 100) * couponMultiplier
      : 0; //if b2b-customer they do not pay for the space but an pay for addons.
    let totalBeforeVAT = !b2bCustomer ? totalPrice / (1 + listingVATPercentage) : 0;
    let totalVAT = totalPrice - totalBeforeVAT;
    let VAT25 = listingVATPercentage === 0.25 ? totalVAT : 0;
    let VAT12 = listingVATPercentage === 0.12 ? totalVAT : 0;
    let VAT6 = listingVATPercentage === 0.06 ? totalVAT : 0;
    const priceListingBeforeVAT =
      (listing.attributes.price.amount / (1 + listingVATPercentage) / 100) * couponMultiplier;
    const listingVAT =
      (listing.attributes.price.amount * couponMultiplier) / 100 - priceListingBeforeVAT;

    const services = additionalServices(bookingData, publicData.additionalServices);

    const todaysDate = new Date().toLocaleString().slice(0, 10);

    const currentUserName =
      ensuredCurrentUser.attributes.profile.firstName +
      ' ' +
      ensuredCurrentUser.attributes.profile.lastName;

    const currentUserEmail = ensuredCurrentUser.attributes.email;

    // Start and end date should show hours if booking is hourly, otherwise only dates
    // therefore we have a check for the booking type and removes different amounts of the string.
    let startDate = '';
    let endDate = '';

    if (bookingData.bookingType === 'hourly') {
      startDate = bookingDates.bookingStart.toLocaleString().substr(0, 17);
      endDate = bookingDates.bookingEnd.toLocaleString().substr(0, 17);
    } else {
      startDate = bookingDates.bookingStart.toLocaleString().substr(0, 10);
      let endDateModified = new Date(bookingDates.bookingEnd);
      endDateModified.setDate(bookingDates.bookingEnd.getDate() - 1);
      endDate = endDateModified.toLocaleString().substr(0, 10);
    }

    // Get the included services to include in the email if it is sent to the user.
    const getIncludedServices = () => {
      if (recipient === 'buyer' && publicData.offer) {
        let included = [];
        let key = 1;
        const offerOptions = findOptionsForSelectFilter('offer', filters);
        publicData.offer.forEach((offer) => {
          const offerName = offerOptions.find((x) => x.key === offer);
          if (offerName) {
            included.push(offerName.label);
            key++;
          }
        });
        return included;
      } else {
        return [];
      }
    };

    const includedServices = getIncludedServices();

    const getOpeningTimes = (listing, bookingDates) => {
      const { publicData } = listing.attributes;
      const { operationHours } = publicData;
      const { bookingStart, bookingEnd } = bookingDates;

      let openingDays = [];
      const dateStepper = new Date(bookingStart);
      while (dateStepper < bookingEnd) {
        openingDays.push(dateStepper.toString().slice(0, 3));
        dateStepper.setDate(dateStepper.getDate() + 1);
      }
      let openingTimes = [];
      openingDays.forEach((d, i) =>
        openingTimes.push(
          `${d}: ${operationHours[d.toLowerCase()].openTime} - ${
            operationHours[d.toLowerCase()].closeTime
          }`
        )
      );
      return openingTimes;
    };
    const openingHoursForCoworking =
      publicData.operationHours !== undefined ? getOpeningTimes(listing, bookingDates) : [];

    const listingAddress =
      publicData.location.address && formatLocation(publicData.location.address);

    const message_data = {
      listingName: nameToShow,
      customerName: currentUserName,
      customerEmail: currentUserEmail,
      seats: bookingData.seats,
      startDate: startDate,
      endDate: endDate,
      bookingDate: todaysDate,
      category: publicData.category,
      maxSeats: publicData.maxSeats,
      emailConfirmationContent: publicData.emailConfirmationContent
        ? publicData.emailConfirmationContent
        : '',
      address: listingAddress,
      digitalKeyAccess: digitalKeyAccess && digitalKeyAccess,
      accessProvider: accessProvider && accessProvider,
      buildingAccess: publicData.accessOption,
      accessDetails: publicData.accessDetails,
      accessDetailsFloorNumber: publicData.accessWorkspaceFloor,
      wifiName: publicData.wifiName,
      wifiPassword: publicData.wifiPassword,
      unitPrice: listing.attributes?.price.amount.toString().slice(0, -2),
      currency: listing.attributes?.price.currency,
      totalListingPrice:
        (listing.attributes?.price.amount * listingUnitsBought * couponMultiplier) / 100,
      listingUnitsBought: listingUnitsBought,
      priceBeforeVAT: priceListingBeforeVAT.toFixed(2),
      listingVAT: listingVAT.toFixed(2),
      totalBeforeVAT: totalBeforeVAT.toFixed(2),
      totalVAT: totalVAT.toFixed(2),
      totalPrice: totalPrice,
      VAT25: VAT25.toFixed(2),
      VAT12: VAT12.toFixed(2),
      VAT6: VAT6.toFixed(2),
      transactionURL: transactionURL,
      locale: locale,
      isB2b: b2bCustomer,
      services: services && services.length > 0 ? services : null,
      includedServices: includedServices && includedServices.length > 0 ? includedServices : null,
      openingHoursForCoworking:
        openingHoursForCoworking &&
        openingHoursForCoworking.length > 0 &&
        bookingData.bookingType === 'daily'
          ? openingHoursForCoworking
          : null,
    };

    // Check if the email should go to the buyer or to the seller and return the email adress
    const getRecipients = (recipient) => {
      if (recipient === 'provider') {
        // check if the provider have one or two emails and then add the existing ones.
        return publicData.bookingEmailAddress[1] && publicData.bookingEmailAddress[1] !== ''
          ? `${publicData.bookingEmailAddress[0]},${publicData.bookingEmailAddress[1]}`
          : publicData.bookingEmailAddress[0];
      } else if (recipient === 'buyer') {
        return user.attributes.email;
      }
    };

    const getTemplate = (recipient) => {
      if (isAcceptOrDeclineRequest && recipient === 'buyer') {
        return isDeclined
          ? 'MeetingRoomRequestDeclinedCustomer'
          : `MeetingRoomRequestAcceptedCustomer`;
      } else if (isAccepted && recipient === 'provider') {
        return `MeetingRoomRequestAcceptedProvider`;
      } else if (recipient === 'provider') {
        if (!isRequest) {
          return 'confirmationOnDemandProvider';
        } else return 'MeetingRoomRequestProvider';
      } else if (recipient === 'buyer' && b2bCustomer === true) {
        if (!isRequest) {
          return 'confirmationOnDemandCustomer';
        } else return 'MeetingRoomRequestCustomer';
      } else if (recipient === 'buyer') {
        if (!isRequest) {
          return 'confirmationOnDemandCustomer';
        } else return 'MeetingRoomRequestCustomer';
      }
    };

    const to = getRecipients(recipient);
    var myHeaders = new Headers();
    myHeaders.append('Content-Type', 'application/json');
    myHeaders.append('Accept', 'application/json');
    myHeaders.append('Authorization', `Bearer ${CUSTOMER_IO_APP_API_KEY}`);

    const raw = JSON.stringify({
      identifiers: {
        email: to.split(',')[0],
      },
      to: to,
      transactional_message_id: getTemplate(recipient),
      language: locale,
      message_data: message_data,
    });

    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow',
    };

    fetch('https://api-eu.customer.io/v1/send/email', requestOptions)
      .then((response) => response.text())
      .then((result) => console.log(result))
      .catch((error) => console.log('error', error));
    return true;
  };

  const result = sendProviderEmail(user);
  return result;
};

export default SendEmail;
