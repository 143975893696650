import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from '../../util/reactIntl';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import { ensureListing } from '../../util/data';

import css from './SearchMapPriceLabel.module.scss';

class SearchMapPriceLabel extends Component {
  shouldComponentUpdate(nextProps) {
    const currentListing = ensureListing(this.props.listing);
    const nextListing = ensureListing(nextProps.listing);
    const isSameListing = currentListing.id.uuid === nextListing.id.uuid;
    const hasSamePrice = currentListing.attributes.price === nextListing.attributes.price;
    const hasSameActiveStatus = this.props.isActive === nextProps.isActive;
    const hasSameRefreshToken =
      this.props.mapComponentRefreshToken === nextProps.mapComponentRefreshToken;

    return !(isSameListing && hasSamePrice && hasSameActiveStatus && hasSameRefreshToken);
  }

  render() {
    const { className, rootClassName, listing, onListingClicked, isActive } = this.props;
    const currentListing = ensureListing(listing);

    const classes = classNames(rootClassName || css.root, className);
    const priceLabelClasses = classNames(css.priceLabel, { [css.priceLabelActive]: isActive });

    return (
      <button className={classes} onClick={() => onListingClicked(currentListing)}>
        <div className={css.caretShadow} />
        <svg
          className={priceLabelClasses}
          width="23"
          height="32"
          viewBox="0 0 23 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M0 11.2C0 5.008 5.008 0 11.2 0C17.392 0 22.4 5.008 22.4 11.2C22.4 19.6 11.2 32 11.2 32C11.2 32 0 19.6 0 11.2ZM7.20089 11.2001C7.20089 13.4093 8.99175 15.2001 11.2009 15.2001C13.41 15.2001 15.2009 13.4093 15.2009 11.2001C15.2009 8.99099 13.41 7.20013 11.2009 7.20013C8.99175 7.20013 7.20089 8.99099 7.20089 11.2001Z"
            fill="#222222"
          />
        </svg>
      </button>
    );
  }
}

SearchMapPriceLabel.defaultProps = {
  className: null,
  rootClassName: null,
};

const { func, string } = PropTypes;

SearchMapPriceLabel.propTypes = {
  className: string,
  rootClassName: string,
  listing: propTypes.listing.isRequired,
  onListingClicked: func.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

export default injectIntl(SearchMapPriceLabel);
