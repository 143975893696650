import classNames from 'classnames';
import get from 'lodash/get';
import { any, bool, number, oneOf, string } from 'prop-types';
import { useState } from 'react';
import { Form as FinalForm } from 'react-final-form';
import { useIntl } from 'react-intl';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import {
  EditSubscriptionSuccessModal,
  FieldNumberInput,
  FieldRadioButton,
  FieldSelect,
  Form,
  NamedLink,
  PrimaryButton,
  ReactivateSubscriptionSuccessModal,
} from '../../components';
import FieldSingleCalendarInput from '../../components_new/FieldSingleCalendarInput/FieldSingleCalendarInput';
import BottomButtons from '../../containers/SubscriptionManagementPage/MySubscriptionTab/BottomButtons';
import CurrentPlanBox from '../../containers/SubscriptionManagementPage/MySubscriptionTab/CurrentPlanBox';
import {
  editSubscription,
  reactivateSubscription,
} from '../../containers/SubscriptionManagementPage/SubscriptionManagementPage.duck';
import PackageModel from '../../models/PackageModel';
import { updateHubspotPipeline } from '../../util/api';
import { formatMoneyWithSpace, SUBSCRIPTION_VAT } from '../../util/currency';
import { useBoolean } from '../../util/hooks';
import {
  MY_SUBSCRIPTION,
  SUBSCRIPTION_OPTIONS,
  SUBSCRIPTION_STATUS_ACTIVE,
  SUBSCRIPTION_STATUS_CANCELLED,
  SUBSCRIPTION_STATUS_PAUSED,
} from '../../util/types';
import { composeValidators, minimumValue, required } from '../../util/validators';
import css from './EditSubscriptionForm.module.scss';

const IMMEDIATELY = 'immediately';
const START_FROM_NEXT_RENEWAL = 'start_from_next_renewal';
const CUSTOM_DATE = 'custom_date';

const EditSubscriptionForm = (props) => {
  const { setReactivateModalOpen, selectedPackage, selectedCreditAmount = 0 } = props;
  const intl = useIntl();
  const dispatch = useDispatch();
  const [date, setDate] = useState(null);
  const [isSuccessModalOpen, setSuccessModalOpen] = useBoolean();
  const [isReactivateSuccessModalOpen, setReactivateSuccessModalOpen] = useBoolean();
  const isInBindingPeriod = selectedPackage && selectedPackage !== SUBSCRIPTION_OPTIONS[0];
  const companyAccount = useSelector((state) => state.user.companyAccount, shallowEqual);
  const editSubscriptionInProgress = useSelector(
    (state) => state.SubscriptionManagementPage.editSubscriptionInProgress,
    shallowEqual
  );

  const editSubscriptionError = useSelector(
    (state) => state.SubscriptionManagementPage.editSubscriptionError,
    shallowEqual
  );

  const actionSubscriptionInProgress = useSelector(
    (state) => state.SubscriptionManagementPage.actionSubscriptionInProgress
  );
  const actionSubscriptionError = useSelector(
    (state) => state.SubscriptionManagementPage.actionSubscriptionError,
    shallowEqual
  );
  const onEditSubscription = async ({
    totalCredits,
    newMonthPrice,
    startOption,
    selectedPackage,
  }) => dispatch(editSubscription({ totalCredits, newMonthPrice, startOption, selectedPackage }));
  const onReactivateSubscription = async ({
    reactivateDate,
    totalCredits,
    newMonthPrice,
    selectedPackage,
  }) =>
    dispatch(
      reactivateSubscription({ reactivateDate, totalCredits, newMonthPrice, selectedPackage })
    );
  const { monthUnitPrice = 0, totalCredits = 0 } = get(
    companyAccount,
    'attributes.profile.metadata',
    {}
  );
  const initialValues = {
    creditsNumber: Math.max(selectedCreditAmount, totalCredits), // todo - add in value from redux as optional from for companies page
    startOption: IMMEDIATELY,
  };

  const handleEditSubscriptionSubmit = async (values) => {
    const { creditsNumber = 0, startOption, bindingPeriod = 1 } = values;
    const newSelectedPackage =
      selectedPackage && isInBindingPeriod ? selectedPackage : `months_${bindingPeriod}`;
    const newPackage = PackageModel.currentPackage({ selectedPackage: newSelectedPackage });
    const newMonthPrice =
      newPackage && creditsNumber
        ? (newPackage.pricePerCredit * creditsNumber * 100 * (1 + SUBSCRIPTION_VAT)).toFixed(0)
        : 0;
    try {
      await onEditSubscription({
        newMonthPrice,
        startOption,
        totalCredits: creditsNumber,
        selectedPackage: newSelectedPackage,
      });
      const hubspotParams = {
        user: companyAccount,
        amount: newMonthPrice,
        credits: creditsNumber,
      };
      updateHubspotPipeline(hubspotParams);
      setSuccessModalOpen.on();
    } catch (error) {}
  };

  const handleReactivateSubscriptionSubmit = async (values) => {
    const { startOption, creditsNumber = 0, bindingPeriod = 1 } = values;
    const newSelectedPackage =
      selectedPackage && isInBindingPeriod ? selectedPackage : `months_${bindingPeriod}`;
    const newPackage = PackageModel.currentPackage({ selectedPackage: newSelectedPackage });
    const newMonthPrice =
      newPackage && creditsNumber
        ? (newPackage.pricePerCredit * creditsNumber * (1 + SUBSCRIPTION_VAT)).toFixed(0)
        : 0;
    try {
      await onReactivateSubscription({
        ...(startOption === IMMEDIATELY
          ? {}
          : { reactivateDate: Math.floor(new Date(date).getTime()) }),
        totalCredits: creditsNumber,
        newMonthPrice,
        selectedPackage: newSelectedPackage,
      });
      setReactivateSuccessModalOpen.on();
    } catch (error) {}
  };

  const handleSubmit = async (values) => {
    switch (props.subscriptionStatus) {
      case SUBSCRIPTION_STATUS_ACTIVE:
        await handleEditSubscriptionSubmit(values);
        break;
      case SUBSCRIPTION_STATUS_CANCELLED:
        await handleReactivateSubscriptionSubmit(values);
        break;
      default:
        break;
    }
  };
  return (
    <FinalForm
      {...props}
      onSubmit={handleSubmit}
      initialValues={{ ...initialValues }}
      render={(formRenderProps) => {
        const {
          handleSubmit,
          values,
          invalid,
          credits,
          renewalDate,
          creditsLeft,
          setCancelReasonModalOpen,
          setPauseModalOpen,
          subscriptionStatus,
          form,
          isResumeSuccess,
          setResumeSuccess,
          pristine,
        } = formRenderProps;
        const isSubscriptionActive = subscriptionStatus === SUBSCRIPTION_STATUS_ACTIVE;
        const isSubscriptionPaused = subscriptionStatus === SUBSCRIPTION_STATUS_PAUSED;
        const isSubscriptionCancelled = subscriptionStatus === SUBSCRIPTION_STATUS_CANCELLED;
        const { creditsNumber = 0, startOption, bindingPeriod = 1 } = values;
        const newPackage = PackageModel.currentPackage({
          selectedPackage:
            selectedPackage && isInBindingPeriod ? selectedPackage : `months_${bindingPeriod}`,
        });
        const totalPrice =
          newPackage && creditsNumber
            ? parseFloat((newPackage.pricePerCredit * creditsNumber).toFixed(2))
            : 0;
        const submitDisabled =
          invalid ||
          (monthUnitPrice < totalPrice && !startOption) ||
          (creditsNumber < credits && isInBindingPeriod) ||
          editSubscriptionInProgress ||
          actionSubscriptionInProgress ||
          isSubscriptionPaused ||
          (pristine && !isSubscriptionCancelled);

        const rightBtnText = isSubscriptionActive
          ? intl.formatMessage({ id: 'MySubscriptionTab.confirmText' })
          : intl.formatMessage({ id: 'MySubscriptionTab.activateText' });

        const submitTextDesktop = isSubscriptionActive
          ? intl.formatMessage({ id: 'MySubscriptionTab.submitText' })
          : intl.formatMessage({ id: 'MySubscriptionTab.activateText' });

        const showBindingPeriod =
          (selectedPackage && selectedPackage === SUBSCRIPTION_OPTIONS[0]) || !selectedPackage;

        const startOptionMaybe =
          monthUnitPrice < totalPrice * (1 + SUBSCRIPTION_VAT) ||
          (!isInBindingPeriod &&
            monthUnitPrice >= totalPrice * (1 + SUBSCRIPTION_VAT) &&
            !pristine) ||
          (!isInBindingPeriod && bindingPeriod !== 1) ||
          isSubscriptionCancelled;
        const submitInProgress = editSubscriptionInProgress || actionSubscriptionInProgress;

        const pricingCards = PackageModel.LIST.PRICING.map((p) => ({
          ...p,
        }));
        const selectOptions =
          pricingCards &&
          pricingCards.map((option) => (
            <option key={`packageOption${option.value}Months`} value={option.value}>
              {intl.formatMessage(
                { id: `RespacesPass.pricing.card.option${option.value}` },
                { benefit: option.benefit }
              )}
            </option>
          ));

        return isSubscriptionPaused ? (
          <div className={css.formSection}>
            <h2 className={css.formTitle}>
              {intl.formatMessage({ id: 'EditSubscriptionForm.formTitle' })}
            </h2>
            <p className={css.formDesc}>
              {intl.formatMessage({ id: 'EditSubscriptionForm.formDescription' })}
            </p>
            <div className={css.mobileDisplay}>
              <CurrentPlanBox
                intl={intl}
                credits={credits}
                renewalDate={renewalDate}
                creditsLeft={creditsLeft}
                setCancelReasonModalOpen={setCancelReasonModalOpen}
                setPauseModalOpen={setPauseModalOpen}
                subscriptionStatus={subscriptionStatus}
                setReactivateModalOpen={setReactivateModalOpen}
              />
            </div>
          </div>
        ) : (
          <Form onSubmit={handleSubmit}>
            <div className={css.formSection}>
              <h2 className={css.formTitle}>
                {intl.formatMessage({ id: 'EditSubscriptionForm.formTitle' })}
              </h2>
              <p className={css.formDesc}>
                {intl.formatMessage({ id: 'EditSubscriptionForm.formDescription' })}
              </p>
              <div className={css.mobileDisplay}>
                <CurrentPlanBox
                  intl={intl}
                  credits={credits}
                  renewalDate={renewalDate}
                  creditsLeft={creditsLeft}
                  setCancelReasonModalOpen={setCancelReasonModalOpen}
                  setPauseModalOpen={setPauseModalOpen}
                  subscriptionStatus={subscriptionStatus}
                  setReactivateModalOpen={setReactivateModalOpen}
                />
              </div>
            </div>

            <div className={css.formSection}>
              <FieldNumberInput
                id="creditsNumber"
                name="creditsNumber"
                className={css.numberInputWrapper}
                label={intl.formatMessage({ id: 'EditSubscriptionForm.creditsLabel' })}
                labelClassName={css.formSectionTitle}
                inline
                validate={composeValidators(
                  minimumValue(
                    intl.formatMessage({ id: 'EditSubscriptionForm.minCreditsRequired' }),
                    isInBindingPeriod ? credits + 1 : 1
                  ),
                  required(intl.formatMessage({ id: 'EditSubscriptionForm.creditsRequired' }))
                )}
              />
            </div>
            {showBindingPeriod && (
              <div className={classNames(css.formSection, css.bindingPeriod)}>
                <label htmlFor="bindingPeriod">
                  {intl.formatMessage({ id: 'RespacesPass.pricing.card.bindingLabel' })}
                </label>
                <FieldSelect id="bindingPeriod" name="bindingPeriod">
                  {selectOptions}
                </FieldSelect>
              </div>
            )}

            {startOptionMaybe && (
              <div className={css.formSection}>
                <div className={css.formSectionTitle}>
                  {intl.formatMessage({ id: 'EditSubscriptionForm.startOptionLabel' })}
                </div>
                <div className={css.formSectionDesc}>
                  {intl.formatMessage({ id: 'EditSubscriptionForm.startOptionDesc' })}
                </div>
                <FieldRadioButton
                  id="startOptionImmediately"
                  name="startOption"
                  value={IMMEDIATELY}
                  label={intl.formatMessage({ id: 'EditSubscriptionForm.immediately' })}
                  labelClassName={css.labelWrapper}
                  textClassName={css.labelText}
                  svgClassName={css.radioIcon}
                />
                {isSubscriptionActive && (
                  <FieldRadioButton
                    id="startOptionNextRenewal"
                    name="startOption"
                    value={START_FROM_NEXT_RENEWAL}
                    label={intl.formatMessage(
                      { id: 'EditSubscriptionForm.nextRenewal' },
                      {
                        span: (msg) => <span className={css.grayText}>{msg}</span>,
                        renewalDate,
                      }
                    )}
                    labelClassName={css.labelWrapper}
                    textClassName={css.labelText}
                    svgClassName={css.radioIcon}
                    showAsRequired={pristine}
                  />
                )}
                {isSubscriptionCancelled && (
                  <div className={css.customDateWrapper}>
                    <FieldRadioButton
                      id="startOptionCustomDate"
                      name="startOption"
                      value={CUSTOM_DATE}
                      label={intl.formatMessage({ id: 'EditSubscriptionForm.customDate' })}
                      labelClassName={css.labelWrapper}
                      textClassName={css.labelText}
                      svgClassName={css.radioIcon}
                    />
                    <FieldSingleCalendarInput
                      id="customDate"
                      name="customDate"
                      date={date}
                      setDate={setDate}
                      values={values}
                      form={form}
                      className={css.customDateCalendar}
                      inputClassname={css.inputField}
                    />
                  </div>
                )}
              </div>
            )}
            <div className={css.summaryWrapper}>
              <div className={css.priceRow}>
                <div className={css.titleVAT}>
                  {intl.formatMessage({ id: 'AddOneTimeCreditsForm.subtotalTitle' })}
                </div>
                <div className={css.pricePerMonth}>
                  {intl.formatMessage(
                    { id: 'AddOneTimeCreditsForm.price' },
                    {
                      span: (msg) => <span className={css.price}>{msg}</span>,
                      totalPrice: formatMoneyWithSpace(totalPrice),
                    }
                  )}
                </div>
              </div>
              <div className={css.priceRow}>
                <div className={css.title}>
                  {intl.formatMessage({ id: 'AddOneTimeCreditsForm.totalPriceTitle' })}
                </div>
                <div className={css.pricePerMonth}>
                  {intl.formatMessage(
                    { id: 'AddOneTimeCreditsForm.price' },
                    {
                      span: (msg) => <span className={css.price}>{msg}</span>,
                      totalPrice: formatMoneyWithSpace(
                        totalPrice * (1 + SUBSCRIPTION_VAT).toFixed(2)
                      ),
                    }
                  )}
                  <div className={css.vat}>
                    {intl.formatMessage({ id: 'AddOneTimeCreditsForm.vat' })}
                  </div>
                </div>
              </div>
            </div>
            {(editSubscriptionError || actionSubscriptionError) && (
              <div className={css.error}>
                {intl.formatMessage({ id: 'EditSubscriptionForm.editSubscriptionError' })}
              </div>
            )}
            <div className={css.submitDesktopWrapper}>
              <NamedLink
                name="SubscriptionManagementPage"
                params={{ tab: MY_SUBSCRIPTION, action: null }}
                className={css.discardBtn}
              >
                {intl.formatMessage({ id: 'MySubscriptionTab.discard' })}
              </NamedLink>
              <PrimaryButton
                type="submit"
                className={classNames(css.confirmBtn, {
                  [css.activate]: isSubscriptionCancelled,
                })}
                inProgress={submitInProgress}
                disabled={submitDisabled}
              >
                {submitTextDesktop}
              </PrimaryButton>
            </div>
            <BottomButtons
              leftBtnText={intl.formatMessage({ id: 'MySubscriptionTab.discard' })}
              rightBtnText={rightBtnText}
              rightBtnType="submit"
              rightBtnInProgress={submitInProgress}
              rightBtnDisabled={submitDisabled}
            />
            <EditSubscriptionSuccessModal
              isOpen={isSuccessModalOpen}
              onClose={setSuccessModalOpen.off}
              creditsNumber={creditsNumber}
              totalPrice={totalPrice}
            />
            <ReactivateSubscriptionSuccessModal
              isOpen={isResumeSuccess ? isResumeSuccess : isReactivateSuccessModalOpen}
              onClose={isResumeSuccess ? setResumeSuccess.off : setReactivateSuccessModalOpen.off}
              creditsNumber={creditsNumber}
              totalPrice={totalPrice}
              renewalDate={renewalDate}
              isResumeSuccess={isResumeSuccess}
            />
          </Form>
        );
      }}
    />
  );
};

EditSubscriptionForm.defaultProps = {};

EditSubscriptionForm.propTypes = {
  credits: number.isRequired,
  renewalDate: string.isRequired,
  creditsLeft: number.isRequired,
  setCancelReasonModalOpen: any,
  setPauseModalOpen: any,
  subscriptionStatus: oneOf([
    SUBSCRIPTION_STATUS_ACTIVE,
    SUBSCRIPTION_STATUS_CANCELLED,
    SUBSCRIPTION_STATUS_PAUSED,
  ]).isRequired,
  isReactivateModalOpen: bool.isRequired,
  setReactivateModalOpen: any.isRequired,
};
export default EditSubscriptionForm;
