// Add here the translations of the country names using key "<language_code>: 'transalation'" e.g. fi: 'Afganistan'
// prettier-ignore
const countryCodes = [
  { code: 'AF', en: 'Afghanistan' },
  { code: 'AX', en: 'Åland Islands' },
  { code: 'AL', en: 'Albania' },
  { code: 'DZ', en: 'Algeria' },
  { code: 'AS', en: 'American Samoa' },
  { code: 'AD', en: 'Andorra' },
  { code: 'AO', en: 'Angola' },
  { code: 'AI', en: 'Anguilla' },
  { code: 'AQ', en: 'Antarctica' },
  { code: 'AG', en: 'Antigua and Barbuda' },
  { code: 'AR', en: 'Argentina' },
  { code: 'AM', en: 'Armenia' },
  { code: 'AW', en: 'Aruba' },
  { code: 'AU', en: 'Australia' },
  { code: 'AT', en: 'Austria' },
  { code: 'AZ', en: 'Azerbaijan' },
  { code: 'BS', en: 'Bahamas' },
  { code: 'BH', en: 'Bahrain' },
  { code: 'BD', en: 'Bangladesh' },
  { code: 'BB', en: 'Barbados' },
  { code: 'BY', en: 'Belarus' },
  { code: 'BE', en: 'Belgium' },
  { code: 'BZ', en: 'Belize' },
  { code: 'BJ', en: 'Benin' },
  { code: 'BM', en: 'Bermuda' },
  { code: 'BT', en: 'Bhutan' },
  { code: 'BO', en: 'Bolivia' },
  { code: 'BQ', en: 'Bonaire, Sint Eustatius and Saba' },
  { code: 'BA', en: 'Bosnia and Herzegovina' },
  { code: 'BW', en: 'Botswana' },
  { code: 'BV', en: 'Bouvet Island' },
  { code: 'BR', en: 'Brazil' },
  { code: 'IO', en: 'British Indian Ocean Territory' },
  { code: 'BN', en: 'Brunei Darussalam' },
  { code: 'BG', en: 'Bulgaria' },
  { code: 'BF', en: 'Burkina Faso' },
  { code: 'BI', en: 'Burundi' },
  { code: 'KH', en: 'Cambodia' },
  { code: 'CM', en: 'Cameroon' },
  { code: 'CA', en: 'Canada' },
  { code: 'CV', en: 'Cape Verde' },
  { code: 'KY', en: 'Cayman Islands' },
  { code: 'CF', en: 'Central African Republic' },
  { code: 'TD', en: 'Chad' },
  { code: 'CL', en: 'Chile' },
  { code: 'CN', en: 'China' },
  { code: 'CX', en: 'Christmas Island' },
  { code: 'CC', en: 'Cocos (Keeling) Islands' },
  { code: 'CO', en: 'Colombia' },
  { code: 'KM', en: 'Comoros' },
  { code: 'CG', en: 'Congo' },
  { code: 'CD', en: 'Congo, the Democratic Republic of the' },
  { code: 'CK', en: 'Cook Islands' },
  { code: 'CR', en: 'Costa Rica' },
  { code: 'CI', en: 'Côte d\'Ivoire' },
  { code: 'HR', en: 'Croatia' },
  { code: 'CU', en: 'Cuba' },
  { code: 'CW', en: 'Curaçao' },
  { code: 'CY', en: 'Cyprus' },
  { code: 'CZ', en: 'Czech Republic' },
  { code: 'DK', en: 'Denmark' },
  { code: 'DJ', en: 'Djibouti' },
  { code: 'DM', en: 'Dominica' },
  { code: 'DO', en: 'Dominican Republic' },
  { code: 'EC', en: 'Ecuador' },
  { code: 'EG', en: 'Egypt' },
  { code: 'SV', en: 'El Salvador' },
  { code: 'GQ', en: 'Equatorial Guinea' },
  { code: 'ER', en: 'Eritrea' },
  { code: 'EE', en: 'Estonia' },
  { code: 'ET', en: 'Ethiopia' },
  { code: 'FK', en: 'Falkland Islands (Malvinas)' },
  { code: 'FO', en: 'Faroe Islands' },
  { code: 'FJ', en: 'Fiji' },
  { code: 'FI', en: 'Finland' },
  { code: 'FR', en: 'France' },
  { code: 'GF', en: 'French Guiana' },
  { code: 'PF', en: 'French Polynesia' },
  { code: 'TF', en: 'French Southern Territories' },
  { code: 'GA', en: 'Gabon' },
  { code: 'GM', en: 'Gambia' },
  { code: 'GE', en: 'Georgia' },
  { code: 'DE', en: 'Germany' },
  { code: 'GH', en: 'Ghana' },
  { code: 'GI', en: 'Gibraltar' },
  { code: 'GR', en: 'Greece' },
  { code: 'GL', en: 'Greenland' },
  { code: 'GD', en: 'Grenada' },
  { code: 'GP', en: 'Guadeloupe' },
  { code: 'GU', en: 'Guam' },
  { code: 'GT', en: 'Guatemala' },
  { code: 'GG', en: 'Guernsey' },
  { code: 'GN', en: 'Guinea' },
  { code: 'GW', en: 'Guinea-Bissau' },
  { code: 'GY', en: 'Guyana' },
  { code: 'HT', en: 'Haiti' },
  { code: 'HM', en: 'Heard Island and McDonald Islands' },
  { code: 'VA', en: 'Holy See (Vatican City State)' },
  { code: 'HN', en: 'Honduras' },
  { code: 'HK', en: 'Hong Kong' },
  { code: 'HU', en: 'Hungary' },
  { code: 'IS', en: 'Iceland' },
  { code: 'IN', en: 'India' },
  { code: 'ID', en: 'Indonesia' },
  { code: 'IR', en: 'Iran, Islamic Republic of' },
  { code: 'IQ', en: 'Iraq' },
  { code: 'IE', en: 'Ireland' },
  { code: 'IM', en: 'Isle of Man' },
  { code: 'IL', en: 'Israel' },
  { code: 'IT', en: 'Italy' },
  { code: 'JM', en: 'Jamaica' },
  { code: 'JP', en: 'Japan' },
  { code: 'JE', en: 'Jersey' },
  { code: 'JO', en: 'Jordan' },
  { code: 'KZ', en: 'Kazakhstan' },
  { code: 'KE', en: 'Kenya' },
  { code: 'KI', en: 'Kiribati' },
  { code: 'KP', en: 'Korea, Democratic People\'s Republic of' },
  { code: 'KR', en: 'Korea, Republic of' },
  { code: 'KW', en: 'Kuwait' },
  { code: 'KG', en: 'Kyrgyzstan' },
  { code: 'LA', en: 'Laos' },
  { code: 'LV', en: 'Latvia' },
  { code: 'LB', en: 'Lebanon' },
  { code: 'LS', en: 'Lesotho' },
  { code: 'LR', en: 'Liberia' },
  { code: 'LY', en: 'Libya' },
  { code: 'LI', en: 'Liechtenstein' },
  { code: 'LT', en: 'Lithuania' },
  { code: 'LU', en: 'Luxembourg' },
  { code: 'MO', en: 'Macao' },
  { code: 'MK', en: 'North Macedonia' },
  { code: 'MG', en: 'Madagascar' },
  { code: 'MW', en: 'Malawi' },
  { code: 'MY', en: 'Malaysia' },
  { code: 'MV', en: 'Maldives' },
  { code: 'ML', en: 'Mali' },
  { code: 'MT', en: 'Malta' },
  { code: 'MH', en: 'Marshall Islands' },
  { code: 'MQ', en: 'Martinique' },
  { code: 'MR', en: 'Mauritania' },
  { code: 'MU', en: 'Mauritius' },
  { code: 'YT', en: 'Mayotte' },
  { code: 'MX', en: 'Mexico' },
  { code: 'FM', en: 'Micronesia, Federated States of' },
  { code: 'MD', en: 'Moldova' },
  { code: 'MC', en: 'Monaco' },
  { code: 'MN', en: 'Mongolia' },
  { code: 'ME', en: 'Montenegro' },
  { code: 'MS', en: 'Montserrat' },
  { code: 'MA', en: 'Morocco' },
  { code: 'MZ', en: 'Mozambique' },
  { code: 'MM', en: 'Myanmar' },
  { code: 'NA', en: 'Namibia' },
  { code: 'NR', en: 'Nauru' },
  { code: 'NP', en: 'Nepal' },
  { code: 'NL', en: 'Netherlands' },
  { code: 'NC', en: 'New Caledonia' },
  { code: 'NZ', en: 'New Zealand' },
  { code: 'NI', en: 'Nicaragua' },
  { code: 'NE', en: 'Niger' },
  { code: 'NG', en: 'Nigeria' },
  { code: 'NU', en: 'Niue' },
  { code: 'NF', en: 'Norfolk Island' },
  { code: 'MP', en: 'Northern Mariana Islands' },
  { code: 'NO', en: 'Norway' },
  { code: 'OM', en: 'Oman' },
  { code: 'PK', en: 'Pakistan' },
  { code: 'PW', en: 'Palau' },
  { code: 'PS', en: 'Palestine, State of' },
  { code: 'PA', en: 'Panama' },
  { code: 'PG', en: 'Papua New Guinea' },
  { code: 'PY', en: 'Paraguay' },
  { code: 'PE', en: 'Peru' },
  { code: 'PH', en: 'Philippines' },
  { code: 'PN', en: 'Pitcairn' },
  { code: 'PL', en: 'Poland' },
  { code: 'PT', en: 'Portugal' },
  { code: 'PR', en: 'Puerto Rico' },
  { code: 'QA', en: 'Qatar' },
  { code: 'RE', en: 'Réunion' },
  { code: 'RO', en: 'Romania' },
  { code: 'RU', en: 'Russian Federation' },
  { code: 'RW', en: 'Rwanda' },
  { code: 'BL', en: 'Saint Barthélemy' },
  { code: 'SH', en: 'Saint Helena, Ascension and Tristan da Cunha' },
  { code: 'KN', en: 'Saint Kitts and Nevis' },
  { code: 'LC', en: 'Saint Lucia' },
  { code: 'MF', en: 'Saint Martin (French part)' },
  { code: 'PM', en: 'Saint Pierre and Miquelon' },
  { code: 'VC', en: 'Saint Vincent and the Grenadines' },
  { code: 'WS', en: 'Samoa' },
  { code: 'SM', en: 'San Marino' },
  { code: 'ST', en: 'Sao Tome and Principe' },
  { code: 'SA', en: 'Saudi Arabia' },
  { code: 'SN', en: 'Senegal' },
  { code: 'RS', en: 'Serbia' },
  { code: 'SC', en: 'Seychelles' },
  { code: 'SL', en: 'Sierra Leone' },
  { code: 'SG', en: 'Singapore' },
  { code: 'SX', en: 'Sint Maarten (Dutch part)' },
  { code: 'SK', en: 'Slovakia' },
  { code: 'SI', en: 'Slovenia' },
  { code: 'SB', en: 'Solomon Islands' },
  { code: 'SO', en: 'Somalia' },
  { code: 'ZA', en: 'South Africa' },
  { code: 'GS', en: 'South Georgia and the South Sandwich Islands' },
  { code: 'SS', en: 'South Sudan' },
  { code: 'ES', en: 'Spain' },
  { code: 'LK', en: 'Sri Lanka' },
  { code: 'SD', en: 'Sudan' },
  { code: 'SR', en: 'Suriname' },
  { code: 'SJ', en: 'Svalbard and Jan Mayen' },
  { code: 'SZ', en: 'Swaziland' },
  { code: 'SE', en: 'Sweden' },
  { code: 'CH', en: 'Switzerland' },
  { code: 'SY', en: 'Syrian Arab Republic' },
  { code: 'TW', en: 'Taiwan', },
  { code: 'TJ', en: 'Tajikistan' },
  { code: 'TZ', en: 'Tanzania' },
  { code: 'TH', en: 'Thailand' },
  { code: 'TL', en: 'Timor-Leste' },
  { code: 'TG', en: 'Togo' },
  { code: 'TK', en: 'Tokelau' },
  { code: 'TO', en: 'Tonga' },
  { code: 'TT', en: 'Trinidad and Tobago' },
  { code: 'TN', en: 'Tunisia' },
  { code: 'TR', en: 'Turkey' },
  { code: 'TM', en: 'Turkmenistan' },
  { code: 'TC', en: 'Turks and Caicos Islands' },
  { code: 'TV', en: 'Tuvalu' },
  { code: 'UG', en: 'Uganda' },
  { code: 'UA', en: 'Ukraine' },
  { code: 'AE', en: 'United Arab Emirates' },
  { code: 'GB', en: 'United Kingdom' },
  { code: 'US', en: 'United States' },
  { code: 'UM', en: 'United States Minor Outlying Islands' },
  { code: 'UY', en: 'Uruguay' },
  { code: 'UZ', en: 'Uzbekistan' },
  { code: 'VU', en: 'Vanuatu' },
  { code: 'VE', en: 'Venezuela' },
  { code: 'VN', en: 'Vietnam' },
  { code: 'VG', en: 'Virgin Islands, British' },
  { code: 'VI', en: 'Virgin Islands, U.S.' },
  { code: 'WF', en: 'Wallis and Futuna' },
  { code: 'EH', en: 'Western Sahara' },
  { code: 'YE', en: 'Yemen' },
  { code: 'ZM', en: 'Zambia' },
  { code: 'ZW', en: 'Zimbabwe' },
];

const getCountryCodes = (lang) => {
  // Add the lnew locale here so that the correct translations will be returned.
  // If locale is unknown or the translation is missing, this will default to english coutnry name.
  const codes = countryCodes.map((c) => {
    const countryName = c[lang] ? c[lang] : c['en'];
    const counryCode = c.code;

    return { code: counryCode, name: countryName };
  });
  return codes;
};

export default getCountryCodes;
