import React from 'react';

import css from './LocationAutocompleteInputNew.module.scss';

const IconCurrentLocation = () => (
  <div className={css.currentLocationIcon}>
    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6 8.25C6.59674 8.25 7.16903 8.01295 7.59099 7.59099C8.01295 7.16903 8.25 6.59674 8.25 6C8.25 5.40326 8.01295 4.83097 7.59099 4.40901C7.16903 3.98705 6.59674 3.75 6 3.75C5.40326 3.75 4.83097 3.98705 4.40901 4.40901C3.98705 4.83097 3.75 5.40326 3.75 6C3.75 6.59674 3.98705 7.16903 4.40901 7.59099C4.83097 8.01295 5.40326 8.25 6 8.25ZM6.375 0.375C6.375 0.275544 6.33549 0.180161 6.26517 0.109835C6.19484 0.0395089 6.09946 0 6 0C5.90054 0 5.80516 0.0395089 5.73483 0.109835C5.66451 0.180161 5.625 0.275544 5.625 0.375V1.515C4.56464 1.60368 3.56999 2.06518 2.81758 2.81758C2.06518 3.56999 1.60368 4.56464 1.515 5.625H0.375C0.275544 5.625 0.180161 5.66451 0.109835 5.73483C0.0395089 5.80516 0 5.90054 0 6C0 6.09946 0.0395089 6.19484 0.109835 6.26517C0.180161 6.33549 0.275544 6.375 0.375 6.375H1.515C1.60368 7.43536 2.06518 8.43001 2.81758 9.18242C3.56999 9.93482 4.56464 10.3963 5.625 10.485V11.625C5.625 11.7245 5.66451 11.8198 5.73483 11.8902C5.80516 11.9605 5.90054 12 6 12C6.09946 12 6.19484 11.9605 6.26517 11.8902C6.33549 11.8198 6.375 11.7245 6.375 11.625V10.485C7.43531 10.3962 8.42988 9.93463 9.18225 9.18225C9.93463 8.42988 10.3962 7.43531 10.485 6.375H11.625C11.7245 6.375 11.8198 6.33549 11.8902 6.26517C11.9605 6.19484 12 6.09946 12 6C12 5.90054 11.9605 5.80516 11.8902 5.73483C11.8198 5.66451 11.7245 5.625 11.625 5.625H10.485C10.3963 4.56464 9.93482 3.56999 9.18242 2.81758C8.43001 2.06518 7.43536 1.60368 6.375 1.515V0.375ZM6 9.75C5.00544 9.75 4.05161 9.35491 3.34835 8.65165C2.64509 7.94839 2.25 6.99456 2.25 6C2.25 5.00544 2.64509 4.05161 3.34835 3.34835C4.05161 2.64509 5.00544 2.25 6 2.25C6.99456 2.25 7.94839 2.64509 8.65165 3.34835C9.35491 4.05161 9.75 5.00544 9.75 6C9.75 6.99456 9.35491 7.94839 8.65165 8.65165C7.94839 9.35491 6.99456 9.75 6 9.75Z"
        fill="black"
      />
    </svg>
  </div>
);

export default IconCurrentLocation;
