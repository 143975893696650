import { denormalisedResponseEntities /* ensureOwnListing */ } from '../util/data';
import { storableError } from '../util/errors';
import { transitionsToRequested } from '../util/transaction';
// import { LISTING_STATE_DRAFT } from '../util/types';
import * as log from '../util/log';
import { authInfo } from './Auth.duck';
import { stripeAccountCreateSuccess } from './stripeConnectAccount.duck';
import {
  getCompanyAccount,
  toggleCompanyFavorites,
  addFavoriteLocationHasura,
  removeFavoriteLocationHasura,
  getFavoriteLocationsHasura,
} from '../util/api';

export const LISTING_STATES = {
  PUBLISHED: 'published',
  CLOSED: 'closed',
};

const getListing = async ({ sdk, listingId }) => {
  const {
    data: {
      data: {
        id,
        attributes: {
          title,
          price,
          state,
          publicData: {
            ameneties,
            bookingType,
            minSeats,
            maxSeats,
            category,
            location: { address },
            priceMax,
            requestPrice,
            locationId,
          },
        },
      },
      included: images,
    },
  } = await sdk.listings.show({
    id: listingId,
    include: ['images'],
    'limit.images': 1,
    'fields.image': ['variants.square-small2x'],
  });

  return {
    id,
    attributes: {
      title,
      price,
      state,
      publicData: {
        ameneties,
        bookingType,
        minSeats,
        maxSeats,
        category,
        location: { address },
        priceMax,
        requestPrice,
        locationId,
      },
    },
    images,
  };
};

export const generateListingArray = ({ sdk, listingIdList }) => {
  const array = [];

  return new Promise((resolve, reject) => {
    if (Array.isArray(listingIdList)) {
      listingIdList.length > 0 &&
        listingIdList.forEach(async (UUID) => {
          const listing = await getListing({ sdk, listingId: UUID });
          array.push(listing);

          if (listingIdList.length === array.length) {
            resolve(array);
          }
        });
    }
  });
};

const getUserInfo = (currentUser) => {
  const {
    attributes: {
      profile: {
        publicData: { organization: companyName },
        protectedData: { companyAccountId: companyId },
      },
    },
  } = currentUser;

  return {
    companyName,
    companyId: !!companyId ? companyId : currentUser.id.uuid,
    isCompanyAdmin: !companyId,
  };
};

// ================ Action types ================ //

export const CURRENT_USER_SHOW_REQUEST = 'app/user/CURRENT_USER_SHOW_REQUEST';
export const CURRENT_USER_SHOW_SUCCESS = 'app/user/CURRENT_USER_SHOW_SUCCESS';
export const CURRENT_USER_SHOW_ERROR = 'app/user/CURRENT_USER_SHOW_ERROR';
export const CURRENT_USER_ADD_SUBSCRIPTION_SUCCESS =
  'app/user/CURRENT_USER_ADD_SUBSCRIPTION_SUCCESS';

export const CLEAR_CURRENT_USER = 'app/user/CLEAR_CURRENT_USER';

export const FETCH_CURRENT_USER_HAS_LISTINGS_REQUEST =
  'app/user/FETCH_CURRENT_USER_HAS_LISTINGS_REQUEST';
export const FETCH_CURRENT_USER_HAS_LISTINGS_SUCCESS =
  'app/user/FETCH_CURRENT_USER_HAS_LISTINGS_SUCCESS';
export const FETCH_CURRENT_USER_HAS_LISTINGS_ERROR =
  'app/user/FETCH_CURRENT_USER_HAS_LISTINGS_ERROR';

export const FETCH_CURRENT_USER_NOTIFICATIONS_REQUEST =
  'app/user/FETCH_CURRENT_USER_NOTIFICATIONS_REQUEST';
export const FETCH_CURRENT_USER_NOTIFICATIONS_SUCCESS =
  'app/user/FETCH_CURRENT_USER_NOTIFICATIONS_SUCCESS';
export const FETCH_CURRENT_USER_NOTIFICATIONS_ERROR =
  'app/user/FETCH_CURRENT_USER_NOTIFICATIONS_ERROR';

export const FETCH_CURRENT_USER_HAS_ORDERS_REQUEST =
  'app/user/FETCH_CURRENT_USER_HAS_ORDERS_REQUEST';
export const FETCH_CURRENT_USER_HAS_ORDERS_SUCCESS =
  'app/user/FETCH_CURRENT_USER_HAS_ORDERS_SUCCESS';
export const FETCH_CURRENT_USER_HAS_ORDERS_ERROR = 'app/user/FETCH_CURRENT_USER_HAS_ORDERS_ERROR';

export const SEND_VERIFICATION_EMAIL_REQUEST = 'app/user/SEND_VERIFICATION_EMAIL_REQUEST';
export const SEND_VERIFICATION_EMAIL_SUCCESS = 'app/user/SEND_VERIFICATION_EMAIL_SUCCESS';
export const SEND_VERIFICATION_EMAIL_ERROR = 'app/user/SEND_VERIFICATION_EMAIL_ERROR';

export const FETCH_COMPANY_ACCOUNT_SUCCESS = 'app/user/FETCH_COMPANY_ACCOUNT_SUCCESS';

export const SET_FAVORITES_ARRAY = 'app/user/SET_FAVORITES_ARRAY';
export const SET_FAVORITES_LOADING = 'app/user/SET_FAVORITES_LOADING';
export const SET_COMPANY_FAVORITES_ARRAY = 'app/user/SET_COMPANY_FAVORITES_ARRAY';
export const SET_FAVORITES_ARRAY_OF_OBJECTS = 'app/user/SET_FAVORITES_ARRAY_OF_OBJECTS';
export const SET_COMPANY_FAVORITES_ARRAY_OF_OBJECTS =
  'app/user/SET_COMPANY_FAVORITES_ARRAY_OF_OBJECTS';
export const SET_COMPANY_FAVORITES_ARRAY_LOADING = 'app/user/SET_COMPANY_FAVORITES_ARRAY_LOADING';
export const SET_COMPANY_INFO = 'app/user/SET_COMPANY_INFO';

// ================ Reducer ================ //

const mergeCurrentUser = (oldCurrentUser, newCurrentUser) => {
  const { id: oId, type: oType, attributes: oAttr, ...oldRelationships } = oldCurrentUser || {};
  const { id, type, attributes, ...relationships } = newCurrentUser || {};

  // Passing null will remove currentUser entity.
  // Only relationships are merged.
  // TODO figure out if sparse fields handling needs a better handling.
  return newCurrentUser === null
    ? null
    : oldCurrentUser === null
    ? newCurrentUser
    : { id, type, attributes, ...oldRelationships, ...relationships };
};

const initialState = {
  currentUser: null,
  currentUserShowError: null,
  currentUserHasListings: false,
  currentUserHasListingsError: null,
  currentUserNotificationCount: 0,
  currentUserNotificationCountError: null,
  currentUserHasOrders: null, // This is not fetched unless unverified emails exist
  currentUserHasOrdersError: null,
  sendVerificationEmailInProgress: false,
  sendVerificationEmailError: null,
  companyAccount: null,
  favoritesArray: [],
  favoritesLoading: false,
  companyFavoritesArray: [],
  favoritesArrayOfObjects: [],
  companyFavoritesArrayOfObjects: [],
  companyFavoritesArrayLoading: false,
  companyInfo: {
    companyId: '',
    companyName: '',
  },
};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case SET_FAVORITES_ARRAY:
      return { ...state, favoritesArray: [...payload] };
    case SET_FAVORITES_ARRAY_OF_OBJECTS:
      return { ...state, favoritesArrayOfObjects: [...payload] };
    case SET_COMPANY_FAVORITES_ARRAY:
      return { ...state, companyFavoritesArray: [...payload] };
    case SET_COMPANY_FAVORITES_ARRAY_OF_OBJECTS:
      return { ...state, companyFavoritesArrayOfObjects: [...payload] };
    case SET_COMPANY_FAVORITES_ARRAY_LOADING:
      return { ...state, companyFavoritesArrayLoading: payload };
    case SET_FAVORITES_LOADING:
      return { ...state, favoritesLoading: payload };
    case SET_COMPANY_INFO:
      return { ...state, companyInfo: { ...payload } };
    case CURRENT_USER_SHOW_REQUEST:
      return { ...state, currentUserShowError: null };
    case CURRENT_USER_SHOW_SUCCESS:
      return { ...state, currentUser: mergeCurrentUser(state.currentUser, payload) };
    case CURRENT_USER_SHOW_ERROR:
      // eslint-disable-next-line no-console
      console.error(payload);
      return { ...state, currentUserShowError: payload };

    case CLEAR_CURRENT_USER:
      return {
        ...state,
        currentUser: null,
        currentUserShowError: null,
        currentUserHasListings: false,
        currentUserHasListingsError: null,
        currentUserNotificationCount: 0,
        currentUserNotificationCountError: null,
      };

    case FETCH_CURRENT_USER_HAS_LISTINGS_REQUEST:
      return { ...state, currentUserHasListingsError: null };
    case FETCH_CURRENT_USER_HAS_LISTINGS_SUCCESS:
      return { ...state, currentUserHasListings: payload.hasListings };
    case FETCH_CURRENT_USER_HAS_LISTINGS_ERROR:
      console.error(payload); // eslint-disable-line
      return { ...state, currentUserHasListingsError: payload };

    case FETCH_CURRENT_USER_NOTIFICATIONS_REQUEST:
      return { ...state, currentUserNotificationCountError: null };
    case FETCH_CURRENT_USER_NOTIFICATIONS_SUCCESS:
      return { ...state, currentUserNotificationCount: payload.transactions.length };
    case FETCH_CURRENT_USER_NOTIFICATIONS_ERROR:
      console.error(payload); // eslint-disable-line
      return { ...state, currentUserNotificationCountError: payload };

    case FETCH_CURRENT_USER_HAS_ORDERS_REQUEST:
      return { ...state, currentUserHasOrdersError: null };
    case FETCH_CURRENT_USER_HAS_ORDERS_SUCCESS:
      return { ...state, currentUserHasOrders: payload.hasOrders };
    case FETCH_CURRENT_USER_HAS_ORDERS_ERROR:
      console.error(payload); // eslint-disable-line
      return { ...state, currentUserHasOrdersError: payload };

    case SEND_VERIFICATION_EMAIL_REQUEST:
      return {
        ...state,
        sendVerificationEmailInProgress: true,
        sendVerificationEmailError: null,
      };
    case SEND_VERIFICATION_EMAIL_SUCCESS:
      return {
        ...state,
        sendVerificationEmailInProgress: false,
      };
    case SEND_VERIFICATION_EMAIL_ERROR:
      return {
        ...state,
        sendVerificationEmailInProgress: false,
        sendVerificationEmailError: payload,
      };
    case CURRENT_USER_ADD_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        currentUser: mergeCurrentUser(state.currentUser, payload),
      };
    case FETCH_COMPANY_ACCOUNT_SUCCESS: {
      return {
        ...state,
        companyAccount: payload,
      };
    }
    default:
      return state;
  }
}

// ================ Selectors ================ //
export const setFavoritesArray = (payload) => ({
  type: SET_FAVORITES_ARRAY,
  payload,
});

export const setCompanyFavoritesArray = (payload) => ({
  type: SET_COMPANY_FAVORITES_ARRAY,
  payload,
});

export const setFavoritesArrayOfObjects = (payload) => ({
  type: SET_FAVORITES_ARRAY_OF_OBJECTS,
  payload,
});

export const setCompanyFavoritesArrayOfObjects = (payload) => ({
  type: SET_COMPANY_FAVORITES_ARRAY_OF_OBJECTS,
  payload,
});

const setCompanyFavoritesArrayLoading = (payload) => ({
  type: SET_COMPANY_FAVORITES_ARRAY_LOADING,
  payload,
});

const setFavoritesLoading = (payload) => ({
  type: SET_FAVORITES_LOADING,
  payload,
});

export const setCompanyInfo = (payload) => ({
  type: SET_COMPANY_INFO,
  payload,
});

export const hasCurrentUserErrors = (state) => {
  const { user } = state;
  return (
    user.currentUserShowError ||
    user.currentUserHasListingsError ||
    user.currentUserNotificationCountError ||
    user.currentUserHasOrdersError
  );
};

export const verificationSendingInProgress = (state) => {
  return state.user.sendVerificationEmailInProgress;
};

// ================ Action creators ================ //

export const currentUserShowRequest = () => ({ type: CURRENT_USER_SHOW_REQUEST });

export const currentUserShowSuccess = (user) => ({
  type: CURRENT_USER_SHOW_SUCCESS,
  payload: user,
});

export const currentUserShowError = (e) => ({
  type: CURRENT_USER_SHOW_ERROR,
  payload: e,
  error: true,
});

export const clearCurrentUser = () => ({ type: CLEAR_CURRENT_USER });

const fetchCurrentUserHasListingsRequest = () => ({
  type: FETCH_CURRENT_USER_HAS_LISTINGS_REQUEST,
});

export const fetchCurrentUserHasListingsSuccess = (hasListings) => ({
  type: FETCH_CURRENT_USER_HAS_LISTINGS_SUCCESS,
  payload: { hasListings },
});

const fetchCurrentUserHasListingsError = (e) => ({
  type: FETCH_CURRENT_USER_HAS_LISTINGS_ERROR,
  error: true,
  payload: e,
});

const fetchCurrentUserNotificationsRequest = () => ({
  type: FETCH_CURRENT_USER_NOTIFICATIONS_REQUEST,
});

export const fetchCurrentUserNotificationsSuccess = (transactions) => ({
  type: FETCH_CURRENT_USER_NOTIFICATIONS_SUCCESS,
  payload: { transactions },
});

const fetchCurrentUserNotificationsError = (e) => ({
  type: FETCH_CURRENT_USER_NOTIFICATIONS_ERROR,
  error: true,
  payload: e,
});

const fetchCurrentUserHasOrdersRequest = () => ({
  type: FETCH_CURRENT_USER_HAS_ORDERS_REQUEST,
});

export const fetchCurrentUserHasOrdersSuccess = (hasOrders) => ({
  type: FETCH_CURRENT_USER_HAS_ORDERS_SUCCESS,
  payload: { hasOrders },
});

const fetchCurrentUserHasOrdersError = (e) => ({
  type: FETCH_CURRENT_USER_HAS_ORDERS_ERROR,
  error: true,
  payload: e,
});

export const sendVerificationEmailRequest = () => ({
  type: SEND_VERIFICATION_EMAIL_REQUEST,
});

export const sendVerificationEmailSuccess = () => ({
  type: SEND_VERIFICATION_EMAIL_SUCCESS,
});

export const sendVerificationEmailError = (e) => ({
  type: SEND_VERIFICATION_EMAIL_ERROR,
  error: true,
  payload: e,
});

export const addSubscription = (e) => ({
  type: CURRENT_USER_ADD_SUBSCRIPTION_SUCCESS,
  payload: e,
});

export const fetchCompanyAccountSuccess = (response) => ({
  type: FETCH_COMPANY_ACCOUNT_SUCCESS,
  payload: response,
});

// ================ Thunks ================ //

export const fetchCurrentUserHasListings = () => (dispatch, getState, sdk) => {
  dispatch(fetchCurrentUserHasListingsRequest());
  const { currentUser } = getState().user;

  if (!currentUser) {
    dispatch(fetchCurrentUserHasListingsSuccess(false));
    return Promise.resolve(null);
  }

  const params = {
    // Since we are only interested in if the user has
    // listings, we only need at most one result.
    page: 1,
    per_page: 1,
  };

  return sdk.ownListings
    .query(params)
    .then((response) => {
      const hasListings = response.data.data && response.data.data.length > 0;
      //     const listing = hasListings ? response.data.data[0] : null;

      const hasPublishedListings = hasListings;
      // &&
      // ensureOwnListing(response.data.data[0]).attributes.state !== LISTING_STATE_DRAFT;
      dispatch(fetchCurrentUserHasListingsSuccess(!!hasPublishedListings));
    })
    .catch((e) => dispatch(fetchCurrentUserHasListingsError(storableError(e))));
};

export const fetchCurrentUserHasOrders = () => (dispatch, getState, sdk) => {
  dispatch(fetchCurrentUserHasOrdersRequest());

  if (!getState().user.currentUser) {
    dispatch(fetchCurrentUserHasOrdersSuccess(false));
    return Promise.resolve(null);
  }

  const params = {
    only: 'order',
    page: 1,
    per_page: 1,
  };

  return sdk.transactions
    .query(params)
    .then((response) => {
      const hasOrders = response.data.data && response.data.data.length > 0;
      dispatch(fetchCurrentUserHasOrdersSuccess(!!hasOrders));
    })
    .catch((e) => dispatch(fetchCurrentUserHasOrdersError(storableError(e))));
};

// Notificaiton page size is max (100 items on page)
const NOTIFICATION_PAGE_SIZE = 100;

export const fetchCurrentUserNotifications = () => (dispatch, getState, sdk) => {
  dispatch(fetchCurrentUserNotificationsRequest());

  const apiQueryParams = {
    only: 'sale',
    last_transitions: transitionsToRequested,
    page: 1,
    per_page: NOTIFICATION_PAGE_SIZE,
  };

  sdk.transactions
    .query(apiQueryParams)
    .then((response) => {
      const transactions = response.data.data;
      dispatch(fetchCurrentUserNotificationsSuccess(transactions));
    })
    .catch((e) => dispatch(fetchCurrentUserNotificationsError(storableError(e))));
};

export const fetchCurrentUser =
  (params = null) =>
  (dispatch, getState, sdk) => {
    dispatch(currentUserShowRequest());
    const { isAuthenticated } = getState().Auth;

    if (!isAuthenticated) {
      // Make sure current user is null
      dispatch(currentUserShowSuccess(null));
      return Promise.resolve({});
    }

    const parameters = params || {
      include: ['profileImage', 'stripeAccount'],
      'fields.image': ['variants.square-small', 'variants.square-small2x'],
    };

    return sdk.currentUser
      .show(parameters)
      .then((response) => {
        const entities = denormalisedResponseEntities(response);
        if (entities.length !== 1) {
          throw new Error('Expected a resource in the sdk.currentUser.show response');
        }
        const currentUser = entities[0];

        // Save stripeAccount to store.stripe.stripeAccount if it exists
        if (currentUser.stripeAccount) {
          dispatch(stripeAccountCreateSuccess(currentUser.stripeAccount));
        }

        // set current user id to the logger
        log.setUserId(currentUser.id.uuid);
        dispatch(currentUserShowSuccess(currentUser));
        return currentUser;
      })
      .then((currentUser) => {
        const {
          attributes: {
            profile: {
              metadata: { companyFavoriteListings },
            },
          },
        } = currentUser || {};

        dispatch(setFavoritesLoading(true));
        getFavoriteLocationsHasura(currentUser.id.uuid)
          .then((response) => {
            const favoriteLocations = response.data.favorite_locations;
            dispatch(
              setFavoritesArray(favoriteLocations.map((location) => location.location_rel?.id))
            );
            dispatch(
              setFavoritesArrayOfObjects(favoriteLocations.map((location) => location.location_rel))
            );
            dispatch(setFavoritesLoading(false));
          })
          .catch((e) => console.error(e));

        if (
          !!companyFavoriteListings &&
          Array.isArray(companyFavoriteListings) &&
          !!companyFavoriteListings.length
        ) {
          dispatch(setCompanyFavoritesArray(companyFavoriteListings));
          generateListingArray({ sdk, listingIdList: companyFavoriteListings }).then(
            (arrayOfListings) => {
              dispatch(setCompanyFavoritesArrayOfObjects(arrayOfListings));
              dispatch(setCompanyFavoritesArrayLoading(false));
            }
          );
        }

        dispatch(fetchCurrentUserHasListings());
        dispatch(fetchCurrentUserNotifications());
        if (!currentUser.attributes.emailVerified) {
          dispatch(fetchCurrentUserHasOrders());
        }

        // Make sure auth info is up to date
        dispatch(authInfo());
      })
      .catch((e) => {
        // Make sure auth info is up to date
        dispatch(authInfo());
        log.error(e, 'fetch-current-user-failed');
        dispatch(currentUserShowError(storableError(e)));
      });
  };

export const sendVerificationEmail = () => (dispatch, getState, sdk) => {
  if (verificationSendingInProgress(getState())) {
    return Promise.reject(new Error('Verification email sending already in progress'));
  }
  dispatch(sendVerificationEmailRequest());
  return sdk.currentUser
    .sendVerificationEmail()
    .then(() => dispatch(sendVerificationEmailSuccess()))
    .catch((e) => dispatch(sendVerificationEmailError(storableError(e))));
};

export const changeUserSubscription =
  (subscriptionDetails, listings, isCancelled) => (dispatch, getState, sdk) => {
    const { id, plan_id, customer_id } = subscriptionDetails;
    let currentUser = getState().user.currentUser;
    const currentSub =
      currentUser.attributes?.profile?.protectedData?.subscriptions.find(
        (sub) => sub.details.id === id
      ) || [];
    currentSub.details = { id, plan_id, customer_id };
    if (listings && listings.length) {
      const listingsIds = listings.map((listing, index) => listing.id.uuid);
      currentSub.listingId = listingsIds;
    }
    sdk.currentUser
      .updateProfile({
        protectedData: {
          subscriptions: [
            ...(currentUser.attributes?.profile?.protectedData?.subscriptions.filter(
              (sub) => sub.details.id !== id
            ) || []),
            ...(isCancelled ? [] : [currentSub]),
          ],
        },
      })
      .then((response) => {
        let currentUser = getState().user.currentUser;
        const entities = denormalisedResponseEntities(response);
        if (entities.length !== 1) {
          throw new Error('Expected a resource in the sdk.currentUser.updateProfile response');
        }
        currentUser.attributes.profile.protectedData.subscriptions = [
          ...(currentUser.attributes?.profile?.protectedData?.subscriptions.filter(
            (sub) => sub.details.id !== id
          ) || []),
          ...(isCancelled ? [] : [currentSub]),
        ];
        dispatch(addSubscription(currentUser));
      });
  };

/**
 * Add or remove listing from favorites
 * @param {String} id
 *
 * @returns {Array} new array of favorite listings for state
 */
export const toggleUserFavoriteListings = (id) => (dispatch, getState, sdk) => {
  dispatch(setFavoritesLoading(true));
  let currentUser = getState().user.currentUser;
  const userObj = {
    sharetribeId: currentUser?.id?.uuid,
    companyId:
      currentUser?.attributes?.profile?.protectedData?.companyAccountId || currentUser?.id?.uuid,
    firstName: currentUser?.attributes?.profile?.firstName,
    lastName: currentUser?.attributes?.profile?.lastName,
    imgUrl: currentUser?.profileImage?.attributes?.variants?.['square-small2x']?.url,
  };

  const {
    user: { favoritesArray, favoritesArrayOfObjects },
  } = getState();

  // if id is in favoritesArray, remove it, otherwise add it
  if (favoritesArray.includes(id)) {
    removeFavoriteLocationHasura({ userId: userObj.sharetribeId, locationId: id })
      .then(() => {
        //remove id from array
        const index = favoritesArray.findIndex((f) => f === id);
        favoritesArray.splice(index, 1);
        //remove object from array
        const objectIndex = favoritesArrayOfObjects.findIndex((f) => f?.id === id);
        favoritesArrayOfObjects.splice(objectIndex, 1);
      })
      .then(() => {
        dispatch(setFavoritesArray(favoritesArray));
        dispatch(setFavoritesArrayOfObjects(favoritesArrayOfObjects));
      })
      .finally(() => dispatch(setFavoritesLoading(false)))
      .catch((e) => console.log('Error ', e));
  } else {
    addFavoriteLocationHasura({ user: userObj, locationId: id })
      .then((res) => {
        //add new favorite id to array
        favoritesArray.push(res.data.insert_favorite_locations_one.location_rel.id);
        //add new favorite object to array
        favoritesArrayOfObjects.push(res.data.insert_favorite_locations_one.location_rel);
      })
      .then(() => {
        dispatch(setFavoritesArray(favoritesArray));
        dispatch(setFavoritesArrayOfObjects(favoritesArrayOfObjects));
      })
      .finally(() => dispatch(setFavoritesLoading(false)))
      .catch((e) => console.log('Error ', e));
  }
};

export const toggleCompanyFavoriteListings = (id) => (dispatch, getState, sdk) => {
  const {
    user: { companyFavoritesArray, companyFavoritesArrayOfObjects, currentUser },
  } = getState();

  const listingIdIndex = companyFavoritesArray.findIndex((f) => f === id);
  const listingObjectIndex = companyFavoritesArrayOfObjects.findIndex((f) => f.id.uuid === id);

  listingIdIndex < 0
    ? companyFavoritesArray.push(id)
    : companyFavoritesArray.splice(listingIdIndex, 1);
  if (listingObjectIndex < 0) {
    getListing({ sdk, listingId: id }).then((listing) =>
      companyFavoritesArrayOfObjects.push(listing)
    );
  } else {
    companyFavoritesArrayOfObjects.splice(listingObjectIndex, 1);
  }

  toggleCompanyFavorites({
    id: currentUser.id.uuid,
    companyFavoriteListings: companyFavoritesArray,
  })
    .then(() => {
      dispatch(setCompanyFavoritesArray(companyFavoritesArray));
      dispatch(setCompanyFavoritesArrayOfObjects(companyFavoritesArrayOfObjects));
    })
    .catch((e) => console.log('Error: ', e));
};

export const fetchCompanyUser = () => async (dispatch, getState, sdk) => {
  try {
    const res = await getCompanyAccount();
    dispatch(fetchCompanyAccountSuccess(res.data.data));
  } catch (error) {
    console.log('Fetch company account fail. ', error);
  }
};
