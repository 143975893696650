import React, { Fragment } from 'react';
import ContentLoader from 'react-content-loader';

import css from './TableLoader.module.scss';

const TableLoader = () => {
  const heading = { width: 300, height: 26 },
    row = 1,
    column = 5,
    width = 1366,
    padding = 12,
    borderRadius = 4,
    list = [];

  let height;

  for (let i = 1; i <= row; i++) {
    const itemWidth = (width - padding * (column + 1)) / column;

    const height1 = (itemWidth * 9) / 16;

    const height2 = 20;

    const height3 = 20;

    const headingWidth = heading.width;

    const headingHeight = heading.height;

    const space =
      padding +
      headingHeight +
      (padding + height1) +
      (padding / 2 + height2) +
      height3 +
      padding * 6;

    const yHeading = padding + space * (i - 1);

    list.push(
      <rect
        x={padding}
        y={yHeading}
        rx={0}
        ry={0}
        width={headingWidth}
        height={headingHeight}
        key={`Heading_${i}`}
      />
    );

    for (let j = 0; j < column; j++) {
      const x = padding + j * (itemWidth + padding);

      const y1 = yHeading + headingHeight + padding * 3;

      const y3 = y1 + padding + height3;

      list.push(
        <Fragment key={`Items_${i},${j}`}>
          <rect
            x={x + (itemWidth - itemWidth * 0.35) / 2}
            y={y1}
            rx={0}
            ry={0}
            width={itemWidth * 0.35}
            height={height3}
          />
          <rect
            x={x}
            y={y1 + 30}
            rx={borderRadius}
            ry={borderRadius}
            width={itemWidth}
            height={50}
          />
          <rect
            x={x}
            y={y3 + padding + 50}
            rx={borderRadius}
            ry={borderRadius}
            width={itemWidth}
            height={height1}
          />
        </Fragment>
      );

      if (i === row) {
        height = y3 + height1 + height3 + 50;
      }
    }
  }

  return (
    <section className={css.container}>
      <div className={css.wrapper}>
        <ContentLoader viewBox={`0 0 ${width} ${height}`} width={width} height={height}>
          {list}
        </ContentLoader>
      </div>
    </section>
  );
};

export default TableLoader;
