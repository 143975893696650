import React, { useCallback, useEffect, useState } from 'react';
import classNames from 'classnames';
import { useIntl } from 'react-intl';
import { shape, string } from 'prop-types';

import { ButtonNew } from '../../components_new';
import { BUTTON_TYPES, BUTTON_SIZES } from '../../components_new/ButtonNew/ButtonNew';

import css from './BookShowingForm.module.scss';

const BookShowingForm = ({
  onFormSubmit,
  locationAddess,
  currentUserInfoForEmail,
  formSubmitStep,
  setFormSubmitStep,
  fixedOfficeTypeTranslation,
  seatsInfo,
  listingPrice,
  areaMin,
  areaMax,
  calculatedSeats,
}) => {
  const intl = useIntl();
  const [formLoading, setFormLoading] = useState(false);
  const [formSubmitSuccess, setFormSubmitSuccess] = useState(false);
  const [weekOffset, setWeekOffset] = useState(0);
  const [values, setValues] = useState({
    fullName: currentUserInfoForEmail?.fullName || '',
    companyName: currentUserInfoForEmail?.companyName || '',
    date: '',
    time: '',
    email: currentUserInfoForEmail?.email || '',
    phone: currentUserInfoForEmail?.phone || '',
    message: '',
    suggestTime: false,
  });
  const [errors, setErrors] = useState({
    fullName: false,
    companyName: false,
    date: false,
    time: false,
    email: false,
    phone: false,
    suggestTime: false,
  });

  useEffect(() => {
    setFormSubmitSuccess(false);
  }, []);

  const handleChange = useCallback(
    ({ target: { name, value } }) => {
      setValues({ ...values, [name]: value });
      setErrors({ ...errors, [name]: false });
    },
    [values]
  );

  const handleDateInput = useCallback(
    (val) => {
      setValues({ ...values, date: val });
      setErrors({ ...errors, date: false });
    },
    [values]
  );

  const handleTimeInput = useCallback(
    (val) => {
      setValues({ ...values, time: val });
      setErrors({ ...errors, time: false });
    },
    [values]
  );

  const handleSuggestTimeInput = useCallback(
    (val) => {
      setValues({ ...values, suggestTime: val, time: '', date: '' });
      setErrors({ ...errors, suggestTime: false });
    },
    [values]
  );

  const handleSubmit = useCallback(
    async (e) => {
      setFormLoading(true);
      setErrors({
        fullName: false,
        companyName: false,
        date: false,
        time: false,
        email: false,
        phone: false,
      });
      e.preventDefault();
      const localErrors = Object.keys(values).reduce((acc, curr) => {
        if (curr === 'email' || curr === 'fullName') {
          acc[curr] = curr !== 'message' ? !values[curr] : false;
        }
        return acc;
      }, {});

      setErrors(localErrors);
      const showErrors = Object.keys(localErrors).some(
        (key) => key !== 'message' && !!localErrors[key]
      );

      if (!showErrors) {
        await onFormSubmit(values);
        setFormSubmitSuccess(true);
      }

      setFormLoading(false);
    },
    [errors, values]
  );

  function getWorkingDaysOfWeek(weekOffset = 0) {
    const days = [];
    const today = new Date();
    const startOfWeek = new Date(
      today.getFullYear(),
      today.getMonth(),
      today.getDate() - today.getDay() + 1
    ); // get the date of the first day of the week

    startOfWeek.setDate(startOfWeek.getDate() + weekOffset * 7); // add the week offset

    for (let i = 0; i < 5; i++) {
      const date = new Date(startOfWeek);
      date.setDate(date.getDate() + i);
      const weekDay = date
        .toLocaleDateString(intl.locale === 'sv' ? 'se-SV' : 'en-US', { weekday: 'short' })
        .toUpperCase();
      const isDisabled = date < today;

      days.push({
        dayNumber: date.getDate(),
        weekDay,
        date: date.toLocaleDateString(intl.locale === 'sv' ? 'se-SV' : 'en-US', {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
        }),
        isDisabled,
      });
    }

    return days;
  }

  function handleNextWeekClick() {
    setWeekOffset((prevOffset) => prevOffset + 1);
  }

  function handlePreviousWeekClick() {
    if (weekOffset === 0) return;
    setWeekOffset((prevOffset) => prevOffset - 1);
  }

  const selectedWeekDate = new Date();
  selectedWeekDate.setDate(selectedWeekDate.getDate() + weekOffset * 7);
  const selectedWeekMonth = selectedWeekDate.toLocaleDateString(
    intl.locale === 'sv' ? 'se-SV' : 'en-US',
    {
      month: 'long',
    }
  );
  const selectedWeekYear = selectedWeekDate.getFullYear();

  const selectedWeekLabel = `${selectedWeekMonth} ${selectedWeekYear}`;

  const forwardIcon = (
    <svg width="10" height="18" viewBox="0 0 10 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1 1L9 9L1 17" stroke="#222222" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );

  const backwardIcon = (
    <svg width="10" height="18" viewBox="0 0 10 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9 1L1 9L9 17"
        stroke="#222222"
        strokeOpacity={weekOffset === 0 ? '0.2' : '1'}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );

  const timeOptions = [];
  for (let hour = 9; hour <= 16; hour++) {
    for (let minute of ['00', '30']) {
      const timeString = `${hour}:${minute}`;
      timeOptions.push(timeString);
    }
  }

  return (
    <form className={css.wrapper} method="POST" onSubmit={handleSubmit}>
      {formSubmitSuccess ? (
        <>
          <legend className={css.legend}>
            {intl.formatMessage({ id: 'BookShowingForm.successTitle' })}
          </legend>
          <p className={css.caption}>
            {intl.formatMessage({
              id: 'BookShowingForm.successCaption',
            })}
          </p>
        </>
      ) : formSubmitStep === 0 ? (
        <>
          <legend className={css.legend}>{fixedOfficeTypeTranslation}</legend>
          <p className={css.firstStepDescription}>
            <span>
              {calculatedSeats && (areaMin || areaMax) ? (
                <>
                  {areaMin && areaMax && areaMin !== areaMax
                    ? `${areaMin} - ${areaMax}`
                    : areaMin || areaMax}{' '}
                  m<sup>2</sup>
                </>
              ) : (
                <>
                  {seatsInfo} {intl.formatMessage({ id: 'BookShowingForm.seats' })}
                </>
              )}
            </span>
            <span>{listingPrice}</span>
          </p>
          <legend className={css.legend}>
            {intl.formatMessage({ id: 'BookShowingForm.formTitleFirstStep' })}
          </legend>
          <p className={css.firstStepDescription}>
            {intl.formatMessage({ id: 'BookShowingForm.formDescriptionFirstStep' })}
          </p>
          <fieldset className={classNames(css.submitBtnWrapper, css.nextStepBtn)}>
            <ButtonNew
              nativeType="submit"
              type={BUTTON_TYPES.GREEN}
              size={BUTTON_SIZES.LARGE}
              inProgress={formLoading}
              block
              onClick={() => {
                setFormSubmitStep(1);
              }}
            >
              {intl.formatMessage({ id: 'BookShowingForm.bookShowingBtnLabel' })}
            </ButtonNew>
          </fieldset>
        </>
      ) : formSubmitStep === 1 ? (
        <>
          <legend
            className={classNames(css.legend, css.backBtn)}
            onClick={(e) => {
              e.preventDefault();
              setFormSubmitStep(0);
              handleSuggestTimeInput(false);
            }}
          >
            <span>{'←  '}</span>
            {intl.formatMessage({ id: 'BookShowingForm.formTitleSecondStep' })}
          </legend>
          <div>
            <div className={css.weekSelectorWrapper}>
              <h4>{selectedWeekLabel}</h4>
              <div>
                <button
                  className={css.previousBtn}
                  onClick={(e) => {
                    e.preventDefault();
                    handlePreviousWeekClick();
                  }}
                >
                  {backwardIcon}
                </button>
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    handleNextWeekClick();
                  }}
                >
                  {forwardIcon}
                </button>
              </div>
            </div>
            <input type="hidden" name="date" value={values.date} />
            <ul className={css.weekWrapper}>
              {getWorkingDaysOfWeek(weekOffset).map((day) => (
                <li
                  key={day.date}
                  className={classNames(
                    css.dateBox,
                    (day.isDisabled || values.suggestTime) && css.disabled,
                    day.date === values.date && css.selected
                  )}
                  onClick={(e) => {
                    e.preventDefault();
                    !day.isDisabled && !values.suggestTime && handleDateInput(day.date);
                  }}
                >
                  <span>{day.weekDay}</span>
                  <span className={css.dayNumber}>{day.dayNumber}</span>
                </li>
              ))}
            </ul>
          </div>
          <select
            className={classNames(css.timePicker)}
            id="time"
            value={values.time}
            disabled={values.suggestTime}
            onChange={(e) => handleTimeInput(e.target.value)}
          >
            <option className={css.defaultOption} value="">
              {intl.formatMessage({ id: 'BookShowingForm.timePlaceholder' })}
            </option>
            {timeOptions.map((time) => (
              <option key={time} value={time}>
                {time}
              </option>
            ))}
          </select>
          <fieldset className={classNames(css.fieldset, css.suggestTimeWrapper)}>
            <input
              type="checkbox"
              name="suggestTime"
              value="false"
              id="suggestTime"
              onClick={(e) => {
                handleSuggestTimeInput(e.target.checked);
              }}
            />
            <label htmlFor="suggestTime" className={css.suggestTimeLabel}>
              {intl.formatMessage({ id: 'BookShowingForm.suggestTimeLabel' })}
            </label>
          </fieldset>
          <fieldset className={classNames(css.submitBtnWrapper, css.nextStepBtn)}>
            <ButtonNew
              nativeType="submit"
              type={BUTTON_TYPES.GREEN}
              size={BUTTON_SIZES.LARGE}
              inProgress={formLoading}
              block
              disabled={!values.date && !values.suggestTime}
              onClick={() => {
                setFormSubmitStep(2);
              }}
            >
              {intl.formatMessage({ id: 'BookShowingForm.nextStepBtnLabel' })}
            </ButtonNew>
          </fieldset>
        </>
      ) : (
        <>
          <legend
            className={classNames(css.legend, css.backBtn)}
            onClick={(e) => {
              e.preventDefault();
              setFormSubmitStep(1);
            }}
          >
            <span>{'←  '}</span>
            {intl.formatMessage({ id: 'BookShowingForm.formTitleThirdStep' })}
          </legend>
          {values.date && (
            <div className={classNames(css.fieldset, css.info)}>
              <span>{intl.formatMessage({ id: 'BookShowingForm.datetimeLabel' })}: </span>
              <span>{`${values.date} ${values.time}`}</span>
            </div>
          )}
          <div className={classNames(css.fieldset, css.info)}>
            <span>{intl.formatMessage({ id: 'BookShowingForm.locationLabel' })}: </span>
            <span>{locationAddess}</span>
          </div>
          <fieldset className={css.fieldset}>
            <input
              className={classNames(css.input, !!errors.fullName && css.invalid)}
              type="text"
              name="fullName"
              id="fullName"
              placeholder={intl.formatMessage({ id: 'BookShowingForm.fullNamePlaceholder' })}
              value={values.fullName}
              onInput={handleChange}
            />
            {!!errors.fullName && (
              <p className={css.errorMessage}>
                {intl.formatMessage({ id: 'BookShowingForm.fullNameError' })}
              </p>
            )}
          </fieldset>
          <fieldset className={css.fieldset}>
            <input
              className={classNames(css.input, !!errors.companyName && css.invalid)}
              type="text"
              name="companyName"
              id="companyName"
              placeholder={intl.formatMessage({ id: 'BookShowingForm.companyNamePlaceholder' })}
              value={values.companyName}
              onInput={handleChange}
            />
            {!!errors.companyName && (
              <p className={css.errorMessage}>
                {intl.formatMessage({ id: 'BookShowingForm.companyNameError' })}
              </p>
            )}
          </fieldset>
          <fieldset className={css.fieldset}>
            <input
              className={classNames(css.input, !!errors.email && css.invalid)}
              type="text"
              name="email"
              id="email"
              placeholder={intl.formatMessage({ id: 'BookShowingForm.emailPlaceholder' })}
              value={values.email}
              onInput={handleChange}
            />
            {!!errors.email && (
              <p className={css.errorMessage}>
                {intl.formatMessage({ id: 'BookShowingForm.emailError' })}
              </p>
            )}
          </fieldset>
          <fieldset className={css.fieldset}>
            <input
              className={classNames(css.input, !!errors.phone && css.invalid)}
              type="text"
              name="phone"
              id="phone"
              placeholder={intl.formatMessage({ id: 'BookShowingForm.phonePlaceholder' })}
              value={values.phone}
              onInput={handleChange}
            />
            {!!errors.phone && (
              <p className={css.errorMessage}>
                {intl.formatMessage({ id: 'BookShowingForm.phoneError' })}
              </p>
            )}
          </fieldset>
          <fieldset className={css.fieldset}>
            <textarea
              className={css.input}
              name="message"
              id="message"
              placeholder={intl.formatMessage({ id: 'BookShowingForm.messagePlaceholder' })}
              value={values.message}
              onInput={handleChange}
            />
          </fieldset>
          <fieldset className={classNames(css.fieldset, css.submitBtnWrapper)}>
            <ButtonNew
              nativeType="submit"
              type={BUTTON_TYPES.GREEN}
              size={BUTTON_SIZES.LARGE}
              inProgress={formLoading}
              block
            >
              {intl.formatMessage({ id: 'BookShowingForm.submitBtnLabel' })}
            </ButtonNew>
          </fieldset>
          <p className={css.caption}>{intl.formatMessage({ id: 'BookShowingForm.caption' })}</p>
        </>
      )}
    </form>
  );
};

BookShowingForm.propTypes = {
  currentUserInfoForEmail: shape({
    fullName: string,
    companyName: string,
    email: string,
    phone: string,
  }),
};

export default BookShowingForm;
