import React from 'react';

const AcceptedIcon = ({ className }) => (
  <svg
    className={className}
    width="18"
    height="13"
    viewBox="0 0 18 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1 6.46338L6.33333 11.9268L17 1"
      stroke="#74AF84"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
  </svg>
);

export default AcceptedIcon;
