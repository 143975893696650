import { arrayOf, number, shape, string } from 'prop-types';
import React from 'react';

import { FormattedMessage } from 'react-intl';
import { priceData } from '../../../util/currency';
import { intlShape } from '../../../util/reactIntl';
import { types } from '../../../util/sdkLoader';
import ListingPageSection from '../ListingPageSection/ListingPageSection';

import css from './SectionAddons.module.scss';

const { Money } = types;

const addonShape = shape({
  name: string,
  startTime: string,
  endTime: string,
  price: shape({
    amount: number,
    currency: string,
  }),
  vatPercentage: string,
  serviceId: string,
});

const Item = (props) => {
  const {
    intl,
    item: {
      name,
      iconSrc,
      labelId,
      description,
      price: { amount, currency },
      vatPercentage,
    },
  } = props;
  const money = new Money(amount, currency);
  const { formattedPrice } = priceData(money, vatPercentage, intl) || {};

  return (
    <>
      {iconSrc && (
        <div className={css.addon}>
          <div className={css.top}>
            <img src={iconSrc} alt={name} className={css.icon} />
            <FormattedMessage id={labelId}>
              {(id) => <p className={css.name}>{id}</p>}
            </FormattedMessage>
            <p className={css.price}>{formattedPrice}</p>
          </div>
          <p className={css.description}>{description}</p>
        </div>
      )}
    </>
  );
};

Item.propTypes = {
  intl: intlShape,
  item: addonShape,
};

const SectionAddons = ({ intl, addons, addonsList }) => {
  const formattedAddons = addons.map((addon) => {
    const addonInConfig = addonsList.find((a) => a.id === addon.name);
    if (addonInConfig && addonInConfig.iconSrc) {
      return {
        ...addon,
        iconSrc: addonInConfig.iconSrc,
        labelId: addonInConfig.labelId,
      };
    } else {
      return null;
    }
  });
  return (
    <ListingPageSection title={'ListingPage.addons.title'}>
      <div className={css.addonsList}>
        {formattedAddons.map((item, i) => (
          <span key={`addon_${i}`}>{item && <Item intl={intl} item={item} key={item.name} />}</span>
        ))}
      </div>
    </ListingPageSection>
  );
};

SectionAddons.propTypes = {
  intl: intlShape,
  addons: arrayOf(addonShape),
};

export default SectionAddons;
