import React, { useCallback, useEffect, useState } from 'react';
import { arrayOf, shape, string } from 'prop-types';
import { useIntl } from 'react-intl';

import { IconCardSelectButton, EditListingIcons as Icons } from '../../components';

import {
  COWORKING_CATEGORY,
  MEETINGROOMS_CATEGORY,
  PRIVATE_OFFICE_CATEGORY,
  STUDIO_CATEGORY,
  FIXED_OFFICE_CATEGORY,
  FIXED_OFFICE_SPACE,
  FIXED_PRIVATE_ROOM,
  FIXED_DESK_FIXED,
  FIXED_DESK_FLEXIBLE,
  HOST_TYPE_HOTEL,
  HOST_TYPE_PROPERTY_OWNER,
  HOST_TYPE_OFFICE_HOTEL,
  HOST_TYPE_OTHER_COMPANY,
} from '../../util/types';

import css from './CustomCategorySelectFieldMaybe.module.scss';

const CustomCategorySelectFieldMaybe = ({
  id,
  name,
  title,
  categoryOptions,
  categoryInitialOption,
  onCategoryChange,
  onClick,
  hintTitle,
}) => {
  const intl = useIntl();
  const [selectedCate, setSelectedCate] = useState(categoryInitialOption);

  const findCategoryIcon = useCallback((category) => {
    switch (category.key) {
      case COWORKING_CATEGORY:
        return <Icons.CoworkingIcon />;
      case PRIVATE_OFFICE_CATEGORY:
        return <Icons.PrivateOfficeIcon />;
      case MEETINGROOMS_CATEGORY:
        return <Icons.MeetingRoomsIcon />;
      case STUDIO_CATEGORY:
        return <Icons.StudioIcon />;
      case FIXED_OFFICE_CATEGORY:
        return <Icons.FixedOfficeIcon />;
      case FIXED_OFFICE_SPACE:
        return <Icons.FixedOfficeSpaceIcon />;
      case FIXED_PRIVATE_ROOM:
        return <Icons.FixedPrivateRoomIcon />;
      case FIXED_DESK_FIXED:
        return <Icons.FixedFixedDeskIcon />;
      case FIXED_DESK_FLEXIBLE:
        return <Icons.FixedFlexibleDesk />;
      case HOST_TYPE_HOTEL:
        return <Icons.HostHotelIcon />;
      case HOST_TYPE_OFFICE_HOTEL:
        return <Icons.HostOfficeHotelIcon />;
      case HOST_TYPE_PROPERTY_OWNER:
        return <Icons.HostPropertOwnerIcon />;
      case HOST_TYPE_OTHER_COMPANY:
        return <Icons.HostOtherCompanyIcon />;
      default:
        return null;
    }
  }, []);

  const checkSelectedCate = useCallback(
    (category) => selectedCate === category.key,
    [selectedCate]
  );

  useEffect(() => setSelectedCate(categoryInitialOption), [categoryInitialOption]);

  const handleClick = (category) => () => {
    setSelectedCate(category.key);
    onCategoryChange(category.key);
  };

  return (
    <>
      <div className={css.categoryFieldWrapper}>
        <div className={css.categoryFieldLabel}>
          {title && <h5>{title}</h5>}
          {hintTitle && (
            <p className={css.hintTitle} onClick={onClick}>
              {hintTitle}
            </p>
          )}
        </div>
        <div className={css.categoryOptionWrapper}>
          {categoryOptions &&
            categoryOptions.map((c) =>
              c.key === 'none' ? null : (
                <div className={css.cardSelectWrapper} key={c.key}>
                  <IconCardSelectButton
                    labelText={intl.formatMessage({ id: c.labelId })}
                    IconComponent={findCategoryIcon(c)}
                    isSelected={checkSelectedCate(c)}
                    onClick={handleClick(c)}
                  />
                </div>
              )
            )}
        </div>
        <input id={id} name={name} type="hidden" />
      </div>
    </>
  );
};

CustomCategorySelectFieldMaybe.propstypes = {
  id: string.isRequired,
  name: string.isRequired,
  categoryOptions: arrayOf(
    shape({
      id: string,
      key: string,
      label: string,
    })
  ),
};
export default CustomCategorySelectFieldMaybe;
