const prepareDisplayData = ({ data, currentUserId }) =>
  data.map((item) => {
    const isOwn = item.person_a_id === currentUserId;
    const personData = !isOwn ? item.user_a_rel : item.user_b_rel || { email: item.person_b_email };
    return {
      id: item.id,
      status: item.status,
      isOwn,
      personData,
    };
  });

export default prepareDisplayData;
