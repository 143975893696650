import { ensureTransaction } from './data';

// Transitions
export const TRANSITION_ENQUIRE = 'transition/enquire';
export const TRANSITION_B2B_ENQUIRE = 'transition/b2b-enquire';
export const TRANSITION_B2B_ENQUIRE_HOURLY = 'transition/b2b-enquire-hourly';
export const TRANSITION_B2B_ENQUIRE_NO_ADDONS = 'transition/b2b-enquire-no-addons';
export const TRANSITION_B2B_ENQUIRE_HOURLY_NO_ADDONS = 'transition/b2b-enquire-hourly-no-addons';

export const TRANSITION_REQUEST_PAYMENT_AFTER_ENQUIRY = 'transition/request-payment-after-enquiry';
export const TRANSITION_REQUEST_PAYMENT_DAILY_AFTER_ENQUIRY =
  'transition/request-payment-daily-after-enquiry';
export const TRANSITION_CONFIRM_PAYMENT_AFTER_ENQUIRY = 'transition/confirm-payment-after-enquiry';
export const TRANSITION_EXPIRE_PAYMENT_AFTER_ENQUIRY = 'transition/expire-payment-after-enquiry';
export const TRANSITION_B2B_CONFIRM_PAYMENT_AFTER_ENQUIRY =
  'transition/b2b-confirm-payment-after-enquiry';

export const TRANSITION_REQUEST_PAYMENT = 'transition/request-payment';
export const TRANSITION_REQUEST_PAYMENT_DAILY = 'transition/request-payment-daily';

export const TRANSITION_CONFIRM_PAYMENT = 'transition/confirm-payment';
export const TRANSITION_B2B_CONFIRM_PAYMENT = 'transition/b2b-confirm-payment';
export const TRANSITION_EXPIRE_PAYMENT = 'transition/expire-payment';
export const TRANSITION_EXPIRE_PENDING_PAYMENT = 'transition/expire-pending-payment';
export const TRANSITION_B2B_EXPIRE_PENDING_PAYMENT = 'transition/b2b-expire-pending-payment';

export const TRANSITION_ACCEPT = 'transition/accept';
export const TRANSITION_DECLINE = 'transition/decline';
export const TRANSITION_B2B_ACCEPT = 'transition/b2b-accept';
export const TRANSITION_B2B_DECLINE = 'transition/b2b-decline';
export const TRANSITION_B2B_ACCEPT_NO_ADDONS = 'transition/b2b-accept-no-addons';
export const TRANSITION_B2B_DECLINE_NO_ADDONS = 'transition/b2b-decline-no-addons';
export const TRANSITION_B2B_EXPIRE_NO_ADDONS = 'transition/b2b-expire-no-addons';

export const TRANSITION_CUSTOMER_CANCEL = 'transition/customer-cancel';
export const TRANSITION_PROVIDER_CANCEL = 'transition/provider-cancel';
export const TRANSITION_OPERATOR_CANCEL = 'transition/operator-cancel';

export const TRANSITION_DISABLE_CANCELLATION = 'transition/disable-cancellation';

export const TRANSITION_OPERATOR_CANCEL_AFTER_MARK_NO_CANCELLATION =
  'transition/operator-cancel-after-mark-no-cancellation';

export const TRANSITION_COMPLETE = 'transition/complete';

export const TRANSITION_REVIEW_BY_CUSTOMER = 'transition/review-by-customer';
export const TRANSITION_B2B_REVIEW_BY_CUSTOMER = 'transition/b2b-review-by-customer';
export const TRANSITION_EXPIRE_REVIEW_PERIOD = 'transition/expire-review-period';
export const TRANSITION_B2B_EXPIRE_REVIEW_PERIOD = 'transition/b2b-expire-review-period';

// Roles of actors that perform transaction transitions
export const TX_TRANSITION_ACTOR_CUSTOMER = 'customer';
export const TX_TRANSITION_ACTOR_PROVIDER = 'provider';
export const TX_TRANSITION_ACTOR_SYSTEM = 'system';
export const TX_TRANSITION_ACTOR_OPERATOR = 'operator';

export const TX_TRANSITION_ACTORS = [
  TX_TRANSITION_ACTOR_CUSTOMER,
  TX_TRANSITION_ACTOR_PROVIDER,
  TX_TRANSITION_ACTOR_SYSTEM,
  TX_TRANSITION_ACTOR_OPERATOR,
];

// b2b transitions
export const TRANSITION_B2B_ACCEPTED_NO_ADDONS_NO_CANCELLATION =
  'transition/b2b-accepted-no-addons-no-cancellation';
export const TRANSITION_B2B_REQUEST_BOOKING_NO_ADDONS = 'transition/b2b-request-booking-no-addons';
export const TRANSITION_B2B_REQUEST_BOOKING = 'transition/b2b-request-booking';
export const TRANSITION_B2B_DISABLE_CANCELLATION_NO_ADDONS =
  'transition/b2b-disable-cancellation-no-addons';
export const TRANSITION_B2B_DISABLE_CANCELLATION = 'transition/b2b-disable-cancellation';
export const TRANSITION_B2B_COMPLETE = 'transition/b2b-complete';
export const TRANSITION_B2B_COMPLETE_NO_ADDONS = 'transition/b2b-complete-no-addons';
export const TRANSITION_B2B_OPERATOR_CANCEL = 'transition/b2b-operator-cancel';
export const TRANSITION_B2B_PROVIDER_CANCEL = 'transition/b2b-provider-cancel';
export const TRANSITION_B2B_CUSTOMER_CANCEL = 'transition/b2b-customer-cancel';

export const TRANSITION_B2B_OPERATOR_CANCEL_NO_ADDONS = 'transition/b2b-operator-cancel-no-addons';
export const TRANSITION_B2B_PROVIDER_CANCEL_NO_ADDONS = 'transition/b2b-provider-cancel-no-addons';
export const TRANSITION_B2B_CUSTOMER_CANCEL_NO_ADDONS = 'transition/b2b-customer-cancel-no-addons';
export const TRANSITION_B2B_OPERATOR_CANCEL_AFTER_MARK_NO_CANCELLATION =
  'transition/b2b-operator-cancel-after-mark-no-cancellation';
export const TRANSITION_B2B_OPERATOR_CANCEL_AFTER_MARK_NO_ADDONS_NO_CANCELLATION =
  'transition/b2b-operator-cancel-after-mark-no-addons-no-cancellation';

export const TRANSITION_B2B_REQUEST_BOOKING_HOURLY = 'transition/b2b-request-booking-hourly';
export const TRANSITION_B2B_REQUEST_BOOKING_HOURLY_NO_ADDONS =
  'transition/b2b-request-booking-hourly-no-addons';

// States
const STATE_INITIAL = 'initial';
const STATE_ENQUIRY = 'enquiry';
const STATE_PENDING_PAYMENT = 'pending-payment';
const STATE_PENDING_PAYMENT_AFTER_ENQUIRY = 'pending-payment-after-enquiry';
const STATE_B2B_PENDING_PAYMENT_AFTER_ENQUIRY = 'b2b-pending-payment-after-enquiry';
const STATE_PAYMENT_EXPIRED = 'payment-expired';
const STATE_PREAUTHORIZED = 'preauthorized';
const STATE_B2B_PREAUTHORIZED = 'b2b-preauthorized';
const STATE_DECLINED = 'declined';
const STATE_ACCEPTED = 'accepted';
const STATE_B2B_ACCEPTED = 'b2b-accepted';
const STATE_B2B_ACCEPTED_NO_ADDONS = 'b2b-accepted-no-addons';
const STATE_ACCEPTED_NO_CANCELLATION = 'accepted-no-cancellation';
const STATE_B2B_ACCEPTED_NO_CANCELLATION = 'b2b-accepted-no-cancellation';
const STATE_B2B_ACCEPTED_NO_ADDONS_NO_CANCELLATION = 'b2b-accepted-no-addons-no-cancellation';
const STATE_CANCELLED = 'cancelled';
const STATE_DELIVERED = 'delivered';
const STATE_B2B_DELIVERED = 'b2b-delivered';
const STATE_REVIEWED = 'reviewed';

const stateDescription = {
  // id: 'flex-default-process/release-1',
  id: 'flex-default-process/b2b-test',
  initial: STATE_INITIAL,
  states: {
    [STATE_INITIAL]: {
      on: {
        [TRANSITION_ENQUIRE]: STATE_ENQUIRY,
        [TRANSITION_B2B_ENQUIRE_NO_ADDONS]: STATE_B2B_PREAUTHORIZED,
        [TRANSITION_B2B_ENQUIRE_HOURLY_NO_ADDONS]: STATE_B2B_PREAUTHORIZED,
        [TRANSITION_REQUEST_PAYMENT]: STATE_PENDING_PAYMENT,
        [TRANSITION_REQUEST_PAYMENT_DAILY]: STATE_PENDING_PAYMENT,
        [TRANSITION_B2B_REQUEST_BOOKING]: STATE_PENDING_PAYMENT,
        [TRANSITION_B2B_REQUEST_BOOKING_NO_ADDONS]: STATE_B2B_ACCEPTED_NO_ADDONS,
        [TRANSITION_B2B_REQUEST_BOOKING_HOURLY]: STATE_PENDING_PAYMENT,
        [TRANSITION_B2B_REQUEST_BOOKING_HOURLY_NO_ADDONS]: STATE_B2B_ACCEPTED_NO_ADDONS,
      },
    },
    [STATE_ENQUIRY]: {
      on: {
        [TRANSITION_REQUEST_PAYMENT_AFTER_ENQUIRY]: STATE_PENDING_PAYMENT_AFTER_ENQUIRY,
        [TRANSITION_REQUEST_PAYMENT_DAILY_AFTER_ENQUIRY]: STATE_PENDING_PAYMENT_AFTER_ENQUIRY,
        [TRANSITION_B2B_ENQUIRE]: STATE_B2B_PENDING_PAYMENT_AFTER_ENQUIRY,
        [TRANSITION_B2B_ENQUIRE_HOURLY]: STATE_B2B_PENDING_PAYMENT_AFTER_ENQUIRY,
      },
    },
    [STATE_PENDING_PAYMENT]: {
      on: {
        [TRANSITION_EXPIRE_PAYMENT]: STATE_PAYMENT_EXPIRED,
        [TRANSITION_CONFIRM_PAYMENT]: STATE_ACCEPTED,
        [TRANSITION_B2B_CONFIRM_PAYMENT]: STATE_B2B_ACCEPTED,
      },
    },
    [STATE_PENDING_PAYMENT_AFTER_ENQUIRY]: {
      on: {
        [TRANSITION_CONFIRM_PAYMENT_AFTER_ENQUIRY]: STATE_PREAUTHORIZED,
        [TRANSITION_EXPIRE_PENDING_PAYMENT]: STATE_PAYMENT_EXPIRED,
      },
    },
    [STATE_B2B_PENDING_PAYMENT_AFTER_ENQUIRY]: {
      on: {
        [TRANSITION_B2B_CONFIRM_PAYMENT_AFTER_ENQUIRY]: STATE_PREAUTHORIZED,
        [TRANSITION_B2B_EXPIRE_PENDING_PAYMENT]: STATE_PAYMENT_EXPIRED,
      },
    },
    [STATE_PAYMENT_EXPIRED]: {
      on: {
        [TRANSITION_EXPIRE_PAYMENT]: STATE_PAYMENT_EXPIRED,
      },
    },
    [STATE_PREAUTHORIZED]: {
      on: {
        [TRANSITION_ACCEPT]: STATE_ACCEPTED,
        [TRANSITION_B2B_ACCEPT]: STATE_B2B_ACCEPTED,
        [TRANSITION_DECLINE]: STATE_DECLINED,
        [TRANSITION_B2B_DECLINE]: STATE_DECLINED,
        [TRANSITION_EXPIRE_PAYMENT_AFTER_ENQUIRY]: STATE_DECLINED,
      },
    },
    [STATE_B2B_PREAUTHORIZED]: {
      on: {
        [TRANSITION_B2B_ACCEPT_NO_ADDONS]: STATE_B2B_ACCEPTED_NO_ADDONS,
        [TRANSITION_B2B_DECLINE_NO_ADDONS]: STATE_DECLINED,
        [TRANSITION_B2B_EXPIRE_NO_ADDONS]: STATE_DECLINED,
      },
    },
    [STATE_DECLINED]: {},
    [STATE_ACCEPTED]: {
      on: {
        [TRANSITION_CUSTOMER_CANCEL]: STATE_CANCELLED,
        [TRANSITION_OPERATOR_CANCEL]: STATE_CANCELLED,
        [TRANSITION_PROVIDER_CANCEL]: STATE_CANCELLED,
        [TRANSITION_DISABLE_CANCELLATION]: STATE_ACCEPTED_NO_CANCELLATION,
      },
    },
    [STATE_B2B_ACCEPTED]: {
      on: {
        [TRANSITION_B2B_DISABLE_CANCELLATION]: STATE_B2B_ACCEPTED_NO_CANCELLATION,
        [TRANSITION_B2B_CUSTOMER_CANCEL]: STATE_CANCELLED,
        [TRANSITION_B2B_OPERATOR_CANCEL]: STATE_CANCELLED,
        [TRANSITION_B2B_PROVIDER_CANCEL]: STATE_CANCELLED,
      },
    },
    [STATE_B2B_ACCEPTED_NO_ADDONS]: {
      on: {
        [TRANSITION_B2B_DISABLE_CANCELLATION_NO_ADDONS]:
          STATE_B2B_ACCEPTED_NO_ADDONS_NO_CANCELLATION,
        [TRANSITION_B2B_CUSTOMER_CANCEL_NO_ADDONS]: STATE_CANCELLED,
        [TRANSITION_B2B_OPERATOR_CANCEL_NO_ADDONS]: STATE_CANCELLED,
        [TRANSITION_B2B_PROVIDER_CANCEL_NO_ADDONS]: STATE_CANCELLED,
      },
    },

    [STATE_CANCELLED]: {},
    [STATE_ACCEPTED_NO_CANCELLATION]: {
      on: {
        [TRANSITION_COMPLETE]: STATE_DELIVERED,
        [TRANSITION_OPERATOR_CANCEL_AFTER_MARK_NO_CANCELLATION]: STATE_CANCELLED,
      },
    },
    [STATE_B2B_ACCEPTED_NO_CANCELLATION]: {
      on: {
        [TRANSITION_B2B_COMPLETE]: STATE_B2B_DELIVERED,
        [TRANSITION_B2B_OPERATOR_CANCEL_AFTER_MARK_NO_CANCELLATION]: STATE_CANCELLED,
      },
    },
    [STATE_B2B_ACCEPTED_NO_ADDONS_NO_CANCELLATION]: {
      on: {
        [TRANSITION_B2B_COMPLETE_NO_ADDONS]: STATE_B2B_DELIVERED,
        [TRANSITION_B2B_OPERATOR_CANCEL_AFTER_MARK_NO_ADDONS_NO_CANCELLATION]: STATE_CANCELLED,
      },
    },
    [STATE_B2B_DELIVERED]: {
      on: {
        [TRANSITION_B2B_REVIEW_BY_CUSTOMER]: STATE_REVIEWED,
        [TRANSITION_B2B_EXPIRE_REVIEW_PERIOD]: STATE_REVIEWED,
      },
    },
    [STATE_DELIVERED]: {
      on: {
        [TRANSITION_EXPIRE_REVIEW_PERIOD]: STATE_REVIEWED,
        [TRANSITION_REVIEW_BY_CUSTOMER]: STATE_REVIEWED,
      },
    },
    [STATE_REVIEWED]: { type: 'final' },
  },
};

// Note: currently we assume that state description doesn't contain nested states.
const statesFromStateDescription = (description) => description.states || {};

// Get all the transitions from states object in an array
const getTransitions = (states) => {
  const stateNames = Object.keys(states);

  const transitionsReducer = (transitionArray, name) => {
    const stateTransitions = states[name] && states[name].on;
    const transitionKeys = stateTransitions ? Object.keys(stateTransitions) : [];
    return [
      ...transitionArray,
      ...transitionKeys.map((key) => ({ key, value: stateTransitions[key] })),
    ];
  };

  return stateNames.reduce(transitionsReducer, []);
};

// This is a list of all the transitions that this app should be able to handle.
export const TRANSITIONS = getTransitions(statesFromStateDescription(stateDescription)).map(
  (t) => t.key
);

// This function returns a function that has given stateDesc in scope chain.
const getTransitionsToStateFn = (stateDesc) => (state) =>
  getTransitions(statesFromStateDescription(stateDesc))
    .filter((t) => t.value === state)
    .map((t) => t.key);

// Get all the transitions that lead to specified state.
const getTransitionsToState = getTransitionsToStateFn(stateDescription);

// This is needed to fetch transactions that need response from provider.
// I.e. transactions which provider needs to accept or decline
export const transitionsToRequested = getTransitionsToState(STATE_PREAUTHORIZED);

/**
 * Helper functions to figure out if transaction is in a specific state.
 * State is based on lastTransition given by transaction object and state description.
 */

export const txLastTransition = (tx) => ensureTransaction(tx).attributes.lastTransition;

export const txIsEnquired = (tx) =>
  getTransitionsToState(STATE_ENQUIRY).includes(txLastTransition(tx));

export const txIsPaymentPending = (tx) =>
  getTransitionsToState(STATE_PENDING_PAYMENT).includes(txLastTransition(tx));

export const txIsPaymentExpired = (tx) =>
  [
    TRANSITION_EXPIRE_PAYMENT,
    TRANSITION_EXPIRE_PAYMENT_AFTER_ENQUIRY,
    TRANSITION_EXPIRE_PENDING_PAYMENT,
    TRANSITION_B2B_EXPIRE_PENDING_PAYMENT,
    TRANSITION_B2B_EXPIRE_NO_ADDONS,
  ].includes(txLastTransition(tx));

export const txIsRequested = (tx) =>
  [
    ...getTransitionsToState(STATE_PREAUTHORIZED),
    ...getTransitionsToState(STATE_B2B_PREAUTHORIZED),
  ].includes(txLastTransition(tx));

export const txIsAccepted = (tx) =>
  [
    STATE_ACCEPTED,
    TRANSITION_ACCEPT,
    TRANSITION_B2B_ACCEPT,
    TRANSITION_B2B_ACCEPT_NO_ADDONS,
    TRANSITION_CONFIRM_PAYMENT,
    TRANSITION_B2B_CONFIRM_PAYMENT,
    TRANSITION_DISABLE_CANCELLATION,
    TRANSITION_B2B_DISABLE_CANCELLATION_NO_ADDONS,
    TRANSITION_B2B_DISABLE_CANCELLATION,
    TRANSITION_B2B_REQUEST_BOOKING_HOURLY_NO_ADDONS,
    TRANSITION_B2B_REQUEST_BOOKING_NO_ADDONS,
  ].includes(txLastTransition(tx));

export const txIsDeclined = (tx) =>
  getTransitionsToState(STATE_DECLINED).includes(txLastTransition(tx));

export const txIsCanceled = (tx) =>
  getTransitionsToState(STATE_CANCELLED).includes(txLastTransition(tx));

export const txIsDelivered = (tx) =>
  [
    STATE_DELIVERED,
    STATE_B2B_DELIVERED,
    TRANSITION_COMPLETE,
    TRANSITION_B2B_COMPLETE,
    TRANSITION_B2B_COMPLETE_NO_ADDONS,
  ].includes(txLastTransition(tx));

export const txIsReviewed = (tx) =>
  getTransitionsToState(STATE_REVIEWED).includes(txLastTransition(tx));

export const b2bTxIsAccepted = (tx) =>
  [
    ...getTransitionsToState(STATE_B2B_ACCEPTED_NO_ADDONS),
    ...getTransitionsToState(STATE_B2B_ACCEPTED),
    TRANSITION_B2B_DISABLE_CANCELLATION_NO_ADDONS,
    TRANSITION_B2B_DISABLE_CANCELLATION,
  ].includes(txLastTransition(tx));

/**
 * Helper functions to figure out if transaction has passed a given state.
 * This is based on transitions history given by transaction object.
 */

const txTransitions = (tx) => ensureTransaction(tx).attributes.transitions || [];
const hasPassedTransition = (transitionName, tx) =>
  !!txTransitions(tx).find((t) => t.transition === transitionName);

const hasPassedStateFn = (state) => (tx) =>
  getTransitionsToState(state).filter((t) => hasPassedTransition(t, tx)).length > 0;

export const txHasBeenAccepted = hasPassedStateFn(STATE_ACCEPTED);
export const txHasBeenDelivered = hasPassedStateFn(STATE_DELIVERED);
export const txB2BHasBeenDelivered = hasPassedStateFn(STATE_B2B_DELIVERED);

/**
 * Other transaction related utility functions
 */

export const transitionIsReviewed = (transition) =>
  getTransitionsToState(STATE_REVIEWED).includes(transition);

export const isRelevantPastTransition = (transition) => {
  return [
    TRANSITION_CONFIRM_PAYMENT,
    TRANSITION_B2B_CONFIRM_PAYMENT,
    TRANSITION_B2B_REQUEST_BOOKING_NO_ADDONS,
    TRANSITION_B2B_REQUEST_BOOKING_HOURLY_NO_ADDONS,
    TRANSITION_CUSTOMER_CANCEL,
    TRANSITION_PROVIDER_CANCEL,
    TRANSITION_OPERATOR_CANCEL,
    TRANSITION_B2B_OPERATOR_CANCEL,
    TRANSITION_B2B_PROVIDER_CANCEL,
    TRANSITION_B2B_CUSTOMER_CANCEL,
    TRANSITION_B2B_OPERATOR_CANCEL_NO_ADDONS,
    TRANSITION_B2B_PROVIDER_CANCEL_NO_ADDONS,
    TRANSITION_B2B_CUSTOMER_CANCEL_NO_ADDONS,
    TRANSITION_OPERATOR_CANCEL_AFTER_MARK_NO_CANCELLATION,
    TRANSITION_B2B_OPERATOR_CANCEL_AFTER_MARK_NO_CANCELLATION,
    TRANSITION_B2B_OPERATOR_CANCEL_AFTER_MARK_NO_ADDONS_NO_CANCELLATION,
    TRANSITION_COMPLETE,
    TRANSITION_B2B_COMPLETE,
    TRANSITION_B2B_COMPLETE_NO_ADDONS,
    TRANSITION_REVIEW_BY_CUSTOMER,
    TRANSITION_B2B_REVIEW_BY_CUSTOMER,
    TRANSITION_ACCEPT,
    TRANSITION_DECLINE,
    TRANSITION_ENQUIRE,
    TRANSITION_B2B_ENQUIRE_NO_ADDONS,
    TRANSITION_B2B_ENQUIRE_HOURLY_NO_ADDONS,
    TRANSITION_CONFIRM_PAYMENT_AFTER_ENQUIRY,
    TRANSITION_EXPIRE_PAYMENT_AFTER_ENQUIRY,
    TRANSITION_B2B_CONFIRM_PAYMENT_AFTER_ENQUIRY,
    TRANSITION_EXPIRE_PENDING_PAYMENT,
    TRANSITION_B2B_EXPIRE_PENDING_PAYMENT,
    TRANSITION_B2B_ACCEPT,
    TRANSITION_B2B_DECLINE,
    TRANSITION_B2B_ACCEPT_NO_ADDONS,
    TRANSITION_B2B_DECLINE_NO_ADDONS,
    TRANSITION_B2B_EXPIRE_NO_ADDONS,
  ].includes(transition);
};

export const getUserTxRole = (currentUserId, transaction) => {
  const tx = ensureTransaction(transaction);
  const customer = tx.customer;
  if (currentUserId && currentUserId.uuid && tx.id && customer.id) {
    // user can be either customer or provider
    return currentUserId.uuid === customer.id.uuid
      ? TX_TRANSITION_ACTOR_CUSTOMER
      : TX_TRANSITION_ACTOR_PROVIDER;
  } else {
    throw new Error(`Parameters for "userIsCustomer" function were wrong.
      currentUserId: ${currentUserId}, transaction: ${transaction}`);
  }
};

export const txRoleIsProvider = (userRole) => userRole === TX_TRANSITION_ACTOR_PROVIDER;
export const txRoleIsCustomer = (userRole) => userRole === TX_TRANSITION_ACTOR_CUSTOMER;

// Check if the given transition is privileged.
//
// Privileged transitions need to be handled from a secure context,
// i.e. the backend. This helper is used to check if the transition
// should go through the local API endpoints, or if using JS SDK is
// enough.
export const isPrivileged = (transition) => {
  return [
    TRANSITION_REQUEST_PAYMENT,
    TRANSITION_REQUEST_PAYMENT_AFTER_ENQUIRY,
    TRANSITION_REQUEST_PAYMENT_DAILY,
    TRANSITION_REQUEST_PAYMENT_DAILY_AFTER_ENQUIRY,
    TRANSITION_B2B_REQUEST_BOOKING_HOURLY,
    TRANSITION_B2B_REQUEST_BOOKING_HOURLY_NO_ADDONS,
    TRANSITION_B2B_REQUEST_BOOKING,
    TRANSITION_B2B_REQUEST_BOOKING_NO_ADDONS,
    TRANSITION_B2B_ENQUIRE,
    TRANSITION_B2B_ENQUIRE_HOURLY,
    TRANSITION_B2B_ENQUIRE_NO_ADDONS,
    TRANSITION_B2B_ENQUIRE_HOURLY_NO_ADDONS,
  ].includes(transition);
};

export const customerHasLeftReview = (tx) =>
  [TRANSITION_REVIEW_BY_CUSTOMER, TRANSITION_B2B_REVIEW_BY_CUSTOMER].reduce(
    (prev, cur) => prev || hasPassedTransition(cur, tx)
  );

export const shouldShowCancelButton = (tx) =>
  [
    TRANSITION_CONFIRM_PAYMENT,
    TRANSITION_B2B_CONFIRM_PAYMENT,
    TRANSITION_B2B_REQUEST_BOOKING_HOURLY_NO_ADDONS,
    TRANSITION_B2B_REQUEST_BOOKING_NO_ADDONS,
    TRANSITION_ACCEPT,
    TRANSITION_B2B_ACCEPT,
    TRANSITION_B2B_ACCEPT_NO_ADDONS,
  ].includes(txLastTransition(tx));

export const B2B_TRANSITIONS = [
  TRANSITION_B2B_REQUEST_BOOKING_NO_ADDONS,
  TRANSITION_B2B_REQUEST_BOOKING,
  TRANSITION_B2B_DISABLE_CANCELLATION_NO_ADDONS,
  TRANSITION_B2B_DISABLE_CANCELLATION,
  TRANSITION_B2B_COMPLETE_NO_ADDONS,
  TRANSITION_B2B_OPERATOR_CANCEL,
  TRANSITION_B2B_PROVIDER_CANCEL,
  TRANSITION_B2B_CUSTOMER_CANCEL,
  TRANSITION_B2B_OPERATOR_CANCEL_AFTER_MARK_NO_CANCELLATION,
  TRANSITION_B2B_REQUEST_BOOKING_HOURLY,
  TRANSITION_B2B_REQUEST_BOOKING_HOURLY_NO_ADDONS,
  TRANSITION_B2B_REVIEW_BY_CUSTOMER,
  TRANSITION_B2B_EXPIRE_REVIEW_PERIOD,
  TRANSITION_B2B_CONFIRM_PAYMENT,
  TRANSITION_B2B_COMPLETE,
  TRANSITION_B2B_OPERATOR_CANCEL_AFTER_MARK_NO_ADDONS_NO_CANCELLATION,
  TRANSITION_B2B_CONFIRM_PAYMENT_AFTER_ENQUIRY,
  TRANSITION_B2B_ACCEPT,
  TRANSITION_B2B_ACCEPT_NO_ADDONS,
  TRANSITION_B2B_ENQUIRE,
  TRANSITION_B2B_ENQUIRE_HOURLY,
  TRANSITION_B2B_ENQUIRE_NO_ADDONS,
  TRANSITION_B2B_ENQUIRE_HOURLY_NO_ADDONS,
  TRANSITION_B2B_EXPIRE_NO_ADDONS,
  TRANSITION_B2B_EXPIRE_PENDING_PAYMENT,
  TRANSITION_B2B_DECLINE,
  TRANSITION_B2B_DECLINE_NO_ADDONS,
];

export const isB2bTx = (tx) =>
  B2B_TRANSITIONS.reduce((prev, cur) => prev || hasPassedTransition(cur, tx), '');
