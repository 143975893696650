import React, { useEffect, useRef } from 'react';
import classNames from 'classnames';
import { arrayOf, bool, func, node, oneOf, oneOfType, shape, string } from 'prop-types';
import { BottomSheet as BottomSheetNative } from 'react-spring-bottom-sheet';

import { HubspotController } from '../../util/hubspot';
import { ButtonNew } from '../index';
import { inBrowser, saveDeviceVW } from '../../util/device';
import { BUTTON_SIZES, BUTTON_TYPES, LINK_COLORS } from '../ButtonNew/ButtonNew';

import css from './BottomSheet.module.scss';

const BottomSheet = ({
  title,
  isOpen,
  onClose,
  buttonList,
  children,
  singleButtonLeft,
  showFooter,
  noPadding,
  className,
}) => {
  if (buttonList.length > 2) {
    console.error(
      `[ERROR]: buttonList array should be max 2 items. Got ${buttonList.length} instead`
    );
    buttonList = buttonList.slice(0, 2);
  }

  const bottomSheetRef = useRef(null);
  const hubspotController = new HubspotController();

  useEffect(() => {
    if (inBrowser()) {
      const bottomSheet = bottomSheetRef.current;
      !!bottomSheet && saveDeviceVW();

      if (isOpen) {
        hubspotController.hide();
        document.documentElement.classList.add('modalOpen');
      } else {
        hubspotController.show();
        document.documentElement.classList.remove('modalOpen');
      }
    }
  }, [isOpen]);

  return (
    <BottomSheetNative className={className} open={isOpen} onDismiss={onClose}>
      <div className={css.container}>
        <div
          data-bottom-sheet-content="true"
          className={classNames(
            css.content,
            !showFooter && css.withoutFooter,
            noPadding && css.withoutPadding
          )}
        >
          {!!title ? (
            <div className={css.header}>
              <h5 className={css.title}>{title}</h5>
            </div>
          ) : null}
          <div>{children}</div>
        </div>
        {showFooter && (
          <div
            className={classNames(
              css.footer,
              buttonList.length === 1 && !singleButtonLeft && css.singleButton
            )}
          >
            {Array.isArray(buttonList) && buttonList.length
              ? buttonList.map((btn, index) => (
                  <ButtonNew
                    mobileButtonPosition={index === 1 && 'right'}
                    type={btn.buttonType}
                    key={index}
                    {...btn}
                  >
                    {btn.text}
                  </ButtonNew>
                ))
              : null}
          </div>
        )}
      </div>
    </BottomSheetNative>
  );
};

BottomSheet.defaultProps = {
  title: null,
  showFooter: true,
};

BottomSheet.propTypes = {
  title: oneOfType([node, string]),
  isOpen: bool.isRequired,
  onClose: func.isRequired,
  buttonList: arrayOf(
    shape({
      buttonType: oneOf(BUTTON_TYPES.LIST),
      buttonColor: oneOf(LINK_COLORS.LIST),
      size: oneOf(BUTTON_SIZES.LIST),
      text: string.isRequired,
      onClick: func.isRequired,
    })
  ).isRequired,
  showFooter: bool,
};

export default BottomSheet;
