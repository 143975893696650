/**
 * Renders non-reversal line items that are not listed in the
 * `LINE_ITEMS` array in util/types.js
 *
 * The line items are rendered so that the line item code is formatted to human
 * readable form and the line total is printed as price.
 *
 * If you require another kind of presentation for your line items, add them to
 * the `LINE_ITEMS` array in util/types.js and create a specific line item
 * component for them that can be used in the `BookingBreakdown` component.
 */
import React from 'react';
import { priceData } from '../../util/currency';
import { humanizeLineItemCode } from '../../util/data';
import { intlShape } from '../../util/reactIntl';
import { LINE_ITEMS, propTypes } from '../../util/types';

import css from './BookingBreakdown.module.scss';

const LineItemUnknownItemsMaybe = (props) => {
  const { transaction, isProvider, intl } = props;
  const listing = transaction.listing;
  const { additionalServices } = listing?.attributes.publicData || {};
  // resolve unknown non-reversal line items
  const allItems = transaction.attributes.lineItems.filter(
    (item) => LINE_ITEMS.indexOf(item.code) === -1 && !item.reversal
  );

  const items = isProvider
    ? allItems.filter((item) => item.includeFor.includes('provider'))
    : allItems.filter((item) => item.includeFor.includes('customer'));

  const additionalServiceLabel = intl.formatMessage({
    id: 'BookingBreakdown.additionalServiceLabel',
  });

  return items.length > 0 ? (
    <>
      {items.map((item, i) => {
        const quantity = item.quantity;
        const seats = item.seats;
        const units = item.units;
        const isAdditionalService = item.code.includes('-fee');
        let vatPercentage = 0;
        if (isAdditionalService && additionalServices) {
          const currentService = additionalServices.filter((l) => item.code.includes(l.name));
          vatPercentage = currentService[0].vatPercentage;
        }

        const label =
          seats && seats > 1
            ? `${humanizeLineItemCode(item.code)} * ${units} days * ${seats} seats`
            : quantity && quantity > 1
            ? `${humanizeLineItemCode(item.code)} * ${quantity}`
            : humanizeLineItemCode(item.code);
        const formattedLabel = label.split(' ').slice(0, -1).join(' ');
        const { formattedPrice } = priceData(item.lineTotal, vatPercentage, intl) || {};

        return (
          <React.Fragment key={`${i}-item.code`}>
            <div className={css.lineItem}>
              <span className={css.itemLabel}>
                {`${isAdditionalService && additionalServiceLabel}: ${formattedLabel}`}
              </span>
              <span className={css.itemValue}>{formattedPrice}</span>
            </div>
            <hr className={css.totalDivider} />
          </React.Fragment>
        );
      })}
    </>
  ) : null;
};

LineItemUnknownItemsMaybe.propTypes = {
  transaction: propTypes.transaction.isRequired,
  intl: intlShape.isRequired,
};

export default LineItemUnknownItemsMaybe;
