import React, { useEffect, useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { authenticationInProgress, login, signup } from '../../ducks/Auth.duck';
import { LoginForm, SignupForm } from '../../forms';
import routeConfiguration from '../../routeConfiguration';
import { apiBaseUrl, hubspotSubscribeUser, hubspotCreateUpdateUser } from '../../util/api';
import { withViewport } from '../../util/contextHelpers';
import { isSignupEmailTakenError } from '../../util/errors';
import { usePrevious } from '../../util/hooks';
import { pathByRouteName } from '../../util/routes';
import { SocialLoginButton } from '../Button/Button';

import { FacebookLogo, GoogleLogo } from '../../containers/AuthenticationPage/socialLoginLogos';
import LogoIcon from '../LogoIcon/LogoIcon';
import Modal from '../Modal/Modal';
import css from './AuthenticationModal.module.scss';

const AuthenticationModal = ({ isOpen, onClose, viewport, history }) => {
  const { width } = viewport || {};
  const [closeClass, setCloseClass] = useState(css.close);
  const previousState = usePrevious(isOpen);
  const isMobile = useMemo(() => width < 1024, [width]);

  useEffect(() => {
    if (!isOpen && previousState) {
      if (isMobile) {
        setCloseClass(css.close);
      } else {
        setCloseClass(() => css.closeAnimation);
        setTimeout(() => {
          setCloseClass(() => css.close);
        }, 1000);
      }
    }
  }, [isOpen, previousState, isMobile]);

  const loginError = useSelector((state) => state.Auth.loginError);
  const signupError = useSelector((state) => state.Auth.signupError);
  const isAuthenticated = useSelector((state) => state.Auth.isAuthenticated);

  const [tab, setTab] = useState('login');
  const isLogin = tab === 'login';
  //  Probably need to update this when the new filters work is merged.
  let currentLocation = typeof window !== 'undefined' ? window.location.pathname : null;
  const currentSearchString = typeof window !== 'undefined' ? window.location.search : null;

  //make sure that you are forwarded to the app page if loggin in with social login
  if (currentLocation === '/') {
    currentLocation = '/app/';
  }
  const from = `${currentLocation}${currentSearchString}`;

  const authInProgress = useSelector(authenticationInProgress);
  const dispatch = useDispatch();
  const submitLogin = ({ email, password }) => dispatch(login(email, password));
  const handleSubmitSignup = async (values) => {
    const { fname, lname, email, sendUpdateContent, ...rest } = values;
    const params = { firstName: fname.trim(), lastName: lname.trim(), email, ...rest };
    const hubspotParams = {
      email,
      firstName: fname.trim(),
      lastName: lname.trim(),
    };
    const signupResponse = await dispatch(signup(params));
    if (!(signupResponse && signupResponse.type === 'error')) {
      await hubspotCreateUpdateUser(hubspotParams);
      !!sendUpdateContent && hubspotSubscribeUser({ email: email });
    }
  };

  useEffect(() => {
    if (isAuthenticated && isOpen) {
      onClose();

      // if the user is logged in while at landing page, redirect to the app page
      if (currentLocation === '/app/') {
        const path = pathByRouteName('LandingPageLoggedIn', routeConfiguration());
        history && history.replace(path);
      }
    }
  }, [isAuthenticated, onClose, isOpen, history]);
  // Social login buttons
  const showFacebookLogin = !!process.env.REACT_APP_FACEBOOK_APP_ID;
  const showGoogleLogin = !!process.env.REACT_APP_GOOGLE_CLIENT_ID;
  const showSocialLogins = showFacebookLogin || showGoogleLogin;
  const facebookButtonText = isLogin ? (
    <FormattedMessage id="AuthenticationPage.loginWithFacebook" />
  ) : (
    <FormattedMessage id="AuthenticationPage.signupWithFacebook" />
  );
  const googleButtonText = isLogin ? (
    <FormattedMessage id="AuthenticationPage.loginWithGoogle" />
  ) : (
    <FormattedMessage id="AuthenticationPage.signupWithGoogle" />
  );
  const getDefaultRoutes = () => {
    const routes = routeConfiguration();
    const baseUrl = apiBaseUrl();

    // Route where the user should be returned after authentication
    // This is used e.g. with EditListingPage and ListingPage
    const fromParam = from ? `from=${from}` : '';

    // Default route where user is returned after successfull authentication
    const defaultReturn = pathByRouteName('LandingPageLoggedIn', routes);
    const defaultReturnParam = defaultReturn ? `&defaultReturn=${defaultReturn}` : '';

    // Route for confirming user data before creating a new user
    const defaultConfirm = pathByRouteName('ConfirmPage', routes);
    const defaultConfirmParam = defaultConfirm ? `&defaultConfirm=${defaultConfirm}` : '';

    return { baseUrl, fromParam, defaultReturnParam, defaultConfirmParam };
  };
  const authWithFacebook = () => {
    const defaultRoutes = getDefaultRoutes();
    const { baseUrl, fromParam, defaultReturnParam, defaultConfirmParam } = defaultRoutes;
    window.location.href = `${baseUrl}/api/auth/facebook?${fromParam}${defaultReturnParam}${defaultConfirmParam}`;
  };

  const authWithGoogle = () => {
    const defaultRoutes = getDefaultRoutes();
    const { baseUrl, fromParam, defaultReturnParam, defaultConfirmParam } = defaultRoutes;
    window.location.href = `${baseUrl}/api/auth/google?${fromParam}${defaultReturnParam}${defaultConfirmParam}`;
  };

  const socialLoginButtonsMaybe = showSocialLogins ? (
    <div className={css.idpButtons}>
      {showFacebookLogin ? (
        <div className={css.socialButtonWrapper}>
          <SocialLoginButton className={css.socialButton} onClick={() => authWithFacebook()}>
            <span className={css.buttonIcon}>{FacebookLogo}</span>
            {facebookButtonText}
          </SocialLoginButton>
        </div>
      ) : null}

      {showGoogleLogin ? (
        <div className={css.socialButtonWrapper}>
          <SocialLoginButton className={css.socialButton} onClick={() => authWithGoogle()}>
            <span className={css.buttonIcon}>{GoogleLogo}</span>
            {googleButtonText}
          </SocialLoginButton>
        </div>
      ) : null}
      <div className={css.socialButtonsOr}>
        <span className={css.socialButtonsOrText}>
          <FormattedMessage id="AuthenticationPage.or" />
        </span>
      </div>
    </div>
  ) : null;

  const loginErrorMessage = (
    <div className={css.error}>
      <FormattedMessage id="AuthenticationPage.loginFailed" />
    </div>
  );

  const signupErrorMessage = (
    <div className={css.error}>
      {isSignupEmailTakenError(signupError) ? (
        <FormattedMessage id="AuthenticationPage.signupFailedEmailAlreadyTaken" />
      ) : (
        <FormattedMessage id="AuthenticationPage.signupFailed" />
      )}
    </div>
  );

  const errorMessage = (error, message) => (error ? message : null);
  const loginOrSignupError = isLogin
    ? errorMessage(loginError, loginErrorMessage)
    : errorMessage(signupError, signupErrorMessage);

  return (
    <Modal
      id="authenticationModal"
      isOpen={isOpen}
      onClose={onClose}
      closeButtonMessage=" "
      containerClassName={css.container}
      scrollLayerClassName={css.scrollLayer}
      isClosedClassName={closeClass}
    >
      <div className={css.content}>
        <div className={css.heading}>
          <LogoIcon />
          <div className={css.headingText}>
            {isLogin ? (
              <FormattedMessage id="AuthenticationPage.loginHeading" />
            ) : (
              <FormattedMessage id="AuthenticationPage.createAccountHeading" />
            )}
          </div>
        </div>
        <div className={css.loginFormWrapper}>{socialLoginButtonsMaybe}</div>
        {loginOrSignupError}
        {isLogin ? (
          <LoginForm
            className={css.form}
            onSubmit={submitLogin}
            inProgress={authInProgress}
            onSignUp={() => setTab('signUp')}
          />
        ) : (
          <SignupForm
            className={css.form}
            onSubmit={handleSubmitSignup}
            inProgress={authInProgress}
            onBack={() => setTab('login')}
          />
        )}
      </div>
    </Modal>
  );
};

export default withViewport(AuthenticationModal);
