/**
 * This is a wrapper component for different Layouts. Main content should be added to this wrapper.
 */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { isMobile } from '../../util/device';
import css from './LayoutWrapperMain.module.scss';

const LayoutWrapperMain = (props) => {
  const { className, rootClassName, children, hideTopbarSpacer } = props;
  const classes = classNames(rootClassName || css.root, className);

  return (
    <div className={classes} role="main">
      {/* this div is a spacer that takes up the same amount of space as the sticky topbar */}
      {/* needs to be hidden for certain mobile pages - bookings, settings */}
      {(!hideTopbarSpacer || !isMobile) && <div className={css.topBarSpacer}></div>}
      {children}
    </div>
  );
};

LayoutWrapperMain.defaultProps = {
  className: null,
  rootClassName: null,
  hideTopbarSpacer: false,
};

const { bool, node, string } = PropTypes;

LayoutWrapperMain.propTypes = {
  children: node.isRequired,
  className: string,
  rootClassName: string,
  hideTopbarSpacer: bool,
};

export default LayoutWrapperMain;
