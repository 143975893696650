import React from 'react';
import unitedImg from './assets/partnerLogos/UnitedSpaces.png';
import niamImg from './assets/partnerLogos/NIam.png';
import newsecImg from './assets/partnerLogos/newsec.png';
import weworkImg from './assets/partnerLogos/wework.png';
import croisetteImg from './assets/partnerLogos/croisette.png';
import no18Img from './assets/partnerLogos/no18.png';
import regusImg from './assets/partnerLogos/Regus.png';
import helioImg from './assets/partnerLogos/helio.png';

import css from './PartnerProffessional.module.scss';

const SectionPartners = ({ intl }) => {
  return (
    <section className={css.sectionPartners}>
      <h2>{intl.formatMessage({ id: 'PartnerProffessional.SectionPartners.title' })}</h2>
      <div className={css.logoWrapper}>
        <img src={unitedImg} alt="United Spaces" className={css.logo} />
        <img src={niamImg} alt="Niam" className={css.logo} />
        <img src={newsecImg} alt="Newsec" className={css.logo} />
        <img src={weworkImg} alt="wework" className={css.logo} />
        <img src={croisetteImg} alt="Croisette" className={css.logo} />
        <img src={no18Img} alt="no18" className={css.logo} />
        <img src={regusImg} alt="Regus" className={css.logo} />
        <img src={helioImg} alt="Helio" className={css.logo} />
      </div>
    </section>
  );
};

export default SectionPartners;
