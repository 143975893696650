import React, { useState, useEffect, useCallback, useMemo } from 'react';
import moment from 'moment';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { array, bool, func, object } from 'prop-types';

import { TopbarContainer } from '../index';
import {
  Page,
  LayoutSingleColumn,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  Footer,
} from '../../components';
import { ImageGridWithSlider } from '../../components_new';

import {
  loadData as loadListingData,
  fetchTimeSlots,
  fetchTransactionLineItems,
} from '../ListingPage/ListingPage.duck';
import { setInitialValues, loadData } from './LocationPage.duck';
import { initializeCardPaymentData } from '../../ducks/stripe.duck';
import { getMarketplaceEntities } from '../../ducks/marketplaceData.duck';

import config from '../../config';
import routeConfiguration from '../../routeConfiguration';
import { getLocale } from '../../util/localeHelper';
import { createSlug, parse } from '../../util/urlHelpers';
import { priceData } from '../../util/currency';
import { types as sdkTypes } from '../../util/sdkLoader';
import { getUnitTranslationKey } from '../../util/data';
import {
  DAILY_BOOKING,
  HOURLY_BOOKING,
  MONTHLY_BOOKING,
  FIXED_OFFICE_CATEGORY,
  propTypes,
} from '../../util/types';
import { calculateQuantity, dateFromLocalToAPI, timestampToDate } from '../../util/dates';
import { createResourceLocatorString, findRouteByRouteName } from '../../util/routes';
import { isCompanyUser, isEmployeeUser } from '../../util/b2bHelper';
import { manageDisableScrolling, isScrollingDisabled } from '../../ducks/UI.duck';

import { findOptionsForSelectFilter } from '../../util/search';
import SectionHeader from './SectionHeader';
import SectionDescription from './SectionDescription';
import SectionListings from './SectionListings';
import SectionOpeningHours from './SectionOpeningHours';
import SectionQuestions from './SectionQuestions';
import SectionMap from './SectionMap';
import SectionBookingPanel from './SectionBookingPanel';
import { PageLoading } from './ui';

import css from './LocationPage.module.scss';

const { Money, UUID } = sdkTypes;

const prepareBookingTimes = ({
  bookingType,
  month,
  startDate,
  endDate,
  bookingStartTime,
  bookingEndTime,
}) => {
  let selectedStart, selectedEnd;

  switch (bookingType) {
    case MONTHLY_BOOKING:
      selectedStart = month.start;
      selectedEnd = moment(month.end).add(1, 'second').set('millisecond', 0).toDate();
      break;
    case DAILY_BOOKING:
      selectedStart = startDate;
      selectedEnd = endDate;
      break;
    case HOURLY_BOOKING:
      selectedStart = bookingStartTime;
      selectedEnd = bookingEndTime;
      break;
    default:
      break;
  }

  if ([MONTHLY_BOOKING, DAILY_BOOKING].includes(bookingType)) {
    return {
      bookingStart: dateFromLocalToAPI(selectedStart),
      bookingEnd: dateFromLocalToAPI(selectedEnd),
    };
  }

  return {
    bookingStart: timestampToDate(selectedStart),
    bookingEnd: timestampToDate(selectedEnd),
  };
};

const LocationPage = () => {
  const intl = useIntl();
  const history = useHistory();
  const { id, slug } = useParams();
  const dispatch = useDispatch();
  const {
    locationInfo,
    listingSearchedFor,
    currentUser,
    companyAccount,
    favoritesArray,
    bookingData,
    lineItems,
    monthlyTimeSlots,
    timeSlots,
    fetchLineItemsInProgress,
    fetchLineItemsError,
    fetchTimeSlotsError,
    scrollingDisabled,
    locationListings,
    getListing,
  } = useSelector((state) => {
    const { locationInfo, listingSearchedFor, locationListings } = state.LocationPage;
    const { currentUser, companyAccount, favoritesArray } = state.user;
    const {
      bookingData,
      lineItems,
      monthlyTimeSlots,
      timeSlots,
      fetchLineItemsInProgress,
      fetchLineItemsError,
      fetchTimeSlotsError,
    } = state.ListingPage;

    const getListing = (id) => {
      const ref = { id, type: 'listing' };
      const listings = getMarketplaceEntities(state, [ref]);
      return listings.length === 1 ? listings[0] : null;
    };

    return {
      locationInfo,
      listingSearchedFor,
      currentUser,
      companyAccount,
      favoritesArray,
      bookingData,
      lineItems,
      monthlyTimeSlots,
      timeSlots,
      fetchLineItemsInProgress,
      fetchLineItemsError,
      fetchTimeSlotsError,
      scrollingDisabled: isScrollingDisabled(state),
      locationListings,
      getListing,
    };
  }, shallowEqual);

  const onManageDisableScrolling = useCallback(
    (componentId, disableScrolling) => {
      dispatch(manageDisableScrolling(componentId, disableScrolling));
    },
    [dispatch]
  );

  const [selectedListing, setSelectedListing] = useState({});
  const [listForListingDropdown, setListForListingDropdown] = useState([]);

  const location = useLocation();
  const { category, address } = parse(location.search, {
    latlng: ['origin'],
    latlngBounds: ['bounds'],
  });

  useEffect(() => {
    if (listingSearchedFor) {
      setSelectedListing(listingSearchedFor);
    }
  }, [listingSearchedFor]);

  const isFavorite = favoritesArray && favoritesArray?.includes(locationInfo.id);

  useEffect(() => {
    if (Array.isArray(locationListings?.data)) {
      const filterConfig = config.custom.filters;
      const fixedOfficeTypeOptions = findOptionsForSelectFilter('fixedOfficeType', filterConfig);

      setListForListingDropdown(
        locationListings?.data.reduce((acc, curr) => {
          const fixedOfficeTypeTranslation =
            curr?.attributes?.publicData?.category === FIXED_OFFICE_CATEGORY &&
            curr?.attributes?.publicData?.fixedOfficeType
              ? fixedOfficeTypeOptions.find(
                  (o) => o.key === curr?.attributes?.publicData?.fixedOfficeType
                )
              : null;

          acc.push({
            id: curr.id.uuid,
            slug: createSlug(curr.attributes.title),
            category: curr.attributes.publicData.category,
            categoryTranslation: intl.formatMessage({
              id: `MarketplaceConfig.filters.category.${curr.attributes.publicData.category}`,
            }),
            fixedOfficeTypeTranslation:
              fixedOfficeTypeTranslation &&
              intl.formatMessage({
                id: fixedOfficeTypeTranslation.labelId,
              }),
            price: curr.attributes.publicData.requestPrice
              ? intl.formatMessage({ id: 'LocationCard.requestPrice' })
              : priceData(
                  new Money(curr.attributes.price.amount, curr.attributes.price.currency),
                  curr.attributes.publicData.vatPercentage,
                  intl
                ).formattedPrice,
            unitTranslationKey: curr.attributes.publicData.requestPrice
              ? ''
              : intl.formatMessage({ id: getUnitTranslationKey(curr) }),
            minSeats: curr.attributes.publicData.minSeats,
            maxSeats: curr.attributes.publicData.maxSeats,
          });

          return acc;
        }, [])
      );
    }
  }, [locationListings]);

  useEffect(() => {
    selectedListing?.id && dispatch(loadListingData(selectedListing));
  }, [selectedListing]);

  const selectListing = useMemo(
    () =>
      ({ id, slug }) => {
        setSelectedListing(id === selectedListing.id ? '' : { id, slug });
      },
    [selectedListing.id]
  );

  const handleSubmit = useCallback(
    (values) => {
      const { id: listingId, slug: listingSlug } = selectedListing;
      const listingFound = getListing(new UUID(listingId));

      const {
        attributes: {
          publicData: { bookingType },
        },
      } = listingFound;

      const {
        bookingStartTime,
        bookingEndTime,
        month,
        startDate,
        endDate,
        seats,
        ...restOfValues
      } = values;

      const { bookingStart, bookingEnd } = prepareBookingTimes({
        bookingType,
        bookingStartTime,
        bookingEndTime,
        month,
        startDate,
        endDate,
      });

      const type =
        bookingType === MONTHLY_BOOKING
          ? 'months'
          : bookingType === DAILY_BOOKING
          ? 'days'
          : 'hours';

      const bookingData = {
        units: calculateQuantity({
          startDate: bookingStart,
          endDate: bookingEnd,
          type,
        }),
        seats: +seats,
        bookingType,
        ...restOfValues,
      };

      const initialValues = {
        locationName: locationInfo.name,
        locationId: locationInfo.id,
        listing: listingFound,
        bookingDates: {
          bookingStart,
          bookingEnd,
        },
        confirmPaymentError: null,
        bookingData,
      };

      const shouldSaveToSessionStorage = !currentUser;

      const routes = routeConfiguration();
      const { setInitialValues } = findRouteByRouteName('CheckoutPage', routes);
      dispatch(setInitialValues(initialValues, shouldSaveToSessionStorage));

      const filteredLineItems =
        lineItems && lineItems.filter((item) => !item.code.includes('commission'));
      const total =
        lineItems &&
        filteredLineItems.map((item) => item.lineTotal.amount).reduce((acc, val) => acc + val) /
          100;

      if (typeof window !== 'undefined' && !config.dev) {
        if (window.fbq != null) {
          window.fbq('track', 'InitiateCheckout', {
            content_category: listingFound.attributes.title,
            contents: filteredLineItems,
            currency: (lineItems && lineItems[0].lineTotal.currency) || 'SEK',
            value: total || 0,
          });
        }
      }

      dispatch(initializeCardPaymentData());

      history.push(
        createResourceLocatorString(
          'CheckoutPage',
          routes,
          { id: listingId, slug: listingSlug },
          {}
        )
      );
    },
    [
      selectedListing,
      getListing,
      locationInfo.name,
      locationInfo.id,
      currentUser,
      dispatch,
      lineItems,
      history,
    ]
  );

  const onFetchTimeSlots = useCallback(
    (listingId, start, end, timeZone) => {
      dispatch(fetchTimeSlots(new UUID(listingId.uuid), start, end, timeZone));
    },
    [dispatch]
  );

  const onFetchTransactionLineItems = useCallback(
    (data) => dispatch(fetchTransactionLineItems(data)),
    [dispatch]
  );

  const currentUserInfoForEmail = useMemo(() => {
    if (!!currentUser) {
      const {
        attributes: {
          email,
          profile: {
            firstName,
            lastName,
            protectedData: { phone },
            publicData: { organization },
          },
        },
      } = currentUser;

      return {
        fullName: `${firstName} ${lastName}`,
        email,
        companyName: organization,
        phone,
      };
    } else {
      return {};
    }
  }, [currentUser]);

  const topbar = <TopbarContainer currentPage="LocationPage" />;

  if (!locationInfo.listings_new_rel) {
    const loadingTitle = intl.formatMessage({
      id: 'ListingPage.loadingListingTitle',
    });

    return (
      <Page title={loadingTitle} scrollingDisabled={scrollingDisabled}>
        <LayoutSingleColumn className={css.pageRoot}>
          <LayoutWrapperTopbar>{topbar}</LayoutWrapperTopbar>
          <LayoutWrapperMain>
            <PageLoading />
          </LayoutWrapperMain>
          <LayoutWrapperFooter>
            <Footer />
          </LayoutWrapperFooter>
        </LayoutSingleColumn>
      </Page>
    );
  }

  const schemaImage = locationInfo?.images ? locationInfo.images[0].large : null;
  const schemaImages = JSON.stringify(locationInfo?.images?.map((image) => image.large));
  const description =
    intl.locale === 'sv' ? locationInfo.description_sv : locationInfo.description_en;
  const titleDifferentiator = !!category ? category : !!address ? address : '';
  const schemaTitle = intl.formatMessage(
    { id: 'ListingPage.schemaTitle' },
    { title: locationInfo.name, siteTitle: config.siteTitle, titleDifferentiator }
  );

  return (
    <Page
      title={schemaTitle}
      contentType="website"
      description={`${description}${category ? ` | ${category}` : ''}`}
      facebookImages={[
        {
          name: 'facebook',
          url: schemaImage,
          width: 1200,
          height: 630,
        },
      ]}
      twitterImages={[
        {
          name: 'twitter',
          url: schemaImage,
          width: 600,
          height: 314,
        },
      ]}
      schema={{
        '@context': 'http://schema.org',
        '@type': 'ItemPage',
        description: description,
        name: schemaTitle,
        image: schemaImages,
      }}
      scrollingDisabled={scrollingDisabled}
    >
      <LayoutSingleColumn>
        <LayoutWrapperTopbar>{topbar}</LayoutWrapperTopbar>
        <LayoutWrapperMain className={css.mainWrapper}>
          <ImageGridWithSlider
            title={slug}
            state="published"
            images={locationInfo.images ? locationInfo.images : []}
            editParams={{
              id,
              slug,
              type: 'location',
              view: 'locations',
              tab: 'overview',
              name: 'DashboardPage',
            }}
            isOwn={currentUser?.id?.uuid === locationInfo.owner_id}
            isLocation={true}
            onManageDisableScrolling={onManageDisableScrolling}
          />
          <div className={css.container}>
            <div className={css.main}>
              <SectionHeader
                name={locationInfo.name}
                address={locationInfo.address}
                keywords={locationInfo.keywords?.map((k) => intl.formatMessage({ id: k }))}
                isFavorite={isFavorite}
                locationId={locationInfo.id}
              />
              <SectionDescription intl={intl} text={locationInfo[`description_${getLocale()}`]} />
              {!!locationInfo?.listings_new_rel && !!locationInfo?.listings_new_rel?.length && (
                <SectionListings
                  listings={locationInfo?.listings_new_rel}
                  selectedListing={selectedListing}
                  onSelectListing={selectListing}
                  isCompanyUser={isCompanyUser(currentUser)}
                  isEmployeeUser={isEmployeeUser(currentUser)}
                  locationImages={locationInfo?.images}
                  intl={intl}
                />
              )}
              {!!Object.keys(locationInfo?.opening_hours).length && (
                <SectionOpeningHours days={locationInfo.opening_hours} intl={intl} />
              )}
              <SectionMap
                id={locationInfo.id}
                address={locationInfo.address}
                geolocation={locationInfo.geolocation}
                intl={intl}
              />
              <SectionQuestions intl={intl} />
            </div>

            <aside className={css.bookingPanelWrapper}>
              {!!listForListingDropdown && (
                <SectionBookingPanel
                  showBookingPanel={
                    !!locationListings && locationListings?.data?.length > 0 && !!selectedListing.id
                  }
                  listing={locationListings?.data?.find((l) => l.id.uuid === selectedListing.id)}
                  currentUser={currentUser}
                  companyAccount={companyAccount}
                  bookingData={bookingData}
                  lineItems={lineItems}
                  monthlyTimeSlots={monthlyTimeSlots}
                  timeSlots={timeSlots}
                  onFetchTimeSlots={onFetchTimeSlots}
                  onFetchTransactionLineItems={onFetchTransactionLineItems}
                  fetchLineItemsInProgress={fetchLineItemsInProgress}
                  fetchLineItemsError={fetchLineItemsError}
                  fetchTimeSlotsError={fetchTimeSlotsError}
                  listForListingDropdown={listForListingDropdown}
                  onSelectListing={selectListing}
                  onSubmit={handleSubmit}
                  isOwnListing={false}
                  locationInfo={locationInfo}
                  currentUserInfoForEmail={currentUserInfoForEmail}
                  onManageDisableScrolling={onManageDisableScrolling}
                  pathname={location.pathname}
                />
              )}
            </aside>
          </div>
        </LayoutWrapperMain>
        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </LayoutSingleColumn>
    </Page>
  );
};

LocationPage.setInitialValues = (initialValues) => setInitialValues(initialValues);
LocationPage.loadData = (params, search) => loadData(params, search);

//add default props for unit tests
LocationPage.defaultProps = {
  locationInfo: {
    name: '',
    address: '',
    description_sv: '',
    description_en: '',
    images: [],
    opening_hours: {},
    listings_new_rel: [],
  },
  listingSearchedFor: {},
  currentUser: null,
  companyAccount: null,
  favoritesArray: [],
  bookingData: {},
  lineItems: [],
  monthlyTimeSlots: [],
  timeSlots: [],
  fetchLineItemsInProgress: false,
  fetchLineItemsError: null,
  fetchTimeSlotsError: null,
  getListing: () => {},
};

//add proptype validation
LocationPage.propTypes = {
  locationInfo: object.isRequired,
  listingSearchedFor: object.isRequired,
  currentUser: propTypes.currentUser,
  companyAccount: object,
  favoritesArray: array,
  bookingData: object,
  lineItems: array,
  monthlyTimeSlots: array,
  timeSlots: array,
  fetchLineItemsInProgress: bool.isRequired,
  fetchLineItemsError: propTypes.error,
  fetchTimeSlotsError: propTypes.error,
  getListing: func.isRequired,
};

export default LocationPage;
