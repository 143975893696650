import { inBrowser, narrowViewportMatch } from './device';

export class HubspotController {
  constructor() {
    this.hubspotTrigger = null;
  }

  open() {
    // if (inBrowser) {
    // window?.HubSpotConversations?.widget?.open();
    // }
  }

  remove() {
    if (inBrowser) {
      window?.HubSpotConversations?.widget?.remove();
    }
  }

  getContainer() {
    return new Promise((resolve, reject) => {
      let getHS = setInterval(() => {
        const hs = document.getElementById('hubspot-messages-iframe-container');
        if (hs instanceof HTMLElement) {
          this.hubspotTrigger = hs;
          clearInterval(getHS);
          resolve();
        }
      });
    });
  }

  /**
   * @param mobileOnly {Boolean}
   * */
  async updateAppearance(mobileOnly = false) {
    const shouldCallFunction = mobileOnly
      ? narrowViewportMatch().matches && inBrowser()
      : inBrowser();

    if (shouldCallFunction) {
      await this.getContainer();

      if (this.hubspotTrigger instanceof HTMLElement) {
        return {
          raise: () => this.hubspotTrigger.classList.add('raised'),
          decrease: () => this.hubspotTrigger.classList.remove('raised'),
          hide: () => this.hubspotTrigger.classList.add('hidden'),
          show: () => this.hubspotTrigger.classList.remove('hidden'),
        };
      }
    }
  }

  hide() {
    if (inBrowser && narrowViewportMatch().matches) {
      this.getContainer().then(() => {
        if (this.hubspotTrigger instanceof HTMLElement) {
          this.hubspotTrigger.classList.add('hidden');
        }
      });
    }
  }

  show() {
    // if (inBrowser && narrowViewportMatch().matches) {
    //   this.getContainer().then(() => {
    //     if (this.hubspotTrigger instanceof HTMLElement) {
    //       this.hubspotTrigger.classList.remove('hidden');
    //     }
    //   });
    // }
  }
}
