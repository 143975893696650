import React from 'react';
import { instanceOf, string } from 'prop-types';
import classNames from 'classnames';
import { isSameDay, formatDateToText } from '../../util/dates';
import { injectIntl, intlShape, FormattedMessage } from '../../util/reactIntl';
import { DATE_TYPE_DATE, DATE_TYPE_DATETIME, MONTHLY_BOOKING, propTypes } from '../../util/types';
import moment from 'moment';

import css from './TimeRange.module.scss';

const singleDay = (startDate, endDate) => {
  const d1 = moment(startDate);
  const d2 = moment(endDate);
  return d1.add(1, 'days').valueOf() === d2.valueOf();
};

export const TimeRangeComponent = (props) => {
  const {
    rootClassName,
    className,
    startDate,
    endDate,
    dateType,
    timeZone,
    intl,
    seats,
    bookingType,
  } = props;
  const start = formatDateToText(intl, startDate, timeZone);
  const end = formatDateToText(intl, endDate, timeZone);
  const isSingleDay = isSameDay(startDate, endDate, timeZone) || singleDay(startDate, endDate);

  const classes = classNames(rootClassName || css.root, className);
  const seatMaybe =
    seats > 0 ? (
      <span className={css.dateSection}>
        <FormattedMessage id={'TimeRangeComponent.seats'} values={{ seats }} />
      </span>
    ) : null;

  if (bookingType === MONTHLY_BOOKING)
    return (
      <div className={classes}>
        <span className={css.dateSection}>{`${start.month}`}</span>
        {seatMaybe}
      </div>
    );
  if (isSingleDay && dateType === DATE_TYPE_DATE) {
    return (
      <div className={classes}>
        <span className={css.dateSection}>{`${start.date}`}</span>
        {seatMaybe}
      </div>
    );
  } else if (dateType === DATE_TYPE_DATE) {
    return (
      <div className={classes}>
        <span className={css.dateSection}>{`${start.date} -`}</span>
        <span className={css.dateSection}>{`${end.date}`}</span>
        {seatMaybe}
      </div>
    );
  } else if (isSingleDay && dateType === DATE_TYPE_DATETIME) {
    return (
      <div className={classes}>
        <span className={css.dateSection}>
          {`${start.date}, ${start.time} - ${end.time}`} {seatMaybe}
        </span>
      </div>
    );
  } else {
    return (
      <div className={classes}>
        <span className={css.dateSection}>{`${start.dateAndTime} - `}</span>
        <span className={css.dateSection}>{`${end.dateAndTime}`}</span>
        {seatMaybe}
      </div>
    );
  }
};

TimeRangeComponent.defaultProps = {
  rootClassName: null,
  className: null,
  dateType: null,
  timeZone: null,
};

TimeRangeComponent.propTypes = {
  rootClassName: string,
  className: string,
  startDate: instanceOf(Date).isRequired,
  endDate: instanceOf(Date).isRequired,
  dateType: propTypes.dateType,
  timeZone: string,

  // from injectIntl
  intl: intlShape.isRequired,
};

export default injectIntl(TimeRangeComponent);
