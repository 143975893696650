import React from 'react';
import { FormattedMessage } from 'react-intl';
import { propTypes } from '../../util/types';
import css from './BookingBreakdown.module.scss';

const LineItemListingType = ({ transaction }) => {
  return (
    <>
      <div className={css.lineItem}>
        <FormattedMessage id="BookingBreakdown.listingType">
          {(id) => <span className={css.itemLabel}>{id}</span>}
        </FormattedMessage>
        {transaction.listing && (
          <>
            <FormattedMessage
              id={`MarketplaceConfig.filters.category.${transaction.listing.attributes.publicData.category}`}
            >
              {(id) => <span className={`${css.itemValue} ${css.listingType}`}>{id}</span>}
            </FormattedMessage>
            {transaction.listing.attributes.publicData.category === 'meeting' &&
              transaction.listing.attributes.publicData.maxSeats && (
                <FormattedMessage
                  id={`BookingBreakdown.maxSeats`}
                  values={{ maxSeats: transaction.listing.attributes.publicData.maxSeats }}
                >
                  {(id) => <span className={`${css.itemValue} ${css.listingType}`}>{id}</span>}
                </FormattedMessage>
              )}
          </>
        )}
      </div>
      <hr className={css.totalDivider} />
    </>
  );
};

LineItemListingType.propTypes = {
  transaction: propTypes.transaction.isRequired,
};

export default LineItemListingType;
