import React, { Component } from 'react';
import { bool, func, node, object, string } from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { ButtonNew } from '../../components_new';
import { LINK_COLORS } from '../../components_new/ButtonNew/ButtonNew';
import { ComboBoxClearButton } from '../../forms/ListingSearchForm/ui';
import { updateFilters } from '../../ducks/SearchPageNew.duck';

import { FilterForm } from '../../forms';
import css from './FilterPlain.module.scss';

class FilterPlainComponent extends Component {
  constructor(props) {
    super(props);
    this.state = { isOpen: false };

    this.handleChange = this.handleChange.bind(this);
    this.toggleIsOpen = this.toggleIsOpen.bind(this);
  }

  handleChange(values) {
    const { onSubmit, name } = this.props;
    switch (name) {
      case 'category': {
        if (values) {
          updateFilters(values.category.join(','));
        }
        break;
      }
      case 'ameneties': {
        if (values) {
          updateFilters(values.ameneties);
        }
        break;
      }
      case 'offer': {
        if (values) {
          updateFilters(values.offer);
        }
        break;
      }
      case 'fixedofficetype': {
        if (values) {
          updateFilters(values.fixedofficetype);
        }
        break;
      }
      case 'host': {
        if (values) {
          updateFilters(values.host);
        }
        break;
      }
      case 'contracttype': {
        if (values) {
          updateFilters(values.contracttype);
        }
        break;
      }
      case 'allowvatexemptcompanies': {
        if (values) {
          updateFilters(
            !!values.allowvatexemptcompanies && values.allowvatexemptcompanies.length > 0
          );
        }
        break;
      }
    }
    onSubmit(values);
  }

  handleClear() {
    const { onSubmit, onClear } = this.props;

    if (onClear) {
      onClear();
    }

    onSubmit(null);
  }

  toggleIsOpen() {
    this.setState((prevState) => ({ isOpen: !prevState.isOpen }));
  }

  render() {
    const {
      rootClassName,
      className,
      plainClassName,
      id,
      label,
      isSelected,
      children,
      initialValues,
      keepDirtyOnReinitialize,
      contentPlacementOffset,
      selectedOptionsCount,
      hideLabel,
      showViewMoreButton,
      isMobile,
      isRelevantFilter,
      closeRelevantFilter,
    } = this.props;
    const classes = classNames(rootClassName || css.root, className);

    const labelClass = isSelected ? css.filterLabelSelected : css.filterLabel;

    return (
      <div className={classes}>
        {!hideLabel && (
          <div className={labelClass}>
            <span className={labelClass}>
              {label}
              {!!selectedOptionsCount && (
                <div className={css.selectedOptionsCount}>{selectedOptionsCount}</div>
              )}
            </span>
          </div>
        )}
        <div
          id={id}
          className={classNames(
            plainClassName,
            css.plain,
            isMobile && css.isMobile,
            this.state.isOpen && css.isOpen
          )}
          ref={(node) => {
            this.filterContent = node;
          }}
        >
          <FilterForm
            id={`${id}.form`}
            liveEdit
            contentPlacementOffset={contentPlacementOffset}
            onChange={this.handleChange}
            initialValues={initialValues}
            keepDirtyOnReinitialize={keepDirtyOnReinitialize}
          >
            {children}
          </FilterForm>
        </div>
        {isMobile && showViewMoreButton && (
          <ButtonNew
            className={css.showMore}
            type="inline"
            linkColor={LINK_COLORS.BLUE}
            textLight
            underlined
            onClick={this.toggleIsOpen}
          >
            {this.state.isOpen ? (
              <FormattedMessage id={'FilterPlain.showLess'}>{(id) => id}</FormattedMessage>
            ) : (
              <FormattedMessage id={'FilterPlain.showMore'}>{(id) => id}</FormattedMessage>
            )}
          </ButtonNew>
        )}
        {isRelevantFilter && (
          <ComboBoxClearButton
            onClick={() => {
              this.handleClear();
              closeRelevantFilter();
            }}
            className={css.clearBtnWrapper}
            onClose={() => closeRelevantFilter()}
            isRelevantFilter={true}
          />
        )}
      </div>
    );
  }
}

FilterPlainComponent.defaultProps = {
  rootClassName: null,
  className: null,
  plainClassName: null,
  initialValues: null,
  keepDirtyOnReinitialize: false,
  isRelevantFilter: false,
};

FilterPlainComponent.propTypes = {
  rootClassName: string,
  className: string,
  plainClassName: string,
  id: string.isRequired,
  onSubmit: func.isRequired,
  label: node.isRequired,
  isSelected: bool.isRequired,
  children: node.isRequired,
  initialValues: object,
  keepDirtyOnReinitialize: bool,
  isRelevantFilter: bool,
  closeRelevantFilter: func,

  // form injectIntl
  intl: intlShape.isRequired,
};

const FilterPlain = injectIntl(FilterPlainComponent);

export default FilterPlain;
