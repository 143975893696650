import React from 'react';
import classNames from 'classnames';
import { Field } from 'react-final-form';
import { string, bool, oneOf, func } from 'prop-types';

import css from './FieldSwitch.module.scss';

export const FIELD_SWITCH_COLORS = {
  WHITE: 'white',
  GREEN: 'green',
};

FIELD_SWITCH_COLORS.LIST = [FIELD_SWITCH_COLORS.WHITE, FIELD_SWITCH_COLORS.GREEN];

export const FIELD_SWITCH_SIZES = {
  LARGE: 'large',
  SMALL: 'small',
};

FIELD_SWITCH_SIZES.LIST = [FIELD_SWITCH_SIZES.LARGE, FIELD_SWITCH_SIZES.SMALL];

const FieldSwitch = ({ id, name, isChecked, onChange, color, size, label, disabled, withIcon }) => {
  const toggleClassList = classNames(
    css.toggle,
    css[color],
    css[size],
    isChecked && css.active,
    disabled && css.disabled
  );

  const circleClassList = classNames(css.circle, isChecked && css.active);
  const iconClassList = classNames(css.icon, isChecked && css.active, !isChecked && css.hidden);

  const fieldProps = {
    id,
    name,
    onChange,
    component: 'input',
    type: 'checkbox',
    className: css.input,
  };

  return (
    <label className={css.wrapper} htmlFor={id}>
      <Field {...fieldProps} />
      <div className={toggleClassList}>
        <div className={circleClassList}>
          {withIcon && (
            <svg
              className={iconClassList}
              width="13"
              height="11"
              viewBox="0 0 13 11"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M11.6666 1.73364L4.33332 9.40152L1 5.91612"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          )}
        </div>
      </div>
      {label && <span className={css.label}>{label}</span>}
    </label>
  );
};

FieldSwitch.defaultProps = {
  label: null,
  disabled: false,
  withIcon: true,
  color: FIELD_SWITCH_COLORS.GREEN,
  size: FIELD_SWITCH_SIZES.LARGE,
};

FieldSwitch.propTypes = {
  id: string.isRequired,
  name: string.isRequired,
  isChecked: bool.isRequired,
  onChange: func.isRequired,
  color: oneOf(FIELD_SWITCH_COLORS.LIST),
  size: oneOf(FIELD_SWITCH_SIZES.LIST),
  label: string,
  disabled: bool,
  withIcon: bool,
};

export default FieldSwitch;
