import React from 'react';
import classNames from 'classnames';
import { bool, func } from 'prop-types';
import { IconSpinner } from '../../../components';

import css from '../FavoriteListingToggle.module.scss';

const FavoriteButton = ({ isFavorite, isLoading, onClick }) => (
  <button className={css.favoriteButton} onClick={!isLoading && onClick}>
    {isLoading ? (
      <IconSpinner className={css.IconSpinner} />
    ) : (
      <svg
        className={classNames(css.favoriteIcon, isFavorite && css.active)}
        width="19"
        height="16"
        viewBox="0 0 19 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M16.6891 2.2377C16.2738 1.84531 15.7806 1.53404 15.2377 1.32168C14.6949 1.10931 14.1131 1 13.5255 1C12.9379 1 12.3561 1.10931 11.8133 1.32168C11.2704 1.53404 10.7772 1.84531 10.3619 2.2377L9.49978 3.05166L8.63771 2.2377C7.79866 1.44548 6.66066 1.00041 5.47407 1.00041C4.28747 1.00041 3.14947 1.44548 2.31042 2.2377C1.47137 3.02993 1 4.10441 1 5.22479C1 6.34516 1.47137 7.41965 2.31042 8.21187L3.1725 9.02583L9.49978 15L15.8271 9.02583L16.6891 8.21187C17.1047 7.81967 17.4344 7.354 17.6593 6.84146C17.8842 6.32893 18 5.77958 18 5.22479C18 4.67 17.8842 4.12064 17.6593 3.60811C17.4344 3.09558 17.1047 2.6299 16.6891 2.2377Z"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    )}
  </button>
);

FavoriteButton.propTypes = {
  isOnListingPage: bool,
  isFavorite: bool,
  onClick: func,
};

export default FavoriteButton;
