import React from 'react';

import { NamedLink } from '../../components';

import css from './LocationPage.module.scss';

const SectionQuestions = ({ intl }) => (
  <section className={css.section}>
    <h4 className={css.sectionTitle}>{intl.formatMessage({ id: 'LocationPage.questions' })}</h4>
    <p className={css.questions}>
      {intl.formatMessage(
        { id: 'LocationPage.questionsCaption' },
        {
          link: (text) => <NamedLink name="FAQPage">{text}</NamedLink>,
          email: (text) => (
            <a href="mailto:hello@respaces.co" target="_blank" rel="noreferrer noopener">
              {text}
            </a>
          ),
        }
      )}
    </p>
  </section>
);

export default SectionQuestions;
