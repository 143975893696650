import React, { useState } from 'react';
import {
  Form,
  Button,
  FieldTextInput,
  SubscriptionManagementIcons as Icons,
} from '../../components';
import { Form as FinalForm } from 'react-final-form';
import classNames from 'classnames';
import * as validators from '../../util/validators';
import css from './TeamAccessTabForm.module.scss';
import isNull from 'lodash/isNull';
import { ButtonNew } from '../../components_new';
import { BUTTON_SIZES, BUTTON_TYPES } from '../../components_new/ButtonNew/ButtonNew';

const TeamAccessTabForm = (props) => {
  const { onSubmitContactForm, ...rest } = props;
  const [userInvited, setUserInvited] = useState(false);

  return (
    <FinalForm
      {...rest}
      onSubmit={onSubmitContactForm}
      render={(fieldRenderProps) => {
        const {
          intl,
          handleSubmit,
          invalid,
          values,
          emailList,
          setEmailList,
          form,
          emailInputFocused: focused,
          setEmailInputFocuses: setFocused,
          emailInputRef,
          labelBoardOpen,
          setLabelBoardOpen,
          labelBoardRef,
          labelList,
          setLabelList,
          selectedLabel,
          setSelectedLabel,
          closeContactModal,
          addTeamMembersInProgress,
        } = fieldRenderProps;
        const { teamAccessEmail = '' } = values;

        const hasEmail = emailList.length > 0;
        const submitButtonDisabled = !hasEmail;

        const emailRequiredMessage = intl.formatMessage({
          id: 'OrganizationDetailTab.emailRequired',
        });
        const emailRequired = validators.required(emailRequiredMessage);
        const emailInvalidMessage = intl.formatMessage({
          id: 'OrganizationDetailTab.emailInvalid',
        });
        const emailValid = validators.emailFormatValid(emailInvalidMessage);

        const contactModalHeaderTitle = intl.formatMessage({
          id: 'TeamAccessTab.ContactModal.headerTitle',
        });

        const contactModalHeaderNoLabel = intl.formatMessage({
          id: 'TeamAccessTab.ContactModal.noLabel',
        });

        const contactModalLabelCreateTitle = intl.formatMessage({
          id: 'TeamAccessTab.ContactModal.labelCreateTitle',
        });

        const contactModalLabelCreateFieldPlaceholder = intl.formatMessage({
          id: 'TeamAccessTab.ContactModal.labelCreateFieldPlaceholder',
        });

        const contactModalEmailFieldPlaceholder = intl.formatMessage({
          id: 'TeamAccessTab.ContactModal.emailFieldPlaceholder',
        });

        const contactModalCancelButton = intl.formatMessage({
          id: 'TeamAccessTab.ContactModal.cancelButton',
        });

        const contactModalCreateButton = intl.formatMessage({
          id: 'TeamAccessTab.ContactModal.createButton',
        });

        const importContactsText = intl.formatMessage({
          id: 'TeamAccessTab.ContactModal.importContactsText',
        });

        const importContactsDescriptionPre = intl.formatMessage({
          id: 'TeamAccessTab.ContactModal.importContactsDescriptionPre',
        });

        const importContactsDescriptionPos = intl.formatMessage({
          id: 'TeamAccessTab.ContactModal.importContactsDescriptionPos',
        });

        const userInvitedHeading = intl.formatMessage({
          id: 'TeamAccessTab.ContactModal.userInvitedHeading',
        });
        const userInvitedText = intl.formatMessage({
          id: 'TeamAccessTab.ContactModal.userInvitedInfo',
        });

        const handleEmailFieldFocus = () => {
          setFocused(true);
        };

        const handleInputRefFocus = () => {
          if (emailInputRef.current) {
            emailInputRef.current.focus();
          }
        };

        const handleEmailFieldBlur = (e) => {
          const { value } = e.target;
          // The returned value is undefined => email is valid
          const isEmailInValid = validators.emailFormatValid(emailInvalidMessage)(value);
          if (!isEmailInValid) {
            const newEmailList = Array.from(new Set([...emailList, value.toLowerCase()]));
            setEmailList(newEmailList);
            form.change('teamAccessEmail', '');
          }
          setFocused(false);
        };

        const handleKeyDown = (event) => {
          if (event.key === 'Enter' || event.keyCode == 32) {
            event.preventDefault();
            handleEmailFieldBlur(event);
          }
        };

        const removeEmail = (index) => {
          const newEmailList = [...emailList.slice(0, index), ...emailList.slice(index + 1)];
          setEmailList(newEmailList);
        };

        const openLabelBoard = () => {
          setLabelBoardOpen(true);
        };

        const handleLabelFieldBlur = (e) => {
          const { value } = e.target;
          if (parseInt(value) < 1) {
            return;
          }
          if (value && !labelList.includes(parseInt(value))) {
            setLabelList(labelList.concat(parseInt(value)));
            form.batch(() => {
              form.change('newLabel', null);
            });
          }
        };

        const toggleSelectLabel = (label) => () => {
          if (!selectedLabel || selectedLabel !== label) {
            setSelectedLabel(label);
          } else {
            setSelectedLabel(null);
          }
        };

        const showSelectedLabel = (credits) => {
          if (credits < 0) {
            return intl.formatMessage({ id: 'TeamAccessTab.labelForm.labelUnlimited' });
          } else {
            return intl.formatMessage(
              { id: 'TeamAccessTab.labelForm.labelText' },
              { credits: credits }
            );
          }
        };

        const newLabelInputClasses = classNames(css.newLabelInput, {
          [css.inputError]: invalid,
        });
        return (
          <Form onSubmit={handleSubmit}>
            <div className={css.modalHeader}>
              <span className={css.modalHeaderTitle}>
                {userInvited ? userInvitedHeading : contactModalHeaderTitle}
              </span>
            </div>
            <div className={css.modalContent}> {userInvited && userInvitedText}</div>

            <div
              className={classNames(css.inputWrapper, {
                [css.focused]: focused,
                [css.empty]: !hasEmail && teamAccessEmail === '',
              })}
              onClick={handleInputRefFocus}
            >
              <span className={css.inputPlaceholder}>{contactModalEmailFieldPlaceholder}</span>
              {hasEmail &&
                emailList.map((email, index) => (
                  <div className={css.emailLabel} key={email}>
                    {email}
                    <span onClick={() => removeEmail(index)}>
                      <Icons.CloseIcon className={css.closeIcon} />
                    </span>
                  </div>
                ))}

              <FieldTextInput
                id="teamAccessEmail"
                name="teamAccessEmail"
                type="email"
                inputRootClass={css.emailField}
                onBlur={handleEmailFieldBlur}
                onFocus={handleEmailFieldFocus}
                inputRef={emailInputRef}
                onKeyDown={handleKeyDown}
              />
            </div>
            {/* <div className={css.modalContent}> */}
            {/* {importContactsDescriptionPre}&nbsp;<span>{importContactsText}</span>&nbsp;
              {importContactsDescriptionPos} */}
            {/* </div> */}
            <div className={css.modalLabel}>
              <div className={css.labelHeaderWrapper} onClick={openLabelBoard}>
                <Icons.LabelIcon className={css.labelIcon} />
                <span>
                  {isNull(selectedLabel)
                    ? contactModalHeaderNoLabel
                    : showSelectedLabel(selectedLabel)}
                </span>
              </div>
              {labelBoardOpen && (
                <div className={css.labelCreateWrapper} ref={labelBoardRef}>
                  <span className={css.labelCreateTitle}>{contactModalLabelCreateTitle}</span>
                  {labelList.map((creditLabel) => (
                    <div
                      className={classNames(css.labelOption, {
                        [css.selected]: creditLabel === selectedLabel,
                      })}
                      key={creditLabel}
                      onClick={toggleSelectLabel(creditLabel)}
                    >
                      <Icons.LabelIcon className={css.labelOptionIcon} />
                      <span>{showSelectedLabel(creditLabel)}</span>
                    </div>
                  ))}
                  <div className={css.labelInput}>
                    <Icons.LabelIcon className={css.labelOptionIcon} />
                    <FieldTextInput
                      id="newLabel"
                      name="newLabel"
                      type="number"
                      inputRootClass={newLabelInputClasses}
                      placeholder={contactModalLabelCreateFieldPlaceholder}
                      onBlur={handleLabelFieldBlur}
                      validate={validators.positiveCreditLabel('invalid')}
                    />
                  </div>
                </div>
              )}
            </div>
            <div className={css.modalBtnWrapper}>
              <ButtonNew
                type={BUTTON_TYPES.BLACK}
                size={BUTTON_SIZES.SMALL}
                onClick={(e) => {
                  e.preventDefault();
                  closeContactModal();
                }}
              >
                {contactModalCancelButton}
              </ButtonNew>

              <ButtonNew
                type={BUTTON_TYPES.GREEN}
                size={BUTTON_SIZES.SMALL}
                disabled={submitButtonDisabled}
                inProgress={addTeamMembersInProgress}
                spinnerClassName={css.modalBtnSpinner}
                onClick={() => {
                  setUserInvited(true);
                }}
              >
                {contactModalCreateButton}
              </ButtonNew>
            </div>
          </Form>
        );
      }}
    />
  );
};

export default TeamAccessTabForm;
