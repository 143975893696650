import React, { useState } from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import CompanyGetInTouchForm from '../../forms/CompanyGetInTouchForm/CompanyGetInTouchForm';
import SendFormEmail from '../../emails/CompanyFormSubmissionEmail';

import BirdieMail from '../../assets/BirdieMail';

import css from './SectionCompanyForm.module.scss';

const SectionCompanyForm = (props) => {
  const { intl } = props;

  const [formSent, setFormSent] = useState(false);

  const CompanyformSubmit = (values) => {
    setFormSent(true);
    SendFormEmail(values);

    if (typeof document !== 'undefined') {
      const companyForm = document.getElementById('company_form');
      if (!!companyForm) {
        companyForm.scrollIntoView({ behavior: 'smooth' });
      }
    }
  };

  return (
    <section className={css.desktopWrapper} id="company_form">
      {!formSent ? (
        <div className={css.contentBox}>
          <div className={css.formInfo}>
            <h2 className={css.heading}>
              <FormattedMessage id="SectionCompanyForm.heading" />
            </h2>
            <p className={css.infoText}>
              <FormattedMessage id="SectionCompanyForm.infoText" />
            </p>
            <svg
              className={css.arrow}
              width="138"
              height="135"
              viewBox="0 0 138 135"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M26.1696 64.6074L26.6281 65.4099L26.617 65.4151L26.6052 65.4199L26.1696 64.6074ZM136.937 118C137.511 118.151 137.769 118.673 137.514 119.165L133.352 127.194C133.097 127.687 132.424 127.964 131.85 127.813C131.275 127.662 131.017 127.139 131.272 126.647L134.972 119.51L126.653 117.321C126.079 117.17 125.821 116.647 126.076 116.155C126.331 115.662 127.004 115.385 127.579 115.536L136.937 118ZM67.6901 0.214565C77.0264 12.0037 73.5215 25.2624 64.3623 37.0197C55.2256 48.7488 40.3866 59.1144 26.6281 65.4099L25.7098 63.8052C39.1629 57.6496 53.6342 47.5189 62.4865 36.1561C71.3158 24.822 74.4777 12.3954 65.7369 1.35841L67.6901 0.214565ZM26.6052 65.4199C16.4692 69.8426 6.74622 67.2539 2.41071 62.3032C0.235175 59.8205 -0.632378 56.6825 0.599825 53.5071C1.836 50.3215 5.10602 47.2996 10.721 44.966L11.5468 46.6112C6.37384 48.7607 3.72935 51.379 2.76229 53.8719C1.79113 56.3739 2.43205 58.9509 4.31298 61.0992C8.08916 65.4104 16.6725 67.7478 25.7334 63.7946L26.6052 65.4199ZM10.721 44.966C16.4675 42.5787 23.5091 42.379 30.4166 43.8635C37.3409 45.3518 44.2487 48.5536 49.8099 53.1182C60.9499 62.2632 66.7579 76.9659 56.2795 94.3964L54.2609 93.7408C64.2213 77.1711 58.7488 63.251 48.1052 54.5146C42.7745 50.138 36.1655 47.0844 29.5979 45.6728C23.0129 44.2581 16.589 44.5158 11.5468 46.6112L10.721 44.966ZM56.2795 94.3964C51.3401 102.614 49.2643 110.089 49.9978 116.185C50.7283 122.252 54.2414 126.992 60.612 129.825C67.0162 132.674 76.4127 133.637 88.9883 131.921C101.557 130.206 117.22 125.825 136.066 118.067L136.882 119.717C117.886 127.535 101.976 132.004 89.0932 133.762C76.2182 135.519 66.2878 134.578 59.3478 131.491C52.374 128.39 48.5246 123.166 47.7326 116.587C46.9439 110.038 49.1852 102.184 54.2609 93.7408L56.2795 94.3964Z"
                fill="#E8909C"
              />
            </svg>
          </div>
          <CompanyGetInTouchForm CompanyformSubmit={CompanyformSubmit} intl={intl} />
        </div>
      ) : (
        <div className={css.thankYouBox}>
          <h2>
            <FormattedMessage id="SectionCompanyForm.headingSubmitted" />
          </h2>
          <p>
            <FormattedMessage id="SectionCompanyForm.infoTextSubmitted" />
          </p>
          <BirdieMail />
        </div>
      )}
    </section>
  );
};

SectionCompanyForm.defaultProps = { rootClassName: null, className: null };

SectionCompanyForm.propTypes = {};

export default SectionCompanyForm;
