import { capitalize } from './string';

const WEEKDAYS_IN_ORDER = ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun'];

/**
 * @param openingHours {Object}
 * @return formattedOpeningHours {Array}
 * */
export const smartOpeningHours = (openingHours) => {
  return WEEKDAYS_IN_ORDER.map((day) =>
    !!openingHours[day] ? { day, hours: { ...openingHours[day] } } : null
  )
    .filter(Boolean)
    .reduce((acc, { day, hours }) => {
      const lastDayInArray = acc[acc.length - 1];
      const currentDayIndex = WEEKDAYS_IN_ORDER.findIndex((d) => d === day) + 1;
      const previousDayIndex =
        WEEKDAYS_IN_ORDER.findIndex((d) => {
          const withDash = !!lastDayInArray?.days.toLowerCase().match(/-/);
          if (withDash) {
            const [first, second] = lastDayInArray?.days.toLowerCase().split('-');
            return d === second;
          } else {
            return d === lastDayInArray?.days.toLowerCase();
          }
        }) + 1;

      const isNextDay = previousDayIndex === currentDayIndex - 1;
      const canMerge =
        lastDayInArray?.openTime === hours.openTime &&
        lastDayInArray?.closeTime === hours.closeTime;

      if (isNextDay && canMerge) {
        const newTitle = acc[acc.length - 1].days.split('-')[0];
        lastDayInArray.days = `${newTitle}-${capitalize(day)}`;
      } else {
        acc.push({
          days: `${capitalize(day)}`,
          ...hours,
        });
      }

      return acc;
    }, []);
};
