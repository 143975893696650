import React from 'react';
import { array, bool, object, oneOf, oneOfType, string } from 'prop-types';
import classNames from 'classnames';
import config from '../../config';

import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Autoplay, Navigation, Pagination } from 'swiper';
import left from './assets/left.svg';
import right from './assets/right.svg';
import bullet from './assets/bullet.svg';

import css from './ListingImageSlider.module.scss';

export const DIRECTIONS = ['horizontal', 'vertical'];

SwiperCore.use([Autoplay, Navigation, Pagination]);
const ListingImageSlider = (props) => {
  // Slider takes :
  // autoplay(to use default 3 second delay) or autoplay{{delay:5000}} for custom delay - defaults to false
  // data is the object/array of listings or images or reviews
  // showLocation and showTitle both bools to show location title and location as on Landing Page. default to false
  const {
    autoplay,
    className,
    data,
    direction,
    showLocation,
    showTitle,
    allowTouchMove,
    isMobile,
  } = props;

  if (!data) {
    return null;
  }
  const classes = classNames(className, css.sliderContainer);
  // if category = images, create array of small images for listing card.
  // if category = spaces then the listing/data object will be mapped through directly.
  // if category = reviews then will map through that object directly.
  const images = data.images.map((img) => img.attributes?.variants['landscape-crop2x']?.url);

  const content = images.map((img, index) => (
    <SwiperSlide key={`swiperSlide_images_${index}`} className={css.slideContainer}>
      <img className={css.image} src={img} alt="Listing" />
    </SwiperSlide>
  ));

  return (
    <div className={classes}>
      <Swiper
        className={css['swiper-container']}
        spaceBetween={isMobile ? 0 : 8}
        slidesPerView={isMobile ? 1 : 'auto'}
        allowTouchMove={allowTouchMove}
        speed={500}
        loop
        direction={direction}
        initialSlide={0}
        autoplay={
          autoplay && {
            delay: autoplay.delay || 3000,
            disableOnInteraction: false,
          }
        }
        navigation={{
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        }}
        pagination={
          isMobile
            ? {
                el: '.swiper-pagination',
                type: 'bullets',
                bulletActiveClass: css['pagination-bullet-active'],
                bulletClass: css['pagination-bullet'],
                renderBullet: function (index, className) {
                  return `<img width="16" height="10" class="${className}" alt="slider button" src=${bullet} />`;
                },
              }
            : {}
        }
      >
        {content}
        <div className={css.fadedGradient}></div>
        <>
          <img
            width="40"
            height="40"
            className={classNames('swiper-button-next', css.swiperButton)}
            alt="slider button"
            src={right}
          />
          <img
            width="40"
            height="40"
            className={classNames('swiper-button-prev', css.swiperButton)}
            alt="slider button"
            src={left}
          />
        </>
        {isMobile && <div className={`swiper-pagination ${css.swiperPagination}`} />}
      </Swiper>
    </div>
  );
};

ListingImageSlider.defaultProps = {
  autoplay: false,
  className: null,
  data: {},
  direction: DIRECTIONS[0],
  showLocation: false,
  showTitle: false,
  allowTouchMove: true,
};
ListingImageSlider.propTypes = {
  autoplay: oneOfType([bool, object]),
  className: string,
  data: oneOfType([array, object]),
  direction: oneOf(DIRECTIONS),
  showLocation: bool,
  showTitle: bool,
  allowTouchMove: bool,
};

export default ListingImageSlider;
