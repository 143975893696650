import React, { useEffect, useRef, useState } from 'react';
import { IconSpinner } from '../../../components';
import { PaginationLinks } from '../../../components';
import { parse } from '../../../util/urlHelpers';
import css from './PaymentHistoryTab.module.scss';
import format from 'date-fns/format';
import { formatMoney } from '../../../util/currency';
import { types as sdkTypes } from '../../../util/sdkLoader';
import get from 'lodash/get';
import slice from 'lodash/slice';
import { invoiceStatusAdapter } from '../../../util/b2bHelper';
import { IconDownload } from '../../../components_new';

const { Money } = sdkTypes;

const PER_PAGE = 10;

const getStartEndPos = (page) => {
  return {
    start: (page - 1) * PER_PAGE,
    end: page * PER_PAGE,
  };
};

const PaymentHistoryTab = (props) => {
  const {
    intl,
    isMobileLayout,
    params,
    location,
    paymentHistory,
    fetchInProgress,
    onDownloadInvoicePDF,
  } = props;
  const [paymentsToShow, setPaymentsToShow] = useState([]);
  const [page, setPage] = useState(1);
  useEffect(() => {
    const currentPage = get(parse(location.search), 'page', 1);

    setPage(currentPage);

    const { start, end } = getStartEndPos(currentPage);
    setPaymentsToShow(slice(paymentHistory, start, end));
  }, [location.search, paymentHistory]);

  const totalPages =
    paymentHistory.length % PER_PAGE > 0
      ? parseInt(paymentHistory.length / PER_PAGE) + 1
      : parseInt(paymentHistory.length / PER_PAGE);

  const pagination = {
    totalPages: totalPages,
    totalItems: paymentHistory.length,
    page: page,
    perPage: PER_PAGE,
  };

  const pagingLinks =
    pagination && pagination.totalPages > 1 ? (
      <PaginationLinks
        className={css.pagination}
        rootClassName={css.paginationWrapper}
        pageName="SubscriptionManagementPage"
        pagePathParams={params}
        pagination={pagination}
      />
    ) : null;

  const title = intl.formatMessage({
    id: 'PaymentHistoryTab.title',
  });

  const dateColumnName = intl.formatMessage({
    id: 'PaymentHistoryTab.dateColumnName',
  });

  const descriptionColumnName = intl.formatMessage({
    id: 'PaymentHistoryTab.descriptionColumnName',
  });

  const statusColumnName = intl.formatMessage({
    id: 'PaymentHistoryTab.statusColumnName',
  });

  const priceColumnName = intl.formatMessage({
    id: 'PaymentHistoryTab.priceColumnName',
  });

  const downloadColumnName = intl.formatMessage({
    id: 'PaymentHistoryTab.downloadColumnName',
  });

  const onDownloadHandler = async (invoiceId) => {
    const invoicePDFUrl = await onDownloadInvoicePDF(invoiceId);
    const isExistDownloadUrl = invoicePDFUrl !== '';
    const hasWindow = typeof window !== 'undefined';
    if (isExistDownloadUrl && hasWindow) {
      window.open(invoicePDFUrl, '_blank');
    }
  };

  const renderPaymentItem = (payment) => {
    if (!payment) {
      return null;
    }
    const { generated_at, currency_code, status, line_items, id: invoiceId } = payment.invoice;
    const { subscription_id, amount, entity_type } =
      line_items.find(
        (item) =>
          // item.entity_id === 'company-credit' ||
          item.entity_type === 'adhoc' || item.entity_type === 'plan'
        // item.entity_id === '3-days-week' ||
        //for test subscription plan
        // item.entity_id === 'test-company-credit'
      ) || {};

    const description =
      entity_type === 'plan'
        ? intl.formatMessage({ id: 'PaymentHistoryTab.subscription' })
        : intl.formatMessage({ id: 'PaymentHistoryTab.oneTimePurchase' });

    return (
      <div className={css.row} key={subscription_id}>
        <div className={css.firstMobileCol}>
          <span className={css.datePayment} onClick={() => onDownloadHandler(invoiceId)}>
            {format(new Date(generated_at * 1000), 'dd/MM/yyyy')}
          </span>
          <span className={css.descriptionPayment}>{description}</span>
          <span className={css.statusPayment}>{invoiceStatusAdapter(status, intl)}</span>
        </div>
        <div className={css.secondMobileCol}>
          <span className={css.amount}>{formatMoney(intl, new Money(amount, currency_code))}</span>
          <span className={css.download} onClick={() => onDownloadHandler(invoiceId)}>
            <IconDownload />
          </span>
        </div>
      </div>
    );
  };

  return (
    <div className={css.tabContainer}>
      {!isMobileLayout ? <h2 className={css.sectionTitle}>{title}</h2> : null}

      {fetchInProgress ? (
        <div className={css.pageLoading}>
          <IconSpinner />
        </div>
      ) : (
        <div className={css.paymentTableWrapper}>
          {!isMobileLayout && (
            <div className={css.tableHeader}>
              <div className={css.firstGroupLabel}>
                <span>{dateColumnName}</span>
                <span>{descriptionColumnName}</span>
                <span>{statusColumnName}</span>
              </div>
              <div className={css.secondGroupLabel}>
                <span>{priceColumnName}</span>
                <span>{downloadColumnName}</span>
              </div>
            </div>
          )}

          <div className={css.tableBody}>{paymentsToShow.map(renderPaymentItem)}</div>
          {pagingLinks}
        </div>
      )}
    </div>
  );
};

export default PaymentHistoryTab;
