import React, { memo } from 'react';
import { injectIntl } from 'react-intl';
import { arrayOf, shape, string, func } from 'prop-types';

import css from './RadioGroup.module.scss';

const RadioGroup = memo(({ list, name, onChange, currentValue, intl }) => (
  <ul className={css.group}>
    {list.map((radio) => (
      <li className={css.item} key={`radio_group_${name}_${radio.id}`}>
        <label htmlFor={radio.id} className={css.wrapper}>
          <input
            className={css.input}
            onChange={() => onChange(radio.id)}
            checked={currentValue === radio.id}
            id={radio.id}
            name={name}
            type="radio"
          />
          <div className={css.fakeInput} />
          <span className={css.label}>{intl.formatMessage({ id: radio.label })}</span>
        </label>
      </li>
    ))}
  </ul>
));

RadioGroup.propTypes = {
  list: arrayOf(
    shape({
      id: string.isRequired,
      label: string.isRequired,
    })
  ).isRequired,
  name: string.isRequired,
  onChange: func.isRequired,
};

export default injectIntl(RadioGroup);
