import React, { useEffect, useMemo, useState } from 'react';
import { bool, string } from 'prop-types';
import { compose } from 'redux';
import { useForm } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import classNames from 'classnames';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import {
  FieldSelect,
  FieldCheckbox,
  Button,
  EditListingIcons as Icons,
  FixedBottomButtons,
  PopupModal,
  FieldNumberInput,
  FieldRadioButton,
  OutsideClickHandler,
} from '../../components';

import css from './EditListingAvailabilityPlanFormNew.module.scss';
import { required } from '../../util/validators';
import { HOURLY_BOOKING } from '../../util/types';
import isEmpty from 'lodash/isEmpty';
import { useBoolean, useViewport } from '../../util/hooks';
import { MAX_MOBILE_SCREEN_WIDTH } from '../../util/device';
import CalendarExceptionModal from './CalendarExceptionModal';
import difference from 'lodash/difference';
import intersection from 'lodash/intersection';
import uniq from 'lodash/uniq';
import moment from 'moment';

import {
  ALL_END_HOURS,
  ALL_START_HOURS,
  daysBetween,
  formatDateWithOption,
  printHourStrings,
  timeToValue,
} from '../../util/dates';
import { DatePickerNew } from '../../components_new';

const sortEntries =
  (defaultCompareReturn = 0) =>
  (a, b) => {
    if (a.startTime && b.startTime) {
      const aStart = timeToValue(a.startTime);
      const bStart = timeToValue(b.startTime);
      return aStart - bStart;
    }
    return defaultCompareReturn;
  };

const findEntryFn = (entry) => (e) =>
  e.startTime === entry.startTime && e.endTime === entry.endTime;

export const filterStartHours = (availableStartHours, values, dayOfWeek, index) => {
  if (isEmpty(values)) return [];
  const entries = values[dayOfWeek];
  const currentEntry = entries[index];

  // If there is no end time selected, return all the available start times
  if (!currentEntry.endTime) {
    return availableStartHours;
  }

  // By default the entries are not in order so we need to sort the entries by startTime
  // in order to find out the previous entry
  const sortedEntries = [...entries].sort(sortEntries());

  // Find the index of the current entry from sorted entries
  const currentIndex = sortedEntries.findIndex(findEntryFn(currentEntry));

  // If there is no next entry or the previous entry does not have endTime,
  // return all the available times before current selected end time.
  // Otherwise return all the available start times that are after the previous entry or entries.
  const prevEntry = sortedEntries[currentIndex - 1];
  const pickBefore = (time) => (h) => timeToValue(h) < timeToValue(time);
  const pickBetween = (start, end) => (h) =>
    timeToValue(h) >= timeToValue(start) && timeToValue(h) < timeToValue(end);

  return !prevEntry || !prevEntry.endTime
    ? availableStartHours.filter(pickBefore(currentEntry.endTime))
    : availableStartHours.filter(pickBetween(prevEntry.endTime, currentEntry.endTime));
};

export const filterEndHours = (availableEndHours, values, dayOfWeek, index) => {
  if (isEmpty(values)) return [];
  const entries = values[dayOfWeek];
  const currentEntry = entries[index];

  // If there is no start time selected, return an empty array;
  if (!currentEntry.startTime) {
    return [];
  }

  // By default the entries are not in order so we need to sort the entries by startTime
  // in order to find out the allowed start times
  const sortedEntries = [...entries].sort(sortEntries(-1));

  // Find the index of the current entry from sorted entries
  const currentIndex = sortedEntries.findIndex(findEntryFn(currentEntry));

  // If there is no next entry,
  // return all the available end times that are after the start of current entry.
  // Otherwise return all the available end hours between current start time and next entry.
  const nextEntry = sortedEntries[currentIndex + 1];
  const pickAfter = (time) => (h) => timeToValue(h) > timeToValue(time);
  const pickBetween = (start, end) => (h) =>
    timeToValue(h) > timeToValue(start) && timeToValue(h) <= timeToValue(end);

  return !nextEntry || !nextEntry.startTime
    ? availableEndHours.filter(pickAfter(currentEntry.startTime))
    : availableEndHours.filter(pickBetween(currentEntry.startTime, nextEntry.startTime));
};

const getEntryBoundaries = (values, dayOfWeek, intl, findStartHours) => (index) => {
  if (isEmpty(values)) return [];
  const entries = values[dayOfWeek];
  const boundaryDiff = findStartHours ? 0 : 1;

  return entries.reduce((allHours, entry, i) => {
    const { startTime, endTime } = entry || {};

    if (i !== index && startTime && endTime) {
      const startHour = timeToValue(startTime);
      const endHour = timeToValue(endTime);
      const hoursBetween = Array(endHour - startHour)
        .fill()
        .map((v, i) => printHourStrings(startHour + i + boundaryDiff));

      return allHours.concat(hoursBetween);
    }

    return allHours;
  }, []);
};

const DailyPlan = (props) => {
  const { dayOfWeek, values, intl, isTimeBase, openCopyTimeModal, setDayCopySelected } = props;
  const form = useForm();
  const getEntryStartTimes = getEntryBoundaries(values, dayOfWeek, intl, true);
  const getEntryEndTimes = getEntryBoundaries(values, dayOfWeek, intl, false);

  const hasEntries = !isEmpty(values) && values['weekday']?.includes(dayOfWeek);

  const startTimePlaceholder = intl.formatMessage({
    id: 'EditListingAvailabilityPlanForm.startTimePlaceholder',
  });
  const endTimePlaceholder = intl.formatMessage({
    id: 'EditListingAvailabilityPlanForm.endTimePlaceholder',
  });

  //If a day is checked - give it default opening hours
  values.weekday.forEach((day) => {
    if (!values[day][0].startTime && !values[day][0].endTime) {
      values[day][0].startTime = '08:00';
      values[day][0].endTime = '17:00';
    }
  });

  return (
    <div className={classNames(css.weekDay, !hasEntries ? css.noEntries : null)}>
      <FieldCheckbox
        id={dayOfWeek}
        name="weekday"
        value={dayOfWeek}
        label={dayOfWeek}
        className={css.weekdayFieldCheckbox}
        textClassName={css.dayLabel}
        svgClassName={css.checkboxIcon}
      />

      <FieldArray name={dayOfWeek}>
        {({ fields }) => {
          const addNewHourField = () => fields.push({ startTime: null, endTime: null });
          const removeHourField = (index) => () => {
            if (fields.length > 1) {
              return fields.remove(index);
            } else {
              form.batch(() => {
                form.change(dayOfWeek, [
                  {
                    startTime: null,
                    endTime: null,
                  },
                ]);
                form.change(
                  'weekday',
                  values['weekday'].filter((day) => day !== dayOfWeek)
                );
              });
              return fields;
            }
          };
          const handleCopyBtnClick = (index) => () => {
            openCopyTimeModal();
            const { copyTime = [] } = values;
            setDayCopySelected(dayOfWeek);
            if (!copyTime.includes(dayOfWeek)) {
              form.change('copyTime', [...copyTime, dayOfWeek]);
            }
          };
          return hasEntries ? (
            <div className={css.timePicker}>
              {fields.map((name, index) => {
                // Pick available start hours
                const pickUnreservedStartHours = (h) => !getEntryStartTimes(index).includes(h);
                const availableStartHours = ALL_START_HOURS.filter(pickUnreservedStartHours);

                // Pick available end hours
                const pickUnreservedEndHours = (h) => !getEntryEndTimes(index).includes(h);
                const availableEndHours = ALL_END_HOURS.filter(pickUnreservedEndHours);

                return (
                  <div className={css.fieldWrapper} key={name}>
                    {isTimeBase && (
                      <div className={css.formRow}>
                        <div className={css.fieldSelectWrapper}>
                          <div className={css.field}>
                            <FieldSelect
                              id={`${name}.startTime`}
                              name={`${name}.startTime`}
                              selectClassName={css.fieldSelect}
                              selectErrorClass={css.selectError}
                              hideDefaultError
                              validate={required(' ')}
                            >
                              <option disabled value="">
                                {startTimePlaceholder}
                              </option>
                              {filterStartHours(availableStartHours, values, dayOfWeek, index).map(
                                (s) => (
                                  <option value={s} key={s}>
                                    {s}
                                  </option>
                                )
                              )}
                            </FieldSelect>
                          </div>
                          <span className={css.dashBetweenTimes}>-</span>
                          <div className={css.field}>
                            <FieldSelect
                              id={`${name}.endTime`}
                              name={`${name}.endTime`}
                              selectClassName={css.fieldSelect}
                              selectErrorClass={css.selectError}
                              hideDefaultError
                              validate={required(' ')}
                            >
                              <option disabled value="">
                                {endTimePlaceholder}
                              </option>
                              {filterEndHours(availableEndHours, values, dayOfWeek, index).map(
                                (s) => (
                                  <option value={s} key={s}>
                                    {s}
                                  </option>
                                )
                              )}
                            </FieldSelect>
                          </div>
                          <Button
                            type="button"
                            className={css.actionButton}
                            onClick={removeHourField(index)}
                          >
                            <Icons.DeleteIcon className={css.delete} />
                          </Button>
                        </div>
                        <div className={css.actionButtons}>
                          {index === 0 && (
                            <>
                              <Button
                                type="button"
                                className={css.actionButton}
                                onClick={addNewHourField}
                              >
                                <Icons.PlusIcon className={css.plus} />
                              </Button>
                              <Button
                                type="button"
                                className={css.actionButton}
                                onClick={handleCopyBtnClick(index)}
                              >
                                <Icons.CopyIcon className={css.copy} />
                              </Button>
                            </>
                          )}
                        </div>
                      </div>
                    )}
                    {!isTimeBase && (
                      <div className={css.formRow}>
                        <div className={css.fieldSelectWrapper}>
                          <div className={css.field}>
                            <FieldSelect
                              id={`${name}.startTime`}
                              name={`${name}.startTime`}
                              selectClassName={css.fieldSelect}
                              selectErrorClass={css.selectError}
                              hideDefaultError
                              validate={required(' ')}
                            >
                              <option disabled value="">
                                {startTimePlaceholder}
                              </option>
                              {filterStartHours(availableStartHours, values, dayOfWeek, index).map(
                                (s) => (
                                  <option value={s} key={s}>
                                    {s}
                                  </option>
                                )
                              )}
                            </FieldSelect>
                          </div>
                          <span className={css.dashBetweenTimes}>-</span>
                          <div className={css.field}>
                            <FieldSelect
                              id={`${name}.endTime`}
                              name={`${name}.endTime`}
                              selectClassName={css.fieldSelect}
                              selectErrorClass={css.selectError}
                              hideDefaultError
                              validate={required(' ')}
                            >
                              <option disabled value="">
                                {endTimePlaceholder}
                              </option>
                              {filterEndHours(availableEndHours, values, dayOfWeek, index).map(
                                (s) => (
                                  <option value={s} key={s}>
                                    {s}
                                  </option>
                                )
                              )}
                            </FieldSelect>
                          </div>
                          <Button
                            type="button"
                            className={css.actionButton}
                            onClick={removeHourField(index)}
                          >
                            <Icons.DeleteIcon className={css.delete} />
                          </Button>
                        </div>
                        <div className={css.actionButtons}>
                          {index === 0 && (
                            <Button
                              type="button"
                              className={css.actionButton}
                              onClick={handleCopyBtnClick(index)}
                            >
                              <Icons.CopyIcon className={css.copy} />
                            </Button>
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                );
              })}
            </div>
          ) : (
            <div className={css.unavailable}>
              {intl.formatMessage({
                id: 'EditListingAvailabilityPlanFormNew.unavailable',
              })}
            </div>
          );
        }}
      </FieldArray>
    </div>
  );
};

const WEEKLY_HOURS = 'weekly_hours';
const DATE_OVERRIDES = 'date_overrides';

const EditListingAvailabilityPlanFormNewComponent = (props) => {
  const {
    bookingType,
    rootClassName,
    className,
    weekdays,
    values,
    intl,
    isPrivate,
    saveException,
    availabilityExceptions,
    timeZone,
    form,
    addExceptionInProgress,
    onDeleteAvailabilityException,
    inProgress,
    ready,
    disabled,
    isPublished,
  } = props;
  const [isDatepickerOpen, setDatepickerOpen] = useState(false);

  const [noContractLengthRequired, setNoContractLengthRequired] = useState(false);
  useEffect(() => {
    setNoContractLengthRequired(!!values.noReqForContractLength);
  }, [values]);
  const [tableTab, setTableTab] = useState(WEEKLY_HOURS);
  const { width } = useViewport();
  const isMobile = useMemo(() => width < MAX_MOBILE_SCREEN_WIDTH, [width]);
  const [dayCopySelected, setDayCopySelected] = useState();
  const [isCopyTimeModalOpen, setCopyTimeModalOpen] = useBoolean();
  const [isExceptionModalOpen, setExceptionModalOpen] = useBoolean();

  const handleChangeTab = (tabName) => (e) => {
    setTableTab(tabName);
  };

  const classes = classNames(rootClassName || css.root, className);
  const isTimeBase = bookingType === HOURLY_BOOKING;
  const initialExceptionDates = useMemo(
    () => uniq(availabilityExceptions.map((exc) => moment(exc.attributes.start))),
    [availabilityExceptions]
  );

  const rightBtnText = isPublished
    ? intl.formatMessage({ id: 'EditListing.saveButton' })
    : intl.formatMessage({ id: 'EditListing.submitButton' });

  const handleSubmitCopyModal = () => {
    const { copyTime = [], weekday = [] } = values;

    const newWeekday = difference(copyTime, weekday); // use to add new values to the field weekday

    // use to check time range and modify values of weekday
    const intersectionDay = intersection(copyTime, weekday).filter(
      (day) => day !== dayCopySelected
    );

    form.batch(() => {
      form.change('weekday', [...weekday, ...newWeekday]);
      intersectionDay.forEach((day) => {
        form.change(day, [...values[dayCopySelected]]);
      });
      newWeekday.forEach((day) => {
        form.change(day, [...values[dayCopySelected]]);
      });
    });
    setCopyTimeModalOpen.off();
  };

  const deleteException = (params) => () => {
    onDeleteAvailabilityException(params);
  };

  const handleDateUpdate = ({ startDate }) => {
    form.change('startDate', startDate.format('DD/MM/YYYY'));
  };

  return (
    <div className={classes}>
      {values.category === 'fixed' ? (
        <>
          <fieldset className={css.fieldset}>
            <div className={css.mainInput}>
              <label className={css.label}>
                {intl.formatMessage({ id: 'EditListingAvailabilityPlanFormNew.startDate' })}
              </label>
              <button
                type="button"
                onClick={() => setDatepickerOpen(true)}
                className={css.openDatepickerBtn}
              >
                {values.startDate ||
                  intl.formatMessage({ id: 'EditListingAvailabilityPlanFormNew.datePlaceholder' })}
                <svg
                  width="14"
                  height="8"
                  viewBox="0 0 14 8"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M1 1L7 7L13 1" stroke="black" />
                </svg>
              </button>
              {isDatepickerOpen && (
                <OutsideClickHandler
                  className={css.datepickerWrapper}
                  onOutsideClick={() => setDatepickerOpen(false)}
                >
                  <DatePickerNew
                    value={values.startDate}
                    onDatesChange={handleDateUpdate}
                    selectMultipleDays={false}
                    onClose={() => setDatepickerOpen(false)}
                  />
                </OutsideClickHandler>
              )}
            </div>
          </fieldset>
          <fieldset className={css.fieldset}>
            <div className={css.mainInput}>
              <label className={css.label}>
                {intl.formatMessage({ id: 'EditListingAvailabilityPlanFormNew.price' })}
              </label>
              <div className={css.priceAndUnitInput}>
                <FieldNumberInput
                  className={css.pricePerUnitInput}
                  id="pricePerUnit"
                  name="pricePerUnit"
                  placeholder="0"
                  inline={true}
                />
                <FieldSelect className={css.priceUnitSelect} name="priceUnit" id="priceUnit">
                  {['month', 'year'].map((o) => (
                    <option
                      value={o}
                      key={`price_unit_option_${o}`}
                      selected={values?.priceUnit === o}
                    >
                      / {intl.formatMessage({ id: `EditListingAvailabilityPlanFormNew.unit.${o}` })}
                    </option>
                  ))}
                </FieldSelect>
              </div>
            </div>
            <div className={css.subInput}>
              <FieldCheckbox
                textClassName={css.checkboxLabel}
                id="requestPrice"
                name="requestPrice"
                label={intl.formatMessage({
                  id: 'EditListingAvailabilityPlanFormNew.requestPrice',
                })}
              />
            </div>
            {!!values?.requestPrice && !Number(values?.pricePerUnit) && (
              <div className={css.tip}>
                {intl.formatMessage(
                  { id: 'EditListingAvailabilityPlanFormNew.tip' },
                  { bold: (text) => <span>{text}</span> }
                )}
              </div>
            )}
          </fieldset>
          <fieldset className={css.fieldset}>
            <label className={css.label}>
              {intl.formatMessage({ id: 'EditListingAvailabilityPlanFormNew.contractType' })}
            </label>
            <FieldRadioButton
              id="first_hand"
              name="contractType"
              value="first_hand"
              label={intl.formatMessage({
                id: 'EditListingAvailabilityPlanFormNew.contractType.firstHand',
              })}
              textClassName={css.labelText}
            />
            <FieldRadioButton
              id="subleasing"
              name="contractType"
              value="subleasing"
              label={intl.formatMessage({
                id: 'EditListingAvailabilityPlanFormNew.contractType.subleasing',
              })}
              textClassName={css.labelText}
            />
          </fieldset>
          <fieldset className={css.fieldset}>
            <label className={css.label}>
              {intl.formatMessage({ id: 'EditListingAvailabilityPlanFormNew.paymentPeriod' })}
            </label>
            {['month', 'quarter', 'year'].map((radio) => (
              <FieldRadioButton
                key={`payment_period_radio_${radio}`}
                id={radio}
                name="paymentPeriod"
                value={radio}
                label={intl.formatMessage({
                  id: `EditListingAvailabilityPlanFormNew.period.${radio}`,
                })}
                textClassName={css.labelText}
              />
            ))}
          </fieldset>
          <fieldset className={css.fieldset}>
            <div className={css.mainInput}>
              <label className={css.label}>
                {intl.formatMessage({ id: 'EditListingAvailabilityPlanFormNew.contractLength' })}
              </label>
              <div
                className={classNames(
                  css.priceAndUnitInput,
                  noContractLengthRequired && css.disabled
                )}
              >
                <FieldNumberInput
                  className={css.pricePerUnitInput}
                  id="contractLengthPerUnit"
                  name="contractLengthPerUnit"
                  placeholder="0"
                  disabled={noContractLengthRequired}
                  inline={true}
                />
                <FieldSelect
                  className={css.priceUnitSelect}
                  name="contractLengthUnit"
                  id="contractLengthUnit"
                  disabled={noContractLengthRequired}
                >
                  {['month', 'year'].map((o) => (
                    <option
                      value={o}
                      key={`contract_length_unit_${o}`}
                      selected={values?.contractLengthUnit === o}
                    >
                      {intl.formatMessage({
                        id: `EditListingAvailabilityPlanFormNew.unit.${o}.plural`,
                      })}
                    </option>
                  ))}
                </FieldSelect>
              </div>
            </div>
            <p className={css.contractDescription}>
              {intl.formatMessage({
                id: 'EditListingAvailabilityPlanFormNew.contractLength.caption',
              })}
            </p>
            <div className={css.subInput}>
              <FieldCheckbox
                textClassName={css.checkboxLabel}
                id="noReqForContractLength"
                name="noReqForContractLength"
                label={intl.formatMessage({
                  id: 'EditListingAvailabilityPlanFormNew.noReqForContractLength',
                })}
              />
            </div>
          </fieldset>
          <fieldset className={css.fieldset}>
            <div className={css.mainInput}>
              <label className={css.label}>
                {intl.formatMessage({ id: 'EditListingAvailabilityPlanFormNew.noticePeriod' })}
              </label>
              <div className={css.priceAndUnitInput}>
                <FieldNumberInput
                  className={css.pricePerUnitInput}
                  id="noticePeriodPerUnit"
                  name="noticePeriodPerUnit"
                  placeholder="0"
                  inline={true}
                />
                <FieldSelect
                  className={css.priceUnitSelect}
                  name="noticePeriodUnit"
                  id="noticePeriodUnit"
                >
                  {['month', 'year'].map((o) => (
                    <option
                      value={o}
                      key={`notice_period_unit_${o}`}
                      selected={values?.noticePeriodUnit === o}
                    >
                      {intl.formatMessage({
                        id: `EditListingAvailabilityPlanFormNew.unit.${o}.plural`,
                      })}
                    </option>
                  ))}
                </FieldSelect>
              </div>
            </div>
          </fieldset>
          <fieldset className={css.fieldset}>
            <label className={css.label}>
              {intl.formatMessage({
                id: 'EditListingAvailabilityPlanFormNew.allowVatExemptCompanies',
              })}
            </label>
            <FieldCheckbox
              textClassName={css.checkboxLabel}
              id="allowVatExemptCompanies"
              name="allowVatExemptCompanies"
              label={intl.formatMessage({
                id: 'FieldBoolean.yes',
              })}
            />
          </fieldset>
        </>
      ) : (
        <>
          <div className={css.timezone}>
            <FormattedMessage
              id="EditListingAvailabilityPlanFormNew.timezone"
              values={{
                span: (msg) => <span className={css.boldText}>{msg}</span>,
                timezone: timeZone,
              }}
            />
          </div>
          <div className={css.mobileTabs}>
            <div
              className={classNames(css.tab, tableTab === WEEKLY_HOURS ? css.active : null)}
              onClick={handleChangeTab(WEEKLY_HOURS)}
            >
              {intl.formatMessage({ id: 'EditListingAvailabilityPlanFormNew.weeklyHour' })}
            </div>
            <div
              className={classNames(css.tab, tableTab === DATE_OVERRIDES ? css.active : null)}
              onClick={handleChangeTab(DATE_OVERRIDES)}
            >
              {intl.formatMessage({ id: 'EditListingAvailabilityPlanFormNew.dateOverride' })}
            </div>
          </div>
          <div className={css.setPlanWrapper}>
            {!isMobile ? (
              <div className={css.setHoursWrapper}>
                <h2 className={css.heading}>
                  {intl.formatMessage({ id: 'EditListingAvailabilityPlanFormNew.setWeeklyHour' })}
                </h2>
                <div className={css.week}>
                  {weekdays.map((w) => {
                    return (
                      <DailyPlan
                        dayOfWeek={w}
                        key={w}
                        values={values}
                        intl={intl}
                        isTimeBase={isTimeBase}
                        isPrivate={isPrivate}
                        numberOfSeats={values?.seats || 0}
                        openCopyTimeModal={setCopyTimeModalOpen.on}
                        setDayCopySelected={setDayCopySelected}
                      />
                    );
                  })}
                </div>
              </div>
            ) : (
              tableTab === WEEKLY_HOURS && (
                <div className={css.setHoursWrapper}>
                  <h2 className={css.heading}>
                    {intl.formatMessage({ id: 'EditListingAvailabilityPlanFormNew.setWeeklyHour' })}
                  </h2>
                  <div className={css.week}>
                    {weekdays.map((w) => {
                      return (
                        <DailyPlan
                          dayOfWeek={w}
                          key={w}
                          values={values}
                          intl={intl}
                          isTimeBase={isTimeBase}
                          isPrivate={isPrivate}
                          numberOfSeats={values.seats || 0}
                          openCopyTimeModal={setCopyTimeModalOpen.on}
                          setDayCopySelected={setDayCopySelected}
                        />
                      );
                    })}
                  </div>
                </div>
              )
            )}

            {!isMobile ? (
              <div className={css.dateOverridesWrapper}>
                <h2 className={css.heading}>
                  {intl.formatMessage({ id: 'EditListingAvailabilityPlanFormNew.addDateOverride' })}
                </h2>
                {isEmpty(availabilityExceptions) && (
                  <p className={css.emptyExceptionText}>
                    {intl.formatMessage({
                      id: 'EditListingAvailabilityPlanFormNew.emptyException',
                    })}
                  </p>
                )}
                {availabilityExceptions &&
                  availabilityExceptions.map((exception) => {
                    const { start, end } = exception?.attributes || {};
                    const isWholeDay = daysBetween(start, end) === 1;

                    return (
                      <div key={exception.id.uuid} className={css.exceptionWrapper}>
                        <span className={css.exceptionDate}>
                          {formatDateWithOption(start, 'MMM DD, YYYY')}
                        </span>
                        <span className={css.exceptionTime}>
                          {/* TODO: Bring back the time range when we support hourly exceptions
                      {isWholeDay || values.bookingType === 'daily'
                        ? intl.formatMessage({
                            id: 'EditListingAvailabilityPlanFormNew.unavailable',
                          })
                        : `${formatDateWithOption(start, 'HH:mm')} - ${formatDateWithOption(
                            end,
                            'HH:mm'
                          )}`} */}
                          {intl.formatMessage({
                            id: 'EditListingAvailabilityPlanFormNew.unavailable',
                          })}
                        </span>
                        <Button
                          type="button"
                          className={css.actionButton}
                          onClick={deleteException({ id: exception.id })}
                        >
                          <Icons.DeleteIcon className={css.delete} />
                        </Button>
                      </div>
                    );
                  })}
                <Button
                  type="button"
                  className={css.addExceptionBtn}
                  onClick={setExceptionModalOpen.on}
                >
                  {intl.formatMessage({ id: 'EditListingAvailabilityPlanFormNew.addException' })}
                </Button>
              </div>
            ) : (
              tableTab === DATE_OVERRIDES && (
                <div className={css.dateOverridesWrapper}>
                  <h2 className={css.heading}>
                    {intl.formatMessage({
                      id: 'EditListingAvailabilityPlanFormNew.addDateOverride',
                    })}
                  </h2>
                  {isEmpty(availabilityExceptions) && (
                    <p className={css.emptyExceptionText}>
                      {intl.formatMessage({
                        id: 'EditListingAvailabilityPlanFormNew.emptyException',
                      })}
                    </p>
                  )}
                  {availabilityExceptions &&
                    availabilityExceptions.map((exception) => {
                      const { start, end } = exception?.attributes || {};

                      const isWholeDay = daysBetween(start, end) === 1;
                      return (
                        <div key={exception.id.uuid} className={css.exceptionWrapper}>
                          <span className={css.exceptionDate}>
                            {formatDateWithOption(start, 'MMM DD, YYYY')}
                          </span>
                          <span className={css.exceptionTime}>
                            {isWholeDay
                              ? intl.formatMessage({
                                  id: 'EditListingAvailabilityPlanFormNew.unavailable',
                                })
                              : `${formatDateWithOption(start, 'HH:mm')} - ${formatDateWithOption(
                                  end,
                                  'HH:mm'
                                )}`}
                          </span>
                          <Button
                            type="button"
                            className={css.actionButton}
                            onClick={deleteException({ id: exception.id })}
                          >
                            <Icons.DeleteIcon className={css.delete} />
                          </Button>
                        </div>
                      );
                    })}
                  <Button
                    type="button"
                    className={css.addExceptionBtn}
                    onClick={setExceptionModalOpen.on}
                  >
                    {intl.formatMessage({ id: 'EditListingAvailabilityPlanFormNew.addException' })}
                  </Button>
                </div>
              )
            )}
            <PopupModal
              id="CopyTimeModal"
              isOpen={isCopyTimeModalOpen}
              onClose={setCopyTimeModalOpen.off}
              modalContainer={css.modalContainer}
              hideCloseButton
              usePortal
            >
              <div className={css.copyTimeModalContainer}>
                <div className={css.modalTitle}>
                  {intl.formatMessage({ id: 'EditListingAvailabilityPlanFormNew.copyTime' })}
                </div>
                {weekdays.map((w) => (
                  <FieldCheckbox
                    key={w}
                    id={`copyTime-${w}`}
                    name={`copyTime`}
                    value={w}
                    className={css.copyTimeCheckbox}
                    searchFilterClass="reverseRow"
                    label={intl.formatMessage({
                      id: `EditListingAvailabilityPlanForm.dayOfWeek.${w}`,
                    })}
                  />
                ))}
                <Button type="button" className={css.applyBtn} onClick={handleSubmitCopyModal}>
                  {intl.formatMessage({ id: 'EditListingAvailabilityPlanFormNew.applyBtn' })}
                </Button>
              </div>
            </PopupModal>

            <CalendarExceptionModal
              isOpen={isExceptionModalOpen}
              onClose={setExceptionModalOpen.off}
              saveException={saveException}
              availabilityExceptions={availabilityExceptions}
              timeZone={timeZone}
              values={values}
              initialExceptionDates={initialExceptionDates}
              addExceptionInProgress={addExceptionInProgress}
            />
          </div>
        </>
      )}

      <FixedBottomButtons
        leftBtnText={intl.formatMessage({ id: 'EditListing.backButton' })}
        rightBtnText={rightBtnText}
        rightBtnType="submit"
        rightBtnInProgress={inProgress}
        rightBtnReady={ready}
        rightBtnDisabled={disabled}
      />
    </div>
  );
};

EditListingAvailabilityPlanFormNewComponent.defaultProps = {
  rootClassName: null,
  className: null,
  submitButtonWrapperClassName: null,
  inProgress: false,
};

EditListingAvailabilityPlanFormNewComponent.propTypes = {
  rootClassName: string,
  className: string,
  submitButtonWrapperClassName: string,

  inProgress: bool,

  // from injectIntl
  intl: intlShape.isRequired,
};

const EditListingAvailabilityPlanFormNew = compose(injectIntl)(
  EditListingAvailabilityPlanFormNewComponent
);

EditListingAvailabilityPlanFormNew.displayName = 'EditListingAvailabilityPlanFormNew';

export default EditListingAvailabilityPlanFormNew;
