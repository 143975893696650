import classNames from 'classnames';
import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { BookingPanel } from '../../components';
import config from '../../config';
import { InquiryForm } from '../../forms';
import { sendLeadsToVitec, sendToZapier } from '../../util/api';
import { isCompanyUser, isEmployeeUser } from '../../util/b2bHelper';
import { COWORKING_CATEGORY, FIXED_OFFICE_CATEGORY } from '../../util/types';

import BookShowingEmail from '../../emails/BookShowingEmail';
import InquiryEmail from '../../emails/InquiryEmail';
import css from './LocationPage.module.scss';

const prepareSeats = (listingData) => {
  const { minSeats, maxSeats } = listingData || {};
  if (!minSeats || minSeats === maxSeats) {
    return maxSeats;
  } else if (!maxSeats) {
    return minSeats;
  } else if (!!minSeats && !!maxSeats) {
    return `${minSeats} - ${maxSeats}`;
  }
};

const SectionBookingPanel = memo(
  ({
    showBookingPanel,
    listing,
    currentUser,
    companyAccount,
    lineItems,
    monthlyTimeSlots,
    timeSlots,
    onFetchTimeSlots,
    onFetchTransactionLineItems,
    fetchLineItemsInProgress,
    fetchLineItemsError,
    fetchTimeSlotsError,
    bookingData,
    listForListingDropdown,
    onSelectListing,
    onSubmit,
    locationInfo,
    currentUserInfoForEmail,
    onManageDisableScrolling,
    pathname,
  }) => {
    const intl = useIntl();
    const isFixed = useMemo(
      () => listing?.attributes?.publicData?.category === FIXED_OFFICE_CATEGORY || null,
      [listing?.attributes?.publicData?.category]
    );

    const [isInquiryPressed, setInquiryPressed] = useState(false);
    const [bookViewingFormStep, setBookViewingFormStep] = useState(0);

    // if listing, find the listing in the listForListingDropdown and return price and fixedOfficeTypeTranslation
    const { listingFixedCategoryTranslation, listingPrice } = useMemo(() => {
      if (listing) {
        const index = listForListingDropdown.findIndex((item) => item.id === listing.id.uuid);
        const listingFixedCategoryTranslation =
          listForListingDropdown[index]?.fixedOfficeTypeTranslation || '';
        const listingPrice = `${listForListingDropdown[index]?.price}${listForListingDropdown[index]?.unitTranslationKey}`;
        return { listingFixedCategoryTranslation, listingPrice };
      }
      return { listingFixedCategoryTranslation: '', listingPrice: '' };
    }, [listForListingDropdown, listing]);

    // if user changes listing, show the booking panel
    useEffect(() => {
      if (isInquiryPressed) {
        setInquiryPressed(false);
      }
      if (bookViewingFormStep) {
        setBookViewingFormStep(0);
      }
    }, [listing]);

    const handleBookShowingFormSubmit = useCallback(
      async (payload) => {
        const { fullName, companyName, email, phone, message, date, time, suggestTime } = payload;
        const providerEmails = listing.attributes.publicData.bookingEmailAddress;
        const integration = listing.attributes.publicData.officeAPI;
        const address = locationInfo.address;
        const seats = prepareSeats(listing.attributes.publicData);
        const fixedOfficeTypeTranslation = intl.formatMessage({
          id: `MarketplaceConfig.filters.fixedOfficeType.${listing.attributes.publicData.fixedOfficeType}`,
        });
        const locale = intl.locale;
        const locationName = locationInfo.name;

        const objectUrl = `${config.canonicalRootURL}${pathname}`;

        if (integration?.provider && integration.provider === 'vitec') {
          //if fullName has a space character, split it to firstName and lastName
          const firstName = fullName.split(' ')[0];
          const lastName = fullName.split(' ')[1];

          const requestData = {
            Intressent: {
              Foretag: {
                Namn: companyName,
                KontaktPerson: {
                  Epost: email,
                  Fornamn: firstName,
                  Efternamn: lastName,
                  TelefonMobil: phone,
                },
              },
            },
            Kontaktvag: 'Respaces',
            Objekt: {
              GUID: integration.hostId,
              Typ: 'ObjektTyp.CMLokal',
              Kommentar: suggestTime
                ? `The customer asked for a viewing. Message: ${message}`
                : `The customer asked for a viewing. Date: ${date}, Time: ${time}, Message: ${message}`,
            },
          };
          const customerNbr = integration.customerNbr;

          await sendLeadsToVitec(requestData, customerNbr);
        }

        await BookShowingEmail({
          fullName,
          companyName,
          email,
          phone,
          message,
          date,
          time,
          suggestTime,
          providerEmails,
          address,
          seats,
          fixedOfficeTypeTranslation,
          locale,
          locationName,
          objectUrl,
        });

        sendToZapier({
          fullName,
          companyName,
          email,
          phone,
          message,
          date,
          time,
          suggestTime,
          providerEmails,
          address,
          seats,
          fixedOfficeTypeTranslation,
          locale,
          locationName,
          form: 'bookShowing',
        });
      },
      [currentUser, listing]
    );

    const handleInquiryFormSubmit = useCallback(
      async ({ email, fullName, message }) => {
        const providerEmails = listing
          ? listing.attributes.publicData.bookingEmailAddress
          : locationInfo.listings[0].data.attributes.publicData.bookingEmailAddress;
        const { name: locationName } = locationInfo;
        const fixedOfficeTypeTranslation =
          listing &&
          listing.attributes.publicData.fixedOfficeType &&
          intl.formatMessage({
            id: `MarketplaceConfig.filters.fixedOfficeType.${listing.attributes.publicData.fixedOfficeType}`,
          });
        const categoryTranslation =
          listing &&
          intl.formatMessage({
            id: `MarketplaceConfig.filters.category.${listing.attributes.publicData.category}`,
          });
        const address = locationInfo.address;
        const locale = intl.locale;

        const objectUrl = `${config.canonicalRootURL}${pathname}`;

        await InquiryEmail({
          email,
          fullName,
          message,
          providerEmails,
          locationName,
          address,
          fixedOfficeTypeTranslation,
          categoryTranslation,
          locale,
          objectUrl,
        });
        sendToZapier({
          email,
          fullName,
          message,
          providerEmails,
          locationName,
          address,
          fixedOfficeTypeTranslation,
          categoryTranslation,
          locale,
          form: 'inquiry',
        });
        const integration = listing.attributes.publicData.officeAPI;

        if (integration?.provider && integration.provider === 'vitec') {
          //if fullName has a space character, split it to firstName and lastName
          const firstName = fullName.split(' ')[0];
          const lastName = fullName.split(' ')[1];

          const requestData = {
            Intressent: {
              Foretag: {
                Namn: '',
                KontaktPerson: {
                  Epost: email,
                  Fornamn: firstName,
                  Efternamn: lastName,
                  TelefonMobil: '',
                },
              },
            },
            Kontaktvag: 'Respaces',
            Objekt: {
              GUID: integration.hostId,
              Typ: 'ObjektTyp.CMLokal',
              Kommentar: message,
            },
          };

          await sendLeadsToVitec(requestData);
        }
      },
      [listing]
    );

    return (
      <>
        {showBookingPanel ? (
          <div>
            <button className={css.closeIcon} onClick={() => onSelectListing('')}>
              <svg
                width="15"
                height="15"
                viewBox="0 0 17 17"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M1 1L16 16" stroke="black" />
                <path d="M16 1L1 16" stroke="black" />
              </svg>
            </button>
            <BookingPanel
              className={css.bookingPanel}
              listing={listing}
              currentUser={currentUser}
              isCompanyUser={isCompanyUser(currentUser)}
              isEmployeeUser={isEmployeeUser(currentUser)}
              isFixedOffice={isFixed}
              companyAccount={companyAccount}
              addonList={config.custom.addonsList}
              additionalServices={listing?.attributes?.publicData?.additionalServices}
              lineItems={lineItems}
              monthlyTimeSlots={monthlyTimeSlots}
              timeSlots={timeSlots}
              bookingData={bookingData}
              bookingType={listing?.attributes?.publicData?.bookingType}
              onFetchTimeSlots={onFetchTimeSlots}
              onFetchTransactionLineItems={onFetchTransactionLineItems}
              fetchLineItemsInProgress={fetchLineItemsInProgress}
              fetchLineItemsError={fetchLineItemsError}
              fetchTimeSlotsError={fetchTimeSlotsError}
              onSubmit={onSubmit}
              handleBookShowingFormSubmit={handleBookShowingFormSubmit}
              locationAddess={locationInfo.address}
              currentUserInfoForEmail={currentUserInfoForEmail}
              bookViewingFormStep={bookViewingFormStep}
              setBookViewingFormStep={setBookViewingFormStep}
              fixedOfficeTypeTranslation={listingFixedCategoryTranslation}
              seatsInfo={prepareSeats(listing?.attributes?.publicData)}
              areaMin={listing?.attributes?.publicData?.areaMin}
              areaMax={listing?.attributes?.publicData?.areaMax}
              calculatedSeats={listing?.attributes?.publicData?.calculated_seats}
              listingPrice={listingPrice}
              isInquiryPressed={isInquiryPressed}
              setInquiryPressed={setInquiryPressed}
              onManageDisableScrolling={onManageDisableScrolling}
            />
          </div>
        ) : (
          <div className={css.selectListingBox}>
            <h5 className={css.title}>
              {intl.formatMessage({ id: 'LocationPage.selectListingBox.title' })}
            </h5>
            <p className={css.caption}>
              {intl.formatMessage({ id: 'LocationPage.selectListingBox.caption' })}
            </p>
            <label className={css.label} htmlFor="listing_select">
              {intl.formatMessage({ id: 'LocationPage.selectListingBox.label' })}
            </label>
            <select
              onChange={({ currentTarget }) => {
                const payload = listForListingDropdown.find(
                  (item) => item.id === currentTarget.value
                );
                onSelectListing({ id: payload.id, slug: payload.slug });
              }}
              id="listing_select"
              className={css.select}
              defaultValue={''}
            >
              <option value="">
                {intl.formatMessage({ id: 'LocationPage.selectListingBox.select' })}
              </option>
              {listForListingDropdown.map((item) => (
                <option value={item.id} key={item.id}>
                  {item.fixedOfficeTypeTranslation
                    ? item.fixedOfficeTypeTranslation
                    : item.categoryTranslation}
                  {item.category !== COWORKING_CATEGORY &&
                    ' | ' +
                      intl.formatMessage(
                        { id: 'LocationCard.seats' },
                        {
                          seats: prepareSeats({
                            minSeats: item.minSeats,
                            maxSeats: item.maxSeats,
                          }),
                        }
                      )}
                  {' | '}
                  {item.price}
                  {item.unitTranslationKey}
                </option>
              ))}
            </select>
          </div>
        )}
        {!bookViewingFormStep && (
          <div
            className={classNames(
              css.hideOnMobile,
              isInquiryPressed && isFixed && css.inquiryFormWrapper
            )}
          >
            <InquiryForm
              onFormSubmit={handleInquiryFormSubmit}
              isFixed={isFixed}
              currentUserInfoForEmail={currentUserInfoForEmail}
              isPressed={isInquiryPressed}
              setPressed={setInquiryPressed}
              showCloseBtn={isFixed ? false : true}
            />
          </div>
        )}
      </>
    );
  }
);

export default SectionBookingPanel;
