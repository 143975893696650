import React, { useRef } from 'react';
import { bool, func, shape, string } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import {
  autocompleteSearchRequired,
  autocompletePlaceSelected,
  composeValidators,
} from '../../util/validators';
import {
  Form,
  LocationAutocompleteInputNewField,
  FixedBottomButtons,
  SaveAndExitButton,
} from '../../components';
import css from './EditListingLocationFormNew.module.scss';

const identity = (v) => v;

export const EditListingLocationFormNewComponent = (props) => {
  const currentLocationMapRef = useRef(null);

  const onHandleGetCurrentLocation = () => {
    // currentLocationMapRef.current.touchstart();
  };
  return (
    <FinalForm
      {...props}
      render={(formRenderProps) => {
        const {
          className,
          disabled,
          ready,
          handleSubmit,
          intl,
          invalid,
          pristine,
          saveActionMsg,
          updated,
          updateInProgress,
          form,
          fetchErrors,
          values,
          onSearchMapChange,
          onSaveAndExit,
          isPublished,
        } = formRenderProps;

        const addressRequiredMessage = intl.formatMessage({
          id: 'EditListingLocationForm.addressRequired',
        });
        const addressNotRecognizedMessage = intl.formatMessage({
          id: 'EditListingLocationForm.addressNotRecognized',
        });

        const { updateListingError, showListingsError } = fetchErrors || {};
        const errorMessage = updateListingError ? (
          <p className={css.error}>
            <FormattedMessage id="EditListingLocationForm.updateFailed" />
          </p>
        ) : null;

        const errorMessageShowListing = showListingsError ? (
          <p className={css.error}>
            <FormattedMessage id="EditListingLocationForm.showListingFailed" />
          </p>
        ) : null;

        const classes = classNames(css.root, className);
        const submitReady = (updated && pristine) || ready;
        const submitInProgress = updateInProgress;
        const submitDisabled = invalid || disabled || submitInProgress;

        const locationPlaceholder = intl.formatMessage({
          id: 'EditListingLocationFormNew.locationPlaceholder',
        });

        const rightBtnText = isPublished
          ? intl.formatMessage({ id: 'EditListing.saveButton' })
          : intl.formatMessage({ id: 'EditListing.submitButton' });
        const onSaveAndExitHandler = () => {
          onSaveAndExit(values);
        };
        return (
          <Form className={classes} onSubmit={handleSubmit}>
            <SaveAndExitButton onClick={onSaveAndExitHandler} isLocationTab={true} />
            {errorMessage}
            {errorMessageShowListing}
            <LocationAutocompleteInputNewField
              className={css.locationComboBox}
              inputClassName={css.locationAutocompleteInput}
              focusedClassName={css.locationComboBoxFocused}
              predictionsClassName={css.predictionsRoot}
              validClassName={css.validLocation}
              name="location"
              placeholder={locationPlaceholder}
              useDefaultPredictions={true}
              format={identity}
              valueFromForm={values.location}
              validate={composeValidators(
                autocompleteSearchRequired(addressRequiredMessage),
                autocompletePlaceSelected(addressNotRecognizedMessage)
              )}
              onSearchMapChange={onSearchMapChange}
              currentLocationMapRef={currentLocationMapRef}
            />

            <FixedBottomButtons
              leftBtnText={intl.formatMessage({ id: 'EditListing.backButton' })}
              rightBtnText={rightBtnText}
              rightBtnType="submit"
              rightBtnReady={submitReady}
              rightBtnInProgress={submitInProgress}
              rightBtnDisabled={submitDisabled}
              isLocationTab={true}
            />
          </Form>
        );
      }}
    />
  );
};

EditListingLocationFormNewComponent.defaultProps = {
  selectedPlace: null,
  fetchErrors: null,
};

EditListingLocationFormNewComponent.propTypes = {
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  selectedPlace: propTypes.place,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
};

export default compose(injectIntl)(EditListingLocationFormNewComponent);
