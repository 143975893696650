import Axios from 'axios';

import config from '../config';

const axios = Axios.create({
  baseURL: 'https://api.modernmt.com',
  headers: {
    'MMT-ApiKey': config.modernMTApiKey,
  },
});

export const LANGUAGE_CODES = {
  ENGLISH: 'en',
  SWEDISH: 'sv',
};

LANGUAGE_CODES.LIST = [LANGUAGE_CODES.ENGLISH, LANGUAGE_CODES.SWEDISH];

export const modernMTGetLanguages = async () => {
  const response = await axios.get('/languages');

  /**
   * Response for available languages
   * {
   *   [@language]: [Array<String>]
   * }
   * */
  return response.data.data;
};

/**
 * modernMTTranslate params
 * @source: String (optional, will be ignored in making payload)
 * @target: String (optional, in case of null will be replaced with
 * @text: String | Array<String>
 * */
export const modernMTTranslate = async ({
  source = null,
  target = LANGUAGE_CODES.ENGLISH,
  text,
}) => {
  const params = {
    ...(source && { source }),
    target,
    q: text,
  };

  try {
    const response = await axios.get('/translate', {
      params,
    });

    /**
     * Response for single translation:
     * @billed: Boolean
     * @billedCharacters: Number
     * @characters: Number
     * @translation: String
     * @detectedLanguage: String (optional, in case of source === null)
     * */

    /* If the array of strings was passed as a 'q' param, the response will be the array of objects with the structure described above */
    return response.data.data;
  } catch (e) {
    console.log(e);
  }
};
