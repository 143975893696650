import React, { useEffect, useState } from 'react';
import { PopupModal, EditListingIcons as Icons, FieldSelect, Button } from '../../components';
import css from './CalendarExceptionModal.module.scss';
import {
  isInclusivelyAfterDay,
  isInclusivelyBeforeDay,
  DayPickerSingleDateController,
} from 'react-dates';
import { useIntl } from 'react-intl';
import moment from 'moment';
import config from '../../config';
import { FieldArray } from 'react-final-form-arrays';

import {
  ALL_END_HOURS,
  ALL_START_HOURS,
  dateIsAfter,
  getMonthStartInTimeZone,
  nextMonthFn,
  prevMonthFn,
} from '../../util/dates';
import classNames from 'classnames';
import { array, bool, func, object, string } from 'prop-types';
import {
  endOfAvailabilityExceptionRange,
  filterEndHours,
  filterStartHours,
  getEntryBoundaries,
  onMonthClick,
  TODAY,
} from './EditListingAvailabilityPlanForm.helper';

const defaultProps = {
  noBorder: true,
  hideKeyboardShortcutsPanel: true,
  daySize: 36,

  transitionDuration: 200, // milliseconds between next month changes etc.
  isOutsideRange: (day) => {
    const endOfRange = config.dayCountAvailableForBooking - 1;
    return (
      !isInclusivelyAfterDay(day, moment()) ||
      !isInclusivelyBeforeDay(day, moment().add(endOfRange, 'days'))
    );
  },
  weekDayFormat: 'ddd',
};

const initialHourBlock = {
  startTime: null,
  endTime: null,
};

// Components for the react-dates calendar
const Next = (props) => {
  const { currentMonth, timeZone } = props;
  const nextMonthDate = nextMonthFn(currentMonth, timeZone);

  return dateIsAfter(nextMonthDate, endOfAvailabilityExceptionRange(timeZone, TODAY)) ? null : (
    <Icons.CheronRightIcon className={css.cheronRight} />
  );
};
const Prev = (props) => {
  const { currentMonth, timeZone } = props;
  const prevMonthDate = prevMonthFn(currentMonth, timeZone);
  const currentMonthDate = getMonthStartInTimeZone(TODAY, timeZone);

  return dateIsAfter(prevMonthDate, currentMonthDate) ? (
    <Icons.CheronRightIcon className={css.cheronLeft} />
  ) : (
    <Icons.CheronRightIcon className={classNames(css.cheronLeft, css.disable)} />
  );
};

const CalendarExceptionModal = (props) => {
  const {
    isOpen,
    onClose,
    initialDate,
    value,
    saveException,
    values,
    availabilityExceptions,
    timeZone,
    onMonthChanged,
    addExceptionInProgress,
    initialExceptionDates,
    ...datePickerProps
  } = props;
  const intl = useIntl();
  const [dates, setDates] = useState([]);
  useEffect(() => {
    setDates([...initialExceptionDates]);
  }, [initialExceptionDates]);

  const [currentMonth, setCurrentMonth] = useState(getMonthStartInTimeZone(TODAY, timeZone));

  const initialMoment = initialDate ? moment(initialDate) : null;
  const getEntryStartTimes = getEntryBoundaries(values, true);
  const getEntryEndTimes = getEntryBoundaries(values, false);
  const calendarDate =
    value && value.date instanceof Date && value.date.toString() !== 'Invalid Date'
      ? moment(value.date)
      : initialMoment;

  const startTimePlaceholder = intl.formatMessage({
    id: 'EditListingAvailabilityPlanForm.startTimePlaceholder',
  });
  const endTimePlaceholder = intl.formatMessage({
    id: 'EditListingAvailabilityPlanForm.endTimePlaceholder',
  });

  const modalTitle = intl.formatMessage({ id: 'CalendarExceptionModal.modalTitle' });
  const modalSubTitle = intl.formatMessage({ id: 'CalendarExceptionModal.modalSubTitle' });
  const hourBlockLabel = intl.formatMessage({ id: 'CalendarExceptionModal.hourBlockLabel' });

  const onDateChange = (date) => {
    const newDates = dates.includes(date) ? dates.filter((d) => !date.isSame(d)) : [...dates, date];
    setDates(newDates);
  };

  const handleMonthClick = onMonthClick(currentMonth, setCurrentMonth, timeZone, onMonthChanged);

  const onSubmitDates = () => {
    saveException(values, dates, onClose);
  };

  return (
    <PopupModal
      id="CalendarExceptionModal"
      isOpen={isOpen}
      onClose={onClose}
      modalContainer={css.modalContainer}
      hideCloseButton
    >
      <div className={css.exceptionModalContainer}>
        <div className={css.modalTitle}>{modalTitle}</div>
        <div className={css.modalSubTitle}>
          <div className={css.horizontalLine}>{modalSubTitle}</div>
        </div>
        <div className={css.scrollableArea}>
          {isOpen && (
            <div className={css.calendarWrapper}>
              <DayPickerSingleDateController
                {...datePickerProps}
                onDateChange={onDateChange}
                navNext={<Next currentMonth={currentMonth} timeZone={timeZone} />}
                navPrev={<Prev currentMonth={currentMonth} timeZone={timeZone} />}
                onPrevMonthClick={() => handleMonthClick(prevMonthFn)}
                onNextMonthClick={() => handleMonthClick(nextMonthFn)}
                date={calendarDate}
                isDayHighlighted={(d1) => dates.some((d2) => d1.isSame(d2, 'day'))}
              />
            </div>
          )}

          {
            // TODO: Add/correct logic for making exceptions for hourly bookings that replace the current opening hours
            // this was not working now - the excetions is not not replacing the previous opening hours but just adding to them.
            // There is also not logic for making sure that seats are set to 0 or maxSeats depending on if times are set using this modal.
            // This is a temporary fix for now adding a check that will never be true and setting all exceptions to seats = 0.
          }
          {dates.length > 0 && values.bookingType === 'unicorn' && (
            <div className={css.hourBlockWrapper}>
              <div className={css.hourBlockLabel}>{hourBlockLabel}</div>
              <FieldArray name="exceptionHourBlock">
                {({ fields }) => {
                  const addNewHourBlock = () => {
                    fields.push(initialHourBlock);
                  };
                  const removeHourBlock = (index) => () => {
                    fields.remove(index);
                  };

                  return fields.length > 0 ? (
                    fields.map((name, index) => {
                      // Pick available start hours
                      const pickUnreservedStartHours = (h) =>
                        !getEntryStartTimes(index).includes(h);
                      const availableStartHours = ALL_START_HOURS.filter(pickUnreservedStartHours);

                      // Pick available end hours
                      const pickUnreservedEndHours = (h) => !getEntryEndTimes(index).includes(h);
                      const availableEndHours = ALL_END_HOURS.filter(pickUnreservedEndHours);
                      return (
                        <div className={css.formRow} key={name}>
                          <div className={css.fieldSelectWrapper}>
                            <div className={css.field}>
                              <FieldSelect
                                id={`${name}.startTime`}
                                name={`${name}.startTime`}
                                selectClassName={css.fieldSelect}
                                // validate={required(' ')}
                              >
                                <option disabled value="">
                                  {startTimePlaceholder}
                                </option>
                                {filterStartHours(availableStartHours, values, index).map((s) => (
                                  <option key={s} value={s}>
                                    {s}
                                  </option>
                                ))}
                              </FieldSelect>
                            </div>
                            <span className={css.dashBetweenTimes}>-</span>
                            <div className={css.field}>
                              <FieldSelect
                                id={`[${name}].endTime`}
                                name={`${name}.endTime`}
                                selectClassName={css.fieldSelect}
                                // validate={required(' ')}
                              >
                                <option disabled value="">
                                  {endTimePlaceholder}
                                </option>
                                {filterEndHours(availableEndHours, values, index).map((s) => (
                                  <option key={s} value={s}>
                                    {s}
                                  </option>
                                ))}
                              </FieldSelect>
                            </div>
                            <Button
                              type="button"
                              className={css.actionButton}
                              onClick={removeHourBlock(index)}
                            >
                              <Icons.DeleteIcon className={css.delete} />
                            </Button>
                          </div>
                          {index === fields.length - 1 && (
                            <Button
                              type="button"
                              className={css.actionButton}
                              onClick={addNewHourBlock}
                            >
                              <Icons.PlusIcon className={css.plus} />
                            </Button>
                          )}
                        </div>
                      );
                    })
                  ) : (
                    <div className={css.emptyHourBlock}>
                      <span>
                        {intl.formatMessage({ id: 'CalendarExceptionModal.emptyHourBlock' })}
                      </span>
                      <Button type="button" className={css.actionButton} onClick={addNewHourBlock}>
                        <Icons.PlusIcon />
                      </Button>
                    </div>
                  );
                }}
              </FieldArray>
            </div>
          )}
        </div>
        <div className={css.bottomButtonWrapper}>
          <Button type="button" className={css.cancelBtn} onClick={onClose}>
            {intl.formatMessage({ id: 'CalendarExceptionModal.cancelBtn' })}
          </Button>
          <Button
            type="button"
            className={css.applyBtn}
            onClick={onSubmitDates}
            inProgress={addExceptionInProgress}
          >
            {intl.formatMessage({ id: 'CalendarExceptionModal.applyBtn' })}
          </Button>
        </div>
      </div>
    </PopupModal>
  );
};

CalendarExceptionModal.defaultProps = {
  ...defaultProps,
  initialExceptionDates: [],
  saveException: () => {},
};

CalendarExceptionModal.propTypes = {
  isOpen: bool,
  onClose: func,
  initialDate: array,
  saveException: func.isRequired,
  values: object,
  availabilityExceptions: array,
  timeZone: string,
  onMonthChanged: func,
  addExceptionInProgress: bool,
  initialExceptionDates: array,
};

export default CalendarExceptionModal;
