import React from 'react';
import { FormattedMessage } from 'react-intl';

import { ReviewRating } from '..';

import css from './ReviewCard.module.scss';

const ReviewCard = ({ review }) => {
  const { rating, text, name } = review;

  return (
    <div className={css.reviewCard}>
      <ReviewRating rating={rating} filledColor="gold" reviewStarClassName={css.reviewCardStar} />
      <FormattedMessage id={text}>{(text) => <p className={css.text}>{text}</p>}</FormattedMessage>
      <h5 className={css.name}>{name}</h5>
    </div>
  );
};

export default ReviewCard;
