import React, { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import chunk from 'lodash/chunk';
import isEqual from 'lodash/isEqual';
import { useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';

import { PaginationLinks } from '../../components';
import { getMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { withViewport } from '../../util/contextHelpers';
import { inBrowser, isMobile } from '../../util/device';
import { DEFAULT_SEARCH_PARAMS } from '../../util/search';

import ContentLoaderDesktop from './ContentLoader';
import ContentLoaderMobile from './ContentLoaderMobile';
import TransactionItem from './TransactionItem';

import css from './SectionBookings.module.scss';

const SectionBookings = ({ upcomingRefsLoading, previousRefsLoading }) => {
  return (
    <div className={css.root}>
      <SectionUpcomingBookings isLoading={upcomingRefsLoading} />
      <SectionPreviousBookings isLoading={previousRefsLoading} />
    </div>
  );
};

export default SectionBookings;

const TableHeader = () => {
  return (
    <div className={css.tableHeader}>
      <div className={css.tableHeaderCell}>
        <FormattedMessage id="SectionBookings.tableHeader.location" defaultMessage="LOCATION" />
      </div>
      <div className={css.tableHeaderCell}>
        <FormattedMessage id="SectionBookings.tableHeader.type" defaultMessage="TYPE" />
      </div>
      <div className={css.tableHeaderCell}>
        <FormattedMessage id="SectionBookings.tableHeader.date" defaultMessage="DATE" />
      </div>
      <div className={css.tableHeaderCell}>
        <FormattedMessage id="SectionBookings.tableHeader.status" defaultMessage="STATUS" />
      </div>
      <div className={css.tableHeaderCell} />
    </div>
  );
};

const SectionUpcomingBookings = withViewport(({ viewport, isLoading }) => {
  const locationNames = inBrowser() ? JSON.parse(window.localStorage.getItem('locationNames')) : [];
  const { width } = viewport;
  const isDesktop = width >= 1024;
  const pagination = useSelector((state) => state.CompanyBookingsPage.pagination, isEqual);
  const upcomingRefs = useSelector((state) => state.CompanyBookingsPage.upcomingRefs, isEqual);
  const txs = useSelector((state) => getMarketplaceEntities(state, upcomingRefs), isEqual)
    .sort((a, b) => a.booking.attributes.start - b.booking.attributes.start)
    .map((tx) => {
      const { name: locationName, id: locationId } = locationNames.find(
        (l) => l.id === tx.listing.attributes.publicData.locationId
      );
      return {
        ...tx,
        locationId,
        locationName,
      };
    });
  const hasBookings = txs.length > 0;

  const shouldShow = isDesktop ? pagination.page === 1 : true;

  const noBookingResult = useMemo(() => {
    if (hasBookings) {
      return null;
    }
    return (
      <>
        <div className={css.noResultTitle}>
          <FormattedMessage
            id="SectionBookings.noResultTitle"
            defaultMessage="You dont have anything booked"
          />
        </div>
        <div className={css.noResultSubtitle}>
          <FormattedMessage
            id="SectionBookings.noResultSubtitle"
            defaultMessage="Once you do, you’ll see it here."
          />
        </div>
        <Link className={css.explore} to={DEFAULT_SEARCH_PARAMS}>
          <FormattedMessage id="SectionBookings.explore" defaultMessage="Explore" />
        </Link>
      </>
    );
  }, [hasBookings]);

  return shouldShow ? (
    <div className={css.sectionUpcoming}>
      <div className={css.sectionTitle}>
        <FormattedMessage id="SectionBookings.upcoming" />
      </div>
      {noBookingResult}
      {hasBookings && <TableHeader />}
      {isLoading ? (
        <div className={css.loaderContainer}>
          <div className={css.loaderWrapper}>
            {isMobile ? <ContentLoaderMobile /> : <ContentLoaderDesktop />}
          </div>
        </div>
      ) : (
        txs.map((tx) => (
          <TransactionItem
            transaction={tx}
            key={`TransactionItem_${tx.id.uuid}`}
            className={css.bookingItem}
          />
        ))
      )}
    </div>
  ) : null;
});

const getItemForPage = (items = [], page) => {
  if (page === 1) {
    return items.slice(0, 5);
  }
  const chunked = chunk(items.slice(5), 10);
  return chunked[page - 2];
};

const SectionPreviousBookings = ({ isLoading }) => {
  const locationNames = inBrowser() ? JSON.parse(window.localStorage.getItem('locationNames')) : [];
  const params = useParams();
  const previousRefs = useSelector((state) => state.CompanyBookingsPage.previousRefs, isEqual);

  const pagination = useSelector((state) => state.CompanyBookingsPage.pagination, isEqual);
  const txs = useSelector((state) => getMarketplaceEntities(state, previousRefs), isEqual)
    .sort((a, b) => b.booking.attributes.start - a.booking.attributes.start)
    .map((tx) => {
      const { name: locationName, id: locationId } =
        locationNames.find((l) => l.id === tx.listing.attributes.publicData.locationId) || {};
      return {
        ...tx,
        locationId,
        locationName,
      };
    });

  const { page = 1 } = pagination;
  const hasBookings = txs.length > 0;
  const noBookingResult = useMemo(() => {
    return hasBookings ? null : (
      <>
        <div className={css.noResultTitle}>
          <FormattedMessage
            id="SectionBookings.noResultTitle"
            defaultMessage="You dont have anything booked"
          />
        </div>
      </>
    );
  }, [hasBookings]);

  const txsToShow = getItemForPage([...txs], page);

  return (
    <div className={css.sectionPrevious}>
      <div className={css.sectionTitle}>
        <FormattedMessage id="SectionBookings.previous" />
      </div>
      <TableHeader />
      {isLoading ? (
        <div className={css.loaderContainer}>
          <div className={css.loaderWrapper}>
            {isMobile ? <ContentLoaderMobile /> : <ContentLoaderDesktop />}
          </div>
        </div>
      ) : Array.isArray(txsToShow) && txsToShow.length ? (
        txsToShow.map((tx) => (
          <TransactionItem
            transaction={tx}
            key={`TransactionItem_${tx.id.uuid}`}
            className={css.bookingItem}
          />
        ))
      ) : (
        noBookingResult
      )}

      <PaginationLinks
        pagination={pagination}
        pageName="CompanyBookingsPage"
        params={params}
        className={css.paginationLinks}
        pageClassName={css.paginationNumber}
        isSM
      />
    </div>
  );
};
