export const inBrowser = () => typeof window !== 'undefined';

export const smallViewportMatch = () => _matchMedia('(max-width: 768px)');

export const narrowViewportMatch = () => _matchMedia('(max-width: 878px)');

export const largeViewportMatch = () => _matchMedia('(max-width: 1024px)');

export const largeSViewportMatch = () => _matchMedia('(min-width: 1430px)');

export const touchDeviceMatch = () => _matchMedia('(pointer: coarse)');

export const isMobile = narrowViewportMatch().matches && touchDeviceMatch().matches;

export const BROWSERS = {
  SAFARI: 'Safari',
  CHROME: 'Chrome',
};

export const getBrowser = () => {
  let userAgent = navigator.userAgent,
    tem,
    M = userAgent.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];

  if (/trident/i.test(M[1])) {
    tem = /\brv[ :]+(\d+)/g.exec(userAgent) || [];
    return { name: 'IE', version: tem[1] || '' };
  }

  if (M[1] === 'Chrome') {
    tem = userAgent.match(/\bOPR|Edge\/(\d+)/);
    if (tem != null) {
      return { name: 'Opera', version: tem[1] };
    }
  }

  M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, '-?'];

  if ((tem = userAgent.match(/version\/(\d+)/i)) != null) {
    M.splice(1, 1, tem[1]);
  }

  return {
    browser: M[0],
    version: M[1],
  };
};

export const isNewestSafari = () => {
  const { browser, version } = inBrowser() ? getBrowser() : {};
  return (browser === BROWSERS.SAFARI && version >= '15') || false;
};

export const saveDeviceVW = () => {
  const vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', `${vh}px`);
};

/**
 * @param mediaQS {String}
 **/
function _matchMedia(mediaQS) {
  if (typeof window !== 'undefined') {
    window.matchMedia =
      window.matchMedia ||
      function () {
        return {
          matches: false,
        };
      };

    return window.matchMedia(mediaQS);
  }

  return {
    matches: false,
  };
}

export const MAX_MOBILE_SCREEN_WIDTH = 1024;
