import React from 'react';
import { useIntl } from 'react-intl';
import {
  Button,
  FieldSelect,
  Modal,
  PrimaryButton,
  EditListingIcons as Icons,
  FieldTextInput,
  FieldNumberFixedPlaceholder,
} from '..';
import css from './AddonDetailModal.module.scss';
import config from '../../config';
import { required, composeValidators, minimumValue } from '../../util/validators';
import { ALL_END_HOURS, ALL_START_HOURS, timeToValue } from '../../util/dates';
import { bool, func } from 'prop-types';
import { string } from 'prop-types';
import { object } from 'prop-types';

const MINIMUM_ADDON_PRICE_VALUE = 1;

const onManageDisableScrolling = () => null;

const AddonDetailModal = (props) => {
  const { isOpen, onClose, label, handleClick, values, addonName } = props;
  const intl = useIntl();

  const addonPriceValidator = composeValidators(
    required(intl.formatMessage({ id: 'AddonDetailModal.addonPriceRequired' })),
    minimumValue(
      intl.formatMessage(
        { id: 'AddonDetailModal.addonPriceMinimun' },
        { minimun: MINIMUM_ADDON_PRICE_VALUE }
      ),
      MINIMUM_ADDON_PRICE_VALUE
    )
  );
  const filterEndHours = (availableEndHours, values) => {
    const { addonsInfo = {} } = values;
    const serviceStartTime = addonsInfo?.[addonName]?.startTime;
    if (!serviceStartTime) return [];

    const pickAfter = (time) => (h) => timeToValue(h) > timeToValue(time);
    return availableEndHours.filter(pickAfter(serviceStartTime));
  };
  const priceLabel = intl.formatMessage(
    { id: `AddonDetailModal.price` },
    { light: (val) => <span className={css.lightText}>{val}</span> }
  );
  return (
    <Modal
      id="AddonDetailModal"
      isOpen={isOpen}
      onClose={onClose}
      hideCloseButton
      openClassName={css.openModal}
      scrollLayerClassName={css.scrollableLayer}
      containerClassName={css.modalContainer}
      onManageDisableScrolling={onManageDisableScrolling}
    >
      <div className={css.modalContainer}>
        <div className={css.modalTitle}>{label}</div>
        <div className={css.priceFieldWrapper}>
          <FieldNumberFixedPlaceholder
            id={`addonsInfo[${addonName}].price`}
            name={`addonsInfo[${addonName}].price`}
            placeholder="SEK"
            label={priceLabel}
            isTypingInput
            className={css.fieldWrapper}
            inputWrapperClassname={css.rootInput}
            fixedPlaceholderClassname={css.fixedPlaceholder}
            inline
            validate={addonPriceValidator}
            maxlength="8"
          />
        </div>
        <div className={css.vatFieldWrapper}>
          <FieldSelect
            id={`addonsInfo[${addonName}].vatPercentage`}
            name={`addonsInfo[${addonName}].vatPercentage`}
            className={css.fieldWrapper}
            label={intl.formatMessage({ id: 'AddonDetailModal.vat' })}
            labelClassName={css.fieldTitle}
            inline
            validate={required(
              intl.formatMessage({ id: 'AddonDetailModal.addonVatPercentageRequired' })
            )}
          >
            <option disabled value="" className={css.optionPlaceholder}>
              {intl.formatMessage({ id: 'AddonDetailModal.vat.placeholder' })}
            </option>
            {config.custom.VAT_VALUES.map((opt) => (
              <option value={opt.key} key={`vat_${opt.key}`}>
                {opt.label}
              </option>
            ))}
          </FieldSelect>
        </div>
        <div className={css.serviceTimeFieldWrapper}>
          <div className={css.fieldTitle}>
            {intl.formatMessage({ id: 'AddonDetailModal.timeOfService' })}
          </div>
          <div className={css.fieldsWrapper}>
            <FieldSelect
              id={`addonsInfo[${addonName}].startTime`}
              name={`addonsInfo[${addonName}].startTime`}
              selectClassName={css.fieldSelect}
            >
              <option disabled value="">
                00:00
              </option>
              {ALL_START_HOURS.map((s) => (
                <option value={s} key={s}>
                  {s}
                </option>
              ))}
            </FieldSelect>
            <span className={css.dash}>-</span>
            <FieldSelect
              id={`addonsInfo[${addonName}].endTime`}
              name={`addonsInfo[${addonName}].endTime`}
              selectClassName={css.fieldSelect}
            >
              <option disabled value="">
                00:00
              </option>
              {filterEndHours(ALL_END_HOURS, values).map((s) => (
                <option value={s} key={s}>
                  {s}
                </option>
              ))}
            </FieldSelect>
            <Icons.CheronDownIcon className={css.cheronDown} />
          </div>
        </div>

        <div className={css.detailFieldWrapper}>
          <div className={css.fieldTitle}>
            {intl.formatMessage({ id: 'AddonDetailModal.additionalInfo' })}
          </div>
          <FieldTextInput
            id={`addonsInfo[${addonName}].description`}
            name={`addonsInfo[${addonName}].description`}
            type="textarea"
            className={css.detailField}
            maxCharacters={35}
          />
        </div>
        <div className={css.bottomButtonWrapper}>
          <Button type="button" onClick={onClose} className={css.cancelBtn}>
            {intl.formatMessage({ id: 'AddonDetailModal.cancel' })}
          </Button>
          <PrimaryButton type="button" onClick={handleClick} className={css.applyBtn}>
            {intl.formatMessage({ id: 'AddonDetailModal.apply' })}
          </PrimaryButton>
        </div>
      </div>
    </Modal>
  );
};

AddonDetailModal.propTypes = {
  isOpen: bool.isRequired,
  onClose: func.isRequired,
  label: string.isRequired,
  handleClick: func.isRequired,
  values: object.isRequired,
  addonName: string.isRequired,
};

export default AddonDetailModal;
