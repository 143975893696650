import Markdown from 'markdown-to-jsx';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import {
  Button,
  Footer,
  LayoutSingleColumn,
  LayoutWrapperFooter,
  LayoutWrapperMain,
  LayoutWrapperTopbar,
  NamedLink,
} from '../../components';
import facebookImage from '../../assets/respaces_social_1260x630.png';
import config from '../../config';
import { StaticPage, TopbarContainer } from '../../containers';
import css from './BlogPage.module.scss';

const SinglePost = () => {
  const { fetchDataError, fetchInProgress, postData } = useSelector((state) => {
    const {
      BlogPage: { fetchDataError, fetchInProgress, postData },
    } = state;
    return { fetchDataError, fetchInProgress, postData };
  });

  const defaultSchemaImage = `${config.canonicalRootURL}${facebookImage}`;
  const schemaImage = postData ? postData.cover?.url : defaultSchemaImage;
  const description = postData ? postData.subtitle : `Respaces Blog post`;
  return (
    <StaticPage
      title={postData ? postData.title : 'Respaces Blog Page '}
      contentType="website"
      description={description}
      facebookImages={[
        {
          name: 'facebook',
          url: schemaImage,
          width: 1200,
          height: 630,
        },
      ]}
      twitterImages={[
        {
          name: 'twitter',
          url: schemaImage,
          width: 600,
          height: 314,
        },
      ]}
      schema={{
        '@context': 'http://schema.org',
        '@type': 'BlogPage',
        description: description,
        name: postData ? postData.title : 'Blog Page ',
        image: (postData && postData.cover?.formats?.large?.url) || defaultSchemaImage,
      }}
    >
      <LayoutSingleColumn>
        <LayoutWrapperTopbar>
          <TopbarContainer currentPage="BlogPost" />
        </LayoutWrapperTopbar>
        {/* TODO add translation to all post details */}
        <LayoutWrapperMain className={css.mainWrapperPost}>
          {fetchInProgress ? (
            <FormattedMessage id="SinglePost.loading" />
          ) : postData ? (
            <>
              <div className={css.postContainer}>
                {postData.cover && (
                  <img
                    className={css.mainImage}
                    src={
                      (postData.cover.formats.large && postData.cover.formats.large.url) ||
                      postData.cover.url
                    }
                    alt={postData.title}
                  />
                )}

                <h1 className={css.singlePostTitle}>{postData.title}</h1>
                <p className={css.singlePostInfo}>
                  {`${postData.published_at && postData.published_at.slice(0, 10)} • ${
                    postData.category && postData.category.title
                  }`}
                </p>
                <Markdown
                  className={css.postBlockContent}
                  options={{
                    forceBlock: true,
                  }}
                >
                  {postData.body}
                </Markdown>
                <div className={css.postFooter}>
                  <p>
                    <span className={css.footerTag}>{`#${
                      postData.category?.title
                        ? postData.category.title.replace(/\s/g, '').toLowerCase()
                        : ''
                    }`}</span>{' '}
                    @ Stockholm, Sweden
                  </p>
                </div>
              </div>
              <NamedLink className={css.postButtonLink} name="BlogPage">
                <Button className={css.postButton}>
                  <FormattedMessage id="BlogPage.browseMoreStoriesButton" />
                </Button>
              </NamedLink>
            </>
          ) : (
            !fetchInProgress &&
            !postData &&
            fetchDataError && (
              <FormattedMessage id="BlogPage.errorLoading">
                {(id) => <p className={css.loading}>{id}</p>}
              </FormattedMessage>
            )
          )}
        </LayoutWrapperMain>

        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </LayoutSingleColumn>
    </StaticPage>
  );
};
export default SinglePost;
