import React from 'react';

import css from './LocationCombobox.module.scss';

const ListingIcon = () => (
  <svg
    className={css.locationOptionIcon}
    width="28"
    height="28"
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="14" cy="14" r="14" fill="#F8F8F8" />
    <path
      d="M18.7413 9.89648H15.707V10.6551H18.7413V18.62H15.707V19.3786H19.4999V10.6551C19.4999 10.4539 19.42 10.2609 19.2777 10.1187C19.1354 9.97641 18.9425 9.89648 18.7413 9.89648V9.89648Z"
      fill="#999999"
    />
    <path
      d="M14.523 8H9.30408C9.09083 8 8.88631 8.08472 8.73551 8.23551C8.58472 8.38631 8.5 8.59083 8.5 8.80408V19.3785H15.3271V8.80408C15.3271 8.59083 15.2424 8.38631 15.0916 8.23551C14.9408 8.08472 14.7363 8 14.523 8V8ZM14.5686 18.62H13.4307V17.4821H10.3964V18.62H9.25857V8.80408C9.25857 8.79811 9.25975 8.79219 9.26203 8.78667C9.26432 8.78114 9.26767 8.77613 9.2719 8.7719C9.27613 8.76767 9.28114 8.76432 9.28667 8.76203C9.29219 8.75975 9.29811 8.75857 9.30408 8.75857H14.523C14.529 8.75857 14.5349 8.75975 14.5405 8.76203C14.546 8.76432 14.551 8.76767 14.5552 8.7719C14.5595 8.77613 14.5628 8.78114 14.5651 8.78667C14.5674 8.79219 14.5686 8.79811 14.5686 8.80408V18.62Z"
      fill="#999999"
    />
    <path d="M10.0176 9.89648H10.7761V10.6551H10.0176V9.89648Z" fill="#999999" />
    <path d="M11.5352 9.89648H12.2937V10.6551H11.5352V9.89648Z" fill="#999999" />
    <path d="M13.0508 9.89648H13.8094V10.6551H13.0508V9.89648Z" fill="#999999" />
    <path d="M10.0176 11.793H10.7761V12.5515H10.0176V11.793Z" fill="#999999" />
    <path d="M11.5352 11.793H12.2937V12.5515H11.5352V11.793Z" fill="#999999" />
    <path d="M13.0508 11.793H13.8094V12.5515H13.0508V11.793Z" fill="#999999" />
    <path d="M10.0176 13.6892H10.7761V14.4478H10.0176V13.6892Z" fill="#999999" />
    <path d="M11.5352 13.6892H12.2937V14.4478H11.5352V13.6892Z" fill="#999999" />
    <path d="M13.0508 13.6892H13.8094V14.4478H13.0508V13.6892Z" fill="#999999" />
    <path d="M10.0176 15.5857H10.7761V16.3443H10.0176V15.5857Z" fill="#999999" />
    <path d="M11.5352 15.5857H12.2937V16.3443H11.5352V15.5857Z" fill="#999999" />
    <path d="M13.0508 15.5857H13.8094V16.3443H13.0508V15.5857Z" fill="#999999" />
    <path d="M15.707 11.793H16.4656V12.5515H15.707V11.793Z" fill="#999999" />
    <path d="M17.2246 11.793H17.9832V12.5515H17.2246V11.793Z" fill="#999999" />
    <path d="M15.707 13.6892H16.4656V14.4478H15.707V13.6892Z" fill="#999999" />
    <path d="M17.2246 13.6892H17.9832V14.4478H17.2246V13.6892Z" fill="#999999" />
    <path d="M15.707 15.5857H16.4656V16.3443H15.707V15.5857Z" fill="#999999" />
    <path d="M17.2246 15.5857H17.9832V16.3443H17.2246V15.5857Z" fill="#999999" />
  </svg>
);

export default ListingIcon;
