import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { bool, func, node, oneOfType, shape, string } from 'prop-types';
import { Form as FinalForm } from 'react-final-form';
import { useIntl } from 'react-intl';
import { FieldNumberInput } from '../../../../components';
import { ComboBoxNew } from '../../../../components_new';
import { KEY_CODES } from '../../config';

import { ComboBoxClearButton } from '../index';

import css from './SeatsComboBox.module.scss';

const SeatsComboBoxNew = ({
  id,
  name,
  currentSeats,
  isMobile,
  isOpen,
  onSeatsChange,
  inputRef,
  className,
  focusedClassName,
  dropdownClassName,
  onClose,
  onOpen,
  isRelevantFilter,
}) => {
  const intl = useIntl();
  const [seatsValue, setSeatsValue] = useState('');
  const clear = () => {
    onSeatsChange(null);
    onClose();
  };

  useEffect(() => {
    if (!!currentSeats) {
      setSeatsValue(currentSeats);
    } else {
      setSeatsValue('');
    }
  }, [currentSeats]);

  const handleKeyDown = (e) => {
    e.preventDefault();
    const { key } = e;

    if (key === KEY_CODES.ESCAPE) {
      onClose();
      inputRef?.current?.blur();
    } else if (key === KEY_CODES.ENTER) {
      onClose();
      inputRef?.current?.blur();
    }
  };

  return (
    <ComboBoxNew
      id={id}
      name={name}
      inputRef={inputRef}
      isOpen={isOpen}
      className={className}
      focusedClassName={focusedClassName}
      dropdownClassName={dropdownClassName}
      onFocus={onOpen}
      onBlur={onClose}
      isMobile={isMobile}
      readOnly={true}
      hideInput={isMobile || isRelevantFilter}
      placeholder={intl.formatMessage({ id: 'ListingSearchForm.seatsInput.seatsPlaceholder' })}
      value={seatsValue}
      onKeyDown={handleKeyDown}
      isRelevantFilter={isRelevantFilter}
    >
      <div
        className={classNames(
          css.dropdownWrapper,
          isMobile && css.mobile,
          isRelevantFilter && css.isRelevantFilter
        )}
      >
        <FinalForm
          onSubmit={() => onClose}
          render={() => {
            return (
              <div className={css.option}>
                <span className={css.seatsTitle}>
                  {intl.formatMessage({ id: 'ListingSearchForm.seatsInput.seatsTitle' })}
                </span>
                <div className={css.seatsWrapper}>
                  <FieldNumberInput
                    className={css.inputRootClass}
                    inputRootClass={css.inputSelectClass}
                    name="seatsMax"
                    placeholder={intl.formatMessage({
                      id: 'ListingSearchForm.seatsInput.seatsPlaceholder',
                    })}
                    initialValue={seatsValue}
                    onChange={(e) => {
                      onSeatsChange(e.currentTarget.value);
                    }}
                    id="seatsMax"
                    inline
                    maxLength={3}
                    autofocusProp={true}
                  />
                </div>
                {(!isMobile || (isMobile && isRelevantFilter)) && (
                  <ComboBoxClearButton
                    className={css.clearBtnWrapper}
                    onClick={clear}
                    onClose={onClose}
                    isRelevantFilter={isRelevantFilter}
                  />
                )}
              </div>
            );
          }}
        />
      </div>
    </ComboBoxNew>
  );
};

SeatsComboBoxNew.defaultProps = {
  className: null,
  focusedClassName: null,
  dropdownClassName: null,
};

SeatsComboBoxNew.propTypes = {
  id: string.isRequired,
  name: string.isRequired,
  isMobile: bool,
  isOpen: bool.isRequired,
  onSeatsChange: func,
  onBudgetChange: func,
  onFocus: func,
  onBlur: func,
  inputRef: oneOfType([func, shape({ node })]),
  className: string,
  focusedClassName: string,
  dropdownClassName: string,
};

export default SeatsComboBoxNew;
