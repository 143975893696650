import classNames from 'classnames';
import memoize from 'lodash/memoize';
import moment from 'moment';
import React, { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { NamedLink } from '../../components';
import config from '../../config';
import { findOptionsForSelectFilter } from '../../util/search';
import {
  b2bTxIsAccepted,
  txHasBeenDelivered,
  txIsAccepted,
  txIsCanceled,
  txIsDelivered,
  txIsReviewed,
  txIsRequested,
  txIsDeclined,
  txIsPaymentExpired,
} from '../../util/transaction';
import css from './TransactionItem.module.scss';

const categoriesOptions = findOptionsForSelectFilter('category', config.custom.filters);

const getCategory = memoize((category) =>
  categoriesOptions.find((op) => op.key === category && !op.hideFromListingInfo)
);

const TransactionItem = ({ transaction, className }) => {
  const { listing, locationName } = transaction;
  const nameToShow = locationName || listing.attributes.title;
  const category = listing.attributes.publicData.category;
  const selectedCategory = getCategory(category);
  return (
    <div className={classNames(css.root, className)}>
      <div className={css.info}>
        <div className={css.listingTitle}>{nameToShow}</div>
        <div className={css.mobileRow}>
          <div className={css.infoText}>
            <FormattedMessage
              id={selectedCategory.labelId}
              defaultMessage={selectedCategory.label}
            />
          </div>
          <div className={classNames(css.comma, css.infoText)}>{', '} </div>
          <BookingDate transaction={transaction} />
        </div>
      </div>
      <BookingState transaction={transaction} />
      <NamedLink name="OrderPage" params={{ id: transaction.id.uuid }} className={css.view}>
        <FormattedMessage id="CompanyBookingsPage.view" />
      </NamedLink>
    </div>
  );
};

const BookingDate = ({ transaction }) => {
  const { booking = {} } = transaction;
  const { displayStart, start } = booking.attributes || {};
  const startDate = moment(displayStart || start).format('MMM DD');
  return <div className={css.infoText}>{startDate}</div>;
};

const BookingState = ({ transaction }) => {
  const bookingState = useMemo(() => {
    if (b2bTxIsAccepted(transaction)) {
      return <FormattedMessage id="BookingState.accepted" />;
    }
    if (txIsRequested(transaction)) {
      return <FormattedMessage id="BookingState.requested" />;
    }
    if (txIsDeclined(transaction)) return <FormattedMessage id="BookingState.declined" />;
    if (txIsPaymentExpired(transaction)) return <FormattedMessage id="BookingState.expired" />;
    if (txIsCanceled(transaction)) return <FormattedMessage id="BookingState.cancelled" />;
    if (txHasBeenDelivered(transaction) || txIsDelivered(transaction) || txIsReviewed(transaction))
      return <FormattedMessage id="BookingState.delivered" />;
    return null;
  }, [transaction]);

  return <div className={css.bookingState}>{bookingState}</div>;
};

export default TransactionItem;
