import React from 'react';
import { FormattedMessage } from 'react-intl';

import { NamedLink } from '../../../components';

import ListingPageSection from '../ListingPageSection/ListingPageSection';

import css from '../Common.module.scss';

const SectionFAQ = ({ lastSection }) => (
  <ListingPageSection title={'ListingPage.faq.title'} lastSection={lastSection}>
    <FormattedMessage
      id={'ListingPage.faq.text'}
      values={{
        faq: (link) => (
          <NamedLink className={css.link} name="FAQPage">
            {link}
          </NamedLink>
        ),
        email: (link) => (
          <a className={css.link} target="_blank" rel="noopener noreferrer" href={`mailto:${link}`}>
            {link}
          </a>
        ),
      }}
    >
      {(id) => <p>{id}</p>}
    </FormattedMessage>
  </ListingPageSection>
);

export default SectionFAQ;
