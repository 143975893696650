import React, { memo, useCallback, useMemo, useState } from 'react';
import classNames from 'classnames';
import { bool, func, shape, string } from 'prop-types';
import { useIntl } from 'react-intl';

import { ButtonNew } from '../../components_new';
import { BUTTON_SIZES, BUTTON_TYPES } from '../../components_new/ButtonNew/ButtonNew';

import css from './InquiryForm.module.scss';

const InquiryForm = memo(
  ({
    onFormSubmit,
    isFixed,
    currentUserInfoForEmail,
    isPressed,
    setPressed,
    showCloseBtn = false,
  }) => {
    const intl = useIntl();
    const [formLoading, setFormLoading] = useState(false);
    const [formSubmitSuccess, setFormSubmitSuccess] = useState(false);
    const [values, setValues] = useState({
      fullName: currentUserInfoForEmail?.fullName || '',
      email: currentUserInfoForEmail?.email || '',
      message: '',
    });

    const [errors, setErrors] = useState({
      fullName: false,
      email: false,
      message: false,
    });

    const handleChange = ({ target: { name, value } }) => {
      setValues({ ...values, [name]: value });
      setErrors({ ...errors, [name]: false });
    };

    const handleSubmit = useCallback(
      async (e) => {
        setFormLoading(true);
        e.preventDefault();

        const localErrors = Object.keys(values).reduce((acc, curr) => {
          acc[curr] = !values[curr];
          return acc;
        }, {});

        setErrors(localErrors);
        const showErrors = Object.keys(localErrors).some((key) => !!localErrors[key]);

        if (!showErrors) {
          await onFormSubmit(values);
          setFormSubmitSuccess(true);
        }

        setFormLoading(false);
      },
      [values, errors]
    );

    const textContent = useMemo(
      () =>
        isFixed
          ? {
              title: 'InquiryForm.fixedQuestionTitle',
              caption: 'InquiryForm.fixedQuestionDescription',
              btnText: 'InquiryForm.fixedQuestionBtnLabel',
            }
          : {
              title: 'InquiryForm.questionTitle',
              caption: 'InquiryForm.questionDescription',
              btnText: 'InquiryForm.questionBtnLabel',
            },
      [isFixed]
    );

    return formSubmitSuccess ? (
      <div className={css.wrapper}>
        <h5 className={css.legend}>{intl.formatMessage({ id: 'InquiryForm.successTitle' })}</h5>
        <p className={css.caption}>{intl.formatMessage({ id: 'InquiryForm.successText' })}</p>
      </div>
    ) : isPressed ? (
      <form className={css.wrapper} action="POST" onSubmit={handleSubmit}>
        {showCloseBtn && (
          <button className={css.closeIcon} onClick={() => setPressed(false)}>
            <svg
              width="15"
              height="15"
              viewBox="0 0 17 17"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M1 1L16 16" stroke="black" />
              <path d="M16 1L1 16" stroke="black" />
            </svg>
          </button>
        )}
        <legend className={css.legend}>
          {intl.formatMessage({ id: 'InquiryForm.formTitle' })}
        </legend>
        <p className={css.caption}>{intl.formatMessage({ id: 'InquiryForm.formDescription' })}</p>
        <fieldset className={css.fieldset}>
          <input
            className={classNames(css.input, !!errors.fullName && css.invalid)}
            type="text"
            name="fullName"
            id="fullName"
            value={values.fullName}
            onInput={handleChange}
            placeholder={intl.formatMessage({ id: 'InquiryForm.fullNamePlaceholder' })}
          />
          {!!errors.fullName && (
            <p className={css.errorMessage}>
              {intl.formatMessage({ id: 'InquiryForm.fullNameError' })}
            </p>
          )}
        </fieldset>
        <fieldset className={css.fieldset}>
          <input
            className={classNames(css.input, !!errors.email && css.invalid)}
            type="text"
            name="email"
            id="email"
            value={values.email}
            onInput={handleChange}
            placeholder={intl.formatMessage({ id: 'InquiryForm.emailPlaceholder' })}
          />
          {!!errors.email && (
            <p className={css.errorMessage}>
              {intl.formatMessage({ id: 'InquiryForm.emailError' })}
            </p>
          )}
        </fieldset>
        <fieldset className={css.fieldset}>
          <textarea
            className={classNames(css.input, !!errors.message && css.invalid)}
            name="message"
            id="message"
            value={values.message}
            onInput={handleChange}
            placeholder={intl.formatMessage({ id: 'InquiryForm.messagePlaceholder' })}
          />
          {!!errors.message && (
            <p className={css.errorMessage}>
              {intl.formatMessage({ id: 'InquiryForm.messageError' })}
            </p>
          )}
        </fieldset>
        <fieldset className={classNames(css.fieldset, css.submitBtnWrapper)}>
          <ButtonNew
            nativeType="submit"
            type={BUTTON_TYPES.GREEN}
            size={BUTTON_SIZES.LARGE}
            inProgress={formLoading}
            block
          >
            {intl.formatMessage({ id: 'InquiryForm.submitBtnLabel' })}
          </ButtonNew>
        </fieldset>
      </form>
    ) : (
      <div className={css.wrapper}>
        <h5 className={css.legend}>{intl.formatMessage({ id: textContent.title })}</h5>
        <p className={css.caption}>{intl.formatMessage({ id: textContent.caption })}</p>
        <ButtonNew
          onClick={() => setPressed(true)}
          type={BUTTON_TYPES.SECONDARY}
          size={BUTTON_SIZES.LARGE}
          block
        >
          {intl.formatMessage({ id: textContent.btnText })}
        </ButtonNew>
      </div>
    );
  }
);

InquiryForm.propTypes = {
  onFormSubmit: func.isRequired,
  isFixed: bool,
  isPressed: bool,
  setPressed: func,
  showCloseBtn: bool,
  currentUserInfoForEmail: shape({
    fullName: string,
    companyName: string,
    email: string,
    phone: string,
  }),
};

export default InquiryForm;
