import chunk from 'lodash/chunk';
import { daysBetween, defaultTimeZone, formatDateWithOption, timeToValue } from '../../util/dates';
import {
  AVAILABILITY_PLAN_DAY,
  DAILY_BOOKING,
  DAYS_OF_WEEK,
  HOURLY_BOOKING,
  MONTHLY_BOOKING,
} from '../../util/types';
import isEmpty from 'lodash/isEmpty';
import difference from 'lodash/difference';

export const deleteExceptions = (exceptions = [], onDeleteAvailabilityException) => {
  const pages = chunk(exceptions, 4);

  const deleteFunc = async () => {
    while (pages.length > 0) {
      const currentPage = pages.pop();
      try {
        return await Promise.all(
          currentPage.map((exp) => onDeleteAvailabilityException({ id: exp.id }))
        ).then(() => deleteFunc());
      } catch (error) {
        return Promise.resolve();
      }
    }
  };

  return deleteFunc();
};

export const sortEntries =
  (defaultCompareReturn = 0) =>
  (a, b) => {
    if (a.startTime && b.startTime) {
      const aStart = timeToValue(a.startTime);
      const bStart = timeToValue(b.startTime);
      return aStart - bStart;
    }
    return defaultCompareReturn;
  };

// Create initial entry mapping for form's initial values
export const createEntryDayGroups = (entries, type) =>
  !isEmpty(entries)
    ? entries.reduce((groupedEntries, entry) => {
        const { startTime, endTime: endHour, dayOfWeek, seats } = entry;
        const dayGroup = groupedEntries[dayOfWeek] || [];
        if (type === AVAILABILITY_PLAN_DAY) {
          return {
            ...groupedEntries,
            [dayOfWeek]: [
              ...dayGroup,
              {
                seats,
              },
            ],
          };
        }
        return {
          ...groupedEntries,
          [dayOfWeek]: [
            ...dayGroup,
            {
              startTime,
              endTime: endHour === '00:00' ? '24:00' : endHour,
              seats,
            },
          ],
        };
      }, {})
    : {};

// Create initial values
export const createInitialValues = (availabilityPlan) => {
  const { timezone, entries, type } = availabilityPlan || {};
  const tz = timezone || defaultTimeZone();
  const entryDayGroups = createEntryDayGroups(entries, type);
  const emptyDay = difference(DAYS_OF_WEEK, [...Object.keys(entryDayGroups)]);
  const emptyDayGroups = emptyDay.reduce(
    (groups, day) => ({ ...groups, [day]: [{ startTime: null, endTime: null }] }),
    []
  );
  return {
    timezone: tz,
    ...entryDayGroups,
    ...emptyDayGroups,
    weekday: [...Object.keys(entryDayGroups)],
  };
};

// Create entries from submit values
export const createEntriesFromSubmitValues = (values, valuesSeats, numberOfInstance, isPrivate) => {
  const { weekday = [] } = values;
  const sortedValues = weekday.reduce(
    (submitValues, day) => {
      return submitValues[day]
        ? {
            ...submitValues,
            [day]: submitValues[day].sort(sortEntries()),
          }
        : submitValues;
    },
    { ...values }
  );

  return weekday?.reduce((allEntries, dayOfWeek) => {
    const { bookingType } = sortedValues;
    const dayValues = sortedValues[dayOfWeek] || [];
    const dayEntries = dayValues.map((dayValue) => {
      const { startTime, endTime } = dayValue;
      const seatsMaybe = isPrivate ? numberOfInstance * valuesSeats : valuesSeats;
      return bookingType === HOURLY_BOOKING && startTime && endTime
        ? {
            dayOfWeek,
            seats: seatsMaybe,
            startTime,
            endTime: endTime === '24:00' ? '00:00' : endTime,
          }
        : (bookingType === DAILY_BOOKING || bookingType === MONTHLY_BOOKING) &&
          weekday.includes(dayOfWeek)
        ? { dayOfWeek, seats: seatsMaybe }
        : null;
    });

    return allEntries.concat(dayEntries.filter((e) => !!e));
  }, []);
};

export const createOpenAndCloseTime = (values) => {
  const arrayDayOfWeeks = values?.weekday?.reduce((allEntries, dayOfWeek) => {
    const dayValues = values[dayOfWeek] || [];
    let dayEntries = dayValues.map((dayValue) => {
      const { startTime, endTime } = dayValue;
      if (startTime && endTime) {
        return {
          dayOfWeek,
          openTime: startTime,
          closeTime: endTime === '24:00' ? '00:00' : endTime,
        };
      } else {
        return null;
      }
    });

    if (dayEntries.length > 1) {
      //if several entries for a day - find first opening and last closing time
      let dayEntry = [];
      dayEntry[0] = dayEntries[0];
      dayEntries.forEach((entry) => {
        const dayEntryOpenInt = parseInt(dayEntry[0].openTime.substr(0, 2));
        const EntryOpenInt = parseInt(entry.openTime.substr(0, 2));
        if (EntryOpenInt < dayEntryOpenInt) {
          dayEntry[0].openTime = entry.openTime;
        }
        const dayEntryCloseInt = parseInt(dayEntry[0].closeTime.substr(0, 2));
        const EntryCloseInt = parseInt(entry.closeTime.substr(0, 2));
        if (dayEntryCloseInt < EntryCloseInt) {
          dayEntry[0].closeTime = entry.closeTime;
        }
      });
      dayEntries = dayEntry;
    }

    return allEntries.concat(dayEntries.filter((e) => !!e));
  }, []);

  return arrayDayOfWeeks.reduce((prev, cur) => {
    return {
      ...prev,
      [cur.dayOfWeek]: {
        openTime: cur.openTime,
        closeTime: cur.closeTime,
      },
    };
  }, {});
};

// Ensure that the AvailabilityExceptions are in sensible order.
//
// Note: if you allow fetching more than 100 exception,
// pagination kicks in and that makes client-side sorting impossible.
export const sortExceptionsByStartTime = (a, b) => {
  return a.attributes.start.getTime() - b.attributes.start.getTime();
};

export const createExceptionInitialvalues = (availabilityExceptions) => {
  return availabilityExceptions.reduce((initialValues, exc) => {
    const isWholeDay = daysBetween(exc.attributes.start, exc.attributes.end);
    return isWholeDay
      ? [...initialValues]
      : [
          ...initialValues,
          {
            startTime: formatDateWithOption(exc.attributes.start, 'HH:mm'),
            endTime: formatDateWithOption(exc.attributes.end, 'HH:mm'),
          },
        ];
  }, []);
};
