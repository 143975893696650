import React from 'react';

const PlusCircleIcon = () => {
  return (
    <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13 0.5C10.5277 0.5 8.11099 1.23311 6.05538 2.60663C3.99976 3.98015 2.39761 5.93238 1.45151 8.21646C0.505416 10.5005 0.257874 13.0139 0.74019 15.4386C1.22251 17.8634 2.41301 20.0907 4.16117 21.8388C5.90933 23.587 8.13661 24.7775 10.5614 25.2598C12.9861 25.7421 15.4995 25.4946 17.7836 24.5485C20.0676 23.6024 22.0199 22.0002 23.3934 19.9446C24.7669 17.889 25.5 15.4723 25.5 13C25.4963 9.68594 24.1781 6.50869 21.8347 4.16529C19.4913 1.8219 16.3141 0.503739 13 0.5ZM13 24.5C10.7255 24.5 8.50211 23.8255 6.61095 22.5619C4.71978 21.2983 3.2458 19.5022 2.37539 17.4009C1.50498 15.2995 1.27724 12.9872 1.72097 10.7565C2.16471 8.52568 3.25997 6.47658 4.86828 4.86827C6.47658 3.25997 8.52569 2.1647 10.7565 1.72097C12.9873 1.27724 15.2995 1.50498 17.4009 2.37539C19.5022 3.24579 21.2983 4.71978 22.5619 6.61094C23.8255 8.50211 24.5 10.7255 24.5 13C24.4966 16.0489 23.2839 18.972 21.1279 21.1279C18.972 23.2839 16.0489 24.4966 13 24.5ZM18.5 13C18.5 13.1326 18.4473 13.2598 18.3536 13.3536C18.2598 13.4473 18.1326 13.5 18 13.5H13.5V18C13.5 18.1326 13.4473 18.2598 13.3536 18.3536C13.2598 18.4473 13.1326 18.5 13 18.5C12.8674 18.5 12.7402 18.4473 12.6465 18.3536C12.5527 18.2598 12.5 18.1326 12.5 18V13.5H8.00001C7.8674 13.5 7.74022 13.4473 7.64645 13.3536C7.55268 13.2598 7.50001 13.1326 7.50001 13C7.50001 12.8674 7.55268 12.7402 7.64645 12.6464C7.74022 12.5527 7.8674 12.5 8.00001 12.5H12.5V8C12.5 7.86739 12.5527 7.74021 12.6465 7.64645C12.7402 7.55268 12.8674 7.5 13 7.5C13.1326 7.5 13.2598 7.55268 13.3536 7.64645C13.4473 7.74021 13.5 7.86739 13.5 8V12.5H18C18.1326 12.5 18.2598 12.5527 18.3536 12.6464C18.4473 12.7402 18.5 12.8674 18.5 13Z"
        fill="#D8D8D8"
      />
    </svg>
  );
};

export default PlusCircleIcon;
