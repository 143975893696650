import classNames from 'classnames';
import React, { useState } from 'react';
import { ButtonNew } from '../../components_new';
import { FormattedMessage } from '../../util/reactIntl';
import { ERROR_CODE_TRANSITION_TOO_OLD } from '../../util/types';
import css from './TransactionPanel.module.scss';

// Functional component as a helper to build ActionButtons for
// provider when state is preauthorized
const BTN_CANCEL_ID = 'cancel';

const SaleActionButtonsMaybe = (props) => {
  const {
    className,
    rootClassName,
    showButtons,
    onCancel,
    transitInProgress,
    transitError,
    acceptInProgress,
    declineInProgress,
    onDeclineSale,
    onAcceptSale,
    showCancelButton,
  } = props;
  const [selectedBtn, setSelectedBtn] = useState(null);
  if (!showButtons) return null;

  const onClickCancel = () => {
    setSelectedBtn(BTN_CANCEL_ID);
    onCancel();
  };

  const buttonsDisabled = transitInProgress || acceptInProgress || declineInProgress;

  const errorMessage = transitError ? (
    <p className={css.actionError}>
      {transitError.name && transitError.name === ERROR_CODE_TRANSITION_TOO_OLD ? (
        <FormattedMessage id="TransactionPanel.transitError.transactionTooOld" />
      ) : (
        <FormattedMessage id="TransactionPanel.transitError" />
      )}
    </p>
  ) : null;

  const classes = classNames(rootClassName || css.actionButtons, className);

  return (
    <div className={classes}>
      <div className={css.actionErrors}>{errorMessage}</div>
      <div className={css.actionButtonWrapper}>
        {showCancelButton ? (
          <>
            <div className={css.cancelBookingInfo}>
              <FormattedMessage id="TransactionPanel.cancelInfo" />
            </div>
            <ButtonNew
              className={css.button}
              inProgress={transitInProgress && selectedBtn === BTN_CANCEL_ID}
              disabled={buttonsDisabled}
              onClick={onClickCancel}
              type="secondary"
            >
              <span className={css.buttonCancelText}>
                <FormattedMessage id="TransactionPanel.cancelButton" />
              </span>
            </ButtonNew>
          </>
        ) : (
          <div className={css.buttonStyle}>
            <ButtonNew
              className={css.button}
              inProgress={declineInProgress}
              disabled={buttonsDisabled}
              onClick={onDeclineSale}
              type="secondary"
            >
              <FormattedMessage id="TransactionPanel.declineButton" />
            </ButtonNew>

            <ButtonNew
              className={css.button}
              inProgress={acceptInProgress}
              disabled={buttonsDisabled}
              onClick={onAcceptSale}
              type="green"
            >
              <FormattedMessage id="TransactionPanel.acceptButton" />
            </ButtonNew>
          </div>
        )}
      </div>
    </div>
  );
};

export default SaleActionButtonsMaybe;
