import React, { Component } from 'react';
import { array, bool, func, object, string } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm, FormSpy } from 'react-final-form';
import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import classNames from 'classnames';
import { timestampToDate, calculateQuantityFromHours } from '../../util/dates';
import { HOURLY_BOOKING, propTypes } from '../../util/types';
import config from '../../config';
import { IconSpinner, Form, FieldNumberInput } from '../../components';
import EstimatedBreakdownMaybe from './EstimatedBreakdownMaybe';
import FieldDateAndTimeInput from './FieldDateAndTimeInput';
import { BUTTON_TYPES, BUTTON_SIZES } from '../../components_new/ButtonNew/ButtonNew';
import { ButtonNew } from '../../components_new';
import { parse } from '../../util/urlHelpers';

import css from './BookingTimeForm.module.scss';
import { required } from '../../util/validators';

export class BookingTimeFormComponent extends Component {
  constructor(props) {
    super(props);

    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    this.handleOnChange = this.handleOnChange.bind(this);

    this.state = {
      newListing: true,
      initialData: typeof window !== 'undefined' ? parse(window.location.search) : {},
    };
  }

  handleFormSubmit(e) {
    this.props.onSubmit(e);
  }

  componentDidUpdate(prevProps) {
    // if listingId changes - set newListing to true
    // Used to update the seats input to maxSeats from listing
    const { listingId } = this.props;
    const { listingId: prevListingId } = prevProps;
    if (listingId !== prevListingId) {
      this.setState({ newListing: true });
    }
  }

  // When the values of the form are updated we need to fetch
  // lineItems from FTW backend for the EstimatedTransactionMaybe
  // In case you add more fields to the form, make sure you add
  // the values here to the bookingData object.
  handleOnChange(form, formValues) {
    const { bookingStartTime, bookingEndTime, seats, additionalServices = [] } = formValues.values;
    const startDate = bookingStartTime ? timestampToDate(bookingStartTime) : null;
    const endDate = bookingEndTime ? timestampToDate(bookingEndTime) : null;

    const listingId = this.props.listingId;
    const isOwnListing = this.props.isOwnListing;
    const { isEmployeeUser, isCompanyUser } = this.props;
    const isB2B = isEmployeeUser || isCompanyUser;

    if (
      bookingStartTime &&
      startDate &&
      bookingEndTime &&
      endDate &&
      !this.props.fetchLineItemsInProgress &&
      formValues.valid
    ) {
      //only fetch new line items if the input is valid.
      //The start time must be before the end time &
      // the end time must be whole of half hours just as start date
      if (startDate < endDate && startDate.getMinutes() === endDate.getMinutes()) {
        this.props.onFetchTransactionLineItems({
          bookingData: {
            startDate,
            endDate,
            seats: parseInt(seats),
            bookingType: 'hourly',
            additionalServices,
          },
          listingId,
          isOwnListing,
          isB2B,
        });
      } else {
        // if not meeting the conditions above - set end time to one hour after start time
        const newEndTime = new Date(startDate).setHours(startDate.getHours() + 1);
        form.batch(() => {
          form.change('bookingEndDate', { date: new Date(newEndTime) });
          form.change('bookingEndTime', newEndTime);
        });
      }
    }
  }

  render() {
    const { rootClassName, className, price: unitPrice, bookingData, ...rest } = this.props;
    const classes = classNames(rootClassName || css.root, className);

    if (!unitPrice) {
      return (
        <div className={classes}>
          <p className={css.error}>
            <FormattedMessage id="BookingTimeForm.listingPriceMissing" />
          </p>
        </div>
      );
    }
    if (unitPrice.currency !== config.currency) {
      return (
        <div className={classes}>
          <p className={css.error}>
            <FormattedMessage id="BookingTimeForm.listingCurrencyInvalid" />
          </p>
        </div>
      );
    }

    return (
      <FinalForm
        {...rest}
        unitPrice={unitPrice}
        onSubmit={this.handleFormSubmit}
        initialValues={bookingData}
        render={(fieldRenderProps) => {
          const {
            endDatePlaceholder,
            startDatePlaceholder,
            form,
            pristine,
            handleSubmit,
            intl,
            isOwnListing,
            listingId,
            submitButtonWrapperClassName,
            unitType,
            values,
            monthlyTimeSlots,
            onFetchTimeSlots,
            timeZone,
            lineItems,
            fetchLineItemsInProgress,
            fetchLineItemsError,
            additionalServices,
            listing,
            defaultSearch,
            additionalServicesSection,
            invalid,
            paddingTime,
            isEmployeeUser,
            isCompanyUser,
            isEnoughCreditsForBooking,
            canBookWithCredits,
            listingCredits,
            isRequestBooking,
          } = fieldRenderProps;

          const initialData = this.state.initialData;

          // Get seats from URL
          if (
            pristine &&
            !values.seats &&
            initialData &&
            initialData.minSeats &&
            initialData.minSeats !== 'NaN'
          ) {
            form.batch(() => {
              form.change('seats', initialData.minSeats);
            });
            this.setState({ newListing: false });
            // if no search input for seats - use max seats from listing when selecting new listing
          } else if (this.state.newListing === true) {
            this.setState({ newListing: false });
            form.batch(() => {
              form.change('seats', listing.attributes.publicData.maxSeats);
            });
          }

          const selectedSeats = values && values.seats && values.seats > 0;

          const startTime = values && values.bookingStartTime ? values.bookingStartTime : null;
          const endTime = values && values.bookingEndTime ? values.bookingEndTime : null;

          const bookingStartLabel = intl.formatMessage({
            id: 'BookingTimeForm.bookingStartTitle',
          });
          const bookingEndLabel = intl.formatMessage({
            id: 'BookingTimeForm.bookingEndTitle',
          });

          const startDate = startTime ? timestampToDate(startTime) : null;
          const endDate = endTime ? timestampToDate(endTime) : null;
          const totalHours =
            startDate && endDate ? calculateQuantityFromHours(startDate, endDate) : 1;

          const totalCreditsValue = listingCredits && listingCredits * totalHours;

          // This is the place to collect breakdown estimation data. See the
          // EstimatedBreakdownMaybe component to change the calculations
          // for customized payment processes.
          const bookingData =
            startDate && endDate
              ? {
                  unitType,
                  startDate,
                  endDate,
                  timeZone,
                }
              : null;

          const showEstimatedBreakdown =
            bookingData && lineItems && !fetchLineItemsInProgress && !fetchLineItemsError;

          const bookingInfoMaybe = showEstimatedBreakdown ? (
            <div className={css.priceBreakdownContainer}>
              <EstimatedBreakdownMaybe
                bookingData={bookingData}
                lineItems={lineItems}
                bookingType={HOURLY_BOOKING}
                listing={listing}
                hasAdditionalServices={
                  values.additionalServices && values.additionalServices.length > 0
                }
                isCreditTx={isEmployeeUser || isCompanyUser}
              />
            </div>
          ) : null;

          const loadingSpinnerMaybe = fetchLineItemsInProgress ? (
            <IconSpinner className={css.spinner} />
          ) : null;

          const bookingInfoErrorMaybe = fetchLineItemsError ? (
            fetchLineItemsError === 'insufficient credit' ? (
              <span className={css.sideBarError}>
                <FormattedMessage id="BookingDatesForm.fetchLineItemsInsufficientCreditsError" />
              </span>
            ) : (
              <span className={css.sideBarError}>
                <FormattedMessage id="BookingDatesForm.fetchLineItemsError" />
              </span>
            )
          ) : null;

          const submitButtonClasses = classNames(
            submitButtonWrapperClassName || css.submitButtonWrapper
          );

          const startDateInputProps = {
            label: bookingStartLabel,
            placeholderText: startDatePlaceholder,
          };
          const endDateInputProps = {
            label: bookingEndLabel,
            placeholderText: endDatePlaceholder,
          };

          const dateInputProps = {
            startDateInputProps,
            endDateInputProps,
          };

          const seatsInputLabel = intl.formatMessage({
            id: 'BookingTimeForm.seats.label',
          });

          const seatsInputRequiredMessage = intl.formatMessage({
            id: 'BookingTimeForm.seats.requiredMessage',
          });

          const seatsInputValidator = required(seatsInputRequiredMessage);

          const handleSeatsChange = () => {
            form.batch(() => {
              form.change('bookingStartDate', null);
              form.change('bookingEndDate', null);
              form.change('bookingStartTime', null);
              form.change('bookingEndTime', null);
            });
          };

          const submitButtonDisabled =
            invalid ||
            ((isEmployeeUser || isCompanyUser) && !!fetchLineItemsError) ||
            !canBookWithCredits ||
            ((isEmployeeUser || isCompanyUser) && !isEnoughCreditsForBooking(totalHours));
          return (
            <Form onSubmit={handleSubmit} className={classes}>
              <FormSpy
                subscription={{ values: true, valid: true }}
                onChange={(values) => {
                  this.handleOnChange(form, values);
                }}
              />

              <div className={css.borderFieldsWrapper}>
                <FieldNumberInput
                  name="seats"
                  id="seats"
                  max={listing.attributes.publicData.maxSeats}
                  min={1}
                  autoComplete="off"
                  label={seatsInputLabel}
                  validate={seatsInputValidator}
                  onChange={handleSeatsChange}
                  className={css.seatsNumberField}
                  inputRootClass={css.seatsNumberInput}
                />

                {monthlyTimeSlots && timeZone && selectedSeats ? (
                  <FieldDateAndTimeInput
                    {...dateInputProps}
                    className={css.bookingDates}
                    listingId={listingId}
                    onFetchTimeSlots={onFetchTimeSlots}
                    monthlyTimeSlots={monthlyTimeSlots}
                    values={values}
                    intl={intl}
                    form={form}
                    pristine={pristine}
                    timeZone={timeZone}
                    seats={values.seats || 1}
                    paddingTime={paddingTime}
                    customStartDateClass={css.startDateField}
                    customFieldDateInputClass={css.startDateFieldInput}
                    customFormRowClass={css.formRow}
                    customTimeFormRow={css.timeFormRow}
                    customSelectTimeFieldWrapperClass={css.selectFieldWrapper}
                    customSelectTimeFieldClass={css.selectField}
                    customSelectTimeFieldInputClass={css.selectFieldInput}
                  />
                ) : null}
              </div>
              {(isEmployeeUser || isCompanyUser) && (
                <div className={css.listingCreditInforWrapper}>
                  <div className={css.row}>
                    <div>
                      <FormattedMessage id="BookingTimeForm.valuePerHour" />
                    </div>
                    <div>
                      <strong>
                        <FormattedMessage
                          id="BookingDatesForm.creditsValue"
                          values={{ credits: totalCreditsValue }}
                        />
                      </strong>
                    </div>
                  </div>
                </div>
              )}
              {selectedSeats && additionalServices && additionalServices.length > 0
                ? additionalServicesSection()
                : null}

              {loadingSpinnerMaybe}
              {bookingInfoErrorMaybe}

              <div className={css.openBookingFormWrapper}>
                <p className={`${css.smallPrint}, ${css.smallPrintMobile}`}>
                  <FormattedMessage
                    id={
                      isOwnListing
                        ? 'BookingDatesForm.ownListing'
                        : isRequestBooking
                        ? 'BookingDatesForm.youWontBeChargedInfoRequest'
                        : 'BookingDatesForm.youWontBeChargedInfo'
                    }
                  />
                </p>

                <div className={css.openBookingForm}>
                  <div className={css.backButton} onClick={this.props.onBackPress}>
                    <svg
                      width="16"
                      height="13"
                      viewBox="0 0 16 13"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M16 6.5L0.999999 6.5M0.999999 6.5L6.45454 12M0.999999 6.5L6.45455 1"
                        stroke="#222222"
                      />
                    </svg>{' '}
                    <FormattedMessage id="BookingPanel.back" />
                  </div>
                  <div className={submitButtonClasses}>
                    <ButtonNew
                      disabled={submitButtonDisabled}
                      type={BUTTON_TYPES.GREEN}
                      size={BUTTON_SIZES.LARGE}
                      block
                    >
                      <FormattedMessage
                        id={
                          isRequestBooking
                            ? 'BookingDatesForm.sendRequestMobile'
                            : 'BookingDatesForm.proceed'
                        }
                      />
                    </ButtonNew>
                  </div>
                </div>
              </div>
              <div className={css.desktopButton}>
                <ButtonNew
                  disabled={submitButtonDisabled}
                  type={BUTTON_TYPES.GREEN}
                  size={BUTTON_SIZES.LARGE}
                  block
                >
                  <FormattedMessage
                    id={
                      isRequestBooking ? 'BookingDatesForm.sendRequest' : 'BookingDatesForm.proceed'
                    }
                  />
                </ButtonNew>{' '}
                <p className={css.smallPrint}>
                  <FormattedMessage
                    id={
                      isOwnListing
                        ? 'BookingDatesForm.ownListing'
                        : isRequestBooking
                        ? 'BookingDatesForm.youWontBeChargedInfoRequest'
                        : 'BookingDatesForm.youWontBeChargedInfo'
                    }
                  />
                </p>
              </div>
              {bookingInfoMaybe}
            </Form>
          );
        }}
      />
    );
  }
}

BookingTimeFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  submitButtonWrapperClassName: null,
  isOwnListing: false,
  listingId: null,
  startDatePlaceholder: null,
  endDatePlaceholder: null,
  monthlyTimeSlots: null,
  lineItems: null,
  fetchLineItemsError: null,
  defaultSearch: null,
  bookingData: null,
};

BookingTimeFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  submitButtonWrapperClassName: string,

  unitType: propTypes.bookingUnitType.isRequired,
  isOwnListing: bool,
  listingId: propTypes.uuid,
  monthlyTimeSlots: object,
  onFetchTimeSlots: func.isRequired,

  onFetchTransactionLineItems: func.isRequired,
  lineItems: array,
  fetchLineItemsInProgress: bool.isRequired,
  fetchLineItemsError: propTypes.error,
  defaultSearch: object,
  additionalServicesSection: func.isRequired,
  bookingData: object,

  // from injectIntl
  intl: intlShape.isRequired,

  // for tests
  startDatePlaceholder: string,
  endDatePlaceholder: string,
};

const BookingTimeForm = compose(injectIntl)(BookingTimeFormComponent);
BookingTimeForm.displayName = 'BookingTimeForm';

export default BookingTimeForm;
