import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import css from './AddImages.module.scss';

const EditImageButton = (props) => {
  const { className, rootClassName, onClick } = props;
  const classes = classNames(rootClassName || css.editImage, className);
  return (
    <button className={classes} onClick={onClick} type="button">
      <svg
        width="12"
        height="12"
        viewBox="0 0 12 12"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M9.96397 0C9.697 0.000646449 9.43281 0.0541469 9.18663 0.157415C8.94045 0.260683 8.71715 0.411674 8.52962 0.601678L0.72458 8.40622L0.693978 8.56071L0.153998 11.2754L0 12L0.725074 11.846L3.43978 11.306L3.59378 11.2749L11.3988 3.47039C11.5893 3.28318 11.7406 3.05991 11.8439 2.8136C11.9472 2.56729 12.0004 2.30287 12.0004 2.03578C12.0004 1.7687 11.9472 1.50428 11.8439 1.25797C11.7406 1.01166 11.5893 0.788389 11.3988 0.601184C11.2112 0.411211 10.9878 0.260276 10.7415 0.157092C10.4952 0.0539078 10.231 0.000516767 9.96397 0ZM9.96397 0.94077C10.2127 0.94077 10.4635 1.05429 10.7043 1.29566C11.1846 1.77542 11.1846 2.29615 10.7043 2.77641L10.35 3.1155L8.88401 1.65005L9.22359 1.29566C9.46496 1.05429 9.7152 0.94077 9.96397 0.94077ZM8.19052 2.34452L9.65548 3.80997L3.68657 9.77838C3.36434 9.14791 2.85132 8.63524 2.22063 8.31343L8.19052 2.34452ZM1.58885 9.11599C1.88097 9.23352 2.14632 9.40887 2.36897 9.63152C2.59162 9.85417 2.76698 10.1195 2.8845 10.4116L1.26456 10.7354L1.58885 9.11599Z"
          fill="black"
        />
      </svg>
    </button>
  );
};

EditImageButton.defaultProps = { className: null, rootClassName: null };

const { func, string } = PropTypes;

EditImageButton.propTypes = {
  className: string,
  rootClassName: string,
  onClick: func.isRequired,
};

export default EditImageButton;
