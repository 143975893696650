import React, { useEffect } from 'react';
import classNames from 'classnames';
import { Form as FinalForm } from 'react-final-form';

import { Button, FieldDateInput, Form } from '../../components';
import { BUTTON_TYPES } from '../../components_new/ButtonNew/ButtonNew';
import { CouponCodeInput } from '../../components_new';
import { FormattedMessage } from '../../util/reactIntl';
import { formatMoneyWithSpace } from '../../util/currency';
import PackageModel from '../../models/PackageModel';

import CouponModel from '../../models/CouponModel';

import css from './SubscriptionStartDateForm.module.scss';

const identity = (v) => v;

export const formatQuotePrice = (price) =>
  price === 0 ? 0 : formatMoneyWithSpace(price % 1 === 0 ? price : price.toFixed(2));

const SubscriptionStartDateForm = ({
  onSubmit,
  openQuotePageInforModal,
  intl,
  onUpdateStartDate,
  onUpdateCouponCode,
  proceedLoading,
  retrievedCoupon,
  applyCoupon,
  showCouponError: showError,
  showCouponSuccess: showSuccess,
  selectedPackage,
  initialValues: {
    couponCode,
    couponDiscount,
    originalPrice,
    priceWithDiscount,
    processStartDate,
    totalCredits,
  },
}) => {
  const { id: retrievedCouponId } = retrievedCoupon || {};
  const couponCodeInputProps = {
    id: 'couponCode',
    name: 'couponCode',
    value: couponCode,
    onInput: onUpdateCouponCode,
    buttonType: BUTTON_TYPES.SECONDARY,
    discountDuration: {
      ...(!!retrievedCoupon && {
        period: retrievedCoupon.period,
        period_unit: retrievedCoupon.period_unit,
      }),
    },
    coupon: {
      id: retrievedCoupon?.id,
    },
    showError,
    showSuccess,
    applyCoupon,
    proceedLoading,
  };

  const packageData = PackageModel.currentPackage({ selectedPackage });

  const bookingsPerMonth = Math.floor(totalCredits / 6);

  return (
    <FinalForm
      onSubmit={onSubmit}
      render={(fieldRenderProps) => {
        const { handleSubmit, invalid } = fieldRenderProps;

        const originalPriceLabel = intl.formatMessage({
          id: 'SubscriptionStartDateForm.originalPriceLabel',
        });

        const startDateFieldLabel = intl.formatMessage({
          id: 'SubscriptionStartDateForm.startDateFieldLabel',
        });

        const startDateFieldPlaceholder = intl.formatMessage({
          id: 'SubscriptionStartDateForm.startDateFieldPlaceholder',
        });

        const submitButtonText = intl.formatMessage({
          id: 'SubscriptionStartDateForm.submitButtonText',
        });

        const bookingsPerMonthText = intl.formatMessage(
          {
            id: 'SubscriptionStartDateForm.bookingsPerMonthText',
          },
          {
            bookings: bookingsPerMonth,
            bold: (text) => <strong>{text}</strong>,
          }
        );

        const bindingPeriodValue =
          packageData?.value &&
          intl.formatMessage(
            { id: 'SubscriptionStartDateForm.bindingPeriodValue' },
            { months: packageData.value }
          );

        return (
          <Form onSubmit={handleSubmit} className={css.stickyDesktop}>
            <div className={css.subscriptionStartDateForm}>
              <div className={css.topRow}>
                <span className={css.formLegend}>
                  {intl.formatMessage({ id: 'SubscriptionStartDateForm.yourSubscription' })}
                </span>
                <div className={css.editBtn} onClick={openQuotePageInforModal}>
                  <FormattedMessage id="SubscriptionStartDateForm.editDetails" />
                </div>
              </div>
              <div className={css.bookingsPerMonth}>{bookingsPerMonthText}</div>
              <div className={css.borderBottomRow}>
                <span>
                  <FormattedMessage id="SubscriptionStartDateForm.totalCredits" />
                </span>
                <span>
                  <b>{totalCredits}</b>
                </span>
              </div>
              {packageData?.value > 1 && (
                <div className={css.borderBottomRow}>
                  <FormattedMessage id="SubscriptionStartDateForm.bindingPeriod" />
                  <span>
                    <b>{bindingPeriodValue}</b>
                  </span>
                </div>
              )}

              {!!retrievedCouponId && (
                <>
                  <div className={css.borderBottomRow}>
                    <span>{originalPriceLabel}</span>
                    <span>
                      <FormattedMessage id="SubscriptionStartDateForm.currency" />{' '}
                      <b>{CouponModel.formatQuotePrice(originalPrice)}</b>
                    </span>
                  </div>
                  <div className={classNames(css.borderBottomRow, css.accentRow)}>
                    <span>
                      {intl.formatMessage({
                        id: 'SubscriptionStartDateForm.discount',
                      })}
                    </span>
                    <span>
                      <FormattedMessage id="SubscriptionStartDateForm.currency" />{' '}
                      <b>{CouponModel.formatQuotePrice(couponDiscount)}</b>
                    </span>
                  </div>
                </>
              )}
              <div className={css.priceWrapper}>
                <FormattedMessage id="SubscriptionStartDateForm.totalLabel">
                  {(id) => <span className={css.totalLabel}>{id}</span>}
                </FormattedMessage>
                {!!originalPrice && (
                  <div className={css.price}>
                    <FormattedMessage id="SubscriptionStartDateForm.currency" />{' '}
                    <b>
                      {CouponModel.formatQuotePrice(
                        !!couponDiscount ? priceWithDiscount : originalPrice
                      )}
                    </b>
                    <FormattedMessage id="SubscriptionStartDateForm.month" />
                    <div className={css.vat}>
                      <FormattedMessage id="SubscriptionStartDateForm.vat25" />
                    </div>
                  </div>
                )}
              </div>
              <div className={css.couponInputWrapper}>
                <CouponCodeInput {...couponCodeInputProps} />
              </div>
              <FieldDateInput
                id="processStartDate"
                name="processStartDate"
                label={startDateFieldLabel}
                placeholderText={startDateFieldPlaceholder}
                format={identity}
                input={{
                  value: processStartDate,
                  onChange: onUpdateStartDate,
                  onBlur: () => null,
                  onFocus: () => null,
                }}
                className={css.startDateField}
                inputClassName={css.startDateInput}
                labelClassName={css.startDateLabel}
              />
              <Button type="submit" className={css.proceedBtn} disabled={invalid}>
                {submitButtonText}
              </Button>
              <p className={css.paymentInfo}>
                <FormattedMessage id="SubscriptionStartDateForm.paymentInfo" />
              </p>
            </div>
          </Form>
        );
      }}
    />
  );
};

SubscriptionStartDateForm.defaultProps = {
  onSubmit: () => {},
};

export default SubscriptionStartDateForm;
