import React from 'react';
import { compose } from 'redux';
import { FormattedMessage, injectIntl } from '../../util/reactIntl';
import { Form as FinalForm } from 'react-final-form';
import css from './NewsletterSignupForm.module.scss';
import * as validators from '../../util/validators';
import { Form, PrimaryButton, FieldTextInput } from '../../components';
import { hubspotSubscribeUser, hubspotCreateUpdateUser } from '../../util/api';

const NewsletterSignupFormComponent = (props) => {
  const { setNewsletterSignupConfirm } = props;

  const submitForm = async (values) => {
    const { firstName, lastName, email } = values;
    const hubspotParams = { firstName: firstName.trim(), lastName: lastName.trim(), email };
    try {
      await hubspotCreateUpdateUser(hubspotParams);
      await hubspotSubscribeUser({ email: email });
      setNewsletterSignupConfirm(true);
    } catch (e) {
      console.error(e);
    }
  };
  return (
    <FinalForm
      {...props}
      onSubmit={(values) => {
        const b_name = document.getElementById('b_name');
        const b_email = document.getElementById('b_email');
        const b_comment = document.getElementById('b_comment');
        const isSpam = !!b_name.value || !!b_email.value || !!b_comment.value;

        if (!isSpam) {
          submitForm(values);
        }
      }}
      render={(formRenderProps) => {
        const { formId, handleSubmit, inProgress, invalid, intl } = formRenderProps;

        // email
        const emailLabel = intl.formatMessage({
          id: 'ConfirmSignupForm.emailLabel',
        });
        const emailPlaceholder = intl.formatMessage({
          id: 'ConfirmSignupForm.emailPlaceholder',
        });
        const emailRequiredMessage = intl.formatMessage({
          id: 'ConfirmSignupForm.emailRequired',
        });
        const emailRequired = validators.required(emailRequiredMessage);
        const emailInvalidMessage = intl.formatMessage({
          id: 'ConfirmSignupForm.emailInvalid',
        });
        const emailValid = validators.emailFormatValid(emailInvalidMessage);

        // firstName
        const firstNameLabel = intl.formatMessage({
          id: 'ConfirmSignupForm.firstNameLabel',
        });
        const firstNamePlaceholder = intl.formatMessage({
          id: 'ConfirmSignupForm.firstNamePlaceholder',
        });
        const firstNameRequiredMessage = intl.formatMessage({
          id: 'ConfirmSignupForm.firstNameRequired',
        });
        const firstNameRequired = validators.required(firstNameRequiredMessage);

        // lastName
        const lastNameLabel = intl.formatMessage({
          id: 'ConfirmSignupForm.lastNameLabel',
        });
        const lastNamePlaceholder = intl.formatMessage({
          id: 'ConfirmSignupForm.lastNamePlaceholder',
        });
        const lastNameRequiredMessage = intl.formatMessage({
          id: 'ConfirmSignupForm.lastNameRequired',
        });
        const lastNameRequired = validators.required(lastNameRequiredMessage);

        const submitInProgress = inProgress;
        const submitDisabled = invalid || submitInProgress;

        return (
          <Form className={css.subscribeForm} onSubmit={handleSubmit}>
            <div>
              <FieldTextInput
                type="email"
                id={formId ? `${formId}.email` : 'email'}
                name="email"
                autoComplete="email"
                label={emailLabel}
                placeholder={emailPlaceholder}
                validate={validators.composeValidators(emailRequired, emailValid)}
              />
              <FieldTextInput
                className={css.firstNameRoot}
                type="text"
                id={formId ? `${formId}.firstName` : 'firstName'}
                name="firstName"
                autoComplete="given-name"
                label={firstNameLabel}
                placeholder={firstNamePlaceholder}
                validate={firstNameRequired}
              />
              <FieldTextInput
                className={css.lastNameRoot}
                type="text"
                id={formId ? `${formId}.lastName` : 'lastName'}
                name="lastName"
                autoComplete="family-name"
                label={lastNameLabel}
                placeholder={lastNamePlaceholder}
                validate={lastNameRequired}
              />
            </div>
            <PrimaryButton
              className={css.button}
              type="submit"
              inProgress={submitInProgress}
              disabled={submitDisabled}
            >
              <FormattedMessage id="Footer.subscribeButton" />
            </PrimaryButton>
            <div
              className={css.honeyPot}
              aria-hidden="true"
              aria-label="Please leave the following three fields empty"
            >
              <label htmlFor="b_name">Name: </label>
              <input
                type="text"
                name="b_name"
                tabIndex="-1"
                value=""
                placeholder="Freddie"
                id="b_name"
              />

              <label htmlFor="b_email">Email: </label>
              <input
                type="email"
                name="b_email"
                tabIndex="-1"
                value=""
                placeholder="youremail@gmail.com"
                id="b_email"
              />

              <label htmlFor="b_comment">Comment: </label>
              <textarea
                name="b_comment"
                tabIndex="-1"
                placeholder="Please comment"
                id="b_comment"
              ></textarea>
            </div>
          </Form>
        );
      }}
    />
  );
};

const NewsletterSignupForm = compose(injectIntl)(NewsletterSignupFormComponent);
NewsletterSignupForm.displayName = 'NewsletterSignupForm';
export default NewsletterSignupForm;
