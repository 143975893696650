import React from 'react';
import classNames from 'classnames';
import { useInView } from 'react-intersection-observer';
import { Link } from 'react-router-dom';
import { oneOf, string, bool, object, shape, func, array, arrayOf } from 'prop-types';

import { PrimaryButton } from '../../components';
import { ButtonNew } from '..';
import { BUTTON_TYPES, BUTTON_SIZES, LINK_COLORS, MOBILE_POSITION } from '../ButtonNew/ButtonNew';
import { narrowViewportMatch, touchDeviceMatch } from '../../util/device';

import css from './MediaAndTextSection.module.scss';
import { FormattedMessage } from 'react-intl';

export const ANIMATION_TYPES = {
  RIGHT: 'fromRight',
  BOTTOM: 'fromBottom',
};

ANIMATION_TYPES.LIST = [ANIMATION_TYPES.RIGHT, ANIMATION_TYPES.BOTTOM];

export const TEXT_ALIGN = {
  LEFT: 'left',
  RIGHT: 'right',
};

TEXT_ALIGN.LIST = [TEXT_ALIGN.LEFT, TEXT_ALIGN.RIGHT];

export const TEXT_ALIGN_MOBILE = {
  BOTTOM: 'bottom',
  TOP: 'top',
};

TEXT_ALIGN_MOBILE.LIST = [TEXT_ALIGN_MOBILE.BOTTOM, TEXT_ALIGN_MOBILE.TOP];

export const BG_COLORS = {
  LIGHT: 'light',
  DARK: 'dark',
  TRANSPARENT: 'transparent',
};

BG_COLORS.LIST = [BG_COLORS.LIGHT, BG_COLORS.DARK, BG_COLORS.TRANSPARENT];

export const SPACINGS = {
  SMALL: 's',
  MEDIUM: 'm',
};

SPACINGS.LIST = [SPACINGS.SMALL, SPACINGS.MEDIUM];

export const BULLET_TYPES = {
  REGULAR: 'regular',
  NUMBER: 'number',
  CHECK: 'check',
};

BULLET_TYPES.LIST = [BULLET_TYPES.REGULAR, BULLET_TYPES.NUMBER, BULLET_TYPES.CHECK];

export const CHECKMARK_COLORS = {
  BLACK: 'black',
  GREEN: 'green',
};

CHECKMARK_COLORS.LIST = [CHECKMARK_COLORS.BLACK, CHECKMARK_COLORS.GREEN];

const isMobile =
  (narrowViewportMatch && narrowViewportMatch().matches) ||
  (touchDeviceMatch && touchDeviceMatch().matches);

const CheckMark = ({ checkmarkClassList }) => (
  <svg
    className={checkmarkClassList}
    width="15"
    height="11"
    viewBox="0 0 15 11"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M1.5 5L5.5 9L13.5 1" fill="#fff" strokeWidth="2" />
  </svg>
);

const shouldRenderOnMobile = (payload) => (isMobile && payload.showMobile) || !isMobile;

const ImageGrid = ({ media }) => {
  const shadowImgClassList = classNames(css.img, css.imgWithShadow);

  const shadowLinkClassList = classNames(css.ghostLink, {
    [css.visible]: isMobile,
  });
  return (
    <div className={css.imgGrid}>
      {media.content.map((item) => (
        <div className={css.imgWrapper} key={`image_grid_${item.img || item}`}>
          <img className={shadowImgClassList} src={item.img || item} alt="" />
          <Link className={shadowLinkClassList} to={item.to} />
          {item.title && (
            <>
              <div className={css.imgText}>
                <h4 className={css.imgTitle}>{item.title}</h4>
                {item.text && <p className={css.imgSubtitle}>{item.text}</p>}
                {item.to && (
                  <Link className={css.imgCtaLink} to={item.to}>
                    <FormattedMessage id="LandingPage.SectionOnDemand.ctaText">
                      {(id) => <PrimaryButton className={css.imgCtaButton}>{id}</PrimaryButton>}
                    </FormattedMessage>
                  </Link>
                )}
              </div>
              <div className={css.imgTextOverlay} />
            </>
          )}
        </div>
      ))}
    </div>
  );
};

const MediaWrapper = ({ media, wideMedia }) => {
  const imgWrapperClassList = classNames(css.imgWrapper, {
    [css.wide]: wideMedia,
  });

  return media?.content?.text ? (
    <div className={css.textContent}>
      {media?.content.text && <div className={css.text}>{media?.content.text}</div>}
      {media?.content.link.text && media?.content.link.name && (
        <ButtonNew {...media?.content?.link}>
          <span>{media?.content.link.text}</span>
        </ButtonNew>
      )}
    </div>
  ) : typeof media?.content !== 'undefined' && shouldRenderOnMobile(media) ? (
    <div className={css.media}>
      {/* If there's one image, render it. Otherwise render grid of 4 images */}
      {typeof media.content === 'string' ? (
        <div className={imgWrapperClassList}>
          <img className={css.img} src={media.content} alt="" />
        </div>
      ) : (
        <ImageGrid media={media} />
      )}
    </div>
  ) : null;
};

const Features = ({ features }) =>
  features && features.list && shouldRenderOnMobile(features) ? (
    <ul className={css.featuresList}>
      {features.list.map(
        (item) =>
          shouldRenderOnMobile(item) && (
            <li className={css.feature} key={`feature_item_${item.title}`}>
              <h5 className={css.featureTitle}>{item.title}</h5>
              <p className={css.featureText}>{item.text}</p>
            </li>
          )
      )}
    </ul>
  ) : null;

const MediaAndTextSection = ({
  className,
  title,
  caption,
  subtitle,
  bullets,
  bulletType,
  checkColor,
  features,
  textAlign,
  textAlignMobile,
  media,
  wideMedia,
  spacing,
  bgColor,
  children,
  buttonsList,
  animationType,
}) => {
  if (Array.isArray(buttonsList) && buttonsList.length > 2) {
    throw new Error('buttonsList prop max length is 2 buttons!');
  }

  const isClientSide = typeof window !== 'undefined' && typeof IntersectionObserver !== 'undefined';

  const { ref, inView } = useInView({
    /* With the treshold of 0.5 there's too much white space
     * to scroll before the animation appears. */
    threshold: animationType === ANIMATION_TYPES.RIGHT ? 0.5 : 0.2,
    triggerOnce: true,
  });

  const containerClassList = classNames(
    className,
    css.container,
    isClientSide && animationType && css[animationType],
    isClientSide && animationType && inView && css.containerVisible,
    bgColor === BG_COLORS.DARK
      ? css.bgDark
      : BG_COLORS.TRANSPARENT
      ? css.bgTransparent
      : css.bgLight,
    css[`spacing_${spacing}`]
  );

  const contentClassList = classNames(
    css.content,
    textAlign === TEXT_ALIGN.LEFT ? css.textLeft : css.textRight,
    textAlignMobile === TEXT_ALIGN_MOBILE.BOTTOM ? css.textMobileBottom : css.textMobileTop,
    media && !media.showMobile && css.withoutMedia
  );

  const bulletListClassList = classNames(
    css.bullets,
    bulletType === BULLET_TYPES.CHECK && css.bulletsWithCheckmark,
    bulletType === BULLET_TYPES.NUMBER && css.withNumber
  );

  const bulletClassList = classNames(
    css.bulletsItem,
    css[bulletType],
    bullets.bold && css.bulletBold
  );
  const checkmarkClassList = classNames(css.checkmark, css[checkColor]);

  const scrollToContainer = (toScroll) => {
    if (typeof document !== 'undefined') {
      document.getElementById(toScroll).scrollIntoView({ behavior: 'smooth' });
    }
  };

  const buttonsWithProps =
    Array.isArray(buttonsList) &&
    buttonsList.map((button) => ({
      name: button.name,
      params: button.params,
      withIcon: typeof button.withIcon === 'boolean' ? button.withIcon : true,
      type: button.type || BUTTON_TYPES.INLINE,
      size: button.size || BUTTON_SIZES.SMALL,
      linkColor: button.linkColor || LINK_COLORS.BLUE,
      mobileButtonPosition: button.mobileButtonPosition || MOBILE_POSITION.LEFT,
      linkText: button.linkText,
      toScroll: button.toScroll,
      externalLink: button.externalLink,
      ...(button.toScroll && {
        toScroll: button.toScroll,
      }),
      ...(button.callback && !button.toScroll && !button.name && { onClick: button.callback }),
    }));

  return (
    <div className={containerClassList} ref={isClientSide && animationType ? ref : null}>
      <div className={contentClassList}>
        <div className={css.info}>
          {subtitle && subtitle.content && <h2 className={css.subtitle}>{subtitle.content}</h2>}
          {title &&
            title.content &&
            shouldRenderOnMobile(title) &&
            (title.isH1 ? (
              <h1 className={css.titleH1}>{title.content}</h1>
            ) : (
              <h2 className={css.titleH2}>{title.content}</h2>
            ))}
          {caption && caption.text && shouldRenderOnMobile(caption) && (
            <div className={css.text}>{caption.text}</div>
          )}
          {bullets && bullets.list && shouldRenderOnMobile(bullets) && (
            <>
              {bullets.title && <h5 className={css.bulletsTitle}>{bullets.title}</h5>}
              <ul className={bulletListClassList}>
                {bullets.list.map((item, i) => (
                  <li key={`media_and_text_bullet_${i}`} className={bulletClassList}>
                    {bulletType === BULLET_TYPES.NUMBER && <span>{i + 1}. </span>}
                    {bulletType === BULLET_TYPES.CHECK && (
                      <CheckMark checkmarkClassList={checkmarkClassList} />
                    )}
                    {item}
                  </li>
                ))}
              </ul>
            </>
          )}
          <Features features={features} />
          {Array.isArray(buttonsWithProps) ? (
            <div className={css.buttonContainer}>
              {buttonsWithProps.map((btn, index) => (
                <div className={css.buttonWrapper} key={index}>
                  <ButtonNew
                    {...btn}
                    onClick={() => {
                      btn.toScroll ? scrollToContainer(btn.toScroll) : btn.onClick();
                    }}
                  >
                    <span>{btn.linkText}</span>
                  </ButtonNew>
                </div>
              ))}
            </div>
          ) : null}
        </div>
        {children ? (
          <div className={css.media}>
            <div className={css.childWrapper}>{children}</div>
          </div>
        ) : (
          <MediaWrapper media={media} wideMedia={wideMedia} />
        )}
      </div>
    </div>
  );
};

MediaAndTextSection.defaultProps = {
  title: {
    content: null,
    showMobile: true,
    isH1: false,
  },
  caption: {
    content: null,
    showMobile: true,
  },
  subtitle: {
    content: null,
    showMobile: true,
  },
  bullets: {
    list: null,
    bold: false,
  },
  bulletType: BULLET_TYPES?.CHECK,
  checkColor: CHECKMARK_COLORS?.GREEN,
  textAlign: TEXT_ALIGN?.LEFT,
  textAlignMobile: TEXT_ALIGN_MOBILE?.BOTTOM,
  bgColor: BG_COLORS?.LIGHT,
  spacing: SPACINGS?.SMALL,
  animationType: null,
};

MediaAndTextSection.propTypes = {
  title: shape({
    content: string,
    showMobile: bool,
    isH1: bool,
  }),
  caption: shape({
    content: string,
    showMobile: bool,
  }),
  subtitle: shape({
    content: string,
    showMobile: bool,
  }),
  bullets: shape({
    list: array,
    bold: bool,
  }),
  bulletType: oneOf(BULLET_TYPES?.LIST),
  checkColor: oneOf(CHECKMARK_COLORS?.LIST),
  media: object,
  wideMedia: bool,
  textAlign: oneOf(TEXT_ALIGN?.LIST),
  textAlignMobile: oneOf(TEXT_ALIGN_MOBILE?.LIST),
  bgColor: oneOf(BG_COLORS?.LIST),
  spacing: oneOf(SPACINGS?.LIST),
  buttonsList: arrayOf(
    shape({
      withIcon: bool,
      linkText: string.isRequired,
      name: string,
      toScroll: string,
      type: oneOf(BUTTON_TYPES?.LIST),
      size: oneOf(BUTTON_SIZES?.LIST),
      linkColor: oneOf(LINK_COLORS?.LIST),
      callback: func,
    })
  ),
  animationType: oneOf(ANIMATION_TYPES?.LIST),
};

export default MediaAndTextSection;
