import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { bool, object } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import config from '../../config';
import { injectIntl, intlShape } from '../../util/reactIntl';
import { isScrollingDisabled } from '../../ducks/UI.duck';
import { propTypes } from '../../util/types';
import { isEmployeeUser, isCompanyUser } from '../../util/b2bHelper';
import { getLocale } from '../../util/localeHelper';
import {
  Page,
  LayoutSingleColumn,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  Footer,
  LandingHero,
} from '../../components';
import { FavoriteListingsSlider, SectionTeamScheduling } from '../../components_new';
import { TopbarContainer } from '../../containers';
import ContentLoader from '../../components_new/FavoriteListingsSlider/ContentLoader';
import {
  updateSearchCategories,
  updateSearchDates,
  updateSearchTimes,
  updateSearchLocation,
  updateSearchListingName,
} from '../SearchPage/SearchPage.duck';

import { toggleUserFavoriteListings, generateListingArray } from '../../ducks/user.duck';

import facebookImage from '../../assets/respaces_social_1260x630.png';
import twitterImage from '../../assets/respaces_social_1260x630.png';

import css from './LandingPageLoggedIn.module.scss';

const sharetribeSdk = require('sharetribe-flex-sdk');
const SHARETRIBE_SDK_CLIENT_ID = process.env.REACT_APP_SHARETRIBE_SDK_CLIENT_ID;

// Create new SDK instance
const sdk = sharetribeSdk.createInstance({
  clientId: SHARETRIBE_SDK_CLIENT_ID,
});

export const LandingPageLoggedInComponent = (props) => {
  const {
    intl,
    scrollingDisabled,
    currentUser,
    favoritesArray,
    favoritesLoading,
    favoritesArrayOfObjects,
    toggleUserFavoriteListings,
  } = props;
  const isB2B = isEmployeeUser(currentUser) || isCompanyUser(currentUser);
  const [strapiShelvesLoading, setStrapiShelvesLoading] = useState(false);

  const locale = getLocale();

  const onToggleFavoriteListing = (id) => toggleUserFavoriteListings(id);

  const [listingObjectsForShelves, setListingObjectsForShelves] = useState([]);
  const tempArray = [];
  // fetch strapi listing ids for shelves
  useEffect(() => {
    setStrapiShelvesLoading(true);
    // TODO add a check for isArray
    const getListings = async () => {
      const strapiResponse = await axios.get('https://cms.respaces.co/shelves');
      const shelves = strapiResponse.data;
      if (shelves && Array.isArray(shelves)) {
        for (const shelf in shelves) {
          if (
            (shelves[shelf].identifier.includes('beta') && config.dev) ||
            (!shelves[shelf].identifier.includes('beta') && !config.dev)
          ) {
            try {
              await generateListingArray({ sdk, listingIdList: shelves[shelf].listingIDs }).then(
                (response) => tempArray.push({ ...shelves[shelf], listingsData: response })
              );
            } catch (error) {
              console.error(error);
            }
          }
        }
      }
      setListingObjectsForShelves(tempArray);
      setStrapiShelvesLoading(false);
    };
    getListings();
    //eslint-disable-next-line
  }, []);

  // Schema for search engines (helps them to understand what this page is about)
  // http://schema.org
  // We are using JSON-LD format
  const siteTitle = config.siteTitle;
  const schemaTitle = intl.formatMessage({ id: 'LandingPage.schemaTitle' }, { siteTitle });
  const schemaDescription = intl.formatMessage({ id: 'LandingPage.schemaDescription' });
  const schemaImage = `${config.canonicalRootURL}${facebookImage}`;

  /* I didn't find good way to merge default props with passed, so these
   * props should be passed to render standard blue link with arrow.
   * If you need other styles, just pass custom object instead of that,
   * for example: { type: 'primary', withIcon: false } - will render black button
   * with icon and white text */

  return (
    <Page
      id="page"
      className={css.root}
      scrollingDisabled={scrollingDisabled}
      contentType="website"
      description={schemaDescription}
      title={schemaTitle}
      facebookImages={[{ url: facebookImage, width: 1200, height: 630 }]}
      twitterImages={[
        { url: `${config.canonicalRootURL}${twitterImage}`, width: 600, height: 314 },
      ]}
      schema={{
        '@context': 'http://schema.org',
        '@type': 'WebPage',
        description: schemaDescription,
        name: schemaTitle,
        image: [schemaImage],
      }}
    >
      <LayoutSingleColumn>
        <LayoutWrapperTopbar>
          <TopbarContainer currentPage="LandingPageLoggedIn" />
        </LayoutWrapperTopbar>
        <LayoutWrapperMain>
          <div className={css.sections}>
            <div className={css.section}>
              <div className={`${css.sectionContentFirstChild} ${css.heroWrapper}`}>
                <LandingHero />
              </div>
            </div>
          </div>

          <FavoriteListingsSlider
            id="personal-favorites"
            title={intl.formatMessage({ id: 'FavoriteListingToggle.personalFavorites' })}
            listings={favoritesArrayOfObjects}
            favoriteListingsIds={favoritesArray}
            onToggleFavoriteListing={onToggleFavoriteListing}
            isShowCredits={isB2B}
          />
          <SectionTeamScheduling />

          {strapiShelvesLoading ? (
            <div className={css.strapiShelvesLoadingContainer}>
              <ContentLoader />
            </div>
          ) : (
            listingObjectsForShelves &&
            listingObjectsForShelves.map((shelf) => (
              <FavoriteListingsSlider
                key={`FavoriteListingsSlider-${shelf.identifier}`}
                id={shelf.identifier}
                title={locale === 'sv' ? shelf.titleSV : shelf.titleEN} // add swedish check
                listings={shelf.listingsData}
                favoriteListingsIds={favoritesArray}
                onToggleFavoriteListing={onToggleFavoriteListing}
                isControlledByRespaces
                isShowCredits={isB2B}
              />
            ))
          )}
        </LayoutWrapperMain>
        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </LayoutSingleColumn>
    </Page>
  );
};

LandingPageLoggedInComponent.defaultProps = {
  currentUserListing: null,
  currentUserListingFetched: false,
};

LandingPageLoggedInComponent.propTypes = {
  scrollingDisabled: bool.isRequired,
  currentUserListing: propTypes.ownListing,
  currentUserListingFetched: bool,

  // from withRouter
  history: object.isRequired,
  location: object.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = (state) => {
  const {
    user: {
      favoritesArray,
      favoritesLoading,
      favoritesArrayOfObjects,
      currentUser,
      currentUserListing,
      currentUserListingFetched,
    },
    SearchPage: {
      categories: stateCategories,
      location: stateLocation,
      dates: stateDates,
      times: stateTimes,
      listingName: stateListingName,
    },
  } = state;

  return {
    favoritesArray,
    favoritesLoading,
    favoritesArrayOfObjects,
    scrollingDisabled: isScrollingDisabled(state),
    currentUser,
    currentUserListing,
    currentUserListingFetched,
    stateCategories,
    stateLocation,
    stateDates,
    stateTimes,
    stateListingName,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateSearchCategories: (categories) => dispatch(updateSearchCategories(categories)),
    updateSearchDates: (dates) => dispatch(updateSearchDates(dates)),
    updateSearchTimes: (times) => dispatch(updateSearchTimes(times)),
    updateSearchLocation: (location) => dispatch(updateSearchLocation(location)),
    updateSearchListingName: (name) => dispatch(updateSearchListingName(name)),
    toggleUserFavoriteListings: (payload) => dispatch(toggleUserFavoriteListings(payload)),
  };
};

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const LandingPageLoggedIn = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl
)(LandingPageLoggedInComponent);

export default LandingPageLoggedIn;
