import get from 'lodash/get';
import React from 'react';
import { useIntl } from 'react-intl';
import { formatMoney } from '../../util/currency';
import { FormattedMessage } from '../../util/reactIntl';
import { types } from '../../util/sdkLoader';
import { propTypes } from '../../util/types';
import css from './BookingBreakdown.module.scss';
const { Money } = types;
// This component was made from a copy of the LineItemVAT component, thus some of it may be unnecessary.

const LineItemCouponMaybe = (props) => {
  const { transaction } = props;
  const intl = useIntl();
  const txTotalSavings = get(transaction, 'attributes.protectedData.totalSavings', null);

  const totalSavings =
    txTotalSavings && txTotalSavings.amount
      ? new Money(txTotalSavings.amount * 100, txTotalSavings.currency)
      : null;

  const formattedSavings = totalSavings ? formatMoney(intl, totalSavings) : null;
  const savingsLine = totalSavings ? (
    <div>
      <hr className={css.totalDivider} />
      <div className={css.lineItem}>
        <span className={css.itemLabel}>
          <FormattedMessage id="BookingBreakdown.totalSavings" />
        </span>
        <span className={css.itemValue}>- {formattedSavings}</span>
      </div>
    </div>
  ) : null;
  return <div>{savingsLine}</div>;
};

LineItemCouponMaybe.propTypes = {
  transaction: propTypes.transaction.isRequired,
};

export default LineItemCouponMaybe;
