import React, { memo } from 'react';

import { OpeningHours } from '../../components_new';

import css from './LocationPage.module.scss';

const SectionOpeningHours = memo(({ days, intl }) => (
  <section className={css.section}>
    <h4 className={css.sectionTitle}>{intl.formatMessage({ id: 'LocationPage.openingHours' })}</h4>
    <OpeningHours daysList={days} />
  </section>
));

export default SectionOpeningHours;
