/**
 *  TopbarMobileMenu prints the menu content for authenticated user or
 * shows login actions for those who are not authenticated.
 */
import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { ACCOUNT_SETTINGS_PAGES } from '../../routeConfiguration';
import { propTypes } from '../../util/types';

import {
  InlineTextButton,
  LanguageSelector,
  NamedLink,
  NotificationBadge,
  Logo,
} from '../../components';

import { ButtonNew } from '../../components_new';
import { BUTTON_SIZES } from '../../components_new/ButtonNew/ButtonNew';
import { TABS } from '../../ducks/DashboardPage.duck';

import css from './TopbarMobileMenu.module.scss';

const TopbarMobileMenu = (props) => {
  const {
    isAuthenticated,
    currentPage,
    currentUserHasListings,
    currentUserHasLocations,
    notificationCount,
    onLogout,
    isB2BEmployee,
    isB2BAccount,
    showBookADemoButton,
  } = props;

  const currentPageClass = (page) => {
    const isAccountSettingsPage =
      page === 'AccountSettingsPage' && ACCOUNT_SETTINGS_PAGES.includes(currentPage);
    return currentPage === page || isAccountSettingsPage ? css.currentPage : null;
  };

  const alwaysMenu = (
    <div className={css.alwaysMenu}>
      <NamedLink
        className={classNames(css.navigationLink, currentPageClass('SearchPage'))}
        name="SearchPage"
      >
        <FormattedMessage id="TopbarMobileMenu.searchPageLink" />
      </NamedLink>
      {!isB2BEmployee && (
        <>
          <NamedLink
            className={classNames(css.navigationLink, currentPageClass('Partner'))}
            name="Partner"
          >
            <FormattedMessage id="TopbarDesktop.spaceSupplier" />
          </NamedLink>
        </>
      )}
      <NamedLink
        className={classNames(css.navigationLink, currentPageClass('AboutPage'))}
        name="AboutPage"
      >
        <FormattedMessage id="TopbarDesktop.toAboutPage" />
      </NamedLink>
      <NamedLink
        className={classNames(css.navigationLink, currentPageClass('ContactUs'))}
        name="ContactUs"
      >
        <FormattedMessage id="TopbarDesktop.toContactPage" />
      </NamedLink>
      {showBookADemoButton && (
        <FormattedMessage id="TopbarDesktop.bookADemo">
          {(id) => (
            <ButtonNew
              externalLink="https://meetings.hubspot.com/sofia-bostroem"
              size={BUTTON_SIZES.SMALL}
            >
              {id}
            </ButtonNew>
          )}
        </FormattedMessage>
      )}
      <div className={css.languageSelector}>
        <LanguageSelector />
      </div>
    </div>
  );

  if (!isAuthenticated) {
    return (
      <div className={css.root}>
        <div className={css.topBar}>
          <Logo format="mobile" />
        </div>
        <div className={css.content}>{alwaysMenu}</div>
      </div>
    );
  }

  const notificationCountBadge =
    notificationCount > 0 ? (
      <NotificationBadge className={css.notificationBadge} count={notificationCount} />
    ) : null;

  return (
    <div className={css.root}>
      <div className={css.topBar}>
        <Logo format="mobile" />
      </div>
      <div className={css.content}>
        <NamedLink
          className={classNames(css.navigationLink, css.first, currentPageClass('InboxPage'))}
          name={!isB2BAccount ? 'InboxPage' : 'CompanyBookingsPage'}
          params={!isB2BAccount ? { tab: currentUserHasListings ? 'sales' : 'orders' } : null}
        >
          <FormattedMessage id="TopbarMobileMenu.inboxLink" />
          {notificationCountBadge}
        </NamedLink>
        {isB2BAccount && (
          <NamedLink
            className={classNames(
              css.navigationLink,
              currentPageClass('SubscriptionManagementPage')
            )}
            name="SubscriptionManagementPage"
            params={{ tab: 'team-access' }}
          >
            <FormattedMessage id="TopbarDesktop.companyAccount" />
          </NamedLink>
        )}
        <NamedLink
          className={classNames(css.navigationLink, currentPageClass('ManageListingsPage'))}
          name="DashboardPage"
          state={{ from: currentPage }}
          params={{ view: 'locations', tab: TABS.LOCATIONS }}
        >
          <FormattedMessage
            id={
              currentUserHasLocations || !!currentUserHasListings
                ? 'TopbarMobileMenu.yourListingsLink'
                : 'TopbarMobileMenu.becomePartner'
            }
          />
        </NamedLink>
        <NamedLink
          className={classNames(css.navigationLink, currentPageClass('AccountSettingsPage'))}
          name="AccountSettingsPage"
        >
          <FormattedMessage id="TopbarMobileMenu.accountSettingsLink" />
        </NamedLink>
        {alwaysMenu}
        <InlineTextButton rootClassName={css.logoutButton} onClick={onLogout}>
          <FormattedMessage id="TopbarMobileMenu.logoutLink" />
        </InlineTextButton>
      </div>
    </div>
  );
};

TopbarMobileMenu.defaultProps = {
  currentUser: null,
  notificationCount: 0,
  currentPage: null,
  showBookADemoButton: false,
};

const { bool, func, number, string } = PropTypes;

TopbarMobileMenu.propTypes = {
  isAuthenticated: bool.isRequired,
  currentUser: propTypes.currentUser,
  currentPage: string,
  notificationCount: number,
  onLogout: func.isRequired,
  showBookADemoButton: bool,
};

export default TopbarMobileMenu;
