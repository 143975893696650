import { loadData as ListingPageLoader } from './ListingPage/ListingPage.duck';
import { loadData as LocationPageLoader } from './LocationPage/LocationPage.duck';
import { loadData as EditListingPageLoader } from './EditListingPage/EditListingPage.duck';
import { loadData as ProfilePageLoader } from './ProfilePage/ProfilePage.duck';
import {
  loadData as TransactionPageLoader,
  setInitialValues as TransactionPageInitialValues,
} from './TransactionPage/TransactionPage.duck';
import { loadData as ManageListingsPageLoader } from './ManageListingsPage/ManageListingsPage.duck';
import { loadData as ContactDetailsPageLoader } from './ContactDetailsPage/ContactDetailsPage.duck';
import { loadData as StripePayoutPageLoader } from './StripePayoutPage/StripePayoutPage.duck';
import { loadData as PaymentMethodsPageLoader } from './PaymentMethodsPage/PaymentMethodsPage.duck';
import { loadData as EmailVerificationPageLoader } from './EmailVerificationPage/EmailVerificationPage';
import { loadData as InboxPageLoader } from './InboxPage/InboxPage.duck';
import { setInitialValues as CheckoutPageInitialValues } from './CheckoutPage/CheckoutPage.duck';
import { setInitialValues as OrderConfirmationPageInitialValues } from './OrderConfirmationPage/OrderConfirmationPage.duck';
import { loadData as SubscriptionManagementPageLoader } from './SubscriptionManagementPage/SubscriptionManagementPage.duck';
import { loadData as CompanyAccountPageLoader } from './CompanyAccountPage/CompanyAccountPage.duck';
import { loadData as CompanyBookingsPageLoadData } from './CompanyBookingsPage/CompanyBookingsPage.duck';
import { loadData as SEOLandingPageLoadData } from './SEOLandingPage/SEOLandingPage.duck';
import { loadData as BlogPageLoadData } from './BlogPage/BlogPage.duck';
import { loadDataSinglePost as SinglePostLoadData } from './BlogPage/BlogPage.duck';
import { loadData as LandingPagePageLoader } from './LandingPage/LandingPage.duck';

const getPageDataLoadingAPI = () => ({
  CheckoutPage: { setInitialValues: CheckoutPageInitialValues },
  ListingPage: { loadData: ListingPageLoader },
  LocationPage: { loadData: LocationPageLoader },
  EditListingPage: { loadData: EditListingPageLoader },
  ProfilePage: { loadData: ProfilePageLoader },
  TransactionPage: {
    loadData: TransactionPageLoader,
    setInitialValues: TransactionPageInitialValues,
  },
  OrderConfirmationPage: {
    setInitialValues: OrderConfirmationPageInitialValues,
  },
  ManageListingsPage: { loadData: ManageListingsPageLoader },
  ContactDetailsPage: { loadData: ContactDetailsPageLoader },
  StripePayoutPage: { loadData: StripePayoutPageLoader },
  PaymentMethodsPage: { loadData: PaymentMethodsPageLoader },
  EmailVerificationPage: { loadData: EmailVerificationPageLoader },
  InboxPage: { loadData: InboxPageLoader },
  SubscriptionManagementPage: { loadData: SubscriptionManagementPageLoader },
  CompanyAccountPage: { loadData: CompanyAccountPageLoader },
  CompanyBookingsPage: { loadData: CompanyBookingsPageLoadData },
  SEOLandingPage: { loadData: SEOLandingPageLoadData },
  BlogPage: { loadData: BlogPageLoadData },
  SinglePostPage: { loadData: SinglePostLoadData },
  LandingPage: { loadData: LandingPagePageLoader },
});
export default getPageDataLoadingAPI;
