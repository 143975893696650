import React from 'react';
import { FormattedMessage } from 'react-intl';
import css from './InNews.module.scss';
import { ExternalLink } from '../../../components';
import news1 from '../news_1.png';
import news2 from '../news_2.png';
import news3 from '../news_3.png';

export const InNews = () => {
  const news = [
    {
      img: news1,
      title: 'Coronavirus: How the world of work may change forever',
      link: 'https://www.bbc.com/worklife/article/20201023-coronavirus-how-will-the-pandemic-change-the-way-we-work',
    },
    {
      img: news2,
      title: 'Crisis situation for the hotel industry - but here is the salvation\n',
      link: 'https://www.di.se/nyheter/krislage-for-hotellbranschen-men-har-finns-raddningen/',
    },
    {
      img: news3,
      title: 'Hon vill få fler till krogen – för att jobba\n',
      link: 'https://www.svd.se/hon-vill-fa-fler-till-krogen--for-att-jobba',
    },
  ];

  return (
    <div className={css.wrapper}>
      <div className={css.title}>
        <FormattedMessage id="About.inNews.title" />
      </div>
      <div className={css.offers}>
        {news.map((cNews) => (
          <div className={css.offer}>
            <div className={css.newsLogo}>
              <img className={css.img} src={cNews.img} alt="" />
            </div>
            <div className={css.newsTitle}>{cNews.title}</div>
            <ExternalLink className={css.newsLink} href={cNews.link}>
              <FormattedMessage id="About.inNews.linkText" />
            </ExternalLink>
          </div>
        ))}
      </div>
    </div>
  );
};
