import isEqual from 'lodash/isEqual';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { calcRenewalDate, getCreditsLeftAndLimit } from '../../util/b2bHelper';
import { SUBSCRIPTION_STATUS_ACTIVE } from '../../util/types';
import css from './SectionAvailableCredit.module.scss';

const SectionAvailableCredit = () => {
  const currentUser = useSelector((state) => state.user.currentUser);

  const companyAccount = useSelector((state) => state.user.companyAccount, isEqual);

  const { currentCredits, creditLimit } = getCreditsLeftAndLimit(currentUser, companyAccount);

  const subscriptionRenewalDate = calcRenewalDate(companyAccount);

  const { subscriptionStartTimestamp, subscriptionStatus } =
    companyAccount?.attributes.profile.metadata || {};

  const renewalDateMaybe = subscriptionStartTimestamp ? subscriptionRenewalDate : '//-//-////';

  return (
    <div className={css.root}>
      <div className={css.content}>
        <div className={css.title}>
          <FormattedMessage id="SectionAvailableCredit.title" defaultMessage="Available credits" />
        </div>
        <div className={css.row}>
          <div className={css.renewal}>
            <FormattedMessage
              id={
                subscriptionStatus
                  ? `SectionAvailableCredit.${subscriptionStatus}`
                  : `SectionAvailableCredit.${SUBSCRIPTION_STATUS_ACTIVE}`
              }
              values={{
                date: renewalDateMaybe,
              }}
            />
          </div>
          <div className={css.remaining}>
            <FormattedMessage
              id="SectionAvailableCredit.remaining"
              values={{ current: currentCredits, limit: creditLimit }}
            >
              {(id) => <span className={css.number}>{id}</span>}
            </FormattedMessage>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SectionAvailableCredit;
