const StatusModel = {
  DRAFT: {
    id: 'draft',
    label: 'MarketplaceConfig.listingStatus.draft',
    color: 'yellow',
  },
  PENDING: {
    id: 'pendingApproval',
    label: 'MarketplaceConfig.listingStatus.pendingApproval',
    color: 'yellow',
  },
  PUBLISHED: {
    id: 'published',
    label: 'MarketplaceConfig.listingStatus.published',
    color: 'green',
  },
  CLOSED: {
    id: 'closed',
    label: 'MarketplaceConfig.listingStatus.closed',
    color: 'grey',
  },
};

StatusModel.LIST = [
  StatusModel.DRAFT,
  StatusModel.PENDING,
  StatusModel.PUBLISHED,
  StatusModel.CLOSED,
];

StatusModel.ID_TO_DATA = {
  [StatusModel.DRAFT.id]: StatusModel.DRAFT,
  [StatusModel.PENDING.id]: StatusModel.PENDING,
  [StatusModel.PUBLISHED.id]: StatusModel.PUBLISHED,
  [StatusModel.CLOSED.id]: StatusModel.CLOSED,
};

StatusModel.TYPES = [
  StatusModel.DRAFT.id,
  StatusModel.PENDING.id,
  StatusModel.PUBLISHED.id,
  StatusModel.CLOSED.id,
];

export default StatusModel;
