import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import { bool, func, string } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';

import { NamedLink, OutsideClickHandler } from '../../components';
import { ButtonNew } from '../index';
import { BUTTON_TYPES, LINK_COLORS } from '../ButtonNew/ButtonNew';

import { inBrowser } from '../../util/device';
import { intlShape } from '../../util/reactIntl';

import { mapStateToProps, mapDispatchToProps } from './model/store';
import { validateEmail } from './model/validateEmail';

import css from './InviteModal.module.scss';

const InviteModalComponent = ({
  onInviteUser,
  inviteLoading,
  isInviteSuccessful,
  isInviteFailed,
  toggleInviteSuccess,
  toggleInviteError,
  alreadyHaveConnection,
  toggleAlreadyHaveConnection,
  isOpen,
  onClose,
  className,
  intl,
}) => {
  const [email, setEmail] = useState('');
  const [emailValid, setEmailValid] = useState(true);
  const [isSchedulePage, setSchedulePage] = useState(false);

  useEffect(() => {
    setEmailValid(validateEmail(email));
  }, [email]);

  useEffect(() => {
    inBrowser() && setSchedulePage(window.location.pathname === '/inbox/schedule');
  }, []);

  const showInput = !isInviteSuccessful && !alreadyHaveConnection && !isInviteFailed;

  let title, caption, btnCallback, btnText;

  const resetState = () => {
    toggleAlreadyHaveConnection(false);
    toggleInviteSuccess(false);
    toggleInviteError(false);
    setEmail('');
  };

  useEffect(() => {
    return () => resetState();
  }, [isOpen]);

  if (isInviteSuccessful) {
    title = 'InviteModal.successTitle';
    caption = 'InviteModal.successCaption';
    btnText = 'InviteModal.inviteMore';
    btnCallback = resetState;
  } else if (!inviteLoading && alreadyHaveConnection) {
    title = 'InviteModal.errorTitle';
    caption = 'InviteModal.errorCaption';
    btnText = 'InviteModal.tryAgain';
    btnCallback = resetState;
  } else if (isInviteFailed) {
    title = 'InviteModal.failedTitle';
    caption = 'InviteModal.failedCaption';
    btnText = 'InviteModal.tryAgain';
    btnCallback = resetState;
  } else {
    title = 'InviteModal.title';
    caption = 'InviteModal.caption';
    btnText = 'InviteModal.send';
    btnCallback = () => {
      onInviteUser(email);
    };
  }

  if (inviteLoading) {
    btnText = 'InviteModal.loading';
  }

  return (
    isOpen && (
      <OutsideClickHandler
        className={classNames(css.inviteModal, className)}
        onOutsideClick={onClose}
      >
        <h5>{intl.formatMessage({ id: title })}</h5>
        <p>{intl.formatMessage({ id: caption }, { email })}</p>
        {showInput && (
          <input
            onKeyDown={(e) => e.key === 'Enter' && emailValid && onInviteUser(email)}
            onInput={({ currentTarget }) => setEmail(currentTarget.value)}
            type="text"
            placeholder={intl.formatMessage({ id: 'InviteModal.emailPlaceholder' })}
          />
        )}
        <div className={css.buttonsWrapper}>
          <button onClick={onClose} className={css.cancel}>
            {/* TODO: Check for page - landing or inbox? */}
            {isInviteSuccessful && isSchedulePage ? (
              <NamedLink name="LandingPageLoggedIn">
                {intl.formatMessage({ id: 'InviteModal.toLandingPage' })}
              </NamedLink>
            ) : (
              !inviteLoading &&
              intl.formatMessage({
                id: isInviteSuccessful ? 'InviteModal.close' : 'InviteModal.cancel',
              })
            )}
          </button>
          <ButtonNew
            type={BUTTON_TYPES.INLINE}
            linkColor={LINK_COLORS.BLUE}
            onClick={btnCallback}
            disabled={email.length === 0 || !emailValid}
          >
            {intl.formatMessage({ id: btnText })}
          </ButtonNew>
        </div>
      </OutsideClickHandler>
    )
  );
};

InviteModalComponent.propTypes = {
  onInviteUser: func.isRequired,
  inviteLoading: bool.isRequired,
  isInviteSuccessful: bool.isRequired,
  toggleInviteSuccess: func.isRequired,
  alreadyHaveConnection: bool.isRequired,
  toggleAlreadyHaveConnection: func.isRequired,
  isOpen: bool.isRequired,
  onClose: func.isRequired,
  className: string,
  intl: intlShape,
};

const InviteModal = compose(connect(mapStateToProps, mapDispatchToProps))(InviteModalComponent);

export default injectIntl(InviteModal);
