import React, { memo, useCallback, useState, useEffect } from 'react';
import classNames from 'classnames';
import { useIntl } from 'react-intl';

import config from '../../../../config';
import { convertPriceToCredit, priceData } from '../../../../util/currency';
import { types as sdkTypes } from '../../../../util/sdkLoader';
import { findOptionsForSelectFilter } from '../../../../util/search';
import { FIXED_OFFICE_CATEGORY, COWORKING_CATEGORY } from '../../../../util/types';
import { ImageCarousel, Modal } from '../../../../components';
import { Tooltip } from '../../../../components_new';
import { inBrowser } from '../../../../util/device';

import css from './ListingCollapsibleCard.module.scss';

const { Money } = sdkTypes;

const filterConfig = config.custom.filters;

const ListingCollapsibleCard = ({
  id,
  slug,
  price,
  description,
  vat,
  image,
  images = [],
  unitTranslationKey,
  category,
  fixedOfficeType,
  amenities,
  amenitiesInfo,
  offer,
  isOpen,
  onToggle,
  showCredits,
  minSeats,
  maxSeats,
  requestPrice,
  contractLength,
  contractLengthUnit,
  startDate,
  paymentPeriod,
  allowVatExemptCompanies,
  contractType,
  noticePeriodPerUnit,
  noticePeriodUnit,
  noReqForContractLength,
  areaMin,
  areaMax,
  isIntegrationPartner,
  useLocationImages,
  vitecId,
  infoDescriptions,
  calculatedArea,
  calculatedSeats,
}) => {
  const [imageCarouselOpen, setImageCarouselOpen] = useState(false);
  const [tipOpen, setTipOpen] = useState(false);
  const intl = useIntl();
  const amenitiesOptions = findOptionsForSelectFilter('ameneties', filterConfig);
  const featuresAmenities = amenitiesOptions?.filter((o) => !!amenities?.includes(o.key));
  const offerOptions = findOptionsForSelectFilter('offer', filterConfig);
  const featuresOffer = offerOptions?.filter((o) => !!offer?.includes(o.key));
  const missingOffer = offerOptions?.filter(
    (obj) => obj.important && !offer?.includes(obj.key) && obj[category]
  );
  const isFixed = category === FIXED_OFFICE_CATEGORY;
  const showAdditionalInfoSection =
    isFixed &&
    (contractLength ||
      contractLengthUnit ||
      startDate ||
      paymentPeriod ||
      allowVatExemptCompanies ||
      contractType ||
      noticePeriodPerUnit ||
      noticePeriodUnit ||
      areaMin ||
      areaMax);

  const categoryOptions = findOptionsForSelectFilter('category', filterConfig);
  const categoryTranslation = categoryOptions.find((o) => o.key === category).labelId;
  const contractDescription = infoDescriptions?.contract_length;

  const fixedOfficeTypeOptions =
    isFixed && findOptionsForSelectFilter('fixedOfficeType', filterConfig);
  const fixedOfficeTypeTranslation =
    fixedOfficeTypeOptions &&
    fixedOfficeType &&
    fixedOfficeTypeOptions.find((o) => o.key === fixedOfficeType).labelId;

  const getSeatsFormatted = useCallback(() => {
    if (!minSeats || minSeats === maxSeats) {
      return maxSeats;
    } else if (!maxSeats) {
      return minSeats;
    } else if (!!minSeats && !!maxSeats) {
      return `${minSeats} - ${maxSeats}`;
    }
  }, [minSeats, maxSeats]);

  useEffect(() => {
    if (inBrowser()) {
      imageCarouselOpen
        ? document?.body?.classList.add('blockScroll')
        : document?.body?.classList.remove('blockScroll');
    }
  }, [imageCarouselOpen]);

  const getSeatsOrAreaFormatted = useCallback(() => {
    if (calculatedSeats && !calculatedArea) {
      return (
        <>
          {areaMin && areaMax && areaMin !== areaMax
            ? `${areaMin} - ${areaMax}`
            : areaMin || areaMax}{' '}
          m<sup>2</sup>
        </>
      );
    } else {
      return (
        <>
          {category === COWORKING_CATEGORY &&
            intl.formatMessage({ id: 'LocationPage.listingCard.upTo' })}
          {getSeatsFormatted()}{' '}
          {intl.formatMessage(
            { id: 'LocationPage.listingCard.seats' },
            { asterisk: isIntegrationPartner && calculatedSeats ? '*' : '' }
          )}
        </>
      );
    }
  }, [calculatedSeats]);

  return (
    <>
      <Modal
        id={`LocationPage.locationImageCarousel_${id}`}
        scrollLayerClassName={css.carouselModalScrollLayer}
        containerClassName={css.carouselModalContainer}
        isOpen={imageCarouselOpen}
        onClose={(e) => {
          e.stopPropagation();
          setImageCarouselOpen(false);
        }}
        usePortal
        lightCloseButton
      >
        <ImageCarousel images={images} isLocation={useLocationImages} />
      </Modal>
      <div
        id={id}
        onClick={() => {
          if (!tipOpen) {
            onToggle({ id, slug });
            // scroll to active div after half a delay to allow the animation to finish
            !isOpen &&
              typeof window !== 'undefined' &&
              window.innerWidth > 768 &&
              setTimeout(() => {
                const element = document.getElementById(id);
                element.scrollIntoView({ behavior: 'smooth', block: 'center' });
              }, 200);
          } else {
            setTipOpen(false);
          }
        }}
        className={classNames(css.card, isOpen && css.highlight)}
      >
        <div className={classNames(css.top, isOpen && css.isOpen)}>
          <div className={classNames(css.listingImg, isOpen && css.isOpen)}>
            <img
              src={image}
              alt=""
              onClick={(e) => {
                e.preventDefault();
                isOpen && e.stopPropagation();
                isOpen && setImageCarouselOpen(true);
              }}
            />
            <div className={classNames(css.imageCount, css.isOpen)}>
              <span>{images.length}</span>
            </div>
          </div>
          <div className={css.mainInfo}>
            <h5 className={css.listingCategory}>
              {intl.formatMessage({
                id: fixedOfficeTypeTranslation ? fixedOfficeTypeTranslation : categoryTranslation,
              })}
            </h5>
            <p className={css.seatsCount}>{getSeatsOrAreaFormatted()}</p>
            <p className={css.description}>{description}</p>
            {vitecId && (
              <img
                src={`https://hitcounter.vitec.net/IncrementCounter.aspx?guid=${vitecId}&requestedFrom=Respaces`}
                alt=""
              ></img>
            )}
          </div>
          <div className={css.price}>
            <span className={css.bold}>
              {requestPrice
                ? intl.formatMessage({ id: 'ListingPage.header.priceOnRequestMessage' })
                : showCredits
                ? intl.formatMessage(
                    { id: 'BookingPanel.credit.creditsValue' },
                    {
                      credits: convertPriceToCredit(new Money(price, 'SEK')),
                    }
                  )
                : priceData(new Money(price, 'SEK'), vat, intl).formattedPrice}
            </span>
            {!requestPrice && (
              <>
                {intl.formatMessage({ id: unitTranslationKey })}{' '}
                {!showCredits && (
                  <span className={css.vat}>
                    {intl.formatMessage({ id: 'LocationPage.listingCard.excludingVAT' })}
                  </span>
                )}
              </>
            )}
          </div>
        </div>
        <div className={classNames(css.bottom, isOpen && css.isOpen)}>
          <h5 className={css.subtitle}>
            {intl.formatMessage({
              id: `LocationPage.listingCard.amenitiesTitle${isFixed ? 'Fixed' : ''}`,
            })}
          </h5>
          <ul className={css.amenitiesList}>
            {featuresAmenities.map((am) => (
              <li
                key={`listing_card_amenities_${am.key}`}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  setTipOpen(!tipOpen);
                }}
              >
                <img src={am.iconSrc} alt={am.id} className={css.featureIcon} />
                {intl.formatMessage({ id: am.labelId })}
                {amenitiesInfo[am.key]?.description ? (
                  <Tooltip content={amenitiesInfo[am.key]?.description} className={css.amenityInfo}>
                    <svg
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g opacity="0.3">
                        <path
                          d="M8.492 6.91092C8.46974 6.78797 8.40225 6.67782 8.30283 6.60214C8.20341 6.52647 8.07926 6.49076 7.95482 6.50205C7.83039 6.51334 7.71469 6.57081 7.63051 6.66314C7.54633 6.75547 7.49977 6.87598 7.5 7.00092V11.5029L7.508 11.5929C7.53026 11.7159 7.59775 11.826 7.69717 11.9017C7.79659 11.9774 7.92074 12.0131 8.04518 12.0018C8.16961 11.9905 8.28531 11.933 8.36949 11.8407C8.45367 11.7484 8.50023 11.6279 8.5 11.5029V7.00092L8.492 6.91092Z"
                          fill="black"
                        />
                        <path
                          d="M8.79907 4.75C8.79907 4.55109 8.72006 4.36032 8.5794 4.21967C8.43875 4.07902 8.24798 4 8.04907 4C7.85016 4 7.65939 4.07902 7.51874 4.21967C7.37809 4.36032 7.29907 4.55109 7.29907 4.75C7.29907 4.94891 7.37809 5.13968 7.51874 5.28033C7.65939 5.42098 7.85016 5.5 8.04907 5.5C8.24798 5.5 8.43875 5.42098 8.5794 5.28033C8.72006 5.13968 8.79907 4.94891 8.79907 4.75Z"
                          fill="black"
                        />
                        <path
                          d="M16 8C16 5.87827 15.1571 3.84344 13.6569 2.34315C12.1566 0.842855 10.1217 0 8 0C5.87827 0 3.84344 0.842855 2.34315 2.34315C0.842855 3.84344 0 5.87827 0 8C0 10.1217 0.842855 12.1566 2.34315 13.6569C3.84344 15.1571 5.87827 16 8 16C10.1217 16 12.1566 15.1571 13.6569 13.6569C15.1571 12.1566 16 10.1217 16 8ZM1 8C1 7.08075 1.18106 6.1705 1.53284 5.32122C1.88463 4.47194 2.40024 3.70026 3.05025 3.05025C3.70026 2.40024 4.47194 1.88463 5.32122 1.53284C6.1705 1.18106 7.08075 1 8 1C8.91925 1 9.8295 1.18106 10.6788 1.53284C11.5281 1.88463 12.2997 2.40024 12.9497 3.05025C13.5998 3.70026 14.1154 4.47194 14.4672 5.32122C14.8189 6.1705 15 7.08075 15 8C15 9.85652 14.2625 11.637 12.9497 12.9497C11.637 14.2625 9.85652 15 8 15C6.14348 15 4.36301 14.2625 3.05025 12.9497C1.7375 11.637 1 9.85652 1 8Z"
                          fill="black"
                        />
                      </g>
                    </svg>
                  </Tooltip>
                ) : (
                  ''
                )}
              </li>
            ))}
            {featuresOffer.map((o) => (
              <li key={`listing_card_offer_${o.key}`}>
                <img src={o.iconSrc} alt={o.id} className={css.featureIcon} />
                {intl.formatMessage({ id: o.labelId })}
              </li>
            ))}
            {missingOffer.map((o) => (
              <li key={`listing_card_offer_${o.key}`} className={css.missingOffer}>
                <img src={o.iconSrc} alt={o.label} className={css.featureIcon} />
                {intl.formatMessage({ id: o.labelId })}
              </li>
            ))}
          </ul>
          {showAdditionalInfoSection && (
            <>
              <h5 className={css.subtitle}>
                {intl.formatMessage({ id: 'LocationPage.listingCard.detailsTitle' })}
              </h5>
              <ul className={css.detailsList}>
                {!noReqForContractLength && contractLength && (
                  <li>
                    <span>
                      {intl.formatMessage({ id: 'LocationPage.listingCard.contractLength' })}
                    </span>
                    <span>
                      {contractDescription
                        ? contractDescription
                        : intl.formatMessage(
                            {
                              id:
                                contractLengthUnit === 'month'
                                  ? 'LocationPage.listingCard.contractLengthMonth'
                                  : 'LocationPage.listingCard.contractLengthYear',
                            },
                            {
                              contractLength:
                                contractLengthUnit === 'year'
                                  ? contractLength / 12
                                  : contractLength,
                            }
                          )}
                    </span>
                  </li>
                )}
                {noticePeriodPerUnit && (
                  <li>
                    <span>
                      {intl.formatMessage({ id: 'LocationPage.listingCard.noticePeriod' })}
                    </span>
                    <span>
                      {intl.formatMessage(
                        {
                          id:
                            noticePeriodUnit === 'month'
                              ? 'LocationPage.listingCard.noticePeriodMonth'
                              : 'LocationPage.listingCard.noticePeriodYear',
                        },
                        { noticePeriodPerUnit: noticePeriodPerUnit }
                      )}
                    </span>
                  </li>
                )}
                {startDate && (
                  <li>
                    <span>{intl.formatMessage({ id: 'LocationPage.listingCard.moveInDate' })}</span>
                    <span>{startDate.split('T')[0]}</span>
                  </li>
                )}
                {paymentPeriod && (
                  <li>
                    <span>
                      {intl.formatMessage({ id: 'LocationPage.listingCard.paymentPeriod' })}
                    </span>
                    <span>
                      {intl.formatMessage({
                        id: `LocationPage.listingCard.paymentPeriod.${paymentPeriod}`,
                      })}
                    </span>
                  </li>
                )}
                {allowVatExemptCompanies && (
                  <li>
                    <span>{intl.formatMessage({ id: 'LocationPage.listingCard.VATexcempt' })}</span>
                    {intl.formatMessage({
                      id: `LocationPage.listingCard.VATexcempt.${allowVatExemptCompanies}`,
                    })}
                  </li>
                )}
                {contractType && (
                  <li>
                    <span>
                      {intl.formatMessage({ id: 'LocationPage.listingCard.contractType' })}
                    </span>
                    {intl.formatMessage({
                      id: `LocationPage.listingCard.contractType.${contractType}`,
                    })}
                  </li>
                )}
                {(areaMin || areaMax) && (
                  <li>
                    <span>{intl.formatMessage({ id: 'LocationPage.listingCard.area' })}</span>
                    {areaMin && areaMax && areaMin !== areaMax
                      ? `${areaMin} - ${areaMax}`
                      : areaMin || areaMax}
                  </li>
                )}
                {calculatedSeats && (minSeats || maxSeats) && (
                  <li>
                    <span>
                      {intl.formatMessage(
                        { id: 'LocationPage.listingCard.seatsCapital' },
                        { asterisk: isIntegrationPartner ? '*' : '' }
                      )}
                    </span>
                    {getSeatsFormatted()}
                  </li>
                )}
              </ul>
            </>
          )}
          {isIntegrationPartner && calculatedSeats && (
            <p className={css.description}>
              {intl.formatMessage({ id: 'LocationPage.listingCard.seatsDisclaimer' })}
            </p>
          )}
        </div>
        <div className={css.btnWrapper}>
          <button className={css.toggleCollapseBtn}>
            {intl.formatMessage({
              id: isOpen ? 'LocationPage.listingCard.collapse' : 'LocationPage.listingCard.open',
            })}
          </button>
        </div>
      </div>
    </>
  );
};

export default ListingCollapsibleCard;
