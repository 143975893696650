import React from 'react';

const Arrow = ({ className }) => {
  return (
    <svg
      className={className}
      width="346"
      height="377"
      viewBox="0 0 346 377"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M313.499 301.185C356.499 139.685 69.4992 183.185 114.5 243.185C159.5 303.185 228.499 147.185 183.499 93.6846C144.127 46.8756 64.4993 118.185 8.99931 93.6847"
        stroke="#E8909C"
        stroke-width="1.5"
        fill="none"
      />
      <line
        y1="-0.75"
        x2="8"
        y2="-0.75"
        transform="matrix(-0.390517 -0.920596 -0.920596 0.390517 10.3145 101.121)"
        stroke="#E8909C"
        stroke-width="1.5"
        fill="none"
      />
      <line
        y1="-0.75"
        x2="8"
        y2="-0.75"
        transform="matrix(-0.390517 -0.920596 -0.920596 0.390517 10.3145 101.121)"
        stroke="#E8909C"
        stroke-width="1.5"
        fill="none"
      />
      <line
        x1="14.6581"
        y1="90.8753"
        x2="7.29336"
        y2="93.9994"
        stroke="#E8909C"
        stroke-width="1.5"
        fill="none"
      />
      <line
        x1="14.6581"
        y1="90.8753"
        x2="7.29336"
        y2="93.9994"
        stroke="#E8909C"
        stroke-width="1.5"
        fill="none"
      />
    </svg>
  );
};
export default Arrow;
