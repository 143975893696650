import { arrayOf, func, shape, string } from 'prop-types';

export const TABLE_PROP_TYPES = {
  headers: arrayOf(
    shape({
      id: string.isRequired,
      label: string.isRequired,
      width: string.isRequired,
    })
  ).isRequired,
  data: arrayOf(
    shape({
      id: string.isRequired,
      action: string.isRequired,
    })
  ).isRequired,
  tableId: string.isRequired,
  onRowClick: func.isRequired,
  onInitAction: func.isRequired,
};
