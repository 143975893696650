import { number } from 'prop-types';
import { useEffect, useRef, useState } from 'react';
import { Form as FinalForm } from 'react-final-form';
import { useIntl } from 'react-intl';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import {
  EditSubscriptionSuccessModal,
  FieldNumberInput,
  FieldRadioButton,
  Form,
  PrimaryButton,
  StripeSplitingCreditCard,
} from '../../components';
import config from '../../config';
import BottomButtons from '../../containers/SubscriptionManagementPage/MySubscriptionTab/BottomButtons';
import { buyOneTimeCredits } from '../../containers/SubscriptionManagementPage/SubscriptionManagementPage.duck';
import { formatMoneyWithSpace, SUBSCRIPTION_VAT } from '../../util/currency';
import { ensurePaymentMethodCard, ensureStripeCustomer } from '../../util/data';
import { useBoolean } from '../../util/hooks';
import { stripeErrorTranslation } from '../../util/stripe';
import { required } from '../../util/validators';
import css from './AddOneTimeCreditsForm.module.scss';

const INVOICE_METHOD = 'invoice';
const CREDIT_CARD_METHOD = 'card';

const AddOneTimeCreditsForm = ({ selectedCreditAmount, hasChargebeePaymentSource }) => {
  const intl = useIntl();
  const [stripe, setStripe] = useState(null);
  const stripeCard = useRef(null);
  const [cardNumberError, setCardNumberError] = useState(null);
  const [cardNumberValid, setCardNumberValid] = useState(false);
  const [cardExpiryError, setCardExpiryError] = useState(null);
  const [cardExpiryValid, setCardExpiryValid] = useState(false);
  const [cardCvcError, setCardCvcError] = useState(null);
  const [cardCvcValid, setCardCvcValid] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState(null);
  const dispatch = useDispatch();
  const [isSuccessModalOpen, setSuccessModalOpen] = useBoolean();

  const currentUser = useSelector((state) => state.user.currentUser, shallowEqual);
  const stripeCustomerFetched = useSelector(
    (state) => state.CheckoutPage.stripeCustomerFetched,
    shallowEqual
  );
  const buyOneTimeCreditsInProgress = useSelector(
    (state) => state.SubscriptionManagementPage.buyOneTimeCreditsInProgress,
    shallowEqual
  );
  const buyOneTimeCreditsError = useSelector(
    (state) => state.SubscriptionManagementPage.buyOneTimeCreditsError,
    shallowEqual
  );

  const onBuyOneTimeCredits = async ({
    totalPrice,
    oneTimeCreditNumber,
    paymentSource,
    paymentOption,
  }) =>
    dispatch(buyOneTimeCredits({ totalPrice, oneTimeCreditNumber, paymentSource, paymentOption }));

  useEffect(() => {
    if (window && !window.Stripe) {
      throw new Error('Stripe must be loaded for StripePaymentForm');
    }

    if (config.stripe.publishableKey) {
      setStripe(window.Stripe(config.stripe.publishableKey));
    }
  }, []);

  const hasDefaultPaymentMethod = !!(
    stripeCustomerFetched &&
    ensureStripeCustomer(currentUser.stripeCustomer).attributes.stripeCustomerId &&
    ensurePaymentMethodCard(currentUser.stripeCustomer.defaultPaymentMethod).id
  );
  const defaultPaymentMethod = hasDefaultPaymentMethod
    ? currentUser.stripeCustomer.defaultPaymentMethod
    : null;
  const ensuredDefaultPaymentMethod = ensurePaymentMethodCard(defaultPaymentMethod);
  const perPrice = process.env.REACT_APP_PRICE_PER_CREDIT / (1 + SUBSCRIPTION_VAT);

  const handleCardChange = (event, cardField) => {
    const { error, complete } = event;
    const _error = error ? stripeErrorTranslation(intl, error) : null;
    switch (cardField) {
      case 'cardNumber':
        setCardNumberError(_error);
        setCardNumberValid(complete);
        break;
      case 'cardExpiry': {
        setCardExpiryError(_error);
        setCardExpiryValid(complete);
        break;
      }
      case 'cardCvc': {
        setCardCvcError(_error);
        setCardCvcValid(complete);
        break;
      }
      default:
        break;
    }
  };

  const handleOneTimeCreditsFormSubmit = async (values) => {
    const { paymentOption, creditsNumber } = values;
    let cardTokenResult;
    if (
      paymentOption === CREDIT_CARD_METHOD &&
      (!hasDefaultPaymentMethod || (hasDefaultPaymentMethod && !hasChargebeePaymentSource))
    ) {
      cardTokenResult = await stripe.createToken(stripeCard.current.cardNumber);
    }
    const totalPrice = creditsNumber ? (creditsNumber * perPrice).toFixed(2) * 100 : 0;
    const submitParams = {
      paymentSource: cardTokenResult ? cardTokenResult?.token?.id : null,
      oneTimeCreditNumber: creditsNumber,
      totalPrice,
      paymentOption,
    };
    try {
      await onBuyOneTimeCredits(submitParams);
      setSuccessModalOpen.on();
    } catch (error) {
      console.error(error);
    }
  };
  const initialValues = { creditsNumber: selectedCreditAmount };

  return (
    <FinalForm
      onSubmit={handleOneTimeCreditsFormSubmit}
      initialValues={{ ...initialValues }}
      render={(formRenderProps) => {
        const { handleSubmit, invalid, values } = formRenderProps;
        const totalPrice = values.creditsNumber
          ? parseFloat((values.creditsNumber * perPrice).toFixed(2))
          : 0;
        const totalPriceWithVAT = (totalPrice * (1 + SUBSCRIPTION_VAT)).toFixed(2);
        const isInvoice = values.paymentOption === INVOICE_METHOD;
        const cardFormInvalid =
          cardNumberError ||
          cardExpiryError ||
          cardCvcError ||
          !cardNumberValid ||
          !cardExpiryValid ||
          !cardCvcValid;
        const submitDisabled =
          invalid ||
          (!isInvoice && !hasDefaultPaymentMethod && cardFormInvalid) ||
          (!isInvoice && paymentMethod === 'replaceCard' && cardFormInvalid);

        return (
          <Form className={css.formContainer} onSubmit={handleSubmit}>
            <div className={css.formSection}>
              <FieldNumberInput
                id="creditsNumber"
                name="creditsNumber"
                className={css.numberInputWrapper}
                label={intl.formatMessage({ id: 'AddOneTimeCreditsForm.creditsLabel' })}
                subLabel={intl.formatMessage(
                  { id: 'AddOneTimeCreditsForm.creditsSubLabel' },
                  { perPrice }
                )}
                labelClassName={css.formSectionTitle}
                subLabelClassName={css.subLabel}
                inline
                validate={required(
                  intl.formatMessage({ id: 'AddOneTimeCreditsForm.creditsRequired' })
                )}
                maxLength="6"
              />
            </div>
            <div className={css.formSection}>
              <div className={css.formSectionTitle}>
                {intl.formatMessage({ id: 'AddOneTimeCreditsForm.paymentOptionLabel' })}
              </div>
              <FieldRadioButton
                id="paymentOptionInvoice"
                name="paymentOption"
                value={INVOICE_METHOD}
                label={intl.formatMessage({ id: 'AddOneTimeCreditsForm.invoice' })}
                labelClassName={css.labelWrapper}
                textClassName={css.labelText}
                svgClassName={css.radioIcon}
              />
              <FieldRadioButton
                id="paymentOptionCard"
                name="paymentOption"
                value={CREDIT_CARD_METHOD}
                label={intl.formatMessage({ id: 'AddOneTimeCreditsForm.card' })}
                labelClassName={css.labelWrapper}
                textClassName={css.labelText}
                svgClassName={css.radioIcon}
              />
              {values?.paymentOption === CREDIT_CARD_METHOD && (
                <>
                  <StripeSplitingCreditCard
                    stripe={stripe}
                    stripeCard={stripeCard}
                    defaultPaymentMethod={ensuredDefaultPaymentMethod}
                    handleCardChange={handleCardChange}
                    setCardNumberValid={setCardNumberValid}
                    setCardExpiryValid={setCardExpiryValid}
                    setCardCvcValid={setCardCvcValid}
                    paymentMethod={paymentMethod}
                    setPaymentMethod={setPaymentMethod}
                    showCardDetail={hasDefaultPaymentMethod && hasChargebeePaymentSource}
                  />
                </>
              )}
            </div>
            <div className={css.summaryWrapper}>
              <div className={css.rowWrapper}>
                <div className={css.title}>
                  {intl.formatMessage({ id: 'AddOneTimeCreditsForm.subtotalTitle' })}
                </div>
                <div className={css.summaryInfo}>
                  <div className={css.pricePerMonth}>
                    {intl.formatMessage(
                      { id: 'AddOneTimeCreditsForm.price' },
                      {
                        span: (msg) => <span className={css.price}>{msg}</span>,
                        totalPrice: formatMoneyWithSpace(totalPrice),
                      }
                    )}
                  </div>
                  <div className={css.vatTitle}>
                    {intl.formatMessage({ id: 'AddOneTimeCreditsForm.vat' })}
                  </div>
                </div>
              </div>
              <div className={css.rowWrapper}>
                <div className={css.title}>
                  {intl.formatMessage({ id: 'AddOneTimeCreditsForm.totalPriceTitle' })}
                </div>
                <div className={css.summaryInfo}>
                  <div className={css.pricePerMonth}>
                    {intl.formatMessage(
                      { id: 'AddOneTimeCreditsForm.price' },
                      {
                        span: (msg) => <span className={css.price}>{msg}</span>,
                        totalPrice: formatMoneyWithSpace(totalPriceWithVAT),
                      }
                    )}
                  </div>
                </div>
              </div>
            </div>
            {buyOneTimeCreditsError && (
              <div className={css.error}>
                {intl.formatMessage({ id: 'AddOneTimeCreditsForm.buyOneTimeError' })}
              </div>
            )}
            <div className={css.submitDesktopWrapper}>
              <div className={css.conditionConfirm}>
                {intl.formatMessage({ id: 'AddOneTimeCreditsForm.conditionText' })}
              </div>
              <PrimaryButton
                type="submit"
                className={css.confirmBtn}
                inProgress={buyOneTimeCreditsInProgress}
                disabled={submitDisabled}
              >
                {intl.formatMessage({ id: 'MySubscriptionTab.confirmText' })}
              </PrimaryButton>
            </div>
            <BottomButtons
              leftBtnText={intl.formatMessage({ id: 'MySubscriptionTab.discard' })}
              rightBtnText={intl.formatMessage({ id: 'MySubscriptionTab.confirmText' })}
              rightBtnType="submit"
              rightBtnInProgress={buyOneTimeCreditsInProgress}
              rightBtnDisabled={submitDisabled}
            />
            <EditSubscriptionSuccessModal
              isOpen={isSuccessModalOpen}
              onClose={setSuccessModalOpen.off}
              creditsNumber={values.creditsNumber}
              totalPrice={totalPrice}
            />
          </Form>
        );
      }}
    />
  );
};

AddOneTimeCreditsForm.defaultProps = { selectedCreditAmount: 0 };

AddOneTimeCreditsForm.propTypes = { selectedCreditAmount: number };
export default AddOneTimeCreditsForm;
