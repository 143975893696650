import React from 'react';

const ImgIcon = () => (
  <svg width="71" height="71" viewBox="0 0 71 71" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="35.5" cy="35.5" r="35.5" fill="#F8F8F8" />
    <g opacity="0.3">
      <path
        d="M47.3325 26.2963V25.2444C47.3301 24.4083 46.9969 23.607 46.4056 23.0158C45.8143 22.4245 45.0131 22.0913 44.177 22.0889H23.1399C22.3038 22.0913 21.5026 22.4245 20.9113 23.0158C20.32 23.607 19.9868 24.4083 19.9844 25.2444V42.0741C19.9868 42.9102 20.32 43.7114 20.9113 44.3027C21.5026 44.8939 22.3038 45.2272 23.1399 45.2296H24.1918"
        stroke="black"
        strokeWidth="2"
        strokeLinejoin="round"
      />
      <path
        d="M48.5151 27.3481H28.2656C26.5958 27.3481 25.2422 28.7018 25.2422 30.3716V46.4136C25.2422 48.0834 26.5958 49.437 28.2656 49.437H48.5151C50.1849 49.437 51.5385 48.0834 51.5385 46.4136V30.3716C51.5385 28.7018 50.1849 27.3481 48.5151 27.3481Z"
        stroke="black"
        strokeWidth="2"
        strokeLinejoin="round"
      />
      <path
        d="M43.4486 35.381C44.5658 35.381 45.4715 34.4818 45.4715 33.3726C45.4715 32.2634 44.5658 31.3643 43.4486 31.3643C42.3314 31.3643 41.4258 32.2634 41.4258 33.3726C41.4258 34.4818 42.3314 35.381 43.4486 35.381Z"
        stroke="black"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M41.4243 43.3998L35.695 37.7205C35.3287 37.3586 34.8388 37.1491 34.3242 37.1344C33.8095 37.1196 33.3084 37.3006 32.922 37.6409L25.2422 44.4168"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M36.3672 49.437L44.1634 41.6973C44.5221 41.3435 44.9992 41.1351 45.5026 41.1124C46.0059 41.0897 46.4998 41.2543 46.889 41.5744L51.5382 45.4209"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);

export default ImgIcon;
