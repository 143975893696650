import {
  inviteUser,
  toggleInviteSuccess,
  toggleInviteError,
  toggleAlreadyHaveConnection,
} from '../../../ducks/TeamSchedule.duck';

const mapStateToProps = (state) => {
  const {
    TeamSchedule: { isInviteSuccessful, isInviteFailed, inviteLoading, alreadyHaveConnection },
  } = state;

  return {
    isInviteSuccessful,
    isInviteFailed,
    inviteLoading,
    alreadyHaveConnection,
  };
};

const mapDispatchToProps = (dispatch) => ({
  onInviteUser: (email) => dispatch(inviteUser(email)),
  toggleInviteSuccess: (payload) => dispatch(toggleInviteSuccess(payload)),
  toggleInviteError: (payload) => dispatch(toggleInviteError(payload)),
  toggleAlreadyHaveConnection: (payload) => dispatch(toggleAlreadyHaveConnection(payload)),
});

export { mapStateToProps, mapDispatchToProps };
