import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { Form as FinalForm } from 'react-final-form';
import classNames from 'classnames';
import * as validators from '../../util/validators';
import { Form, PrimaryButton, FieldTextInput, FieldCheckbox, NamedLink } from '../../components';

import css from './SignupForm.module.scss';

const KEY_CODE_ENTER = 13;

const SignupFormComponent = (props) => (
  <FinalForm
    {...props}
    render={(fieldRenderProps) => {
      const {
        rootClassName,
        className,
        formId,
        handleSubmit,
        inProgress,
        invalid,
        intl,
        onOpenTermsOfService,
        onBack,
        locationFrom,
        showCompanyFields,
      } = fieldRenderProps;
      // email
      const emailLabel = intl.formatMessage({
        id: 'SignupForm.emailLabel',
      });
      const emailRequiredMessage = intl.formatMessage({
        id: 'SignupForm.emailRequired',
      });
      const emailRequired = validators.required(emailRequiredMessage);
      const emailInvalidMessage = intl.formatMessage({
        id: 'SignupForm.emailInvalid',
      });
      const emailValid = validators.emailFormatValid(emailInvalidMessage);

      // password
      const passwordLabel = intl.formatMessage({
        id: 'SignupForm.passwordLabel',
      });
      const passwordRequiredMessage = intl.formatMessage({
        id: 'SignupForm.passwordRequired',
      });
      const passwordMinLengthMessage = intl.formatMessage(
        {
          id: 'SignupForm.passwordTooShort',
        },
        {
          minLength: validators.PASSWORD_MIN_LENGTH,
        }
      );
      const passwordMaxLengthMessage = intl.formatMessage(
        {
          id: 'SignupForm.passwordTooLong',
        },
        {
          maxLength: validators.PASSWORD_MAX_LENGTH,
        }
      );
      const passwordMinLength = validators.minLength(
        passwordMinLengthMessage,
        validators.PASSWORD_MIN_LENGTH
      );
      const passwordMaxLength = validators.maxLength(
        passwordMaxLengthMessage,
        validators.PASSWORD_MAX_LENGTH
      );
      const passwordRequired = validators.requiredStringNoTrim(passwordRequiredMessage);
      const passwordValidators = validators.composeValidators(
        passwordRequired,
        passwordMinLength,
        passwordMaxLength
      );

      // firstName
      const firstNameLabel = intl.formatMessage({
        id: 'SignupForm.firstNameLabel',
      });
      const firstNameRequiredMessage = intl.formatMessage({
        id: 'SignupForm.firstNameRequired',
      });
      const firstNameRequired = validators.required(firstNameRequiredMessage);

      // lastName
      const lastNameLabel = intl.formatMessage({
        id: 'SignupForm.lastNameLabel',
      });
      const lastNameRequiredMessage = intl.formatMessage({
        id: 'SignupForm.lastNameRequired',
      });
      const lastNameRequired = validators.required(lastNameRequiredMessage);

      // Phone field
      const phoneNumberLabel = intl.formatMessage({
        id: 'SignupForm.phoneNumberLabel',
      });
      const phoneRequiredMessage = intl.formatMessage({
        id: 'OrganizationDetailTab.phoneNumberRequired',
      });
      const phoneRequired = validators.required(phoneRequiredMessage);
      const phoneNumberInvalidMessage = intl.formatMessage({
        id: 'SignupForm.phoneNumberInvalidMessage',
      });
      const phoneNumberValid = validators.phoneFormatValid(phoneNumberInvalidMessage);

      // Organization name field
      const organizationNameLabel = intl.formatMessage({
        id: 'SignupForm.organizationNameLabel',
      });
      const organizationNameRequiredMessage = intl.formatMessage({
        id: 'OrganizationDetailTab.organizationNameRequired',
      });
      const organizationNameRequired = validators.required(organizationNameRequiredMessage);

      // Organization number
      const businessIdLabel = intl.formatMessage({
        id: 'SignupForm.businessIdLabel',
      });
      const businessIdRequiredMessage = intl.formatMessage({
        id: 'SignupForm.businessIdRequired',
      });
      const businessIdRequired = validators.required(businessIdRequiredMessage);
      const businessIdInvalidMessage = intl.formatMessage({
        id: 'SignupForm.businessIdInvalid',
      });
      const businessIdValid = validators.businessIdValue(businessIdInvalidMessage);

      const classes = classNames(rootClassName || css.root, className);
      const submitInProgress = inProgress;
      const submitDisabled = invalid || submitInProgress;

      const handleTermsKeyUp = (e) => {
        // Allow click action with keyboard like with normal links
        if (e.keyCode === KEY_CODE_ENTER) {
          onOpenTermsOfService();
        }
      };
      const termsLink = (
        <span
          className={css.termsLink}
          onClick={onOpenTermsOfService}
          role="button"
          tabIndex="0"
          onKeyUp={handleTermsKeyUp}
        >
          <FormattedMessage id="SignupForm.termsAndConditionsLinkText" />
        </span>
      );

      return (
        <Form className={classes} onSubmit={handleSubmit}>
          <div>
            <div className={css.name}>
              <FieldTextInput
                className={css.firstNameRoot}
                type="text"
                id={formId ? `${formId}.fname` : 'fname'}
                name="fname"
                autoComplete="given-name"
                label={firstNameLabel}
                placeholder={firstNameLabel}
                validate={firstNameRequired}
                inputRootClass={css.inputRootClass}
                labelClassName={css.label}
              />
              <FieldTextInput
                className={css.lastNameRoot}
                type="text"
                id={formId ? `${formId}.lname` : 'lname'}
                name="lname"
                autoComplete="family-name"
                label={lastNameLabel}
                placeholder={lastNameLabel}
                validate={lastNameRequired}
                inputRootClass={css.inputRootClass}
                labelClassName={css.label}
              />
            </div>
            <FieldTextInput
              type="email"
              id={formId ? `${formId}.email` : 'email'}
              name="email"
              autoComplete="email"
              label={emailLabel}
              placeholder={emailLabel}
              inputRootClass={css.inputRootClass}
              validate={validators.composeValidators(emailRequired, emailValid)}
              labelClassName={css.label}
            />

            {showCompanyFields && (
              <div>
                <FieldTextInput
                  name="phone"
                  id={formId ? `${formId}.phoneNumber` : 'phoneNumber'}
                  label={phoneNumberLabel}
                  placeholder={phoneNumberLabel}
                  type="telephone"
                  labelClassName={css.label}
                  validate={validators.composeValidators(phoneRequired, phoneNumberValid)}
                  inputRootClass={css.inputRootClass}
                />
                <FieldTextInput
                  name="organization"
                  id={formId ? `${formId}.organizationName` : 'organizationName'}
                  label={organizationNameLabel}
                  placeholder={organizationNameLabel}
                  type="text"
                  labelClassName={css.label}
                  validate={organizationNameRequired}
                  inputRootClass={css.inputRootClass}
                />
                <FieldTextInput
                  type="text"
                  id={formId ? `${formId}.businessId` : 'businessId'}
                  name="businessId"
                  autoComplete="businessId"
                  label={businessIdLabel}
                  placeholder={businessIdLabel}
                  inputRootClass={css.inputRootClass}
                  validate={validators.composeValidators(businessIdRequired, businessIdValid)}
                  labelClassName={css.label}
                />
              </div>
            )}

            <FieldTextInput
              className={css.password}
              type="password"
              id={formId ? `${formId}.password` : 'password'}
              name="password"
              autoComplete="new-password"
              label={passwordLabel}
              placeholder={passwordLabel}
              validate={passwordValidators}
              inputRootClass={css.inputRootClass}
              labelClassName={css.label}
            />
          </div>
          <div className={css.checkboxes}>
            <FieldCheckbox
              name="sendUpdateContent"
              id="sendUpdateContent"
              label={intl.formatMessage({
                id: 'SignupForm.newsletterSubscribeText',
              })}
              textClassName={css.smallLabel}
            />
            <FieldCheckbox
              name="agreeTermAndConditions"
              id="agreeTermAndConditions"
              label={
                <FormattedMessage id="SignUpForm.agreeTermAndConditions">
                  {(id) => (
                    <span>
                      {id}
                      <span>
                        <NamedLink name="TermsOfServicePage" className={css.termsLink}>
                          {termsLink}
                        </NamedLink>
                      </span>
                    </span>
                  )}
                </FormattedMessage>
              }
              textClassName={css.smallLabel}
              validate={(value) => (Boolean(value) ? undefined : ' ')}
            />
          </div>

          <div className={css.bottomWrapper}>
            <PrimaryButton
              type="submit"
              inProgress={submitInProgress}
              disabled={submitDisabled}
              className={css.submitBtn}
            >
              <FormattedMessage id="SignupForm.signUp" />
            </PrimaryButton>
            {onBack ? (
              <div onClick={onBack} className={css.backLink}>
                <FormattedMessage id="SignUpForm.backToLogin" />
              </div>
            ) : (
              <NamedLink name="LoginPage" state={{ from: locationFrom }} className={css.backLink}>
                <FormattedMessage id="SignUpForm.backToLogin" />
              </NamedLink>
            )}
          </div>
          <div
            className={css.honeyPot}
            aria-hidden="true"
            aria-label="Please leave the following three fields empty"
          >
            <label htmlFor="c_name">Name: </label>
            <input
              type="text"
              name="c_name"
              tabIndex="-1"
              value=""
              placeholder="Freddie"
              id="c_name"
            />

            <label htmlFor="c_email">Email: </label>
            <input
              type="email"
              name="c_email"
              tabIndex="-1"
              value=""
              placeholder="youremail@gmail.com"
              id="c_email"
            />

            <label htmlFor="c_comment">Comment: </label>
            <textarea
              name="c_comment"
              tabIndex="-1"
              placeholder="Please comment"
              id="c_comment"
            ></textarea>
          </div>
        </Form>
      );
    }}
  />
);

SignupFormComponent.defaultProps = { inProgress: false };

const { bool, func } = PropTypes;

SignupFormComponent.propTypes = {
  inProgress: bool,

  onOpenTermsOfService: func.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const SignupForm = compose(injectIntl)(SignupFormComponent);
SignupForm.displayName = 'SignupForm';

export default SignupForm;
