export default (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_32062_17308)">
      <path
        d="M17.5 2.5H19.0625C19.4062 2.5 19.6875 2.77969 19.6875 3.125C19.6875 3.47031 19.4062 3.75 19.0625 3.75H17.5V5.3125C17.5 5.65625 17.2188 5.9375 16.875 5.9375C16.5312 5.9375 16.25 5.65625 16.25 5.3125V3.75H14.6875C14.3438 3.75 14.0625 3.47031 14.0625 3.125C14.0625 2.77969 14.3438 2.5 14.6875 2.5H16.25V0.9375C16.25 0.592187 16.5312 0.3125 16.875 0.3125C17.2188 0.3125 17.5 0.592187 17.5 0.9375V2.5ZM10.8516 5.18359L19.3438 8.98828C19.7422 9.16797 20 9.5625 20 10C20 10.4375 19.7422 10.832 19.3438 11.0117L10.8516 14.8164C10.5508 14.9375 10.293 15 10 15C9.70703 15 9.41406 14.9375 9.14844 14.8164L0.654688 11.0117C0.256289 10.832 1.17188e-05 10.4375 1.17188e-05 10C1.17188e-05 9.5625 0.256289 9.16797 0.654688 8.98828L9.14844 5.18359C9.41406 5.0625 9.70703 5 10 5C10.293 5 10.5508 5.0625 10.8516 5.18359ZM1.45586 10L9.65625 13.6758C9.73047 13.7266 9.84766 13.75 10 13.75C10.1172 13.75 10.2344 13.7266 10.3438 13.6758L18.543 10L10.3438 6.32422C10.2344 6.27344 10.1172 6.25 10 6.25C9.84766 6.25 9.73047 6.27344 9.65625 6.32422L1.45586 10ZM1.45586 15L9.65625 18.6758C9.73047 18.7266 9.84766 18.75 10 18.75C10.1172 18.75 10.2344 18.7266 10.3438 18.6758L18.543 15L17.2461 14.418C16.9297 14.2773 16.7891 13.9062 16.9297 13.5898C17.0703 13.2773 17.4414 13.1367 17.7539 13.2773L19.3438 13.9883C19.7422 14.168 20 14.5625 20 15C20 15.4375 19.7422 15.832 19.3438 16.0117L10.8516 19.8164C10.5508 19.9375 10.293 20 10 20C9.70703 20 9.41406 19.9375 9.14844 19.8164L0.654688 16.0117C0.256289 15.832 0 15.4375 0 15C0 14.5625 0.256289 14.168 0.654688 13.9883L2.24453 13.2773C2.55938 13.1367 2.9293 13.2773 3.07031 13.5898C3.21133 13.9062 3.0707 14.2773 2.75547 14.418L1.45586 15Z"
        fill="#9AB7DD"
      />
    </g>
    <defs>
      <clipPath id="clip0_32062_17308">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
