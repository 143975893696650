import React, { useState, useRef, createRef } from 'react';
import { bool, func, shape, string } from 'prop-types';
import classNames from 'classnames';
import { Form as FinalForm } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import { FormattedMessage } from '../../util/reactIntl';
import { findOptionsForSelectFilter } from '../../util/search';
import { propTypes, FIXED_OFFICE_CATEGORY } from '../../util/types';
import config from '../../config';
import {
  Form,
  FixedBottomButtons,
  AmenityDetailModal,
  AddonDetailModal,
  SaveAndExitButton,
} from '../../components';

import css from './EditListingAmenitiesForm.module.scss';
import CustomAmenitiesCheckboxField from './CustomAmenitiesCheckboxField';
import { addonsList } from '../../marketplace-custom-config';
import { useBoolean } from '../../util/hooks';
import { types as sdkTypes } from '../../util/sdkLoader';
import { addonsOptionList, amenitiesOptionList, equiqmentOptionList } from './amenitiesConfig';
import pickBy from 'lodash/pickBy';
import { v4 as uuidv4 } from 'uuid';

const { Money } = sdkTypes;

const EditListingAmenitiesFormComponent = (props) => {
  const [isAmenityDetailModalOpen, setAmenityDetailModalOpen] = useBoolean();
  const [amenityOptionClicked, setAmenityOptionClicked] = useState('');
  const [isAddonsModalOpen, setAddonsModalOpen] = useBoolean();
  const [addonOptionClicked, setAddonOptionClicked] = useState('');
  const [addonSelectedOptions, setAddonSelectedOptions] = useState([]);
  const selectedAddonRef = useRef({});
  if (Object.keys(selectedAddonRef.current).length !== addonSelectedOptions.length) {
    selectedAddonRef.current = addonSelectedOptions.reduce((refCurrent, option) => {
      return {
        ...refCurrent,
        [option]: refCurrent[option] || createRef(),
      };
    }, {});
  }

  return (
    <FinalForm
      {...props}
      mutators={{ ...arrayMutators }}
      render={(formRenderProps) => {
        const {
          disabled,
          ready,
          rootClassName,
          className,
          name,
          handleSubmit,
          pristine,
          updated,
          updateInProgress,
          fetchErrors,
          filterConfig,
          intl,
          values,
          form,
          onHandleSaveAndExit,
          category,
          isPublished,
        } = formRenderProps;

        const classes = classNames(rootClassName || css.root, className);
        const submitReady = (updated && pristine) || ready;
        const submitInProgress = updateInProgress;
        const submitDisabled = disabled || submitInProgress;
        const { additionalServices = [], ameneties = [], offer = [] } = values;
        const addonsInitialValues = additionalServices.reduce(
          (addons, addon) => [...addons, addon.name],
          []
        );

        const { updateListingError, showListingsError } = fetchErrors || {};
        const errorMessage = updateListingError ? (
          <p className={css.error}>
            <FormattedMessage id="EditListingFeaturesForm.updateFailed" />
          </p>
        ) : null;

        const errorMessageShowListing = showListingsError ? (
          <p className={css.error}>
            <FormattedMessage id="EditListingFeaturesForm.showListingFailed" />
          </p>
        ) : null;

        const options = findOptionsForSelectFilter('ameneties', filterConfig);

        // Props for "offer" select field
        const offerKey = 'offer';
        const offerOptions = findOptionsForSelectFilter(offerKey, filterConfig);

        const addonsKey = 'addons';

        const checkboxAmenities = options.filter((option) =>
          amenitiesOptionList[category].includes(option.key)
        );

        const checkboxEquiments = offerOptions.filter((option) =>
          equiqmentOptionList[category].includes(option.key)
        );

        const checkboxAddons = addonsList.filter((option) =>
          addonsOptionList[category].includes(option.id)
        );

        const rightBtnText = isPublished
          ? intl.formatMessage({ id: 'EditListing.saveButton' })
          : intl.formatMessage({ id: 'EditListing.submitButton' });

        const handleAmenityEditBtnClick = (option) => {
          setAmenityOptionClicked(option);
          setAmenityDetailModalOpen.on();
        };
        const amenityIsClicked = options.find((option) => option.key === amenityOptionClicked);
        const amenityModalLabel =
          amenityIsClicked &&
          intl.formatMessage(
            { id: 'EditListingAmenitiesForm.amenityModalLabel' },
            { amenity: intl.formatMessage({ id: amenityIsClicked.labelId }) }
          );

        const handleAddonEditBtnClick = (option) => {
          setAddonOptionClicked(option);
          setAddonsModalOpen.on();
        };

        const addonIsClicked = addonsList.find((addon) => addon.id === addonOptionClicked);
        const addonModalLabel =
          addonIsClicked &&
          intl.formatMessage(
            { id: 'EditListingAmenitiesForm.addonModalLabel' },
            { addon: intl.formatMessage({ id: addonIsClicked.labelId }) }
          );

        const handleAmenityInfoModalSubmit = () => {
          const fieldName = `${name}Info.${amenityOptionClicked}`;
          form.change(fieldName, {
            ...values.amenityTime?.[amenityOptionClicked],
            description: values.detailInfo?.[amenityOptionClicked],
          });
          setAmenityDetailModalOpen.off();
        };

        const handleAddonModalClose = () => {
          const { addonsInfo = {} } = values;
          const { price, vatPercentage } = addonsInfo?.[addonOptionClicked] || {};
          if (!price || !vatPercentage) {
            selectedAddonRef.current[addonOptionClicked].current.click();
          }
          setAddonsModalOpen.off();
        };

        const handleAddonModalSubmit = () => {
          const { addonsInfo = {}, additionalServices = [] } = values;
          const { price, vatPercentage } = addonsInfo?.[addonOptionClicked];
          if (!price || !vatPercentage) {
            form.batch(() => {
              form.blur(`addonsInfo[${addonOptionClicked}].price`);
              form.blur(`addonsInfo[${addonOptionClicked}].vatPercentage`);
            });
          } else {
            const existedAddonIndex = additionalServices.findIndex(
              (item) => item.name === addonOptionClicked
            );
            if (existedAddonIndex !== -1) {
              additionalServices[existedAddonIndex] = {
                ...additionalServices[existedAddonIndex],
                ...addonsInfo[addonOptionClicked],
                price: new Money(addonsInfo[addonOptionClicked].price * 100, config.currency),
                multiplySeats:
                  typeof addonIsClicked.multiplySeats !== 'undefined'
                    ? addonIsClicked.multiplySeats
                    : true,
              };
              form.change('additionalServices', additionalServices);
            } else {
              form.change('additionalServices', [
                ...additionalServices,
                {
                  name: addonOptionClicked,
                  ...addonsInfo[addonOptionClicked],
                  price: new Money(addonsInfo[addonOptionClicked].price * 100, config.currency),
                  multiplySeats:
                    typeof addonIsClicked.multiplySeats !== 'undefined'
                      ? addonIsClicked.multiplySeats
                      : true,
                },
              ]);
            }
            setAddonsModalOpen.off();
          }
        };

        const onSaveAndExitHandler = () => {
          const {
            ameneties = [],
            amenetiesInfo = {},
            offer = [],
            environment = [],
            noiselevel = '',
            vibe = [],
            perfectFor = [],
            additionalServices = [],
            addons = [],
          } = values;

          const ensuredAdditionalServices = additionalServices
            .map((s) => ({
              ...s,
              price:
                s.price instanceof Money
                  ? { amount: s.price.amount, currency: s.price.currency }
                  : s.price,
              serviceId: s.id ? s.id : uuidv4(),
            }))
            .filter((s) => addons.includes(s.name));

          const publicData = pickBy({
            ameneties,
            amenetiesInfo,
            offer,
            environment,
            noiselevel,
            vibe,
            perfectFor,
            additionalServices: ensuredAdditionalServices,
          });

          onHandleSaveAndExit({ publicData });
        };
        return (
          <Form className={classes} onSubmit={handleSubmit}>
            <SaveAndExitButton onClick={onSaveAndExitHandler} />
            {errorMessage}
            {errorMessageShowListing}

            <CustomAmenitiesCheckboxField
              id={name}
              name={name}
              selectedLabel={<FormattedMessage id="EditListingFeaturesPanel.amenitiesTitle" />}
              checkboxOptions={checkboxAmenities}
              initialSelectedOptions={ameneties}
              handleEditBtnClick={handleAmenityEditBtnClick}
              showEditBtn
              form={form}
            />

            <CustomAmenitiesCheckboxField
              id={offerKey}
              name={offerKey}
              selectedLabel={<FormattedMessage id="EditListingFeaturesPanel.offerTitle" />}
              checkboxOptions={checkboxEquiments}
              initialSelectedOptions={offer}
              form={form}
            />

            {category !== FIXED_OFFICE_CATEGORY && (
              <CustomAmenitiesCheckboxField
                id={addonsKey}
                name={addonsKey}
                selectedLabel={<FormattedMessage id="EditListingFeaturesPanel.addonsTitle" />}
                checkboxOptions={checkboxAddons}
                initialSelectedOptions={addonsInitialValues}
                handleEditBtnClick={handleAddonEditBtnClick}
                setAddonOptionClicked={setAddonOptionClicked}
                addonSelectedOptions={addonSelectedOptions}
                setAddonSelectedOptions={setAddonSelectedOptions}
                showEditBtn
                form={form}
                selectedAddonRef={selectedAddonRef}
              />
            )}
            <AmenityDetailModal
              isOpen={isAmenityDetailModalOpen}
              onClose={setAmenityDetailModalOpen.off}
              label={amenityModalLabel}
              handleClick={handleAmenityInfoModalSubmit}
              values={values}
              amenityName={amenityOptionClicked}
            />

            {addonOptionClicked && (
              <AddonDetailModal
                isOpen={isAddonsModalOpen}
                onClose={handleAddonModalClose}
                label={addonModalLabel}
                handleClick={handleAddonModalSubmit}
                values={values}
                addonName={addonOptionClicked}
              />
            )}
            <FixedBottomButtons
              leftBtnText={intl.formatMessage({ id: 'EditListing.backButton' })}
              rightBtnText={rightBtnText}
              rightBtnType="submit"
              rightBtnReady={submitReady}
              rightBtnInProgress={submitInProgress}
              rightBtnDisabled={submitDisabled}
            />
          </Form>
        );
      }}
    />
  );
};

EditListingAmenitiesFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  fetchErrors: null,
  filterConfig: config.custom.filters,
  category: 'none',
};

EditListingAmenitiesFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  name: string.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
  filterConfig: propTypes.filterConfig,
  category: string.isRequired,
};

const EditListingAmenitiesForm = EditListingAmenitiesFormComponent;

export default EditListingAmenitiesForm;
