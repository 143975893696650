import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { IconReviewStar } from '../../components';
import { REVIEW_RATINGS } from '../../util/types';

const formattedReviewRatings = [];

REVIEW_RATINGS.forEach((i) => formattedReviewRatings.push(i, `${i}`));

const ReviewRating = (props) => {
  const { className, rootClassName, reviewStarClassName, rating, filledColor } = props;
  const classes = classNames(rootClassName, className);
  return (
    <span className={classes}>
      {REVIEW_RATINGS.map((star) => (
        <IconReviewStar
          key={`star-${star}`}
          className={reviewStarClassName}
          filledColor={filledColor}
          isFilled={star <= rating}
        />
      ))}
    </span>
  );
};

ReviewRating.defaultProps = {
  rootClassName: null,
  className: null,
  reviewStarClassName: null,
};

const { string, oneOf } = PropTypes;

ReviewRating.propTypes = {
  rating: oneOf(formattedReviewRatings).isRequired,
  reviewStartClassName: string,
  rootClassName: string,
  className: string,
};

export default ReviewRating;
