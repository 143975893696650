import React, { Component } from 'react';
import { bool, func, object, node, number, shape, string } from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { withRouter } from 'react-router-dom';

import { ModalInMobile } from '../../components';
import { ButtonNew } from '../../components_new';
import css from './SearchFiltersMobile.module.scss';

class SearchFiltersMobileComponent extends Component {
  constructor(props) {
    super(props);
    this.onResetAll = this.onResetAll.bind(this);
  }

  // Reset all filter query parameters
  onResetAll(e) {
    this.props.resetAll(e);

    // blur event target if event is passed
    if (e && e.currentTarget) {
      e.currentTarget.blur();
    }
  }

  render() {
    const {
      rootClassName,
      className,
      children,
      showAsModalMaxWidth,
      onManageDisableScrolling,
      intl,
      cancelFilters,
      closeFilters,
      isFiltersOpenOnMobile,
      showListingsLabel,
    } = this.props;

    const classes = classNames(rootClassName || css.root, className);

    const filtersHeading = intl.formatMessage({ id: 'SearchFiltersMobile.heading' });

    return (
      <div className={classes}>
        <ModalInMobile
          id="SearchFiltersMobile.filters"
          isModalOpenOnMobile={isFiltersOpenOnMobile}
          onClose={cancelFilters}
          showAsModalMaxWidth={showAsModalMaxWidth}
          onManageDisableScrolling={onManageDisableScrolling}
          containerClassName={css.modalContainer}
          closeButtonMessage=" "
        >
          <div className={css.modalHeadingWrapper}>
            <span className={css.modalHeading}>{filtersHeading}</span>
            <div className={css.mobileFilterClose} onClick={closeFilters}>
              <svg
                width="13"
                height="13"
                viewBox="0 0 13 13"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <line
                  x1="12.0288"
                  y1="0.972461"
                  x2="0.919362"
                  y2="12.0819"
                  stroke="#222222"
                  strokeWidth="1.3"
                  strokeLinecap="round"
                />
                <line
                  x1="0.65"
                  y1="-0.65"
                  x2="16.3611"
                  y2="-0.65"
                  transform="matrix(-0.707109 -0.707105 0.707108 -0.707105 13 12.082)"
                  stroke="#222222"
                  strokeWidth="1.3"
                  strokeLinecap="round"
                />
              </svg>
            </div>
          </div>

          {isFiltersOpenOnMobile ? <div className={css.filtersWrapper}>{children}</div> : null}

          <div className={css.showListingsContainer}>
            <ButtonNew
              className={css.resetAllButton}
              type="inline"
              size="s"
              textLight
              onClick={(e) => this.onResetAll(e)}
            >
              <FormattedMessage id={'SearchFiltersMobile.resetAll'} />
            </ButtonNew>
            <ButtonNew
              className={css.showListingsButton}
              type="green"
              size="l"
              onClick={closeFilters}
            >
              {showListingsLabel}
            </ButtonNew>
          </div>
        </ModalInMobile>
      </div>
    );
  }
}

SearchFiltersMobileComponent.defaultProps = {
  rootClassName: null,
  className: null,
  sortByComponent: null,
  resultsCount: null,
  searchInProgress: false,
  selectedFiltersCount: 0,
};

SearchFiltersMobileComponent.propTypes = {
  rootClassName: string,
  className: string,
  urlQueryParams: object.isRequired,
  sortByComponent: node,
  listingsAreLoaded: bool.isRequired,
  resultsCount: number,
  searchInProgress: bool,
  showAsModalMaxWidth: number.isRequired,
  onManageDisableScrolling: func.isRequired,
  resetAll: func.isRequired,
  selectedFiltersCount: number,

  // from injectIntl
  intl: intlShape.isRequired,

  // from withRouter
  history: shape({
    push: func.isRequired,
  }).isRequired,
};

const SearchFiltersMobile = injectIntl(withRouter(SearchFiltersMobileComponent));

export default SearchFiltersMobile;
