import React, { useEffect, useState } from 'react';
import { FormattedMessage } from '../../../util/reactIntl';
import css from './Aboutus.module.scss';
import image1 from '../about_respaces_1.svg';
import image4 from '../about_respaces_4.svg';
import { MediaAndTextSection } from '../../../components_new';
import config from '../../../config';
import { Offers } from '../Offers/Offers';
import { Press } from '../Press/Press';
import { InNews } from '../InNews/InNews';
import { OurCustomers } from '../../../components_new/OurCustomers/OurCustomers';
import CustomerReviewsSlider from '../../../components/CustomerReviewsSlider/CustomerReviewsSlider';
export const AboutUs = ({ intl }) => {
  // GET OPEN JOBS - NOT USED RIGHT NOW

  // const [availableJobs, setAvailableJobs] = useState([]);

  // useEffect(() => {
  //   axios
  //     // TODO - maybe add a sort to the jobs displayed
  //     .get('https://cms.respaces.co/jobs')
  //     .then((response) => {
  //       setAvailableJobs(response.data);
  //     })
  //     .catch((err) => console.error(err));
  // }, []);

  return (
    <>
      <MediaAndTextSection
        title={{
          content: intl.formatMessage({ id: 'About.Title' }),
          showMobile: true,
          isH1: true,
        }}
        subtitle={{
          content: intl.formatMessage({ id: 'About.subTitle' }),
        }}
        features={{
          list: [
            {
              title: '',
              text: intl.formatMessage({ id: 'About.description1' }),
              showMobile: false,
            },
            {
              title: '',
              text: intl.formatMessage({ id: 'About.description2' }),
              showMobile: true,
            },
          ],
          showMobile: true,
        }}
        textAlign={'left'}
        textAlignMobile={'top'}
        media={{ content: `${config.canonicalRootURL}${image1}`, showMobile: true }}
        spacing={'m'}
      />
      <Offers />
      <MediaAndTextSection
        title={{
          content: intl.formatMessage({ id: 'About.titleTeam' }),
          showMobile: true,
        }}
        features={{
          list: [
            {
              title: '',
              text: intl.formatMessage({ id: 'About.descriptionTeam1' }),
              showMobile: true,
            },
            {
              title: '',
              text: intl.formatMessage({ id: 'About.descriptionTeam2' }),
              showMobile: true,
            },
          ],
          showMobile: true,
        }}
        textAlign={'right'}
        textAlignMobile={'bottom'}
        media={{ content: `${config.canonicalRootURL}${image4}`, showMobile: true }}
        spacing={'m'}
      />
      <OurCustomers />
      <CustomerReviewsSlider />
      <InNews />
      <Press />
    </>
  );
};
