import pick from 'lodash/pick';
import StatusModel from '../../models/StatusModel';
import {
  FIXED_OFFICE_CATEGORY,
  COWORKING_CATEGORY,
  MEETINGROOMS_CATEGORY,
  STUDIO_CATEGORY,
  PRIVATE_OFFICE_CATEGORY,
} from '../../util/types';
import { createSlug } from '../../util/urlHelpers';
import { fetchCompanyUser, fetchCurrentUser } from '../../ducks/user.duck';
import get from 'lodash/get';
import { isCompanyUser as checkCompanyUser } from '../../util/b2bHelper';
import { setInitialLineItemsError } from '../../containers/ListingPage/ListingPage.duck';
import axios from 'axios';

// ================ Action types ================ //
export const SET_INITIAL_VALUES = 'app/LocationPage/SET_INITIAL_VALUES';
export const SHOW_LOCATION_REQUEST = 'app/LocationPage/SHOW_LOCATION_REQUEST';
export const SET_LOCATION_INFO = 'app/LocationPage/SET_LOCATION_INFO';
export const SET_LOCATION_INFO_ERROR = 'app/LocationPage/SET_LOCATION_INFO_ERROR';
export const SET_LISTING_SEARCHED_FOR = 'app/LocationPage/SET_LISTING_SEARCHED_FOR';
export const SET_LOCATION_LISTINGS = 'app/LocationPage/SET_LOCATION_LISTINGS';
export const SET_LOCATION_LISTINGS_ERROR = 'app/LocationPage/SET_LOCATION_LISTINGS_ERROR';

// ================ Reducer ================ //

const initialState = {
  id: null,
  locationInfo: {},
  listingSearchedFor: {},
  locationInfoError: null,
  locationListings: [],
  locationListingsError: null,
};

const locationPageReducer = (state = initialState, action = {}) => {
  const { type, payload } = action;

  switch (type) {
    case SET_INITIAL_VALUES:
      return { ...initialState, ...payload };

    case SHOW_LOCATION_REQUEST:
      return { ...state, id: payload.id, locationInfoError: null };

    case SET_LOCATION_INFO:
      return { ...state, locationInfo: { ...payload } };

    case SET_LOCATION_INFO_ERROR:
      return { ...state, locationInfoError: { ...payload } };

    case SET_LISTING_SEARCHED_FOR:
      return { ...state, listingSearchedFor: { ...payload } };

    case SET_LOCATION_LISTINGS:
      return { ...state, locationListings: { ...payload } };

    case SET_LOCATION_LISTINGS_ERROR:
      return { ...state, locationListingsError: { ...payload } };

    default:
      return state;
  }
};

export default locationPageReducer;

// ================ Action creators ================ //

export const setInitialValues = (initialValues) => ({
  type: SET_INITIAL_VALUES,
  payload: pick(initialValues, Object.keys(initialState)),
});

export const showLocationRequest = (id) => ({
  type: SHOW_LOCATION_REQUEST,
  payload: { id },
});

export const setLocationInfoAction = (locationInfo) => ({
  type: SET_LOCATION_INFO,
  payload: locationInfo,
});

export const setLocationInfoErrorAction = (error) => ({
  type: SET_LOCATION_INFO_ERROR,
  payload: error,
});

export const setListingSearchedForAction = (listingSearchedFor) => ({
  type: SET_LISTING_SEARCHED_FOR,
  payload: listingSearchedFor,
});

export const setLocationListingsAction = (locationListings) => ({
  type: SET_LOCATION_LISTINGS,
  payload: locationListings,
});

export const setLocationListingsErrorAction = (error) => ({
  type: SET_LOCATION_LISTINGS_ERROR,
  payload: error,
});

// ================ Thunks ================ //

export const findListingSearchedFor = (location, search) => async (dispatch) => {
  // Get the search params from search query
  const searchParams = new URLSearchParams(search);
  const category = searchParams.get('category');
  const minSeats = searchParams.get('minSeats');
  const maxSeats = searchParams.get('maxSeats');

  // Find the listing that matches the search query. Will only set a listing as selected if there is only one match

  // If there is a search query, find the listing that matches the search query
  if (searchParams) {
    // Make a new list of all listings where we can remove listings until we have the best result
    let matches = location?.listings_new_rel ?? [];
    // First match on category
    if (category) {
      matches = matches.filter((listing) => {
        return listing.category === category;
      });
    }
    // Second - match on number of seats
    if (minSeats) {
      matches = matches.filter((listing) => {
        return listing.max_seats >= minSeats;
      });
    }
    if (maxSeats) {
      // the maxSeats is a range from min to max if the filters are used and not the fixed office search form
      matches = matches.filter((listing) => {
        return listing.max_seats <= parseInt(maxSeats);
      });
    }
    // Keep adding filters here to get the best match

    // If there is only one match, set it as selected
    if (matches.length === 1) {
      const id = matches[0].sharetribe_id;
      const slug = createSlug(matches[0].title);
      dispatch(setListingSearchedForAction({ id, slug }));
    }
  }
};

export const requestListings = (location) => (dispatch, getState, sdk) => {
  //get all listing id:s from location
  const listingIds = location?.listings_new_rel?.map((l) => l.sharetribe_id);

  if (!listingIds || listingIds.length === 0) {
    return;
  }

  const params = {
    ids: listingIds,
    include: ['images'],
    'fields.image': [
      // Listing page
      'variants.square-small',
      'variants.landscape-crop',
      'variants.landscape-crop2x',
      'variants.landscape-crop4x',
      'variants.landscape-crop6x',

      // Image carousel
      'variants.scaled-small',
      'variants.scaled-medium',
      'variants.scaled-large',
      'variants.scaled-xlarge',
    ],
  };

  const show = sdk.listings.query(params);
  return show
    .then((data) => {
      // If there is only one listing, set it as selected
      if (data.data.length === 1) {
        const id = data.data[0].id;
        const slug = createSlug(data.data[0].title);
        dispatch(setListingSearchedForAction({ id, slug }));
      } else if (data.data.length > 1) {
        //if there are more than one listing, sort them in the same order as in the location
        data.data.sort((a, b) => {
          return (
            location.listings_new_rel.findIndex((l) => l.sharetribe_id === a.id.uuid) -
            location.listings_new_rel.findIndex((l) => l.sharetribe_id === b.id.uuid)
          );
        });
      }
      dispatch(setLocationListingsAction(data.data));

      if (location?.listings_new_rel && data?.data) {
        //add images from locationListings.data to the right listing on locationInfo.listings_new_rel
        location.listings_new_rel.map((l) => {
          const listingSharetribe = data.data.data.find((li) => li.id.uuid === l.sharetribe_id);
          l.images = [];
          if (listingSharetribe && listingSharetribe?.relationships?.images?.data) {
            listingSharetribe?.relationships?.images?.data.map((i) => {
              const image = data?.data?.included?.find((inc) => inc.id.uuid === i.id.uuid);
              if (image) {
                l.images.push(image);
              }
            });
          }
        });
        dispatch(setLocationInfoAction(location));
      }

      return data.data;
    })
    .catch((e) => {
      console.log('requestListings error', e);
      dispatch(setLocationListingsErrorAction(e));
      return e;
    });
};

export const getLocation =
  ({ ownerId, locationId, locationStatuses, listingStatuses, search }) =>
  async (dispatch, getState, sdk) => {
    dispatch(showLocationRequest(locationId));

    const { REACT_APP_HAZURA_SECRET, REACT_APP_HAZURA_DB_URI: hasuraURI } = process.env;

    const headers = {
      'Content-Type': 'application/json',
      'x-hasura-admin-secret': REACT_APP_HAZURA_SECRET,
    };

    const key = !!ownerId ? 'owner_id' : 'id';

    // Define the GraphQL query
    const locationQuery = `
    query GetLocationWithListings($_${key}: uuid, $_listing_status: String, $_statuses: [String!]) {
      locations(where: {
        ${key}: { _eq: $_${key} },
        status: { _in: $_statuses }
      }) {
        listings_new_rel (where: {status: { _eq: $_listing_status }}) {
          sharetribe_id
          title
          amenities
          amenities_info
          description_en
          description_sv
          max_seats
          min_seats
          offer
          price
          vat
          require_showing
          category
          fixed_office_type
          booking_type
          email
          email_2
          request_price
          drawings
          calculated_area
          calculated_seats
          contract_length_per_unit
          contract_length_unit
          start_date
          payment_period
          allow_vat_exempt_companies
          contract_type
          notice_period_per_unit
          notice_period_unit
          info_descriptions
          officeAPI
          additional_services
          area_min
          area_max
          no_req_for_contract_length
        }
        id
        description_en
        description_sv
        address
        name
        opening_hours
        owner_id
        status
        images
        updated_at
        geolocation
        host
      }
    }
  `;

    const variables = {
      ...(!!ownerId && { _owner_id: ownerId }),
      ...(!!locationId && { _id: locationId }),
      _statuses: locationStatuses,
      _listing_status: 'published',
    };

    try {
      const response = await axios.post(
        hasuraURI,
        {
          query: locationQuery,
          variables,
        },
        {
          headers,
        }
      );

      const responseBody = response.data;

      if ('data' in responseBody && 'locations' in responseBody.data) {
        const location = responseBody.data.locations[0];
        // sort included listings by category and maxSeats
        if (location && location?.listings_new_rel && location?.listings_new_rel.length >= 1) {
          const SCORES = {
            [COWORKING_CATEGORY]: 1,
            [MEETINGROOMS_CATEGORY]: 2,
            [STUDIO_CATEGORY]: 3,
            [PRIVATE_OFFICE_CATEGORY]: 4,
            [FIXED_OFFICE_CATEGORY]: 5,
          };
          location.listings_new_rel.sort((a, b) => {
            return SCORES[a.category] - SCORES[b.category] || a.max_seats - b.max_seats;
          });
          // Add listing categories as Keywords to the Location
          location.keywords = location?.listings_new_rel?.reduce((acc, curr) => {
            const translationId = `MarketplaceConfig.filters.category.${curr.category}`;

            !acc.includes(translationId) && acc.push(translationId);

            return acc;
          }, []);
        }

        await dispatch(findListingSearchedFor(location, search));
        const listings = await dispatch(requestListings(location));
        return listings;
      } else {
        dispatch(setLocationInfoErrorAction('Invalid response from Hazura'));
        return 'Invalid response from Hazura';
      }
    } catch (error) {
      console.error(error);
      dispatch(setLocationInfoErrorAction(error));
      return error;
    }
  };

export const loadData = (params, search) => async (dispatch, getState) => {
  return Promise.all([
    dispatch(
      getLocation({
        locationId: params.id,
        locationStatuses: [StatusModel.PUBLISHED.id],
        listingStatuses: [StatusModel.PUBLISHED.id],
        search,
      })
    ),
    dispatch(fetchCurrentUser()),
    dispatch(setInitialLineItemsError()),
  ]).then((responses) => {
    if (typeof window !== 'undefined') {
      const currentUser = getState().user.currentUser;
      const companyAccountId = get(
        currentUser,
        'attributes.profile.protectedData.companyAccountId',
        null
      );
      const isCompanyUser = checkCompanyUser(currentUser);
      if (companyAccountId || isCompanyUser) {
        dispatch(fetchCompanyUser());
      }
    }
    return responses;
  });
};
