import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import { injectIntl } from 'react-intl';

import {
  ImageCarousel,
  ImageSlider,
  Modal,
  ResponsiveImage,
  ResponsiveLocationImage,
} from '../../components';

import { inBrowser } from '../../util/device';

import ActionBar from './ui/ActionBar';

import css from './ImageGridWithSlider.module.scss';

/* Taken from ImageSlider.module.scss + 1px */
const MAX_WIDTH_FOR_IMAGE_SLIDER = 528;

const ImageGridWithSlider = ({
  title,
  state,
  isOwn,
  editParams,
  images,
  onManageDisableScrolling,
  intl,
  isLocation = false,
}) => {
  const [showImageSlider, setShowImageSlider] = useState(false);
  const [isOpen, setOpen] = useState(false);

  useEffect(() => {
    if (inBrowser()) {
      isOpen
        ? document?.body?.classList.add('blockScroll')
        : document?.body?.classList.remove('blockScroll');
    }
  }, [isOpen]);

  useEffect(() => {
    const updateShowImageSlider = () =>
      setShowImageSlider(window.innerWidth < MAX_WIDTH_FOR_IMAGE_SLIDER);

    if (inBrowser()) {
      updateShowImageSlider();
      window.addEventListener('resize', updateShowImageSlider, false);
    }

    return () => {
      window.removeEventListener('resize', updateShowImageSlider);
    };
  }, []);

  const hasImages = images.length > 0;
  const firstImage = images[0] || null;
  const restImages = hasImages ? images.slice(1, 5) : [];
  restImages.length === 3 && restImages.pop();

  const imagesPrepared = [firstImage, ...restImages];

  return (
    <section className={css.sectionImages}>
      <ActionBar state={state} isOwn={isOwn} editParams={editParams} />
      <div className={css.imagesContainer}>
        {showImageSlider && isLocation ? (
          <ImageSlider data={{ images: imagesPrepared }} category="location_page" /> // show for location mobile
        ) : showImageSlider ? (
          <ImageSlider data={{ images: imagesPrepared }} /> // show for listing mobile
        ) : isLocation ? ( // show for location desktop
          <>
            <ResponsiveLocationImage
              rootClassName={classNames(
                css.mainImage,
                restImages.length === 4 ? css.five : restImages.length === 2 ? css.three : css.solo
              )}
              image={firstImage}
              variant={'large'}
              alt={title}
            />
            {!!restImages.length &&
              restImages.map((image, i) => (
                <ResponsiveLocationImage
                  className={classNames(
                    css.gridImage,
                    restImages.length === 4 ? css.five : css.three
                  )}
                  image={image}
                  key={`responsive_image_${i}`}
                  variant={'medium'}
                  alt={title}
                />
              ))}
          </>
        ) : (
          // show for listing desktop
          <>
            <ResponsiveImage
              rootClassName={classNames(
                css.mainImage,
                restImages.length === 4 ? css.five : restImages.length === 2 ? css.three : css.solo
              )}
              image={firstImage}
              variants={['landscape-crop', 'landscape-crop2x']}
              alt={title}
            />
            {!!restImages.length &&
              restImages.map((image, i) => (
                <ResponsiveImage
                  className={classNames(
                    css.gridImage,
                    restImages.length === 4 ? css.five : css.three
                  )}
                  image={image}
                  key={`responsive_image_${i}`}
                  variants={['square-small', 'scaled-small', 'square-small2x']}
                  alt={title}
                />
              ))}
          </>
        )}
      </div>
      {!showImageSlider && hasImages && (
        <button className={css.viewPhotos} onClick={() => setOpen(true)}>
          {intl.formatMessage({ id: 'ListingPage.viewImagesButton' }, { count: images.length })}
        </button>
      )}
      <Modal
        id="LocationPage.imageCarousel"
        scrollLayerClassName={css.carouselModalScrollLayer}
        containerClassName={css.carouselModalContainer}
        isOpen={isOpen}
        onClose={() => setOpen(false)}
        onManageDisableScrolling={onManageDisableScrolling}
        usePortal
        lightCloseButton
      >
        <ImageCarousel images={images} isLocation={isLocation} />
      </Modal>
    </section>
  );
};

export default injectIntl(ImageGridWithSlider);
