import React from 'react';
import { MediaAndTextSection } from '../../components_new';
import { BUTTON_SIZES, BUTTON_TYPES, LINK_COLORS } from '../../components_new/ButtonNew/ButtonNew';
import mainImgSV from './assets/product_SE.png';
import mainImgEN from './assets/product_EN.png';
import css from './PartnerProffessional.module.scss';

const SectionHero = ({ intl, isMobile, onHeroButtonClick }) => {
  return (
    <section className={css.sectionHero}>
      <MediaAndTextSection
        title={{
          content: intl.formatMessage({ id: 'PartnerProffessional.SectionHero.title' }),
          showMobile: true,
          isH1: true,
        }}
        bullets={{
          list: [
            intl.formatMessage({ id: 'PartnerProffessional.SectionHero.bullet1' }),
            intl.formatMessage({ id: 'PartnerProffessional.SectionHero.bullet2' }),
            intl.formatMessage({ id: 'PartnerProffessional.SectionHero.bullet3' }),
          ],
          showMobile: true,
        }}
        bulletType={'check'}
        checkColor={'black'}
        media={{ content: intl.locale === 'sv' ? mainImgSV : mainImgEN, showMobile: true }}
        wideMedia
        textAlign={'left'}
        textAlignMobile={'top'}
        buttonsList={[
          {
            callback: onHeroButtonClick,
            linkText: intl.formatMessage({ id: 'PartnerProffessional.SectionHero.link' }),
            type: BUTTON_TYPES.BLUE,
            linkColor: LINK_COLORS.WHITE,
            size: BUTTON_SIZES.MEDIUM,
            withIcon: false,
            block: isMobile,
          },
        ]}
      />
    </section>
  );
};

export default SectionHero;
