import cookies from 'js-cookie';

export const LOCALES = {
  EN: 'en',
  SV: 'sv',
};

export const getLocale = () => {
  if (typeof global !== 'undefined' && global.initLocale) {
    return global.initLocale;
  }

  if (cookies.get('locale')) {
    return cookies.get('locale') === LOCALES.SV ? LOCALES.SV : LOCALES.EN;
  }

  return LOCALES.SV;
};

// function to change the website locale
export const setLocale = (locale) => {
  cookies.set('locale', locale, { path: '/' });
  window.location.reload();
};
