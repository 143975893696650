import { array, arrayOf, bool, func, string } from 'prop-types';
import React, { useCallback, useEffect, useState, useRef } from 'react';
import { FieldTextInput, IconCardSelectButton } from '../../components';
import { useIntl } from 'react-intl';
import isEqual from 'lodash/isEqual';

import css from './EditListingAmenitiesForm.module.scss';

const ADDONS = 'addons';

const CustomAmenitiesCheckboxField = (props) => {
  const {
    name,
    id,
    checkboxOptions,
    form,
    selectedLabel,
    initialSelectedOptions = [],
    handleEditBtnClick,
    showEditBtn,
    setAddonOptionClicked,
    addonSelectedOptions,
    setAddonSelectedOptions,
    selectedAddonRef,
  } = props;
  const [selectedOptions, setSelectedOptions] = useState(initialSelectedOptions);
  const addonSelectedOptionsRef = useRef(addonSelectedOptions);
  if (!isEqual(addonSelectedOptionsRef.current, addonSelectedOptions)) {
    addonSelectedOptionsRef.current = addonSelectedOptions;
  }
  useEffect(() => {
    if (id === ADDONS) {
      setSelectedOptions([...selectedOptions, ...addonSelectedOptions]);
    }
  }, [addonSelectedOptionsRef.current]);
  const intl = useIntl();
  const checkSelectedOption = useCallback(
    (option) => {
      return selectedOptions.includes(option.key || option.id);
    },
    [selectedOptions]
  );

  const handleClick = (option) => () => {
    if (selectedOptions.includes(option.key || option.id)) {
      const newSelectedOptions = selectedOptions.filter(
        (_option) => _option !== (option.key || option.id)
      );
      form.batch(() => {
        form.change(name, newSelectedOptions);
        form.change(`${name}Info[${option.key || option.id}]`, undefined);
      });
      setSelectedOptions(newSelectedOptions);
      if (name === ADDONS) {
        setAddonSelectedOptions(newSelectedOptions);
        setAddonOptionClicked('');
      }
    } else {
      const newSelectedOptions = [...selectedOptions, option.key || option.id];
      form.batch(() => {
        form.change(name, newSelectedOptions);
        form.change(`${name}Info[${option.key || option.id}]`, {});
      });
      setSelectedOptions(newSelectedOptions);
      if (name === ADDONS) {
        setAddonSelectedOptions(newSelectedOptions);
      }
    }
    if (name === ADDONS && !selectedOptions.includes(option.key || option.id)) {
      handleEditBtnClick(option.key || option.id);
    }
  };
  const handleClickWithStopPropagation = (option) => (e) => {
    e.stopPropagation();
    handleEditBtnClick(option.key || option.id);
  };

  return (
    <div className={css.checkboxFieldWrapper}>
      <div className={css.checkboxFieldLabel}>{selectedLabel}</div>
      <div className={css.optionsWrapper}>
        {checkboxOptions &&
          checkboxOptions.map((option) => {
            if (option.key === 'none') return null;
            const labelText = option.labelId
              ? intl.formatMessage({ id: option.labelId })
              : option.label;
            return (
              <div className={css.cardSelectWrapper} key={option.key || option.id}>
                <IconCardSelectButton
                  labelText={labelText}
                  iconSrc={option.iconSrc}
                  isSelected={checkSelectedOption(option)}
                  onClick={handleClick(option)}
                  canEdited={showEditBtn && checkSelectedOption(option)}
                  editCallback={handleClickWithStopPropagation(option)}
                  selectedAddonRef={selectedAddonRef}
                  option={option}
                />
              </div>
            );
          })}
      </div>
      <FieldTextInput id={id} name={name} type="hidden" />
    </div>
  );
};

CustomAmenitiesCheckboxField.propsType = {
  id: string.isRequired,
  name: string.isRequired,
  selectedLabel: string.isRequired,
  checkboxOptions: arrayOf(string),
  initialSelectedOptions: array,
  handleEditBtnClick: func,
  showEditBtn: bool,
};

export default CustomAmenitiesCheckboxField;
