import React from 'react';
import { injectIntl } from 'react-intl';

import { TypeFormWidget } from '../../../widgets';

import ListingPageSection from '../ListingPageSection/ListingPageSection';

const SectionTypeForm = ({ intl }) => {
  return (
    <ListingPageSection lastSection>
      <TypeFormWidget
        title={intl.formatMessage({ id: 'Widget.ListingPage.TypeForm.title' })}
        text={intl.formatMessage({ id: 'Widget.ListingPage.TypeForm.text' })}
      />
    </ListingPageSection>
  );
};

export default injectIntl(SectionTypeForm);
