import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { ValidationError } from '..';
import { bool, func, object, string } from 'prop-types';
import { OutsideClickHandler } from '../../components';

import css from './FieldNumberInputHero.module.scss';
import { Field } from 'react-final-form';

const defaultParse = (value) => {
  if (isNaN(value)) {
    return 0;
  } else if (value && value.replace) {
    return parseInt(value.replace(/\s/g, ''));
  }
  return value === null ? value : 0;
};

const FieldNumberInputComponent = ({
  rootClassName,
  className,
  inputRootClass,
  customErrorText,
  inputClassName,
  id,
  label,
  input,
  meta,
  inputRef,
  onChange: customOnChange,
  positive,
  min,
  max,
  isOpen,
  setOpen,
  ...rest
}) => {
  if (label && !id) {
    throw new Error('id required when a label is given');
  }

  const { valid, invalid, touched, error } = meta;
  const { onChange } = input;
  const errorText = customErrorText || error;
  const hasError = !!customErrorText || !!(touched && invalid && error);
  const fieldMeta = { touched: hasError, error: errorText };
  const refMaybe = inputRef ? { ref: inputRef } : {};
  // let isOpen = true;
  const handleChange = (e) => {
    if (typeof customOnChange === 'function') customOnChange(e);
    onChange(e);
  };
  const inputClasses =
    inputRootClass ||
    classNames(css.input, inputClassName, {
      [css.inputSuccess]: valid,
      [css.inputError]: hasError,
    });

  const inputProps = {
    className: inputClasses,
    id,
    type: 'text',
    ...refMaybe,
    ...input,
    ...rest,
    onChange: handleChange,
  };
  const classes = classNames(rootClassName || css.root, className);
  const handleClickPlus = () => {
    let nextValue = parseInt(input.value || 0, 10) + 1;
    if (max) nextValue = nextValue > max ? max : nextValue;
    handleChange(nextValue.toString());
    input.onBlur();
  };
  const handleClickMinus = () => {
    let nextValue = parseInt(input.value || 0, 10) - 1;
    if (positive) nextValue = nextValue < 0 ? 0 : nextValue;
    if (min) nextValue = nextValue < min ? min : nextValue;
    onChange(nextValue.toString());
    input.onBlur();
  };
  const handleClickArrowDown = () => {
    setOpen(true);
  };
  return (
    <div className={classes}>
      {label ? <label htmlFor={id}>{label}</label> : null}
      <div className={css.inputWrapper} onClick={handleClickArrowDown}>
        <input {...inputProps} />

        {/* <div className={css.arrows} onClick={handleClickArrowDown}>
          <svg viewBox="0 0 494.148 494.148" width={10} height={10}>
            <path
              d="M201.188 405.284L13.28 130.804C4.596 118.128 0 105.356 0 94.74c0-20.524 16.472-33.22 44.044-33.22h406.124c27.54 0 43.98 12.68 43.98 33.156 0 10.632-4.6 23.2-13.308 35.904L292.936 405.188c-12.104 17.66-28.392 27.44-45.884 27.44-17.48.004-33.764-9.664-45.864-27.344z"
              data-original="#000000"
            />
          </svg>
        </div> */}
        {isOpen ? (
          <section className={css.alignmentSection}>
            <OutsideClickHandler
              onOutsideClick={() => {
                setOpen(false);
              }}
            >
              <div className={css.dropdown}>
                <div className={css.dropdownPlaceholder}>Guests</div>
                <div className={css.plusMinus}>
                  <svg
                    className={css.pointer}
                    width="27"
                    height="27"
                    viewBox="0 0 27 27"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    onClick={handleClickMinus}
                  >
                    <circle
                      cx="13.5"
                      cy="13.5"
                      r="13.25"
                      fill="white"
                      stroke="black"
                      strokeWidth="0.5"
                    />
                    <line x1="10" y1="13.5" x2="18" y2="13.5" stroke="black" />
                  </svg>
                  <section className={css.inputValue}>{input.value ? input.value : 0}</section>
                  <svg
                    className={css.pointer}
                    width="27"
                    height="28"
                    viewBox="0 0 27 28"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    onClick={handleClickPlus}
                  >
                    <circle
                      cx="13.5"
                      cy="14.5"
                      r="13.25"
                      fill="white"
                      stroke="black"
                      strokeWidth="0.5"
                    />
                    <path
                      d="M14.68 19.04H13.29V15.39H9.65V14.03H13.29V10.35H14.68V14.03H18.35V15.39H14.68V19.04Z"
                      fill="black"
                    />
                  </svg>
                </div>
              </div>
            </OutsideClickHandler>
          </section>
        ) : null}
      </div>
      <ValidationError fieldMeta={fieldMeta} />
    </div>
  );
};

const FieldNumberInputHero = ({ onUnmount, ...rest }) => {
  const [isOpen, setOpen] = useState(false);
  useEffect(() => {
    return () => {
      if (typeof onUnmount === 'function') onUnmount();
    };
  }, [onUnmount]);

  return (
    <Field
      component={FieldNumberInputComponent}
      {...rest}
      parse={defaultParse}
      isOpen={isOpen}
      setOpen={setOpen}
    />
  );
};

FieldNumberInputHero.defaultProps = {
  positive: true,
  rootClassName: null,
  className: null,
  inputRootClass: null,
  onUnmount: null,
  customErrorText: null,
  id: null,
  label: null,
  inputRef: null,
};

FieldNumberInputHero.propTypes = {
  rootClassName: string,
  className: string,
  inputRootClass: string,
  onUnmount: func,
  customErrorText: string,
  id: string,
  label: string,
  inputRef: object,
  positive: bool,
};

export default FieldNumberInputHero;
