import React from 'react';
import { FormattedMessage } from '../../../util/reactIntl';
import { NamedLink } from '../../../components';

import css from './Offers.module.scss';

const OPTIONS = [
  {
    title: `About.offer1.title`,
    description: `About.offer1.description`,
    link: `About.offer1.linkText`,
    name: 'OnboardingPage',
  },
  // {
  //   title: `About.offer2.title`,
  //   description: `About.offer2.description`,
  //   link: `About.offer2.linkText`,
  //   name: 'RespacesPass',
  // },
  {
    title: `About.offer3.title`,
    description: `About.offer3.description`,
    link: `About.offer3.linkText`,
    name: 'Partner',
  },
];

export const Offers = () => {
  return (
    <section className={css.section}>
      <div className={css.wrapper}>
        <FormattedMessage id="About.titleOffers">
          {(id) => <h2 className={css.title}>{id}</h2>}
        </FormattedMessage>

        <div className={css.offers}>
          {OPTIONS.map((option) => (
            <div className={css.offer}>
              <FormattedMessage id={option.title}>
                {(id) => <h3 className={css.offerTitle}>{id}</h3>}
              </FormattedMessage>
              <FormattedMessage id={option.description}>
                {(id) => <p className={css.offerDescription}>{id}</p>}
              </FormattedMessage>
              <FormattedMessage id={option.link}>
                {(id) => (
                  <NamedLink name={option.name} className={css.offerLink}>
                    {id}
                  </NamedLink>
                )}
              </FormattedMessage>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};
