import React, { useMemo } from 'react';
import { bool, func, object, string } from 'prop-types';

import { injectIntl, intlShape } from '../../util/reactIntl';
import LocationCardComponent from './LocationCardComponent';
import { types as sdkTypes } from '../../util/sdkLoader';
import { getUnitTranslationKeyFromBookingType } from '../../util/data';

const { UUID } = sdkTypes;

export const LocationCard = ({
  setActiveListing,
  className,
  isFavorite,
  isFavoriteCard,
  isCompanyFavorite,
  isShowCredits,
  showCompanyFavoriteButton,
  isActiveOnBottomSheet,
  onClick,
  isMobile,
  search,
  stateCategories,
  location,
  intl,
}) => {
  const { address, listings: listingsArray = [], id: locationId, name, images } = location || {};

  const groupedListings = useMemo(() => {
    if (!!stateCategories) {
      return listingsArray
        .map((l) => ({
          category: l.category,
          booking_type_translation: getUnitTranslationKeyFromBookingType(l.booking_type),
          min_seats: l?.min_seats || l?.max_seats,
          max_seats: l?.max_seats,
          min_price: l?.price,
          max_price: l?.price,
          vat: l?.vat,
          request_price: l?.request_price,
        }))
        .sort((a, b) => (a.min_price > b.min_price ? 1 : -1));
    } else {
      const reduced = listingsArray.reduce((acc, curr) => {
        const { booking_type, category, min_seats, max_seats, request_price, price } = curr;

        const minSeatsFinal = !!min_seats ? min_seats : max_seats;
        const vat = curr?.vat || 25;

        if (!(category in acc)) {
          acc[category] = {};
        }

        if (!(booking_type in acc[category])) {
          acc[category][booking_type] = {};
        }

        if (!('min_seats' in acc[category][booking_type])) {
          acc[category][booking_type].min_seats = minSeatsFinal;
          acc[category][booking_type].max_seats = max_seats;
        } else {
          acc[category][booking_type].min_seats = Math.min(
            acc[category][booking_type].min_seats,
            minSeatsFinal
          );

          acc[category][booking_type].max_seats = Math.max(
            acc[category][booking_type].max_seats,
            max_seats
          );
        }

        if (!('min_price' in acc[category][booking_type])) {
          acc[category][booking_type].min_price = price;
          acc[category][booking_type].max_price = price;
          acc[category][booking_type].vat = +vat;
        } else {
          if (price < acc[category][booking_type].min_price) {
            acc[category][booking_type].min_price = price;
            acc[category][booking_type].vat = +vat;
          }

          if (price > acc[category][booking_type].max_price) {
            acc[category][booking_type].max_price = price;
            acc[category][booking_type].vat = +vat;
          }
        }

        if (request_price) {
          acc[category][booking_type].request_price = true;
        }

        return acc;
      }, {});

      return Object.keys(reduced).reduce((acc, curr) => {
        acc.push(
          ...Object.keys(reduced[curr]).map((booking_type) => ({
            category: curr,
            booking_type_translation: getUnitTranslationKeyFromBookingType(booking_type),
            ...reduced[curr][booking_type],
          }))
        );

        return acc;
      }, []);
    }
  }, [listingsArray, stateCategories]);

  // Determine if location should have a badge, and what type of badge
  const { badgeStringMaybe, badgeColorMaybe } = useMemo(() => {
    // Find out if location has been assigned the Unique badge
    if (location?.listings?.some((listing) => listing.badge_unique)) {
      return {
        badgeStringMaybe: intl.formatMessage({ id: 'LocationCard.badge.unique' }),
        badgeColorMaybe: 'green',
      };
    } else if (
      // Find out if location has been assigned the Affordable badge
      location?.listings?.some((listing) => listing.badge_affordable)
    ) {
      return {
        badgeStringMaybe: intl.formatMessage({ id: 'LocationCard.badge.affordable' }),
        badgeColorMaybe: 'white',
      };
    } else if (
      // Find out if location a listing that is a shared office
      location.host === 'otherCompany' &&
      location?.listings?.some(
        (listing) => listing?.category === 'fixed' && listing?.fixed_office_type !== 'office'
      )
    ) {
      return {
        badgeStringMaybe: intl.formatMessage({ id: 'LocationCard.badge.shared' }),
        badgeColorMaybe: 'white',
      };
    } else if (
      // Find out if location has been updated within the last month
      location?.listings?.some(
        (listing) =>
          listing.attributes?.createdAt.getTime() > new Date().getTime() - 1000 * 60 * 60 * 24 * 30
      )
    ) {
      return {
        badgeStringMaybe: intl.formatMessage({ id: 'LocationCard.badge.new' }),
        badgeColorMaybe: 'white',
      };
    } else {
      return { badgeStringMaybe: null, badgeColorMaybe: null };
    }
  }, []);

  if (!!locationId) {
    return (
      <LocationCardComponent
        onMouseEnter={
          () =>
            setActiveListing &&
            setActiveListing(new UUID(location.id || location?.listings[0]?.sharetribe_id)) //perhaps .listings_rel[0]?
        }
        onMouseLeave={() => setActiveListing && setActiveListing(null)}
        id={locationId}
        intl={intl}
        title={name}
        listingsArray={groupedListings}
        images={images}
        address={address}
        className={className}
        isFavorite={isFavorite}
        isFavoriteCard={isFavoriteCard}
        isCompanyFavorite={isCompanyFavorite}
        isShowCredits={isShowCredits}
        showCompanyFavoriteButton={showCompanyFavoriteButton}
        isActiveOnBottomSheet={isActiveOnBottomSheet}
        onClick={onClick}
        isMobile={isMobile}
        search={search}
        badgeString={badgeStringMaybe}
        badgeColor={badgeColorMaybe}
      />
    );
  } else {
    return null;
  }
};

LocationCard.defaultProps = {
  setActiveListing: null,
  className: null,
  isFavorite: false,
  isFavoriteCard: false,
  showCompanyFavoriteButton: false,
  isActiveOnBottomSheet: false,
  search: '',
};

LocationCard.propTypes = {
  intl: intlShape.isRequired,
  setActiveListing: func,
  className: string,
  isFavorite: bool,
  isFavoriteCard: bool,
  isCompanyFavorite: bool,
  showCompanyFavoriteButton: bool,
  isActiveOnBottomSheet: bool,
  search: string,
};

export default injectIntl(LocationCard);
