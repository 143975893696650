import React from 'react';
import { Modal } from '..';
import classNames from 'classnames';
import css from './PopupModal.module.scss';
import { func, node, string } from 'prop-types';
import { bool } from 'prop-types';

const PopupModal = (props) => {
  const { children, modalContainer, onClose, ...restOfProps } = props;

  const modalContainerClasses = classNames(css.modalContainer, modalContainer);
  return (
    <Modal
      onClose={onClose}
      openClassName={css.openModal}
      scrollLayerClassName={css.scrollableLayer}
      containerClassName={modalContainerClasses}
      {...restOfProps}
    >
      <div className={css.closeButton} onClick={onClose}>
        <svg
          width="19"
          height="19"
          viewBox="0 0 19 19"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M1 1L18 18" stroke="black" />
          <path d="M18 1L1 18" stroke="black" />
        </svg>
      </div>
      {children}
    </Modal>
  );
};

PopupModal.defaultProps = {
  modalContainer: null,
  children: null,
  isOpen: false,
  onClose: null,
  id: null,
  hideCloseButton: false,
};

PopupModal.propTypes = {
  modalContainer: string,
  children: node,
  isOpen: bool.isRequired,
  onClose: func.isRequired,
  id: string.isRequired,
  hideCloseButton: bool,
  onManageDisableScrolling: func,
};

export default PopupModal;
