import React from 'react';
import { string, objectOf, shape } from 'prop-types';

import ListingPageSection from '../ListingPageSection/ListingPageSection';

import { OpeningHours } from '../../../components_new';

const SectionOpeningHours = ({ daysList }) => (
  <ListingPageSection title={'ListingPage.openingHours.title'}>
    <OpeningHours daysList={daysList} />
  </ListingPageSection>
);

SectionOpeningHours.defaultProps = {
  daysList: [],
};

SectionOpeningHours.propTypes = {
  daysList: objectOf(
    shape({
      closeTime: string,
      openTime: string,
    })
  ).isRequired,
};

export default SectionOpeningHours;
