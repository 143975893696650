import React, { memo } from 'react';
import classNames from 'classnames';
import { FavoriteListingToggle } from '../../components_new';

import css from './LocationPage.module.scss';

const generateKeywords = (arr) =>
  Array.isArray(arr)
    ? arr.reduce((acc, curr, i) => acc + `${i === 0 ? '' : ' • '}${curr}`, '')
    : null;

const SectionHeader = memo(({ name, address, keywords, isFavorite, locationId }) => (
  <section className={classNames(css.section, css.header)}>
    <div className={css.favoriteWrapper}>
      <FavoriteListingToggle listingId={locationId} isFavorite={isFavorite} />
    </div>
    <h1 className={css.locationName}>{name}</h1>
    <p className={css.keywords}>{generateKeywords(keywords)}</p>
    <p className={css.address}>{address}</p>
  </section>
));

export default SectionHeader;
