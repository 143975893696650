import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { bool, func, string } from 'prop-types';

import { intlShape } from '../../util/reactIntl';
import { FavoriteButton } from './ui';
import { mapDispatchToProps } from './model/store';

import css from './FavoriteListingToggle.module.scss';

const FavoriteListingToggleComponent = ({
  listingId,
  wrapperClassName,
  isFavorite,
  onToggleFavoriteListing,
  currentUser,
}) => {
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(false);
  }, [isFavorite]);

  return currentUser ? (
    <div className={classNames(css.favoriteButtonWrapper, wrapperClassName)}>
      <FavoriteButton
        isFavorite={isFavorite}
        isLoading={isLoading}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          setIsLoading(true);
          onToggleFavoriteListing(listingId);
        }}
      />
    </div>
  ) : null;
};

FavoriteListingToggleComponent.propTypes = {
  intl: intlShape,
  listingId: string,
  wrapperClassName: string,
  isFavorite: bool,
  onToggleFavoriteListing: func,
};

const mapStateToProps = (state) => {
  const {
    user: { currentUser },
  } = state;

  return {
    currentUser,
  };
};

const FavoriteListingToggle = compose(
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl
)(FavoriteListingToggleComponent);

export default FavoriteListingToggle;
