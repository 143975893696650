import React, { memo } from 'react';
import { arrayOf, func, shape, string } from 'prop-types';

import { MapWithAddress } from '../../../../components_new';

import css from './LocationInput.module.scss';

const LocationInput = memo(
  ({ id, address, geolocation, onInput, onSelectPrediction, predictions, placeholder }) => (
    <>
      <div className={css.wrapper}>
        <input
          className={css.input}
          type="text"
          name={id}
          id={id}
          value={address}
          placeholder={placeholder}
          autoComplete="off"
          onInput={({ currentTarget: { value } }) => onInput(value)}
        />
        {!!predictions.length && (
          <ul className={css.predictions}>
            {predictions.map((p) => (
              <li>
                <button onClick={() => onSelectPrediction(p)} type="button">
                  {p.description}
                </button>
              </li>
            ))}
          </ul>
        )}
      </div>
      <MapWithAddress
        geolocation={geolocation}
        address={address}
        id="create_location_form_map"
        showAddress={false}
      />
    </>
  )
);

LocationInput.propTypes = {
  id: string,
  value: string,
  onInput: func,
  onSelectPrediction: func,
  predictions: arrayOf(shape({ description: string })),
};

export default LocationInput;
