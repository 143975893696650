import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { NamedLink, SubscriptionManagementIcons as Icons } from '../../../components';
import { ChangePaymentInfoForm } from '../../../forms';
import { MY_SUBSCRIPTION } from '../../../util/types';
import css from './ChangePaymentInfo.module.scss';

const ChangePaymentInfo = (props) => {
  const intl = useIntl();

  return (
    <div className={css.tabContainer}>
      <div className={css.backBtn}>
        <NamedLink
          name="SubscriptionManagementPage"
          params={{ tab: MY_SUBSCRIPTION, action: null }}
        >
          <div className={css.backBtn}>
            <Icons.BackIcon className={css.backIcon} />
            {intl.formatMessage({ id: 'MySubscriptionTab.backToMySubscription' })}
          </div>
        </NamedLink>
      </div>
      <div className={css.tabTitle}>{intl.formatMessage({ id: 'ChangePaymentInfo.title' })}</div>
      <ChangePaymentInfoForm />
    </div>
  );
};

export default ChangePaymentInfo;
