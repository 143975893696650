import React, { useState, useMemo, useCallback } from 'react';
import moment from 'moment';
import css from './MonthInput.module.scss';
import classNames from 'classnames';

const MONTHS = moment.monthsShort();
const NOW = moment();

const fields = ['month', 'year'];

const now = moment();

const MonthInput = ({ onChange, selectedMonth, selectedYear, monthSlots = [], searchFormDate }) => {
  const [selected, setSelected] = useState(searchFormDate || selectedMonth);
  const [year, setYear] = useState(selectedYear || NOW.get('years'));
  const onClickPrevYear = () => {
    setYear((prev) => prev - 1);
  };
  const onClickNextYear = () => {
    setYear((prev) => prev + 1);
  };
  const handleSelectMonth = useCallback(
    (month) => () => {
      const selectedMonth = moment(`${month} ${year}`, 'MMM YYYY');
      setSelected(month);
      onChange({
        start: selectedMonth.toDate(),
        end: selectedMonth.endOf('month').toDate(),
      });
    },
    [onChange, year]
  );

  const monthForRender = useMemo(() => {
    return MONTHS.map((m) => {
      const monthClass = classNames(css.month, {
        [css.selectMonth]: selected === m && selectedYear === year,
        [css.disabled]:
          now.isAfter(moment(`${m} ${year}`, 'MMM YYYY')) ||
          (monthSlots &&
            !monthSlots.find((s) => {
              const start = moment(s.attributes.start);
              const month = moment(`${m} ${year}`, 'MMM YYYY');
              const temp = fields.reduce((prev, f) => {
                prev = prev && start.get(f) === month.get(f);
                return prev;
              }, true);
              return temp;
            })),
      });
      return (
        <div onClick={handleSelectMonth(m)} key={m} className={monthClass}>
          {m}
        </div>
      );
    });
  }, [year, handleSelectMonth, monthSlots, selected, selectedYear]);

  return (
    <div className={css.root}>
      <div className={css.header}>
        <button
          className={css.changeMonthButton}
          onClick={(e) => {
            e.preventDefault();
            onClickPrevYear();
          }}
        >
          <svg
            width="40"
            height="40"
            viewBox="0 0 40 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              opacity="0.8"
              d="M22 14L16 20.5L22 27"
              stroke="#FFFFFF"
              strokeWidth="2"
              fill="none"
            />
          </svg>
        </button>
        <div className={css.currentYear}>{year}</div>
        <button
          className={css.changeMonthButton}
          onClick={(e) => {
            e.preventDefault();
            onClickNextYear();
          }}
        >
          <svg
            width="40"
            height="40"
            viewBox="0 0 40 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              opacity="0.8"
              d="M18 14L24 20.5L18 27"
              stroke="#FFFFFF"
              strokeWidth="2"
              fill="none"
            />
          </svg>
        </button>
      </div>
      <div className={css.months}>{monthForRender}</div>
    </div>
  );
};

export default MonthInput;
