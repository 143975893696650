import React from 'react';
import { injectIntl } from 'react-intl';

import { MediaAndTextSection } from '../../../components_new';
import {
  BULLET_TYPES,
  CHECKMARK_COLORS,
  TEXT_ALIGN_MOBILE,
} from '../../../components_new/MediaAndTextSection/MediaAndTextSection';
import {
  BUTTON_SIZES,
  BUTTON_TYPES,
  LINK_COLORS,
  MOBILE_POSITION,
} from '../../../components_new/ButtonNew/ButtonNew';
import Img from '../../../assets/mobileListingsPage.png';

import css from './SectionClosing.module.scss';
import commonCss from '../RespacesPass.module.scss';

const SectionClosing = ({ intl }) => {
  const INTL_SECTION_NAME = 'RespacesPass.closing';

  const bullets = ['first', 'second', 'third'].map((bullet) =>
    intl.formatMessage(
      { id: `${INTL_SECTION_NAME}.bullet.${bullet}` },
      {
        bold: (val) => <span className={commonCss.bold}>{val}</span>,
      }
    )
  );

  return (
    <MediaAndTextSection
      textAlignMobile={TEXT_ALIGN_MOBILE.TOP}
      subtitle={{
        content: intl.formatMessage({ id: `${INTL_SECTION_NAME}.subtitle` }),
        showMobile: true,
      }}
      title={{
        content: intl.formatMessage({ id: `${INTL_SECTION_NAME}.title` }),
        showMobile: true,
        isH1: true,
      }}
      caption={{
        text: intl.formatMessage({ id: `${INTL_SECTION_NAME}.caption` }),
        showMobile: true,
      }}
      bullets={{
        list: bullets,
        showMobile: true,
      }}
      bulletType={BULLET_TYPES.CHECK}
      checkColor={CHECKMARK_COLORS.BLACK}
      buttonsList={[
        {
          linkText: intl.formatMessage({ id: `${INTL_SECTION_NAME}.cta2` }),
          name: 'SearchPage',
          size: BUTTON_SIZES.SMALL,
          type: BUTTON_TYPES.GREEN,
          linkColor: LINK_COLORS.WHITE,
          withIcon: false,
          mobileButtonPosition: MOBILE_POSITION.FULL_WIDTH,
        },
        {
          linkText: intl.formatMessage({ id: `${INTL_SECTION_NAME}.cta` }),
          name: 'OnboardingPage',
          size: BUTTON_SIZES.SMALL,
          type: BUTTON_TYPES.GREEN,
          linkColor: LINK_COLORS.WHITE,
          withIcon: false,
          mobileButtonPosition: MOBILE_POSITION.FULL_WIDTH,
        },
      ]}
      media={{
        showMobile: true,
        content: Img,
      }}
    />
  );
};

export default injectIntl(SectionClosing);
