import React from 'react';
import { injectIntl } from 'react-intl';

import css from './FilterButton.module.scss';

const FilterButton = ({ isMobile, number = 0, onClick, intl }) => {
  const isActive = !!number;
  const hex = isActive && !isMobile ? '#fff' : isActive ? '#74AF86' : '#222';

  return (
    <div className={`${css.container} ${isActive && css.active}`} onClick={onClick}>
      <div className={css.icon}>
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <line x1="1.5" y1="3.5" x2="18.5" y2="3.5" stroke={hex} strokeLinecap="round" />
          <line x1="1.5" y1="9.5" x2="18.5" y2="9.5" stroke={hex} strokeLinecap="round" />
          <line x1="1.5" y1="15.5" x2="18.5" y2="15.5" stroke={hex} strokeLinecap="round" />
          <circle
            cx="6.5"
            cy="3.5"
            r="2"
            fill={isActive && !isMobile ? '#74AF86' : 'white'}
            stroke={hex}
          />
          <circle
            cx="6.5"
            cy="15.5"
            r="2"
            fill={isActive && !isMobile ? '#74AF86' : 'white'}
            stroke={hex}
          />
          <circle
            cx="13.5"
            cy="9.5"
            r="2"
            fill={isActive && !isMobile ? '#74AF86' : 'white'}
            stroke={hex}
          />
        </svg>
      </div>
      <span className={css.text}>{`${intl.formatMessage({ id: 'SearchPage.filterButton' })} ${
        !!number ? `(${number.toString()})` : ''
      }`}</span>
    </div>
  );
};

export default injectIntl(FilterButton);
