import React, { useEffect, useState } from 'react';
import { injectIntl } from 'react-intl';
import { object } from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';

import { MediaAndTextSection } from '../../components_new';
import {
  TEXT_ALIGN,
  TEXT_ALIGN_MOBILE,
} from '../../components_new/MediaAndTextSection/MediaAndTextSection';
import { intlShape } from '../../util/reactIntl';
import { CATEGORIES } from '../ImageSlider/ImageSlider';
import ImageSlider from '../ImageSlider/ImageSlider';
import config from '../../config';

import respacesPassImg from '../../assets/respaces_pass.png';
import schedulingToolImg from '../../assets/scheduling_tool.png';

const SectionProducts = ({
  intl,
  buttonProps,
  className,
  sdk,
  onModalOpen,
  firstMediaAndTextSection,
  secondMediaAndTextSection,
  thirdMediaAndTextSection,
  textAlignLeft,
}) => {
  const SECTION_NAME = 'LandingPage.SectionProducts';
  const SUBSECTION_FIRST = 'FixedOffice';
  const SUBSECTION_SECOND = 'Pass';
  const SUBSECTION_THIRD = 'Scheduling';

  const [listings, setListings] = useState([]);
  const listingArray = [];

  const featuredListingIdList = config.dev
    ? [
        //development listings
        '626fa9ea-68aa-4345-91aa-4074f7655a71', // Gullmarsstrand - Fyren
        '626bd140-5864-4b05-8d7c-65b9160c47a2', // Helio Kungsholmen
        '626bcfb1-f645-4268-b442-afe54da08036', // LAMB
        '626b9f40-63f0-4b18-9127-54849e49ca9b', // I Know A Palce
      ]
    : [
        //production listings
        '62bdb3b8-c0c4-4bee-9368-2617556bd80e', // United Spaces Helsingborg
        '61b12d91-d2b0-4f8f-8fb5-2a5f90dc4e9f', // WeWork Stockholm
        '62becfdf-e6cb-4c59-be34-03853bde9bfe', // UNITY- Hammarby Sjöstad
      ];

  useEffect(() => {
    featuredListingIdList.forEach(async (UUID) => {
      const {
        data: { data, included: images },
      } = await sdk.listings.show({
        id: UUID,
        include: ['images'],
        'fields.image': ['variants.square-small2x'],
      });

      listingArray.push({ ...data, images });

      if (listingArray.length === featuredListingIdList.length) {
        setListings(listingArray);
      }
    });
    //eslint-disable-next-line
  }, []);

  return (
    <section className={className} id={'products'}>
      <MediaAndTextSection
        className={firstMediaAndTextSection}
        title={{
          content: intl.formatMessage({ id: `${SECTION_NAME}.${SUBSECTION_FIRST}.title` }),
          showMobile: true,
        }}
        subtitle={{
          content: intl.formatMessage({ id: `${SECTION_NAME}.${SUBSECTION_FIRST}.subtitle` }),
          showMobile: true,
        }}
        caption={{
          text: (
            <FormattedMessage
              id={`${SECTION_NAME}.${SUBSECTION_FIRST}.text`}
              values={{
                bullet: (msg) => (
                  <>
                    <br />
                    {msg}
                  </>
                ),
              }}
            />
          ),
          showMobile: true,
        }}
        buttonsList={[
          {
            linkText: intl.formatMessage({ id: `LandingPage.searchCTA` }),
            // callback: onModalOpen,
            name: 'OnboardingPage',
            ...buttonProps,
          },
        ]}
        textAlignMobile={TEXT_ALIGN_MOBILE.TOP}
        textAlign={TEXT_ALIGN.LEFT}
      >
        <ImageSlider
          data={listings}
          category={CATEGORIES[1]}
          showLocation
          showTitle
          autoplay={{ delay: 5000 }}
        />
      </MediaAndTextSection>
      {/* <MediaAndTextSection
        className={classNames(className, secondMediaAndTextSection)}
        title={{
          content: intl.formatMessage({ id: `${SECTION_NAME}.${SUBSECTION_SECOND}.title` }),
          showMobile: true,
        }}
        subtitle={{
          content: intl.formatMessage({ id: `${SECTION_NAME}.${SUBSECTION_SECOND}.subtitle` }),
          showMobile: true,
        }}
        caption={{
          text: intl.formatMessage({ id: `${SECTION_NAME}.${SUBSECTION_SECOND}.text` }),
          showMobile: true,
        }}
        buttonsList={[
          {
            linkText: intl.formatMessage({ id: `${SECTION_NAME}.${SUBSECTION_SECOND}.link` }),
            name: 'RespacesPass',
            ...buttonProps,
          },
        ]}
        textAlignMobile={TEXT_ALIGN_MOBILE.TOP}
        textAlign={textAlignLeft ? TEXT_ALIGN.LEFT : TEXT_ALIGN.RIGHT}
        media={{
          content: respacesPassImg,
          showMobile: true,
        }}
      />
      <MediaAndTextSection
        className={thirdMediaAndTextSection}
        title={{
          content: intl.formatMessage({ id: `${SECTION_NAME}.${SUBSECTION_THIRD}.title` }),
          showMobile: true,
        }}
        subtitle={{
          content: intl.formatMessage({ id: `${SECTION_NAME}.${SUBSECTION_THIRD}.subtitle` }),
          showMobile: true,
        }}
        caption={{
          text: intl.formatMessage({ id: `${SECTION_NAME}.${SUBSECTION_THIRD}.text` }),
          showMobile: true,
        }}
        textAlignMobile={TEXT_ALIGN_MOBILE.TOP}
        textAlign={TEXT_ALIGN.LEFT}
        media={{
          content: schedulingToolImg,
          showMobile: true,
        }}
      /> */}
    </section>
  );
};

SectionProducts.propTypes = {
  intl: intlShape,
  buttonProps: object,
};

export default injectIntl(SectionProducts);
