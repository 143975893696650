import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';
import { LISTING_STATE_DRAFT, FIXED_OFFICE_CATEGORY } from '../../util/types';
import { ensureOwnListing } from '../../util/data';
import { ListingLink, SaveAndExitButton } from '../';
import { EditListingEmailForm } from '../../forms';

import css from './EditListingEmailPanel.module.scss';

const EditListingEmailPanel = (props) => {
  const {
    className,
    rootClassName,
    listing,
    disabled,
    ready,
    onSubmit,
    onChange,
    submitButtonText,
    panelUpdated,
    updateInProgress,
    errors,
    onSaveAndExitListingWizardTab,
    currentUser,
  } = props;

  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureOwnListing(listing);
  const { publicData } = currentListing.attributes;
  const userEmail = currentUser.attributes.email;

  const isPublished = currentListing.id && currentListing.attributes.state !== LISTING_STATE_DRAFT;
  const panelTitle = isPublished ? (
    <FormattedMessage
      id="EditListingEmailPanel.title"
      values={{ listingTitle: <ListingLink listing={listing} /> }}
    />
  ) : (
    <FormattedMessage id="EditListingEmailPanel.createListingTitle" />
  );

  const emailConfirmationContent = publicData.emailConfirmationContent || '';
  const bookingEmailAddress1 = publicData.bookingEmailAddress
    ? publicData.bookingEmailAddress[0]
    : currentUser
    ? userEmail
    : '';
  const bookingEmailAddress2 = publicData.bookingEmailAddress
    ? publicData.bookingEmailAddress[1]
    : '';

  const { accessOption, accessDetails, accessWorkspaceFloor, category, wifiName, wifiPassword } =
    publicData || {};

  const isFixedOffice = category === FIXED_OFFICE_CATEGORY;

  const {
    digitalKeyAccess,
    accessProvider,
    accessTokenLastFour: tokenLastFour,
    accessIdLastFour: idLastFour,
  } = (publicData && publicData.digitalKey) || {};
  return (
    <div className={classes}>
      <div className={css.panelContainer}>
        <h2 className={css.title}>{panelTitle}</h2>
        <EditListingEmailForm
          className={css.form}
          publicData={publicData}
          initialValues={{
            emailConfirmationContent: emailConfirmationContent,
            bookingEmailAddress1: bookingEmailAddress1,
            bookingEmailAddress2: bookingEmailAddress2,
            accessOption: accessOption,
            accessWorkspaceOption: accessDetails,
            accessWorkspaceFloor: accessWorkspaceFloor,
            wifiName: wifiName,
            wifiPassword: wifiPassword,
            digitalKeyAccess: digitalKeyAccess,
            accessProvider: accessProvider,
            accessTokenLastFour: tokenLastFour,
            accessIdLastFour: idLastFour,
          }}
          onSubmit={(values) => {
            const {
              emailConfirmationContent = '',
              bookingEmailAddress1 = '',
              bookingEmailAddress2 = '',
              accessOption = '',
              accessWorkspaceOption = '',
              accessWorkspaceFloor = '',
              accessProvider = '',
              accessToken,
              accessId,
              wifiName = '',
              wifiPassword = '',
            } = values;
            const isDigitalKey = accessOption && accessOption === 'digitalKey';
            const accessTokenLastFour = accessToken
              ? accessToken.slice(-4)
              : tokenLastFour
              ? tokenLastFour
              : '';
            const accessIdLastFour = accessId ? accessId.slice(-4) : idLastFour ? idLastFour : '';

            const bookingEmailAddress = [bookingEmailAddress1, bookingEmailAddress2];
            const updateValues = {
              ...(isDigitalKey && {
                privateData: {
                  ...(accessToken && { accessToken }),
                  ...(accessId && { accessId }),
                },
              }),
              publicData: {
                emailConfirmationContent,
                bookingEmailAddress,
                accessOption,
                accessDetails: accessWorkspaceOption,
                accessWorkspaceFloor:
                  accessWorkspaceOption === 'elevator' ? accessWorkspaceFloor : '',
                wifiName,
                wifiPassword,
                digitalKey: isDigitalKey
                  ? {
                      digitalKeyAccess: isDigitalKey,
                      accessProvider,
                      accessTokenLastFour,
                      accessIdLastFour,
                    }
                  : {},
              },
            };
            onSubmit(updateValues);
          }}
          onChange={onChange}
          disabled={disabled}
          ready={ready}
          saveActionMsg={submitButtonText}
          updated={panelUpdated}
          updateInProgress={updateInProgress}
          onHandleSaveAndExit={onSaveAndExitListingWizardTab}
          fetchErrors={errors}
          isPublished={isPublished}
          isFixedOffice={isFixedOffice}
        />
      </div>
    </div>
  );
};

const { func, object, string, bool } = PropTypes;

EditListingEmailPanel.defaultProps = {
  className: null,
  rootClassName: null,
  listing: null,
};

EditListingEmailPanel.propTypes = {
  className: string,
  rootClassName: string,

  // We cannot use propTypes.listing since the listing might be a draft.
  listing: object,

  disabled: bool.isRequired,
  ready: bool.isRequired,
  onSubmit: func.isRequired,
  onChange: func.isRequired,
  submitButtonText: string.isRequired,
  panelUpdated: bool.isRequired,
  updateInProgress: bool.isRequired,
  errors: object.isRequired,
};

export default EditListingEmailPanel;
