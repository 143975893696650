import React, { Component } from 'react';
import { array, bool, func, object, string } from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { LISTING_STATE_DRAFT } from '../../util/types';
import { EditListingPhotosFormNew } from '../../forms';
import { ensureOwnListing } from '../../util/data';
import { ListingLink, SaveAndExitButton } from '..';

import css from './EditListingPhotosPanelNew.module.scss';

class EditListingPhotosPanelNew extends Component {
  render() {
    const {
      className,
      rootClassName,
      errors,
      disabled,
      ready,
      images,
      listing,
      onImageUpload,
      onUpdateImageOrder,
      submitButtonText,
      panelUpdated,
      updateInProgress,
      onChange,
      onSubmit,
      onRemoveImage,
      onSaveAndExitListingWizardTab,
    } = this.props;

    const rootClass = rootClassName || css.root;
    const classes = classNames(rootClass, className);
    const currentListing = ensureOwnListing(listing);

    const isPublished =
      currentListing.id && currentListing.attributes.state !== LISTING_STATE_DRAFT;
    const panelTitle = isPublished ? (
      <FormattedMessage
        id="EditListingPhotosPanel.title"
        values={{
          listingTitle: (
            <ListingLink listing={listing}>{listing.attributes.publicData.title}</ListingLink>
          ),
        }}
      />
    ) : (
      <FormattedMessage id="EditListingPhotosPanel.createListingTitle" />
    );

    return (
      <div className={classes}>
        <SaveAndExitButton />
        <div className={css.panelContainer}>
          <div className={css.title}>{panelTitle}</div>
          <p className={css.subTitle}>
            <FormattedMessage id="EditListingPhotosPanel.information" />
          </p>
          <EditListingPhotosFormNew
            className={css.form}
            disabled={disabled}
            ready={ready}
            fetchErrors={errors}
            initialValues={{ images }}
            images={images}
            onImageUpload={onImageUpload}
            onSubmit={(values) => {
              const { addImage, ...updateValues } = values;
              onSubmit(updateValues);
            }}
            onChange={onChange}
            onUpdateImageOrder={onUpdateImageOrder}
            onRemoveImage={onRemoveImage}
            saveActionMsg={submitButtonText}
            updated={panelUpdated}
            updateInProgress={updateInProgress}
            onHandleSaveAndExit={onSaveAndExitListingWizardTab}
            isPublished={isPublished}
          />
        </div>
      </div>
    );
  }
}

EditListingPhotosPanelNew.defaultProps = {
  className: null,
  rootClassName: null,
  errors: null,
  images: [],
  listing: null,
};

EditListingPhotosPanelNew.propTypes = {
  className: string,
  rootClassName: string,
  errors: object,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  images: array,

  // We cannot use propTypes.listing since the listing might be a draft.
  listing: object,

  onImageUpload: func.isRequired,
  onUpdateImageOrder: func.isRequired,
  onSubmit: func.isRequired,
  onChange: func.isRequired,
  submitButtonText: string.isRequired,
  panelUpdated: bool.isRequired,
  updateInProgress: bool.isRequired,
  onRemoveImage: func.isRequired,
};

export default EditListingPhotosPanelNew;
