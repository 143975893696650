/* eslint-disable no-console */
import EditListingEmailForm from './EditListingEmailForm';

export const Empty = {
  component: EditListingEmailForm,
  props: {
    publicData: {},
    onSubmit: (values) => {
      console.log('Submit EditListingEmailForm with (unformatted) values:', values);
    },
    saveActionMsg: 'Save rules',
    updated: false,
    updateInProgress: false,
    disabled: false,
    ready: false,
  },
  group: 'forms',
};
