import React from 'react';
import { Button } from '..';
import css from './SaveAndExitButton.module.scss';
import { any, arrayOf, bool, func, number, shape, string, oneOfType, object } from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';

const SaveAndExitButton = (props) => {
  const btnTextDefault = <FormattedMessage id="SaveAndExitButton.btnText" />;

  const { btnText = btnTextDefault, btnClassname, isLocationTab = false, ...rest } = props;

  const classes = classNames(css.saveAndExitBtn, btnClassname, {
    [css.locationTab]: isLocationTab,
  });

  return (
    <div className={css.saveAndExitBtnContainer}>
      <Button type="button" className={classes} {...rest}>
        {btnText}
      </Button>
    </div>
  );
};

SaveAndExitButton.propTypes = {
  btnText: string,
  btnClassname: string,
  onHanldeSaveAndExit: func,
  isLocationTab: bool,
};

export default SaveAndExitButton;
