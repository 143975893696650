import React from 'react';
import { func, string } from 'prop-types';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { mapDispatchToProps } from '../../model/store';
import { injectIntl } from 'react-intl';

import NamedLink from '../../../../components/NamedLink/NamedLink';

import { saveBookingToHazura } from '../../../../util/api';

import { homeImg, fixedImg, exploreImg } from './Icons';

import css from './SetStatusMenu.module.scss';

const IMAGES = {
  HOME: 'https://images.unsplash.com/photo-1480074568708-e7b720bb3f09?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2074&q=80',
  FIXED:
    'https://images.unsplash.com/photo-1574958269340-fa927503f3dd?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1648&q=80',
};

const SetStatusMenuComponent = ({
  date,
  setMenuOpen,
  scheduleUpdate,
  setScheduleUpdate,
  setLoadingStatus,
  currentUser,
  intl,
}) => {
  const userBooking = {
    sharetribeId: currentUser?.id?.uuid,
    companyId:
      currentUser?.attributes?.profile?.protectedData?.companyAccountId || currentUser?.id?.uuid,
    firstName: currentUser?.attributes?.profile?.firstName,
    lastName: currentUser?.attributes?.profile?.lastName,
    imgUrl: currentUser?.profileImage?.attributes?.variants?.['square-small2x']?.url,
  };

  const prepareData = (bookingType) => ({
    authorId: currentUser?.id?.uuid,
    date: date,
    listingCategory: 'schedule',
    ...(bookingType === 'home'
      ? {
          listingId: 'd40e255c-0942-45f7-b0c1-88a530fe0040',
          listingTitle: 'home',
          mainImage: IMAGES.HOME,
        }
      : {
          listingId: 'f015a0e6-2b8c-40fc-a93a-0f616f6c62e0',
          listingTitle: 'fixedOffice',
          mainImage: IMAGES.FIXED,
        }),
  });

  const setStatus = (bookingType) => {
    setLoadingStatus(true);
    setMenuOpen(false);
    saveBookingToHazura({
      user: userBooking,
      booking: prepareData(bookingType),
    })
      .then(() => {
        setScheduleUpdate(scheduleUpdate + 1);
      })
      .catch((e) => {
        console.error(e);
      })
      .finally(() => {
        setLoadingStatus(false);
      });
  };

  return (
    <div className={css.menuWrapper}>
      <div>
        <div className={css.titleWrapper}>
          <span className={css.title}>
            {intl.formatMessage({ id: 'TeamSchedule.setStatusMenu.title' })}
          </span>
        </div>
        <div className={css.selection} onClick={() => setStatus('fixed')}>
          <span className={css.icon}>{fixedImg}</span>
          <span>{intl.formatMessage({ id: 'TeamSchedule.setStatusMenu.fixed' })}</span>
        </div>
        <div className={css.selection} onClick={() => setStatus('home')}>
          <span className={css.icon}>{homeImg}</span>
          <span>{intl.formatMessage({ id: 'TeamSchedule.setStatusMenu.home' })}</span>
        </div>
        <NamedLink name="SearchPage" className={css.explore}>
          <span className={css.icon}>{exploreImg}</span>
          <span>{intl.formatMessage({ id: 'TeamSchedule.setStatusMenu.explore' })}</span>
        </NamedLink>
      </div>
    </div>
  );
};

SetStatusMenuComponent.propTypes = {
  date: string.isRequired,
  setMenuOpen: func.isRequired,
  scheduleUpdate: func.isRequired,
  setScheduleUpdate: func.isRequired,
  setLoadingStatus: func.isRequired,
};

const mapStateToProps = (state) => {
  const { currentUser } = state.user;
  return {
    currentUser,
  };
};

const SetStatusMenu = compose(
  withRouter,
  injectIntl,
  connect(mapStateToProps, mapDispatchToProps)
)(SetStatusMenuComponent);

export default SetStatusMenu;
