import classNames from 'classnames';
import pickBy from 'lodash/pickBy';
import { arrayOf, bool, func, shape, string } from 'prop-types';
import React, { useCallback, useState } from 'react';
import { Form as FinalForm, FormSpy } from 'react-final-form';
import { compose } from 'redux';

import { FieldNumberInput, FixedBottomButtons, Form, SaveAndExitButton } from '../../components';
import {
  MAX_SEATS_DIVIDER,
  MIN_SEATS_DIVIDER,
} from '../../components/EditListingWorkspacePanel/EditListingWorkspacePanel';
import { ButtonNew, CustomCategorySelectFieldMaybe, HintModal } from '../../components_new';
import { BUTTON_TYPES } from '../../components_new/ButtonNew/ButtonNew';
import { isMobile } from '../../util/device';
import { useBoolean } from '../../util/hooks';
import { getLocale } from '../../util/localeHelper';
import { LANGUAGE_CODES, modernMTTranslate } from '../../util/modernmt';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import {
  COWORKING_CATEGORY,
  FIXED_OFFICE_CATEGORY,
  MEETINGROOMS_CATEGORY,
  PRIVATE_OFFICE_CATEGORY,
  STUDIO_CATEGORY,
  propTypes,
} from '../../util/types';
import { composeValidators, maximumValue, minimumValue, required } from '../../util/validators';
import { LanguageToggle, Textarea } from '../CreateLocationForm/ui';

import css from './EditListingWorkspaceForm.module.scss';

const MAXIMUM_ALLOWED_SEATS = 1000;
const MAXIMUM_ALLOWED_AREA = 99999;

const EditListingWorkspaceFormComponent = (props) => {
  const [isMoreThanOneModalOpen, setIsMoreThanOneModalOpen] = useBoolean();
  const [isDescriptionModalOpen, setIsDescriptionModalOpen] = useBoolean();
  const [isSaveEndExitButtonError, setSaveAndExitButtonError] = useState(false);

  const currentLocale = getLocale();
  const [currentLang, updateCurrentLang] = useState(currentLocale);
  const [translationLoading, toggleTranslationLoading] = useState(false);

  const getOppositeLocale = useCallback(() => {
    return currentLang === LANGUAGE_CODES.SWEDISH ? LANGUAGE_CODES.ENGLISH : LANGUAGE_CODES.SWEDISH;
  }, [currentLang]);

  return (
    <FinalForm
      {...props}
      mutators={{
        setCategory: (args, state, utils) => utils.changeValue(state, 'category', () => args[0]),
        setFixedCategory: (args, state, utils) =>
          utils.changeValue(state, 'fixedOfficeType', () => args[0]),
        setMinSeats: (args, state, utils) => utils.changeValue(state, 'minSeats', () => args[0]),
        setMaxSeats: (args, state, utils) => utils.changeValue(state, 'maxSeats', () => args[0]),
        setLanguage: (args, state, utils) => utils.changeValue(state, 'originLang', () => args[0]),
        setDescriptionEn: (args, state, utils) => utils.changeValue(state, 'en', () => args[0]),
        setDescriptionSv: (args, state, utils) => utils.changeValue(state, 'sv', () => args[0]),
      }}
      render={(formRenderProps) => {
        const {
          categoryOptions,
          className,
          disabled,
          ready,
          handleSubmit,
          intl,
          form,
          values,
          errors,
          invalid,
          pristine,
          updated,
          updateInProgress,
          fetchErrors,
          onHandleSaveAndExit,
          isPublished,
          fixedOfficeTypeOptions,
          acceptBookingOnRequest,
        } = formRenderProps;

        const handleCategoryChange = (newCategory) => {
          form.mutators.setCategory(newCategory);
          form.mutators.setFixedCategory('');
          /* Actually it contains the old value here, so we can use that to check previous state */
          if (values?.category === FIXED_OFFICE_CATEGORY) {
            form.mutators.setMinSeats('');
            form.mutators.setMaxSeats('');
          }
        };

        const handleFixedCategoryChange = (newFixedCategory) => {
          form.mutators.setCategory(FIXED_OFFICE_CATEGORY);
          form.mutators.setFixedCategory(newFixedCategory);
        };

        const handleLanguageChange = () => {
          updateCurrentLang(getOppositeLocale());
        };

        const handleDescriptionChange = (value) => {
          if (currentLang === 'en') {
            form.mutators.setDescriptionEn(value);
          } else if (currentLang === 'sv') {
            form.mutators.setDescriptionSv(value);
          }
        };
        const translate = async ({ text, target }) => {
          toggleTranslationLoading(true);
          const response = await modernMTTranslate({
            target,
            text,
          });
          const { translation } = response ? response : {};
          if (target === 'en') {
            form.mutators.setDescriptionEn(translation);
          } else if (target === 'sv') {
            form.mutators.setDescriptionSv(translation);
          }
          form.mutators.setLanguage(currentLang);
          updateCurrentLang(target);
          toggleTranslationLoading(false);
        };

        const descriptionMessage = intl.formatMessage({
          id: 'EditListingDescriptionForm.description',
        });
        const descriptionSubLabel = intl.formatMessage({
          id: 'EditListingDescriptionForm.descriptionSubLabel',
        });
        const descriptionRequiredMessage = intl.formatMessage({
          id: 'EditListingDescriptionForm.descriptionRequired',
        });

        const onSaveAndExitHandler = () => {
          const {
            description,
            category,
            originLang,
            minSeats,
            maxSeats,
            numberOfInstance,
            fixedOfficeType,
            areaMin,
            areaMax,
            en,
            sv,
          } = values;
          setSaveAndExitButtonError(false);

          const calculatedMinSeats =
            (areaMin || areaMax) && !(minSeats || maxSeats)
              ? areaMin
                ? Math.round(Number(areaMin) / MIN_SEATS_DIVIDER)
                : Math.round(Number(areaMax) / MIN_SEATS_DIVIDER)
              : null;
          const calculatedMaxSeats =
            (areaMin || areaMax) && !(minSeats || maxSeats)
              ? areaMax
                ? Math.round(Number(areaMax) / MAX_SEATS_DIVIDER)
                : Math.round(Number(areaMin) / MAX_SEATS_DIVIDER)
              : null;

          const publicData = {
            areaMin,
            areaMax,
            category,
            originLang,
            minSeats: calculatedMinSeats
              ? calculatedMinSeats
              : minSeats && minSeats > 0
              ? minSeats
              : null,
            maxSeats: calculatedMaxSeats ? calculatedMaxSeats : maxSeats > 0 ? maxSeats : null,
            numberOfInstance:
              category === PRIVATE_OFFICE_CATEGORY || MEETINGROOMS_CATEGORY
                ? numberOfInstance
                : null,
            fixedOfficeType: category !== FIXED_OFFICE_CATEGORY ? null : fixedOfficeType,
            acceptBookingOnRequest:
              category !== MEETINGROOMS_CATEGORY ? null : acceptBookingOnRequest,
            calculatedSeats: !!(calculatedMaxSeats || calculatedMinSeats),
            en: { description: en },
            sv: { description: sv },
          };

          const updateValues = pickBy({
            description: values[originLang || getLocale()],
            publicData,
          });

          onHandleSaveAndExit(updateValues);
        };

        const { updateListingError, createListingDraftError, showListingsError } =
          fetchErrors || {};
        const errorMessageUpdateListing = updateListingError ? (
          <p className={css.error}>
            <FormattedMessage id="EditListingDescriptionForm.updateFailed" />
          </p>
        ) : null;

        // This error happens only on first tab (of EditListingWizard)
        const errorMessageCreateListingDraft = createListingDraftError ? (
          <p className={css.error}>
            <FormattedMessage id="EditListingDescriptionForm.createListingDraftError" />
          </p>
        ) : null;

        const errorMessageShowListing = showListingsError ? (
          <p className={css.error}>
            <FormattedMessage id="EditListingDescriptionForm.showListingFailed" />
          </p>
        ) : null;

        const errorMessageMissingTitle = isSaveEndExitButtonError ? (
          <p className={css.error}>
            <FormattedMessage id="EditListingDescriptionForm.showSaveAndExitFailed" />
          </p>
        ) : null;

        const seatsFieldTouched = !!form?.getFieldState('maxSeats')?.touched;
        const areaFieldTouched = !!form?.getFieldState('areaMax')?.touched;

        const classes = classNames(css.root, className);
        const submitReady = (updated && pristine) || ready;
        const submitInProgress = updateInProgress;
        const seatsOrAreaRequired = values && !(values.maxSeats || values.areaMax);
        const submitDisabled =
          invalid || disabled || submitInProgress || !values.category || seatsOrAreaRequired;

        const initialLangValue = values.originLang;

        const getSeatFieldRequiredMessage = (cate) => {
          switch (cate) {
            case COWORKING_CATEGORY:
            case MEETINGROOMS_CATEGORY:
            case PRIVATE_OFFICE_CATEGORY:
              return intl.formatMessage({ id: 'EditListingWorkspaceForm.seatsRequired.seat' });
            case FIXED_OFFICE_CATEGORY:
              return intl.formatMessage({
                id: 'EditListingWorkspaceForm.seatsRequired.fixedOffice',
              });
            default: {
              return intl.formatMessage({ id: 'EditListingWorkspaceForm.seatsRequired.guest' });
            }
          }
        };

        const seatLabel = intl.formatMessage({
          id: `EditListingWorkspaceForm.seatsLabel.${
            values?.category !== STUDIO_CATEGORY ? 'seat' : 'guest'
          }`,
        });
        const roomLabel = intl.formatMessage({ id: 'EditListingWorkspaceForm.seatsLabel.room' });

        const seatFieldRequiredMessage = values && getSeatFieldRequiredMessage(values?.category);
        const roomFieldRequiredMessage = intl.formatMessage({
          id: 'EditListingWorkspaceForm.seatsRequired.room',
        });

        const seatFieldSubLabel = intl.formatMessage({
          id: `EditListingWorkspaceForm.seatsSubLabel.${
            values?.category !== STUDIO_CATEGORY ? 'seat' : 'guest'
          }`,
        });

        const areaLabel = intl.formatMessage({ id: `EditListingWorkspaceForm.areaLabel` });
        const areaFieldSubLabel = intl.formatMessage({
          id: `EditListingWorkspaceForm.areaSubLabel`,
        });

        const rightBtnText = isPublished
          ? intl.formatMessage({ id: 'EditListing.saveButton' })
          : intl.formatMessage({ id: 'EditListing.submitButton' });

        const modalContent = {
          moreThanOne: {
            header: intl.formatMessage({ id: 'EditListing.hint.moreThanOne.header' }),
            body: intl.formatMessage({ id: 'EditListing.hint.moreThanOne.body' }),
            centerAlign: true,
          },
          description: {
            header: intl.formatMessage({ id: 'EditListing.hint.description.header' }),
            body: (
              <FormattedMessage
                id="EditListing.hint.description.body"
                values={{
                  h5: (msg) => <h5 className={css.hintDescriptionHeader}>{msg}</h5>,
                  p: (msg) => <p className={css.hintDescriptionBody}>{msg}</p>,
                }}
              />
            ),
            centerAlign: false,
          },
        };

        return (
          <Form className={classes} onSubmit={handleSubmit}>
            <FormSpy
              onChange={({ values }) => {
                if (values?.areaMax === 0) {
                  form.change('areaMax', null);
                }
              }}
            />
            <SaveAndExitButton onClick={onSaveAndExitHandler} />
            {errorMessageMissingTitle}
            {errorMessageCreateListingDraft}
            {errorMessageUpdateListing}
            {errorMessageShowListing}

            <h4 className={css.grayContainerTitle}>
              {intl.formatMessage({
                id: 'EditListingWorkspaceForm.typeOfSpace',
              })}
            </h4>
            <div className={css.grayContainer}>
              <CustomCategorySelectFieldMaybe
                id="fixedOfficeType"
                name="fixedOfficeType"
                categoryOptions={fixedOfficeTypeOptions}
                intl={intl}
                form={form}
                categoryInitialOption={values.fixedOfficeType}
                onCategoryChange={handleFixedCategoryChange}
                title={intl.formatMessage({ id: 'EditListing.fixedOfficeTitle' })}
                onClick={setIsMoreThanOneModalOpen.on}
                hintTitle={intl.formatMessage({ id: 'EditListing.hint.moreThanOne.button' })}
              />

              <CustomCategorySelectFieldMaybe
                id="category"
                name="category"
                categoryOptions={categoryOptions.filter((o) => o.key !== 'fixed')}
                intl={intl}
                form={form}
                validate={required()}
                categoryInitialOption={values.category}
                onCategoryChange={handleCategoryChange}
                title={intl.formatMessage({ id: 'EditListing.onDemandTitle' })}
              />
            </div>

            {!!values.category && (
              <>
                <fieldset className={css.fieldset}>
                  <div className={css.descriptionFieldLabel}>
                    {descriptionMessage}
                    <p className={css.getInspiration} onClick={setIsDescriptionModalOpen.on}>
                      {intl.formatMessage({ id: 'EditListing.hint.description.button' })}
                    </p>
                  </div>
                  <LanguageToggle onChangeLang={handleLanguageChange} currentLang={currentLang} />
                  <Textarea
                    value={values[currentLang] || ''}
                    onInput={(value) => handleDescriptionChange(value)}
                    charLimit={1500}
                    placeholder={intl.formatMessage({
                      id: 'CreateLocationForm.description.placeholder',
                    })}
                    id="description"
                  />
                  <div className={css.translateBtnWrapper}>
                    <ButtonNew
                      type={BUTTON_TYPES.GREEN}
                      inProgress={translationLoading}
                      onClick={() =>
                        translate({
                          text: values[currentLang],
                          target: getOppositeLocale(),
                        })
                      }
                      disabled={
                        values?.[`description_${currentLocale}`]?.length === 0 ||
                        currentLang !== currentLocale
                      }
                      nativeType="button"
                    >
                      {intl.formatMessage({ id: 'CreateLocationForm.description.translateBtn' })}
                    </ButtonNew>
                  </div>
                </fieldset>
                {values.category === FIXED_OFFICE_CATEGORY && (
                  <>
                    <div className={css.areaWrapper}>
                      <FieldNumberInput
                        id="areaMin"
                        name="areaMin"
                        label={areaLabel}
                        className={classNames(css.numberInputWrapper, css.minAreaInputWrapper)}
                        subLabel={!isMobile ? areaFieldSubLabel : null}
                        subLabelClassName={css.fieldText}
                        inline
                        placeholder="min"
                      />
                      <FieldNumberInput
                        id="areaMax"
                        name="areaMax"
                        label={values?.category !== FIXED_OFFICE_CATEGORY ? areaLabel : null}
                        className={classNames(css.numberInputWrapper, css.maxAreaInputWrapper)}
                        subLabel={!isMobile ? '-' : null}
                        inline
                        validate={
                          areaFieldTouched && !values.maxSeats
                            ? composeValidators(
                                minimumValue(
                                  intl.formatMessage(
                                    { id: 'EditListingWorkspaceForm.maxAreaError' },
                                    { num: values.minArea || 1 }
                                  ),
                                  values?.minArea || 1
                                ),
                                maximumValue(
                                  intl.formatMessage(
                                    { id: 'EditListingWorkspaceForm.maxAreaMaximumError' },
                                    { num: MAXIMUM_ALLOWED_AREA }
                                  ),
                                  MAXIMUM_ALLOWED_AREA
                                )
                              )
                            : undefined
                        }
                        placeholder="max"
                        maxLength="6"
                      />
                    </div>
                    {!!errors.areaMax && areaFieldTouched && (
                      <p className={css.errorMessage}>{errors.areaMax}</p>
                    )}
                  </>
                )}
                <div className={css.seatsWrapper}>
                  {values?.category === FIXED_OFFICE_CATEGORY && (
                    <FieldNumberInput
                      id="minSeats"
                      name="minSeats"
                      label={seatLabel}
                      className={classNames(css.numberInputWrapper, css.minSeatsInputWrapper)}
                      subLabel={!isMobile ? seatFieldSubLabel : null}
                      subLabelClassName={css.fieldText}
                      inline
                      placeholder="min"
                    />
                  )}
                  <FieldNumberInput
                    id="maxSeats"
                    name="maxSeats"
                    label={values?.category !== FIXED_OFFICE_CATEGORY ? seatLabel : null}
                    className={classNames(css.numberInputWrapper, css.maxSeatsInputWrapper)}
                    subLabel={
                      !isMobile && values?.category !== FIXED_OFFICE_CATEGORY
                        ? seatFieldSubLabel
                        : values?.category === FIXED_OFFICE_CATEGORY
                        ? '-'
                        : null
                    }
                    inline
                    validate={
                      seatsFieldTouched && values.maxSeats > 0 && !values.areaMax
                        ? composeValidators(
                            minimumValue(
                              intl.formatMessage(
                                { id: 'EditListingWorkspaceForm.maxSeatsError' },
                                { num: values.seatsMin || 1 }
                              ),
                              values?.seatsMin || 1
                            ),
                            maximumValue(
                              intl.formatMessage(
                                { id: 'EditListingWorkspaceForm.maxSeatsMaximumError' },
                                { num: MAXIMUM_ALLOWED_SEATS }
                              ),
                              MAXIMUM_ALLOWED_SEATS
                            )
                          )
                        : undefined
                    }
                    placeholder="max"
                    maxLength="4"
                  />
                </div>
                {!!errors.maxSeats && seatsFieldTouched && (
                  <p className={css.errorMessage}>{errors.maxSeats}</p>
                )}

                {(values?.category === MEETINGROOMS_CATEGORY ||
                  values?.category === PRIVATE_OFFICE_CATEGORY) && (
                  <FieldNumberInput
                    id="numberOfInstance"
                    name="numberOfInstance"
                    maxLength="3"
                    label={roomLabel}
                    className={css.numberInputWrapper}
                    subLabel={
                      !isMobile
                        ? intl.formatMessage({ id: 'EditListingWorkspaceForm.seatsSubLabel.room' })
                        : null
                    }
                    inline
                    validate={required(roomFieldRequiredMessage)}
                  />
                )}
              </>
            )}

            <FixedBottomButtons
              leftBtnText={intl.formatMessage({ id: 'EditListing.backButton' })}
              rightBtnText={rightBtnText}
              rightBtnType="submit"
              rightBtnDisabled={submitDisabled}
              rightBtnInProgress={submitInProgress}
              rightBtnReady={submitReady}
            />
            <HintModal
              isOpen={isMoreThanOneModalOpen}
              onClose={setIsMoreThanOneModalOpen.off}
              contentHeader={modalContent.moreThanOne.header}
              contentBody={modalContent.moreThanOne.body}
              centerAlign={modalContent.moreThanOne.centerAlign}
            />
            <HintModal
              isOpen={isDescriptionModalOpen}
              onClose={setIsDescriptionModalOpen.off}
              contentHeader={modalContent.description.header}
              contentBody={modalContent.description.body}
              centerAlign={modalContent.description.centerAlign}
            />
          </Form>
        );
      }}
    />
  );
};

EditListingWorkspaceFormComponent.defaultProps = { className: null, fetchErrors: null };

EditListingWorkspaceFormComponent.propTypes = {
  className: string,
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    createListingDraftError: propTypes.error,
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
  categoryOptions: arrayOf(
    shape({
      key: string.isRequired,
      label: string.isRequired,
    })
  ),
};

export default compose(injectIntl)(EditListingWorkspaceFormComponent);
