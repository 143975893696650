import React from 'react';
import css from './OurCustomers.module.scss';
import { isMobile } from '../../util/device';
import classNames from 'classnames';
import worldfavorLogo from './worldfavor_logo.png';
import rcoLogo from './rco_logo.png';
import hmLogo from './h&m_logo.png';
import cdlpLogo from './cdlp_logo.png';
import alpresorLogo from './alpresor_logo.png';
import tengbomLogo from './tengbom_logo.png';
import { FormattedMessage } from 'react-intl';

export const OurCustomers = () => {
  return (
    <section className={css.section}>
      <div className={css.wrapper}>
        <h2 className={css.title}>
          <FormattedMessage id="OurCustomers.header" />
        </h2>
        <div className={classNames(css.icons, isMobile ? css.mobile : css.desktop)}>
          <div className={css.iconWrapper}>
            <img src={worldfavorLogo} className={css.worldfavorLogo} alt="worldfavor Logo"></img>
          </div>
          <div className={css.iconWrapper}>
            <img src={rcoLogo} className={css.rcoLogo} alt="RCO Logo"></img>
          </div>
          <div className={css.iconWrapper}>
            <img src={hmLogo} className={css.hmLogo} alt="H&M Logo"></img>
          </div>
          <div className={css.iconWrapper}>
            <img src={cdlpLogo} className={css.cdlpLogo} alt="CDLP Logo"></img>
          </div>
          <div className={css.iconWrapper}>
            <img src={alpresorLogo} className={css.alpresorLogo} alt="Alpresor Logo"></img>
          </div>
          <div className={css.iconWrapper}>
            <img src={tengbomLogo} className={css.tengbomLogo} alt="Tengbom Logo"></img>
          </div>
        </div>
      </div>
    </section>
  );
};
