import React from 'react';
import { func, oneOfType, shape, node } from 'prop-types';

import css from './SearchButton.module.scss';

const SearchButton = ({ buttonRef }) => (
  <button ref={buttonRef} type="submit" className={css.searchButton}>
    <svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6.51669 12.9982C2.92091 12.9982 0 10.077 0 6.49386C0 2.91068 2.92091 0 6.51669 0C10.1125 0 13.0334 2.91068 13.0334 6.49386C13.0334 10.077 10.1125 12.9982 6.51669 12.9982ZM6.51669 1.43958C3.72231 1.43958 1.44464 3.70928 1.44464 6.49386C1.44464 9.27845 3.72231 11.5587 6.51669 11.5587C9.31106 11.5587 11.5993 9.28895 11.5993 6.49386C11.5993 3.69877 9.3216 1.43958 6.51669 1.43958Z"
        fill="white"
      />
      <path
        d="M17.2733 18C17.0835 18 16.9042 17.9265 16.7671 17.7899L11.2838 12.3258C10.9991 12.0421 10.9991 11.5902 11.2838 11.3065C11.5686 11.0228 12.022 11.0228 12.3067 11.3065L17.79 16.7706C18.0747 17.0543 18.0747 17.5062 17.79 17.7899C17.6529 17.9265 17.4631 18 17.2733 18Z"
        fill="white"
      />
    </svg>
  </button>
);

SearchButton.propTypes = {
  buttonRef: oneOfType([func, shape({ node })]),
};

export default SearchButton;
