import React, { Component } from 'react';
import { bool, string } from 'prop-types';
import { compose } from 'redux';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { Field, Form as FinalForm } from 'react-final-form';
import isEqual from 'lodash/isEqual';
import classNames from 'classnames';
import { ensureCurrentUser } from '../../util/data';
import { propTypes } from '../../util/types';
import * as validators from '../../util/validators';
import { isUploadImageOverLimitError } from '../../util/errors';
import {
  Form,
  Avatar,
  PrimaryButton,
  ImageFromFile,
  IconSpinner,
  FieldTextInput,
} from '../../components';

import css from './ProfileSettingsForm.module.scss';

const ACCEPT_IMAGES = 'image/*';
const UPLOAD_CHANGE_DELAY = 2000; // Show spinner so that browser has time to load img srcset

class ProfileSettingsFormComponent extends Component {
  constructor(props) {
    super(props);

    this.uploadDelayTimeoutId = null;
    this.state = { uploadDelay: false };
    this.submittedValues = {};
  }

  componentDidUpdate(prevProps) {
    // Upload delay is additional time window where Avatar is added to the DOM,
    // but not yet visible (time to load image URL from srcset)
    if (prevProps.uploadInProgress && !this.props.uploadInProgress) {
      this.setState({ uploadDelay: true });
      this.uploadDelayTimeoutId = window.setTimeout(() => {
        this.setState({ uploadDelay: false });
      }, UPLOAD_CHANGE_DELAY);
    }
  }

  componentWillUnmount() {
    window.clearTimeout(this.uploadDelayTimeoutId);
  }

  render() {
    return (
      <FinalForm
        {...this.props}
        render={(fieldRenderProps) => {
          const {
            className,
            currentUser,
            handleSubmit,
            intl,
            invalid,
            onImageUpload,
            pristine,
            profileImage,
            rootClassName,
            updateInProgress,
            updateProfileError,
            uploadImageError,
            uploadInProgress,
            form,
            values,
            activeField,
            setActiveField,
          } = fieldRenderProps;

          const user = ensureCurrentUser(currentUser);

          // First name
          const firstNameLabel = intl.formatMessage({
            id: 'ProfileSettingsForm.firstNameLabel',
          });
          const firstNamePlaceholder = intl.formatMessage({
            id: 'ProfileSettingsForm.firstNamePlaceholder',
          });
          const firstNameRequiredMessage = intl.formatMessage({
            id: 'ProfileSettingsForm.firstNameRequired',
          });
          const firstNameRequired = validators.required(firstNameRequiredMessage);

          // Last name
          const lastNameLabel = intl.formatMessage({
            id: 'ProfileSettingsForm.lastNameLabel',
          });
          const lastNamePlaceholder = intl.formatMessage({
            id: 'ProfileSettingsForm.lastNamePlaceholder',
          });
          const lastNameRequiredMessage = intl.formatMessage({
            id: 'ProfileSettingsForm.lastNameRequired',
          });
          const lastNameRequired = validators.required(lastNameRequiredMessage);

          // Bio
          // const bioLabel = intl.formatMessage({
          //   id: 'ProfileSettingsForm.bioLabel',
          // });
          // const bioPlaceholder = intl.formatMessage({
          //   id: 'ProfileSettingsForm.bioPlaceholder',
          // });

          const uploadingOverlay =
            uploadInProgress || this.state.uploadDelay ? (
              <div className={css.uploadingImageOverlay}>
                <IconSpinner />
              </div>
            ) : null;

          const hasUploadError = !!uploadImageError && !uploadInProgress;
          const errorClasses = classNames({ [css.avatarUploadError]: hasUploadError });
          const transientUserProfileImage = profileImage.uploadedImage || user.profileImage;
          const transientUser = { ...user, profileImage: transientUserProfileImage };

          // Ensure that file exists if imageFromFile is used
          const fileExists = !!profileImage.file;
          const fileUploadInProgress = uploadInProgress && fileExists;
          const delayAfterUpload = profileImage.imageId && this.state.uploadDelay;
          const imageFromFile =
            fileExists && (fileUploadInProgress || delayAfterUpload) ? (
              <ImageFromFile
                id={profileImage.id}
                className={errorClasses}
                rootClassName={css.uploadingImage}
                aspectRatioClassName={css.squareAspectRatio}
                file={profileImage.file}
              >
                {uploadingOverlay}
              </ImageFromFile>
            ) : null;

          // Avatar is rendered in hidden during the upload delay
          // Upload delay smoothes image change process:
          // responsive img has time to load srcset stuff before it is shown to user.
          const avatarClasses = classNames(errorClasses, css.avatar, {
            [css.avatarInvisible]: this.state.uploadDelay,
          });
          const avatarComponent =
            !fileUploadInProgress && profileImage.imageId ? (
              <Avatar
                className={avatarClasses}
                renderSizes="(max-width: 767px) 96px, 240px"
                user={transientUser}
                disableProfileLink
              />
            ) : null;

          const chooseAvatarLabel =
            profileImage.imageId || fileUploadInProgress ? (
              <div className={css.avatarContainer}>
                {imageFromFile}
                {avatarComponent}
                <div className={css.changeAvatar}>
                  <FormattedMessage id="ProfileSettingsForm.changeAvatar" />
                </div>
              </div>
            ) : (
              <div className={css.avatarPlaceholder}>
                <div className={css.avatarPlaceholderText}>
                  <FormattedMessage id="ProfileSettingsForm.addYourProfilePicture" />
                </div>
                <div className={css.avatarPlaceholderTextMobile}>
                  <FormattedMessage id="ProfileSettingsForm.addYourProfilePictureMobile" />
                </div>
              </div>
            );

          const submitError = updateProfileError ? (
            <div className={css.error}>
              <FormattedMessage id="ProfileSettingsForm.updateProfileFailed" />
            </div>
          ) : null;

          const classes = classNames(rootClassName || css.root, className);
          const submitInProgress = updateInProgress;
          const submittedOnce = Object.keys(this.submittedValues).length > 0;
          const pristineSinceLastSubmit = submittedOnce && isEqual(values, this.submittedValues);
          const submitDisabled =
            invalid || pristine || pristineSinceLastSubmit || uploadInProgress || submitInProgress;

          return (
            <Form
              className={classes}
              onSubmit={(e) => {
                this.submittedValues = values;
                handleSubmit(e);
              }}
            >
              <div className={css.sectionContainer}>
                <div className={css.profileImageSection}>
                  <h3 className={css.sectionTitle}>
                    <FormattedMessage id="ProfileSettingsForm.photo" />
                    <div className={css.photoDiv}>
                      <div
                        className={css.photoTip}
                        tooltip-text={intl.formatMessage({
                          id: 'ProfileSettingsForm.photoTooltip',
                        })}
                      >
                        <svg
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g opacity="0.3">
                            <path
                              d="M8.492 6.91092C8.46974 6.78797 8.40225 6.67782 8.30283 6.60214C8.20341 6.52647 8.07926 6.49076 7.95482 6.50205C7.83039 6.51334 7.71469 6.57081 7.63051 6.66314C7.54633 6.75547 7.49977 6.87598 7.5 7.00092V11.5029L7.508 11.5929C7.53026 11.7159 7.59775 11.826 7.69717 11.9017C7.79659 11.9774 7.92074 12.0131 8.04518 12.0018C8.16961 11.9905 8.28531 11.933 8.36949 11.8407C8.45367 11.7484 8.50023 11.6279 8.5 11.5029V7.00092L8.492 6.91092Z"
                              fill="black"
                            />
                            <path
                              d="M8.79907 4.75C8.79907 4.55109 8.72006 4.36032 8.5794 4.21967C8.43875 4.07902 8.24798 4 8.04907 4C7.85016 4 7.65939 4.07902 7.51874 4.21967C7.37809 4.36032 7.29907 4.55109 7.29907 4.75C7.29907 4.94891 7.37809 5.13968 7.51874 5.28033C7.65939 5.42098 7.85016 5.5 8.04907 5.5C8.24798 5.5 8.43875 5.42098 8.5794 5.28033C8.72006 5.13968 8.79907 4.94891 8.79907 4.75Z"
                              fill="black"
                            />
                            <path
                              d="M16 8C16 5.87827 15.1571 3.84344 13.6569 2.34315C12.1566 0.842855 10.1217 0 8 0C5.87827 0 3.84344 0.842855 2.34315 2.34315C0.842855 3.84344 0 5.87827 0 8C0 10.1217 0.842855 12.1566 2.34315 13.6569C3.84344 15.1571 5.87827 16 8 16C10.1217 16 12.1566 15.1571 13.6569 13.6569C15.1571 12.1566 16 10.1217 16 8ZM1 8C1 7.08075 1.18106 6.1705 1.53284 5.32122C1.88463 4.47194 2.40024 3.70026 3.05025 3.05025C3.70026 2.40024 4.47194 1.88463 5.32122 1.53284C6.1705 1.18106 7.08075 1 8 1C8.91925 1 9.8295 1.18106 10.6788 1.53284C11.5281 1.88463 12.2997 2.40024 12.9497 3.05025C13.5998 3.70026 14.1154 4.47194 14.4672 5.32122C14.8189 6.1705 15 7.08075 15 8C15 9.85652 14.2625 11.637 12.9497 12.9497C11.637 14.2625 9.85652 15 8 15C6.14348 15 4.36301 14.2625 3.05025 12.9497C1.7375 11.637 1 9.85652 1 8Z"
                              fill="black"
                            />
                          </g>
                        </svg>
                      </div>
                    </div>
                    <div className={css.addPhotoInfo}>
                      <FormattedMessage id="ProfileSettingsForm.addPhotoInfo" />
                    </div>
                  </h3>
                  <Field
                    accept={ACCEPT_IMAGES}
                    id="profileImage"
                    name="profileImage"
                    label={chooseAvatarLabel}
                    type="file"
                    form={null}
                    uploadImageError={uploadImageError}
                    disabled={uploadInProgress}
                  >
                    {(fieldProps) => {
                      const { accept, id, input, label, disabled, uploadImageError } = fieldProps;
                      const { name, type } = input;
                      const onChange = (e) => {
                        const file = e.target.files[0];
                        form.change(`profileImage`, file);
                        form.blur(`profileImage`);
                        if (file != null) {
                          const tempId = `${file.name}_${Date.now()}`;
                          onImageUpload({ id: tempId, file }).then(() => {
                            handleSubmit();
                          });
                        }
                      };

                      let error = null;

                      if (isUploadImageOverLimitError(uploadImageError)) {
                        error = (
                          <div className={css.error}>
                            <FormattedMessage id="ProfileSettingsForm.imageUploadFailedFileTooLarge" />
                          </div>
                        );
                      } else if (uploadImageError) {
                        error = (
                          <div className={css.error}>
                            <FormattedMessage id="ProfileSettingsForm.imageUploadFailed" />
                          </div>
                        );
                      }

                      return (
                        <div className={css.uploadAvatarWrapper}>
                          <label className={css.label} htmlFor={id}>
                            <div className={css.avatarWrapper}>
                              <div className={css.avatarChangeWrapper}>
                                <div className={css.avatarChange}>
                                  <svg
                                    width="14"
                                    height="11"
                                    viewBox="0 0 14 11"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      opacity="0.8"
                                      d="M12.7037 1.69231H10.5L9.975 0.32033C9.93873 0.226449 9.87226 0.145299 9.78467 0.0879661C9.69709 0.0306329 9.59266 -8.8033e-05 9.48565 1.89482e-07H4.51435C4.2956 1.89482e-07 4.09954 0.128434 4.02662 0.32033L3.5 1.69231H1.2963C0.580093 1.69231 0 2.23324 0 2.9011V9.79121C0 10.4591 0.580093 11 1.2963 11H12.7037C13.4199 11 14 10.4591 14 9.79121V2.9011C14 2.23324 13.4199 1.69231 12.7037 1.69231ZM7 8.58242C5.56759 8.58242 4.40741 7.50055 4.40741 6.16483C4.40741 4.82912 5.56759 3.74725 7 3.74725C8.43241 3.74725 9.59259 4.82912 9.59259 6.16483C9.59259 7.50055 8.43241 8.58242 7 8.58242ZM5.44444 6.16483C5.44444 6.54954 5.60833 6.9185 5.90006 7.19053C6.19178 7.46256 6.58744 7.61538 7 7.61538C7.41256 7.61538 7.80822 7.46256 8.09994 7.19053C8.39167 6.9185 8.55556 6.54954 8.55556 6.16483C8.55556 5.78013 8.39167 5.41117 8.09994 5.13914C7.80822 4.86711 7.41256 4.71429 7 4.71429C6.58744 4.71429 6.19178 4.86711 5.90006 5.13914C5.60833 5.41117 5.44444 5.78013 5.44444 6.16483Z"
                                      fill="white"
                                    />
                                  </svg>
                                </div>
                              </div>

                              <Avatar
                                className={css.avatarContainer}
                                user={currentUser}
                                disableProfileLink
                              />
                              {uploadingOverlay}
                            </div>
                          </label>
                          <input
                            accept={accept}
                            id={id}
                            name={name}
                            className={css.uploadAvatarInput}
                            disabled={disabled}
                            onChange={onChange}
                            type={type}
                          />
                          {error}
                        </div>
                      );
                    }}
                  </Field>
                </div>
              </div>
              <div
                className={classNames(
                  css.sectionContainerName,
                  activeField === 'name' ? css.sectionContainerOpen : css.sectionContainerClosed
                )}
              >
                <div
                  className={css.sectionContainerCollapsible}
                  onClick={() =>
                    activeField === 'name' ? setActiveField(false) : setActiveField('name')
                  }
                />
                <svg
                  className={classNames(
                    css.sectionContainerNameIcon,
                    activeField === 'name' && css.iconClose
                  )}
                  width="9"
                  height="15"
                  viewBox="0 0 9 15"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M1 14L7.5 7.5L1 1" stroke="#706F6F" />
                </svg>
                <h3 className={css.sectionTitle}>
                  <FormattedMessage id="ProfileSettingsForm.name" />
                </h3>
                <h3 className={css.userFullName}>
                  {currentUser.attributes.profile.firstName}{' '}
                  {currentUser.attributes.profile.lastName}
                </h3>
                <div className={css.nameContainer}>
                  <FieldTextInput
                    className={css.firstName}
                    type="text"
                    id="firstName"
                    name="firstName"
                    label={firstNameLabel}
                    placeholder={firstNamePlaceholder}
                    validate={firstNameRequired}
                  />
                  <FieldTextInput
                    className={css.lastName}
                    type="text"
                    id="lastName"
                    name="lastName"
                    label={lastNameLabel}
                    placeholder={lastNamePlaceholder}
                    validate={lastNameRequired}
                  />
                </div>
                {submitError}
                <div className={css.buttonWrapper}>
                  <PrimaryButton
                    className={css.submitButton}
                    type="submit"
                    inProgress={submitInProgress}
                    disabled={submitDisabled}
                    ready={pristineSinceLastSubmit}
                  >
                    <FormattedMessage id="ProfileSettingsForm.saveChanges" />
                  </PrimaryButton>
                  <button
                    className={css.discardButton}
                    onClick={(e) => {
                      {
                        e.preventDefault();
                        form.change('firstName', currentUser.attributes.profile.firstName);
                        form.change('lastName', currentUser.attributes.profile.lastName);
                      }
                    }}
                  >
                    <FormattedMessage id="ContactDetailsForm.discardChanges" />
                  </button>
                </div>
              </div>
              {/* <div className={classNames(css.sectionContainer, css.lastSection)}>
                <h3 className={css.sectionTitle}>
                  <FormattedMessage id="ProfileSettingsForm.bioHeading" />
                </h3>
                <FieldTextInput
                  type="textarea"
                  id="bio"
                  name="bio"
                  label={bioLabel}
                  placeholder={bioPlaceholder}
                />
                <p className={css.bioInfo}>
                  <FormattedMessage id="ProfileSettingsForm.bioInfo" />
                </p>
              </div> */}
              {/* {submitError}
              <Button
                className={css.submitButton}
                type="submit"
                inProgress={submitInProgress}
                disabled={submitDisabled}
                ready={pristineSinceLastSubmit}
              >
                <FormattedMessage id="ProfileSettingsForm.saveChanges" />
              </Button> */}
            </Form>
          );
        }}
      />
    );
  }
}

ProfileSettingsFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  uploadImageError: null,
  updateProfileError: null,
  updateProfileReady: false,
};

ProfileSettingsFormComponent.propTypes = {
  rootClassName: string,
  className: string,

  uploadImageError: propTypes.error,
  uploadInProgress: bool.isRequired,
  updateInProgress: bool.isRequired,
  updateProfileError: propTypes.error,
  updateProfileReady: bool,

  // from injectIntl
  intl: intlShape.isRequired,
};

const ProfileSettingsForm = compose(injectIntl)(ProfileSettingsFormComponent);

ProfileSettingsForm.displayName = 'ProfileSettingsForm';

export default ProfileSettingsForm;
