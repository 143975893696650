import classNames from 'classnames';
import React from 'react';
import { injectIntl } from 'react-intl';
import agv from '../../assets/spaces/agv.png';
import Elite from '../../assets/spaces/Elite.png';
import helio from '../../assets/spaces/helio.png';
import IKAP from '../../assets/spaces/IKAP.png';
import Lamb from '../../assets/spaces/LAMB.png';
import No18 from '../../assets/spaces/No18.png';
import regus from '../../assets/spaces/regus.png';
import spaces from '../../assets/spaces/spaces.png';
import US from '../../assets/spaces/US.png';
import wework from '../../assets/spaces/wework.png';
import CDLP from '../../assets/spaces/CDLP.png';
import { isMobile } from '../../util/device';

import css from './SectionTrustedBy.module.scss';

const SectionTrustedBy = ({ intl, background = 'dark' }) => {
  const INTL_SECTION_NAME = 'RespacesPass.trustedBy';

  const imagesTop = isMobile
    ? [
        { src: No18, alt: 'No18 logo' },
        { src: IKAP, alt: 'I know a place logo' },
        { src: US, alt: 'United Spaces logo' },
        { src: Lamb, alt: 'Lamb logo' },
        { src: Elite, alt: 'Elite logo' },
        { src: regus, alt: 'Regus logo' },
        { src: wework, alt: 'wework logo' },
        { src: agv, alt: 'AGV logo' },
        { src: spaces, alt: 'Spaces logo' },
        { src: CDLP, alt: 'CDLP' },
        { src: helio, alt: 'Helio logo' },
      ]
    : [
        { src: No18, alt: 'No18 logo' },
        { src: IKAP, alt: 'I know a place logo' },
        { src: US, alt: 'United Spaces logo' },
        { src: Lamb, alt: 'Lamb logo' },
        { src: Elite, alt: 'Elite logo' },
        { src: CDLP, alt: 'CDLP' },
      ];
  const imagesBottom = [
    { src: spaces, alt: 'Spaces logo' },
    { src: helio, alt: 'Helio logo' },
    { src: agv, alt: 'AGV logo' },
    { src: regus, alt: 'Regus logo' },
    { src: wework, alt: 'wework logo' },
  ];

  return (
    <section className={classNames(css.section, { [css.white]: background === 'white' })}>
      <div className={classNames(css.container, isMobile ? css.mobile : css.desktop)}>
        <h3 className={css.title}>{intl.formatMessage({ id: `${INTL_SECTION_NAME}.title` })}</h3>
        <div className={classNames(css.icons, { [css.desktop]: !isMobile })}>
          {imagesTop.map((image, index) => (
            <div key={index} className={css.iconWrapper}>
              {/* eslint-disable-next-line jsx-a11y/alt-text */}
              <img {...image} />
            </div>
          ))}
        </div>
        {!isMobile && (
          <div className={classNames(css.icons, css.bottomRow)}>
            {imagesBottom.map((image, index) => (
              <div key={index} className={css.iconWrapper}>
                {/* eslint-disable-next-line jsx-a11y/alt-text */}
                <img {...image} />
              </div>
            ))}
          </div>
        )}
      </div>
    </section>
  );
};

export default injectIntl(SectionTrustedBy);
