import React, { useState } from 'react';
import classNames from 'classnames';
import { bool, func, number, object, string } from 'prop-types';

import { ButtonNew } from '../index';
import { BUTTON_SIZES, BUTTON_TYPES, LINK_COLORS } from '../ButtonNew/ButtonNew';
import {
  MY_SUBSCRIPTION,
  MY_SUBSCRIPTION_ADD_ONE_TIME_CREDITS_ACTION,
  PACKAGE_CREDITS,
  PACKAGE_FREE,
  PACKAGE_SUBSCRIPTION,
  SUBSCRIPTION_OPTIONS,
} from '../../util/types';
import routeConfiguration from '../../routeConfiguration';
import { createResourceLocatorString } from '../../util/routes';
import { formatMoneyWithSpace } from '../../util/currency';
import PackageModel from '../../models/PackageModel';
import css from './PricingCardNew.module.scss';

const PricingCardNew = ({
  credits,
  pricePerMonth,
  pricePerCredit,
  averageBookings,
  onRouteChange,
  freeTrialRouteChange,
  intl,
  history,
  id,
  totalCredits,
  updateTotalCredits,
  isCompanyAccount,
  selectedPackage,
  updateSelectedPackage,
  updateCalculatedPrices,
  freeTrialLoading,
}) => {
  const [bindingPeriod, setBindingPeriod] = useState(1);
  const [buyCreditsTotal, setBuyCreditsTotal] = useState(
    totalCredits && selectedPackage === PACKAGE_CREDITS ? totalCredits : credits
  );
  const [subscriptionCreditsTotal, setSubscriptionCreditsTotal] = useState(
    totalCredits && SUBSCRIPTION_OPTIONS.includes(selectedPackage) ? totalCredits : credits
  );

  const isMonthly = id === PACKAGE_SUBSCRIPTION;
  const isFreeTrial = id === PACKAGE_FREE;

  const submitHandler = () => {
    updateSelectedPackage(isMonthly ? activeSubscription.id : id);

    updateTotalCredits(isMonthly ? subscriptionCreditsTotal : buyCreditsTotal);
    updateCalculatedPrices({ originalPrice: totalPrice });
    if (id === 'buy_credits') {
      if (isCompanyAccount) {
        history.push(
          createResourceLocatorString(
            'SubscriptionManagementPageMySubscription',
            routeConfiguration(),
            {
              tab: MY_SUBSCRIPTION,
              action: MY_SUBSCRIPTION_ADD_ONE_TIME_CREDITS_ACTION,
            }
          )
        );
      } else {
        history.push(createResourceLocatorString('CompanyAccount', routeConfiguration()), {
          from: 'QuotePage',
        });
      }
    } else if (isMonthly) {
      onRouteChange({
        credits: subscriptionCreditsTotal,
        pricePerMonth: totalPrice,
        creditType: id,
      });
    } else {
      freeTrialRouteChange();
    }
  };

  const pricingCards = PackageModel.LIST.PRICING.map((p) => ({
    ...p,
  }));
  const activeSubscription = pricingCards.find((obj) => {
    return obj.id === `months_${bindingPeriod}`;
  });
  const selectOptions =
    pricingCards &&
    pricingCards.map((option) => (
      <option key={`packageOption${option.value}Months`} value={option.value}>
        {intl.formatMessage(
          { id: `RespacesPass.pricing.card.option${option.value}` },
          { benefit: option.benefit }
        )}
      </option>
    ));
  const totalPrice = !isMonthly
    ? pricePerCredit * buyCreditsTotal
    : activeSubscription.pricePerCredit * subscriptionCreditsTotal;
  const bookingsPerMonth = isFreeTrial
    ? averageBookings
    : Math.floor((isMonthly ? subscriptionCreditsTotal : buyCreditsTotal) / 6);
  return (
    <div className={classNames(css.card, isFreeTrial && css.freeTrial)}>
      <div className={css.creditsWrapper}>
        <span className={css.credits}>
          {intl.formatMessage({ id: `RespacesPass.pricing.card.${id}` })}
        </span>
        {isFreeTrial ? (
          <>
            <div className={classNames(css.priceContainer, css.freeTrial)}>
              <span className={css.currency}>
                {intl.formatMessage(
                  { id: 'RespacesPass.pricing.card.freeTrialCredits' },
                  {
                    value: credits,
                    bold: (val) => <span className={classNames(css.price)}>{val}</span>,
                  }
                )}
              </span>
            </div>
            <span className={css.period}>
              {intl.formatMessage(
                { id: 'RespacesPass.pricing.card.freeTrialValue' },
                { value: pricePerMonth }
              )}
            </span>
          </>
        ) : (
          <>
            <div className={css.priceContainer}>
              <span className={css.currency}>
                {intl.formatMessage({ id: 'RespacesPass.pricing.card.currency' })}
              </span>{' '}
              <span className={css.price}>{formatMoneyWithSpace(totalPrice.toFixed(2))}</span>
              {isMonthly ? (
                <span className={css.period}>
                  {intl.formatMessage({ id: 'RespacesPass.pricing.card.perMonthSup' })}
                  <sup>*</sup>
                </span>
              ) : (
                <sup>*</sup>
              )}
            </div>
            <div className={css.pricePerCredit}>
              {intl.formatMessage(
                { id: 'RespacesPass.pricing.card.pricePerCredit' },
                { pricePerCredit: isMonthly ? activeSubscription.pricePerCredit : pricePerCredit }
              )}
            </div>
          </>
        )}
      </div>
      <div className={css.sliderWrapper}>
        {isFreeTrial ? (
          <span>{intl.formatMessage({ id: 'RespacesPass.pricing.freeTrialCaption' })}</span>
        ) : (
          <>
            <div className={css.inputTop}>
              {intl.formatMessage({ id: 'RespacesPass.pricing.card.creditsInput' })}
              <input
                className={css.numberInput}
                type="number"
                id="credits"
                value={isMonthly ? subscriptionCreditsTotal : buyCreditsTotal}
                onChange={({ target: { value } }) => {
                  const validNumber = value > 0 && value !== '';
                  isMonthly
                    ? setSubscriptionCreditsTotal(validNumber ? +value : 1)
                    : setBuyCreditsTotal(validNumber ? +value : 1);
                  updateTotalCredits(validNumber ? +value : 1);
                }}
              />
            </div>
            <input
              className={classNames(css.sliderInput, 'swiper-no-swiping')}
              type="range"
              id={`creditsSlider-${id}`}
              min={1}
              max={2000}
              value={isMonthly ? subscriptionCreditsTotal : buyCreditsTotal}
              onChange={({ target: { value } }) => {
                isMonthly ? setSubscriptionCreditsTotal(+value) : setBuyCreditsTotal(+value);
                updateTotalCredits(+value);
              }}
            />
            {/* <span>{intl.formatMessage({ id: 'RespacesPass.pricing.card.freeCredits' })}</span> */}
          </>
        )}
      </div>

      <p
        className={classNames(css.feature, css.bookingsPerMonth, isFreeTrial && css.freeTrial)}
        key="bookings"
      >
        {intl.formatMessage(
          { id: 'RespacesPass.pricing.card.bookingsPerMonth' },
          { bookings: bookingsPerMonth, bold: (val) => <span className={css.bold}>{val}</span> }
        )}
      </p>

      {isMonthly && (
        <div className={css.bindingPeriod}>
          <label htmlFor="bindingPeriod">
            {intl.formatMessage({ id: 'RespacesPass.pricing.card.bindingLabel' })}
          </label>
          <select
            id="bindingPeriod"
            name="bindingPeriod"
            onChange={({ target: { value } }) => {
              updateSelectedPackage(`months_${value}`);
              setBindingPeriod(value);
            }}
          >
            {selectOptions}
          </select>
        </div>
      )}
      {!isMonthly && (
        <p
          className={classNames(css.feature, isFreeTrial && css.freeTrial, css.bindingPeriod)}
          key="period"
        >
          {isFreeTrial
            ? intl.formatMessage({ id: 'RespacesPass.pricing.card.trial' })
            : intl.formatMessage({ id: 'RespacesPass.pricing.card.binding' })}
        </p>
      )}

      <ButtonNew
        className={css.button}
        onClick={submitHandler}
        size={BUTTON_SIZES.MEDIUM}
        type={isFreeTrial ? BUTTON_TYPES.WHITE : BUTTON_TYPES.GREEN}
        linkColor={isFreeTrial && LINK_COLORS.GREEN} // this throws warning as supplies linkColor false for green buttons
        block
        inProgress={freeTrialLoading}
      >
        {intl.formatMessage({
          id: isFreeTrial
            ? 'RespacesPass.pricing.card.tryForFree'
            : 'RespacesPass.pricing.card.getStarted',
        })}
      </ButtonNew>
    </div>
  );
};

PricingCardNew.propTypes = {
  credits: number,
  pricePerMonth: number,
  pricePerCredit: number,
  averageBookings: number,
  onRouteChange: func,
  history: object,
  id: string,
  totalCredits: number,
  updateTotalCredits: func,
  isCompanyAccount: bool,
  selectedPackage: string,
  updateSelectedPackage: func,
  updateCalculatedPrices: func,
  freeTrialLoading: bool,
};

export default PricingCardNew;
