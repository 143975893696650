import classNames from 'classnames';
import { arrayOf, func, node, object, shape, string } from 'prop-types';
import React, { Component } from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import css from './SelectSingleFilterPlain.module.scss';

const FILTERS = {
  CATEGORY: 'category',
  SEATS: 'maxSeats',
  CONTRACT_LENGTH: 'contractLengthPerUnit',
};

const getQueryParamName = (queryParamNames) => {
  return Array.isArray(queryParamNames) ? queryParamNames[0] : queryParamNames;
};

class SelectSingleFilterPlain extends Component {
  constructor(props) {
    super(props);
    this.state = { isOpen: true };
    this.selectOption = this.selectOption.bind(this);
    this.toggleIsOpen = this.toggleIsOpen.bind(this);
  }

  selectOption(option, e) {
    const { queryParamNames, onSelect } = this.props;
    const queryParamName = getQueryParamName(queryParamNames);

    onSelect({
      [queryParamName]: queryParamName === 'contractLengthPerUnit' ? Number(option) : option,
    });

    // blur event target if event is passed
    if (e && e.currentTarget) {
      e.currentTarget.blur();
    }
  }

  toggleIsOpen() {
    this.setState({ isOpen: !this.state.isOpen });
  }

  render() {
    const { rootClassName, className, label, options, queryParamNames, initialValues } = this.props;
    const queryParamName = getQueryParamName(queryParamNames);

    const initialValue =
      initialValues && initialValues[queryParamName] ? initialValues[queryParamName] : null;
    const labelClass = initialValue ? css.filterLabelSelected : css.filterLabel;

    const classes = classNames(rootClassName || css.root, className);

    return (
      <div className={classes}>
        <div className={labelClass}>
          <FormattedMessage id={label}>
            {(id) => <span className={labelClass}>{id}</span>}
          </FormattedMessage>
        </div>
        <div className={css.optionsContainerOpen}>
          {options.map((option) => {
            // check if this option is selected
            const selected = initialValue === option.key;
            const optionClass = selected ? css.optionSelected : css.option;

            const isFixedOfficeCategory = option.key === 'fixed';

            return (
              <React.Fragment key={option.key}>
                <button
                  className={classNames(optionClass, {
                    [css.fixedOfficeSubLabel]: isFixedOfficeCategory && selected,
                  })}
                  onClick={() => this.selectOption(option.key)}
                >
                  <FormattedMessage id={option.labelId}>
                    {(id) => (
                      <span className={!selected ? css.optionLabel : css.optionLabelSelected}>
                        {id}
                      </span>
                    )}
                  </FormattedMessage>
                  <div className={css.icon}>
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <rect
                        x="0.5"
                        y="0.5"
                        width="19"
                        height="19"
                        rx="9.5"
                        stroke={selected ? '#222222' : '#999999'}
                        fill="none"
                      />
                      <rect
                        x="4.5"
                        y="4.5"
                        width="11"
                        height="11"
                        rx="5.5"
                        fill={selected ? '#222222' : 'none'}
                        stroke={selected ? '#222222' : 'none'}
                      />
                    </svg>
                  </div>
                </button>
              </React.Fragment>
            );
          })}
        </div>
        <button className={css.clearButton} onClick={(e) => this.selectOption(null, e)}>
          <FormattedMessage id={'SelectSingleFilter.plainClear'} />
        </button>
      </div>
    );
  }
}

SelectSingleFilterPlain.defaultProps = {
  rootClassName: null,
  className: null,
  initialValues: null,
};

SelectSingleFilterPlain.propTypes = {
  rootClassName: string,
  className: string,
  queryParamNames: arrayOf(string).isRequired,
  label: node.isRequired,
  onSelect: func.isRequired,

  options: arrayOf(
    shape({
      key: string.isRequired,
      label: string.isRequired,
    })
  ).isRequired,
  initialValues: object,
};

export default SelectSingleFilterPlain;
