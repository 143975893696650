import React from 'react';

import { ListingSearchForm } from '../../../forms';
import { ReviewCard } from '../../../components';

import css from '../LandingHero.module.scss';

const reviews = [
  {
    id: 1,
    rating: 5,
    text: 'SectionHero.reviewText1',
    name: 'Cecilia',
  },
  {
    id: 2,
    rating: 5,
    text: 'SectionHero.reviewText2',
    name: 'Theodore',
  },
  {
    id: 3,
    rating: 5,
    text: 'SectionHero.reviewText3',
    name: 'Filippa',
  },
];

const OnDemand = () => (
  <div>
    <ListingSearchForm />
    <div className={css.reviews}>
      {reviews.map((r) => (
        <div className={css.review} key={`landing_review_${r.id}`}>
          <ReviewCard key={r.id} review={r} />
        </div>
      ))}
    </div>
  </div>
);

export default OnDemand;
