import React from 'react';
import { FormattedMessage, intlShape } from '../../util/reactIntl';
import { convertPriceToCredit, formatMoney, priceWithoutVAT } from '../../util/currency';
import {
  LINE_ITEM_DAY,
  propTypes,
  LINE_ITEM_UNITS,
  DAILY_BOOKING,
  HOURLY_BOOKING,
} from '../../util/types';
import get from 'lodash/get';

import css from './BookingBreakdown.module.scss';

const LINE_ITEMS = [LINE_ITEM_DAY, LINE_ITEM_UNITS];
const LineItemUnitPriceMaybe = (props) => {
  const {
    transaction,
    intl,
    bookingType,
    seats,
    units,
    category,
    vatPercentage = 25,
    isProvider,
  } = props;

  const isCreditTx = get(transaction, 'attributes.protectedData.isCreditTx', false);

  const translationKey = isCreditTx
    ? bookingType === HOURLY_BOOKING
      ? 'BookingBreakdown.credit.valuePerHour'
      : 'BookingBreakdown.credit.valuePerDay'
    : bookingType === DAILY_BOOKING
    ? 'BookingBreakdown.pricePerDay'
    : bookingType === HOURLY_BOOKING
    ? 'BookingBreakdown.pricePerQuantity'
    : 'BookingBreakdown.pricePerMonth';

  const unitPurchase = transaction.attributes.lineItems.find(
    (item) => LINE_ITEMS.includes(item.code) && !item.reversal
  );

  const unitPriceWithoutVAT =
    unitPurchase && priceWithoutVAT(unitPurchase.unitPrice, vatPercentage);
  const formattedUnitPrice = unitPurchase ? formatMoney(intl, unitPriceWithoutVAT) : null;

  const creditsValue = isCreditTx ? convertPriceToCredit(unitPurchase.unitPrice) : null;
  const totalCreditValue =
    creditsValue && bookingType === HOURLY_BOOKING
      ? units * creditsValue
      : creditsValue && bookingType === DAILY_BOOKING
      ? category === 'meeting'
        ? units * creditsValue
        : units * seats * creditsValue
      : null;
  const totalCreditPriceLabel = intl.formatMessage(
    { id: 'BookingBreakdown.credit.creditsValue' },
    { credits: totalCreditValue }
  );
  const creditsValueShow = intl.formatMessage(
    {
      id: 'BookingBreakdown.credit.creditsValue',
    },
    {
      credits: creditsValue,
    }
  );

  return formattedUnitPrice && !totalCreditValue ? (
    <>
      <div className={css.lineItem}>
        <span className={css.itemLabel}>
          <FormattedMessage id={translationKey} />
        </span>
        <span className={css.itemValue}>
          {isCreditTx && !isProvider ? creditsValueShow : formattedUnitPrice}
        </span>
      </div>
      <hr className={css.totalDivider} />
    </>
  ) : totalCreditValue ? (
    <>
      <div className={css.lineItem}>
        <span className={css.itemLabel}>
          <FormattedMessage id={'BookingBreakdown.credit.totalCreditsLabel'} />
        </span>
        <span className={css.itemValue}>{totalCreditPriceLabel}</span>
      </div>
      <hr className={css.totalDivider} />
    </>
  ) : null;
};

LineItemUnitPriceMaybe.propTypes = {
  transaction: propTypes.transaction.isRequired,
  intl: intlShape.isRequired,
};

export default LineItemUnitPriceMaybe;
