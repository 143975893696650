import axios from 'axios';
import config from '../../config';
import { decodeLatLngBounds } from '../../util/urlHelpers';

// ================ Action types ================ //

export const FETCH_DATA_REQUEST_PRIVATE = 'app/LandingPage/FETCH_DATA_REQUEST_PRIVATE';
export const FETCH_DATA_REQUEST_SHARED = 'app/LandingPage/FETCH_DATA_REQUEST_SHARED';
export const FETCH_DATA_REQUEST_COWORKING = 'app/LandingPage/FETCH_DATA_REQUEST_COWORKING';
export const FETCH_DATA_SUCCESS = 'app/LandingPage/FETCH_DATA_SUCCESS';
export const FETCH_DATA_ERROR = 'app/LandingPage/FETCH_DATA_ERROR';

// ================ Reducer ================ //

const initialState = {
  fetchInProgress: { private: false, shared: false, coworking: false },
  fetchDataError: { private: null, shared: null, coworking: null },
  pageData: { coworkingLocations: null, privateOfficeLocations: null, sharedOfficeLocations: null },
};

export default function LandingPageReducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case FETCH_DATA_REQUEST_PRIVATE:
      return {
        ...state,
        fetchInProgress: { ...state.fetchInProgress, coworking: true },
        fetchDataError: { ...state.fetchDataError, private: null },
      };
    case FETCH_DATA_REQUEST_SHARED:
      return {
        ...state,
        fetchInProgress: { ...state.fetchInProgress, coworking: true },
        fetchDataError: { ...state.fetchDataError, shared: null },
      };
    case FETCH_DATA_REQUEST_COWORKING:
      return {
        ...state,
        fetchInProgress: { ...state.fetchInProgress, coworking: true },
        fetchDataError: { ...state.fetchDataError, coworking: null },
      };
    case FETCH_DATA_SUCCESS: {
      return {
        ...state,
        fetchInProgress: { ...state.fetchInProgress, [payload.category]: false },
        pageData: { ...state.pageData, ...payload.data },
      };
    }
    case FETCH_DATA_ERROR:
      console.error(payload); // eslint-disable-line
      return { ...state, fetchInProgress: false, fetchDataError: payload };

    default:
      return state;
  }
}

// ================ Action creators ================ //

const fetchDataRequestPrivate = () => ({ type: FETCH_DATA_REQUEST_PRIVATE });
const fetchDataRequestShared = () => ({ type: FETCH_DATA_REQUEST_SHARED });
const fetchDataRequestCoworking = () => ({ type: FETCH_DATA_REQUEST_COWORKING });
const fetchDataSuccess = (response) => ({
  type: FETCH_DATA_SUCCESS,
  payload: response,
});
const fetchDataError = (response) => ({
  type: FETCH_DATA_ERROR,
  payload: response,
});

// ================ Thunks ================ //

export const loadData = (params) => async (dispatch, getState, sdk) => {
  dispatch(fetchDataRequestPrivate());
  dispatch(fetchDataRequestShared());
  dispatch(fetchDataRequestCoworking());
  try {
    const canonicalRootURL =
      process.env.REACT_APP_ENV === 'development'
        ? 'http://localhost:3500'
        : config.canonicalRootURL;

    const getParams = (category) => {
      const bounds =
        category === 'private'
          ? decodeLatLngBounds('59.36534683,18.11389368,59.30292188,18.00042551') // stockholm - innanforTullarna
          : category === 'shared'
          ? decodeLatLngBounds('59.663376,18.19981,59.232464,17.933788') // stockholm
          : null;
      const host = category === 'shared' && 'otherCompany';

      console.log('params - bounds', bounds);
      console.log('params - host', host);
      return {
        locationFilters: { ...(!!bounds && { bounds }), ...(!!host && { host }) },
        locationFields: [
          'id',
          'name',
          'lat',
          'lng',
          'status',
          'address',
          'images',
          'opening_hours',
          'host',
        ],
        listingFilters: {
          category: category === 'coworking' ? 'coworking' : 'fixed',
          ...(category === 'private' && { fixed_office_type: 'office' }),
          status: 'published',
        },
        listingFields: [
          'id',
          'location_id',
          'category',
          'min_seats',
          'max_seats',
          'fixed_office_type',
          'price',
          'operation_hours',
          'amenities',
          'offer',
          'contract_type',
          'allow_vat_exempt_companies',
          'contract_length_per_unit',
          'rating',
          'booking_type',
          'vat',
          'request_price',
          'badge_unique',
          'badge_affordable',
        ],
        pagination: {
          page: 1,
          perPage: 30,
          totalPages: 0,
          totalItems: 0,
        },

        //if we are using origin, we are sorting by distance, adding another sorting is not supported
        sorting: {
          field: category === 'coworking' ? 'rating' : 'created_at',
          order: 'desc',
          isListingFilter: true,
        },
        limit: 10,
      };
    };

    const responsePrivateOffice = await axios.get(`${canonicalRootURL}/hasura/locations/search`, {
      params: getParams('private'),
    });
    const {
      data: {
        locations: {
          data: { locations: locationsPrivateOffice },
        },
      },
    } = responsePrivateOffice;
    dispatch(
      fetchDataSuccess({
        data: { privateOfficeLocations: locationsPrivateOffice },
        category: 'private',
      })
    );

    const responseSharedOffice = await axios.get(`${canonicalRootURL}/hasura/locations/search`, {
      params: getParams('shared'),
    });
    const {
      data: {
        locations: {
          data: { locations: locationsSharedOffice },
        },
      },
    } = responseSharedOffice;
    dispatch(
      fetchDataSuccess({
        data: { sharedOfficeLocations: locationsSharedOffice },
        category: 'shared',
      })
    );

    const responseCoworking = await axios.get(`${canonicalRootURL}/hasura/locations/search`, {
      params: getParams('coworking'),
    });
    const {
      data: {
        locations: {
          data: { locations: locationsCoworking },
        },
      },
    } = responseCoworking;
    dispatch(
      fetchDataSuccess({ data: { coworkingLocations: locationsCoworking }, category: 'coworking' })
    );
  } catch (error) {
    dispatch(fetchDataError(error));
  }
};
