import { useIntl } from 'react-intl';

import {
  CancelSubscriptionReasonModal,
  NamedLink,
  PauseSubscriptionModal,
  ReactivateSubscriptionSuccessModal,
  SubscriptionManagementIcons as Icons,
} from '../../../components';
import ReactivateSubscriptionModal from '../../../components/ReactivateSubscriptionModal/ReactivateSubscriptionModal';
import { ButtonNew } from '../../../components_new';
import { EditSubscriptionForm } from '../../../forms';

import { HubspotController } from '../../../util/hubspot';
import { MY_SUBSCRIPTION } from '../../../util/types';

import { BUTTON_TYPES, LINK_COLORS } from '../../../components_new/ButtonNew/ButtonNew';

import { number, string } from 'prop-types';
import { useDispatch } from 'react-redux';
import { manageDisableScrolling } from '../../../ducks/UI.duck';
import { useBoolean } from '../../../util/hooks';
import CurrentPlanBox from './CurrentPlanBox';
import css from './EditSubscription.module.scss';

const EditSubscription = (props) => {
  const {
    credits,
    totalPrice,
    renewalDate,
    creditsLeft,
    subscriptionStatus,
    isResumeSuccess,
    setResumeSuccess,
    selectedPackage,
    earliestAllowableCancelDate,
    selectedCreditAmount,
  } = props;
  const intl = useIntl();
  const [isCancelReasonModalOpen, setCancelReasonModalOpen] = useBoolean();
  const [isReactivateModalOpen, setReactivateModalOpen] = useBoolean();
  const [isPauseModalOpen, setPauseModalOpen] = useBoolean();
  const dispatch = useDispatch();

  const onManageDisableScrolling = (id, disableScroll) =>
    dispatch(manageDisableScrolling(id, disableScroll));
  const hubspotController = new HubspotController();
  return (
    <div className={css.tabContainer}>
      <div className={css.leftCol}>
        <NamedLink
          name="SubscriptionManagementPage"
          params={{ tab: MY_SUBSCRIPTION, action: null }}
          className={css.backBtn}
        >
          <Icons.BackIcon className={css.backIcon} />

          <ButtonNew
            type={BUTTON_TYPES.INLINE}
            linkColor={LINK_COLORS.BLUE}
            name="SubscriptionManagementPage"
            params={{ tab: MY_SUBSCRIPTION, action: null }}
          >
            {intl.formatMessage({ id: 'MySubscriptionTab.backToMySubscription' })}
          </ButtonNew>
        </NamedLink>
        <EditSubscriptionForm
          credits={credits}
          renewalDate={renewalDate}
          creditsLeft={creditsLeft}
          setCancelReasonModalOpen={setCancelReasonModalOpen}
          setPauseModalOpen={setPauseModalOpen}
          subscriptionStatus={subscriptionStatus}
          isReactivateModalOpen={isReactivateModalOpen}
          setReactivateModalOpen={setReactivateModalOpen}
          isResumeSuccess={isResumeSuccess}
          setResumeSuccess={setResumeSuccess}
          selectedPackage={selectedPackage}
          selectedCreditAmount={selectedCreditAmount}
        />
        <div className={css.chatWithTeam}>
          {intl.formatMessage({ id: 'MySubscriptionTab.chatWithTeam' })}
        </div>
      </div>
      <div className={css.rightCol}>
        <CurrentPlanBox
          intl={intl}
          credits={credits}
          renewalDate={renewalDate}
          creditsLeft={creditsLeft}
          setCancelReasonModalOpen={setCancelReasonModalOpen}
          setPauseModalOpen={setPauseModalOpen}
          subscriptionStatus={subscriptionStatus}
          setReactivateModalOpen={setReactivateModalOpen}
        />
      </div>
      <CancelSubscriptionReasonModal
        intl={intl}
        isOpen={isCancelReasonModalOpen}
        onClose={setCancelReasonModalOpen.off}
        nextBillingDate={renewalDate}
        onManageDisableScrolling={onManageDisableScrolling}
        openPauseModal={setPauseModalOpen.on}
        earliestAllowableCancelDate={earliestAllowableCancelDate}
      />
      <PauseSubscriptionModal
        isOpen={isPauseModalOpen}
        onClose={setPauseModalOpen.off}
        onManageDisableScrolling={onManageDisableScrolling}
      />
      <ReactivateSubscriptionModal
        isOpen={isReactivateModalOpen}
        onClose={setReactivateModalOpen.off}
        creditsNumber={credits}
        totalPrice={totalPrice}
        renewalDate={renewalDate}
        setResumeSuccess={setResumeSuccess}
      />
      <ReactivateSubscriptionSuccessModal
        isOpen={isResumeSuccess}
        onClose={setResumeSuccess.off}
        isResumeSuccess={isResumeSuccess}
      />
    </div>
  );
};

EditSubscription.propTypes = {
  credits: number.isRequired,
  renewalDate: string.isRequired,
  creditsLeft: number.isRequired,
  packageName: string,
  subscriptionStatus: string,
};

export default EditSubscription;
