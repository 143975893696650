/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { Component } from 'react';
import { array, arrayOf, bool, func, object, shape, string, oneOf } from 'prop-types';
import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import config from '../../config';
import routeConfiguration from '../../routeConfiguration';
import { findOptionsForSelectFilter } from '../../util/search';
import {
  LISTING_STATE_PENDING_APPROVAL,
  LISTING_STATE_CLOSED,
  propTypes,
  MONTHLY_BOOKING,
  DAILY_BOOKING,
  FIXED_OFFICE_CATEGORY,
} from '../../util/types';
import { types as sdkTypes } from '../../util/sdkLoader';
import {
  LISTING_PAGE_DRAFT_VARIANT,
  LISTING_PAGE_PENDING_APPROVAL_VARIANT,
  LISTING_PAGE_PARAM_TYPE_DRAFT,
  LISTING_PAGE_PARAM_TYPE_EDIT,
  createSlug,
} from '../../util/urlHelpers';
import { convertPriceToCredit } from '../../util/currency';
import { createResourceLocatorString, findRouteByRouteName } from '../../util/routes';
import {
  ensureListing,
  ensureOwnListing,
  ensureUser,
  userDisplayNameAsString,
} from '../../util/data';
import { setBookingData } from './ListingPage.duck';
import { timestampToDate, calculateQuantity, dateFromLocalToAPI } from '../../util/dates';
import { getMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { manageDisableScrolling, isScrollingDisabled } from '../../ducks/UI.duck';
import { initializeCardPaymentData } from '../../ducks/stripe.duck.js';
import {
  Page,
  Modal,
  NamedRedirect,
  LayoutSingleColumn,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  Footer,
  BookingPanel,
} from '../../components';
import { EnquiryForm } from '../../forms';
import { TopbarContainer, NotFoundPage } from '../../containers';
import SectionHeader from './SectionHeader/SectionHeader';
import SectionDescription from './SectionDescription/SectionDescription';
import get from 'lodash/get';
import moment from 'moment';
import { HubspotController } from '../../util/hubspot';

import {
  sendEnquiry,
  loadData,
  setInitialValues,
  fetchTimeSlots,
  fetchTransactionLineItems,
} from './ListingPage.duck';
import { updateCurrentBookingId } from '../../ducks/b2b.duck';
import SectionDigitalKeyMaybe from './SectionDigitalKey/SectionDigitalKeyMaybe';

import FixedOfficeContactEmail from '../../emails/FixedOfficeContactEmail/FixedOfficeContactEmail';
import FixedOfficeContactEmailRespaces from '../../emails/FixedOfficeContactEmail/FixedOfficeContactEmailRespaces';
import { getLocale } from '../../util/localeHelper';
import SectionFeatures from './SectionFeatures/SectionFeatures';
import SectionAddons from './SectionAddons/SectionAddons';
import SectionFAQ from './SectionFAQ/SectionFAQ';
import SectionMap from './SectionMap/SectionMap';
import SectionOpeningHours from './SectionOpeningHours/SectionOpeningHours';

import {
  isEmployeeUser as checkEmployeeUser,
  isCompanyUser as checkCompanyUser,
} from '../../util/b2bHelper';
import SectionTypeForm from './SectionTypeForm/SectionTypeForm';
import { ImageGridWithSlider } from '../../components_new';

import css from './ListingPage.module.scss';

const { UUID, Money } = sdkTypes;

export class ListingPageComponent extends Component {
  constructor(props) {
    super(props);
    const { enquiryModalOpenForListingId, params } = props;
    this.state = {
      pageClassNames: [],
      enquiryModalOpen: enquiryModalOpenForListingId === params.id,
      showingBooked: false,
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    // this.onContactUser = this.onContactUser.bind(this);
    this.onSubmitEnquiry = this.onSubmitEnquiry.bind(this);
    this.onBookShowingSubmit = this.onBookShowingSubmit.bind(this);
    this.hubspotController = new HubspotController();
    this.initialData = this.createInitialData();
  }

  createInitialData() {
    /* Saving bookingId to state */
    const bookingId = new URLSearchParams(this.props.location.search).get('bookingId');
    !!bookingId && this.props.updateCurrentBookingId(bookingId);

    const initialDate = new URLSearchParams(this.props.location.search).get('dates');
    // const initialTime = new URLSearchParams(this.props.location.search).get('time');
    const initialSeats = new URLSearchParams(this.props.location.search).get('pub_maxSeats');
    const initialData = {};
    if (initialDate) {
      const [startDate, endDate] = initialDate.split(',');
      initialData.startDate = new Date(startDate.replace(' ', 'T')); // formats string to work in safari
      initialData.endDate = new Date(endDate.replace(' ', 'T'));
    }
    if (initialSeats) {
      const index = initialSeats.indexOf(',');
      initialData.seats = parseInt(initialSeats.slice(0, index));
    } else {
      initialData.seats = 1;
    }
    return initialData;
  }

  handleSubmit(values) {
    const {
      history,
      getListing,
      params,
      callSetInitialValues,
      onInitializeCardPaymentData,
      lineItems,
    } = this.props;

    const listingId = new UUID(params.id);
    const listing = getListing(listingId);
    const bookingType = get(listing, 'attributes.publicData.bookingType');
    const { bookingStartTime, bookingEndTime, month, startDate, endDate, seats, ...restOfValues } =
      values;

    const { bookingStart, bookingEnd } =
      bookingType === MONTHLY_BOOKING
        ? {
            bookingStart: dateFromLocalToAPI(month.start),
            bookingEnd: dateFromLocalToAPI(
              moment(month.end).add(1, 'second').set('millisecond', 0).toDate()
            ),
          }
        : bookingType === DAILY_BOOKING
        ? {
            bookingStart: dateFromLocalToAPI(startDate),
            bookingEnd: dateFromLocalToAPI(endDate),
          }
        : {
            bookingStart: timestampToDate(bookingStartTime),
            bookingEnd: timestampToDate(bookingEndTime),
          };
    const type =
      bookingType === MONTHLY_BOOKING ? 'months' : bookingType === DAILY_BOOKING ? 'days' : 'hours';
    const bookingData = {
      units: calculateQuantity({
        startDate: bookingStart,
        endDate: bookingEnd,
        type,
      }),
      seats: parseInt(seats),
      bookingType,
      ...restOfValues,
    };

    const initialValues = {
      listing,
      bookingData,
      bookingDates: {
        bookingStart,
        bookingEnd,
      },
      confirmPaymentError: null,
    };

    const saveToSessionStorage = !this.props.currentUser;

    const routes = routeConfiguration();
    // Customize checkout page state with current listing and selected bookingDates
    const { setInitialValues } = findRouteByRouteName('CheckoutPage', routes);

    callSetInitialValues(setInitialValues, initialValues, saveToSessionStorage);

    // fb pixel event tracking:
    // possible object properties, none required:
    // content_category, content_ids, contents, currency, num_items, value

    const filteredLineItems =
      lineItems && lineItems.filter((item) => !item.code.includes('commission'));
    const total =
      lineItems &&
      filteredLineItems.map((item) => item.lineTotal.amount).reduce((acc, val) => acc + val) / 100;

    if (typeof window !== 'undefined' && !config.dev) {
      if (window.fbq != null) {
        window.fbq('track', 'InitiateCheckout', {
          content_category: listing.attributes.title,
          contents: filteredLineItems,
          currency: (lineItems && lineItems[0].lineTotal.currency) || 'SEK',
          value: total || 0,
        });
      }
    }

    // Clear previous Stripe errors from store if there is any
    onInitializeCardPaymentData();

    // Redirect to CheckoutPage
    history.push(
      createResourceLocatorString(
        'CheckoutPage',
        routes,
        { id: listing.id.uuid, slug: createSlug(listing.attributes.title) },
        {}
      )
    );
  }

  // onContactUser() {
  //   const { currentUser, history, callSetInitialValues, params, location } = this.props;

  //   if (!currentUser) {
  //     const state = { from: `${location.pathname}${location.search}${location.hash}` };

  //     // We need to log in before showing the modal, but first we need to ensure
  //     // that modal does open when user is redirected back to this listingpage
  //     callSetInitialValues(setInitialValues, { enquiryModalOpenForListingId: params.id });

  //     // signup and return back to listingPage.
  //     history.push(createResourceLocatorString('SignupPage', routeConfiguration(), {}, {}), state);
  //   } else {
  //     this.setState({ enquiryModalOpen: true });
  //   }
  // }

  onSubmitEnquiry(values) {
    const { history, params, onSendEnquiry } = this.props;
    const routes = routeConfiguration();
    const listingId = new UUID(params.id);
    const { message } = values;

    onSendEnquiry(listingId, message.trim())
      .then((txId) => {
        this.setState({ enquiryModalOpen: false });

        // Redirect to OrderDetailsPage
        history.push(
          createResourceLocatorString('OrderDetailsPage', routes, { id: txId.uuid }, {})
        );
      })
      .catch(() => {
        // Ignore, error handling in duck file
      });
  }

  onBookShowingSubmit(values, currentListing) {
    this.setState({ showingBooked: true });
    FixedOfficeContactEmail(values, currentListing, 'provider');
    FixedOfficeContactEmail(values, currentListing, 'buyer');
    FixedOfficeContactEmailRespaces(values, currentListing);
  }

  componentWillUnmount() {
    this.hubspotController.updateAppearance().then((methods) => !!methods && methods.decrease());
  }

  render() {
    const {
      unitType,
      isAuthenticated,
      currentUser,
      getListing,
      getOwnListing,
      intl,
      onManageDisableScrolling,
      onFetchTimeSlots,
      params: rawParams,
      location,
      scrollingDisabled,
      showListingError,
      /* reviews,
      fetchReviewsError, */
      sendEnquiryInProgress,
      sendEnquiryError,
      monthlyTimeSlots,
      filterConfig,
      onFetchTransactionLineItems,
      lineItems,
      fetchLineItemsInProgress,
      fetchLineItemsError,
      timeSlots,
      fetchTimeSlotsError,
      searchParams,
      companyAccount,
      favoritesArray,
      companyFavoritesArray,
      setBookingData,
      bookingData,
      isCompanyAdmin,
    } = this.props;

    const isCompanyUser = checkCompanyUser(currentUser);

    this.hubspotController.updateAppearance(true).then((methods) => !!methods && methods.raise());

    // Get the number of seats and the dates that the user used when searching
    const getDefaultSearch = () => {
      const seats = searchParams.pub_maxSeats
        ? parseInt(searchParams.pub_maxSeats.slice(0, searchParams.pub_maxSeats.indexOf(',')))
        : 1;

      const dates = searchParams.dates && searchParams.dates;

      return { seats, dates };
    };
    const defaultSearch = searchParams ? getDefaultSearch() : null;

    const listingId = new UUID(rawParams.id);
    const isPendingApprovalVariant = rawParams.variant === LISTING_PAGE_PENDING_APPROVAL_VARIANT;
    const isDraftVariant = rawParams.variant === LISTING_PAGE_DRAFT_VARIANT;
    const currentListing =
      isPendingApprovalVariant || isDraftVariant
        ? ensureOwnListing(getOwnListing(listingId))
        : ensureListing(getListing(listingId));

    const isFavorite = favoritesArray && favoritesArray?.includes(listingId.uuid);
    const isCompanyFavorite =
      companyFavoritesArray && companyFavoritesArray?.includes(listingId.uuid);

    const digitalKeyProps = currentListing.attributes.publicData.digitalKey;
    const supportsDigitalAccess = !!digitalKeyProps && digitalKeyProps.digitalKeyAccess;
    const accessProvider = supportsDigitalAccess ? digitalKeyProps.accessProvider : null;

    const listingSlug = rawParams.slug || createSlug(currentListing.attributes.title || '');
    const params = { slug: listingSlug, ...rawParams };

    const listingType = isDraftVariant
      ? LISTING_PAGE_PARAM_TYPE_DRAFT
      : LISTING_PAGE_PARAM_TYPE_EDIT;
    const listingTab = isDraftVariant ? 'photos' : 'description';

    const isApproved =
      currentListing.id && currentListing.attributes.state !== LISTING_STATE_PENDING_APPROVAL;

    const pendingIsApproved = isPendingApprovalVariant && isApproved;

    // If a /pending-approval URL is shared, the UI requires
    // authentication and attempts to fetch the listing from own
    // listings. This will fail with 403 Forbidden if the author is
    // another user. We use this information to try to fetch the
    // public listing.
    const pendingOtherUsersListing =
      (isPendingApprovalVariant || isDraftVariant) &&
      showListingError &&
      showListingError.status === 403;
    const shouldShowPublicListingPage = pendingIsApproved || pendingOtherUsersListing;

    if (shouldShowPublicListingPage) {
      return <NamedRedirect name="ListingPage" params={params} search={location.search} />;
    }

    const {
      description = '',
      geolocation = null,
      price = null,
      title = '',
      publicData,
    } = currentListing.attributes;

    const requireShowing = currentListing.attributes.publicData.requireShowing;
    const isFixedOffice = currentListing.attributes.publicData.category === FIXED_OFFICE_CATEGORY;

    const topbar = <TopbarContainer currentPage="ListingPage" />;

    if (showListingError && showListingError.status === 404) {
      // 404 listing not found

      return <NotFoundPage />;
    } else if (showListingError) {
      // Other error in fetching listing

      const errorTitle = intl.formatMessage({
        id: 'ListingPage.errorLoadingListingTitle',
      });

      return (
        <Page title={errorTitle} scrollingDisabled={scrollingDisabled}>
          <LayoutSingleColumn className={css.pageRoot}>
            <LayoutWrapperTopbar>{topbar}</LayoutWrapperTopbar>
            <LayoutWrapperMain>
              <p className={css.errorText}>
                <FormattedMessage id="ListingPage.errorLoadingListingMessage" />
              </p>
            </LayoutWrapperMain>
            <LayoutWrapperFooter>
              <Footer />
            </LayoutWrapperFooter>
          </LayoutSingleColumn>
        </Page>
      );
    } else if (!currentListing.id) {
      // Still loading the listing

      const loadingTitle = intl.formatMessage({
        id: 'ListingPage.loadingListingTitle',
      });

      return (
        <Page title={loadingTitle} scrollingDisabled={scrollingDisabled}>
          <LayoutSingleColumn className={css.pageRoot}>
            <LayoutWrapperTopbar>{topbar}</LayoutWrapperTopbar>
            <LayoutWrapperMain>
              <p className={css.loadingText}>
                <FormattedMessage id="ListingPage.loadingListingMessage" />
              </p>
            </LayoutWrapperMain>
            <LayoutWrapperFooter>
              <Footer />
            </LayoutWrapperFooter>
          </LayoutSingleColumn>
        </Page>
      );
    }

    const authorAvailable = currentListing && currentListing.author;
    const userAndListingAuthorAvailable = !!(currentUser && authorAvailable);
    const isOwnListing =
      userAndListingAuthorAvailable && currentListing.author.id.uuid === currentUser.id.uuid;

    const currentAuthor = authorAvailable ? currentListing.author : null;
    const ensuredAuthor = ensureUser(currentAuthor);

    // When user is banned or deleted the listing is also deleted.
    // Because listing can be never showed with banned or deleted user we don't have to provide
    // banned or deleted display names for the function
    const authorDisplayName = userDisplayNameAsString(ensuredAuthor, '');

    const isEmployeeUser = checkEmployeeUser(currentUser);

    const handleBookingSubmit = (values) => {
      setBookingData(values);
      const isCurrentlyClosed = currentListing.attributes.state === LISTING_STATE_CLOSED;
      if (isOwnListing || isCurrentlyClosed) {
        window.scrollTo(0, 0);
      } else {
        this.handleSubmit(values);
      }
    };
    const handleBookShowingSubmit = (values) => {
      this.onBookShowingSubmit(values, currentListing);
    };

    const listingImages = (listing, variantName) =>
      (listing.images || [])
        .map((image) => {
          const variants = image.attributes.variants;
          const variant = variants ? variants[variantName] : null;

          // deprecated
          // for backwards combatility only
          const sizes = image.attributes.sizes;
          const size = sizes ? sizes.find((i) => i.name === variantName) : null;

          return variant || size;
        })
        .filter((variant) => variant != null);

    const facebookImages = listingImages(currentListing, 'facebook');
    const twitterImages = listingImages(currentListing, 'twitter');
    const schemaImages = JSON.stringify(facebookImages.map((img) => img.url));
    const siteTitle = config.siteTitle;
    const schemaTitle = intl.formatMessage({ id: 'ListingPage.schemaTitle' }, { title, siteTitle });

    const amenetiesOptions = findOptionsForSelectFilter('ameneties', filterConfig);
    const offerOptions = findOptionsForSelectFilter('offer', filterConfig);
    const categoryOptions = findOptionsForSelectFilter('category', filterConfig);

    const descriptionKeywords = [];

    const vibeStored = get(currentListing, 'attributes.publicData.vibe', []);
    const vibeOptions = findOptionsForSelectFilter('vibe', filterConfig);
    vibeStored && descriptionKeywords.push(vibeOptions.filter((o) => vibeStored === o.key)[0]);

    const environmentStored = get(currentListing, 'attributes.publicData.environment', []);
    const environmentOptions = findOptionsForSelectFilter('environment', filterConfig);
    environmentStored &&
      descriptionKeywords.push(environmentOptions.filter((o) => environmentStored === o.key)[0]);

    const noiselevelStored = get(currentListing, 'attributes.publicData.noiselevel', []);
    const noiselevelOptions = findOptionsForSelectFilter('noiselevel', filterConfig);
    noiselevelStored &&
      descriptionKeywords.push(noiselevelOptions.filter((o) => noiselevelStored === o.key)[0]);

    const bookingType = get(currentListing, 'attributes.publicData.bookingType', null);

    const additionalServices = get(currentListing, 'attributes.publicData.additionalServices', []);
    const ensuredAdditionalServices = additionalServices.map((s) => ({
      ...s,
      price: new Money(s.price.amount, s.price.currency),
    }));

    const isMonthlyBooking = bookingType === MONTHLY_BOOKING;

    const timeSlotsMaybe = isMonthlyBooking ? convertToMonthlyTimeSlots(timeSlots) : timeSlots;

    const isShowCredits = isEmployeeUser || isCompanyUser;
    const credits = price
      ? intl.formatMessage(
          { id: 'ListingPage.header.credits' },
          { credits: convertPriceToCredit(price) }
        )
      : '';

    return (
      <Page
        title={schemaTitle}
        scrollingDisabled={scrollingDisabled}
        author={authorDisplayName}
        contentType="website"
        description={description}
        facebookImages={facebookImages}
        twitterImages={twitterImages}
        schema={{
          '@context': 'http://schema.org',
          '@type': 'ItemPage',
          description: description,
          name: schemaTitle,
          image: schemaImages,
        }}
      >
        <LayoutSingleColumn className={css.pageRoot}>
          <LayoutWrapperTopbar>{topbar}</LayoutWrapperTopbar>
          <LayoutWrapperMain className={css.mainWrapper}>
            <div className={css.wrapper}>
              <div className={css.headinImage}>
                <div className={css.headingOver} />
                <div>
                  <ImageGridWithSlider
                    title={title}
                    state={currentListing.attributes.state}
                    isOwn={isOwnListing}
                    editParams={{
                      id: listingId.uuid,
                      slug: listingSlug,
                      type: listingType,
                      tab: listingTab,
                    }}
                    images={currentListing.images}
                    onManageDisableScrolling={onManageDisableScrolling}
                  />
                </div>
              </div>
              <div className={css.contentContainer}>
                <div className={css.mainContent}>
                  <SectionHeader
                    categoryOptions={categoryOptions}
                    listing={currentListing}
                    category={publicData?.category}
                    descriptionKeywords={descriptionKeywords}
                    isShowCredits={isShowCredits}
                    credits={credits}
                    isFavorite={isFavorite}
                    isCompanyFavorite={isCompanyFavorite}
                    isCompanyAdmin={isCompanyAdmin}
                    currentUser={currentUser}
                    requireShowing={requireShowing}
                    isFixedOffice={isFixedOffice}
                  />
                  <SectionFeatures
                    optionsAmeneties={amenetiesOptions}
                    optionsOffer={offerOptions}
                    publicData={publicData}
                  />
                  <SectionDescription
                    description={publicData[getLocale()]?.description || description}
                    descriptionKeywords={descriptionKeywords}
                  />
                  {publicData.additionalServices && publicData.additionalServices.length > 0 && (
                    <SectionAddons
                      intl={intl}
                      addons={publicData.additionalServices}
                      addonsList={config.custom.addonsList}
                    />
                  )}
                  {supportsDigitalAccess && (
                    <SectionDigitalKeyMaybe accessProvider={accessProvider} />
                  )}
                  {currentListing.attributes.publicData.operationHours && (
                    <SectionOpeningHours
                      daysList={currentListing.attributes.publicData.operationHours}
                    />
                  )}
                  {geolocation && (
                    <SectionMap
                      geolocation={geolocation}
                      publicData={publicData}
                      listingId={currentListing.id}
                    />
                  )}
                  <SectionFAQ
                    lastSection={currentListing?.attributes?.publicData?.category !== 'fixed'}
                  />
                  {currentListing?.attributes?.publicData?.category === 'fixed' && (
                    <SectionTypeForm />
                  )}

                  {/* <SectionReviews reviews={reviews} fetchReviewsError={fetchReviewsError} /> */}
                </div>
                {bookingType && (
                  <BookingPanel
                    className={css.bookingPanel}
                    listing={currentListing}
                    isOwnListing={isOwnListing}
                    unitType={unitType}
                    onSubmit={handleBookingSubmit}
                    authorDisplayName={authorDisplayName}
                    onManageDisableScrolling={onManageDisableScrolling}
                    monthlyTimeSlots={monthlyTimeSlots}
                    onFetchTimeSlots={onFetchTimeSlots}
                    onFetchTransactionLineItems={onFetchTransactionLineItems}
                    lineItems={lineItems}
                    fetchLineItemsInProgress={fetchLineItemsInProgress}
                    fetchLineItemsError={fetchLineItemsError}
                    timeSlots={timeSlotsMaybe}
                    fetchTimeSlotsError={fetchTimeSlotsError}
                    bookingType={bookingType}
                    additionalServices={ensuredAdditionalServices}
                    addonList={config.custom.addonsList}
                    defaultSearch={defaultSearch}
                    onBookShowingSubmit={handleBookShowingSubmit}
                    showingBooked={this.state.showingBooked}
                    isEmployeeUser={isEmployeeUser}
                    currentUser={currentUser}
                    companyAccount={companyAccount}
                    isCompanyUser={isCompanyUser}
                    bookingData={bookingData}
                    initialData={this.initialData}
                    isFixedOffice={isFixedOffice}
                  />
                )}
              </div>
            </div>
            <Modal
              id="ListingPage.enquiry"
              contentClassName={css.enquiryModalContent}
              isOpen={isAuthenticated && this.state.enquiryModalOpen}
              onClose={() => this.setState({ enquiryModalOpen: false })}
              onManageDisableScrolling={onManageDisableScrolling}
            >
              <EnquiryForm
                className={css.enquiryForm}
                submitButtonWrapperClassName={css.enquirySubmitButtonWrapper}
                listingTitle={title}
                authorDisplayName={authorDisplayName}
                sendEnquiryError={sendEnquiryError}
                onSubmit={this.onSubmitEnquiry}
                inProgress={sendEnquiryInProgress}
              />
            </Modal>
          </LayoutWrapperMain>
          <LayoutWrapperFooter>
            <Footer />
          </LayoutWrapperFooter>
        </LayoutSingleColumn>
      </Page>
    );
  }
}

ListingPageComponent.defaultProps = {
  unitType: config.bookingUnitType,
  currentUser: null,
  enquiryModalOpenForListingId: null,
  showListingError: null,
  reviews: [],
  fetchReviewsError: null,
  monthlyTimeSlots: null,
  sendEnquiryError: null,
  filterConfig: config.custom.filters,
  lineItems: null,
  fetchLineItemsError: null,
  companyAccount: null,
};

ListingPageComponent.propTypes = {
  // from withRouter
  history: shape({
    push: func.isRequired,
  }).isRequired,
  location: shape({
    search: string,
  }).isRequired,

  unitType: propTypes.bookingUnitType,
  // from injectIntl
  intl: intlShape.isRequired,

  params: shape({
    id: string.isRequired,
    slug: string,
    variant: oneOf([LISTING_PAGE_DRAFT_VARIANT, LISTING_PAGE_PENDING_APPROVAL_VARIANT]),
  }).isRequired,

  isAuthenticated: bool.isRequired,
  currentUser: propTypes.currentUser,
  getListing: func.isRequired,
  getOwnListing: func.isRequired,
  onManageDisableScrolling: func.isRequired,
  scrollingDisabled: bool.isRequired,
  enquiryModalOpenForListingId: string,
  showListingError: propTypes.error,
  callSetInitialValues: func.isRequired,
  reviews: arrayOf(propTypes.review),
  fetchReviewsError: propTypes.error,
  monthlyTimeSlots: object,
  // monthlyTimeSlots could be something like:
  // monthlyTimeSlots: {
  //   '2019-11': {
  //     timeSlots: [],
  //     fetchTimeSlotsInProgress: false,
  //     fetchTimeSlotsError: null,
  //   }
  // }
  sendEnquiryInProgress: bool.isRequired,
  sendEnquiryError: propTypes.error,
  onSendEnquiry: func.isRequired,
  onInitializeCardPaymentData: func.isRequired,
  filterConfig: array,
  onFetchTransactionLineItems: func.isRequired,
  lineItems: array,
  fetchLineItemsInProgress: bool.isRequired,
  fetchLineItemsError: propTypes.error,
};

const mapStateToProps = (state) => {
  const {
    Auth: { isAuthenticated },
    user: {
      companyAccount,
      currentUser,
      favoritesArray,
      companyFavoritesArray,
      companyInfo: { isCompanyAdmin },
    },
    ListingPage: {
      showListingError,
      /*reviews,
      fetchReviewsError,*/
      monthlyTimeSlots,
      sendEnquiryInProgress,
      sendEnquiryError,
      lineItems,
      fetchLineItemsInProgress,
      fetchLineItemsError,
      enquiryModalOpenForListingId,
      timeSlots,
      fetchTimeSlotsError,
      bookingData,
    },
    SearchPage: { searchParams },
  } = state;

  const getListing = (id) => {
    const ref = { id, type: 'listing' };
    const listings = getMarketplaceEntities(state, [ref]);
    return listings.length === 1 ? listings[0] : null;
  };

  const getOwnListing = (id) => {
    const ref = { id, type: 'ownListing' };
    const listings = getMarketplaceEntities(state, [ref]);
    return listings.length === 1 ? listings[0] : null;
  };

  return {
    isCompanyAdmin,
    favoritesArray,
    companyFavoritesArray,
    isAuthenticated,
    currentUser,
    getListing,
    getOwnListing,
    scrollingDisabled: isScrollingDisabled(state),
    enquiryModalOpenForListingId,
    showListingError,
    /* reviews,
    fetchReviewsError, */
    monthlyTimeSlots,
    lineItems,
    fetchLineItemsInProgress,
    fetchLineItemsError,
    sendEnquiryInProgress,
    sendEnquiryError,
    timeSlots,
    fetchTimeSlotsError,
    searchParams,
    companyAccount,
    bookingData,
  };
};

const mapDispatchToProps = (dispatch) => ({
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
  callSetInitialValues: (setInitialValues, values, saveToSessionStorage) =>
    dispatch(setInitialValues(values, saveToSessionStorage)),
  onFetchTransactionLineItems: (bookingData, listingId, isOwnListing) =>
    dispatch(fetchTransactionLineItems(bookingData, listingId, isOwnListing)),
  onSendEnquiry: (listingId, message) => dispatch(sendEnquiry(listingId, message)),
  onInitializeCardPaymentData: () => dispatch(initializeCardPaymentData()),
  onFetchTimeSlots: (listingId, start, end, timeZone) =>
    dispatch(fetchTimeSlots(listingId, start, end, timeZone)),
  setBookingData: (payload) => dispatch(setBookingData(payload)),
  updateCurrentBookingId: (bookingId) => dispatch(updateCurrentBookingId(bookingId)),
});

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const ListingPage = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl
)(ListingPageComponent);

ListingPage.setInitialValues = (initialValues) => setInitialValues(initialValues);
ListingPage.loadData = loadData;

export default ListingPage;

const convertToMonthlyTimeSlots = (timeSlots = []) => {
  const groupedTimeSlotsByMonth = timeSlots.reduce((prev, cur) => {
    const currentMonth = moment(cur.attributes.start).format('MMM YYYY');
    prev[currentMonth] = (prev[currentMonth] || []).concat(cur);
    return prev;
  }, {});

  const monthSlots = Object.entries(groupedTimeSlotsByMonth).reduce((prev, cur) => {
    const month = moment(cur[0], 'MMM YYYY');
    const endOfMonth = month.clone().endOf('month').get('date');
    if (cur[1].length === endOfMonth) {
      const start = cur[1][0];
      const end = cur[1][cur[1].length - 1];
      prev.push({
        attributes: {
          start: start.attributes.start,
          type: start.attributes.type,
          end: end.attributes.end,
          seats: start.attributes.seats,
        },
      });
    }
    return prev;
  }, []);

  return monthSlots;
};
