import React from 'react';
import { injectIntl } from 'react-intl';
import { func, number } from 'prop-types';

import css from './SeeMoreSpacesButton.module.scss';

const SeeMoreSpacesButton = ({ numberOfExtraBookings, onShowMoreSpaces, intl }) => (
  <button onClick={onShowMoreSpaces} className={css.button}>
    + {intl.formatMessage({ id: 'TeamSchedule.seeMoreButton' }, { num: numberOfExtraBookings })}
  </button>
);

SeeMoreSpacesButton.propTypes = {
  numberOfExtraBookings: number.isRequired,
  onShowMoreSpaces: func.isRequired,
};

export default injectIntl(SeeMoreSpacesButton);
