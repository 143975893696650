import React from 'react';
import { injectIntl } from 'react-intl';

import css from './PopularRibbon.module.scss';

const PopularRibbon = ({ intl }) => {
  return (
    <div className={css.ribbon}>
      <span>{intl.formatMessage({ id: 'PopularRibbon.ribbon' })}</span>
    </div>
  );
};

export default injectIntl(PopularRibbon);
