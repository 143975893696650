import classNames from 'classnames';
import { debounce } from 'lodash';
import moment from 'moment-timezone';
import React, { useRef } from 'react';
import { Form } from 'react-final-form';
import { useDispatch, useSelector } from 'react-redux';
import { OutsideClickHandler } from '../../components';
import config from '../../config';
import FilterComponent from '../../containers/SearchPageNew/FilterComponent';
import { updateFilters } from '../../ducks/SearchPageNew.duck';
import { DateCombobox, SeatsComboBox } from '../../forms/ListingSearchForm/ui';
import {
  inBrowser,
  touchDeviceMatch,
  isNewestSafari,
  narrowViewportMatch,
} from '../../util/device';
import { COWORKING_CATEGORY } from '../../util/types';
import css from './RelevantFilter.module.scss';

const RelevantFilter = ({
  intl,
  filter,
  initialValues,
  relevantFilterOpen,
  setRelevantFilterOpen,
  fixedOfficeSubFilter,
  updateFilterFixed,
  stateCategories,
}) => {
  const dispatch = useDispatch();
  const { currentDates, currentTimes, startDate } = useSelector((state) => {
    const {
      SearchPageNew: {
        filters: { startDate, endDate, startTime, endTime },
      },
    } = state;

    return {
      currentDates: {
        startDate,
        endDate,
      },
      currentTimes: {
        startTime,
        endTime,
      },
      startDate,
    };
  });

  const showMobileForm = !!isNewestSafari()
    ? inBrowser() && narrowViewportMatch().matches
    : inBrowser() && narrowViewportMatch().matches && touchDeviceMatch().matches;

  const dateInputRef = useRef(null);
  const seatsInputRef = useRef(null);
  const initVals = initialValues;
  const isSeats = filter.id === 'maxSeats';
  const isDates = filter.id === 'dates-length';
  const numberActive =
    isSeats && !!initVals?.minSeats
      ? initVals.minSeats
      : !!initVals[filter.queryParamNames[0]] && !isDates && !isSeats
      ? initVals[filter.queryParamNames[0]]?.split(',').length
      : 0;

  const isPrice = filter.id === 'price';
  const priceLabel = isPrice && !!initVals.price && initVals.price.split(',')[1];

  let datesLabel = '';
  if (isDates && startDate) {
    const mStart = moment(startDate);
    datesLabel = mStart.format('MMM DD');
  }

  const buttonLabelIndicator = priceLabel ? priceLabel : numberActive;
  const buttonLabel = !!priceLabel ? (
    <span>{`SEK ${priceLabel}`}</span>
  ) : !!datesLabel ? (
    datesLabel
  ) : (
    <span>
      {intl.formatMessage({ id: filter.label })}
      {!!numberActive && (
        <span className={css.buttonLabelIndicator}>{`(${buttonLabelIndicator})`}</span>
      )}
    </span>
  );

  const setRelevantFilterOpenDebounced = debounce(setRelevantFilterOpen, 100, {
    leading: false,
    trailing: true,
  });

  const handleSeatsChange = (minSeats) => {
    if (!minSeats) {
      dispatch(updateFilters({ minSeats: null, maxSeats: null }));
    } else {
      /* TODO: Update with filters.category */
      const maxSeats =
        stateCategories === COWORKING_CATEGORY
          ? 1000
          : minSeats < 20
          ? minSeats + 5
          : minSeats < 50
          ? minSeats + 10
          : Math.floor(minSeats * 1.2) + 20;
      dispatch(updateFilters({ minSeats, maxSeats }));
    }
  };

  const closeRelevantFilter = () => {
    setRelevantFilterOpenDebounced(null);
  };

  const updateSearchSeatsDebounced = debounce(handleSeatsChange, 500, {
    leading: false,
    trailing: true,
  });

  return (
    <div
      className={classNames(
        css.relevantFilter,
        (!!numberActive || (isDates && !!startDate)) && css.active,
        filter.id === relevantFilterOpen && css.wasactive,
        isDates && css.isDateFilter
      )}
    >
      <div
        className={css.content}
        onClick={(e) => {
          setRelevantFilterOpenDebounced(relevantFilterOpen !== filter.id ? filter.id : null);
        }}
      >
        {buttonLabel}
      </div>
      {relevantFilterOpen === filter.id && isDates ? (
        <OutsideClickHandler
          rootClassName={css.body}
          onOutsideClick={() => {
            setRelevantFilterOpenDebounced(null);
          }}
        >
          <DateCombobox
            className={classNames(css.dateDropdown, startDate && css.active)}
            focusedClassName={css.dateDropdownFocused}
            isOpen={relevantFilterOpen === filter.id}
            onOpen={() => {
              setRelevantFilterOpenDebounced(filter.id);
            }}
            onClose={() => {
              setRelevantFilterOpenDebounced(null);
            }}
            onDatesChange={(dates) => {
              dispatch(updateFilters(dates));
            }}
            onTimesChange={(times) => {
              dispatch(updateFilters(times));
              if (!!times?.startTime && !!times.endTime) {
                dateInputRef?.current?.blur();
              }
            }}
            currentDates={currentDates}
            currentTimes={currentTimes}
            timeOptions={config.custom.HOUR_OPTIONS}
            showTimepickers={true}
            inputRef={dateInputRef}
            intl={intl}
            isRelevantFilter={true}
            isMobile={showMobileForm}
          />
        </OutsideClickHandler>
      ) : (
        relevantFilterOpen === filter.id &&
        !isDates && (
          <OutsideClickHandler
            rootClassName={css.body}
            onOutsideClick={() => {
              setRelevantFilterOpenDebounced(null);
            }}
          >
            {isSeats ? (
              <SeatsComboBox
                className={css.filterForm}
                id="seats"
                name="seatsComboBox"
                isOpen={true}
                onOpen={() => {
                  return;
                }}
                onClose={() => {
                  setRelevantFilterOpenDebounced(null);
                  seatsInputRef?.current?.blur();
                }}
                intl={intl}
                inputRef={seatsInputRef}
                currentSeats={numberActive}
                focusedClassName={css.filterForm}
                onSeatsChange={(val) => {
                  updateSearchSeatsDebounced(+val);
                }}
                isRelevantFilter={true}
                isMobile={showMobileForm}
              />
            ) : (
              <div className={css.filterFormWrapper}>
                <Form
                  className={css.filterForm}
                  onSubmit={() => null}
                  render={() => {
                    return (
                      <FilterComponent
                        key={`SearchResultsPanel.${filter.id}`}
                        className={classNames(
                          css.filterOptions,
                          filter.id === 'host' && css.filterRight
                        )}
                        id={filter.id}
                        label={filter.label}
                        name={filter.id.toLowerCase()}
                        queryParamNames={filter.queryParamNames}
                        initialValues={initialValues}
                        fixedOfficeSubFilter={fixedOfficeSubFilter}
                        updateFilterFixed={updateFilterFixed}
                        hideLabel={true}
                        filterConfig={filter}
                        liveEdit
                        stateCategories={stateCategories}
                        isRelevantFilter={true}
                        closeRelevantFilter={closeRelevantFilter}
                      />
                    );
                  }}
                />
              </div>
            )}
          </OutsideClickHandler>
        )
      )}
    </div>
  );
};

RelevantFilter.propTypes = {};

export default RelevantFilter;
