import classNames from 'classnames';
import { types as sdkTypes } from '../../util/sdkLoader';
import arrayMutators from 'final-form-arrays';
import cloneDeep from 'lodash/cloneDeep';
import compact from 'lodash/compact';
import get from 'lodash/get';
import isEqual from 'lodash/isEqual';
import merge from 'lodash/merge';
import pickBy from 'lodash/pickBy';
import moment from 'moment';
import { arrayOf, bool, func, object, string } from 'prop-types';
import React, { memo, useMemo, useRef } from 'react';
import { Form as FinalForm } from 'react-final-form';
import { shallowEqual, useSelector } from 'react-redux';
import { priceWithoutVAT } from '../../util/currency';
import { ensureOwnListing } from '../../util/data';
import { dateFromLocalToAPI, getDefaultTimeZoneOnBrowser, minutesBetween } from '../../util/dates';
import { FormattedMessage, injectIntl } from '../../util/reactIntl';
import {
  AVAILABILITY_PLAN_DAY,
  AVAILABILITY_PLAN_TIME,
  DAILY_BOOKING,
  DAYS_OF_WEEK,
  FIXED_OFFICE_CATEGORY,
  MEETINGROOMS_CATEGORY,
  HOURLY_BOOKING,
  LISTING_STATE_DRAFT,
  MONTHLY_BOOKING,
  HOST_TYPE_OTHER_COMPANY,
  propTypes,
} from '../../util/types';
import {
  createEntriesFromSubmitValues,
  createExceptionInitialvalues,
  createInitialValues,
  createOpenAndCloseTime,
  sortExceptionsByStartTime,
} from './EditListingAvailabilityPanel.helper';
import css from './EditListingAvailabilityPanel.module.scss';
import SectionNew from './SectionNew';
import SectionSubletting from './SectionSubletting';

const { UUID, Money } = sdkTypes;

// We want to sort exceptions on the client-side, maximum pagination page size is 100,
// so we need to restrict the amount of exceptions to that.

const defaultTimeZone = () =>
  typeof window !== 'undefined' ? getDefaultTimeZoneOnBrowser() : 'Etc/UTC';

//////////////////////////////////
// EditListingAvailabilityPanel //
//////////////////////////////////
const EditListingAvailabilityPanel = memo((props) => {
  const {
    className,
    rootClassName,
    listing,
    availabilityExceptions,
    fetchExceptionsInProgress,
    onAddAvailabilityException,
    onDeleteAvailabilityException,
    disabled,
    ready,
    onSubmit,
    onManageDisableScrolling,
    onNextTab,
    submitButtonText,
    updateInProgress,
    errors,
    intl,
    onSaveAndExitListingWizardTab,
  } = props;
  // Hooks
  const submittedValues = useRef({});
  const currentListing = ensureOwnListing(listing);
  const classes = classNames(rootClassName || css.root, className);
  const { publicData = {}, metadata = {}, availabilityPlan, price } = currentListing.attributes;
  const {
    category,
    paddingTime,
    maxSeats,
    vatPercentage,
    bookingType,
    requireShowing,
    operationHours = [],
    numberOfInstance,
    priceMax,
    requestPrice,
    acceptBookingOnRequest,
    startDate,
    pricePerUnit,
    priceUnit,
    priceSubletting,
    paymentPeriod,
    contractType,
    contractLengthUnit,
    contractLengthPerUnit,
    noReqForContractLength,
    noticePeriodPerUnit,
    noticePeriodUnit,
    allowVatExemptCompanies,
    host,
  } = publicData;
  const { comission } = metadata;

  const isPublished = currentListing.id && currentListing.attributes.state !== LISTING_STATE_DRAFT;
  const isPrivate = ['meeting', 'private'].includes(category);
  const isFixedOffice = category === FIXED_OFFICE_CATEGORY;
  const addExceptionInProgress = useSelector(
    (state) => state.EditListingPage.addExceptionInProgress,
    shallowEqual
  );
  const initialAvailabilityPlan = useMemo(
    () => createInitialValues(availabilityPlan),
    [availabilityPlan]
  );
  const convertedOperationHours = useMemo(() => {
    return bookingType === DAILY_BOOKING || bookingType === MONTHLY_BOOKING
      ? DAYS_OF_WEEK.reduce(
          (prev, cur) =>
            operationHours[cur]
              ? {
                  ...prev,
                  [cur]: [
                    {
                      ...(Array.isArray(initialAvailabilityPlan[cur])
                        ? initialAvailabilityPlan[cur][0]
                        : initialAvailabilityPlan[cur]),
                      startTime: operationHours[cur].openTime,
                      endTime: operationHours[cur].closeTime,
                    },
                  ],
                }
              : prev,
          {}
        )
      : {};
  }, [bookingType, initialAvailabilityPlan, operationHours]);

  const _initialAvailabilityPlan =
    bookingType === DAILY_BOOKING || bookingType === MONTHLY_BOOKING
      ? merge(initialAvailabilityPlan, convertedOperationHours)
      : initialAvailabilityPlan;

  const priceMinusVAT = price ? priceWithoutVAT(price, vatPercentage) : null;

  const initialValues = useMemo(
    () => ({
      acceptBookingOnRequest:
        acceptBookingOnRequest === undefined && category === MEETINGROOMS_CATEGORY
          ? true
          : acceptBookingOnRequest,
      price: priceMinusVAT,
      priceMax: priceMax
        ? priceWithoutVAT(new Money(priceMax, price.currency), vatPercentage)
        : null,
      vatPercentage,
      bookingType: bookingType ? bookingType : isFixedOffice ? MONTHLY_BOOKING : HOURLY_BOOKING,
      requireShowing: requireShowing ? requireShowing : isFixedOffice ? true : false,
      requestPrice,
      exceptionHourBlock: createExceptionInitialvalues(availabilityExceptions),
      copyTime: [],
      startDate,
      pricePerUnit,
      priceUnit: priceUnit || 'month',
      priceSubletting,
      paymentPeriod,
      contractType,
      contractLengthUnit: contractLengthUnit || 'month',
      contractLengthPerUnit: !contractLengthPerUnit
        ? ''
        : contractLengthUnit === 'month'
        ? contractLengthPerUnit
        : contractLengthPerUnit / 12,
      noReqForContractLength,
      noticePeriodPerUnit,
      noticePeriodUnit: noticePeriodUnit || 'month',
      allowVatExemptCompanies,
      ..._initialAvailabilityPlan,
    }),
    [
      price,
      vatPercentage,
      bookingType,
      availabilityExceptions,
      _initialAvailabilityPlan,
      acceptBookingOnRequest,
    ]
  );

  const now = new Date();
  // filter exceptions to remove any exceptions before (now + padding time), and any half hourly exceptions
  const filteredExceptions = availabilityExceptions.filter(
    (exception) =>
      !(
        exception.attributes.start < now &&
        minutesBetween(exception.attributes.start, exception.attributes.end) === 30
      )
  );

  const exceptionCount = filteredExceptions ? filteredExceptions.length : 0;
  const sortedAvailabilityExceptions = filteredExceptions.sort(sortExceptionsByStartTime);

  // Save exception click handler
  const saveException = async (values, exceptionDates, onClose) => {
    const { exceptionHourBlock = [] } = values;
    // TODO: add proper seat handling
    const exceptionsMaybe = exceptionDates.reduce((exceptions, date) => {
      if (exceptionHourBlock.length > 0) {
        const newExceptionList = exceptionHourBlock.map((hourBlock) => {
          const { startTime, endTime } = hourBlock;
          const clonedStartDate = cloneDeep(date);
          const clonedEndDate = cloneDeep(date);
          const [startHour, startMinute] = startTime.split(':');
          const [endHour, endMinute] = endTime.split(':');
          const start = clonedStartDate.hour(startHour).minute(startMinute).second(0);
          const end = clonedEndDate.hour(endHour).minute(endMinute).second(0);

          return availabilityExceptions.findIndex(
            (exc) =>
              moment(exc.attributes.start).isSame(start) && moment(exc.attributes.end).isSame(end)
          ) === -1
            ? {
                start: new Date(start),
                end: new Date(end),
              }
            : null;
        });
        return compact([...exceptions, ...newExceptionList]);
      } else {
        const clonedStartDate = cloneDeep(date);
        const clonedEndDate = cloneDeep(date);
        const start = clonedStartDate;
        const end = clonedEndDate.add(4, 'hours');
        start.set({ hour: 0, minute: 0, second: 0 });
        values.bookingType !== 'daily' && end.set({ hour: 23 });
        const newExceptionDate =
          availabilityExceptions.findIndex(
            (exc) =>
              moment(exc.attributes.start).isSame(start) && moment(exc.attributes.end).isSame(end)
          ) === -1
            ? {
                start: dateFromLocalToAPI(start),
                end: dateFromLocalToAPI(end),
              }
            : null;
        return compact([...exceptions, newExceptionDate]);
      }
    }, []);
    try {
      await Promise.all(
        exceptionsMaybe.map((exception) => {
          onAddAvailabilityException({
            listingId: listing.id,
            seats: 0,
            // TODO: add proper seat handling, like the suggestion below but also working for hourly bookings
            // seats: values.bookingType === 'daily' ? 0 : maxSeats,
            ...exception,
          });
        })
      );
      onClose();
    } catch (error) {
      console.error('Exception submit error: ', error);
    }
  };

  const calculatedPrice = (pricePerUnit, price, vatPercentage) =>
    new Money(
      Math.round(
        isFixedOffice && host === HOST_TYPE_OTHER_COMPANY
          ? pricePerUnit * 100 //VAT is added in SectionSubleasing
          : isFixedOffice
          ? pricePerUnit * (1 + +vatPercentage / 100) * 100
          : price.amount * (1 + vatPercentage / 100)
      ),
      isFixedOffice ? 'SEK' : price.currency
    );

  async function applyNewAvailability(values) {
    const {
      bookingType,
      price,
      priceMax,
      requestPrice,
      vatPercentage = 25,
      amount,
      unit,
      acceptBookingOnRequest,
      category,
      startDate,
      pricePerUnit,
      priceUnit,
      priceSubletting,
      paymentPeriod,
      contractType,
      contractLengthUnit,
      contractLengthPerUnit,
      noReqForContractLength,
      noticePeriodPerUnit,
      noticePeriodUnit,
      allowVatExemptCompanies,
    } = values;

    let { requireShowing } = values;

    const plan = {
      type: bookingType === HOURLY_BOOKING ? AVAILABILITY_PLAN_TIME : AVAILABILITY_PLAN_DAY,
      entries: createEntriesFromSubmitValues(values, maxSeats, numberOfInstance, isPrivate),
    };
    const paddingTime = amount
      ? {
          amount: amount,
          unit: unit,
        }
      : null;

    if (bookingType === HOURLY_BOOKING) {
      plan.timezone = defaultTimeZone();
    }

    if (requireShowing && bookingType !== MONTHLY_BOOKING) {
      requireShowing = false;
    }

    const updateValues = {
      price: calculatedPrice(pricePerUnit, price, vatPercentage),
      availabilityPlan: plan,
      publicData: {
        bookingType,
        vatPercentage,
        paddingTime,
        requireShowing: requireShowing ? requireShowing : isFixedOffice,
        operationHours: createOpenAndCloseTime(values),
        // todo - re make this to allow for currencies
        priceMax: priceMax ? priceMax.amount * (1 + vatPercentage / 100) : null,
        requestPrice,
        acceptBookingOnRequest: category !== MEETINGROOMS_CATEGORY ? null : acceptBookingOnRequest,
        ...(isFixedOffice && {
          startDate,
          priceUnit,
          pricePerUnit,
          priceSubletting,
          contractType,
          contractLengthPerUnit: noReqForContractLength
            ? 0
            : contractLengthUnit === 'month'
            ? contractLengthPerUnit
            : contractLengthPerUnit * 12,
          contractLengthUnit: noReqForContractLength ? '' : contractLengthUnit,
          paymentPeriod,
          noticePeriodPerUnit,
          noticePeriodUnit,
          noReqForContractLength,
          allowVatExemptCompanies,
        }),
      },
    };
    submittedValues.current = values;

    try {
      await onSubmit(updateValues);
    } catch (e) {
      console.error(e);
    }
  }

  const timeZone = get(currentListing, 'attributes.availabilityPlan.timezone', defaultTimeZone());

  const onSaveAndExitHandlers = (values) => () => {
    const {
      bookingType,
      price,
      priceMax,
      requestPrice,
      vatPercentage = 25,
      amount,
      unit,
      acceptBookingOnRequest,
      category,
      startDate,
      pricePerUnit,
      priceUnit,
      priceSubletting,
      paymentPeriod,
      contractType,
      contractLengthUnit,
      contractLengthPerUnit,
      noReqForContractLength,
      noticePeriodPerUnit,
      noticePeriodUnit,
      allowVatExemptCompanies,
    } = values;
    let { requireShowing } = values;

    const paddingTime = amount
      ? {
          amount: amount,
          unit: unit,
        }
      : null;

    const plan = {
      type: bookingType === HOURLY_BOOKING ? AVAILABILITY_PLAN_TIME : AVAILABILITY_PLAN_DAY,
      entries: createEntriesFromSubmitValues(values, maxSeats, numberOfInstance, isPrivate),
    };

    if (bookingType === HOURLY_BOOKING) {
      plan.timezone = defaultTimeZone();
    }
    const operationHours = createOpenAndCloseTime(values);
    if (requireShowing && bookingType !== MONTHLY_BOOKING && !isFixedOffice) {
      requireShowing = false;
    }
    let publicData = pickBy({
      bookingType,
      vatPercentage,
      paddingTime,
      priceMax: priceMax ? priceMax.amount * (1 + vatPercentage / 100) : null,
      requestPrice,
      acceptBookingOnRequest: category !== MEETINGROOMS_CATEGORY ? null : acceptBookingOnRequest,
    });
    if (requireShowing !== null || requireShowing !== undefined) {
      publicData = { ...publicData, requireShowing };
    }
    if (Object.keys(operationHours).length > 0) {
      publicData = { ...publicData, operationHours };
    }

    const updateValues = {
      price: calculatedPrice(pricePerUnit, price, vatPercentage),
      availabilityPlan: plan,
      publicData: {
        bookingType,
        vatPercentage,
        paddingTime,
        requireShowing: requireShowing ? requireShowing : isFixedOffice,
        operationHours: createOpenAndCloseTime(values),
        // todo - re make this to allow for currencies
        priceMax: priceMax ? priceMax.amount * (1 + vatPercentage / 100) : null,
        requestPrice,
        acceptBookingOnRequest: category !== MEETINGROOMS_CATEGORY ? null : acceptBookingOnRequest,
        ...(isFixedOffice && {
          startDate,
          priceUnit,
          pricePerUnit,
          contractType,
          contractLengthPerUnit: noReqForContractLength
            ? 0
            : contractLengthUnit === 'month'
            ? contractLengthPerUnit
            : contractLengthPerUnit * 12,
          priceSubletting,
          contractLengthUnit: noReqForContractLength ? '' : contractLengthUnit,
          paymentPeriod,
          noticePeriodPerUnit,
          noticePeriodUnit,
          noReqForContractLength,
          allowVatExemptCompanies,
        }),
      },
    };

    onSaveAndExitListingWizardTab(updateValues);
  };

  return (
    <main className={classes}>
      <div className={css.panelContainer}>
        <div className={css.title}>
          {category === FIXED_OFFICE_CATEGORY ? (
            <div>
              <FormattedMessage id="EditListingAvailabilityPanel.createListingTitleFixed" />
              <p>
                {intl.formatMessage(
                  { id: 'EditListingAvailabilityPanel.createListingSubtitleFixed' },
                  {
                    bold: (val) => <span className={classNames(css.boldText)}>{val}</span>,
                  }
                )}
              </p>
            </div>
          ) : (
            <FormattedMessage id="EditListingAvailabilityPanel.createListingTitle" />
          )}
        </div>

        <FinalForm
          mutators={{
            ...arrayMutators,
          }}
          initialValues={{
            ...initialValues,
            ...submittedValues.current,
            category,
          }}
          onSubmit={applyNewAvailability}
          render={(formRenderProps) => {
            const { handleSubmit, values, pristine, form, invalid } = formRenderProps;
            const bookingType = get(values, 'bookingType', HOURLY_BOOKING);
            const operationHours =
              bookingType === DAILY_BOOKING
                ? get(currentListing, 'attributes.publicData.operationHours', {})
                : {};
            return category === FIXED_OFFICE_CATEGORY && host === HOST_TYPE_OTHER_COMPANY ? (
              // Subleasing listings
              <SectionSubletting
                intl={intl}
                disabled={disabled || invalid}
                ready={ready}
                errors={errors}
                isPublished={isPublished}
                onManageDisableScrolling={onManageDisableScrolling}
                handleSubmit={handleSubmit}
                updateInProgress={updateInProgress}
                values={values}
                pristine={pristine}
                onSaveAndExitHandlers={onSaveAndExitHandlers}
                submittedValues={submittedValues}
                form={form}
              />
            ) : (
              //On demand and fixed office listings
              <SectionNew
                operationHours={operationHours}
                intl={intl}
                fetchExceptionsInProgress={fetchExceptionsInProgress}
                exceptionCount={exceptionCount}
                sortedAvailabilityExceptions={sortedAvailabilityExceptions}
                onDeleteAvailabilityException={onDeleteAvailabilityException}
                timeZone={timeZone}
                disabled={disabled || invalid}
                ready={ready}
                errors={errors}
                isPublished={isPublished}
                onNextTab={onNextTab}
                onManageDisableScrolling={onManageDisableScrolling}
                handleSubmit={handleSubmit}
                updateInProgress={updateInProgress}
                submitButtonText={submitButtonText}
                saveException={saveException}
                addExceptionInProgress={addExceptionInProgress}
                values={values}
                pristine={pristine}
                form={form}
                onSaveAndExitHandlers={onSaveAndExitHandlers}
                submittedValues={submittedValues}
                paddingTime={paddingTime}
                isFixedOffice={isFixedOffice}
                comission={comission}
              />
            );
          }}
        />
      </div>
    </main>
  );
}, isEqual);

EditListingAvailabilityPanel.defaultProps = {
  className: null,
  rootClassName: null,
  listing: null,
  availabilityExceptions: [],
};

EditListingAvailabilityPanel.propTypes = {
  className: string,
  rootClassName: string,

  // We cannot use propTypes.listing since the listing might be a draft.
  listing: object,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  availabilityExceptions: arrayOf(propTypes.availabilityException),
  fetchExceptionsInProgress: bool.isRequired,
  onAddAvailabilityException: func.isRequired,
  onDeleteAvailabilityException: func.isRequired,
  onSubmit: func.isRequired,
  onManageDisableScrolling: func.isRequired,
  onNextTab: func.isRequired,
  submitButtonText: string.isRequired,
  updateInProgress: bool.isRequired,
  errors: object.isRequired,
};

export default injectIntl(EditListingAvailabilityPanel);
