import { requestTeamScheduleHazura } from '../util/api';

export const UPDATE_TOTAL_CREDITS = 'app/b2b/UPDATE_TOTAL_CREDITS';
export const UPDATE_SELECTED_PACKAGE = 'app/b2b/UPDATE_SELECTED_PACKAGE';
export const UPDATE_START_DATE = 'app/b2b/UPDATE_START_DATE';
export const UPDATE_COUPON_CODE = 'app/b2b/UPDATE_COUPON_CODE';
export const TOGGLE_PROCEED_LOADING = 'app/b2b/TOGGLE_PROCEED_LOADING';
export const UPDATE_RETRIEVED_COUPON = 'app/b2b/UPDATE_RETRIEVED_COUPON';
export const UPDATE_CALCULATED_PRICES = 'app/b2b/UPDATE_CALCULATED_PRICES';
export const UPDATE_CURRENT_BOOKING_ID = 'app/b2b/UPDATE_CURRENT_BOOKING_ID';
export const SET_TOTAL_LOCATIONS = 'app/b2b/SET_TOTAL_LOCATIONS';

/**
 * @param payload {Number} */
export const updateTotalCredits = (payload) => ({ type: UPDATE_TOTAL_CREDITS, payload });

/**
 * @param payload {String} */
export const updateSelectedPackage = (payload) => ({ type: UPDATE_SELECTED_PACKAGE, payload });

export const updateStartDate = (payload) => ({
  type: UPDATE_START_DATE,
  payload,
});

export const updateCouponCode = (payload) => ({
  type: UPDATE_COUPON_CODE,
  payload,
});

export const updateRetrievedCoupon = (payload) => ({
  type: UPDATE_RETRIEVED_COUPON,
  payload,
});

export const updateCalculatedPrices = (payload) => ({
  type: UPDATE_CALCULATED_PRICES,
  payload,
});

export const toggleProceedLoading = (payload) => ({
  type: TOGGLE_PROCEED_LOADING,
  payload,
});

export const updateCurrentBookingIdAction = (payload) => ({
  type: UPDATE_CURRENT_BOOKING_ID,
  payload,
});

export const setTotalLocations = (payload) => ({
  type: SET_TOTAL_LOCATIONS,
  payload,
});

const initialState = {
  couponCode: null,
  totalCredits: 25,
  totalUsers: 1,
  selectedPackage: null,
  packageSize: null,
  packageInfo: {
    employeeCount: 1,
    daysPerWeek: 1,
    hoursPerWeek: 0,
    totalCredits: 25,
    totalPrice: 1000,
  },
  processStartDate: {
    date: new Date(),
  },
  proceedLoading: false,
  retrievedCoupon: null,
  calculatedPrices: {
    originalPrice: 1000,
  },
  currentBookingId: '',
  totalLocations: 0,
};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;

  switch (type) {
    case UPDATE_TOTAL_CREDITS:
      return {
        ...state,
        totalCredits: payload,
      };
    case UPDATE_SELECTED_PACKAGE:
      return {
        ...state,
        selectedPackage: payload,
      };

    case UPDATE_START_DATE:
      return {
        ...state,
        processStartDate: payload,
      };

    case UPDATE_COUPON_CODE:
      return {
        ...state,
        couponCode: payload,
      };

    case TOGGLE_PROCEED_LOADING:
      return {
        ...state,
        proceedLoading: payload,
      };

    case UPDATE_RETRIEVED_COUPON:
      return {
        ...state,
        retrievedCoupon: payload,
      };

    case UPDATE_CALCULATED_PRICES:
      return {
        ...state,
        calculatedPrices: payload,
      };

    case UPDATE_CURRENT_BOOKING_ID:
      return {
        ...state,
        currentBookingId: payload,
      };

    case SET_TOTAL_LOCATIONS:
      return {
        ...state,
        totalLocations: payload,
      };

    default:
      return state;
  }
}

export const updateCurrentBookingId = (bookingId) => (dispatch, getState, sdk) => {
  dispatch(updateCurrentBookingIdAction(bookingId));
};
