import React, { useState } from 'react';
import classNames from 'classnames';
import { shape, string } from 'prop-types';

import { ButtonNew } from '../index';
import { BUTTON_TYPES, LINK_COLORS } from '../ButtonNew/ButtonNew';

import css from './DetailsTab.module.scss';

const DetailsTab = ({ title, subtitle, text, link }) => {
  const [isOpen, toggleOpen] = useState(false);

  return (
    <div className={classNames(css.main, isOpen && css.open)}>
      <button className={css.trigger} onClick={() => toggleOpen(!isOpen)}>
        <span className={css.title}>{title}</span>
        {!!subtitle ? <p className={css.subtitle}>{subtitle}</p> : null}
        <svg
          className={classNames(css.icon, isOpen && css.rotated)}
          width="18"
          height="11"
          fill="none"
          viewBox="0 0 18 11"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M17 1L9 9L1 1" strokeWidth="1.5" />
        </svg>
      </button>
      <div className={css.body}>
        <p className={css.text}>{text}</p>
        {!!link ? (
          <ButtonNew
            name={link.to}
            type={BUTTON_TYPES.INLINE}
            linkColor={LINK_COLORS.BLUE}
            className={css.link}
            withIcon
          >
            {link.text}
          </ButtonNew>
        ) : null}
      </div>
    </div>
  );
};

DetailsTab.defaultProps = {
  subtitle: null,
  link: null,
};

DetailsTab.propTypes = {
  title: string.isRequired,
  subtitle: string,
  text: string.isRequired,
  link: shape({
    text: string.isRequired,
    to: string.isRequired,
  }),
};

export default DetailsTab;
