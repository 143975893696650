import React from 'react';
import { StaticPage, TopbarContainer } from '..';
import {
  LayoutSingleColumn,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperFooter,
} from '../../components';
import { getLocale } from '../../util/localeHelper';
import { LANGUAGE_CODES } from '../../util/modernmt';
import { Widget } from '@typeform/embed-react';
import config from '../../config';
import css from './OnboardingPage.module.scss';

const OnboardingPage = () => {
  let formId = getLocale() === LANGUAGE_CODES.SWEDISH ? 'w4HjQUjG' : 'kzqJ92YT';

  if (config.dev) formId = formId + '?__dangerous-disable-submissions';

  return (
    <StaticPage
      title="Respaces Customer Onboarding"
      description="Page with a form to collection information on the requirements of a customer's office solution."
      schema={{
        '@context': 'http://schema.org',
        '@type': 'WebPage',
        description: 'Questionaire to get started with Respaces',
        name: 'Respaces Customer Onboarding',
      }}
    >
      <LayoutSingleColumn>
        <LayoutWrapperTopbar>
          <TopbarContainer />
        </LayoutWrapperTopbar>
        <LayoutWrapperMain className={css.wrapperMain}>
          <Widget id={formId} className={css.typeform} />
        </LayoutWrapperMain>
        <LayoutWrapperFooter />
      </LayoutSingleColumn>
    </StaticPage>
  );
};

export default OnboardingPage;
