import React from 'react';
import css from '../DayAvailabilityPlan/DayAvailabilityPlan.module.scss';

const PasteIcon = ({ className }) => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      className={className === 'iconGrey' && css.iconGrey}
      strokeWidth="0.5px"
      d="M5 1C5.3625 1 5.66563 1.25625 5.73438 1.6C5.78125 1.83125 5.9875 2 6.225 2H6.5C6.775 2 7 2.225 7 2.5V3H3V2.5C3 2.225 3.225 2 3.5 2H3.775C4.0125 2 4.21875 1.83125 4.26562 1.6C4.33437 1.25625 4.6375 1 5 1ZM2 2H2.08437C2.03125 2.15625 2 2.325 2 2.5V3C2 3.55312 2.44688 4 3 4H7C7.55312 4 8 3.55312 8 3V2.5C8 2.325 7.96875 2.15625 7.91563 2H8C8.55313 2 9 2.44688 9 3H10C10 1.89688 9.10312 1 8 1H6.58125C6.3 0.409375 5.69688 0 5 0C4.30312 0 3.7 0.409375 3.41875 1H2C0.896875 1 0 1.89688 0 3V12C0 13.1031 0.896875 14 2 14H6V13H2C1.44687 13 1 12.5531 1 12V3C1 2.44688 1.44687 2 2 2ZM9 15C8.44687 15 8 14.5531 8 14V6C8 5.44688 8.44687 5 9 5H12V6.75C12 7.44063 12.5594 8 13.25 8H15V14C15 14.5531 14.5531 15 14 15H9ZM13 5.16563L14.8344 7H13.25C13.1125 7 13 6.8875 13 6.75V5.16563ZM14 16C15.1031 16 16 15.1031 16 14V7.37187C16 6.975 15.8406 6.59375 15.5594 6.3125L13.6875 4.44063C13.4062 4.15938 13.025 4 12.6281 4H9C7.89687 4 7 4.89687 7 6V14C7 15.1031 7.89687 16 9 16H14Z"
      fill="#999999"
    />
  </svg>
);

export default PasteIcon;
