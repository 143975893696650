import React, { useEffect, useState } from 'react';
import moment from 'moment';
import classNames from 'classnames';
import { arrayOf, bool, oneOf, shape, string } from 'prop-types';

import {
  BookingCard,
  DayTitle,
  SeeMoreSpacesButton,
  SetStatusButton,
  SetStatusMenu,
} from '../index';
import OutsideClickHandler from '../../../../components/OutsideClickHandler/OutsideClickHandler';

import { DAY_NAME_WITH_DATE } from '../../config/constants';

import css from './Day.module.scss';

const LISTING_TYPES = {
  COWORKING: 'coworking',
  MEETING: 'meeting',
  PRIVATE: 'private',
  SCHEDULE: 'schedule',
};

LISTING_TYPES.LIST = [
  LISTING_TYPES.COWORKING,
  LISTING_TYPES.MEETING,
  LISTING_TYPES.PRIVATE,
  LISTING_TYPES.SCHEDULE,
];

const Day = ({
  date,
  bookings,
  currentUserId,
  onOpenSidebar,
  setShowJoinSpaceButton,
  scheduleUpdate,
  setScheduleUpdate,
}) => {
  const [isMenuOpen, setMenuOpen] = useState(false);
  const [loadingStatus, setLoadingStatus] = useState(false);

  useEffect(() => setLoadingStatus(false), [bookings]);

  const userHasBookings = bookings.some((booking) =>
    booking.users.some((user) => user.authorId === currentUserId || user.userId === currentUserId)
  );

  // Make sure that the bookings that the user has are always on top
  if (userHasBookings) {
    bookings.sort((a, b) => {
      return a.users.some((user) => user.authorId === currentUserId) ===
        b.users.some((user) => user.authorId === currentUserId)
        ? 0
        : a.users.some((user) => user.authorId === currentUserId)
        ? -1
        : 1;
    });
  }

  const showSeeMoreButton = bookings.length > 2;
  const showSetStatusButton = !bookings.length || !userHasBookings;

  const [day, dayDate] = moment(date).format(DAY_NAME_WITH_DATE).split(' ');
  const isToday = date === moment(new Date()).format('DD-MMM-YYYY');

  const finalBookings = showSeeMoreButton ? bookings.slice(0, 2) : bookings;

  return (
    <div className={classNames(css.day, isToday && css.today)}>
      <DayTitle
        day={day}
        date={dayDate}
        userHasBookings={userHasBookings}
        onOpenSidebar={onOpenSidebar}
        isToday={isToday}
      />
      <div>
        {showSetStatusButton && (
          <div className={css.statusButtonWrapper}>
            <OutsideClickHandler
              className={css.statusButtonOutsideClick}
              onOutsideClick={() => {
                setMenuOpen(false);
              }}
            >
              <SetStatusButton
                loadingStatus={loadingStatus}
                onClick={() => setMenuOpen(!isMenuOpen)}
              />
              {isMenuOpen && (
                <SetStatusMenu
                  date={date}
                  setMenuOpen={setMenuOpen}
                  scheduleUpdate={scheduleUpdate}
                  setScheduleUpdate={setScheduleUpdate}
                  setLoadingStatus={setLoadingStatus}
                />
              )}
            </OutsideClickHandler>
          </div>
        )}
        {finalBookings.map((b) => (
          <div className={css.bookingCardWrapper} key={`booking_card_${b.bookingId}`}>
            <BookingCard
              authorId={b.authorId}
              bookingId={b.bookingId}
              currentUserId={currentUserId}
              listingId={b.listingId}
              listingName={b.listingTitle}
              listingCategory={b.listingCategory}
              date={new Date(date)}
              times={b.times}
              onOpenSidebar={() => onOpenSidebar(b)}
              isFilled={b.users.some(
                (user) => user.authorId === currentUserId || user.userId === currentUserId
              )}
              users={b.users}
              setShowJoinSpaceButton={setShowJoinSpaceButton}
              scheduleUpdate={scheduleUpdate}
              setScheduleUpdate={setScheduleUpdate}
              locationId={b.locationId}
              locationName={b.locationName}
            />
          </div>
        ))}
        {showSeeMoreButton && (
          <div className={css.seeMoreSpacesButtonWrapper}>
            <SeeMoreSpacesButton
              onShowMoreSpaces={onOpenSidebar}
              numberOfExtraBookings={bookings.length - 2}
            />
          </div>
        )}
      </div>
    </div>
  );
};

Day.propTypes = {
  date: string.isRequired,
  bookings: arrayOf(
    shape({
      listingId: string.isRequired,
      listingTitle: string.isRequired,
      listingCategory: oneOf(LISTING_TYPES.LIST).isRequired,
      times: shape({
        startTime: string.isRequired,
        endTime: string.isRequired,
      }),
      users: arrayOf(
        shape({
          userId: string.isRequired,
          firstName: string.isRequired,
          lastName: string.isRequired,
          avatarURL: string,
        })
      ),
    })
  ).isRequired,
};

export default Day;
