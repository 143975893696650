import React from 'react';
import { compose } from 'redux';
import { FormattedMessage, injectIntl } from '../../util/reactIntl';
import { Form as FinalForm } from 'react-final-form';
import css from './DownloadReceiptForm.module.scss';
import * as validators from '../../util/validators';
import { Form, PrimaryButton, FieldTextInput } from '../../components';

import createPDF from '../../util/createPDF';

const DownloadReceiptFormComponent = (props) => {
  const { setIsReceiptModalOpen, tx, currentUser, intl } = props;

  return (
    <FinalForm
      {...props}
      onSubmit={(values) => {
        createPDF(tx, currentUser, values, intl);

        setIsReceiptModalOpen(false);
      }}
      render={(formRenderProps) => {
        const { formId, handleSubmit, invalid, intl } = formRenderProps;

        // companyName
        const companyNameLabel = intl.formatMessage({
          id: 'DownloadReceiptForm.companyNameLabel',
        });
        const companyNamePlaceholder = intl.formatMessage({
          id: 'DownloadReceiptForm.companyNamePlaceholder',
        });

        // companyNumber
        const companyNumberLabel = intl.formatMessage({
          id: 'DownloadReceiptForm.companyNumberLabel',
        });
        const companyNumberPlaceholder = intl.formatMessage({
          id: 'DownloadReceiptForm.companyNumberPlaceholder',
        });

        const formHeader = intl.formatMessage({
          id: 'DownloadReceiptForm.formHeader',
        });

        // companyAddress1
        const companyAddress1Label = intl.formatMessage({
          id: 'DownloadReceiptForm.companyAddress1Label',
        });
        const companyAddress1Placeholder = intl.formatMessage({
          id: 'DownloadReceiptForm.companyAddress1Placeholder',
        });

        // companyAddress2
        const companyAddress2Label = intl.formatMessage({
          id: 'DownloadReceiptForm.companyAddress2Label',
        });
        const companyAddress2Placeholder = intl.formatMessage({
          id: 'DownloadReceiptForm.companyAddress2Placeholder',
        });

        // companyPostcode
        const companyPostcodeLabel = intl.formatMessage({
          id: 'DownloadReceiptForm.companyPostcodeLabel',
        });
        const companyPostcodePlaceholder = intl.formatMessage({
          id: 'DownloadReceiptForm.companyPostcodePlaceholder',
        });

        // companyCountry
        const companyCountryLabel = intl.formatMessage({
          id: 'DownloadReceiptForm.companyCountryLabel',
        });
        const companyCountryPlaceholder = intl.formatMessage({
          id: 'DownloadReceiptForm.companyCountryPlaceholder',
        });

        return (
          <Form className={css.subscribeForm}>
            <h3>{formHeader}</h3>
            <div>
              <FieldTextInput
                className={css.firstNameRoot}
                type="text"
                id={formId ? `${formId}.firstName` : 'firstName'}
                name="companyName"
                label={companyNameLabel}
                placeholder={companyNamePlaceholder}
              />
              <FieldTextInput
                type="text"
                id={formId ? `${formId}.email` : 'email'}
                name="companyNumber"
                label={companyNumberLabel}
                placeholder={companyNumberPlaceholder}
              />
              <FieldTextInput
                className={css.firstNameRoot}
                type="text"
                id={formId ? `${formId}.firstName` : 'firstName'}
                name="companyAddress1"
                label={companyAddress1Label}
                placeholder={companyAddress1Placeholder}
              />
              <FieldTextInput
                className={css.firstNameRoot}
                type="text"
                id={formId ? `${formId}.firstName` : 'firstName'}
                name="companyAddress2"
                label={companyAddress2Label}
                placeholder={companyAddress2Placeholder}
              />
              <FieldTextInput
                className={css.firstNameRoot}
                type="text"
                id={formId ? `${formId}.firstName` : 'firstName'}
                name="companyCountry"
                label={companyCountryLabel}
                placeholder={companyCountryPlaceholder}
              />
              <FieldTextInput
                className={css.firstNameRoot}
                type="text"
                id={formId ? `${formId}.firstName` : 'firstName'}
                name="companyPostcode"
                label={companyPostcodeLabel}
                placeholder={companyPostcodePlaceholder}
              />
            </div>
            <PrimaryButton
              className={css.button}
              type="submit"
              onClick={(e) => {
                e.preventDefault();
                handleSubmit();
              }}
            >
              <FormattedMessage id="DownloadReceiptForm.downloadButton" />
            </PrimaryButton>
          </Form>
        );
      }}
    />
  );
};

const DownloadReceiptForm = compose(injectIntl)(DownloadReceiptFormComponent);
DownloadReceiptForm.displayName = 'DownloadReceiptForm';
export default DownloadReceiptForm;
