import React from 'react';
import classNames from 'classnames';
import css from './NoMemberImage.module.scss';

const NoMemberImage = (props) => {
  const { className } = props;

  const classes = classNames(className || css.imageWrapper);

  return (
    <div className={classes}>
      <svg
        width="65"
        height="75"
        viewBox="0 0 65 75"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g opacity="0.31">
          <path
            d="M56.5741 74.6296C58.6661 74.6296 60.1852 73.1106 60.1852 71.0185V3.61111C60.1852 1.51907 58.6661 0 56.5741 0H8.42593C6.33389 0 4.81482 1.51907 4.81482 3.61111V6.42056C4.81482 6.7398 4.94164 7.04597 5.16738 7.2717C5.39311 7.49744 5.69928 7.62426 6.01852 7.62426C6.33777 7.62426 6.64393 7.49744 6.86967 7.2717C7.09541 7.04597 7.22223 6.7398 7.22223 6.42056V3.61111C7.22223 2.85759 7.67241 2.40741 8.42593 2.40741H19.4085V72.2222H8.42593C7.67241 72.2222 7.22223 71.772 7.22223 71.0185V68.8109C7.22223 68.4917 7.09541 68.1855 6.86967 67.9598C6.64393 67.734 6.33777 67.6072 6.01852 67.6072C5.69928 67.6072 5.39311 67.734 5.16738 67.9598C4.94164 68.1855 4.81482 68.4917 4.81482 68.8109V71.0185C4.81482 73.1106 6.33389 74.6296 8.42593 74.6296H56.5741ZM21.6667 2.40741H56.5741C57.3276 2.40741 57.7778 2.85759 57.7778 3.61111V71.0185C57.7778 71.772 57.3276 72.2222 56.5741 72.2222H21.6667V2.40741Z"
            fill="black"
          />
          <path
            d="M65 68.6111V6.01852C65 5.69928 64.8732 5.39311 64.6474 5.16738C64.4217 4.94164 64.1155 4.81482 63.7963 4.81482C63.4771 4.81482 63.1709 4.94164 62.9451 5.16738C62.7194 5.39311 62.5926 5.69928 62.5926 6.01852V68.6111C62.5926 68.9304 62.7194 69.2365 62.9451 69.4623C63.1709 69.688 63.4771 69.8148 63.7963 69.8148C64.1155 69.8148 64.4217 69.688 64.6474 69.4623C64.8732 69.2365 65 68.9304 65 68.6111Z"
            fill="black"
          />
          <path
            d="M1.2037 24.074H10.8333C11.1526 24.074 11.4587 23.9472 11.6845 23.7215C11.9102 23.4957 12.037 23.1896 12.037 22.8703C12.037 22.5511 11.9102 22.2449 11.6845 22.0192C11.4587 21.7934 11.1526 21.6666 10.8333 21.6666H1.2037C0.884462 21.6666 0.578295 21.7934 0.352557 22.0192C0.126818 22.2449 0 22.5511 0 22.8703C0 23.1896 0.126818 23.4957 0.352557 23.7215C0.578295 23.9472 0.884462 24.074 1.2037 24.074Z"
            fill="black"
          />
          <path
            d="M1.2037 14.4444H10.8333C11.1526 14.4444 11.4587 14.3176 11.6845 14.0918C11.9102 13.8661 12.037 13.5599 12.037 13.2407C12.037 12.9214 11.9102 12.6153 11.6845 12.3895C11.4587 12.1638 11.1526 12.037 10.8333 12.037H1.2037C0.884462 12.037 0.578295 12.1638 0.352557 12.3895C0.126818 12.6153 0 12.9214 0 13.2407C0 13.5599 0.126818 13.8661 0.352557 14.0918C0.578295 14.3176 0.884462 14.4444 1.2037 14.4444Z"
            fill="black"
          />
          <path
            d="M1.2037 33.7037H10.8333C11.1526 33.7037 11.4587 33.5769 11.6845 33.3511C11.9102 33.1254 12.037 32.8192 12.037 32.5C12.037 32.1807 11.9102 31.8746 11.6845 31.6488C11.4587 31.4231 11.1526 31.2963 10.8333 31.2963H1.2037C0.884462 31.2963 0.578295 31.4231 0.352557 31.6488C0.126818 31.8746 0 32.1807 0 32.5C0 32.8192 0.126818 33.1254 0.352557 33.3511C0.578295 33.5769 0.884462 33.7037 1.2037 33.7037Z"
            fill="black"
          />
          <path
            d="M1.2037 52.9629H10.8333C11.1526 52.9629 11.4587 52.8361 11.6845 52.6104C11.9102 52.3847 12.037 52.0785 12.037 51.7592C12.037 51.44 11.9102 51.1338 11.6845 50.9081C11.4587 50.6824 11.1526 50.5555 10.8333 50.5555H1.2037C0.884462 50.5555 0.578295 50.6824 0.352557 50.9081C0.126818 51.1338 0 51.44 0 51.7592C0 52.0785 0.126818 52.3847 0.352557 52.6104C0.578295 52.8361 0.884462 52.9629 1.2037 52.9629Z"
            fill="black"
          />
          <path
            d="M1.2037 43.3333H10.8333C11.1526 43.3333 11.4587 43.2065 11.6845 42.9808C11.9102 42.755 12.037 42.4488 12.037 42.1296C12.037 41.8104 11.9102 41.5042 11.6845 41.2785C11.4587 41.0527 11.1526 40.9259 10.8333 40.9259H1.2037C0.884462 40.9259 0.578295 41.0527 0.352557 41.2785C0.126818 41.5042 0 41.8104 0 42.1296C0 42.4488 0.126818 42.755 0.352557 42.9808C0.578295 43.2065 0.884462 43.3333 1.2037 43.3333Z"
            fill="black"
          />
          <path
            d="M1.2037 62.5926H10.8333C11.1526 62.5926 11.4587 62.4658 11.6845 62.24C11.9102 62.0143 12.037 61.7081 12.037 61.3889C12.037 61.0696 11.9102 60.7635 11.6845 60.5377C11.4587 60.312 11.1526 60.1852 10.8333 60.1852H1.2037C0.884462 60.1852 0.578295 60.312 0.352557 60.5377C0.126818 60.7635 0 61.0696 0 61.3889C0 61.7081 0.126818 62.0143 0.352557 62.24C0.578295 62.4658 0.884462 62.5926 1.2037 62.5926Z"
            fill="black"
          />
        </g>
      </svg>
    </div>
  );
};

export default NoMemberImage;
