import React from 'react';
import classNames from 'classnames';
import { oneOf } from 'prop-types';
import { injectIntl } from 'react-intl';

import StatusModel from '../../models/StatusModel';

import css from './ListingStatus.module.scss';

const ListingStatus = ({ status, intl }) => (
  <div className={classNames(css.status, css[StatusModel.ID_TO_DATA[status]?.color])}>
    {!!StatusModel.ID_TO_DATA[status]
      ? intl.formatMessage({ id: StatusModel.ID_TO_DATA[status]?.label })
      : ''}
  </div>
);

ListingStatus.propTypes = {
  status: oneOf(StatusModel.TYPES),
};

export default injectIntl(ListingStatus);
