import React, { useEffect, useState } from 'react';
import { usePrevious } from '../../util/hooks';
import Modal from '../Modal/Modal';
import css from './ModalMobileMenu.module.scss';

const ModalMobileMenu = ({ onManageDisableScrolling, onClose, isOpen, children }) => {
  const [closeClass, setCloseClass] = useState(css.close);
  const previousState = usePrevious(isOpen);
  useEffect(() => {
    if (!isOpen && previousState) {
      setCloseClass(() => css.closeAnimation);
      setTimeout(() => {
        setCloseClass(() => css.close);
      }, 1000);
    }
  }, [isOpen, previousState]);

  return (
    <Modal
      id="TopbarMobileMenu"
      isOpen={isOpen}
      onClose={onClose}
      usePortal
      onManageDisableScrolling={onManageDisableScrolling}
      openClassName={css.open}
      isClosedClassName={closeClass}
    >
      {children}
    </Modal>
  );
};

export default ModalMobileMenu;
