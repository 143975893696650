import React, { useState } from 'react';
import { arrayOf, bool, shape, string } from 'prop-types';
import classNames from 'classnames';
import css from './DropDownList.module.scss';
import { CSSTransition } from 'react-transition-group';
import './DropDownList.module.scss';

const DropDownList = ({ menuList, bold, border, indent }) => {
  const [openSection, setOpenSection] = useState('');
  const classes = classNames(css.itemHeader, border && css.border, bold && css.bold);
  return (
    <div className={css.wrapper}>
      {menuList.length &&
        menuList.map((item, index) => {
          return (
            <div key={item.header} className={css.itemWrapper}>
              <div
                className={classes}
                onClick={() => (openSection === index ? setOpenSection('') : setOpenSection(index))}
              >
                <div className={css.itemText}>{item.header}</div>
                <div className={css.openIcon}>
                  <p className={openSection === index ? css.closeIcon : undefined}>+</p>
                </div>
              </div>
              <CSSTransition
                in={openSection === index}
                classNames="item-display"
                unmountOnExit
                timeout={300}
              >
                <div
                  className={classNames(css.content, indent && css.indent)}
                  style={{ outflow: 'hidden' }}
                >
                  {item.content}
                </div>
              </CSSTransition>
            </div>
          );
        })}
    </div>
  );
};

DropDownList.defaultProps = {
  menuList: [],
  bold: false,
  border: false,
  indent: false,
};

DropDownList.propTypes = {
  menuList: arrayOf(
    shape({
      header: string.isRequired,
      content: string.isRequired,
    })
  ).isRequired,
  bold: bool,
  border: bool,
  indent: bool,
};

export default DropDownList;
