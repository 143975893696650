import React, { Component } from 'react';
import { string, func, arrayOf, shape, number, object } from 'prop-types';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { sortByNearbySpaces } from '../../containers/SearchPage/SearchPage.duck';
import { Menu, MenuContent, MenuItem, MenuLabel } from '../../components';
import css from './SortByPopup.module.scss';

const optionLabel = (options, key) => {
  const option = options.find((o) => o.key === key);
  return option ? option.labelId : `MarketplaceConfig.sortConfig.${key}`;
};

const SortByIconUp = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 384 512">
      <path d="M203.3 36.7c-6.2-6.2-16.4-6.2-22.6 0l-176 176c-6.2 6.2-6.2 16.4 0 22.6s16.4 6.2 22.6 0L176 86.6V464c0 8.8 7.2 16 16 16s16-7.2 16-16V86.6L356.7 235.3c6.2 6.2 16.4 6.2 22.6 0s6.2-16.4 0-22.6l-176-176z" />
    </svg>
  );
};

const SortByIconDown = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 384 512">
      <path d="M180.7 475.3c6.2 6.2 16.4 6.2 22.6 0l176-176c6.2-6.2 6.2-16.4 0-22.6s-16.4-6.2-22.6 0L208 425.4 208 48c0-8.8-7.2-16-16-16s-16 7.2-16 16l0 377.4L27.3 276.7c-6.2-6.2-16.4-6.2-22.6 0s-6.2 16.4 0 22.6l176 176z" />
    </svg>
  );
};

class SortByPopup extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
      sortDirection: props?.sortBy?.order,
      sortOption: props?.sortBy?.field,
      isListingFilter: props?.sortBy?.isListingFilter,
    };
    this.onToggleActive = this.onToggleActive.bind(this);
    this.selectOption = this.selectOption.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.sortBy !== this.props.sortBy) {
      this.setState({
        sortDirection: this.props?.sortBy?.order,
        sortOption: this.props?.sortBy?.field,
        isListingFilter: this.props?.sortBy?.isListingFilter,
      });
    }
  }

  onToggleActive(isOpen) {
    this.setState({ isOpen: isOpen });
  }

  selectOption(urlParam, option) {
    this.setState({ isOpen: false });
    this.props.onSelect(
      urlParam,
      this.state.sortOption,
      this.state.sortDirection,
      this.state.isListingFilter
    );
  }

  render() {
    const {
      rootClassName,
      className,
      menuLabelRootClassName,
      urlParam,
      options,
      initialValue,
      contentPlacementOffset,
      intl,
      sortByNearbySpaces,
    } = this.props;

    // resolve menu label text and class
    const menuLabelId = optionLabel(options, this.state.sortOption);
    const menuLabel = menuLabelId && intl.formatMessage({ id: menuLabelId });

    const classes = classNames(rootClassName || css.root, className);
    const menuLabelClasses = classNames(menuLabelRootClassName || css.menuLabel);

    return (
      <>
        <div
          className={css.sortByIcon}
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            this.setState(
              {
                sortDirection:
                  this.state.sortOption !== 'distance' && this.state.sortDirection === 'asc'
                    ? 'desc'
                    : 'asc',
              },
              () => {
                this.selectOption(urlParam, this.state.sortOption);
                sortByNearbySpaces(false);
              }
            );
          }}
        >
          {this.state.sortDirection === 'asc' ? <SortByIconUp /> : <SortByIconDown />}
        </div>
        <Menu
          className={classes}
          useArrow={false}
          contentPlacementOffset={contentPlacementOffset}
          onToggleActive={this.onToggleActive}
          isOpen={this.state.isOpen}
        >
          <MenuLabel className={menuLabelClasses}>
            <span className={css.bold}>{menuLabel}</span>
          </MenuLabel>
          <MenuContent className={css.menuContent}>
            {options.map((option) => {
              // check if this option is selected
              const selected = initialValue === option.key;
              const labelMaybe = intl.formatMessage({
                id: option.labelId,
                defaultMessage: option.label,
              });

              return (
                <MenuItem key={option.key}>
                  <button
                    className={`${css.menuItem} ${selected && css.selected}`}
                    disabled={option.disabled}
                    onClick={() => {
                      this.setState(
                        {
                          sortOption: option.key,
                          isListingFilter: option.isListingFilter,
                        },
                        () => {
                          if (!selected) {
                            this.selectOption(urlParam, option.key);
                          }
                          sortByNearbySpaces(false);
                        }
                      );
                    }}
                  >
                    <span />
                    {labelMaybe}
                  </button>
                </MenuItem>
              );
            })}
          </MenuContent>
        </Menu>
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  sortByNearbySpaces: (bool) => dispatch(sortByNearbySpaces(bool)),
});

const mapStateToProps = (state) => {
  const {
    SearchPageNew: { sortBy },
  } = state;

  return {
    sortBy,
  };
};

SortByPopup.defaultProps = {
  rootClassName: null,
  className: null,
  menuLabelRootClassName: null,
  initialValue: null,
  contentPlacementOffset: 0,
};

SortByPopup.propTypes = {
  rootClassName: string,
  className: string,
  menuLabelRootClassName: string,
  urlParam: string.isRequired,
  onSelect: func.isRequired,
  options: arrayOf(
    shape({
      key: string.isRequired,
      label: string.isRequired,
    })
  ).isRequired,
  initialValue: object,
  contentPlacementOffset: number,
};

export default connect(mapStateToProps, mapDispatchToProps)(SortByPopup);
