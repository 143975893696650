import React from 'react';
import { MediaAndTextSection } from '../../components_new';
import Image from './assets/getStarted.png';
import css from './PartnerSubletting.module.scss';

const SectionGetStarted = ({ intl, isMobile, linkProps, onButtonClick }) => {
  return (
    <section>
      <MediaAndTextSection
        title={{
          content: intl.formatMessage({ id: 'PartnerSubletting.SectionGetStarted.title' }),
          showMobile: true,
        }}
        caption={{
          text: (
            <ul className={css.getStartedList}>
              <li>
                <h3>
                  {intl.formatMessage({
                    id: 'PartnerSubletting.SectionGetStarted.bullet1.caption',
                  })}
                </h3>
                <p>
                  {intl.formatMessage({ id: 'PartnerSubletting.SectionGetStarted.bullet1.text' })}
                </p>
              </li>
              <li>
                <h3>
                  {intl.formatMessage({
                    id: 'PartnerSubletting.SectionGetStarted.bullet2.caption',
                  })}
                </h3>
                <p>
                  {intl.formatMessage({ id: 'PartnerSubletting.SectionGetStarted.bullet2.text' })}
                </p>
              </li>
              <li>
                <h3>
                  {intl.formatMessage({
                    id: 'PartnerSubletting.SectionGetStarted.bullet3.caption',
                  })}
                </h3>
                <p>
                  {intl.formatMessage({ id: 'PartnerSubletting.SectionGetStarted.bullet3.text' })}
                </p>
              </li>
            </ul>
          ),
          showMobile: true,
        }}
        bulletType={'check'}
        checkColor={'green'}
        media={{ content: Image, showMobile: true }}
        wideMedia
        textAlign={'right'}
        textAlignMobile={'top'}
      />
    </section>
  );
};

export default SectionGetStarted;
