import React, { useState } from 'react';
import { OutsideClickHandler } from '../../../../components';
import css from './ImageInput.module.scss';

const LocationImageComponent = (props) => {
  const { intl, imageObject, index, markImageAsPrimary, deleteImage } = props;
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const menuDots = (
    <svg width="16" height="4" viewBox="0 0 16 4" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="6" width="4" height="4" rx="2" fill="white" />
      <rect x="12" width="4" height="4" rx="2" fill="white" />
      <rect width="4" height="4" rx="2" fill="white" />
    </svg>
  );

  const handleImageMenuClick = (e) => {
    e.preventDefault();
    setIsMenuOpen(!isMenuOpen);
  };

  const handleDelete = (e) => {
    e.preventDefault();
    setIsMenuOpen(false);
    deleteImage(index);
  };

  const handleMarkPrimary = (e) => {
    e.preventDefault();
    setIsMenuOpen(false);
    markImageAsPrimary(index);
  };

  return (
    <>
      <img src={imageObject.medium} className={css.image} alt="location" />
      <button className={css.imageOverlayButton} onClick={(e) => handleImageMenuClick(e)}>
        {menuDots}
      </button>
      {isMenuOpen && (
        <OutsideClickHandler onOutsideClick={() => setIsMenuOpen(false)}>
          <div className={css.imageMenu}>
            <button className={css.imageMenuButton} onClick={(e) => handleDelete(e)}>
              {intl.formatMessage({ id: 'CreateLocationForm.imageInput.delete' })}
            </button>
            {index !== 0 && (
              <button className={css.imageMenuButton} onClick={(e) => handleMarkPrimary(e)}>
                {intl.formatMessage({ id: 'CreateLocationForm.imageInput.markPrimary' })}
              </button>
            )}
          </div>
        </OutsideClickHandler>
      )}
    </>
  );
};

export default LocationImageComponent;
