const companyformSubmit = (values) => {
  //Sends an email to the provider with all order details. Uses Mailchimp API
  // and sends all data points as an object. This objects needs to be built and
  // the data needs to be calculated before sending.

  // Mailchimp API for sending transactional emails to provider when paymant is confirmed
  const MAILCHIMP_APP_KEY = process.env.REACT_APP_MAILCHIMP_TRANSACTIONAL_KEY;
  const mailchimp = require('@mailchimp/mailchimp_transactional')(MAILCHIMP_APP_KEY);

  const sendCompanyFormEmail = async () => {
    // These are the variables sent to Mailchimp and accessed there by the name tag.
    let global_merge_vars = [
      {
        name: 'fname',
        content: values.fname || 'Not filled',
      },
      {
        name: 'lname',
        content: values.lname || 'Not filled',
      },
      {
        name: 'additionalInfo',
        content: values.additionalInfo || 'Not filled',
      },
      {
        name: 'company',
        content: values.company || 'Not filled',
      },
      {
        name: 'email',
        content: values.email || 'Not filled',
      },
      {
        name: 'employees',
        content: values.employees || 'Not filled',
      },
      {
        name: 'phonenumber',
        content: values.phonenumber || 'Not filled',
      },
    ];

    const toEmail = [
      {
        email: 'sales@respaces.co',
        type: 'to',
      },
    ];

    const getMessage = () => {
      return {
        from_email: 'admin@respaces.co',
        from_name: 'The Respaces Team',
        subject: 'New Company Form submission',
        text: 'A company submitted the form',
        to: toEmail,
        global_merge_vars: global_merge_vars,
      };
    };

    const message = getMessage();

    // eslint-disable-next-line
    const response = await mailchimp.messages.sendTemplate({
      template_name: 'CompanyFormEmail',
      message: message,
      template_content: global_merge_vars,
    });
    return true;
  };
  const result = sendCompanyFormEmail();
  return result;
};

export default companyformSubmit;
