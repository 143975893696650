import React, { memo } from 'react';
import { injectIntl } from 'react-intl';
import { func, oneOf } from 'prop-types';
import { LANGUAGE_CODES } from '../../../../util/modernmt';

import css from './LanguageToggle.module.scss';

const LanguageToggle = memo(({ currentLang, onChangeLang, intl }) => (
  <div className={css.wrapper}>
    {LANGUAGE_CODES.LIST.map((l) => (
      <label key={`language_toggle_lang_${l}`} htmlFor={l}>
        <input
          id={l}
          checked={l === currentLang}
          onChange={() => onChangeLang(l)}
          type="radio"
          name="language"
          className={css.input}
        />
        <span className={css.languageButton}>
          {intl.formatMessage({ id: `languageName.${l}` })}
        </span>
      </label>
    ))}
  </div>
));

LanguageToggle.propTypes = {
  currentLang: oneOf(LANGUAGE_CODES.LIST),
  onChangeLang: func,
};

export default injectIntl(LanguageToggle);
