import React from 'react';
import { FormattedMessage } from 'react-intl';

import ListingPageSection from '../ListingPageSection/ListingPageSection';
import accessyImage from './accessy.png';
import parakeyImage from './parakey.png';

import css from './SectionDigitalKeyMaybe.module.scss';

const accessProviderLogo = { accessy: accessyImage, parakey: parakeyImage };

const SectionDigitalKeyMaybe = ({ accessProvider }) => {
  return (
    <ListingPageSection title={'ListingPage.digitalKeyMaybe.title'}>
      <div className={css.digitalKeySection}>
        <img
          className={`${css.logo} ${css[accessProvider]}`}
          src={accessProviderLogo[accessProvider]}
          alt="Digital key provider logo"
        ></img>
        <FormattedMessage id={`ListingPage.digitalKeyMaybe.${accessProvider}Text`}>
          {(id) => <p className={css.text}>{id}</p>}
        </FormattedMessage>
      </div>
    </ListingPageSection>
  );
};

export default SectionDigitalKeyMaybe;
