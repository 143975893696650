import React, { useRef, useState, useEffect } from 'react';
import classNames from 'classnames';
import { Field, FormSpy } from 'react-final-form';
import { string, bool } from 'prop-types';

import { OutsideClickHandler } from '..';
import { injectIntl, intlShape } from '../../util/reactIntl';
import { propTypes } from '../../util/types';
import { touchDeviceMatch, narrowViewportMatch } from '../../util/device';

import ClockIcon from './ClockIcon';
import css from './TimesFilterHero.module.scss';

const List = ({ options, onSelect }) => {
  return (
    <div className={css.list}>
      <div className={css.listOptions}>
        {options.map((op) => (
          <div id={`List_${op.key}`} className={css.listItem} onClick={() => onSelect(op)}>
            {op.label}
          </div>
        ))}
      </div>
    </div>
  );
};

const EightAMKey = 'List_480';

const TimesFilterHeroComponent = ({
  id,
  placeholder,
  options,
  stateTimes,
  disabled = false,
  initialIndex = 0,
  onClick,
  onChange,
}) => {
  const isTouchDevice = touchDeviceMatch().matches || narrowViewportMatch().matches;
  const [open, setOpen] = useState(false);
  const componentRef = useRef(null);
  const scrollRef = useRef(null);

  const currentValue = (!!stateTimes && stateTimes[id]) || null;

  const wrapperClassList = classNames(css.contentWrapper, disabled && css.disabled);

  useEffect(() => {
    if (initialIndex !== 0 && open) {
      const el = document.getElementById(EightAMKey);
      if (el) {
        scrollRef.current.scroll(0, el.offsetTop);
      }
    }
  }, [open, initialIndex]);

  return isTouchDevice ? (
    <label className={wrapperClassList} htmlFor={id}>
      <select
        className={css.nativeSelect}
        name={id}
        id={id}
        onChange={(e) => {
          onChange(e.currentTarget.value);
        }}
      >
        <option value="" selected={!currentValue} disabled>
          {placeholder}
        </option>
        {options.map((opt) => (
          <option value={opt.key} selected={currentValue === opt.key}>
            {opt.label}
          </option>
        ))}
      </select>
    </label>
  ) : (
    <div
      className={wrapperClassList}
      onClick={() => {
        if (typeof onClick === 'function') {
          onClick();
        }
        if (open || disabled) return;
        setOpen(true);
      }}
      ref={componentRef}
    >
      <FormSpy
        render={() => {
          const value = (!!stateTimes && stateTimes[id]) || null;
          const selectedOption = options.find((op) => op.key === value);

          return (
            <section
              className={classNames(css.placeholder, {
                [css.placeholderFilled]: !!value,
              })}
            >
              <ClockIcon /> {selectedOption ? selectedOption.label : placeholder}
            </section>
          );
        }}
      />
      <Field
        name={id}
        render={({ input }) => {
          const handleSelectItem = (item) => {
            const { key } = item;
            setOpen(() => false);
            input.onFocus();
            input.onChange(key);
            onChange(key);
            input.onBlur();
          };

          return (
            <>
              {open ? (
                <section className={css.alignmentSection}>
                  <OutsideClickHandler
                    onOutsideClick={() => {
                      setOpen(false);
                    }}
                  >
                    <div
                      ref={scrollRef}
                      className={css.timeslots}
                      style={{ width: componentRef.current.clientWidth }}
                    >
                      <List
                        options={options}
                        input={input}
                        onSelect={handleSelectItem}
                        initialIndex={initialIndex}
                      />
                    </div>
                  </OutsideClickHandler>
                </section>
              ) : null}
            </>
          );
        }}
      />
    </div>
  );
};

TimesFilterHeroComponent.defaultProps = {
  rootClassName: null,
  className: null,
  dateRangeLengthFitler: null,
  liveEdit: false,
  contentPlacementOffset: 0,
  placeholder: 'Dates',
  PlaceholderChanged: false,
};

TimesFilterHeroComponent.propTypes = {
  rootClassName: string,
  className: string,
  // id: string.isRequired,
  dateRangeLengthFitler: propTypes.filterConfig,
  intl: intlShape.isRequired,
  placeholder: string,
  datesPlaceholderChanged: bool,
};

const TimesFilterHero = injectIntl(TimesFilterHeroComponent);

export default TimesFilterHero;
