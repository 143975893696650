import React, { useState, useRef, useEffect } from 'react';
import classNames from 'classnames';
import { injectIntl } from 'react-intl';
import { string } from 'prop-types';

import css from './ExpandableText.module.scss';

const LINE_HEIGHT = 24;

const ExpandableText = ({ text, intl }) => {
  const [isExpanded, toggleExpanded] = useState(false);
  const [shouldExpand, setShouldExpand] = useState(false);

  const textContainer = useRef(null);

  useEffect(() => {
    const height = textContainer?.current?.getBoundingClientRect().height;
    setShouldExpand(height > LINE_HEIGHT * 3);
  }, [textContainer]);

  return (
    <>
      <p
        ref={textContainer}
        className={classNames(css.text, isExpanded && css.expanded, shouldExpand && css.expandable)}
      >
        {text}
      </p>
      {shouldExpand && (
        <button className={css.toggleBtn} onClick={() => toggleExpanded(!isExpanded)}>
          {intl.formatMessage({ id: isExpanded ? 'ListingPage.readLess' : 'ListingPage.readMore' })}
        </button>
      )}
    </>
  );
};

ExpandableText.propTypes = {
  text: string.isRequired,
};

export default injectIntl(ExpandableText);
