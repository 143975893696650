import config from '../../../config';
import { routeConfiguration } from '../../../index';
import { createResourceLocatorString } from '../../../util/routes';
import { prepareDateString } from '../../../util/dates';

/**
 * @param currentCategory {String}
 * @param currentLocation {Object}
 * @param currentDates {Object}
 * @param currentTimes {Object}
 * @param currentSeats {Object}
 * @param currentBudget {String}
 * @param history {Object} */

const searchListings = ({
  history,
  currentCategory,
  currentLocation,
  currentDates,
  currentTimes,
  maxSeats,
  minSeats,
  currentHost,
  ameneties,
  contractType,
  offer,
  price,
  contractLengthPerUnit,
  allowVatExemptCompanies,
}) => {
  /* Preparing location */
  const { selectedPlace: { address, bounds, origin } = {} } = currentLocation || {};

  /* Preparing dates */
  const { startTime, endTime } = currentTimes || {};

  const datesString = prepareDateString({ dates: currentDates, times: currentTimes });

  /* Preparing search params object */
  const searchParams = {
    ...(address && { address, origin }),
    ...(!!startTime && !!endTime && { time: `${startTime},${endTime}` }),
    ...(!!datesString && { dates: datesString }),
    ...(!!currentCategory && { category: currentCategory }),
    ...(!!minSeats && { minSeats: minSeats }),
    ...(!!maxSeats && { maxSeats: maxSeats }),
    ...(!!currentHost && { host: currentHost }),
    ...(!!ameneties && { ameneties }),
    ...(!!contractType && { contractType }),
    ...(!!offer && { offer }),
    ...(!!price && { price }),
    ...(!!contractLengthPerUnit && { contractLengthPerUnit }),
    ...(!!allowVatExemptCompanies && { allowVatExemptCompanies }),
    bounds: !!bounds?._sdkType ? bounds : config.maps.search.defaultLocationBounds,
  };

  const searchString = createResourceLocatorString(
    'SearchPage',
    routeConfiguration(),
    {},
    searchParams
  );

  history.push(searchString);
};

export default searchListings;
