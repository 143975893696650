import React, { useState, useEffect } from 'react';
import { arrayOf, bool, number, oneOf, shape, string } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import classNames from 'classnames';
import {
  txIsAccepted,
  txIsCanceled,
  txIsEnquired,
  txHasBeenDelivered,
  txB2BHasBeenDelivered,
  txIsPaymentExpired,
  txIsPaymentPending,
  txIsRequested,
  txIsDeclined,
} from '../../util/transaction';
import { propTypes, DATE_TYPE_DATETIME } from '../../util/types';
// import { createSlug, stringify } from '../../util/urlHelpers';
import { ensureCurrentUser, ensureListing } from '../../util/data';
import { getMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { isScrollingDisabled } from '../../ducks/UI.duck';
import {
  Avatar,
  BookingTimeInfo,
  NamedLink,
  NotificationBadge,
  Page,
  PaginationLinks,
  TabNav,
  LayoutSideNavigation,
  LayoutWrapperMain,
  LayoutWrapperSideNav,
  LayoutWrapperTopbar,
  LayoutWrapperFooter,
  Footer,
  IconSpinner,
  UserDisplayName,
  Modal,
  OutsideClickHandler,
} from '../../components';
import { InviteModal } from '../../components_new';
import { TopbarContainer, NotFoundPage } from '../../containers';
import config from '../../config';
import { DownloadReceiptForm } from '../../forms';
import { isEmployeeUser, isCompanyUser } from '../../util/b2bHelper';
import { parse } from '../../util/urlHelpers';
import { isMobile } from '../../util/device';

import ContentLoader from './ContentLoader';
import ContentLoaderMobile from './ContentLoaderMobile';
import { loadData, INBOX_PAGE_SIZE } from './InboxPage.duck';
import css from './InboxPage.module.scss';
import chunk from 'lodash/chunk';

import { getRequests, getConnections, updateConnection } from '../../ducks/TeamSchedule.duck';
import ConnectionsTable from './ConnectionsTable';
import AddNewUserIcon from '../../components_new/SectionTeamScheduling/ui/icons/AddNewUserIcon';
import UserIcon from './ui/icons/UserIcon';

const formatDate = (intl, date) => {
  return {
    short: intl.formatDate(date, {
      month: 'short',
      day: 'numeric',
    }),
    long: `${intl.formatDate(date)} ${intl.formatTime(date)}`,
  };
};

// Translated name of the state of the given transaction
export const txState = (intl, tx, type) => {
  const isOrder = type === 'order';
  if (txIsAccepted(tx)) {
    return {
      nameClassName: css.nameNotEmphasized,
      bookingClassName: css.bookingNoActionNeeded,
      lastTransitionedAtClassName: css.lastTransitionedAtNotEmphasized,
      stateClassName: css.stateSucces,
      state: intl.formatMessage({
        id: 'InboxPage.stateAccepted',
      }),
    };
  }
  if (txHasBeenDelivered(tx) || txB2BHasBeenDelivered(tx)) {
    return {
      nameClassName: css.nameNotEmphasized,
      bookingClassName: css.bookingNoActionNeeded,
      lastTransitionedAtClassName: css.lastTransitionedAtNotEmphasized,
      stateClassName: css.stateNoActionNeeded,
      state: intl.formatMessage({
        id: 'InboxPage.stateDelivered',
      }),
    };
  }
  if (txIsPaymentPending(tx)) {
    return {
      nameClassName: isOrder ? css.nameNotEmphasized : css.nameEmphasized,
      bookingClassName: css.bookingNoActionNeeded,
      lastTransitionedAtClassName: css.lastTransitionedAtNotEmphasized,
      stateClassName: isOrder ? css.stateActionNeeded : css.stateNoActionNeeded,
      state: intl.formatMessage({
        id: 'InboxPage.statePendingPayment',
      }),
    };
  }
  if (txIsPaymentExpired(tx)) {
    return {
      nameClassName: css.nameNotEmphasized,
      bookingClassName: css.bookingNoActionNeeded,
      lastTransitionedAtClassName: css.lastTransitionedAtNotEmphasized,
      stateClassName: css.stateNoActionNeeded,
      state: intl.formatMessage({
        id: 'InboxPage.stateExpired',
      }),
    };
  }
  if (txIsRequested(tx)) {
    return {
      nameClassName: css.nameNotEmphasized,
      bookingClassName: css.bookingNoActionNeeded,
      lastTransitionedAtClassName: css.lastTransitionedAtEmphasized,
      stateClassName: css.stateActionNeeded,
      state: intl.formatMessage({
        id: isOrder ? 'InboxPage.stateRequested' : 'InboxPage.statePending',
      }),
    };
  }
  if (txIsDeclined(tx)) {
    return {
      nameClassName: css.nameNotEmphasized,
      bookingClassName: css.bookingNoActionNeeded,
      lastTransitionedAtClassName: css.lastTransitionedAtNotEmphasized,
      stateClassName: css.stateNoActionNeeded,
      state: intl.formatMessage({
        id: 'InboxPage.stateDeclined',
      }),
    };
  }
  if (txIsCanceled(tx)) {
    return {
      nameClassName: css.nameNotEmphasized,
      bookingClassName: css.bookingNoActionNeeded,
      lastTransitionedAtClassName: css.lastTransitionedAtNotEmphasized,
      stateClassName: css.stateNoActionNeeded,
      state: intl.formatMessage({
        id: 'InboxPage.stateCanceled',
      }),
    };
  }
  return null;
};

// Functional component as internal helper to print BookingTimeInfo if that is needed
const BookingInfoMaybe = (props) => {
  const { bookingClassName, isOrder, intl, tx, unitType } = props;
  const isEnquiry = txIsEnquired(tx);

  if (isEnquiry) {
    return null;
  }
  const listingAttributes = ensureListing(tx.listing).attributes;
  const timeZone = listingAttributes.availabilityPlan
    ? listingAttributes.availabilityPlan.timezone
    : 'Etc/UTC';

  // If you want to show the booking price after the booking time on InboxPage you can
  // add the price after the BookingTimeInfo component. You can get the price by uncommenting
  // sthe following lines:

  // const bookingPrice = isOrder ? tx.attributes.payinTotal : tx.attributes.payoutTotal;
  // const price = bookingPrice ? formatMoney(intl, bookingPrice) : null;

  // Remember to also add formatMoney function from 'util/currency.js' and add this after BookingTimeInfo:
  // <div className={css.itemPrice}>{price}</div>

  return (
    <div className={classNames(css.bookingInfoWrapper, bookingClassName)}>
      <BookingTimeInfo
        bookingClassName={bookingClassName}
        isOrder={isOrder}
        intl={intl}
        tx={tx}
        unitType={unitType}
        dateType={DATE_TYPE_DATETIME}
        timeZone={timeZone}
      />
    </div>
  );
};

BookingInfoMaybe.propTypes = {
  intl: intlShape.isRequired,
  isOrder: bool.isRequired,
  tx: propTypes.transaction.isRequired,
  unitType: propTypes.bookingUnitType.isRequired,
};

// const createListingLink = (listing, otherUser, searchParams = {}, className = '') => {
//   const listingId = listing.id && listing.id.uuid;
//   const label = listing.attributes.title;
//   const listingDeleted = listing.attributes.deleted;

//   if (!listingDeleted) {
//     const params = { id: listingId, slug: createSlug(label) };
//     const to = { search: stringify(searchParams) };
//     return (
//       <NamedLink className={className} name="ListingPage" params={params} to={to}>
//         {/* <img src={listing.attributes.} alt="Listing"></img> */}
//         {/* <Avatar user={otherUser} disableProfileLink /> */}
//       </NamedLink>
//     );
//   } else {
//     return <FormattedMessage id="TransactionPanel.deletedListingOrderTitle" />;
//   }
// };

export const InboxItem = (props) => {
  const { unitType, type, tx, intl, stateData, currentUser, showDownloadReceiptBtn } = props;
  const [isReceiptModalOpen, setIsReceiptModalOpen] = useState(false);
  const { customer, provider, listing } = tx;
  const isOrder = type === 'order';

  const otherUser = isOrder ? provider : customer;
  const otherUserDisplayName = !isOrder && <UserDisplayName user={otherUser} intl={intl} />;
  const listingName =
    listing && listing.attributes.title + ' - ' + listing.attributes.publicData.category;
  const isOtherUserBanned = otherUser.attributes.banned;

  // const isSaleNotification = !isOrder && txIsRequested(tx);
  const isSaleNotification = !isOrder;
  const rowNotificationDot = isSaleNotification ? <div className={css.notificationDot} /> : null;
  const lastTransitionedAt = formatDate(intl, tx.attributes.lastTransitionedAt);

  const linkClasses = classNames(css.itemLink, {
    [css.bannedUserLink]: isOtherUserBanned,
  });

  // const listingLink = listing ? createListingLink(listing, otherUser) : null;

  return (
    <div className={css.item}>
      {!isOrder && <Avatar className={css.itemAvatar} user={otherUser} />}
      <NamedLink
        className={linkClasses}
        name={isOrder ? 'OrderDetailsPage' : 'SaleDetailsPage'}
        params={{ id: tx.id.uuid }}
      >
        {isSaleNotification && <div className={css.rowNotificationDot}>{rowNotificationDot}</div>}
        <div className={css.itemInfo}>
          <div className={classNames(css.itemUsername, stateData.nameClassName)}>
            <span className={css.userDisplayName}>{otherUserDisplayName}</span> {listingName}
          </div>
          <BookingInfoMaybe
            bookingClassName={stateData.bookingClassName}
            intl={intl}
            isOrder={isOrder}
            tx={tx}
            unitType={unitType}
          />
        </div>
        {showDownloadReceiptBtn && (
          <div
            className={css.receiptButton}
            onClick={(e) => {
              e.preventDefault();
              setIsReceiptModalOpen(true);
            }}
          >
            <img
              className={css.icon}
              src={'/static/icons/receipt.svg'}
              alt={`Download your receipt`}
            />
            <span>{intl.formatMessage({ id: `InboxPage.receipt` })}</span>
          </div>
        )}
        <div className={css.itemState}>
          <div className={classNames(css.stateName, stateData.stateClassName)}>
            {stateData.state}
          </div>
          <div
            className={classNames(css.lastTransitionedAt, stateData.lastTransitionedAtClassName)}
            title={lastTransitionedAt.long}
          >
            {lastTransitionedAt.short}
          </div>
        </div>
      </NamedLink>

      <Modal
        id="DownloadReceipt"
        isOpen={isReceiptModalOpen}
        onClose={(e) => {
          e.preventDefault();
          setIsReceiptModalOpen(false);
        }}
        containerClassName={css.modalContainer}
        usePortal
      >
        <DownloadReceiptForm
          setIsReceiptModalOpen={setIsReceiptModalOpen}
          tx={tx}
          currentUser={currentUser}
          intl={intl}
        />
      </Modal>
    </div>
  );
};

InboxItem.defaultProps = {
  showDownloadReceiptBtn: true,
};

InboxItem.propTypes = {
  unitType: propTypes.bookingUnitType.isRequired,
  type: oneOf(['order', 'sale']).isRequired,
  tx: propTypes.transaction.isRequired,
  intl: intlShape.isRequired,
  showDownloadReceiptBtn: bool,
};

export const InboxPageComponent = (props) => {
  const {
    unitType,
    currentUser,
    fetchInProgress,
    currentUserHasListings,
    fetchOrdersOrSalesError,
    intl,
    pagination,
    params,
    providerNotificationCount,
    scrollingDisabled,
    transactions,
    getRequests,
    getConnections,
    pendingRequests,
    connections,
    requestsLoading,
    connectionsLoading,
    updateConnection,
  } = props;
  const [isInviteModalOpen, setInviteModalOpen] = useState(false);
  const [acceptedRequests, setAcceptedRequests] = useState([]);

  const { tab } = params;
  const ensuredCurrentUser = ensureCurrentUser(currentUser);

  useEffect(() => {
    if (!!currentUser?.id?.uuid) {
      getRequests();
      getConnections();
    }
  }, [currentUser?.id?.uuid]);

  const validTab = tab === 'orders' || tab === 'sales' || tab === 'schedule';
  if (!validTab) {
    return <NotFoundPage />;
  }

  const isOrders = tab === 'orders';
  const isSales = tab === 'sales';
  const isSchedule = tab === 'schedule';

  const currentPageTitle = isOrders ? 'InboxPage.ordersTitle' : 'InboxPage.connectionsTitle';
  const title = intl.formatMessage({ id: currentPageTitle });

  const toTxItem = (tx) => {
    const type = isOrders ? 'order' : 'sale';
    const stateData = txState(intl, tx, type);

    // Render InboxItem only if the latest transition of the transaction is handled in the `txState` function.
    return stateData ? (
      <li key={tx.id.uuid} className={css.listItem}>
        <InboxItem
          unitType={unitType}
          type={type}
          tx={tx}
          intl={intl}
          stateData={stateData}
          currentUser={currentUser}
        />
      </li>
    ) : null;
  };

  const error = fetchOrdersOrSalesError ? (
    <p className={css.error}>
      <FormattedMessage id="InboxPage.fetchFailed" />
    </p>
  ) : null;

  const noResults =
    !fetchInProgress && transactions.length === 0 && !fetchOrdersOrSalesError ? (
      <li key="noResults" className={css.noResults}>
        <FormattedMessage id={isOrders ? 'InboxPage.noOrdersFound' : 'InboxPage.noSalesFound'} />
      </li>
    ) : null;

  const hasOrderOrSaleTransactions = (tx, isOrdersTab, user) => {
    return isOrdersTab
      ? user.id && tx && tx.length > 0 && tx[0].customer.id.uuid === user.id.uuid
      : user.id && tx && tx.length > 0 && tx[0].provider.id.uuid === user.id.uuid;
  };
  const hasTransactions =
    !fetchInProgress && hasOrderOrSaleTransactions(transactions, isOrders, ensuredCurrentUser);
  const pagingLinks =
    hasTransactions && pagination && pagination.totalPages > 1 ? (
      <PaginationLinks
        className={css.pagination}
        pageName="InboxPage"
        pagePathParams={params}
        pagination={pagination}
      />
    ) : null;

  const providerNotificationBadge =
    providerNotificationCount > 0 ? <NotificationBadge count={providerNotificationCount} /> : null;

  const isB2B = isEmployeeUser(currentUser) || isCompanyUser(currentUser);
  let tabs = isB2B
    ? currentUserHasListings
      ? [
          {
            text: <FormattedMessage id="CompanyBookingsPageComponent.companyTabTitle" />,
            selected: false,
            linkProps: {
              name: 'CompanyBookingsPage',
            },
          },
          {
            text: <FormattedMessage id="InboxPage.ordersTabTitle" />,
            selected: isOrders,
            linkProps: {
              name: 'InboxPage',
              params: { tab: 'orders' },
            },
          },
        ]
      : [
          {
            text: <FormattedMessage id="CompanyBookingsPageComponent.companyTabTitle" />,
            selected: false,
            linkProps: {
              name: 'CompanyBookingsPage',
            },
          },
          {
            text: <FormattedMessage id="InboxPage.ordersTabTitle" />,
            selected: isOrders,
            linkProps: {
              name: 'InboxPage',
              params: { tab: 'orders' },
            },
          },
        ]
    : currentUserHasListings
    ? [
        {
          text: <FormattedMessage id="InboxPage.ordersTabTitle" />,
          selected: isOrders,
          linkProps: {
            name: 'InboxPage',
            params: { tab: 'orders' },
          },
        },
      ]
    : [
        {
          text: <FormattedMessage id="InboxPage.ordersTabTitle" />,
          selected: isOrders,
          linkProps: {
            name: 'InboxPage',
            params: { tab: 'orders' },
          },
        },
      ];

  tabs = tabs.map((tab) => ({
    ...tab,
    linkClassName: css.tabLabel,
    linkSelectedClassName: css.tabLabelSelected,
  }));

  tabs.push({
    text: intl.formatMessage({ id: 'InboxPage.scheduleTabTitle' }),
    selected: isSchedule,
    linkProps: {
      name: 'InboxPage',
      params: { tab: 'schedule' },
    },
    linkClassName: css.tabLabel,
    linkSelectedClassName: css.tabLabelSelected,
  });
  const nav = <TabNav rootClassName={css.tabs} tabRootClassName={css.tab} tabs={tabs} />;

  const acceptRequest = (connectionId) => {
    updateConnection({ newStatus: 'request_accepted', connectionId });
    setAcceptedRequests([...acceptedRequests, connectionId]);
  };

  const cancelRequest = (connectionId) => {
    updateConnection({ newStatus: 'request_cancelled', connectionId });
  };

  const showPendingRequests = pendingRequests && !!pendingRequests.length && !requestsLoading;

  return (
    <Page title={title} scrollingDisabled={scrollingDisabled}>
      <LayoutSideNavigation>
        <LayoutWrapperTopbar>
          <TopbarContainer
            className={css.topbar}
            mobileRootClassName={css.mobileTopbar}
            desktopClassName={css.desktopTopbar}
            currentPage="InboxPage"
          />
        </LayoutWrapperTopbar>
        <LayoutWrapperSideNav className={css.navigation}>{nav}</LayoutWrapperSideNav>
        <LayoutWrapperMain hideTopbarSpacer={true}>
          {isSchedule ? (
            <section className={css.connectionsSection}>
              <div className={css.connectionsHeader}>
                <h4 className={css.connectionsTitle}>
                  {intl.formatMessage({ id: 'InboxPage.connections.title' })}
                </h4>
                <div className={css.inviteModalWrapper}>
                  <button
                    onClick={() => setInviteModalOpen(true)}
                    className={css.connectionsInviteButton}
                  >
                    <UserIcon />
                    {intl.formatMessage({ id: 'InboxPage.addNewUserBtn' })}
                  </button>
                  <InviteModal
                    className={css.inviteModal}
                    isOpen={isInviteModalOpen}
                    onClose={() => setInviteModalOpen(false)}
                  />
                </div>
                <p className={css.connectionsCaption}>
                  {intl.formatMessage({ id: 'InboxPage.connections.caption' })}
                </p>
              </div>
              <div className={css.pendingConnectionsTableWrapper}>
                {showPendingRequests && (
                  <ConnectionsTable
                    items={pendingRequests}
                    type="pending"
                    isLoading={requestsLoading}
                    onAccept={acceptRequest}
                    onDecline={cancelRequest}
                    acceptedRequests={acceptedRequests}
                    intl={intl}
                  />
                )}
              </div>
              {Array.isArray(connections) && connections.length > 0 && (
                <p className={css.usersCount}>
                  <span>
                    {intl.formatMessage(
                      { id: 'InboxPage.usersCount' },
                      { num: connections.length }
                    )}
                  </span>
                </p>
              )}
              <ConnectionsTable
                items={connections}
                type="connections"
                isLoading={connectionsLoading}
                currentUserId={currentUser?.id?.uuid}
                onDecline={cancelRequest}
                intl={intl}
              />
              <button
                onClick={() => setInviteModalOpen(true)}
                className={css.connectionsInviteMobileButton}
              >
                <svg
                  width="29"
                  height="28"
                  viewBox="0 0 29 28"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M16.6885 27.928H12.2405V16.248H0.5925V11.896H12.2405V0.119999H16.6885V11.896H28.4325V16.248H16.6885V27.928Z"
                    fill="#9AB7DD"
                  />
                </svg>
              </button>
            </section>
          ) : (
            <>
              {error}
              <ul className={css.itemList}>
                {fetchInProgress ? (
                  <div className={css.loaderContainer}>
                    <div className={css.loaderWrapper}>
                      {isMobile ? <ContentLoaderMobile /> : <ContentLoader />}
                    </div>
                  </div>
                ) : (
                  transactions.map(toTxItem)
                )}
                {noResults}
              </ul>
              {pagingLinks}
            </>
          )}
        </LayoutWrapperMain>
        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </LayoutSideNavigation>
    </Page>
  );
};

InboxPageComponent.defaultProps = {
  unitType: config.bookingUnitType,
  currentUser: null,
  currentUserListing: null,
  currentUserHasOrders: null,
  fetchOrdersOrSalesError: null,
  pagination: null,
  providerNotificationCount: 0,
  sendVerificationEmailError: null,
};

InboxPageComponent.propTypes = {
  params: shape({
    tab: string.isRequired,
  }).isRequired,

  unitType: propTypes.bookingUnitType,
  currentUser: propTypes.currentUser,
  currentUserListing: propTypes.ownListing,
  fetchInProgress: bool.isRequired,
  fetchOrdersOrSalesError: propTypes.error,
  pagination: propTypes.pagination,
  providerNotificationCount: number,
  scrollingDisabled: bool.isRequired,
  transactions: arrayOf(propTypes.transaction).isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = (state, props) => {
  const { params, location = {} } = props;
  const { search } = location;
  const { page = 1 } = parse(search);
  const { fetchInProgress, fetchOrdersOrSalesError, pagination, transactionRefs } = state.InboxPage;
  const {
    currentUser,
    currentUserListing,
    currentUserHasListings,
    currentUserNotificationCount: providerNotificationCount,
  } = state.user;
  const {
    TeamSchedule: { requests: pendingRequests, requestsLoading, connectionsLoading, connections },
  } = state;

  const txToDisplay = chunk(transactionRefs, INBOX_PAGE_SIZE)[page - 1] || [];

  return {
    currentUser,
    currentUserListing,
    currentUserHasListings,
    fetchInProgress,
    fetchOrdersOrSalesError,
    pagination,
    providerNotificationCount,
    scrollingDisabled: isScrollingDisabled(state),
    transactions: getMarketplaceEntities(state, txToDisplay),
    pendingRequests,
    connections,
    requestsLoading,
    connectionsLoading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getRequests: () => dispatch(getRequests()),
    getConnections: () => dispatch(getConnections()),
    updateConnection: (payload) => dispatch(updateConnection(payload)),
  };
};

const InboxPage = compose(
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl
)(InboxPageComponent);

InboxPage.loadData = loadData;

export default InboxPage;
