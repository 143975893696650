import React from 'react';
import { useIntl } from 'react-intl';
import { StaticPage, TopbarContainer } from '..';
import {
  LayoutSingleColumn,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  Footer,
} from '../../components';
import { ButtonNew } from '../../components_new';
import { BUTTON_TYPES, BUTTON_SIZES } from '../../components_new/ButtonNew/ButtonNew';
import celebrationImage from './celebration.svg';

import css from './FormSubmissionConfirmation.module.scss';

const FormSubmissionConfirmation = () => {
  const intl = useIntl();

  return (
    <StaticPage
      title="Thank you"
      schema={{
        '@context': 'http://schema.org',
        '@type': 'ThankYouPage',
        description: 'Thank you page when submitting a form',
        name: 'Submission complete',
      }}
    >
      <LayoutSingleColumn>
        <LayoutWrapperTopbar>
          <TopbarContainer />
        </LayoutWrapperTopbar>
        <LayoutWrapperMain className={css.wrapperMain}>
          <img src={celebrationImage} className={css.image} />
          <h1 className={css.title}>
            {intl.formatMessage({ id: 'FormSubmissionConfirmation.title' })}
          </h1>
          <p className={css.text}>
            {intl.formatMessage({ id: 'FormSubmissionConfirmation.text' })}
          </p>
          <ButtonNew
            name="SearchPage"
            type={BUTTON_TYPES.GREEN}
            size={BUTTON_SIZES.MEDIUM}
            block={false}
            className={css.button}
          >
            {intl.formatMessage({ id: 'FormSubmissionConfirmation.button' })}
          </ButtonNew>
        </LayoutWrapperMain>
        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </LayoutSingleColumn>
    </StaticPage>
  );
};

export default FormSubmissionConfirmation;
