import React from 'react';
import { Button, Modal } from '..';
import css from './DeleteTeamMemberModal.module.scss';

const DeleteTeamMemberModal = (props) => {
  const { intl, memberId, memberEmail, isOpen, onClose, onDeleteMember, inProgress, error } = props;

  const handleDeleteButtonClick = async () => {
    await onDeleteMember({ memberId, memberEmail });
    await onClose();
  };

  return (
    <Modal
      id={`DeleteTeamMemberModal-${memberId}`}
      isOpen={isOpen}
      onClose={onClose}
      className={css.modal}
      containerClassName={css.modalContainer}
      scrollLayerClassName={css.scrollLayer}
      hideCloseButton
    >
      <div className={css.container}>
        <div className={css.title}>{intl.formatMessage({ id: 'DeleteTeamMemberModal.title' })}</div>
        {error && (
          <div className={css.error}>
            {intl.formatMessage({ id: 'DeleteTeamMemberModal.error' })}
          </div>
        )}
        <div className={css.buttonsWrapper}>
          <Button type="button" onClick={onClose} className={css.cancelButton}>
            {intl.formatMessage({ id: 'DeleteTeamMemberModal.cancelButton' })}
          </Button>
          <Button
            type="button"
            onClick={handleDeleteButtonClick}
            className={css.deleteButton}
            disabled={inProgress}
            inProgress={inProgress}
          >
            {intl.formatMessage({ id: 'DeleteTeamMemberModal.deleteButton' })}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

DeleteTeamMemberModal.defaultProps = {
  error: null,
  inProgress: false,
  isOpen: false,
  memberId: null,
};

export default DeleteTeamMemberModal;
