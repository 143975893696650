import React from 'react';
import ContentLoader from 'react-content-loader';

import css from './ContentLoader.module.scss';

const ContentLoaderComponent = () => {
  const heading = { width: 250, height: 38 },
    width = 1366,
    column = 5,
    padding = 12,
    borderRadius = 4,
    list = [];

  let height;

  const header = (
    <rect
      x={0}
      y={0}
      rx={borderRadius}
      ry={borderRadius}
      width={heading.width}
      height={heading.height}
      key="heading"
    />
  );

  list.push(header);

  const cardWidth = (width - padding * (column + 1)) / column;

  for (let i = 0; i < column; i++) {
    const x = i * (cardWidth + padding);
    const y = heading.height + padding + 15;

    list.push(
      <rect
        x={x}
        y={y}
        rx={borderRadius}
        ry={borderRadius}
        width={cardWidth}
        height={(cardWidth * 4) / 5}
        key={`card_${i}`}
      />
    );
  }

  height = heading.height + padding + 15 + (cardWidth * 4) / 5;

  return (
    <section className={css.container}>
      <div className={css.wrapper}>
        <ContentLoader viewBox={`0 0 ${width} ${height}`} width={width} height={height}>
          {list}
        </ContentLoader>
      </div>
    </section>
  );
};

export default ContentLoaderComponent;
