import React, { useState } from 'react';
import moment from 'moment';
import classNames from 'classnames';
import { injectIntl } from 'react-intl';

import { ButtonNew, DatePickerNew } from '../../../index';
import { OutsideClickHandler } from '../../../../components';
import { BUTTON_SIZES, BUTTON_TYPES } from '../../../ButtonNew/ButtonNew';
import BookingSummaryCard from '../BookingCard/BookingSummaryCard';
import BookingInfoBottomSheet from '../BookingInfoBottomSheet/BookingInfoBottomSheet';

import { DAY_NAME, MONTH, DAY } from '../../config/constants';

import css from './Sidebar.module.scss';

const Sidebar = ({
  currentUserId,
  isSidebarOpen,
  isBottomSheetOpen,
  bottomSheetData,
  onCloseSidebar,
  onOpenBottomSheet,
  onCloseBottomSheet,
  onGoToSearchPage,
  currentDate,
  onDateChange,
  bookings,
  updateCurrentBookingId,
  intl,
  showJoinSpaceButton,
  setShowJoinSpaceButton,
}) => {
  const [isDatepickerOpen, setDatepickerOpen] = useState(false);

  //Take the date and make a string that can be used to create a slug for the link to the listing page
  //Format for date search: 2022-06-22 00:00,2022-06-22 00:00
  const dateSearch =
    moment(currentDate).format('YYYY-MM-DD') + ' ' + moment(currentDate).format('HH:mm');
  const searchParams = new URLSearchParams({
    dates: `${dateSearch},${dateSearch}`,
  });

  const [currentDay, currentDateMonth, currentDateDay] = moment(currentDate)
    .format(`${DAY_NAME}, ${MONTH}, ${DAY}`)
    .split(', ');

  const handleDateChange = (val) => {
    onDateChange(val);
    setDatepickerOpen(false);
  };

  const joinedBookings = [];
  const notJoinedBookings = [];

  Array.isArray(bookings) &&
    bookings.length &&
    bookings.forEach((b) => {
      if (b.users.some((u) => u.authorId === currentUserId || u.userId === currentUserId)) {
        joinedBookings.push(b);
      } else {
        notJoinedBookings.push(b);
      }
    });

  const findTransactionId = (users) => {
    const user = users.find((u) => u.authorId === currentUserId);
    return user ? user.transactionId : null;
  };

  return (
    <>
      <div
        onClick={() => {
          onCloseSidebar();
          onCloseBottomSheet();
        }}
        className={classNames(css.overlay, isSidebarOpen && css.visible)}
      />
      <div className={classNames(css.sidebar, isSidebarOpen && css.visible)}>
        <div>
          <OutsideClickHandler onOutsideClick={() => setDatepickerOpen(false)}>
            <button
              onClick={() => setDatepickerOpen(!isDatepickerOpen)}
              className={classNames(css.date, isDatepickerOpen && css.open)}
            >
              {currentDay && currentDay !== 'Invalid date' && (
                <span className={css.bold}>
                  {intl.formatMessage({ id: `MarketplaceConfig.day.${currentDay}` })},{' '}
                </span>
              )}
              {currentDateMonth && (
                <span>
                  {intl.formatMessage({ id: `MarketplaceConfig.month.${currentDateMonth}` })}{' '}
                  {currentDateDay}
                </span>
              )}
              <svg
                width="12"
                height="7"
                viewBox="0 0 12 7"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M1 1L6 6L11 1" stroke="black" />
              </svg>
            </button>
            {isDatepickerOpen && (
              <div className={css.datepickerWrapper}>
                <DatePickerNew
                  className={css.datepicker}
                  value={{ startDate: moment(currentDate), endDate: moment(currentDate) }}
                  onDatesChange={handleDateChange}
                  onClose={() => null}
                  selectMultipleDays={false}
                />
              </div>
            )}
          </OutsideClickHandler>
        </div>
        <button className={css.closeButtonWrapper} onClick={onCloseSidebar}>
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M1 1L15 15" stroke="black" />
            <path d="M15 1L1 15" stroke="black" />
          </svg>
        </button>
        <div className={css.main}>
          <h5 className={css.sectionTitle}>
            {intl.formatMessage({ id: 'TeamSchedule.sidebar.yourPlan' })}
          </h5>
          {joinedBookings.length ? (
            <div className={css.bookingListWrapper}>
              {joinedBookings.map((b) => (
                <div
                  className={css.bookingWrapper}
                  key={`booking_summary_card_${b.listingId}_${b.bookingId}`}
                >
                  <BookingSummaryCard
                    transactionId={findTransactionId(b.users)}
                    address={b.address}
                    listingId={b.listingId}
                    listingName={b.listingTitle}
                    listingCategory={b.listingCategory}
                    locationId={b.locationId}
                    locationName={b.locationName}
                    users={b.users}
                    times={b.times}
                    isFilled
                    searchParams={searchParams}
                    onJoin={() => {
                      updateCurrentBookingId(b.bookingId);
                      onOpenBottomSheet(b);
                      setShowJoinSpaceButton(false);
                    }}
                  />
                </div>
              ))}
            </div>
          ) : (
            <>
              <p className={css.text}>
                {intl.formatMessage({ id: 'TeamSchedule.sidebar.noBookings' })}
              </p>
              <div className={css.listingTypeBtnWrapper}>
                <ButtonNew
                  onClick={() => onGoToSearchPage('coworking')}
                  type={BUTTON_TYPES.GREEN}
                  size={BUTTON_SIZES.SMALL}
                >
                  {intl.formatMessage({ id: 'MarketplaceConfig.filters.category.coworking' })}
                </ButtonNew>
                <ButtonNew
                  onClick={() => onGoToSearchPage('meeting')}
                  type={BUTTON_TYPES.GREEN}
                  size={BUTTON_SIZES.SMALL}
                >
                  {intl.formatMessage({ id: 'MarketplaceConfig.filters.category.meeting' })}
                </ButtonNew>
              </div>
            </>
          )}

          <div className={css.bookings}>
            <h5 className={css.sectionTitle}>
              {intl.formatMessage({ id: 'TeamSchedule.sidebar.team' })}
            </h5>
            {notJoinedBookings.length ? (
              <div className={css.bookingListWrapper}>
                {notJoinedBookings.map((b, index) => (
                  <div
                    className={css.bookingWrapper}
                    key={`not_joined_booking_summary_card_${b.listingId}_${b.bookingId}`}
                  >
                    <BookingSummaryCard
                      address={b.address}
                      listingId={b.listingId}
                      listingName={b.listingTitle}
                      listingCategory={b.listingCategory}
                      locationId={b.locationId}
                      locationName={b.locationName}
                      users={b.users}
                      times={b.times}
                      searchParams={searchParams}
                      isFilled={b.authorId === currentUserId}
                      onJoin={() => {
                        updateCurrentBookingId(b.bookingId);
                        onOpenBottomSheet(b);
                        setShowJoinSpaceButton(b.listingCategory !== 'schedule' && true);
                      }}
                    />
                  </div>
                ))}
              </div>
            ) : (
              <p className={css.text}>
                {intl.formatMessage({ id: 'TeamSchedule.sidebar.noTeamBookings' })}
              </p>
            )}
          </div>
        </div>
        <BookingInfoBottomSheet
          currentDate={currentDate}
          searchParams={searchParams}
          bookingData={bottomSheetData}
          isOpen={isBottomSheetOpen}
          onClose={onCloseBottomSheet}
          showJoinSpaceButton={showJoinSpaceButton}
          singleButtonLeft
        />
      </div>
    </>
  );
};

export default injectIntl(Sidebar);
