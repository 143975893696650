import React from 'react';
import { bool, func, shape, string } from 'prop-types';
import classNames from 'classnames';
import { Form as FinalForm } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import { FormattedMessage } from '../../util/reactIntl';
import { findOptionsForSelectFilter } from '../../util/search';
import { propTypes } from '../../util/types';
import config from '../../config';
import {
  Button,
  FieldCheckboxGroup,
  FieldCheckboxAndInfoGroup,
  Form,
  FieldSelect,
} from '../../components';

import css from './EditListingFeaturesForm.module.scss';

const EditListingFeaturesFormComponent = (props) => (
  <FinalForm
    {...props}
    mutators={{ ...arrayMutators }}
    render={(formRenderProps) => {
      const {
        disabled,
        ready,
        rootClassName,
        className,
        name,
        handleSubmit,
        pristine,
        saveActionMsg,
        updated,
        updateInProgress,
        fetchErrors,
        filterConfig,
        extraInfoPlaceholderLabel,
        category,
        intl,
      } = formRenderProps;

      const classes = classNames(rootClassName || css.root, className);
      const submitReady = (updated && pristine) || ready;
      const submitInProgress = updateInProgress;
      const submitDisabled = disabled || submitInProgress;

      const { updateListingError, showListingsError } = fetchErrors || {};
      const errorMessage = updateListingError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingFeaturesForm.updateFailed" />
        </p>
      ) : null;

      const errorMessageShowListing = showListingsError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingFeaturesForm.showListingFailed" />
        </p>
      ) : null;

      const options = findOptionsForSelectFilter('ameneties', filterConfig);

      // Props for "offer" select field
      const offerKey = 'offer';
      const offerOptions = findOptionsForSelectFilter(offerKey, filterConfig);

      // Props for "vibe" select field
      const vibeKey = 'vibe';
      const vibeOptions = findOptionsForSelectFilter(vibeKey, filterConfig);

      // Props for "noiselevel" select field
      const noiselevelKey = 'noiselevel';
      const noiselevelOptions = findOptionsForSelectFilter(noiselevelKey, filterConfig);

      // Props for "environment" select field
      const environmentKey = 'environment';
      const environmentOptions = findOptionsForSelectFilter(environmentKey, filterConfig);

      // Props for "perfect" select field
      // const perfectForKey = 'coworking';
      // const perfectForOptions = findOptionsForSelectFilter(keywordsKey, perfectFor);
      const coworking = [
        {
          key: 'coworkingsurroundings',
          label: 'Changing surroundings',
          labelId: 'EditListingFeaturesForm.coworking.coworkingsurroundings',
        },
        {
          key: 'coworkingdone',
          label: 'Getting things done',
          labelId: 'EditListingFeaturesForm.coworking.coworkingdone',
        },
        {
          key: 'coworkinginspired',
          label: 'Get inspiried',
          labelId: 'EditListingFeaturesForm.coworking.coworkinginspired',
        },
        {
          key: 'coworkingfocus',
          label: 'Focus without interruptions',
          labelId: 'EditListingFeaturesForm.coworking.coworkingfocus',
        },
      ];
      const meetingRoom = [
        {
          key: 'meetingintimate',
          label: 'Small and intimate meetings',
          labelId: 'EditListingFeaturesForm.meeting.meetingintimate',
        },
        {
          key: 'meetinginterviews',
          label: 'Interviews',
          labelId: 'EditListingFeaturesForm.meeting.meetinginterviews',
        },
        {
          key: 'meetingcalls',
          label: 'Video calls',
          labelId: 'EditListingFeaturesForm.meeting.meetingcalls',
        },
        {
          key: 'meetingfocus',
          label: 'Individual focus',
          labelId: 'EditListingFeaturesForm.meeting.meetingfocus',
        },
        {
          key: 'meetingteam',
          label: 'Team meetings',
          labelId: 'EditListingFeaturesForm.meeting.meetingteam',
        },
        {
          key: 'meetingworkshop',
          label: 'Workshops',
          labelId: 'EditListingFeaturesForm.meeting.meetingworkshop',
        },
        {
          key: 'meetingmeeting',
          label: 'Video meetings',
          labelId: 'EditListingFeaturesForm.meeting.meetingmeeting',
        },
        {
          key: 'meetingpresentation',
          label: 'Presentations',
          labelId: 'EditListingFeaturesForm.meeting.meetingpresentation',
        },
        {
          key: 'meetingconference',
          label: 'Conference',
          labelId: 'EditListingFeaturesForm.meeting.meetingconference',
        },
        {
          key: 'meetingbig',
          label: 'Big meeting',
          labelId: 'EditListingFeaturesForm.meeting.meetingbig',
        },
        {
          key: 'meetingcreative',
          label: 'Meetings in a creative environment',
          labelId: 'EditListingFeaturesForm.meeting.meetingcreative',
        },
        {
          key: 'meetingrare',
          label: 'All-day meetings in a rare space',
          labelId: 'EditListingFeaturesForm.meeting.meetingrare',
        },
      ];
      const privateOffice = [
        {
          key: 'officeFocus',
          label: 'Days when you need to focus',
          labelId: 'EditListingFeaturesForm.privateOffice.officeFocus',
        },
        {
          key: 'officePrivacy',
          label: 'When you need privacy',
          labelId: 'EditListingFeaturesForm.privateOffice.officePrivacy',
        },
        {
          key: 'smallMeetings',
          label: 'Small meetings',
          labelId: 'EditListingFeaturesForm.privateOffice.smallMeetings',
        },
        {
          key: 'workshops',
          label: 'Workshops',
          labelId: 'EditListingFeaturesForm.privateOffice.workshops',
        },
      ];

      let perfectFor = [];
      switch (category) {
        case 'coworking':
          perfectFor = coworking;
          break;
        case 'meeting':
          perfectFor = meetingRoom;
          break;
        case 'private':
          perfectFor = privateOffice;
          break;
        default:
          perfectFor = [];
      }

      return (
        <Form className={classes} onSubmit={handleSubmit}>
          {errorMessage}
          {errorMessageShowListing}

          <FieldCheckboxGroup
            className={css.features}
            id={'perfectFor'}
            name={'perfectFor'}
            options={perfectFor}
            twoColumns={true}
            label={<FormattedMessage id="EditListingFeaturesPanel.amenetiesPerfectForTitle" />}
          />

          <FieldCheckboxGroup
            className={css.features}
            id={offerKey}
            name={offerKey}
            options={offerOptions}
            twoColumns={true}
            label={<FormattedMessage id="EditListingFeaturesPanel.offerTitle" />}
            category={category}
          />
          <FieldSelect
            id={vibeKey}
            name={vibeKey}
            label={<FormattedMessage id="EditListingFeaturesPanel.vibeTitle" />}
            // validate={validators.required(vatRequiredMessage)}
          >
            <option disabled value="">
              {intl.formatMessage({
                id: 'EditListingFeaturesPanel.vibePlaceholder',
                defaultMessage: 'Vibe',
              })}
            </option>
            {vibeOptions.map((opt) => (
              <option value={opt.key} key={`vat_${opt.key}`}>
                {intl.formatMessage({
                  id: opt.labelId,
                  defaultMessage: opt.label,
                })}
              </option>
            ))}
          </FieldSelect>

          <FieldSelect
            id={environmentKey}
            name={environmentKey}
            label={<FormattedMessage id="EditListingFeaturesPanel.environmentTitle" />}
            // validate={validators.required(vatRequiredMessage)}
          >
            <option disabled value="">
              {intl.formatMessage({
                id: 'EditListingFeaturesPanel.environmentPlaceholder',
                defaultMessage: 'Environment',
              })}
            </option>
            {environmentOptions.map((opt) => (
              <option value={opt.key} key={`vat_${opt.key}`}>
                {intl.formatMessage({
                  id: opt.labelId,
                  defaultMessage: opt.label,
                })}
              </option>
            ))}
          </FieldSelect>

          <FieldSelect
            id={noiselevelKey}
            name={noiselevelKey}
            label={<FormattedMessage id="EditListingFeaturesPanel.soundTitle" />}
            // validate={validators.required(vatRequiredMessage)}
          >
            <option disabled value="">
              {intl.formatMessage({
                id: 'EditListingFeaturesPanel.soundPlaceholder',
                defaultMessage: 'Environment',
              })}
            </option>
            {noiselevelOptions.map((opt) => (
              <option value={opt.key} key={`vat_${opt.key}`}>
                {intl.formatMessage({
                  id: opt.labelId,
                  defaultMessage: opt.label,
                })}
              </option>
            ))}
          </FieldSelect>
          <div className={css.amenities}>
            <FormattedMessage id="EditListingFeaturesPanel.amenetiesTitle" />
            <FieldCheckboxAndInfoGroup
              className={css.features}
              id={name}
              name={name}
              options={options}
              label={<FormattedMessage id="EditListingFeaturesPanel.amenetiesSubtitle" />}
              infoLabel={extraInfoPlaceholderLabel}
              category={category}
            />
          </div>

          <Button
            className={css.submitButton}
            type="submit"
            inProgress={submitInProgress}
            disabled={submitDisabled}
            ready={submitReady}
          >
            {saveActionMsg}
          </Button>
        </Form>
      );
    }}
  />
);

EditListingFeaturesFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  fetchErrors: null,
  filterConfig: config.custom.filters,
  category: 'none',
};

EditListingFeaturesFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  name: string.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
  filterConfig: propTypes.filterConfig,
  category: string.isRequired,
};

const EditListingFeaturesForm = EditListingFeaturesFormComponent;

export default EditListingFeaturesForm;
