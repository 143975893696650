import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { apiBaseUrl, deleteLocationImage } from '../../../../util/api';

import { IconSpinner } from '../../../../components';
import { ImgIcon, UploadIcon } from '../Icons';
import LocationImageComponent from './LocationImageComponent';

import css from './ImageInput.module.scss';

const ImageInput = ({ images, updateImages, locationID, intl }) => {
  const [files, setFiles] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [uploadError, setUploadError] = useState(null);

  const handleFileChange = (event) => {
    setFiles(event.target.files);
  };

  const handleDrop = (event) => {
    event.preventDefault();
    const files = event.dataTransfer.files;
    setFiles(files);
  };

  useEffect(() => {
    handleUpload();
  }, [files]);

  const handleUpload = async () => {
    if (!files) {
      return;
    }
    setUploadError(null);
    setIsLoading(true);

    const formData = new FormData();
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      const allowedExtensions = /(\.jpg|\.jpeg|\.png|\.gif|\.webp|\.tiff)$/i;
      if (file && file.name && !file.name.match(allowedExtensions)) {
        alert(`File format is not supported: ${file.name}`);
        continue;
      }
      formData.append(`image`, file);
    }
    formData.append('locationID', locationID);

    return axios
      .post(`${apiBaseUrl()}/api/upload-location-image`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        withCredentials: true,
      })
      .then((res) => {
        const newImages = res.data.imageURLs.map((imgObj) => ({
          large: imgObj[0],
          medium: imgObj[1],
          small: imgObj[2],
        }));
        updateImages([...images, ...newImages]);
        setIsLoading(false);
      })
      .catch((error) => {
        setUploadError(error);
        setIsLoading(false);
      });
  };

  const markImageAsPrimary = (index) => {
    // move the image to the first position in the array and update state
    if (index >= 0 && index < images.length) {
      const element = images[index];
      images.splice(index, 1);
      images.unshift(element);
      updateImages([...images]);
    }
  };

  const deleteImage = (index) => {
    if (index >= 0 && index < images.length) {
      // delete the image from the state and the image grid
      const element = images[index];
      images.splice(index, 1);
      updateImages([...images]);

      // delete each version of the image from Amazon S3
      Object.values(element).forEach((imageURL) => {
        console.log('deleting image from S3', imageURL);
        deleteLocationImage(imageURL);
      });
    }
  };

  const imageGrid = images?.map((imageObject, index) => {
    return (
      <div className={css.imageGridItem} key={index}>
        <LocationImageComponent
          intl={intl}
          imageObject={imageObject}
          index={index}
          markImageAsPrimary={markImageAsPrimary}
          deleteImage={deleteImage}
        />
      </div>
    );
  });

  return (
    <div className={css.imageInputRoot}>
      <div className={css.imageGridWrapper}>
        {imageGrid}
        <label className={css.label} id="image" name="image" onDrop={handleDrop}>
          <ImgIcon />
          {isLoading ? (
            <IconSpinner />
          ) : (
            <>
              <UploadIcon /> {intl.formatMessage({ id: 'CreateLocationForm.imageInput.upload' })}
            </>
          )}
          <input
            className={css.input}
            type="file"
            multiple
            name="image"
            id="location_image"
            onChange={handleFileChange}
          />
        </label>
      </div>
      {!!uploadError && (
        <p className={css.error}>
          {intl.formatMessage({ id: 'CreateLocationForm.imageInput.error' })}
        </p>
      )}
    </div>
  );
};

export default ImageInput;
