import React, { memo } from 'react';
import { injectIntl } from 'react-intl';
import { string } from 'prop-types';

import css from './FieldHeader.module.scss';

const FieldHeader = memo(({ label, caption, hintHeader, onClickHint, intl }) => (
  <>
    <div className={css.heading}>
      <h5 className={css.label}>{intl.formatMessage({ id: label })}</h5>
      {!!hintHeader && (
        <p className={css.hint} onClick={onClickHint}>
          {intl.formatMessage({ id: hintHeader })}
        </p>
      )}
    </div>
    {!!caption && (
      <p className={css.caption}>
        {intl.formatMessage({ id: caption }, { bold: (text) => <strong>{text}</strong> })}
      </p>
    )}
  </>
));

FieldHeader.propTypes = {
  label: string.isRequired,
  caption: string,
};

export default injectIntl(FieldHeader);
