import React from 'react';
import classNames from 'classnames';
import { injectIntl } from 'react-intl';
import { bool, object, oneOf } from 'prop-types';

import { NamedLink } from '../../../components';
import EditIcon from './EditIcon';
import {
  LISTING_STATE_PENDING_APPROVAL,
  LISTING_STATE_CLOSED,
  LISTING_STATE_DRAFT,
  LISTING_STATE_PUBLISHED,
} from '../../../util/types';

import css from './ActionBar.module.scss';

const STATUSES = {
  pendingApproval: LISTING_STATE_PENDING_APPROVAL,
  draft: LISTING_STATE_DRAFT,
  closed: LISTING_STATE_CLOSED,
  published: LISTING_STATE_PUBLISHED,
};

const TYPES = {
  listing: 'listing',
  location: 'location',
};

const MESSAGES = {
  own_published: 'ListingPage.ownListing',
  own_pendingApproval: 'ListingPage.ownListingPendingApproval',
  own_closed: 'ListingPage.ownClosedListing',
  own_draft: 'ListingPage.ownListingDraft',
  closed: 'ListingPage.closedListing',
};

const ActionBar = ({ type, state, editParams, isOwn, intl }) => {
  const actionBarText = isOwn
    ? MESSAGES[`own_${state}`]
    : state === STATUSES.closed
    ? MESSAGES.closed
    : null;

  const actionBtnText =
    state === STATUSES.draft ? 'ListingPage.finishListing' : 'ListingPage.editListing';

  const textClassList = classNames(
    state === STATUSES.closed && css.closedText,
    state === STATUSES.closed && !isOwn && css.textCenter,
    state === STATUSES.pendingApproval && css.ownTextPendingApproval
  );

  return isOwn || state === STATUSES.closed ? (
    <div className={css.actionBar}>
      <p className={textClassList}>
        {intl.formatMessage({
          id: actionBarText,
        })}
      </p>
      {isOwn && (
        <NamedLink
          className={css.editLink}
          params={editParams}
          name={editParams.name ? editParams.name : 'EditListingPage'}
        >
          <EditIcon className={css.editIcon} />
          {intl.formatMessage({ id: actionBtnText })}
        </NamedLink>
      )}
    </div>
  ) : null;
};

ActionBar.propTypes = {
  type: oneOf(Object.values(TYPES)),
  state: oneOf(Object.values(STATUSES)),
  editParams: object.isRequired,
  isOwn: bool,
};

export default injectIntl(ActionBar);
