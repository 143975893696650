import { bool, func, string } from 'prop-types';
import React from 'react';
import { Button, PrimaryButton } from '../../../components';
import css from './MySubscriptionTab.module.scss';

const BottomButtons = (props) => {
  const {
    leftBtnText,
    leftBtnHandleClick,
    rightBtnText,
    rightBtnHandleClick,
    rightBtnType,
    rightBtnReady,
    rightBtnInProgress,
    rightBtnDisabled,
  } = props;

  return (
    <div className={css.bottomButtonWrapper}>
      <Button type="button" className={css.backButton} onClick={leftBtnHandleClick}>
        {leftBtnText}
      </Button>
      <PrimaryButton
        type={rightBtnType}
        className={css.submitButton}
        ready={rightBtnReady}
        disabled={rightBtnDisabled}
        inProgress={rightBtnInProgress}
        onClick={rightBtnHandleClick}
      >
        {rightBtnText}
      </PrimaryButton>
    </div>
  );
};

BottomButtons.defaultProps = {};

BottomButtons.propTypes = {
  leftBtnText: string.isRequired,
  leftBtnHandleClick: func,
  isLocationTab: bool,
  rightBtnText: string.isRequired,
  rightBtnHandleClick: func,
  rightBtnReady: bool,
  rightBtnInProgress: bool,
  rightBtnDisabled: bool,
};
export default BottomButtons;
