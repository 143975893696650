import React from 'react';

const UserIcon = () => (
  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M13 13.1H13.1V13V11C13.1 10.1649 12.3786 9.43985 11.2849 8.92948C10.1831 8.41532 8.66804 8.1 7 8.1C5.33196 8.1 3.8169 8.41532 2.71511 8.92948C1.62145 9.43985 0.9 10.1649 0.9 11V13V13.1H1H13ZM7 9.1C8.42693 9.1 9.7142 9.32276 10.6411 9.67933C11.1049 9.85775 11.4733 10.0677 11.7241 10.2957C11.9749 10.5237 12.1 10.7619 12.1 11V12.1H1.9V11C1.9 10.7619 2.02515 10.5237 2.2759 10.2957C2.52667 10.0677 2.89513 9.85775 3.3589 9.67933C4.2858 9.32276 5.57307 9.1 7 9.1ZM9.05061 1.74939C8.50675 1.20554 7.76913 0.9 7 0.9C6.23087 0.9 5.49325 1.20554 4.94939 1.74939C4.40554 2.29325 4.1 3.03087 4.1 3.8C4.1 4.56913 4.40554 5.30675 4.94939 5.85061C5.49325 6.39446 6.23087 6.7 7 6.7C7.76913 6.7 8.50675 6.39446 9.05061 5.85061C9.59447 5.30675 9.9 4.56913 9.9 3.8C9.9 3.03087 9.59447 2.29325 9.05061 1.74939ZM5.6565 2.4565C6.01282 2.10018 6.49609 1.9 7 1.9C7.50391 1.9 7.98718 2.10018 8.3435 2.4565C8.69982 2.81282 8.9 3.29609 8.9 3.8C8.9 4.30391 8.69982 4.78718 8.3435 5.1435C7.98718 5.49982 7.50391 5.7 7 5.7C6.49609 5.7 6.01282 5.49982 5.6565 5.1435C5.30018 4.78718 5.1 4.30391 5.1 3.8C5.1 3.29609 5.30018 2.81282 5.6565 2.4565Z"
      fill="#9AB7DD"
      stroke="#9AB7DD"
      strokeWidth="0.2"
    />
  </svg>
);

export default UserIcon;
