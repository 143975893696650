import React, { useState, useEffect } from 'react';
import { injectIntl } from 'react-intl';
import { ButtonNew } from '../../components_new';

import css from './CouponCodeInput.module.scss';

const CouponCodeInput = ({
  intl,
  id,
  name,
  value,
  showError,
  showSuccess,
  onInput,
  applyCoupon,
  proceedLoading,
  discountDuration,
  coupon,
  buttonType = 'green',
}) => {
  useEffect(() => {
    toggleOpen(!!coupon.id);
  }, [coupon.id]);

  const [isOpen, toggleOpen] = useState(!!coupon.id);

  const couponButtonText = intl.formatMessage({
    id: 'SubscriptionStartDateForm.couponOpener',
  });

  const couponFieldPlaceholder = intl.formatMessage({
    id: 'SubscriptionStartDateForm.couponFieldPlaceholder',
  });

  const couponFailMessage = intl.formatMessage(
    { id: 'SubscriptionStartDateForm.couponFail' },
    { reason: 'Reason' }
  );

  const couponSuccessMessage = discountDuration.period
    ? intl.formatMessage(
        {
          id: 'SubscriptionStartDateForm.couponSuccess',
        },
        {
          count: discountDuration.period,
        }
      )
    : intl.formatMessage({ id: 'SubscriptionStartDateForm.couponSuccessOneTime' });

  const couponApplyText = intl.formatMessage({
    id: 'SubscriptionStartDateForm.applyCoupon',
  });

  const inputProps = {
    id,
    name,
    value,
    onInput: ({ currentTarget: { value } }) => onInput(value),
    placeholder: couponFieldPlaceholder,
    className: css.message,
    type: 'text',
  };

  return (
    <div className={css.couponField}>
      <div>
        <button
          type="button"
          className={`${css.couponTitle} ${isOpen ? css.open : ''}`}
          onClick={() => toggleOpen(!isOpen)}
        >
          {couponButtonText}
        </button>
        <span>{isOpen ? ' -' : ' +'}</span>
      </div>
      <div className={isOpen ? css.couponOpen : css.couponClosed}>
        <input {...inputProps} />
        {showError && (
          <div className={css.couponFailMessage} id="couponMessage">
            {couponFailMessage}
          </div>
        )}
        {showSuccess && (
          <div className={css.couponSuccessMessage} id="couponMessage">
            {couponSuccessMessage}
          </div>
        )}
        <ButtonNew
          className={css.couponButton}
          onClick={(e) => {
            e.preventDefault();
            applyCoupon();
          }}
          inProgress={proceedLoading}
          type={buttonType}
        >
          {couponApplyText}
        </ButtonNew>
      </div>
    </div>
  );
};

export default injectIntl(CouponCodeInput);
