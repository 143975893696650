import get from 'lodash/get';
import pick from 'lodash/pick';
import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { fetchCompanyUser, fetchCurrentUser } from '../../ducks/user.duck';
import { denormalisedResponseEntities } from '../../util/data';
import {
  isB2bTx,
  TRANSITIONS,
  TRANSITION_B2B_COMPLETE_NO_ADDONS,
  TRANSITION_B2B_CUSTOMER_CANCEL,
  TRANSITION_B2B_CUSTOMER_CANCEL_NO_ADDONS,
  TRANSITION_B2B_DISABLE_CANCELLATION,
  TRANSITION_B2B_DISABLE_CANCELLATION_NO_ADDONS,
  TRANSITION_B2B_EXPIRE_REVIEW_PERIOD,
  TRANSITION_B2B_OPERATOR_CANCEL,
  TRANSITION_B2B_OPERATOR_CANCEL_NO_ADDONS,
  TRANSITION_B2B_OPERATOR_CANCEL_AFTER_MARK_NO_CANCELLATION,
  TRANSITION_B2B_PROVIDER_CANCEL,
  TRANSITION_B2B_PROVIDER_CANCEL_NO_ADDONS,
  TRANSITION_B2B_REQUEST_BOOKING,
  TRANSITION_B2B_REQUEST_BOOKING_HOURLY,
  TRANSITION_B2B_REQUEST_BOOKING_HOURLY_NO_ADDONS,
  TRANSITION_B2B_REQUEST_BOOKING_NO_ADDONS,
  TRANSITION_B2B_REVIEW_BY_CUSTOMER,
  TRANSITION_B2B_CONFIRM_PAYMENT,
  TRANSITION_B2B_ENQUIRE,
  TRANSITION_B2B_ENQUIRE_HOURLY,
  TRANSITION_B2B_ENQUIRE_NO_ADDONS,
  TRANSITION_B2B_ENQUIRE_HOURLY_NO_ADDONS,
  TRANSITION_B2B_CONFIRM_PAYMENT_AFTER_ENQUIRY,
  TRANSITION_B2B_EXPIRE_NO_ADDONS,
  TRANSITION_B2B_DECLINE_NO_ADDONS,
  TRANSITION_B2B_DECLINE,
  TRANSITION_B2B_EXPIRE_PENDING_PAYMENT,
  TRANSITION_ACCEPT,
  TRANSITION_B2B_ACCEPT,
  TRANSITION_B2B_ACCEPT_NO_ADDONS,
} from '../../util/transaction';
import { parse } from '../../util/urlHelpers';
import { queryAllPage } from './CompanyBookingsPage.helper';
import { isCompanyUser as checkCompanyUser } from '../../util/b2bHelper';

const TX_PER_PAGE = 10;

const FETCH_UPCOMING_REQUEST = 'app/CompanyBookingsPage/FETCH_UPCOMING_REQUEST';
const FETCH_UPCOMING_SUCCESS = 'app/CompanyBookingsPage/FETCH_UPCOMING_SUCCESS';
const FETCH_UPCOMING_ERROR = 'app/CompanyBookingsPage/FETCH_UPCOMING_ERROR';
const TOGGLE_FETCH_UPCOMING_LOADING = 'app/CompanyBookingsPage/TOGGLE_FETCH_UPCOMING_LOADING';

const FETCH_PREVIOUS_REQUEST = 'app/CompanyBookingsPage/FETCH_PREVIOUS_REQUEST';
const FETCH_PREVIOUS_SUCCESS = 'app/CompanyBookingsPage/FETCH_PREVIOUS_SUCCESS';
const FETCH_PREVIOUS_ERROR = 'app/CompanyBookingsPage/FETCH_PREVIOUS_ERROR';
const TOGGLE_FETCH_PREVIOUS_LOADING = 'app/CompanyBookingsPage/TOGGLE_FETCH_PREVIOUS_LOADING';

const initialState = {
  upcomingRefs: [],
  previousRefs: [],
  upcomingRefsLoading: false,
  previousRefsLoading: false,
  pagination: {
    totalItems: 0,
    totalPages: 0,
    page: 1,
    perPage: TX_PER_PAGE,
  },
};

export default function companyBookingsPageReducer(state = initialState, { type, payload }) {
  switch (type) {
    case FETCH_UPCOMING_SUCCESS:
      return {
        ...state,
        upcomingRefs: payload,
      };
    case FETCH_PREVIOUS_SUCCESS:
      return {
        ...state,
        previousRefs: payload.ids,
        pagination: payload.pagination,
      };
    case TOGGLE_FETCH_PREVIOUS_LOADING:
      return {
        ...state,
        previousRefsLoading: payload,
      };
    case TOGGLE_FETCH_UPCOMING_LOADING:
      return {
        ...state,
        upcomingRefsLoading: payload,
      };
    default:
      return state;
  }
}

const toggleFetchUpcomingLoading = (newState) => ({
  type: TOGGLE_FETCH_UPCOMING_LOADING,
  payload: newState,
});

const toggleFetchPreviousLoading = (newState) => ({
  type: TOGGLE_FETCH_PREVIOUS_LOADING,
  payload: newState,
});

const fetchUpcomingSuccess = (ids) => ({
  type: FETCH_UPCOMING_SUCCESS,
  payload: ids,
});

const fetchPreviousSuccess = (ids, pagination) => ({
  type: FETCH_PREVIOUS_SUCCESS,
  payload: {
    ids,
    pagination,
  },
});

const fetchUpcoming = () => async (dispatch, getState, sdk) => {
  try {
    dispatch(toggleFetchUpcomingLoading(true));
    const response = await sdk.transactions.query({
      lastTransitions: [
        TRANSITION_B2B_DISABLE_CANCELLATION,
        TRANSITION_B2B_REQUEST_BOOKING,
        TRANSITION_B2B_REQUEST_BOOKING_HOURLY,
        TRANSITION_B2B_REQUEST_BOOKING_NO_ADDONS,
        TRANSITION_B2B_REQUEST_BOOKING_HOURLY_NO_ADDONS,
        TRANSITION_B2B_CONFIRM_PAYMENT,
        TRANSITION_B2B_DISABLE_CANCELLATION_NO_ADDONS,
        TRANSITION_B2B_DISABLE_CANCELLATION,
        TRANSITION_B2B_ENQUIRE,
        TRANSITION_B2B_ENQUIRE_HOURLY,
        TRANSITION_B2B_ENQUIRE_NO_ADDONS,
        TRANSITION_B2B_ENQUIRE_HOURLY_NO_ADDONS,
        TRANSITION_B2B_CONFIRM_PAYMENT_AFTER_ENQUIRY,
        TRANSITION_B2B_ACCEPT,
        TRANSITION_B2B_ACCEPT_NO_ADDONS,
      ],
      only: 'order',
      per_page: 100,
      include: [
        'provider',
        'provider.profileImage',
        'customer',
        'customer.profileImage',
        'booking',
        'listing',
      ],
      'fields.transaction': [
        'lastTransition',
        'lastTransitionedAt',
        'transitions',
        'payinTotal',
        'payoutTotal',
        'protectedData',
      ],
      'fields.user': ['profile.displayName', 'profile.abbreviatedName'],
      'fields.image': ['variants.square-small', 'variants.square-small2x'],
    });

    dispatch(addMarketplaceEntities(response));
    const txs = denormalisedResponseEntities(response);
    const ids = txs.map((tx) => pick(tx, ['id', 'type']));
    dispatch(fetchUpcomingSuccess(ids));
    dispatch(toggleFetchUpcomingLoading(false));
  } catch (error) {}
};

const fetchPrevious =
  (page = 1) =>
  async (dispatch, getState, sdk) => {
    dispatch(toggleFetchPreviousLoading(true));
    const params = {
      lastTransitions: [
        TRANSITION_B2B_COMPLETE_NO_ADDONS,
        TRANSITION_B2B_OPERATOR_CANCEL,
        TRANSITION_B2B_OPERATOR_CANCEL_NO_ADDONS,
        TRANSITION_B2B_PROVIDER_CANCEL,
        TRANSITION_B2B_PROVIDER_CANCEL_NO_ADDONS,
        TRANSITION_B2B_CUSTOMER_CANCEL,
        TRANSITION_B2B_CUSTOMER_CANCEL_NO_ADDONS,
        TRANSITION_B2B_OPERATOR_CANCEL_AFTER_MARK_NO_CANCELLATION,
        TRANSITION_B2B_REVIEW_BY_CUSTOMER,
        TRANSITION_B2B_EXPIRE_REVIEW_PERIOD,
        TRANSITION_B2B_CONFIRM_PAYMENT_AFTER_ENQUIRY,
        TRANSITION_B2B_EXPIRE_NO_ADDONS,
        TRANSITION_B2B_DECLINE_NO_ADDONS,
        TRANSITION_B2B_DECLINE,
        TRANSITION_B2B_EXPIRE_PENDING_PAYMENT,
      ],
      only: 'order',
      include: [
        'provider',
        'provider.profileImage',
        'customer',
        'customer.profileImage',
        'booking',
        'listing',
      ],
      'fields.transaction': [
        'lastTransition',
        'lastTransitionedAt',
        'transitions',
        'payinTotal',
        'payoutTotal',
        'protectedData',
      ],
      'fields.user': ['profile.displayName', 'profile.abbreviatedName'],
      'fields.image': ['variants.square-small', 'variants.square-small2x'],
    };
    try {
      const result = await queryAllPage({
        sdk: sdk.transactions,
        query: params,
        perPage: 100,
      });

      let txs = [];
      for (let i = 0; i < result.length; i++) {
        dispatch(addMarketplaceEntities(result[i]));
        txs = txs.concat(denormalisedResponseEntities(result[i]));
      }
      const pagination = {
        totalItems: txs.length,
        totalPages: txs.length <= 5 ? 1 : Math.ceil((txs.length - 5) / TX_PER_PAGE) + 1, // First page only shows 5 previous transactions, other pages show 10.
        page,
        perPage: TX_PER_PAGE,
      };
      const ids = txs.map((tx) => pick(tx, ['id', 'type']));
      dispatch(fetchPreviousSuccess(ids, pagination));
      dispatch(toggleFetchPreviousLoading(false));
      return result;
    } catch (error) {}
  };

export const loadData = (_, search) => (dispatch, getState) => {
  const { page = 1 } = parse(search);
  return Promise.all([
    dispatch(fetchUpcoming()),
    dispatch(fetchPrevious(page)),
    dispatch(fetchCurrentUser()),
  ]).then(() => {
    const currentUser = getState().user.currentUser;
    const companyAccountId = get(
      currentUser,
      'attributes.profile.protectedData.companyAccountId',
      null
    );
    const isCompanyUser = checkCompanyUser(currentUser);
    if (companyAccountId || isCompanyUser) {
      dispatch(fetchCompanyUser());
    }
  });
};
