import React from 'react';
import ContentLoader from 'react-content-loader';

const ContentLoaderMobile = () => (
  <ContentLoader width="366" height="324" viewBox="0 0 366 324">
    <rect x="310" y="26" width="56" height="14" rx="4" />
    <rect y="9" width="150" height="18" rx="4" />
    <rect y="31" width="110" height="14" rx="4" />
    <rect x="191" y="19" width="90" height="16" rx="4" />
    <rect x="310" y="9" width="56" height="14" rx="4" />
    <rect y="53" width="366" height="1" />
    <rect x="310" y="80" width="56" height="14" rx="4" />
    <rect y="63" width="150" height="18" rx="4" />
    <rect y="85" width="110" height="14" rx="4" />
    <rect x="191" y="73" width="90" height="16" rx="4" />
    <rect x="310" y="63" width="56" height="14" rx="4" />
    <rect y="107" width="366" height="1" />
    <rect x="310" y="134" width="56" height="14" rx="4" />
    <rect y="117" width="150" height="18" rx="4" />
    <rect y="139" width="110" height="14" rx="4" />
    <rect x="191" y="127" width="90" height="16" rx="4" />
    <rect x="310" y="117" width="56" height="14" rx="4" />
    <rect y="161" width="366" height="1" />
    <rect x="310" y="188" width="56" height="14" rx="4" />
    <rect y="171" width="150" height="18" rx="4" />
    <rect y="193" width="110" height="14" rx="4" />
    <rect x="191" y="181" width="90" height="16" rx="4" />
    <rect x="310" y="171" width="56" height="14" rx="4" />
    <rect y="215" width="366" height="1" />
    <rect x="310" y="242" width="56" height="14" rx="4" />
    <rect y="225" width="150" height="18" rx="4" />
    <rect y="247" width="110" height="14" rx="4" />
    <rect x="191" y="235" width="90" height="16" rx="4" />
    <rect x="310" y="225" width="56" height="14" rx="4" />
    <rect y="269" width="366" height="1" />
    <rect x="310" y="296" width="56" height="14" rx="4" />
    <rect y="279" width="150" height="18" rx="4" />
    <rect y="301" width="110" height="14" rx="4" />
    <rect x="191" y="289" width="90" height="16" rx="4" />
    <rect x="310" y="279" width="56" height="14" rx="4" />
    <rect y="323" width="366" height="1" />
  </ContentLoader>
);

export default ContentLoaderMobile;
