import React from 'react';
import css from '../DayAvailabilityPlan/DayAvailabilityPlan.module.scss';

const CopyIcon = ({ className }) => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      className={
        (className === 'iconGrey' && css.iconGrey) || (className === 'iconGreen' && css.iconGreen)
      }
      strokeWidth="0.5px"
      d="M2 15H9C9.55313 15 10 14.5531 10 14V12.5C10 12.225 10.225 12 10.5 12C10.775 12 11 12.225 11 12.5V14C11 15.1031 10.1031 16 9 16H2C0.895313 16 0 15.1031 0 14V7C0 5.89687 0.895313 5 2 5H3.5C3.775 5 4 5.225 4 5.5C4 5.775 3.775 6 3.5 6H2C1.44781 6 1 6.44688 1 7V14C1 14.5531 1.44781 15 2 15ZM5 2C5 0.895313 5.89687 0 7 0H14C15.1031 0 16 0.895313 16 2V9C16 10.1031 15.1031 11 14 11H7C5.89687 11 5 10.1031 5 9V2ZM7 10H14C14.5531 10 15 9.55313 15 9V2C15 1.44781 14.5531 1 14 1H7C6.44688 1 6 1.44781 6 2V9C6 9.55313 6.44688 10 7 10Z"
      fill="#999999"
    />
  </svg>
);

export default CopyIcon;
