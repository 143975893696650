import React from 'react';
import ContentLoader from 'react-content-loader';

const ContentLoaderMobile = () => (
  <ContentLoader viewBox="0 0 366 480" width="366" height="480">
    <rect x="330" y="31" width="36" height="18" rx="4" />
    <rect y="16" width="200" height="24" rx="4" />
    <rect y="46" width="160" height="18" rx="4" />
    <rect y="79" width="366" height="1" />
    <rect x="330" y="191" width="36" height="18" rx="4" />
    <rect y="176" width="200" height="24" rx="4" />
    <rect y="206" width="160" height="18" rx="4" />
    <rect y="239" width="366" height="1" />
    <rect x="330" y="111" width="36" height="18" rx="4" />
    <rect y="96" width="200" height="24" rx="4" />
    <rect y="126" width="160" height="18" rx="4" />
    <rect y="159" width="366" height="1" />
    <rect x="330" y="271" width="36" height="18" rx="4" />
    <rect y="256" width="200" height="24" rx="4" />
    <rect y="286" width="160" height="18" rx="4" />
    <rect y="319" width="366" height="1" />
    <rect x="330" y="431" width="36" height="18" rx="4" />
    <rect y="416" width="200" height="24" rx="4" />
    <rect y="446" width="160" height="18" rx="4" />
    <rect y="479" width="366" height="1" />
    <rect x="330" y="351" width="36" height="18" rx="4" />
    <rect y="336" width="200" height="24" rx="4" />
    <rect y="366" width="160" height="18" rx="4" />
    <rect y="399" width="366" height="1" />
  </ContentLoader>
);

export default ContentLoaderMobile;
