import React, { useCallback } from 'react';
import css from './EditSubscription.module.scss';
import { NamedLink, Button } from '../../../components';
import {
  MY_SUBSCRIPTION,
  MY_SUBSCRIPTION_ADD_ONE_TIME_CREDITS_ACTION,
  SUBSCRIPTION_STATUS_ACTIVE,
  SUBSCRIPTION_STATUS_CANCELLED,
  SUBSCRIPTION_STATUS_PAUSED,
} from '../../../util/types';
import { intlShape } from '../../../util/reactIntl';
import { any, number, string } from 'prop-types';
import { object } from 'prop-types';
import { formatSubscriptionStatus } from '../utils/subscription';
import classNames from 'classnames';
import { useViewport } from '../../../util/hooks';
import { MAX_MOBILE_SCREEN_WIDTH } from '../../../util/device';

const CurrentPlanBox = (props) => {
  const {
    intl,
    credits,
    renewalDate,
    creditsLeft,
    setCancelReasonModalOpen,
    setPauseModalOpen,
    setReactivateModalOpen,
    subscriptionStatus,
  } = props;
  const { width } = useViewport();
  const isMobile = width < MAX_MOBILE_SCREEN_WIDTH;
  const isSubscriptionActive = subscriptionStatus === SUBSCRIPTION_STATUS_ACTIVE;
  const isSubscriptionPaused = subscriptionStatus === SUBSCRIPTION_STATUS_PAUSED;
  const isSubscriptionCancelled = subscriptionStatus === SUBSCRIPTION_STATUS_CANCELLED;
  const formattedSubscriptionStatus = formatSubscriptionStatus(subscriptionStatus, intl);
  const subscriptionStatusClasses = classNames(css.subscriptionStatus, {
    [css.active]: isSubscriptionActive,
    [css.cancel]: isSubscriptionCancelled,
    [css.pause]: isSubscriptionPaused,
  });
  const showActionButton = isSubscriptionActive || isSubscriptionPaused;

  return (
    <div className={css.currentPlanBox}>
      <div className={css.titleRow}>
        <div className={css.title}>
          <span className={css.titleContent}>
            {intl.formatMessage({ id: 'EditSubscription.currentPlanTitle' })}
          </span>
          {!isMobile && (
            <span className={subscriptionStatusClasses}>{formattedSubscriptionStatus}</span>
          )}
        </div>
        {showActionButton && (
          <div className={css.actionButtonsWrapper}>
            <Button
              type="button"
              className={css.actionButton}
              onClick={setCancelReasonModalOpen.on}
            >
              {intl.formatMessage({ id: 'EditSubscription.cancelSubscription' })}
            </Button>
            <span className={css.separator}>|</span>
            <Button
              type="button"
              className={css.actionButton}
              onClick={isSubscriptionActive ? setPauseModalOpen.on : setReactivateModalOpen.on}
            >
              {isSubscriptionActive
                ? intl.formatMessage({ id: 'EditSubscription.pauseSubscription' })
                : intl.formatMessage({ id: 'EditSubscription.reactivateSubscription' })}
            </Button>
          </div>
        )}
      </div>
      <div className={css.creditsWrapperDesktop}>
        {intl.formatMessage(
          { id: 'MySubscriptionTab.creditPerMonthDesktop' },
          {
            span: (msg) => <span className={css.boldText}>{msg}</span>,
            credits,
          }
        )}
      </div>
      <div className={css.renewalDesktop}>
        {intl.formatMessage({ id: 'EditSubscription.renewalDateDesktop' }, { renewalDate })}
      </div>
      <NamedLink
        className={css.oneTimeCreditsBtn}
        name="SubscriptionManagementPageMySubscription"
        params={{ tab: MY_SUBSCRIPTION, action: MY_SUBSCRIPTION_ADD_ONE_TIME_CREDITS_ACTION }}
      >
        {intl.formatMessage({ id: 'MySubscriptionTab.oneTimeCreditsBtn' })}
      </NamedLink>
      {showActionButton && (
        <div className={css.actionButtonsWrapperDesktop}>
          <Button type="button" className={css.actionButton} onClick={setCancelReasonModalOpen.on}>
            {intl.formatMessage({ id: 'EditSubscription.cancelSubscription' })}
          </Button>
          <span className={css.separator}>|</span>
          <Button
            type="button"
            className={css.actionButton}
            onClick={isSubscriptionActive ? setPauseModalOpen.on : setReactivateModalOpen.on}
          >
            {isSubscriptionActive
              ? intl.formatMessage({ id: 'EditSubscription.pauseSubscription' })
              : intl.formatMessage({ id: 'EditSubscription.reactivateSubscription' })}
          </Button>
        </div>
      )}
    </div>
  );
};

CurrentPlanBox.defaultProps = {};

CurrentPlanBox.propTypes = {
  intl: intlShape.isRequired,
  credits: number,
  renewalDate: string,
  creditsLeft: number,
  setCancelReasonModalOpen: object,
  subscriptionStatus: string,
  setReactivateModalOpen: any,
};
export default CurrentPlanBox;
