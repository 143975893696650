import React from 'react';
import { array, bool, object, oneOf, oneOfType, string } from 'prop-types';
import classNames from 'classnames';
import config from '../../config';

import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Autoplay, Navigation, Pagination } from 'swiper';
import left from './assets/left.svg';
import right from './assets/right.svg';
import bullet from './assets/bullet.svg';
import { FormattedMessage } from 'react-intl';

import css from './ImageSlider.module.scss';

export const CATEGORIES = [
  'images',
  'spaces',
  'reviews',
  'text',
  'images_local',
  'location_images',
  'location_page',
];
export const DIRECTIONS = ['horizontal', 'vertical'];

SwiperCore.use([Autoplay, Navigation, Pagination]);
const ImageSlider = (props) => {
  // Slider takes :
  // autoplay(to use default 3 second delay) or autoplay{{delay:5000}} for custom delay - defaults to false
  // category to determine if it is displaying several images of 1 listing, 1 image of each of several listings with text, or a review object(or array of review objects)
  // data is the object/array of listings or images or reviews
  // showLocation and showTitle both bools to show location title and location as on Landing Page. default to false
  const {
    autoplay,
    category,
    className,
    data,
    direction,
    showLocation,
    showTitle,
    allowTouchMove,
  } = props;

  if (!data) {
    return null;
  }
  const classes = classNames(
    className,
    css.sliderContainer,
    category === 'reviews' ? css.sliderContainerReviews : '',
    category === 'text' ? css.textContainer : ''
  );
  // if category = images, create array of small images for listing card.
  // if category = spaces then the listing/data object will be mapped through directly.
  // if category = reviews then will map through that object directly.
  const images =
    category === CATEGORIES[0] && data.images.length >= 1
      ? data.images.map((img) => img.attributes?.variants['square-small2x']?.url)
      : null;

  const content =
    category === CATEGORIES[0] && images
      ? images.map((img, index) => (
          <SwiperSlide key={`swiperSlide_images_${index}`}>
            <img className={css.image} src={img} alt="work here" />
          </SwiperSlide>
        ))
      : category === CATEGORIES[1]
      ? data.map((listing, index) => (
          <SwiperSlide className={css.swiperSlide} key={`swiperSlide_listings_${index}`}>
            <a
              href={`${config.canonicalRootURL}/l/${listing.id.uuid}`}
              target="_blank"
              rel="noopener noreferrer"
              className={css.spacesImageLink}
            >
              <img
                className={css.spacesImage}
                src={
                  listing.images[0]?.attributes?.variants['scaled-medium']?.url ||
                  listing.images[0]?.attributes?.variants['square-small2x']?.url
                }
                alt="work here"
              />
            </a>
            <div className={css.spacesInfoContainer}>
              {showTitle && <h3 className={css.spacesTitle}>{listing.attributes.title}</h3>}
              {showLocation && (
                <h4 className={css.spacesLocation}>
                  {listing.attributes.publicData.location.address.split(', ').slice(-2).join(' ')}
                </h4>
              )}
            </div>
          </SwiperSlide>
        ))
      : category === CATEGORIES[2]
      ? data.map((review, index) => (
          <SwiperSlide className={css.swiperSlide} key={`swiperSlide_reviews_${index}`}>
            <div className={css.reviewsSlideContainer}>
              <div className={css.reviewImageWrapper}>
                {review.img && (
                  <img className={css.reviewImage} src={review.img} alt={review.alt} />
                )}
              </div>
              <h3 className={css.reviewQuote}>
                {review.text && <span className={css.reviewQuote}>{review.type}</span>}
                {review.text}
                {review.author && <p className={css.reviewAuthor}>{review.author}</p>}
              </h3>
            </div>
          </SwiperSlide>
        ))
      : category === CATEGORIES[3]
      ? data.map((text, index) => (
          <SwiperSlide className={css.swiperSlide} key={`swiperSlide_reviews_${index}`}>
            <div className={css.textSlideContainer}>{text}</div>
          </SwiperSlide>
        ))
      : category === CATEGORIES[4]
      ? data.images.map((img, index) => (
          <SwiperSlide
            className={classNames(css.swiperSlide, css.swiperSliderKek)}
            key={`swiperSlide_reviews_${index}`}
          >
            <img src={img} alt="review of Respaces" />
          </SwiperSlide>
        ))
      : category === CATEGORIES[5]
      ? data?.images?.map((img, index) => (
          <SwiperSlide
            className={classNames(css.swiperSlide, css.swiperSliderKek)}
            key={`location_image_${index}`}
          >
            <img src={img?.medium} className={css.image} alt="location image" />
          </SwiperSlide>
        ))
      : category === CATEGORIES[6]
      ? data?.images?.map((img, index) => (
          <SwiperSlide className={css.swiperSlide} key={`location_page_${index}`}>
            <img src={img?.medium} className={css.spacesImage} alt="location image" />
          </SwiperSlide>
        ))
      : null;

  return (
    <div className={classes}>
      <Swiper
        className={classNames(
          category === CATEGORIES[2] ? css['swiper-container-reviews'] : css['swiper-container']
        )}
        spaceBetween={0}
        slidesPerView={1}
        allowTouchMove={allowTouchMove}
        speed={500}
        loop
        direction={direction}
        initialSlide={0}
        autoplay={
          autoplay && {
            delay: autoplay.delay || 3000,
            disableOnInteraction: false,
          }
        }
        navigation={{
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        }}
        pagination={{
          el: '.swiper-pagination',
          type: 'bullets',
          bulletActiveClass: css['pagination-bullet-active'],
          bulletClass: css['pagination-bullet'],
          renderBullet: function (index, className) {
            return `<img width="16" height="10" class="${className}" alt="slider button" src=${bullet} />`;
          },
        }}
      >
        {content}
        {category !== 'text' && (
          <>
            <img
              width="40"
              height="40"
              className={classNames(
                'swiper-button-next',
                category === CATEGORIES[2] ? css.swiperButtonReviews : css.swiperButton
              )}
              alt="slider button"
              src={right}
            />
            <img
              width="40"
              height="40"
              className={classNames(
                'swiper-button-prev',
                category === CATEGORIES[2] ? css.swiperButtonReviews : css.swiperButton
              )}
              alt="slider button"
              src={left}
            />
          </>
        )}
        {category !== CATEGORIES[4] && category !== CATEGORIES[2] && (
          <div className={`swiper-pagination ${css.swiperPagination}`} />
        )}
        {category === CATEGORIES[2] && (
          <div
            className={classNames(
              `swiper-pagination ${css.swiperPaginationReviews}`,
              css.reviewPagination
            )}
          />
        )}
      </Swiper>
    </div>
  );
};

ImageSlider.defaultProps = {
  autoplay: false,
  category: CATEGORIES[0],
  className: null,
  data: {},
  direction: DIRECTIONS[0],
  showLocation: false,
  showTitle: false,
  allowTouchMove: true,
};
ImageSlider.propTypes = {
  autoplay: oneOfType([bool, object]),
  category: oneOf(CATEGORIES),
  className: string,
  data: oneOfType([array, object]),
  direction: oneOf(DIRECTIONS),
  showLocation: bool,
  showTitle: bool,
  allowTouchMove: bool,
};

export default ImageSlider;
