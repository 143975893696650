import React, { memo, useState } from 'react';
import { injectIntl } from 'react-intl';
import { func } from 'prop-types';

import ActionDropdown from './ActionDropdown';

export const LOCATION_TABLE_ACTION = {
  VIEW: {
    id: 'viewLocation',
    label: 'Table.action.viewLocation',
  },
  EDIT: {
    id: 'editLocation',
    label: 'Table.action.editLocation',
  },
};

LOCATION_TABLE_ACTION.LIST = [LOCATION_TABLE_ACTION.VIEW, LOCATION_TABLE_ACTION.EDIT];

LOCATION_TABLE_ACTION.ID_TO_DATA = {
  [LOCATION_TABLE_ACTION.VIEW.id]: LOCATION_TABLE_ACTION.VIEW,
  [LOCATION_TABLE_ACTION.EDIT.id]: LOCATION_TABLE_ACTION.EDIT,
};

const LocationTableAction = memo(({ id, onInitAction, intl }) => {
  const [isOpen, setOpen] = useState(false);

  const handleClick = (e, action) => {
    e.stopPropagation();
    !!onInitAction && onInitAction({ action, id });
    setOpen(false);
  };

  return (
    <ActionDropdown isOpen={isOpen} onOpen={() => setOpen(true)} onClose={() => setOpen(false)}>
      <>
        {LOCATION_TABLE_ACTION.LIST.map((action) => (
          <button
            key={`location_action_${action.id}_button`}
            onClick={(e) => handleClick(e, action.id)}
          >
            {intl.formatMessage({ id: action.label })}
          </button>
        ))}
      </>
    </ActionDropdown>
  );
});

LocationTableAction.propTypes = {
  onClick: func,
};

export default injectIntl(LocationTableAction);
