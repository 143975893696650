import { updateCurrentBookingId } from '../../../ducks/b2b.duck';
import { getTeamSchedule, updateTeamSchedule } from '../../../ducks/TeamSchedule.duck';

const mapDispatchToProps = (dispatch) => ({
  updateCurrentBookingId: (id) => dispatch(updateCurrentBookingId(id)),
  getTeamSchedule: (id) => dispatch(getTeamSchedule(id)),
  updateTeamSchedule: (payload) => dispatch(updateTeamSchedule(payload)),
});

const mapStateToProps = (state) => {
  const {
    b2b: { currentBookingId },
    TeamSchedule: { teamSchedule, teamScheduleLoading },
  } = state;

  const currentUserId = state.user?.currentUser?.id?.uuid || '';
  const { companyAccountId = '', isCompanyAccount = '' } =
    state.user?.currentUser?.attributes?.profile?.protectedData || {};

  return {
    isCompanyAccount,
    companyAccountId,
    currentUserId,
    teamSchedule,
    teamScheduleLoading,
    currentBookingId,
  };
};

export { mapDispatchToProps, mapStateToProps };
