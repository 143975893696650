import React from 'react';
import moment from 'moment';
import { injectIntl } from 'react-intl';

import { Avatar, BottomSheet } from '../../../index';
import { BUTTON_TYPES } from '../../../ButtonNew/ButtonNew';
import { createSlug } from '../../../../util/urlHelpers';

import { DAY, DAY_NAME, MONTH_SHORT } from '../../config/constants';

import css from './BookingInfoBottomSheet.module.scss';

const prepareTimes = (times) => {
  if (!times || !times.startTime || !times.endTime) {
    return { startTime: null, endTime: null };
  }

  if (typeof times.startTime === 'number' && typeof times.endTime === 'number') {
    return {
      startTime: moment(new Date(times.startTime)).format('HH.mm'),
      endTime: moment(new Date(times.endTime)).format('HH.mm'),
    };
  } else {
    return {
      startTime: times.startTime.split(':').join('.'),
      endTime: times.endTime.split(':').join('.'),
    };
  }
};

const BookingInfoBottomSheet = ({
  currentDate,
  searchParams,
  bookingData,
  isOpen,
  onClose,
  singleButtonLeft,
  showJoinSpaceButton,
  intl,
}) => {
  const { listingId, listingName, address, times, users, listingImgUrl, mainImage } =
    bookingData || {};

  const formattedTimes = prepareTimes(times);

  const goToListingPage = () => {
    const routeString = `/l/${createSlug(listingName)}/${listingId}?${searchParams}`;
    window.open(routeString, '_blank').focus();
  };

  const [currentDay, currentDateMonth, currentDateDay] = moment(currentDate)
    .format(`${DAY_NAME}, ${MONTH_SHORT}, ${DAY}`)
    .split(', ');

  return (
    <BottomSheet
      isOpen={isOpen}
      onClose={onClose}
      className={css.bottomSheet}
      buttonList={[
        {
          buttonType: BUTTON_TYPES.GREEN,
          text: intl.formatMessage({ id: 'TeamSchedule.bottomSheet.join' }),
          onClick: goToListingPage,
        },
      ]}
      singleButtonLeft={singleButtonLeft}
      showFooter={showJoinSpaceButton}
    >
      <>
        <img className={css.img} src={mainImage || listingImgUrl} alt="" />
        <div className={css.container}>
          <div className={css.listingInfo}>
            <h4 className={css.listingName}>{listingName}</h4>
            {address && <p className={css.address}>{address}</p>}
          </div>
          <div className={css.dateTime}>
            {currentDay && currentDateMonth && (
              <p className={css.date}>
                {intl.formatMessage({ id: `MarketplaceConfig.day.${currentDay}` })}, {''}
                {intl.formatMessage({
                  id: `MarketplaceConfig.month.short.${currentDateMonth}`,
                })}
                {currentDateDay}
              </p>
            )}
            {formattedTimes?.startTime && formattedTimes?.endTime && (
              <p className={css.times}>
                {formattedTimes?.startTime} - {formattedTimes?.endTime}
              </p>
            )}
          </div>
          <div className={css.teamInfo} data-body-scroll-lock-ignore="true">
            <div className={css.icon}>
              <svg
                width="22"
                height="14"
                viewBox="0 0 22 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M15 6C16.66 6 17.99 4.66 17.99 3C17.99 1.34 16.66 0 15 0C13.34 0 12 1.34 12 3C12 4.66 13.34 6 15 6ZM7 6C8.66 6 9.99 4.66 9.99 3C9.99 1.34 8.66 0 7 0C5.34 0 4 1.34 4 3C4 4.66 5.34 6 7 6ZM7 8C4.67 8 0 9.17 0 11.5V14H14V11.5C14 9.17 9.33 8 7 8ZM15 8C14.71 8 14.38 8.02 14.03 8.05C15.19 8.89 16 10.02 16 11.5V14H22V11.5C22 9.17 17.33 8 15 8Z"
                  fill="#D8D8D8"
                />
              </svg>
            </div>
            <div className={css.main}>
              <h6 className={css.teamMemberCount}>
                {Array.isArray(users) && users.length
                  ? intl.formatMessage(
                      { id: 'TeamSchedule.bottomSheet.teamMemberCount' },
                      { count: Array.isArray(users) ? users.length : 0 }
                    )
                  : intl.formatMessage({ id: 'TeamSchedule.bottomSheet.noTeamMembers' })}
              </h6>
              <ul className={css.teamMemberList}>
                {Array.isArray(users) &&
                  users.map((u, index) => (
                    <li
                      className={css.teamMember}
                      key={`bottom_sheet_avatar_${u.firstName}_${index}`}
                    >
                      <Avatar
                        abbreviatedName={`${u.firstName.charAt(0)}${u.lastName.charAt(0)}`}
                        url={u.avatarURL}
                        className={css.avatar}
                      />
                      <span>
                        {u.firstName} {u.lastName}
                      </span>
                    </li>
                  ))}
              </ul>
            </div>
          </div>
        </div>
      </>
    </BottomSheet>
  );
};

export default injectIntl(BookingInfoBottomSheet);
