import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { propTypes } from '../../util/types';
import { isScrollingDisabled } from '../../ducks/UI.duck';
import { PasswordChangeForm } from '../../forms';
import classNames from 'classnames';
import { isEmployeeUser, isCompanyUser } from '../../util/b2bHelper';

import { changePassword, changePasswordClear, resetPassword } from './PasswordChangeComponent.duck';
import css from './PasswordChangeComponent.module.scss';

export const PasswordChangeComponentComponent = (props) => {
  const {
    changePasswordError,
    changePasswordInProgress,
    currentUser,
    currentUserHasListings,
    onChange,
    onSubmitChangePassword,
    onResetPassword,
    resetPasswordInProgress,
    resetPasswordError,
    passwordChanged,
    scrollingDisabled,
    activeField,
    setActiveField,
    intl,
  } = props;

  const changePasswordForm =
    currentUser && currentUser.id ? (
      <PasswordChangeForm
        className={css.form}
        changePasswordError={changePasswordError}
        currentUser={currentUser}
        onSubmit={onSubmitChangePassword}
        onChange={onChange}
        onResetPassword={onResetPassword}
        resetPasswordInProgress={resetPasswordInProgress}
        resetPasswordError={resetPasswordError}
        inProgress={changePasswordInProgress}
        ready={passwordChanged}
      />
    ) : null;
  const isB2B = isEmployeeUser(currentUser) || isCompanyUser(currentUser);

  const title = intl.formatMessage({ id: 'PasswordChangeComponent.title' });

  return (
    <div className={classNames(activeField === 'password' ? css.isOpen : css.isClosed)}>
      <div
        className={css.sectionContainerCollapsible}
        onClick={() =>
          activeField === 'password' ? setActiveField(false) : setActiveField('password')
        }
      />
      <div className={css.contactDetailsSection}>
        <svg
          className={classNames(
            css.contactDetailsSectionIcon,
            activeField === 'password' && css.iconClose
          )}
          width="9"
          height="15"
          viewBox="0 0 9 15"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M1 14L7.5 7.5L1 1" stroke="#706F6F" />
        </svg>
        <h1 className={css.title}>
          <FormattedMessage id="PasswordChangeComponent.heading" />
        </h1>
        <p>••••••••••••</p>
        {changePasswordForm}
      </div>
    </div>
  );
};

PasswordChangeComponentComponent.defaultProps = {
  changePasswordError: null,
  currentUser: null,
  resetPasswordInProgress: false,
  resetPasswordError: null,
};

const { bool, func } = PropTypes;

PasswordChangeComponentComponent.propTypes = {
  changePasswordError: propTypes.error,
  changePasswordInProgress: bool.isRequired,
  currentUser: propTypes.currentUser,
  onChange: func.isRequired,
  onSubmitChangePassword: func.isRequired,
  passwordChanged: bool.isRequired,
  scrollingDisabled: bool.isRequired,
  resetPasswordInProgress: bool,
  resetPasswordError: propTypes.error,

  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = (state) => {
  // Topbar needs user info.
  const {
    changePasswordError,
    changePasswordInProgress,
    passwordChanged,
    resetPasswordInProgress,
    resetPasswordError,
  } = state.PasswordChangeComponent;
  const { currentUser, currentUserHasListings } = state.user;
  return {
    changePasswordError,
    changePasswordInProgress,
    currentUser,
    currentUserHasListings,
    passwordChanged,
    scrollingDisabled: isScrollingDisabled(state),
    resetPasswordInProgress,
    resetPasswordError,
  };
};

const mapDispatchToProps = (dispatch) => ({
  onChange: () => dispatch(changePasswordClear()),
  onSubmitChangePassword: (values) => dispatch(changePassword(values)),
  onResetPassword: (values) => dispatch(resetPassword(values)),
});

const PasswordChangeComponent = compose(
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl
)(PasswordChangeComponentComponent);

export default PasswordChangeComponent;
