import React from 'react';
import classNames from 'classnames';
import image1 from './assets/carl.png';
import image2 from './assets/joni.png';
import image3 from './assets/carl-johan.png';
import css from './PartnerSubletting.module.scss';

const SectionTesimonials = ({ intl }) => {
  return (
    <section className={classNames(css.sectionTesimonials)}>
      <div className={css.testimonial}>
        <img src={image1} alt="Carl" />
        <p>
          {intl.formatMessage({
            id: 'PartnerSubletting.SectionTestimonials.step1.text',
          })}
        </p>
        <h3 className={css.title}>
          {intl.formatMessage({
            id: 'PartnerSubletting.SectionTestimonials.step1.title',
          })}
        </h3>
      </div>
      <div className={css.testimonial}>
        <img src={image2} alt="Joni" />
        <p>
          {intl.formatMessage({
            id: 'PartnerSubletting.SectionTestimonials.step2.text',
          })}
        </p>
        <h3 className={css.title}>
          {intl.formatMessage({
            id: 'PartnerSubletting.SectionTestimonials.step2.title',
          })}
        </h3>
      </div>
      <div className={css.testimonial}>
        <img src={image3} alt="Carl-Johan" />
        <p>
          {intl.formatMessage({
            id: 'PartnerSubletting.SectionTestimonials.step3.text',
          })}
        </p>
        <h3 className={css.title}>
          {intl.formatMessage({
            id: 'PartnerSubletting.SectionTestimonials.step3.title',
          })}
        </h3>
      </div>
    </section>
  );
};

export default SectionTesimonials;
