import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import css from './IconDownload.module.scss';

const IconDownload = (props) => {
  const { className, rootClassName, ...rest } = props;
  const classes = classNames(rootClassName || css.root, className);

  return (
    <span className={css.iconDownload}>
      <svg
        className={classes}
        width="14"
        height="14"
        viewBox="0 0 14 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0.4375 8.6626C0.553532 8.6626 0.664812 8.70869 0.746859 8.79074C0.828906 8.87279 0.875 8.98407 0.875 9.1001V11.2876C0.875 11.5197 0.967187 11.7422 1.13128 11.9063C1.29538 12.0704 1.51794 12.1626 1.75 12.1626H12.25C12.4821 12.1626 12.7046 12.0704 12.8687 11.9063C13.0328 11.7422 13.125 11.5197 13.125 11.2876V9.1001C13.125 8.98407 13.1711 8.87279 13.2531 8.79074C13.3352 8.70869 13.4465 8.6626 13.5625 8.6626C13.6785 8.6626 13.7898 8.70869 13.8719 8.79074C13.9539 8.87279 14 8.98407 14 9.1001V11.2876C14 11.7517 13.8156 12.1968 13.4874 12.525C13.1592 12.8532 12.7141 13.0376 12.25 13.0376H1.75C1.28587 13.0376 0.840752 12.8532 0.512563 12.525C0.184374 12.1968 0 11.7517 0 11.2876V9.1001C0 8.98407 0.0460936 8.87279 0.128141 8.79074C0.210188 8.70869 0.321468 8.6626 0.4375 8.6626Z"
          fill="black"
        />
        <path
          d="M6.6908 10.3722C6.73144 10.413 6.77972 10.4453 6.83287 10.4674C6.88602 10.4894 6.94301 10.5008 7.00055 10.5008C7.0581 10.5008 7.11508 10.4894 7.16823 10.4674C7.22138 10.4453 7.26966 10.413 7.3103 10.3722L9.9353 7.74725C10.0175 7.6651 10.0636 7.55368 10.0636 7.4375C10.0636 7.32132 10.0175 7.2099 9.9353 7.12775C9.85315 7.0456 9.74173 6.99945 9.62555 6.99945C9.50937 6.99945 9.39795 7.0456 9.3158 7.12775L7.43805 9.00638V1.3125C7.43805 1.19647 7.39196 1.08519 7.30991 1.00314C7.22786 0.921094 7.11658 0.875 7.00055 0.875C6.88452 0.875 6.77324 0.921094 6.69119 1.00314C6.60915 1.08519 6.56305 1.19647 6.56305 1.3125V9.00638L4.6853 7.12775C4.60315 7.0456 4.49173 6.99945 4.37555 6.99945C4.25937 6.99945 4.14795 7.0456 4.0658 7.12775C3.98365 7.2099 3.9375 7.32132 3.9375 7.4375C3.9375 7.55368 3.98365 7.6651 4.0658 7.74725L6.6908 10.3722Z"
          fill="black"
        />
      </svg>
    </span>
  );
};

const { string } = PropTypes;

IconDownload.defaultProps = {
  className: null,
  rootClassName: null,
};

IconDownload.propTypes = {
  className: string,
  rootClassName: string,
};

export default IconDownload;
