import React, { useState } from 'react';
import { Field, Form as FinalForm } from 'react-final-form';
import {
  Button,
  FieldTextInput,
  Form,
  SubscriptionManagementIcons as Icons,
  IconSpinner,
} from '../../components';
import css from './CompanySettingsForm.module.scss';
import './CompanySettingsForm.module.scss';
import classNames from 'classnames';
import { CSSTransition } from 'react-transition-group';
import get from 'lodash/get';
import { isUploadImageOverLimitError } from '../../util/errors';
import { FormattedMessage } from 'react-intl';

const ACCEPT_IMAGES = 'image/*';

const CompanySettingsForm = (props) => {
  const { onSubmit } = props;
  const [showCompanyNameField, setShowCompanyNameField] = useState(false);

  const handleToggleShowCompanyNameField = () => {
    setShowCompanyNameField(!showCompanyNameField);
  };
  return (
    <FinalForm
      {...props}
      onSubmit={onSubmit}
      setShowCompanyNameField={setShowCompanyNameField}
      render={(formRenderProps) => {
        const {
          handleSubmit,
          intl,
          currentUser,
          onCompanyLogoUploadImage,
          uploadCompanyLogoInProgress,
          uploadCompanyLogoError,
          updateCompanyNameInProgress,
          form,
          logoImageUrl,
          setShowCompanyNameField,
        } = formRenderProps;

        const { organization } = get(currentUser, 'attributes.profile.publicData', {});
        const handleDiscardButton = () => {
          setShowCompanyNameField(!showCompanyNameField);
          form.change('companyName', '');
        };

        const uploadLogoDisabled = uploadCompanyLogoInProgress;
        return (
          <Form onSubmit={handleSubmit}>
            <div className={css.companyLogoFieldWrapper}>
              <div className={css.logoWrapper}>
                {logoImageUrl ? (
                  <img
                    className={css.logo}
                    src={logoImageUrl}
                    alt={intl.formatMessage({ id: 'CompanySettingForm.logo' })}
                  />
                ) : (
                  <div className={css.logoImageTemporary}>
                    {intl.formatMessage({ id: 'CompanySettingForm.logo' })}
                  </div>
                )}
              </div>
              <div className={css.logoTitle}>
                {intl.formatMessage({ id: 'CompanySettingForm.logo' })}
              </div>
              <div className={css.row}>
                <p className={css.logoContent}>
                  {intl.formatMessage({ id: 'CompanySettingForm.logoContent' })}
                </p>
                <Field
                  id="companyLogo"
                  name="companyLogo"
                  type="file"
                  disabled={uploadLogoDisabled}
                  accept={ACCEPT_IMAGES}
                  uploadCompanyLogoError={uploadCompanyLogoError}
                  form={null}
                >
                  {(fieldProps) => {
                    const { id, input, disabled, uploadCompanyLogoError, accept } = fieldProps;
                    const { name, type } = input;
                    const onChange = (e) => {
                      const file = e.target.files[0];
                      form.blur('companyLogo');
                      if (file) {
                        const tempId = `${file.name}_${Date.now()}`;
                        onCompanyLogoUploadImage({ id: tempId, file });
                      }
                    };
                    let uploadError;
                    if (isUploadImageOverLimitError(uploadCompanyLogoError)) {
                      uploadError = (
                        <div className={css.error}>
                          <FormattedMessage id="ProfileSettingsForm.imageUploadFailedFileTooLarge" />
                        </div>
                      );
                    } else if (uploadCompanyLogoError) {
                      uploadError = (
                        <div className={css.error}>
                          <FormattedMessage id="ProfileSettingsForm.imageUploadFailed" />
                        </div>
                      );
                    }

                    return (
                      <div className={css.uploadInputWrapper}>
                        {disabled ? (
                          <div className={css.pageLoading}>
                            <IconSpinner />
                          </div>
                        ) : (
                          <label htmlFor={id} className={css.uploadInputLabel}>
                            {intl.formatMessage({ id: 'CompanySettingForm.uploadLogo' })}
                          </label>
                        )}

                        <input
                          id={id}
                          name={name}
                          type={type}
                          className={css.uploadInput}
                          onChange={onChange}
                          disabled={disabled}
                          accept={accept}
                        />
                        {uploadError}
                      </div>
                    );
                  }}
                </Field>
              </div>
            </div>
            <div className={css.companyNameFieldWrapper}>
              <div className={css.companyNameTitle}>
                {intl.formatMessage({ id: 'CompanySettingForm.companyName' })}
              </div>
              <div className={css.companyNameContent} onClick={handleToggleShowCompanyNameField}>
                <div className={css.companyName}>{organization}</div>
                <Icons.CheronRightIcon
                  className={classNames(css.cheronRight, {
                    [css.rotale]: !showCompanyNameField,
                  })}
                />
              </div>
              <CSSTransition
                in={showCompanyNameField}
                classNames="company-name-field"
                unmountOnExit
                timeout={300}
              >
                <div>
                  <FieldTextInput
                    id="companyName"
                    name="companyName"
                    type="text"
                    placeholder={organization}
                    label={intl.formatMessage({ id: 'CompanySettingForm.companyNameFieldLabel' })}
                    className={css.companyNameField}
                  />

                  <div className={css.submitButtonWrapper}>
                    <Button
                      className={css.discardButton}
                      type="button"
                      onClick={handleDiscardButton}
                    >
                      {intl.formatMessage({ id: 'CompanySettingForm.discardButton' })}
                    </Button>
                    <Button
                      className={css.submitButton}
                      type="submit"
                      inProgress={updateCompanyNameInProgress}
                      disabled={updateCompanyNameInProgress}
                    >
                      {intl.formatMessage({ id: 'CompanySettingForm.submitButton' })}
                    </Button>
                  </div>
                </div>
              </CSSTransition>
            </div>
          </Form>
        );
      }}
    />
  );
};

export default CompanySettingsForm;
