import React from 'react';
import { Modal } from '..';
import { EditQuoteInformationForm } from '../../forms';
import css from './EditQuoteInformationModal.module.scss';

const EditQuoteInformationModal = (props) => {
  const { intl, isOpen, onClose, onSubmit, initialValues, showMinCreditsError } = props;

  return (
    <Modal
      id="EditQuoteInformationModal"
      isOpen={isOpen}
      onClose={onClose}
      className={css.modal}
      containerClassName={css.modalContainer}
      hideCloseButton
    >
      <div className={css.closeButton} onClick={onClose}>
        <svg
          width="19"
          height="19"
          viewBox="0 0 19 19"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M1 1L18 18" stroke="black" />
          <path d="M18 1L1 18" stroke="black" />
        </svg>
      </div>
      <EditQuoteInformationForm
        intl={intl}
        initialValues={initialValues}
        onSubmit={onSubmit}
        onClose={onClose}
        showMinCreditsError={showMinCreditsError}
      />
    </Modal>
  );
};

export default EditQuoteInformationModal;
