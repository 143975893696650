import React, { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import { string } from 'prop-types';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { debounce } from 'lodash';

import { BottomSheetWithSteps } from '../../components_new';

import config from '../../config';
import { inBrowser, isNewestSafari, touchDeviceMatch } from '../../util/device';
import { HubspotController } from '../../util/hubspot';
import { FIXED_OFFICE_CATEGORY, MEETINGROOMS_CATEGORY, COWORKING_CATEGORY } from '../../util/types';
import {
  updateSearchCategories,
  updateSearchLocation,
  updateSearchDates,
  updateSearchTimes,
  updatePredictions,
} from '../../containers/SearchPage/SearchPage.duck';
import { updateFilters } from '../../ducks/SearchPageNew.duck';
import { useSearch } from '../../containers/SearchPageNew/lib/useSearch';

import { searchListings } from './api';
import { LISTING_CATEGORIES } from './config';
import {
  CategoryCombobox,
  DateCombobox,
  LocationCombobox,
  MobileFormTrigger,
  SearchButton,
  SeatsComboBox,
  Separator,
} from './ui';

import css from './ListingSearchForm.module.scss';
import { defaultLocationBounds } from '../../default-location-searches';

const PAGES = {
  LANDING: 'LandingPage',
  SEARCH: 'SearchPage',
};
const CATEGORIES_TO_SHOW_SEATS = [FIXED_OFFICE_CATEGORY, MEETINGROOMS_CATEGORY];

const ListingSearchForm = ({ className, isSeoPage = false }) => {
  const intl = useIntl();
  const history = useHistory();
  const dispatch = useDispatch();
  const {
    currentCategory,
    currentLocation,
    currentDates,
    currentTimes,
    currentSeats,
    maxSeats,
    minSeats,
    currentHost,
    listingArray,
    currentPredictions,
    ameneties,
    contractType,
    offer,
    price,
    contractLengthPerUnit,
    allowVatExemptCompanies,
  } = useSelector((state) => {
    const {
      SearchPage: { predictions: currentPredictions },
      SearchPageNew: {
        filters: {
          address,
          bounds,
          category,
          origin,
          startDate,
          endDate,
          openTime,
          closeTime,
          minSeats,
          maxSeats,
          ameneties,
          contractType,
          host,
          offer,
          price,
          contractLengthPerUnit,
          allowVatExemptCompanies,
        },
      },
      marketplaceData: { entities: listingArray },
    } = state;

    return {
      currentCategory: category,
      currentLocation: {
        search: address,
        selectedPlace: {
          address,
          bounds,
          origin,
        },
      },
      currentDates: {
        startDate,
        endDate,
      },
      currentTimes: {
        openTime,
        closeTime,
      },
      currentSeats: !!minSeats && !!maxSeats ? `${minSeats},${maxSeats}` : null,
      maxSeats,
      minSeats,
      currentHost: host,
      listingArray,
      currentPredictions,
      ameneties,
      contractType,
      offer,
      price,
      contractLengthPerUnit,
      allowVatExemptCompanies,
    };
  });

  const { clearSecondaryFilters } = useSearch();

  const showBottomSheet = !isNewestSafari();
  const locationInputRef = useRef(null);
  const categoryInputRef = useRef(null);
  const dateInputRef = useRef(null);
  const seatsInputRef = useRef(null);
  const buttonRef = useRef(null);

  const [isLocationComboboxOpen, toggleLocationComboboxOpen] = useState(false);
  const [isCategoryComboboxOpen, toggleCategoryComboboxOpen] = useState(false);
  const [isDateComboboxOpen, toggleDateComboboxOpen] = useState(false);
  const [isSeatsComboboxOpen, toggleSeatsComboboxOpen] = useState(false);
  const [isMobileFormOpen, setMobileFormOpen] = useState(false);
  const [currentStep, updateStep] = useState(0);
  const [currentPage, setCurrentPage] = useState(null);
  const [showSeatsComboBox, setShowSeatsComboBox] = useState(
    CATEGORIES_TO_SHOW_SEATS.includes(currentCategory)
  );
  const [showDatesComboBox, setShowDatesComboBox] = useState(
    !CATEGORIES_TO_SHOW_SEATS.includes(currentCategory)
  );
  const hubspotController = new HubspotController();

  useEffect(() => {
    if (inBrowser()) {
      setCurrentPage(window.location.pathname === '/s' ? PAGES.SEARCH : PAGES.LANDING);
    }
  }, []);

  useEffect(() => {
    if (currentPage === PAGES.LANDING && !isSeoPage) {
      if (!!currentCategory && showSeatsComboBox && seatsInputRef.current) {
        seatsInputRef.current.focus();
      } else if (!!currentCategory && showDatesComboBox && dateInputRef.current) {
        dateInputRef.current.focus();
      }
    }
  }, [
    showSeatsComboBox,
    seatsInputRef,
    showDatesComboBox,
    dateInputRef,
    currentCategory,
    currentPage,
  ]);
  useEffect(() => {
    if (CATEGORIES_TO_SHOW_SEATS.includes(currentCategory)) {
      setShowSeatsComboBox(true);
      setShowDatesComboBox(false);
    } else {
      setShowSeatsComboBox(false);
      setShowDatesComboBox(true);
    }
  }, [currentCategory]);

  const initiateSearch = (e) => {
    e.preventDefault();
    hubspotController.show();
    searchListings({
      history,
      currentCategory,
      currentLocation,
      currentDates,
      currentTimes,
      currentSeats,
      maxSeats,
      minSeats,
      currentHost,
      ameneties,
      contractType,
      offer,
      price,
      contractLengthPerUnit,
      allowVatExemptCompanies,
    });
  };

  const handleSeatsChange = (minSeats) => {
    const maxSeats =
      /* TODO: Update with filters.category */
      currentCategory === COWORKING_CATEGORY
        ? 1000
        : minSeats < 20
        ? minSeats + 5
        : minSeats < 50
        ? minSeats + 10
        : Math.floor(minSeats * 1.2) + 20;

    dispatch(updateFilters({ minSeats, maxSeats }));
  };

  const updateSearchSeatsDebounced = debounce(handleSeatsChange, 500, {
    leading: false,
    trailing: true,
  });

  const formSteps = [
    {
      component: (
        <LocationCombobox
          isOpen={true}
          onChange={({ payload, closeModal }) => {
            dispatch(
              updateFilters({
                ...(!!payload.search
                  ? { ...payload.selectedPlace }
                  : { address: null, bounds: defaultLocationBounds, origin: null }),
              })
            );
            closeModal && updateStep(currentStep + 1);
          }}
          currentLocation={currentLocation}
          listingArray={listingArray}
          history={history}
          intl={intl}
          inputRef={locationInputRef}
          updatePredictions={(predictions) => dispatch(updatePredictions(predictions))}
          currentPredictions={currentPredictions}
          isMobile={true}
        />
      ),
      title: intl.formatMessage({ id: 'ListingSearchForm.locationSearchInput.title' }),
    },
    {
      component: (
        <CategoryCombobox
          id={'category'}
          name="categoryCombobox"
          className={classNames(css.categoryCombobox, css.mobile)}
          focusedClassName={css.categoryComboboxFocused}
          dropdownClassName={css.categoryComboboxDropdown}
          categories={LISTING_CATEGORIES.LIST}
          currentCategory={currentCategory}
          isOpen={true}
          onChange={(val) => {
            clearSecondaryFilters();
            dispatch(updateSearchCategories(val));
            dispatch(updateFilters({ category: val }));
            updateStep(currentStep + 1);
          }}
          isMobile={true}
        />
      ),
      title: intl.formatMessage({ id: 'ListingSearchForm.categoryInput.title' }),
    },
    {
      component: CATEGORIES_TO_SHOW_SEATS.includes(currentCategory) ? (
        <SeatsComboBox
          id="seats"
          name="seatsCombobox"
          isOpen={true}
          intl={intl}
          isMobile={true}
          currentSeats={minSeats}
          className={css.seatsCombobox}
          onSeatsChange={(val) => {
            updateSearchSeatsDebounced(+val);
          }}
        />
      ) : (
        <DateCombobox
          isOpen={true}
          onDatesChange={(dates) => {
            dispatch(updateFilters({ startDate: dates.startDate, endDate: dates.endDate }));
            dispatch(updateSearchDates(dates));
          }}
          onTimesChange={(times) => {
            dispatch(updateFilters({ openTime: times.startTime, closeTime: times.endTime }));
            dispatch(updateSearchTimes(times));
          }}
          currentDates={currentDates}
          currentTimes={currentTimes}
          timeOptions={config.custom.HOUR_OPTIONS}
          showTimepickers={[LISTING_CATEGORIES.MEETING.id, LISTING_CATEGORIES.STUDIO.id].includes(
            currentCategory
          )}
          intl={intl}
          isMobile={true}
        />
      ),
      title: intl.formatMessage({ id: 'ListingSearchForm.dateInput.title' }),
    },
  ];

  return (
    <>
      <div className={css.mobileFormWrapper}>
        <MobileFormTrigger
          onClick={() => setMobileFormOpen(true)}
          currentCategory={currentCategory}
          currentLocation={currentLocation}
          currentDates={currentDates}
        />
        <BottomSheetWithSteps
          intl={intl}
          isOpen={isMobileFormOpen}
          onClose={() => setMobileFormOpen(false)}
          currentStep={currentStep}
          updateStep={updateStep}
          steps={formSteps}
          onFormSubmit={(values) => {
            updateStep(0);
            setMobileFormOpen(false);
            initiateSearch(values);
          }}
          showBottomSheet={showBottomSheet}
        />
      </div>
      <form className={classNames(css.desktopSearchForm, className)} onSubmit={initiateSearch}>
        <LocationCombobox
          isOpen={isLocationComboboxOpen}
          onBlur={() => toggleLocationComboboxOpen(false)}
          onFocus={() => toggleLocationComboboxOpen(true)}
          onChange={({ payload, closeModal }) => {
            dispatch(updateSearchLocation(payload));
            dispatch(updateFilters({ ...payload.selectedPlace }));
            if (closeModal) {
              locationInputRef?.current?.blur();
              toggleLocationComboboxOpen(false);
            }

            if (!currentCategory && closeModal) {
              categoryInputRef?.current?.focus();
            }
          }}
          currentLocation={currentLocation}
          updatePredictions={(predictions) => dispatch(updatePredictions(predictions))}
          listingArray={listingArray}
          inputRef={locationInputRef}
          history={history}
          intl={intl}
          isMobile={false}
        />
        <Separator isHidden={isLocationComboboxOpen || isCategoryComboboxOpen} />
        <CategoryCombobox
          id={'category'}
          name="categoryCombobox"
          className={css.categoryCombobox}
          focusedClassName={css.categoryComboboxFocused}
          dropdownClassName={css.categoryComboboxDropdown}
          categories={LISTING_CATEGORIES.LIST}
          currentCategory={currentCategory}
          isOpen={isCategoryComboboxOpen}
          onChange={(val) => {
            clearSecondaryFilters();
            dispatch(updateSearchCategories(val));
            dispatch(updateFilters({ category: val }));
            categoryInputRef?.current?.blur();

            if (
              !(currentDates?.min || currentDates?.max) &&
              !CATEGORIES_TO_SHOW_SEATS.includes(val)
            ) {
              setShowSeatsComboBox(false);
              setShowDatesComboBox(true);
            } else if (CATEGORIES_TO_SHOW_SEATS.includes(val)) {
              setShowDatesComboBox(false);
              setShowSeatsComboBox(true);
            }
          }}
          onFocus={() => toggleCategoryComboboxOpen(true)}
          onBlur={() => toggleCategoryComboboxOpen(false)}
          inputRef={categoryInputRef}
          isMobile={false}
        />

        <Separator isHidden={isCategoryComboboxOpen} />

        {showSeatsComboBox && !showDatesComboBox && (
          <SeatsComboBox
            className={css.seatsCombobox}
            id="seats"
            name="seatsComboBox"
            isOpen={isSeatsComboboxOpen}
            onOpen={() => {
              toggleSeatsComboboxOpen(true);
            }}
            onClose={() => {
              toggleSeatsComboboxOpen(false);
              seatsInputRef?.current?.blur();
              buttonRef?.current?.focus();
            }}
            intl={intl}
            inputRef={seatsInputRef}
            currentSeats={minSeats}
            focusedClassName={css.seatsComboboxFocused}
            dropdownClassName={css.seatsComboboxDropdown}
            onSeatsChange={(val) => {
              updateSearchSeatsDebounced(parseInt(val));
            }}
          />
        )}
        {showDatesComboBox && !showSeatsComboBox && (
          <DateCombobox
            isOpen={isDateComboboxOpen}
            onOpen={() => {
              toggleDateComboboxOpen(true);
            }}
            onClose={() => {
              toggleDateComboboxOpen(false);
              buttonRef?.current?.focus();
            }}
            onDatesChange={(dates) => {
              dispatch(updateFilters({ startDate: dates.startDate, endDate: dates.endDate }));
              // dispatch(updateSearchDates(dates));
              dateInputRef?.current?.blur();
              buttonRef?.current?.focus();
            }}
            onTimesChange={(times) => {
              dispatch(updateFilters({ openTime: times.startTime, closeTime: times.endTime }));
              // dispatch(updateSearchTimes(times));
              if (!!times?.startTime && !!times.endTime) {
                dateInputRef?.current?.blur();
                toggleDateComboboxOpen(false);
                currentPage === PAGES.LANDING && buttonRef?.current?.focus();
              }
            }}
            currentDates={currentDates}
            currentTimes={currentTimes}
            timeOptions={config.custom.HOUR_OPTIONS}
            showTimepickers={[LISTING_CATEGORIES.MEETING.id, LISTING_CATEGORIES.STUDIO.id].includes(
              currentCategory
            )}
            inputRef={dateInputRef}
            intl={intl}
          />
        )}
        <SearchButton buttonRef={buttonRef} />
      </form>
    </>
  );
};

ListingSearchForm.defaultProps = {
  className: null,
};

ListingSearchForm.propTypes = {
  className: string,
};

export default ListingSearchForm;
