import React from 'react';
import { Button, FieldNumberFixedPlaceholder, FieldSelect, Form } from '../../components';
import { Form as FinalForm } from 'react-final-form';
import * as validators from '../../util/validators';
import { FormattedMessage } from '../../util/reactIntl';

import css from './EditQuoteInformationForm.module.scss';

const EditQuoteInformationForm = (props) => {
  const { onSubmit, intl, onClose, initialValues, showMinCreditsError } = props;

  return (
    <FinalForm
      onSubmit={onSubmit}
      initialValues={initialValues}
      render={(fieldRenderProps) => {
        const { handleSubmit, values } = fieldRenderProps;

        const creditsPlaceholder = intl.formatMessage({
          id: 'EditQuoteInformationModal.creditsPlaceholder',
        });

        const creditsLabel = intl.formatMessage({
          id: 'EditQuoteInformationModal.creditsLabel',
        });

        const creditsRequiredMessage = intl.formatMessage({
          id: 'EditQuoteInformationModal.creditsRequiredMessage',
        });

        const creditLessThanEmployeeNumberMessage = intl.formatMessage({
          id: 'EditQuoteInformationModal.creditLessThanEmployeeNumber',
        });

        const minCreditsErrorMessage = intl.formatMessage({
          id: 'EditQuoteInformationModal.minCreditsErrorMessage',
        });

        const totalCreditsNotLessThanEmployeeNumber = (message) => (value) => {
          const parsedIntValue = parseInt(value);
          const parsedIntEmployeeNumberValue = values && parseInt(values.employeeNumber);
          return parsedIntValue < parsedIntEmployeeNumberValue ? message : undefined;
        };
        const creditsRequired = validators.composeValidators(
          validators.required(creditsRequiredMessage),
          totalCreditsNotLessThanEmployeeNumber(creditLessThanEmployeeNumberMessage)
        );

        return (
          <Form onSubmit={handleSubmit}>
            <div className={css.formContainer}>
              <div className={css.formTitle}>
                <FormattedMessage id="EditQuoteInformationModal.formTitle" />
              </div>
              <div className={css.fieldsWrapper}>
                <FieldNumberFixedPlaceholder
                  id="totalCredits"
                  name="totalCredits"
                  label={creditsLabel}
                  placeholder={creditsPlaceholder}
                  inputPlaceholder="1"
                  inputWrapperClassname={css.rootInput}
                  className={css.rootField}
                  isTypingInput
                  validate={creditsRequired}
                />
                {showMinCreditsError && <p style={{ color: 'red' }}>{minCreditsErrorMessage}</p>}
              </div>
              <div className={css.modalButtonWrapper}>
                <div className={css.cancelBtnWrapper}>
                  <span onClick={onClose}>
                    <FormattedMessage id="EditQuoteInformationModal.cancelButton" />
                  </span>
                  &nbsp;{intl.formatMessage({ id: 'EditQuoteInformationModal.or' })}
                </div>
                <Button type="submit" className={css.submitButton}>
                  <FormattedMessage id="EditQuoteInformationModal.submitButton" />
                </Button>
                <div className={css.note}>
                  <FormattedMessage id="EditQuoteInformationModal.noteText" />
                </div>
              </div>
            </div>
          </Form>
        );
      }}
    />
  );
};

export default EditQuoteInformationForm;
