import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { getLocale } from '../../util/localeHelper';
import { LANGUAGE_CODES } from '../../util/modernmt';

import css from './CookiePolicy.module.scss';

const CookiePolicy = (props) => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);

  if (getLocale() === LANGUAGE_CODES.SWEDISH) {
    return (
      <div className={classes}>
        <p className={css.lastUpdated}>Senast uppdaterad: 16 september 2021</p>
        <h2>Varför och för vem?</h2>
        <p>
          Respaces AB org. nr 559258-0160 ("Respaces", "vi", "oss", "vår") använder cookies och
          andra spårningstekniker ("Cookies") för att förbättra din användarupplevelse.
        </p>
        <p>
          Denna Cookie Policy ("Policyn") beskriver Respacess användning av Cookies genom att
          förklara vad Cookies är, hur vi använder oss av dom och hur du kan styra användningen av
          Cookies när du använder vår tjänst - Respaces - och/eller vår hemsida www.respaces.co
          (gemensamt kallade "Webbplatsen"). Policyn informerar inte om användningen av Cookies som
          är avgörande för att vi ska kunna tillhandahålla Webbplatsen och dess funktioner till dig
          (s.k. absolut nödvändiga cookies)
        </p>
        <p>
          För mer information om hur vi behandlar dina personuppgifter i samband med Cookies, se vår
          <a href="/privacy-policy">Integritetspolicy</a>.
        </p>
        <h2>Vad är Cookies?</h2>
        <p>
          En Cookie är en textfil som skickas från Webbplatsen och lagras på din dator, mobiltelefon
          eller annan enhet som används för att besöka Webbplatsen.
        </p>
        <p>
          Cookies hjälper oss t.ex. att känna igen den enhet du använder till nästa gång du besöker
          Webbplatsen, tillhandahålla vissa nödvändiga funktioner och att dokumentera
          användarbeteenden på Webbplatsen.{' '}
        </p>
        <h2>Hur länge lagras Cookien?</h2>
        <p>Det finns två olika typer av Cookies; Sessioncookies och permanenta cookies.</p>
        <p>
          <strong>Sessioncookies </strong>raderas från din enhet när du stänger ner din webbläsare
          (t.ex. Internet explorer, Safari eller Google Chrome).
        </p>
        <p>
          <strong>Permanenta cookies </strong> finns kvar på din enhet efter att du har stängt
          webbläsaren. Den varar så länge som det är angett i Cookien eller tills du själv tar bort
          den.
        </p>
        <h2>Respaces användning av Cookies</h2>
        <p>Vi använder följande typer av Cookies:</p>
        <p>
          <strong>Funktion</strong>
          <br />
          Cookien hjälper oss att komma ihåg dina val (t.ex. användarnamn, språk osv.) och kan
          berätta för oss hur du har integrerat med Webbplatsen för att göra tjänsten mer personlig.
        </p>
        <p>
          <strong>Prestanda</strong>
          <br />
          Cookien analyserar hur besökare använder Webbplatsen och övervakar Webbplatsens prestanda.
          Detta hjälper oss att identifiera och åtgärda eventuella problem. Vi kan t.ex. använda
          prestandacookies för att hålla reda på vilka sidor som är mest populära eller hur lång tid
          det tar för en sida att ladda.
        </p>
        <p>
          <strong>Drift och optimering</strong>
          <br />
          Cookien hjälper oss att se till att Webbplatsen funkar som den ska. De ger oss också
          information så vi kan testa nya ideér och förbättra Webbplatsen.
        </p>
        <p>
          <strong>Marknadsföring</strong>
          <br />
          Cookien hjälper oss att vara mer relevanta i vår kommunikation med dig och förbättra din
          upplevelse. Detta kan vi göra genom att samla in information om hur du interagerar med
          Webbplatsen.
        </p>

        <h2>Förstapartscookies</h2>
        <p>
          Förstapartscookies ställs in av Webbplatsen på din enhet och kan därför endast läsas utav
          dig eller oss.
        </p>

        <ul>
          <li>
            <ul>
              <li>
                <strong>Namn: </strong>_gid
              </li>
              <li>
                <strong>Typ: </strong>Optimering
              </li>
              <li>
                <strong>Syfte: </strong>Spara information om hur webbplatsen används.
              </li>
              <li>
                <strong>Varaktighet: </strong>1 dag
              </li>
            </ul>
          </li>
          <li>
            <br />
            <ul>
              <li>
                <strong>Namn: </strong>__stripe_mid
              </li>
              <li>
                <strong>Typ: </strong>Funktion
              </li>
              <li>
                <strong>Syfte: </strong>För att undvika betalningsbedrägeri
              </li>
              <li>
                <strong>Varaktighet: </strong>1 år
              </li>
            </ul>
          </li>
          <li>
            <br />
            <ul>
              <li>
                <strong>Namn: </strong>__stripe_sid
              </li>
              <li>
                <strong>Typ: </strong>Funktion
              </li>
              <li>
                <strong>Syfte: </strong>För att undvika betalningsbedrägeri
              </li>
              <li>
                <strong>Varaktighet: </strong>30 minuter
              </li>
            </ul>
          </li>
          <li>
            <br />
            <ul>
              <li>
                <strong>Namn: </strong>_hjMinimizedPolls
              </li>
              <li>
                <strong>Typ: </strong>Optimering
              </li>
              <li>
                <strong>Syfte: </strong>Mäta hur hemsidan används för att kunna förbättra
                upplevelsen.
              </li>
              <li>
                <strong>Varaktighet: </strong>30 minuter
              </li>
            </ul>
          </li>
          <li>
            <br />
            <ul>
              <li>
                <strong>Namn: </strong>_hjMinimizedPolls
              </li>
              <li>
                <strong>Typ: </strong>Funktion
              </li>
              <li>
                <strong>Syfte: </strong>Spara information om användarens val på webplatsen
              </li>
              <li>
                <strong>Varaktighet: </strong>1 år
              </li>
            </ul>
          </li>
          <li>
            <br />
            <ul>
              <li>
                <strong>Namn: </strong>euCookiesAccepted
              </li>
              <li>
                <strong>Typ: </strong>Funktion
              </li>
              <li>
                <strong>Syfte: </strong>Spara information om användarens val på webplatsen
              </li>
              <li>
                <strong>Varaktighet: </strong>10 år
              </li>
            </ul>
          </li>
          <li>
            <br />
            <ul>
              <li>
                <strong>Namn: </strong>_fbp
              </li>
              <li>
                <strong>Typ: </strong>Marknadsföring
              </li>
              <li>
                <strong>Syfte: </strong>Mäta användarens aktivitet på webbplatsen
              </li>
              <li>
                <strong>Varaktighet: </strong>3 månader
              </li>
            </ul>
          </li>
          <li>
            <br />
            <ul>
              <li>
                <strong>Namn: </strong>_ga
              </li>
              <li>
                <strong>Typ: </strong>Optimering
              </li>
              <li>
                <strong>Syfte: </strong>Identifiera unika användare av webplatsen
              </li>
              <li>
                <strong>Varaktighet: </strong>2 år
              </li>
            </ul>
          </li>
          <li>
            <br />
            <ul>
              <li>
                <strong>Namn: </strong>_hjid
              </li>
              <li>
                <strong>Typ: </strong>Optimering
              </li>
              <li>
                <strong>Syfte: </strong>Spara en unik ID för användaren
              </li>
              <li>
                <strong>Varaktighet: </strong>1 år
              </li>
            </ul>
          </li>
        </ul>
        <h2>Hantera cookies</h2>
        <p>
          När du besöker Webbplatsen för första gången ber vi dig att samtycka till användningen av
          icke-nödvändiga cookies. Du kan när som helst dra tillbaka ditt samtycke. Om du inte
          längre vill lagra de Cookies du har samtyckt till kan du använda dina
          webbläsarinställningar för att godkänna, neka och ta bort Cookies (finns vanligtvis under
          "Hjälp", "Verktyg" eller "Redigera"). Notera att du kanske inte kan använda alla
          funktioner på Webbplatsen om du väljer att neka Cookies.
        </p>

        <h2>Ändringar i den här policyn</h2>
        <p>
          Vi reserverar oss rätten att göra ändringar i denna Policy. I de fall ändringen påverkar
          våra skyldigheter eller dina rättigheter, kommer vi att informera om ändringarna i förväg
          så att du ges möjlighet att ta ställning till den uppdaterade policyn.
        </p>
        <h2>Kontakt</h2>
        <p>
          Hör av dig till oss om du har frågor om dina rättigheter eller om du har några andra
          frågor om hur vi behandlar dina personuppgifter:{' '}
          <a href="mailto:hello@respaces.co">hello@respaces.co </a>
        </p>
      </div>
    );
  } else {
    return (
      <div className={classes}>
        <p className={css.lastUpdated}>Last updated: 2021-09-16</p>
        <h2>Why and who?</h2>
        <p>
          Respaces AB Reg. No. 559258-0160 ("Respaces", "we", "us", "our") is using cookies and
          other tracking technologies ("Cookies") in order to improve your user experience.
        </p>
        <p>
          This Cookie Policy ("the Policy") describes Respaces’s usage of Cookies by explaining what
          Cookies are, why we use them and how you can control the usage of Cookies when you use our
          service - Respaces – and/or our Webpage www.respaces.co (commonly referred to as
          "Webpage"). The Policy does not inform about the usage of Cookies that are essential in
          order to provide the Webpage and its services to you (so-called strictly necessary
          cookies).
        </p>
        <p>
          For more information about how we process your personal data in relation to Cookies, see
          our
          <a href="/privacy-policy">Privacy Policy</a>.
        </p>
        <h2>What are Cookies?</h2>
        <p>
          A Cookie is a text file that is sent from the Webpage and stored on your computer, cell
          phone or any other device that you use to visit the Webpage.
        </p>
        <p>
          Cookies allow us to e.g. recognise the device you use next time you visit the Webpage,
          provide certain essential functions and to monitor user behavior on the Webpage.
        </p>
        <h2>How long is the Cookie stored?</h2>
        <p>There are two types of Cookies; Session cookies and Permanent cookies. </p>
        <p>
          <strong>Session cookies </strong>are deleted when you close the browser (e.g. Internet
          explorer, Safari or Google Chrome).
        </p>
        <p>
          <strong>Permanent cookies </strong> remains on your device after you have closed the
          browser. The Cookie will be stored for as long as it is indicated in the Cookie or until
          you remove it.
        </p>
        <h2>Respaces’s usage of Cookies</h2>
        <p>We use the following types of Cookies:</p>
        <p>
          <strong>Preference</strong>
          <br />
          These Cookies remembers your choices on the Webpage (e.g. username, language etc.) and
          tells us how you have interacted with the Webpageso that we can personalise the service.
        </p>
        <p>
          <strong>Performance</strong>
          <br />
          These Cookies analyse how you use the Webpage and monitors the Webpage’s performance. This
          helps us to identify and manage eventual problems. A performance cookie can e.g. keep
          track of the traffick on different pages and give us information on how long it takes for
          a page to load.
        </p>
        <p>
          <strong>Operation and optimisation</strong>
          <br />
          These Cookies help us ensure that the Webpage is working as it should. It also gives us
          information which allow us to test new ideas and improve the Webpage.
        </p>
        <p>
          <strong>Security</strong>
          <br />
          These Cookies help us increase the security by preventing, detecting and managing harmful
          activities on the Webpage.
        </p>
        <p>
          <strong>Marketing</strong>
          <br />
          By collecting information on how you interact with the Webpage, these Cookies helps us to
          be more relevant in our communication with you and improve your experience.
        </p>
        <p>
          These Cookies are often placed by a third party with the intent to handle performance for
          ads, show ads and / or build user profiles in order to display ads elsewhere.
        </p>

        <h2>First party Cookies</h2>
        <p>
          First party cookies are set by the Webpage on your device and can therefore only be read
          by you or us.
        </p>

        <ul>
          <li>
            <ul>
              <li>
                <strong>Name: </strong>_gid
              </li>
              <li>
                <strong>Type: </strong>Optimization
              </li>
              <li>
                <strong>Purpose: </strong>Store information about how the web site is used.
              </li>
              <li>
                <strong>Duration: </strong>1 day
              </li>
            </ul>
          </li>
          <li>
            <br />
            <ul>
              <li>
                <strong>Name: </strong>__stripe_mid
              </li>
              <li>
                <strong>Type: </strong>Security
              </li>
              <li>
                <strong>Purpose: </strong>Used to avoid fraud
              </li>
              <li>
                <strong>Duration: </strong>1 year
              </li>
            </ul>
          </li>
          <li>
            <br />
            <ul>
              <li>
                <strong>Name: </strong>__stripe_sid
              </li>
              <li>
                <strong>Type: </strong>Security
              </li>
              <li>
                <strong>Purpose: </strong>Used to avoid fraud
              </li>
              <li>
                <strong>Duration: </strong>30 minutes
              </li>
            </ul>
          </li>
          <li>
            <br />
            <ul>
              <li>
                <strong>Name: </strong>_hjMinimizedPolls
              </li>
              <li>
                <strong>Type: </strong>Optimization
              </li>
              <li>
                <strong>Purpose: </strong>Meassure how the web site is used in order to improve the
                service
              </li>
              <li>
                <strong>Duration: </strong>30 minutes
              </li>
            </ul>
          </li>
          <li>
            <br />
            <ul>
              <li>
                <strong>Name: </strong>_hjMinimizedPolls
              </li>
              <li>
                <strong>Type: </strong>Functional
              </li>
              <li>
                <strong>Purpose: </strong>Save information about th eusers settings on the web site.
              </li>
              <li>
                <strong>Duration: </strong>1 year
              </li>
            </ul>
          </li>
          <li>
            <br />
            <ul>
              <li>
                <strong>Name: </strong>euCookiesAccepted
              </li>
              <li>
                <strong>Type: </strong>Functional
              </li>
              <li>
                <strong>Purpose: </strong>Save information about th eusers settings on the web site.
              </li>
              <li>
                <strong>Duration: </strong>10 years
              </li>
            </ul>
          </li>
          <li>
            <br />
            <ul>
              <li>
                <strong>Name: </strong>_fbp
              </li>
              <li>
                <strong>Type: </strong>Marketing
              </li>
              <li>
                <strong>Purpose: </strong>Meassure the users activity on the web site
              </li>
              <li>
                <strong>Duration: </strong>3 months
              </li>
            </ul>
          </li>
          <li>
            <br />
            <ul>
              <li>
                <strong>Name: </strong>_ga
              </li>
              <li>
                <strong>Type: </strong>Optimization
              </li>
              <li>
                <strong>Purpose: </strong>Identify unique users on the web site
              </li>
              <li>
                <strong>Duration: </strong>2 years
              </li>
            </ul>
          </li>
          <li>
            <br />
            <ul>
              <li>
                <strong>Name: </strong>_hjid
              </li>
              <li>
                <strong>Type: </strong>Optimization
              </li>
              <li>
                <strong>Purpose: </strong>Save a unique ID for the user
              </li>
              <li>
                <strong>Duration: </strong>1 year
              </li>
            </ul>
          </li>
        </ul>
        <h2>Managing Cookies</h2>
        <p>
          When you visit the Webpage for the first time we ask for your consent to use non-necessary
          cookies. You can withdraw your consent at any time. If you no longer wish to store the
          Cookies you have accepted, you can go to your web-browser settings and accept, deny or
          remove cookies (usually under "Help", "Settings" or "Edit"). Note that you will likely not
          be able to use all services on the Webpage if you choose to deny Cookies.
        </p>

        <h2>Changes to this Policy</h2>
        <p>
          We reserve the right to make changes in this Policy. If a change affects our obligations
          or your rights, we will inform you about the changes in advance in order to give you the
          possibility to accept the updated policy.
        </p>
        <h2>Contact</h2>
        <p>
          Contact us if you have any questions about Cookies:
          <a href="mailto:hello@respaces.co"> hello@respaces.co </a>
        </p>
      </div>
    );
  }
};

CookiePolicy.defaultProps = {
  rootClassName: null,
  className: null,
};

const { string } = PropTypes;

CookiePolicy.propTypes = {
  rootClassName: string,
  className: string,
};

export default CookiePolicy;
