export const DAY_NAME = 'dddd';
export const DAY_NAME_WITH_DATE = 'ddd DD';
export const MONTH_AND_DAY = 'MMM DD';
export const MONTH_SHORT = 'MMM';
export const MONTH = 'MMMM';
export const DAY = 'DD';
export const FULL_DATE_WITH_MONTH_NAME = 'DD-MMM-YYYY';
export const WEEK_NUMBER = 'W';
export const TIME_FORMAT = 'HH:mm';
export const BUSINESS_DAYS = {
  MONDAY: 'Monday',
  TUESDAY: 'Tuesday',
  WEDNESDAY: 'Wednesday',
  THURSDAY: 'Thursday',
  FRIDAY: 'Friday',
};
BUSINESS_DAYS.LIST = [
  BUSINESS_DAYS.MONDAY,
  BUSINESS_DAYS.TUESDAY,
  BUSINESS_DAYS.WEDNESDAY,
  BUSINESS_DAYS.THURSDAY,
  BUSINESS_DAYS.FRIDAY,
];
export const PAGE_NAMES = {
  LISTING_PAGE: 'listingPage',
  ORDER: 'orderConfirmationPage',
};
