import React from 'react';
import ContentLoader from 'react-content-loader';

import css from './TableLoaderMobile.module.scss';

const TableLoaderMobile = () => {
  const currentWeekSizes = { height: 24, width: 109 },
    currentWeekDatesSizes = { height: 24, width: 230 },
    daySizes = { titleWidth: 34, titleHeight: 20, titleMargin: 14, daySize: 40, dayMargin: 38 },
    paddingY = 14,
    paddingX = 35,
    list = [];

  list.push(
    <rect
      height={currentWeekSizes.height}
      width={currentWeekSizes.width}
      rx={0}
      ry={0}
      x={0}
      y={0}
    />
  );

  list.push(
    <rect
      height={currentWeekDatesSizes.height}
      width={currentWeekDatesSizes.width}
      rx={0}
      ry={0}
      x={0}
      y={currentWeekSizes.height + paddingY}
    />
  );

  for (let i = 0; i <= 4; i++) {
    const dayX = i * (daySizes.daySize + paddingX);
    const dayTitleOffset = (daySizes.daySize - daySizes.titleWidth) / 2;
    const dayTitleX = i * (daySizes.daySize + dayTitleOffset / i + paddingX);

    list.push(
      <>
        <rect
          x={i === 0 ? dayTitleOffset : dayTitleX}
          y={currentWeekSizes.height + currentWeekDatesSizes.height + paddingY * 3}
          rx={0}
          ry={0}
          width={daySizes.titleWidth}
          height={daySizes.titleHeight}
        />
        <rect
          x={dayX}
          y={
            currentWeekSizes.height +
            currentWeekDatesSizes.height +
            daySizes.titleMargin +
            paddingY * 4
          }
          rx={0}
          ry={0}
          width={daySizes.daySize}
          height={daySizes.daySize}
        />
      </>
    );
  }

  list.push(
    <>
      <rect x={0} y={200} rx={5} ry={5} width={100} height={20} />
      <rect x={0} y={240} rx={5} ry={5} width={350} height={50} />
    </>
  );

  return (
    <section className={css.container}>
      <div className={css.wrapper}>
        <ContentLoader>{list}</ContentLoader>
      </div>
    </section>
  );
};

export default TableLoaderMobile;
