import React from 'react';
import moment from 'moment';
import classNames from 'classnames';
import { Swiper, SwiperSlide } from 'swiper/react';

import check from '../icons/check.svg';
import question from '../icons/question.svg';

import css from './WeeksSlider.module.scss';

const WeeksMobileSlider = ({ weeks, weekIndex, dayIndex, onUpdateDay, onUpdateWeek }) => {
  const params = {
    className: css.sliderWrapper,
    spaceBetween: 15,
    slidesPerView: 1,
    initialSlide: weekIndex || 0,
    onRealIndexChange: ({ activeIndex }) => {
      onUpdateWeek(activeIndex);
      onUpdateDay(0);
    },
  };

  return (
    <Swiper {...params}>
      {weeks.map((w, index) => (
        <SwiperSlide className={css.daysGrid} key={`week_${index}`}>
          {w.map(({ date, isFilled }, i) => (
            <button
              key={i}
              className={classNames(css.dayButton, dayIndex === i && css.selected)}
              onClick={() => onUpdateDay(i)}
            >
              <span className={css.dayName}>{moment(date).format('ddd')}</span>
              <div className={css.dayIcon}>
                <img src={isFilled ? check : question} alt="" />
              </div>
            </button>
          ))}
        </SwiperSlide>
      ))}
    </Swiper>
  );
};

export default WeeksMobileSlider;
