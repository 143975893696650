import React from 'react';
import { useIntl } from 'react-intl';
import { FieldNumberInput, FieldSelect } from '../../components';
import { DAILY_BOOKING, HOURLY_BOOKING } from '../../util/types';
import { required } from '../../util/validators';
import css from './EditListingAvailabilityPaddingTimeForm.module.scss';

const EditListingAvailabilityPaddingTimeForm = ({
  initialValues = {},
  bookingType,
  selectClassName,
  fieldClassName,
}) => {
  const intl = useIntl();
  if (bookingType === DAILY_BOOKING || bookingType === HOURLY_BOOKING) {
    return (
      <div className={css.root}>
        <div className={css.field}>
          <FieldNumberInput
            name="amount"
            initialValue={initialValues.amount}
            className={css.numberInputWrapper}
          />
        </div>
        <div className={css.field}>
          <FieldSelect
            name="unit"
            validate={required(' ')}
            initialValue={initialValues.unit}
            className={fieldClassName}
            selectClassName={selectClassName}
          >
            <option value="" key="unit" disabled>
              {intl.formatMessage({
                id: 'EditListingAvailabilityPaddingTimeForm.paddingTimeUnit.placeholder',
              })}
            </option>
            <option value="day" key="day">
              {intl.formatMessage({
                id: 'EditListingAvailabilityPaddingTimeForm.paddingTimeUnit.day',
              })}
            </option>
            <option value="hour" key="hour">
              {intl.formatMessage({
                id: 'EditListingAvailabilityPaddingTimeForm.paddingTimeUnit.hour',
              })}
            </option>
          </FieldSelect>
        </div>
      </div>
    );
  } else {
    return null;
  }
};

export default EditListingAvailabilityPaddingTimeForm;
