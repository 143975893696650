import classNames from 'classnames';
import { arrayOf, oneOf, shape, string } from 'prop-types';
import React from 'react';
import css from './Avatars.module.scss';

/**
 * @param firstName {String}
 * @param lastName {String} */
const abbreviatedName = (firstName, lastName) => {
  return !!lastName
    ? `${firstName.charAt(0)}${lastName.charAt(0)}`
    : !!firstName
    ? `${firstName.charAt(0)}`
    : null;
};

export const AVATAR_SIZES = {
  SMALL: 'small',
  LARGE: 'large',
};

AVATAR_SIZES.LIST = [AVATAR_SIZES.SMALL, AVATAR_SIZES.LARGE];

export const Avatar = ({ url, abbreviatedName, size, className }) =>
  url ? (
    <div className={classNames(css.avatar, css[size], className)}>
      <img src={url} alt="profile image" />
    </div>
  ) : (
    <div className={classNames(css.avatar, css[size], className)}>{abbreviatedName}</div>
  );

Avatar.defaultProps = {
  size: AVATAR_SIZES.SMALL,
};

export const Avatars = ({ avatars, avatarClassName, size }) => (
  <div className={css.avatarsList}>
    {avatars.length > 3 ? (
      <>
        <Avatar
          size={size}
          url={avatars[0]?.avatarURL}
          abbreviatedName={abbreviatedName(avatars[0]?.firstName, avatars[0]?.lastName)}
          className={avatarClassName}
        />
        <Avatar
          size={size}
          url={avatars[1]?.avatarURL}
          abbreviatedName={abbreviatedName(avatars[1]?.firstName, avatars[1]?.lastName)}
          className={avatarClassName}
        />
        <div className={classNames(css.avatar, css[size], avatarClassName)}>
          +{avatars.length - 2}
        </div>
      </>
    ) : (
      avatars.map((a, i) => (
        <Avatar
          size={size}
          url={a.avatarURL}
          abbreviatedName={abbreviatedName(a.firstName, a.lastName)}
          className={avatarClassName}
          key={`Avatar_${a.firstName}_${i}`}
        />
      ))
    )}
  </div>
);

Avatars.defaultProps = {
  size: AVATAR_SIZES.SMALL,
};

Avatars.propTypes = {
  avatars: arrayOf(
    shape({
      avatarURL: string,
      firstName: string,
      lastName: string,
    })
  ),
  avatarClassName: string,
  size: oneOf(AVATAR_SIZES.LIST),
};
