import React from 'react';

import css from './LocationAutocompleteInputNew.module.scss';

const LocationIcon = (props) => {
  return (
    <svg width="21" height="27" viewBox="0 0 21 27" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.4286 1C7.92887 1.00295 5.53239 1.99726 3.76483 3.76482C1.99727 5.53238 1.00296 7.92886 1.00001 10.4286C0.997016 12.4713 1.66428 14.4587 2.89944 16.0857C2.89944 16.0857 3.15658 16.4243 3.19858 16.4731L10.4286 25L17.662 16.4689C17.6997 16.4234 17.9577 16.0857 17.9577 16.0857L17.9586 16.0831C19.1931 14.4568 19.8601 12.4704 19.8572 10.4286C19.8542 7.92886 18.8599 5.53238 17.0923 3.76482C15.3248 1.99726 12.9283 1.00295 10.4286 1V1ZM10.4286 13.8571C9.75047 13.8571 9.0876 13.6561 8.52377 13.2793C7.95994 12.9026 7.52049 12.3671 7.26099 11.7406C7.00149 11.1141 6.9336 10.4248 7.06589 9.75969C7.19818 9.09461 7.52472 8.4837 8.00422 8.00421C8.48371 7.52471 9.09462 7.19817 9.7597 7.06588C10.4248 6.93359 11.1141 7.00148 11.7406 7.26098C12.3671 7.52048 12.9026 7.95993 13.2793 8.52376C13.6561 9.08759 13.8572 9.75046 13.8572 10.4286C13.856 11.3375 13.4944 12.2089 12.8517 12.8517C12.209 13.4944 11.3375 13.856 10.4286 13.8571V13.8571Z"
        stroke="black"
        strokeWidth="1.4"
      />
    </svg>
  );
};

export default LocationIcon;
