/*
 * Renders a group of checkboxes that can be used to select
 * multiple values from a set of options.
 *
 * Also adds a text input for addid extra information related to each checkbox
 *
 * The corresponding component when rendering the selected
 * values is PropertyGroup.
 *
 */

import React from 'react';
import { arrayOf, bool, node, shape, string } from 'prop-types';
import classNames from 'classnames';
import { FieldArray } from 'react-final-form-arrays';
import { FieldCheckbox, ValidationError, FieldTextInput } from '..';
import { useIntl } from 'react-intl';

import css from './FieldCheckboxAndInfoGroup.module.scss';

const FieldCheckboxRenderer = (props) => {
  const {
    className,
    rootClassName,
    label,
    twoColumns,
    id,
    fields,
    options,
    meta,
    infoLabel,
    category,
  } = props;
  const intl = useIntl();

  const classes = classNames(rootClassName || css.root, className);
  const listClasses = twoColumns ? classNames(css.list, css.twoColumns) : css.list;

  return (
    <fieldset className={classes}>
      {label ? <legend>{label}</legend> : null}
      <ul className={listClasses}>
        {options.map((option, index) => {
          const fieldId = `${id}.${option.key}`;
          const labelMaybe = intl.formatMessage({
            id: option.labelId,
            defaultMessage: option.label,
          });
          if (!category || (category && category in option))
            return (
              <li key={fieldId} className={css.item}>
                <FieldCheckbox
                  id={fieldId}
                  name={fields.name}
                  label={labelMaybe}
                  value={option.key}
                />

                <FieldTextInput
                  id={fieldId.concat('Info')}
                  name={fields.name.concat('Info.').concat(option.key)}
                  type="textarea"
                  placeholder={labelMaybe.concat(infoLabel)}
                />
              </li>
            );
          return null;
        })}
      </ul>
      <ValidationError fieldMeta={{ ...meta }} />
    </fieldset>
  );
};

FieldCheckboxRenderer.defaultProps = {
  rootClassName: null,
  className: null,
  label: null,
  infoLabel: null,
  twoColumns: false,
  category: null,
};

FieldCheckboxRenderer.propTypes = {
  rootClassName: string,
  className: string,
  id: string.isRequired,
  label: node,
  options: arrayOf(
    shape({
      key: string.isRequired,
      label: node.isRequired,
    })
  ).isRequired,
  twoColumns: bool,
  category: string,
};

const FieldCheckboxAndInfoGroup = (props) => (
  <FieldArray component={FieldCheckboxRenderer} {...props} />
);

// Name and component are required fields for FieldArray.
// Component-prop we define in this file, name needs to be passed in
FieldCheckboxAndInfoGroup.propTypes = {
  name: string.isRequired,
};

export default FieldCheckboxAndInfoGroup;
