import React, { useCallback, useEffect, useState } from 'react';
import moment from 'moment-timezone';
import classNames from 'classnames';
import { arrayOf, bool, func, instanceOf, node, oneOfType, shape, string } from 'prop-types';
import { useIntl } from 'react-intl';

import { intlShape } from '../../../../util/reactIntl';
import { KEY_CODES } from '../../config';
import { ComboBoxNew, DatePickerControl, DatePickerNew } from '../../../../components_new';

import { ComboBoxClearButton, TimePicker } from '../index';

import css from './DateCombobox.module.scss';

const DateCombobox = ({
  isOpen,
  onOpen,
  onClose,
  onDatesChange,
  onTimesChange,
  currentDates,
  currentTimes,
  timeOptions,
  isMobile,
  showTimepickers,
  inputRef,
  isRelevantFilter,
  className,
  focusedClassName,
  dropdownClassName,
}) => {
  const intl = useIntl();
  const [multipleDaysChecked, toggleMultipleDaysChecked] = useState(false);
  const [showTimes, toggleShowTimes] = useState(false);
  const [textValue, setTextValue] = useState('');

  const placeholder = intl.formatMessage({
    id: 'SectionHero.datesPlaceholder',
  });

  const formatTextValue = useCallback(() => {
    const { startDate, endDate } = currentDates || {};

    if (startDate && endDate) {
      const mStart = moment(startDate),
        mEnd = moment(endDate);

      if (mEnd.isSame(mStart)) {
        setTextValue(mEnd.format('MMM DD'));
      } else {
        setTextValue(`${mStart.format('MMM DD')} - ${mEnd.format('MMM DD')}`);
      }
    } else {
      setTextValue('');
    }
  }, [currentDates]);

  const endTimeOptions =
    (currentTimes?.startTime &&
      timeOptions.filter((op) => parseInt(op.key, 10) > parseInt(currentTimes?.startTime, 10))) ||
    [];

  const clear = () => {
    onTimesChange({ openTime: null, closeTime: null });
    onDatesChange({ startDate: null, endDate: null });
    onClose();
  };

  const selectToday = () => {
    onDatesChange({
      startDate: moment().startOf('date'),
      endDate: moment().startOf('date'),
    });
    onClose();
  };

  const selectTomorrow = () => {
    const tomorrow = moment().add(1, 'day').startOf('date');
    onDatesChange({
      startDate: tomorrow,
      endDate: tomorrow,
    });
    onClose();
  };

  const handleKeyDown = (e) => {
    e.preventDefault();
    const { key } = e;
    if (key === KEY_CODES.TAB) {
      onClose();
      inputRef?.current?.blur();
    } else if (key === KEY_CODES.ESCAPE) {
      onClose();
      inputRef?.current?.blur();
    } else if (key === KEY_CODES.ENTER) {
      selectToday();
    }
  };

  useEffect(() => {
    formatTextValue();
    if (
      !!currentDates?.startDate &&
      !!currentDates?.endDate &&
      !moment(currentDates?.startDate).isSame(moment(currentDates?.endDate))
    ) {
      toggleMultipleDaysChecked(true);
    }
  }, [currentDates, formatTextValue]);

  useEffect(() => {
    if (!!currentTimes?.startTime) {
      toggleShowTimes(true);
    }
  }, [currentTimes]);

  return (
    <ComboBoxNew
      id={'dates'}
      value={textValue}
      className={classNames(css.dateCombobox, className, isMobile && css.mobile)}
      focusedClassName={focusedClassName || css.dateComboboxFocused}
      dropdownClassName={dropdownClassName || css.dateComboboxDropdown}
      placeholder={placeholder}
      isOpen={isOpen}
      onFocus={onOpen}
      onBlur={onClose}
      inputRef={inputRef}
      onKeyDown={handleKeyDown}
      isMobile={isMobile}
      hideInput={isMobile || (!isMobile && isRelevantFilter)}
      isRelevantFilter={isRelevantFilter}
      readOnly
    >
      <div className={css.dropdownWrapper}>
        {(!isMobile || (isMobile && isRelevantFilter)) && (
          <>
            <button
              onClick={selectToday}
              className={classNames(css.optionWrapper, css.optionButton)}
              type="button"
            >
              {intl.formatMessage({ id: 'DateRangeLengthFilterHero.today' })}
            </button>
            <button
              onClick={selectTomorrow}
              className={classNames(css.optionWrapper, css.optionButton)}
              type="button"
            >
              {intl.formatMessage({ id: 'DateRangeLengthFilterHero.tomorrow' })}
            </button>
          </>
        )}
        <DatePickerNew
          value={{ ...currentDates }}
          onDatesChange={onDatesChange}
          onClose={onClose}
          selectMultipleDays={multipleDaysChecked}
        />
        <div
          className={classNames(
            css.dropdownFooter,
            isMobile && css.mobile,
            isRelevantFilter && css.isRelevantFilter
          )}
        >
          {/* Toggle for adding end date - removed to make it easier to search for opening hours */}
          {/* <DatePickerControl
            id="multiple_dates"
            label={intl.formatMessage({ id: 'ListingSearchForm.dateInput.addEndDate' })}
            isChecked={multipleDaysChecked}
            onChange={() => {
              toggleMultipleDaysChecked(!multipleDaysChecked);
            }}
            isMobile={isMobile}
          /> */}
          {showTimepickers && (
            <DatePickerControl
              id="show_times"
              label={intl.formatMessage({ id: 'ListingSearchForm.dateInput.includeTime' })}
              isChecked={showTimes}
              onChange={() => {
                toggleShowTimes(!showTimes);
                onTimesChange({
                  startTime: null,
                  endTime: null,
                });
              }}
              isMobile={isMobile}
            />
          )}
          {showTimes && (
            <div
              className={classNames(
                css.dropdownTimePickers,
                isRelevantFilter && css.isRelevantFilter,
                isMobile && css.mobile
              )}
            >
              <TimePicker
                options={timeOptions}
                value={currentTimes?.startTime}
                id={'startTime'}
                placeholder={'Start'}
                onChange={(val) =>
                  onTimesChange({
                    ...currentTimes,
                    startTime: val,
                  })
                }
                disabled={currentDates === null}
              />
              <div className={css.dropdownTimepickerSeparator}>-</div>
              <TimePicker
                options={endTimeOptions}
                value={currentTimes?.endTime}
                id={'endTime'}
                placeholder={'End'}
                onChange={(val) =>
                  onTimesChange({
                    ...currentTimes,
                    endTime: val,
                  })
                }
                disabled={!currentTimes?.startTime}
                last
              />
            </div>
          )}
        </div>
        {currentDates?.startDate && (
          <ComboBoxClearButton
            onClick={clear}
            className={css.clearBtnWrapper}
            onClose={onClose}
            isRelevantFilter={isRelevantFilter}
          />
        )}
      </div>
    </ComboBoxNew>
  );
};

DateCombobox.defaultProps = {
  currentDates: null,
  currentTimes: null,
  timeOptions: [],
  isMobile: false,
  showTimepickers: false,
};

DateCombobox.propTypes = {
  isOpen: bool.isRequired,
  onOpen: func,
  onClose: func,
  onDatesChange: func.isRequired,
  onTimesChange: func.isRequired,
  intl: intlShape.isRequired,
  currentDates: shape({
    startDate: instanceOf(Date),
    endDate: instanceOf(Date),
  }),
  currentTimes: shape({
    startTime: string,
    endDate: string,
  }),
  timeOptions: arrayOf(
    shape({
      key: string,
      label: string,
    })
  ),
  isMobile: bool,
  showTimepickers: bool,
  inputRef: oneOfType([func, shape({ node })]),
};

export default DateCombobox;
