import React from 'react';
import { shape, string } from 'prop-types';

import { propTypes } from '../../../util/types';

import ListingPageSection from '../ListingPageSection/ListingPageSection';

import { MapWithAddress } from '../../../components_new';

const SectionMap = ({
  geolocation,
  publicData: {
    location: { address = '' },
  },
  listingId,
}) => (
  <ListingPageSection title={'ListingPage.location.title'}>
    <MapWithAddress id={listingId} address={address} geolocation={geolocation} />
  </ListingPageSection>
);

SectionMap.defaultProps = {
  geolocation: null,
  listingId: null,
};

SectionMap.propTypes = {
  publicData: shape({
    location: shape({
      address: string,
    }),
  }),
  geolocation: propTypes.latlng,
  listingId: propTypes.uuid,
};

export default SectionMap;
