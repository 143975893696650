// import { func, number, string } from 'prop-types';
import React from 'react';
// import { connect } from 'react-redux';
import { compose } from 'redux';
import {
  Footer,
  LayoutSingleColumn,
  LayoutWrapperFooter,
  LayoutWrapperMain,
  LayoutWrapperTopbar,
  // SectionProducts,
  // Modal,
} from '../../components';
// import SectionTrustedBy from '../../components_new/SectionTrustedBy/SectionTrustedBy';
// import {
//   updateCalculatedPrices,
//   updateSelectedPackage,
//   updateTotalCredits,
// } from '../../ducks/b2b.duck';
// import SectionNewToRespacesPass from './SectionNewToRespacesPass/SectionNewToRespacesPass';
// import { inBrowser } from '../../util/device';
// import { injectIntl, intlShape } from '../../util/reactIntl';
import { StaticPage, TopbarContainer } from '../index';
import css from './RespacesPass.module.scss';
// import CustomerReviewsSlider from '../../components/CustomerReviewsSlider/CustomerReviewsSlider';
// import SectionGetStarted from './SectionGetStarted/SectionGetStarted';
// import SectionRespacesPassUsedBy from './SectionRespacesPassUsedBy/SectionRespacesPassUsedBy';
// import SectionFirst from './SectionFirst/SectionFirst';
import SectionClosing from './SectionClosing/SectionClosing';
// import SectionPricing from './SectionPricing/SectionPricing';
// import { updateCompanyAccount } from '../../ducks/Auth.duck';
// import { useDispatch } from 'react-redux';
// import { createResourceLocatorString } from '../../util/routes';
// import { routeConfiguration } from '../../index';
import { withRouter } from 'react-router-dom';
// import AdditionalCompanyDetailsForm from '../../forms/AdditionalCompanyDetailsForm/AdditionalCompanyDetailsForm';
// import { PACKAGE_FREE } from '../../util/types';

const RespacesPassComponent = (
  {
    // intl,
    // calculatedPrices,
    // updateCalculatedPrices,
    // selectedPackage,
    // totalCredits,
    // updateSelectedPackage,
    // updateTotalCredits,
    // currentUser,
    // history,
  }
) => {
  // const [freeTrialLoading, setFreeTrialLoading] = useState(false);
  // const [companyDetailsModalOpen, setCompanyDetailsModalOpen] = useState(false);

  // useEffect(() => {
  //   if (inBrowser()) {
  //     const scrollHash = window.location.hash;
  //     const el = scrollHash && document.querySelector(scrollHash);
  //     if (el) {
  //       el.scrollIntoView({
  //         alignToTop: true,
  //         behavior: 'smooth',
  //       });
  //     }
  //   }
  // }, []);

  // const sharetribeSdk = require('sharetribe-flex-sdk');
  // const SHARETRIBE_SDK_CLIENT_ID = process.env.REACT_APP_SHARETRIBE_SDK_CLIENT_ID;

  // Create new SDK instance
  // const sdk = sharetribeSdk.createInstance({
  //   clientId: SHARETRIBE_SDK_CLIENT_ID,
  // });

  // const dispatch = useDispatch();
  // const isCompanyAccount = currentUser?.attributes.profile.protectedData?.isCompanyAccount;
  // const receivedFreeTrial = currentUser?.attributes.profile.protectedData?.receivedFreeTrial;

  // const freeTrialRouteChange = () => {
  //   // handle free trial
  //   if (currentUser && !isCompanyAccount) {
  //     setCompanyDetailsModalOpen(true);
  //   } else if (!currentUser) {
  //     const hash = '#freetrial';
  //     /* eslint-disable-next-line no-restricted-globals */
  //     history.push(createResourceLocatorString('SignupPage', routeConfiguration(), {}, {}, hash));
  //   }
  // };

  // const handleAdditionalDetailsSubmit = async (phone, organization, businessId) => {
  //   await dispatch(
  //     updateCompanyAccount({
  //       isFreeTrial: true,
  //       selectedPackage: PACKAGE_FREE,
  //       phone,
  //       organization,
  //       businessId,
  //     })
  //   );
  //   history.push(
  //     createResourceLocatorString('SubscriptionManagementPage', routeConfiguration(), {
  //       tab: 'usage-log',
  //     })
  //   );
  // };

  return (
    <StaticPage
      title="Respaces Pass" /* Maybe change these values */
      description="Respaces pass allows users to buy credits to book flexible workspaces"
      schema={{
        '@context': 'http://schema.org',
        '@type': 'WebPage',
        description:
          'Use Respaces credits to book flexible workspaces' /* Maybe change these values */,
        name: 'Respaces pass',
        robots: 'noindex',
      }}
    >
      <LayoutSingleColumn>
        <LayoutWrapperTopbar>
          <TopbarContainer currentPage="RespacesPass" showBookADemoButton={false} />
        </LayoutWrapperTopbar>

        <LayoutWrapperMain className={css.mainWrapper}>
          <SectionClosing />
          {/* <SectionFirst />
          <SectionNewToRespacesPass
            sdk={sdk}
            currentUser={currentUser}
            title={intl.formatMessage({
              id: 'RespacesPass.SectionNewToRespacesPass.Popular.Title',
            })}
            listingsPopular
          />
          <SectionTrustedBy />
          <SectionRespacesPassUsedBy />
          <CustomerReviewsSlider />
          <SectionGetStarted />
          <SectionPricing
            freeTrialRouteChange={freeTrialRouteChange}
            calculatedPrices={calculatedPrices}
            selectedPackage={selectedPackage}
            totalCredits={totalCredits}
            updateSelectedPackage={updateSelectedPackage}
            updateTotalCredits={updateTotalCredits}
            updateCalculatedPrices={updateCalculatedPrices}
            currentUser={currentUser}
            freeTrialLoading={freeTrialLoading}
          />
          <Modal
            id="additionalCompanyDetailsModal"
            containerClassName={css.modalContainer}
            isOpen={companyDetailsModalOpen}
            onClose={() => setCompanyDetailsModalOpen(false)}
          >
            <AdditionalCompanyDetailsForm
              intl={intl}
              setCompanyDetailsModalOpen={setCompanyDetailsModalOpen}
              handleAdditionalDetailsSubmit={handleAdditionalDetailsSubmit}
            />
          </Modal>
          <SectionProducts
            sdk={sdk}
            firstMediaAndTextSection={css.hideMediaAndTextSection}
            secondMediaAndTextSection={css.hideMediaAndTextSection}
            className={css.lightGrayBackground}
          />
          <SectionNewToRespacesPass
            sdk={sdk}
            currentUser={currentUser}
            title={intl.formatMessage({ id: 'RespacesPass.SectionNewToRespacesPass.Title' })}
            caption={intl.formatMessage({ id: 'RespacesPass.SectionNewToRespacesPass.Caption' })}
            listingsRecentlyAdded
          /> */}
        </LayoutWrapperMain>

        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </LayoutSingleColumn>
    </StaticPage>
  );
};

// RespacesPassComponent.propTypes = {
//   intl: intlShape.isRequired,
//   totalPrice: number,
//   selectedPackage: string,
//   totalCredits: number,
//   pricePerCredit: number,
//   updateSelectedPackage: func,
//   updateTotalCredits: func,
// };

// const mapStateToProps = (state) => {
//   const {
//     b2b: { totalCredits, calculatedPrices, selectedPackage },
//     user: { currentUser },
//   } = state;

//   return {
//     totalCredits,
//     calculatedPrices,
//     currentUser,
//     selectedPackage,
//   };
// };

// const mapDispatchToProps = (dispatch) => ({
//   updateSelectedPackage: (payload) => dispatch(updateSelectedPackage(payload)),
//   updateTotalCredits: (payload) => dispatch(updateTotalCredits(payload)),
//   updateCalculatedPrices: (payload) => dispatch(updateCalculatedPrices(payload)),
// });

const RespacesPass = compose(
  withRouter
  // connect(mapStateToProps, mapDispatchToProps),
  // injectIntl
)(RespacesPassComponent);

export default RespacesPass;
