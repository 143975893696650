import React from 'react';
import ContentLoader from 'react-content-loader';

const ContentLoaderComponent = () => (
  <ContentLoader viewBox="0 0 768 389" width="768" height="389">
    <rect x="698" y="34" width="70" height="18" rx="4" />
    <rect y="34" width="120" height="14" rx="4" />
    <rect y="11" width="180" height="18" rx="4" />
    <rect x="542" y="22" width="130" height="20" rx="4" />
    <rect x="698" y="12" width="70" height="18" rx="4" />
    <rect y="63" width="768" height="1" />
    <rect x="698" y="164" width="70" height="18" rx="4" />
    <rect y="164" width="120" height="14" rx="4" />
    <rect y="141" width="180" height="18" rx="4" />
    <rect x="542" y="152" width="130" height="20" rx="4" />
    <rect x="698" y="142" width="70" height="18" rx="4" />
    <rect y="193" width="768" height="1" />
    <rect x="698" y="99" width="70" height="18" rx="4" />
    <rect y="99" width="120" height="14" rx="4" />
    <rect y="76" width="180" height="18" rx="4" />
    <rect x="542" y="87" width="130" height="20" rx="4" />
    <rect x="698" y="77" width="70" height="18" rx="4" />
    <rect y="128" width="768" height="1" />
    <rect x="698" y="229" width="70" height="18" rx="4" />
    <rect y="229" width="120" height="14" rx="4" />
    <rect y="206" width="180" height="18" rx="4" />
    <rect x="542" y="217" width="130" height="20" rx="4" />
    <rect x="698" y="207" width="70" height="18" rx="4" />
    <rect y="258" width="768" height="1" />
    <rect x="698" y="359" width="70" height="18" rx="4" />
    <rect y="359" width="120" height="14" rx="4" />
    <rect y="336" width="180" height="18" rx="4" />
    <rect x="542" y="347" width="130" height="20" rx="4" />
    <rect x="698" y="337" width="70" height="18" rx="4" />
    <rect y="388" width="768" height="1" />
    <rect x="698" y="294" width="70" height="18" rx="4" />
    <rect y="294" width="120" height="14" rx="4" />
    <rect y="271" width="180" height="18" rx="4" />
    <rect x="542" y="282" width="130" height="20" rx="4" />
    <rect x="698" y="272" width="70" height="18" rx="4" />
    <rect y="323" width="768" height="1" />
    <rect x="698" y="294" width="70" height="18" rx="4" />
    <rect y="294" width="120" height="14" rx="4" />
    <rect y="271" width="180" height="18" rx="4" />
    <rect x="542" y="282" width="130" height="20" rx="4" />
    <rect x="698" y="272" width="70" height="18" rx="4" />
    <rect y="323" width="768" height="1" />
    <rect x="698" y="294" width="70" height="18" rx="4" />
    <rect y="294" width="120" height="14" rx="4" />
    <rect y="271" width="180" height="18" rx="4" />
    <rect x="542" y="282" width="130" height="20" rx="4" />
    <rect x="698" y="272" width="70" height="18" rx="4" />
    <rect y="323" width="768" height="1" />
  </ContentLoader>
);

export default ContentLoaderComponent;
