import React from 'react';
import { arrayOf, bool, func, number, shape, node, string } from 'prop-types';

import { BottomSheet } from '../index';
import { BUTTON_TYPES, MOBILE_POSITION } from '../ButtonNew/ButtonNew';
import { intlShape } from '../../util/reactIntl';

import css from './BottomSheetWithSteps.module.scss';

const BottomSheetWithSteps = ({
  intl,
  isOpen,
  onClose,
  currentStep,
  updateStep,
  steps,
  onFormSubmit,
  showBottomSheet,
}) => {
  const buttonList = [
    currentStep < steps.length - 1
      ? {
          buttonType: BUTTON_TYPES.INLINE,
          size: 'm',
          text: intl.formatMessage({ id: 'ListingSearchForm.skipButton' }),
          textLight: true,
          onClick: () => updateStep(currentStep + 1),
        }
      : null,
    {
      buttonType: BUTTON_TYPES.GREEN,
      size: 'l',
      text: intl.formatMessage({ id: 'ListingSearchForm.searchButton' }),
      onClick: onFormSubmit,
      mobileButtonPosition: MOBILE_POSITION.RIGHT,
    },
  ].filter(Boolean);

  const closeBottomSheet = () => {
    onClose();
    updateStep(0);
  };

  const BottomSheetHeader = () => (
    <>
      {currentStep > 0 ? (
        <button onClick={() => updateStep(currentStep - 1)} className={css.headerButton}>
          <svg
            width="8"
            height="14"
            viewBox="0 0 8 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M7 1L1 7L7 13" stroke="black" />
          </svg>
        </button>
      ) : (
        <button onClick={closeBottomSheet} className={css.headerButton}>
          <svg
            width="14"
            height="14"
            viewBox="0 0 14 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M1 1L13 13" stroke="black" />
            <path d="M13 1L1 13" stroke="black" />
          </svg>
        </button>
      )}
      <span>{steps[currentStep]?.title}</span>
    </>
  );

  return (
    <BottomSheet
      title={BottomSheetHeader()}
      isOpen={isOpen}
      onClose={closeBottomSheet}
      buttonList={buttonList}
      showBottomSheet={showBottomSheet}
    >
      {/* Right now it's suited just for forms, as it's the only use case */}
      <form onSubmit={onFormSubmit}>{steps[currentStep]?.component}</form>
    </BottomSheet>
  );
};

BottomSheetWithSteps.defaultProps = {
  onFormSubmit: () => null,
};

BottomSheetWithSteps.propTypes = {
  /* Props for BottomSheet */
  isOpen: bool.isRequired,
  onClose: func.isRequired,
  showBottomSheet: bool.isRequired,

  /* Props for steps */
  intl: intlShape.isRequired,
  currentStep: number.isRequired,
  steps: arrayOf(shape({ component: node, title: string })).isRequired,
  updateStep: func.isRequired,
  onFormSubmit: func,
};

export default BottomSheetWithSteps;
