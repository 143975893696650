import React, { memo } from 'react';
import { bool } from 'prop-types';
import PriceFilterPlain from './RangeFilterPlain';
import RangeFilterPopup from './RangeFilterPopup';
import isEqual from 'lodash/isEqual';

const RangeFilter = memo((props) => {
  const { showAsPopup, ...rest } = props;
  return showAsPopup ? <RangeFilterPopup {...rest} /> : <PriceFilterPlain {...rest} />;
}, isEqual);

RangeFilter.defaultProps = {
  showAsPopup: false,
};

RangeFilter.propTypes = {
  showAsPopup: bool,
};

export default RangeFilter;
