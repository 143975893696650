import React from 'react';
import classNames from 'classnames';
import { bool, node, string } from 'prop-types';
import { EditListingIcons as Icons } from '..';

import css from './IconCardSelectButton.module.scss';

const IconCardSelectButton = (props) => {
  const {
    labelText,
    IconComponent,
    iconSrc = '',
    isSelected = false,
    canEdited = false,
    onClick,
    editCallback,
    selectedAddonRef,
    option,
  } = props;

  const containerClasses = classNames(css.iconCardContainer, {
    [css.selected]: isSelected,
  });

  return (
    <div
      className={containerClasses}
      onClick={onClick}
      ref={selectedAddonRef?.current?.[option.id]}
    >
      <div className={css.cornerIcons}>
        {canEdited && <Icons.EditIcon className={css.editIcon} handleClick={editCallback} />}
        {isSelected && <Icons.GreenCircleTickIcon />}
      </div>
      {IconComponent && <div className={css.iconWrapper}>{IconComponent}</div>}
      {iconSrc && (
        <div>
          <img src={iconSrc} alt="" />
        </div>
      )}
      <div className={css.labelText}>{labelText}</div>
    </div>
  );
};

IconCardSelectButton.propTypes = {
  labelText: string.isRequired,
  IconComponent: node,
  iconSrc: string,
  isSelected: bool,
  canEdited: bool,
};

export default IconCardSelectButton;
