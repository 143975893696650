import classNames from 'classnames';
import get from 'lodash/get';
import { array, bool, func, node, object, oneOfType, shape, string } from 'prop-types';
import React from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { FieldCheckbox, ModalInMobile } from '../../components';
import { ButtonNew } from '../../components_new';
import {
  BUTTON_SIZES,
  BUTTON_TYPES,
  MOBILE_POSITION,
} from '../../components_new/ButtonNew/ButtonNew';
import config from '../../config';
import { formatSubscriptionStatus } from '../../containers/SubscriptionManagementPage/utils/subscription';
import { BookingMonthForm, BookingTimeForm } from '../../forms';
import BookingDatesForm from '../../forms/BookingDatesForm/BookingDatesForm';
import { filters } from '../../marketplace-custom-config';
import {
  calcCompanyUsedCredits,
  calcRenewalDate,
  getCreditsLeftAndLimit,
  isUnlimitedCredits,
} from '../../util/b2bHelper';
import { convertPriceToCredit, priceData } from '../../util/currency';
import { getUnitTranslationKey } from '../../util/data';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { findOptionsForSelectFilter } from '../../util/search';
import {
  DAILY_BOOKING,
  HOURLY_BOOKING,
  LISTING_STATE_CLOSED,
  MONTHLY_BOOKING,
  propTypes,
  SUBSCRIPTION_OPTIONS,
  SUBSCRIPTION_STATUS_ACTIVE,
  SUBSCRIPTION_STATUS_CANCELLED,
  SUBSCRIPTION_STATUS_PAUSED,
} from '../../util/types';
import { parse, stringify } from '../../util/urlHelpers';
import { BookShowingForm, InquiryForm } from '../../forms';
import { types as sdkTypes } from '../../util/sdkLoader';

import css from './BookingPanel.module.scss';
const { UUID } = sdkTypes;

// This defines when ModalInMobile shows content as Modal
const MODAL_BREAKPOINT = 1023;
const TODAY = new Date();

const openBookModal = (isOwnListing, isClosed, history, location) => {
  if (isOwnListing || isClosed) {
    window.scrollTo(0, 0);
  } else {
    const { pathname, search, state } = location;
    const searchString = `?${stringify({ ...parse(search), book: true })}`;
    history.push(`${pathname}${searchString}`, state);
  }
};

const closeBookModal = (history) => {
  history.goBack();
};

const dateFormattingOptions = { month: 'short', day: 'numeric', weekday: 'short' };

const options = findOptionsForSelectFilter('category', filters);

const BookingPanel = (props) => {
  const {
    rootClassName,
    className,
    titleClassName,
    listing,
    isOwnListing,
    unitType,
    onSubmit,
    subTitle,
    onManageDisableScrolling,
    onFetchTimeSlots,
    monthlyTimeSlots,
    history,
    location,
    intl,
    onFetchTransactionLineItems,
    lineItems,
    fetchLineItemsInProgress,
    fetchLineItemsError,
    timeSlots,
    fetchTimeSlotsError,
    bookingType,
    additionalServices = [],
    addonList,
    defaultSearch,
    isEmployeeUser,
    currentUser,
    companyAccount,
    isCompanyUser,
    bookingData,
    initialData,
    isFixedOffice,
    handleBookShowingFormSubmit,
    locationAddess,
    currentUserInfoForEmail,
    bookViewingFormStep,
    setBookViewingFormStep,
    fixedOfficeTypeTranslation,
    seatsInfo,
    listingPrice,
    handleInquiryFormSubmit,
    isInquiryPressed,
    setInquiryPressed,
    areaMin,
    areaMax,
    calculatedSeats,
  } = props;
  const price = listing.attributes.price;
  const vatPercentage = listing.attributes.publicData.vatPercentage || 25; // add fixed variable for default

  const listingCredits = convertPriceToCredit(price);
  const listingId = new UUID(listing.id.uuid);

  const timeZone =
    listing.attributes.availabilityPlan && listing.attributes.availabilityPlan.timezone;
  const hasListingState = !!listing.attributes.state;
  const isClosed = hasListingState && listing.attributes.state === LISTING_STATE_CLOSED;
  const showBookingTimeForm =
    hasListingState && !isClosed && bookingType === HOURLY_BOOKING && !isFixedOffice;
  const showBookingDatesForm =
    hasListingState && !isClosed && bookingType === DAILY_BOOKING && !isFixedOffice;
  const showBookingMonthForm =
    hasListingState && !isClosed && bookingType === MONTHLY_BOOKING && !isFixedOffice;
  const showClosedListingHelpText = listing.id && isClosed;
  const { formattedPrice } = priceData(price, vatPercentage, intl);
  const isBook = !!parse(location.search).book;

  const requireShowing = listing.attributes.publicData.requireShowing;
  const isRequestBooking = !!listing.attributes.publicData.acceptBookingOnRequest;

  const paddingTime = get(listing, 'attributes.publicData.paddingTime', { amount: 0, unit: null });

  const subTitleText = !!subTitle
    ? subTitle
    : showClosedListingHelpText
    ? intl.formatMessage({ id: 'BookingPanel.subTitleClosedListing' })
    : null;

  const unitTranslationKey = getUnitTranslationKey(listing);

  const classes = classNames(rootClassName || css.root, className);
  const titleClasses = classNames(titleClassName || css.bookingTitle);

  const category = options.find((c) => c.key === listing.attributes.publicData.category);

  const { remainedCredits } = get(currentUser, 'attributes.profile.metadata', {});
  const { selectedPackage } = get(currentUser, 'attributes.profile.protectedData', {});
  const isMonthlySubscription = selectedPackage
    ? SUBSCRIPTION_OPTIONS.includes(selectedPackage)
    : isEmployeeUser || isCompanyUser;
  const isUnlimitedCreditsEmployee = isUnlimitedCredits(currentUser);
  const totalUsedCredits = companyAccount ? calcCompanyUsedCredits(companyAccount) : null;
  const { totalCredits = 0, previousUnusedCredits = 0 } = get(
    companyAccount,
    'attributes.profile.metadata',
    {}
  );
  const {
    subscriptionStatus = SUBSCRIPTION_STATUS_ACTIVE,
    oneTimeCredits = 0,
    remainingPreviousUnusedCredits = 0,
  } = get(companyAccount, 'attributes.profile.metadata', {});
  const remainingCredits = totalCredits - calcCompanyUsedCredits(companyAccount);
  const subscriptionRenewalDate = calcRenewalDate(companyAccount);
  const isSubscriptionActive = subscriptionStatus === SUBSCRIPTION_STATUS_ACTIVE;
  const isSubscriptionPaused = subscriptionStatus === SUBSCRIPTION_STATUS_PAUSED;
  const isSubscriptionCancelled = subscriptionStatus === SUBSCRIPTION_STATUS_CANCELLED;
  const canBookWithCredits =
    isSubscriptionActive ||
    (!isSubscriptionActive &&
      (oneTimeCredits > 0 || remainingPreviousUnusedCredits > 0 || remainingCredits > 0));

  const isEnoughCreditsForBooking = (units) => {
    return (
      (isUnlimitedCreditsEmployee || remainedCredits >= listingCredits * units) &&
      totalUsedCredits <= totalCredits + previousUnusedCredits - listingCredits * units
    );
  };
  const formattedSubscriptionStatus = formatSubscriptionStatus(subscriptionStatus, intl);
  const subscriptionStatusClasses = classNames(css.subscriptionStatus, {
    [css.active]: isSubscriptionActive,
    [css.cancel]: isSubscriptionCancelled,
    [css.pause]: isSubscriptionPaused,
  });

  const { currentCredits, creditLimit } = getCreditsLeftAndLimit(currentUser, companyAccount);
  const remainingCreditsText = (
    <span>
      <b>
        {creditLimit > 0 ? `${currentCredits}/` : ''}
        {creditLimit}
      </b>
      &nbsp;
      <FormattedMessage id="BookingPanel.credit.remaining" />
    </span>
  );

  const additionalServicesSection = () => {
    const getAdditionalServiceLabel = (service) => {
      const addon = addonList.filter((o) => service.name === o.id)[0];
      const serviceVAT = service.vatPercentage;
      const { formattedPrice } = priceData(service.price, serviceVAT, intl) || {};
      if (addon && addon.labelId) {
        return (
          <div className={css.addonTextWrapper}>
            <span>
              {intl.formatMessage({
                id: addon.labelId,
              })}
            </span>
            <span>{formattedPrice}</span>
          </div>
        );
      } else {
        return null;
      }
    };
    return (
      <div className={css.additionalServices}>
        <FormattedMessage id="EditListingPricingForm.additionalServices">
          {(id) => <div className={css.additionalServicesTitle}>{id}</div>}
        </FormattedMessage>
        {additionalServices.map((s) => (
          <>
            {getAdditionalServiceLabel(s) && (
              <>
                <FieldCheckbox
                  name="additionalServices"
                  value={s.serviceId}
                  id={s.serviceId}
                  label={getAdditionalServiceLabel(s)}
                  textClassName={css.additionalServicesText}
                />
              </>
            )}
          </>
        ))}
      </div>
    );
  };

  const handleCloseBookModal = (history, location) => {
    closeBookModal(history, location);
    if (isInquiryPressed) setInquiryPressed(false);
  };

  return (
    <div className={classes}>
      <ModalInMobile
        containerClassName={classNames(!isFixedOffice && css.modalContainer)}
        id="BookingTimeFormInModal"
        isModalOpenOnMobile={isBook}
        onClose={() => handleCloseBookModal(history, location)}
        showAsModalMaxWidth={MODAL_BREAKPOINT}
        onManageDisableScrolling={onManageDisableScrolling}
        closeButtonMessage={' '}
        modalContentCSS={css.modalContent}
      >
        {!requireShowing ? (
          <div>
            <h2 className={titleClasses}>{listing.attributes.title}</h2>
            <div className={css.bookingHeading}>
              <FormattedMessage id={category.labelId}>
                {(id) => <div className={css.bookingType}>{id}</div>}
              </FormattedMessage>
              <div className={css.desktopPriceContainer}>
                <div className={css.desktopPriceValue}>
                  {isEmployeeUser || isCompanyUser ? (
                    <FormattedMessage
                      id="BookingPanel.credit.creditsValue"
                      values={{ credits: listingCredits }}
                    />
                  ) : (
                    formattedPrice
                  )}
                </div>
                <div className={css.desktopPerUnit}>
                  <FormattedMessage id={unitTranslationKey} />
                </div>
              </div>
              {subTitleText && (
                <div className={css.bookingHeadingContainer}>
                  <div className={css.bookingHelp}>{subTitleText}</div>
                </div>
              )}
            </div>
          </div>
        ) : null}
        {(isEmployeeUser || isCompanyUser) && !isFixedOffice && (
          <div className={css.availableCreditsWrapper}>
            <div className={css.availableCredits}>
              <div className={css.title}>
                <FormattedMessage id="BookingPanel.credit.availableCredit" />
              </div>
              {isMonthlySubscription && (
                <div className={css.renewalDate}>
                  <FormattedMessage
                    id="BookingPanel.credit.renewal"
                    values={{ date: subscriptionRenewalDate }}
                  />
                </div>
              )}
            </div>
            <div>
              <div className={subscriptionStatusClasses}>{formattedSubscriptionStatus}</div>
              <div className={css.remaining}>{remainingCreditsText}</div>
            </div>
          </div>
        )}

        {showBookingTimeForm ? (
          <BookingTimeForm
            className={css.bookingForm}
            formId="BookingPanel"
            submitButtonWrapperClassName={css.bookButtonWrapper}
            unitType={unitType}
            onSubmit={onSubmit}
            price={price}
            listingId={listingId}
            isOwnListing={isOwnListing}
            monthlyTimeSlots={monthlyTimeSlots}
            onFetchTimeSlots={onFetchTimeSlots}
            startDatePlaceholder={intl.formatDate(TODAY, dateFormattingOptions)}
            endDatePlaceholder={intl.formatDate(TODAY, dateFormattingOptions)}
            timeZone={timeZone}
            onFetchTransactionLineItems={onFetchTransactionLineItems}
            lineItems={lineItems}
            fetchLineItemsInProgress={fetchLineItemsInProgress}
            fetchLineItemsError={fetchLineItemsError}
            additionalServices={additionalServices}
            listing={listing}
            defaultSearch={defaultSearch}
            additionalServicesSection={additionalServicesSection}
            onBackPress={() => closeBookModal(history, location)}
            paddingTime={paddingTime}
            isEmployeeUser={isEmployeeUser}
            isCompanyUser={isCompanyUser}
            isEnoughCreditsForBooking={isEnoughCreditsForBooking}
            bookingData={bookingData}
            canBookWithCredits={canBookWithCredits}
            listingCredits={listingCredits}
            isRequestBooking={isRequestBooking}
          />
        ) : null}

        {showBookingDatesForm ? (
          <BookingDatesForm
            className={css.bookingForm}
            formId="BookingPanel"
            submitButtonWrapperClassName={css.bookButtonWrapper}
            unitType={unitType}
            onSubmit={onSubmit}
            price={price}
            listingId={listingId}
            isOwnListing={isOwnListing}
            timeSlots={timeSlots}
            fetchTimeSlotsError={fetchTimeSlotsError}
            onFetchTransactionLineItems={onFetchTransactionLineItems}
            lineItems={lineItems}
            fetchLineItemsInProgress={fetchLineItemsInProgress}
            fetchLineItemsError={fetchLineItemsError}
            additionalServices={additionalServices}
            listing={listing}
            defaultSearch={defaultSearch}
            additionalServicesSection={additionalServicesSection}
            onBackPress={() => closeBookModal(history, location)}
            isEmployeeUser={isEmployeeUser}
            isCompanyUser={isCompanyUser}
            isEnoughCreditsForBooking={isEnoughCreditsForBooking}
            paddingTime={paddingTime}
            bookingData={bookingData}
            canBookWithCredits={canBookWithCredits}
            initialData={initialData}
            listingCredits={listingCredits}
            isRequestBooking={isRequestBooking}
          />
        ) : null}
        {/* Can still maybe remove some unnecessary props here that were copied over */}
        {isFixedOffice && !isInquiryPressed ? (
          <BookShowingForm
            onFormSubmit={handleBookShowingFormSubmit}
            locationAddess={locationAddess}
            currentUserInfoForEmail={currentUserInfoForEmail}
            formSubmitStep={bookViewingFormStep}
            setFormSubmitStep={setBookViewingFormStep}
            fixedOfficeTypeTranslation={fixedOfficeTypeTranslation}
            seatsInfo={seatsInfo}
            listingPrice={listingPrice}
            areaMin={areaMin}
            areaMax={areaMax}
            calculatedSeats={calculatedSeats}
          />
        ) : showBookingMonthForm ? (
          <BookingMonthForm
            className={css.bookingForm}
            formId="BookingPanel"
            submitButtonWrapperClassName={css.bookButtonWrapper}
            unitType={unitType}
            onSubmit={onSubmit}
            price={price}
            listingId={listingId}
            isOwnListing={isOwnListing}
            timeSlots={timeSlots}
            fetchTimeSlotsError={fetchTimeSlotsError}
            onFetchTransactionLineItems={onFetchTransactionLineItems}
            lineItems={lineItems}
            fetchLineItemsInProgress={fetchLineItemsInProgress}
            fetchLineItemsError={fetchLineItemsError}
            intl={intl}
            additionalServices={additionalServices}
            listing={listing}
            defaultSearch={defaultSearch}
            additionalServicesSection={additionalServicesSection}
            onBackPress={() => closeBookModal(history, location)}
            bookingData={bookingData}
            isEmployeeUser={isEmployeeUser}
            isCompanyUser={isCompanyUser}
            canBookWithCredits={canBookWithCredits}
          />
        ) : null}
        {/* Inquiry form is here so that it displays in mobile for fixed offices
        For desktop it is displayed from SectionBookingPanel.js */}
        {!bookViewingFormStep && isFixedOffice && (
          <div
            className={classNames(!isInquiryPressed && css.inquiryFormWrapper, css.hideOnDesktop)}
          >
            <InquiryForm
              onFormSubmit={handleInquiryFormSubmit}
              isFixed={isFixedOffice}
              currentUserInfoForEmail={currentUserInfoForEmail}
              isPressed={isInquiryPressed}
              setPressed={setInquiryPressed}
            />
          </div>
        )}
      </ModalInMobile>
      <div className={css.openBookingForm}>
        {!isFixedOffice && (
          <div className={css.priceContainer}>
            <section className={css.priceValue}>
              {isEmployeeUser || isCompanyUser ? (
                <FormattedMessage
                  id="BookingPanel.credit.creditsValue"
                  values={{ credits: listingCredits }}
                />
              ) : (
                formattedPrice
              )}
              <section className={css.perUnit}>
                <FormattedMessage style={{ display: 'inline' }} id={unitTranslationKey} />
              </section>
            </section>
          </div>
        )}

        {/* add conditional here to show a requets booking button instead of book now button */}
        {showBookingTimeForm || showBookingDatesForm || showBookingMonthForm || isFixedOffice ? (
          <ButtonNew
            className={classNames(css.bookButton, isFixedOffice && css.buttonWide)}
            type={BUTTON_TYPES.GREEN}
            size={BUTTON_SIZES.LARGE}
            mobileButtonPosition={MOBILE_POSITION.RIGHT}
            onClick={() => openBookModal(isOwnListing, isClosed, history, location)}
          >
            {!requireShowing ? (
              <FormattedMessage id="BookingPanel.ctaButtonMessage" />
            ) : isFixedOffice ? (
              <FormattedMessage id="BookingPanel.ctaButtonEnquireFixedOffice" />
            ) : (
              <FormattedMessage id="BookingPanel.ctaButtonEnquire" />
            )}
          </ButtonNew>
        ) : isClosed ? (
          <div className={css.closedListingButton}>
            <FormattedMessage id="BookingPanel.closedListingButtonText" />
          </div>
        ) : null}
      </div>
    </div>
  );
};

BookingPanel.defaultProps = {
  rootClassName: null,
  className: null,
  titleClassName: null,
  isOwnListing: false,
  subTitle: null,
  unitType: config.bookingUnitType,
  monthlyTimeSlots: null,
  lineItems: null,
  fetchLineItemsError: null,
  defaultSearch: null,
  showingTimeSlots: null,
  bookingData: null,
};

BookingPanel.propTypes = {
  rootClassName: string,
  className: string,
  titleClassName: string,
  listing: oneOfType([propTypes.listing, propTypes.ownListing, object]),
  isOwnListing: bool,
  unitType: propTypes.bookingUnitType,
  onSubmit: func.isRequired,
  subTitle: oneOfType([node, string]),
  onManageDisableScrolling: func.isRequired,
  onFetchTimeSlots: func.isRequired,
  monthlyTimeSlots: object,
  onFetchTransactionLineItems: func.isRequired,
  lineItems: array,
  fetchLineItemsInProgress: bool.isRequired,
  fetchLineItemsError: propTypes.error,
  defaultSearch: object,
  showingTimeSlots: object,
  bookingData: object,

  // from withRouter
  history: shape({
    push: func.isRequired,
  }).isRequired,
  location: shape({
    search: string,
  }).isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

export default compose(withRouter, injectIntl)(BookingPanel);
