import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Redirect, withRouter } from 'react-router';
import { compose } from 'redux';
import { IconSpinner, NamedRedirect, Page } from '../../components';
import { TopbarContainer } from '..';
import { checkingInvitation, fetchCompanyAccount, acceptInvitation } from './InvitationPage.duck';
import routeConfiguration from '../../routeConfiguration';
import { createResourceLocatorString } from '../../util/routes';
import css from './InvitationPage.module.scss';
import { EmployeeCreateAccountForm } from '../../forms';
import { injectIntl } from 'react-intl';
import classNames from 'classnames';
import { withViewport } from '../../util/contextHelpers';
import { ensureUser } from '../../util/data';
import get from 'lodash/get';
import { signupEmployee } from '../../ducks/Auth.duck';

const MAX_MOBILE_SCREEN_WIDTH = 768;

const InvitationPageComponent = (props) => {
  const {
    intl,
    location,
    history,
    onCheckingInvitation,
    checkingInProgress,
    nextDestination,
    error,
    viewport,
    onFetchCompanyAccount,
    companyAccount,
    fetchCompanyAccountInProgress,
    fetchCompanyAccountError,
    onSignupEmployee,
    signupInProgress,
    isAuthenticated,
    currentUser,
    acceptInProgress,
    acceptError,
    onAcceptInvitation,
  } = props;

  const { search, pathname } = location;
  const searchParams = new URLSearchParams(search);
  const email = searchParams.get('email');
  const companyId = searchParams.get('companyId');

  const isMobileLayout = viewport.width < MAX_MOBILE_SCREEN_WIDTH;
  const ensuredCompanyAccount = ensureUser(companyAccount);
  const organization = get(
    ensuredCompanyAccount,
    'attributes.profile.publicData.organization',
    null
  );
  const { teamMemberData } = get(ensuredCompanyAccount, 'attributes.profile.metadata', {});
  useEffect(() => {
    if (!email || !companyId) {
      history.push(createResourceLocatorString('LandingPage', routeConfiguration()));
    }
  }, [email, companyId]);

  useEffect(() => {
    const user = ensureUser(currentUser);
    if (
      isAuthenticated &&
      !!user.id &&
      (nextDestination === 'currentPage' || nextDestination === 'LoginPage')
    ) {
      // call api accept the invitation
      onAcceptInvitation({ email, companyId, teamMemberData, user: ensuredCompanyAccount });
    } else if (isAuthenticated && !!user.id && nextDestination === 'LandingPage') {
      history.push(createResourceLocatorString('LandingPage', routeConfiguration()));
    } else if (!isAuthenticated && nextDestination === 'LoginPage') {
      const from = `${pathname}${search}`;
      history.push(createResourceLocatorString('LoginPage', routeConfiguration()), {
        from,
      });
    } else if (!isAuthenticated && nextDestination === 'currentPage' && email && companyId) {
      onFetchCompanyAccount({ companyId });
      onCheckingInvitation({
        email,
        companyId,
      });
    }
  }, [isAuthenticated, currentUser, nextDestination, email, companyId]);

  const notFoundPageUrl = createResourceLocatorString('NotFoundPage', routeConfiguration());

  const handleEmployeeCreateFormSubmit = (values) => {
    const { firstName, lastName, password } = values;
    onSignupEmployee({
      firstName: firstName.trim(),
      lastName: lastName.trim(),
      password,
      email,
      companyAccountId: companyId,
    });
  };

  const signupPage = (
    <Page className={css.page}>
      <TopbarContainer
        currentPage="InvitaionPage"
        className={classNames({ [css.topBarContainer]: isMobileLayout })}
      />
      <div className={css.pageContainer}>
        <h1 className={css.pageTitle}>{intl.formatMessage({ id: 'InvitationPage.title' })}</h1>
        <p className={css.pageContent}>
          {intl.formatMessage({ id: 'InvitationPage.content' }, { organization: organization })}
        </p>
        <div className={css.formWrapper}>
          <EmployeeCreateAccountForm
            intl={intl}
            onSubmit={handleEmployeeCreateFormSubmit}
            inProgress={signupInProgress}
          />
        </div>
      </div>
    </Page>
  );

  return (
    <>
      {checkingInProgress || fetchCompanyAccountInProgress || acceptInProgress ? (
        <div className={css.spinnerWrapper}>
          <IconSpinner className={css.spinner} />
        </div>
      ) : !error && !fetchCompanyAccountError && !acceptError ? (
        signupPage
      ) : (
        <Redirect to={notFoundPageUrl} />
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  const { isAuthenticated, signupInProgress } = state.Auth;
  const { currentUser } = state.user;
  const {
    checkingInProgress,
    nextDestination,
    error,
    companyAccount,
    fetchCompanyAccountInProgress,
    fetchCompanyAccountError,
    acceptInProgress,
    acceptError,
  } = state.InvitationPage;

  return {
    checkingInProgress,
    nextDestination,
    error,
    companyAccount,
    fetchCompanyAccountInProgress,
    fetchCompanyAccountError,
    signupInProgress,
    isAuthenticated,
    currentUser,
    acceptInProgress,
    acceptError,
  };
};

const mapDispatchToProps = (dispatch) => ({
  onCheckingInvitation: (params) => dispatch(checkingInvitation(params)),
  onFetchCompanyAccount: (params) => dispatch(fetchCompanyAccount(params)),
  onSignupEmployee: (params) => dispatch(signupEmployee(params)),
  onAcceptInvitation: (params) => dispatch(acceptInvitation(params)),
});

const InvitationPage = compose(
  withRouter,
  injectIntl,
  withViewport,
  connect(mapStateToProps, mapDispatchToProps)
)(InvitationPageComponent);

export default InvitationPage;
