import React, { useState, useEffect, useCallback, useMemo, memo } from 'react';
import classNames from 'classnames';
import {
  any,
  arrayOf,
  bool,
  func,
  number,
  oneOf,
  oneOfType,
  shape,
  string,
  node,
} from 'prop-types';
import { useHistory } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';

import { sortByNearbySpaces } from '../../../../containers/SearchPage/SearchPage.duck';
import { updateSort } from '../../../../ducks/SearchPageNew.duck';
import { defaultLocationBounds } from '../../../../default-location-searches';

import config from '../../../../config';
import { ComboBoxNew } from '../../../../components_new';
import { NamedLink } from '../../../../components';
import { ALLOWED_CHARS_IN_LOCATION_INPUT } from '../../../../util/regex';
import { inBrowser } from '../../../../util/device';

import GeocoderGoogleMaps from '../../model/GeocoderGoogleMaps';
import { KEY_CODES } from '../../config';
import { ComboBoxClearButton } from '../index';

import CurrentLocationIcon from './CurrentLocationIcon';
import ListingIcon from './ListingIcon';
import LocationIcon from './LocationIcon';

import css from './LocationCombobox.module.scss';

const { defaults } = config.maps.search;

const geocoder = new GeocoderGoogleMaps();

/* Using for reset and default myLocation */
const EMPTY_LOCATION = {
  address: '',
  bounds: defaultLocationBounds,
  origin: {},
};

const Item = memo(({ onClick, text, isHighlighted }) => (
  <li
    className={classNames(css.locationOptionWrapper, isHighlighted && css.highlighted)}
    onClick={onClick}
  >
    <LocationIcon />
    <span>{text}</span>
  </li>
));

const LocationComboBox = memo(
  ({
    isOpen,
    onBlur,
    onFocus,
    onChange,
    currentLocation,
    updatePredictions,
    currentPredictions,
    inputRef,
    isMobile = false,
  }) => {
    const history = useHistory();
    const intl = useIntl();
    const dispatch = useDispatch();
    const [localValue, setLocalValue] = useState('');
    const [listingPredictions, setListingPredictions] = useState([]);
    const [finalList, setFinalList] = useState(defaults);
    const [highlightedIndex, setHighlightedIndex] = useState(0);
    const [expandDropdown, setExpandDropdown] = useState(false);
    const [predictionsLoading, setPredictionsLoading] = useState(false);
    const [totalPredictions, setTotalPredictions] = useState(0);

    useEffect(() => {
      /* Meaning the local value does not match state location */
      if (localValue === '' && !!currentLocation?.search && currentLocation?.search?.length) {
        setLocalValue(currentLocation?.search);
      }
    }, [currentLocation, localValue]);

    useEffect(() => {
      const { search } = currentLocation || {};
      const selectedItemIndex = finalList.findIndex(
        (item) => item?.predictionPlace?.address === search || item?.place_name === search
      );
      setHighlightedIndex(selectedItemIndex);
    }, [currentLocation, finalList]);

    const locationsNames = useMemo(
      () => (inBrowser() ? JSON.parse(window.localStorage.getItem('locationNames')) : []),
      []
    );

    /**
     * @param address {String}
     * @param bounds {Object | Null}
     * @param origin {Object | Null}
     * @param closeModal {Boolean}
     * */
    const selectPlace = useCallback(
      ({ address, bounds, origin, closeModal = true }) => {
        const payload = {
          search: address,
          selectedPlace: {
            address,
            bounds,
            origin,
          },
        };
        setLocalValue(payload.search);
        onChange({ payload, closeModal });
      },
      [onChange]
    );

    const getUserLocation = useCallback(() => {
      /* Getting current location and saving it for later use, if needed */
      geocoder
        .getPlaceDetails({
          id: 'current-location',
          predictionPlace: {},
        })
        .then((result) => selectPlace({ ...result }));

      setLocalValue(currentLocation?.search);
      if (Array.isArray(currentPredictions) && currentPredictions.length) {
        setFinalList(currentPredictions);
      }
    }, [currentLocation?.search, currentPredictions, selectPlace]);

    const clear = useCallback(() => {
      selectPlace({ ...EMPTY_LOCATION, closeModal: false });
      setFinalList(defaults);
      setListingPredictions([]);
      setExpandDropdown(false);
    }, []);

    const closeDropdown = useCallback(() => {
      onBlur();
      inputRef.current && inputRef.current?.blur();
    }, [inputRef, onBlur]);

    const handleInput = async (e) => {
      const {
        currentTarget: { value },
      } = e;

      /* Testing for invalid characters */
      if (!ALLOWED_CHARS_IN_LOCATION_INPUT.test(value)) {
        e.preventDefault();
        setLocalValue(localValue);
        inputRef.current.value = localValue;
        return;
      } else {
        setLocalValue(value);
      }

      /* Starting main function */
      setPredictionsLoading(true);
      if (!!value) {
        /* Getting locations */
        const { predictions: locationPredictions } = await geocoder.getPlacePredictions(value);
        setFinalList(locationPredictions);
        updatePredictions(locationPredictions);

        const filtered = value
          ? locationsNames
              .filter(({ name }) => name.toLowerCase().match(value.toLowerCase()))
              .sort((a, b) => (a.title > b.title ? 1 : -1))
          : [];

        setListingPredictions(filtered);

        /* Expanding dropdown logic */
        const shouldExpand = !!(
          (locationPredictions.length &&
            locationPredictions.some((p) => p.description.length > 50)) ||
          (listingPredictions.length && listingPredictions.some((p) => p.name.length > 50))
        );
        setExpandDropdown(shouldExpand);

        setPredictionsLoading(false);
      } else {
        setListingPredictions([]);
        setFinalList(defaults);
        setPredictionsLoading(false);
      }
    };

    const selectPlaceOnEnter = useCallback(() => {
      const locationPredictionHighlighted =
        (highlightedIndex < finalList.length && highlightedIndex !== -1) || !localValue;
      if (locationPredictionHighlighted) {
        const isDefaultPrediction = 'predictionPlace' in finalList[highlightedIndex];
        isDefaultPrediction
          ? selectPlace(finalList[highlightedIndex]?.predictionPlace)
          : geocoder.getPlaceDetails(finalList[highlightedIndex]).then(selectPlace);
      } else {
        const highlightedLocation = listingPredictions[highlightedIndex - finalList.length];
        if (!!highlightedLocation) {
          const listingUrl = `/location/${highlightedLocation.slug}/${highlightedLocation.id}`;
          history.push(listingUrl);
        }
      }
    }, [highlightedIndex, finalList, localValue, selectPlace, listingPredictions, history]);

    const handleKeyDown = useCallback(
      (e) => {
        const { key } = e;
        const locationPredictionsLength = finalList.length;
        const listingPredictionsLength = listingPredictions.length;
        const totalAmountOfPredictions = locationPredictionsLength + listingPredictionsLength;

        if (key === KEY_CODES.ESCAPE) {
          closeDropdown();
        } else if (key === KEY_CODES.TAB) {
          onBlur();
        } else if (key === KEY_CODES.ENTER) {
          e.preventDefault();
          selectPlaceOnEnter();
        } else if (key === KEY_CODES.DOWN) {
          if (highlightedIndex < totalAmountOfPredictions - 1) {
            setHighlightedIndex(highlightedIndex + 1);
          }
        } else if (key === KEY_CODES.UP) {
          highlightedIndex > -1 && setHighlightedIndex(highlightedIndex - 1);
        }
      },
      [
        finalList.length,
        listingPredictions.length,
        closeDropdown,
        onBlur,
        selectPlaceOnEnter,
        highlightedIndex,
      ]
    );

    useEffect(() => {
      setTotalPredictions(finalList.length + listingPredictions.length);
    }, [finalList.length, listingPredictions.length]);

    return (
      <div className={classNames(css.locationComboboxWrapper, isMobile && css.mobile)}>
        <ComboBoxNew
          id={'location'}
          inputWrapperClassName={css.locationComboboxInputWrapper}
          inputClassName={isMobile ? css.locationComboboxInputMobile : null}
          className={classNames(css.locationCombobox, isMobile && css.mobile)}
          focusedClassName={css.locationComboboxFocused}
          dropdownClassName={classNames(
            css.locationComboboxDropdown,
            expandDropdown && css.expanded
          )}
          placeholder={intl.formatMessage({
            id: 'ListingSearchForm.locationSearchInput.locationOrName',
          })}
          value={localValue}
          isOpen={isOpen}
          onFocus={onFocus}
          onBlur={onBlur}
          onInput={handleInput}
          onKeyDown={handleKeyDown}
          inputRef={inputRef}
          isMobile={isMobile}
        >
          <div
            className={classNames(css.locationComboboxDropdownContainer, isMobile && css.mobile)}
          >
            {predictionsLoading && <div className={css.locationComboboxOverlay} />}
            {!!finalList.length ? (
              <div
                className={css.locationListWrapper}
                onClick={() => {
                  dispatch(sortByNearbySpaces(false));
                }}
              >
                <div className={css.listTitle}>
                  {intl.formatMessage({ id: 'ListingSearchForm.locationSearchInput.location' })}
                </div>
                <ul className={css.locationOptionList}>
                  {finalList.map((item, i) => (
                    <Item
                      key={i}
                      isHighlighted={i === highlightedIndex}
                      text={item?.predictionPlace?.address || item?.description}
                      onClick={async () => {
                        const defaultPrediction = !!item?.place_id;
                        selectPlace(
                          defaultPrediction
                            ? await geocoder.getPlaceDetails(item)
                            : item.predictionPlace
                        );
                        setHighlightedIndex(i);
                      }}
                    />
                  ))}
                </ul>
              </div>
            ) : null}
            {listingPredictions.length && !!localValue ? (
              <div
                className={classNames(
                  (finalList.length || (!finalList.length && !localValue)) &&
                    css.listingsWrapperWithMargin
                )}
              >
                <h4 className={css.listTitle}>
                  {intl.formatMessage({ id: 'ListingSearchForm.locationSearchInput.spaces' })}
                </h4>
                <ul className={css.listingOptionList}>
                  {listingPredictions.map(({ id, slug, name }, i) => (
                    <NamedLink
                      key={`prediction_${id}_${slug}`}
                      className={classNames(
                        css.locationOptionWrapper,
                        highlightedIndex === i + finalList.length && css.highlighted
                      )}
                      name="LocationPage"
                      params={{ id, slug }}
                    >
                      <ListingIcon />
                      <span>{name}</span>
                    </NamedLink>
                  ))}
                </ul>
              </div>
            ) : null}
          </div>
          {!localValue && (
            <button
              className={css.currentLocationOption}
              type="button"
              onClick={() => {
                getUserLocation();
                dispatch(sortByNearbySpaces(true));
                dispatch(updateSort({ field: 'distance', order: 'asc', isListingFilter: false }));
              }}
            >
              <CurrentLocationIcon />
              <span>
                {intl.formatMessage({
                  id: 'ListingSearchForm.locationSearchInput.useCurrentLocation',
                })}
              </span>
            </button>
          )}
          {!!localValue && <ComboBoxClearButton noMargin={totalPredictions} onClick={clear} />}
        </ComboBoxNew>
      </div>
    );
  }
);

LocationComboBox.propTypes = {
  isOpen: bool.isRequired,
  onBlur: func,
  onFocus: func,
  onChange: func.isRequired,
  currentLocation: shape({
    search: string,
    selectedPlace: shape({
      address: string,
      bounds: shape({
        ne: shape({
          _sdkType: oneOf(['LatLng']),
          lat: number,
          lng: number,
        }),
        sw: shape({
          _sdkType: oneOf(['LatLng']),
          lat: number,
          lng: number,
        }),
      }),
      origin: shape({
        _sdkType: oneOf(['LatLng']),
        lat: number,
        lng: number,
      }),
    }),
  }),
  updatePredictions: func,
  currentPredictions: arrayOf(any),
  inputRef: oneOfType([func, shape({ node })]),
};

export default LocationComboBox;
