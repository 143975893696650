import React, { useState } from 'react';
import { arrayOf, bool, func, shape, string } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import { maxLength, required, composeValidators } from '../../util/validators';
import { Form, Button, FieldTextInput, LanguageSelect, SecondaryButton } from '../../components';
import CustomCategorySelectFieldMaybe from './CustomCategorySelectFieldMaybe';

import css from './EditListingDescriptionForm.module.scss';
import { modernMTTranslate } from '../../util/modernmt';

const TITLE_MAX_LENGTH = 60;

const EditListingDescriptionFormComponent = (props) => {
  const [isFetchingLangInProgress, updateFetchingLangStatus] = useState(false);
  return (
    <FinalForm
      {...props}
      mutators={{
        updateTranslatedDescription: (args, state, utils) => {
          utils.changeValue(state, 'translatedDescription', () => args[0]);
        },
      }}
      render={(formRenderProps) => {
        const {
          categoryOptions,
          className,
          disabled,
          ready,
          handleSubmit,
          intl,
          form,
          values,
          invalid,
          pristine,
          saveActionMsg,
          updated,
          updateInProgress,
          fetchErrors,
        } = formRenderProps;

        const titleMessage = intl.formatMessage({ id: 'EditListingDescriptionForm.title' });
        const titlePlaceholderMessage = intl.formatMessage({
          id: 'EditListingDescriptionForm.titlePlaceholder',
        });
        const titleRequiredMessage = intl.formatMessage({
          id: 'EditListingDescriptionForm.titleRequired',
        });
        const maxLengthMessage = intl.formatMessage(
          { id: 'EditListingDescriptionForm.maxLength' },
          {
            maxLength: TITLE_MAX_LENGTH,
          }
        );

        const descriptionMessage = intl.formatMessage({
          id: 'EditListingDescriptionForm.description',
        });
        const descriptionPlaceholderMessage = intl.formatMessage({
          id: 'EditListingDescriptionForm.descriptionPlaceholder',
        });
        const maxLength60Message = maxLength(maxLengthMessage, TITLE_MAX_LENGTH);
        const descriptionRequiredMessage = intl.formatMessage({
          id: 'EditListingDescriptionForm.descriptionRequired',
        });

        const fetchTranslations = async () => {
          updateFetchingLangStatus(true);
          const { originLang, translationLang, description } = values;

          const response = await modernMTTranslate({
            source: originLang,
            target: translationLang,
            text: description,
          });

          form.mutators.updateTranslatedDescription(response?.translation);
          updateFetchingLangStatus(false);
        };

        const { updateListingError, createListingDraftError, showListingsError } =
          fetchErrors || {};
        const errorMessageUpdateListing = updateListingError ? (
          <p className={css.error}>
            <FormattedMessage id="EditListingDescriptionForm.updateFailed" />
          </p>
        ) : null;

        // This error happens only on first tab (of EditListingWizard)
        const errorMessageCreateListingDraft = createListingDraftError ? (
          <p className={css.error}>
            <FormattedMessage id="EditListingDescriptionForm.createListingDraftError" />
          </p>
        ) : null;

        const errorMessageShowListing = showListingsError ? (
          <p className={css.error}>
            <FormattedMessage id="EditListingDescriptionForm.showListingFailed" />
          </p>
        ) : null;

        const classes = classNames(css.root, className);
        const submitReady = (updated && pristine) || ready;
        const submitInProgress = updateInProgress;
        const submitDisabled = invalid || disabled || submitInProgress;

        const canFetchTranslation =
          values.originLang && values.translationLang && values.description;

        return (
          <Form className={classes} onSubmit={handleSubmit}>
            {errorMessageCreateListingDraft}
            {errorMessageUpdateListing}
            {errorMessageShowListing}
            <h5 className={css.fieldTitle}>
              {intl.formatMessage({ id: 'EditListing.listingOriginLangSelectLabel' })}
            </h5>
            <p className={css.fieldText}>
              {intl.formatMessage({ id: 'EditListing.listingOriginLangSelectText' })}
            </p>
            <LanguageSelect id="originLang" name="originLang" className={css.title} />

            <FieldTextInput
              id="title"
              name="title"
              className={css.title}
              type="text"
              label={titleMessage}
              placeholder={titlePlaceholderMessage}
              maxLength={TITLE_MAX_LENGTH}
              validate={composeValidators(required(titleRequiredMessage), maxLength60Message)}
              autoFocus
            />

            <CustomCategorySelectFieldMaybe
              id="category"
              name="category"
              categoryOptions={categoryOptions}
              intl={intl}
            />

            <FieldTextInput
              id="description"
              name="description"
              className={css.description}
              type="textarea"
              label={descriptionMessage}
              placeholder={descriptionPlaceholderMessage}
              validate={composeValidators(required(descriptionRequiredMessage))}
            />

            <h5 className={css.fieldTitle}>
              {intl.formatMessage({ id: 'EditListing.translationLangSelectLabel' })}
            </h5>

            <p className={css.fieldText}>
              <FormattedMessage id="EditListing.translationFetchInfoText" />
            </p>
            <div className={css.translationFetch}>
              <LanguageSelect
                id="translationLang"
                name="translationLang"
                label={intl.formatMessage({ id: 'EditListing.translationLangLabel' })}
                optionToBlock={values.originLang}
              />
              <SecondaryButton
                type="button"
                onClick={fetchTranslations}
                disabled={!canFetchTranslation}
                inProgress={isFetchingLangInProgress}
              >
                <FormattedMessage id="EditListing.fetchTranslationButtonText" />
              </SecondaryButton>
            </div>

            {!isFetchingLangInProgress && values.translatedDescription && (
              <FieldTextInput
                type="textarea"
                id="translatedDescription"
                name="translatedDescription"
                className={css.description}
                label={intl.formatMessage({ id: 'EditListing.fetchedTranslationInputLabel' })}
                validate={composeValidators(required(descriptionRequiredMessage))}
                required="false"
              />
            )}

            <Button
              className={css.submitButton}
              type="submit"
              inProgress={submitInProgress}
              disabled={submitDisabled}
              ready={submitReady}
            >
              {saveActionMsg}
            </Button>
          </Form>
        );
      }}
    />
  );
};

EditListingDescriptionFormComponent.defaultProps = { className: null, fetchErrors: null };

EditListingDescriptionFormComponent.propTypes = {
  className: string,
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    createListingDraftError: propTypes.error,
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
  categoryOptions: arrayOf(
    shape({
      key: string.isRequired,
      label: string.isRequired,
    })
  ),
};

export default compose(injectIntl)(EditListingDescriptionFormComponent);
