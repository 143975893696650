import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconSocialMediaLinkedin.module.scss';

const IconSocialMediaLinkedin = (props) => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);
  return (
    <svg
      className={classes}
      width="17"
      height="17"
      viewBox="0 0 17 17"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 0C0.447715 0 0 0.447715 0 1V16C0 16.5523 0.447715 17 1 17H16C16.5523 17 17 16.5523 17 16V1C17 0.447715 16.5523 0 16 0H1ZM2.54075 6.4182H5.18724V14.3698H2.54075V6.4182ZM5.36154 3.95845C5.34437 3.1788 4.78684 2.58497 3.88149 2.58497C2.97614 2.58497 2.38426 3.1788 2.38426 3.95845C2.38426 4.72195 2.95865 5.33288 3.84713 5.33288H3.86405C4.78684 5.33288 5.36154 4.72195 5.36154 3.95845ZM11.6844 6.23148C13.4259 6.23148 14.7315 7.36821 14.7315 9.8106L14.7314 14.3698H12.085V10.1157C12.085 9.04715 11.702 8.31801 10.744 8.31801C10.0129 8.31801 9.57736 8.80956 9.38609 9.28432C9.31611 9.45447 9.29893 9.69154 9.29893 9.92918V14.37H6.65213C6.65213 14.37 6.68701 7.16455 6.65213 6.41841H9.29893V7.54469C9.65018 7.00327 10.2793 6.23148 11.6844 6.23148Z"
        fillRule="evenodd"
      />
    </svg>
  );
};

IconSocialMediaLinkedin.defaultProps = { rootClassName: null, className: null };

const { string } = PropTypes;

IconSocialMediaLinkedin.propTypes = { rootClassName: string, className: string };

export default IconSocialMediaLinkedin;
