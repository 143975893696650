const FixedOfficeContactEmailRespaces = (values, listing) => {
  //Sends an email to the provider with all order details. Uses Mailchimp API
  // and sends all data points as an object. This objects needs to be built and
  // the data needs to be calculated before sending.

  // Mailchimp API for sending transactional emails to provider when paymant is confirmed
  const MAILCHIMP_APP_KEY = process.env.REACT_APP_MAILCHIMP_TRANSACTIONAL_KEY;
  const mailchimp = require('@mailchimp/mailchimp_transactional')(MAILCHIMP_APP_KEY);

  const sendShowingEmail = async () => {
    // These are the variables sent to Mailchimp and accessed there by the name tag.
    let global_merge_vars = [
      {
        name: 'listingName',
        content: listing.attributes.title,
      },
      {
        name: 'fname',
        content: values.fname,
      },
      {
        name: 'address',
        content: listing.attributes.publicData.location.address,
      },
      {
        name: 'companyName',
        content: values.companyName,
      },
      {
        name: 'phonenumber',
        content: values.phonenumber,
      },
      {
        name: 'email',
        content: values.email,
      },
      {
        name: 'numberOfSeats',
        content: values.numberOfSeats,
      },
      {
        name: 'budget',
        content: values.budget,
      },
    ];

    // eslint-disable-next-line
    const response = await mailchimp.messages.sendTemplate({
      template_name: 'Showing confirmation - Respaces',
      message: {
        from_email: 'bookings@respaces.co',
        from_name: 'The Respaces Team',
        subject: `New form submission for ${listing.attributes.title}`,
        text: `A form has been submitted for interest of ${listing.attributes.title} by ${values.fname}`,
        to: [
          {
            email: 'hello@respaces.co',
            type: 'to',
          },
        ],
        global_merge_vars: global_merge_vars,
      },
      template_content: global_merge_vars,
    });
    return true;
  };

  const result = sendShowingEmail();
  return result;
};

export default FixedOfficeContactEmailRespaces;
