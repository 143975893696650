import React, { memo } from 'react';
import classNames from 'classnames';

import { TABLE_PROP_TYPES } from '../../config/propTypes';

import { ListingStatus } from '../../../index';

import { TableAction } from '..';

import css from '../../Table.module.scss';

const DesktopTable = memo(({ headers, data, onRowClick, onInitAction, tableId, intl }) => (
  <table className={css.table}>
    <thead className={css.head}>
      <tr className={css.row}>
        {headers.map((h) => (
          <th
            className={classNames(css.headerCell, h.id === 'action' && css.actionCell)}
            key={`${tableId}_header_cell_${h.id}`}
            style={{ width: h.width }}
          >
            {typeof h.label === 'string'
              ? !!h.label
                ? intl.formatMessage({ id: h.label })
                : null
              : h.label}
          </th>
        ))}
      </tr>
    </thead>
    <tbody className={css.body}>
      {data.map((row, i) => (
        <tr
          onClick={(e) => {
            onRowClick && onRowClick({ id: row.id, i });
          }}
          className={css.row}
          key={`${tableId}_table_row_${row.id}`}
        >
          {headers.map((h) => (
            <td
              className={classNames(css.bodyCell, h.id === 'action' && css.actionCell)}
              key={`${tableId}_table_cell_${h.id}`}
            >
              {h.id === 'action' ? (
                typeof row.action === 'string' ? (
                  <TableAction
                    id={row.id}
                    action={row.action}
                    status={row.status}
                    isUnassigned={tableId === 'unassigned_listings'}
                    onInitAction={onInitAction}
                  />
                ) : (
                  row.action
                )
              ) : h.id === 'status' ? (
                <ListingStatus status={row.status} />
              ) : (
                row[h.id]
              )}
            </td>
          ))}
        </tr>
      ))}
    </tbody>
  </table>
));

DesktopTable.propTypes = TABLE_PROP_TYPES;

export default DesktopTable;
