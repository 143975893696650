import React from 'react';
import { useIntl } from 'react-intl';
import { shallowEqual, useSelector } from 'react-redux';
import { NamedLink, SubscriptionManagementIcons as Icons } from '../../../components';
import { AddOneTimeCreditsForm } from '../../../forms';
import { MY_SUBSCRIPTION } from '../../../util/types';
import css from './AddOneTimeCredits.module.scss';
import get from 'lodash/get';
import { ButtonNew } from '../../../components_new';
import { BUTTON_TYPES, LINK_COLORS } from '../../../components_new/ButtonNew/ButtonNew';

const AddOneTimeCredits = (props) => {
  const intl = useIntl();
  const currentUser = useSelector((state) => state.user.currentUser, shallowEqual);

  const { selectedCreditAmount, hasChargebeePaymentSource } = props || {};
  const totalCredits = get(currentUser, 'attributes.metadata.totalCredits', 0);

  return (
    <div className={css.tabContainer}>
      <NamedLink
        name="SubscriptionManagementPage"
        params={{ tab: MY_SUBSCRIPTION, action: null }}
        className={css.backBtn}
      >
        <Icons.BackIcon className={css.backIcon} />

        <ButtonNew
          type={BUTTON_TYPES.INLINE}
          linkColor={LINK_COLORS.BLUE}
          name="SubscriptionManagementPage"
          params={{ tab: MY_SUBSCRIPTION, action: null }}
        >
          {intl.formatMessage({ id: 'MySubscriptionTab.backToMySubscription' })}
        </ButtonNew>
      </NamedLink>
      <div className={css.tabTitle}>{intl.formatMessage({ id: 'AddOneTimeCredits.title' })}</div>
      <p className={css.tabDescription}>
        {intl.formatMessage({ id: 'AddOneTimeCredits.tabDescription' }, { totalCredits })}
      </p>
      <AddOneTimeCreditsForm
        selectedCreditAmount={selectedCreditAmount}
        hasChargebeePaymentSource={hasChargebeePaymentSource}
      />
    </div>
  );
};

export default AddOneTimeCredits;
