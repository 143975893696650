import React, { useState, useRef, useEffect } from 'react';
import classNames from 'classnames';
import { arrayOf, bool, func, shape, string } from 'prop-types';

import { isMobile } from '../../../../util/device';
import { OutsideClickHandler } from '../../../../components';

import { adjustDropdownPosition } from '../../../../util/adjust_dropdown';

import css from './TimePicker.module.scss';

const NativeTimePicker = ({ id, name, options, onChange, value, disabled }) => (
  <select
    id={id}
    name={name}
    onChange={({ currentTarget: { value } }) => onChange(value)}
    className={classNames(css.timepicker, css.mobile, disabled && css.disabled)}
    disabled={disabled}
  >
    {Array.isArray(options) &&
      options.map((opt) => (
        <option
          value={opt.key}
          selected={opt.key === value}
          key={`timepicker_option_${id}_${opt.key}_${opt.label}`}
        >
          {opt.label}
        </option>
      ))}
  </select>
);

const TimePicker = ({ id, name, onChange, options, disabled, value, placeholder, last }) => {
  const [isOpen, setOpen] = useState(false);
  const optionsRef = useRef(null);

  useEffect(() => {
    adjustDropdownPosition(optionsRef);
  }, [isOpen]);

  const valueToShow = !!value
    ? options.find((opt) => opt.key === value)?.label
    : last && options.length
    ? options[0].label
    : placeholder;

  const scrollToOption = () => {
    if (value) {
      const index = options.findIndex((opt) => opt.key === value) - 1; // To display it in center
      const heightOfOption = optionsRef?.current?.firstChild.offsetHeight;

      optionsRef.current.scrollTop = index * heightOfOption - heightOfOption;
    }
  };

  const openTimepicker = () => {
    setOpen(true);
    setTimeout(() => scrollToOption(), 50);
  };

  return isMobile ? (
    <NativeTimePicker
      id={id}
      name={name}
      options={options}
      onChange={onChange}
      value={value}
      disabled={disabled}
    />
  ) : (
    <OutsideClickHandler onOutsideClick={() => setOpen(false)}>
      <div className={classNames(css.timepicker, isOpen && css.focused, disabled && css.disabled)}>
        <button
          onClick={openTimepicker}
          className={classNames(css.timepickerTrigger)}
          type="button"
          disabled={disabled}
        >
          {valueToShow}
        </button>
        {isOpen ? (
          <ul ref={optionsRef} className={classNames(css.timepickerDropdown, last && css.last)}>
            {options.map((opt) => (
              <li
                id={opt.key === value ? 'timepicker_option_selected' : null}
                className={classNames(css.timepickerOption, opt.key === value && css.selected)}
              >
                <button
                  onClick={() => {
                    onChange(opt.key);
                    setOpen(false);
                  }}
                  type="button"
                >
                  {opt.label}
                </button>
              </li>
            ))}
          </ul>
        ) : null}
      </div>
    </OutsideClickHandler>
  );
};

TimePicker.defaultProps = {
  disabled: false,
  value: '',
  placeholder: null,
  last: false,
};

TimePicker.propTypes = {
  id: string.isRequired,
  name: string.isRequired,
  onChange: func.isRequired,
  options: arrayOf(
    shape({
      key: string,
      label: string,
    })
  ).isRequired,
  disabled: bool,
  value: string,
  placeholder: string,
  last: bool,
};

export default TimePicker;
