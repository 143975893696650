import { calculateQuantityFromHours, daysBetween } from '../../../util/dates';
import { DAILY_BOOKING, HOURLY_BOOKING, LINE_ITEM_CREDITS } from '../../../util/types';
import { convertPriceToCredit } from '../../../util/currency';
import get from 'lodash/get';
import { types as sdkTypes } from '../../../util/sdkLoader';
import config from '../../../config';

const { Money } = sdkTypes;
const defaultPrice = new Money(0, config.currency);

export const calcTotalUsedCreditsFromBooking = (tx) => {
  //Get amount of credit from a b2b transaction
  const { listing, booking, attributes } = tx;
  const bookingType = get(attributes, 'protectedData.bookingType', null);
  const bookingStart = get(booking, 'attributes.displayStart', null);
  const bookingEnd = get(booking, 'attributes.displayEnd', null);
  const listingType = get(listing, 'attributes.publicData.category', null);
  const linetItems = get(attributes, 'lineItems', []);
  const lineItemCreditsCommission = linetItems.find((i) => i.code && i.code === LINE_ITEM_CREDITS);
  const seats = get(lineItemCreditsCommission, 'seats', null);
  const lineTotal = get(lineItemCreditsCommission, 'lineTotal', defaultPrice);
  const unitPrice = get(lineItemCreditsCommission, 'unitPrice', defaultPrice);
  // credit commission is negative amount
  const newUnitPrice = new Money(-unitPrice.amount, unitPrice.currency);
  const newLineTotal = new Money(-lineTotal.amount, lineTotal.currency);
  switch (bookingType) {
    case DAILY_BOOKING:
      const totalDays = bookingStart && bookingEnd ? daysBetween(bookingStart, bookingEnd) : 1;
      const creditsDailyValue = convertPriceToCredit(newUnitPrice);
      return creditsDailyValue && listingType === 'meeting'
        ? creditsDailyValue * (totalDays || 1)
        : creditsDailyValue * (seats || 1) * (totalDays || 1);
    case HOURLY_BOOKING:
      const totalHours =
        bookingStart && bookingEnd ? calculateQuantityFromHours(bookingStart, bookingEnd) : 1;
      const creditsHourlyValue = convertPriceToCredit(newUnitPrice);
      return creditsHourlyValue && creditsHourlyValue * totalHours;
    default:
      return convertPriceToCredit(newLineTotal);
  }
};
