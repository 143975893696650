import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Modal, IconSpinner, TeamAccessMember } from '../../../components';
import TeamAccessTabNoMember from './TeamAccessTabNoMember';
import { SubscriptionManagementIcons as Icons, PaginationLinks } from '../../../components';
import { parse } from '../../../util/urlHelpers';
import { getLocale } from '../../../util/localeHelper';

import css from './TeamAccessTab.module.scss';
import { TeamAccessTabForm } from '../../../forms';
import classNames from 'classnames';
import { useToggleCompoment } from '../../../util/hooks';
import { sortingList } from '../../../marketplace-custom-config';
import sortBy from 'lodash/sortBy';
import reverse from 'lodash/reverse';
import { ContactButtons } from './TeamAccessTabNoMember';
import { intlShape } from '../../../util/reactIntl';
import { updateHubspotPipeline } from '../../../util/api';
import { STATUS_REGISTERED, STATUS_DELETED } from '../../../util/types';

const onManageDisableScrolling = () => null;

const labelListInitial = [-1, 6];

const PER_PAGE = 9;

const SORTING_BY_EMAIL = 'email';
const SORTING_BY_NAME = 'name';
const SORTING_BY_ACCESS = 'access';
const SORTING_BY_CREDITS_USED = 'credits_used';

const TeamAccessTab = (props) => {
  const {
    intl,
    members,
    fetchInProgress,
    isMobileLayout,
    params,
    contactModalOpen,
    openContactModal,
    closeContactModal,
    onAddTeamMemberList,
    addTeamMembersInProgress,
    location,
    onDeleteMember,
    onEditMemberAccess,
    deleteMemberInProgress,
    deleteMemberError,
    editMemberAccessInProgress,
    editMemberAccessError,
    currentUser,
  } = props;

  const [emailList, setEmailList] = useState([]);
  const [emailInputFocused, setEmailInputFocuses] = useState(false);
  const [labelBoardOpen, setLabelBoardOpen] = useState(false);
  const [labelList, setLabelList] = useState(labelListInitial);
  const [selectedLabel, setSelectedLabel] = useState(null);
  const [membersInPage, setMembersInPage] = useState([]);
  const [sortingBoardOpen, setSortingBoardOpen] = useState(false);
  const [sortingType, setSortingType] = useState(SORTING_BY_NAME);
  const [sortingIncreasing, setSortingIncreasing] = useState(true);

  const totalActiveMembers = members.filter((member) => member.status !== STATUS_DELETED);
  const totalRegisteredMembers = members.filter((member) => member.status === STATUS_REGISTERED);

  const emailInputRef = useRef();
  const contactModalRef = useRef();
  const labelBoardRef = useRef();

  const locale = getLocale();

  const { componentRef: sortingBoardRef, parentComponentRef: tabContainerRef } = useToggleCompoment(
    () => setSortingBoardOpen(false)
  );

  const { search } = location;
  const { page = 1 } = parse(search);

  const totalPages =
    members.length % PER_PAGE > 0
      ? parseInt(members.length / PER_PAGE) + 1
      : parseInt(members.length / PER_PAGE);
  const pagination = {
    totalPages: totalPages,
    totalItems: members.length,
    page: page,
    perPage: PER_PAGE,
  };

  const sortingProcess = useMemo(() => {
    switch (sortingType) {
      case SORTING_BY_EMAIL: {
        return sortingIncreasing ? sortBy(members, ['email']) : reverse(sortBy(members, ['email']));
      }
      case SORTING_BY_NAME: {
        return sortingIncreasing
          ? sortBy(members, ['firstName', 'lastName'])
          : reverse(sortBy(members, ['firstName', 'lastName']));
      }
      case SORTING_BY_ACCESS: {
        return sortingIncreasing
          ? sortBy(members, ['credits'])
          : reverse(sortBy(members, ['credits']));
      }
      case SORTING_BY_CREDITS_USED: {
        return sortingIncreasing
          ? sortBy(members, ['usedCredits'])
          : reverse(sortBy(members, ['usedCredits']));
      }
      default:
        break;
    }
  }, [members, sortingType, sortingIncreasing]);

  useEffect(() => {
    const newMembers = sortingProcess;
    setMembersInPage([...newMembers.slice((page - 1) * PER_PAGE, page * PER_PAGE)]);
  }, [members, page, sortingType, sortingIncreasing, sortingProcess]);

  useEffect(() => {
    const contactModalRefCurrent = contactModalRef.current;

    if (contactModalRef.current) {
      contactModalRefCurrent.addEventListener('click', handleCloseLabelBoard);
    }
    return () => {
      contactModalRefCurrent.removeEventListener('click', handleCloseLabelBoard);
    };
  }, []);

  const onSubmitContactForm = (values) => {
    if (emailList.length > 0) {
      const creditLabel = selectedLabel ? selectedLabel : -1; // set default label value is unlimited
      onAddTeamMemberList(emailList, creditLabel, locale).then(() => {
        const hubspotParams = {
          user: currentUser,
          invitedUsers: emailList.length + totalActiveMembers.length,
          activeUsers: totalRegisteredMembers.length,
        };
        updateHubspotPipeline(hubspotParams);
        setEmailList([]);
        closeContactModal();
        typeof window !== 'undefined' && window.location.reload(false);
      });
    }
  };

  const handleCloseLabelBoard = (e) => {
    if (labelBoardRef.current && !labelBoardRef.current.contains(e.target)) {
      setLabelBoardOpen(false);
    }
  };

  const handleOpenSortingBoard = () => {
    setSortingBoardOpen(true);
  };

  const handleChangeSortingType = (type) => () => {
    setSortingType(type);
    setSortingBoardOpen(false);
  };

  const handleChangeSortingOrder = () => {
    setSortingIncreasing(!sortingIncreasing);
  };

  const isNoMember = Array.isArray(members) && members.length === 0;

  const title = intl.formatMessage({
    id: 'TeamAccessTab.title',
  });

  const content = intl.formatMessage({
    id: 'TeamAccessTab.content',
  });

  const desktopContent = intl.formatMessage({
    id: 'TeamAccessTab.desktopContent',
  });

  const contactModal = (
    <Modal
      id="contactModal"
      isOpen={contactModalOpen}
      onClose={closeContactModal}
      hideCloseButton
      openClassName={css.openModal}
      scrollLayerClassName={css.scrollableLayer}
      containerClassName={css.modalContainer}
      onManageDisableScrolling={onManageDisableScrolling}
      modalRef={contactModalRef}
    >
      <TeamAccessTabForm
        intl={intl}
        onSubmitContactForm={onSubmitContactForm}
        emailList={emailList}
        setEmailList={setEmailList}
        emailInputFocused={emailInputFocused}
        setEmailInputFocuses={setEmailInputFocuses}
        emailInputRef={emailInputRef}
        labelBoardOpen={labelBoardOpen}
        setLabelBoardOpen={setLabelBoardOpen}
        labelBoardRef={labelBoardRef}
        labelList={labelList}
        setLabelList={setLabelList}
        selectedLabel={selectedLabel}
        setSelectedLabel={setSelectedLabel}
        closeContactModal={closeContactModal}
        addTeamMembersInProgress={addTeamMembersInProgress}
      />
    </Modal>
  );

  const pagingLinks =
    !isMobileLayout && pagination && pagination.totalPages > 1 ? (
      <PaginationLinks
        className={css.pagination}
        rootClassName={css.paginationWrapper}
        pageName="SubscriptionManagementPage"
        pagePathParams={params}
        pagination={pagination}
      />
    ) : null;

  const renderMemberItem = (member) => (
    <TeamAccessMember
      key={`team-access-member-${member.email}`}
      intl={intl}
      member={member}
      css={css}
      isMobileLayout={isMobileLayout}
      onDeleteMember={onDeleteMember}
      onEditMemberAccess={onEditMemberAccess}
      deleteMemberInProgress={deleteMemberInProgress}
      deleteMemberError={deleteMemberError}
      editMemberAccessInProgress={editMemberAccessInProgress}
      editMemberAccessError={editMemberAccessError}
    />
  );

  const sortByText = intl.formatMessage({
    id: 'TeamAccessTab.sortBy',
  });

  const memberColumnName = intl.formatMessage({
    id: 'TeamAccessTab.memberColumnName',
  });

  const accessColumnName = intl.formatMessage({
    id: 'TeamAccessTab.accessColumnName',
  });

  const creditsUsedColumnName = intl.formatMessage({
    id: 'TeamAccessTab.creditsUsedColumnName',
  });

  const getSortingType = () => {
    return sortingList.find((item) => item.id === sortingType).label;
  };

  const createContactText = intl.formatMessage({
    id: 'SubscriptionManagementPage.createContact',
  });

  const importContactText = intl.formatMessage({
    id: 'SubscriptionManagementPage.importContact',
  });

  return (
    <div className={css.tabContainer} ref={tabContainerRef}>
      {isMobileLayout ? (
        <p className={css.sectionContent}>{content}</p>
      ) : !isMobileLayout && !isNoMember ? (
        <>
          <h2 className={css.sectionTitle}>{title}</h2>
          <div className={css.addUserWrapper}>
            <p className={css.sectionContent}>{desktopContent}</p>
            <ContactButtons
              openContactModal={openContactModal}
              isInline={true}
              createContactText={createContactText}
              importContactText={importContactText}
            />
          </div>
        </>
      ) : null}

      {fetchInProgress ? (
        <div className={css.pageLoading}>
          <IconSpinner />
        </div>
      ) : isNoMember ? (
        <TeamAccessTabNoMember openContactModal={openContactModal} intl={intl} />
      ) : (
        <>
          <div className={css.inforWrapper}>
            <span className={css.memberNumber}>
              <strong>{members.length}</strong>&nbsp;
              {members.length === 1 ? 'member' : 'members'}
            </span>
            <div className={css.sortingWrapper}>
              <Icons.ArrowDownIcon
                className={classNames(css.sortingIcon, {
                  [css.reverse]: sortingIncreasing,
                })}
                onClick={handleChangeSortingOrder}
              />

              <span className={css.sortingBy} onClick={handleOpenSortingBoard}>
                {sortByText}&nbsp;<strong>{getSortingType()}</strong>
              </span>
              {sortingBoardOpen && (
                <div className={css.sortingBoardWrapper} ref={sortingBoardRef}>
                  {sortingList.map((item) => (
                    <div
                      key={`sorting-list-${item.id}`}
                      className={classNames(css.sortingItem, {
                        [css.active]: item.id === sortingType,
                      })}
                      onClick={handleChangeSortingType(item.id)}
                    >
                      {item.label}
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
          <div className={css.memberTableWrapper}>
            <div className={css.tableHeader}>
              <span>{memberColumnName}</span>
              <span>{accessColumnName}</span>
              {!isMobileLayout && <span>{creditsUsedColumnName}</span>}
            </div>

            <div className={css.tableBody}>
              {isMobileLayout
                ? sortingProcess.map(renderMemberItem)
                : membersInPage.map(renderMemberItem)}
            </div>
            {pagingLinks}
          </div>
        </>
      )}

      {isMobileLayout && (
        <div className={css.floatBtn} onClick={openContactModal}>
          <span className={css.floatBtnPlus}>+</span>
        </div>
      )}
      {contactModal}
    </div>
  );
};

TeamAccessTab.propType = {
  intl: intlShape.isRequired,
};

export default TeamAccessTab;
