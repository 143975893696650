import React, { useState } from 'react';
import { string } from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';

import { ImageSlider } from '..';

import OnDemand from './components/OnDemand';
import { narrowViewportMatch, touchDeviceMatch } from '../../util/device';

import onDemandImg from './assets/spring.svg';

import css from './LandingHero.module.scss';

const isMobile =
  (narrowViewportMatch && narrowViewportMatch().matches) ||
  (touchDeviceMatch && touchDeviceMatch().matches);

const LandingHero = ({ intl }) => {
  const [showVideo, setShowVideo] = useState(false);

  const spaceTypes = [
    intl.formatMessage({ id: 'SectionHero.category.coworking' }),
    intl.formatMessage({ id: 'SectionHero.category.private' }),
    intl.formatMessage({ id: 'SectionHero.category.meeting' }),
    intl.formatMessage({ id: 'SectionHero.category.studio' }),
  ];

  const animatedTextOptions = {
    category: 'text',
    autoplay: { delay: 3000 },
    direction: 'vertical',
    allowTouchMove: false,
  };

  return (
    <div className={css.container}>
      <div className={css.grid}>
        <div className={css.header}>
          <h1 className={css.title}>
            <div className={css.titleFirstRow}>
              <FormattedMessage id="SectionHero.title1" />
              <div className={css.animatedTextWrapper}>
                <ImageSlider
                  className={css.animatedText}
                  data={spaceTypes}
                  {...animatedTextOptions}
                />
              </div>
            </div>
            <FormattedMessage id="SectionHero.title2" />
          </h1>
          <h2 className={css.subtitle}>
            <FormattedMessage id="SectionHero.subTitle" />
          </h2>
        </div>
        <div className={`${css.content} ${isMobile && css.mobile}`}>
          <OnDemand />
        </div>

        <div className={css.media}>
          {!showVideo && (
            <>
              <img className={css.img} src={onDemandImg} alt="On demand bookings" />
              <button className={css.videoButton} onClick={() => setShowVideo(true)}>
                <svg
                  width="56"
                  height="56"
                  viewBox="0 0 56 56"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect x="13" y="12" width="31" height="32" fill="white" />
                  <path
                    d="M28 0C12.544 0 0 12.544 0 28C0 43.456 12.544 56 28 56C43.456 56 56 43.456 56 28C56 12.544 43.456 0 28 0ZM22.4 40.6V15.4L39.2 28L22.4 40.6Z"
                    fill="black"
                  />
                </svg>
              </button>
            </>
          )}
          {showVideo && (
            <div>
              <iframe
                title="Product video"
                className={css.video}
                src="https://player.vimeo.com/video/610629623?h=b7a051bec0&autoplay=1"
                frameBorder="0"
                allow="autoplay; fullscreen; picture-in-picture"
                allowFullScreen
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

LandingHero.props = {
  openSection: string,
};

export default injectIntl(LandingHero);
