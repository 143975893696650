import React from 'react';
import classNames from 'classnames';
import { any, bool, func, node, object, string } from 'prop-types';

import css from './ComboBoxNew.module.scss';

const ComboBoxNew = ({
  id,
  name,
  value,
  isOpen,
  placeholder,
  readOnly,
  isMobile,
  children,
  onInput,
  onFocus,
  onBlur,
  onKeyDown,
  inputRef,
  hideInput,
  className,
  inputWrapperClassName,
  inputClassName,
  focusedClassName,
  dropdownClassName,
  isRelevantFilter,
}) => {
  const comboBoxClassList = classNames(
    css.combobox,
    isMobile && css.mobile,
    isOpen && css.comboboxFocused,
    className,
    isOpen && !isMobile && focusedClassName
  );
  const dropdownClassLists = classNames(
    css.dropdown,
    dropdownClassName,
    isMobile && css.mobile,
    isRelevantFilter && css.isRelevantFilter
  );

  return (
    <>
      <div className={classNames(comboBoxClassList)}>
        {!hideInput && (
          <label className={classNames(css.label, inputWrapperClassName)} htmlFor={id}>
            <input
              ref={inputRef}
              className={classNames(css.input, inputClassName, isMobile && css.mobileInput)}
              id={id}
              name={name}
              value={value}
              onFocus={onFocus}
              onKeyDown={onKeyDown}
              placeholder={placeholder}
              onInput={onInput}
              readOnly={readOnly}
              type="text"
              autoComplete="off"
            />
          </label>
        )}
        {isOpen && <div className={dropdownClassLists}>{children}</div>}
      </div>
      {isOpen && ((!isMobile && !isRelevantFilter) || (isMobile && isRelevantFilter)) && (
        <div className={css.overlay} onClick={onBlur} />
      )}
    </>
  );
};

ComboBoxNew.defaultProps = {
  onInput: () => null,
};

ComboBoxNew.propTypes = {
  id: string,
  name: string,
  value: any,
  isOpen: bool,
  placeholder: string,
  readOnly: bool,
  isMobile: bool,
  children: node,
  onInput: func,
  onFocus: func,
  onBlur: func,
  onKeyDown: func,
  inputRef: object,
  hideInput: bool,
  className: string,
  inputClassName: string,
  focusedClassName: string,
  dropdownClassName: string,
};

export default ComboBoxNew;
