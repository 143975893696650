import React, { memo } from 'react';
import { injectIntl } from 'react-intl';
import { func } from 'prop-types';

import { ButtonNew } from '../../../../components_new';
import { BUTTON_TYPES } from '../../../../components_new/ButtonNew/ButtonNew';

import css from './NoListingsBox.module.scss';

const NoListingsBox = memo(({ onAddNewListing, intl }) => (
  <div className={css.box}>
    <h5 className={css.title}>{intl.formatMessage({ id: 'NoListingsBox.title' })}</h5>
    <p className={css.caption}>{intl.formatMessage({ id: 'NoListingsBox.caption' })}</p>
    <ButtonNew onClick={onAddNewListing} type={BUTTON_TYPES.BLUE} name="NewListingPage">
      {intl.formatMessage({ id: 'NoListingsBox.addNewListing' })}
    </ButtonNew>
  </div>
));

NoListingsBox.propTypes = {
  onAddNewListing: func,
};

export default injectIntl(NoListingsBox);
