import React, { memo } from 'react';
import { injectIntl } from 'react-intl';
import { ButtonNew } from '../../../../components_new';
import { BUTTON_TYPES } from '../../../../components_new/ButtonNew/ButtonNew';
import css from './GetStarted.module.scss';
import LocationIcon from '../Icons/LocationIcon';
import AddListingToLocationIcon from '../Icons/AddListingToLocationIcon';
import ClockIcon from '../Icons/ClockIcon';
import { useSelector } from 'react-redux';

const Steps = memo(({ intl }) => (
  <ul>
    {[{ id: 1, img: LocationIcon }, { id: 2, img: AddListingToLocationIcon }, { id: 3 }].map(
      (step) => (
        <li key={`get_started_step_${step.id}`} className={css.step}>
          <h6>
            <span>{step.id}</span>
            {intl.formatMessage({ id: `GetStarted.step_${step.id}.title` })}
            <div className={css.stepImg}>{step.img}</div>
          </h6>
          <p>{intl.formatMessage({ id: `GetStarted.step_${step.id}.text` })}</p>
        </li>
      )
    )}
  </ul>
));

const GetStarted = memo(({ onAddLocationClick, intl }) => {
  const { currentUser } = useSelector((state) => {
    const {
      user: { currentUser },
    } = state;
    return { currentUser };
  });

  const userName =
    currentUser.attributes.profile.firstName.charAt(0).toUpperCase() +
    currentUser.attributes.profile.firstName.slice(1);
  return (
    <div>
      <h5 className={css.title}>
        {intl.formatMessage({ id: 'GetStarted.title' }, { userName: userName })}
      </h5>
      <p className={css.text}>{intl.formatMessage({ id: 'GetStarted.text' })}</p>
      <Steps intl={intl} />
      <div className={css.bottomSection}>
        <ButtonNew onClick={onAddLocationClick} type={BUTTON_TYPES.BLUE}>
          {intl.formatMessage({ id: 'GetStarted.addLocation' })}
        </ButtonNew>
        <div className={css.imageWrapper}>
          {ClockIcon}
          <p className={css.clockText}>{intl.formatMessage({ id: 'GetStarted.clock.text' })}</p>
        </div>
      </div>
    </div>
  );
});

export default injectIntl(GetStarted);
