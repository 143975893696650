import React from 'react';
import { injectIntl } from 'react-intl';
import { object, string } from 'prop-types';

import { MediaAndTextSection } from '../../../../components_new';
import {
  TEXT_ALIGN,
  TEXT_ALIGN_MOBILE,
} from '../../../../components_new/MediaAndTextSection/MediaAndTextSection';
import { intlShape } from '../../../../util/reactIntl';

import imgSE from './categories_SE.svg';
import imgEN from './categories_EN.svg';

const SectionProducts = ({ intl, buttonProps, className }) => {
  const SECTION_NAME = 'LandingPage.SectionListYourSpace';

  return (
    <section className={className} id={'listYourSpace'}>
      <MediaAndTextSection
        title={{
          content: intl.formatMessage({ id: `${SECTION_NAME}.title` }),
          showMobile: true,
        }}
        subtitle={{
          content: intl.formatMessage({ id: `${SECTION_NAME}.subtitle` }),
          showMobile: true,
        }}
        caption={{
          text: intl.formatMessage({ id: `${SECTION_NAME}.text` }),
          showMobile: true,
        }}
        buttonsList={[
          {
            linkText: intl.formatMessage({ id: `${SECTION_NAME}.link` }),
            name: 'Partner',
            ...buttonProps,
          },
        ]}
        textAlignMobile={TEXT_ALIGN_MOBILE.TOP}
        textAlign={TEXT_ALIGN.RIGHT}
        media={{
          content: intl.locale === 'sv' ? imgSE : imgEN,
          showMobile: true,
        }}
      />
    </section>
  );
};

SectionProducts.propTypes = {
  intl: intlShape,
  buttonProps: object,
  className: string,
};

export default injectIntl(SectionProducts);
