import classNames from 'classnames';
import { number } from 'prop-types';
import React, { useCallback } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { Button, PopupModal } from '..';
import { resumeSubscription } from '../../containers/SubscriptionManagementPage/SubscriptionManagementPage.duck';
import { manageDisableScrolling } from '../../ducks/UI.duck';
import { OutsideClickHandler } from '../../components';
import { formatMoneyWithSpace, SUBSCRIPTION_VAT } from '../../util/currency';
import css from './ReactivateSubscriptionModal.module.scss';

const ReactivateSubscriptionModal = (props) => {
  const { isOpen, onClose, creditsNumber, totalPrice, renewalDate, setResumeSuccess } = props;
  const intl = useIntl();
  const dispatch = useDispatch();

  const resumeInProgress = useSelector(
    (state) => state.SubscriptionManagementPage.actionSubscriptionInProgress
  );
  const totalPriceWithVat = (totalPrice * (1 + SUBSCRIPTION_VAT)).toFixed(2);

  const handleReactivateSubmit = useCallback(async () => {
    await dispatch(
      resumeSubscription({
        totalCredits: creditsNumber,
        newMonthPrice: totalPriceWithVat,
      })
    );
    onClose();
    setResumeSuccess.on();
  }, [creditsNumber, dispatch, onClose, setResumeSuccess, totalPriceWithVat]);
  const onManageDisableScrolling = (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling));
  return (
    <PopupModal
      id="ReactivateSubscriptionSuccessModal"
      isOpen={isOpen}
      onClose={onClose}
      modalContainer={css.modalContainer}
      onManageDisableScrolling={onManageDisableScrolling}
      hideCloseButton
    >
      <OutsideClickHandler onOutsideClick={onClose}>
        <div className={css.container}>
          <h2 className={css.modalTitle}>
            {intl.formatMessage({ id: 'ReactivateSubscriptionModal.title' })}
          </h2>
          <p className={css.modalDesc}>
            {intl.formatMessage({ id: 'ReactivateSubscriptionModal.description' })}
          </p>
          <div className={css.row}>
            <div className={classNames(css.rowLabel, css.boldText)}>
              {intl.formatMessage({ id: 'ReactivateSubscriptionModal.subDetailLabel' })}
            </div>
            <div className={css.rowValue}>
              {intl.formatMessage(
                { id: 'ReactivateSubscriptionModal.renewalDate' },
                { renewalDate }
              )}
            </div>
          </div>
          <div className={css.row}>
            <div className={css.rowLabel}>
              {intl.formatMessage({ id: 'ReactivateSubscriptionModal.creditsLabel' })}
            </div>
            <div className={css.rowValue}>{creditsNumber}</div>
          </div>
          <div className={css.row}>
            <div className={css.rowLabel}>
              {intl.formatMessage({ id: 'ReactivateSubscriptionModal.subTotalLabel' })}
            </div>
            <div className={css.rowValue}>
              {intl.formatMessage(
                { id: 'ReactivateSubscriptionModal.subTotal' },
                {
                  totalPrice: formatMoneyWithSpace(totalPrice),
                }
              )}
            </div>
          </div>
          <div className={css.row}>
            <div className={css.rowLabel}>
              {intl.formatMessage({ id: 'ReactivateSubscriptionModal.vatLabel' })}
            </div>
            <div className={css.rowValue}>
              {intl.formatMessage({ id: 'ReactivateSubscriptionModal.vat' })}
            </div>
          </div>
          <div className={css.horizontalLine}></div>
          <div className={classNames(css.row, css.totalRow)}>
            <div className={classNames(css.rowLabel, css.boldText)}>
              {intl.formatMessage({ id: 'ReactivateSubscriptionModal.totalLabel' })}
            </div>
            <div className={classNames(css.rowValue, css.boldText)}>
              {intl.formatMessage(
                { id: 'ReactivateSubscriptionModal.total' },
                {
                  totalPrice: formatMoneyWithSpace(totalPriceWithVat),
                }
              )}
            </div>
          </div>
          <div className={css.horizontalLineAbsolute}></div>
          <div className={css.bottomButtons}>
            <Button type="button" className={css.cancelBtn} onClick={onClose}>
              {intl.formatMessage({ id: 'ReactivateSubscriptionModal.cancel' })}
            </Button>
            <Button
              type="button"
              className={css.confirmBtn}
              onClick={handleReactivateSubmit}
              inProgress={resumeInProgress}
              disabled={resumeInProgress}
            >
              {intl.formatMessage({ id: 'ReactivateSubscriptionModal.confirm' })}
            </Button>
          </div>
        </div>
      </OutsideClickHandler>
    </PopupModal>
  );
};

ReactivateSubscriptionModal.defaultProps = {};

ReactivateSubscriptionModal.propTypes = {
  creditsNumber: number.isRequired,
  totalPrice: number.isRequired,
};
export default ReactivateSubscriptionModal;
