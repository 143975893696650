import React, { useRef, useState } from 'react';
import { array, bool, func, shape, string } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm, Field } from 'react-final-form';
import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import isEqual from 'lodash/isEqual';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import { nonEmptyArray, composeValidators } from '../../util/validators';
import { isUploadImageOverLimitError } from '../../util/errors';
import {
  AddImages,
  Button,
  Form,
  ValidationError,
  EditListingIcons as Icons,
  FixedBottomButtons,
  SaveAndExitButton,
} from '../../components';

import css from './EditListingPhotosFormNew.module.scss';
import { useBoolean } from '../../util/hooks';
import { useDispatch } from 'react-redux';
import { editImageRequest } from '../../containers/EditListingPage/EditListingPage.duck';

const ACCEPT_IMAGES = 'image/*';
const ACCEPT_IMAGE_NUMBER = 1;

export const EditListingPhotosFormComponent = (props) => {
  const { onImageUpload, images } = props;
  const [imageUploadRequested, setImageUploadRequested] = useBoolean();
  const [editImageId, setEditImageId] = useState();
  const [newEditImageId, setNewEditImageId] = useState();
  const submittedImages = useRef([]);
  const imageInputRef = useRef();
  const dispatch = useDispatch();

  const onEditImage = async ({ imagePayload, imageId }) =>
    dispatch(editImageRequest({ imagePayload, imageId }));

  const onImageUploadHandler = async (file) => {
    if (file) {
      setImageUploadRequested.on();
      try {
        if (editImageId) {
          const newImageId = `${file.name}_${Date.now()}`;
          setNewEditImageId(newImageId);
          await onEditImage({
            imagePayload: { id: newImageId, file },
            imageId: editImageId,
          });
          setNewEditImageId('');
          setEditImageId('');
        } else {
          await onImageUpload({ id: `${file.name}_${Date.now()}`, file });
        }
        setImageUploadRequested.off();
      } catch (error) {
        setImageUploadRequested.off();
      }
    }
  };

  return (
    <FinalForm
      {...props}
      onImageUploadHandler={onImageUploadHandler}
      imageUploadRequested={imageUploadRequested}
      imageInputRef={imageInputRef}
      initialValues={{ images: images }}
      newEditImageId={newEditImageId}
      render={(formRenderProps) => {
        const {
          form,
          className,
          fetchErrors,
          handleSubmit,
          images,
          imageUploadRequested,
          intl,
          invalid,
          onImageUploadHandler,
          onRemoveImage,
          disabled,
          ready,
          updated,
          updateInProgress,
          imageInputRef,
          newEditImageId,
          values,
          onHandleSaveAndExit,
          isPublished,
        } = formRenderProps;

        const chooseImageText = (
          <div className={css.emptyPhotosWrapper}>
            <Icons.PhotoIcon className={css.photoIcon} />
            <div className={css.dragDropText}>
              {intl.formatMessage({ id: 'EditListingPhotosFormNew.dragAndDropText' })}
            </div>
            <div className={css.uploadWrapper}>
              <Icons.UploadIcon />
              <span className={css.uploadText}>
                {intl.formatMessage({ id: 'EditListingPhotosFormNew.upload' })}
              </span>
            </div>
          </div>
        );

        const imageRequiredMessage = intl.formatMessage({
          id: 'EditListingPhotosForm.imageRequired',
        });

        const { publishListingError, showListingsError, updateListingError, uploadImageError } =
          fetchErrors || {};
        const uploadOverLimit = isUploadImageOverLimitError(uploadImageError);

        let uploadImageFailed = null;

        if (uploadOverLimit) {
          uploadImageFailed = (
            <p className={css.error}>
              <FormattedMessage id="EditListingPhotosForm.imageUploadFailed.uploadOverLimit" />
            </p>
          );
        } else if (uploadImageError) {
          uploadImageFailed = (
            <p className={css.error}>
              <FormattedMessage id="EditListingPhotosForm.imageUploadFailed.uploadFailed" />
            </p>
          );
        }

        // NOTE: These error messages are here since Photos panel is the last visible panel
        // before creating a new listing. If that order is changed, these should be changed too.
        // Create and show listing errors are shown above submit button
        const publishListingFailed = publishListingError ? (
          <p className={css.error}>
            <FormattedMessage id="EditListingPhotosForm.publishListingFailed" />
          </p>
        ) : null;
        const showListingFailed = showListingsError ? (
          <p className={css.error}>
            <FormattedMessage id="EditListingPhotosForm.showListingFailed" />
          </p>
        ) : null;
        const submittedOnce = submittedImages.current.length > 0;
        // imgs can contain added images (with temp ids) and submitted images with uniq ids.
        const arrayOfImgIds = (imgs) =>
          imgs.map((i) => (typeof i.id === 'string' ? i.imageId : i.id));
        const imageIdsFromProps = arrayOfImgIds(images);
        const imageIdsFromPreviousSubmit = arrayOfImgIds(submittedImages.current);
        const imageArrayHasSameImages = isEqual(imageIdsFromProps, imageIdsFromPreviousSubmit);
        const pristineSinceLastSubmit = submittedOnce && imageArrayHasSameImages;

        const submitReady = (updated && pristineSinceLastSubmit) || ready;
        const submitInProgress = updateInProgress;
        const submitDisabled =
          invalid ||
          disabled ||
          submitInProgress ||
          imageUploadRequested ||
          ready ||
          Object.keys(images).length < ACCEPT_IMAGE_NUMBER;

        const classes = classNames(css.root, className);
        const isEmptyImage = Object.keys(images).length === 0;
        const addImageFieldNumber =
          Object.keys(images).length >= ACCEPT_IMAGE_NUMBER
            ? new Array(1).fill(0)
            : new Array(ACCEPT_IMAGE_NUMBER - Object.keys(images).length).fill(0);

        const rightBtnText = isPublished
          ? intl.formatMessage({ id: 'EditListing.saveButton' })
          : intl.formatMessage({ id: 'EditListing.submitButton' });
        const handleDrop = (e) => {
          e.preventDefault();
          const files = [...e.dataTransfer.files];

          files.forEach((file) => {
            if (/^image/.test(file.type)) {
              onImageUploadHandler(file);
            }
          });
        };
        const handleEmptyWrapperClick = (e) => {
          e.stopPropagation();
          imageInputRef.current.click();
        };
        const getImageId = (imageId) => {
          setEditImageId(imageId);
        };

        const onSaveAndExitHandler = () => {
          onHandleSaveAndExit(values);
        };
        return (
          <Form
            className={classes}
            onSubmit={(e) => {
              submittedImages.current = images;
              handleSubmit(e);
            }}
          >
            <SaveAndExitButton onClick={onSaveAndExitHandler} />
            {updateListingError ? (
              <p className={css.error}>
                <FormattedMessage id="EditListingPhotosForm.updateFailed" />
              </p>
            ) : null}
            <AddImages
              className={css.imagesField}
              images={images}
              thumbnailClassName={css.thumbnail}
              savedImageAltText={intl.formatMessage({
                id: 'EditListingPhotosForm.savedImageAltText',
              })}
              onRemoveImage={onRemoveImage}
              imageInputRef={imageInputRef}
              getImageId={getImageId}
              imageUploadRequested={imageUploadRequested}
              newEditImageId={newEditImageId}
            >
              <Field
                id="addImage"
                name="addImage"
                accept={ACCEPT_IMAGES}
                form={null}
                label={chooseImageText}
                type="file"
                disabled={imageUploadRequested}
                imageInputRef={imageInputRef}
              >
                {(fieldprops) => {
                  const {
                    accept,
                    input,
                    label,
                    disabled: fieldDisabled,
                    imageInputRef,
                  } = fieldprops;
                  const { name, type } = input;
                  const onChange = (e) => {
                    const file = e.target.files[0];
                    form.change(`addImage`, file);
                    form.blur(`addImage`);
                    onImageUploadHandler(file);
                  };
                  const inputProps = {
                    accept,
                    id: name,
                    name,
                    onChange,
                    type,
                    ref: imageInputRef,
                  };
                  return (
                    <>
                      {fieldDisabled ? null : (
                        <input {...inputProps} className={css.addImageInput} />
                      )}
                      {isEmptyImage && (
                        <div
                          className={css.noPhotosWrapper}
                          onDrop={handleDrop}
                          onClick={handleEmptyWrapperClick}
                        >
                          <Icons.PhotoIcon className={css.photoIcon} />
                          <div className={css.dragDropTitle}>
                            {intl.formatMessage({
                              id: 'EditListingPhotosFormNew.dragAndDropText',
                            })}
                          </div>
                          <p className={css.addingPhotoGuide}>
                            {intl.formatMessage({
                              id: 'EditListingPhotosFormNew.addingPhotoGuide',
                            })}
                          </p>
                          <label className={css.uploadBtn}>
                            <Icons.UploadIcon />
                            {intl.formatMessage({ id: 'EditListingPhotosFormNew.upload' })}
                          </label>
                          <label className={css.uploadBtnDesktop}>
                            <Icons.UploadIcon />
                            <span>
                              {intl.formatMessage({
                                id: 'EditListingPhotosFormNew.uploadFromDevice',
                              })}
                            </span>
                          </label>
                        </div>
                      )}
                      {!isEmptyImage &&
                        addImageFieldNumber.map((_, index) => (
                          <div className={css.addImageWrapper} key={index} onDrop={handleDrop}>
                            <label htmlFor={name} className={css.addImage}>
                              {label}
                            </label>
                          </div>
                        ))}
                    </>
                  );
                }}
              </Field>

              <Field
                component={(props) => {
                  const { input, meta } = props;
                  return (
                    <div className={css.imageRequiredWrapper}>
                      <input {...input} />
                      <ValidationError fieldMeta={meta} />
                    </div>
                  );
                }}
                name="images"
                type="hidden"
                validate={composeValidators(nonEmptyArray(imageRequiredMessage))}
              />
            </AddImages>
            {uploadImageFailed}

            {publishListingFailed}
            {showListingFailed}

            <FixedBottomButtons
              leftBtnText={intl.formatMessage({ id: 'EditListing.backButton' })}
              rightBtnText={rightBtnText}
              rightBtnType="submit"
              rightBtnReady={submitReady}
              rightBtnDisabled={submitDisabled}
              rightBtnInProgress={submitInProgress}
            />
          </Form>
        );
      }}
    />
  );
};

EditListingPhotosFormComponent.defaultProps = { fetchErrors: null, images: [] };

EditListingPhotosFormComponent.propTypes = {
  fetchErrors: shape({
    publishListingError: propTypes.error,
    showListingsError: propTypes.error,
    uploadImageError: propTypes.error,
    updateListingError: propTypes.error,
  }),
  images: array,
  intl: intlShape.isRequired,
  onImageUpload: func.isRequired,
  onUpdateImageOrder: func.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  onRemoveImage: func.isRequired,
};

export default compose(injectIntl)(EditListingPhotosFormComponent);
