import unionWith from 'lodash/unionWith';
import { storableError } from '../../util/errors';
import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import config from '../../config';
import { createSlug, parse } from '../../util/urlHelpers';
import { inBrowser } from '../../util/device';
import { defaultLocationBounds } from '../../default-location-searches';
import axios from 'axios';

export const LISTINGS_PER_PAGE = 24;

// ================ Action types ================ //

export const SEARCH_LISTINGS_REQUEST = 'app/SearchPage/SEARCH_LISTINGS_REQUEST';
export const SEARCH_LISTINGS_SUCCESS = 'app/SearchPage/SEARCH_LISTINGS_SUCCESS';
export const SEARCH_LISTINGS_ERROR = 'app/SearchPage/SEARCH_LISTINGS_ERROR';

export const SEARCH_MAP_LISTINGS_REQUEST = 'app/SearchPage/SEARCH_MAP_LISTINGS_REQUEST';
export const SEARCH_MAP_LISTINGS_SUCCESS = 'app/SearchPage/SEARCH_MAP_LISTINGS_SUCCESS';
export const SEARCH_MAP_LISTINGS_ERROR = 'app/SearchPage/SEARCH_MAP_LISTINGS_ERROR';

export const SEARCH_MAP_SET_ACTIVE_LISTING = 'app/SearchPage/SEARCH_MAP_SET_ACTIVE_LISTING';

export const SEARCH_LISTINGS_UPDATE_CATEGORIES = 'app/SearchPage/SEARCH_LISTINGS_UPDATE_CATEGORIES';
export const SEARCH_LISTINGS_UPDATE_LOCATION = 'app/SearchPage/SEARCH_LISTINGS_UPDATE_LOCATION';
export const SEARCH_LISTINGS_UPDATE_DATES = 'app/SearchPage/SEARCH_LISTINGS_UPDATE_DATES';
export const SEARCH_LISTINGS_UPDATE_TIMES = 'app/SearchPage/SEARCH_LISTINGS_UPDATE_TIMES';
export const SEARCH_LISTINGS_UPDATE_SEATS = 'app/SearchPage/SEARCH_LISTINGS_UPDATE_SEATS';
export const SEARCH_LISTINGS_UPDATE_BUDGET = 'app/SearchPage/SEARCH_LISTINGS_UPDATE_BUDGET';
export const SEARCH_LISTINGS_UPDATE_LISTING_NAME =
  'app/SearchPage/SEARCH_LISTINGS_UPDATE_LISTING_NAME';
export const SEARCH_LISTINGS_UPDATE_PAGE = 'app/SearchPage/SEARCH_LISTINGS_UPDATE_PAGE';
export const SEARCH_LISTINGS_UPDATE_PREDICTIONS =
  'app/SearchPage/SEARCH_LISTINGS_UPDATE_PREDICTIONS';

export const SEARCH_FILTERS_UPDATE_AMENETIES = 'app/SearchPage/SEARCH_FILTERS_UPDATE_AMENETIES';
export const SEARCH_FILTERS_UPDATE_ADDITIONAL_SERVICES =
  'app/SearchPage/SEARCH_FILTERS_UPDATE_ADDITIONAL_SERVICES';
export const SEARCH_FILTERS_UPDATE_SEATS = 'app/SearchPage/SEARCH_FILTERS_UPDATE_SEATS';
export const SEARCH_FILTERS_UPDATE_FIXED_FILTERS =
  'app/SearchPage/SEARCH_FILTERS_UPDATE_FIXED_FILTERS';
export const SEARCH_FILTERS_UPDATE_HOST = 'app/SearchPage/SEARCH_FILTERS_UPDATE_HOST';
export const SEARCH_FILTERS_UPDATE_CONTRACT_LENGTH =
  'app/SearchPage/SEARCH_FILTERS_UPDATE_CONTRACT_LENGTH';
export const SEARCH_FILTERS_UPDATE_CONTRACT_TYPE =
  'app/SearchPage/SEARCH_FILTERS_UPDATE_CONTRACT_TYPE';
export const SEARCH_FILTERS_UPDATE_ALLOW_VAT = 'app/SearchPage/SEARCH_FILTERS_UPDATE_ALLOW_VAT';

export const UPDATE_LOCATION_IDS = 'app/SearchPage/UPDATE_LOCATION_IDS';
export const TOGGLE_GET_LOCATIONS_LOADING = 'app/SearchPage/TOGGLE_GET_LOCATIONS_LOADING';
export const UPDATE_LOCATIONS_DATA = 'app/SearchPage/UPDATE_LOCATIONS_DATA';

export const SORT_BY_NEARBY_SPACES = 'app/SearchPage/SORT_BY_NEARBY_SPACES';

export const SET_LOCATIONS_DATA = 'app/SearchPage/SET_LOCATIONS_DATA';

export const UPDATE_FILTERS = 'app/SearchPage/UPDATE_FILTERS';
// ================ Reducer ================ //

const initialState = {
  pagination: {
    totalItems: 0,
    totalPages: 1,
    page: 1,
    perPage: LISTINGS_PER_PAGE,
  },
  searchParams: null,
  searchInProgress: false,
  searchListingsError: null,
  currentPageResultIds: [],
  searchMapListingIds: [],
  searchMapListingsError: null,
  categories: '',
  location: null,
  predictions: [],
  dates: {},
  listingName: null,
  ameneties: [],
  additionalServices: [],
  fixedOfficeType: [],
  host: null,
  times: {},
  searchSeats: '',
  contractLength: null,
  contractType: null,
  allowVat: false,
  times: null,
  searchSeats: null,
  budget: null,
  locationIds: [],
  locationsLoading: false,
  locationsData: [],
  isNearbySpaces: false,
  filters: {},
};

const resultIds = (data) => data.data.map((l) => l.id);

const listingPageReducer = (state = initialState, action = {}) => {
  const { type, payload } = action;

  switch (type) {
    case SEARCH_LISTINGS_REQUEST:
      return {
        ...state,
        searchParams: payload.searchParams,
        searchInProgress: true,
        searchMapListingIds: [],
        searchListingsError: null,
      };
    case SEARCH_LISTINGS_SUCCESS:
      return {
        ...state,
        currentPageResultIds: resultIds(payload.data),
        pagination: {
          ...state.pagination,
        },
      };
    case SEARCH_LISTINGS_ERROR:
      // eslint-disable-next-line no-console
      console.error(payload);
      return { ...state, searchInProgress: false, searchListingsError: payload };

    case SEARCH_MAP_LISTINGS_REQUEST:
      return {
        ...state,
        searchMapListingsError: null,
      };
    case SEARCH_MAP_LISTINGS_SUCCESS: {
      const searchMapListingIds = unionWith(
        state.searchMapListingIds,
        resultIds(payload.data),
        (id1, id2) => id1.uuid === id2.uuid
      );
      return {
        ...state,
        searchMapListingIds,
      };
    }
    case SEARCH_MAP_LISTINGS_ERROR:
      // eslint-disable-next-line no-console
      console.error(payload);
      return { ...state, searchMapListingsError: payload };

    case SEARCH_MAP_SET_ACTIVE_LISTING:
      return {
        ...state,
        activeListingId: payload,
      };

    case SEARCH_LISTINGS_UPDATE_CATEGORIES:
      return {
        ...state,
        categories: payload,
      };

    case SEARCH_LISTINGS_UPDATE_LOCATION:
      return {
        ...state,
        location: payload !== null ? { ...payload } : payload,
      };

    case SEARCH_LISTINGS_UPDATE_DATES:
      return {
        ...state,
        dates: payload,
      };

    case SEARCH_LISTINGS_UPDATE_TIMES:
      return {
        ...state,
        times: payload,
      };

    case SEARCH_LISTINGS_UPDATE_SEATS:
      return {
        ...state,
        searchSeats: payload,
      };

    case SEARCH_LISTINGS_UPDATE_BUDGET:
      return {
        ...state,
        budget: payload,
      };

    case SEARCH_LISTINGS_UPDATE_LISTING_NAME:
      return {
        ...state,
        listingName: payload,
      };
    case SEARCH_FILTERS_UPDATE_AMENETIES:
      return {
        ...state,
        ameneties: payload,
      };
    case SEARCH_FILTERS_UPDATE_ADDITIONAL_SERVICES:
      return {
        ...state,
        additionalServices: payload,
      };
    case SEARCH_FILTERS_UPDATE_SEATS:
      return {
        ...state,
        seats: payload,
      };
    case SEARCH_FILTERS_UPDATE_FIXED_FILTERS:
      return {
        ...state,
        fixedOfficeType: payload,
      };
    case SEARCH_FILTERS_UPDATE_HOST:
      return {
        ...state,
        host: payload,
      };
    case SEARCH_FILTERS_UPDATE_CONTRACT_LENGTH:
      return {
        ...state,
        contractLength: payload,
      };
    case SEARCH_FILTERS_UPDATE_CONTRACT_TYPE:
      return {
        ...state,
        contractType: payload,
      };
    case SEARCH_FILTERS_UPDATE_ALLOW_VAT:
      return {
        ...state,
        allowVat: payload,
      };

    case SEARCH_LISTINGS_UPDATE_PAGE:
      return {
        ...state,
        pagination: {
          ...state.pagination,
          page: payload,
        },
      };

    case SEARCH_LISTINGS_UPDATE_PREDICTIONS:
      return {
        ...state,
        predictions: [...payload],
      };

    case UPDATE_LOCATION_IDS:
      return {
        ...state,
        locationIds: payload,
      };
    case TOGGLE_GET_LOCATIONS_LOADING:
      return {
        ...state,
        locationsLoading: payload,
      };
    case UPDATE_LOCATIONS_DATA:
      return {
        ...state,
        pagination: {
          totalItems: payload.length,
          totalPages: Math.ceil(payload.length / LISTINGS_PER_PAGE),
          page: 1,
          perPage: LISTINGS_PER_PAGE,
        },
        locationsData: payload,
        searchInProgress: false,
      };
    case SET_LOCATIONS_DATA: {
      return {
        ...state,
        locationsData: [...payload],
      };
    }
    case SORT_BY_NEARBY_SPACES:
      return {
        ...state,
        isNearbySpaces: payload,
      };

    case UPDATE_FILTERS:
      return {
        ...state,
        filters: { ...payload },
      };

    default:
      return state;
  }
};

export default listingPageReducer;

// ================ Action creators ================ //
export const setLocationsData = (payload) => ({
  type: SET_LOCATIONS_DATA,
  payload,
});

export const updateFilters = (payload) => ({
  type: UPDATE_FILTERS,
  payload,
});

export const searchListingsRequest = (searchParams) => ({
  type: SEARCH_LISTINGS_REQUEST,
  payload: { searchParams },
});

export const searchListingsSuccess = (response) => ({
  type: SEARCH_LISTINGS_SUCCESS,
  payload: { data: response.data },
});

export const searchListingsError = (e) => ({
  type: SEARCH_LISTINGS_ERROR,
  error: true,
  payload: e,
});

export const searchMapListingsRequest = () => ({ type: SEARCH_MAP_LISTINGS_REQUEST });

export const searchMapListingsSuccess = (response) => ({
  type: SEARCH_MAP_LISTINGS_SUCCESS,
  payload: { data: response.data },
});

export const searchMapListingsError = (e) => ({
  type: SEARCH_MAP_LISTINGS_ERROR,
  error: true,
  payload: e,
});

const filterFixedOfficesBySeats = ({ min, max, listings }) => {
  return listings.filter((l) => {
    if (min && !max) {
      return min <= l.attributes?.publicData?.maxSeats;
    } else if (min && max) {
      return !l.attributes?.publicData?.minSeats
        ? !(min > l.attributes?.publicData?.maxSeats)
        : !(min > l.attributes?.publicData?.maxSeats || max < l.attributes?.publicData?.minSeats);
    } else if (!min && max) {
      return l.attributes?.publicData?.minSeats ? !(l.attributes?.publicData?.minSeats > max) : l;
    } else {
      return l;
    }
  });
};

export const saveToLocalStorage = (data) => {
  inBrowser() &&
    window.localStorage.setItem(
      'locationNames',
      JSON.stringify(
        data.map((l) => ({
          name: l.name,
          id: l.id,
          slug: createSlug(l.name),
        }))
      )
    );
};

export const searchListings = () => async (dispatch, getState, sdk) => {
  dispatch(toggleGetLocationsLoading(true));
  /* 1. Get search values */
  const { additionalServices, ameneties, searchSeats, categories, location, times } =
    getState().SearchPage;

  /* 2. Group them by location and listing filters */
  const address = location?.selectedPlace?.address;
  const { lat, lng } = location?.selectedPlace?.origin || {};
  const offer = additionalServices;
  const [min_seats, max_seats] = !!searchSeats ? searchSeats.split(',') : [];
  const category = categories;
  const amenities =
    ameneties?.reduce((acc, curr) => {
      acc[curr] = {};
      return acc;
    }, {}) || {};
  const opening_hours = times;

  /* 3. Prepare payload for api endpoint */
  const locationFilters = {
    /*...(!!address && { address }),
    ...(!!lat && { lat, lng }),
    ...(!!Object.keys(opening_hours).length && { opening_hours }),*/
  };

  const listingFilters = {
    /*...(Array.isArray(offer) && !!offer.length && { offer }),
    ...(!!min_seats && { min_seats, max_seats }),*/
    ...(!!category && { category }),
    /*...(!!Object.keys(amenities).length && { amenities }),*/
  };

  const locationFields = ['id', 'name', 'status', 'address', 'images'];

  const listingFields = [
    'id',
    'category',
    'title',
    'price',
    'booking_type',
    'min_seats',
    'max_seats',
  ];

  /* 4. Make a request with necessary fields selection */
  const response = await axios.get('http://localhost:3500/hasura/locations/search', {
    params: {
      locationFilters,
      locationFields,
      listingFilters,
      listingFields,
    },
  });

  /* 5. Save to state */
  dispatch(setLocationsData(response?.data?.locations));

  dispatch(toggleGetLocationsLoading(false));
};

export const setActiveListing = (listingId) => ({
  type: SEARCH_MAP_SET_ACTIVE_LISTING,
  payload: listingId,
});

/* State update logic goes here */
export const updateSearchCategories = (categories) => ({
  type: SEARCH_LISTINGS_UPDATE_CATEGORIES,
  payload: categories,
});

export const updateSearchLocation = (location) => ({
  type: SEARCH_LISTINGS_UPDATE_LOCATION,
  payload: location,
});

export const updateSearchDates = (dates) => ({
  type: SEARCH_LISTINGS_UPDATE_DATES,
  payload: dates,
});

export const updateSearchTimes = (times) => ({
  type: SEARCH_LISTINGS_UPDATE_TIMES,
  payload: times,
});

export const updateSearchSeats = (seats) => ({
  type: SEARCH_LISTINGS_UPDATE_SEATS,
  payload: seats,
});

export const updateSearchBudget = (times) => ({
  type: SEARCH_LISTINGS_UPDATE_BUDGET,
  payload: times,
});

export const updateSearchListingName = (name) => ({
  type: SEARCH_LISTINGS_UPDATE_LISTING_NAME,
  payload: name,
});
export const updateFilterAmeneties = (ameneties) => ({
  type: SEARCH_FILTERS_UPDATE_AMENETIES,
  payload: ameneties,
});
export const updateFilterAdditionalServices = (services) => ({
  type: SEARCH_FILTERS_UPDATE_ADDITIONAL_SERVICES,
  payload: services,
});
export const updateFilterFixed = (filters) => ({
  type: SEARCH_FILTERS_UPDATE_FIXED_FILTERS,
  payload: filters,
});
export const updateFilterHost = (filters) => ({
  type: SEARCH_FILTERS_UPDATE_HOST,
  payload: filters,
});
export const updateFilterContractLength = (filters) => ({
  type: SEARCH_FILTERS_UPDATE_CONTRACT_LENGTH,
  payload: filters,
});
export const updateFilterContractType = (filters) => ({
  type: SEARCH_FILTERS_UPDATE_CONTRACT_TYPE,
  payload: filters,
});
export const updateFilterAllowVAT = (filters) => ({
  type: SEARCH_FILTERS_UPDATE_ALLOW_VAT,
  payload: filters,
});
export const updatePage = (page) => ({
  type: SEARCH_LISTINGS_UPDATE_PAGE,
  payload: page,
});
export const updatePredictions = (predictions) => ({
  type: SEARCH_LISTINGS_UPDATE_PREDICTIONS,
  payload: predictions,
});

export const searchMapListings = (searchParams) => (dispatch, getState, sdk) => {
  dispatch(searchMapListingsRequest(searchParams));

  return sdk.listings
    .query(searchParams)
    .then((response) => {
      dispatch(addMarketplaceEntities(response));
      dispatch(searchMapListingsSuccess(response));
      return response;
    })
    .catch((e) => {
      dispatch(searchMapListingsError(storableError(e)));
      throw e;
    });
};

const updateLocationIds = (payload) => ({
  type: UPDATE_LOCATION_IDS,
  payload,
});

const toggleGetLocationsLoading = (payload) => ({
  type: TOGGLE_GET_LOCATIONS_LOADING,
  payload,
});

const updateLocationsDataAction = (payload) => ({
  type: UPDATE_LOCATIONS_DATA,
  payload,
});

export const loadData = (params, search) => {
  const queryParams = parse(search, {
    latlng: ['origin'],
    latlngBounds: ['bounds'],
  });
  const { address, origin, bounds = defaultLocationBounds, ...rest } = queryParams;

  const originMaybe = config.sortSearchByDistance && origin ? { origin } : {};
  return searchListings({
    ...rest,
    ...originMaybe,
    ...(address && { address }),
    bounds,
    include: ['author', 'images'],
    'fields.listing': ['title', 'geolocation', 'createdAt', 'price', 'publicData'],
    'fields.user': ['profile.displayName', 'profile.abbreviatedName'],
    'fields.image': ['variants.landscape-crop', 'variants.landscape-crop2x'],
    'limit.images': 3,
  });
};

export const sortByNearbySpaces = (boolean) => ({
  type: SORT_BY_NEARBY_SPACES,
  payload: boolean,
});
