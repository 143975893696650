import React from 'react';

const ArrowUpIcon = ({ onClick }) => {
  return (
    <svg viewBox="0 0 494.148 494.148" width={10} height={10} onClick={onClick}>
      <path
        d="M201.188 88.864L13.28 363.344C4.596 376.02 0 388.792 0 399.408c0 20.524 16.472 33.22 44.044 33.22h406.124c27.54 0 43.98-12.68 43.98-33.156 0-10.632-4.6-23.2-13.308-35.904L292.936 88.96c-12.104-17.66-28.392-27.44-45.884-27.44-17.48-.004-33.764 9.664-45.864 27.344z"
        data-original="#000000"
      />
    </svg>
  );
};

export default ArrowUpIcon;
