import { bool, object } from 'prop-types';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';

import {
  default as facebookImage,
  default as twitterImage,
} from '../../assets/respaces_social_1260x630.png';
import {
  Footer,
  LayoutSingleColumn,
  LayoutWrapperFooter,
  LayoutWrapperMain,
  LayoutWrapperTopbar,
  Page,
} from '../../components';
import { ButtonNew, FavoriteListingsSlider, InfoModal } from '../../components_new';
import { BUTTON_SIZES, BUTTON_TYPES, LINK_COLORS } from '../../components_new/ButtonNew/ButtonNew';
import ContentLoader from '../../components_new/FavoriteListingsSlider/ContentLoader';
import config from '../../config';
import { TopbarContainer } from '../../containers';
import { isScrollingDisabled } from '../../ducks/UI.duck';
import { toggleCompanyFavoriteListings, toggleUserFavoriteListings } from '../../ducks/user.duck';
import { isCompanyUser, isEmployeeUser } from '../../util/b2bHelper';
import { injectIntl, intlShape } from '../../util/reactIntl';
import { propTypes } from '../../util/types';
import {
  updateSearchCategories,
  updateSearchDates,
  updateSearchListingName,
  updateSearchLocation,
  updateSearchTimes,
} from '../SearchPage/SearchPage.duck';

import CustomerReviewsSlider from '../../components/CustomerReviewsSlider/CustomerReviewsSlider';
import routeConfiguration from '../../routeConfiguration';
import { isMobile } from '../../util/device';
import { pathByRouteName } from '../../util/routes';
import SectionCta from './components/SectionCta/SectionCta';
import SectionHero from './components/SectionHero/SectionHero';
import SectionListYourSpace from './components/SectionListYourSpace/SectionListYourSpace';
import SectionTagline from './components/SectionTagline/SectionTagline';
import SectionUseCases from './components/SectionUseCases/SectionUseCases';

import css from './LandingPage.module.scss';

const sharetribeSdk = require('sharetribe-flex-sdk');
const SHARETRIBE_SDK_CLIENT_ID = process.env.REACT_APP_SHARETRIBE_SDK_CLIENT_ID;

// Create new SDK instance
const sdk = sharetribeSdk.createInstance({
  clientId: SHARETRIBE_SDK_CLIENT_ID,
});

export const LandingPageComponent = (props) => {
  const {
    intl,
    scrollingDisabled,
    currentUser,
    favoritesArray,
    companyFavoritesArray,
    companyInfo,
    toggleUserFavoriteListings,
    toggleCompanyFavoriteListings,
    history,
    pageData,
    fetchInProgress,
    fetchDataError,
  } = props;
  const { isCompanyAdmin } = companyInfo || {};
  const isB2B = isEmployeeUser(currentUser) || isCompanyUser(currentUser);
  const isCompanyAdminAndB2B = isCompanyAdmin && isB2B;
  const [isScrolled, setIsScrolled] = useState(false);
  const [isModalOpen, setModalOpen] = useState(false);

  if (currentUser) {
    const path = pathByRouteName('LandingPageLoggedIn', routeConfiguration());
    history && history.replace(path);
  }

  const onToggleFavoriteListing = (id) => {
    toggleUserFavoriteListings(id);
  };

  // Check if user is scrolled to top - used for setting class on hero
  const handleScroll = () => {
    if (typeof window !== 'undefined') {
      if (window.innerWidth < 768) {
        isScrolled && window.scrollY <= 60
          ? setIsScrolled(false)
          : !isScrolled && window.scrollY > 60 && setIsScrolled(true);
      } else {
        isScrolled && window.scrollY <= 100
          ? setIsScrolled(false)
          : !isScrolled && window.scrollY > 100 && setIsScrolled(true);
      }
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true });

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [isScrolled]);

  // Schema for search engines (helps them to understand what this page is about)
  // http://schema.org
  // We are using JSON-LD format
  const siteTitle = config.siteTitle;
  const schemaTitle = intl.formatMessage({ id: 'LandingPage.schemaTitle' }, { siteTitle });
  const schemaDescription = intl.formatMessage({ id: 'LandingPage.schemaDescription' });
  const schemaImage = `${config.canonicalRootURL}${facebookImage}`;

  /* I didn't find good way to merge default props with passed, so these
   * props should be passed to render standard blue link with arrow.
   * If you need other styles, just pass custom object instead of that,
   * for example: { type: 'primary', withIcon: false } - will render black button
   * with icon and white text */
  const defaultLinkProps = {
    type: BUTTON_TYPES.INLINE,
    size: BUTTON_SIZES.SMALL,
    linkColor: LINK_COLORS.BLUE,
    withIcon: true,
  };

  return (
    <Page
      id="page"
      className={css.root}
      scrollingDisabled={scrollingDisabled}
      contentType="website"
      description={schemaDescription}
      title={schemaTitle}
      facebookImages={[{ url: facebookImage, width: 1200, height: 630 }]}
      twitterImages={[
        { url: `${config.canonicalRootURL}${twitterImage}`, width: 600, height: 314 },
      ]}
      schema={{
        '@context': 'http://schema.org',
        '@type': 'WebPage',
        description: schemaDescription,
        name: schemaTitle,
        image: [schemaImage],
      }}
    >
      <LayoutSingleColumn>
        <LayoutWrapperTopbar>
          <TopbarContainer currentPage="LandingPage" isScrolled={isScrolled} />
        </LayoutWrapperTopbar>
        <LayoutWrapperMain>
          <div className={css.sections}>
            <div className={css.heroWrapper}>
              <div className={css.heroContainer}>
                <SectionHero isMobile={isMobile} />
              </div>
            </div>
          </div>

          {(fetchInProgress?.private && !fetchDataError?.private) ||
          (fetchInProgress?.shared && !fetchDataError?.shared) ||
          (fetchInProgress?.coworking && !fetchDataError?.coworking) ? (
            <>
              <div className={css.strapiShelvesLoadingContainer}>
                <ContentLoader />
              </div>
              <div className={css.strapiShelvesLoadingContainer}>
                <ContentLoader />
              </div>
              <div className={css.strapiShelvesLoadingContainer}>
                <ContentLoader />
              </div>
            </>
          ) : (
            <section className={css.listingShelves}>
              {pageData?.privateOfficeLocations && pageData?.privateOfficeLocations.length > 0 && (
                <FavoriteListingsSlider
                  key={`FavoriteListingsSlider-privateOffice`}
                  id={'LandingPageSlide_privateOfficeLocations'}
                  title={intl.formatMessage({
                    id: `LandingPage.shelf.privateOffice.title`,
                  })}
                  listings={pageData.privateOfficeLocations}
                  favoriteListingsIds={favoritesArray} // remove?
                  companyFavoriteListingsIds={companyFavoritesArray} // remove?
                  onToggleFavoriteListing={onToggleFavoriteListing} // remove?
                  onToggleCompanyFavoriteListing={toggleCompanyFavoriteListings} // remove?
                  showCompanyFavoriteButton={isCompanyAdminAndB2B} // remove?
                  isCompanyAdmin={isCompanyAdminAndB2B} // remove?
                  isControlledByRespaces
                  isShowCredits={isB2B}
                />
              )}
              {pageData?.sharedOfficeLocations && pageData?.sharedOfficeLocations.length > 0 && (
                <FavoriteListingsSlider
                  key={`FavoriteListingsSlider-sharedOffice`}
                  id={'LandingPageSlide_sharedOfficeLocations'}
                  title={intl.formatMessage({
                    id: `LandingPage.shelf.sharedOffice.title`,
                  })}
                  listings={pageData.sharedOfficeLocations}
                  favoriteListingsIds={favoritesArray} // remove?
                  companyFavoriteListingsIds={companyFavoritesArray} // remove?
                  onToggleFavoriteListing={onToggleFavoriteListing} // remove?
                  onToggleCompanyFavoriteListing={toggleCompanyFavoriteListings} // remove?
                  showCompanyFavoriteButton={isCompanyAdminAndB2B} // remove?
                  isCompanyAdmin={isCompanyAdminAndB2B} // remove?
                  isControlledByRespaces
                  isShowCredits={isB2B}
                />
              )}
              {pageData?.coworkingLocations && pageData?.coworkingLocations.length > 0 && (
                <FavoriteListingsSlider
                  key={`FavoriteListingsSlider-coworking`}
                  id={'LandingPageSlide_coworkingLocations'}
                  title={intl.formatMessage({
                    id: `LandingPage.shelf.coworking.title`,
                  })}
                  listings={pageData.coworkingLocations}
                  favoriteListingsIds={favoritesArray} // remove?
                  companyFavoriteListingsIds={companyFavoritesArray} // remove?
                  onToggleFavoriteListing={onToggleFavoriteListing} // remove?
                  onToggleCompanyFavoriteListing={toggleCompanyFavoriteListings} // remove?
                  showCompanyFavoriteButton={isCompanyAdminAndB2B} // remove?
                  isCompanyAdmin={isCompanyAdminAndB2B} // remove?
                  isControlledByRespaces
                  isShowCredits={isB2B}
                />
              )}
            </section>
          )}

          <SectionTagline />
          <SectionUseCases
            buttonProps={defaultLinkProps}
            history={history}
            className={css.grayBackground}
          />
          <SectionListYourSpace
            className={`${isMobile ? css.listYourSpace : ''}`}
            buttonProps={defaultLinkProps}
          />
          <div className={css.customerReviewsSlider}>
            <CustomerReviewsSlider />
          </div>
          <SectionCta onModalOpen={() => setModalOpen(true)} />
          <InfoModal
            isOpen={isModalOpen}
            onClose={() => setModalOpen(false)}
            color="green"
            left={{
              images: [
                {
                  avatarURL: '/static/info_modal/left_1.png',
                },
                {
                  avatarURL: '/static/info_modal/oskar.jpg',
                },
                {
                  avatarURL: '/static/info_modal/left_3.png',
                },
              ],
              title: intl.formatMessage({ id: 'LandingPage.InfoModal.left.title' }),
              text: intl.formatMessage({ id: 'LandingPage.InfoModal.left.text' }),
              buttons: (
                <>
                  <ButtonNew
                    name="OnboardingPage"
                    type={BUTTON_TYPES.SECONDARY}
                    size={isMobile ? BUTTON_SIZES.SMALL : BUTTON_SIZES.MEDIUM}
                    linkColor={LINK_COLORS.GREEN}
                  >
                    {intl.formatMessage({ id: 'LandingPage.InfoModal.left.buttonText' })}
                  </ButtonNew>
                  <span>
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g>
                        <path
                          d="M10.0002 16.8C6.2402 16.8 3.2002 13.76 3.2002 9.99995C3.2002 6.23995 6.2402 3.19995 10.0002 3.19995C13.7602 3.19995 16.8002 6.23995 16.8002 9.99995C16.8002 13.76 13.7602 16.8 10.0002 16.8ZM10.0002 3.99995C6.6802 3.99995 4.0002 6.67995 4.0002 9.99995C4.0002 13.32 6.6802 16 10.0002 16C13.3202 16 16.0002 13.32 16.0002 9.99995C16.0002 6.67995 13.3202 3.99995 10.0002 3.99995Z"
                          fill="white"
                        />
                        <path
                          d="M12.1196 13.4799L9.59961 10.9599V6.3999H10.3996V10.6399L12.6796 12.9199L12.1196 13.4799Z"
                          fill="white"
                        />
                      </g>
                    </svg>
                    {'<'} 2 min
                  </span>
                </>
              ),
            }}
            right={{
              title: intl.formatMessage({ id: 'LandingPage.InfoModal.right.title' }),
              text: intl.formatMessage({ id: 'LandingPage.InfoModal.right.text' }),
              buttons: (
                <ButtonNew
                  name="SearchPage"
                  type={BUTTON_TYPES.GREEN}
                  size={isMobile ? BUTTON_SIZES.SMALL : BUTTON_SIZES.MEDIUM}
                >
                  {intl.formatMessage({ id: 'LandingPage.InfoModal.right.buttonText' })}
                </ButtonNew>
              ),
              images: [
                {
                  avatarURL: '/static/info_modal/right_1.png',
                },
                {
                  avatarURL: '/static/info_modal/right_2.png',
                },
                {
                  avatarURL: '/static/info_modal/right_3.png',
                },
              ],
            }}
          />
        </LayoutWrapperMain>
        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </LayoutSingleColumn>
    </Page>
  );
};

LandingPageComponent.defaultProps = {
  currentUserListing: null,
  currentUserListingFetched: false,
};

LandingPageComponent.propTypes = {
  scrollingDisabled: bool.isRequired,
  currentUserListing: propTypes.ownListing,
  currentUserListingFetched: bool,

  // from withRouter
  history: object.isRequired,
  location: object.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = (state) => {
  const {
    user: {
      favoritesArray,
      companyFavoritesArray,
      favoritesArrayOfObjects,
      companyFavoritesArrayOfObjects,
      companyInfo,
      currentUser,
      currentUserListing,
      currentUserListingFetched,
    },
    SearchPage: {
      categories: stateCategories,
      location: stateLocation,
      dates: stateDates,
      times: stateTimes,
      listingName: stateListingName,
    },
    LandingPage: { pageData, fetchInProgress, fetchDataError },
  } = state;

  return {
    favoritesArray,
    companyFavoritesArray,
    favoritesArrayOfObjects,
    companyFavoritesArrayOfObjects,
    companyInfo,
    scrollingDisabled: isScrollingDisabled(state),
    currentUser,
    currentUserListing,
    currentUserListingFetched,
    stateCategories,
    stateLocation,
    stateDates,
    stateTimes,
    stateListingName,
    pageData,
    fetchInProgress,
    fetchDataError,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateSearchCategories: (categories) => dispatch(updateSearchCategories(categories)),
    updateSearchDates: (dates) => dispatch(updateSearchDates(dates)),
    updateSearchTimes: (times) => dispatch(updateSearchTimes(times)),
    updateSearchLocation: (location) => dispatch(updateSearchLocation(location)),
    updateSearchListingName: (name) => dispatch(updateSearchListingName(name)),
    toggleUserFavoriteListings: (payload) => dispatch(toggleUserFavoriteListings(payload)),
    toggleCompanyFavoriteListings: (payload) => dispatch(toggleCompanyFavoriteListings(payload)),
  };
};

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const LandingPage = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl
)(LandingPageComponent);

export default LandingPage;
