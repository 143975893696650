import React from 'react';
import { bool } from 'prop-types';
import { FormattedMessage, intlShape } from '../../util/reactIntl';
import { formatMoney } from '../../util/currency';
import { txIsCanceled, txIsDelivered, txIsDeclined } from '../../util/transaction';
import { propTypes } from '../../util/types';
import { types as sdkTypes } from '../../util/sdkLoader';

import css from './BookingBreakdown.module.scss';

const { Money } = sdkTypes;

const LineItemUnitPrice = (props) => {
  const { transaction, isProvider, intl, isCreditTx } = props;

  let providerTotalMessageId = 'BookingBreakdown.providerTotalDefault';
  if (txIsDelivered(transaction)) {
    providerTotalMessageId = 'BookingBreakdown.providerTotalDelivered';
  }
  if (txIsCanceled(transaction)) {
    providerTotalMessageId = 'BookingBreakdown.providerTotalCanceled';
  }

  let payoutTotal = 0;
  const currency = transaction.attributes.payoutTotal.currency;

  if (isProvider && isCreditTx) {
    const creditAmount = transaction.attributes.lineItems.find((item) =>
      item.code.includes('credits-commission')
    );
    const providerCommission = transaction.attributes.metadata.payoutLineItems.find((item) =>
      item.code.includes('provider-commission')
    );
    payoutTotal =
      transaction.attributes.payoutTotal.amount -
      creditAmount.lineTotal.amount +
      providerCommission.unitPrice.amount;
  }

  const totalLabel = isProvider ? (
    <FormattedMessage id={providerTotalMessageId} />
  ) : (
    <FormattedMessage id="BookingBreakdown.total" />
  );

  const totalPrice =
    isProvider && isCreditTx && !(txIsCanceled(transaction) || txIsDeclined(transaction))
      ? new Money(payoutTotal, currency)
      : isProvider
      ? transaction.attributes.payoutTotal
      : transaction.attributes.payinTotal;
  const formattedTotalPrice = formatMoney(intl, totalPrice);

  return (
    <>
      <div className={css.lineItemTotal}>
        <div className={css.totalLabel}>{totalLabel}</div>
        <div className={css.totalPrice}>{formattedTotalPrice}</div>
      </div>
    </>
  );
};

LineItemUnitPrice.propTypes = {
  transaction: propTypes.transaction.isRequired,
  isProvider: bool.isRequired,
  intl: intlShape.isRequired,
};

export default LineItemUnitPrice;
