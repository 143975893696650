import React, { useEffect } from 'react';
import classNames from 'classnames';
import { PlusCircleIcon, SubstractCircleIcon, ValidationError } from '..';
import { bool, func, object, string } from 'prop-types';

import css from './FieldNumberFixedPlaceholder.module.scss';
import { Field } from 'react-final-form';

const defaultParse = (value) => {
  if (isNaN(value)) {
    return 0;
  } else if (value && value.replace) {
    return parseInt(value.replace(/\s/g, ''));
  }
  return value === null ? value : 0;
};

const FieldNumberFixedPlaceholderComponent = ({
  rootClassName,
  className,
  inputRootClass,
  customErrorText,
  inputClassName,
  id,
  label,
  input,
  meta,
  inputRef,
  onChange: customOnChange,
  positive,
  min,
  max,
  readOnly,
  placeholder,
  inputPlaceholder,
  isTypingInput,
  isButtonInput,
  inputWrapperClassname,
  canModify = false,
  fixedPlaceholderClassname,
  inline,
  ...rest
}) => {
  if (label && !id) {
    throw new Error('id required when a label is given');
  }

  const { valid, invalid, touched, error } = meta;
  const { onChange } = input;
  const errorText = customErrorText || error;
  const hasError = !!customErrorText || !!(touched && invalid && error);
  const fieldMeta = { touched: hasError, error: errorText };
  const refMaybe = inputRef ? { ref: inputRef } : {};
  const handleChange = (e) => {
    if (typeof customOnChange === 'function') customOnChange(e);
    onChange(e);
  };
  const inputClasses =
    inputRootClass ||
    classNames(css.input, inputClassName, {
      [css.inputSuccess]: valid,
      [css.inputError]: hasError,
    });

  const inputProps = {
    className: inputClasses,
    id,
    type: 'text',
    placeholder: inputPlaceholder,
    disabled: !canModify && (isButtonInput || false),
    ...refMaybe,
    ...input,
    ...rest,
    onChange: handleChange,
  };
  const classes = classNames(rootClassName || css.root, className);
  const wrapper = classNames(css.wrapper, inputWrapperClassname);

  const subBtnClassname = classNames(css.subBtn, {
    [css.active]: input.value > min,
  });

  const plusBtnClassname = classNames(css.plusBtn, {
    [css.active]: input.value < max,
  });

  const handleClickArrowUp = () => {
    if (readOnly) {
      return;
    }
    let nextValue = parseInt(input.value || 0, 10) + 1;
    if (max) nextValue = nextValue > max ? max : nextValue;
    handleChange(nextValue.toString());
    input.onBlur();
  };
  const handleClickArrowDown = () => {
    if (readOnly) {
      return;
    }
    let nextValue = parseInt(input.value || 0, 10) - 1;
    if (positive) nextValue = nextValue < 0 ? 0 : nextValue;
    if (min) nextValue = nextValue < min ? min : nextValue;
    onChange(nextValue.toString());
    input.onBlur();
  };

  const fixedPlaceholderClasses = classNames(css.fixedPlaceholder, fixedPlaceholderClassname);

  return (
    <div className={classes}>
      <div className={classNames(inline ? css.inline : 'null')}>
        {label ? <label htmlFor={id}>{label}</label> : null}
        <div className={wrapper}>
          <div className={fixedPlaceholderClasses}>{placeholder}</div>

          {isTypingInput && (
            <div className={css.inputWrapper}>
              <input {...inputProps} readOnly={readOnly} />
            </div>
          )}

          {isButtonInput && (
            <div className={css.inputButtonWrapper}>
              <div className={subBtnClassname} onClick={handleClickArrowDown}>
                <SubstractCircleIcon />
              </div>
              <input {...inputProps} readOnly={readOnly} />
              <div className={plusBtnClassname} onClick={handleClickArrowUp}>
                <PlusCircleIcon />
              </div>
            </div>
          )}
        </div>
      </div>
      <ValidationError fieldMeta={fieldMeta} />
    </div>
  );
};

const FieldNumberFixedPlaceholder = ({ onUnmount, initialValue, ...rest }) => {
  useEffect(() => {
    return () => {
      if (typeof onUnmount === 'function') onUnmount();
    };
  }, [onUnmount]);
  const initMaybe = initialValue ? { defaultValue: initialValue } : {};
  return (
    <Field
      component={FieldNumberFixedPlaceholderComponent}
      {...rest}
      parse={defaultParse}
      {...initMaybe}
    />
  );
};

FieldNumberFixedPlaceholder.defaultProps = {
  positive: true,
  rootClassName: null,
  className: null,
  inputRootClass: null,
  onUnmount: null,
  customErrorText: null,
  id: null,
  label: null,
  inputRef: null,
  fixedPlaceholderClassname: '',
};

FieldNumberFixedPlaceholder.propTypes = {
  rootClassName: string,
  className: string,
  inputRootClass: string,
  onUnmount: func,
  customErrorText: string,
  id: string,
  label: string,
  inputRef: object,
  positive: bool,
  isTypingInput: bool,
  isButtonInput: bool,
  inputPlaceholder: string,
  inputWrapperClassname: string,
  fixedPlaceholderClassname: string,
};

export default FieldNumberFixedPlaceholder;
