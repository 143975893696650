import chunk from 'lodash/chunk';
const calculateRemainPages = (meta) => {
  const { totalPages = 1 } = meta;
  if (totalPages <= 1) return [];
  return new Array(totalPages - 1).fill(0).map((_v, i) => i + 2);
};
export const queryAllPage = async ({ sdk, query, include, perPage = 20 }) => {
  let result = [];
  const queryPage = async ({ page }) => {
    const response = await sdk.query({
      include,
      page,
      perPage,
      ...query,
    });
    result = [...result, response];
    return response;
  };
  const firstResponse = await queryPage({ page: 1 });
  const { meta } = firstResponse.data;
  const remainPages = calculateRemainPages(meta);
  const chunkedPages = chunk(remainPages, 4);
  if (chunkedPages.length) {
    for (let i = 0; i < chunkedPages.length; i++) {
      await Promise.all(chunkedPages[i].map((page) => queryPage({ page })));
    }
    return result;
  }
  return result;
};
