/**
 Sends an email with inquiry
 @param fullName {String}
 @param companyName {String}
 @param email {String}
 @param phone {String}
 @param message {String}
 @param date {String}
 @param providerEmais {array}
 @param address {String}
 @param seats {String}
 @param fixedOfficeTypeTranslation {String}
 @param locale {String}
 @param locationName {String}
 */
const BookShowingEmail = async ({
  fullName,
  companyName,
  email,
  phone,
  message,
  date,
  time,
  suggestTime,
  providerEmails,
  address,
  seats,
  fixedOfficeTypeTranslation,
  locale,
  locationName,
  objectUrl,
}) => {
  const CUSTOMER_IO_APP_API_KEY = process.env.REACT_APP_CUSTOMER_IO_APP_API_KEY;

  const sendShowingEmail = async () => {
    const message_data = {
      fullName: fullName,
      companyName: companyName,
      email: email,
      phone: phone,
      message: message,
      date: date,
      time: time,
      suggestTime: suggestTime,
      address: address,
      seats: seats,
      fixedOfficeTypeTranslation: fixedOfficeTypeTranslation,
      locale: locale,
      locationName: locationName,
      objectUrl: objectUrl,
    };

    const to =
      providerEmails[1] && providerEmails[1] !== ''
        ? `${providerEmails[0]},${providerEmails[1]}`
        : providerEmails[0];

    var myHeaders = new Headers();
    myHeaders.append('Content-Type', 'application/json');
    myHeaders.append('Accept', 'application/json');
    myHeaders.append('Authorization', `Bearer ${CUSTOMER_IO_APP_API_KEY}`);

    const raw = JSON.stringify({
      identifiers: {
        email: providerEmails[0],
      },
      to: to,
      transactional_message_id: 'bookAShowing_partner',
      language: locale,
      message_data: message_data,
    });

    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow',
    };

    fetch('https://api-eu.customer.io/v1/send/email', requestOptions)
      .then((response) => response.text())
      .then((result) => console.log(result))
      .catch((error) => console.log('error', error));
    return true;
  };

  return await sendShowingEmail();
};

export default BookShowingEmail;
