import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { StaticPage, TopbarContainer } from '../../containers';
import {
  LayoutSingleColumn,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  Footer,
  NamedLink,
} from '../../components';
import contacts from './contacts';

import css from './ContactUs.module.scss';

const ContactUs = () => {
  const intl = useIntl();
  const linkedInLogo = (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M16.3599 0.359863H1.63546C0.931303 0.359863 0.359863 0.917863 0.359863 1.60498V16.3931C0.359863 17.0811 0.931303 17.6399 1.63546 17.6399H16.3599C17.0646 17.6399 17.6399 17.0811 17.6399 16.3931V1.60498C17.6399 0.917863 17.0646 0.359863 16.3599 0.359863ZM5.48482 15.0848H2.92066V6.83866H5.48482V15.0848ZM4.20274 5.71114C4.00757 5.71114 3.81431 5.67268 3.63401 5.59797C3.4537 5.52325 3.28989 5.41373 3.15193 5.27568C3.01396 5.13763 2.90455 4.97374 2.82995 4.79339C2.75535 4.61304 2.71702 4.41976 2.71714 4.22458C2.71714 3.83061 2.87365 3.45277 3.15223 3.17419C3.43081 2.89561 3.80865 2.7391 4.20262 2.7391C4.5966 2.7391 4.97443 2.89561 5.25302 3.17419C5.5316 3.45277 5.6881 3.83061 5.6881 4.22458C5.6881 4.61864 5.53165 4.99657 5.25312 5.27532C4.9746 5.55406 4.59679 5.71082 4.20274 5.71114V5.71114ZM15.0839 15.0848H12.5238V11.0749C12.5238 10.1183 12.5051 8.88826 11.1915 8.88826C9.85762 8.88826 9.65434 9.9301 9.65434 11.0055V15.0851H7.09234V6.83866H9.55114V7.96474H9.58618C9.92842 7.31674 10.7646 6.63298 12.0116 6.63298C14.6051 6.63298 15.0841 8.34034 15.0841 10.5615V15.0848H15.0839Z"
        fill="black"
      />
    </svg>
  );

  return (
    <StaticPage
      title="Contact Us at Respaces"
      description={intl.formatMessage({ id: 'ContactUs.schemaDescription' })}
      schema={{
        '@context': 'http://schema.org',
        '@type': 'ContactUs',
        description: 'Contact us page, find our contact details here.',
        name: 'Contact us page',
      }}
    >
      <LayoutSingleColumn>
        <LayoutWrapperTopbar>
          <TopbarContainer currentPage="ContactUs" />
        </LayoutWrapperTopbar>

        <LayoutWrapperMain className={css.staticPageWrapper}>
          <div className={css.contentWrapper}>
            <h1 className={css.pageTitleDesktop}>
              <FormattedMessage id="ContactUs.titleDesktop" />
            </h1>
            {/* ----- here to help section ----- */}
            <div className={css.contentMain}>
              <p>
                <FormattedMessage id="ContactUs.helpParagrapgh1" />
                <NamedLink name="FAQPage" className={css.boldLink}>
                  <FormattedMessage id="ContactUs.helpParagrapgh2" />
                </NamedLink>
                <FormattedMessage id="ContactUs.helpParagrapgh3" />
              </p>
              <h3 className={css.subSection}>
                <FormattedMessage id="ContactUs.joinFamily" />
              </h3>
              <p>
                <FormattedMessage id="ContactUs.joinParagrapgh1" />
                <NamedLink name="CareersPage" className={css.boldLink}>
                  <FormattedMessage id="ContactUs.joinParagrapgh2" />
                </NamedLink>{' '}
                <FormattedMessage id="ContactUs.joinParagrapgh3" />
              </p>
            </div>
            <div className={css.emailWrapper}>
              <div className={css.contactEmails}>
                <div className={css.contactEmail}>
                  <h3>
                    <FormattedMessage id="ContactUs.generalHeading" />
                  </h3>
                  <p>
                    <a href="mailto:support@respaces.co">support@respaces.co</a>
                  </p>
                </div>
              </div>
              <div>
                <div className={css.contactEmail}>
                  <h3>
                    <FormattedMessage id="ContactUs.everythingElselHeading" />
                  </h3>
                </div>
                <p>
                  <a href="mailto:hello@respaces.co">hello@respaces.co</a>
                </p>
              </div>
            </div>
            <h2 className={css.ourTeam}>
              <FormattedMessage id="ContactUs.ourTeam" />
            </h2>
            <div className={css.contacts}>
              {contacts.map((contact) => {
                return (
                  <div key={contact.name} className={css.contact}>
                    <img src={contact.image} alt={contact.name} className={css.profileImage} />
                    <div className={css.contactInfo}>
                      <span className={css.nameAndLogo}>
                        <h3>{contact.name}</h3>
                        <a
                          href={contact.linkedIn}
                          target="_blank"
                          rel="noopener noreferrer"
                          className={css.desktopLogo}
                        >
                          {linkedInLogo}
                        </a>
                      </span>
                      <p>{contact.title}</p>
                      {contact.tel && <p>{contact.tel}</p>}
                      <p>
                        <a href={`mailto:${contact.email}`}>{contact.email}</a>
                      </p>
                      <a
                        href={contact.linkedIn}
                        target="_blank"
                        rel="noopener noreferrer"
                        className={css.mobileLogo}
                      >
                        {linkedInLogo}
                      </a>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </LayoutWrapperMain>

        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </LayoutSingleColumn>
    </StaticPage>
  );
};

export default ContactUs;
