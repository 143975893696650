import React from 'react';
import { injectIntl } from 'react-intl';

import { intlShape } from '../../../../util/reactIntl';

import sharedOfficeImg from './sharedOffice.png';
import onDemandImg from './onDemand.png';
import officesImg from './offices.png';
import css from './SectionUseCases.module.scss';
import { ButtonNew } from '../../../../components_new';
import classNames from 'classnames';

const SectionUseCases = ({ intl, buttonProps, history, className, hideButtons = false }) => {
  const SECTION_NAME = 'LandingPage.SectionUseCases';
  const classes = classNames(className, css.section);

  const goToSearchPage = (category) => {
    switch (category) {
      case 'coworking': {
        history.push(
          `/s?bounds=66.07380721%2C23.11029511%2C55.11320652%2C7.66319966&category=coworking&host=otherCompany`
        );
        break;
      }
      case 'sharedOffice': {
        history.push(
          `/s?bounds=66.07380721%2C23.11029511%2C55.11320652%2C7.66319966&category=fixed&host=otherCompany`
        );
        break;
      }
      case 'fixedOffice': {
        history.push(
          `/s?bounds=66.07380721%2C23.11029511%2C55.11320652%2C7.66319966&category=fixed&host=propertyOwner%2CofficeHotel%2Chotel`
        );
        break;
      }
    }
  };

  return (
    <section className={classes}>
      <div className={css.container}>
        <div className={css.header}>
          <span className={css.subtitle}>
            {intl.formatMessage({ id: `${SECTION_NAME}.subtitle` })}
          </span>
          <h3 className={css.title}>{intl.formatMessage({ id: `${SECTION_NAME}.title` })}</h3>
        </div>
        <div className={css.main}>
          <div className={css.useCase}>
            <div className={css.useCaseImgWrapper}>
              <img src={sharedOfficeImg} alt="Shared offices" />
            </div>
            <h4 className={css.useCaseTitle}>
              {intl.formatMessage({ id: `${SECTION_NAME}.sharedOffice.title` })}
            </h4>
            <ol>
              <li>{intl.formatMessage({ id: `${SECTION_NAME}.sharedOffice.bullet1` })}</li>
              <li>{intl.formatMessage({ id: `${SECTION_NAME}.sharedOffice.bullet2` })}</li>
              <li>{intl.formatMessage({ id: `${SECTION_NAME}.sharedOffice.bullet3` })}</li>
            </ol>
            {!hideButtons && (
              <ButtonNew onClick={() => goToSearchPage('sharedOffice')} {...buttonProps}>
                {intl.formatMessage({ id: `${SECTION_NAME}.sharedOffice.button` })}
              </ButtonNew>
            )}
          </div>
          <div className={css.useCase}>
            <div className={css.useCaseImgWrapper}>
              <img src={onDemandImg} alt="Coworking and meeting rooms on demand" />
            </div>
            <h4 className={css.useCaseTitle}>
              {intl.formatMessage({ id: `${SECTION_NAME}.onDemand.title` })}
            </h4>
            <ol>
              <li>{intl.formatMessage({ id: `${SECTION_NAME}.onDemand.bullet1` })}</li>
              <li>{intl.formatMessage({ id: `${SECTION_NAME}.onDemand.bullet2` })}</li>
              <li>{intl.formatMessage({ id: `${SECTION_NAME}.onDemand.bullet3` })}</li>
            </ol>
            {!hideButtons && (
              <ButtonNew onClick={() => goToSearchPage('coworking')} {...buttonProps}>
                {intl.formatMessage({ id: `${SECTION_NAME}.onDemand.button` })}
              </ButtonNew>
            )}
          </div>
          <div className={css.useCase}>
            <div className={css.useCaseImgWrapper}>
              <img src={officesImg} alt="office and office hotels" />
            </div>
            <h4 className={css.useCaseTitle}>
              {intl.formatMessage({ id: `${SECTION_NAME}.office.title` })}
            </h4>
            <ol>
              <li>{intl.formatMessage({ id: `${SECTION_NAME}.office.bullet1` })}</li>
              <li>{intl.formatMessage({ id: `${SECTION_NAME}.office.bullet2` })}</li>
            </ol>
            {!hideButtons && (
              <ButtonNew onClick={() => goToSearchPage('fixedOffice')} {...buttonProps}>
                {intl.formatMessage({ id: `${SECTION_NAME}.office.button` })}
              </ButtonNew>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

SectionUseCases.propTypes = {
  intl: intlShape,
};

export default injectIntl(SectionUseCases);
