export default (
  <svg width="16" height="22" viewBox="0 0 16 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_32062_17266)">
      <path
        d="M11.3333 8C11.3333 9.84167 9.84167 11.3333 8 11.3333C6.15833 11.3333 4.66667 9.84167 4.66667 8C4.66667 6.15833 6.15833 4.66667 8 4.66667C9.84167 4.66667 11.3333 6.15833 11.3333 8ZM8 10C9.10417 10 10 9.10417 10 8C10 6.89583 9.10417 6 8 6C6.89583 6 6 6.89583 6 8C6 9.10417 6.89583 10 8 10ZM16 8C16 11.6417 11.125 18.125 8.9875 20.8C8.475 21.4375 7.525 21.4375 7.0125 20.8C4.8375 18.125 0 11.6417 0 8C0 3.58167 3.58167 0 8 0C12.4167 0 16 3.58167 16 8ZM8 1.33333C4.31667 1.33333 1.33333 4.31667 1.33333 8C1.33333 8.65 1.55958 9.54167 2.02333 10.6417C2.47792 11.7208 3.11667 12.8917 3.83917 14.0625C5.25833 16.3667 6.94167 18.5708 8 19.9C9.05833 18.5708 10.7417 16.3667 12.1625 14.0625C12.8833 12.8917 13.5208 11.7208 13.975 10.6417C14.4417 9.54167 14.6667 8.65 14.6667 8C14.6667 4.31667 11.6833 1.33333 8 1.33333Z"
        fill="#9AB7DD"
      />
    </g>
    <defs>
      <clipPath id="clip0_32062_17266">
        <rect width="16" height="21.3333" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
