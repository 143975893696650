import React from 'react';
import { Form as FinalForm } from 'react-final-form';
import css from './CancelSubscriptionReasonForm.module.scss';
import {
  FieldRadioButton,
  FieldTextInput,
  Form,
  PrimaryButton,
  SecondaryButton,
} from '../../components';
import { useIntl } from 'react-intl';
import DropdownSelect from '../../components_new/DropdownSelect/DropdownSelect';
import { cancelReasons } from '../../containers/SubscriptionManagementPage/utils/subscription';
import { required } from '../../util/validators';
import { OutsideClickHandler } from '../../components';

const CancelSubscriptionReasonForm = (props) => {
  const intl = useIntl();
  return (
    <FinalForm
      {...props}
      render={(formRenderProps) => {
        const {
          handleSubmit,
          pristine,
          form,
          values,
          handlePauseOpenClick,
          nextBillingDate,
          cancelError,
          cancelInProgress,
          onClose,
          endOfBindingPeriod,
          isInCancellationWindow,
        } = formRenderProps;
        const cancelReasonsList = cancelReasons(intl);
        const onReasonSelect = (value) => {
          form.change('cancelReasonSelect', value);
        };
        const otherReasonFieldShowing =
          values?.cancelReasonSelect === 'Other' || values?.cancelReason === 'Other';
        const submitDisabled = cancelInProgress || pristine;
        return (
          <OutsideClickHandler onOutsideClick={onClose}>
            <Form onSubmit={handleSubmit} className={css.container}>
              <h2>{intl.formatMessage({ id: 'CancelSubscriptionReasonModal.title' })}</h2>
              <p className={css.desc}>
                {intl.formatMessage({ id: 'CancelSubscriptionReasonModal.pauseDesc' })}
                &nbsp;
                <span className={css.pauseLink} onClick={handlePauseOpenClick}>
                  {intl.formatMessage({ id: 'CancelSubscriptionReasonModal.pauseLink' })}
                </span>
              </p>
              <p className={css.desc}>
                {intl.formatMessage(
                  {
                    id: isInCancellationWindow
                      ? 'CancelSubscriptionReasonModal.cancelDesc'
                      : 'CancelSubscriptionReasonModal.cancelInBindingPeriod',
                  },
                  {
                    span: (msg) => <span className={css.boldText}>{msg}</span>,
                    date: isInCancellationWindow ? nextBillingDate : endOfBindingPeriod,
                  }
                )}
              </p>
              <div className={css.selectOptionWrapper}>
                <DropdownSelect
                  placeholder={intl.formatMessage({
                    id: 'CancelSubscriptionReasonModal.cancelReasonPlaceholder',
                  })}
                  options={cancelReasonsList}
                  onSelect={onReasonSelect}
                  selectedOption={values?.cancelReasonSelect}
                />
                <FieldTextInput id="cancelReasonSelect" name="cancelReasonSelect" type="hidden" />
              </div>
              <div className={css.radioOptionWrapper}>
                {cancelReasonsList.map((reason, index) => (
                  <FieldRadioButton
                    key={index}
                    id={`cancelReasonOption${index}`}
                    name="cancelReason"
                    label={reason.label}
                    value={reason.value}
                    // showAsRequired={pristine}
                    className={css.fieldRadio}
                  />
                ))}
              </div>
              {otherReasonFieldShowing && (
                <FieldTextInput
                  id="cancelReasonOther"
                  name="cancelReasonOther"
                  type="textarea"
                  className={css.textareaField}
                  validate={required(
                    intl.formatMessage({ id: 'CancelSubscriptionReasonModal.reasonRequired' })
                  )}
                />
              )}
              {cancelError && (
                <div className={css.error}>
                  {intl.formatMessage({ id: 'CancelSubscriptionReasonModal.cancelError' })}
                </div>
              )}
              <div className={css.bottomButtonWrapper}>
                <PrimaryButton type="button" className={css.noCancelBtn} onClick={onClose}>
                  {intl.formatMessage({ id: 'CancelSubscriptionReasonModal.noCancel' })}
                </PrimaryButton>
                <SecondaryButton
                  type="submit"
                  className={css.submitCancelBtn}
                  inProgress={cancelInProgress}
                  disabled={submitDisabled}
                >
                  {intl.formatMessage({ id: 'CancelSubscriptionReasonModal.submitCancel' })}
                </SecondaryButton>
              </div>
            </Form>
          </OutsideClickHandler>
        );
      }}
    />
  );
};

CancelSubscriptionReasonForm.defaultProps = {};

CancelSubscriptionReasonForm.propTypes = {};
export default CancelSubscriptionReasonForm;
