import React from 'react';
import { FormattedMessage } from 'react-intl';
import { array, string } from 'prop-types';

import { ExpandableText } from '../../../components_new';
import ListingPageSection from '../ListingPageSection/ListingPageSection';

import css from './SectionDescription.module.scss';
import commonCss from '../Common.module.scss';

const SectionDescription = ({ description, descriptionKeywords }) => (
  <ListingPageSection title="ListingPage.about.title">
    <ul className={commonCss.keywords}>
      {descriptionKeywords[0] &&
        descriptionKeywords.map((keyword, i) => (
          <li key={`${i}keyword_${keyword}`} className={commonCss.keywords}>
            <FormattedMessage id={keyword.labelId}>
              {(id) => (
                <span className={css.keyword} key={`keyword_${keyword}`}>
                  {id}
                </span>
              )}
            </FormattedMessage>
            {i < descriptionKeywords.length - 1 && i < 2 && <span className={css.separator} />}
          </li>
        ))}
    </ul>
    <ExpandableText text={description} />
  </ListingPageSection>
);

SectionDescription.propTypes = {
  description: string,
  descriptionKeywords: array,
};

export default SectionDescription;
