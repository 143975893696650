import React, { useState, useEffect } from 'react';
import { StaticPage, TopbarContainer } from '../../containers';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  LayoutSingleColumn,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  Footer,
  NamedLink,
} from '../../components';
import axios from 'axios';
import Logo from './Logo';
import css from './CareersPage.module.scss';

const CareersPage = () => {
  const intl = useIntl();
  const [availableJobs, setAvailableJobs] = useState(null);

  const name = 'hello';
  const domain = 'respaces.co';

  useEffect(() => {
    axios
      // TODO - maybe add a sort to the jobs displayed
      .get('https://cms.respaces.co/jobs')
      .then((response) => {
        setAvailableJobs(response.data);
      })
      .catch((err) => console.error(err));
  }, []);
  return (
    <StaticPage
      title="Respaces Careers"
      description={intl.formatMessage({ id: 'CareersPage.schemaDescription' })}
      schema={{
        '@context': 'http://schema.org',
        '@type': 'CareersPage',
        description: 'Careers at Respaces, see what jobs are available and apply here',
        name: 'Careers Page',
      }}
    >
      <LayoutSingleColumn>
        <LayoutWrapperTopbar>
          <TopbarContainer currentPage="CareersPage" />
        </LayoutWrapperTopbar>

        <LayoutWrapperMain className={css.mainWrapper}>
          <h1 className={css.title}>{intl.formatMessage({ id: 'CareersPage.Title' })}</h1>
          <p className={css.subtitle}>{intl.formatMessage({ id: 'CareersPage.Subtitle' })}</p>
          {availableJobs && !availableJobs.length ? (
            // probably need a replacement text here
            <p className={css.subtitle}>{intl.formatMessage({ id: 'CareersPage.noJobs' })}</p>
          ) : (
            <div className={css.tableContainer}>
              <div className={css.jobTableDesktop}>
                <table>
                  <tr>
                    <th>
                      <FormattedMessage id="CareersPage.jobTitle" />
                    </th>
                    <th>
                      <FormattedMessage id="CareersPage.jobLocation" />
                    </th>
                    <th>
                      <FormattedMessage id="CareersPage.jobDepartment" />
                    </th>
                    <th></th>
                  </tr>
                  {availableJobs &&
                    availableJobs.map((job) => (
                      <tr key={job._id}>
                        <td>{job.title}</td>
                        <td>{job.location}</td>
                        <td>{job.department}</td>
                        <td>
                          <NamedLink
                            className={css.linkWrapper}
                            name="JobPost"
                            params={{ slug: job.slug }}
                          >
                            <FormattedMessage id="CareersPage.findOutMore">
                              {(id) => <span className={css.moreArrow}>{id}</span>}
                            </FormattedMessage>
                          </NamedLink>
                        </td>
                      </tr>
                    ))}
                </table>
              </div>
              <div className={css.jobTableMobile}>
                <table>
                  {availableJobs &&
                    availableJobs.map((job) => (
                      <tr className={css.mobileRow}>
                        <tr key={job._id}>
                          <td>
                            {job.title}: {job.department}
                          </td>
                        </tr>
                        <tr>
                          <td>{job.location}</td>
                        </tr>
                        <tr>
                          <td>
                            <NamedLink
                              className={css.linkWrapper}
                              name="JobPost"
                              params={{ slug: job.slug }}
                            >
                              <FormattedMessage id="CareersPage.findOutMore">
                                {(id) => <span className={css.moreArrow}>{id}</span>}
                              </FormattedMessage>
                            </NamedLink>
                          </td>
                        </tr>
                      </tr>
                    ))}
                </table>
              </div>
            </div>
          )}
          <h3 className={css.footer}>
            <span>{intl.formatMessage({ id: 'CareersPage.Footer' })}</span>
            <a className={css.helloButton} href={`mailto:${name}@${domain}?subject=hello`}>
              hello@respaces.co
            </a>
          </h3>
          <Logo className={css.illustration} />
        </LayoutWrapperMain>

        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </LayoutSingleColumn>
    </StaticPage>
  );
};

export default CareersPage;
