import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import css from './BookingBreakdown.module.scss';

const LineItemCustomerInfor = ({ transaction, currentUser }) => {
  const displayName = currentUser.attributes.profile.displayName;
  const email = currentUser.attributes.email;
  return (
    <>
      <div className={css.lineItem}>
        <span>
          <FormattedMessage id="BookingBreakdown.credit.name" />
        </span>
        <span className={css.itemValue}>{displayName}</span>
      </div>
      <hr className={css.totalDivider} />

      <div className={css.lineItem}>
        <span>
          <FormattedMessage id="BookingBreakdown.credit.email" />
        </span>
        <span className={css.itemValue}>{email}</span>
      </div>
      <hr className={css.totalDivider} />
    </>
  );
};

export default LineItemCustomerInfor;
