import React, { useEffect, useRef, useState, useMemo } from 'react';
import { Form as FinalForm } from 'react-final-form';
import css from './ChangePaymentInfoForm.module.scss';
import {
  Form,
  StripeSplitingCreditCard,
  FieldTextInput,
  PrimaryButton,
  IconOrganizations as Icons,
  IconSpinner,
  ChangePaymentInfoSuccessModal,
} from '../../components';
import { useIntl } from 'react-intl';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import config from '../../config';
import { stripeErrorTranslation } from '../../util/stripe';
import { ensurePaymentMethodCard } from '../../util/data';
import get from 'lodash/get';
import { required } from '../../util/validators';
import BottomButtons from '../../containers/SubscriptionManagementPage/MySubscriptionTab/BottomButtons';
import { changePaymentMethod } from '../../containers/SubscriptionManagementPage/SubscriptionManagementPage.duck';
import { types as sdkTypes } from '../../util/sdkLoader';
import { useBoolean } from '../../util/hooks';

const { UUID } = sdkTypes;
const INVOICE_METHOD = 'invoiceMethod';
const CREDIT_CARD_METHOD = 'creditCardMethod';

const ChangePaymentInfoForm = () => {
  const intl = useIntl();
  const [stripe, setStripe] = useState(null);
  const [cardNumberError, setCardNumberError] = useState(null);
  const [cardNumberValid, setCardNumberValid] = useState(false);
  const [cardExpiryError, setCardExpiryError] = useState(null);
  const [cardExpiryValid, setCardExpiryValid] = useState(false);
  const [cardCvcError, setCardCvcError] = useState(null);
  const [cardCvcValid, setCardCvcValid] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState(null);
  const [cardPaymentMethod, setCardPaymentMethod] = useState(null);
  const [isSuccessModalOpen, setSuccessModalOpen] = useBoolean();
  const dispatch = useDispatch();
  const stripeCard = useRef(null);
  const fetchMySubscriptionInProgress = useSelector(
    (state) => state.SubscriptionManagementPage.fetchMySubscriptionInProgress
  );
  const currentUser = useSelector((state) => state.user.currentUser, shallowEqual);

  const changePaymentMethodInProgress = useSelector(
    (state) => state.SubscriptionManagementPage.changePaymentMethodInProgress,
    shallowEqual
  );
  const chargebeeBillingAddress = useSelector(
    (state) => state.SubscriptionManagementPage.chargebeeBillingAddress,
    shallowEqual
  );
  const primaryPaymentSource = useSelector(
    (state) => state.SubscriptionManagementPage.primaryPaymentSource,
    shallowEqual
  );

  const onChangePaymentMethod = ({ paymentSource, billingAddress, primaryPaymentSource }) =>
    dispatch(changePaymentMethod({ paymentSource, billingAddress, primaryPaymentSource }));

  useEffect(() => {
    if (window && !window.Stripe) {
      throw new Error('Stripe must be loaded for StripePaymentForm');
    }

    if (config.stripe.publishableKey) {
      setStripe(window.Stripe(config.stripe.publishableKey));
    }
  }, []);
  useEffect(() => {
    const paymentType = get(currentUser, 'attributes.profile.metadata.paymentType', '');
    if (paymentType === 'invoice') {
      setPaymentMethod(INVOICE_METHOD);
    } else if (paymentType === 'card') {
      setPaymentMethod(CREDIT_CARD_METHOD);
    }
  }, [currentUser]);

  const invoiceMethodText = intl.formatMessage({
    id: 'ChangePaymentInfoForm.invoiceMethodText',
  });

  const creditCardMethodText = intl.formatMessage({
    id: 'ChangePaymentInfoForm.creditCardMethodText',
  });
  const hasDefaultPaymentMethod = !!primaryPaymentSource;

  const defaultPaymentMethod = primaryPaymentSource
    ? {
        ...ensurePaymentMethodCard({}),
        id: new UUID(),
        attributes: {
          ...ensurePaymentMethodCard({}).attributes,
          card: {
            brand: primaryPaymentSource.brand,
            expirationMonth: primaryPaymentSource.expiry_month,
            expirationYear: primaryPaymentSource.expiry_year,
            last4Digits: primaryPaymentSource.last4,
          },
        },
      }
    : {};
  const ensuredDefaultPaymentMethod = ensurePaymentMethodCard(defaultPaymentMethod);

  const orgName = get(currentUser, 'attributes.profile.publicData.organization', '');
  const businessId = get(currentUser, 'attributes.profile.protectedData.businessId', '');
  const { line1, line2, city, email } = chargebeeBillingAddress || {};

  const initialValues = useMemo(
    () => ({
      addressLine1: line1,
      addressLine2: line2,
      invoiceEmail: email,
      city,
    }),
    [city, email, line1, line2]
  );

  const handleCardChange = (event, cardField) => {
    const { error, complete } = event;
    const _error = error ? stripeErrorTranslation(intl, error) : null;
    switch (cardField) {
      case 'cardNumber':
        setCardNumberError(_error);
        setCardNumberValid(complete);
        break;
      case 'cardExpiry': {
        setCardExpiryError(_error);
        setCardExpiryValid(complete);
        break;
      }
      case 'cardCvc': {
        setCardCvcError(_error);
        setCardCvcValid(complete);
        break;
      }
      default:
        break;
    }
  };

  const handleInvoiceMethod = (form) => () => {
    stripeCard.current = null;
    form.change('cardHolderName', '');
    setPaymentMethod(INVOICE_METHOD);
  };

  const handleCreditCardMethod = (form) => () => {
    setPaymentMethod(CREDIT_CARD_METHOD);
  };

  const handleChangePaymentMethodSubmit = async (values) => {
    const { addressLine1, addressLine2, city, invoiceEmail } = values;
    let cardTokenResult;
    if (paymentMethod === CREDIT_CARD_METHOD && stripeCard?.current?.cardNumber) {
      cardTokenResult = await stripe.createToken(stripeCard.current.cardNumber);
    }
    const paymentSource =
      paymentMethod === CREDIT_CARD_METHOD && cardTokenResult ? cardTokenResult?.token?.id : null;
    const billingAddress =
      paymentMethod === INVOICE_METHOD
        ? {
            line1: addressLine1,
            line2: addressLine2,
            city,
            email: invoiceEmail,
            country: config.address.addressCountry, // SE
          }
        : null;
    await onChangePaymentMethod({ paymentSource, billingAddress, primaryPaymentSource });
    setSuccessModalOpen.on();
  };

  return fetchMySubscriptionInProgress ? (
    <div className={css.loading}>
      <IconSpinner />
    </div>
  ) : (
    <FinalForm
      onSubmit={handleChangePaymentMethodSubmit}
      initialValues={initialValues}
      render={(formRenderProps) => {
        const { handleSubmit, form, invalid } = formRenderProps;
        const isInvoice = paymentMethod === INVOICE_METHOD;
        const cardFormInvalid =
          cardNumberError ||
          cardExpiryError ||
          cardCvcError ||
          !cardNumberValid ||
          !cardExpiryValid ||
          !cardCvcValid;
        const submitDisabled =
          invalid ||
          (!isInvoice && !hasDefaultPaymentMethod && cardFormInvalid) ||
          (!isInvoice && cardPaymentMethod === 'replaceCard' && cardFormInvalid);
        return (
          <Form className={css.formContainer} onSubmit={handleSubmit}>
            <div className={css.paymentMethodWrapper}>
              <div
                className={`${css.paymentMethod} ${
                  paymentMethod === INVOICE_METHOD && css.selected
                }`}
                onClick={handleInvoiceMethod(form)}
              >
                <Icons.IconInvoice className={css.paymentMethodIcon} />
                <span className={css.paymentMethodText}>{invoiceMethodText}</span>
              </div>
              <div
                className={`${css.paymentMethod} ${
                  paymentMethod === CREDIT_CARD_METHOD && css.selected
                }`}
                onClick={handleCreditCardMethod(form)}
              >
                <Icons.IconCreditCard className={css.paymentMethodIcon} />
                <span className={css.paymentMethodText}>{creditCardMethodText}</span>
              </div>
            </div>
            {paymentMethod === INVOICE_METHOD && (
              <>
                <div className={css.textRow}>
                  <span className={css.title}>
                    {intl.formatMessage({ id: 'ChangePaymentInfoForm.orgName' })}
                  </span>
                  <span className={css.text}>{orgName}</span>
                </div>
                <div className={css.textRow}>
                  <span className={css.title}>
                    {intl.formatMessage({ id: 'ChangePaymentInfoForm.businessId' })}
                  </span>
                  <span className={css.text}>{businessId}</span>
                </div>
                <div className={css.billingAddressTitle}>
                  {intl.formatMessage({ id: 'ChangePaymentInfoForm.billingAddressTitle' })}
                </div>
                <div className={css.fieldInputWrapper}>
                  <FieldTextInput
                    id="addressLine1"
                    name="addressLine1"
                    placeholder={intl.formatMessage({
                      id: 'ChangePaymentInfoForm.addressLine1Placeholder',
                    })}
                    label={intl.formatMessage(
                      { id: 'ChangePaymentInfoForm.addressLine1Label' },
                      {
                        sup: (msg) => <sup>{msg}</sup>,
                      }
                    )}
                    inputRootClass={css.fieldInput}
                    labelClassName={css.fieldLabel}
                    validate={required(
                      intl.formatMessage({ id: 'ChangePaymentInfoForm.addressLine1Required' })
                    )}
                  />
                </div>
                <div className={css.fieldInputWrapper}>
                  <FieldTextInput
                    id="addressLine2"
                    name="addressLine2"
                    placeholder={intl.formatMessage({
                      id: 'ChangePaymentInfoForm.addressLine2Placeholder',
                    })}
                    label={intl.formatMessage({ id: 'ChangePaymentInfoForm.addressLine2Label' })}
                    inputRootClass={css.fieldInput}
                    labelClassName={css.fieldLabel}
                  />
                </div>
                <div className={css.fieldInputWrapper}>
                  <FieldTextInput
                    id="city"
                    name="city"
                    placeholder={intl.formatMessage({
                      id: 'ChangePaymentInfoForm.cityPlaceholder',
                    })}
                    label={intl.formatMessage(
                      { id: 'ChangePaymentInfoForm.cityLabel' },
                      {
                        sup: (msg) => <sup>{msg}</sup>,
                      }
                    )}
                    inputRootClass={css.fieldInput}
                    labelClassName={css.fieldLabel}
                    validate={required(
                      intl.formatMessage({ id: 'ChangePaymentInfoForm.cityRequired' })
                    )}
                  />
                </div>
                <div className={css.horizontalLine}></div>
                <div className={css.fieldInputWrapper}>
                  <FieldTextInput
                    id="invoiceEmail"
                    name="invoiceEmail"
                    placeholder={intl.formatMessage({
                      id: 'ChangePaymentInfoForm.invoiceEmailPlaceholder',
                    })}
                    label={intl.formatMessage({ id: 'ChangePaymentInfoForm.invoiceEmailLabel' })}
                    inputRootClass={css.fieldInput}
                    labelClassName={css.fieldLabel}
                  />
                </div>
              </>
            )}
            {paymentMethod === CREDIT_CARD_METHOD && (
              <>
                <StripeSplitingCreditCard
                  stripe={stripe}
                  stripeCard={stripeCard}
                  defaultPaymentMethod={ensuredDefaultPaymentMethod}
                  handleCardChange={handleCardChange}
                  setCardNumberValid={setCardNumberValid}
                  setCardExpiryValid={setCardExpiryValid}
                  setCardCvcValid={setCardCvcValid}
                  paymentMethod={cardPaymentMethod}
                  setPaymentMethod={setCardPaymentMethod}
                  showCardDetail={hasDefaultPaymentMethod}
                />
              </>
            )}
            <div className={css.submitDesktopWrapper}>
              <div className={css.conditionConfirm}>
                {intl.formatMessage({ id: 'ChangePaymentInfoForm.conditionText' })}
              </div>
              <PrimaryButton
                type="submit"
                className={css.confirmBtn}
                disabled={submitDisabled}
                inProgress={changePaymentMethodInProgress}
              >
                {intl.formatMessage({ id: 'MySubscriptionTab.saveText' })}
              </PrimaryButton>
            </div>
            <BottomButtons
              leftBtnText={intl.formatMessage({ id: 'MySubscriptionTab.discard' })}
              rightBtnText={intl.formatMessage({ id: 'MySubscriptionTab.saveText' })}
              rightBtnType="submit"
              rightBtnInProgress={changePaymentMethodInProgress}
              rightBtnDisabled={submitDisabled}
            />
            <ChangePaymentInfoSuccessModal
              isOpen={isSuccessModalOpen}
              onClose={setSuccessModalOpen.off}
            />
          </Form>
        );
      }}
    />
  );
};

ChangePaymentInfoForm.defaultProps = {};

ChangePaymentInfoForm.propTypes = {};
export default ChangePaymentInfoForm;
