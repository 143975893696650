import React, { memo } from 'react';
import { injectIntl } from 'react-intl';

import { isMobile } from '../../util/device';

import { DesktopTable, MobileTable } from './components';
import { TABLE_PROP_TYPES } from './config/propTypes';

const Table = memo(({ headers, data, tableId, onRowClick, onInitAction, intl }) =>
  isMobile ? (
    <MobileTable
      headers={headers}
      data={data}
      tableId={tableId}
      onRowClick={onRowClick}
      onInitAction={onInitAction}
      intl={intl}
    />
  ) : (
    <DesktopTable
      headers={headers}
      data={data}
      tableId={tableId}
      onRowClick={onRowClick}
      onInitAction={onInitAction}
      intl={intl}
    />
  )
);

Table.propTypes = TABLE_PROP_TYPES;

export default injectIntl(Table);
