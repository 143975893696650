import React from 'react';
import { bool, object } from 'prop-types';
import { intlShape, injectIntl } from '../../util/reactIntl';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';

import config from '../../config';

import SortByPlain from './SortByPlain';
import SortByPopup from './SortByPopup';

const SortBy = (props) => {
  const {
    sort,
    options,
    showAsPopup,
    isConflictingFilterActive,
    intl,
    origin,
    defaultSeats,
    isNearbySpaces,
    ...rest
  } = props;

  const { queryParamName } = config.custom.sortConfig;

  const preparedOptions =
    options ||
    config.custom.sortConfig.options.map((option) => {
      return {
        ...option,
      };
    });

  const componentProps = {
    urlParam: queryParamName,
    label: intl.formatMessage({ id: 'SortBy.heading' }),
    options: preparedOptions,
    /* initialValue: isNearbySpaces
      ? 'nearbySpaces'
      : origin && !sort
      ? 'meta_rating'
      : sort || defaultValue, */
    initialValue: sort || preparedOptions[0].key,
    intl,
    ...rest,
  };

  return showAsPopup ? <SortByPopup {...componentProps} /> : <SortByPlain {...componentProps} />;
};

const mapStateToProps = (state) => {
  const {
    SearchPage: { isNearbySpaces },
  } = state;

  return {
    isNearbySpaces,
  };
};
SortBy.defaultProps = {
  sort: null,
  showAsPopup: false,
};

SortBy.propTypes = {
  sort: object,
  showAsPopup: bool,
  isConflictingFilterActive: bool.isRequired,
  intl: intlShape.isRequired,
};

export default compose(withRouter, connect(mapStateToProps), injectIntl)(SortBy);
