import React from 'react';
import { FormattedMessage, FormattedDate } from '../../util/reactIntl';
import moment from 'moment';
import {
  DATE_TYPE_DATE,
  propTypes,
  HOURLY_BOOKING,
  DATE_TYPE_DATETIME,
  DAILY_BOOKING,
} from '../../util/types';

import css from './BookingBreakdown.module.scss';

const BookingPeriod = (props) => {
  const { startDate, endDate, dateType, timeZone } = props;

  const timeFormatOptions =
    dateType === DATE_TYPE_DATE
      ? {
          weekday: 'short',
        }
      : {
          weekday: 'short',
          hour: 'numeric',
          minute: 'numeric',
        };

  const dateFormatOptions = {
    month: 'short',
    day: 'numeric',
  };

  const timeZoneMaybe = timeZone ? { timeZone } : null;

  return (
    <>
      <div className={css.lineItem}>
        <div className={css.itemLabel}>
          <FormattedMessage id="BookingBreakdown.bookingStart" />
        </div>
        <div className={css.dayInfo}>
          <FormattedDate value={startDate} {...timeFormatOptions} {...timeZoneMaybe} />
        </div>
        <div className={css.itemValue}>
          <FormattedDate value={startDate} {...dateFormatOptions} {...timeZoneMaybe} />
        </div>
      </div>
      <hr className={css.totalDivider} />
      <div className={css.lineItem}>
        <div className={css.itemLabel}>
          <FormattedMessage id="BookingBreakdown.bookingEnd" />
        </div>
        <div className={css.dayInfo}>
          <FormattedDate value={endDate} {...timeFormatOptions} {...timeZoneMaybe} />
        </div>
        <div className={css.itemValue}>
          <FormattedDate value={endDate} {...dateFormatOptions} {...timeZoneMaybe} />
        </div>
      </div>
      <hr className={css.totalDivider} />
    </>
  );
};

const LineItemBookingPeriod = (props) => {
  const { booking, timeZone, bookingType } = props;

  // Attributes: displayStart and displayEnd can be used to differentiate shown time range
  // from actual start and end times used for availability reservation. It can help in situations
  // where there are preparation time needed between bookings.
  // Read more: https://www.sharetribe.com/api-reference/marketplace.html#bookings
  const { start, end, displayStart, displayEnd } = booking.attributes;
  const localStartDate = displayStart || start;
  const localEndDateRaw = displayEnd || end;

  const endDay =
    bookingType === DAILY_BOOKING ? moment(localEndDateRaw).subtract(1, 'days') : localEndDateRaw;
  const dateType = bookingType === HOURLY_BOOKING ? DATE_TYPE_DATETIME : DATE_TYPE_DATE;
  return (
    <>
      <BookingPeriod
        startDate={localStartDate}
        endDate={endDay}
        dateType={dateType}
        timeZone={timeZone}
        bookingType={bookingType}
      />
    </>
  );
};
LineItemBookingPeriod.defaultProps = { dateType: null };

LineItemBookingPeriod.propTypes = {
  booking: propTypes.booking.isRequired,
  dateType: propTypes.dateType,
};

export default LineItemBookingPeriod;
