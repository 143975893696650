import React, { Component } from 'react';
import { string, bool } from 'prop-types';
import { injectIntl, intlShape, FormattedMessage } from '../../util/reactIntl';
import { propTypes } from '../../util/types';
import classNames from 'classnames';

import { FieldDateRangeController, OutsideClickHandler } from '../../components';
import css from './DateRangeLengthFilterHero.module.scss';

export class DateRangeLengthFilterHeroComponent extends Component {
  constructor(props) {
    super(props);

    this.state = { isOpen: false };
    this.state = { isDateOpen: false };
  }

  componentDidUpdate(prevProps) {
    if (this.props.placeholder !== prevProps.placeholder) {
      this.setState({ isOpen: false });
      this.setState({ isDateOpen: false });
    }
  }

  render() {
    const { placeholder, compRef } = this.props;
    const datesQueryParamName = 'dates';

    const popUpWidth = () => {
      return document.getElementById('datesWrapper').offsetWidth + 2;
    };

    return (
      <>
        <div
          className={css.datesWrapper}
          id="datesWrapper"
          onClick={() => {
            if (this.state.isOpen !== true) {
              this.setState({ isOpen: true });
            }
          }}
          ref={compRef}
        >
          <section
            className={classNames(css.placeholder, {
              [css.placeholderFilled]: this.props.datesPlaceholderChanged,
            })}
          >
            {placeholder}
          </section>

          {this.state.isOpen ? (
            <section className={css.alignmentSection}>
              <OutsideClickHandler
                onOutsideClick={() => {
                  this.setState({ isOpen: false });
                  this.setState({ isDateOpen: false });
                }}
              >
                <div className={css.daySelector} style={{ width: popUpWidth() }}>
                  <div
                    className={css.dayOption}
                    onClick={() => {
                      this.props.todaysDate();
                      this.setState({ isOpen: false });
                    }}
                  >
                    <FormattedMessage id="DateRangeLengthFilterHero.today" />
                  </div>
                  <div
                    className={css.dayOption}
                    onClick={() => {
                      this.props.tomorrowsDate();
                      this.setState({ isOpen: false });
                    }}
                  >
                    <FormattedMessage id="DateRangeLengthFilterHero.tomorrow" />
                  </div>
                  <div
                    className={css.dayOption}
                    onClick={() => {
                      this.setState({ isDateOpen: true });
                    }}
                  >
                    <FormattedMessage id="DateRangeLengthFilterHero.selectDate" />
                  </div>

                  {this.state.isDateOpen ? (
                    <FieldDateRangeController
                      className={css.dateRange}
                      name={datesQueryParamName}
                      controllerRef={(node) => {
                        this.popupControllerRef = node;
                      }}
                    />
                  ) : null}
                </div>
              </OutsideClickHandler>
            </section>
          ) : null}
        </div>
      </>
    );
  }
}

DateRangeLengthFilterHeroComponent.defaultProps = {
  rootClassName: null,
  className: null,
  dateRangeLengthFitler: null,
  liveEdit: false,
  contentPlacementOffset: 0,
  placeholder: 'Dates',
  PlaceholderChanged: false,
};

DateRangeLengthFilterHeroComponent.propTypes = {
  rootClassName: string,
  className: string,
  // id: string.isRequired,
  dateRangeLengthFitler: propTypes.filterConfig,
  intl: intlShape.isRequired,
  placeholder: string,
  datesPlaceholderChanged: bool,
};

const DateRangeLengthFilterHero = injectIntl(DateRangeLengthFilterHeroComponent);

export default DateRangeLengthFilterHero;
