const FixedOfficeContactEmail = (values, listing, recipient) => {
  //Sends an email to the provider with all order details. Uses Mailchimp API
  // and sends all data points as an object. This objects needs to be built and
  // the data needs to be calculated before sending.

  // Mailchimp API for sending transactional emails to provider when paymant is confirmed
  const MAILCHIMP_APP_KEY = process.env.REACT_APP_MAILCHIMP_TRANSACTIONAL_KEY;
  const mailchimp = require('@mailchimp/mailchimp_transactional')(MAILCHIMP_APP_KEY);

  const sendShowingEmail = async () => {
    // These are the variables sent to Mailchimp and accessed there by the name tag.
    let global_merge_vars = [
      {
        name: 'listingName',
        content: listing.attributes.title,
      },
      {
        name: 'fname',
        content: values.fname,
      },
      {
        name: 'address',
        content: listing.attributes.publicData.location.address,
      },
      {
        name: 'companyName',
        content: values.companyName,
      },
      {
        name: 'phonenumber',
        content: values.phonenumber,
      },
      {
        name: 'email',
        content: values.email,
      },
      {
        name: 'numberOfSeats',
        content: values.numberOfSeats,
      },
      {
        name: 'budget',
        content: values.budget,
      },
    ];
    // Check if the email should go to the buyer or to the seller and return the email adress
    const getRecipient = (recipient) => {
      if (recipient === 'provider') {
        // check if the provider have one or two emails and then add the existing ones.
        return listing.attributes.publicData.bookingEmailAddress[1]
          ? [
              {
                email: listing.attributes.publicData.bookingEmailAddress[0],
                type: 'to',
              },
              {
                email: listing.attributes.publicData.bookingEmailAddress[1],
                type: 'to',
              },
            ]
          : [
              {
                email: listing.attributes.publicData.bookingEmailAddress[0],
                type: 'to',
              },
            ];
      } else if (recipient === 'buyer') {
        return [
          {
            email: values.email,
            type: 'to',
          },
        ];
      }
    };
    const toEmail = getRecipient(recipient);

    const getTemplate = (recipient) => {
      if (recipient === 'provider') {
        return 'Showing confirmation - Partner';
      } else if (recipient === 'buyer') {
        return 'Showing confirmation - Guest';
      }
    };
    const template = getTemplate(recipient);

    const getMessage = (recipient) => {
      if (recipient === 'provider') {
        // Gives different emails for provider and buyer
        return {
          from_email: 'bookings@respaces.co',
          from_name: 'The Respaces Team',
          subject: `New lead for ${listing.attributes.title}`,
          text: `You have a new request for information about ${listing.attributes.title}. The booking was made by ${values.fname}. "${values.message}"`,
          to: toEmail,
          global_merge_vars: global_merge_vars,
        };
      } else if (recipient === 'buyer') {
        return {
          from_email: 'bookings@respaces.co',
          from_name: 'The Respaces Team',
          subject: `Your interest for ${listing.attributes.title}`,
          text: `You have requested information about ${listing.attributes.title}. A representative will be in contact with you shortly. Your message reads: "${values.message}"`,
          to: toEmail,
          global_merge_vars: global_merge_vars,
        };
      }
    };

    const message = getMessage(recipient);

    // eslint-disable-next-line
    const response = await mailchimp.messages.sendTemplate({
      template_name: template,
      message: message,
      template_content: global_merge_vars,
    });
    return true;
  };

  const result = sendShowingEmail();
  return result;
};

export default FixedOfficeContactEmail;
