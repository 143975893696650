import React from 'react';

import css from './LocationCombobox.module.scss';

const CurrentLocationIcon = () => (
  <svg
    className={css.currentLocationIcon}
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.82246 4.45455C5.45177 4.45455 4.34157 5.59364 4.34157 7C4.34157 8.40636 5.45177 9.54545 6.82246 9.54545C8.19316 9.54545 9.30336 8.40636 9.30336 7C9.30336 5.59364 8.19316 4.45455 6.82246 4.45455ZM12.3673 6.36364C12.082 3.71 10.029 1.60364 7.44269 1.31091V0H6.20224V1.31091C3.61591 1.60364 1.56296 3.71 1.27766 6.36364H0V7.63636H1.27766C1.56296 10.29 3.61591 12.3964 6.20224 12.6891V14H7.44269V12.6891C10.029 12.3964 12.082 10.29 12.3673 7.63636H13.6449V6.36364H12.3673ZM6.82246 11.4545C4.4222 11.4545 2.4809 9.46273 2.4809 7C2.4809 4.53727 4.4222 2.54545 6.82246 2.54545C9.22273 2.54545 11.164 4.53727 11.164 7C11.164 9.46273 9.22273 11.4545 6.82246 11.4545Z"
      fill="black"
    />
  </svg>
);

export default CurrentLocationIcon;
