import React from 'react';
import { StaticPage, TopbarContainer } from '..';
import {
  LayoutSingleColumn,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  Footer,
} from '../../components';
import { getLocale } from '../../util/localeHelper';
import { LANGUAGE_CODES } from '../../util/modernmt';
import { Widget } from '@typeform/embed-react';
import config from '../../config';
import css from './OnboardingPartnersPage.module.scss';

const OnboardingPartnersPage = () => {
  let formId = getLocale() === LANGUAGE_CODES.SWEDISH ? 'X5EXgoJx' : 'Ve4a0YNo';

  if (config.dev) formId = formId + '?__dangerous-disable-submissions';

  return (
    <StaticPage
      title="Respaces Partner Onboarding"
      description="Page with a form to collection information on the office space a potential partner has available."
      schema={{
        '@context': 'http://schema.org',
        '@type': 'WebPage',
        description: 'Questioneer to get started with Respaces',
        name: 'Respaces Partner Onboarding',
      }}
    >
      <LayoutSingleColumn>
        <LayoutWrapperTopbar>
          <TopbarContainer />
        </LayoutWrapperTopbar>
        <LayoutWrapperMain className={css.wrapperMain}>
          <Widget id={formId} className={css.typeform} />
        </LayoutWrapperMain>
        <LayoutWrapperFooter />
      </LayoutSingleColumn>
    </StaticPage>
  );
};

export default OnboardingPartnersPage;
