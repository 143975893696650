import React, { useState } from 'react';
import { bool, func, shape, string } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import {
  Form,
  FieldTextInput,
  FixedBottomButtons,
  SaveAndExitButton,
  FieldRadioButton,
  FieldSelect,
  InlineTextButton,
} from '../../components';
import get from 'lodash/get';
import pickBy from 'lodash/pickBy';
import * as validators from '../../util/validators';

import css from './EditListingEmailForm.module.scss';

export const EditListingEmailFormComponent = (props) => {
  const [showTokenUpdateInput, setShowTokenUpdateInput] = useState(false);
  const [showIdUpdateInput, setShowIdUpdateInput] = useState(false);
  return (
    <FinalForm
      {...props}
      render={(formRenderProps) => {
        const {
          className,
          disabled,
          ready,
          handleSubmit,
          intl,
          updateInProgress,
          fetchErrors,
          isPublished,
          inProgress,
          onHandleSaveAndExit,
          values,
          invalid,
          initialValues,
          isFixedOffice,
        } = formRenderProps;

        const accessProvider = get(values, 'accessProvider', null);

        const emailContentLabelMessage = intl.formatMessage({
          id: 'EditListingEmailForm.emailContentLabel',
        });
        const emailContentPlaceholderMessage = intl.formatMessage({
          id: 'EditListingEmailForm.emailContentPlaceholder',
        });
        const bookingEmailAddressLabelMessage = intl.formatMessage({
          id: `EditListingEmailForm.bookingEmailAddressLabel${isFixedOffice ? 'FixedOffice' : ''}`,
        });
        const bookingEmailAddressSubLabelMessage = intl.formatMessage({
          id: 'EditListingEmailForm.bookingEmailAddressSubLabelFixedOffice',
        });
        const bookingEmailAddressPlaceholderMessage = intl.formatMessage({
          id: 'EditListingEmailForm.bookingEmailAddressPlaceholder',
        });
        const bookingEmailAddressCopyLabelMessage = intl.formatMessage({
          id: `EditListingEmailForm.bookingEmailAddressCopyLabel${
            isFixedOffice ? 'FixedOffice' : ''
          }`,
        });
        const emailContentSubTitle = intl.formatMessage({
          id: 'EditListingEmailPanel.createListingSubTitle',
        });
        const emailRequiredMessage = intl.formatMessage({
          id: 'EditListingEmailPanel.emailRequiredMessage',
        });
        const digitalKeyPlaceholderMessage = intl.formatMessage({
          id: 'EditListingEmailForm.digitalKeyPlaceholder',
        });
        const accessProviderRequiredMessage = intl.formatMessage({
          id: 'EditListingEmailForm.accessProviderRequired',
        });
        const checkInTitle = intl.formatMessage({
          id: 'EditListingEmailForm.checkInTitle',
        });
        const checkInSubtitle = intl.formatMessage({
          id: 'EditListingEmailForm.checkInSubtitle',
        });
        const accessHeader = intl.formatMessage({
          id: 'EditListingEmailForm.accessHeader',
        });
        const accessWorkspaceHeader = intl.formatMessage({
          id: 'EditListingEmailForm.accessWorkspaceHeader',
        });
        const accessOptionIntercom = intl.formatMessage({
          id: 'EditListingEmailForm.accessOptionIntercom',
        });
        const accessOptionDigitalKey = intl.formatMessage({
          id: 'EditListingEmailForm.accessOptionDigitalKey',
        });
        const accessOptionReception = intl.formatMessage({
          id: 'EditListingEmailForm.accessOptionReception',
        });

        const accessWorkspaceOptionReception = intl.formatMessage({
          id: 'EditListingEmailForm.accessWorkspaceOptionReception',
        });
        const accessWorkspaceOptionElevator = intl.formatMessage({
          id: 'EditListingEmailForm.accessWorkspaceOptionElevator',
        });

        const accessTokenMessage = intl.formatMessage({
          id: 'EditListingEmailForm.accessToken',
        });
        const accessTokenPlaceholderMessage = intl.formatMessage({
          id: 'EditListingEmailForm.accessTokenPlaceholder',
        });
        const accessTokenRequiredMessage = intl.formatMessage({
          id: 'EditListingEmailForm.accessTokenRequired',
        });
        const accessIdMessage =
          accessProvider === 'parakey'
            ? intl.formatMessage({
                id: 'EditListingEmailForm.accessIdParakey',
              })
            : intl.formatMessage({
                id: 'EditListingEmailForm.accessIdAccessy',
              });
        const accessIdPlaceholderMessage =
          accessProvider === 'parakey'
            ? intl.formatMessage({
                id: 'EditListingEmailForm.accessIdPlaceholderParakey',
              })
            : intl.formatMessage({
                id: 'EditListingEmailForm.accessIdPlaceholderAccessy',
              });
        const accessIdRequiredMessage =
          accessProvider === 'parakey'
            ? intl.formatMessage({
                id: 'EditListingEmailForm.accessIdRequiredParakey',
              })
            : intl.formatMessage({
                id: 'EditListingEmailForm.accessIdRequiredAccessy',
              });
        const clickToEditMessage = intl.formatMessage({
          id: 'EditListingEmailForm.clickToEdit',
        });
        const wifiLabel = intl.formatMessage({
          id: 'EditListingEmailForm.wifiLabel',
        });
        const wifiSubLabel = intl.formatMessage({
          id: 'EditListingEmailForm.wifiSubLabel',
        });
        const wifiNameMessage = intl.formatMessage({
          id: 'EditListingEmailForm.wifiNameMessage',
        });
        const wifiNamePlaceholder = intl.formatMessage({
          id: 'EditListingEmailForm.wifiNamePlaceholder',
        });
        const wifiPassword = intl.formatMessage({
          id: 'EditListingEmailForm.wifiPassword',
        });
        const wifiPasswordPlaceholder = intl.formatMessage({
          id: 'EditListingEmailForm.wifiPasswordPlaceholder',
        });

        const rightBtnText = isPublished
          ? intl.formatMessage({ id: 'EditListing.saveButton' })
          : intl.formatMessage({ id: 'EditListing.submitButton' });

        const { updateListingError, showListingsError } = fetchErrors || {};
        const errorMessage = updateListingError ? (
          <p className={css.error}>
            <FormattedMessage id="EditListingEmailForm.updateFailed" />
          </p>
        ) : null;
        const errorMessageShowListing = showListingsError ? (
          <p className={css.error}>
            <FormattedMessage id="EditListingEmailForm.showListingFailed" />
          </p>
        ) : null;

        const classes = classNames(css.root, className);
        const submitInProgress = updateInProgress;
        const submitDisabled = invalid || disabled || submitInProgress;

        const accessOption = get(values, 'accessOption', null);
        const accessWorkspaceOption = get(values, 'accessWorkspaceOption', null);
        const isDigitalKey = accessOption && accessOption === 'digitalKey';
        const hasAccessToken = !!initialValues?.accessTokenLastFour;
        const hasAccessId = !!initialValues?.accessIdLastFour;

        const onSaveAndExitHandler = () => {
          const {
            bookingEmailAddress1 = '',
            bookingEmailAddress2 = '',
            emailConfirmationContent = '',
            accessOption = '',
            accessWorkspaceOption = '',
            accessWorkspaceFloor = '',
            accessProvider = '',
            accessTokenLastFour = '',
            accessIdLastFour = '',
            accessToken,
            accessId,
            wifiName = '',
            wifiPassword = '',
          } = values;

          const bookingEmailAddress =
            bookingEmailAddress1 && bookingEmailAddress2
              ? [bookingEmailAddress1, bookingEmailAddress2]
              : bookingEmailAddress1
              ? [bookingEmailAddress1]
              : bookingEmailAddress2
              ? [bookingEmailAddress2]
              : [];

          const publicData = pickBy({
            bookingEmailAddress,
            emailConfirmationContent,
            accessOption,
            accessDetails: accessWorkspaceOption,
            accessWorkspaceFloor: accessWorkspaceOption === 'elevator' ? accessWorkspaceFloor : '',
            wifiName,
            wifiPassword,
            digitalKey: isDigitalKey
              ? {
                  digitalKeyAccess: isDigitalKey,
                  accessProvider,
                  accessTokenLastFour,
                  accessIdLastFour,
                }
              : {},
          });

          onHandleSaveAndExit({
            publicData,
            ...(isDigitalKey && {
              privateData: {
                ...(accessToken && { accessToken }),
                ...(accessId && { accessId }),
              },
            }),
          });
        };

        return (
          <Form className={classes} onSubmit={handleSubmit}>
            <SaveAndExitButton onClick={onSaveAndExitHandler} />
            {errorMessage}
            {errorMessageShowListing}
            {!isFixedOffice && (
              <section className={css.accessWrapper}>
                <h3 className={css.sectionTitle}>{checkInTitle}</h3>
                <h3 className={css.checkInSubtitle}>{checkInSubtitle} </h3>
                <div className={css.sectionHeader}>{accessHeader}</div>
                <div>
                  <FieldRadioButton
                    key={`intercom-access-radio`}
                    id={`accessOption-intercom`}
                    name="accessOption"
                    value={'intercom'}
                    label={accessOptionIntercom}
                    labelClassName={css.labelWrapper}
                    textClassName={css.labelText}
                    svgClassName={css.radioIcon}
                  />
                  <FieldRadioButton
                    key={`digitalKey-access-radio`}
                    id={`accessOption-digitalKey`}
                    name="accessOption"
                    value="digitalKey"
                    label={accessOptionDigitalKey}
                    labelClassName={css.labelWrapper}
                    textClassName={css.labelText}
                    svgClassName={css.radioIcon}
                  />
                  {isDigitalKey && (
                    <div>
                      <FieldSelect
                        id="accessProvider"
                        name="accessProvider"
                        selectClassName={css.fieldSelect}
                        className={css.selectWrapper}
                        labelClassName={css.fieldTitle}
                        inline
                        validate={validators.required({ accessProviderRequiredMessage })}
                      >
                        <option disabled value="">
                          {digitalKeyPlaceholderMessage}
                        </option>
                        <option value="parakey">Parakey</option>
                        <option value="accessy">Accessy</option>
                      </FieldSelect>
                      {accessProvider && accessProvider === 'parakey' && (
                        <label className={css.savedDetailsWrapper}>
                          {accessTokenMessage}
                          {showTokenUpdateInput || !hasAccessToken ? (
                            <FieldTextInput
                              className={css.access}
                              type="text"
                              name="accessToken"
                              id="accessToken"
                              placeholder={accessTokenPlaceholderMessage}
                              validate={validators.required(accessTokenRequiredMessage)}
                            />
                          ) : (
                            <InlineTextButton
                              className={css.savedAccessDetails}
                              onClick={() => setShowTokenUpdateInput(true)}
                            >
                              •••••••••••••••••••••••• {initialValues.accessTokenLastFour} (
                              {clickToEditMessage})
                            </InlineTextButton>
                          )}
                        </label>
                      )}
                      {accessProvider && (
                        <label className={css.savedDetailsWrapper}>
                          {accessIdMessage}
                          {showIdUpdateInput || !hasAccessId ? (
                            <FieldTextInput
                              className={css.access}
                              type="text"
                              name="accessId"
                              id="accessId"
                              placeholder={accessIdPlaceholderMessage}
                              validate={validators.required(accessIdRequiredMessage)}
                            />
                          ) : (
                            <InlineTextButton
                              className={css.savedAccessDetails}
                              onClick={() => setShowIdUpdateInput(true)}
                            >
                              ••••••••••• {initialValues.accessIdLastFour} ({clickToEditMessage})
                            </InlineTextButton>
                          )}
                        </label>
                      )}
                    </div>
                  )}
                  <FieldRadioButton
                    key={`reception-access-radio`}
                    id={`accessOption-reception`}
                    name="accessOption"
                    value="reception"
                    label={accessOptionReception}
                    labelClassName={css.labelWrapper}
                    textClassName={css.labelText}
                    svgClassName={css.radioIcon}
                  />
                </div>
                <div className={css.sectionHeader}>{accessWorkspaceHeader}</div>
                <div>
                  <FieldRadioButton
                    key={`reception-access-workspace-radio`}
                    id={`accessWorkspaceOption-reception`}
                    name="accessWorkspaceOption"
                    value="reception"
                    label={accessWorkspaceOptionReception}
                    labelClassName={css.labelWrapper}
                    textClassName={css.labelText}
                    svgClassName={css.radioIcon}
                  />
                  <div className={css.elevatorOption}>
                    <FieldRadioButton
                      key={`reception-access-workspace-radio`}
                      id={`accessWorkspaceOption-elevator`}
                      name="accessWorkspaceOption"
                      value="elevator"
                      label={accessWorkspaceOptionElevator}
                      labelClassName={css.labelWrapper}
                      textClassName={css.labelText}
                      svgClassName={css.radioIcon}
                    />
                    <FieldTextInput
                      id="accessWorkspaceOption-floor"
                      name="accessWorkspaceFloor"
                      type="text"
                      className={css.elevatorFloor}
                      disabled={accessWorkspaceOption !== 'elevator'}
                      placeholder="0"
                    />
                  </div>
                </div>
              </section>
            )}
            <section className={css.email}>
              <h3 className={css.sectionTitle}>
                <FormattedMessage id="EditListingEmailForm.emailTitle" />
              </h3>
              {!isFixedOffice && (
                <FieldTextInput
                  id="emailConfirmationContent"
                  name="emailConfirmationContent"
                  className={css.policy}
                  type="textarea"
                  label={emailContentSubTitle}
                  labelClassName={css.labelClassName}
                  subLabel={emailContentLabelMessage}
                  subLabelClassname={css.subLabelClassname}
                  placeholder={emailContentPlaceholderMessage}
                />
              )}
              <FieldTextInput
                type="email"
                id="bookingEmailAddress1"
                name="bookingEmailAddress1"
                className={css.email}
                label={bookingEmailAddressLabelMessage}
                labelClassName={css.labelClassName}
                subLabel={isFixedOffice ? bookingEmailAddressSubLabelMessage : null}
                subLabelClassname={css.subLabelClassname}
                placeholder={bookingEmailAddressPlaceholderMessage}
                autoComplete="email"
                validate={validators.composeValidators(
                  validators.required(emailRequiredMessage),
                  validators.emailFormatValid(emailRequiredMessage)
                )}
              />
              <FieldTextInput
                type="email"
                id="bookingEmailAddress2"
                name="bookingEmailAddress2"
                className={css.email}
                label={bookingEmailAddressCopyLabelMessage}
                labelClassName={css.labelClassName}
                placeholder={bookingEmailAddressPlaceholderMessage}
                autoComplete="email"
              />
              {!isFixedOffice && (
                <>
                  <label className={css.labelClassName}>{wifiLabel}</label>
                  <div className={css.subLabelClassname}>{wifiSubLabel}</div>
                  <FieldTextInput
                    id="wifiName"
                    name="wifiName"
                    className={css.wifi}
                    type="text"
                    label={wifiNameMessage}
                    labelClassName={css.labelClassName}
                    placeholder={wifiNamePlaceholder}
                  />
                  <FieldTextInput
                    id="wifiPassword"
                    name="wifiPassword"
                    className={css.wifi}
                    type="text"
                    label={wifiPassword}
                    labelClassName={css.labelClassName}
                    placeholder={wifiPasswordPlaceholder}
                  />
                </>
              )}
              <FixedBottomButtons
                leftBtnText={intl.formatMessage({ id: 'EditListing.backButton' })}
                rightBtnText={rightBtnText}
                rightBtnType="submit"
                rightBtnInProgress={inProgress}
                rightBtnReady={ready}
                rightBtnDisabled={submitDisabled}
              />
            </section>
          </Form>
        );
      }}
    />
  );
};

EditListingEmailFormComponent.defaultProps = {
  selectedPlace: null,
  updateError: null,
};

EditListingEmailFormComponent.propTypes = {
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  selectedPlace: propTypes.place,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
};

export default compose(injectIntl)(EditListingEmailFormComponent);
