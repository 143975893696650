import classNames from 'classnames';
import { arrayOf, bool, object, shape, string } from 'prop-types';
import React, { memo, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';

import { ImageSlider, NamedLink } from '../../components';
import CategoryModel from '../../models/CategoryModel';
import { convertPriceToCredit, priceData } from '../../util/currency';
import { types as sdkTypes } from '../../util/sdkLoader';
import { DAILY_BOOKING, HOURLY_BOOKING, MONTHLY_BOOKING } from '../../util/types';
import { createSlug } from '../../util/urlHelpers';

import { BADGE_COLORS } from '../Badge/Badge';

import { Badge, FavoriteListingToggle } from '../index';
import css from './LocationCard.module.scss';

const { Money } = sdkTypes;

const BOOKING_TYPES = {
  DAILY_BOOKING,
  HOURLY_BOOKING,
  MONTHLY_BOOKING,
};

BOOKING_TYPES.LIST = [DAILY_BOOKING, HOURLY_BOOKING, MONTHLY_BOOKING];

const getFormattedPriceAndCredits = ({ price, vat, intl }) => {
  const money = !!price && new Money(price, 'SEK');

  const { formattedPrice } = money ? priceData(money, vat, intl) : {};
  const credits = money ? convertPriceToCredit(money) : 0;

  return { formattedPrice, credits };
};

const ListingItem = memo(
  ({
    category = 'fixed',
    booking_type_translation,
    min_price = 0,
    max_price = 0,
    vat = 0,
    min_seats = 0,
    max_seats = 0,
    showCredits = false,
    request_price = false,
  }) => {
    const intl = useIntl();
    const seatsFormatted = useMemo(() => {
      return min_seats !== max_seats
        ? `${min_seats} - ${intl.formatMessage({ id: 'LocationCard.seats' }, { seats: max_seats })}`
        : intl.formatMessage({ id: 'LocationCard.seats' }, { seats: min_seats });
    }, [intl, max_seats, min_seats]);

    const { formattedPrice: minPriceFormatted, credits: minPriceCredits } =
      getFormattedPriceAndCredits({ price: min_price, vat: vat, intl });
    const { formattedPrice: maxPriceFormatted, credits: maxPriceCredits } =
      getFormattedPriceAndCredits({ price: max_price, vat: vat, intl });

    const isFixedOffice = category === CategoryModel.FIXED.id;

    const finalPrice = useMemo(() => {
      if ((request_price && isFixedOffice) || !max_price) {
        return intl.formatMessage({ id: 'LocationCard.requestPrice' });
      } else if (showCredits && !isFixedOffice) {
        if (minPriceCredits === maxPriceCredits) {
          return `${intl.formatMessage(
            { id: 'ListingCardNew.credits' },
            { credits: minPriceCredits }
          )}${intl.formatMessage({
            id: booking_type_translation,
          })}`;
        } else {
          return `${intl.formatMessage(
            { id: 'ListingCardNew.credits' },
            { credits: minPriceCredits }
          )}${intl.formatMessage({
            id: booking_type_translation,
          })}`;
        }
      } else {
        if (min_price === max_price) {
          return `${minPriceFormatted}${intl.formatMessage({
            id: booking_type_translation,
          })}`;
        } else {
          return `${intl.formatMessage({
            id: 'LocationCard.from',
          })} ${minPriceFormatted} ${intl.formatMessage({
            id: booking_type_translation,
          })}`;
          /* DO NOT REMOVE YET
          finalPrice = intl.formatMessage({ id: 'LocationCard.from' });
          if (locale === 'sv') {
            finalPrice += ` ${withoutCurrency(
              minPriceFormatted
            )} - ${maxPriceFormatted}${intl.formatMessage({
              id: availability,
            })}`;
          } else {
            finalPrice += ` ${minPriceFormatted} - ${withoutCurrency(
              maxPriceFormatted
            )}${intl.formatMessage({ id: availability })}`;
          }*/
        }
      }
    }, [
      intl,
      isFixedOffice,
      maxPriceCredits,
      max_price,
      minPriceCredits,
      minPriceFormatted,
      min_price,
      request_price,
      showCredits,
      vat,
    ]);

    return (
      <li className={css.listing}>
        <p>
          <span>
            {intl.formatMessage({
              id: CategoryModel.ID_TO_DATA[category].labelId,
              defaultMessage: category,
            })}
          </span>
          <span className={css.listingSeats}>{seatsFormatted}</span>
        </p>
        <p>
          <span className={css.price}>{finalPrice}</span>
        </p>
      </li>
    );
  }
);

const LocationCardComponent = ({
  intl,
  title,
  address,
  listingsArray,
  /* rating, */
  isFavorite,
  isFavoriteCard,
  isCompanyFavorite,
  id,
  images,
  onMouseEnter,
  onMouseLeave,
  className,
  isShowCredits,
  category,
  showCompanyFavoriteButton,
  isActiveOnBottomSheet,
  onClick,
  isMobile,
  search,
  badgeColor,
  badgeString,
  badgeUppercase,
}) => {
  const [viewMoreLocations, setViewMoreLocations] = useState(false);

  const isInShelf = isFavoriteCard && !isActiveOnBottomSheet;

  return (
    <div className={css.listingCardContainer}>
      {' '}
      {/* extra div here for positioning the favorites button outside of the parent listingCard element */}
      <div
        className={classNames(
          className,
          css.listingCard,
          isMobile && css.mobile,
          isInShelf && css.shelfCard,
          isActiveOnBottomSheet && css.bottomSheet
        )}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        onClick={onClick}
      >
        <NamedLink
          className={css.listingLink}
          name="LocationPage"
          params={{
            id,
            slug: createSlug(title),
          }}
          to={{ search: search }}
          target="_blank"
          rel="noopener noreferrer"
        >
          <div className={classNames(css.imgWrapper, isInShelf && css.shelfImage)}>
            {(images && images.length === 1) || isInShelf ? (
              <img
                className={css.img}
                src={images && images.length > 0 ? images[0].medium : null}
                alt={title}
              />
            ) : (
              <ImageSlider className={css.img} category="location_images" data={{ images }} />
            )}
            {badgeString && (
              <div className={css.badgeWrapper}>
                <Badge text={badgeString} color={badgeColor} uppercase={badgeUppercase} />
              </div>
            )}
            <div className={css.favoriteButtonWrapper}>
              <FavoriteListingToggle
                listingId={id}
                isFavorite={isFavorite}
                isCompanyFavorite={isCompanyFavorite}
                showCompanyFavoriteButton={showCompanyFavoriteButton}
              />
            </div>
          </div>
          <div
            className={classNames(
              css.content,
              isMobile && css.mobile,
              isInShelf && css.shelfContent
            )}
          >
            <div className={css.top}>
              <h3 className={css.title}>{title}</h3>
              <span className={css.location}>
                {!isInShelf || !category
                  ? address
                  : `${intl.formatMessage({ id: category.labelId })}, ${address.split(',')[0]}`}
              </span>
            </div>
            {!isInShelf && (
              <ul
                className={classNames(
                  css.bottom,
                  viewMoreLocations && css.expanded,
                  listingsArray.length === 3 && css.threeListings
                )}
              >
                {listingsArray.map((l, index) => (
                  <ListingItem
                    {...l}
                    showCredits={isShowCredits}
                    key={`listing_category_${l.category}_location_${id}_${index}`}
                  />
                ))}
              </ul>
            )}
            {listingsArray?.length > 3 && (
              <div className={css.listingsPlusWrapper}>
                <button
                  className={css.listingsPlus}
                  onClick={(e) => {
                    e.preventDefault();
                    setViewMoreLocations(!viewMoreLocations);
                  }}
                >
                  {!viewMoreLocations
                    ? intl.formatMessage(
                        { id: 'LocationCard.showMore' },
                        { num: listingsArray.length - 2 }
                      )
                    : intl.formatMessage({ id: 'LocationCard.hide' })}
                </button>
              </div>
            )}
          </div>
        </NamedLink>
      </div>
    </div>
  );
};

LocationCardComponent.defaultProps = {
  images: null,
  id: null,
  isFavorite: false,
  /* rating: null,*/
  unitTranslationKey: BOOKING_TYPES.DAILY_BOOKING,
  badgeColor: BADGE_COLORS.WHITE,
  badgeString: false,
};

LocationCardComponent.propTypes = {
  title: string.isRequired,
  images: arrayOf(
    shape({
      attributes: object,
    })
  ).isRequired,
  id: string,
  features: string,
  isFavorite: bool,
  unitTranslationKey: string,
  /* rating: number, */
  badgeColor: string,
  badgeString: string,
  badgeUppercase: bool,
};

export default LocationCardComponent;
