import React from 'react';
import classNames from 'classnames';
import { array, string, func } from 'prop-types';

import css from './ContentToggle.module.scss';

const ContentToggle = ({ tabs, currentTab, onChange, name }) => (
  <div className={css.main}>
    {tabs.map((tab) => (
      <label
        key={`${name}_option_${tab.id}`}
        className={classNames(css.button, tab.id === currentTab && css.active)}
      >
        {tab.label}
        <input
          className={css.hiddenInput}
          name={name}
          value={tab.id}
          id={`${name}_option_${tab.id}`}
          type="radio"
          onChange={() => onChange(tab.id)}
        />
      </label>
    ))}
  </div>
);

ContentToggle.propTypes = {
  tabs: array.isRequired,
  currentTab: string.isRequired,
  onChange: func.isRequired,
  name: string.isRequired,
};

export default ContentToggle;
