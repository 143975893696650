import moment from 'moment';
import { bool, func, number, string } from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { PopupModal } from '..';
import { cancelSubscription } from '../../containers/SubscriptionManagementPage/SubscriptionManagementPage.duck';
import CancelSubscriptionReasonForm from '../../forms/CancelSubscriptionReasonForm/CancelSubscriptionReasonForm';
import css from './CancelSubscriptionReasonModal.module.scss';

const CancelSubscriptionReasonModal = (props) => {
  const {
    nextBillingDate,
    isOpen,
    onClose,
    openPauseModal,
    onManageDisableScrolling,
    earliestAllowableCancelDate,
  } = props;
  const dispatch = useDispatch();
  const cancelInProgress = useSelector(
    (state) => state.SubscriptionManagementPage.cancelSubscriptionInProgress
  );
  const cancelError = useSelector(
    (state) => state.SubscriptionManagementPage.cancelSubscriptionError
  );

  const endOfBindingPeriod = moment(earliestAllowableCancelDate).format('DD-MM-YYYY');
  const cancelDateBoundary = new Date();
  cancelDateBoundary.setMonth(cancelDateBoundary.getMonth() + 1);
  const isInCancellationWindow = earliestAllowableCancelDate <= cancelDateBoundary.getTime();

  const onSubmit = (values) => {
    const { cancelReasonSelect, cancelReason, cancelReasonOther } = values;
    dispatch(
      cancelSubscription({
        cancelReasonCode: cancelReasonSelect || cancelReason,
        cancelReasonCodeOtherMessage: cancelReasonOther,
        ...(!isInCancellationWindow &&
          earliestAllowableCancelDate && { earliestAllowableCancelDate }),
      })
    );
    onClose();
  };
  const handlePauseOpenClick = () => {
    onClose();
    openPauseModal();
  };

  return (
    <PopupModal
      id="cancelSubscriptionModal"
      isOpen={isOpen}
      onClose={onClose}
      modalContainer={css.modalContainer}
      onManageDisableScrolling={onManageDisableScrolling}
      hideCloseButton
    >
      <CancelSubscriptionReasonForm
        onSubmit={onSubmit}
        handlePauseOpenClick={handlePauseOpenClick}
        nextBillingDate={nextBillingDate}
        cancelError={cancelError}
        cancelInProgress={cancelInProgress}
        onClose={onClose}
        endOfBindingPeriod={endOfBindingPeriod}
        isInCancellationWindow={isInCancellationWindow}
      />
    </PopupModal>
  );
};

CancelSubscriptionReasonModal.defaultProps = {
  nextBillingDate: null,
  isOpen: false,
  onClose: () => {},
  openPauseModal: () => {},
  onManageDisableScrolling: () => {},
};

CancelSubscriptionReasonModal.propTypes = {
  nextBillingDate: string.isRequired,
  isOpen: bool.isRequired,
  onClose: func.isRequired,
  openPauseModal: func.isRequired,
  onManageDisableScrolling: func,
  earliestAllowableCancelDate: number,
};
export default CancelSubscriptionReasonModal;
