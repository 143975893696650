import React, { useCallback, useState, useEffect } from 'react';
import pickBy from 'lodash/pickBy';
import classNames from 'classnames';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { array, bool, func, number, shape, string } from 'prop-types';

import config from '../../config';
import routeConfiguration from '../../routeConfiguration';
import { withViewport } from '../../util/contextHelpers';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { propTypes } from '../../util/types';
import { parse, stringify } from '../../util/urlHelpers';
import { setTotalLocations } from '../../ducks/b2b.duck';
import { pathByRouteName } from '../../util/routes';
import { isCompanyUser, isEmployeeUser } from '../../util/b2bHelper';

import {
  AuthenticationModal,
  Button,
  LimitedAccessBanner,
  Logo,
  ModalMissingInformation,
  NamedLink,
  TopbarDesktop,
  TopbarMobileMenu,
} from '../index';

import MenuIcon from './MenuIcon';
import ModalMobileMenu from './ModalMobileMenu';

import css from './Topbar.module.scss';

const redirectToURLWithModalState = (history, location, modalStateParam) => {
  const { pathname, search, state } = location;
  const searchString = `?${stringify({ [modalStateParam]: 'open', ...parse(search) })}`;
  history.push(`${pathname}${searchString}`, state);
};

const redirectToURLWithoutModalState = (history, location, modalStateParam) => {
  const { pathname, search, state } = location;
  const queryParams = pickBy(parse(search), (v, k) => {
    return k !== modalStateParam;
  });
  const stringified = stringify(queryParams);
  const searchString = stringified ? `?${stringified}` : '';
  history.push(`${pathname}${searchString}`, state);
};

const GenericError = (props) => {
  const { show } = props;
  const classes = classNames(css.genericError, {
    [css.genericErrorVisible]: show,
  });
  return (
    <div className={classes}>
      <div className={css.genericErrorContent}>
        <p className={css.genericErrorText}>
          <FormattedMessage id="Topbar.genericError" />
        </p>
      </div>
    </div>
  );
};

GenericError.propTypes = {
  show: bool.isRequired,
};

const MAX_MOBILE_SCREEN_WIDTH = 768;

const TopbarComponent = ({
  className,
  rootClassName,
  desktopClassName,
  mobileRootClassName,
  mobileClassName,
  isAuthenticated,
  authScopes,
  authInProgress,
  currentUser,
  currentUserHasListings,
  currentUserHasOrders,
  currentPage,
  notificationCount,
  viewport,
  intl,
  location,
  onManageDisableScrolling,
  onResendVerificationEmail,
  sendVerificationEmailInProgress,
  sendVerificationEmailError,
  showGenericError,
  subscriptionsAmount,
  showBookADemoButton,
  isScrolled,
  onLogout,
  history,
  currentUserId,
  setTotalLocations,
  totalLocations,
}) => {
  const [showAuthModal, setShowAuthModal] = useState(false);

  const handleMobileMenuOpen = () => redirectToURLWithModalState(history, location, 'mobilemenu');

  const handleMobileMenuClose = () =>
    redirectToURLWithoutModalState(history, location, 'mobilemenu');

  const handleLogout = () => {
    onLogout().then(() => {
      const path = pathByRouteName('LandingPage', routeConfiguration());

      // In production we ensure that data is really lost,
      // but in development mode we use stored values for debugging
      if (config.dev) {
        history.push(path);
      } else if (typeof window !== 'undefined') {
        window.location = path;
      }

      console.log('logged out'); // eslint-disable-line
    });
  };

  const isB2BEmployee = isEmployeeUser(currentUser);
  const isB2BAccount = isCompanyUser(currentUser);

  const { mobilemenu, mobilesearch, address, origin, bounds } = parse(location.search, {
    latlng: ['origin'],
    latlngBounds: ['bounds'],
  });

  const notificationDot = notificationCount > 0 ? <div className={css.notificationDot} /> : null;

  const isMobileLayout = viewport.width < MAX_MOBILE_SCREEN_WIDTH;
  const isMobileMenuOpen = isMobileLayout && mobilemenu === 'open';
  const isMobileSearchOpen = isMobileLayout && mobilesearch === 'open';
  const isLandingpageTop = !isScrolled && currentPage === 'LandingPage';

  const requestLocations = useCallback(async (userId) => {
    const queryString = `
      query MyQuery($_owner_id: uuid) {
        locations(where: { owner_id: { _eq: $_owner_id } }) {
          id
        }
      }
    `;

    const response = await fetch(process.env.REACT_APP_HAZURA_DB_URI, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-Hasura-Admin-Secret': process.env.REACT_APP_HAZURA_SECRET,
      },
      body: JSON.stringify({
        query: queryString,
        variables: {
          _owner_id: userId,
        },
      }),
    });

    const result = await response.json();

    if ('data' in result && 'locations' in result?.data) {
      setTotalLocations(result?.data?.locations?.length);
    } else {
      console.error(result?.errors[0]);
    }
  }, []);

  useEffect(() => {
    !!currentUserId && requestLocations(currentUserId);
  }, [currentUserId]);

  const mobileMenu = (
    <TopbarMobileMenu
      isAuthenticated={isAuthenticated}
      onLogout={handleLogout}
      currentUserHasListings={currentUserHasListings}
      currentUserHasLocations={totalLocations > 0}
      notificationCount={notificationCount}
      currentPage={currentPage}
      subscriptionsAmount={subscriptionsAmount}
      isB2BEmployee={isB2BEmployee}
      isB2BAccount={isB2BAccount}
      showBookADemoButton={showBookADemoButton}
    />
  );

  // Only render current search if full place object is available in the URL params
  const locationFieldsPresent = config.sortSearchByDistance
    ? address && origin && bounds
    : address && bounds;
  const initialSearchFormValues = {
    location: locationFieldsPresent
      ? {
          search: address,
          selectedPlace: { address, origin, bounds },
        }
      : null,
  };

  const classes = classNames(rootClassName || css.root, className);

  return (
    <div className={classes}>
      <LimitedAccessBanner
        isAuthenticated={isAuthenticated}
        authScopes={authScopes}
        currentUser={currentUser}
        onLogout={handleLogout}
        currentPage={currentPage}
      />
      <div
        className={classNames(mobileRootClassName || css.container, mobileClassName, {
          [css.landingPage]: isLandingpageTop,
        })}
      >
        <NamedLink
          className={css.home}
          name={isAuthenticated ? 'LandingPageLoggedIn' : 'LandingPage'}
          title={intl.formatMessage({ id: 'Topbar.logoIcon' })}
        >
          <Logo format="mobile" color={isLandingpageTop ? 'white' : 'black'} />
        </NamedLink>
        <div className={css.buttonMenuWrapper}>
          {!isAuthenticated && (
            <div
              className={css.loginText}
              id="LoginButtonIdentifier"
              onClick={() => setShowAuthModal(true)}
            >
              <FormattedMessage id="Topbar.mobileLogin" />
            </div>
          )}
          <Button
            rootClassName={css.menu}
            onClick={handleMobileMenuOpen}
            title={intl.formatMessage({ id: 'Topbar.menuIcon' })}
          >
            <MenuIcon className={css.menuIcon} color={isLandingpageTop ? 'white' : 'black'} />
            {notificationDot}
          </Button>
        </div>
      </div>
      <div className={css.desktop}>
        <TopbarDesktop
          className={desktopClassName}
          currentUserHasListings={currentUserHasListings}
          currentUserHasLocations={totalLocations > 0}
          currentUser={currentUser}
          currentPage={currentPage}
          initialSearchFormValues={initialSearchFormValues}
          intl={intl}
          isAuthenticated={isAuthenticated}
          notificationCount={notificationCount}
          onLogout={handleLogout}
          subscriptionsAmount={subscriptionsAmount}
          isB2BEmployee={isB2BEmployee}
          isB2BAccount={isB2BAccount}
          showBookADemoButton={showBookADemoButton}
          isScrolled={isScrolled}
          history={history}
        />
      </div>
      <ModalMobileMenu isOpen={isMobileMenuOpen} onClose={handleMobileMenuClose}>
        {authInProgress ? null : mobileMenu}
      </ModalMobileMenu>
      <ModalMissingInformation
        id="MissingInformationReminder"
        containerClassName={css.missingInformationModal}
        currentUser={currentUser}
        currentUserHasListings={currentUserHasListings}
        currentUserHasOrders={currentUserHasOrders}
        location={location}
        onManageDisableScrolling={onManageDisableScrolling}
        onResendVerificationEmail={onResendVerificationEmail}
        sendVerificationEmailInProgress={sendVerificationEmailInProgress}
        sendVerificationEmailError={sendVerificationEmailError}
      />

      <GenericError show={showGenericError} />
      <AuthenticationModal
        isOpen={showAuthModal}
        onClose={() => setShowAuthModal(false)}
        history={history}
      />
    </div>
  );
};

const mapStateToProps = (state) => {
  const currentUserId = state.user?.currentUser?.id?.uuid;
  const { totalLocations } = state.b2b;

  return {
    currentUserId,
    totalLocations,
  };
};

const mapDispatchToProps = (dispatch) => ({
  setTotalLocations: (payload) => dispatch(setTotalLocations(payload)),
});

TopbarComponent.defaultProps = {
  className: null,
  rootClassName: null,
  desktopClassName: null,
  mobileRootClassName: null,
  mobileClassName: null,
  notificationCount: 0,
  currentUser: null,
  currentUserHasOrders: null,
  currentPage: null,
  sendVerificationEmailError: null,
  authScopes: [],
  subscriptionsAmount: 0,
  showBookADemoButton: false,
  isScrolled: false,
};

TopbarComponent.propTypes = {
  className: string,
  rootClassName: string,
  desktopClassName: string,
  mobileRootClassName: string,
  mobileClassName: string,
  isAuthenticated: bool.isRequired,
  authScopes: array,
  authInProgress: bool.isRequired,
  currentUser: propTypes.currentUser,
  currentUserHasListings: bool.isRequired,
  currentUserHasOrders: bool,
  currentPage: string,
  notificationCount: number,
  onLogout: func.isRequired,
  onManageDisableScrolling: func.isRequired,
  onResendVerificationEmail: func.isRequired,
  sendVerificationEmailInProgress: bool.isRequired,
  sendVerificationEmailError: propTypes.error,
  showGenericError: bool.isRequired,
  subscriptionsAmount: number.isRequired,
  showBookADemoButton: bool,
  isScrolled: bool,

  // These are passed from Page to keep Topbar rendering aware of location changes
  history: shape({
    push: func.isRequired,
  }).isRequired,
  location: shape({
    search: string.isRequired,
  }).isRequired,

  // from withViewport
  viewport: shape({
    width: number.isRequired,
    height: number.isRequired,
  }).isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

export default compose(
  withViewport,
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl
)(TopbarComponent);
