import { oneOf, string, func } from 'prop-types';
import React from 'react';
import { useIntl } from 'react-intl';
import { Button } from '../../../components';
import { SUBSCRIPTION_STATUS_CANCELLED, SUBSCRIPTION_STATUS_PAUSED } from '../../../util/types';
import css from './ReactivateBox.module.scss';

const ReactivateBox = (props) => {
  const { subscriptionStatus, date, onReactivate } = props;

  const intl = useIntl();
  return (
    <div className={css.boxContainer}>
      {subscriptionStatus === SUBSCRIPTION_STATUS_PAUSED ? (
        <p className={css.desc}>
          {intl.formatMessage({ id: 'ReactivateBox.pauseDesc' }, { date: date })}
        </p>
      ) : (
        <p className={css.desc}>
          {intl.formatMessage({ id: 'ReactivateBox.cancelDesc' }, { date: date })}
        </p>
      )}
      <Button className={css.btn} type="button" onClick={onReactivate}>
        {intl.formatMessage({ id: 'ReactivateBox.reactivate' })}
      </Button>
    </div>
  );
};

ReactivateBox.defaultProps = {};

ReactivateBox.propTypes = {
  subscriptionStatus: oneOf([SUBSCRIPTION_STATUS_PAUSED, SUBSCRIPTION_STATUS_CANCELLED]).isRequired,
  date: string.isRequired,
  onReactivate: func.isRequired,
};
export default ReactivateBox;
