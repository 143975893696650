import React, { useEffect, useState } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { useHistory } from 'react-router-dom';
import { compose } from 'redux';
import { StaticPage, TopbarContainer } from '..';
import mobileIllustration from '../../assets/quote-page-mobile.svg';
import topIllustration from '../../assets/quote-page.svg';
import {
  EditQuoteInformationModal,
  Footer,
  LayoutSingleColumn,
  LayoutWrapperFooter,
  LayoutWrapperMain,
  LayoutWrapperTopbar,
} from '../../components';
import DropDownList from '../../components_new/DropdownList/DropDownList';
import {
  toggleProceedLoading,
  updateCalculatedPrices,
  updateCouponCode,
  updateRetrievedCoupon,
  updateStartDate,
  updateTotalCredits,
} from '../../ducks/b2b.duck';
import { SubscriptionStartDateForm } from '../../forms/';
import CouponModel from '../../models/CouponModel';
import PackageModel from '../../models/PackageModel';
import routeConfiguration from '../../routeConfiguration';
import { retrieveCoupon } from '../../util/api';
import { withViewport } from '../../util/contextHelpers';
import { useModal } from '../../util/hooks';
import { createResourceLocatorString } from '../../util/routes';
import css from './QuotePage.module.scss';

const QuotePage = ({
  intl,
  totalCredits,
  updateTotalCredits,
  processStartDate,
  updateStartDate,
  couponCode,
  updateCouponCode,
  proceedLoading,
  toggleProceedLoading,
  retrievedCoupon,
  updateRetrievedCoupon,
  calculatedPrices,
  updateCalculatedPrices,
  selectedPackage,
}) => {
  const history = useHistory();
  const {
    modalOpen: quotePageInforModalOpen,
    openModal: openQuotePageInforModal,
    closeModal: closeQuotePageInforModal,
  } = useModal();

  const [showMinCreditsError, toggleMinCreditsError] = useState(false);
  const [showCouponError, setCouponCodeError] = useState(false);
  const [showCouponSuccess, setCouponCodeSuccess] = useState(false);

  useEffect(() => {
    validateCoupon();
  }, []);

  useEffect(() => {
    const packageFound = PackageModel.currentPackage({ selectedPackage });
    recalculatePrice({
      price: packageFound.pricePerCredit * totalCredits,
      coupon: retrievedCoupon,
      pricePerCredit: packageFound.pricePerCredit,
    });
  }, [totalCredits]);

  const onSubmitSubscriptionStartDateForm = async () => {
    history.push(createResourceLocatorString('CompanyAccount', routeConfiguration()), {
      from: 'QuotePage',
    });
  };

  const validateCoupon = async () => {
    if (!!couponCode) {
      toggleProceedLoading(true);
      const couponValidationResult = await retrieveCoupon(couponCode);
      toggleProceedLoading(false);

      const { id, discount_type, period, period_unit, status } = couponValidationResult;
      /* Please note that http_status_code is not the same code that is sent in res.status(code).send(payload).
       * This is just object key with a value.  */

      if (status !== CouponModel.STATUSES.ACTIVE) {
        setCouponCodeError(true);
        setCouponCodeSuccess(false);
        updateRetrievedCoupon(false);
        return;
      }

      const payload = {
        id,
        discount_type,
        period,
        period_unit,
      };

      if (discount_type === CouponModel.TYPES.FIXED) {
        payload.discount_amount = couponValidationResult.discount_amount / 100; // (discount / 100) * 1.25 (price with vat)
      }

      if (discount_type === CouponModel.TYPES.PERCENTAGE) {
        payload.discount_percentage = couponValidationResult.discount_percentage;
      }

      setCouponCodeError(false);
      setCouponCodeSuccess(true);
      updateRetrievedCoupon(payload);
    }
  };

  /**
   * @param price {Number}
   * @param coupon {Object | null}
   * @param pricePerCredit {Number | null} */
  const recalculatePrice = ({ price, coupon, pricePerCredit }) => {
    updateCalculatedPrices(
      CouponModel.calculatePrice({
        originalPrice: price,
        ...(!!coupon && { coupon }),
      }),
      pricePerCredit
    );
  };

  useEffect(() => {
    recalculatePrice({
      price: calculatedPrices.originalPrice,
      coupon: retrievedCoupon,
    });
  }, [retrievedCoupon]);

  const FAQs = [
    {
      question: intl.formatMessage({ id: `RespacesPass.faq.Q1` }),
      answer: intl.formatMessage({ id: `RespacesPass.faq.A1` }),
    },
    {
      question: intl.formatMessage({ id: `RespacesPass.faq.Q2` }),
      answer: intl.formatMessage({ id: `RespacesPass.faq.A2` }),
    },
    {
      question: intl.formatMessage({ id: `RespacesPass.faq.Q3` }),
      answer: intl.formatMessage({ id: `RespacesPass.faq.A3` }),
    },
  ];

  return (
    <StaticPage
      title="Respaces for Companies" /* Maybe change these values */
      schema={{
        '@context': 'http://schema.org',
        '@type': 'WebPage',
        description: 'For companies wanting to use Respaces' /* Maybe change these values */,
        name: 'Respaces For Companies',
      }}
    >
      <LayoutSingleColumn>
        <LayoutWrapperTopbar>
          <TopbarContainer />
        </LayoutWrapperTopbar>

        <LayoutWrapperMain className={css.mainWrapper}>
          <div className={css.mobileTitle}>
            <p className={css.subtitle}>
              <FormattedMessage id="QuotePage.forCompanies" />
            </p>
            <h1 className={css.title}>
              <FormattedMessage id="QuotePage.quotePageTitle" />
            </h1>
          </div>

          <div className={css.responsiveLayout}>
            {/* Complimentary access */}
            <div className={css.subscriptionStartDateFormWrapper}>
              <SubscriptionStartDateForm
                onSubmit={onSubmitSubscriptionStartDateForm}
                openQuotePageInforModal={openQuotePageInforModal}
                initialValues={{
                  totalCredits,
                  processStartDate,
                  couponCode,
                  ...calculatedPrices,
                }}
                onUpdateStartDate={updateStartDate}
                onUpdateCouponCode={updateCouponCode}
                applyCoupon={validateCoupon}
                proceedLoading={proceedLoading}
                showCouponError={showCouponError}
                showCouponSuccess={showCouponSuccess}
                retrievedCoupon={retrievedCoupon}
                selectedPackage={selectedPackage}
                intl={intl}
              />
            </div>
            <div className={css.imgMobile}>
              <img src={mobileIllustration} alt="Respaces quote" />
            </div>

            <section className={css.descriptionSection}>
              <div className={css.hideMobile}>
                <p className={css.subtitle}>
                  <FormattedMessage id="QuotePage.forCompanies" />
                </p>
                <h1 className={css.title}>
                  <FormattedMessage id="QuotePage.quotePageTitle" />
                </h1>
                <div className={css.videoWrapper}>
                  <img src={topIllustration} alt="Respaces quote" />
                </div>
              </div>
              <div className={css.descriptionWrapper}>
                <div className={css.content}>
                  <h4>
                    <FormattedMessage id="QuotePage.signupTitle" />
                  </h4>
                  <p>
                    <FormattedMessage id="QuotePage.signupContent" />
                  </p>
                </div>
                <div className={css.content}>
                  <h4>
                    <FormattedMessage id="QuotePage.inviteEmployeesTitle" />
                  </h4>
                  <p>
                    <FormattedMessage id="QuotePage.inviteEmployeesContent" />
                  </p>
                </div>
                <div className={css.content}>
                  <h4>
                    <FormattedMessage id="QuotePage.trackActivityTitle" />
                  </h4>
                  <p>
                    <FormattedMessage id="QuotePage.trackActivityContent" />
                  </p>
                </div>
              </div>
              <div className={css.faqWrapper}>
                <h3 className={css.title}>
                  <FormattedMessage id="QuotePage.commonQuestions" />
                </h3>
                <DropDownList
                  menuList={FAQs.map((faq) => ({
                    header: faq.question,
                    content: faq.answer,
                  }))}
                  bold
                  border
                />
                <p className={css.contactEmail}>
                  <FormattedMessage id="QuotePage.contactEmailText" />
                  <a href="mailto:support@respaces.co">
                    <FormattedMessage id="QuotePage.contactEmail" />
                  </a>
                  .
                </p>
              </div>
            </section>
          </div>
          <EditQuoteInformationModal
            intl={intl}
            isOpen={quotePageInforModalOpen}
            onClose={closeQuotePageInforModal}
            showMinCreditsError={showMinCreditsError}
            initialValues={{
              totalCredits,
            }}
            onSubmit={({ totalCredits }) => {
              toggleMinCreditsError(false);
              if (totalCredits <= 0) {
                toggleMinCreditsError(true);
                return;
              }
              updateTotalCredits(totalCredits);
              closeQuotePageInforModal();
            }}
          />
        </LayoutWrapperMain>

        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </LayoutSingleColumn>
    </StaticPage>
  );
};

const mapStateToProps = (state) => {
  const {
    b2b: {
      totalPrice,
      totalCredits,
      packageInfo,
      packageSize,
      processStartDate,
      couponCode,
      proceedLoading,
      retrievedCoupon,
      calculatedPrices,
      selectedPackage,
    },
  } = state;

  return {
    totalPrice,
    totalCredits,
    couponCode,
    packageSize,
    packageInfo,
    processStartDate,
    proceedLoading,
    retrievedCoupon,
    calculatedPrices,
    selectedPackage,
  };
};

const mapDispatchToProps = (dispatch) => ({
  updateTotalCredits: (payload) => dispatch(updateTotalCredits(payload)),
  updateStartDate: (payload) => dispatch(updateStartDate(payload)),
  updateCouponCode: (payload) => dispatch(updateCouponCode(payload)),
  toggleProceedLoading: (payload) => dispatch(toggleProceedLoading(payload)),
  updateRetrievedCoupon: (payload) => dispatch(updateRetrievedCoupon(payload)),
  updateCalculatedPrices: (payload) => dispatch(updateCalculatedPrices(payload)),
});

export default compose(
  injectIntl,
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  withViewport
)(QuotePage);
