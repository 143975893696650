import React from 'react';
import { FormattedMessage } from 'react-intl';
import { propTypes } from '../../util/types';
import css from './BookingBreakdown.module.scss';
import get from 'lodash/get';

const LineItemListingTitle = ({ transaction, locationName }) => {
  const isCreditTx = get(transaction, 'attributes.protectedData.isCreditTx', false);

  const listingTitleId = isCreditTx
    ? 'BookingBreakdown.credit.listingTitle'
    : 'BookingBreakdown.listingTitle';

  return (
    <>
      <div className={css.lineItem}>
        <FormattedMessage id={listingTitleId}>
          {(id) => <span className={css.itemLabel}>{id}</span>}
        </FormattedMessage>
        <span className={css.itemValue}>
          {!!locationName
            ? locationName
            : transaction.listing && transaction.listing.attributes.title}
          {}
        </span>
      </div>
      <hr className={css.totalDivider} />
    </>
  );
};

LineItemListingTitle.propTypes = {
  transaction: propTypes.transaction.isRequired,
};

export default LineItemListingTitle;
