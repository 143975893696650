const contacts = [
  {
    name: 'Jeanna Lundberg',
    email: 'jeanna@respaces.co',
    title: 'CEO, Founder',
    linkedIn: 'https://www.linkedin.com/in/jeanna-lundberg-b8910064/',
    image: 'https://drive.google.com/uc?export=view&id=1wG2JKxzOoTiTHTnGeVzD0gQ0oam7aVdh',
  },
  {
    name: 'Oskar Gellerbrant',
    tel: '+46 73 993 92 16',
    email: 'oskar@respaces.co',
    title: 'CTO, Founder',
    linkedIn: 'https://www.linkedin.com/in/oskar-gellerbrant/',
    image: 'https://drive.google.com/uc?export=view&id=1iufG1ORpnI4DdsKl7YFSy4pUjDw0UYOS',
  },
  {
    name: 'Phil Smith',
    title: 'Front-end Developer',
    linkedIn: 'https://www.linkedin.com/in/phil-smith-825a7b172/',
    image: 'https://drive.google.com/uc?export=view&id=14En9vwoknr2CO0pq0m2TGg8GlNYg3nC5',
  },
];

export default contacts;
