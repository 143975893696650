import Modal from '../../components/Modal/Modal';
import { injectIntl } from 'react-intl';
import ButtonNew from '../ButtonNew/ButtonNew';
import classNames from 'classnames';
import { EditListingIcons as Icons } from '../../components';

import css from './HintModal.module.scss';

const HintModal = ({ isOpen, onClose, contentHeader, contentBody, centerAlign = false, intl }) => {
  return (
    <Modal
      containerClassName={css.modalContainer}
      hideCloseButton
      openClassName={css.openModal}
      scrollLayerClassName={css.scrollableLayer}
      isOpen={isOpen}
      onClose={onClose}
    >
      <div className={css.closeButton} onClick={onClose}>
        <Icons.CloseIcon />
      </div>
      <div className={classNames(css.hintContent, centerAlign && css.centerAlign)}>
        <h5>{contentHeader}</h5>
        <span>{contentBody}</span>
      </div>
      <ButtonNew
        className={css.hintButton}
        type="blue"
        onClick={(e) => {
          e.preventDefault();
          onClose();
        }}
      >
        {intl.formatMessage({ id: 'CreateLocationForm.description.hintButton' })}
      </ButtonNew>
    </Modal>
  );
};

export default injectIntl(HintModal);
