const COMMON_INTL_KEY = 'MarketplaceConfig.filters.category';

const CategoryModel = {
  COWORKING: {
    id: 'coworking',
    label: 'Coworking',
    labelId: `${COMMON_INTL_KEY}.coworking`,
  },

  PRIVATE: {
    id: 'private',
    label: 'Office Space',
    labelId: `${COMMON_INTL_KEY}.private`,
  },

  MEETING: {
    id: 'meeting',
    label: 'Meeting space',
    labelId: `${COMMON_INTL_KEY}.meeting`,
  },

  STUDIO: {
    id: 'studio',
    label: 'Studios',
    labelId: `${COMMON_INTL_KEY}.studio`,
  },

  FIXED: {
    id: 'fixed',
    labelId: `${COMMON_INTL_KEY}.fixed`,
  },
};

CategoryModel.LIST = [
  CategoryModel.COWORKING,
  CategoryModel.PRIVATE,
  CategoryModel.MEETING,
  CategoryModel.STUDIO,
  CategoryModel.FIXED,
];

CategoryModel.ID_TO_DATA = {
  [CategoryModel.COWORKING.id]: CategoryModel.COWORKING,
  [CategoryModel.PRIVATE.id]: CategoryModel.PRIVATE,
  [CategoryModel.MEETING.id]: CategoryModel.MEETING,
  [CategoryModel.STUDIO.id]: CategoryModel.STUDIO,
  [CategoryModel.FIXED.id]: CategoryModel.FIXED,
};

export default CategoryModel;
