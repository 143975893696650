import React from 'react';
import classNames from 'classnames';
import { bool, oneOf, string } from 'prop-types';

import css from './Badge.module.scss';

export const BADGE_COLORS = {
  GREY: 'grey',
  GREEN: 'green',
  RED: 'red',
  BLACK: 'black',
  WHITE: 'white',
};

BADGE_COLORS.LIST = [
  BADGE_COLORS.GREY,
  BADGE_COLORS.GREEN,
  BADGE_COLORS.RED,
  BADGE_COLORS.BLACK,
  BADGE_COLORS.WHITE,
];

const Badge = ({ text, color, uppercase }) => (
  <div className={classNames(css.badge, css[color], uppercase && css.uppercase)}>{text}</div>
);

Badge.defaultProps = {
  color: BADGE_COLORS.GREEN,
};

Badge.propTypes = {
  text: string.isRequired,
  color: oneOf(BADGE_COLORS.LIST),
  uppercase: bool,
};

export default Badge;
