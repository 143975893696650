import React from 'react';
import { MediaAndTextSection } from '../../components_new';
import Image from './assets/forPropertyOwnersImg.jpg';
import vitecImg from './assets/vitec.png';
import fasadImg from './assets/fasad.png';
import parakeyImg from './assets/parakey.png';
import accessyImg from './assets/accessy.png';
import css from './PartnerProffessional.module.scss';

const SectionForPropertyOwners = ({ intl, isMobile, onAPIClick }) => {
  return (
    <section className={css.sectionHero}>
      <MediaAndTextSection
        title={{
          content: intl.formatMessage({
            id: 'PartnerProffessional.SectionForPropertyOwners.title',
          }),
          showMobile: true,
        }}
        subtitle={{
          content: intl.formatMessage({
            id: 'PartnerProffessional.SectionForPropertyOwners.subtitle',
          }),
          showMobile: true,
        }}
        caption={{
          text: intl.formatMessage({ id: 'PartnerProffessional.SectionForPropertyOwners.caption' }),
          showMobile: true,
        }}
        bulletType={'check'}
        checkColor={'green'}
        media={{ content: Image, showMobile: true }}
        wideMedia
        textAlign={'left'}
        textAlignMobile={'bottom'}
        buttonsList={[
          {
            linkText: intl.formatMessage({
              id: 'PartnerProffessional.SectionForPropertyOwners.linkApi',
            }),
            callback: onAPIClick,
            type: 'secondary',
            withIcon: false,
            block: isMobile,
          },
          {
            linkText: intl.formatMessage({
              id: 'PartnerProffessional.SectionForPropertyOwners.linkDigital',
            }),
            type: 'blue',
            linkColor: 'whiteText',
            withIcon: false,
            name: 'DashboardPage',
            params: { view: 'locations', tab: 'create' },
            to: {
              state: { from: 'PartnerProffessional' },
            },
            block: isMobile,
            mobileButtonPosition: 'fullWidth',
          },
        ]}
      />
      <div className={css.integrationLogos}>
        <div className={css.logoWrapper}>
          <img src={vitecImg} alt="VITEC" />
          <img src={fasadImg} alt="FasAd" />
          <img src={parakeyImg} alt="Parakey" />
          <img src={accessyImg} alt="Accessy" />
        </div>
      </div>
    </section>
  );
};

export default SectionForPropertyOwners;
