import React from 'react';
import { Form as FinalForm } from 'react-final-form';
import {
  Form,
  Button,
  FieldTextInput,
  IconOrganizations as Icons,
  FieldCheckbox,
} from '../../components';
import routeConfiguration from '../../routeConfiguration';
import { createResourceLocatorString } from '../../util/routes';
import * as validators from '../../util/validators';

import css from './EmployeeCreateAccountForm.module.scss';

const EmployeeCreateAccountForm = (props) => {
  const { onSubmit, intl } = props;
  return (
    <FinalForm
      {...props}
      onSubmit={onSubmit}
      render={(fieldRenderProps) => {
        const { intl, handleSubmit, signupInProgress, inProgress, invalid } = fieldRenderProps;

        const submitDisabled = invalid || inProgress;
        // First name field
        const firstNameRequiredMessage = intl.formatMessage({
          id: 'EmployeeCreateAccountForm.firstNameRequired',
        });
        const firstNameRequired = validators.required(firstNameRequiredMessage);
        const firstNameFieldPlaceholder = intl.formatMessage({
          id: 'EmployeeCreateAccountForm.firstNameFieldPlaceholder',
        });

        // Last name field
        const lastNameRequiredMessage = intl.formatMessage({
          id: 'EmployeeCreateAccountForm.lastNameRequired',
        });
        const lastNameRequired = validators.required(lastNameRequiredMessage);
        const lastNameFieldPlaceholder = intl.formatMessage({
          id: 'EmployeeCreateAccountForm.lastNameFieldPlaceholder',
        });

        // Password field
        const passwordRequiredMessage = intl.formatMessage({
          id: 'EmployeeCreateAccountForm.passwordRequired',
        });
        const passwordMinLengthMessage = intl.formatMessage(
          { id: 'SignupForm.passwordTooShort' },
          { minLength: validators.PASSWORD_MIN_LENGTH }
        );
        const passwordMaxLengthMessage = intl.formatMessage(
          { id: 'SignupForm.passwordTooLong' },
          { maxLength: validators.PASSWORD_MAX_LENGTH }
        );
        const passwordMinLength = validators.minLength(
          passwordMinLengthMessage,
          validators.PASSWORD_MIN_LENGTH
        );
        const passwordMaxLength = validators.maxLength(
          passwordMaxLengthMessage,
          validators.PASSWORD_MAX_LENGTH
        );
        const passwordRequired = validators.requiredStringNoTrim(passwordRequiredMessage);
        const passwordValidators = validators.composeValidators(
          passwordRequired,
          passwordMinLength,
          passwordMaxLength
        );
        const passwordFieldPlaceholder = intl.formatMessage({
          id: 'EmployeeCreateAccountForm.passwordFieldPlaceholder',
        });

        const termsOfServicePageUrl = createResourceLocatorString(
          'TermsOfServicePage',
          routeConfiguration()
        );

        const emailSubscribeLabel = intl.formatMessage({
          id: 'EmployeeCreateAccountForm.emailSubscribe',
        });

        return (
          <Form onSubmit={handleSubmit}>
            <div className={css.inputWrapper}>
              <Icons.IconUser className={css.icon} />
              <FieldTextInput
                name="firstName"
                placeholder={firstNameFieldPlaceholder}
                type="text"
                className={css.iconInput}
                validate={firstNameRequired}
              />
            </div>
            <div className={css.inputWrapper}>
              <Icons.IconUser className={css.icon} />
              <FieldTextInput
                name="lastName"
                placeholder={lastNameFieldPlaceholder}
                type="text"
                className={css.iconInput}
                validate={lastNameRequired}
                autoComplete="off"
              />
            </div>
            <div className={css.inputWrapper}>
              <Icons.IconLock className={css.icon} />
              <FieldTextInput
                name="password"
                placeholder={passwordFieldPlaceholder}
                type="password"
                className={css.iconInput}
                validate={passwordValidators}
                autoComplete="off"
              />
            </div>
            <div className={css.emailSubscribeWrapper}>
              <FieldCheckbox
                textClassName={css.emailSubscribe}
                id="emailSubscribe"
                name="emailSubscribe"
                value="emailSubscribe"
                label={emailSubscribeLabel}
                checkboxSize="large"
              />
            </div>
            <p className={css.termAndConditions}>
              {intl.formatMessage({ id: 'EmployeeCreateAccountForm.termAndConditionsPreText' })}
              <a
                href={termsOfServicePageUrl}
                target="_blank"
                rel="noreferrer"
                className={css.terms}
              >
                {intl.formatMessage({ id: 'EmployeeCreateAccountForm.termAndConditions' })}
              </a>
            </p>
            <Button
              type="submit"
              disabled={submitDisabled}
              className={css.submitButton}
              inProgress={signupInProgress}
            >
              {intl.formatMessage({ id: 'EmployeeCreateAccountForm.submitButtonText' })}
            </Button>
          </Form>
        );
      }}
    />
  );
};

export default EmployeeCreateAccountForm;
