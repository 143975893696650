import React, { memo, useEffect, useState } from 'react';
import { injectIntl } from 'react-intl';

import config from '../../../../config';
import { WEEKDAYS } from '../../config/consts';
import { capitalize } from '../../../../lib/string';

import { CheckIcon, PasteIcon, CopyIcon } from '../Icons';
import classNames from 'classnames';
import css from './DayAvailabilityPlan.module.scss';

const DayAvailabilityPlan = memo(({ values, onUpdateValues, intl }) => {
  const [clipboard, updateClipboard] = useState(null);
  const [copyInitiated, setCopyInitiated] = useState(false);
  const [dayCopied, setDayCopied] = useState(null);

  /**
   * @param day {String}
   * @param checked {Boolean}
   * */
  const toggleDay = ({ day, checked }) => {
    if (checked) {
      onUpdateValues({
        ...values,
        [day]: { openTime: '480', closeTime: '1020' },
      });
    } else {
      if (day === dayCopied) {
        setDayCopied(null);
        updateClipboard(null);
      }
      handleDeleteDay(day);
    }
  };

  /**
   * @param day {String}
   * @param id {String}
   * @param value {String}
   * */
  const handleSelectChange = ({ day, id, value }) => {
    onUpdateValues({
      ...values,
      [day]: { ...values[day], [id]: value },
    });
    if (day === dayCopied) {
      setDayCopied(null);
      updateClipboard(null);
    }
  };

  const handleDeleteDay = (day) => {
    if (day in values) {
      const newPayload = { ...values };
      delete newPayload[day];
      onUpdateValues(newPayload);
    }
  };

  const copy = (day) => {
    updateClipboard(values[day]);
    setDayCopied(day);
  };

  const paste = (day) => {
    onUpdateValues({
      ...values,
      [day]: { ...clipboard },
    });
  };

  useEffect(() => {
    if (!Object.keys(values).length) {
      updateClipboard(null);
    }
  }, [values]);

  useEffect(() => {
    setCopyInitiated(!!clipboard);
  }, [clipboard]);

  return (
    <ul className={css.week}>
      {WEEKDAYS.map((w) => (
        <li key={`day_availability_plan_${w}`} className={css.weekDay}>
          <label className={css.checkboxWrapper} htmlFor={w}>
            <input
              type="checkbox"
              name={w}
              id={w}
              onChange={({ currentTarget }) =>
                toggleDay({
                  day: w,
                  checked: currentTarget.checked,
                })
              }
              checked={w in values}
            />
            <div className={css.pseudoCheckbox}>
              <CheckIcon />
            </div>
            <span className={css.day}>
              {intl.formatMessage({ id: `MarketplaceConfig.day.short.${capitalize(w)}` })}
            </span>
          </label>
          <div className={css.startEndTimes}>
            {w in values ? (
              <>
                <select
                  onChange={({ currentTarget }) =>
                    handleSelectChange({ day: w, id: 'openTime', value: currentTarget.value })
                  }
                  className={css.timeSelect}
                  name="openTime"
                  id="openTime"
                >
                  {config.custom.HOUR_OPTIONS.map((o) => (
                    <option
                      key={`open_time_option_${o.key}`}
                      value={o.key}
                      selected={values[w].openTime === o.key}
                    >
                      {o.label}
                    </option>
                  ))}
                </select>
                <span className={css.dash}>{'-'}</span>
                <select
                  onChange={({ currentTarget }) =>
                    handleSelectChange({ day: w, id: 'closeTime', value: currentTarget.value })
                  }
                  className={css.timeSelect}
                  name="closeTime"
                  id="closeTime"
                >
                  {config.custom.HOUR_OPTIONS.map((o) => (
                    <option
                      key={`close_time_option_${o.key}`}
                      value={o.key}
                      selected={values[w].closeTime === o.key}
                    >
                      {o.label}
                    </option>
                  ))}
                </select>
              </>
            ) : (
              <span className={css.closed}>
                {intl.formatMessage({ id: 'DayAvailabilityPlan.unavailable' })}
              </span>
            )}
          </div>
          <div className={css.controls}>
            {w in values ? (
              <button
                type="button"
                onClick={() => copy(w)}
                className={classNames(css.controlBtn, css.copyBtn)}
                tooltip-text={intl.formatMessage({ id: 'DayAvailabilityPlan.copyTooltip' })}
              >
                {w !== dayCopied && clipboard !== null ? (
                  <CopyIcon className={'iconGrey'} />
                ) : w === dayCopied ? (
                  <CopyIcon className={'iconGreen'} />
                ) : (
                  <CopyIcon />
                )}
              </button>
            ) : (
              <div className={css.tempDiv}></div>
            )}
            {copyInitiated && (
              <button type="button" onClick={() => paste(w)} className={css.controlBtn}>
                {w === dayCopied ? <PasteIcon className={'iconGrey'} /> : <PasteIcon />}
              </button>
            )}
          </div>
        </li>
      ))}
    </ul>
  );
});

DayAvailabilityPlan.defaultProps = {
  values: {},
};

export default injectIntl(DayAvailabilityPlan);
