import classNames from 'classnames';
import { arrayOf, bool, object, shape, string } from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { ImageSlider, NamedLink } from '../../components';
import {
  COWORKING_CATEGORY,
  DAILY_BOOKING,
  FIXED_OFFICE_CATEGORY,
  HOURLY_BOOKING,
  MONTHLY_BOOKING,
} from '../../util/types';
import { createSlug } from '../../util/urlHelpers';
import { BADGE_COLORS } from '../Badge/Badge';
import { Badge, FavoriteListingToggle } from '../index';
import css from './ListingCardNew.module.scss';

/* const StarIcon = () => (
  <svg width="15" height="16" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7.5 0L9.8175 5.08278L15 5.90285L11.25 9.85702L12.135 15.4432L7.5 12.8044L2.865 15.4432L3.75 9.85702L0 5.90285L5.1825 5.08278L7.5 0Z"
      fill="#E8909C"
    />
  </svg>
); */

const BOOKING_TYPES = {
  DAILY_BOOKING,
  HOURLY_BOOKING,
  MONTHLY_BOOKING,
};

BOOKING_TYPES.LIST = [DAILY_BOOKING, HOURLY_BOOKING, MONTHLY_BOOKING];

const seatsInfo = (seats, category, minSeats, isFixedOffice) =>
  seats && category?.key !== COWORKING_CATEGORY ? (
    <>
      {isFixedOffice && !minSeats && (
        <FormattedMessage id="ListingPage.header.seats.upTo" values={{ seats: seats }} />
      )}
      <FormattedMessage
        id={isFixedOffice ? 'ListingPage.header.seatsFixed' : 'ListingPage.header.seats'}
        values={{ seats: seats }}
      />
    </>
  ) : (
    ''
  );

const ListingCardNew = ({
  intl,
  title,
  location,
  type,
  price,
  /* rating, */
  features,
  isFavorite,
  isFavoriteCard,
  isCompanyFavorite,
  hideFavoriteIcon,
  id,
  images,
  unitTranslationKey,
  onMouseEnter,
  onMouseLeave,
  className,
  credits,
  isShowCredits,
  minSeats,
  seats,
  category,
  showCompanyFavoriteButton,
  isActiveOnBottomSheet,
  onClick,
  isMobile,
  search,
  priceMax,
  requestPrice,
  locationId,
}) => {
  const isInShelf = isFavoriteCard && !isActiveOnBottomSheet;
  const isFixedOffice = category && category.key === FIXED_OFFICE_CATEGORY;

  const numberOfGuests = `${minSeats ? `${minSeats} - ` : ''}${seats}`;
  const formattedPrice = `${price.formattedPrice}${
    isFixedOffice && priceMax ? ` - ${priceMax / 100} kr` : ''
  }`;

  return (
    <div className={css.listingCardContainer}>
      {/* extra div here for positioning the favorites button outside of the parent listingCard element */}
      <div
        className={classNames(
          className,
          css.listingCard,
          isMobile && css.mobile,
          isInShelf && css.shelfCard,
          isActiveOnBottomSheet && css.bottomSheet
        )}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        onClick={onClick}
      >
        <NamedLink
          className={css.listingLink}
          name="LocationPage"
          params={{
            id: `${locationId}`,
            slug: createSlug(title),
          }}
          to={{ search: search }}
          target="_blank"
          rel="noopener noreferrer"
        >
          <div className={classNames(css.imgWrapper, isInShelf && css.shelfImage)}>
            {images.length === 1 ? (
              <img
                className={css.img}
                src={images[0].attributes.variants['square-small2x'].url}
                alt={title}
              />
            ) : (
              <ImageSlider className={css.img} category="images" data={{ images }} />
            )}
            {isCompanyFavorite &&
              !isInShelf && ( //TODO double check this banner shows when we want it to
                <div className={css.badgeWrapper}>
                  <Badge
                    text={intl.formatMessage({ id: 'ListingCard.companyFavoriteBadge' })}
                    color={BADGE_COLORS.BLACK}
                    uppercase
                  />
                </div>
              )}
            {!hideFavoriteIcon && (
              <div className={css.favoriteButtonWrapper}>
                <FavoriteListingToggle
                  listingId={locationId}
                  isFavorite={isFavorite}
                  isCompanyFavorite={isCompanyFavorite}
                  showCompanyFavoriteButton={showCompanyFavoriteButton}
                />
              </div>
            )}
          </div>
          <div
            className={classNames(
              css.content,
              isMobile && css.mobile,
              isInShelf && css.shelfContent
            )}
          >
            <div className={css.top}>
              <h3 className={css.title}>{title}</h3>
              <span className={css.location}>
                {!isInShelf
                  ? location
                  : `${intl.formatMessage({ id: category.labelId })}, ${location.split(',')[0]}`}
              </span>
            </div>
            <div className={classNames(css.bottom)}>
              {!isInShelf && <p className={css.type}>{type}</p>}
              <p className={css.price}>
                {!requestPrice ? (
                  <>
                    <span className={css.formattedPrice}>
                      {isShowCredits && !isFixedOffice ? credits : formattedPrice}
                    </span>
                    {isFixedOffice ? (
                      <FormattedMessage id={'UnitKey.perMonthFixed'} />
                    ) : (
                      <FormattedMessage id={unitTranslationKey} />
                    )}
                  </>
                ) : (
                  intl.formatMessage({ id: 'ListingCard.priceOnRequest' })
                )}
              </p>
              <p className={css.features}>{features}</p>
              <p className={css.seats}>
                {seatsInfo(numberOfGuests, category, minSeats, isFixedOffice)}
              </p>
              {/* TODO: Uncomment when rating will be implemented */}
              {/* {rating && (
            <div className={css.rating}>
              <StarIcon />
              <span>{rating}</span>
            </div>
          )} */}
            </div>
          </div>
        </NamedLink>
      </div>
    </div>
  );
};

ListingCardNew.defaultProps = {
  images: null,
  id: null,
  isFavorite: false,
  hideFavoriteIcon: false,
  /* rating: null,*/
  unitTranslationKey: BOOKING_TYPES.DAILY_BOOKING,
};

ListingCardNew.propTypes = {
  title: string.isRequired,
  location: string.isRequired,
  type: string.isRequired,
  price: object.isRequired,
  images: arrayOf(
    shape({
      attributes: object,
    })
  ).isRequired,
  id: string,
  features: string,
  isFavorite: bool,
  unitTranslationKey: string,
  /* rating: number, */
};

export default ListingCardNew;
