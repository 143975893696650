import React, { memo, useCallback, useEffect, useState } from 'react';
import classNames from 'classnames';
import { func, string } from 'prop-types';
import { injectIntl } from 'react-intl';
import { CSSTransition } from 'react-transition-group';

import { OutsideClickHandler } from '../../../../components';

import css from './SortBy.module.scss';

const OPTIONS = {
  locations: [
    { id: 'name', label: 'SortBy.name' },
    { id: 'updated_at', label: 'SortBy.updated_at' },
  ],
  listings: [
    { id: 'category', label: 'SortBy.category' },
    { id: 'seats', label: 'SortBy.seats' },
    { id: 'status', label: 'SortBy.status' },
  ],
};

OPTIONS.ID_TO_DATA = {
  locations: {
    name: OPTIONS.locations[0],
    updated_at: OPTIONS.locations[1],
  },
  listings: {
    category: OPTIONS.listings[0],
    seats: OPTIONS.listings[1],
    status: OPTIONS.listings[2],
  },
};

const SortBy = memo(({ sortBy, onUpdateSortBy, id, intl }) => {
  const [isOpen, setOpen] = useState(false);

  const handleSortByUpdate = useCallback(
    (id) => {
      onUpdateSortBy(id);
      setOpen(false);
    },
    [onUpdateSortBy]
  );

  const tableKey = id === 'unassigned_listings' ? 'listings' : id;

  return (
    <div className={css.dropdown}>
      <button className={css.trigger} onClick={() => setOpen(true)}>
        {intl.formatMessage({ id: 'SortBy.title' })}
        <span className={classNames(!!sortBy && css.currentCategory)}>
          {intl.formatMessage({
            id: OPTIONS.ID_TO_DATA?.[tableKey]?.[sortBy]?.label || OPTIONS?.[tableKey]?.[0]?.label,
          })}
        </span>
      </button>
      <CSSTransition in={isOpen} timeout={300} classNames="dropdown" unmountOnExit>
        <OutsideClickHandler rootClassName={css.body} onOutsideClick={() => setOpen(false)}>
          {OPTIONS[tableKey].map((option) => (
            <button
              key={`sort_by_${option.id}`}
              className={classNames(option.id === sortBy && css.selected)}
              onClick={() => handleSortByUpdate(option.id)}
            >
              {intl.formatMessage({ id: option.label })}
            </button>
          ))}
        </OutsideClickHandler>
      </CSSTransition>
    </div>
  );
});
SortBy.propTypes = {
  sortBy: string,
  onUpdateSortBy: func,
  id: string,
};

export default injectIntl(SortBy);
