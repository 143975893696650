import salesTeamImg from '../../assets/sales_team.png';
import { SectionCtaWithBg } from '../../components_new';
import { BUTTON_SIZES, BUTTON_TYPES, LINK_COLORS } from '../../components_new/ButtonNew/ButtonNew';
import { CTA_WITH_BG_COLORS } from '../../components_new/SectionCtaWithBg/SectionCtaWithBg';
import css from './PartnerProffessional.module.scss';

const SectionContact = ({ intl }) => {
  return (
    <section className={css.sectionContact}>
      <SectionCtaWithBg
        title={intl.formatMessage({ id: 'PartnerProffessional.SectionContact.title' })}
        caption={intl.formatMessage({ id: 'PartnerProffessional.SectionContact.caption' })}
        image={salesTeamImg}
        imageAltText="Sales team"
        links={[
          {
            text: intl.formatMessage({ id: 'PartnerProffessional.SectionContact.button' }),
            externalLink: 'https://meetings.hubspot.com/sofia-bostroem',
            type: BUTTON_TYPES.WHITE,
            linkColor: LINK_COLORS.BLUE,
            size: BUTTON_SIZES.MEDIUM,
          },
        ]}
        bgColor={CTA_WITH_BG_COLORS.BLUE}
      />
    </section>
  );
};

export default SectionContact;
