import React from 'react';

const AddNewUserIcon = () => (
  <svg width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M7.09091 9.45508C4.72409 9.45508 0 10.9169 0 13.8187V16.0005H14.1818V13.8187C14.1818 10.9169 9.45773 9.45508 7.09091 9.45508Z" />
    <circle cx="6.90942" cy="4" r="4" />
    <circle cx="14.6682" cy="10.7881" r="4" stroke="#F8F8F8" />
    <path d="M14.6682 8.72754V12.8488" stroke="#F8F8F8" strokeWidth="0.8" strokeLinecap="round" />
    <path d="M12.6077 10.7881H16.7289" stroke="#F8F8F8" strokeWidth="0.8" strokeLinecap="round" />
  </svg>
);

export default AddNewUserIcon;
