import React, { useState } from 'react';
import { bool, string } from 'prop-types';

import { Map } from '../../components';

import config from '../../config';
import { propTypes } from '../../util/types';
import { obfuscatedCoordinates } from '../../util/maps';
import { formatLocation } from '../../util/data';

import LocationIcon from './ui/LocationIcon';

import css from './MapWithAddress.module.scss';

const MapWithAddress = ({ geolocation, address, id, showAddress }) => {
  const [isStatic, setStatic] = useState(true);

  const cacheKey = !!id ? `${id}_${geolocation.lat}_${geolocation.lng}` : null;

  const mapProps = config.maps.fuzzy.enabled
    ? { obfuscatedCenter: obfuscatedCoordinates(geolocation, cacheKey) }
    : { address, center: geolocation };

  const map = <Map {...mapProps} useStaticMap={isStatic} />;

  return !!geolocation.lat && !!geolocation.lng ? (
    <div>
      {showAddress && (
        <>
          <LocationIcon />
          <a
            target="_blank"
            rel="noreferrer noopener"
            className={css.locationAddress}
            href={`http://maps.google.com/?q=${address}`}
          >
            <a
              target="_blank"
              rel="noreferrer noopener"
              className={css.locationAddress}
              href={`http://maps.apple.com/maps?q=${address}`}
            >
              {formatLocation(address)}
            </a>
          </a>
        </>
      )}
      {isStatic ? (
        <button onClick={() => setStatic(false)} className={css.map}>
          {map}
        </button>
      ) : (
        <div className={css.map}>{map}</div>
      )}
    </div>
  ) : null;
};

MapWithAddress.defaultProps = {
  showAddress: true,
  center: null,
  address: '',
};

MapWithAddress.propTypes = {
  address: string.isRequired,
  center: propTypes.latlng,
  id: propTypes.uuid.isRequired,
  showAddress: bool,
};

export default MapWithAddress;
