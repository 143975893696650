import React from 'react';
import { FieldSelect } from '../../components';

import css from './EditListingDescriptionForm.module.scss';

const CustomCategorySelectFieldMaybe = (props) => {
  const { name, id, categoryOptions, intl } = props;
  const categoryLabel = intl.formatMessage({
    id: 'EditListingDescriptionForm.categoryLabel',
  });

  return categoryOptions ? (
    <FieldSelect className={css.category} name={name} id={id} label={categoryLabel}>
      {categoryOptions.map((c) => (
        <option key={c.key} value={c.key}>
          {intl.formatMessage({ id: c.labelId, defaultMessage: c.label })}
        </option>
      ))}
    </FieldSelect>
  ) : null;
};

export default CustomCategorySelectFieldMaybe;
