import React from 'react';
import { CompanySettingsForm } from '../../forms';
import css from './SettingsTab.module.scss';

const SettingsTab = (props) => {
  const { onUpdateCompanyName, ...rest } = props;
  const { intl } = rest;
  const handleSubmit = (values) => {
    const { companyName } = values;
    if (companyName) {
      onUpdateCompanyName({ companyName });
    }
  };
  return (
    <div className={css.tabContainer}>
      <h2 className={css.sectionTitle}>{intl.formatMessage({ id: 'SettingsTab.settings' })}</h2>
      <CompanySettingsForm {...rest} onSubmit={handleSubmit} />
    </div>
  );
};

export default SettingsTab;
