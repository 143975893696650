import classNames from 'classnames';
import React from 'react';
import { useIntl } from 'react-intl';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { CSSTransition } from 'react-transition-group';
import { TopbarContainer } from '..';
import { routeConfiguration } from '../..';
import { EditListingIcons as Icons, Page, StripeConnectAccountStatusBox } from '../../components';
import config from '../../config';
import { getLocale } from '../../util/localeHelper';
import { getMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import {
  getStripeConnectAccountLink,
  stripeAccountClearError,
} from '../../ducks/stripeConnectAccount.duck';
import { StripeConnectAccountForm } from '../../forms';
import { ensureCurrentUser, ensureOwnListing } from '../../util/data';
import { useBoolean } from '../../util/hooks';
import { createResourceLocatorString } from '../../util/routes';
import { types as sdkTypes } from '../../util/sdkLoader';
import { FIXED_OFFICE_CATEGORY } from '../../util/types';
import { savePayoutDetails } from '../EditListingPage/EditListingPage.duck';
import imgEN from './imgEN.png';
import imgSV from './imgSV.png';
import css from './EditListingFinishPage.module.scss';
import { ButtonNew } from '../../components_new';
import { BUTTON_TYPES } from '../../components_new/ButtonNew/ButtonNew';

const { UUID } = sdkTypes;

const STRIPE_ONBOARDING_RETURN_URL_SUCCESS = 'success';
const STRIPE_ONBOARDING_RETURN_URL_FAILURE = 'failure';

// Check if there's requirements on selected type: 'past_due', 'currently_due' etc.
const hasRequirements = (stripeAccountData, requirementType) =>
  stripeAccountData != null &&
  stripeAccountData.requirements &&
  Array.isArray(stripeAccountData.requirements[requirementType]) &&
  stripeAccountData.requirements[requirementType].length > 0;

// Redirect user to Stripe's hosted Connect account onboarding form
const handleGetStripeConnectAccountLinkFn = (getLinkFn, commonParams) => (type) => () => {
  getLinkFn({ type, ...commonParams })
    .then((url) => {
      window.location.href = url;
    })
    .catch((err) => console.error(err));
};

// Create return URL for the Stripe onboarding form
const createReturnURL = (returnURLType, rootURL, routes, pathParams) => {
  const path = createResourceLocatorString(
    'EditListingStripeOnboardingPage',
    routes,
    { ...pathParams, returnURLType },
    {}
  );
  const root = rootURL.replace(/\/$/, '');
  return `${root}${path}`;
};

// Get attribute: stripeAccountData
const getStripeAccountData = (stripeAccount) => stripeAccount.attributes.stripeAccountData || null;

// Get last 4 digits of bank account returned in Stripe account
const getBankAccountLast4Digits = (stripeAccountData) =>
  stripeAccountData && stripeAccountData.external_accounts.data.length > 0
    ? stripeAccountData.external_accounts.data[0].last4
    : null;

const EditListingFinishPage = (props) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const params = useParams();
  const [openPayoutDetailForm, setOpenPayoutDetailForm] = useBoolean();
  const {
    getAccountLinkInProgress,
    getAccountLinkError,
    createStripeAccountError,
    updateStripeAccountError,
    fetchStripeAccountError,
    stripeAccount,
    stripeAccountFetched,
  } = useSelector((state) => state.stripeConnectAccount, shallowEqual);

  const { payoutDetailsSaveInProgress, payoutDetailsSaved } = useSelector(
    (state) => state.EditListingPage,
    shallowEqual
  );

  const { currentUser } = useSelector((state) => state.user);
  const state = useSelector((state) => state, shallowEqual);

  const onPayoutDetailsFormChange = () => dispatch(stripeAccountClearError());
  const onGetStripeConnectAccountLink = (params) => dispatch(getStripeConnectAccountLink(params));
  const onPayoutDetailsSubmit = (values, isUpdateCall) =>
    dispatch(savePayoutDetails(values, isUpdateCall));
  const getOwnListing = (id) => {
    const listings = getMarketplaceEntities(state, [{ id, type: 'ownListing' }]);
    return listings.length === 1 ? listings[0] : null;
  };

  const currentLocale = getLocale();
  const rootURL = config.canonicalRootURL;
  const routes = routeConfiguration();
  const { returnURLType, ...pathParams } = params;
  const ensuredCurrentUser = ensureCurrentUser(currentUser);
  const currentUserLoaded = !!ensuredCurrentUser.id;
  const formDisabled = getAccountLinkInProgress;
  const stripeConnected = currentUserLoaded && !!stripeAccount && !!stripeAccount.id;
  const stripeAccountData = stripeConnected ? getStripeAccountData(stripeAccount) : null;
  const savedCountry = stripeAccountData ? stripeAccountData.country : null;
  const stripeAccountError =
    createStripeAccountError || updateStripeAccountError || fetchStripeAccountError;
  const accountId = stripeConnected ? stripeAccount.id : null;

  const successURL = createReturnURL(
    STRIPE_ONBOARDING_RETURN_URL_SUCCESS,
    rootURL,
    routes,
    pathParams
  );
  const failureURL = createReturnURL(
    STRIPE_ONBOARDING_RETURN_URL_FAILURE,
    rootURL,
    routes,
    pathParams
  );
  const handleGetStripeConnectAccountLink = handleGetStripeConnectAccountLinkFn(
    onGetStripeConnectAccountLink,
    {
      accountId,
      successURL,
      failureURL,
    }
  );

  const requirementsMissing =
    stripeAccount &&
    (hasRequirements(stripeAccountData, 'past_due') ||
      hasRequirements(stripeAccountData, 'currently_due'));

  const returnedAbnormallyFromStripe = returnURLType === STRIPE_ONBOARDING_RETURN_URL_FAILURE;
  const showVerificationNeeded = stripeConnected && requirementsMissing;
  const { id: listingId } = params;
  const currentListing = ensureOwnListing(getOwnListing(new UUID(listingId)));

  const category = currentListing.attributes.publicData?.category;
  const isFixedOffice = category === FIXED_OFFICE_CATEGORY;

  const cheronRightIconClass = classNames(css.cheronRightIcon, {
    [css.rotate]: openPayoutDetailForm,
  });
  const handlePayoutDetailClick = () => {
    setOpenPayoutDetailForm.toggle();
  };

  return (
    <Page title={intl.formatMessage({ id: 'EditListingFinishPage.listingReadyText' })}>
      <TopbarContainer currentPage="EditListingFinishPage" />
      <main className={css.mainContainer}>
        <section className={css.sectionContainer}>
          <img className={css.image} alt="" src={currentLocale === 'en' ? imgEN : imgSV} />
        </section>
        <section className={classNames(css.sectionContainer, css.payoutDetail)}>
          {isFixedOffice ? (
            <>
              <div className={classNames(css.sectionWrapper, css.first)}>
                <div className={css.checkmark}>
                  <Icons.CheckmarkIcon />
                </div>
                <h2 className={classNames(css.sectionTitle, css.grey)}>
                  {intl.formatMessage({ id: 'EditListingFinishPage.listYourSpace' })}
                </h2>
              </div>
              <div className={css.sectionWrapper}>
                <h2 className={css.sectionTitle}>
                  <span className={css.number}>2</span>
                  {intl.formatMessage({ id: 'EditListingFinishPage.awaitApproval' })}
                  <div className={css.clockIconWrapper}>
                    <Icons.ClockIcon className={css.clockIcon} />
                    Ca 24h
                  </div>
                </h2>
                <div className={css.completeWrapper}>
                  <span>
                    {intl.formatMessage({ id: 'EditListingFinishPage.awaitApprovalText' })}
                  </span>
                </div>
              </div>
              <div className={css.sectionWrapper}>
                <h2 className={css.sectionTitle}>
                  <span className={css.number}>3</span>
                  {intl.formatMessage({ id: 'EditListingFinishPage.letYouKnow' })}
                </h2>
                <div className={css.completeWrapper}>
                  <span>{intl.formatMessage({ id: 'EditListingFinishPage.letYouKnowText' })}</span>
                </div>
              </div>
              <ButtonNew
                type={BUTTON_TYPES.BLUE}
                params={{ view: 'locations', tab: 'overview' }}
                name={'DashboardPage'}
              >
                {intl.formatMessage({ id: 'EditListingFinishPage.buttonText' })}
              </ButtonNew>
            </>
          ) : (
            <>
              <h2 className={css.sectionTitle}>
                {intl.formatMessage({ id: 'EditListingFinishPage.listingReadyText' })}
              </h2>
              <p className={css.sectionDesc}>
                {intl.formatMessage({
                  id: isFixedOffice
                    ? 'EditListingFinishPage.listingReadyDescriptionFixedOffice'
                    : 'EditListingFinishPage.listingReadyDesc',
                })}
              </p>
              <div className={css.sectionWrapper}>
                {!isFixedOffice && (
                  <h2 className={css.sectionTitle}>
                    {intl.formatMessage({ id: 'EditListingFinishPage.createListing' })}
                  </h2>
                )}
                <div className={css.completeWrapper}>
                  <Icons.CompleteIcon />
                  <span>{intl.formatMessage({ id: 'EditListingFinishPage.complete' })}</span>
                </div>
              </div>

              <div className={classNames(css.sectionWrapper, css.listing)}>
                <div className={cheronRightIconClass} onClick={handlePayoutDetailClick}>
                  <Icons.CheronRightIcon />
                </div>
                <h2 className={css.sectionTitle}>
                  {intl.formatMessage({ id: 'EditListingFinishPage.payoutDetailTitle' })}
                </h2>
                {stripeConnected || (!stripeConnected && openPayoutDetailForm) ? (
                  <p className={css.sectionDesc}>
                    {intl.formatMessage({ id: 'EditListingFinishPage.payoutDetailOpenFormText' })}
                  </p>
                ) : (
                  <p className={css.sectionDesc}>
                    {intl.formatMessage({ id: 'EditListingFinishPage.payoutDetailCloseFormText' })}
                  </p>
                )}
                <CSSTransition
                  in={stripeConnected || (!stripeConnected && openPayoutDetailForm)}
                  classNames="company-name-field"
                  unmountOnExit
                  timeout={300}
                >
                  <StripeConnectAccountForm
                    disabled={formDisabled}
                    inProgress={payoutDetailsSaveInProgress}
                    ready={payoutDetailsSaved}
                    currentUser={ensuredCurrentUser}
                    stripeBankAccountLastDigits={getBankAccountLast4Digits(stripeAccountData)}
                    savedCountry={savedCountry}
                    submitButtonText={intl.formatMessage({
                      id: 'StripePayoutPage.submitButtonText',
                    })}
                    stripeAccountError={stripeAccountError}
                    stripeAccountFetched={stripeAccountFetched}
                    stripeAccountLinkError={getAccountLinkError}
                    onChange={onPayoutDetailsFormChange}
                    onSubmit={onPayoutDetailsSubmit}
                    onGetStripeConnectAccountLink={handleGetStripeConnectAccountLink}
                    stripeConnected={stripeConnected}
                  >
                    {stripeConnected && !returnedAbnormallyFromStripe && showVerificationNeeded ? (
                      <StripeConnectAccountStatusBox
                        type="verificationNeeded"
                        inProgress={getAccountLinkInProgress}
                        onGetStripeConnectAccountLink={handleGetStripeConnectAccountLink(
                          'custom_account_verification'
                        )}
                      />
                    ) : stripeConnected && savedCountry && !returnedAbnormallyFromStripe ? (
                      <StripeConnectAccountStatusBox
                        type="verificationSuccess"
                        inProgress={getAccountLinkInProgress}
                        disabled={payoutDetailsSaveInProgress}
                        onGetStripeConnectAccountLink={handleGetStripeConnectAccountLink(
                          'custom_account_update'
                        )}
                      />
                    ) : null}
                  </StripeConnectAccountForm>
                </CSSTransition>
              </div>
            </>
          )}
        </section>
      </main>
    </Page>
  );
};

export default EditListingFinishPage;
