import React from 'react';
import { useIntl } from 'react-intl';
import {
  Button,
  FieldSelect,
  Modal,
  PrimaryButton,
  EditListingIcons as Icons,
  FieldTextInput,
} from '..';
import css from './AmenityDetailModal.module.scss';
import { bool, func, string } from 'prop-types';
import { object } from 'prop-types';
import { ALL_END_HOURS, ALL_START_HOURS, timeToValue } from '../../util/dates';

const onManageDisableScrolling = () => null;

const AmenityDetailModal = (props) => {
  const { isOpen, onClose, label, handleClick, values, amenityName } = props;

  const intl = useIntl();

  const filterEndHours = (availableEndHours, values) => {
    const { amenityTime = {} } = values;
    const serviceStartTime = amenityTime?.[amenityName]?.startTime;
    if (!serviceStartTime) return [];

    const pickAfter = (time) => (h) => timeToValue(h) > timeToValue(time);
    return availableEndHours.filter(pickAfter(serviceStartTime));
  };
  return (
    <Modal
      id="AmenityDetailModal"
      isOpen={isOpen}
      onClose={onClose}
      hideCloseButton
      openClassName={css.openModal}
      scrollLayerClassName={css.scrollableLayer}
      containerClassName={css.modalContainer}
      onManageDisableScrolling={onManageDisableScrolling}
    >
      <div className={css.modalContainer}>
        <div className={css.modalTitle}>{label}</div>
        <div className={css.serviceTimeFieldWrapper}>
          <div className={css.fieldTitle}>
            {intl.formatMessage({ id: 'AmenityDetailModal.timeOfService' })}
          </div>
          <div className={css.fieldsWrapper}>
            <FieldSelect
              id={`amenityTime[${amenityName}][startTime]`}
              name={`amenityTime[${amenityName}][startTime]`}
              selectClassName={css.fieldSelect}
            >
              <option disabled value="">
                00:00
              </option>
              {ALL_START_HOURS.map((s) => (
                <option value={s} key={s}>
                  {s}
                </option>
              ))}
            </FieldSelect>
            <span className={css.dash}>-</span>
            <FieldSelect
              id={`amenityTime[${amenityName}][endTime]`}
              name={`amenityTime[${amenityName}][endTime]`}
              selectClassName={css.fieldSelect}
            >
              <option disabled value="">
                00:00
              </option>
              {filterEndHours(ALL_END_HOURS, values).map((s) => (
                <option value={s} key={s}>
                  {s}
                </option>
              ))}
            </FieldSelect>
            <Icons.CheronDownIcon className={css.cheronDown} />
          </div>
        </div>

        <div className={css.detailFieldWrapper}>
          <div className={css.fieldTitle}>
            {intl.formatMessage({ id: 'AmenityDetailModal.additionalInfo' })}
          </div>
          <FieldTextInput
            id={`detailInfo[${amenityName}]`}
            name={`detailInfo[${amenityName}]`}
            type="textarea"
            className={css.detailField}
            maxCharacters={35}
          />
        </div>
        <div className={css.bottomButtonWrapper}>
          <Button type="button" onClick={onClose} className={css.cancelBtn}>
            {intl.formatMessage({ id: 'AmenityDetailModal.cancel' })}
          </Button>
          <PrimaryButton type="button" onClick={handleClick} className={css.applyBtn}>
            {intl.formatMessage({ id: 'AmenityDetailModal.apply' })}
          </PrimaryButton>
        </div>
      </div>
    </Modal>
  );
};

AmenityDetailModal.propTypes = {
  isOpen: bool.isRequired,
  onClose: func.isRequired,
  label: string,
  handleClick: func,
  amenityName: string,
  values: object,
};

export default AmenityDetailModal;
