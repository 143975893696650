import React, { useRef } from 'react';
import { bool, func, shape, string } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import config from '../../config';
import { propTypes, DAILY_BOOKING, HOURLY_BOOKING } from '../../util/types';
import * as validators from '../../util/validators';
import { formatMoney } from '../../util/currency';
import { types as sdkTypes } from '../../util/sdkLoader';
import {
  Button,
  Form,
  FieldCurrencyInput,
  FieldSelect,
  FieldNumberInput,
  FieldTextInput,
  FieldCheckbox,
  FieldBoolean,
} from '../../components';
import css from './EditListingPricingForm.module.scss';
import { FieldArray } from 'react-final-form-arrays';
import arrayMutators from 'final-form-arrays';

const { Money } = sdkTypes;

export const EditListingPricingFormComponent = ({ initialValues, onSubmit, ...props }) => {
  const previousValues = useRef(null);
  return (
    <FinalForm
      {...props}
      mutators={{
        ...arrayMutators,
      }}
      initialValues={previousValues.current ? previousValues.current : initialValues}
      onSubmit={(values) => {
        previousValues.current = values;
        onSubmit(values);
      }}
      render={(formRenderProps) => {
        const {
          className,
          disabled,
          ready,
          handleSubmit,
          intl,
          invalid,
          pristine,
          saveActionMsg,
          updated,
          updateInProgress,
          fetchErrors,
          values,
        } = formRenderProps;
        const { bookingType, additionalServices = [] } = values;

        const translationKey =
          bookingType === DAILY_BOOKING
            ? 'EditListingPricingForm.pricePerDay'
            : bookingType === HOURLY_BOOKING
            ? 'EditListingPricingForm.pricePerUnit'
            : 'EditListingPricingForm.pricePerMonth';

        const pricePerUnitMessage = intl.formatMessage({
          id: translationKey,
        });

        const pricePlaceholderMessage = intl.formatMessage({
          id: 'EditListingPricingForm.priceInputPlaceholder',
        });

        const bookingTypeLabel = intl.formatMessage({
          id: 'EditListingPricingForm.bookingTypeLabel',
        });

        const bookingTypeRequiredMessage = intl.formatMessage({
          id: 'EditListingPricingForm.bookingTypeRequiredMessage',
        });

        const discountOfferMessage = intl.formatMessage({
          id: 'EditListingPricingForm.discountOfferMessage',
        });

        const priceRequired = validators.required(
          intl.formatMessage({
            id: 'EditListingPricingForm.priceRequired',
          })
        );
        const minPrice = new Money(config.listingMinimumPriceSubUnits, config.currency);
        const minPriceRequired = validators.moneySubUnitAmountAtLeast(
          intl.formatMessage(
            {
              id: 'EditListingPricingForm.priceTooLow',
            },
            {
              minPrice: formatMoney(intl, minPrice),
            }
          ),
          config.listingMinimumPriceSubUnits
        );
        const priceValidators = config.listingMinimumPriceSubUnits
          ? validators.composeValidators(priceRequired, minPriceRequired)
          : priceRequired;

        const additionalServiceLabel = intl.formatMessage({
          id: 'EditListingPricingForm.additionalServices',
        });

        const addServiceLabelId =
          !additionalServices ||
          (Array.isArray(additionalServices) && additionalServices.length === 0)
            ? 'EditListingPricingForm.addAService'
            : 'EditListingPricingForm.addAnotherService';
        const addServiceLabel = intl.formatMessage({ id: addServiceLabelId });
        const removeServiceLabel = intl.formatMessage({
          id: 'EditListingPricingForm.removeService',
        });
        const serviceNameLabel = intl.formatMessage({
          id: 'EditListingPricingForm.serviceNameLabel',
        });
        const serviceNamePlaceholder = intl.formatMessage({
          id: 'EditListingPricingForm.serviceNamePlaceholder',
        });
        const serviceDescriptionLabel = intl.formatMessage({
          id: 'EditListingPricingForm.serviceDescriptionLabel',
        });
        const serviceDescriptionPlaceholder = intl.formatMessage({
          id: 'EditListingPricingForm.serviceDescriptionPlaceholder',
        });
        const serviceDescriptionMaximumMessage = intl.formatMessage({
          id: 'EditListingPricingForm.serviceDescriptionMaximum',
        });

        const servicePriceLabel = intl.formatMessage({
          id: 'EditListingPricingForm.servicePriceLabel',
        });

        const vatLabel = intl.formatMessage({
          id: 'EditListingPricingForm.vatLabel',
        });

        const vatPlaceholder = intl.formatMessage({
          id: 'EditListingPricingForm.vatPlaceholder',
        });

        const vatRequiredMessage = intl.formatMessage({
          id: 'EditListingPricingForm.vatRequiredMessage',
        });

        const multiplyWithSeatsLabel = intl.formatMessage({
          id: 'EditListingPricingForm.multiplyWithSeatsLabel',
        });
        const bookingTypePlaceholder = intl.formatMessage({
          id: 'EditListingPricingForm.bookingTypePlaceholder',
        });
        const requireShowingLabel = intl.formatMessage({
          id: 'EditListingPricingForm.requireShowingLabel',
        });
        const requireShowingPlaceholder = intl.formatMessage({
          id: 'EditListingPricingForm.requireShowingPlaceholder',
        });

        const classes = classNames(css.root, className);
        const submitReady = (updated && pristine) || ready;
        const submitInProgress = updateInProgress;
        const submitDisabled = invalid || disabled || submitInProgress;
        const { updateListingError, showListingsError } = fetchErrors || {};

        const addonsList = config.custom.addonsList;

        return (
          <Form onSubmit={handleSubmit} className={classes}>
            {updateListingError ? (
              <p className={css.error}>
                <FormattedMessage id="EditListingPricingForm.updateFailed" />
              </p>
            ) : null}
            {showListingsError ? (
              <p className={css.error}>
                <FormattedMessage id="EditListingPricingForm.showListingFailed" />
              </p>
            ) : null}
            <FieldSelect
              name="bookingType"
              id="bookingType"
              label={bookingTypeLabel}
              validate={validators.required(bookingTypeRequiredMessage)}
            >
              <option disabled value="">
                {bookingTypePlaceholder}
              </option>
              {config.custom.bookingTypes.map((b) => (
                <option key={b.key} value={b.key}>
                  {intl.formatMessage({ id: b.labelId })}
                </option>
              ))}
            </FieldSelect>
            {bookingType && bookingType === 'monthly' && (
              <FieldBoolean
                name="requireShowing"
                id="requireShowing"
                label={requireShowingLabel}
                placeholder={requireShowingPlaceholder}
              />
            )}
            {bookingType && (
              <>
                <FieldCurrencyInput
                  id="price"
                  name="price"
                  className={css.priceInput}
                  label={pricePerUnitMessage}
                  placeholder={pricePlaceholderMessage}
                  currencyConfig={config.currencyConfig}
                  validate={priceValidators}
                />
                <FieldSelect
                  id={`vatPercentage`}
                  name={`vatPercentage`}
                  label={vatLabel}
                  validate={validators.required(vatRequiredMessage)}
                >
                  <option disabled value="">
                    {vatPlaceholder}
                  </option>
                  {config.custom.VAT_VALUES.map((opt) => (
                    <option value={opt.key} key={`vat_${opt.key}`}>
                      {opt.label}
                    </option>
                  ))}
                </FieldSelect>
              </>
            )}
            <p>
              <FormattedMessage id="EditListingPricingForm.discountOfferHeading" />
            </p>
            <FieldNumberInput
              id="discountOffer"
              name="discountOffer"
              className={css.priceInput}
              label={discountOfferMessage}
              // placeholder={pricePlaceholderMessage}
              // currencyConfig={config.currencyConfig}
              // validate={priceValidators}
            />

            <div className={css.additionalServices} id="additionalServicesSection">
              <label htmlFor="additionalServicesSection">{additionalServiceLabel}</label>
              <FormattedMessage id="EditListingPricingForm.additionalServicesDescription" />
              <FieldArray
                name="additionalServices"
                render={({ fields }) => {
                  return (
                    <div>
                      {fields.map((name, index) => (
                        <section className={css.service}>
                          <div className={css.field}>
                            <FieldSelect
                              id={`${name}.name`}
                              name={`${name}.name`}
                              label={serviceNameLabel}
                              placeholder={serviceNamePlaceholder}
                            >
                              <option value="" selected>
                                {serviceNamePlaceholder}
                              </option>
                              {addonsList.map((addon) => (
                                <option value={addon.id}>
                                  {intl.formatMessage({ id: addon.labelId })}
                                </option>
                              ))}
                            </FieldSelect>
                          </div>
                          <div className={css.field}>
                            <FieldTextInput
                              id={`${name}.description`}
                              name={`${name}.description`}
                              label={serviceDescriptionLabel}
                              placeholder={serviceDescriptionPlaceholder}
                              validate={validators.maxLength(serviceDescriptionMaximumMessage, 50)}
                            />
                          </div>
                          <div className={css.field}>
                            <FieldCurrencyInput
                              id={`${name}.price`}
                              name={`${name}.price`}
                              className={css.priceInput}
                              label={servicePriceLabel}
                              placeholder={pricePlaceholderMessage}
                              currencyConfig={config.currencyConfig}
                              validate={priceValidators}
                            />
                          </div>
                          <div className={css.field}>
                            <FieldSelect
                              id={`${name}.vatPercentage`}
                              name={`${name}.vatPercentage`}
                              label={vatLabel}
                              validate={validators.required(vatRequiredMessage)}
                            >
                              <option disabled value="">
                                {vatPlaceholder}
                              </option>
                              {config.custom.VAT_VALUES.map((opt) => (
                                <option value={opt.key} key={`${name}.vat_${opt.key}`}>
                                  {opt.label}
                                </option>
                              ))}
                            </FieldSelect>
                          </div>
                          <div className={css.field}>
                            <FieldCheckbox
                              name={`${name}.multiplySeats`}
                              id={`${name}.multiplySeats`}
                              label={multiplyWithSeatsLabel}
                            />
                          </div>
                          <div className={css.removeWrapper}>
                            <div className={css.removeService} onClick={() => fields.remove(index)}>
                              {removeServiceLabel}
                            </div>
                          </div>
                        </section>
                      ))}
                      {fields.length < config.maximumAdditionalServices && (
                        <div className={css.addService} onClick={() => fields.push(undefined)}>
                          {addServiceLabel}
                        </div>
                      )}
                    </div>
                  );
                }}
              />
            </div>

            <Button
              className={css.submitButton}
              type="submit"
              inProgress={submitInProgress}
              disabled={submitDisabled}
              ready={submitReady}
            >
              {saveActionMsg}
            </Button>
          </Form>
        );
      }}
    />
  );
};

EditListingPricingFormComponent.defaultProps = { fetchErrors: null };

EditListingPricingFormComponent.propTypes = {
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
};

export default compose(injectIntl)(EditListingPricingFormComponent);
