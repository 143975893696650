import moment from 'moment';

export const formatSubscriptionStatus = (subscriptionStatus, intl) => {
  switch (subscriptionStatus) {
    case 'active':
      return intl.formatMessage({ id: 'MySubscriptionTab.status.active' });
    case 'cancelled':
      return intl.formatMessage({ id: 'MySubscriptionTab.status.cancelled' });
    case 'paused':
      return intl.formatMessage({ id: 'MySubscriptionTab.status.paused' });
    default:
      return '';
  }
};

export const cancelReasons = (intl) => {
  return [
    {
      value: 'Too expensive',
      label: intl.formatMessage({ id: 'CancelReason.reason1' }),
    },
    {
      value: 'Not enough space',
      label: intl.formatMessage({ id: 'CancelReason.reason2' }),
    },
    {
      value: `Haven't use it enough`,
      label: intl.formatMessage({ id: 'CancelReason.reason3' }),
    },
    {
      value: 'I signed up for coworking',
      label: intl.formatMessage({ id: 'CancelReason.reason4' }),
    },
    {
      value: 'Will work from home',
      label: intl.formatMessage({ id: 'CancelReason.reason5' }),
    },
    {
      value: 'I signed up for a fixed office',
      label: intl.formatMessage({ id: 'CancelReason.reason6' }),
    },
    {
      value: 'No space that suits me',
      label: intl.formatMessage({ id: 'CancelReason.reason7' }),
    },
    {
      value: 'Other',
      label: intl.formatMessage({ id: 'CancelReason.reason8' }),
    },
  ];
};

/**
 * Convert week number to resume date for using in pause subscription feature
 *
 * @param {String} week number to pause subscription
 *
 * @returns {String} ISO timestamp.
 */
export const convertWeekToResumeDate = (week) => {
  const weekInt = parseInt(week);
  const today = new Date();
  const resumeDate = moment(today).add(weekInt, 'weeks');
  return Math.floor(new Date(resumeDate).getTime() / 1000);
};
