import React from 'react';

import css from './LocationIcon.module.scss';

const LocationIcon = () => (
  <svg
    className={css.icon}
    width="21"
    height="20"
    viewBox="0 0 21 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18 8.36364C18 14.0909 10.5 19 10.5 19C10.5 19 3 14.0909 3 8.36364C3 6.41068 3.79018 4.53771 5.1967 3.15676C6.60322 1.77581 8.51088 1 10.5 1C12.4891 1 14.3968 1.77581 15.8033 3.15676C17.2098 4.53771 18 6.41068 18 8.36364Z"
      stroke="black"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.5 11C11.8807 11 13 9.88071 13 8.5C13 7.11929 11.8807 6 10.5 6C9.11929 6 8 7.11929 8 8.5C8 9.88071 9.11929 11 10.5 11Z"
      stroke="black"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default LocationIcon;
