import React from 'react';
import { bool, func, number, object, string } from 'prop-types';
import classNames from 'classnames';
import debounce from 'lodash/debounce';
import { Field, Form as FinalForm, FormSpy } from 'react-final-form';
import { injectIntl, intlShape } from '../../util/reactIntl';
import { maximumValue } from '../../util/validators';
import { ComboBoxClearButton } from '../../forms/ListingSearchForm/ui';

import { Form } from '../../components';
import css from './PriceFilterForm.module.scss';

const DEBOUNCE_WAIT_TIME = 400;

const PriceFilterFormComponent = (props) => {
  const { liveEdit, onChange, onSubmit, onCancel, onClear, ...rest } = props;
  if (liveEdit && !onChange) {
    throw new Error('PriceFilterForm: if liveEdit is true you need to provide onChange function');
  }

  if (!liveEdit && !(onCancel && onClear && onSubmit)) {
    throw new Error(
      'PriceFilterForm: if liveEdit is false you need to provide onCancel, onClear, and onSubmit functions'
    );
  }

  const handleChange = debounce(
    (formState) => {
      if (formState.dirty) {
        const { minPrice, maxPrice, ...restValues } = formState.values;
        onChange({
          minPrice: minPrice === '' ? rest.min : minPrice,
          maxPrice: maxPrice === '' ? rest.max : maxPrice,
          ...restValues,
        });
      }
    },
    DEBOUNCE_WAIT_TIME,
    { leading: false, trailing: true }
  );

  const handleClear = () => {
    onClear();
  };

  const handleSubmit = (values) => {
    const { minPrice, maxPrice, ...restValues } = values;
    return onSubmit({
      minPrice: minPrice === '' ? rest.min : minPrice,
      maxPrice: maxPrice === '' ? rest.max : maxPrice,
      ...restValues,
    });
  };

  const formCallbacks = liveEdit
    ? { onSubmit: () => null }
    : { onSubmit: handleSubmit, onCancel, onClear };
  return (
    <FinalForm
      {...rest}
      {...formCallbacks}
      render={(formRenderProps) => {
        const {
          handleSubmit,
          id,
          showAsPopup,
          intl,
          max,
          hideLabel,
          closeRelevantFilter,
          category,
        } = formRenderProps;
        const placeholder = !!category
          ? ''
          : intl.formatMessage({ id: 'PriceFilterForm.maxPricePlaceholder' });

        const classes = classNames(css.root, {
          [css.popup]: showAsPopup,
          [css.plain]: !showAsPopup,
        });

        return (
          <Form className={classes} onSubmit={handleSubmit} tabIndex="0">
            <div className={css.contentWrapper}>
              {hideLabel && (
                <span className={css.label}>
                  {intl.formatMessage({ id: 'PriceFilterForm.label' })}
                </span>
              )}
              <div className={css.inputsWrapper}>
                <Field
                  className={css.maxPrice}
                  id={`${id}.maxPrice`}
                  name="maxPrice"
                  component="input"
                  type="number"
                  placeholder={placeholder}
                  max={max}
                  validate={maximumValue(
                    intl.formatMessage(
                      { id: 'EditListingWorkspaceForm.maxSeatsMaximumError' },
                      { num: max }
                    ),
                    9999999
                  )}
                />
              </div>
            </div>

            {hideLabel && (
              <ComboBoxClearButton
                onClick={() => {
                  handleClear();
                  closeRelevantFilter();
                }}
                className={css.clearBtnWrapper}
                onClose={() => closeRelevantFilter()}
                isRelevantFilter={true}
              />
            )}
            {liveEdit && (
              <FormSpy onChange={handleChange} subscription={{ values: true, dirty: true }} />
            )}
          </Form>
        );
      }}
    />
  );
};

PriceFilterFormComponent.defaultProps = {
  liveEdit: false,
  showAsPopup: false,
  style: null,
  min: 0,
  step: 1,
  onCancel: null,
  onChange: null,
  onClear: null,
  onSubmit: null,
  hideLabel: false,
};

PriceFilterFormComponent.propTypes = {
  id: string.isRequired,
  liveEdit: bool,
  showAsPopup: bool,
  onCancel: func,
  onChange: func,
  onClear: func,
  onSubmit: func,
  style: object,
  min: number.isRequired,
  max: number.isRequired,
  step: number,
  hideLabel: bool,
  closeRelevantFilter: func,

  // form injectIntl
  intl: intlShape.isRequired,
};

const PriceFilterForm = injectIntl(PriceFilterFormComponent);

export default PriceFilterForm;
