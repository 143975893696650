import React, { memo, useMemo } from 'react';
import classNames from 'classnames';
import { useIntl } from 'react-intl';
import { bool, object, string } from 'prop-types';

import { createSlug } from '../../../../util/urlHelpers';
import { NamedLink } from '../../../../components';

import css from './BookingCard.module.scss';

const BookingCardTitle = memo(
  ({ locationId, locationName, listingId, listingName, searchParams, isNotSchedule }) => {
    const intl = useIntl();
    const isLocation = !!locationId;

    const commonProps = useMemo(
      () => ({
        to: { search: searchParams.toString() },
        target: '_blank',
        rel: 'noopener noreferrer',
        className: classNames(css.listingName, css.aboveOverlayForClick),
      }),
      [searchParams]
    );

    const linkProps = useMemo(
      () =>
        !!isLocation
          ? {
              name: 'LocationPage',
              params: { id: locationId, slug: createSlug(locationName) },
            }
          : {
              name: 'ListingPage',
              params: { id: listingId, slug: createSlug(listingName) },
            },
      [isLocation, locationId, listingId, locationName, listingName]
    );

    return isNotSchedule ? (
      <NamedLink {...commonProps} {...linkProps}>
        {isLocation ? locationName : listingName}
      </NamedLink>
    ) : (
      <div className={css.listingName}>
        {intl.formatMessage({ id: `TeamSchedule.${listingName}` })}
      </div>
    );
  }
);

BookingCardTitle.propTypes = {
  locationId: string,
  locationName: string,
  listingId: string,
  listingName: string,
  searchParams: object,
  isNotSchedule: bool,
};

export default BookingCardTitle;
