import { useState, useRef, useEffect, useCallback } from 'react';

export const usePrevious = (value) => {
  const ref = useRef();

  useEffect(() => {
    ref.current = value;
  }, [value]);

  return ref.current;
};

export const useModal = () => {
  const [modalOpen, setModalOpen] = useState(false);

  const openModal = () => setModalOpen(true);
  const closeModal = () => setModalOpen(false);

  return {
    modalOpen,
    openModal,
    closeModal,
  };
};

export const useToggleCompoment = (handlingEventCallback) => {
  const componentRef = useRef();
  const parentComponentRef = useRef();

  useEffect(() => {
    if (parentComponentRef.current) {
      parentComponentRef.current.addEventListener('click', handleCloseComponent);
    }
    return () => {
      parentComponentRef.current.removeEventListener('click', handleCloseComponent);
    };
  }, []);

  const handleCloseComponent = (e) => {
    if (componentRef.current && !componentRef.current.contains(e.target)) {
      handlingEventCallback();
    }
  };

  return {
    componentRef,
    parentComponentRef,
  };
};

export const useBoolean = (initialState = false) => {
  const [value, setValue] = useState(initialState);
  const on = useCallback(() => {
    setValue(true);
  }, []);
  const off = useCallback(() => {
    setValue(false);
  }, []);
  const toggle = useCallback(() => {
    setValue((prev) => !prev);
  }, []);
  return [value, { on, off, toggle }];
};

export const useViewport = () => {
  const [viewport, setViewport] = useState({ width: 0, height: 0 });

  const handleWindowResize = () => {
    setViewport({
      width: window.innerWidth,
      height: window.innerHeight,
    });
  };
  useEffect(() => {
    if (window) {
      handleWindowResize();
      window.addEventListener('resize', handleWindowResize);
      window.addEventListener('orientationchange', handleWindowResize);
    }

    return () => {
      window.removeEventListener('resize', handleWindowResize);
      window.removeEventListener('orientationchange', handleWindowResize);
    };
  }, []);

  return viewport;
};
