import classNames from 'classnames';
import React, { useState, useEffect } from 'react';
import { OutsideClickHandler } from '../../components';
import { defaultLocationSearches } from '../../default-location-searches';
import { updateFilters } from '../../ducks/SearchPageNew.duck';
import { useDispatch } from 'react-redux';

import css from './AreaFilter.module.scss';

const AreaFilter = ({
  intl,
  areaFilterOpen,
  setAreaFilterOpen,
  areas,
  selectedArea,
  setSelectedArea,
}) => {
  const [parentCity, setParentCity] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    if (areas.length > 0) {
      const parentCity = defaultLocationSearches.find((city) => {
        return city.predictionPlace.address === areas[0].parent;
      });
      setParentCity(parentCity);
    }
  }, [areas]);

  const updateArea = (area) => {
    const newArea = {
      address: area.parent,
      bounds: area.predictionPlace.bounds,
      origin: area.predictionPlace.origin,
    };

    dispatch(updateFilters(newArea));
    setSelectedArea(area);
    setAreaFilterOpen(false);
  };

  return (
    <OutsideClickHandler
      onOutsideClick={() => {
        setAreaFilterOpen(false);
      }}
    >
      <div
        className={classNames(
          css.areaFilter,
          !!selectedArea && css.active,
          areaFilterOpen && css.wasactive
        )}
      >
        <div
          className={css.content}
          onClick={(e) => {
            e.preventDefault();
            setAreaFilterOpen(!areaFilterOpen);
          }}
        >
          <span>
            {selectedArea
              ? selectedArea.predictionPlace.address
              : intl.formatMessage({ id: 'AreaFilter.placeholder' })}
          </span>
        </div>
        {areaFilterOpen && (
          <div className={css.filterOptions}>
            {areas.map((area) => {
              return (
                <div
                  className={classNames(css.cityArea, area.id === selectedArea?.id && css.active)}
                  key={area.id}
                  onClick={() => {
                    updateArea(area);
                  }}
                >
                  {area.predictionPlace.address}
                </div>
              );
            })}
            {selectedArea && (
              <div className={css.buttonsWrapper}>
                <div
                  className={classNames(css.cityArea, css.clear)}
                  onClick={() => {
                    dispatch(
                      updateFilters({
                        address: parentCity?.predictionPlace?.address,
                        bounds: parentCity?.predictionPlace?.bounds,
                        origin: parentCity?.predictionPlace?.origin,
                      })
                    );
                    setSelectedArea(null);
                    setAreaFilterOpen(false);
                  }}
                >
                  {intl.formatMessage({ id: 'AreaFilter.clearBtn' })}
                </div>
                <div
                  className={classNames(css.cityArea, css.done)}
                  type="button"
                  onClick={() => setAreaFilterOpen(false)}
                >
                  {intl.formatMessage({ id: 'ListingSearchForm.locationSearchInput.done' })}
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </OutsideClickHandler>
  );
};

AreaFilter.propTypes = {};

export default AreaFilter;
