import { bool, func, string } from 'prop-types';
import React, { useCallback } from 'react';
import { useParams, useHistory } from 'react-router';
import classNames from 'classnames';
import { Button, PrimaryButton } from '..';
import { routeConfiguration } from '../..';
import { createResourceLocatorString } from '../../util/routes';
import { TABS } from '../EditListingWizard/EditListingWizard';
import {
  AMENITIES,
  AVAILABILITY,
  EMAIL,
  LOCATION,
  OVERVIEW,
  PHOTOS,
  WORKSPACE,
} from '../EditListingWizard/EditListingWizardTab';
import css from './FixedBottomButtons.module.scss';

const pathParamsToPrevTab = (params, tab, marketplaceTabs) => {
  const prevTabIndex = marketplaceTabs.findIndex((s) => s === tab) - 1;
  const prevTab = prevTabIndex > 0 ? marketplaceTabs[prevTabIndex] : marketplaceTabs[0];
  return { ...params, tab: prevTab };
};

const FixedBottomButtons = (props) => {
  const {
    leftBtnText,
    leftBtnHandleClick,
    isLocationTab = false,
    rightBtnText,
    rightBtnHandleClick,
    rightBtnType,
    rightBtnReady,
    rightBtnInProgress,
    rightBtnDisabled,
  } = props;

  const params = useParams();
  const history = useHistory();
  const { tab } = params;
  const getTabNumber = useCallback(() => {
    switch (tab) {
      case WORKSPACE:
        return 1;
      case LOCATION:
        return 2;
      case AMENITIES:
        return 3;
      case AVAILABILITY:
        return 4;
      case EMAIL:
        return 5;
      case PHOTOS:
        return 6;
      case OVERVIEW:
        return 7;
      default:
        return 0;
    }
  }, [tab]);
  const progressBarWidth = {
    width: `calc((100% / 7) * ${getTabNumber(tab)})`,
  };

  const handleBack = () => {
    if (tab === WORKSPACE) return;
    const routes = routeConfiguration();
    const prevPathParams = pathParamsToPrevTab(params, tab, TABS);
    const uri = createResourceLocatorString('EditListingPage', routes, prevPathParams, {});
    history.push(uri);
  };
  const bottomButtonWrapperClasses = classNames(css.bottomButtonWrapper, {
    [css.locationTab]: isLocationTab,
  });

  return (
    <div className={bottomButtonWrapperClasses}>
      <div className={css.progressBarWrapper}>
        <div className={css.progressBar} style={progressBarWidth}></div>
      </div>
      <Button type="button" className={css.backButton} onClick={handleBack}>
        {leftBtnText}
      </Button>
      <PrimaryButton
        type={rightBtnType}
        className={css.submitButton}
        ready={rightBtnReady}
        disabled={rightBtnDisabled}
        inProgress={rightBtnInProgress}
        onClick={rightBtnHandleClick}
      >
        {rightBtnText}
      </PrimaryButton>
    </div>
  );
};

FixedBottomButtons.propTypes = {
  leftBtnText: string.isRequired,
  leftBtnHandleClick: func,
  isLocationTab: bool,
  rightBtnText: string.isRequired,
  rightBtnHandleClick: func,
  rightBtnReady: bool,
  rightBtnInProgress: bool,
  rightBtnDisabled: bool,
};

export default FixedBottomButtons;
