import React, { memo, useMemo } from 'react';
import { arrayOf, shape } from 'prop-types';
import { injectIntl } from 'react-intl';

import { Table } from '../../../../components_new';
import { isMobile } from '../../../../util/device';

import css from './UnassignedListingsTable.module.scss';

const UnassignedListingsTable = memo(({ data, onInitAction, intl }) => {
  const HEADERS = useMemo(
    () => [
      { id: 'name_with_img', label: 'UnassignedListingsTable.table.name', width: '30%' },
      { id: 'status', label: 'LocationDetails.table.status', width: '15%' },
      { id: 'category', label: 'UnassignedListingsTable.table.category', width: '15%' },
      { id: 'addToLocationAction', label: '', width: isMobile ? '50%' : '30%' },
      { id: 'action', label: '', width: '35%' },
    ],
    []
  );

  return (
    <section className={css.wrapper}>
      <header className={css.header}>
        <h5 className={css.title}>{intl.formatMessage({ id: 'UnassignedListingsTable.title' })}</h5>
        <p className={css.listingsCount}>
          {intl.formatMessage(
            { id: 'UnassignedListingsTable.listingsCount' },
            { num: data.length, bold: (text) => <span>{text}</span> }
          )}
        </p>
      </header>
      {!!data.length && (
        <Table
          tableId="unassigned_listings"
          headers={HEADERS}
          data={data}
          onInitAction={onInitAction}
        />
      )}
    </section>
  );
});

UnassignedListingsTable.defaultProps = {
  headers: [],
  data: [],
};

UnassignedListingsTable.propTypes = {
  headers: arrayOf(shape({})),
  data: arrayOf(shape({})),
};

export default injectIntl(UnassignedListingsTable);
