import { checkInvitation, acceptInvitation as acceptInvitationApi } from '../../util/api';
import { storableError } from '../../util/errors';

import { STATUS_REGISTERED, STATUS_DELETED } from '../../util/types';
import { updateHubspotPipeline } from '../../util/api';

// ================ Action types ================ //

export const CHECKING_PROCESS = 'app/InvitationPage/CHECKING_PROCESS';
export const CHECKING_PROCESS_SUCCESS = 'app/InvitationPage/CHECKING_PROCESS_SUCCESS';
export const CHECKING_PROCESS_ERROR = 'app/InvitationPage/CHECKING_PROCESS_ERROR';

export const FETCH_COMPANY_ACCOUNT_REQUEST = 'app/InvitationPage/FETCH_COMPANY_ACCOUNT_REQUEST';
export const FETCH_COMPANY_ACCOUNT_SUCCESS = 'app/InvitationPage/FETCH_COMPANY_ACCOUNT_SUCCESS';
export const FETCH_COMPANY_ACCOUNT_ERROR = 'app/InvitationPage/FETCH_COMPANY_ACCOUNT_ERROR';

export const ACCEPT_PROCESS_REQUEST = 'app/InvitationPage/ACCEPT_PROCESS_REQUEST';
export const ACCEPT_PROCESS_SUCCESS = 'app/InvitationPage/ACCEPT_PROCESS_SUCCESS';
export const ACCEPT_PROCESS_ERROR = 'app/InvitationPage/ACCEPT_PROCESS_ERROR';
// ================ Reducer ================ //
const initialState = {
  checkingInProgress: false,
  nextDestination: 'currentPage',
  error: false,
  fetchCompanyAccountInProgress: false,
  fetchCompanyAccountError: null,
  companyAccount: null,
  acceptInProgress: false,
  acceptError: null,
};

export default function invitationReducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case CHECKING_PROCESS:
      return {
        ...state,
        checkingInProgress: true,
        error: false,
      };
    case CHECKING_PROCESS_SUCCESS:
      return {
        ...state,
        checkingInProgress: false,
        nextDestination: payload.nextDestination,
        error: null,
      };
    case CHECKING_PROCESS_ERROR:
      return {
        ...state,
        checkingInProgress: false,
        nextDestination: 'currentPage',
        error: true,
      };
    case FETCH_COMPANY_ACCOUNT_REQUEST: {
      return {
        ...state,
        fetchCompanyAccountInProgress: true,
        fetchCompanyAccountError: null,
      };
    }
    case FETCH_COMPANY_ACCOUNT_SUCCESS: {
      return {
        ...state,
        fetchCompanyAccountInProgress: false,
        companyAccount: payload,
      };
    }
    case FETCH_COMPANY_ACCOUNT_ERROR: {
      return {
        ...state,
        fetchCompanyAccountInProgress: false,
        fetchCompanyAccountError: payload,
      };
    }
    case ACCEPT_PROCESS_REQUEST:
      return {
        ...state,
        acceptInProgress: true,
        acceptError: null,
      };
    case ACCEPT_PROCESS_SUCCESS:
      return {
        ...state,
        acceptInProgress: false,
        nextDestination: payload.nextDestination,
      };
    case ACCEPT_PROCESS_ERROR:
      return {
        ...state,
        acceptInProgress: false,
        acceptError: true,
      };
    default:
      return state;
  }
}

// ================ Action creators ================ //

const checkingProcess = () => ({
  type: CHECKING_PROCESS,
});

const checkingProcessSuccess = (response) => ({
  type: CHECKING_PROCESS_SUCCESS,
  payload: response,
});

const checkingProcessError = () => ({
  type: CHECKING_PROCESS_ERROR,
});

const fetchCompanyAccountRequest = () => ({
  type: FETCH_COMPANY_ACCOUNT_REQUEST,
});

const fetchCompanyAccountSuccess = (response) => ({
  type: FETCH_COMPANY_ACCOUNT_SUCCESS,
  payload: response,
});

const fetchCompanyAccountError = (error) => ({
  type: FETCH_COMPANY_ACCOUNT_ERROR,
  payload: error,
});

const acceptProcessRequest = () => ({
  type: ACCEPT_PROCESS_REQUEST,
});

const acceptProcessSuccess = (response) => ({
  type: ACCEPT_PROCESS_SUCCESS,
  payload: response,
});

const acceptProcessError = () => ({
  type: ACCEPT_PROCESS_ERROR,
});

export const checkingInvitation = (params) => async (dispatch, getState, sdk) => {
  dispatch(checkingProcess());
  try {
    const response = await checkInvitation(params);

    dispatch(checkingProcessSuccess(response));
  } catch (e) {
    dispatch(checkingProcessError());
  }
};

export const acceptInvitation = (params) => async (dispatch, getState, sdk) => {
  dispatch(acceptProcessRequest());
  try {
    const { teamMemberData } = params;
    const response = await acceptInvitationApi(params);
    const teamMembers = Object.values(teamMemberData);
    const totalInvitedMembers = teamMembers.filter((member) => member.status !== STATUS_DELETED);
    const totalRegisteredMembers = teamMembers.filter(
      (member) => member.status === STATUS_REGISTERED
    );
    const hubspotParams = {
      user: response.user,
      invitedUsers: totalInvitedMembers.length,
      activeUsers: totalRegisteredMembers.length + 1,
    };
    updateHubspotPipeline(hubspotParams);
    dispatch(acceptProcessSuccess(response));
  } catch (error) {
    dispatch(acceptProcessError());
  }
};

export const fetchCompanyAccount = (params) => (dispatch, getState, sdk) => {
  dispatch(fetchCompanyAccountRequest());
  const { companyId } = params;
  return sdk.users
    .show({
      id: companyId,
    })
    .then((res) => {
      dispatch(fetchCompanyAccountSuccess(res.data.data));
    })
    .catch((error) => {
      dispatch(fetchCompanyAccountError(storableError(error)));
    });
};
