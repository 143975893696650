/**
 * Creates a sortable image grid with children added to the end of the created grid.
 *
 * Example:
 * // images = [{ id: 'tempId', imageId: 'realIdFromAPI', file: File }];
 * <AddImages images={images}>
 *   <input type="file" accept="images/*" onChange={handleChange} />
 * </AddImages>
 */
import React, { useState } from 'react';
import PropTypes, { bool } from 'prop-types';
import classNames from 'classnames';
import { ImageFromFile, ResponsiveImage, IconSpinner } from '../../components';

import css from './AddImages.module.scss';
import RemoveImageButton from './RemoveImageButton';
import EditImageButton from './EditImageButton';
import { useIntl } from 'react-intl';

const ThumbnailWrapper = (props) => {
  const {
    className,
    image,
    savedImageAltText,
    onRemoveImage,
    isCoverPhoto,
    imageInputRef,
    getImageId,
    images,
    imageUploadRequested,
    index,
    newEditImageId,
  } = props;
  const intl = useIntl();
  const handleRemoveClick = (e) => {
    e.stopPropagation();
    onRemoveImage(image.id);
  };
  const handleEditImage = (e) => {
    e.stopPropagation();
    getImageId(image.id);
    imageInputRef.current.click();
  };

  if (image.file) {
    // Add remove button only when the image has been uploaded and can be removed
    const removeButton = image.imageId ? <RemoveImageButton onClick={handleRemoveClick} /> : null;
    const editButton = image.imageId ? <EditImageButton onClick={handleEditImage} /> : null;
    // While image is uploading we show overlay on top of thumbnail
    const uploadingOverlay =
      (imageUploadRequested && images[index].id === newEditImageId) || !image.imageId ? (
        <div className={css.thumbnailLoading}>
          <IconSpinner />
        </div>
      ) : null;
    const thumbnailClasses = classNames(className, {
      [css.coverPhotoThumbnail]: isCoverPhoto,
    });
    return (
      <ImageFromFile
        id={image.id}
        className={thumbnailClasses}
        rootClassName={css.thumbnail}
        file={image.file}
      >
        {isCoverPhoto && (
          <div className={css.coverPhoto}>{intl.formatMessage({ id: 'AddImages.coverPhoto' })}</div>
        )}
        {removeButton}
        {editButton}
        {uploadingOverlay}
      </ImageFromFile>
    );
  } else {
    const classes = classNames(css.thumbnail, className, {
      [css.coverPhotoThumbnail]: isCoverPhoto,
    });
    // While image is uploading we show overlay on top of thumbnail
    const uploadingOverlay =
      imageUploadRequested && images[index].id === newEditImageId ? (
        <div className={css.thumbnailLoading}>
          <IconSpinner />
        </div>
      ) : null;
    return (
      <div className={classes}>
        <div className={css.threeToTwoWrapper}>
          {isCoverPhoto && (
            <div className={css.coverPhoto}>
              {intl.formatMessage({ id: 'AddImages.coverPhoto' })}
            </div>
          )}
          <ResponsiveImage
            rootClassName={css.rootForImage}
            image={image}
            alt={savedImageAltText}
            variants={['landscape-crop', 'landscape-crop2x']}
          />
          <RemoveImageButton onClick={handleRemoveClick} />
          <EditImageButton onClick={handleEditImage} />
          {uploadingOverlay}
        </div>
      </div>
    );
  }
};

ThumbnailWrapper.defaultProps = { className: null };

const { array, func, node, string, object } = PropTypes;

ThumbnailWrapper.propTypes = {
  className: string,
  image: object.isRequired,
  savedImageAltText: string.isRequired,
  onRemoveImage: func.isRequired,
  isCoverPhoto: bool,
};

const AddImages = (props) => {
  const {
    children,
    className,
    thumbnailClassName,
    images,
    savedImageAltText,
    onRemoveImage,
    imageInputRef,
    getImageId,
    imageUploadRequested,
    newEditImageId,
  } = props;
  const classes = classNames(css.root, className);
  return (
    <div className={classes}>
      {images.map((image, index) => {
        return (
          <ThumbnailWrapper
            image={image}
            index={index}
            key={image.id.uuid || image.id}
            className={thumbnailClassName}
            savedImageAltText={savedImageAltText}
            onRemoveImage={onRemoveImage}
            isCoverPhoto={index === 0}
            imageInputRef={imageInputRef}
            getImageId={getImageId}
            images={images}
            imageUploadRequested={imageUploadRequested}
            newEditImageId={newEditImageId}
          />
        );
      })}
      {children}
    </div>
  );
};

AddImages.defaultProps = { className: null, thumbnailClassName: null, images: [] };

AddImages.propTypes = {
  images: array,
  children: node.isRequired,
  className: string,
  thumbnailClassName: string,
  savedImageAltText: string.isRequired,
  onRemoveImage: func.isRequired,
};

export default AddImages;
