import React, { useState } from 'react';
import { Field } from 'react-final-form';
import classNames from 'classnames';
import css from './FieldMonthInput.module.scss';
import MonthInput from './MonthInput';
import moment from 'moment';
import { OutsideClickHandler } from '..';
import ValidationError from '../ValidationError/ValidationError';

// function daysInMonth(month, year) {
//   return new Date(year, month, 0).getDate();
// }

// const isFullMonth = (month, year, length) => {
//   return daysInMonth(month, year) === length;
// };

const FieldMonthInputComponent = ({
  rootClassName,
  className,
  input,
  meta,
  id,
  label,
  timeSlots = [],
  onChange: customChange,
  searchFormDate,
  ...rest
}) => {
  const { onFocus, onBlur, value, onChange } = input;
  const [focus, setFocus] = useState(false);

  const handleFocus = () => {
    onFocus();
    setFocus(true);
  };

  const handleBlur = () => {
    onBlur();
    setFocus(false);
  };

  const handleOnChange = (value) => {
    onChange(value);
    if (typeof customChange === 'function') customChange(value);
    handleBlur();
  };

  const classes = classNames(rootClassName || css.root, className);
  const { start } = value;
  const monthInputPropsMaybe = start
    ? {
        selectedMonth: moment(start).format('MMM'),
        selectedYear: parseInt(moment(start).format('YYYY'), 10),
      }
    : {};
  const formattedValue = (start && moment(start).format('MMM YYYY')) || '';
  return (
    <div className={classes}>
      {label && <label htmlFor={id}>{label}</label>}
      <input id={id} {...rest} readOnly onFocus={handleFocus} value={formattedValue} />
      <ValidationError fieldMeta={meta} />
      {focus && (
        <OutsideClickHandler onOutsideClick={handleBlur}>
          <div className={css.monthInput}>
            <MonthInput
              onChange={handleOnChange}
              {...monthInputPropsMaybe}
              monthSlots={timeSlots}
              searchFormDate={searchFormDate}
            />
          </div>
        </OutsideClickHandler>
      )}
    </div>
  );
};

const FieldMonthInput = (props) => {
  return <Field component={FieldMonthInputComponent} {...props} />;
};

export default FieldMonthInput;
