import chunk from 'lodash/chunk';
import { HOURLY_BOOKING } from '../../util/types';
import { defaultTimeZone } from '../../util/dates';

export const deleteExceptions = (exceptions = [], onDeleteAvailabilityException) => {
  const pages = chunk(exceptions, 4);

  const deleteFunc = async () => {
    while (pages.length > 0) {
      const currentPage = pages.pop();
      try {
        return await Promise.all(
          currentPage.map((exp) => onDeleteAvailabilityException({ id: exp.id }))
        ).then(() => deleteFunc());
      } catch (error) {
        return Promise.resolve();
      }
    }
  };

  return deleteFunc();
};

export const createDefaultAvailabilityPlan = (bookingType) => {
  if (bookingType === HOURLY_BOOKING)
    return {
      type: 'availability-plan/time',
      timezone: defaultTimeZone(),
      entries: [],
    };
  return {
    type: 'availability-plan/day',
    entries: [],
  };
};
