import { FormattedMessage } from 'react-intl';
import { ContentWithBtnLink } from '../../../components_new';
import React from 'react';
import css from '../Aboutus/Aboutus.module.scss';

export const Press = () => {
  return (
    <section className={css.section}>
      <ContentWithBtnLink
        header={<FormattedMessage id="About.press.title" />}
        description={<FormattedMessage id="About.press.description" />}
        linkPage="ContactUs"
        btnText={<FormattedMessage id="About.press.btnText" />}
      />
    </section>
  );
};
