import classNames from 'classnames';
import React from 'react';
import { Form as FinalForm } from 'react-final-form';
import { FormattedMessage } from 'react-intl';
import {
  FieldNumberInput,
  FieldPhoneNumberInput,
  FieldTextInput,
  Form,
  IconSpinner,
  NamedLink,
  PrimaryButton,
} from '../../components';
import * as validators from '../../util/validators';
import css from './FixedOfficeContactForm.module.scss';

const FixedOfficeContactForm = ({ intl, isOwnListing, onBookShowingSubmit, currentUser }) => {
  const { firstName, lastName } = currentUser?.attributes.profile || {};
  const { email } = currentUser?.attributes || {};
  const initialVals = { fname: firstName && lastName && `${firstName} ${lastName}`, email };
  return (
    <FinalForm
      intl={intl}
      onSubmit={(values) => {
        onBookShowingSubmit(values);
      }}
      initialValues={initialVals}
      render={(formRenderProps) => {
        const {
          handleSubmit,
          fetchLineItemsInProgress,
          fetchLineItemsError,
          rootClassName,
          className,
          intl,
          invalid,
          disabled,
          updated,
          pristine,
          ready,
          updateInProgress,
          values,
        } = formRenderProps;

        // firstName
        const fullNamePlaceholder = intl.formatMessage({
          id: 'FixedOfficeContactForm.fullNamePlaceholder',
        });
        const fullNameRequiredMessage = intl.formatMessage({
          id: 'FixedOfficeContactForm.fullNameRequired',
        });
        const fullNameRequired = validators.required(fullNameRequiredMessage);

        // email
        const emailPlaceholder = intl.formatMessage({
          id: 'FixedOfficeContactForm.emailPlaceholder',
        });
        const emailRequiredMessage = intl.formatMessage({
          id: 'FixedOfficeContactForm.emailRequired',
        });
        const emailRequired = validators.required(emailRequiredMessage);
        const emailInvalidMessage = intl.formatMessage({
          id: 'FixedOfficeContactForm.emailInvalid',
        });
        const emailValid = validators.emailFormatValid(emailInvalidMessage);

        // company name
        const companyNamePlaceholder = intl.formatMessage({
          id: 'FixedOfficeContactForm.companyNamePlaceholder',
        });
        const companyNameRequiredMessage = intl.formatMessage({
          id: 'FixedOfficeContactForm.companyNameRequired',
        });
        const companyNameRequired = validators.required(companyNameRequiredMessage);

        // Phone number
        const phoneNumberPlaceholder = intl.formatMessage({
          id: 'FixedOfficeContactForm.phoneNumberPlaceholder',
        });
        const phoneNumberInvalidMessage = intl.formatMessage({
          id: 'FixedOfficeContactForm.phoneNumberInvalid',
        });
        const phoneNumberValid = validators.phoneFormatValid(phoneNumberInvalidMessage);

        // Num of seats
        const numberOfSeatsPlaceholder = intl.formatMessage({
          id: 'FixedOfficeContactForm.numberOfSeatsPlaceholder',
        });

        // budget
        const budgetPlaceholder = intl.formatMessage({
          id: 'FixedOfficeContactForm.budgetPlaceholder',
        });

        const loadingSpinnerMaybe = fetchLineItemsInProgress ? (
          <IconSpinner className={css.spinner} />
        ) : null;

        const bookingInfoErrorMaybe = fetchLineItemsError ? (
          <span className={css.sideBarError}>
            <FormattedMessage id="BookingDatesForm.fetchLineItemsError" />
          </span>
        ) : null;

        const classes = classNames(rootClassName || css.root, className);

        const submitReady = (updated && pristine) || ready;
        const submitInProgress = updateInProgress;
        const submitDisabled = invalid || disabled || submitInProgress || isOwnListing;

        return (
          <Form className={classes} id="showingForm" onSubmit={handleSubmit}>
            <h2 className={css.bookingHeading}>
              {intl.formatMessage({ id: 'FixedOfficeContactForm.heading' })}
            </h2>
            <p className={css.bookingSubHeading}>
              {intl.formatMessage({ id: 'FixedOfficeContactForm.subHeading' })}
            </p>
            <div className={css.customerDetailsContainer}>
              <FieldTextInput
                className={css.fullNameRoot}
                type="text"
                name="fname"
                placeholder={fullNamePlaceholder}
                validate={fullNameRequired}
                inputRootClass={css.inputRootClass}
              />
              <FieldTextInput
                className={css.companyName}
                type="text"
                name="companyName"
                validate={companyNameRequired}
                placeholder={companyNamePlaceholder}
                inputRootClass={css.inputRootClass}
              />
              <FieldTextInput
                className={css.emailAddress}
                type="email"
                name="email"
                placeholder={emailPlaceholder}
                inputRootClass={css.inputRootClass}
                validate={validators.composeValidators(emailRequired, emailValid)}
                labelClassName={css.label}
              />
              <FieldPhoneNumberInput
                className={css.phoneNumber}
                name="phonenumber"
                placeholder={phoneNumberPlaceholder}
                inputRootClass={css.inputRootClass}
                validate={values.phonenumber && phoneNumberValid}
                labelClassName={css.label}
              />
              <FieldNumberInput
                className={css.numberOfSeats}
                type="text"
                name="numberOfSeats"
                placeholder={numberOfSeatsPlaceholder}
                inputRootClass={css.seatsInput}
              />
              <div className={css.budgetWrapper}>
                <FieldNumberInput
                  className={css.budget}
                  type="text"
                  name="budget"
                  id="budgetInput"
                  placeholder={budgetPlaceholder}
                  inputRootClass={css.budgetInput}
                />
                <label className={css.budgetLabel} htmlFor="budgetInput">
                  SEK
                </label>
              </div>
            </div>

            {loadingSpinnerMaybe}
            {bookingInfoErrorMaybe}
            {isOwnListing ? (
              <p className={css.smallPrint}>
                <FormattedMessage id="BookingDatesForm.ownListing" />
              </p>
            ) : null}
            <div className={css.submitButtonWrapper}>
              <PrimaryButton
                className={css.button}
                type="submit"
                inProgress={submitInProgress}
                disabled={submitDisabled}
                ready={submitReady}
              >
                <FormattedMessage id="FixedOfficeContactForm.button" />
              </PrimaryButton>
            </div>
            <span className={css.termsText}>
              {intl.formatMessage({ id: 'FixedOfficeContactForm.terms' })}
              <NamedLink name="TermsOfServicePage">
                {intl.formatMessage({ id: 'FixedOfficeContactForm.termsLink' })}
              </NamedLink>
            </span>
          </Form>
        );
      }}
    />
  );
};

export default FixedOfficeContactForm;
