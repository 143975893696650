import React, { memo } from 'react';

import { ExpandableText } from '../../components_new';

import css from './LocationPage.module.scss';

const SectionDescription = memo(({ text, intl }) => (
  <section className={css.section}>
    <h4 className={css.sectionTitle}>{intl.formatMessage({ id: 'LocationPage.aboutTitle' })}</h4>
    <ExpandableText text={text} />
  </section>
));

export default SectionDescription;
