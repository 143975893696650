import React from 'react';
import { func } from 'prop-types';
import { injectIntl } from 'react-intl';

import { intlShape } from '../../../../util/reactIntl';
import SearchButton from '../SearchButton/SearchButton';

import css from './MobileFormTrigger.module.scss';

const MobileFormTrigger = ({ onClick, intl, currentCategory, currentLocation, currentDates }) => {
  const loc = currentLocation && currentLocation.search ? currentLocation.search : '';
  const cat = currentCategory
    ? intl.formatMessage({ id: `EditListingOverviewPanel.category.${currentCategory}` })
    : '';
  // const date = currentDates && currentDates.startDate ? currentDates.startDate : '';
  const text = `${loc}${!!loc && !!cat ? ', ' : ''}${cat}`;

  return (
    <button onClick={onClick} className={css.trigger}>
      <span className={css.span}>
        {loc || cat ? text : intl.formatMessage({ id: 'ListingSearchForm.mobileTrigger' })}
      </span>
      <SearchButton />
    </button>
  );
};

MobileFormTrigger.propTypes = {
  onClick: func.isRequired,
  intl: intlShape.isRequired,
};

export default injectIntl(MobileFormTrigger);
