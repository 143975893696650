import React from 'react';
import { Form as FinalForm } from 'react-final-form';
import { useIntl } from 'react-intl';
import { FieldTextInput, Form, PrimaryButton } from '..';
import css from './EditListingOverviewPanel.module.scss';
import isEqual from 'lodash/isEqual';

const TranslatedTextForm = (props) => {
  const intl = useIntl();
  return (
    <FinalForm
      {...props}
      render={(fieldRenderProps) => {
        const { handleSubmit, initialValues, values } = fieldRenderProps;
        const disabled = isEqual(initialValues, values);
        return (
          <Form className={css.translatedFormContainer} onSubmit={handleSubmit}>
            <FieldTextInput
              id="translatedDescription"
              name="translatedDescription"
              type="textarea"
              className={css.translatedField}
            />
            <PrimaryButton type="submit" className={css.saveChangeBtn} disabled={disabled}>
              {intl.formatMessage({ id: 'TranslatedTextForm.saveBtn' })}
            </PrimaryButton>
          </Form>
        );
      }}
    />
  );
};

TranslatedTextForm.defaultProps = {};

TranslatedTextForm.propTypes = {};
export default TranslatedTextForm;
