import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { getLocale } from '../../util/localeHelper';
import { LANGUAGE_CODES } from '../../util/modernmt';
import { NamedLink } from '../../components';

import css from './PrivacyPolicy.module.scss';

const PrivacyPolicy = (props) => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);

  if (getLocale() === LANGUAGE_CODES.SWEDISH) {
    return (
      <div className={classes}>
        <p className={css.lastUpdated}>Senast uppdaterad: 16 september 2021</p>
        <h2>Varför och för vem?</h2>
        <p>
          På Respaces AB org.nr (559258-0160) ("Respaces", "vi", "oss", "vår") bryr vi oss om
          personlig integritet. Det betyder att vi respekterar och värnar om din integritet och
          rätten till kontroll och transparens vid behandling av dina Personuppgifter.
        </p>
        <p>
          Den här Integritetspolicyn ("Policyn") är tillämplig för de behandlingar som Respaces är
          Personuppgiftsansvarig för. Policyn beskriver övergripande för vilka ändamål vi behöver
          dina Personuppgifter, vilken rättslig grund vi stödjer oss på samt vilka åtgärder vi
          vidtar för att skydda personuppgifter. Vi informerar även om hur du gör för att utöva de
          rättigheter du har kopplat till vår behandling av dina Personuppgifter.
        </p>
        <p>
          Policyn informerar om vår hantering av Personuppgifter i de fall du kommunicerar med oss,
          använder Tjänsten eller besöker vår hemsida www.respaces.co (tillsammans "Funktioner").
        </p>
        <h2>Den här policyn riktar sig till:</h2>
        <ul>
          <li>- Användare av Tjänsten</li>
          <li>- Potentiella kunder </li>
          <li>- Kunder</li>
          <li>- Anställda hos potentiella kunder</li>
          <li>- Anställda hos befintliga kunder</li>
          <li>- Besökare på vår hemsida</li>
        </ul>
        <h2>Definitioner</h2>
        <p>
          <strong>"Behandling"</strong> av Personuppgifter är allt som kan göras med en
          Personuppgift, t.ex. lagring, ändring, läsning, överlämning osv.{' '}
        </p>
        <p>
          <strong>"Gällande rätt" </strong>är den lagstiftning som är tillämplig behandlingen av
          Personuppgifter inklusive Dataskyddsförordningen (GDPR), kompletterande nationell
          lagstiftning, samt praxis, vägledningar och rekommendationer utfärdade av en nationell
          eller europeisk tillsynsmyndighet.
        </p>
        <p>
          <strong>"Personuppgifter"</strong> är all slags information som går att koppla till en
          identifierbar, levande person.
        </p>
        <p>
          <strong>"Personuppgiftsansvarige"</strong> är det företag/organisation som bestämmer för
          vilka ändamål och på vilket sätt Personuppgifterna ska behandlas och därmed även ansvarar
          för att Personuppgifter behandlas enligt Gällande Rätt.
        </p>
        <p>
          <strong>"Personuppgiftsbiträde"</strong> är det företag/organisation som behandlar
          Personuppgifter för den Personuppgiftsansvariges räkning och får därmed endast Behandla
          Personuppgifterna enligt Personuppgiftsansvariges instruktioner samt gällande
          lagstiftning.
        </p>
        <p>
          <strong>"Registrerad"</strong> betyder den levande, fysiska person vars Personuppgifter
          behandlas.
        </p>
        <p>
          <strong>"Tjänsten"</strong> betyder förmedlingen av arbetsplatser mellan två parter.
        </p>
        <h2>Respaces personuppgiftsansvar</h2>
        <p>
          Informationen i denna Policy omfattar Behandling av Personuppgifter som Respaces är
          Personuppgiftsansvarig över, dvs. den Behandling för vilka vi bestämmer ändamålet med
          (varför en behandling görs) och medel för (på vilket sätt, vilka personuppgifter, hur
          länge osv.). Policyn beskriver inte hur vi behandlar personuppgifter i rollen som
          Personuppgiftsbiträde - alltså då vi behandlar personuppgifter på uppdrag av våra kunder.
        </p>
        <p>
          Respaces förmedlar uthyrning av arbetsplatser. Vi behöver därför behandla dina
          personuppgifter för att vi ska kunna skapa ett användarkonto och matcha dig med lediga
          tider efter dina behov. Vi delar även uppgifterna med de ägare av lokaler som du bokar
          hos.
        </p>
        <h2>Respacess behandling av personuppgifter</h2>
        <p>
          Vi har ett ansvar att beskriva och visa hur vi lever upp till de krav som ställs på oss
          när vi behandlar dina Personuppgifter. Det här avsnittet syftar till att ge dig en
          förståelde för vilka typer av personuppgifer vi behandlar om dig och för vilka ändamål.
        </p>
        <h2>Hur länge sparar vi dina Personuppgifter?</h2>
        <p>
          Vi sparar dina Personuppgifter så länge det är nödvändigt med hänsyn till det ändamål som
          de samlades in för. Beroende av vilken rättslig grund vi stödjer behandlingen på kan detta
          a) följa av ett avtal, b) vara beroende av ett giltigt samtycke, c) framgå av lagstiftning
          eller d) följa av en intern bedömning baserad på en intresseavvägning.
        </p>
        <p>
          Vi sparar aldrig dina Personuppgifter längre än nödvändigt och gallrar Personuppgifter
          regelbundet. Respaces vidtar även rimliga åtgärder för att hålla de Personuppgifter som
          behandlas aktuella och att radera inaktuella och på andra sätt felaktiga eller överflödiga
          Personuppgifter.
        </p>
        <h2>Behandlingar</h2>
        <p>
          Det huvudsakliga ändamålet med den personuppgiftsbehandling som vi utför är att
          tillhandahålla, utföra och förbättra våra tjänster gentemot dig. Det finns flera olika
          skäl till att vi kan behöva samla in, hantera och spara dina uppgifter.{' '}
        </p>
        <p>Vi behandlar huvudsakligen följande personuppgifter:</p>
        <ul>
          <li>
            - Kontakt- och identifikationsuppgifter för att bekräfta din identitet, verifiera dina
            uppgifter och kunna kommunicera med dig
          </li>
          <li>
            - Information om din användning av tjänsten eller produkten för att kunna förbättra din
            kundupplevelse
          </li>
          <li>- Konsumtionsmönster för att kunna tillgodose dig med specifika erbjudande</li>
          <li>
            - Betalningsinformation för att kunna erbjuda exempelvis autogiro och andra betalsätt
          </li>
        </ul>
        <h2>Hur får vi tillgång till dina personuppgifter?</h2>
        <p>
          Vi inhämtar dina personuppgifter på ett antal olika sätt. Vi får främst tillgång till dina
          personuppgifter:{' '}
        </p>
        <ul>
          <li>- Genom att du själv har lämnat dina personuppgifter till oss</li>
          <li>- Genom att din arbetsgivare har lämnat dina personuppgifter till oss</li>
          <li>- Genom information som har skapats från analys av data</li>
        </ul>

        <h2>Rättsliga grunder</h2>
        <p>
          För att vi ska få behandla dina personuppgifter krävs det att vi har s.k. laglig grund för
          respektive behandling. I vår verksamhet behandlar vi dina personuppgifter främst på
          följande grunder:{' '}
        </p>
        <p>
          <strong>Samtycke</strong> - Respaces behandlar dina Personuppgifter efter att vi har fått
          ditt samtycke till Behandling. Information om behandlingen lämnas alltid i samband med att
          vi frågar efter samtycket.
        </p>
        <p>
          <strong>Intresseavvägning</strong> - Respaces får behandla personuppgifter om vi bedömt
          att det finns ett berättigat intresse som väger tyngre än den Registrerades skydd för den
          personliga integriteten och om Behandlingen är nödvändig för det aktuella ändamålet.
        </p>
        <p>
          Om du vill ha ytterligare information kring vilken eller vilka rättslig(a) grund(er) som
          vi behandlar just dina personuppgifter för har du alltid rätt att begära ut ett s.k.
          registerutdrag. Läs mer under "Hur du använder dina rättigheter" nedan.
        </p>
        <h2>Dina rättigheter</h2>
        <p>
          Du är den som bestämmer över dina Personuppgifter. Vi strävar alltid efter att se till att
          du kan utöva dina rättigheter så effektivt och smidigt som möjligt.{' '}
        </p>
        <p>
          <strong>Tillgång - </strong>Du har alltid rätt att få information om de
          Personuppgiftsbehandlingar som rör dig i ett s.k. registerutdrag. Av registerutdraget
          framgår de bl.a. vilka av dina personuppgifter vi har lagrade samt för vilka ändamål och
          på vilken rättslig grund. Vi lämnar endast ut uppgifter om vi har kunnat säkerställa att
          det faktiskt är du som frågar efter uppgifterna.
        </p>
        <p>
          <strong>Rättelse - </strong>Upptäcker du att de Personuppgifter vi behandlar om dig inte
          stämmer, hör av dig till oss så fixar vi det!{' '}
        </p>
        <p>
          <strong>Radering - </strong>Vill du att vi glömmer dig helt? Du har rätt att begära
          radering av dina Personuppgifter när de inte längre är nödvändiga för det syfte de blev
          insamlade för. Om vi är skyldiga att behålla dina uppgifter enligt lag eller ett avtal som
          vi har ingått med dig kommer vi att säkerställa att de endast behandlas för det specifika
          ändamål som framgår av lagen eller avtalet. Därefter ser vi till att uppgifterna raderas
          så snart som möjligt.
        </p>
        <p>
          <strong>Invändning - </strong>Håller du inte med oss om att vårt intresse av att behandla
          dina Personuppgifter väger tyngre än ditt intresse av skydd för den personliga
          integriteten? Ingen fara - i så fall ser vi över vår intresseavvägning och kontrollerar
          att den fortfarande håller. Vi väger såklart in din invändning när vi gör en ny bedömning
          för att utvärdera om vi fortfarande kan motivera vår Behandling av dina Personuppgifter.
          Invänder du mot direktmarknadsföring kommer vi ta bort dina Personuppgifter på en gång
          utan att se över vår bedömning.
        </p>
        <p>
          <strong>Begränsning - </strong>Du kan även be oss att begränsa vår Behandling av dina
          uppgifter:
          <ul>
            <li>
              - Under tiden som vi hanterar en begäran från dig om någon av dina andra rättigheter.
            </li>
            <li>
              - Om du, istället för att begära radering, vill att vi markerar att uppgifterna inte
              ska behandlas för ett visst ändamål. Om du t.ex. inte vill att vi skickar reklam till
              dig i framtiden behöver vi fortfarande spara ditt namn för att veta att vi inte ska
              kontakta dig.
            </li>
            <li>
              - I de fall där vi inte längre behöver uppgifterna för det ändamål som de samlades in
              för, förutsatt att du inte har ett intresse av att vi behåller uppgifterna för att
              kunna göra gällande ett rättsligt anspråk.
            </li>
          </ul>
        </p>
        <p>
          <strong>Begränsning - </strong>Vi kan ge dig de uppgifter du själv lämnat till oss eller
          som vi har fått av dig i samband med att vi ingått ett avtal med dig. Du får dina
          uppgifter i ett allmänt använt och maskinläsbart format som du sedan kan ta med dig till
          en annan Personuppgiftsansvarig.
        </p>
        <p>
          <strong>Återkalla samtycke Återkalla samtycke - </strong>Om du har samtyckt till en eller
          flera specifika behandling(ar) av dina Personuppgifter har du närsomhelst rätt att
          återkalla ditt samtycke och därmed be oss att upphöra med Behandlingen omedelbart.
          Observera att du endast kan återkalla ditt samtycke för framtida Behandling(ar) av
          Personuppgifter och inte för någon Behandling som redan skett.
        </p>
        <h2>Hur du använder dina rättigheter</h2>
        <p>
          Kontakta oss på <a href="mailto:hello@respaces.co">hello@respaces.co</a> så hjälper vi
          dig.
        </p>
        <h2>Överföring av Personuppgifter</h2>
        <p>
          För att bedriva vår verksamhet kan vi behöva ta hjälp av andra som behandlar
          Personuppgifter för vår räkning, så kallade Personuppgiftsbiträden.
        </p>
        <p>
          I de fall där våra Personuppgiftsbiträden överför Personuppgifterna till ett land utanför
          EU/EES har vi säkerställt att Behandlingen är laglig enligt Gällande rätt genom att något
          av följande krav är uppfyllda:{' '}
        </p>
        <ul>
          <li>
            - det finns ett beslut från EU-kommissionen om att landet säkerställer adekvat
            skyddsnivå;
          </li>
          <li>
            - tillämpning av EU-kommissionens standardavtalsklausuler för tredjelandsöverföring;
            eller
          </li>
          <li>- andra lämpliga skyddsåtgärder som uppfyller Gällande rätt. </li>
        </ul>
        <p>
          Vi har ingått personuppgiftsbiträdesavtal (PUB-avtal) med alla våra
          Personuppgiftsbiträden. PUB-avtalet reglerar hur Personuppgiftsbiträdet får behandla
          Personuppgifterna och vilka säkerhetsåtgärder som krävs för personuppgiftsbehandlingen.{' '}
        </p>
        <p>
          Vi kan även behöva lämna dina Personuppgifter till vissa utpekade myndigheter för att
          fullgöra skyldigheter enligt lag eller myndighetsbeslut.
        </p>
        <h2>Säkerhet</h2>
        <p>
          Respaces har vidtagit tekniska och organisatoriska åtgärder för att säkerställa att dina
          personuppgifter behandlas på ett säkert sätt och att de skyddas från förlust, missbruk och
          obehörig eller otillåten åtkomst.{' '}
        </p>
        <h2>Våra säkerhetsåtgärder</h2>
        <p>
          <strong>Organisatoriska säkerhetsåtgärder </strong>är åtgärder som implementeras i
          arbetssätt och rutiner inom organisationen. Våra organisatoriska säkerhetsåtgärder är:
        </p>
        <ul>
          <li>- Interna styrdokument (policys/instruktioner)</li>
          <li>- Inlogg- och lösenordshantering </li>
        </ul>
        <p>
          <strong>Tekniska säkerhetsåtgärder </strong>är åtgärder som implementeras genom tekniska
          lösningar. Våra tekniska säkerhetsåtgärder är:
        </p>
        <ul>
          <li>- Kryptering</li>
          <li>- Åtkomstlista</li>
          <li>- Säkerhetskopiering</li>
        </ul>
        <h2>Cookies</h2>
        <p>
          Respaces använder cookies och liknande spårningstekniker för att bl.a. analysera hur
          Funktioner används så att vi kan ge dig den absolut bästa användarupplevelsen. Mer
          information om hur vi använder cookies finns i vår Cookie Policy (
          <NamedLink name="CookiePolicyPage">www.respaces.co/cookies</NamedLink>).
        </p>
        <h2>Om vi inte håller vad vi lovar</h2>
        <p>
          Om du upplever att vi behandlar dina Personuppgifter på ett felaktigt sätt, även efter att
          du har uppmärksammat oss om detta, har du alltid rätt att lämna ditt klagomål till
          Integritetsskyddsmyndigheten.
        </p>
        <p>
          Mer information om våra skyldigheter och dina rättigheter finns på
          Integritetsskyddsmyndighetens hemsida (https://www.imy.se/). Du kan också kontakta
          myndigheten på imy@imy.se.
        </p>
        <h2>Ändringar i den här policyn</h2>
        <p>
          Vi reserverar oss rätten att göra ändringar i denna Policy. I de fall ändringen påverkar
          våra skyldigheter eller dina rättigheter, kommer vi att informera om ändringarna i förväg
          så att du ges möjlighet att ta ställning till den uppdaterade policyn.{' '}
        </p>
        <h2>Kontakt</h2>
        <p>
          Hör av dig till oss om du har frågor om dina rättigheter eller om du har några andra
          frågor om hur vi behandlar dina personuppgifter:{' '}
          <a href="mailto:hello@respaces.co">hello@respaces.co </a>
        </p>
      </div>
    );
  } else {
    return (
      <div className={classes}>
        <p className={css.lastUpdated}>Last update: 16 September 2021</p>
        <h2>Why and who?</h2>
        <p>
          Respaces cares about privacy and protecting the Personal Data handled by us. This means
          that we care about your personal integrity and actively work to protect it.
        </p>
        <p>
          In this Policy we overall describe how and the purposes for which we use your Personal
          Data as well as what lawful basis we use and what measures we take to protect Personal
          Data. We also provide information on how you exercise the rights you have linked to our
          Processing of Personal data.
        </p>
        <p>
          Respaces AB Reg. No. (559258-0160) ("Respaces", "we", "us", "our") is the Controller of
          all Personal Data listed in this Privacy Policy (the "Policy").
        </p>
        <p>
          This Policy provides information on how we handle Personal Data when you communicate with
          us, use the Services or visit our website www.respaces.co (together the "Functions").
        </p>
        <h2>The intended recipient of the information provided in this Policy is:</h2>
        <ul>
          <li>- Users of the Services</li>
          <li>- Potential customers</li>
          <li>- Customers</li>
          <li>- Employees of existing customers </li>
          <li>- Visitors of our website </li>
        </ul>
        <h2>Definitions</h2>
        <p>
          <strong>"Applicable Law" </strong> refers to the legislation applicable to the Processing
          of Personal Data, including the GDPR, supplementary national legislation, as well as
          practices, guidelines and recommendations issued by a national or EU supervisory
          authority.
        </p>
        <p>
          <strong>"Controller"</strong> is the company/organisation that decides for what purposes
          and in what way personal data is to be processed and is responsible for the Processing of
          Personal Data in accordance with Applicable Law.
        </p>
        <p>
          <strong>"Data Subject"</strong> is the living, natural person whose Personal Data is being
          processed.
        </p>
        <p>
          <strong>"Personal Data"</strong> is all information relating, directly or indirectly, to
          an identifiable natural person.
        </p>
        <p>
          <strong>"Processing"</strong> means any operation or set of operations which is performed
          on Personal data, e.g. storage, modification, reading, handover and similar.
        </p>
        <p>
          <strong>"Processor"</strong> is the company/organisation that processes Personal Data on
          behalf of the Controller and can therefore only process the Personal Data according to the
          instructions of the Controller and the Applicable Law.
        </p>
        <p>
          <strong>"The Services"</strong> means the mediation of workplaces between two parties.
        </p>
        <p>
          The definitions above shall apply in the Policy regardless if they are capitalised or not.
        </p>
        <h2>Respaces's role as a Controller</h2>
        <p>
          The information in this Policy covers Personal Data Processing for which Respaces is the
          Controller. As a Controller we are responsible for the Processing for which we decide the
          purpose of ("the why") and the means for the Processing (what methods, what Personal Data
          and for how long it is stored. The Policy does not describe how we Process Personal Data
          in the role of a Processor - i.e. when we process Personal Data on behalf of our
          customers.
        </p>
        <p>
          Respaces mediates rental of workplaces. We therefore need to process your personal data so
          that we can create a user account and match you with available locations according to your
          needs. We also share the information with the owners of premises that you book with.
        </p>
        <h2>Respaces's Processing of Personal Data</h2>
        <p>
          We have a responsibility to describe and demonstrate how we fulfill the requirements that
          are imposed on us when we Process your Personal Data. This section aims to give you an
          understanding of what type of Personal Data we Process and on what reasons.
        </p>
        <h2>For how long do we store your Personal Data?</h2>
        <p>
          We will keep your Personal Data as long as it is necessary for the purpose for which it
          was collected. Depending on the lawful basis on which we support the Processing, this may
          a) be regulated in a contract, b) be dependent on valid consent, c) be stated in
          legislation or d) follow by an internal assessment based on a legitimate interest
          assessment (LIA). In the list below, we indicate, where possible, the period during which
          the Personal Data will be stored and the criteria used to determine the storage period.
        </p>
        <p>
          We never store your Personal Data longer than neccessary and delete Personal Data
          regularly. Respaces also takes reasonable actions to keep the Personal Data being
          Processed updated and to delete outdated and otherwise incorrect or redundant Personal
          Data.
        </p>
        <h2>Processings</h2>
        <p>
          The main purpose of the data rocessing undertaken by us is to provide, carry out and
          improve our services to you. There are several different reasons why we may need to
          collect, manage and save your data.
        </p>
        <p>We mainly Process the following types of Personal Data:</p>
        <ul>
          <li>
            - Contact details to be able to confirm your identity, to verify your personal and
            contact details and to be able to communicate with you
          </li>
          <li>
            - Information on your usage of the service or product to improve our services provided
            to you
          </li>
          <li>- Consumption patterns to be able to provide you with specific offers</li>
          <li>
            - Payment information to be able to offer e.g. direct debit and other payment options
          </li>
        </ul>
        <h2>How do we get access to your Personal Data?</h2>
        <p>
          We collect your Personal Data in a number of different ways. We mainly get access to your
          Personal Data:
        </p>
        <ul>
          <li>- By you providing your Personal Data to us</li>
          <li>- By your employer providing your Personal Data to us</li>
          <li>- Through information created from data analysis</li>
        </ul>

        <h2>Lawful basis</h2>
        <p>
          In order for us to be able to process your Personal Data, it is required that we have
          so-called legal basis for each process. In our business, we process your personal data
          mainly on the following grounds:
        </p>
        <p>
          <strong>Consent </strong> - Respaces may process your personal data after you have given
          your consent to the Processing. Information regarding the processing is always provided in
          connection to the request of consent.
        </p>
        <p>
          <strong>Legitimate interest </strong> - Respaces may process Personal Data if we have
          assessed that a legitimate interest overrides the interest of fundamental rights and
          freedoms of the Data Subject, and if the processing is necessary for the purpose in
          question.
        </p>
        <h2>Your rights</h2>
        <p>
          You are the one in control of your Personal Data and we always strive to ensure that you
          can exercise your rights as efficiently and smoothly as possible.
        </p>
        <p>
          <strong>Access - </strong>You always have the right to receive information about the
          Processing of data that concerns you. We only provide information if we have been able to
          verify that it is you that are requesting the information.
        </p>
        <p>
          <strong>Rectification -</strong>If you find that the Personal Data we process about you is
          incorrect, let us know and we will fix it!
        </p>
        <p>
          <strong>Erasure - </strong>Do you want us to completely forget about you? You have the
          right to be forgotten and request deletion of your Personal Data when the Processing is no
          longer necessary for the purpose for which it was collected. If we are required to retain
          your information under applicable law or a contract that we have entered with you, we will
          ensure that it is processed only for the specific purpose set forth in such applicable law
          or contract. We will thereafter erase the information as soon as possible.
        </p>
        <p>
          <strong>Objections - </strong>Do you disagree with our assessment that a legitimate
          interest for Processing your Personal Data overrides your interest in protecting your
          privacy? Don’t worry - in such case, we will review our legitimate interest assessment. Of
          course, we add your objection to the balance and make a new assessment to see if we can
          still justify our Processing of your Personal Data. If you object to direct marketing, we
          will immediately delete your personal information without making an assessment.
        </p>
        <p>
          <strong>Restriction - </strong>You can also ask us to restrict our Processing of your
          Personal Data
          <ul>
            <li>- Whilst we are Processing a request from you for any of your other rights;</li>
            <li>- Whilst we are Processing a request from you for any of your other rights;</li>
            <li>
              - In cases where we no longer need the information in relation to the purpose for
              which it was collected, provided that you do not have an interest in retaining it to
              make a legal claim.
            </li>
          </ul>
        </p>
        <p>
          <strong>Data portability - </strong>We may provide you with the data that you have
          submitted to us or that we have received from you in connection with a contract that we
          have entered with you. You will receive your information in a commonly used and
          machine-readable format that you can transfer to another personal data manager.
        </p>
        <p>
          <strong>Withdraw consent - </strong>If you have given consent to one or several specific
          Processing(s) of your Personal Data, you have the right to withdraw your consent at any
          time and thus ask us to terminate the Processing immediately. Please note that you can
          only withdraw your consent for future Processing of Personal Data and not for Processing
          that has already taken place.
        </p>
        <h2>How you use your rights</h2>
        <p>
          Contact us at <a href="mailto:hello@respaces.co">hello@respaces.co</a> and we will assist
          you.
        </p>
        <h2>Transfer of personal data</h2>
        <p>
          In order to run our business, we may need help from others who will process Personal Data
          on our behalf, so-called Processors.
        </p>
        <p>
          In cases where our Processors transfer Personal Data outside the EU/EEA, we have ensured
          that the level of protection is adequate, and in compliance with Applicable Law, by
          controlling that either of the following requirements are fulfilled:
        </p>
        <ul>
          <li>
            - the EU Commission has determined that the level of protection is adequate in the third
            country where the data is processed;
          </li>
          <li>
            - the Processor has signed up to the EU Commission's standard contract clauses (SCCs)
            for data transfer to non-EU/EEA countries; or
          </li>
          <li>
            - the Processor has signed up to the EU Commission's standard contract clauses (SCCs)
            for data transfer to non-EU/EEA countries; or{' '}
          </li>
        </ul>
        <p>
          We have entered into Data Processing Agreements (DPA) with all our Processors. The DPA
          sets out, among other things, how the Processor may process the Personal Data and what
          security measures are required for the Processing.
        </p>
        <p>
          We may also need to disclose your personal information to certain designated authorities
          in order to fulfill obligations under applicable law or legally binding judgements.
        </p>
        <h2>Our processors</h2>
        <p>
          Respaces does not sell your Personal Data to third parties and of course we do not share
          your Personal Data with just anyone. However, in some cases we may need to share your
          Personal Data with selected third parties. If so, we make sure that the transfer happens
          in a secure way that protects your privacy. To follow are categories of recipients with
          whom we may share your data.
        </p>
        <ul>
          <li>- Advertising agencies and suppliers of print and advertising.</li>
          <li>
            - IT suppliers for e.g. business systems and case management. In order to be able to
            carry out our assignments and services, we store your Personal Data in our business
            systems (a system that administers our customers and contacts).
          </li>
          <li>
            - Statistics to contribute to industry statistics and to improve the customer
            experience.
          </li>
        </ul>
        <h2>Security measures</h2>
        <p>
          Respaces has taken technical and organisational measures to ensure that your Personal Data
          is processed securely and protected from loss, abuse and unauthorised access.
        </p>
        <h2>Our security measures</h2>
        <p>
          <strong>Organisational security measures </strong>are measures that are implemented in
          work methods and routines within the organisation.
        </p>
        <ul>
          <li>- Internal governance documents (policys/instructions)</li>
          <li>- Internal governance documents (policys/instructions)</li>
        </ul>
        <p>
          <strong>Technical security measures </strong>are measures implemented through technical
          solutions.
        </p>
        <ul>
          <li>- Encryption </li>
          <li>- Access control level </li>
          <li>- Back-up </li>
        </ul>
        <h2>Cookies</h2>
        <p>
          Respaces uses cookies and similar tracking techniques to analyse the use of the Functions
          so that we can give you the best user experience. For more information on how we use
          cookies, see our Cookie Policy (
          <a href="www.respaces.co/cookies">www.respaces.co/cookies</a>).
        </p>
        <h2>If we don’t keep our promise</h2>
        <p>
          If you think that we are not Processing your Personal Data correctly, even after you have
          notified us of this, you are always entitled to submit your complaint to the Swedish
          Authority for Privacy Protection.
        </p>
        <p>
          More information about our obligations and your rights can be found at https://www.imy.se/
          You can contact the authority via e-mail at: imy@imy.se
        </p>
        <h2>Changes to this policy</h2>
        <p>
          We reserve the rights to make changes to this Policy. In the event that the change affects
          our obligations or your rights, we will inform you about the changes in advance so that
          you are given the opportunity to take a position on the updated policy.
        </p>
        <h2>Contact</h2>
        <p>
          Please contact us if you have questions about your rights or if you have any other
          questions about how we process your personal information:
          <a href="mailto:hello@respaces.co"> hello@respaces.co </a>
        </p>
      </div>
    );
  }
};

PrivacyPolicy.defaultProps = {
  rootClassName: null,
  className: null,
};

const { string } = PropTypes;

PrivacyPolicy.propTypes = {
  rootClassName: string,
  className: string,
};

export default PrivacyPolicy;
