import axios from 'axios';
import { defaultLocationBounds } from '../default-location-searches';
import { HOUR_OPTIONS } from '../marketplace-custom-config';
import config from '../config';
import moment from 'moment';

const TOGGLE_SEARCH_LOADING = 'app/SearchPageNew/TOGGLE_SEARCH_LOADING';
const SET_LOCATIONS = 'app/SearchPageNew/SET_LOCATIONS';
const SET_MAP_LOCATIONS = 'app/SearchPageNew/SET_MAP_LOCATIONS';
const UPDATE_FILTERS = 'app/SearchPageNew/UPDATE_FILTERS';
const UPDATE_PAGINATION = 'app/SearchPageNew/UPDATE_PAGINATION';
const UPDATE_SORT_BY = 'app/SearchPageNew/UPDATE_SORT_BY';

const toggleSearchLoading = (payload) => ({
  type: TOGGLE_SEARCH_LOADING,
  payload,
});

const setLocations = (payload) => ({
  type: SET_LOCATIONS,
  payload,
});

const setMapLocations = (payload) => ({
  type: SET_MAP_LOCATIONS,
  payload,
});

export const updateFilters = (payload) => ({
  type: UPDATE_FILTERS,
  payload,
});

export const updatePagination = (payload) => ({
  type: UPDATE_PAGINATION,
  payload,
});

export const updateSort = (payload) => ({
  type: UPDATE_SORT_BY,
  payload,
});

const initialState = {
  isLoading: false,
  locations: [],
  mapLocations: [],
  filters: {
    category: null,
    address: null,
    bounds: defaultLocationBounds,
  },
  pagination: {
    page: 1,
    perPage: 30,
    totalPages: 0,
    totalItems: 0,
    totalListings: 0,
  },
  sortBy: {
    field: 'created_at',
    order: 'desc',
    isListingFilter: true,
  },
};

const reducer = (state = initialState, action = {}) => {
  const { type, payload } = action;

  switch (type) {
    case TOGGLE_SEARCH_LOADING:
      return {
        ...state,
        isLoading: payload,
      };
    case SET_LOCATIONS:
      return {
        ...state,
        locations: [...payload],
      };
    case SET_MAP_LOCATIONS:
      return {
        ...state,
        mapLocations: [...payload],
      };
    case UPDATE_FILTERS:
      return {
        ...state,
        filters: { ...state.filters, ...payload },
        pagination: { ...state.pagination, page: 1 },
      };
    case UPDATE_PAGINATION:
      return {
        ...state,
        pagination: { ...state.pagination, ...payload },
      };
    case UPDATE_SORT_BY:
      return {
        ...state,
        sortBy: payload,
      };
    default:
      return state;
  }
};

export const requestChunk = () => async (dispatch, getState, sdk) => {
  dispatch(toggleSearchLoading(true));
  const { filters, sortBy, pagination, locations: stateLocations } = getState().SearchPageNew;
  const {
    category,
    name,
    host,
    startTime,
    endTime,
    bounds,
    minSeats,
    maxSeats,
    fixedOfficeType,
    price,
    ameneties,
    offer,
    contractType,
    allowVatExemptCompanies,
    contractLengthPerUnit,
    origin,
  } = filters;

  const startDate =
    filters.startDate && typeof filters.startDate === 'string'
      ? moment(filters.startDate)
      : filters.startDate;

  const useOrigin = !!origin && !!sortBy && sortBy.field === 'distance';
  const weekdays = ['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat'];

  const locationFilters = {
    ...(!useOrigin && { bounds }), //if we are using origin, we are not using bounds
    ...(!!useOrigin && { origin }),
    ...(!!name && { name }),
    ...(!!host && { host: host.split(',') }),
  };

  const listingFilters = {
    status: 'published',
    ...(!!category && { category }),
    ...(!!minSeats && { min_seats: minSeats }),
    ...(!!maxSeats && { max_seats: maxSeats }),
    ...(!!fixedOfficeType && { fixed_office_type: fixedOfficeType.split(',') }),
    ...(!!price && { price: parseInt(price.split(',')[1]) * 100 }),
    ...(!!startDate && { operation_day: weekdays[startDate.weekday()] }), //include the weekday to check if the listing is open on that day
    ...(!!ameneties && { amenities: ameneties.split(',') }),
    ...(!!offer && { offer: offer.split(',') }),
    ...(!!contractType && { contract_type: contractType.split(',') }),
    ...(!!allowVatExemptCompanies && { allow_vat_exempt_companies: true }),
    ...(!!contractLengthPerUnit && { contract_length_per_unit: parseInt(contractLengthPerUnit) }),
  };

  const locationFields = [
    'id',
    'name',
    'lat',
    'lng',
    'status',
    'address',
    'images',
    'opening_hours',
    'host',
  ];

  const listingFields = [
    'id',
    'location_id',
    'category',
    'min_seats',
    'max_seats',
    'fixed_office_type',
    'price',
    'operation_hours',
    'amenities',
    'offer',
    'contract_type',
    'allow_vat_exempt_companies',
    'contract_length_per_unit',
    'rating',
    'booking_type',
    'vat',
    'request_price',
    'badge_unique',
    'badge_affordable',
  ];

  // find start and end time in the HOUR_OPTIONS array and return the label since that is what is used in the database
  const startTimeLabel =
    startTime && HOUR_OPTIONS.find((option) => option.key === startTime)?.label;
  const endTimeLabel = endTime && HOUR_OPTIONS.find((option) => option.key === endTime)?.label;
  const openTimes = { startTimeLabel, endTimeLabel };
  const canonicalRootURL =
    process.env.REACT_APP_ENV === 'development' ? 'http://localhost:3500' : config.canonicalRootURL;
  const response = await axios.get(`${canonicalRootURL}/hasura/locations/search`, {
    params: {
      locationFilters,
      locationFields,
      listingFilters,
      listingFields,
      openTimes,
      pagination,
      ...(!!sortBy?.field &&
        !useOrigin && {
          //if we are using origin, we are sorting by distance, adding another sorting is not supported
          sorting: {
            field: sortBy.field,
            order: sortBy.order,
            isListingFilter: sortBy.isListingFilter,
          },
        }),
    },
  });

  const {
    data: {
      locations: {
        data: { locations, mapLocations },
        meta,
      },
    },
  } = response;

  dispatch(
    setLocations(
      locations && pagination.page > 1
        ? [...stateLocations, ...locations]
        : locations
        ? locations
        : []
    )
  );
  dispatch(setMapLocations(mapLocations ? mapLocations : []));
  dispatch(
    updatePagination({
      totalItems: meta?.totalItems,
      totalListings: meta?.totalListings,
      totalPages: meta?.totalPages,
    })
  );
  dispatch(toggleSearchLoading(false));
};

export default reducer;
