import {
  Footer,
  LayoutSingleColumn,
  LayoutWrapperFooter,
  LayoutWrapperMain,
  LayoutWrapperTopbar,
} from '../../components';
import { StaticPage, TopbarContainer } from '../../containers';

import { injectIntl } from 'react-intl';
import { compose } from 'redux';
import { AboutUs } from './Aboutus/AboutUs';

const AboutPageComponent = ({ intl }) => {
  return (
    <StaticPage
      title="About Us"
      schema={{
        '@context': 'http://schema.org',
        '@type': 'AboutPage',
        description: 'About Us',
        name: 'About page',
      }}
    >
      <LayoutSingleColumn>
        <LayoutWrapperTopbar>
          <TopbarContainer currentPage="AboutPage" />
        </LayoutWrapperTopbar>
        <LayoutWrapperMain>
          <AboutUs intl={intl} />
        </LayoutWrapperMain>
        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </LayoutSingleColumn>
    </StaticPage>
  );
};

const AboutPage = compose(injectIntl)(AboutPageComponent);

export default AboutPage;
