import React from 'react';
import classNames from 'classnames';
import { injectIntl } from 'react-intl';
import { bool, func, string } from 'prop-types';

import css from './DayTitle.module.scss';

const DayTitle = ({ day, date, userHasBookings, onOpenSidebar, isToday, intl }) => (
  <button onClick={onOpenSidebar} className={classNames(css.date, isToday && css.today)}>
    {intl.formatMessage({ id: `MarketplaceConfig.day.short.${day}` })} <span>{date}</span>
    {userHasBookings ? (
      <svg
        width="14"
        height="14"
        viewBox="0 0 14 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0 7C0 3.134 3.134 0 7 0C10.866 0 14 3.134 14 7C14 10.866 10.866 14 7 14C3.134 14 0 10.866 0 7ZM10.3535 5.3535C10.4446 5.2592 10.495 5.1329 10.4938 5.0018C10.4927 4.8707 10.4401 4.74529 10.3474 4.65259C10.2547 4.55989 10.1293 4.5073 9.9982 4.50616C9.8671 4.50502 9.7408 4.55542 9.6465 4.6465L6.25 8.043L4.6035 6.3965C4.5092 6.30542 4.3829 6.25502 4.2518 6.25616C4.1207 6.2573 3.99529 6.30989 3.90259 6.40259C3.80989 6.49529 3.7573 6.6207 3.75616 6.7518C3.75502 6.8829 3.80542 7.0092 3.8965 7.1035L5.8965 9.1035C5.99026 9.19724 6.11742 9.24989 6.25 9.24989C6.38258 9.24989 6.50974 9.19724 6.6035 9.1035L10.3535 5.3535Z"
          fill="#74AF86"
        />
      </svg>
    ) : (
      <svg
        width="14"
        height="14"
        viewBox="0 0 14 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="7" cy="7" r="7" fill="#D8D8D8" />
        <line x1="3.5" y1="7" x2="10.5" y2="7" stroke="#F8F8F8" strokeLinecap="round" />
      </svg>
    )}
  </button>
);

DayTitle.propTypes = {
  day: string.isRequired,
  date: string.isRequired,
  userHasBookings: bool.isRequired,
  onOpenSidebar: func,
};

export default injectIntl(DayTitle);
