import React, { memo } from 'react';

const PlusIcon = memo(({ className }) => (
  <svg
    className={className}
    width="12"
    height="11"
    viewBox="0 0 12 11"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M7.016 10.848H5.348V6.468H0.98V4.836H5.348V0.42H7.016V4.836H11.42V6.468H7.016V10.848Z" />
  </svg>
));

export default PlusIcon;
