import { curry } from 'ramda';
import get from 'lodash/get';
import config from '../config';

import respacesLogo from '../assets/respacesLogo.png';

import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';

pdfMake.vfs = pdfFonts.pdfMake.vfs;

const formatNumberLocaleCurrency = curry((currency, number) => {
  return new Intl.NumberFormat('sv-SE', { style: 'currency', currency }).format(number);
});
const createPDF = async (data, currentUser, values, intl) => {
  const { attributes, listing, id } = data || {};
  const {
    companyName,
    companyNumber,
    companyAddress1,
    companyAddress2,
    companyPostcode,
    companyCountry,
  } = values;
  const listingServices = listing.attributes.publicData.additionalServices;
  const listingVATAmount = listing.attributes.publicData.vatPercentage || 25;
  const bookingDate = get(attributes, 'transitions', {}).filter(
    (item) => item.transition === 'transition/confirm-payment'
  );
  // TODO - coupon will not be included in any transactions made before this code goes live, so will have to calculate it from the
  // difference between listingServices/listing unitPrice and the lineItem price.
  const { coupon } = attributes.protectedData || {};

  const items = get(attributes, 'protectedData.lineItems', {}).filter((item) =>
    item.includeFor.includes('customer')
  );

  let units = items.filter((item) => item.code === 'line-item/units');
  let additionalServices = items.filter((item) => item.code.includes('fee'));

  const formatCurrency = formatNumberLocaleCurrency(attributes.payinTotal.currency);

  const VATGroups = { VAT25: 0, VAT12: 0, VAT6: 0 };
  VATGroups[`VAT${listingVATAmount}`] += parseFloat(units[0].vat.amount);
  const mappedServices = additionalServices.map((item) => {
    const vatPercentage = listingServices.find(
      (x) => `${x.name} fee` === item.code.toLowerCase()
    ).vatPercentage;

    VATGroups[`VAT${vatPercentage}`] += parseFloat(item.vat.amount);
    const multiply = listingServices.find(
      (x) => `${x.name} fee` === item.code.toLowerCase()
    ).multiplySeats;

    // if the price should be multiplied with number of seats, adjust total units bought
    let unitsBooked = 1;
    if (multiply === true) {
      unitsBooked = item.seats * item.units;
    } else {
      unitsBooked = item.quantity;
    }
    return [
      {
        text: item.code,
        style: 'tableRow',
        border: [false, false, false, true],
      },
      { text: unitsBooked, style: 'tableRow', border: [false, false, false, true] },
      {
        text: item.unitPrice.amount,
        style: 'tableRow',
        border: [false, false, false, true],
      },
      {
        text: item.lineTotal.amount,
        style: 'tableRow',
        border: [false, false, false, true],
      },
    ];
  });
  let VATArr = [];
  for (let key in VATGroups) {
    if (VATGroups[key] > 0) {
      VATArr.push([
        {
          text: `${key.slice(3)}% :`,
          style: 'summaryHeader',
          margin: [0, 5, 0, 0],
        },
        {
          text: formatCurrency(VATGroups[key]),
          style: 'summaryValue',
          margin: [0, 5, 0, 0],
        },
      ]);
    }
  }

  const couponMessage = coupon &&
    coupon.valid && {
      text: intl.formatMessage(
        {
          id: 'DownloadReceipt.discountClause',
        },
        { percentage: coupon.discount.percent_off }
      ),
      style: 'rowInfo',
      margin: [0, 10, 0, 0],
    };

  const billingDetailsTable = [
    [
      {
        text: intl.formatMessage({ id: 'DownloadReceipt.behalfOf' }),
        style: 'billingHeader',
        margin: [0, 0, 20, 0],
      },
      {
        text: `${currentUser.attributes.profile.firstName} ${currentUser.attributes.profile.lastName}`,
        style: 'billingValue',
      },
    ],
    ...(companyName
      ? [
          [
            {
              text: `${intl.formatMessage({ id: 'DownloadReceipt.companyName' })}`,
              style: 'billingHeader',
            },
            {
              text: `${companyName || ''}`,
              style: 'billingValue',
            },
          ],
        ]
      : []),
    ...(companyNumber
      ? [
          [
            {
              text: intl.formatMessage({ id: 'DownloadReceipt.companyNumber' }),
              style: 'billingHeader',
            },
            {
              text: `${companyNumber}`,
              style: 'billingValue',
            },
          ],
        ]
      : []),
    ...(companyAddress1
      ? [
          [
            {
              text: intl.formatMessage({ id: 'DownloadReceipt.companyAddress1' }),
              style: 'billingHeader',
            },
            {
              text: `${companyAddress1 || ''}`,
              style: 'billingValue',
            },
          ],
        ]
      : []),
    ...(companyAddress2
      ? [
          [
            {
              text: intl.formatMessage({ id: 'DownloadReceipt.companyAddress2' }),
              style: 'billingHeader',
            },
            {
              text: `${companyAddress2 || ''}`,
              style: 'billingValue',
            },
          ],
        ]
      : []),
    ...(companyCountry
      ? [
          [
            {
              text: intl.formatMessage({ id: 'DownloadReceipt.companyCountry' }),
              style: 'billingHeader',
            },
            {
              text: `${companyCountry || ''}`,
              style: 'billingValue',
            },
          ],
        ]
      : []),
    ...(companyPostcode
      ? [
          [
            {
              text: intl.formatMessage({ id: 'DownloadReceipt.companyPostcode' }),
              style: 'billingHeader',
            },
            {
              text: `${companyPostcode || ''}`,
              style: 'billingValue',
            },
          ],
        ]
      : []),
  ];

  const options = {
    pageSize: 'A4',
    pageOrientation: 'portrait',
    pageMargins: [40, 80, 40, 60],
    header: {
      margin: [40, 20, 0, 0],
      columns: [
        {
          image: 'logo',
          height: 40,
          width: 120,
          alignment: 'center',
        },
        {
          columns: [
            {
              text: intl.formatMessage({ id: 'DownloadReceipt.headerInvoiceNumber' }),
              style: 'header',
              alignment: 'right',
              margin: [0, 0, 20, 0],
            },
            {
              text: `${id.uuid}`,
              style: 'header',
            },
          ],
        },
      ],
    },
    footer: {
      columns: [
        {
          text: `Respaces AB
        Styrmansgatan 43, 114 54, Stockholm`,
          style: 'footer',
        },
        {
          text: `Org.nr: 559258-0160
        Email: invoice@respaces.co`,
          alignment: 'right',
          style: 'footer',
        },
      ],
    },
    content: [
      {
        id: 'billing',
        margin: [0, 30, 0, 30],
        columns: [
          {
            table: {
              widths: ['auto', '*'],
              body: billingDetailsTable,
            },
            layout: {
              defaultBorder: false,
            },
            width: '*',
          },
          [
            {
              text: [
                {
                  text: intl.formatMessage({ id: 'DownloadReceipt.transactionDate' }),
                  style: 'billingHeader',
                  alignment: 'right',
                },
                {
                  text: bookingDate[0].createdAt.toLocaleString(),
                  style: 'billingValue',
                  alignment: 'right',
                },
              ],
              width: 'auto',
              alignment: 'right',
            },
            {
              text: [
                {
                  text: intl.formatMessage({ id: 'DownloadReceipt.createdDate' }),
                  style: 'billingHeader',
                  alignment: 'right',
                },
                {
                  text: new Date().toLocaleString(),
                  style: 'billingValue',
                  alignment: 'right',
                },
              ],
              width: 'auto',
              alignment: 'right',
            },
          ],
        ],
      },
      {
        table: {
          widths: ['35%', '*', '*', '*'],
          body: [
            // Headers
            [
              {
                text: intl.formatMessage({ id: 'DownloadReceipt.headerDescription' }),
                style: 'tableHeader',
                border: [false, false, false, true],
              },
              {
                text: intl.formatMessage({ id: 'DownloadReceipt.headerUnits' }),
                style: 'tableHeader',
                border: [false, false, false, true],
              },
              {
                text: intl.formatMessage({ id: 'DownloadReceipt.headerUnitPrice' }),
                style: 'tableHeader',
                border: [false, false, false, true],
              },
              {
                text: intl.formatMessage({ id: 'DownloadReceipt.headerAmount' }),
                style: 'tableHeader',
                border: [false, false, false, true],
              },
            ],
            // main unit of the listing which was booked
            [
              {
                text: listing.attributes.title,
                style: 'tableRow',
                border: [false, false, false, true],
              },
              {
                text: units[0].seats * units[0].units,
                style: 'tableRow',
                border: [false, false, false, true],
              },
              {
                text: units[0].unitPrice.amount,
                style: 'tableRow',
                border: [false, false, false, true],
              },
              {
                text: units[0].lineTotal.amount,
                style: 'tableRow',
                border: [false, false, false, true],
              },
            ],
            // additional services
            ...mappedServices,
          ],
        },
        layout: {
          fillColor: function (rowIndex, node, columnIndex) {
            return rowIndex % 2 === 0 || rowIndex === 0 ? null : '#f3f3f3';
          },
          defaultBorder: false,
        },
      },
      {
        columns: [couponMessage],
      },
      {
        id: 'summary',
        margin: [0, 30, 0, 0],
        columns: [
          { text: [], width: 320 },
          {
            table: {
              widths: [100, 80],
              body: [
                [
                  {
                    text: intl.formatMessage({ id: 'DownloadReceipt.summaryCurrency' }),
                    style: 'summaryHeader',
                  },
                  {
                    text: attributes.payinTotal.currency,
                    style: 'summaryValue',
                  },
                ],
                [
                  {
                    text: intl.formatMessage({ id: 'DownloadReceipt.summarySubtotal' }),
                    style: 'summaryHeader',
                    margin: [0, 10, 0, 0],
                  },
                  {
                    text: formatCurrency(
                      attributes.payinTotal.amount / 100 - attributes.protectedData.totalVAT.amount
                    ),
                    style: 'summaryValue',
                    margin: [0, 10, 0, 0],
                  },
                ],
                [
                  {
                    text: intl.formatMessage({ id: 'DownloadReceipt.summaryVAT' }),
                    style: 'summaryHeader',
                  },
                  {
                    text: formatCurrency(attributes.protectedData.totalVAT.amount),
                    style: 'summaryValue',
                  },
                ],
                [
                  {
                    text: intl.formatMessage({ id: 'DownloadReceipt.summaryTotal' }),
                    style: 'summaryHeader',
                    bold: true,
                    margin: [0, 10, 0, 0],
                    border: [false, true, false, false],
                  },
                  {
                    text: formatCurrency(attributes.payinTotal.amount / 100),
                    style: 'summaryValue',
                    bold: true,
                    margin: [0, 10, 0, 0],
                    border: [false, true, false, false],
                  },
                ],
              ],
            },
            layout: {
              defaultBorder: false,
            },
          },
        ],
      },
      {
        id: 'vat-summary',
        margin: [0, 30, 0, 0],
        columns: [
          { text: [], width: 320 },
          {
            table: {
              widths: [100, 80],
              body: [
                [
                  {
                    text: intl.formatMessage({ id: 'DownloadReceipt.taxSummary' }),
                    style: 'summaryHeader',
                  },
                  {
                    text: intl.formatMessage({ id: 'DownloadReceipt.taxTotal' }),
                    style: 'summaryValue',
                  },
                ],
                ...VATArr,
              ],
            },
            layout: {
              defaultBorder: false,
            },
          },
        ],
      },
    ],
    images: {
      // in browser is supported loading images via url (https or http protocol) (minimal version: 0.1.67)
      logo: `${config.canonicalRootURL}${respacesLogo}`,
    },
    styles: {
      header: {
        fontSize: 9,
      },
      footer: {
        fontSize: 9,
        margin: [40, 0, 40, 0],
      },
      rowInfo: {
        fontSize: 8,
      },
      tableHeader: {
        bold: true,
        fontSize: 10,
        color: 'black',
      },
      tableRow: {
        fontSize: 10,
        color: 'black',
      },
      summaryHeader: {
        fontSize: 10,
        alignment: 'right',
        color: 'black',
      },
      summaryValue: {
        fontSize: 10,
        alignment: 'right',
        color: 'black',
      },
      billingHeader: {
        fontSize: 10,
        color: 'black',
        alignment: 'left',
        margin: [0, 0, 20, 0],
      },
      billingValue: {
        fontSize: 10,
        color: 'black',
      },
    },
  };

  pdfMake.createPdf(options).open();
};

export default createPDF;
