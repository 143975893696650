import React from 'react';
import classNames from 'classnames';
import { bool, func, string } from 'prop-types';
import { Form as FinalForm } from 'react-final-form';

import { FieldSwitch } from '../index';
import { FIELD_SWITCH_SIZES } from '../FieldSwitch/FieldSwitch';

import css from './DatePickerControl.module.scss';

const DatePickerControl = ({ id, label, isMobile, isChecked, onChange }) => (
  <div className={classNames(css.dropdownCheckboxWithLabel, isMobile && css.mobile)}>
    <span>{label}</span>
    <FinalForm
      onSubmit={() => null}
      render={() => (
        <FieldSwitch
          isChecked={isChecked}
          onChange={onChange}
          name={id}
          id={id}
          withIcon={false}
          size={isMobile ? FIELD_SWITCH_SIZES.LARGE : FIELD_SWITCH_SIZES.SMALL}
        />
      )}
    />
  </div>
);

DatePickerControl.propTypes = {
  id: string.isRequired,
  label: string.isRequired,
  isMobile: bool.isRequired,
  isChecked: bool.isRequired,
  onChange: func.isRequired,
};

export default DatePickerControl;
