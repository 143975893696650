export const homeImg = (
  <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="14" cy="14" r="14" fill="#F8F8F8" />
    <path
      d="M19.2463 12.7064L14.3702 8.22046C14.2117 8.07462 14.003 7.9957 13.7876 8.00018C13.5723 8.00467 13.367 8.09221 13.2147 8.24452L8.73014 12.7291L8.5 12.9592V19.5704H12.8214V15.4454H15.1786V19.5704H19.5V12.9399L19.2463 12.7064ZM13.8045 8.78573C13.8114 8.78573 13.8072 8.78723 13.8042 8.79015C13.8011 8.78723 13.7976 8.78573 13.8045 8.78573ZM18.7143 18.7847H15.9643V15.4454C15.9643 15.237 15.8815 15.0371 15.7342 14.8898C15.5868 14.7424 15.387 14.6597 15.1786 14.6597H12.8214C12.613 14.6597 12.4132 14.7424 12.2658 14.8898C12.1185 15.0371 12.0357 15.237 12.0357 15.4454V18.7847H9.28571V13.2847L13.8045 8.80009C13.8048 8.7998 13.8048 8.79953 13.8051 8.79923L18.7143 13.2847V18.7847Z"
      fill="#999999"
    />
  </svg>
);

export const fixedImg = (
  <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="14" cy="14" r="14" fill="#F8F8F8" />
    <path
      d="M18.7413 9.89648H15.707V10.6551H18.7413V18.62H15.707V19.3786H19.4999V10.6551C19.4999 10.4539 19.42 10.2609 19.2777 10.1187C19.1354 9.97641 18.9425 9.89648 18.7413 9.89648Z"
      fill="#999999"
    />
    <path
      d="M14.523 8H9.30408C9.09083 8 8.88631 8.08472 8.73551 8.23551C8.58472 8.38631 8.5 8.59083 8.5 8.80408V19.3785H15.3271V8.80408C15.3271 8.59083 15.2424 8.38631 15.0916 8.23551C14.9408 8.08472 14.7363 8 14.523 8ZM14.5686 18.62H13.4307V17.4821H10.3964V18.62H9.25857V8.80408C9.25857 8.79811 9.25975 8.79219 9.26203 8.78667C9.26432 8.78114 9.26767 8.77613 9.2719 8.7719C9.27613 8.76767 9.28114 8.76432 9.28667 8.76203C9.29219 8.75975 9.29811 8.75857 9.30408 8.75857H14.523C14.529 8.75857 14.5349 8.75975 14.5405 8.76203C14.546 8.76432 14.551 8.76767 14.5552 8.7719C14.5595 8.77613 14.5628 8.78114 14.5651 8.78667C14.5674 8.79219 14.5686 8.79811 14.5686 8.80408V18.62Z"
      fill="#999999"
    />
    <path d="M10.0176 9.89648H10.7761V10.6551H10.0176V9.89648Z" fill="#999999" />
    <path d="M11.5352 9.89648H12.2937V10.6551H11.5352V9.89648Z" fill="#999999" />
    <path d="M13.0508 9.89648H13.8094V10.6551H13.0508V9.89648Z" fill="#999999" />
    <path d="M10.0176 11.793H10.7761V12.5515H10.0176V11.793Z" fill="#999999" />
    <path d="M11.5352 11.793H12.2937V12.5515H11.5352V11.793Z" fill="#999999" />
    <path d="M13.0508 11.793H13.8094V12.5515H13.0508V11.793Z" fill="#999999" />
    <path d="M10.0176 13.6895H10.7761V14.448H10.0176V13.6895Z" fill="#999999" />
    <path d="M11.5352 13.6895H12.2937V14.448H11.5352V13.6895Z" fill="#999999" />
    <path d="M13.0508 13.6895H13.8094V14.448H13.0508V13.6895Z" fill="#999999" />
    <path d="M10.0176 15.5859H10.7761V16.3445H10.0176V15.5859Z" fill="#999999" />
    <path d="M11.5352 15.5859H12.2937V16.3445H11.5352V15.5859Z" fill="#999999" />
    <path d="M13.0508 15.5859H13.8094V16.3445H13.0508V15.5859Z" fill="#999999" />
    <path d="M15.707 11.793H16.4656V12.5515H15.707V11.793Z" fill="#999999" />
    <path d="M17.2246 11.793H17.9832V12.5515H17.2246V11.793Z" fill="#999999" />
    <path d="M15.707 13.6895H16.4656V14.448H15.707V13.6895Z" fill="#999999" />
    <path d="M17.2246 13.6895H17.9832V14.448H17.2246V13.6895Z" fill="#999999" />
    <path d="M15.707 15.5859H16.4656V16.3445H15.707V15.5859Z" fill="#999999" />
    <path d="M17.2246 15.5859H17.9832V16.3445H17.2246V15.5859Z" fill="#999999" />
  </svg>
);

export const exploreImg = (
  <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="14" cy="14" r="14" fill="#F8F8F8" />
    <path
      d="M18.6276 12.9091C18.6276 16.7273 13.8138 20 13.8138 20C13.8138 20 9 16.7273 9 12.9091C9 11.6071 9.50716 10.3585 10.4099 9.43784C11.3127 8.51721 12.5371 8 13.8138 8C15.0905 8 16.3149 8.51721 17.2176 9.43784C18.1204 10.3585 18.6276 11.6071 18.6276 12.9091Z"
      stroke="#999999"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M13.8136 14.5452C14.6998 14.5452 15.4182 13.8126 15.4182 12.9088C15.4182 12.0051 14.6998 11.2725 13.8136 11.2725C12.9274 11.2725 12.209 12.0051 12.209 12.9088C12.209 13.8126 12.9274 14.5452 13.8136 14.5452Z"
      stroke="#999999"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);
