import React from 'react';
import { CSSTransition } from 'react-transition-group';
import { bool, func } from 'prop-types';

import { OutsideClickHandler } from '../../../../components';

import css from './ActionDropdown.module.scss';

const ActionDropdown = ({ isOpen, onOpen, onClose, children }) => (
  <div className={css.dropdown}>
    <button
      onClick={(e) => {
        e.stopPropagation();
        onOpen();
      }}
      className={css.trigger}
    >
      <svg width="4" height="20" viewBox="0 0 4 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="2" cy="18" r="2" transform="rotate(-90 2 18)" fill="#D8D8D8" />
        <circle cx="2" cy="10" r="2" transform="rotate(-90 2 10)" fill="#D8D8D8" />
        <circle cx="2" cy="2" r="2" transform="rotate(-90 2 2)" fill="#D8D8D8" />
      </svg>
    </button>
    <CSSTransition in={isOpen} timeout={300} classNames="dropdown" unmountOnExit>
      <OutsideClickHandler rootClassName={css.body} onOutsideClick={onClose}>
        {children}
      </OutsideClickHandler>
    </CSSTransition>
  </div>
);

ActionDropdown.propTypes = {
  isOpen: bool,
  onClose: func,
};

export default ActionDropdown;
