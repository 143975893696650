import React from 'react';
import { FormattedMessage } from 'react-intl';
import { arrayOf, bool, shape, string } from 'prop-types';
import classNames from 'classnames';

import ListingPageSection from '../ListingPageSection/ListingPageSection';

import css from './SectionFeatures.module.scss';

const SectionFeatures = ({
  optionsAmeneties,
  optionsOffer,
  publicData: { category, ameneties, offer },
}) => {
  if (!ameneties && !offer) {
    return null;
  }

  const featuresAmeneties = optionsAmeneties.filter((o) => !!ameneties.includes(o.key));
  const featuresOffer = optionsOffer.filter((o) => !!offer.includes(o.key));

  const missingOffer = optionsOffer.filter(
    (obj) => obj.important && !offer.includes(obj.key) && obj[category]
  );

  return (
    <ListingPageSection title={'ListingPage.features.title'}>
      {featuresAmeneties.length > 0 ? (
        <h6 className={css.subTitle}>
          <FormattedMessage id={'ListingPage.features.amenetiesTitle'} />
        </h6>
      ) : null}
      <div className={css.features}>
        {featuresAmeneties.map((o) => (
          <div className={css.feature} key={o.key}>
            <span className={css.featureIconSpan}>
              <img src={o.iconSrc} alt={o.label} className={css.featureIcon} />
            </span>
            <span className={css.featureLabelSpan}>
              <FormattedMessage id={o.labelId} />
            </span>
          </div>
        ))}
      </div>
      {featuresOffer.length > 0 || missingOffer.length > 0 ? (
        <h6 className={css.subTitle}>
          <FormattedMessage id={'ListingPage.features.offerTitle'} />
        </h6>
      ) : null}
      <div className={css.features}>
        {featuresOffer.map((o) => (
          <div className={css.feature} key={o.key}>
            <span className={css.featureIconSpan}>
              <img src={o.iconSrc} alt={o.label} className={css.featureIcon} />
            </span>
            <span className={css.featureLabelSpan}>
              <FormattedMessage id={o.labelId} />
            </span>
          </div>
        ))}
        {missingOffer.map((o) => (
          <div className={classNames(css.feature, css.missingOffer)} key={o.key}>
            <span className={css.featureIconSpan}>
              <img src={o.iconSrc} alt={o.label} className={css.featureIcon} />
            </span>
            <span className={css.featureLabelSpan}>
              <FormattedMessage id={o.labelId} />
            </span>
          </div>
        ))}
      </div>
    </ListingPageSection>
  );
};

SectionFeatures.propTypes = {
  optionsAmeneties: arrayOf(
    shape({
      iconSrc: string,
      key: string,
      label: string,
      labelId: string,
      coworking: bool,
      meeting: bool,
      office: bool,
      studio: bool,
    })
  ).isRequired,
  optionsOffer: arrayOf(
    shape({
      iconSrc: string,
      key: string,
      label: string,
      labelId: string,
      coworking: bool,
      meeting: bool,
      office: bool,
      studio: bool,
      important: bool,
    })
  ).isRequired,
  publicData: shape({
    ameneties: arrayOf(string),
    offer: arrayOf(string),
  }),
};

export default SectionFeatures;
