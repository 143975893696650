import React from 'react';
import { node, string } from 'prop-types';
import classNames from 'classnames';
import { Field } from 'react-final-form';

import css from './FieldCheckbox.module.scss';

const IconCheckbox = (props) => {
  const { className, checkedClassName, boxClassName } = props;
  return (
    <svg
      className={className}
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        className={boxClassName || css.box}
        x="0.5"
        y="0.5"
        width="19"
        height="19"
        rx="2.5"
        fill="none"
        stroke="#222222"
      />
      <path
        className={checkedClassName || css.checked}
        d="M5.88235 10.49L8.3045 12.9409L14.1176 7.05859"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

IconCheckbox.defaultProps = { className: null, checkedClassName: null, boxClassName: null };

IconCheckbox.propTypes = { className: string, checkedClassName: string, boxClassName: string };

const IconLargeCheckbox = (props) => {
  const { className } = props;
  return (
    <svg
      className={className}
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        className={css.largeBox}
        x="0.5"
        y="0.5"
        width="19"
        height="19"
        rx="2.5"
        fill="#ffffff"
        stroke="#222222"
      />
      <path
        d="M5.88235 10.49L8.3045 12.9409L14.1176 7.05859"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
const FieldCheckboxComponent = (props) => {
  const {
    rootClassName,
    className,
    svgClassName,
    textClassName,
    searchFilterClass,
    id,
    label,
    useSuccessColor,
    checkboxSize,
    ...rest
  } = props;

  const classes = classNames(rootClassName || css.root, className, css[searchFilterClass]);
  const iconClasses = classNames(svgClassName || css.iconRoot, css[searchFilterClass]);
  const checkboxProps = {
    id,
    className: css.input,
    component: 'input',
    type: 'checkbox',
    ...rest,
  };

  const successColorVariantMaybe = useSuccessColor
    ? {
        checkedClassName: css.checkedSuccess,
        boxClassName: css.boxSuccess,
      }
    : {};

  const showCheckbox = () => {
    switch (checkboxSize) {
      case 'large':
        return <IconLargeCheckbox className={svgClassName} />;
      default:
        return <IconCheckbox className={svgClassName} {...successColorVariantMaybe} />;
    }
  };
  return (
    <span className={classes}>
      <Field {...checkboxProps} />
      <label htmlFor={id} className={classNames(css.label, css[searchFilterClass])}>
        <span className={classNames(css.checkboxWrapper, css[searchFilterClass])}>
          {showCheckbox()}
        </span>
        <span className={classNames(css.text, textClassName || css.textRoot)}>{label}</span>
      </label>
    </span>
  );
};

FieldCheckboxComponent.defaultProps = {
  className: null,
  rootClassName: null,
  svgClassName: null,
  textClassName: null,
  label: null,
};

FieldCheckboxComponent.propTypes = {
  className: string,
  rootClassName: string,
  svgClassName: string,
  textClassName: string,

  // Id is needed to connect the label with input.
  id: string.isRequired,
  label: node,

  // Name groups several checkboxes to an array of selected values
  name: string.isRequired,

  // Checkbox needs a value that is passed forward when user checks the checkbox
  value: string.isRequired,
};

export default FieldCheckboxComponent;
