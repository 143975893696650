import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { StaticPage, TopbarContainer } from '..';
import { FormattedMessage } from 'react-intl';
import {
  LayoutSingleColumn,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  Footer,
  Button,
} from '../../components';
import axios from 'axios';
import Markdown from 'markdown-to-jsx';
import IconSocialMediaFacebook from '../../components/IconSocialMediaFacebook/IconSocialMediaFacebook';
import IconSocialMediaLinkedin from '../../components/IconSocialMediaLinkedin/IconSocialMediaLinkedin';
import Arrow from './Arrow';
import imgLeft from './assets/left.png';
import imgMid from './assets/middle.png';
import imgRight from './assets/right.png';
import css from './JobPost.module.scss';

export default function SinglePost() {
  const [jobData, setJobData] = useState(null);
  const { slug } = useParams();
  const name = 'hello';
  const domain = 'respaces.co';
  const host =
    typeof window !== 'undefined' &&
    !window.location.hostname.includes(
      'localhost'
    ) /* included this for development only benefit - maybe not needed */
      ? window.location.hostname
      : 'beta.respaces.co';

  useEffect(() => {
    axios
      .get(`https://cms.respaces.co/jobs?slug=${slug}`)
      .then((response) => {
        setJobData(response.data[0]);
      })
      .catch((err) => console.error(err));
  }, [slug]);

  if (!jobData) {
    return <FormattedMessage id="SinglePost.loading" />;
  } else {
    const applyButtonTarget =
      jobData.link && /^http/.test(jobData.link)
        ? jobData.link
        : `mailto:${name}@${domain}?subject=${jobData.title}-${jobData.department}`;
    return (
      <StaticPage>
        <LayoutSingleColumn>
          <LayoutWrapperTopbar>
            <TopbarContainer currentPage="JobPost" />
          </LayoutWrapperTopbar>
          {/* TODO add translation to all post details */}
          <LayoutWrapperMain className={css.mainWrapperJob}>
            <section className={css.sectionTitle}>
              <h2 className={css.jobPostTitle}>
                {jobData.title}: {jobData.department}
              </h2>
              <h3>{jobData.description}</h3>
              <a
                className={css.postButton}
                href={applyButtonTarget}
                target="_blank"
                rel="noopener noreferrer"
              >
                <Button className={css.postButton}>
                  <FormattedMessage id="CareersPage.applyButton" />
                </Button>
              </a>
            </section>
            <div className={css.imageContainer}>
              <img className={css.imgLeft} src={imgLeft} alt="Work with us"></img>
              <img className={css.imgMid} src={imgMid} alt="Work with us"></img>
              <img className={css.imgRight} src={imgRight} alt="Work with us"></img>
              <Arrow className={css.arrow} />
            </div>
            <section className={css.sectionBody}>
              <Markdown className={css.contentBody}>{jobData.body}</Markdown>
              <aside className={css.aside}>
                <ul className={css.infoList}>
                  <li>
                    <FormattedMessage id="CareersPage.location">
                      {(id) => <h4>{id}</h4>}
                    </FormattedMessage>
                    <p>{jobData.location || ''}</p>
                  </li>
                  <li>
                    <FormattedMessage id="CareersPage.department">
                      {(id) => <h4>{id}</h4>}
                    </FormattedMessage>
                    <p>{jobData.department || ''}</p>
                  </li>
                  <li>
                    <FormattedMessage id="CareersPage.employmentType">
                      {(id) => <h4>{id}</h4>}
                    </FormattedMessage>
                    <p>{jobData.employment_type || ''}</p>
                  </li>
                  <li>
                    <FormattedMessage id="CareersPage.minimumExperience">
                      {(id) => <h4>{id}</h4>}
                    </FormattedMessage>
                    <p>{jobData.minimum_experience || ''}</p>
                  </li>
                  {/* Share job post to socials */}
                  <li>
                    <div className={css.shareThisJob}>
                      <FormattedMessage id="CareersPage.shareTitle">
                        {(id) => <h4>{id}</h4>}
                      </FormattedMessage>
                      <a
                        className={css.fbIcon}
                        target="_blank"
                        href={`https://www.facebook.com/sharer/sharer.php?u=https://${host}/careers/${slug}`}
                        rel="noopener noreferrer"
                      >
                        <IconSocialMediaFacebook />
                      </a>
                      <a
                        className={css.liIcon}
                        href={`https://www.linkedin.com/shareArticle?url=https://${host}/careers/${slug}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <IconSocialMediaLinkedin />
                      </a>
                    </div>
                  </li>
                </ul>
              </aside>
            </section>
            <a
              className={css.applyButtonBottom}
              href={applyButtonTarget}
              target="_blank"
              rel="noopener noreferrer"
            >
              <Button>
                <FormattedMessage id="CareersPage.applyButton" />
              </Button>
            </a>
            <div className={css.shareThisJobMobile}>
              <FormattedMessage id="CareersPage.shareTitle">
                {(id) => <h4>{id}</h4>}
              </FormattedMessage>
              <a
                className={css.fbIcon}
                target="_blank"
                href={`https://www.facebook.com/sharer/sharer.php?u=https://beta.respaces.co/careers/${slug}`}
                rel="noopener noreferrer"
              >
                <IconSocialMediaFacebook />
              </a>
              <a
                className={css.liIcon}
                href={`https://www.linkedin.com/shareArticle?url=https://beta.respaces.co/careers/${slug}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <IconSocialMediaLinkedin />
              </a>
            </div>
          </LayoutWrapperMain>

          <LayoutWrapperFooter>
            <Footer />
          </LayoutWrapperFooter>
        </LayoutSingleColumn>
      </StaticPage>
    );
  }
}
