import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { arrayOf, bool, func, shape, string, oneOfType, node } from 'prop-types';
import { useIntl } from 'react-intl';

import { intlShape } from '../../../../util/reactIntl';
import { ComboBoxNew } from '../../../../components_new';
import { LISTING_CATEGORIES } from '../../config';

import { KEY_CODES } from '../../config';

import { ComboBoxClearButton } from '../index';

import css from './CategoryCombobox.module.scss';

const Item = ({ id, labelId, onClick, intl, isHighlighted, isMobile }) => (
  <div key={id}>
    {(id === 'fixed' || id === 'coworking') && (
      <>
        <div className={css.optionHeader}>
          {intl.formatMessage({ id: `ListingSearchForm.categoryComboBoxHeader.${id}` })}
        </div>
        <div className={css.separator}></div>
      </>
    )}
    <div
      className={classNames(
        css.optionWrapper,
        isHighlighted && css.highlighted,
        isMobile && css.mobile
      )}
      key={`category_combobox_item_${id}`}
      onClick={() => onClick(id)}
    >
      <div className={css.option}>
        <span className={css.optionName}>{intl?.formatMessage({ id: labelId })}</span>
      </div>
    </div>
  </div>
);

Item.propTypes = {
  id: string,
  labelId: string,
  onClick: func,
  intl: intlShape,
};

const CategoryComboBoxNew = ({
  id,
  name,
  categories,
  currentCategory,
  isMobile,
  isOpen,
  onChange,
  onFocus,
  onBlur,
  inputRef,
  className,
  focusedClassName,
  dropdownClassName,
}) => {
  const intl = useIntl();
  const [highlightedIndex, setHighlightedIndex] = useState(0);
  const [categoryValue, setCategoryValue] = useState('');

  useEffect(() => {
    const i = currentCategory ? categories.findIndex((c) => c.id === currentCategory) : 0;
    setHighlightedIndex(i);
  }, []);

  const inputProps = {
    id,
    name,
    inputRef,
    isOpen,
    className,
    focusedClassName,
    dropdownClassName,
    onFocus,
    onBlur,
    isMobile,
    readOnly: true,
    hideInput: isMobile,
    placeholder: intl.formatMessage({ id: 'MarketplaceConfig.filters.category' }),
    value: categoryValue,
  };

  useEffect(() => {
    if (!!currentCategory) {
      const id = LISTING_CATEGORIES.ID_TO_DATA[currentCategory]?.labelId;
      setCategoryValue(intl?.formatMessage({ id }));
    } else {
      setCategoryValue('');
    }
  }, [currentCategory]);

  categories = categories.map((category, i) => ({
    ...category,
    onClick: (val) => onSelectCategory({ category: val, i }),
    intl,
    isMobile,
    isHighlighted: i === highlightedIndex,
    key: `category_combobox_item_${category.id}`,
  }));

  const onSelectCategory = ({ category, i }) => {
    onBlur && onBlur(false);
    onChange(category);
    setHighlightedIndex(i);
  };

  const handleKeyDown = (e) => {
    e.preventDefault();
    const { key } = e;

    if (key === KEY_CODES.ESCAPE) {
      onBlur();
    } else if (key === KEY_CODES.TAB) {
      onChange(null);
    } else if (key === KEY_CODES.DOWN) {
      highlightedIndex < categories.length - 1 && setHighlightedIndex(highlightedIndex + 1);
    } else if (key === KEY_CODES.UP) {
      highlightedIndex > -1 && setHighlightedIndex(highlightedIndex - 1);
    } else if (key === KEY_CODES.ENTER) {
      const currentId = highlightedIndex >= 0 && categories[highlightedIndex].id;
      onChange(currentId);
      onBlur();
    }
  };

  return (
    <ComboBoxNew onKeyDown={handleKeyDown} {...inputProps}>
      <div className={classNames(css.dropdownWrapper, isMobile && css.mobile)}>
        {categories.map(Item)}
        {!!currentCategory && (
          <ComboBoxClearButton
            onClick={() => onSelectCategory({ category: null, i: 0 })}
            className={css.clearBtnWrapper}
            key={`category_combobox_item_clear`}
          />
        )}
      </div>
    </ComboBoxNew>
  );
};

CategoryComboBoxNew.defaultProps = {
  className: null,
  focusedClassName: null,
  dropdownClassName: null,
};

CategoryComboBoxNew.propTypes = {
  id: string.isRequired,
  name: string.isRequired,
  categories: arrayOf(shape(LISTING_CATEGORIES.SHAPE)).isRequired,
  currentCategory: string,
  isMobile: bool.isRequired,
  isOpen: bool.isRequired,
  onChange: func.isRequired,
  onFocus: func,
  onBlur: func,
  inputRef: oneOfType([func, shape({ node })]),
  className: string,
  focusedClassName: string,
  dropdownClassName: string,
};

export default CategoryComboBoxNew;
