import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { shallowEqual, useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { PopupModal, PrimaryButton, SecondaryButton } from '..';
import DropdownSelect from '../../components_new/DropdownSelect/DropdownSelect';
import { pauseSubscription } from '../../containers/SubscriptionManagementPage/SubscriptionManagementPage.duck';
import { convertWeekToResumeDate } from '../../containers/SubscriptionManagementPage/utils/subscription';
import { OutsideClickHandler } from '../../components';
import css from './PauseSubscriptionModal.module.scss';

const PauseSubscriptionModal = (props) => {
  const { isOpen, onClose, onManageDisableScrolling } = props;
  const intl = useIntl();
  const dispatch = useDispatch();
  const [selectTime, setSelectTime] = useState('1');
  const pauseInProgress = useSelector(
    (state) => state.SubscriptionManagementPage.actionSubscriptionInProgress
  );
  const pauseError = useSelector(
    (state) => state.SubscriptionManagementPage.actionSubscriptionError,
    shallowEqual
  );
  const onSelectTime = (value) => {
    setSelectTime(value);
  };
  const onSubmit = () => {
    const resumeDate = convertWeekToResumeDate(selectTime);
    dispatch(pauseSubscription({ resumeDate }));
  };
  return (
    <PopupModal
      id="pauseSubscriptionModal"
      isOpen={isOpen}
      onClose={onClose}
      modalContainer={css.modalContainer}
      onManageDisableScrolling={onManageDisableScrolling}
      hideCloseButton
    >
      <OutsideClickHandler onOutsideClick={onClose}>
        <div className={css.container}>
          <h2>{intl.formatMessage({ id: 'PauseSubscriptionModal.title' })}</h2>
          <p>{intl.formatMessage({ id: 'PauseSubscriptionModal.description' })}</p>
          <DropdownSelect
            intl={intl}
            options={[
              {
                value: '1',
                label: intl.formatMessage({ id: 'PauseSubscriptionModal.timeOption' }, { week: 1 }),
              },
              {
                value: '2',
                label: intl.formatMessage({ id: 'PauseSubscriptionModal.timeOption' }, { week: 2 }),
              },
              {
                value: '3',
                label: intl.formatMessage({ id: 'PauseSubscriptionModal.timeOption' }, { week: 3 }),
              },
              {
                value: '4',
                label: intl.formatMessage({ id: 'PauseSubscriptionModal.timeOption' }, { week: 4 }),
              },
              {
                value: '5',
                label: intl.formatMessage({ id: 'PauseSubscriptionModal.timeOption' }, { week: 5 }),
              },
              {
                value: '6',
                label: intl.formatMessage({ id: 'PauseSubscriptionModal.timeOption' }, { week: 6 }),
              },
              {
                value: '7',
                label: intl.formatMessage({ id: 'PauseSubscriptionModal.timeOption' }, { week: 7 }),
              },
              {
                value: '8',
                label: intl.formatMessage({ id: 'PauseSubscriptionModal.timeOption' }, { week: 8 }),
              },
            ]}
            onSelect={onSelectTime}
            selectedOption={selectTime}
          />
          <div className={css.bottomButtonWrapper}>
            <PrimaryButton type="button" className={css.noPauseBtn} onClick={onClose}>
              {intl.formatMessage({ id: 'PauseSubscriptionModal.noPause' })}
            </PrimaryButton>
            <SecondaryButton
              type="button"
              className={css.submitPauseBtn}
              onClick={() => {
                onSubmit();
                onClose();
              }}
              inProgress={pauseInProgress}
              disabled={pauseInProgress}
            >
              {intl.formatMessage({ id: 'PauseSubscriptionModal.submitPause' })}
            </SecondaryButton>
          </div>
        </div>
      </OutsideClickHandler>
    </PopupModal>
  );
};

PauseSubscriptionModal.defaultProps = {};

PauseSubscriptionModal.propTypes = {};
export default PauseSubscriptionModal;
