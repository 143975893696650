import React, { memo } from 'react';
import { MapWithAddress } from '../../components_new';
import { types as sdkTypes } from '../../util/sdkLoader';

import css from './LocationPage.module.scss';

const { UUID } = sdkTypes;

const SectionMap = memo(({ geolocation, id, address, intl }) => (
  <section className={css.section}>
    <h4 className={css.sectionTitle}>{intl.formatMessage({ id: 'LocationPage.map' })}</h4>
    <MapWithAddress
      geolocation={geolocation}
      id={new UUID(id)}
      address={address}
      className={css.map}
    />
  </section>
));

export default SectionMap;
