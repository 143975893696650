import React from 'react';
import classNames from 'classnames';
import image1 from './assets/rob.png';
import image2 from './assets/moa.png';
import image3 from './assets/venus.png';
import css from './PartnerProffessional.module.scss';

const SectionTesimonials = ({ intl }) => {
  return (
    <section className={classNames(css.sectionTesimonials)}>
      <div className={css.testimonial}>
        <img src={image1} alt="Rob" />
        <p>
          {intl.formatMessage({
            id: 'PartnerProffessional.SectionTestimonials.step1.text',
          })}
        </p>
        <h3 className={css.title}>
          {intl.formatMessage({
            id: 'PartnerProffessional.SectionTestimonials.step1.title',
          })}
        </h3>
      </div>
      <div className={css.testimonial}>
        <img src={image2} alt="Moa" />
        <p>
          {intl.formatMessage({
            id: 'PartnerProffessional.SectionTestimonials.step2.text',
          })}
        </p>
        <h3 className={css.title}>
          {intl.formatMessage({
            id: 'PartnerProffessional.SectionTestimonials.step2.title',
          })}
        </h3>
      </div>
      <div className={css.testimonial}>
        <img src={image3} alt="Venus" />
        <p>
          {intl.formatMessage({
            id: 'PartnerProffessional.SectionTestimonials.step3.text',
          })}
        </p>
        <h3 className={css.title}>
          {intl.formatMessage({
            id: 'PartnerProffessional.SectionTestimonials.step3.title',
          })}
        </h3>
      </div>
    </section>
  );
};

export default SectionTesimonials;
