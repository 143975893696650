import React from 'react';

import { ListingCollapsibleCard } from './ui';

import { getUnitTranslationKeyHasura } from '../../util/data';
import { createSlug } from '../../util/urlHelpers';

import css from './LocationPage.module.scss';

const SectionListings = ({
  listings,
  selectedListing,
  onSelectListing,
  isCompanyUser,
  isEmployeeUser,
  locationImages,
  intl,
}) => (
  <section className={css.section}>
    <h4 className={css.sectionTitle}>
      {intl.formatMessage({ id: 'LocationPage.availableListings' })}
    </h4>
    {listings &&
      listings.map((l) => (
        <ListingCollapsibleCard
          key={l.sharetribe_id}
          id={l.sharetribe_id}
          slug={createSlug(l.title)}
          price={l.price}
          description={intl.locale === 'sv' ? l.description_sv : l.description_en}
          vat={l.vat}
          image={
            l.drawings
              ? l.drawings[0]
              : l.images && l.images.length >= 1
              ? l.images[0]?.attributes?.variants['square-small']?.url
              : locationImages[0].medium
          }
          images={
            l.drawings ? l.drawings : l.images && l.images.length > 1 ? l.images : locationImages
          }
          useLocationImages={l.drawings || !(l.images && l.images.length > 1)}
          category={l.category}
          categoryTranslation={intl.formatMessage({
            id: `MarketplaceConfig.filters.category.${l.category}`,
          })}
          fixedOfficeType={l.fixed_office_type}
          unitTranslationKey={getUnitTranslationKeyHasura(l.booking_type)}
          amenities={l.ameneties}
          amenitiesInfo={l.ameneties_info}
          offer={l.offer}
          isOpen={selectedListing.id === l.sharetribe_id}
          onToggle={onSelectListing}
          showCredits={(isCompanyUser || isEmployeeUser) && l.category !== 'fixed'}
          minSeats={l.min_seats}
          maxSeats={l.max_seats}
          requestPrice={l.request_price}
          contractLength={l.contract_length_per_unit}
          contractLengthUnit={l.contract_length_unit}
          startDate={l.start_date}
          paymentPeriod={l.payment_period}
          allowVatExemptCompanies={l.allow_vat_exempt_companies}
          contractType={l.contract_type}
          noticePeriodPerUnit={l.notice_period_per_unit}
          noticePeriodUnit={l.notice_period_unit}
          noReqForContractLength={l.no_req_for_contract_length}
          areaMin={l.area_min}
          areaMax={l.area_max}
          isIntegrationPartner={!!l.officeAPI}
          vitecId={l.officeAPI?.provider === 'vitec' && l.officeAPI?.hostId}
          infoDescriptions={l.info_descriptions}
          calculatedArea={l.calculated_area}
          calculatedSeats={l.calculated_seats}
        />
      ))}
  </section>
);

export default SectionListings;
