import React from 'react';
import ContentLoader from 'react-content-loader';

const ContentLoaderDesktop = () => (
  <ContentLoader viewBox="0 0 998 336" width="998" height="336">
    <rect y="18" width="310" height="20" rx="4" />
    <rect x="405" y="18" width="100" height="20" rx="4" />
    <rect x="879" y="18" width="60" height="20" rx="4" />
    <rect x="603" y="18" width="150" height="20" rx="4" />
    <rect y="54.353" width="998" height="1.64706" />
    <rect y="74" width="310" height="20" rx="4" />
    <rect x="405" y="74" width="100" height="20" rx="4" />
    <rect x="879" y="74" width="60" height="20" rx="4" />
    <rect x="603" y="74" width="150" height="20" rx="4" />
    <rect y="110.353" width="998" height="1.64706" />
    <rect y="130" width="310" height="20" rx="4" />
    <rect x="405" y="130" width="100" height="20" rx="4" />
    <rect x="879" y="130" width="60" height="20" rx="4" />
    <rect x="603" y="130" width="150" height="20" rx="4" />
    <rect y="166.353" width="998" height="1.64706" />
    <rect y="186" width="310" height="20" rx="4" />
    <rect x="405" y="186" width="100" height="20" rx="4" />
    <rect x="879" y="186" width="60" height="20" rx="4" />
    <rect x="603" y="186" width="150" height="20" rx="4" />
    <rect y="222.353" width="998" height="1.64706" />
    <rect y="242" width="310" height="20" rx="4" />
    <rect x="405" y="242" width="100" height="20" rx="4" />
    <rect x="879" y="242" width="60" height="20" rx="4" />
    <rect x="603" y="242" width="150" height="20" rx="4" />
    <rect y="278.353" width="998" height="1.64706" />
    <rect y="298" width="310" height="20" rx="4" />
    <rect x="405" y="298" width="100" height="20" rx="4" />
    <rect x="879" y="298" width="60" height="20" rx="4" />
    <rect x="603" y="298" width="150" height="20" rx="4" />
    <rect y="334.353" width="998" height="1.64706" />
  </ContentLoader>
);

export default ContentLoaderDesktop;
