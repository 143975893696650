import React, { memo, useState } from 'react';
import { injectIntl } from 'react-intl';
import { func, string } from 'prop-types';

import StatusModel from '../../../../models/StatusModel';

import ActionDropdown from './ActionDropdown';

export const LISTING_TABLE_ACTIONS = {
  DUPLICATE: {
    id: 'duplicateListing',
    label: 'Table.action.duplicateListing',
  },
  EDIT: {
    id: 'editListing',
    label: 'Table.action.editListing',
  },
  UNASSIGN: {
    id: 'unassignListing',
    label: 'Table.action.unassignListing',
  },
  CLOSE: {
    id: 'closeListing',
    label: 'Table.action.closeListing',
  },
  OPEN: {
    id: 'openListing',
    label: 'Table.action.openListing',
  },
};

LISTING_TABLE_ACTIONS.LIST = [
  LISTING_TABLE_ACTIONS.DUPLICATE,
  LISTING_TABLE_ACTIONS.EDIT,
  LISTING_TABLE_ACTIONS.UNASSIGN,
  LISTING_TABLE_ACTIONS.CLOSE,
  LISTING_TABLE_ACTIONS.OPEN,
];

LISTING_TABLE_ACTIONS.ID_TO_DATA = {
  [LISTING_TABLE_ACTIONS.DUPLICATE.id]: LISTING_TABLE_ACTIONS.DUPLICATE,
  [LISTING_TABLE_ACTIONS.EDIT.id]: LISTING_TABLE_ACTIONS.EDIT,
  [LISTING_TABLE_ACTIONS.UNASSIGN.id]: LISTING_TABLE_ACTIONS.UNASSIGN,
  [LISTING_TABLE_ACTIONS.CLOSE.id]: LISTING_TABLE_ACTIONS.CLOSE,
  [LISTING_TABLE_ACTIONS.OPEN.id]: LISTING_TABLE_ACTIONS.OPEN,
};

const ListingTableAction = memo(({ onInitAction, status, isUnassigned, id, intl }) => {
  const [isOpen, setOpen] = useState(false);

  const handleInitAction = (payload) => {
    setOpen(false);
    onInitAction(payload);
  };

  return (
    <ActionDropdown isOpen={isOpen} onOpen={() => setOpen(true)} onClose={() => setOpen(false)}>
      <>
        {LISTING_TABLE_ACTIONS.LIST.map((action) =>
          (action.id === LISTING_TABLE_ACTIONS.CLOSE.id && status === StatusModel.PUBLISHED.id) ||
          (action.id === LISTING_TABLE_ACTIONS.OPEN.id && status === StatusModel.CLOSED.id) ||
          (action.id === LISTING_TABLE_ACTIONS.UNASSIGN.id && !isUnassigned) ||
          [LISTING_TABLE_ACTIONS.DUPLICATE.id, LISTING_TABLE_ACTIONS.EDIT.id].includes(
            action.id
          ) ? (
            <button
              onClick={() => handleInitAction({ action: action.id, id })}
              key={`listing_table_action_${action.id}`}
            >
              {intl.formatMessage({ id: action.label })}
            </button>
          ) : null
        )}
      </>
    </ActionDropdown>
  );
});

ListingTableAction.propTypes = {
  onInitAction: func,
  id: string,
};

export default injectIntl(ListingTableAction);
