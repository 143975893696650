import React, { useState } from 'react';
import coworkingImage from '../../assets/b2b_coworking.png';
import AddToCalendarGenerator from '../../util/AddToCalendarGenerator';
import classNames from 'classnames';

import css from './CreditBookingConfirmation.module.scss';

const CreditBookingConfirmation = (props) => {
  const [isCalendarModalOpen, setIsCalendarModalOpen] = useState(false);

  const { intl, transaction: transactionData, isRequested, locationName } = props;

  const title = intl.formatMessage({
    id: isRequested ? 'CreditBookingConfirmation.titleRequest' : 'CreditBookingConfirmation.title',
  });

  const content = intl.formatMessage(
    {
      id: !isRequested
        ? 'CreditBookingConfirmation.content'
        : 'CreditBookingConfirmation.contentRequested',
    },
    { title: locationName }
  );

  const calendarURLs = AddToCalendarGenerator({ ...transactionData, locationName });

  const calendarLinks = [
    {
      icon: '/static/icons/Calendar_Google.svg',
      textID: 'google',
      link: calendarURLs.google,
    },
    {
      icon: '/static/icons/Calendar_Outlook.svg',
      textID: 'outlook',
      link: calendarURLs.outlook,
    },
    {
      icon: '/static/icons/Calendar_Outlook.svg',
      textID: 'office365',
      link: calendarURLs.office365,
    },
    {
      icon: '/static/icons/Calendar_Apple.svg',
      textID: 'ics',
      link: calendarURLs.ics,
    },
  ];

  const CalendarLinksList = calendarLinks.map((link, index) => (
    <li className={css.linkButton} key={`transactionPanel.${link.icon}.link_${index}`}>
      <a href={link.link} target="_blank" rel="noreferrer noopener" className={css.linkContent}>
        <img className={css.icon} src={link.icon} alt={`${link.icon} icon`} />
        <span>{intl.formatMessage({ id: `orderConfirmationPage.link.${link.textID}` })}</span>
      </a>
    </li>
  ));

  return (
    <section
      className={css.confirmationWrapper}
      onClick={() => isCalendarModalOpen && setIsCalendarModalOpen(false)}
    >
      <h1 className={css.title}>{title}</h1>
      <p className={css.content}>{content}</p>
      <div className={css.imageWrapper}>
        <img src={coworkingImage} alt="your credit booking is confirmed" className={css.image} />
      </div>

      {!isRequested && (
        <div className={css.actionsWrapper}>
          <ul className={css.linksList}>
            <li
              className={classNames(css.linkButton, {
                [css.calendarIsOpen]: isCalendarModalOpen,
              })}
              key={`transactionPanel.addToCalendar.link`}
            >
              <div className={css.linkContent} onClick={() => setIsCalendarModalOpen(true)}>
                <img
                  className={css.icon}
                  src={'/static/icons/calendar.svg'}
                  alt={`Add to calendar`}
                />
                <span>{intl.formatMessage({ id: `orderConfirmationPage.link.calendar` })}</span>
              </div>
              {isCalendarModalOpen && (
                <ul className={css.calendarLinksWrapper}>{CalendarLinksList}</ul>
              )}
            </li>
          </ul>
          {/* Below hidden for now until functionality is available */}
          {/* <div className={css.actionItem}>
          <Icons.IconPrinter className={css.actionIcon} />
          <span className={css.actionText}>{printReceipt}</span>
        </div>
        <div className={css.actionItem}>
          <Icons.IconUser className={css.actionIcon} />
          <span className={css.actionText}>{inviteColleague}</span>
        </div> */}
        </div>
      )}
    </section>
  );
};

export default CreditBookingConfirmation;
