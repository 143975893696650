import React, { useEffect, useState } from 'react';
import { injectIntl } from 'react-intl';
import { CSSTransition } from 'react-transition-group';
import salesTeamImg from '../../assets/salesTeam.png';

import { ButtonNew } from '../../components_new';
import { BUTTON_SIZES, BUTTON_TYPES, LINK_COLORS } from '../../components_new/ButtonNew/ButtonNew';
import { inBrowser } from '../../util/device';

import css from './TypeFormWidget.module.scss';
import './animation.scss';
import classNames from 'classnames';

const TypeFormWidget = ({
  id,
  title,
  text,
  closeable,
  intl,
  setShowWidget,
  flexTypeFormWidget,
}) => {
  const [visible, setVisible] = useState(false);

  const closeWidget = () => {
    if (inBrowser()) {
      window.sessionStorage.setItem(id, '1');
      setVisible(false);
      setShowWidget(false);
    }
  };

  useEffect(() => {
    setVisible(!Boolean(window.sessionStorage.getItem(id)));
  }, []);

  if (visible)
    return (
      <CSSTransition in={visible} timeout={500} classNames="widget" unmountOnExit>
        <div className={classNames(css.widget, flexTypeFormWidget && css.flexTypeForm)}>
          <div className={css.avatars}>
            <img src={salesTeamImg} alt="Sales team"></img>
          </div>
          <div className={css.top}>
            <h4 className={css.title}>{title}</h4>
            <p className={css.text}>{text}</p>
          </div>
          <div className={css.bottom}>
            <ButtonNew
              name="OnboardingPage"
              type={BUTTON_TYPES.WHITE}
              size={BUTTON_SIZES.MEDIUM}
              linkColor={LINK_COLORS.GREEN}
            >
              {intl.formatMessage({ id: 'Widget.TypeForm.buttonText' })}
            </ButtonNew>
            <p className={css.span}>
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g>
                  <path
                    d="M9.99922 16.8C6.23922 16.8 3.19922 13.76 3.19922 9.99995C3.19922 6.23995 6.23922 3.19995 9.99922 3.19995C13.7592 3.19995 16.7992 6.23995 16.7992 9.99995C16.7992 13.76 13.7592 16.8 9.99922 16.8ZM9.99922 3.99995C6.67922 3.99995 3.99922 6.67995 3.99922 9.99995C3.99922 13.32 6.67922 16 9.99922 16C13.3192 16 15.9992 13.32 15.9992 9.99995C15.9992 6.67995 13.3192 3.99995 9.99922 3.99995Z"
                    fill="white"
                  />
                  <path
                    d="M12.1196 13.48L9.59961 10.96V6.40002H10.3996V10.64L12.6796 12.92L12.1196 13.48Z"
                    fill="white"
                  />
                </g>
              </svg>
              <span>{'<'} 2 min</span>
            </p>
          </div>
          {closeable && (
            <button onClick={closeWidget} className={css.closeButton}>
              <svg fill="none" xmlns="http://www.w3.org/2000/svg">
                <line
                  x1="12.0293"
                  y1="1.06066"
                  x2="1.06127"
                  y2="12.0287"
                  stroke="white"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                />
                <line
                  x1="0.75"
                  y1="-0.75"
                  x2="16.2611"
                  y2="-0.75"
                  transform="matrix(-0.707109 -0.707105 0.707108 -0.707105 13 12.0288)"
                  stroke="white"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                />
              </svg>
            </button>
          )}
        </div>
      </CSSTransition>
    );
  else return null;
};

export default injectIntl(TypeFormWidget);
