import get from 'lodash/get';
import moment from 'moment';
import { ensureCurrentUser } from './data';
import { CREDITS_TYPE_UNLIMITED, STATUS_REGISTERED } from './types';

export const isUnlimitedCredits = (currentUser) => {
  const ensuredCurrentUser = ensureCurrentUser(currentUser);
  const { creditsDistributionType } = get(ensuredCurrentUser, 'attributes.profile.metadata', {});
  return creditsDistributionType === CREDITS_TYPE_UNLIMITED;
};

export const calcCompanyUsedCredits = (companyUser) => {
  const teamMemberData = get(companyUser, 'attributes.profile.metadata.teamMemberData', {});
  const teamMember = Object.values(teamMemberData);
  const registeredMember = teamMember.filter((member) => member.status === STATUS_REGISTERED);
  return registeredMember.reduce((sum, member) => sum + member.usedCredits, 0);
};

export const isEmployeeUser = (currentUser) => {
  const ensuredCurrentUser = ensureCurrentUser(currentUser);
  return !!get(ensuredCurrentUser, 'attributes.profile.protectedData.companyAccountId', null);
};

export const isCompanyUser = (currentUser) => {
  const ensuredCurrentUser = ensureCurrentUser(currentUser);
  return get(ensuredCurrentUser, 'attributes.profile.protectedData.isCompanyAccount', false);
};

/** Returns renewal date
 *
 *
 * @param {Object} companyUser
 * @param {String} formatString
 * @returns {String} renewal date
 */
export const calcRenewalDate = (companyUser, formatString = 'YYYY-MM-DD') => {
  const { subscriptionStartTimestamp } = get(companyUser, 'attributes.profile.metadata', {});
  const diffMonth = moment().diff(moment(subscriptionStartTimestamp), 'months', false) + 1;
  return moment(subscriptionStartTimestamp).add(diffMonth, 'month').format(formatString);
};

export const calcRenewalDayLeft = (companyUser) => {
  const { subscriptionStartTimestamp } = get(companyUser, 'attributes.profile.metadata', {});
  const diffMonth = moment().diff(moment(subscriptionStartTimestamp), 'months', false) + 1;
  const nextRenewalDate = moment(subscriptionStartTimestamp).add(diffMonth, 'month');
  return -moment().diff(nextRenewalDate, 'days');
};

export const invoiceStatusAdapter = (status, intl) => {
  switch (status) {
    case 'paid': {
      return intl.formatMessage({ id: 'PaymentHistoryTab.paid' });
    }
    case 'payment_due': {
      return intl.formatMessage({ id: 'PaymentHistoryTab.paymentDue' });
    }
    case 'not_paid': {
      return intl.formatMessage({ id: 'PaymentHistoryTab.notPaid' });
    }
    case 'pending': {
      return intl.formatMessage({ id: 'PaymentHistoryTab.pending' });
    }
    case 'voided': {
      return intl.formatMessage({ id: 'PaymentHistoryTab.voided' });
    }
    case 'posted': {
      return intl.formatMessage({ id: 'PaymentHistoryTab.posted' });
    }
    default: {
      return '-';
    }
  }
};

export const getCreditsLeftAndLimit = (currentUser, companyAccount) => {
  const { remainedCredits = 0, distributedCredits = 0 } = get(
    currentUser,
    'attributes.profile.metadata',
    {}
  );
  const totalUsedCredits = companyAccount ? calcCompanyUsedCredits(companyAccount) : null;
  const totalCredits = get(companyAccount, 'attributes.profile.metadata.totalCredits', null);

  const previousUnusedCredits = get(
    companyAccount,
    'attributes.profile.metadata.previousUnusedCredits',
    0
  );

  const hasUnlimitedCredits = isUnlimitedCredits(companyAccount);

  let currentCredits, creditLimit;

  if (hasUnlimitedCredits || isCompanyUser(currentUser)) {
    currentCredits = totalCredits + previousUnusedCredits - totalUsedCredits;
    creditLimit = totalCredits + previousUnusedCredits;
  } else if (totalCredits + previousUnusedCredits - totalUsedCredits < remainedCredits) {
    currentCredits = totalCredits + previousUnusedCredits - totalUsedCredits;
    creditLimit = distributedCredits;
  } else {
    currentCredits = remainedCredits;
    creditLimit = distributedCredits;
  }

  return {
    currentCredits,
    creditLimit,
  };
};
