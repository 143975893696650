import React, { useState } from 'react';
import classNames from 'classnames';
import css from './EditListingOverviewPanel.module.scss';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  ListingImageSlider,
  LanguageSelect,
  FixedBottomButtons,
  NamedLink,
  SaveAndExitButton,
  IconSpinner,
} from '..';
import config from '../../config';
import { findOptionsForSelectFilter } from '../../util/search';
import get from 'lodash/get';
import { formatMoney } from '../../util/currency';
import { formatLocation } from '../../util/data';
import { useBoolean, useViewport } from '../../util/hooks';
import { MAX_MOBILE_SCREEN_WIDTH } from '../../util/device';
import { useParams } from 'react-router';
import {
  AMENITIES,
  AVAILABILITY,
  LOCATION,
  PHOTOS,
  WORKSPACE,
  EMAIL,
} from '../EditListingWizard/EditListingWizardTab';
import { modernMTTranslate } from '../../util/modernmt';
import TranslatedTextForm from './TranslatedTextForm';

const formatTime = (startTime = '', endTime = '') => {
  return `${startTime} ${startTime && endTime ? '-' : ''} ${endTime}`;
};

const EditListingOverviewPanel = (props) => {
  const {
    className,
    rootClass,
    listing,
    filterConfig,
    addonList,
    onSubmit,
    onSaveAndExitListingWizardTab,
  } = props;
  const intl = useIntl();
  const params = useParams();
  const { width } = useViewport();
  const [translatedText, setTranslatedText] = useState();
  const [translationLang, setTranslationLang] = useState();
  const [isTranslating, setTranslating] = useBoolean();
  const isMobile = width < MAX_MOBILE_SCREEN_WIDTH;
  const listingTitle = get(listing, 'attributes.title', 'Name');
  const description = get(listing, 'attributes.description');
  const publicData = get(listing, 'attributes.publicData', {});
  const {
    category,
    ameneties = [],
    amenetiesInfo = {},
    additionalServices = [],
    operationHours = {},
    location = {},
    originLang,
    accessDetails,
    accessOption,
    accessWorkspaceFloor,
    bookingEmailAddress,
    wifiName,
    wifiPassword,
  } = publicData;
  const { address } = location;

  const options = findOptionsForSelectFilter('ameneties', filterConfig);
  const amenitiesItem = options.filter((option) => ameneties.includes(option.key));
  const addonsItem = additionalServices.reduce((services, service) => {
    const addon = addonList.find((o) => o.id === service.name);
    return [
      ...services,
      {
        ...service,
        labelId: addon.labelId,
        iconSrc: addon.iconSrc,
      },
    ];
  }, []);

  const classes = classNames(rootClass || css.root, className);
  const panelTitle = isMobile
    ? intl.formatMessage({ id: 'EditListingOverviewPanel.panelTitle' })
    : intl.formatMessage({ id: 'EditListingOverviewPanel.panelTitleDesktop' });
  const editText = intl.formatMessage({ id: 'EditListingOverviewPanel.editButton' });
  const listingNameTitle = intl.formatMessage({ id: 'EditListingOverviewPanel.listingNameTitle' });
  const categoryTitle = intl.formatMessage({ id: 'EditListingOverviewPanel.categoryTitle' });
  const photoTitle = intl.formatMessage({ id: 'EditListingOverviewPanel.photoTitle' });
  const amenitiesTitle = intl.formatMessage({ id: 'EditListingOverviewPanel.amenitiesTitle' });
  const descriptionTitle = intl.formatMessage({ id: 'EditListingOverviewPanel.descriptionTitle' });
  const addonsTitle = intl.formatMessage({ id: 'EditListingOverviewPanel.addonsTitle' });
  const openingHoursTitle = intl.formatMessage({
    id: 'EditListingOverviewPanel.openingHoursTitle',
  });
  const addressTitle = intl.formatMessage({ id: 'EditListingOverviewPanel.addressTitle' });

  const accessTitle = intl.formatMessage({ id: 'EditListingOverviewPanel.accessTitle' });
  const accessLabel = intl.formatMessage({ id: 'EditListingOverviewPanel.accessLabel' });
  const accessWorkspaceLabel = intl.formatMessage({
    id: 'EditListingOverviewPanel.accessWorkspaceLabel',
  });
  const accessValue = accessOption
    ? intl.formatMessage({
        id: `EditListingOverviewPanel.accessValue.${accessOption}`,
      })
    : '';
  const accessWorkspaceValue = accessDetails
    ? intl.formatMessage(
        {
          id: `EditListingOverviewPanel.accessWorkspaceValue.${accessDetails}`,
        },
        {
          floor:
            accessWorkspaceFloor && intl.formatMessage({ id: 'EditListingOverviewPanel.floor' }),
          level: accessWorkspaceFloor,
        }
      )
    : '';
  const primaryEmailLabel = intl.formatMessage({
    id: `EditListingOverviewPanel.primaryEmailLabel`,
  });
  const secondaryEmailLabel = intl.formatMessage({
    id: `EditListingOverviewPanel.secondaryEmailLabel`,
  });
  const wifiNameLabel = intl.formatMessage({ id: `EditListingOverviewPanel.wifiNameLabel` });
  const wifiPasswordLabel = intl.formatMessage({
    id: `EditListingOverviewPanel.wifiPasswordLabel`,
  });

  const images = get(listing, 'images');

  const tabLink = (tab) => {
    return { name: 'EditListingPage', params: { ...params, tab } };
  };

  const handleTranslation = async (lang) => {
    try {
      if (lang) {
        if (!translatedText) {
          setTranslating.on();
          const translatedDescription = await modernMTTranslate({
            source: originLang,
            target: lang,
            text: description,
          });

          const textsForAmenitiesTranslation = [];
          const amenitiesIndexList = {};
          ameneties.forEach((item, i) => {
            textsForAmenitiesTranslation.push(amenetiesInfo[item]?.description);
            amenitiesIndexList[item] = i;
          });

          const translatedAmenities = await modernMTTranslate({
            source: originLang,
            target: lang,
            text: textsForAmenitiesTranslation,
          });

          const updatedAmenities = ameneties.reduce((acc, cur) => {
            return amenetiesInfo[cur].description
              ? {
                  ...acc,
                  [cur]: translatedAmenities[amenitiesIndexList[cur]]?.translation,
                }
              : acc;
          }, {});

          setTranslatedText({
            [lang]: {
              description: translatedDescription.translation,
              ameneties: updatedAmenities,
            },
          });
          setTranslating.off();
        }
        setTranslationLang(lang);
      } else {
        setTranslationLang('');
      }
    } catch (e) {
      console.error('Translate failed - ', e);
    }
  };
  const handleSubmit = () => {
    const publicData = {
      ...translatedText,
    };
    onSubmit({
      publicData,
    });
  };
  const onSaveAndExitHanler = () => {
    const publicData = {
      ...translatedText,
    };
    onSaveAndExitListingWizardTab({ publicData });
  };

  const handleTranslatedFormSubmit = ({ translatedDescription }) => {
    setTranslatedText({
      ...translatedText,
      [translationLang]: {
        ...translatedText[translationLang],
        description: translatedDescription,
      },
    });
  };

  return (
    <div className={classes}>
      <SaveAndExitButton onClick={onSaveAndExitHanler} />
      <div className={css.panelContainer}>
        <div className={css.panelTitle}>{panelTitle}</div>
        <p className={css.subTitle}>
          {intl.formatMessage({ id: 'EditListingOverviewPanel.panelSubTitle' })}
        </p>
        {!isMobile && (
          <>
            <div className={css.sectionWrapper}>
              <div className={css.titleWrapper}>
                <div className={css.title}>{listingNameTitle}</div>
                <div className={css.editBtn}>
                  <NamedLink {...tabLink(WORKSPACE)}>{editText}</NamedLink>
                </div>
              </div>
              <div className={css.description}>{listingTitle}</div>
            </div>
            <div className={css.sectionWrapper}>
              <div className={css.titleWrapper}>
                <div className={css.title}>{categoryTitle}</div>
                <div className={css.editBtn}>
                  <NamedLink {...tabLink(WORKSPACE)}>{editText}</NamedLink>
                </div>
              </div>
              <div className={css.description}>
                {intl.formatMessage({ id: `EditListingOverviewPanel.category.${category}` })}
              </div>
            </div>
          </>
        )}
        {isMobile && (
          <div className={css.languageSelectWrapper}>
            <LanguageSelect initialLangValue={originLang} translateCb={handleTranslation} />
          </div>
        )}
        <div className={css.imageSwiperWrapper}>
          {!isMobile && (
            <div className={css.titleWrapper}>
              <div className={css.title}>{photoTitle}</div>
              <div className={css.editBtn}>
                <NamedLink {...tabLink(PHOTOS)}>{editText}</NamedLink>
              </div>
            </div>
          )}
          <ListingImageSlider data={{ images }} isMobile={isMobile} />
        </div>
        {isMobile && (
          <>
            <div className={css.sectionWrapper}>
              <div className={css.titleWrapper}>
                <div className={css.title}>{listingTitle}</div>
                <div className={css.editBtn}>
                  <NamedLink {...tabLink(WORKSPACE)}>{editText}</NamedLink>
                </div>
              </div>
              <div className={css.listingCategory}>
                {' '}
                {intl.formatMessage({ id: `EditListingOverviewPanel.category.${category}` })}
              </div>
            </div>
          </>
        )}

        <div className={css.sectionWrapper}>
          <div className={css.titleWrapper}>
            <div className={css.title}>{amenitiesTitle}</div>
            <div className={css.editBtn}>
              <NamedLink {...tabLink(AMENITIES)}>{editText}</NamedLink>
            </div>
          </div>
          {amenitiesItem.map((item) => (
            <div key={item.key} className={css.amenity}>
              {intl.formatMessage({ id: item.labelId })}
            </div>
          ))}
        </div>
        <div className={css.sectionWrapper}>
          <div className={css.titleWrapper}>
            <div className={css.title}>
              {isMobile ? (
                descriptionTitle
              ) : (
                <div className={css.row}>
                  <span className={css.titleInRow}>{descriptionTitle}</span>
                  <LanguageSelect initialLangValue={originLang} translateCb={handleTranslation} />
                </div>
              )}
            </div>
            <div className={css.editBtn}>
              <NamedLink {...tabLink(WORKSPACE)}>{editText}</NamedLink>
            </div>
          </div>
          {isTranslating ? (
            <div className={css.loading}>
              <IconSpinner />
            </div>
          ) : translationLang ? (
            <TranslatedTextForm
              initialValues={{ translatedDescription: translatedText[translationLang].description }}
              onSubmit={handleTranslatedFormSubmit}
            />
          ) : (
            <div className={css.description}>
              {translationLang ? translatedText[translationLang].description : description}
            </div>
          )}
        </div>
        <div className={css.sectionWrapper}>
          <div className={css.titleWrapper}>
            <div className={css.title}>{addonsTitle}</div>
            <div className={css.editBtn}>
              <NamedLink {...tabLink(AMENITIES)}>{editText}</NamedLink>
            </div>
          </div>
          <div className={css.addons}>
            {addonsItem.map((item) => (
              <div key={item.name} className={css.addon}>
                <div className={css.row}>
                  <div className={css.addonName}>{intl.formatMessage({ id: item.labelId })}</div>
                  <div className={css.addonPrice}>{formatMoney(intl, item.price)}</div>
                </div>
                <div className={css.row}>
                  <div className={css.addonDesc}>
                    {`${item.description ? item.description : ''} ${formatTime(
                      item.serviceStartTime,
                      item.serviceEndTime
                    )}`}
                    &nbsp;
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className={css.sectionWrapper}>
          <div className={css.titleWrapper}>
            <div className={css.title}>{openingHoursTitle}</div>
            <div className={css.editBtn}>
              <NamedLink {...tabLink(AVAILABILITY)}>{editText}</NamedLink>
            </div>
          </div>
          <div className={css.openingHours}>
            {Object.keys(operationHours).map(
              (hourBlock) =>
                operationHours[hourBlock].openTime &&
                operationHours[hourBlock].closeTime && (
                  <div key={hourBlock} className={css.openingHour}>
                    <div className={css.dayOfWeek}>
                      {intl.formatMessage({
                        id: `EditListingAvailabilityPlanForm.dayOfWeek.${hourBlock}`,
                      })}
                    </div>
                    <div className={css.openingTime}>
                      {formatTime(
                        operationHours[hourBlock].openTime,
                        operationHours[hourBlock].closeTime
                      )}
                    </div>
                  </div>
                )
            )}
          </div>
        </div>
        {address && (
          <div className={css.sectionWrapper}>
            <div className={css.titleWrapper}>
              <div className={css.title}>{addressTitle}</div>
              <div className={css.editBtn}>
                <NamedLink {...tabLink(LOCATION)}>{editText}</NamedLink>
              </div>
            </div>
            <div className={css.address}>{formatLocation(address)}</div>
          </div>
        )}
        <div className={classNames(css.sectionWrapper, css.noBorder)}>
          <div className={css.titleWrapper}>
            <div className={css.title}>{accessTitle}</div>
            <div className={css.editBtn}>
              <NamedLink {...tabLink(EMAIL)}>{editText}</NamedLink>
            </div>
          </div>
          {accessOption && (
            <div className={css.access}>
              <span className={css.bold}>{accessLabel}</span>
              {accessValue}
            </div>
          )}
          {accessDetails && (
            <div className={css.access}>
              <span className={css.bold}>{accessWorkspaceLabel}</span>
              {accessWorkspaceValue}
            </div>
          )}
          {bookingEmailAddress && bookingEmailAddress[0] && (
            <>
              <div className={css.email}>
                <span className={css.bold}>{primaryEmailLabel}</span>
                {bookingEmailAddress[0]}
              </div>
              {bookingEmailAddress[1] && (
                <div className={css.email}>
                  <span className={css.bold}>{secondaryEmailLabel}</span>
                  {bookingEmailAddress[1]}
                </div>
              )}
            </>
          )}
          {wifiName && (
            <>
              <div className={css.wifi}>
                <span className={css.bold}>{wifiNameLabel}</span>
                {wifiName}
              </div>
              <div className={css.wifi}>
                <span className={css.bold}>{wifiPasswordLabel}</span>
                {wifiPassword}
              </div>
            </>
          )}
        </div>
        <FixedBottomButtons
          leftBtnText={intl.formatMessage({ id: 'EditListing.backButton' })}
          rightBtnText={intl.formatMessage({ id: 'EditListing.finishButton' })}
          rightBtnHandleClick={handleSubmit}
        />
      </div>
    </div>
  );
};

EditListingOverviewPanel.defaultProps = {
  filterConfig: config.custom.filters,
  addonList: config.custom.addonsList,
};
export default EditListingOverviewPanel;
