import React from 'react';
import css from './CompanyAccountPage.module.scss';
import { OrganizationDetailForm } from '../../forms';

const OrganizationDetailTab = (props) => {
  const {
    intl,
    setCurrentTab,
    onSubmit,
    signupInProgress,
    isCurrentTab = false,
    isPassed = false,
    isMobileLayout = false,
    isAuthenticated,
    signupError,
    selectedPackage,
    additionalCompanyDetails,
  } = props;

  if (!isCurrentTab && isMobileLayout) {
    return null;
  }

  return (
    <div className={css.tabContainer}>
      <OrganizationDetailForm
        intl={intl}
        onSubmit={onSubmit}
        isCurrentTab={isCurrentTab}
        isPassed={isPassed}
        isMobileLayout={isMobileLayout}
        signupInProgress={signupInProgress}
        setCurrentTab={setCurrentTab}
        isAuthenticated={isAuthenticated}
        signupError={signupError}
        selectedPackage={selectedPackage}
        additionalCompanyDetails={additionalCompanyDetails}
      />
    </div>
  );
};

export default OrganizationDetailTab;
