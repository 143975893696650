import { bool, func } from 'prop-types';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  PopupModal,
  EditListingIcons as Icons,
  CreateListingIllustrationImage,
  NamedLink,
} from '..';
import css from './CreateListingIntroductionModal.module.scss';

const CreateListingIntroductionModal = ({ isOpen, onClose }) => {
  const intl = useIntl();

  return (
    <PopupModal
      id="CreateListingIntroductionModal"
      isOpen={isOpen}
      onClose={onClose}
      modalContainer={css.modalContainer}
      hideCloseButton
    >
      <div className={css.introductionModalContainer}>
        <div className={css.modalTitle}>
          {intl.formatMessage({ id: 'CreateListingIntroductionModal.modalTitle' })}
        </div>
        <div className={css.row}>
          <Icons.ClockIcon className={css.clockIcon} />
          <div className={css.estimatedTime}>
            <FormattedMessage
              id="CreateListingIntroductionModal.estimatedTime"
              values={{
                span: (msg) => <span className={css.boldText}>{msg}</span>,
              }}
            />
          </div>
        </div>
        <div className={css.row}>
          <div className={css.getStarted}>
            <div className={css.title}>
              {intl.formatMessage({ id: 'CreateListingIntroductionModal.getStartedTitle' })}
            </div>
            <br />
            <ul>
              <li>
                {intl.formatMessage({ id: 'CreateListingIntroductionModal.getStartedText1' })}
              </li>
              <li>
                {intl.formatMessage({ id: 'CreateListingIntroductionModal.getStartedText2' })}
              </li>
              <li>
                {intl.formatMessage({ id: 'CreateListingIntroductionModal.getStartedText3' })}
              </li>
            </ul>
          </div>
          <div className={css.illustrationImage}>
            <CreateListingIllustrationImage />
          </div>
        </div>
        <h2 className={css.question}>
          {intl.formatMessage({ id: 'CreateListingIntroductionModal.question' })}
        </h2>
        <p className={css.answer}>
          {intl.formatMessage({ id: 'CreateListingIntroductionModal.answer' })}
        </p>
        <NamedLink className={css.addSpace} name="NewListingPage">
          {intl.formatMessage({ id: 'CreateListingIntroductionModal.getStartedBtn' })}
        </NamedLink>
      </div>
    </PopupModal>
  );
};

CreateListingIntroductionModal.propTypes = {
  isOpen: bool.isRequired,
  onClose: func.isRequired,
};

export default CreateListingIntroductionModal;
