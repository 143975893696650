import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';
import { LISTING_STATE_DRAFT } from '../../util/types';
import { ensureOwnListing } from '../../util/data';
import { ListingLink, LocationMap } from '../../components';
import { EditListingLocationFormNew } from '../../forms';
import debounce from 'lodash/debounce';
import css from './EditListingLocationPanel.module.scss';
import { withRouter } from 'react-router-dom';
const SEARCH_WITH_MAP_DEBOUNCE = 300; // Little bit of debounce before search is initiated.

const CurrentLocation = (props) => {
  const { onClick } = props;
  return (
    <div className={css.currentLocationWrapper} onClick={onClick}>
      <svg
        className={css.currentLocation}
        width={16}
        height={16}
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M8 11C8.79565 11 9.55871 10.6839 10.1213 10.1213C10.6839 9.55871 11 8.79565 11 8C11 7.20435 10.6839 6.44129 10.1213 5.87868C9.55871 5.31607 8.79565 5 8 5C7.20435 5 6.44129 5.31607 5.87868 5.87868C5.31607 6.44129 5 7.20435 5 8C5 8.79565 5.31607 9.55871 5.87868 10.1213C6.44129 10.6839 7.20435 11 8 11ZM8.5 0.5C8.5 0.367392 8.44732 0.240215 8.35355 0.146447C8.25979 0.0526785 8.13261 0 8 0C7.86739 0 7.74021 0.0526785 7.64645 0.146447C7.55268 0.240215 7.5 0.367392 7.5 0.5V2.02C6.08618 2.13824 4.75998 2.75357 3.75678 3.75678C2.75357 4.75998 2.13824 6.08618 2.02 7.5H0.5C0.367392 7.5 0.240215 7.55268 0.146447 7.64645C0.0526785 7.74021 0 7.86739 0 8C0 8.13261 0.0526785 8.25979 0.146447 8.35355C0.240215 8.44732 0.367392 8.5 0.5 8.5H2.02C2.13824 9.91382 2.75357 11.24 3.75678 12.2432C4.75998 13.2464 6.08618 13.8618 7.5 13.98V15.5C7.5 15.6326 7.55268 15.7598 7.64645 15.8536C7.74021 15.9473 7.86739 16 8 16C8.13261 16 8.25979 15.9473 8.35355 15.8536C8.44732 15.7598 8.5 15.6326 8.5 15.5V13.98C9.91374 13.8616 11.2398 13.2462 12.243 12.243C13.2462 11.2398 13.8616 9.91374 13.98 8.5H15.5C15.6326 8.5 15.7598 8.44732 15.8536 8.35355C15.9473 8.25979 16 8.13261 16 8C16 7.86739 15.9473 7.74021 15.8536 7.64645C15.7598 7.55268 15.6326 7.5 15.5 7.5H13.98C13.8618 6.08618 13.2464 4.75998 12.2432 3.75678C11.24 2.75357 9.91382 2.13824 8.5 2.02V0.5ZM8 13C6.67392 13 5.40215 12.4732 4.46447 11.5355C3.52678 10.5979 3 9.32608 3 8C3 6.67392 3.52678 5.40215 4.46447 4.46447C5.40215 3.52678 6.67392 3 8 3C9.32608 3 10.5979 3.52678 11.5355 4.46447C12.4732 5.40215 13 6.67392 13 8C13 9.32608 12.4732 10.5979 11.5355 11.5355C10.5979 12.4732 9.32608 13 8 13Z"
          fill="black"
        />
      </svg>
    </div>
  );
};
class EditListingLocationPanel extends Component {
  constructor(props) {
    super(props);

    this.getInitialValues = this.getInitialValues.bind(this);
    this.state = {
      initialValues: this.getInitialValues(),
    };
    this.onMapMoveEnd = debounce(this.onMapMoveEnd.bind(this), SEARCH_WITH_MAP_DEBOUNCE);
  }

  getInitialValues() {
    const { listing } = this.props;
    const currentListing = ensureOwnListing(listing);
    const { geolocation, publicData } = currentListing.attributes;

    // Only render current search if full place object is available in the URL params
    // TODO bounds are missing - those need to be queried directly from Google Places
    const locationFieldsPresent =
      publicData && publicData.location && publicData.location.address && geolocation;
    const location = publicData && publicData.location ? publicData.location : {};
    const { address, bounds } = location;

    return {
      location: locationFieldsPresent
        ? {
            search: address,
            selectedPlace: { address, origin: geolocation, bounds },
          }
        : {
            search: '',
            selectedPlace: { address: '', origin: null, bounds: null },
          },
    };
  }
  // Callback to determine if new search is needed
  // when map is moved by user or viewport has changed
  onMapMoveEnd(viewportBoundsChanged, data) {}

  onSearchMapChange = (values) => {
    const { selectedPlace } = values;
    const predictionsKey = 'predictions';
    if (selectedPlace) {
      delete values[predictionsKey];
      this.setState({
        initialValues: {
          location: values,
        },
      });
    }
  };

  render() {
    const {
      className,
      rootClassName,
      listing,
      disabled,
      ready,
      onSubmit,
      onChange,
      submitButtonText,
      panelUpdated,
      updateInProgress,
      errors,
      location,
      onSaveAndExitListingWizardTab,
      intl,
    } = this.props;

    const classes = classNames(rootClassName || css.root, className);
    const currentListing = ensureOwnListing(listing);

    const isPublished =
      currentListing.id && currentListing.attributes.state !== LISTING_STATE_DRAFT;

    const { bounds, origin } = this.state.initialValues.location.selectedPlace;

    const onSaveAndExit = (values) => {
      const { location } = values;
      const {
        selectedPlace: { address, origin, bounds },
      } = location;
      const updateValues = {
        geolocation: origin,
        publicData: {
          location: {
            address,
            bounds,
          },
        },
      };
      onSaveAndExitListingWizardTab(updateValues);
    };

    const onSubmitHandler = (values) => {
      const { location } = values;
      const {
        selectedPlace: { address, origin, bounds },
      } = location;
      const updateValues = {
        geolocation: origin,
        publicData: {
          location: {
            address,
            bounds,
          },
        },
      };

      this.setState({
        initialValues: {
          location: { search: address, selectedPlace: { address, origin, bounds } },
        },
      });

      onSubmit(updateValues);
    };
    return (
      <div className={classes}>
        <LocationMap
          reusableContainerClassName={css.map}
          bounds={bounds}
          center={origin}
          location={location}
          onMapMoveEnd={this.onMapMoveEnd}
          messages={intl.messages}
        />
        <EditListingLocationFormNew
          className={css.form}
          initialValues={this.state.initialValues}
          onSubmit={(values) => {
            onSubmitHandler(values);
          }}
          onChange={onChange}
          saveActionMsg={submitButtonText}
          disabled={disabled}
          ready={ready}
          updated={panelUpdated}
          updateInProgress={updateInProgress}
          fetchErrors={errors}
          onSearchMapChange={this.onSearchMapChange}
          onSaveAndExit={onSaveAndExit}
          isPublished={isPublished}
        />
        <CurrentLocation />
      </div>
    );
  }
}

const { func, object, string, bool } = PropTypes;

EditListingLocationPanel.defaultProps = {
  className: null,
  rootClassName: null,
  listing: null,
};

EditListingLocationPanel.propTypes = {
  className: string,
  rootClassName: string,

  // We cannot use propTypes.listing since the listing might be a draft.
  listing: object,

  disabled: bool.isRequired,
  ready: bool.isRequired,
  onSubmit: func.isRequired,
  onChange: func.isRequired,
  submitButtonText: string.isRequired,
  panelUpdated: bool.isRequired,
  updateInProgress: bool.isRequired,
  errors: object.isRequired,
};

export default withRouter(EditListingLocationPanel);
