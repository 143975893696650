import React, { useEffect } from 'react';
import classNames from 'classnames';
import { ValidationError } from '..';
import { bool, func, object, string } from 'prop-types';

import css from './FieldNumberInput.module.scss';
import { Field } from 'react-final-form';
import ArrowUpIcon from './ArrowUpIcon';
import ArrowDownIcon from './ArrowDownIcon';

const defaultParse = (value) => {
  if (isNaN(value)) {
    return 0;
  } else if (value && value.replace) {
    return parseInt(value.replace(/\s/g, ''));
  }
  return value === null ? value : 0;
};

const FieldNumberInputComponent = ({
  rootClassName,
  className,
  inputRootClass,
  customErrorText,
  inputClassName,
  id,
  label,
  input,
  meta,
  inputRef,
  onChange: customOnChange,
  positive,
  min,
  max,
  readOnly,
  inline = false,
  subLabel,
  subLabelClassName,
  labelClassName,
  ...rest
}) => {
  if (label && !id) {
    throw new Error('id required when a label is given');
  }

  const { valid, invalid, touched, error } = meta;
  const { onChange } = input;
  const errorText = customErrorText || error;
  const hasError = !!customErrorText || !!(touched && invalid && error);
  const fieldMeta = { touched: hasError, error: errorText };
  const refMaybe = inputRef ? { ref: inputRef } : {};
  const handleChange = (e) => {
    if (typeof customOnChange === 'function') customOnChange(e);
    onChange(e);
  };
  const inputClasses =
    inputRootClass ||
    classNames(css.input, inputClassName, {
      [css.inputSuccess]: valid,
      [css.inputError]: hasError,
    });

  const inputProps = {
    className: inputClasses,
    id,
    type: 'text',
    ...refMaybe,
    ...input,
    ...rest,
    onChange: handleChange,
  };
  const classes = classNames(rootClassName || css.root, className);
  const subLabelClasses = classNames(css.subLabel, subLabelClassName);
  const labelClasses = classNames(css.label, labelClassName);
  const handleClickArrowUp = () => {
    if (readOnly) {
      return;
    }
    let nextValue = parseInt(input.value || 0, 10) + 1;
    if (max) nextValue = nextValue > max ? max : nextValue;
    handleChange(nextValue.toString());
    input.onBlur();
  };
  const handleClickArrowDown = () => {
    if (readOnly) {
      return;
    }
    let nextValue = parseInt(input.value || 0, 10) - 1;
    if (positive) nextValue = nextValue < 0 ? 0 : nextValue;
    if (min) nextValue = nextValue < min ? min : nextValue;
    onChange(nextValue.toString());
    input.onBlur();
  };
  return (
    <div className={classes}>
      {inline ? (
        <div className={css.inlineInputWrapper}>
          {(label || subLabel) && (
            <div>
              {label ? (
                <label className={labelClasses} htmlFor={id}>
                  {label}
                </label>
              ) : null}
              {subLabel ? <div className={subLabelClasses}>{subLabel}</div> : null}
            </div>
          )}
          <div className={css.inputWrapper}>
            <input {...inputProps} readOnly={readOnly} />
            {!readOnly && !inline && (
              <div className={css.arrows}>
                <ArrowUpIcon onClick={handleClickArrowUp} />
                <ArrowDownIcon onClick={handleClickArrowDown} />
              </div>
            )}
          </div>
        </div>
      ) : (
        <>
          {label ? <label htmlFor={id}>{label}</label> : null}
          <div className={css.inputWrapper}>
            <input {...inputProps} readOnly={readOnly} />
            {!readOnly && (
              <div className={css.arrows}>
                <ArrowUpIcon onClick={handleClickArrowUp} />
                <ArrowDownIcon onClick={handleClickArrowDown} />
              </div>
            )}
          </div>
        </>
      )}
      <ValidationError fieldMeta={fieldMeta} />
    </div>
  );
};

const FieldNumberInput = ({ onUnmount, initialValue, autofocusProp = false, ...rest }) => {
  useEffect(() => {
    return () => {
      if (typeof onUnmount === 'function') onUnmount();
    };
  }, [onUnmount]);
  const initMaybe = initialValue ? { defaultValue: initialValue } : {};
  return (
    <Field
      autoFocus={autofocusProp}
      component={FieldNumberInputComponent}
      {...rest}
      parse={defaultParse}
      {...initMaybe}
    />
  );
};

FieldNumberInput.defaultProps = {
  positive: true,
  rootClassName: null,
  className: null,
  inputRootClass: null,
  onUnmount: null,
  customErrorText: null,
  id: null,
  label: null,
  inputRef: null,
  labelClassName: null,
};

FieldNumberInput.propTypes = {
  rootClassName: string,
  className: string,
  inputRootClass: string,
  onUnmount: func,
  customErrorText: string,
  id: string,
  label: string,
  inputRef: object,
  positive: bool,
  labelClassName: string,
};

export default FieldNumberInput;
