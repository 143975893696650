import { func, object } from 'prop-types';
import React, { useCallback, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { FieldTextInput, SavedCardDetails } from '..';
import { propTypes } from '../../util/types';
import { required } from '../../util/validators';
import css from './StripeSplitingCreditCard.module.scss';

const stripeElementsOptions = {
  fonts: [
    {
      family: 'MarkPro',
      fontSmoothing: 'antialiased',
      src: 'local("MarkPro"),url(../../fonts/MarkProRegular.woff) format("woff")',
    },
  ],
};

const cardStyles = {
  base: {
    fontFamily: '"MarkPro", Helvetica, Arial, sans-serif',
    fontSize: '16px',
    fontSmoothing: 'antialiased',
    lineHeight: '26px',
    letterSpacing: '-0.1px',
    color: '#222222',
    '::placeholder': {
      color: 'rgba(0,0,0,0.5)',
    },
  },
  invalid: {
    color: '#ff0000',
  },
};

const StripeSplitingCreditCard = (props) => {
  const {
    stripe,
    stripeCard,
    handleCardChange,
    setCardNumberValid,
    setCardExpiryValid,
    setCardCvcValid,
    defaultPaymentMethod,
    paymentMethod,
    setPaymentMethod,
    showCardDetail,
  } = props;
  const intl = useIntl();

  const initializeStripeElements = useCallback(() => {
    const elements = stripe.elements(stripeElementsOptions);
    if (!stripeCard.current) {
      const cardNumber = elements.create('cardNumber', {
        showIcon: true,
        placeholder: 'Card number',
        style: cardStyles,
      });
      cardNumber.mount('#creditCardNumber');
      const cardExpiry = elements.create('cardExpiry', { style: cardStyles });
      cardExpiry.mount('#creditCardExpiry');
      const cardCvc = elements.create('cardCvc', {
        placeholder: '123',
        style: cardStyles,
      });
      cardCvc.mount('#creditCardCvc');

      stripeCard.current = {
        cardNumber,
        cardExpiry,
        cardCvc,
      };

      cardNumber.addEventListener('change', (e) => handleCardChange(e, 'cardNumber'));
      cardExpiry.addEventListener('change', (e) => handleCardChange(e, 'cardExpiry'));
      cardCvc.addEventListener('change', (e) => handleCardChange(e, 'cardCvc'));
    }
  }, [handleCardChange, stripe, stripeCard]);

  useEffect(() => {
    if (stripe) {
      if (!showCardDetail || paymentMethod === 'replaceCard') {
        initializeStripeElements();
      }
    }

    return () => {
      stripeCard.current = null;
      setCardNumberValid(false);
      setCardExpiryValid(false);
      setCardCvcValid(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    setCardCvcValid,
    setCardExpiryValid,
    setCardNumberValid,
    stripe,
    paymentMethod,
    showCardDetail,
  ]);

  const handleChangeCardPaymentMethod = (changeTo) => {
    if (stripeCard.current && changeTo === 'defaultCard') {
      const { cardNumber, cardExpiry, cardCvc } = stripeCard.current;

      cardNumber.removeEventListener('change', (e) => handleCardChange(e, 'cardNumber'));
      cardExpiry.removeEventListener('change', (e) => handleCardChange(e, 'cardExpiry'));
      cardCvc.removeEventListener('change', (e) => handleCardChange(e, 'cardCvc'));

      cardNumber.unmount();
      cardExpiry.unmount();
      cardCvc.unmount();

      stripeCard.current = null;
    }

    setPaymentMethod(changeTo);
  };

  return (
    <div className={css.cardSplitingWrappper}>
      {showCardDetail ? (
        <SavedCardDetails
          className={css.paymentMethodSelector}
          card={defaultPaymentMethod.attributes.card}
          onChange={handleChangeCardPaymentMethod}
          paymentMethod={paymentMethod}
        />
      ) : null}
      {(paymentMethod === 'replaceCard' || !showCardDetail) && (
        <>
          <FieldTextInput
            id="cardHolderName"
            name="cardHolderName"
            placeholder={intl.formatMessage({
              id: 'AddOneTimeCreditsForm.cardHolderNamePlaceholder',
            })}
            label={intl.formatMessage({ id: 'AddOneTimeCreditsForm.cardHolderNameLabel' })}
            inputRootClass={css.fieldInput}
            labelClassName={css.cardHolderNameLabel}
            validate={required(
              intl.formatMessage({ id: 'AddOneTimeCreditsForm.cardHolderNameRequired' })
            )}
          />
          <div className={css.cardNumberWrapper}>
            <div className={css.fieldTitle}>
              {intl.formatMessage({ id: 'StripeSplitingCreditCard.cardNumber' })}
            </div>
            <div className={css.cardNumber} id="creditCardNumber"></div>
          </div>
          <div className={css.row}>
            <div className={css.cardExpiryWrapper}>
              <div className={css.fieldTitle}>
                {intl.formatMessage({ id: 'StripeSplitingCreditCard.cardExpiry' })}
              </div>
              <div className={css.cardExpiry} id="creditCardExpiry"></div>
            </div>
            <div className={css.cardCvcWrapper}>
              <div className={css.fieldTitle}>
                {intl.formatMessage({ id: 'StripeSplitingCreditCard.cardCvc' })}
              </div>
              <div className={css.cardCvc} id="creditCardCvc"></div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

StripeSplitingCreditCard.defaultProps = {
  stripeCard: null,
};

StripeSplitingCreditCard.propTypes = {
  stripe: object,
  stripeCard: object.isRequired,
  handleCardChange: func.isRequired,
  setCardNumberValid: func.isRequired,
  setCardExpiryValid: func.isRequired,
  setCardCvcValid: func.isRequired,
  defaultPaymentMethod: propTypes.defaultPaymentMethod,
};
export default StripeSplitingCreditCard;
