import { formatMoneyWithSpace } from '../util/currency';

class CouponModel {
  static TYPES = {
    FIXED: 'fixed_amount',
    PERCENTAGE: 'percentage',
  };

  static STATUSES = {
    ACTIVE: 'active',
    EXPIRED: 'expired',
    ARCHIVED: 'archived',
  };

  static calculatePrice({ originalPrice, coupon }) {
    const { id: couponCode, discount_type, discount_amount, discount_percentage } = coupon || {};

    if (!couponCode) {
      return {
        originalPrice,
      };
    }

    const payload = {
      originalPrice,
    };

    if (discount_type === this.TYPES.PERCENTAGE && discount_percentage) {
      payload.priceWithDiscount = originalPrice * (1 - discount_percentage / 100);
      payload.couponDiscount = originalPrice - payload.priceWithDiscount;
    } else if (discount_type === this.TYPES.FIXED && discount_amount) {
      if (originalPrice <= discount_amount * 0.8) {
        payload.priceWithDiscount = 0;
        payload.couponDiscount = originalPrice;
      } else {
        payload.priceWithDiscount = originalPrice - discount_amount * 0.8;
        payload.couponDiscount = discount_amount * 0.8;
      }
    }

    return payload;
  }

  static formatQuotePrice(price) {
    return price === 0 || !price
      ? 0
      : formatMoneyWithSpace(price % 1 === 0 ? price : price.toFixed(2));
  }
}

export default CouponModel;
