/**
 Sends an email with inquiry
 @param email {String}
 @param fullName {String}
 @param locationName {String}
 @param message {String}
 @param providerEmail {String}
 @param address {String}
 @param fixedOfficeTypeTranslation {String}
 @param categoryTranslation {String}
 @param locale {String}
 */
const InquiryEmail = async ({
  email,
  fullName,
  locationName,
  message,
  providerEmails,
  address,
  fixedOfficeTypeTranslation,
  categoryTranslation,
  locale,
  objectUrl,
}) => {
  const CUSTOMER_IO_APP_API_KEY = process.env.REACT_APP_CUSTOMER_IO_APP_API_KEY;

  const sendInquiryEmail = async () => {
    const message_data = {
      fullName: fullName,
      category: categoryTranslation,
      email: email,
      message: message,
      address: address,
      fixedOfficeType: fixedOfficeTypeTranslation,
      locale: locale,
      locationName: locationName,
      objectUrl: objectUrl,
    };

    const to =
      providerEmails[1] && providerEmails[1] !== ''
        ? `${providerEmails[0]},${providerEmails[1]}`
        : providerEmails[0];

    var myHeaders = new Headers();
    myHeaders.append('Content-Type', 'application/json');
    myHeaders.append('Accept', 'application/json');
    myHeaders.append('Authorization', `Bearer ${CUSTOMER_IO_APP_API_KEY}`);

    const raw = JSON.stringify({
      identifiers: {
        email: providerEmails[0],
      },
      to: to,
      transactional_message_id: 'locationInterest_partner',
      language: locale,
      message_data: message_data,
    });

    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow',
    };

    fetch('https://api-eu.customer.io/v1/send/email', requestOptions)
      .then((response) => response.text())
      .then((result) => console.log(result))
      .catch((error) => console.log('error', error));
    return true;
  };

  return await sendInquiryEmail();
};

export default InquiryEmail;
