import React from 'react';
import classNames from 'classnames';
import { bool, oneOf, string } from 'prop-types';

import { ButtonNew } from '../index';

import css from './SectionCtaWithBg.module.scss';

export const CTA_WITH_BG_COLORS = {
  WHITE: 'white',
  GRAY: 'gray',
  GREEN: 'green',
  BLUE: 'blue',
};

CTA_WITH_BG_COLORS.LIST = [
  CTA_WITH_BG_COLORS.WHITE,
  CTA_WITH_BG_COLORS.GRAY,
  CTA_WITH_BG_COLORS.GREEN,
  CTA_WITH_BG_COLORS.BLUE,
];

export const CTA_WITH_BG_TITLE_ALIGN = {
  LEFT: 'Left',
  CENTER: 'Center',
  RIGHT: 'Right',
};

CTA_WITH_BG_TITLE_ALIGN.LIST = [
  CTA_WITH_BG_TITLE_ALIGN.LEFT,
  CTA_WITH_BG_TITLE_ALIGN.CENTER,
  CTA_WITH_BG_TITLE_ALIGN.RIGHT,
];

const SectionCtaWithBg = ({
  title,
  caption,
  image,
  imageAltText,
  bgColor,
  titleLarge,
  titleAlign,
  titleAlignInMobile,
  links,
}) => (
  <section className={classNames(css.section, css[bgColor])}>
    <div className={css.container}>
      {image && <img src={image} alt={imageAltText} className={css.image} />}
      <h2
        className={classNames(
          css.title,
          css[`title${titleAlign}`],
          css[`titleMobile${titleAlignInMobile}`],
          titleLarge && css.titleLarge
        )}
      >
        {title}
      </h2>
      {caption && <h4 className={css.caption}>{caption}</h4>}
      <div className={css.buttonWrapper}>
        {links &&
          links.map(({ text: linkText, ...restButtonProps }) => (
            <ButtonNew key={linkText} className={css.button} {...restButtonProps}>
              {linkText}
            </ButtonNew>
          ))}
      </div>
    </div>
  </section>
);

SectionCtaWithBg.defaultProps = {
  caption: null,
  image: null,
  imageAltText: 'Image',
  bgColor: CTA_WITH_BG_COLORS.WHITE,
  titleLarge: false,
  titleAlign: CTA_WITH_BG_TITLE_ALIGN.CENTER,
  titleAlignInMobile: CTA_WITH_BG_TITLE_ALIGN.CENTER,
};

SectionCtaWithBg.propTypes = {
  title: string.isRequired,
  caption: string,
  image: string,
  imageAltText: string,
  bgColor: oneOf(CTA_WITH_BG_COLORS.LIST),
  titleLarge: bool,
  titleAlign: oneOf(CTA_WITH_BG_TITLE_ALIGN.LIST),
  titleAlignInMobile: oneOf(CTA_WITH_BG_TITLE_ALIGN.LIST),
};

export default SectionCtaWithBg;
