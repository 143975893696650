import React, { Component } from 'react';
import { array, arrayOf, func, node, number, object, string } from 'prop-types';
import classNames from 'classnames';
import { isMobile } from '../../util/device';
import { injectIntl, intlShape } from '../../util/reactIntl';
import { FieldCheckbox } from '../../components';

import { FilterPopup, FilterPlain } from '../../components';
import css from './SelectMultipleFilter.module.scss';
import { useIntl } from 'react-intl';

// SelectMultipleFilter doesn't need array mutators since it doesn't require validation.
// TODO: Live edit didn't work with FieldCheckboxGroup
//       There's a mutation problem: formstate.dirty is not reliable with it.
const GroupOfFieldCheckboxes = (props) => {
  const { id, className, name, options, searchFilterClass, stateCategories, displayInColumns } =
    props;
  const intl = useIntl();
  return (
    <fieldset className={className}>
      <ul className={classNames(css.list, displayInColumns && css.columns)}>
        {options.map((option, index) => {
          const isCategory = name === 'category' && stateCategories?.length >= 1;
          const isChecked = isCategory && stateCategories.includes(option.key);
          const fieldId = `${id}.${option.key}`;
          const labelMaybe = intl.formatMessage({
            id: option.labelId,
            defaultMessage: option.label,
          });
          const checkboxProps = {
            searchFilterClass,
            name,
            id: fieldId,
            label: labelMaybe,
            value: name === 'allowvatexemptcompanies' ? 'true' : option.key,
            ...(name === 'category' && { checked: isChecked }),
          };

          return (
            <li key={fieldId} className={css.item}>
              <FieldCheckbox {...checkboxProps} />
            </li>
          );
        })}
      </ul>
    </fieldset>
  );
};

const getQueryParamName = (queryParamNames) => {
  return Array.isArray(queryParamNames) ? queryParamNames[0] : queryParamNames;
};

// Format URI component's query param: { pub_key: 'has_all:a,b,c' }
const format = (selectedOptions, queryParamName) => {
  const hasOptionsSelected = selectedOptions && selectedOptions.length > 0;
  const value = hasOptionsSelected ? `${selectedOptions.join(',')}` : null;
  return { [queryParamName]: value };
};

class SelectMultipleFilter extends Component {
  constructor(props) {
    super(props);

    this.filter = null;
    this.filterContent = null;

    this.positionStyleForContent = this.positionStyleForContent.bind(this);
  }

  positionStyleForContent() {
    if (this.filter && this.filterContent) {
      // Render the filter content to the right from the menu
      // unless there's no space in which case it is rendered
      // to the left
      const distanceToRight = window.innerWidth - this.filter.getBoundingClientRect().right;
      const labelWidth = this.filter.offsetWidth;
      const contentWidth = this.filterContent.offsetWidth;
      const contentWidthBiggerThanLabel = contentWidth - labelWidth;
      const renderToRight = distanceToRight > contentWidthBiggerThanLabel;
      const contentPlacementOffset = this.props.contentPlacementOffset;

      const offset = renderToRight
        ? { left: contentPlacementOffset }
        : { right: contentPlacementOffset };
      // set a min-width if the content is narrower than the label
      const minWidth = contentWidth < labelWidth ? { minWidth: labelWidth } : null;

      return { ...offset, ...minWidth };
    }
    return {};
  }

  render() {
    const {
      rootClassName,
      className,
      searchFilterClass,
      id,
      name,
      label,
      options,
      initialValues,
      contentPlacementOffset,
      onSubmit,
      queryParamNames,
      intl,
      showAsPopup,
      stateCategories,
      updateSearchCategories,
      updateFilterAmeneties,
      updateFilterAdditionalServices,
      updateFilterFixed,
      updateFilterHost,
      updateFilterContractType,
      updateFilterAllowVAT,
      isRelevantFilter,
      ...rest
    } = this.props;

    const classes = classNames(rootClassName || css.root, className);

    const filteredOptions =
      stateCategories && stateCategories.length > 0
        ? options.filter((option) => option[stateCategories])
        : options;

    const queryParamName = getQueryParamName(queryParamNames);
    const hasInitialValues = !!initialValues && !!initialValues[queryParamName];
    // Parse options from param strings like "has_all:a,b,c" or "a,b,c"
    const selectedOptions = hasInitialValues
      ? typeof initialValues[queryParamName] !== 'string'
        ? initialValues[queryParamName]
        : initialValues[queryParamName].split(',')
      : [];

    const labelForPopup = hasInitialValues
      ? intl.formatMessage(
          { id: 'SelectMultipleFilter.labelSelected' },
          { labelText: label, count: selectedOptions.length }
        )
      : label;

    const labelForPlain = intl.formatMessage({ id: label });

    const contentStyle = this.positionStyleForContent();

    const displayInColumns =
      name?.includes('ameneties') ||
      name?.includes('offer') ||
      (name?.includes('host') && !isRelevantFilter) ||
      (name?.includes('fixedofficetype') && !isRelevantFilter);
    const showViewMoreButton = name?.includes('ameneties') || name?.includes('offer');

    // pass the initial values with the name key so that
    // they can be passed to the correct field
    const namedInitialValues = { [name]: selectedOptions };

    const handleSubmit = (values) => {
      const usedValue = values ? values[name] : values;
      onSubmit(format(usedValue, queryParamName));
    };

    return showAsPopup ? (
      <FilterPopup
        className={classes}
        rootClassName={rootClassName}
        popupClassName={css.popupSize}
        name={name}
        label={labelForPopup}
        isSelected={hasInitialValues}
        id={`${id}.popup`}
        showAsPopup
        contentPlacementOffset={contentPlacementOffset}
        onSubmit={handleSubmit}
        initialValues={namedInitialValues}
        keepDirtyOnReinitialize
        isRelevantFilter={isRelevantFilter}
        {...rest}
      >
        <GroupOfFieldCheckboxes
          className={css.fieldGroup}
          searchFilterClass={searchFilterClass}
          name={name}
          id={`${id}-checkbox-group`}
          options={filteredOptions}
        />
      </FilterPopup>
    ) : (
      <FilterPlain
        className={className}
        rootClassName={rootClassName}
        name={name}
        label={labelForPlain}
        selectedOptionsCount={selectedOptions.length}
        isSelected={hasInitialValues}
        id={`${id}.plain`}
        liveEdit
        contentPlacementOffset={contentStyle}
        onSubmit={handleSubmit}
        initialValues={namedInitialValues}
        showViewMoreButton={showViewMoreButton}
        isMobile={isMobile}
        isRelevantFilter={isRelevantFilter}
        {...rest}
      >
        <GroupOfFieldCheckboxes
          className={css.fieldGroupPlain}
          searchFilterClass={searchFilterClass}
          name={name}
          id={`${id}-checkbox-group`}
          options={filteredOptions}
          stateCategories={stateCategories}
          displayInColumns={displayInColumns && !isMobile}
        />
      </FilterPlain>
    );
  }
}

SelectMultipleFilter.defaultProps = {
  rootClassName: null,
  className: null,
  initialValues: null,
  contentPlacementOffset: 0,
};

SelectMultipleFilter.propTypes = {
  rootClassName: string,
  className: string,
  id: string.isRequired,
  name: string.isRequired,
  queryParamNames: arrayOf(string).isRequired,
  label: node.isRequired,
  onSubmit: func.isRequired,
  options: array.isRequired,
  initialValues: object,
  contentPlacementOffset: number,

  // form injectIntl
  intl: intlShape.isRequired,
};

export default injectIntl(SelectMultipleFilter);
