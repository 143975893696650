import React, { useState } from 'react';
import { StaticPage, TopbarContainer } from '..';
import facebookImage from '../../assets/respaces_social_1260x630.png';
import {
  Footer,
  LayoutSingleColumn,
  LayoutWrapperFooter,
  LayoutWrapperMain,
  LayoutWrapperTopbar,
} from '../../components';
import { ButtonNew, DropDownList, MediaAndTextSection } from '../../components_new';
import { BUTTON_SIZES, BUTTON_TYPES, LINK_COLORS } from '../../components_new/ButtonNew/ButtonNew';
import config from '../../config';
import { isMobile } from '../../util/device';
import { injectIntl, intlShape } from '../../util/reactIntl';
import css from './PartnerSubletting.module.scss';
import SectionCompanyLogos from './SectionCompanyLogos';
import SectionContact from './SectionContact';
import SectionGetStarted from './SectionGetStarted';
import SectionGoodImages from './SectionGoodImages';
import SectionTestimonials from './SectionTestimonials';
import SectionWhyYouShouldListYourSpace from './SectionWhyYouShouldListYourSpace';
import SectionYourTerms from './SectionYourTerms';
import FAQImage from './assets/faq.png';
import heroImg from './assets/subletMap.png';

const name = 'hello';
const domain = 'respaces.co';

const SpaceSupplier = ({ intl }) => {
  const [isModalOpen, setModalOpen] = useState(false);

  const FAQs = [
    {
      question: intl.formatMessage({ id: 'PartnerSubletting.faq.question1' }),
      answer: intl.formatMessage({ id: 'PartnerSubletting.faq.answer1' }),
    },
    {
      question: intl.formatMessage({ id: 'PartnerSubletting.faq.question2' }),
      answer: intl.formatMessage({ id: 'PartnerSubletting.faq.answer2' }),
    },
    {
      question: intl.formatMessage({ id: 'PartnerSubletting.faq.question3' }),
      answer: intl.formatMessage({ id: 'PartnerSubletting.faq.answer3' }),
    },
    {
      question: intl.formatMessage({ id: 'PartnerSubletting.faq.question4' }),
      answer: intl.formatMessage({ id: 'PartnerSubletting.faq.answer4' }),
    },
    {
      question: intl.formatMessage({ id: 'PartnerSubletting.faq.question5' }),
      answer: intl.formatMessage({ id: 'PartnerSubletting.faq.answer5' }),
    },
  ];

  const defaultLinkProps = {
    type: BUTTON_TYPES.INLINE,
    size: BUTTON_SIZES.SMALL,
    linkColor: LINK_COLORS.BLUE,
    withIcon: true,
  };

  const schemaImage = `${config.canonicalRootURL}${heroImg ? heroImg : facebookImage}`;
  return (
    <StaticPage
      title={intl.locale === 'sv' ? 'Hyr ut i andra hand' : 'Subletting your space'}
      description={intl.formatMessage({ id: 'PartnerSubletting.schemaDescription' })}
      facebookImages={[
        {
          name: 'facebook',
          url: schemaImage,
          width: 1200,
          height: 630,
        },
      ]}
      twitterImages={[
        {
          name: 'twitter',
          url: schemaImage,
          width: 600,
          height: 314,
        },
      ]}
      schema={{
        '@context': 'http://schema.org',
        '@type': 'SpaceSupplier',
        description:
          'Informaton for companies that want to rent out part of or the whole office space on Respaces',
        name: 'Space Supplier',
      }}
    >
      <LayoutSingleColumn>
        <LayoutWrapperTopbar>
          <TopbarContainer showBookADemoButton={false} currentPage="Partner" />
        </LayoutWrapperTopbar>

        <LayoutWrapperMain className={css.root}>
          <section className={css.sectionHero}>
            <MediaAndTextSection
              title={{
                content: intl.formatMessage({ id: 'PartnerSubletting.SectionHero.title' }),
                showMobile: true,
                isH1: true,
              }}
              bullets={{
                list: [
                  intl.formatMessage({ id: 'PartnerSubletting.SectionHero.bullet1' }),
                  intl.formatMessage({ id: 'PartnerSubletting.SectionHero.bullet2' }),
                  intl.formatMessage({ id: 'PartnerSubletting.SectionHero.bullet3' }),
                ],
                showMobile: true,
              }}
              bulletType={'check'}
              checkColor={'black'}
              media={{ content: heroImg, showMobile: true }}
              wideMedia
              textAlign={'left'}
              textAlignMobile={'top'}
              buttonsList={[
                {
                  linkText: intl.formatMessage({ id: 'PartnerSubletting.SectionHero.button' }),
                  type: BUTTON_TYPES.BLUE,
                  linkColor: LINK_COLORS.WHITE,
                  size: BUTTON_SIZES.MEDIUM,
                  withIcon: false,
                  block: isMobile,
                  name: 'DashboardPage',
                  params: { view: 'locations', tab: 'create' },
                  mobileButtonPosition: 'center',
                },
              ]}
            />
          </section>
          <SectionTestimonials intl={intl} />
          <SectionCompanyLogos intl={intl} />
          <SectionWhyYouShouldListYourSpace intl={intl} />
          <SectionGetStarted intl={intl} />
          <SectionYourTerms intl={intl} />
          <section className={css.faqSection}>
            <div className={css.faqWrapper}>
              <img src={FAQImage} alt="FAQ" className={css.faqImage} />
              <div className={css.faqQuestions}>
                <h2 className={css.faqTitle}>
                  {intl.formatMessage({ id: 'PartnerSubletting.faq.title' })}
                </h2>
                <DropDownList
                  menuList={FAQs.map((faq) => ({
                    header: faq.question,
                    content: faq.answer,
                  }))}
                  bold
                  border
                />
                <p className={css.faqContact}>
                  {intl.formatMessage({ id: 'PartnerSubletting.faq.contact' })}
                  <a className={css.mailLink} href={`mailto:${name}@${domain}`}>
                    hello@respaces.co
                  </a>
                </p>
              </div>
            </div>
          </section>

          <SectionGoodImages
            intl={intl}
            isMobile={isMobile}
            linkProps={defaultLinkProps}
            onButtonClick={() => setModalOpen(true)}
          />

          <SectionContact intl={intl} />
          <div className={css.sticyBottomBar}>
            <div className={css.sticyBottomBarButtonWrapper}>
              <ButtonNew
                type={BUTTON_TYPES.INLINE}
                size={BUTTON_SIZES.MEDIUM}
                linkColor={LINK_COLORS.BLACK}
                withIcon={false}
                block={isMobile}
                name="Partner"
                mobileButtonPosition="left"
              >
                {intl.formatMessage({ id: 'PartnerSubletting.sticyBottomBar.buttonBack' })}
              </ButtonNew>
              <ButtonNew
                type={BUTTON_TYPES.BLUE}
                size={BUTTON_SIZES.MEDIUM}
                linkColor={LINK_COLORS.WHITE}
                withIcon={false}
                block={isMobile}
                name="DashboardPage"
                params={{ view: 'locations', tab: 'create' }}
                mobileButtonPosition="right"
              >
                {intl.formatMessage({ id: 'PartnerSubletting.sticyBottomBar.button' })}
              </ButtonNew>
            </div>
          </div>
        </LayoutWrapperMain>

        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </LayoutSingleColumn>
    </StaticPage>
  );
};

SpaceSupplier.propTypes = {
  intl: intlShape.isRequired,
};
export default injectIntl(SpaceSupplier);
