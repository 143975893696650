import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { parse } from '../../../util/urlHelpers';
import { routeConfiguration } from '../../..';
import { createResourceLocatorString } from '../../../util/routes';
import {
  requestChunk,
  updatePagination,
  updateSort,
  updateFilters,
} from '../../../ducks/SearchPageNew.duck';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { useLocation } from 'react-router-dom';
import config from '../../../config';

export function useSearch() {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  const { filters, sortBy, pagination, isLoading } = useSelector((state) => {
    const { filters, sortBy, pagination, isLoading } = state.SearchPageNew;
    return { filters, sortBy, pagination, isLoading };
  });

  const filterConfig = config.custom.filters;

  const numberOfActiveFilters =
    filters &&
    Object.entries(filters).reduce((acc, curr) => {
      const [key, val] = curr;
      // filter out unnecessary filters
      if (
        !val ||
        ['bounds', 'origin', 'address', 'maxSeats', 'endDate'].includes(key) ||
        filterConfig.find((filter) => filter.id === key)?.group === 'secondary' ||
        (!!filters.category &&
          !filterConfig.find((x) => x.id === key)?.for.includes(filters.category)) ||
        (!!filters.category &&
          filterConfig.find((x) => x.id === key)?.relevantFor?.includes(filters.category))
      ) {
        return acc;
      } else if (!!val) {
        let count = [
          'allowVatExemptCompanies',
          'contractLengthPerUnit',
          'price',
          'minSeats',
          'startDate',
        ].includes(key)
          ? 1
          : val.split(',').length;
        acc += count;
      }
      return acc;
    }, 0);

  const initialRequest = useCallback(() => {
    const params = parse(location?.search, {
      latlng: ['origin'],
      latlngBounds: ['bounds'],
    });

    dispatch(updateFilters(params));
  }, [dispatch, location?.search]);

  const handlePageUpdate = useCallback(
    (page) => {
      dispatch(updatePagination({ page }));
      dispatch(requestChunk());
    },
    [dispatch]
  );

  const handleSortUpdate = useCallback(
    (_, field, order, isListingFilter) => {
      dispatch(updateSort({ field, order, isListingFilter }));
      dispatch(requestChunk());
    },
    [dispatch]
  );

  const handleFilterUpdate = useCallback(
    (payload) => {
      dispatch(updateFilters(payload));
    },
    [dispatch]
  );

  const requestAndUpdateUrl = useCallback(() => {
    dispatch(requestChunk());
    const queryString = createResourceLocatorString(
      'SearchPage',
      routeConfiguration(),
      {},
      filters
    );

    history.replace(queryString);
  }, [dispatch, filters, history]);

  const clearSecondaryFilters = useCallback(
    () =>
      handleFilterUpdate({
        host: null,
        minSeats: null,
        maxSeats: null,
        fixedOfficeType: null,
        price: null,
        startDate: null,
        endDate: null,
        ameneties: null,
        offer: null,
        contractType: null,
        allowVatExemptCompanies: null,
        contractLengthPerUnit: null,
      }),
    [handleFilterUpdate]
  );

  return {
    filters,
    sortBy,
    pagination,
    isLoading,
    numberOfActiveFilters,
    initialRequest,
    handlePageUpdate,
    handleSortUpdate,
    handleFilterUpdate,
    clearSecondaryFilters,
    requestAndUpdateUrl,
  };
}
