import {
  toggleUserFavoriteListings,
  toggleCompanyFavoriteListings,
} from '../../../ducks/user.duck';

const mapDispatchToProps = (dispatch) => ({
  onToggleFavoriteListing: (payload) => dispatch(toggleUserFavoriteListings(payload)),
  onToggleCompanyFavoriteListing: (payload) => dispatch(toggleCompanyFavoriteListings(payload)),
});

export { mapDispatchToProps };
