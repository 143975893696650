import config from '../config';
import { PACKAGE_CREDITS, PACKAGE_SUBSCRIPTION } from '../util/types';
const { pricePerCreditSmall, pricePerCreditMedium, pricePerCreditLarge } = config;

const PackageModel = {
  // FREE: {
  //   id: PACKAGE_FREE,
  //   credits: FREE_TRIAL_CREDIT_AMOUNT,
  //   pricePerMonth: 1000,
  //   averageBookings: 4,
  // },
  BUY_CREDITS: {
    id: PACKAGE_CREDITS,
    credits: 100,
    pricePerCredit: pricePerCreditSmall,
  },
  SUBSCRIPTION: {
    id: PACKAGE_SUBSCRIPTION,
    credits: 100,
    pricePerCredit: pricePerCreditSmall,
  },
  MONTHS_1: {
    id: 'months_1',
    value: 1,
    credits: 25,
    pricePerCredit: pricePerCreditSmall,
  },
  MONTHS_6: {
    id: 'months_6',
    benefit: 4,
    value: 6,
    pricePerCredit: parseFloat(pricePerCreditMedium.toFixed(2)),
  },
  MONTHS_12: {
    id: 'months_12',
    benefit: 8,
    value: 12,
    pricePerCredit: parseFloat(pricePerCreditLarge.toFixed(2)),
  },
};

PackageModel.ID_TO_DATA = {
  // [PackageModel.FREE.id]: PackageModel.FREE,
  [PackageModel.BUY_CREDITS.id]: PackageModel.BUY_CREDITS,
  [PackageModel.SUBSCRIPTION.id]: PackageModel.SUBSCRIPTION,
  [PackageModel.MONTHS_1.id]: PackageModel.MONTHS_1,
  [PackageModel.MONTHS_6.id]: PackageModel.MONTHS_6,
  [PackageModel.MONTHS_12.id]: PackageModel.MONTHS_12,
};

PackageModel.LIST = {
  DISPLAY: [/* PackageModel.FREE,  */ PackageModel.BUY_CREDITS, PackageModel.SUBSCRIPTION],

  PRICING: [PackageModel.MONTHS_1, PackageModel.MONTHS_6, PackageModel.MONTHS_12],
};

PackageModel.currentPackage = function ({ selectedPackage }) {
  return selectedPackage ? PackageModel.ID_TO_DATA[selectedPackage] : PackageModel.MONTHS_1;
};

export default PackageModel;
