import React from 'react';
import classNames from 'classnames';
import { injectIntl } from 'react-intl';
import { bool, func, string } from 'prop-types';

import css from './ComboBoxClearButton.module.scss';

const ComboBoxClearButton = ({ noMargin, onClick, className, onClose, isRelevantFilter, intl }) => (
  <div className={classNames(css.clearButtonWrapper, noMargin === 0 && css.noMargin, className)}>
    <button className={css.clearButton} type="button" onClick={onClick}>
      {intl.formatMessage({ id: 'ListingSearchForm.locationSearchInput.clear' })}
    </button>
    {isRelevantFilter && (
      <button className={css.doneButton} type="button" onClick={onClose}>
        {intl.formatMessage({ id: 'ListingSearchForm.locationSearchInput.done' })}
      </button>
    )}
  </div>
);

ComboBoxClearButton.propTypes = {
  noMargin: bool,
  onClick: func,
  className: string,
};

export default injectIntl(ComboBoxClearButton);
