import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import {
  Button,
  Footer,
  IconSpinner,
  LayoutSingleColumn,
  LayoutWrapperFooter,
  LayoutWrapperMain,
  LayoutWrapperTopbar,
  NamedLink,
} from '../../components';
import { StaticPage, TopbarContainer } from '../../containers';
import css from './BlogPage.module.scss';

const BlogPage = () => {
  const { fetchDataError, fetchInProgress, blogData } = useSelector((state) => {
    const {
      BlogPage: { fetchDataError, fetchInProgress, blogData },
    } = state;
    return { fetchDataError, fetchInProgress, blogData };
  });

  const [groupCount, setGroupCount] = useState(1);

  // divides blogdata into groups of 4
  const splitBlogData = (arr) => {
    let arrCopy = arr.slice(0);
    let groupSize = 4;
    let returnedArr = [];
    while (arrCopy.length) {
      returnedArr.push(arrCopy.splice(0, groupSize));
    }
    return returnedArr;
  };
  // create an array of groups of blog posts
  let groupedPosts = blogData ? splitBlogData(blogData) : null;

  // This controls the display of data for each post
  // TODO add translation to all post details

  const postTile = (post, index) => {
    let img =
      post.cover && post.cover.formats.large
        ? post.cover.formats.large.url
        : post.cover
        ? post.cover.url
        : null;
    return (
      <NamedLink
        className={css.linkWrapper}
        name="BlogPost"
        params={{ slug: post.slug, id: post.id }}
        key={`post_${index}`}
      >
        <div className={css.blogPost}>
          <img src={img} alt={post.slug}></img>
          <h2 className={css.blogPostTitle}>{post.title}</h2>
          <h3 className={css.blogPostInfo}>
            {post.published_at && post.published_at.slice(0, 10)} •{' '}
            {post.category && post.category.title}
          </h3>
          <FormattedMessage id="BlogPage.readMore">
            {(id) => <p className={css.readMore}>{id}</p>}
          </FormattedMessage>
        </div>
      </NamedLink>
    );
  };
  // map through all grouped blogdata and create a post for each, giving an array of groups of up to 4 posts
  const posts = groupedPosts
    ? groupedPosts.map((group, index) => {
        return (
          <section
            className={group.length > 2 ? css.groupSection : css.halfSection}
            key={`group_${index}`}
          >
            {group.map((post, index) => postTile(post, index))}
          </section>
        );
      })
    : null;

  return (
    <StaticPage
      title="Respaces Blog Page Overview"
      description="Respaces Blog Overview,showing all the blog posts we have published"
      schema={{
        '@context': 'http://schema.org',
        '@type': 'BlogPage',
        description: 'Respaces Blog Overview,showing all the blog posts we have published',
        name: 'Blog Page Overview',
      }}
    >
      <LayoutSingleColumn>
        <LayoutWrapperTopbar>
          <TopbarContainer currentPage="BlogPage" />
        </LayoutWrapperTopbar>
        <LayoutWrapperMain className={css.mainWrapper}>
          <FormattedMessage id="BlogPage.header">
            {(id) => <h1 className={css.blogHeader}>{id}</h1>}
          </FormattedMessage>
          {fetchInProgress ? (
            <IconSpinner className={css.spinner} />
          ) : !fetchInProgress && !posts && fetchDataError ? (
            <FormattedMessage id="BlogPage.errorLoading">
              {(id) => <p className={css.loading}>{id}</p>}
            </FormattedMessage>
          ) : (
            <>
              {posts.slice(0, groupCount)}
              <Button
                className={css.blogButton}
                disabled={blogData && blogData.length <= groupCount * 4}
                onClick={() => {
                  setGroupCount(groupCount + 1);
                }}
              >
                <FormattedMessage id="BlogPage.viewMoreButton" />
              </Button>
            </>
          )}
        </LayoutWrapperMain>

        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </LayoutSingleColumn>
    </StaticPage>
  );
};

export default BlogPage;
