import {
  MY_SUBSCRIPTION,
  TEAM_ACCESS,
  USAGE_LOG,
  PAYMENT_HISTORY,
  SETTINGS,
  STATUS_DELETED,
  STATUS_REGISTERED,
} from '../../util/types';
import { parse } from '../../util/urlHelpers';
import get from 'lodash/get';
import pick from 'lodash/pick';
import { storableError } from '../../util/errors';
import { denormalisedResponseEntities } from '../../util/data';
import { fetchCompanyUser, fetchCurrentUser } from '../../ducks/user.duck';
import {
  addTeamMemberList as addTeamMemberListApi,
  apiBaseUrl,
  getUsageLog,
  getChargebeeInvoices,
  deleteTeamMember,
  editTeamMemberAcces,
  getInvoicePDF,
  buyOneTimeCredits as buyOneTimeCreditsApi,
  changePaymentMethod as changePaymentMethodApi,
  getChargebeeUser,
  listPaymentSource as listChargebeePaymentSourceApi,
  updateMyCompanyCreditSubscription,
  retrieveMyCompanyCreditSubscription,
  cancelMyCompanyCreditSubscription,
  pauseMyCompanyCreditSubscription,
  reactivateMyCompanyCreditSubscription,
  resumeMyCompanyCreditSubscription,
  updateHubspotPipeline,
} from '../../util/api';
import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import axios from 'axios';
import * as log from '../../util/log';
import { isB2bTx } from '../../util/transaction';
import { stripeCustomer } from '../CheckoutPage/CheckoutPage.duck';

// ================ Action types ================ //

export const FETCH_TEAM_ACCESS_REQUEST = 'app/SubscriptionManagementPage/FETCH_TEAM_ACCESS_REQUEST';
export const FETCH_TEAM_ACCESS_SUCCESS = 'app/SubscriptionManagementPage/FETCH_TEAM_ACCESS_SUCCESS';
export const FETCH_TEAM_ACCESS_ERROR = 'app/SubscriptionManagementPage/FETCH_TEAM_ACCESS_ERROR';

export const ADD_TEAM_MEMBER_LIST = 'app/SubscriptionManagementPage/ADD_TEAM_MEMBER_LIST';
export const ADD_TEAM_MEMBER_LIST_SUCCESS =
  'app/SubscriptionManagementPage/ADD_TEAM_MEMBER_LIST_SUCCESS';
export const ADD_TEAM_MEMBER_LIST_ERROR =
  'app/SubscriptionManagementPage/ADD_TEAM_MEMBER_LIST_ERROR';

export const FETCH_USAGE_LOG_REQUEST = 'app/SubscriptionManagementPage/FETCH_USAGE_LOG_REQUEST';
export const FETCH_USAGE_LOG_SUCCESS = 'app/SubscriptionManagementPage/FETCH_USAGE_LOG_SUCCESS';
export const FETCH_USAGE_LOG_ERROR = 'app/SubscriptionManagementPage/FETCH_USAGE_LOG_ERROR';
export const FETCH_CREDIT_USAGE = 'app/SubscriptionManagementPage/FETCH_CREDIT_USAGE';

export const COMPANY_LOGO_UPLOAD_IMAGE_REQUEST =
  'app/SubscriptionManagementPage/COMPANY_LOGO_UPLOAD_IMAGE_REQUEST';
export const COMPANY_LOGO_UPLOAD_IMAGE_SUCCESS =
  'app/SubscriptionManagementPage/COMPANY_LOGO_UPLOAD_IMAGE_SUCCESS';
export const COMPANY_LOGO_UPLOAD_IMAGE_ERROR =
  'app/SubscriptionManagementPage/COMPANY_LOGO_UPLOAD_IMAGE_ERROR';

export const COMPANY_NAME_UPDATE_REQUEST =
  'app/SubscriptionManagementPage/COMPANY_NAME_UPDATE_REQUEST';
export const COMPANY_NAME_UPDATE_SUCCESS =
  'app/SubscriptionManagementPage/COMPANY_NAME_UPDATE_SUCCESS';
export const COMPANY_NAME_UPDATE_ERROR = 'app/SubscriptionManagementPage/COMPANY_NAME_UPDATE_ERROR';

export const FETCH_PAYMENT_HISTORY_REQUEST =
  'app/SubscriptionManagementPage/FETCH_PAYMENT_HISTORY_REQUEST';
export const FETCH_PAYMENT_HISTORY_SUCCESS =
  'app/SubscriptionManagementPage/FETCH_PAYMENT_HISTORY_SUCCESS';
export const FETCH_PAYMENT_HISTORY_ERROR =
  'app/SubscriptionManagementPage/FETCH_PAYMENT_HISTORY_ERROR';

export const DELETE_MEMBER_REQUEST = 'app/SubscriptionManagementPage/DELETE_MEMBER_REQUEST';
export const DELETE_MEMBER_SUCCESS = 'app/SubscriptionManagementPage/DELETE_MEMBER_SUCCESS';
export const DELETE_MEMBER_ERROR = 'app/SubscriptionManagementPage/DELETE_MEMBER_ERROR';

export const EDIT_MEMBER_ACCESS_REQUEST =
  'app/SubscriptionManagementPage/EDIT_MEMBER_ACCESS_REQUEST';
export const EDIT_MEMBER_ACCESS_SUCCESS =
  'app/SubscriptionManagementPage/EDIT_MEMBER_ACCESS_SUCCESS';
export const EDIT_MEMBER_ACCESS_ERROR = 'app/SubscriptionManagementPage/EDIT_MEMBER_ACCESS_ERROR';

export const GET_INVOICE_PDF_REQUEST = 'app/SubscriptionManagementPage/GET_INVOICE_PDF_REQUEST';
export const GET_INVOICE_PDF_SUCCESS = 'app/SubscriptionManagementPage/GET_INVOICE_PDF_SUCCESS';
export const GET_INVOICE_PDF_ERROR = 'app/SubscriptionManagementPage/GET_INVOICE_PDF_ERROR';

export const BUY_ONE_TIME_CREDITS_REQUEST =
  'app/SubscriptionManagementPage/BUY_ONE_TIME_CREDITS_REQUEST';
export const BUY_ONE_TIME_CREDITS_SUCCESS =
  'app/SubscriptionManagementPage/BUY_ONE_TIME_CREDITS_SUCCESS';
export const BUY_ONE_TIME_CREDITS_ERROR =
  'app/SubscriptionManagementPage/BUY_ONE_TIME_CREDITS_ERROR';

export const CHANGE_PAYMENT_METHOD_REQUEST =
  'app/SubscriptionManagementPage/CHANGE_PAYMENT_METHOD_REQUEST';
export const CHANGE_PAYMENT_METHOD_SUCCESS =
  'app/SubscriptionManagementPage/CHANGE_PAYMENT_METHOD_SUCCESS';
export const CHANGE_PAYMENT_METHOD_ERROR =
  'app/SubscriptionManagementPage/CHANGE_PAYMENT_METHOD_ERROR';

export const FETCH_CHARGEBEE_INFO = 'app/SubscriptionManagementPage/FETCH_CHARGEBEE_INFO';

export const EDIT_SUBSCRIPTION_REQUEST = 'app/SubscriptionManagementPage/EDIT_SUBSCRIPTION_REQUEST';
export const EDIT_SUBSCRIPTION_SUCCESS = 'app/SubscriptionManagementPage/EDIT_SUBSCRIPTION_SUCCESS';
export const EDIT_SUBSCRIPTION_ERROR = 'app/SubscriptionManagementPage/EDIT_SUBSCRIPTION_ERROR';

export const CANCEL_SUBSCRIPTION_REQUEST =
  'app/SubscriptionManagementPage/CANCEL_SUBSCRIPTION_REQUEST';
export const CANCEL_SUBSCRIPTION_SUCCESS =
  'app/SubscriptionManagementPage/CANCEL_SUBSCRIPTION_SUCCESS';
export const CANCEL_SUBSCRIPTION_ERROR = 'app/SubscriptionManagementPage/CANCEL_SUBSCRIPTION_ERROR';

export const ACTION_SUBSCRIPTION_REQUEST =
  'app/SubscriptionManagementPage/ACTION_SUBSCRIPTION_REQUEST';
export const ACTION_SUBSCRIPTION_SUCCESS =
  'app/SubscriptionManagementPage/ACTION_SUBSCRIPTION_SUCCESS';
export const ACTION_SUBSCRIPTION_ERROR = 'app/SubscriptionManagementPage/ACTION_SUBSCRIPTION_ERROR';
const FETCH_MY_SUBSCRIPTION_REQUEST =
  'app/SubscriptionManagementPage/FETCH_MY_SUBSCRIPTION_REQUEST';
const FETCH_MY_SUBSCRIPTION_SUCCESS =
  'app/SubscriptionManagementPage/FETCH_MY_SUBSCRIPTION_SUCCESS';
const FETCH_EARLIEST_CANCEL_DATE = 'app/SubscriptionManagementPage/FETCH_EARLIEST_CANCEL_DATE';

// ================ Reducer ================ //
const initialState = {
  fetchTeamAccessInProgress: false,
  fetchTeamAccessError: null,
  members: [],
  teamPagination: null,
  deleteMemberInProgress: false,
  deleteMemberError: null,
  editMemberAccessInProgress: false,
  editMemberAccessError: null,

  fetchUsageLogInProgress: false,
  fetchUsageLogError: null,
  usageLogBookingRefs: [],
  totalCredits: 0,
  usedCredits: 0,
  teamMembersNumber: 0,
  previousUnusedCredits: 0,
  nextTotalCredits: 0,
  nextBillingDate: null,
  resumeDate: null,
  earliestAllowableCancelDate: null,

  addTeamMembersInProgress: false,
  addTeamMembersError: null,

  logoImageUrl: null,
  uploadCompanyLogoInProgress: false,
  uploadCompanyLogoError: null,
  updateCompanyNameInProgress: false,
  updateCompanyNameError: null,

  paymentHistory: [],
  fetchPaymentHistoryInProgress: false,
  fetchPaymentHistoryError: null,

  getPDFInvoiceInProgress: false,
  getPDFInoiceError: null,

  buyOneTimeCreditsInProgress: false,
  buyOneTimeCreditsError: null,

  changePaymentMethodInProgress: false,
  changePaymentMethodError: null,

  chargebeeBillingAddress: null,
  primaryPaymentSource: null,

  editSubscriptionInProgress: false,
  editSubscriptionError: null,

  cancelSubscriptionInProgress: false,
  cancelSubscriptionError: null,

  pauseSubscriptionInProgress: false,
  pauseSubscriptionError: null,

  actionSubscriptionInProgress: false,
  actionSubscriptionError: null,
  fetchMySubscriptionInProgress: false,

  hasChargebeePaymentSource: false,
};

const entityRefs = (entities) =>
  entities.map((entity) => ({
    id: entity.id,
    type: entity.type,
  }));

export default function subscriptionManagementReducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case GET_INVOICE_PDF_REQUEST:
      return {
        ...state,
        getPDFInvoiceInProgress: true,
        getPDFInoiceError: null,
      };
    case GET_INVOICE_PDF_SUCCESS:
      return {
        ...state,
        getPDFInvoiceInProgress: false,
      };
    case GET_INVOICE_PDF_ERROR:
      return {
        ...state,
        getPDFInoiceError: payload,
        getPDFInvoiceInProgress: false,
      };
    case FETCH_TEAM_ACCESS_REQUEST:
      return {
        ...state,
        fetchTeamAccessInProgress: true,
        fetchTeamAccessError: null,
      };

    case FETCH_TEAM_ACCESS_SUCCESS: {
      return {
        ...state,
        fetchTeamAccessInProgress: false,
        members: payload,
      };
    }

    case FETCH_TEAM_ACCESS_ERROR:
      return {
        ...state,
        fetchTeamAccessInProgress: false,
        fetchTeamAccessError: payload,
      };

    case ADD_TEAM_MEMBER_LIST:
      return {
        ...state,
        addTeamMembersInProgress: true,
        addTeamMembersError: null,
      };

    case ADD_TEAM_MEMBER_LIST_SUCCESS:
      return {
        ...state,
        addTeamMembersInProgress: false,
        members: payload,
      };

    case ADD_TEAM_MEMBER_LIST_ERROR:
      return {
        ...state,
        addTeamMembersInProgress: false,
        addTeamMembersError: payload,
      };

    case FETCH_USAGE_LOG_REQUEST:
      return {
        ...state,
        fetchUsageLogInProgress: true,
        fetchUsageLogError: null,
      };

    case FETCH_USAGE_LOG_SUCCESS: {
      return {
        ...state,
        fetchUsageLogInProgress: false,
        fetchUsageLogError: null,
        usageLogBookingRefs: payload.allTransactionsIds,
        teamMembersNumber: payload.teamMembersNumber,
      };
    }

    case FETCH_USAGE_LOG_ERROR: {
      return {
        ...state,
        fetchUsageLogInProgress: false,
        fetchUsageLogError: payload,
      };
    }

    case FETCH_CREDIT_USAGE: {
      return {
        ...state,
        nextTotalCredits: payload.nextTotalCredits,
        previousUnusedCredits: payload.previousUnusedCredits,
        totalCredits: payload.totalCredits,
        usedCredits: payload.usedCredits,
      };
    }

    case COMPANY_LOGO_UPLOAD_IMAGE_REQUEST: {
      return {
        ...state,
        uploadCompanyLogoInProgress: true,
        uploadCompanyLogoInError: null,
      };
    }

    case COMPANY_LOGO_UPLOAD_IMAGE_SUCCESS: {
      return {
        ...state,
        uploadCompanyLogoInProgress: false,
        logoImageUrl: payload,
      };
    }

    case COMPANY_LOGO_UPLOAD_IMAGE_ERROR: {
      return {
        ...state,
        uploadCompanyLogoInProgress: false,
        uploadCompanyLogoInError: payload,
      };
    }

    case COMPANY_NAME_UPDATE_REQUEST: {
      return {
        ...state,
        updateCompanyNameInProgress: true,
        updateCompanyNameError: null,
      };
    }

    case COMPANY_NAME_UPDATE_SUCCESS: {
      return {
        ...state,
        updateCompanyNameInProgress: false,
      };
    }

    case COMPANY_NAME_UPDATE_ERROR: {
      return {
        ...state,
        updateCompanyNameInProgress: false,
        updateCompanyNameError: payload,
      };
    }

    case FETCH_PAYMENT_HISTORY_REQUEST:
      return {
        ...state,
        fetchPaymentHistoryInProgress: true,
        fetchPaymentHistoryError: null,
      };

    case FETCH_PAYMENT_HISTORY_SUCCESS: {
      return {
        ...state,
        fetchPaymentHistoryInProgress: false,
        paymentHistory: payload,
      };
    }

    case FETCH_PAYMENT_HISTORY_ERROR:
      return {
        ...state,
        fetchPaymentHistoryInProgress: false,
        fetchPaymentHistoryError: payload,
      };

    case DELETE_MEMBER_REQUEST:
      return {
        ...state,
        deleteMemberInProgress: true,
        deleteMemberError: null,
      };
    case DELETE_MEMBER_SUCCESS:
      return {
        ...state,
        deleteMemberInProgress: false,
      };
    case DELETE_MEMBER_ERROR:
      return {
        ...state,
        deleteMemberInProgress: false,
        deleteMemberError: payload,
      };

    case EDIT_MEMBER_ACCESS_REQUEST:
      return {
        ...state,
        editMemberAccessInProgress: true,
        editMemberAccessError: null,
      };
    case EDIT_MEMBER_ACCESS_SUCCESS:
      return {
        ...state,
        editMemberAccessInProgress: false,
      };
    case EDIT_MEMBER_ACCESS_ERROR:
      return {
        ...state,
        editMemberAccessInProgress: false,
        editMemberAccessError: payload,
      };

    case BUY_ONE_TIME_CREDITS_REQUEST:
      return {
        ...state,
        buyOneTimeCreditsInProgress: true,
        buyOneTimeCreditsError: null,
      };
    case BUY_ONE_TIME_CREDITS_SUCCESS:
      return {
        ...state,
        buyOneTimeCreditsInProgress: false,
      };
    case BUY_ONE_TIME_CREDITS_ERROR:
      return {
        ...state,
        buyOneTimeCreditsInProgress: false,
        buyOneTimeCreditsError: payload,
      };
    case CHANGE_PAYMENT_METHOD_REQUEST:
      return {
        ...state,
        changePaymentMethodInProgress: true,
        changePaymentMethodError: null,
      };
    case CHANGE_PAYMENT_METHOD_SUCCESS:
      return {
        ...state,
        changePaymentMethodInProgress: false,
      };
    case CHANGE_PAYMENT_METHOD_ERROR:
      return {
        ...state,
        changePaymentMethodInProgress: false,
        changePaymentMethodError: payload,
      };
    case FETCH_CHARGEBEE_INFO:
      return {
        ...state,
        chargebeeBillingAddress: payload?.billingAddress,
        primaryPaymentSource: payload?.primaryPaymentSource,
        nextBillingDate: payload?.nextBillingAt,
        resumeDate: payload?.resumeDate,
        hasChargebeePaymentSource: payload?.hasChargebeePaymentSource,
      };
    case EDIT_SUBSCRIPTION_REQUEST:
      return {
        ...state,
        editSubscriptionInProgress: true,
        editSubscriptionError: null,
      };
    case EDIT_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        editSubscriptionInProgress: false,
      };
    case EDIT_SUBSCRIPTION_ERROR:
      return {
        ...state,
        editSubscriptionInProgress: false,
        editSubscriptionError: payload,
      };
    case CANCEL_SUBSCRIPTION_REQUEST:
      return {
        ...state,
        cancelSubscriptionInProgress: true,
        cancelSubscriptionError: null,
      };
    case CANCEL_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        cancelSubscriptionInProgress: false,
      };
    case CANCEL_SUBSCRIPTION_ERROR:
      return {
        ...state,
        cancelSubscriptionInProgress: false,
        cancelSubscriptionError: payload,
      };
    case ACTION_SUBSCRIPTION_REQUEST:
      return {
        ...state,
        actionSubscriptionInProgress: true,
        actionSubscriptionError: null,
      };
    case ACTION_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        actionSubscriptionInProgress: false,
      };
    case ACTION_SUBSCRIPTION_ERROR:
      return {
        ...state,
        actionSubscriptionInProgress: false,
        actionSubscriptionError: payload,
      };
    case FETCH_MY_SUBSCRIPTION_REQUEST:
      return {
        ...state,
        fetchMySubscriptionInProgress: true,
      };
    case FETCH_MY_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        fetchMySubscriptionInProgress: false,
      };
    case FETCH_EARLIEST_CANCEL_DATE:
      return {
        ...state,
        earliestAllowableCancelDate: payload,
      };
    default:
      return state;
  }
}

// ================ Action creators ================ //

const fetchTeamAccessRequest = () => ({
  type: FETCH_TEAM_ACCESS_REQUEST,
});

const fetchTeamAccessSuccess = (response) => ({
  type: FETCH_TEAM_ACCESS_SUCCESS,
  payload: response,
});

const fetchTeamAccessError = (error) => ({
  type: FETCH_TEAM_ACCESS_ERROR,
  payload: error,
});

const addTeamMemberListRequest = () => ({
  type: ADD_TEAM_MEMBER_LIST,
});

const addTeamMemberListSuccess = (response) => ({
  type: ADD_TEAM_MEMBER_LIST_SUCCESS,
  payload: response,
});

const addTeamMemberListError = (error) => ({
  type: ADD_TEAM_MEMBER_LIST_ERROR,
  payload: error,
});

const fetchUsageLogRequest = () => ({
  type: FETCH_USAGE_LOG_REQUEST,
});

const fetchUsageLogSuccess = (data) => ({
  type: FETCH_USAGE_LOG_SUCCESS,
  payload: data,
});

const fetchUsageLogError = (error) => ({
  type: FETCH_USAGE_LOG_ERROR,
  payload: error,
});

const fetchCreditUsage = (data) => ({
  type: FETCH_CREDIT_USAGE,
  payload: data,
});

const companyLogoUploadImageRequest = (params) => ({
  type: COMPANY_LOGO_UPLOAD_IMAGE_REQUEST,
  payload: { params },
});

const companyLogoUploadImageSuccess = (result) => ({
  type: COMPANY_LOGO_UPLOAD_IMAGE_SUCCESS,
  payload: result,
});

const companyLogoUploadImageError = (error) => ({
  type: COMPANY_LOGO_UPLOAD_IMAGE_ERROR,
  payload: error,
});

const companyNameUpdateRequest = () => ({
  type: COMPANY_NAME_UPDATE_REQUEST,
});

const companyNameUpdateSuccess = () => ({
  type: COMPANY_NAME_UPDATE_SUCCESS,
});

const companyNameUpdateError = (error) => ({
  type: COMPANY_NAME_UPDATE_ERROR,
  payload: error,
});

const fetchPaymentHistoryRequest = () => ({
  type: FETCH_PAYMENT_HISTORY_REQUEST,
});

const fetchPaymentHistorySuccess = (response) => ({
  type: FETCH_PAYMENT_HISTORY_SUCCESS,
  payload: response,
});

const fetchPaymentHistoryError = (error) => ({
  type: FETCH_PAYMENT_HISTORY_ERROR,
  payload: error,
});

const deleteMemberRequest = () => ({
  type: DELETE_MEMBER_REQUEST,
});

const deleteMemberSuccess = () => ({
  type: DELETE_MEMBER_SUCCESS,
});

const deleteMemberFail = (response) => ({
  type: DELETE_MEMBER_ERROR,
  payload: response,
});

const editMemberAccessRequest = () => ({
  type: EDIT_MEMBER_ACCESS_REQUEST,
});

const editMemberAccessSuccess = () => ({
  type: EDIT_MEMBER_ACCESS_SUCCESS,
});

const editMemberAccessFail = (response) => ({
  type: EDIT_MEMBER_ACCESS_ERROR,
  payload: response,
});

const getInvoicePDFRequest = () => ({
  type: GET_INVOICE_PDF_REQUEST,
});

const getInvoicePDFSuccess = () => ({
  type: GET_INVOICE_PDF_SUCCESS,
});

const getInvoicePDFFail = (response) => ({
  type: GET_INVOICE_PDF_ERROR,
  payload: response,
});

const buyOneTimeCreditsRequest = () => ({
  type: BUY_ONE_TIME_CREDITS_REQUEST,
});

const buyOneTimeCreditsSuccess = () => ({
  type: BUY_ONE_TIME_CREDITS_SUCCESS,
});

const buyOneTimeCreditsError = (error) => ({
  type: BUY_ONE_TIME_CREDITS_ERROR,
  payload: error,
});

const changePaymentMethodRequest = () => ({
  type: CHANGE_PAYMENT_METHOD_REQUEST,
});

const changePaymentMethodSuccess = () => ({
  type: CHANGE_PAYMENT_METHOD_SUCCESS,
});

const changePaymntMethodError = (error) => ({
  type: CHANGE_PAYMENT_METHOD_ERROR,
  payload: error,
});

const fetchChargebeeInfo = (data) => ({
  type: FETCH_CHARGEBEE_INFO,
  payload: data,
});

const editSubscriptionRequest = () => ({
  type: EDIT_SUBSCRIPTION_REQUEST,
});

const editSubscriptionSuccess = () => ({
  type: EDIT_SUBSCRIPTION_SUCCESS,
});

const editSubscriptionError = (error) => ({
  type: EDIT_SUBSCRIPTION_ERROR,
  payload: error,
});

const cancelSubscriptionRequest = () => ({
  type: CANCEL_SUBSCRIPTION_REQUEST,
});

const cancelSubscriptionSuccess = () => ({
  type: CANCEL_SUBSCRIPTION_SUCCESS,
});

const cancelSubscriptionError = (error) => ({
  type: CANCEL_SUBSCRIPTION_ERROR,
  payload: error,
});

const actionSubscriptionRequest = () => ({
  type: ACTION_SUBSCRIPTION_REQUEST,
});

const actionSubscriptionSuccess = () => ({
  type: ACTION_SUBSCRIPTION_SUCCESS,
});

const actionSubscriptionError = (error) => ({
  type: ACTION_SUBSCRIPTION_ERROR,
  payload: error,
});
const fetchMySubscriptionRequest = () => ({
  type: FETCH_MY_SUBSCRIPTION_REQUEST,
});

const fetchMySubscriptionSuccess = () => ({
  type: FETCH_MY_SUBSCRIPTION_SUCCESS,
});

const fetchEarliestAllowableCancelDate = (data) => ({
  type: FETCH_EARLIEST_CANCEL_DATE,
  payload: data,
});
// ================ Thunks ================ //

export const addTeamMemberList = (emailList, credit, locale) => async (dispatch, getState, sdk) => {
  dispatch(addTeamMemberListRequest());

  try {
    const response = await addTeamMemberListApi({ emailList, credit, locale });
    const teamMemberData = get(
      response.data.data,
      'attributes.profile.metadata.teamMemberData',
      {}
    );
    const teamMember = Object.values(teamMemberData);
    const registeredMember = teamMember.filter((member) => member.status !== STATUS_DELETED);
    dispatch(addTeamMemberListSuccess(registeredMember));
    return response;
  } catch (e) {
    dispatch(addTeamMemberListError(storableError(e)));
  }
};

export const companyLogoUploadImage = (actionPayload) => async (dispatch, getState, sdk) => {
  dispatch(companyLogoUploadImageRequest(actionPayload));
  const formData = new FormData();
  formData.append('image', actionPayload.file);

  return axios
    .post(`${apiBaseUrl()}/api/upload-company-logo-image`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      withCredentials: true,
    })
    .then((res) => {
      const { imageUrl } = res.data;
      dispatch(companyLogoUploadImageSuccess(imageUrl));
    })
    .catch((e) => {
      dispatch(companyLogoUploadImageError(storableError(e)));
    });
};

export const updateCompanyName =
  ({ companyName }) =>
  (dispatch, getState, sdk) => {
    dispatch(companyNameUpdateRequest());
    const bodyParams = {
      publicData: {
        organization: companyName,
      },
    };
    sdk.currentUser
      .updateProfile(bodyParams)
      .then(() => {
        dispatch(companyNameUpdateSuccess());
        dispatch(fetchCurrentUser());
      })
      .catch((e) => {
        dispatch(companyNameUpdateError(storableError(e)));
      });
  };

export const deleteMember =
  ({ memberId, memberEmail }) =>
  async (dispatch) => {
    dispatch(deleteMemberRequest());
    try {
      const res = await deleteTeamMember({ memberId, memberEmail });
      const companyAccount = res.data.data;
      const teamMemberData = get(companyAccount, 'attributes.profile.metadata.teamMemberData', {});
      const teamMember = Object.values(teamMemberData);
      const displayedMembers = teamMember.filter((member) => member.status !== STATUS_DELETED);
      if (displayedMembers.length > 0) {
        dispatch(fetchTeamAccessSuccess(displayedMembers));
      } else {
        dispatch(fetchTeamAccessSuccess([]));
      }
      const hubspotParams = {
        user: companyAccount,
        invitedUsers: displayedMembers.length,
        activeUsers: teamMember.filter((member) => member.status === STATUS_REGISTERED).length,
      };
      updateHubspotPipeline(hubspotParams);
      dispatch(deleteMemberSuccess());
      return Promise.resolve();
    } catch (error) {
      dispatch(deleteMemberFail(error));
      log.error(error, 'delete-team-member-failed', {
        memberId,
        memberEmail,
      });
    }
  };

export const editMemberAccess =
  ({ memberId, memberEmail, creditsValue }) =>
  async (dispatch) => {
    dispatch(editMemberAccessRequest());
    try {
      const res = await editTeamMemberAcces({ memberId, memberEmail, creditsValue });
      const companyAccount = res.data.data;
      const teamMemberData = get(companyAccount, 'attributes.profile.metadata.teamMemberData', {});
      const teamMember = Object.values(teamMemberData);
      const displayedMembers = teamMember.filter((member) => member.status !== STATUS_DELETED);
      if (displayedMembers.length > 0) {
        dispatch(fetchTeamAccessSuccess(displayedMembers));
      } else {
        dispatch(fetchTeamAccessSuccess([]));
      }
      dispatch(editMemberAccessSuccess());
    } catch (error) {
      dispatch(editMemberAccessFail(error));
      log.error(error, 'edit-team-member-access-failed', {
        memberId,
        memberEmail,
      });
    }
  };

export const updateUsageLog = async (
  dispatch,
  getState,
  currentUser,
  teamMembers,
  registeredMember
) => {
  try {
    const hasWindow = typeof window !== 'undefined';

    const teamMembersNumber = Array.isArray(registeredMember) && registeredMember.length;
    const teamMemberIdList = teamMembers.reduce((idList, member) => {
      if (member.id) {
        return idList.concat(member.id);
      }
      return idList;
    }, []);
    const { usageLogBookingRefs } = getState().SubscriptionManagementPage;
    if (hasWindow) {
      //need to be reload because of marketplaceState effects to UsageLogTab state
      if (usageLogBookingRefs.length === 0) {
        dispatch(fetchUsageLogRequest());
      }
      // this is the time consuming task
      const allTransactionsResponse = await getUsageLog({ teamMemberIdList });
      allTransactionsResponse.data.forEach((memberTx) =>
        dispatch(addMarketplaceEntities(memberTx))
      );
      const allTransactions = allTransactionsResponse.data.reduce((allTx, memberTx) => {
        const allMemberTx = denormalisedResponseEntities(memberTx);
        const allCreditMemberTx = allMemberTx.filter((tx) => isB2bTx(tx));
        return [...allTx, ...allCreditMemberTx];
      }, []);

      // async function stall(stallTime = 3000) {
      //   await new Promise((resolve) => setTimeout(resolve, stallTime));
      //   console.log('transactions loaded');
      // }
      // await stall();
      const allTransactionsIds = allTransactions.map((tx) => pick(tx, ['id', 'type']));
      dispatch(
        fetchUsageLogSuccess({
          allTransactionsIds,
          teamMembersNumber,
        })
      );
    }
  } catch (error) {
    dispatch(fetchUsageLogError(storableError(error)));
  }
};

export const loadData = (params, search) => async (dispatch, getState, sdk) => {
  const { tab } = params;
  const availableTabs = [MY_SUBSCRIPTION, TEAM_ACCESS, USAGE_LOG, PAYMENT_HISTORY, SETTINGS];

  if (!availableTabs.includes(tab)) {
    return Promise.reject(new Error(`Invalid tab for SubscriptionManagementPage: ${tab}`));
  }

  await dispatch(fetchCurrentUser());
  await dispatch(fetchCompanyUser());

  const { currentUser } = getState().user;

  const teamMemberData = get(currentUser, 'attributes.profile.metadata.teamMemberData', {});
  const teamMembers = Object.values(teamMemberData);
  const registeredMember = teamMembers.filter((member) => member.status !== STATUS_DELETED);

  const { logoImageUrl } = get(currentUser, 'attributes.profile.publicData', '');
  if (logoImageUrl) {
    dispatch(companyLogoUploadImageSuccess(logoImageUrl));
  }

  const earliestAllowableCancelDate = get(
    currentUser,
    'attributes.profile.privateData.earliestAllowableCancelDate'
  );
  if (earliestAllowableCancelDate) {
    dispatch(fetchEarliestAllowableCancelDate(earliestAllowableCancelDate));
  }

  const { page = 1 } = parse(search);

  switch (tab) {
    case TEAM_ACCESS: {
      try {
        dispatch(fetchTeamAccessRequest());
        if (registeredMember.length > 0) {
          dispatch(fetchTeamAccessSuccess(registeredMember));
        } else {
          dispatch(fetchTeamAccessSuccess([]));
        }
      } catch (e) {
        dispatch(fetchTeamAccessError(storableError(e)));
      }
      break;
    }

    case USAGE_LOG: {
      const totalCredits = get(currentUser, 'attributes.profile.metadata.totalCredits', 0);
      const nextTotalCredits = get(currentUser, 'attributes.profile.metadata.nextTotalCredits', 0);
      const previousUnusedCredits = get(
        currentUser,
        'attributes.profile.metadata.previousUnusedCredits',
        0
      );

      const usedCredits = teamMembers.reduce((sum, member) => sum + member.usedCredits, 0);

      dispatch(
        fetchCreditUsage({ totalCredits, usedCredits, previousUnusedCredits, nextTotalCredits })
      );

      updateUsageLog(dispatch, getState, currentUser, teamMembers, registeredMember);

      break;
    }
    case PAYMENT_HISTORY: {
      try {
        const hasWindow = typeof window !== 'undefined';
        const { paymentHistory: paymentHistoryDuck } = getState().SubscriptionManagementPage;

        if (hasWindow && paymentHistoryDuck.length === 0) {
          dispatch(fetchPaymentHistoryRequest());
          const fetchAllData = async ({ data, offset = null, ...restParam }) => {
            try {
              const res = await getChargebeeInvoices({
                offset,
                ...restParam,
              });

              data.push(...res.list);

              const nextOffset = res.next_offset;

              if (nextOffset) {
                await fetchAllData({
                  data,
                  offset: nextOffset,
                  ...restParam,
                });
              }
            } catch (e) {}
          };

          let data = [];
          fetchAllData({ data, limit: 100 })
            .then(() => {
              dispatch(fetchPaymentHistorySuccess(data));
            })
            .catch((e) => {
              dispatch(fetchPaymentHistoryError(e));
            });
        }
      } catch (e) {
        dispatch(fetchPaymentHistoryError(e));
      }
      break;
    }

    case MY_SUBSCRIPTION: {
      try {
        dispatch(fetchMySubscriptionRequest());
        await dispatch(stripeCustomer());
        await dispatch(fetchCompanyUser());
        const chargebeeUser = await getChargebeeUser();
        const { subscription } = await retrieveMyCompanyCreditSubscription();

        const { next_billing_at: nextBillingAt, resume_date: resumeDate } = subscription || {};
        const { billingAddress } = chargebeeUser.customer;
        const paymentSources = await listChargebeePaymentSourceApi();
        const primaryPaymentSource =
          Array.isArray(paymentSources.results) && paymentSources.results.length > 0
            ? paymentSources.results[0].payment_source.card
            : null;
        dispatch(
          fetchChargebeeInfo({
            billingAddress,
            primaryPaymentSource,
            nextBillingAt,
            resumeDate,
            hasChargebeePaymentSource: !!primaryPaymentSource,
          })
        );
        dispatch(fetchMySubscriptionSuccess());
      } catch (error) {
        log.error(error, 'fetch-my-subscription-tab-failed');
      }
      break;
    }

    default:
      return Promise.resolve();
  }
  if (tab !== USAGE_LOG) {
    updateUsageLog(dispatch, getState, currentUser, teamMembers, registeredMember);
  }
};

export const downloadInvoicePDF = (invoiceId) => async (dispatch, getState, sdk) => {
  dispatch(getInvoicePDFRequest());
  try {
    const invoiceResponse = await getInvoicePDF(invoiceId);
    const { download_url } = invoiceResponse && invoiceResponse.download;
    dispatch(getInvoicePDFSuccess());
    return download_url;
  } catch (error) {
    dispatch(getInvoicePDFFail(error));
    log.error(error, 'get-invoice-fail', {
      invoiceId,
    });
  }
};

export const buyOneTimeCredits =
  ({ totalPrice, oneTimeCreditNumber, paymentSource, paymentOption }) =>
  async (dispatch) => {
    dispatch(buyOneTimeCreditsRequest());
    try {
      await buyOneTimeCreditsApi({
        totalPrice,
        oneTimeCreditNumber,
        paymentSource,
        paymentOption,
      });
      dispatch(buyOneTimeCreditsSuccess());
    } catch (error) {
      dispatch(buyOneTimeCreditsError(storableError(error)));
      throw error;
    }
  };

export const changePaymentMethod =
  ({ paymentSource, billingAddress, primaryPaymentSource }) =>
  async (dispatch) => {
    dispatch(changePaymentMethodRequest());
    try {
      await changePaymentMethodApi({ paymentSource, billingAddress, primaryPaymentSource });
      dispatch(changePaymentMethodSuccess());
    } catch (error) {
      dispatch(changePaymntMethodError(storableError(error)));
    }
  };

export const editSubscription =
  ({ totalCredits, newMonthPrice, startOption, selectedPackage }) =>
  async (dispatch) => {
    dispatch(editSubscriptionRequest());
    try {
      await updateMyCompanyCreditSubscription({
        totalCredits,
        newMonthPrice,
        startOption,
        selectedPackage,
      });
      dispatch(editSubscriptionSuccess());
    } catch (error) {
      dispatch(editSubscriptionError(storableError(error)));
      throw error;
    }
  };

export const cancelSubscription =
  ({ cancelReasonCode, cancelReasonCodeOtherMessage, earliestAllowableCancelDate }) =>
  async (dispatch) => {
    dispatch(cancelSubscriptionRequest());
    try {
      await cancelMyCompanyCreditSubscription({
        cancelReasonCode,
        cancelReasonCodeOtherMessage,
        earliestAllowableCancelDate,
      });
      await dispatch(fetchCompanyUser());
      dispatch(cancelSubscriptionSuccess());
    } catch (error) {
      dispatch(cancelSubscriptionError(storableError(error)));
    }
  };

export const pauseSubscription =
  ({ resumeDate }) =>
  async (dispatch) => {
    dispatch(actionSubscriptionRequest());
    try {
      await pauseMyCompanyCreditSubscription({ resumeDate });
      await dispatch(fetchCompanyUser());
      dispatch(actionSubscriptionSuccess());
    } catch (error) {
      dispatch(actionSubscriptionError(storableError(error)));
    }
  };

export const reactivateSubscription =
  ({ reactivateDate, totalCredits, newMonthPrice, selectedPackage }) =>
  async (dispatch) => {
    dispatch(actionSubscriptionRequest());
    try {
      const formattedNewMonthPrice =
        typeof newMonthPrice !== 'number' ? parseFloat(newMonthPrice) * 100 : newMonthPrice * 100;
      await reactivateMyCompanyCreditSubscription({ reactivateDate });
      await updateMyCompanyCreditSubscription({
        totalCredits,
        newMonthPrice: formattedNewMonthPrice,
        startOption: 'immediately',
        selectedPackage,
      });
      await dispatch(fetchCompanyUser());
      dispatch(actionSubscriptionSuccess());
    } catch (error) {
      dispatch(actionSubscriptionError(storableError(error)));
    }
  };

export const resumeSubscription =
  ({ totalCredits, newMonthPrice }) =>
  async (dispatch) => {
    dispatch(actionSubscriptionRequest());
    try {
      const formattedNewMonthPrice =
        typeof newMonthPrice !== 'number' ? parseFloat(newMonthPrice) * 100 : newMonthPrice * 100;
      await resumeMyCompanyCreditSubscription();
      await updateMyCompanyCreditSubscription({
        totalCredits,
        newMonthPrice: formattedNewMonthPrice,
        startOption: 'immediately',
      });
      await dispatch(fetchCompanyUser());
      dispatch(actionSubscriptionSuccess());
    } catch (error) {
      dispatch(actionSubscriptionError(storableError(error)));
    }
  };
