import React, { memo, useEffect, useMemo, useState } from 'react';

import { SortBy } from '../../../../containers/DashboardPage/components';

import { TableAction } from '../index';
import { TABLE_PROP_TYPES } from '../../config/propTypes';

import css from './MobileTable.module.scss';

const MobileTable = memo(({ headers, data, onRowClick, onInitAction, tableId, intl }) => {
  const [sortBy, setSortBy] = useState('');

  const sortedData = useMemo(
    () => data.sort((a, b) => (a[sortBy] > b[sortBy] ? 1 : -1)),
    [data, sortBy]
  );

  return (
    <>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          marginBottom: '0.5px solid #000000',
        }}
      >
        <p>{intl.formatMessage({ id: 'LocationsTab.locationCount' }, { num: data.length })}</p>
        <SortBy sortBy={sortBy} onUpdateSortBy={setSortBy} id={tableId} />
      </div>
      <ul>
        {sortedData.map((row, i) => (
          <li
            key={`mobile_table_row_${row.id}`}
            className={css.row}
            onClick={() => onRowClick && onRowClick({ id: row.id, i })}
          >
            <>
              {row.mobile_content}
              <TableAction
                id={row.id}
                action={row.action}
                status={row.status}
                isUnassigned={tableId === 'unassigned_listings'}
                onInitAction={onInitAction}
              />
            </>
            {!!row.addToLocationAction && (
              <div className={css.addToLocationActionWrapper}>{row.addToLocationAction}</div>
            )}
          </li>
        ))}
      </ul>
    </>
  );
});

MobileTable.propTypes = TABLE_PROP_TYPES;

export default MobileTable;
