import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Link, useParams, useLocation } from 'react-router-dom';

import {
  AuthenticationModal,
  Avatar,
  InlineTextButton,
  LanguageSelector,
  Logo,
  Menu,
  MenuContent,
  MenuItem,
  MenuLabel,
  NamedLink,
} from '../../components';
import { ButtonNew } from '../../components_new';
import { BUTTON_SIZES, BUTTON_TYPES, LINK_COLORS } from '../../components_new/ButtonNew/ButtonNew';
import { ACCOUNT_SETTINGS_PAGES } from '../../routeConfiguration';
import { FormattedMessage, intlShape } from '../../util/reactIntl';
import { propTypes } from '../../util/types';

import { ListingSearchForm } from '../../forms';
import { TABS } from '../../ducks/DashboardPage.duck';

import css from './TopbarDesktop.module.scss';

const TopbarDesktop = (props) => {
  const {
    className,
    currentUser,
    currentPage,
    rootClassName,
    currentUserHasListings,
    currentUserHasLocations,
    notificationCount,
    intl,
    isAuthenticated,
    onLogout,
    isB2BEmployee,
    isB2BAccount,
    showBookADemoButton,
    isScrolled,
    history,
  } = props;
  const params = useParams();
  const [mounted, setMounted] = useState(false);
  const [showLogin, setShowLogin] = useState(false);
  const [aboutDropdown, setAboutDropdown] = useState(false);

  const { pathname } = useLocation();
  const isSearchPage = pathname === '/s';
  const isLandingPage = currentPage === 'LandingPage';

  useEffect(() => {
    setMounted(true);
  }, []);

  const authenticatedOnClientSide = mounted && isAuthenticated;
  const isAuthenticatedOrJustHydrated = isAuthenticated || !mounted;

  const classes = classNames(rootClassName || css.root, className, {
    [css.isLandingPage]: isLandingPage && !isScrolled,
  });

  const currentPageClass = (page) => {
    const isAccountSettingsPage =
      page === 'AccountSettingsPage' && ACCOUNT_SETTINGS_PAGES.includes(currentPage);
    return currentPage === page || isAccountSettingsPage ? css.currentPage : null;
  };

  const profileMenu = authenticatedOnClientSide ? (
    <Menu className={css.profileMenu}>
      <MenuLabel className={css.profileMenuLabel} isOpenClassName={css.profileMenuIsOpen}>
        {notificationCount > 0 ? (
          <div className={classNames(css.notificationDot, css.avatarDot)} />
        ) : null}
        <Avatar className={css.avatar} user={currentUser} disableProfileLink />
      </MenuLabel>
      <MenuContent className={css.profileMenuContent}>
        <MenuItem key="InboxPage">
          <NamedLink
            className={classNames(css.yourListingsLink, currentPageClass('InboxPage'))}
            name={isB2BAccount || isB2BEmployee ? 'CompanyBookingsPage' : 'InboxPage'}
            params={!isB2BAccount ? { tab: currentUserHasListings ? 'sales' : 'orders' } : null}
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.inbox" />
            {notificationCount > 0 ? <div className={classNames(css.notificationDot)} /> : null}
          </NamedLink>
          <div>
            <span className={css.menuItemBorder} />
          </div>
        </MenuItem>
        <MenuItem key="SubscriptionManagementPage">
          {isB2BAccount && (
            <>
              <NamedLink
                className={classNames(
                  css.yourListingsLink,
                  currentPageClass('SubscriptionManagementPage')
                )}
                name="SubscriptionManagementPage"
                params={{ tab: 'team-access' }}
              >
                <span className={css.menuItemBorder} />
                <FormattedMessage id="TopbarDesktop.companyAccount" />
              </NamedLink>
              <div>
                <span className={css.menuItemBorder} />
              </div>
            </>
          )}
        </MenuItem>
        <MenuItem key="ManageListingsPage">
          <>
            <NamedLink
              className={classNames(css.yourListingsLink, currentPageClass('ManageListingsPage'))}
              name="DashboardPage"
              state={{ from: currentPage, ...params }}
              params={{ view: 'locations', tab: TABS.LOCATIONS }}
            >
              <span className={css.menuItemBorder} />
              <FormattedMessage
                id={
                  currentUserHasListings || currentUserHasLocations
                    ? 'TopbarDesktop.yourListingsLink'
                    : 'TopbarDesktop.becomePartner'
                }
              />
            </NamedLink>
            <div>
              <span className={css.menuItemBorder} />
            </div>
          </>
        </MenuItem>
        <MenuItem key="AccountSettingsPage">
          <NamedLink
            className={classNames(css.yourListingsLink, currentPageClass('AccountSettingsPage'))}
            name="AccountSettingsPage"
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.accountSettingsLink" />
          </NamedLink>
        </MenuItem>
        <MenuItem key="logout">
          <InlineTextButton rootClassName={css.logoutButton} onClick={onLogout}>
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.logout" />
          </InlineTextButton>
        </MenuItem>
      </MenuContent>
    </Menu>
  ) : null;

  /* Chechking if the page is Landing page as well, after checking if the user is authenticated (logged in) */
  const loginLink = isAuthenticatedOrJustHydrated ? null : (
    <div
      name="LoginPage"
      id="LoginButtonIdentifier"
      className={css.navLink}
      onClick={() => setShowLogin(true)}
    >
      <FormattedMessage id="TopbarDesktop.login" />
    </div>
  );

  return (
    <nav className={classes}>
      <div
        className={classNames(css.leftSide, {
          [css.searchPage]: isSearchPage,
        })}
      >
        <NamedLink
          className={css.logoLink}
          name={isAuthenticated ? 'LandingPageLoggedIn' : 'LandingPage'}
        >
          <Logo
            format="desktop"
            color={isLandingPage && !isScrolled ? 'white' : 'black'}
            className={css.logo}
            alt={intl.formatMessage({ id: 'TopbarDesktop.logo' })}
          />
        </NamedLink>

        {!!isSearchPage && (
          <div className={css.searchFormWrapper}>
            <ListingSearchForm />
          </div>
        )}
        {!!isSearchPage && (
          <ButtonNew
            className={css.button}
            name="OnboardingPage"
            type={BUTTON_TYPES.GREEN}
            size={BUTTON_SIZES.SMALL}
          >
            {intl.formatMessage({ id: 'TopbarDesktop.matchMe' })}
          </ButtonNew>
        )}
      </div>

      <AuthenticationModal
        isOpen={showLogin}
        onClose={() => setShowLogin(false)}
        history={history}
      />

      {/* -Placed the links in the "rightside" container to be easier to manipulate them
      -Also placed the "rightSide" inside another container (rightSideContainer) along with
      the {profileMenu}. That's because the links are displayed as inline, and the profileMenu as inline-block
      */}
      <nav className={css.rightSideContainer}>
        {!isSearchPage && (
          <nav className={css.rightSide}>
            <NamedLink className={css.navLink} name="SearchPage">
              <FormattedMessage id="TopbarDesktop.findWorkspaceLink" />
            </NamedLink>
            <NamedLink
              className={classNames(css.navLink, {
                [css.active]: currentPage === 'Partner',
              })}
              name="Partner"
            >
              <FormattedMessage id="TopbarDesktop.spaceSupplier" />
            </NamedLink>
            <div
              className={classNames(css.navLink, css.dropdownWrapper)}
              onMouseEnter={() => setAboutDropdown(true)}
              onMouseLeave={() => setAboutDropdown(false)}
            >
              {intl.formatMessage({ id: 'TopbarDesktop.aboutLink' })}
              <svg
                className={css.arrow}
                width="14"
                height="8"
                viewBox="0 0 14 8"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1 0.5L7 6.5L13 0.5"
                  stroke={isLandingPage && !isScrolled ? 'white' : 'black'}
                />
              </svg>
              {aboutDropdown && (
                <div
                  className={classNames(css.dropdown, {
                    [css.landingPage]: isLandingPage && !isScrolled,
                  })}
                >
                  <NamedLink
                    className={classNames(css.navLink, css.dropdownLink, {
                      [css.landingPage]: isLandingPage && !isScrolled,
                      [css.active]: currentPage === 'AboutPage',
                    })}
                    name="AboutPage"
                  >
                    <FormattedMessage id="TopbarDesktop.toAboutPage" />
                  </NamedLink>
                  <NamedLink
                    className={classNames(css.navLink, css.dropdownLink, {
                      [css.landingPage]: isLandingPage && !isScrolled,
                      [css.active]: currentPage === 'ContactUs',
                    })}
                    name="ContactUs"
                  >
                    <FormattedMessage id="TopbarDesktop.toContactPage" />
                  </NamedLink>
                </div>
              )}
            </div>
            {loginLink}
          </nav>
        )}
        {profileMenu}
        <div className={css.languageSelectorWrapper}>
          <LanguageSelector currentPage={currentPage} isScrolled={isScrolled} />
        </div>
        {showBookADemoButton && (
          <FormattedMessage id="TopbarDesktop.bookADemo">
            {(id) => (
              <ButtonNew
                className={css.showBookADemoButton}
                externalLink="https://meetings.hubspot.com/sofia-bostroem"
                size={BUTTON_SIZES.SMALL}
              >
                {id}
              </ButtonNew>
            )}
          </FormattedMessage>
        )}
      </nav>
    </nav>
  );
};

const { bool, func, object, number, string } = PropTypes;

TopbarDesktop.defaultProps = {
  rootClassName: null,
  className: null,
  currentUser: null,
  currentPage: null,
  notificationCount: 0,
  initialSearchFormValues: {},
  showBookADemoButton: false,
  isScrolled: false,
};

TopbarDesktop.propTypes = {
  rootClassName: string,
  className: string,
  currentUserHasListings: bool.isRequired,
  currentUser: propTypes.currentUser,
  currentPage: string,
  isAuthenticated: bool.isRequired,
  onLogout: func.isRequired,
  notificationCount: number,
  initialSearchFormValues: object,
  intl: intlShape.isRequired,
  showBookADemoButton: bool,
  isScrolled: bool,
};

export default TopbarDesktop;
