import React, { Component } from 'react';
import classNames from 'classnames';
import { bool, string } from 'prop-types';
import config from '../../config';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { isMobileSafari } from '../../util/userAgent';
import { NamedLink, Modal } from '../../components';
import AddToCalendarGenerator from '../../util/AddToCalendarGenerator';
import { DownloadReceiptForm } from '../../forms';

import image from '../../assets/transactionPanel.svg';

import css from './TransactionPanel.module.scss';

export class TransactionPanelComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sendMessageFormFocused: false,
      isReviewModalOpen: false,
      reviewSubmitted: false,
      isCalendarModalOpen: false,
      isReceiptModalOpen: false,
    };
    this.isMobSaf = false;
    this.transactionData = props.transaction;
  }
  componentDidMount() {
    this.isMobSaf = isMobileSafari();
  }

  render() {
    const {
      rootClassName,
      className,
      savePaymentMethodFailed,
      intl,
      transaction: tx,
      currentUser,
      isRequested,
      locationName,
    } = this.props;

    const paymentMethodsPageLink = (
      <NamedLink name="PaymentMethodsPage">
        <FormattedMessage id="TransactionPanel.paymentMethodsPageLink" />
      </NamedLink>
    );

    const classes = classNames(rootClassName || css.root, className);

    const links = [
      // these are commented out until we decide what to do with them
      // { icon: '/static/icons/calendar.svg', textID: 'calendar', link: '' },
      // { icon: '/static/icons/printer.svg', textID: 'printer', link: '' },
      {
        icon: config.siteSpotifyLogo,
        textID: 'spotify',
        link: config.siteSpotifyPage,
      },
      {
        icon: config.siteFacebookLogo,
        textID: 'facebook',
        link: config.siteFacebookPage,
      },
      {
        icon: config.siteInstagramLogo,
        textID: 'instagram',
        link: config.siteInstagramPage,
      },
    ];

    const linksList = links.map((link) => (
      <li className={css.linkButton} key={`transactionPanel.${link.icon}.link`}>
        <a href={link.link} target="_blank" rel="noreferrer noopener" className={css.linkContent}>
          <img className={css.icon} src={link.icon} alt={`${link.icon} icon`} />
          <span>{intl.formatMessage({ id: `orderConfirmationPage.link.${link.textID}` })}</span>
        </a>
      </li>
    ));

    const calendarURLs = AddToCalendarGenerator({ ...this.transactionData, locationName });

    const calendarLinks = [
      {
        icon: '/static/icons/Calendar_Google.svg',
        textID: 'google',
        link: calendarURLs.google,
      },
      {
        icon: '/static/icons/Calendar_Outlook.svg',
        textID: 'outlook',
        link: calendarURLs.outlook,
      },
      {
        icon: '/static/icons/Calendar_Outlook.svg',
        textID: 'office365',
        link: calendarURLs.office365,
      },
      {
        icon: '/static/icons/Calendar_Apple.svg',
        textID: 'ics',
        link: calendarURLs.ics,
      },
    ];
    const heading = intl.formatMessage({
      id: !isRequested ? 'orderConfirmationPage.heading' : 'orderConfirmationPage.headingRequest',
    });
    const subheading = intl.formatMessage(
      {
        id: !isRequested
          ? 'orderConfirmationPage.subheading'
          : 'orderConfirmationPage.subheadingRequested',
      },
      { title: locationName }
    );

    const CalendarLinksList = calendarLinks.map((link) => (
      <li className={css.linkButton} key={`transactionPanel.${link.icon}.link`}>
        <a href={link.link} target="_blank" rel="noreferrer noopener" className={css.linkContent}>
          <img className={css.icon} src={link.icon} alt={`${link.icon} icon`} />
          <span>{intl.formatMessage({ id: `orderConfirmationPage.link.${link.textID}` })}</span>
        </a>
      </li>
    ));

    return (
      <div className={classes}>
        <div
          className={css.contentWrapper}
          onClick={() =>
            this.state.isCalendarModalOpen && this.setState({ isCalendarModalOpen: false })
          }
        >
          <div className={`${css.text} ${css.mobile}`}>
            <h1 className={css.heading}>{heading}</h1>
            <p className={css.subheading}>{subheading}</p>
          </div>
          <div className={css.textWrapper}>
            <div className={`${css.text} ${css.desktop}`}>
              <h1 className={css.heading}>{heading}</h1>
              <p className={css.subheading}>{subheading}</p>
            </div>
            {isRequested && (
              <div className={css.links}>
                <ul className={css.linksList}>
                  <li
                    className={classNames(css.linkButton, {
                      [css.calendarIsOpen]: this.state.isCalendarModalOpen,
                    })}
                    key={`transactionPanel.addToCalendar.link`}
                  >
                    <div
                      className={css.linkContent}
                      onClick={() => this.setState({ isCalendarModalOpen: true })}
                    >
                      <img
                        className={css.icon}
                        src={'/static/icons/calendar.svg'}
                        alt={`Add to calendar`}
                      />
                      <span>
                        {intl.formatMessage({ id: `orderConfirmationPage.link.calendar` })}
                      </span>
                    </div>
                    {this.state.isCalendarModalOpen && (
                      <ul className={css.calendarLinksWrapper}>{CalendarLinksList}</ul>
                    )}
                  </li>
                  <li className={css.linkButton} key={`transactionPanel.downloadReceipt.link`}>
                    <div
                      className={css.linkContent}
                      onClick={() => this.setState({ isReceiptModalOpen: true })}
                    >
                      <img
                        className={css.icon}
                        src={'/static/icons/receipt.svg'}
                        alt={`Download your receipt`}
                      />
                      <span>
                        {intl.formatMessage({ id: `orderConfirmationPage.link.receipt` })}
                      </span>
                    </div>
                    <Modal
                      id="DownloadReceipt"
                      isOpen={this.state.isReceiptModalOpen}
                      onClose={(e) => {
                        e.preventDefault();
                        this.setState({ isReceiptModalOpen: false });
                      }}
                      containerClassName={css.modalContainer}
                      usePortal
                    >
                      <DownloadReceiptForm
                        setIsReceiptModalOpen={() => this.setState({ isReceiptModalOpen: false })}
                        tx={tx}
                        currentUser={currentUser}
                      />
                    </Modal>
                  </li>
                  {linksList}
                </ul>
              </div>
            )}
          </div>
          <div className={css.image}>
            <img
              className={css.svg}
              src={`${config.canonicalRootURL}${image}`}
              alt="vector graphic"
            />
          </div>

          {savePaymentMethodFailed ? (
            <p className={css.genericError}>
              <FormattedMessage
                id="TransactionPanel.savePaymentMethodFailed"
                values={{ paymentMethodsPageLink }}
              />
            </p>
          ) : null}
        </div>
      </div>
    );
  }
}

TransactionPanelComponent.defaultProps = {
  rootClassName: null,
  className: null,
  savePaymentMethodFailed: false,
};

TransactionPanelComponent.propTypes = {
  rootClassName: string,
  className: string,
  savePaymentMethodFailed: bool,

  // from injectIntl
  intl: intlShape,
};

const TransactionPanel = injectIntl(TransactionPanelComponent);

export default TransactionPanel;
