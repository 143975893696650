import classNames from 'classnames';
import { bool, func, object, string } from 'prop-types';
import React from 'react';
import config from '../../config';
import { EditListingWorkspaceForm } from '../../forms';
import { ensureOwnListing } from '../../util/data';
import { getLocale } from '../../util/localeHelper';
import { findOptionsForSelectFilter } from '../../util/search';
import {
  FIXED_OFFICE_CATEGORY,
  LISTING_STATE_DRAFT,
  MEETINGROOMS_CATEGORY,
} from '../../util/types';

import css from './EditListingWorkspacePanel.module.scss';

export const MAX_SEATS_DIVIDER = 11;
export const MIN_SEATS_DIVIDER = 17;

const EditListingWorkspacePanel = (props) => {
  const {
    className,
    rootClassName,
    listing,
    locationId,
    disabled,
    ready,
    onSubmit,
    onChange,
    submitButtonText,
    panelUpdated,
    updateInProgress,
    onSaveAndExitListingWizardTab,
    errors,
  } = props;

  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureOwnListing(listing);
  const { description, publicData } = currentListing.attributes;
  const isPublished = currentListing.id && currentListing.attributes.state !== LISTING_STATE_DRAFT;

  const {
    areaMin,
    areaMax,
    calculatedSeats,
    category,
    originLang,
    minSeats,
    maxSeats,
    numberOfInstance,
    fixedOfficeType,
    acceptBookingOnRequest,
    en = {},
    sv = {},
  } = publicData;

  const initialValues = {
    areaMin: areaMin && areaMin > 0 && areaMin,
    areaMax,
    category,
    minSeats: !calculatedSeats && minSeats && minSeats > 0 ? minSeats : null,
    maxSeats: !calculatedSeats ? maxSeats : null,
    originLang: originLang || getLocale(),
    numberOfInstance,
    fixedOfficeType,
    en: en.description || '',
    sv: sv.description || '',
  };

  const categoryOptions = findOptionsForSelectFilter('category', config.custom.filters);
  const fixedOfficeTypeOptions = findOptionsForSelectFilter(
    'fixedOfficeType',
    config.custom.filters
  );
  return (
    <div className={classes}>
      <div className={css.panelContainer}>
        <EditListingWorkspaceForm
          className={css.form}
          initialValues={initialValues}
          saveActionMsg={submitButtonText}
          onSubmit={(values) => {
            const {
              description,
              category,
              originLang,
              minSeats,
              maxSeats,
              numberOfInstance,
              fixedOfficeType,
              areaMin,
              areaMax,
              en,
              sv,
            } = values;
            const calculatedMinSeats =
              (areaMin || areaMax) && !(minSeats || maxSeats)
                ? areaMin
                  ? Math.round(Number(areaMin) / MIN_SEATS_DIVIDER)
                  : Math.round(Number(areaMax) / MIN_SEATS_DIVIDER)
                : null;
            const calculatedMaxSeats =
              (areaMin || areaMax) && !(minSeats || maxSeats)
                ? areaMax
                  ? Math.round(Number(areaMax) / MAX_SEATS_DIVIDER)
                  : Math.round(Number(areaMin) / MAX_SEATS_DIVIDER)
                : null;

            const updateValues = {
              /* TODO: Leaving description on first level of publicData for backwards compatibility */
              description: values[originLang || getLocale()],
              publicData: {
                areaMin: areaMin && areaMin > 0 && areaMin,
                areaMax,
                category,
                originLang,
                minSeats: calculatedMinSeats
                  ? calculatedMinSeats
                  : minSeats && minSeats > 0
                  ? minSeats
                  : null,
                maxSeats: calculatedMaxSeats ? calculatedMaxSeats : maxSeats > 0 ? maxSeats : null,
                [originLang]: { description },
                numberOfInstance,
                fixedOfficeType: category === FIXED_OFFICE_CATEGORY ? fixedOfficeType : null,
                acceptBookingOnRequest:
                  category !== MEETINGROOMS_CATEGORY ? null : acceptBookingOnRequest,
                calculatedSeats: !!(calculatedMaxSeats || calculatedMinSeats),
                en: { ...publicData.en, description: en },
                sv: { ...publicData.sv, description: sv },
              },
            };

            onSubmit(updateValues);
          }}
          onChange={onChange}
          disabled={disabled}
          ready={ready}
          updated={panelUpdated}
          updateInProgress={updateInProgress}
          fetchErrors={errors}
          categoryOptions={categoryOptions}
          category={category}
          fixedOfficeTypeOptions={fixedOfficeTypeOptions}
          onHandleSaveAndExit={onSaveAndExitListingWizardTab}
          isPublished={isPublished}
          locationId={locationId}
          acceptBookingOnRequest={acceptBookingOnRequest}
        />
      </div>
    </div>
  );
};

EditListingWorkspacePanel.defaultProps = {
  className: null,
  rootClassName: null,
  errors: null,
  listing: null,
};

EditListingWorkspacePanel.propTypes = {
  className: string,
  rootClassName: string,

  // We cannot use propTypes.listing since the listing might be a draft.
  listing: object,

  disabled: bool.isRequired,
  ready: bool.isRequired,
  onSubmit: func.isRequired,
  onChange: func.isRequired,
  submitButtonText: string.isRequired,
  panelUpdated: bool.isRequired,
  updateInProgress: bool.isRequired,
  errors: object.isRequired,
};

export default EditListingWorkspacePanel;
