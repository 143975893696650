import React from 'react';
import css from './FilterPillButton.module.scss';

const FilterPillButton = (props) => {
  const { children, onClick } = props;
  return (
    <button className={css.pill} onClick={onClick}>
      {children}
      <svg width="9" height="9" viewBox="0 0 9 9" fill="none" xmlns="http://www.w3.org/2000/svg">
        <line
          x1="8.32755"
          y1="0.707107"
          x2="0.7071"
          y2="8.32755"
          stroke="#222222"
          strokeLinecap="round"
        />
        <line
          x1="0.5"
          y1="-0.5"
          x2="11.2769"
          y2="-0.5"
          transform="matrix(-0.707109 -0.707105 0.707109 -0.707105 9 8.32764)"
          stroke="#222222"
          strokeLinecap="round"
        />
      </svg>
    </button>
  );
};

export default FilterPillButton;
