import React from 'react';
import { node, string } from 'prop-types';
import classNames from 'classnames';
import { Field } from 'react-final-form';

import css from './FieldRadioButton.module.scss';

const IconRadioButton = (props) => {
  const { checkedClassName } = props;
  return (
    <div>
      <svg className={props.className} width="14" height="14" xmlns="http://www.w3.org/2000/svg">
        <circle
          className={props.showAsRequired ? css.required : css.notChecked}
          cx="5"
          cy="19"
          r="6"
          transform="translate(2 -12)"
          strokeWidth="2"
          fill="none"
          fillRule="evenodd"
        />

        <g
          className={classNames(css.checked, checkedClassName || css.checkedStyle)}
          transform="translate(2 -12)"
          fill="none"
          fillRule="evenodd"
        >
          <circle strokeWidth="2" cx="5" cy="19" r="6" />
          <circle fill="#FFF" fillRule="nonzero" cx="5" cy="19" r="3" />
        </g>
      </svg>
    </div>
  );
};

const IconRadioButtonNew = (props) => {
  const { checkedClassName, className, showAsRequired } = props;
  return (
    <svg
      className={className}
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        className={classNames(showAsRequired ? css.newRequired : css.outCircle)}
        x="0.5"
        y="0.5"
        width="19"
        height="19"
        rx="9.5"
        stroke="#999999"
      />
      <rect
        className={classNames(css.newChecked, checkedClassName)}
        width="8"
        height="8"
        rx="4"
        transform="matrix(1 0 0 -1 6 14)"
        fill="white"
      />
    </svg>
  );
};

IconRadioButton.defaultProps = { className: null };

IconRadioButton.propTypes = { className: string };

const FieldRadioButtonComponent = (props) => {
  const {
    rootClassName,
    className,
    svgClassName,
    checkedClassName,
    id,
    label,
    showAsRequired,
    labelClassName,
    textClassName,
    ...rest
  } = props;

  const classes = classNames(rootClassName || css.root, className);
  const labelClasses = classNames(css.label, labelClassName);
  const textClasses = classNames(css.text, textClassName);
  const radioButtonProps = {
    id,
    className: css.input,
    component: 'input',
    type: 'radio',
    ...rest,
  };

  return (
    <span className={classes}>
      <Field {...radioButtonProps} />
      <label htmlFor={id} className={labelClasses}>
        <span className={css.radioButtonWrapper}>
          <IconRadioButtonNew
            className={svgClassName}
            checkedClassName={checkedClassName}
            showAsRequired={showAsRequired}
          />
        </span>
        <span className={textClasses}>{label}</span>
      </label>
    </span>
  );
};

FieldRadioButtonComponent.defaultProps = {
  className: null,
  rootClassName: null,
  svgClassName: null,
  checkedClassName: null,
  label: null,
  labelClassName: null,
  textClassName: null,
};

FieldRadioButtonComponent.propTypes = {
  className: string,
  rootClassName: string,
  svgClassName: string,
  checkedClassName: string,

  // Id is needed to connect the label with input.
  id: string.isRequired,
  label: node,

  // Name groups several RadioButtones to an array of selected values
  name: string.isRequired,

  // RadioButton needs a value that is passed forward when user checks the RadioButton
  value: string.isRequired,
  labelClassName: string,
  textClassName: string,
};

export default FieldRadioButtonComponent;
