import React from 'react';

import css from './AvatarSecondary.module.scss';

const abbreviatedName = ({ first_name, last_name }) => {
  const first = first_name?.charAt(0).toUpperCase();
  const last = last_name?.charAt(0).toUpperCase();

  if (!last) {
    return first;
  } else if (!first) {
    return last;
  } else {
    return `${first}${last}`;
  }
};

const AvatarSecondary = ({ first_name, last_name, url }) => (
  <div className={css.avatar}>
    {url ? (
      <img className={css.img} src={url} alt="" />
    ) : (
      <div className={css.abbreviatedName}>{abbreviatedName({ first_name, last_name })}</div>
    )}
  </div>
);

export default AvatarSecondary;
