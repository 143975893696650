/**
 * @param data {Array}
 * @param sortBy {String}
 * */
function sortByEmailFunc({ data, sortBy }) {
  if (sortBy === '') {
    return data;
  } else {
    if (sortBy === 'asc') {
      return data.sort((a, b) => (a.personData.email > b.personData.email ? 1 : -1));
    } else {
      return data.sort((a, b) => (a.personData.email < b.personData.email ? 1 : -1));
    }
  }
}

export default sortByEmailFunc;
