import React, { useCallback, useState } from 'react';
import { FieldSelect, FieldTextInput } from '..';
import { LANGUAGE_CODES } from '../../util/modernmt';
import { injectIntl } from '../../util/reactIntl';
import classNames from 'classnames';
import css from './LanguageSelect.module.scss';

const LanguageSelect = (props) => {
  const { id, name, className, optionToBlock, form, initialLangValue, translateCb, intl } = props;
  const languageOptions = [...LANGUAGE_CODES.LIST];
  const [selectedLang, setSelectedLang] = useState(initialLangValue);

  const formatLang = (lang) => {
    switch (lang) {
      case LANGUAGE_CODES.ENGLISH:
        return intl.formatMessage({ id: 'languageName.en' });
      case LANGUAGE_CODES.SWEDISH:
        return intl.formatMessage({ id: 'languageName.sv' });
      default:
        return null;
    }
  };
  const checkSelectedLang = useCallback(
    (lang) => {
      return selectedLang === lang;
    },
    [selectedLang]
  );

  const langOptionClassname = (lang) =>
    classNames(css.langOption, {
      [css.selected]: checkSelectedLang(lang),
    });
  const handleLangOptionClick = (lang) => () => {
    if (lang === optionToBlock) return;
    setSelectedLang(lang);
    if (form) {
      form.change(name, lang);
    } else if (!form && lang !== initialLangValue) {
      translateCb(lang);
    } else if (!form && lang === initialLangValue) {
      translateCb();
    }
  };
  return (
    <>
      <div className={className}>
        {languageOptions.map((lang) => (
          <span
            key={lang}
            className={langOptionClassname(lang)}
            onClick={handleLangOptionClick(lang)}
          >
            {formatLang(lang)}
          </span>
        ))}
      </div>
      {form && id && name && <FieldTextInput id={id} name={name} type="hidden" />}
    </>
  );
};

export default injectIntl(LanguageSelect);
