import classNames from 'classnames';
import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { ButtonNew } from '../../../../components_new';
import {
  BUTTON_SIZES,
  BUTTON_TYPES,
  LINK_COLORS,
  MOBILE_POSITION,
} from '../../../../components_new/ButtonNew/ButtonNew';
import { ListingSearchForm } from '../../../../forms';
import { intlShape } from '../../../../util/reactIntl';
import apollo from './Assets/apollo.png';
import HeroImageMobile from './Assets/hero_mobile.png';
import HeroImageDesktop from './Assets/hero_desktop.png';
import hm from './Assets/hm.png';
import mendi from './Assets/mendi.png';
import parakey from './Assets/parakey.png';
import rco from './Assets/rco.png';
import remote from './Assets/remote.png';
import css from './SectionHero.module.scss';

const SectionHero = ({ isMobile, intl }) => {
  const SECTION_NAME = 'LandingPage.hero';

  const checkMark = (
    <svg width="16" height="13" viewBox="0 0 16 13" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1 6.25851L5.66667 11.517L15 1" stroke="white" stroke-width="1.5" />
    </svg>
  );

  return (
    <div className={css.sectionHeroWrapper}>
      <div className={css.sectionHero}>
        <div className={css.textContainer}>
          <h1 className={css.desktop}>{intl.formatMessage({ id: `${SECTION_NAME}.title` })}</h1>
          <h1 className={css.mobile}>
            {intl.formatMessage({ id: `${SECTION_NAME}.titleMobile` })}
          </h1>
          <p className={css.text}>{<FormattedMessage id={`${SECTION_NAME}.text`} />}</p>
          <ul className={css.heroList}>
            <li>
              {checkMark}
              <span className={css.heroBullet}>
                {intl.formatMessage({ id: `${SECTION_NAME}.bullet1` })}
              </span>
            </li>
            <li>
              {checkMark}
              <span className={css.heroBullet}>
                {intl.formatMessage({ id: `${SECTION_NAME}.bullet2` })}
              </span>
            </li>
            <li key="fixedOffice">
              {checkMark}
              <span className={css.heroBullet}>
                <FormattedMessage id={`${SECTION_NAME}.bullet3`} />
              </span>
            </li>
          </ul>
          <div className={css.searchContainer}>
            <ListingSearchForm />
            <p>{intl.formatMessage({ id: `${SECTION_NAME}.or` })}</p>
            <ButtonNew
              className={css.matchButton}
              name="OnboardingPage"
              type={BUTTON_TYPES.GREENWITHWHITEBORDER}
              linkColor={LINK_COLORS.WHITE}
              size={BUTTON_SIZES.small}
              mobileButtonPosition={MOBILE_POSITION.FULL_WIDTH}
            >
              {intl.formatMessage({ id: `${SECTION_NAME}.button` })}
            </ButtonNew>
          </div>
        </div>
        <div className={css.imageContainer}>
          <img src={HeroImageDesktop} className={classNames(css.heroImage, css.desktop)} alt="" />
          <img src={HeroImageMobile} className={classNames(css.heroImage, css.mobile)} alt="" />
        </div>
      </div>
      <div className={css.logoContainer}>
        <img src={hm} className={css.image} alt="hm" />
        <img src={remote} className={css.image} alt="remote" />
        <img src={rco} className={css.image} alt="RCO" />
        <img src={parakey} className={css.image} alt="parakey" />
        <img src={mendi} className={css.image} alt="mendi" />
        <img src={apollo} className={css.image} alt="apollo" />
      </div>
    </div>
  );
};

SectionHero.propTypes = {
  intl: intlShape,
};

export default injectIntl(SectionHero);
