import React from 'react';
import { MediaAndTextSection } from '../../components_new';
import ImageEN from './assets/categories_EN.svg';
import ImageSV from './assets/categories_SV.svg';
import css from './PartnerProffessional.module.scss';

const SectionYourTerms = ({ intl }) => {
  return (
    <section className={css.sectionHero}>
      <MediaAndTextSection
        title={{
          content: intl.formatMessage({ id: 'PartnerProffessional.SectionYourTerms.title' }),
          showMobile: true,
        }}
        subtitle={{
          content: intl.formatMessage({ id: 'PartnerProffessional.SectionYourTerms.subtitle' }),
          showMobile: true,
        }}
        bullets={{
          list: [
            intl.formatMessage({ id: 'PartnerProffessional.SectionYourTerms.bullet1' }),
            intl.formatMessage({ id: 'PartnerProffessional.SectionYourTerms.bullet2' }),
            intl.formatMessage({ id: 'PartnerProffessional.SectionYourTerms.bullet3' }),
          ],
          showMobile: true,
        }}
        bulletType={'check'}
        checkColor={'black'}
        media={{ content: intl.locale === 'sv' ? ImageSV : ImageEN, showMobile: true }}
        wideMedia
        textAlign={'right'}
        textAlignMobile={'top'}
        bgColor={'dark'}
      />
    </section>
  );
};

export default SectionYourTerms;
