import { ImageSlider } from '../../components';
import { CATEGORIES } from '../../components/ImageSlider/ImageSlider';
import author1 from './CustomerReviewsAuthors/author1.png';
import author2 from './CustomerReviewsAuthors/author2.png';
import author3 from './CustomerReviewsAuthors/author3.png';
import author4 from './CustomerReviewsAuthors/author4.png';
import author5 from './CustomerReviewsAuthors/author5.png';
import author6 from './CustomerReviewsAuthors/author6.png';
import css from './CustomerReviewsSlider.module.scss';
import { injectIntl } from 'react-intl';

const CustomerReviewsSlider = ({ intl }) => {
  const customerReviews = [
    {
      img: author1,
      text: intl.formatMessage({ id: 'OurCustomers.review.description1' }),
      author: intl.formatMessage({ id: 'OurCustomers.review.author1' }),
      alt: intl.formatMessage({ id: 'OurCustomers.review.author1' }),
      type: intl.formatMessage({ id: 'OurCustomers.review.type.respacesPass' }),
    },
    {
      img: author2,
      text: intl.formatMessage({ id: 'OurCustomers.review.description2' }),
      author: intl.formatMessage({ id: 'OurCustomers.review.author2' }),
      alt: intl.formatMessage({ id: 'OurCustomers.review.author2' }),
      type: intl.formatMessage({ id: 'OurCustomers.review.type.respacesPass' }),
    },
    // {
    //   img: author3,
    //   text: intl.formatMessage({ id: 'OurCustomers.review.description3' }),
    //   author: intl.formatMessage({ id: 'OurCustomers.review.author3' }),
    //   alt: intl.formatMessage({ id: 'OurCustomers.review.author3' }),
    //   type: intl.formatMessage({ id: 'OurCustomers.review.type.leasor' }),
    // },
    {
      img: author4,
      text: intl.formatMessage({ id: 'OurCustomers.review.description4' }),
      author: intl.formatMessage({ id: 'OurCustomers.review.author4' }),
      alt: intl.formatMessage({ id: 'OurCustomers.review.author4' }),
      type: intl.formatMessage({ id: 'OurCustomers.review.type.fixedOffice' }),
    },
    {
      img: author5,
      text: intl.formatMessage({ id: 'OurCustomers.review.description5' }),
      author: intl.formatMessage({ id: 'OurCustomers.review.author5' }),
      alt: intl.formatMessage({ id: 'OurCustomers.review.author5' }),
      type: intl.formatMessage({ id: 'OurCustomers.review.type.leasor' }),
    },
    {
      img: author6,
      text: intl.formatMessage({ id: 'OurCustomers.review.description6' }),
      author: intl.formatMessage({ id: 'OurCustomers.review.author6' }),
      alt: intl.formatMessage({ id: 'OurCustomers.review.author6' }),
      type: intl.formatMessage({ id: 'OurCustomers.review.type.leasor' }),
    },
  ];

  return (
    <div className={css.reviewsSection}>
      <h2 className={css.reviewHeader}>
        {intl.formatMessage({ id: 'OurCustomers.review.header' })}
      </h2>
      <ImageSlider category={CATEGORIES[2]} data={customerReviews} autoplay={{ delay: 5000 }} />
    </div>
  );
};

export default injectIntl(CustomerReviewsSlider);
