import React, { Component } from 'react';
import classNames from 'classnames';
import debounce from 'lodash/debounce';
import { any, arrayOf, bool, func, number, shape, string, oneOfType, object } from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';

import config from '../../config';
import { propTypes } from '../../util/types';

import IconCurrentLocation from './IconCurrentLocation';
import LocationIcon from './LocationIcon';
// import Geocoder, { GeocoderAttribution, CURRENT_LOCATION_ID } from './GeocoderMapbox';
import Geocoder, { GeocoderAttribution, CURRENT_LOCATION_ID } from './GeocoderGoogleMaps';

import { FieldRadioButton } from '..';
import css from './LocationAutocompleteInputNew.module.scss';

// A list of default predictions that can be shown when the user
// focuses on the autocomplete input without typing a search. This can
// be used to reduce typing and Geocoding API calls for common
// searches.
export const defaultPredictions = (
  config.maps.search.suggestCurrentLocation
    ? [{ id: CURRENT_LOCATION_ID, predictionPlace: {} }]
    : []
).concat(config.maps.search.defaults);

const DEBOUNCE_WAIT_TIME = 300;
const DEBOUNCE_WAIT_TIME_FOR_SHORT_QUERIES = 1000;
const KEY_CODE_ARROW_UP = 38;
const KEY_CODE_ARROW_DOWN = 40;
const KEY_CODE_ENTER = 13;
const KEY_CODE_TAB = 9;
const KEY_CODE_ESC = 27;
const DIRECTION_UP = 'up';
const DIRECTION_DOWN = 'down';
const TOUCH_TAP_RADIUS = 5; // Movement within 5px from touch start is considered a tap

// Touch devices need to be able to distinguish touches for scrolling and touches to tap
const getTouchCoordinates = (nativeEvent) => {
  const touch = nativeEvent && nativeEvent.changedTouches ? nativeEvent.changedTouches[0] : null;
  return touch ? { x: touch.screenX, y: touch.screenY } : null;
};

// Renders the autocompletion prediction results in a list
const LocationPredictionsList = (props) => {
  const {
    rootClassName,
    className,
    attributionClassName,
    predictions,
    geocoder,
    highlightedIndex,
    onSelectStart,
    onSelectMove,
    onSelectEnd,
  } = props;

  if (predictions.length === 0) {
    return null;
  }

  const showingDefaults = !!predictions[0].id?.match('default-');

  /* eslint-disable jsx-a11y/no-static-element-interactions */
  const item = (prediction, index) => (
    <li
      className={index === highlightedIndex ? css.highlighted : null}
      key={geocoder.getPredictionId(prediction)}
      onTouchStart={(e) => {
        e.preventDefault();
        onSelectStart(getTouchCoordinates(e.nativeEvent));
      }}
      onMouseDown={(e) => {
        e.preventDefault();
        onSelectStart();
      }}
      onTouchMove={(e) => {
        e.preventDefault();
        onSelectMove(getTouchCoordinates(e.nativeEvent));
      }}
      onTouchEnd={(e) => {
        e.preventDefault();
        onSelectEnd(prediction);
      }}
      onMouseUp={(e) => {
        e.preventDefault();
        onSelectEnd(prediction);
      }}
    >
      <div className={css.predictionWithRadio}>
        {showingDefaults && (
          <FieldRadioButton
            value={geocoder.getPredictionAddress(prediction)}
            name={geocoder.getPredictionAddress(prediction)}
            id={geocoder.getPredictionAddress(prediction)}
          />
        )}
        {geocoder.getPredictionAddress(prediction)}
      </div>
    </li>
  );
  /* eslint-enable jsx-a11y/no-static-element-interactions */

  const classes = classNames(rootClassName || css.predictionsRoot, className);

  return (
    <div className={classes}>
      <ul className={css.predictions}>{predictions.map(item)}</ul>
      <GeocoderAttribution className={attributionClassName} />
    </div>
  );
};

LocationPredictionsList.defaultProps = {
  rootClassName: null,
  className: null,
  attributionClassName: null,
  highlightedIndex: null,
};

LocationPredictionsList.propTypes = {
  rootClassName: string,
  className: string,
  attributionClassName: string,
  predictions: arrayOf(object).isRequired,
  geocoder: object.isRequired,
  highlightedIndex: number,
  onSelectStart: func.isRequired,
  onSelectMove: func.isRequired,
  onSelectEnd: func.isRequired,
};

// Get the current value with defaults from the given
// LocationAutocompleteInput props.
const currentValue = (props) => {
  const value = props.input.value || {};
  const { search = '', predictions = [], selectedPlace = null } = value;
  return { search, predictions, selectedPlace };
};

/*
  Location auto completion input component

  This component can work as the `component` prop to Final Form's
  <Field /> component. It takes a custom input value shape, and
  controls the onChange callback that is called with the input value.

  The component works by listening to the underlying input component
  and calling a Geocoder implementation for predictions. When the
  predictions arrive, those are passed to Final Form in the onChange
  callback.

  See the LocationAutocompleteInput.example.js file for a usage
  example within a form.
*/
class LocationAutocompleteInputImpl extends Component {
  constructor(props) {
    super(props);
    this._isMounted = false;

    this.state = {
      inputHasFocus: false,
      selectionInProgress: false,
      touchStartedFrom: null,
      highlightedIndex: -1, // -1 means no highlight
      fetchingPlaceDetails: false,
      fetchingPredictions: false,
    };

    // Ref to the input element.
    this.input = null;
    this.shortQueryTimeout = null;

    this.getGeocoder = this.getGeocoder.bind(this);
    this.currentPredictions = this.currentPredictions.bind(this);
    this.changeHighlight = this.changeHighlight.bind(this);
    this.selectPrediction = this.selectPrediction.bind(this);
    this.selectItemIfNoneSelected = this.selectItemIfNoneSelected.bind(this);
    this.onKeyDown = this.onKeyDown.bind(this);
    this.onChange = this.onChange.bind(this);
    this.handleOnBlur = this.handleOnBlur.bind(this);
    this.handlePredictionsSelectStart = this.handlePredictionsSelectStart.bind(this);
    this.handlePredictionsSelectMove = this.handlePredictionsSelectMove.bind(this);
    this.handlePredictionsSelectEnd = this.handlePredictionsSelectEnd.bind(this);
    this.finalizeSelection = this.finalizeSelection.bind(this);

    // Debounce the method to avoid calling the API too many times
    // when the user is typing fast.
    this.predict = debounce(this.predict.bind(this), DEBOUNCE_WAIT_TIME, { leading: true });
  }

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    window.clearTimeout(this.shortQueryTimeout);
    this._isMounted = false;
  }

  getGeocoder() {
    // Create the Geocoder as late as possible only when it is needed.
    if (!this._geocoder) {
      this._geocoder = new Geocoder();
    }
    return this._geocoder;
  }

  currentPredictions() {
    const { search, predictions: fetchedPredictions } = currentValue(this.props);
    const { useDefaultPredictions } = this.props;
    const hasFetchedPredictions = fetchedPredictions && fetchedPredictions.length > 0;
    const showDefaultPredictions = !search && !hasFetchedPredictions && useDefaultPredictions;

    return showDefaultPredictions ? defaultPredictions : fetchedPredictions;
  }

  // Interpret input key event
  onKeyDown(e) {
    if (e.keyCode === KEY_CODE_ARROW_UP) {
      // Prevent changing cursor position in input
      e.preventDefault();
      this.changeHighlight(DIRECTION_UP);
    } else if (e.keyCode === KEY_CODE_ARROW_DOWN) {
      // Prevent changing cursor position in input
      e.preventDefault();
      this.changeHighlight(DIRECTION_DOWN);
    } else if (e.keyCode === KEY_CODE_ENTER) {
      const { selectedPlace } = currentValue(this.props);

      if (!selectedPlace) {
        // Prevent form submit, try to select value instead.
        e.preventDefault();
        e.stopPropagation();
        this.selectItemIfNoneSelected();
        this.input.blur();
      }
    } else if (e.keyCode === KEY_CODE_TAB) {
      this.selectItemIfNoneSelected();
      this.input.blur();
    } else if (e.keyCode === KEY_CODE_ESC && this.input) {
      this.input.blur();
    }
  }

  // Handle input text change, fetch predictions if the value isn't empty
  onChange(e) {
    const onChange = this.props.input.onChange;
    const predictions = this.currentPredictions();
    const newValue = e.target.value;

    // Clear the current values since the input content is changed
    onChange({
      search: newValue,
      predictions: newValue ? predictions : [],
      selectedPlace: null,
    });

    // Clear highlighted prediction since the input value changed and
    // results will change as well
    this.setState({ highlightedIndex: -1 });

    if (!newValue) {
      // No need to fetch predictions on empty input
      return;
    }

    if (newValue.length >= 3) {
      if (this.shortQueryTimeout) {
        window.clearTimeout(this.shortQueryTimeout);
      }
      this.predict(newValue);
    } else {
      this.shortQueryTimeout = window.setTimeout(() => {
        this.predict(newValue);
      }, DEBOUNCE_WAIT_TIME_FOR_SHORT_QUERIES);
    }
  }

  // Change the currently highlighted item by calculating the new
  // index from the current state and the given direction number
  // (DIRECTION_UP or DIRECTION_DOWN)
  changeHighlight(direction) {
    this.setState((prevState, props) => {
      const predictions = this.currentPredictions();
      const currentIndex = prevState.highlightedIndex;
      let index = currentIndex;

      if (direction === DIRECTION_UP) {
        // Keep the first position if already highlighted
        index = currentIndex === 0 ? 0 : currentIndex - 1;
      } else if (direction === DIRECTION_DOWN) {
        index = currentIndex + 1;
      }

      // Check that the index is within the bounds
      if (index < 0) {
        index = -1;
      } else if (index >= predictions.length) {
        index = predictions.length - 1;
      }

      return { highlightedIndex: index };
    });
  }

  // Select the prediction in the given item. This will fetch/read the
  // place details and set it as the selected place.
  selectPrediction(prediction) {
    this.props.input.onChange({
      ...this.props.input,
      selectedPlace: null,
    });

    this.setState({ fetchingPlaceDetails: true });
    this.props.setFetchingPlaceDetails && this.props.setFetchingPlaceDetails(true);
    this.getGeocoder()
      .getPlaceDetails(prediction)
      .then((place) => {
        if (!this._isMounted) {
          // Ignore if component already unmounted
          return;
        }
        const locationValue = {
          search: place.address,
          predictions: [],
          selectedPlace: place,
        };

        this.setState({ fetchingPlaceDetails: false });
        this.props.setFetchingPlaceDetails && this.props.setFetchingPlaceDetails(false);
        this.props.input.onChange(locationValue);
        this.props.onSearchMapChange(locationValue);
      })

      .catch((e) => {
        this.setState({ fetchingPlaceDetails: false });
        this.props.setFetchingPlaceDetails && this.props.setFetchingPlaceDetails(false);
        // eslint-disable-next-line no-console
        console.error(e);
        this.props.input.onChange({
          ...this.props.input.value,
          selectedPlace: null,
        });
      });
  }
  selectItemIfNoneSelected() {
    if (this.state.fetchingPredictions) {
      // No need to select anything since prediction fetch is still going on
      return;
    }

    const { search, selectedPlace } = currentValue(this.props);
    const predictions = this.currentPredictions();
    if (!selectedPlace) {
      if (predictions && predictions.length > 0) {
        const index = this.state.highlightedIndex !== -1 ? this.state.highlightedIndex : 0;
        this.selectPrediction(predictions[index]);
      } else {
        this.predict(search);
      }
    }
  }
  predict(search) {
    const onChange = this.props.input.onChange;
    this.setState({ fetchingPredictions: true });

    return this.getGeocoder()
      .getPlacePredictions(search)
      .then((results) => {
        const { search: currentSearch } = currentValue(this.props);
        this.setState({ fetchingPredictions: false });

        // If the earlier predictions arrive when the user has already
        // changed the search term, ignore and wait until the latest
        // predictions arrive. Without this logic, results for earlier
        // requests would override whatever the user had typed since.
        //
        // This is essentially the same as switchLatest in RxJS or
        // takeLatest in Redux Saga, without canceling the earlier
        // requests.
        if (results.search === currentSearch) {
          onChange({
            search: results.search,
            predictions: results.predictions,
            selectedPlace: null,
          });
        }
      })
      .catch((e) => {
        this.setState({ fetchingPredictions: false });
        // eslint-disable-next-line no-console
        console.error(e);
        const value = currentValue(this.props);
        onChange({
          ...value,
          selectedPlace: null,
        });
      });
  }

  finalizeSelection() {
    this.setState({ inputHasFocus: false, highlightedIndex: -1 });
    this.props.input.onBlur(currentValue(this.props));
  }

  handleOnBlur() {
    if (this.props.closeOnBlur && !this.state.selectionInProgress) {
      this.finalizeSelection();
    }
  }

  handlePredictionsSelectStart(touchCoordinates) {
    this.setState({
      selectionInProgress: true,
      touchStartedFrom: touchCoordinates,
      isSwipe: false,
    });
  }

  handlePredictionsSelectMove(touchCoordinates) {
    this.setState((prevState) => {
      const touchStartedFrom = prevState.touchStartedFrom;
      const isTouchAction = !!touchStartedFrom;
      const isSwipe = isTouchAction
        ? Math.abs(touchStartedFrom.y - touchCoordinates.y) > TOUCH_TAP_RADIUS
        : false;

      return { selectionInProgress: false, isSwipe };
    });
  }

  handlePredictionsSelectEnd(prediction) {
    let selectAndFinalize = false;
    this.setState(
      (prevState) => {
        if (!prevState.isSwipe) {
          selectAndFinalize = true;
        }
        return { selectionInProgress: false, touchStartedFrom: null, isSwipe: false };
      },
      () => {
        if (selectAndFinalize) {
          this.selectPrediction(prediction);
          this.finalizeSelection();
        }
      }
    );
  }

  render() {
    const {
      autoFocus,
      rootClassName,
      className,
      focusedClassName,
      inputClassName,
      predictionsClassName,
      predictionsAttributionClassName,
      validClassName,
      placeholder,
      listingName,
      input,
      meta,
      intl,
      inputRef,
      currentLocationMapRef,
    } = this.props;

    const { name, onFocus } = input;
    const { search } = currentValue(this.props);
    const { touched, valid } = meta || {};
    const isValid = valid && touched;
    const [...predictions] = this.currentPredictions();

    const handleOnFocus = (e) => {
      this.setState({ inputHasFocus: true });
      onFocus(e);
    };

    // Only render predictions when the input has focus. For
    // development and easier workflow with the browser devtools, you
    // might want to hardcode this to `true`. Otherwise the dropdown
    // list will disappear.
    const renderPredictions = this.state.inputHasFocus;

    const rootClass = classNames(
      rootClassName || css.root,
      className,
      renderPredictions && focusedClassName
    );

    const inputClass = classNames(inputClassName || css.input, {
      [validClassName]: isValid,
    });
    const predictionsClass = classNames(predictionsClassName);

    const locationIconClasses = classNames(css.locationIcon, {
      [css.locationIconFocused]: renderPredictions,
    });

    const predictionMainClass = classNames(css.predictionsMain, {
      [css.hiddenClass]: !renderPredictions,
    });
    return (
      <div className={css.locationAutoCompleteWrapper}>
        <div className={rootClass}>
          <div className={css.locationIconWrapper}>
            <LocationIcon className={locationIconClasses} />
          </div>
          <input
            className={inputClass}
            type="text"
            autoComplete="off"
            autoFocus={autoFocus}
            placeholder={placeholder}
            name={name}
            value={listingName || search}
            disabled={this.state.fetchingPlaceDetails}
            onFocus={handleOnFocus}
            onChange={this.onChange}
            onBlur={() => null}
            onKeyDown={this.onKeyDown}
            readOnly={!!listingName}
            ref={(node) => {
              this.input = node;
              if (inputRef) {
                inputRef(node);
              }
            }}
          />
        </div>

        <div className={predictionMainClass}>
          <LocationPredictionsList
            rootClassName={predictionsClass}
            attributionClassName={predictionsAttributionClassName}
            predictions={predictions}
            geocoder={this.getGeocoder()}
            highlightedIndex={this.state.highlightedIndex}
            onSelectStart={this.handlePredictionsSelectStart}
            onSelectMove={this.handlePredictionsSelectMove}
            onSelectEnd={this.handlePredictionsSelectEnd}
          />

          <span
            ref={currentLocationMapRef}
            onTouchStart={(e) => {
              e.preventDefault();
              this.handlePredictionsSelectStart(getTouchCoordinates(e.nativeEvent));
            }}
            onMouseDown={(e) => {
              e.preventDefault();
              this.handlePredictionsSelectStart();
            }}
            onTouchMove={(e) => {
              e.preventDefault();
              this.handlePredictionsSelectMove(getTouchCoordinates(e.nativeEvent));
            }}
            onTouchEnd={(e) => {
              e.preventDefault();
              /* Replaced 'nearby' with this object, because in case of prediction selected and then current location pressed
               * it throws an error, because it becomes undefined. In that way we ensure that current listing button will always set the
               * actual current listing */
              this.handlePredictionsSelectEnd({ id: 'current-location', predictionPlace: {} });
            }}
            onMouseUp={(e) => {
              e.preventDefault();
              this.handlePredictionsSelectEnd({ id: 'current-location', predictionPlace: {} });
            }}
            className={css.currentLocationWrapper}
          >
            <IconCurrentLocation />
            <FormattedMessage id="LocationAutocompleteInputNew.currentLocation" />
          </span>
        </div>
      </div>
    );
  }
}

LocationAutocompleteInputImpl.defaultProps = {
  autoFocus: false,
  closeOnBlur: true,
  rootClassName: null,
  className: null,
  iconClassName: null,
  inputClassName: null,
  predictionsClassName: null,
  predictionsAttributionClassName: null,
  validClassName: null,
  placeholder: '',
  useDefaultPredictions: true,
  listingName: null,
  meta: null,
  inputRef: null,
};

LocationAutocompleteInputImpl.propTypes = {
  autoFocus: bool,
  rootClassName: string,
  className: string,
  closeOnBlur: bool,
  iconClassName: string,
  inputClassName: string,
  predictionsClassName: string,
  predictionsAttributionClassName: string,
  validClassName: string,
  placeholder: string,
  useDefaultPredictions: bool,
  listingName: string,
  input: shape({
    name: string.isRequired,
    value: oneOfType([
      shape({
        search: string,
        predictions: any,
        selectedPlace: propTypes.place,
      }),
      string,
    ]),
    onChange: func.isRequired,
    onFocus: func.isRequired,
    onBlur: func.isRequired,
  }).isRequired,
  meta: shape({
    valid: bool.isRequired,
    touched: bool.isRequired,
  }),
  inputRef: func,
};

export default injectIntl(LocationAutocompleteInputImpl);
