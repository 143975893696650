import React from 'react';
import classNames from 'classnames';
import { injectIntl } from 'react-intl';
import { bool, string } from 'prop-types';

import { intlShape } from '../../../util/reactIntl';

import css from './ListingPageSection.module.scss';

const ListingPageSection = ({ intl, title, children, lastSection }) => (
  <section className={classNames(css.section, lastSection && css.last)}>
    {title && <h5 className={css.title}>{intl.formatMessage({ id: title })}</h5>}
    {children}
  </section>
);

ListingPageSection.defaultProps = {
  lastSection: false,
};

ListingPageSection.propTypes = {
  intl: intlShape,
  title: string.isRequired,
  lastSection: bool,
};

export default injectIntl(ListingPageSection);
