// These helpers are calling FTW's own server-side routes
// so, they are not directly calling Marketplace API or Integration API.
// You can find these api endpoints from 'server/api/...' directory

import { types as sdkTypes, transit } from './sdkLoader';
import config from '../config';
import Decimal from 'decimal.js';

export const apiBaseUrl = () => {
  const port = process.env.REACT_APP_DEV_API_SERVER_PORT;
  const useDevApiServer = process.env.NODE_ENV === 'development' && !!port;

  // In development, the dev API server is running in a different port
  if (useDevApiServer) {
    return `http://localhost:${port}`;
  }

  // Otherwise, use the same domain and port as the frontend
  return `${window?.location.origin}`;
};

// Application type handlers for JS SDK.
//
// NOTE: keep in sync with `typeHandlers` in `server/api-util/sdk.js`
export const typeHandlers = [
  // Use Decimal type instead of SDK's BigDecimal.
  {
    type: sdkTypes.BigDecimal,
    customType: Decimal,
    writer: (v) => new sdkTypes.BigDecimal(v.toString()),
    reader: (v) => new Decimal(v.value),
  },
];

const serialize = (data) => {
  return transit.write(data, { typeHandlers, verbose: config.sdk.transitVerbose });
};

const deserialize = (str) => {
  return transit.read(str, { typeHandlers });
};

const get = (path, additionalOptions = {}) => {
  if (typeof window !== 'undefined') {
    const url = `${apiBaseUrl()}${path}`;
    const options = {
      method: 'GET',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/transit+json',
      },
      ...additionalOptions,
    };
    return window
      .fetch(url, options)
      .then((res) => {
        if (res.status >= 400) {
          return res.json().then((data) => {
            let e = new Error();
            e = Object.assign(e, data);

            throw e;
          });
        }
        return res;
      })
      .then((res) => {
        const contentTypeHeader = res.headers.get('Content-Type');
        const contentType = contentTypeHeader ? contentTypeHeader.split(';')[0] : null;
        if (contentType === 'application/transit+json') {
          return res.text().then(deserialize);
        } else if (contentType === 'application/json') {
          return res.json();
        }
        return res.text();
      });
  }
};

const post = (path, body) => {
  if (typeof window !== 'undefined') {
    const url = `${apiBaseUrl()}${path}`;
    const options = {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/transit+json',
      },
      body: serialize(body),
    };
    return window
      .fetch(url, options)
      .then((res) => {
        if (res.status >= 400) {
          const e = new Error('Local API request failed');
          e.apiResponse = res;
          throw e;
        }
        return res;
      })
      .then((res) => {
        const contentTypeHeader = res.headers.get('Content-Type');
        const contentType = contentTypeHeader ? contentTypeHeader.split(';')[0] : null;
        if (contentType === 'application/transit+json') {
          return res.text().then(deserialize);
        } else if (contentType === 'application/json') {
          return res.json();
        }
        return res.text();
      });
  }
};

const put = (path, body) => {
  if (typeof window !== 'undefined') {
    const url = `${apiBaseUrl()}${path}`;
    const options = {
      method: 'PUT',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/transit+json',
      },
      body: serialize(body),
    };
    return window
      .fetch(url, options)
      .then((res) => {
        if (res.status >= 400) {
          const e = new Error('Local API request failed');
          e.apiResponse = res;
          throw e;
        }
        return res;
      })
      .then((res) => {
        const contentTypeHeader = res.headers.get('Content-Type');
        const contentType = contentTypeHeader ? contentTypeHeader.split(';')[0] : null;
        if (contentType === 'application/transit+json') {
          return res.text().then(deserialize);
        } else if (contentType === 'application/json') {
          return res.json();
        }
        return res.text();
      });
  }
};

const deleteMethod = (path, additionalOptions = {}) => {
  if (typeof window !== 'undefined') {
    const url = `${apiBaseUrl()}${path}`;
    const options = {
      method: 'DELETE',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/transit+json',
      },
      ...additionalOptions,
    };
    return window
      .fetch(url, options)
      .then((res) => {
        if (res.status >= 400) {
          return res.json().then((data) => {
            let e = new Error();
            e = Object.assign(e, data);

            throw e;
          });
        }
        return res;
      })
      .then((res) => {
        const contentTypeHeader = res.headers.get('Content-Type');
        const contentType = contentTypeHeader ? contentTypeHeader.split(';')[0] : null;
        if (contentType === 'application/transit+json') {
          return res.text().then(deserialize);
        } else if (contentType === 'application/json') {
          return res.json();
        }
        return res.text();
      });
  }
};

// Fetch transaction line items from the local API endpoint.
//
// See `server/api/transaction-line-items.js` to see what data should
// be sent in the body.
export const transactionLineItems = (body) => {
  return post('/api/transaction-line-items', body);
};

// Initiate a privileged transaction.
//
// With privileged transitions, the transactions need to be created
// from the backend. This endpoint enables sending the booking data to
// the local backend, and passing that to the Marketplace API.
//
// See `server/api/initiate-privileged.js` to see what data should be
// sent in the body.
export const initiatePrivileged = (body) => {
  return post('/api/initiate-privileged', body);
};

// Transition a transaction with a privileged transition.
//
// This is similar to the `initiatePrivileged` above. It will use the
// backend for the transition. The backend endpoint will add the
// payment line items to the transition params.
//
// See `server/api/transition-privileged.js` to see what data should
// be sent in the body.
export const transitionPrivileged = (body) => {
  return post('/api/transition-privileged', body);
};

// Create user with identity provider (e.g. Facebook or Google)
//
// If loginWithIdp api call fails and user can't authenticate to Flex with idp
// we will show option to create a new user with idp.
// For that user needs to confirm data fetched from the idp.
// After the confirmation, this endpoint is called to create a new user with confirmed data.
//
// See `server/api/auth/createUserWithIdp.js` to see what data should
// be sent in the body.
export const createUserWithIdp = (body) => {
  return post('/api/auth/create-user-with-idp', body);
};

export const retrieveSubscription = (id) => {
  return fetch(
    'api/subscription?' +
      new URLSearchParams({
        id,
      })
  );
};

export const updateUserEmail = (body) => {
  return post('/api/update-user-email', body);
};

export const editSubscription = (id) => {
  return post('/api/edit-subscription', { id });
};

export const createPortalSession = (userId) => {
  return post('/api/portal-session', { id: userId });
};

export const retrieveCoupon = (couponCode) => {
  return post('/api/retrieve-chargebee-coupon', { couponCode });
};

/**
 * Fire Facebook Conversion API Event for marketing stats
 *
 * @param eventName {String}
 * @param payload {Object}
 * */
export const fireFacebookEvent = ({ eventName, payload = {} }) => {
  return post('/api/facebook-pixel', { eventName, payload });
};

export const addTeamMemberList = (body) => {
  return post('/api/add-team-member', body);
};

export const checkInvitation = (body) => {
  return post('/api/check-invitation', body);
};

/**
 * Chargebee api
 *
 * @param eventName {String}
 * @param payload {Object}
 * */
export const finalizeCompanyAccount = ({ userId, coupon_ids, selectedPackage }) => {
  return post('/api/finalize-company-account', { userId, coupon_ids, selectedPackage });
};

export const getChargebeeUser = () => {
  return get(`/api/chargebee-user`);
};

export const createPaymentSource = ({ paymentSource }) => {
  return post(`/api/chargebee-payment-source`, { paymentSource });
};

export const updatePaymentSource = ({ paymentMethodId, params }) => {
  return put(`/api/chargebee-payment-source/${paymentMethodId}`, { ...params });
};

export const deleteChargebeeUser = ({ paymentMethodId }) => {
  return deleteMethod(`/api/chargebee-user/${paymentMethodId}`);
};

export const retrievePaymentSource = ({ paymentMethodId }) => {
  return get(`/api/chargebee-payment-source/${paymentMethodId}`);
};

export const listPaymentSource = () => {
  return get(`/api/list-chargebee-payment-source`);
};

export const retrieveMySubscription = () => {
  return get(`/api/my-subscription`);
};

export const retrieveMyCompanyCreditSubscription = () => {
  return get('/api/retrieve-my-company-credit-subscription');
};

/**
 * Update subscription
 *
 * @param {Number} totalCredits total credits to update
 *
 * @param {Number} newMonthPrice total credits to update
 *
 * @returns {Object} true if the browser recognizes the key.
 */
export const updateMyCompanyCreditSubscription = ({
  totalCredits,
  newMonthPrice,
  startOption,
  selectedPackage,
}) => {
  return post('/api/update-my-company-credit-subscription', {
    totalCredits,
    newMonthPrice,
    startOption,
    selectedPackage,
  });
};

export const cancelMyCompanyCreditSubscription = ({
  cancelReasonCode,
  cancelReasonCodeOtherMessage,
  earliestAllowableCancelDate,
}) => {
  return post('/api/cancel-my-company-credit-subscription', {
    cancelReasonCode,
    cancelReasonCodeOtherMessage,
    earliestAllowableCancelDate,
  });
};

export const reactivateMyCompanyCreditSubscription = ({ reactivateDate }) => {
  return post('/api/reactivate-my-company-credit-subscription', { reactivateDate });
};

export const pauseMyCompanyCreditSubscription = ({ resumeDate }) => {
  return post('/api/pause-my-company-credit-subscription', { resumeDate });
};

export const resumeMyCompanyCreditSubscription = () => {
  return post('/api/resume-my-company-credit-subscription');
};

/**
 * Credit api
 *
 * @param eventName {String}
 * @param payload {Object}
 * */
export const cancelCreditPayout = (body) => {
  return post('/api/cancel-credit-payout', body);
};

export const addCompanyAccountToMemberList = ({ userId }) => {
  return post('/api/add-company-account-to-member-list', { userId });
};

export const getCompanyAccount = () => {
  return get('/api/get-company-account');
};

export const updateEmployeeCreditInfo = ({ userId }) => {
  return post('/api/update-employee-credit-info', { userId });
};

export const acceptInvitation = ({ email, companyId }) => {
  return post('/api/accept-invitation', { email, companyId });
};

export const getUsageLog = (body) => {
  return post('/api/get-usage-log', body);
};

export const getChargebeeTx = ({ offset, limit }) => {
  return get(`/api/get-chargebee-transaction?${offset ? 'offset=' + offset : ''}&limit=${limit}`);
};

export const getChargebeeInvoices = ({ offset, limit }) => {
  return get(`/api/get-chargebee-invoices?${offset ? 'offset=' + offset : ''}&limit=${limit}`);
};

export const getInvoicePDF = (invoiceId) => {
  return get(`/api/get-invoice/pdf/${invoiceId}`);
};

export const buyOneTimeCredits = ({
  totalPrice,
  oneTimeCreditNumber,
  paymentSource,
  paymentOption,
  userId,
  saveCard,
}) => {
  return post('/api/buy-one-time-credits', {
    totalPrice,
    oneTimeCreditNumber,
    paymentSource,
    paymentOption,
    userId,
    saveCard,
  });
};

export const changePaymentMethod = ({ paymentSource, billingAddress, primaryPaymentSource }) => {
  return post('/api/change-payment-method', {
    paymentSource,
    billingAddress,
    primaryPaymentSource,
  });
};

export const deleteTeamMember = ({ memberId, memberEmail }) => {
  return post('/api/delete-team-member', { memberId, memberEmail });
};

export const editTeamMemberAcces = ({ memberId, memberEmail, creditsValue }) => {
  return post('/api/edit-team-member-access', { memberId, memberEmail, creditsValue });
};
// Hubspot b2b pipeline
export const updateHubspotPipeline = (body) => {
  return post('/api/hubspot-b2b-pipeline', body);
};
// Hubspot subscription
export const hubspotSubscribeUser = (body) => {
  return post('/api/hubspot-subscribe-user', body);
};
export const hubspotCreateUpdateUser = (body) => {
  return post('/api/hubspot-create-update-user', body);
};

export const accessyCreateAccess = (body) => {
  return post('/api/accessy-create-access', body);
};

export const accessyDeleteAccess = (body) => {
  return post('/api/accessy-delete-access', body);
};

export const parakeyCreateAccess = (body) => {
  return post('/api/parakey-create-access', body);
};
export const parakeyDeleteAccess = (body) => {
  return post(`/api/parakey-delete-access`, body);
};

export const voucherify = (body) => {
  return post('/api/voucherify', body);
};

export const sendLeadsToVitec = (body) => {
  return post('/api/send-leads-to-vitec', body);
};

export const updateCompanyBookingsMetadata = (body) => {
  return post('/api/update-company-bookings-metadata', body);
};

export const toggleCompanyFavorites = (body) => {
  return post('/api/toggle-company-favorites', body);
};

export const requestTeamScheduleHazura = (body) => post('/api/team-schedule-hazura', body);
export const saveBookingToHazura = (payload) => post('/api/save-booking-to-hazura', payload);
export const addFavoriteLocationHasura = (payload) =>
  post('/api/add-favorite-location-hasura', payload);
export const removeFavoriteLocationHasura = (payload) =>
  post('/api/remove-favorite-location-hasura', payload);
export const getFavoriteLocationsHasura = (payload) =>
  post('/api/get-favorite-locations-hasura', payload);
export const updateBookingHazura = (payload) => post('/api/update-booking-hazura', payload);
export const updateUserHazura = (payload) => post('/api/update-user-hazura', payload);
export const requestMoreWeeks = (payload) => post('/api/request-more-weeks', payload);
export const addConnectionHazura = (payload) => post('/api/add-connection-hazura', payload);
export const updateConnectionHazura = (payload) => post('/api/update-connection-hazura', payload);
export const getRequestsHazura = (payload) => post('/api/get-requests-hazura', payload);
export const getConnectionsHazura = (payload) => post('/api/get-connections-hazura', payload);
export const getConnectionsWithEmailHazura = (payload) =>
  post('/api/get-connections-email-hazura', payload);
export const getUsersHazura = (payload) => post('/api/get-users-hazura', payload);

/**
 * @param ownerId {String}
 * @param listingId {String}
 * @param locationStatuses {Array}
 * @param listingStatuses {Array}
 * */
export const getLocationHazura = ({ ownerId, locationId, locationStatuses, listingStatuses }) =>
  post('/api/get-single-location-hazura', {
    ownerId,
    locationId,
    locationStatuses,
    listingStatuses,
  });

export const getLocationsHazura = (payload) => post('/api/get-locations-hazura', payload);
export const createLocationHazura = (payload) => post('/api/create-location-hazura', payload);
export const updateLocationHazura = (payload) => post('/api/update-location-hazura', payload);
export const deleteLocationImage = (url) => post('/api/delete-location-image', url);

/**
 * @param locationId {String}
 * @param listingId {String}
 * */
export const assignListingHazura = ({ locationId, listingId, locationData }) =>
  post('/api/assign-listing-hazura', { locationId, listingId, locationData });

/**
 * @param locationId {String}
 * @param listingId {String}
 * */
export const unassignListingHazura = ({ locationId, listingId }) =>
  post('/api/unassign-listing-hazura', { locationId, listingId });

export const sendToZapier = (body) => {
  return post('/api/send-to-zapier', body);
};

export const sendEmail = (body) => {
  return post('/api/transactionalEmail', body);
};
