import React, { useState } from 'react';
import { func } from 'prop-types';
import { Form as FinalForm, FormSpy } from 'react-final-form';
import { injectIntl, intlShape, FormattedMessage } from '../../util/reactIntl';
import * as validators from '../../util/validators';

import {
  Form,
  FieldPhoneNumberInput,
  FieldTextInput,
  FieldSelect,
  AccentButton,
  NamedLink,
} from '../../components';
import classNames from 'classnames';
import css from './CompanyGetInTouchForm.module.scss';

const CompanyGetInTouchFormComponent = (props) => {
  const { CompanyformSubmit } = props;
  const [disableButton, setButtonDisable] = useState(true);
  const [hideForm, setFormHidden] = useState(false);
  const setFormHiddenFalse = () => {
    setFormHidden(false);
  };

  const formClass = classNames(css.section, { [css.collapsed]: hideForm });
  const showFormButtonClass = classNames(css.showFormButton, { [css.collapsed]: !hideForm });

  return (
    <FinalForm
      {...props}
      onSubmit={(values) => {
        CompanyformSubmit(values);
      }}
      render={(formRenderProps) => {
        const { intl, handleSubmit } = formRenderProps;

        const firstNamePlaceholder = intl.formatMessage({
          id: 'CompanyGetInTouchForm.firstNamePlaceholder',
        });
        const firstNameRequiredMessage = intl.formatMessage({
          id: 'CompanyGetInTouchForm.firstNameRequired',
        });
        const firstNameRequired = validators.required(firstNameRequiredMessage);

        const lastNamePlaceholder = intl.formatMessage({
          id: 'CompanyGetInTouchForm.lastNamePlaceholder',
        });
        const lastNameRequiredMessage = intl.formatMessage({
          id: 'CompanyGetInTouchForm.lastNameRequired',
        });
        const lastNameRequired = validators.required(lastNameRequiredMessage);

        const emailPlaceholder = intl.formatMessage({
          id: 'CompanyGetInTouchForm.emailPlaceholder',
        });
        const emailRequiredMessage = intl.formatMessage({
          id: 'CompanyGetInTouchForm.emailRequired',
        });
        const emailRequired = validators.required(emailRequiredMessage);
        const emailInvalidMessage = intl.formatMessage({
          id: 'CompanyGetInTouchForm.emailInvalid',
        });
        const emailValid = validators.emailFormatValid(emailInvalidMessage);

        const phoneNumberPlaceholder = intl.formatMessage({
          id: 'CompanyGetInTouchForm.phoneNumberPlaceholder',
        });

        const companyNamePlaceholder = intl.formatMessage({
          id: 'CompanyGetInTouchForm.companyNamePlaceholder',
        });
        const companyNameRequiredMessage = intl.formatMessage({
          id: 'CompanyGetInTouchForm.companyNameRequired',
        });
        const companyNameRequired = validators.required(companyNameRequiredMessage);

        const numberOfEmployeesPlaceholder = intl.formatMessage({
          id: 'CompanyGetInTouchForm.numberOfEmployeesPlaceholder',
        });

        const additionalInformationPlaceholder = intl.formatMessage({
          id: 'CompanyGetInTouchForm.additionalInformationPlaceholder',
        });

        const numberOfEmployeesOptions = ['1-5', '5-20', '20-50', '50-100', '100-500', '500+'];

        return (
          <section>
            <Form id={5} onSubmit={handleSubmit} tabIndex="0" className={formClass}>
              <FormSpy
                subscription={{ values: true, valid: true }}
                onChange={({ values, valid }) => {
                  if (valid && values.email) {
                    setButtonDisable(false);
                  }
                }}
              />

              <div className={css.contentWrapper}>
                <FieldTextInput
                  className={css.firstNameRoot}
                  type="text"
                  name="fname"
                  placeholder={firstNamePlaceholder}
                  validate={firstNameRequired}
                  inputRootClass={css.inputRootClass}
                />
                <FieldTextInput
                  className={css.lastNameRoot}
                  type="text"
                  name="lname"
                  placeholder={lastNamePlaceholder}
                  validate={lastNameRequired}
                  inputRootClass={css.inputRootClass}
                />
                <FieldTextInput
                  className={css.emailAddress}
                  type="email"
                  name="email"
                  placeholder={emailPlaceholder}
                  inputRootClass={css.inputRootClass}
                  validate={validators.composeValidators(emailRequired, emailValid)}
                  labelClassName={css.label}
                />
                <FieldPhoneNumberInput
                  className={css.phoneNumber}
                  name="phonenumber"
                  placeholder={phoneNumberPlaceholder}
                  inputRootClass={css.inputRootClass}
                  labelClassName={css.label}
                />
                {/* TODO this needs some validation of number provided */}
                <FieldTextInput
                  className={css.companyName}
                  type="text"
                  name="company"
                  placeholder={companyNamePlaceholder}
                  validate={companyNameRequired}
                  inputRootClass={css.inputRootClass}
                  labelClassName={css.label}
                />
                <FieldSelect id="employees" name="employees" className={css.employees}>
                  <option value="employees">{numberOfEmployeesPlaceholder}</option>
                  {numberOfEmployeesOptions.map((opt) => (
                    <option key={opt} value={opt}>
                      {opt}
                    </option>
                  ))}
                </FieldSelect>

                <FieldTextInput
                  className={css.additionalInfo}
                  type="textarea"
                  name="additionalInfo"
                  placeholder={additionalInformationPlaceholder}
                  inputRootClass={css.inputRootClass}
                  labelClassName={css.label}
                />
              </div>
              <div className={css.submitButtonWrapper}>
                <AccentButton type="submit" disabled={disableButton}>
                  <FormattedMessage id="CompanyGetInTouchForm.submitButton" />
                </AccentButton>
                <p>
                  <FormattedMessage id="CompanyGetInTouchForm.infoSection" />
                  <NamedLink name="TermsOfServicePage">
                    <FormattedMessage id="CompanyGetInTouchForm.infoSectionLink" />
                  </NamedLink>
                </p>
              </div>
            </Form>
            <div className={showFormButtonClass}>
              <AccentButton onClick={setFormHiddenFalse}>
                <FormattedMessage id="CompanyGetInTouchForm.submitButton" />
              </AccentButton>
            </div>
          </section>
        );
      }}
    />
  );
};

CompanyGetInTouchFormComponent.defaultProps = {};

CompanyGetInTouchFormComponent.propTypes = {
  intl: intlShape.isRequired,
  CompanyformSubmit: func.isRequired,
};

const CompanyGetInTouchForm = injectIntl(CompanyGetInTouchFormComponent);

export default CompanyGetInTouchForm;
