import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';
import { useIntl } from 'react-intl';

import { LISTING_STATE_DRAFT } from '../../util/types';
import { ensureListing } from '../../util/data';
import { EditListingAmenitiesForm } from '../../forms';
import { ListingLink, SaveAndExitButton } from '../../components';

import css from './EditListingAmenitiesPanel.module.scss';
import { modernMTTranslate } from '../../util/modernmt';
import { useViewport } from '../../util/hooks';
import { MAX_MOBILE_SCREEN_WIDTH } from '../../util/device';
import { v4 as uuidv4 } from 'uuid';
import { types as sdkTypes } from '../../util/sdkLoader';
const { Money } = sdkTypes;

const FEATURES_NAME = 'ameneties';

const EditListingAmenitiesPanel = (props) => {
  const {
    rootClassName,
    className,
    listing,
    disabled,
    ready,
    onSubmit,
    onChange,
    submitButtonText,
    panelUpdated,
    updateInProgress,
    errors,
    extraInfoPlaceholderLabel,
    onSaveAndExitListingWizardTab,
  } = props;

  const intl = useIntl();
  const { width } = useViewport();
  const isMobile = width < MAX_MOBILE_SCREEN_WIDTH;

  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureListing(listing);
  const { publicData } = currentListing.attributes;

  const isPublished = currentListing.id && currentListing.attributes.state !== LISTING_STATE_DRAFT;
  const panelTitle = isPublished ? (
    <FormattedMessage
      id="EditListingAmenitiesPanel.title"
      values={{
        listingTitle: (
          <ListingLink listing={listing}>{listing.attributes.publicData.title}</ListingLink>
        ),
      }}
    />
  ) : (
    <FormattedMessage id="EditListingAmenitiesPanel.createListingTitle" />
  );

  const ameneties = publicData && publicData.ameneties;
  const amenetiesInfo = publicData && publicData.amenetiesInfo;
  const offer = publicData && publicData.offer;
  const category = publicData && publicData.category;
  const environment = publicData && publicData.environment;
  const noiselevel = publicData && publicData.noiselevel;
  const vibe = publicData && publicData.vibe;
  const perfectFor = publicData && publicData.perfectFor;
  const additionalServices = publicData && publicData.additionalServices;

  const addonsInfo = useMemo(() => {
    return !additionalServices
      ? {}
      : [...additionalServices].reduce((addons, addon) => {
          return {
            ...addons,
            [addon.name]: {
              ...addon,
              price: addon.price.amount / 100,
              vatPercentage: addon.vatPercentage,
            },
          };
        }, {});
  }, [additionalServices]);

  const detailInfo = useMemo(() => {
    return !amenetiesInfo
      ? {}
      : Object.keys(amenetiesInfo).reduce((allDetails, amenity) => {
          return {
            ...allDetails,
            [amenity]: amenetiesInfo[amenity].description,
          };
        }, {});
  }, [amenetiesInfo]);

  const amenityTime = useMemo(() => {
    return !amenetiesInfo
      ? {}
      : Object.keys(amenetiesInfo).reduce((allAmenityTimes, amenity) => {
          return {
            ...allAmenityTimes,
            [amenity]: {
              startTime: amenetiesInfo[amenity].startTime,
              endTime: amenetiesInfo[amenity].endTime,
            },
          };
        }, {});
  }, [amenetiesInfo]);

  const initialValues = useMemo(
    () => ({
      additionalServices,
      ameneties,
      amenetiesInfo,
      offer,
      perfectFor,
      environment,
      noiselevel,
      vibe,
      addonsInfo,
      detailInfo,
      amenityTime,
    }),
    [
      additionalServices,
      addonsInfo,
      ameneties,
      amenetiesInfo,
      environment,
      noiselevel,
      offer,
      perfectFor,
      vibe,
      detailInfo,
      amenityTime,
    ]
  );

  const handleSubmit = (values) => {
    const { originLang } = publicData;
    const {
      ameneties = [],
      amenetiesInfo = {},
      offer = [],
      environment = [],
      noiselevel = '',
      vibe = [],
      perfectFor = [],
      additionalServices = [],
      addons = [],
    } = values;

    const ensuredAdditionalServices = additionalServices.map((s) => ({
      ...s,
      price:
        s.price instanceof Money ? { amount: s.price.amount, currency: s.price.currency } : s.price,
      serviceId: s.id ? s.id : uuidv4(),
    }));
    const updatedValues = {
      publicData: {
        ameneties,
        amenetiesInfo,
        offer,
        environment,
        noiselevel,
        vibe,
        perfectFor,
        additionalServices: ensuredAdditionalServices,
        [originLang]: {
          ...listing.attributes.publicData[originLang],
          ameneties: { ...amenetiesInfo },
        },
      },
    };
    onSubmit(updatedValues);
  };

  return (
    <div className={classes}>
      <div className={css.panelContainer}>
        <div className={css.title}>{panelTitle}</div>
        <p className={css.subTitle}>
          {isMobile ? (
            <FormattedMessage id="EditListingAmenitiesPanel.subTitle" />
          ) : (
            <FormattedMessage
              id="EditListingAmenitiesPanel.subTitleDesktop"
              values={{
                span: (msg) => <span className={css.boldText}>{msg}</span>,
              }}
            />
          )}
        </p>

        <EditListingAmenitiesForm
          className={css.form}
          name={FEATURES_NAME}
          extraInfoPlaceholderLabel={extraInfoPlaceholderLabel}
          initialValues={initialValues}
          onSubmit={handleSubmit}
          onChange={onChange}
          saveActionMsg={submitButtonText}
          disabled={disabled}
          ready={ready}
          updated={panelUpdated}
          updateInProgress={updateInProgress}
          fetchErrors={errors}
          category={category}
          intl={intl}
          onHandleSaveAndExit={onSaveAndExitListingWizardTab}
          isPublished={isPublished}
        />
      </div>
    </div>
  );
};

EditListingAmenitiesPanel.defaultProps = {
  rootClassName: null,
  className: null,
  listing: null,
};

const { bool, func, object, string } = PropTypes;

EditListingAmenitiesPanel.propTypes = {
  rootClassName: string,
  className: string,

  // We cannot use propTypes.listing since the listing might be a draft.
  listing: object,

  disabled: bool.isRequired,
  ready: bool.isRequired,
  onSubmit: func.isRequired,
  onChange: func.isRequired,
  submitButtonText: string.isRequired,
  panelUpdated: bool.isRequired,
  updateInProgress: bool.isRequired,
  errors: object.isRequired,
};

export default EditListingAmenitiesPanel;
