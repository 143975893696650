import classNames from 'classnames';
import React, { useEffect, useRef, useState } from 'react';
import { Form as FinalForm } from 'react-final-form';
import { FormattedMessage } from 'react-intl';
import {
  Button,
  FieldTextInput,
  Form,
  IconOrganizations as Icons,
  PrimaryButton,
} from '../../components';
import config from '../../config';
import { OneTimePaymentWithCardElement } from '../../forms/StripePaymentForm/StripePaymentForm';
import routeConfiguration from '../../routeConfiguration';
import { isSignupEmailTakenError } from '../../util/errors';
import { createResourceLocatorString } from '../../util/routes';
import { SUBSCRIPTION_OPTIONS } from '../../util/types';
import * as validators from '../../util/validators';
import css from './OrganizationDetailForm.module.scss';

const INVOICE_METHOD = 'invoiceMethod';
const CREDIT_CARD_METHOD = 'creditCardMethod';

const cardStyles = {
  base: {
    fontFamily: '"MarkPro", Helvetica, Arial, sans-serif',
    fontSize: '18px',
    fontSmoothing: 'antialiased',
    lineHeight: '24px',
    letterSpacing: '-0.1px',
    color: '#4A4A4A',
    border: '1px solid #999999',
    borderRadius: '10px',
    height: '60px',
    '::placeholder': {
      color: '#B2B2B2',
      fontSize: '16px',
    },
  },
};

const stripeElementsOptions = {
  fonts: [
    {
      family: 'sofiapro',
      fontSmoothing: 'antialiased',
      src: 'local("sofiapro"), local("SofiaPro"), local("Sofia Pro"), url("https://assets-sharetribecom.sharetribe.com/webfonts/sofiapro/sofiapro-medium-webfont.woff2") format("woff2")',
    },
  ],
};

const OrganizationDetailForm = (props) => {
  const { onSubmit, setCurrentTab, additionalCompanyDetails, selectedPackage } = props;
  const [paymentMethod, setPaymentMethod] = useState(null);
  const [error, setError] = useState(null);
  const [cardContainer, setCardContainer] = useState(null);
  const [cardValueValid, setCardValueValid] = useState(false);
  const [stripe, setStripe] = useState(null);
  const card = useRef(null);
  const [localLoading, setLocalLoading] = useState(false);

  const isSubscription = SUBSCRIPTION_OPTIONS.includes(selectedPackage);

  const {
    phone: savedPhone,
    businessId: savedBusinessId,
    organization: savedOrganization,
  } = additionalCompanyDetails || {};

  useEffect(() => {
    if (!window.Stripe) {
      throw new Error('Stripe must be loaded for StripePaymentForm');
    }

    if (config.stripe.publishableKey) {
      setStripe(window.Stripe(config.stripe.publishableKey));
    }
  }, []);

  const initializeStripeElement = (element) => {
    const elements = stripe.elements(stripeElementsOptions);
    if (!card.current) {
      card.current = elements.create('card', { style: cardStyles });
      card.current.mount(element || cardContainer);
      window.addEventListener('resize', () => {
        if (card) {
          if (window.innerWidth < 1024) {
            card.current.update({ style: { base: { fontSize: '18px', lineHeight: '24px' } } });
          } else {
            card.current.update({ style: { base: { fontSize: '20px', lineHeight: '32px' } } });
          }
        }
      });
    }
  };

  const handleStripeElementRef = (el) => {
    setCardContainer(el);
    if (stripe && el) {
      initializeStripeElement(el);
    }
  };

  const handleChangePaymentMethod = (method) => {
    setPaymentMethod(method);
  };

  const handleInvoiceMethod = (form) => {
    card.current = null;
    form.change('cardHolderName', '');
    form.change(INVOICE_METHOD, INVOICE_METHOD);
    handleChangePaymentMethod(INVOICE_METHOD);
  };

  const handleCreditCardMethod = (form) => {
    form.change(INVOICE_METHOD, CREDIT_CARD_METHOD);
    handleChangePaymentMethod(CREDIT_CARD_METHOD);
  };

  const backToPreviosTab = () => {
    setCurrentTab(0);
  };

  const handleFormSubmit = async (values) => {
    setLocalLoading(true);
    const {
      name = '',
      email = '',
      password = '',
      phone,
      businessId,
      organization,
      invoiceEmail,
      ...rest
    } = values;
    const formatedName = name.trim();
    const nameList = formatedName.split(' ');
    const firstName = nameList[0];
    const lastName = nameList[nameList.length - 1];
    let cardTokenResult;
    let cardToken;
    if (paymentMethod === CREDIT_CARD_METHOD) {
      try {
        cardTokenResult = await stripe.createToken(card.current);
        if (cardTokenResult.token) {
          cardToken = cardTokenResult.token;
        } else if (cardTokenResult.error) {
          console.log('err', cardTokenResult.error);
          return;
        }
        setLocalLoading(false);
      } catch (error) {
        console.log('error', error);
        setLocalLoading(false);
        return;
      }
    }

    const signupParams = {
      firstName,
      lastName,
      email,
      password,
      phone,
      businessId,
      organization,
      invoiceEmail,
      cardToken: paymentMethod === CREDIT_CARD_METHOD ? cardToken.id : null,
    };
    if (card.current) {
      onSubmit({ signupParams, rest }); // todo - investigate why this is repeated here? whats the history?
    } else {
      onSubmit({ signupParams, rest });
    }
  };

  return (
    <FinalForm
      {...props}
      onSubmit={handleFormSubmit}
      render={(fieldRenderProps) => {
        const {
          intl,
          handleSubmit,
          invalid,
          inProgress: submitInProgress,
          form,
          values,
          isCurrentTab,
          isPassed,
          isMobileLayout,
          signupInProgress,
          isAuthenticated,
          signupError,
        } = fieldRenderProps;

        const { invoiceMethod = [], cardHolderName } = values;
        const submitButtonDisabled = invalid || (!(invoiceMethod.length > 0) && !cardHolderName);
        const buttonInProgress = (signupInProgress || localLoading) && !signupError;

        const tabIconClass = classNames(css.tabIcon, {
          [css.current]: isCurrentTab,
          [css.passed]: isPassed,
        });

        // Name field
        const nameRequiredMessage = intl.formatMessage({
          id: 'OrganizationDetailTab.nameRequired',
        });
        const nameRequired = validators.required(nameRequiredMessage);
        const nameFieldPlaceholder = intl.formatMessage({
          id: 'OrganizationDetailTab.nameFieldPlaceholder',
        });

        // Email field
        const emailRequiredMessage = intl.formatMessage({
          id: 'OrganizationDetailTab.emailRequired',
        });
        const emailRequired = validators.required(emailRequiredMessage);
        const emailInvalidMessage = intl.formatMessage({
          id: 'OrganizationDetailTab.emailInvalid',
        });
        const emailValid = validators.emailFormatValid(emailInvalidMessage);
        const emailFieldPlaceholder = intl.formatMessage({
          id: 'OrganizationDetailTab.emailFieldPlaceholder',
        });

        // Phone field
        const phoneRequiredMessage = intl.formatMessage({
          id: 'OrganizationDetailTab.phoneNumberRequired',
        });
        const phoneRequired = validators.required(phoneRequiredMessage);
        const phoneFieldPlaceholder = intl.formatMessage({
          id: 'OrganizationDetailTab.phoneFieldPlaceholder',
        });

        // Organization name field
        const organizationNameRequiredMessage = intl.formatMessage({
          id: 'OrganizationDetailTab.organizationNameRequired',
        });
        const organizationNameRequired = validators.required(organizationNameRequiredMessage);
        const organizationFieldPlaceholder = intl.formatMessage({
          id: 'OrganizationDetailTab.organizationFieldPlaceholder',
        });

        // Business Id field
        const businessIdRequiredMessage = intl.formatMessage({
          id: 'OrganizationDetailTab.businessIdRequired',
        });
        const businessIdRequired = validators.required(businessIdRequiredMessage);
        const businessIdFieldPlaceholder = intl.formatMessage({
          id: 'OrganizationDetailTab.businessIdFieldPlaceholder',
        });
        const businessIdInvalidFormatMessage = intl.formatMessage({
          id: 'OrganizationDetailTab.businessIdInvalidFormat',
        });
        const businessIdInvalidFormat = validators.businessIdValue(businessIdInvalidFormatMessage);
        const businessIdValidate = validators.composeValidators(
          businessIdRequired,
          businessIdInvalidFormat
        );

        // Password field
        const passwordRequiredMessage = intl.formatMessage({
          id: 'OrganizationDetailTab.passwordRequired',
        });
        const passwordMinLengthMessage = intl.formatMessage(
          { id: 'SignupForm.passwordTooShort' },
          { minLength: validators.PASSWORD_MIN_LENGTH }
        );
        const passwordMaxLengthMessage = intl.formatMessage(
          { id: 'SignupForm.passwordTooLong' },
          { maxLength: validators.PASSWORD_MAX_LENGTH }
        );
        const passwordMinLength = validators.minLength(
          passwordMinLengthMessage,
          validators.PASSWORD_MIN_LENGTH
        );
        const passwordMaxLength = validators.maxLength(
          passwordMaxLengthMessage,
          validators.PASSWORD_MAX_LENGTH
        );
        const passwordRequired = validators.requiredStringNoTrim(passwordRequiredMessage);
        const passwordValidators = validators.composeValidators(
          passwordRequired,
          passwordMinLength,
          passwordMaxLength
        );
        const passwordFieldPlaceholder = intl.formatMessage({
          id: 'OrganizationDetailTab.passwordFieldPlaceholder',
        });

        // Payment methods
        const paymentMethodTitle = intl.formatMessage({
          id: 'OrganizationDetailTab.paymentMethodTitle',
        });
        const invoiceMethodText = intl.formatMessage({
          id: 'OrganizationDetailTab.invoiceMethodText',
        });

        const creditCardMethodText = intl.formatMessage({
          id: 'OrganizationDetailTab.creditCardMethodText',
        });
        const cardHolderNameRequiredMessage = intl.formatMessage({
          id: 'OrganizationDetailTab.cardHolderNameRequired',
        });
        const cardHolderNameRequired = validators.required(cardHolderNameRequiredMessage);
        const cardHolderNamePlaceholder = intl.formatMessage({
          id: 'OrganizationDetailTab.cardHolderNamePlaceholder',
        });
        const cardHolderNameLabel = intl.formatMessage({
          id: 'OrganizationDetailTab.cardHolderNameLabel',
        });
        const cardLabel = intl.formatMessage({
          id: 'OrganizationDetailTab.cardLabel',
        });

        const billingEmailPlaceholder = intl.formatMessage({
          id: 'OrganizationDetailTab.billingEmailPlaceholder',
        });

        const billingEmailLabel = intl.formatMessage({
          id: 'OrganizationDetailTab.billingEmailLabel',
        });

        const billingEmailInvalid = intl.formatMessage({
          id: 'OrganizationDetailTab.billingEmailInvalid',
        });

        const validateEmail = validators.billingEmailFormatValid(billingEmailInvalid);

        // Page contents
        const organizationDetailTitle = intl.formatMessage({
          id: 'OrganizationDetailTab.organizationDetailTitle',
        });

        const organizationDetailContent = intl.formatMessage({
          id: 'OrganizationDetailTab.organizationDetailContent',
        });

        const securityCommitmentContent = intl.formatMessage({
          id: 'OrganizationDetailTab.securityCommitmentContent',
        });

        const securityCommitmentContentDesktop = intl.formatMessage({
          id: 'OrganizationDetailTab.securityCommitmentContentDesktop',
        });

        const backButton = intl.formatMessage({
          id: 'OrganizationDetailTab.backButton',
        });

        const submitButton = intl.formatMessage({
          id: 'OrganizationDetailTab.submitButton',
        });

        const introduceSubs = intl.formatMessage({
          id: 'OrganizationDetailTab.introduceSubs',
        });

        const policyText = intl.formatMessage({
          id: 'OrganizationDetailTab.policyText',
        });

        const termsOfServiceText = intl.formatMessage({
          id: 'OrganizationDetailTab.termsOfServiceText',
        });

        const savePaymentMethodText = intl.formatMessage({
          id: 'OrganizationDetailTab.savePaymentMethodText',
        });

        const handleBackButton = () => {
          if (paymentMethod) {
            setPaymentMethod(null);
            card.current = null;
            form.change(INVOICE_METHOD, []);
            form.change('cardHolderName', '');
          }
          backToPreviosTab();
        };
        const hasCardError = error && !submitInProgress;
        const cardClasses = classNames(css.card, {
          [css.cardSuccess]: cardValueValid,
          [css.cardError]: hasCardError,
        });

        const termsOfServicePageUrl = createResourceLocatorString(
          'TermsOfServicePage',
          routeConfiguration()
        );
        const policy = (
          <p className={css.sectionContent}>
            {policyText}&nbsp;
            <a
              href={termsOfServicePageUrl}
              target="_blank"
              rel="noopener noreferrer"
              className={css.privacyLink}
            >
              {termsOfServiceText}
            </a>
          </p>
        );

        const signupErrorMessage = (
          <div className={css.error}>
            {isSignupEmailTakenError(signupError) ? (
              <FormattedMessage id="AuthenticationPage.signupFailedEmailAlreadyTaken" />
            ) : (
              <FormattedMessage id="AuthenticationPage.signupFailed" />
            )}
          </div>
        );

        const companyFormSection = (
          <>
            <p className={css.sectionContent}>{organizationDetailContent}</p>
            {!isAuthenticated && (
              <div className={css.inputWrapper}>
                <Icons.IconUser className={css.icon} />
                <FieldTextInput
                  name="name"
                  placeholder={nameFieldPlaceholder}
                  type="text"
                  className={css.iconInput}
                  validate={nameRequired}
                />
              </div>
            )}
            {!isAuthenticated && (
              <div className={css.inputWrapper}>
                <Icons.IconMail className={css.icon} />
                <FieldTextInput
                  name="email"
                  placeholder={emailFieldPlaceholder}
                  type="text"
                  className={css.iconInput}
                  validate={validators.composeValidators(emailRequired, emailValid)}
                />
              </div>
            )}
            {!savedPhone && (
              <div className={css.inputWrapper}>
                <Icons.IconPhone className={css.icon} />
                <FieldTextInput
                  name="phone"
                  placeholder={phoneFieldPlaceholder}
                  type="telephone"
                  className={css.iconInput}
                  validate={phoneRequired}
                />
              </div>
            )}
            {!savedOrganization && (
              <div className={css.inputWrapper}>
                <Icons.IconOrganization className={css.icon} />
                <FieldTextInput
                  name="organization"
                  placeholder={organizationFieldPlaceholder}
                  type="text"
                  className={css.iconInput}
                  validate={organizationNameRequired}
                />
              </div>
            )}
            {!savedBusinessId && (
              <div className={css.inputWrapper}>
                <Icons.IconBusiness className={css.icon} />
                <FieldTextInput
                  name="businessId"
                  placeholder={businessIdFieldPlaceholder}
                  type="text"
                  className={css.iconInput}
                  validate={businessIdValidate}
                />
              </div>
            )}
            {!isAuthenticated && (
              <div className={css.inputWrapper}>
                <Icons.IconLock className={css.icon} />
                <FieldTextInput
                  name="password"
                  placeholder={passwordFieldPlaceholder}
                  type="password"
                  className={css.iconInput}
                  validate={passwordValidators}
                />
              </div>
            )}
          </>
        );
        const paymentMethodSection = (
          <>
            <section>
              <div className={css.titleWrapper}>
                <h2 className={css.sectionTitle}>{paymentMethodTitle}</h2>
              </div>

              <div className={css.paymentMethodWrapper}>
                <div
                  className={`${css.paymentMethod} ${
                    paymentMethod === INVOICE_METHOD && css.selected
                  }`}
                  onClick={() => {
                    handleInvoiceMethod(form);
                  }}
                >
                  <Icons.IconInvoice className={css.paymentMethodIcon} />
                  <span className={css.paymentMethodText}>{invoiceMethodText}</span>
                </div>

                <div
                  className={`${css.paymentMethod} ${
                    paymentMethod === CREDIT_CARD_METHOD && css.selected
                  }`}
                  onClick={() => {
                    handleCreditCardMethod(form);
                  }}
                >
                  <Icons.IconCreditCard className={css.paymentMethodIcon} />
                  <span className={css.paymentMethodText}>{creditCardMethodText}</span>
                </div>
              </div>
              {paymentMethod === INVOICE_METHOD && (
                <div className={css.invoiceEmailWrapper}>
                  <FieldTextInput
                    id="invoiceEmail"
                    name="invoiceEmail"
                    type="text"
                    placeholder={billingEmailPlaceholder}
                    label={billingEmailLabel}
                    inputRootClass={css.invoiceEmailInput}
                    labelClassName={css.invoiceEmailInputLabel}
                    validate={validateEmail}
                  />
                </div>
              )}
              {paymentMethod === CREDIT_CARD_METHOD && (
                <div className={css.creditCardMethodWrapper}>
                  <FieldTextInput
                    id="cardHolderName"
                    type="text"
                    name="cardHolderName"
                    placeholder={cardHolderNamePlaceholder}
                    inputRootClass={css.cardHolderNameInput}
                    label={cardHolderNameLabel}
                    labelClassName={css.cardHolderNameLabel}
                    validate={cardHolderNameRequired}
                  />
                  <div className={css.paymentCardWrapper}>
                    <label className={css.cardLabel}>{cardLabel}</label>
                    <OneTimePaymentWithCardElement
                      cardClasses={cardClasses}
                      formId={'creditCardMethodField'}
                      handleStripeElementRef={handleStripeElementRef}
                      hasCardError={error}
                      error={error}
                      label={[`${savePaymentMethodText} `]}
                      intl={intl}
                      isCompanyForm
                      isSubscription={isSubscription}
                    />
                  </div>
                  <div className={css.securityCommitment}>
                    <Icons.IconGreenShield />
                    <p className={css.securityCommitmentContent}>
                      {isMobileLayout
                        ? securityCommitmentContent
                        : securityCommitmentContentDesktop}
                    </p>
                  </div>
                </div>
              )}
              {isMobileLayout && policy}
            </section>
            {signupError && signupErrorMessage}
            <div className={css.bottomButtonWrapper}>
              <Button type="button" className={css.backButton} onClick={handleBackButton}>
                {backButton}
              </Button>
              {!isMobileLayout && policy}
              <PrimaryButton
                type="submit"
                className={css.submitButton}
                disabled={submitButtonDisabled}
                inProgress={buttonInProgress}
              >
                {submitButton}
              </PrimaryButton>
            </div>
          </>
        );
        return (
          <Form onSubmit={handleSubmit}>
            <section>
              {!isMobileLayout && <div className={tabIconClass}>2</div>}
              <h2 className={css.sectionTitle}>{organizationDetailTitle}</h2>
              {!isMobileLayout && !isCurrentTab && !isPassed ? (
                <div className={css.introduceSubs}>
                  <Icons.IconHand className={css.handIcon} />
                  <p className={css.introduceSubsContent}>{introduceSubs}</p>
                </div>
              ) : (
                companyFormSection
              )}
            </section>
            {!isMobileLayout && !isCurrentTab && !isPassed ? null : paymentMethodSection}
          </Form>
        );
      }}
    />
  );
};

export default OrganizationDetailForm;
