import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './ArrowHeroSmall.module.scss';

const ArrowLarge = (props) => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);

  return (
    <svg
      className={classes}
      width={'200'}
      height={'190'}
      viewBox={'0 0 251 242'}
      fill={'none'}
      xmlns={'http://www.w3.org/2000/svg'}
    >
      <path
        d={
          'M1.5 0.5C6.40564 17.892 13.2817 28.2934 31 36.5C43.8828 42.4669 67.8215 52.9095 103.5 44.5C114.075 42.0075 118.205 24.3676 110.5 16.5C101.376 7.18344 88.3031 9.9088 82.5 16.5C60.622 41.3492 70.2386 66.7295 77 79.5C91.8035 107.46 129.859 101.956 142.5 131.5C151.408 152.321 132.145 166.897 116.5 176.5C97.9734 187.872 64 195 64 195M64 195L71.5 187.5M64 195L74 198.5'
        }
      />
    </svg>
  );
};

ArrowLarge.defaultProps = { rootClassName: null, className: null };

const { string } = PropTypes;

ArrowLarge.propTypes = { rootClassName: string, className: string };

export default ArrowLarge;
