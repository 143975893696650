import React, { memo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';
import { LISTING_STATE_DRAFT } from '../../util/types';
import { ListingLink } from '../../components';
import { EditListingPricingForm } from '../../forms';
import { ensureOwnListing } from '../../util/data';
import { types as sdkTypes } from '../../util/sdkLoader';
import config from '../../config';
import get from 'lodash/get';
import isEqual from 'lodash/isEqual';
import { v4 as uuidv4 } from 'uuid';

import css from './EditListingPricingPanel.module.scss';
import { deleteExceptions } from '../EditListingAvailabilityPanel/EditListingAvailabilityPanel.helper';
import { createDefaultAvailabilityPlan } from './EditListingPricingPanel.helper';

const { Money } = sdkTypes;

const EditListingPricingPanel = memo((props) => {
  const {
    className,
    rootClassName,
    listing,
    disabled,
    ready,
    onSubmit,
    onChange,
    submitButtonText,
    panelUpdated,
    updateInProgress,
    errors,
    availabilityExceptions,
    onDeleteAvailabilityException,
  } = props;

  const handleSubmit = async (values) => {
    const {
      bookingType,
      price,
      discountOffer,
      additionalServices = [],
      vatPercentage,
      requireShowing,
      showingTimes = [],
    } = values;
    const currentBookingType = get(listing, 'attributes.publicData.bookingType');
    const currentAvailabilityPlan = get(listing, 'attributes.availabilityPlan', null);
    if (currentBookingType !== bookingType) {
      await deleteExceptions(availabilityExceptions, onDeleteAvailabilityException);
    }
    const ensuredAdditionalServices = additionalServices.map((s) => ({
      ...s,
      price:
        s.price instanceof Money ? { amount: s.price.amount, currency: s.price.currency } : s.price,
      serviceId: s.id ? s.id : uuidv4(),
    }));
    const ensuredShowingTimes = [];
    showingTimes.sort((b, a) => b.date.date - a.date.date);
    showingTimes.forEach((element) => {
      element.date.date.setHours(element.time.substr(0, 2));
      element.date.date.setMinutes(element.time.substr(3, 4));
      ensuredShowingTimes.push(element.date.date.toString());
    });
    onSubmit({
      price,
      publicData: {
        bookingType,
        discountOffer,
        additionalServices: ensuredAdditionalServices,
        vatPercentage,
        requireShowing,
        showingTimes: ensuredShowingTimes,
      },
      availabilityPlan:
        currentBookingType !== bookingType
          ? createDefaultAvailabilityPlan(bookingType)
          : currentAvailabilityPlan,
    });
  };

  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureOwnListing(listing);
  const { price, publicData = {} } = currentListing.attributes;
  const {
    bookingType,
    discountOffer,
    additionalServices = [],
    vatPercentage,
    requireShowing,
    showingTimes,
  } = publicData;

  const isPublished = currentListing.id && currentListing.attributes.state !== LISTING_STATE_DRAFT;
  const panelTitle = isPublished ? (
    <FormattedMessage
      id="EditListingPricingPanel.title"
      values={{
        listingTitle: (
          <ListingLink listing={listing}>{listing.attributes.publicData.title}</ListingLink>
        ),
      }}
    />
  ) : (
    <FormattedMessage id="EditListingPricingPanel.createListingTitle" />
  );

  const priceCurrencyValid = price instanceof Money ? price.currency === config.currency : true;
  const initialAdditionalServices = additionalServices.map((s) => ({
    ...s,
    price: new Money(s.price.amount, s.price.currency),
  }));
  let initalShowingTimes = [];
  // const showingTimesArray = Object.values(showingTimes);
  showingTimes &&
    showingTimes.forEach((element) => {
      const date = new Date(Date.parse(element));
      if (date >= new Date()) {
        let hours = date.getHours().toString();
        if (hours.length === 1) {
          hours = '0' + hours;
        }
        let minutes = date.getMinutes().toString();
        if (minutes.length === 1) {
          minutes += '0';
        }
        initalShowingTimes.push({
          date: { date: date },
          time: `${hours}:${minutes}`,
        });
      }
    });
  const form = priceCurrencyValid ? (
    <EditListingPricingForm
      className={css.form}
      initialValues={{
        price,
        bookingType,
        discountOffer,
        additionalServices: initialAdditionalServices,
        vatPercentage,
        requireShowing,
        showingTimes: initalShowingTimes,
      }}
      onSubmit={handleSubmit}
      onChange={onChange}
      saveActionMsg={submitButtonText}
      disabled={disabled}
      ready={ready}
      updated={panelUpdated}
      updateInProgress={updateInProgress}
      fetchErrors={errors}
    />
  ) : (
    <div className={css.priceCurrencyInvalid}>
      <FormattedMessage id="EditListingPricingPanel.listingPriceCurrencyInvalid" />
    </div>
  );

  return (
    <div className={classes}>
      <h2 className={css.title}>{panelTitle}</h2>
      <p>
        <FormattedMessage id="EditListingPricingPanel.subTitle" />
      </p>
      {form}
    </div>
  );
}, isEqual);

const { func, object, string, bool } = PropTypes;

EditListingPricingPanel.defaultProps = {
  className: null,
  rootClassName: null,
  listing: null,
};

EditListingPricingPanel.propTypes = {
  className: string,
  rootClassName: string,

  // We cannot use propTypes.listing since the listing might be a draft.
  listing: object,

  disabled: bool.isRequired,
  ready: bool.isRequired,
  onSubmit: func.isRequired,
  onChange: func.isRequired,
  submitButtonText: string.isRequired,
  panelUpdated: bool.isRequired,
  updateInProgress: bool.isRequired,
  errors: object.isRequired,
};

export default EditListingPricingPanel;
