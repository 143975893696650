const { google, outlook, office365, ics } = require('calendar-link');

const AddToCalendarGenerator = (props) => {
  const { booking, listing, locationName } = props;

  const event = {
    title: `${locationName} - ${listing.attributes.publicData.category}`,
    description: listing.attributes.publicData.emailConfirmationContent
      ? listing.attributes.publicData.emailConfirmationContent
      : '',
    start: booking.attributes.start,
    end: booking.attributes.end,
    location: listing.attributes.publicData.location.address,
    busy: listing.attributes.publicData.bookingType === 'daily' ? false : true,
    allDay: listing.attributes.publicData.bookingType === 'daily' ? true : false,
  };

  // If daily booking - add opening hours to description
  if (
    listing.attributes.publicData.bookingType === 'daily' &&
    !!listing.attributes.publicData.operationHours
  ) {
    const { operationHours } = listing.attributes.publicData;

    const start = new Date(booking.attributes.start);
    const end = new Date(booking.attributes.end - 86400000); //remove one day

    //Find week day for start and end date. e.g: 'fri'
    const startDay = start.toString().slice(0, 3).toLowerCase();
    const endDay = end.toString().slice(0, 3).toLowerCase();

    if (event.description.length > 0) {
      event.description = event.description.concat(`\n \n`);
    }
    event.description = event.description.concat(
      `Opening hours: ${operationHours[startDay].openTime} - ${operationHours[endDay].closeTime}`
    );
  }
  return {
    google: google(event),
    outlook: outlook(event),
    office365: office365(event),
    ics: ics(event),
  };
};

export default AddToCalendarGenerator;
