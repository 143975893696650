import React from 'react';

import css from './LocationCombobox.module.scss';

const LocationIcon = () => (
  <div className={css.locationOptionIcon}>
    <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="14" cy="14" r="14" fill="#F8F8F8" />
      <path
        d="M18.6276 12.9091C18.6276 16.7273 13.8138 20 13.8138 20C13.8138 20 9 16.7273 9 12.9091C9 11.6071 9.50716 10.3585 10.4099 9.43784C11.3127 8.51721 12.5371 8 13.8138 8C15.0905 8 16.3149 8.51721 17.2176 9.43784C18.1204 10.3585 18.6276 11.6071 18.6276 12.9091Z"
        stroke="#999999"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.8136 14.5452C14.6998 14.5452 15.4182 13.8126 15.4182 12.9088C15.4182 12.0051 14.6998 11.2725 13.8136 11.2725C12.9274 11.2725 12.209 12.0051 12.209 12.9088C12.209 13.8126 12.9274 14.5452 13.8136 14.5452Z"
        stroke="#999999"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </div>
);

export default LocationIcon;
