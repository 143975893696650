import moment from 'moment';
import { bool, func, instanceOf, object, oneOfType, shape, string } from 'prop-types';
import React, { Component } from 'react';
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import config from '../../config';
import routeConfiguration from '../../routeConfiguration';
import {
  ensureBooking,
  ensureCurrentUser,
  ensureListing,
  ensurePaymentMethodCard,
  ensureStripeCustomer,
  ensureTransaction,
  ensureUser,
} from '../../util/data';
import { minutesBetween } from '../../util/dates';
import {
  isTransactionChargeDisabledError,
  isTransactionInitiateAmountTooLowError,
  isTransactionInitiateBookingTimeNotAvailableError,
  isTransactionInitiateListingNotFoundError,
  isTransactionInitiateMissingStripeAccountError,
  isTransactionZeroPaymentError,
  transactionInitiateOrderStripeErrors,
} from '../../util/errors';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { findRouteByRouteName, pathByRouteName } from '../../util/routes';
import { DATE_TYPE_DATETIME, propTypes } from '../../util/types';
import { createSlug } from '../../util/urlHelpers';
// import { fireFacebookEvent } from '../../util/api';
import { txIsPaymentExpired, txIsPaymentPending } from '../../util/transaction';
// import { TRANSITION_ENQUIRE, txIsPaymentPending, txIsPaymentExpired } from '../../util/transaction';
import get from 'lodash/get';
import {
  BookingBreakdown,
  Button,
  Logo,
  NamedLink,
  NamedRedirect,
  Page,
  PrimaryButton,
  ResponsiveImage,
} from '../../components';
import { ButtonNew, DropDownList } from '../../components_new';
import { LINK_COLORS } from '../../components_new/ButtonNew/ButtonNew';
import { savePaymentMethod } from '../../ducks/paymentMethods.duck';
import { handleCardPayment, retrievePaymentIntent } from '../../ducks/stripe.duck';
import { isScrollingDisabled } from '../../ducks/UI.duck';
import { StripePaymentForm } from '../../forms';
import { filters } from '../../marketplace-custom-config';
import {
  accessyCreateAccess,
  parakeyCreateAccess,
  saveBookingToHazura,
  voucherify,
} from '../../util/api';
import SendEmail from '../../emails/TransactionEmailNew';
import {
  isCompanyUser as checkCompanyUser,
  isEmployeeUser as checkEmployeeUser,
} from '../../util/b2bHelper';
import { HubspotController } from '../../util/hubspot';
import {
  confirmPayment,
  enquire,
  initiateOrder,
  sendMessage,
  setCoupon,
  setInitialValues,
  speculateTransaction,
  stripeCustomer,
  updateB2BBookingsMetadata,
} from './CheckoutPage.duck';
import css from './CheckoutPage.module.scss';
import { clearData, storeData, storedData } from './CheckoutPageSessionHelpers';

const STORAGE_KEY = 'CheckoutPage';

// Stripe PaymentIntent statuses, where user actions are already completed
// https://stripe.com/docs/payments/payment-intents/status
const STRIPE_PI_USER_ACTIONS_DONE_STATUSES = ['processing', 'requires_capture', 'succeeded'];

// Payment charge options
const ONETIME_PAYMENT = 'ONETIME_PAYMENT';
const PAY_AND_SAVE_FOR_LATER_USE = 'PAY_AND_SAVE_FOR_LATER_USE';
const USE_SAVED_CARD = 'USE_SAVED_CARD';

const paymentFlow = (selectedPaymentMethod, saveAfterOnetimePayment) => {
  // Payment mode could be 'replaceCard', but without explicit saveAfterOnetimePayment flag,
  // we'll handle it as one-time payment
  return selectedPaymentMethod === 'defaultCard'
    ? USE_SAVED_CARD
    : saveAfterOnetimePayment
    ? PAY_AND_SAVE_FOR_LATER_USE
    : ONETIME_PAYMENT;
};

const initializeOrderPage = (initialValues, routes, dispatch) => {
  const OrderPage = findRouteByRouteName('OrderConfirmationPage', routes);
  // Transaction is already created, but if the initial message
  // sending failed, we tell it to the OrderDetailsPage.
  dispatch(OrderPage.setInitialValues(initialValues));
};

const checkIsPaymentExpired = (existingTransaction) => {
  return txIsPaymentExpired(existingTransaction)
    ? true
    : txIsPaymentPending(existingTransaction)
    ? minutesBetween(existingTransaction.attributes.lastTransitionedAt, new Date()) >= 15
    : false;
};

export class CheckoutPageComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      pageData: {},
      dataLoaded: false,
      submitting: false,
      couponFailMessage: '',
      couponValid: false,
      couponOpen: false,
      phoneNumber: null,
      phoneNumberValid: false,
    };
    this.stripe = null;

    this.onStripeInitialized = this.onStripeInitialized.bind(this);
    this.loadInitialData = this.loadInitialData.bind(this);
    this.handlePaymentIntent = this.handlePaymentIntent.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleCoupon = this.handleCoupon.bind(this);
    this.onCouponChange = this.onCouponChange.bind(this);
    this.onCouponClick = this.onCouponClick.bind(this);
    this.handleCreditsTxConfirm = this.handleCreditsTxConfirm.bind(this);
    this.handlePhoneNumberChange = this.handlePhoneNumberChange.bind(this);
    this.hubspotController = new HubspotController();
  }

  componentDidMount() {
    if (window) {
      this.loadInitialData();
      this.hubspotController.updateAppearance().then((methods) => !!methods && methods.raise());
    }
  }

  componentWillMount() {
    this.hubspotController.updateAppearance().then((methods) => !!methods && methods.decrease());
  }

  /**
   * Load initial data for the page
   *
   * Since the data for the checkout is not passed in the URL (there
   * might be lots of options in the future), we must pass in the data
   * some other way. Currently the ListingPage sets the initial data
   * for the CheckoutPage's Redux store.
   *
   * For some cases (e.g. a refresh in the CheckoutPage), the Redux
   * store is empty. To handle that case, we store the received data
   * to window.sessionStorage and read it from there if no props from
   * the store exist.
   *
   * This function also sets of fetching the speculative transaction
   * based on this initial data.
   */
  loadInitialData() {
    const {
      bookingData,
      bookingDates,
      listing,
      transaction,
      fetchSpeculatedTransaction,
      fetchStripeCustomer,
      history,
    } = this.props;

    // Fetch currentUser with stripeCustomer entity
    // Note: since there's need for data loading in "componentWillMount" function,
    //       this is added here instead of loadData static function.
    fetchStripeCustomer();

    // Browser's back navigation should not rewrite data in session store.
    // Action is 'POP' on both history.back() and page refresh cases.
    // Action is 'PUSH' when user has directed through a link
    // Action is 'REPLACE' when user has directed through login/signup process
    const hasNavigatedThroughLink = history.action === 'PUSH' || history.action === 'REPLACE';

    const hasDataInProps = !!(bookingData && bookingDates && listing) && hasNavigatedThroughLink;
    if (hasDataInProps) {
      // Store data only if data is passed through props and user has navigated through a link.
      storeData(bookingData, bookingDates, listing, transaction, STORAGE_KEY);
    }

    // NOTE: stored data can be empty if user has already successfully completed transaction.
    const pageData = hasDataInProps
      ? { bookingData, bookingDates, listing, transaction }
      : storedData(STORAGE_KEY);

    // Check if a booking is already created according to stored data.
    const tx = pageData ? pageData.transaction : null;
    const isBookingCreated = tx && tx.booking && tx.booking.id;

    const shouldFetchSpeculatedTransaction =
      pageData &&
      pageData.listing &&
      pageData.listing.id &&
      pageData.bookingData &&
      pageData.bookingDates &&
      pageData.bookingDates.bookingStart &&
      pageData.bookingDates.bookingEnd &&
      pageData.bookingData.units &&
      pageData.bookingData.seats &&
      pageData.bookingData.bookingType &&
      !isBookingCreated;
    if (shouldFetchSpeculatedTransaction) {
      const listingId = pageData.listing.id;
      const transactionId = tx ? tx.id : null;
      const { bookingStart, bookingEnd } = pageData.bookingDates;
      const { seats, bookingType, additionalServices = [] } = pageData.bookingData;

      const coupon = pageData.bookingData.coupon;
      // Fetch speculated transaction for showing price in booking breakdown
      // NOTE: if unit type is line-item/units, quantity needs to be added.
      // The way to pass it to checkout page is through pageData.bookingData

      fetchSpeculatedTransaction(
        {
          listingId,
          bookingStart,
          bookingEnd,
          seats,
          bookingType,
          additionalServices,
          coupon,
        },
        transactionId
      );
    }

    this.setState({ pageData: pageData || {}, dataLoaded: true });
  }

  handlePaymentIntent(handlePaymentParams) {
    const {
      currentUser,
      stripeCustomerFetched,
      onInitiateOrder,
      onHandleCardPayment,
      onConfirmPayment,
      onSendMessage,
      onSavePaymentMethod,
      onUpdateB2BBookingsMetadata,
      onSendEnquiry,
    } = this.props;
    const {
      pageData,
      speculatedTransaction,
      message,
      paymentIntent,
      selectedPaymentMethod,
      saveAfterOnetimePayment,
    } = handlePaymentParams;
    const storedTx = ensureTransaction(pageData.transaction);

    const ensuredCurrentUser = ensureCurrentUser(currentUser);
    const ensuredStripeCustomer = ensureStripeCustomer(ensuredCurrentUser.stripeCustomer);
    const ensuredDefaultPaymentMethod = ensurePaymentMethodCard(
      ensuredStripeCustomer.defaultPaymentMethod
    );

    let createdPaymentIntent = null;

    const hasDefaultPaymentMethod = !!(
      stripeCustomerFetched &&
      ensuredStripeCustomer.attributes.stripeCustomerId &&
      ensuredDefaultPaymentMethod.id
    );
    const stripePaymentMethodId = hasDefaultPaymentMethod
      ? ensuredDefaultPaymentMethod.attributes.stripePaymentMethodId
      : null;

    const selectedPaymentFlow = paymentFlow(selectedPaymentMethod, saveAfterOnetimePayment);
    // Step 0: initiate enquiry
    const fnSendEnquiry = (fnParams) => {
      if (!!fnParams.acceptBookingOnRequest) {
        return onSendEnquiry(fnParams);
      } else {
        return fnParams;
      }
    };

    // Step 1: initiate order by requesting payment from Marketplace API
    const fnRequestPayment = (fnParams) => {
      // fnParams should be { listingId, bookingStart, bookingEnd }
      const hasPaymentIntents =
        storedTx.attributes.protectedData && storedTx.attributes.protectedData.stripePaymentIntents;

      // If paymentIntent exists, order has been initiated previously.
      return hasPaymentIntents ? Promise.resolve(storedTx) : onInitiateOrder(fnParams, storedTx.id);
    };

    // Step 2: pay using Stripe SDK
    const fnHandleCardPayment = (fnParams) => {
      // fnParams should be returned transaction entity
      const order = ensureTransaction(fnParams);
      if (order.id) {
        // Store order.
        const { bookingData, bookingDates, listing } = pageData;
        storeData(bookingData, bookingDates, listing, order, STORAGE_KEY);
        this.setState({ pageData: { ...pageData, transaction: order } });
      }

      const hasPaymentIntents =
        order.attributes.protectedData && order.attributes.protectedData.stripePaymentIntents;

      if (!hasPaymentIntents) {
        throw new Error(
          `Missing StripePaymentIntents key in transaction's protectedData. Check that your transaction process is configured to use payment intents.`
        );
      }

      const { stripePaymentIntentClientSecret } = hasPaymentIntents
        ? order.attributes.protectedData.stripePaymentIntents.default
        : null;

      const { stripe, card, billingDetails, paymentIntent } = handlePaymentParams;
      const stripeElementMaybe = selectedPaymentFlow !== USE_SAVED_CARD ? { card } : {};

      // Note: payment_method could be set here for USE_SAVED_CARD flow.
      // { payment_method: stripePaymentMethodId }
      // However, we have set it already on API side, when PaymentIntent was created.
      const paymentParams =
        selectedPaymentFlow !== USE_SAVED_CARD
          ? {
              payment_method_data: {
                billing_details: billingDetails,
              },
            }
          : {};

      const params = {
        stripePaymentIntentClientSecret,
        orderId: order.id,
        stripe,
        ...stripeElementMaybe,
        paymentParams,
      };

      // If paymentIntent status is not waiting user action,
      // handleCardPayment has been called previously.
      const hasPaymentIntentUserActionsDone =
        paymentIntent && STRIPE_PI_USER_ACTIONS_DONE_STATUSES.includes(paymentIntent.status);
      return hasPaymentIntentUserActionsDone
        ? Promise.resolve({ transactionId: order.id, paymentIntent })
        : onHandleCardPayment(params);
    };

    // Step 3: complete order by confirming payment to Marketplace API
    // Parameter should contain { paymentIntent, transactionId } returned in step 2
    const fnConfirmPayment = (fnParams) => {
      const { listing } = pageData;
      const tx = speculatedTransaction ? speculatedTransaction : storedTx;
      createdPaymentIntent = fnParams.paymentIntent;

      const isCreditTx = get(tx, 'attributes.protectedData.isCreditTx', false);
      const routes = routeConfiguration();
      const routePath = pathByRouteName('SaleDetailsPage', routes, {
        id: fnParams.transactionId.uuid,
      });
      const transactionURL = `${config.canonicalRootURL}${routePath ? routePath : '/inbox/sales'}`;
      SendEmail({
        pageData: {
          ...pageData,
          locationName: this.props.locationName,
        },
        user: ensuredCurrentUser,
        recipient: 'provider',
        transactionURL,
        filters,
        locale: this.props.intl.locale,
      });
      SendEmail({
        pageData: {
          ...pageData,
          locationName: this.props.locationName,
        },
        user: ensuredCurrentUser,
        recipient: 'buyer',
        b2bCustomer: isCreditTx,
        filters,
        locale: this.props.intl.locale,
      });
      const digitalKeyProps = listing.attributes.publicData.digitalKey;
      const supportsDigitalAccess = digitalKeyProps && digitalKeyProps.digitalKeyAccess;
      // if listing supports digitalKeyAccess, create keys for this transaction
      if (supportsDigitalAccess) {
        if (digitalKeyProps.accessProvider === 'accessy') {
          accessyCreateAccess({
            phoneNumber: this.state.phoneNumber,
            listingId: listing.id.uuid,
            bookingStart: tx.booking.attributes.start,
            bookingEnd: tx.booking.attributes.end,
            transactionId: fnParams.transactionId.uuid,
            userId: ensuredCurrentUser.id.uuid,
          });
        } else if (digitalKeyProps.accessProvider === 'parakey') {
          parakeyCreateAccess({
            email: ensuredCurrentUser.attributes.email,
            listingId: listing.id.uuid,
            bookingStart: tx.booking.attributes.start,
            bookingEnd: tx.booking.attributes.end,
            transactionId: fnParams.transactionId.uuid,
          });
        }
      }

      const mainListingImage = listing.images[0]?.attributes.variants['square-small2x'].url;
      const bookingHours = {
        startTime: null,
        endTime: null,
      };

      if (listing.attributes.publicData.bookingType === 'hourly') {
        const { start, end } = tx?.booking?.attributes;
        // todo - change this to not be hard coded as +2 hours
        bookingHours.startTime = moment(start).utcOffset('+0200').format('HH:mm');
        bookingHours.endTime = moment(end).utcOffset('+0200').format('HH:mm');
      }

      if (listing.attributes.publicData.bookingType === 'daily') {
        const currentDay = moment(tx.booking.attributes.start).format('ddd').toLowerCase();
        const currentOpeningHours = listing.attributes.publicData.operationHours[currentDay];
        bookingHours.startTime = currentOpeningHours.openTime;
        bookingHours.endTime = currentOpeningHours.closeTime;
      }

      const payload = {
        user: {
          sharetribeId: currentUser?.id?.uuid,
          companyId:
            currentUser?.attributes?.profile?.protectedData?.companyAccountId ||
            currentUser?.id?.uuid,
          firstName: currentUser?.attributes?.profile?.firstName,
          lastName: currentUser?.attributes?.profile?.lastName,
          imgUrl: currentUser?.profileImage?.attributes?.variants?.['square-small2x']?.url,
        },
        booking: {
          authorId: currentUser?.id?.uuid,
          listingAvailability: listing.attributes.publicData.bookingType,
          bookingId: null /* OR from state, later */,
          date: moment(tx?.booking?.attributes?.end).subtract(1, 'days').format('DD-MMM-YYYY'),
          listingId: listing?.id?.uuid,
          listingCategory: listing?.attributes?.publicData?.category,
          listingTitle: listing?.attributes?.title,
          mainImage: mainListingImage,
          startTime: bookingHours.startTime,
          endTime: bookingHours.endTime,
          transactionId: fnParams.transactionId.uuid,
          locationId: this.props.locationId,
        },
      };

      saveBookingToHazura(payload)
        .then((res) => console.log(res))
        .catch((e) => console.log(e));

      const updateBookingData = {
        user: ensuredCurrentUser,
        listing,
        bookingStart: tx.booking.attributes.start,
        bookingEnd: tx.booking.attributes.end,
        transactionId: fnParams.transactionId.uuid,
      };
      if (isCreditTx) {
        onUpdateB2BBookingsMetadata(updateBookingData);
      }
      // if code exists and is valid, it has been used on transaction, and is therefore redeemed here and logged in voucherify
      const code = pageData.coupon && pageData.coupon.valid ? pageData.coupon.code : null;
      const url = `https://api.voucherify.io/v1/vouchers/${code}/redemption`;
      if (code) {
        voucherify({ url });
      }
      // facebook pixel tracking
      // can take :
      // content_ids, content_name, content_type, contents, currency, num_items, value
      // Required: currency and value
      if (typeof window !== 'undefined' && !config.dev) {
        if (window.fbq != null) {
          window.fbq('track', 'Purchase', {
            content_name: fnParams.paymentIntent.description,
            contents: [{ transactionId: fnParams.transactionId.uuid }],
            currency: fnParams.paymentIntent.currency.toUpperCase() || 'SEK', // REQUIRED
            value: fnParams.paymentIntent.amount / 100 || 0, // REQUIRED
          });
        }
      }

      return onConfirmPayment({ ...fnParams, listing, isCreditTx });
    };

    // Step 4: send initial message
    const fnSendMessage = (fnParams) => {
      return onSendMessage({ ...fnParams, message });
    };

    // Step 5: optionally save card as defaultPaymentMethod
    const fnSavePaymentMethod = (fnParams) => {
      const pi = createdPaymentIntent || paymentIntent;

      if (selectedPaymentFlow === PAY_AND_SAVE_FOR_LATER_USE) {
        return onSavePaymentMethod(ensuredStripeCustomer, pi.payment_method)
          .then((response) => {
            if (response.errors) {
              return { ...fnParams, paymentMethodSaved: false };
            }
            return { ...fnParams, paymentMethodSaved: true };
          })
          .catch((e) => {
            // Real error cases are catched already in paymentMethods page.
            return { ...fnParams, paymentMethodSaved: false };
          });
      } else {
        return Promise.resolve({ ...fnParams, paymentMethodSaved: true });
      }
    };

    // Here we create promise calls in sequence
    // This is pretty much the same as:
    // fnRequestPayment({...initialParams})
    //   .then(result => fnHandleCardPayment({...result}))
    //   .then(result => fnConfirmPayment({...result}))
    const applyAsync = (acc, val) => acc.then(val);
    const composeAsync =
      (...funcs) =>
      (x) =>
        funcs.reduce(applyAsync, Promise.resolve(x));
    const handlePaymentIntentCreation = composeAsync(
      fnSendEnquiry,
      fnRequestPayment,
      fnHandleCardPayment,
      fnConfirmPayment,
      fnSendMessage,
      fnSavePaymentMethod
    );

    // Create order aka transaction
    // NOTE: if unit type is line-item/units, quantity needs to be added.
    // The way to pass it to checkout page is through pageData.bookingData
    const tx = speculatedTransaction ? speculatedTransaction : storedTx;

    // Note: optionalPaymentParams contains Stripe paymentMethod,
    // but that can also be passed on Step 2
    // stripe.handleCardPayment(stripe, { payment_method: stripePaymentMethodId })
    const optionalPaymentParams =
      selectedPaymentFlow === USE_SAVED_CARD && hasDefaultPaymentMethod
        ? { paymentMethod: stripePaymentMethodId }
        : selectedPaymentFlow === PAY_AND_SAVE_FOR_LATER_USE
        ? { setupPaymentMethodForSaving: true }
        : {};

    const isEmployeeUser = checkEmployeeUser(currentUser);
    const isCompanyUser = checkCompanyUser(currentUser);

    const orderParams = {
      listingId: pageData.listing.id,
      acceptBookingOnRequest: pageData.listing.attributes.publicData.acceptBookingOnRequest,
      bookingStart: tx.booking.attributes.start,
      bookingEnd: tx.booking.attributes.end,
      seats: pageData.bookingData ? pageData.bookingData.seats : null,
      bookingType: pageData ? pageData.bookingData.bookingType : null,
      additionalServices: pageData ? pageData.bookingData.additionalServices : [],
      isCreditTx: isEmployeeUser || isCompanyUser,
      protectedData: {
        bookingType: pageData ? pageData.bookingData.bookingType : null,
        price: {
          amount: pageData.listing.attributes.price.amount,
          currency: pageData.listing.attributes.price.currency,
        },
      },
      coupon: this.props.coupon,
      ...optionalPaymentParams,
    };

    return handlePaymentIntentCreation(orderParams);
  }

  handleSubmit(values) {
    const { dispatch } = this.props;
    if (this.state.submitting) {
      return;
    }
    this.setState({ submitting: true });

    const { speculatedTransaction, currentUser, paymentIntent, history } = this.props;
    const { card, message, paymentMethod, formValues } = values;
    const {
      name,
      addressLine1,
      addressLine2,
      postal,
      city,
      state,
      country,
      saveAfterOnetimePayment,
    } = formValues;

    // Billing address is recommended.
    // However, let's not assume that <StripePaymentAddress> data is among formValues.
    // Read more about this from Stripe's docs
    // https://stripe.com/docs/stripe-js/reference#stripe-handle-card-payment-no-element
    const addressMaybe =
      addressLine1 && postal
        ? {
            address: {
              city: city,
              country: country,
              line1: addressLine1,
              line2: addressLine2,
              postal_code: postal,
              state: state,
            },
          }
        : {};
    const billingDetails = {
      name,
      email: ensureCurrentUser(currentUser).attributes.email,
      ...addressMaybe,
    };

    const requestPaymentParams = {
      pageData: {
        ...this.state.pageData,
        locationName: this.props.locationName,
      },
      speculatedTransaction,
      stripe: this.stripe,
      card,
      billingDetails,
      message,
      paymentIntent,
      selectedPaymentMethod: paymentMethod,
      saveAfterOnetimePayment: !!saveAfterOnetimePayment,
      accessyPhoneNumber: this.state.accessyPhoneNumber,
    };

    this.handlePaymentIntent(requestPaymentParams)
      .then((res) => {
        const { orderId, messageSuccess, paymentMethodSaved } = res;
        this.setState({ submitting: false });

        const routes = routeConfiguration();
        const initialMessageFailedToTransaction = messageSuccess ? null : orderId;
        const orderDetailsPath = pathByRouteName('OrderConfirmationPage', routes, {
          id: orderId.uuid,
        });
        const initialValues = {
          initialMessageFailedToTransaction,
          savePaymentMethodFailed: !paymentMethodSaved,
        };

        // fireFacebookEvent({
        //   eventName: 'Purchase',
        //   payload: {
        //     type: 'onDemand',
        //     ...initialValues,
        //   },
        // });

        initializeOrderPage(initialValues, routes, dispatch);
        clearData(STORAGE_KEY);
        history.replace(orderDetailsPath);
      })
      .catch((err) => {
        console.error(err);
        this.setState({ submitting: false });
      });
  }

  handleCreditsTxConfirm() {
    if (this.state.submitting) {
      return;
    }
    this.setState({ submitting: true });
    const {
      onInitiateOrder,
      history,
      speculatedTransaction,
      currentUser,
      dispatch,
      onUpdateB2BBookingsMetadata,
    } = this.props;

    const pageData = this.state.pageData;
    const { listing } = pageData;
    const storedTx = ensureTransaction(pageData.transaction);
    const ensuredCurrentUser = ensureCurrentUser(currentUser);
    const tx = speculatedTransaction ? speculatedTransaction : storedTx;
    const isRequest = !!listing.attributes.publicData.acceptBookingOnRequest;

    const orderParams = {
      listingId: listing.id,
      acceptBookingOnRequest: isRequest,
      bookingStart: tx.booking.attributes.start,
      bookingEnd: tx.booking.attributes.end,
      seats: pageData.bookingData ? pageData.bookingData.seats : null,
      bookingType: pageData ? pageData.bookingData.bookingType : null,
      additionalServices: pageData ? pageData.bookingData.additionalServices : [],
      isCreditTx: true,
      protectedData: {
        bookingType: pageData ? pageData.bookingData.bookingType : null,
        price: {
          amount: listing.attributes.price.amount,
          currency: listing.attributes.price.currency,
        },
      },
    };

    const mainListingImage = listing.images[0]?.attributes.variants['square-small2x'].url;

    const bookingHours = {
      startTime: null,
      endTime: null,
    };

    if (listing.attributes.publicData.bookingType === 'hourly') {
      // todo - change this to not be hard coded as +2 hours
      const { start, end } = tx?.booking?.attributes;
      bookingHours.startTime = moment(start).utcOffset('+0200').format('HH:mm');
      bookingHours.endTime = moment(end).utcOffset('+0200').format('HH:mm');
    }

    if (listing.attributes.publicData.bookingType === 'daily') {
      const currentDay = moment(tx.booking.attributes.start).format('ddd').toLowerCase();
      const currentOpeningHours = listing.attributes.publicData.operationHours[currentDay];
      bookingHours.startTime = currentOpeningHours.openTime;
      bookingHours.endTime = currentOpeningHours.closeTime;
    }

    onInitiateOrder(orderParams)
      .then((res) => {
        const { id } = res;
        this.setState({ submitting: false });

        const payload = {
          user: {
            sharetribeId: currentUser?.id?.uuid,
            companyId:
              currentUser?.attributes?.profile?.protectedData?.companyAccountId ||
              currentUser?.id?.uuid,
            firstName: currentUser?.attributes?.profile?.firstName,
            lastName: currentUser?.attributes?.profile?.lastName,
            imgUrl: currentUser?.profileImage?.attributes?.variants?.['square-small2x']?.url,
          },
          booking: {
            authorId: currentUser?.id?.uuid,
            listingAvailability: listing.attributes.publicData.bookingType,
            bookingId: null /* OR from state, later */,
            date: moment(tx?.booking?.attributes?.end).subtract(1, 'days').format('DD-MMM-YYYY'),
            listingId: listing?.id?.uuid,
            listingCategory: listing?.attributes?.publicData?.category,
            listingTitle: listing?.attributes?.title,
            mainImage: mainListingImage,
            startTime: bookingHours.startTime,
            endTime: bookingHours.endTime,
            transactionId: id.uuid,
            locationId: this.props.locationId,
          },
        };

        saveBookingToHazura(payload).then((res) => console.log(res));

        const routes = routeConfiguration();
        // const initialMessageFailedToTransaction = messageSuccess ? null : orderId;
        const orderDetailsPath = pathByRouteName('OrderConfirmationPage', routes, {
          id: id.uuid,
        });
        const initialValues = {
          // initialMessageFailedToTransaction,
        };

        initializeOrderPage(initialValues, routes, dispatch);
        const updateBookingData = {
          user: ensuredCurrentUser,
          listing,
          bookingStart: tx.booking.attributes.start,
          bookingEnd: tx.booking.attributes.end,
          transactionId: id.uuid,
        };
        onUpdateB2BBookingsMetadata(updateBookingData);

        const routePath = pathByRouteName('SaleDetailsPage', routes, {
          id: id.uuid,
        });
        const transactionURL = `${config.canonicalRootURL}${
          routePath ? routePath : '/inbox/sales'
        }`;
        SendEmail({
          pageData: {
            ...pageData,
            locationName: this.props.locationName,
          },
          user: ensuredCurrentUser,
          recipient: 'buyer',
          b2bCustomer: true,
          filters,
          locale: this.props.intl.locale,
        });
        SendEmail({
          pageData: {
            ...pageData,
            locationName: this.props.locationName,
          },
          user: ensuredCurrentUser,
          recipient: 'provider',
          ...(isRequest && { transactionURL }),
          filters,
          locale: this.props.intl.locale,
        });

        // facebook pixel tracking
        // can take :
        // content_ids, content_name, content_type, contents, currency, num_items, value
        // Required: currency and value
        // do we want to change the amount to the amount it is worth rather than amount paid in, which will just be any additional services purchased?
        if (typeof window !== 'undefined' && !config.dev) {
          if (window.fbq != null) {
            window.fbq('track', 'Purchase', {
              content_name: `${listing.attributes.title} B2B`,
              contents: [{ transactionId: id.uuid }],
              currency: tx.attributes.payinTotal.currency.toUpperCase() || 'SEK', // REQUIRED
              value: tx.attributes.protectedData.lineItems[0]?.lineTotal.amount || 0, // REQUIRED
            });
          }
        }

        const digitalKeyProps = listing.attributes.publicData.digitalKey;
        const supportsDigitalAccess = digitalKeyProps && digitalKeyProps.digitalKeyAccess;
        // if listing supports digitalKeyAccess, create keys for this transaction
        if (supportsDigitalAccess) {
          if (digitalKeyProps.accessProvider === 'accessy') {
            accessyCreateAccess({
              phoneNumber: this.state.phoneNumber,
              listingId: listing.id.uuid,
              bookingStart: tx.booking.attributes.start,
              bookingEnd: tx.booking.attributes.end,
              transactionId: id.uuid,
              userId: ensuredCurrentUser.id.uuid,
            });
          } else if (digitalKeyProps.accessProvider === 'parakey') {
            parakeyCreateAccess({
              email: ensuredCurrentUser.attributes.email,
              listingId: listing.id.uuid,
              bookingStart: tx.booking.attributes.start,
              bookingEnd: tx.booking.attributes.end,
              transactionId: id.uuid,
            });
          }
        }

        clearData(STORAGE_KEY);
        history.replace(orderDetailsPath);
      })
      .catch((err) => {
        console.error(err);
        this.setState({ submitting: false });
      });
  }

  onStripeInitialized(stripe) {
    this.stripe = stripe;

    const { paymentIntent, onRetrievePaymentIntent } = this.props;
    const tx = this.state.pageData ? this.state.pageData.transaction : null;

    // We need to get up to date PI, if booking is created but payment is not expired.
    const shouldFetchPaymentIntent =
      this.stripe &&
      !paymentIntent &&
      tx &&
      tx.id &&
      tx.booking &&
      tx.booking.id &&
      txIsPaymentPending(tx) &&
      !checkIsPaymentExpired(tx);

    if (shouldFetchPaymentIntent) {
      const { stripePaymentIntentClientSecret } =
        tx.attributes.protectedData && tx.attributes.protectedData.stripePaymentIntents
          ? tx.attributes.protectedData.stripePaymentIntents.default
          : {};

      // Fetch up to date PaymentIntent from Stripe
      onRetrievePaymentIntent({ stripe, stripePaymentIntentClientSecret });
    }
  }
  // this handles coupon validation, sending request to voucherify with validate url
  handleCoupon(e) {
    // get the code from coupon text field and remove any accidental whitespace
    const code = e.target.value.trim();
    const url = `https://api.voucherify.io/v1/vouchers/${code}/validate`;
    //  the following process is copied from above, creating a new transaction with voucherify response
    const { fetchSpeculatedTransaction } = this.props;
    const pageData = this.state.pageData;
    // Check if a booking is already created according to stored data.
    const tx = pageData ? pageData.transaction : null;

    const listingId = pageData.listing.id;
    const transactionId = tx ? tx.id : null;
    const { bookingStart, bookingEnd } = pageData.bookingDates;
    const { seats, bookingType, additionalServices = [] } = pageData.bookingData;

    voucherify({ url })
      .then((response) => {
        const coupon = response.data;
        pageData.coupon = coupon;
        let fail = coupon ? coupon.reason : null;
        let success = coupon && coupon.valid ? true : false;

        fetchSpeculatedTransaction(
          {
            listingId,
            bookingStart,
            bookingEnd,
            seats,
            bookingType,
            coupon,
            additionalServices,
          },
          transactionId
        );

        this.setState({
          couponFailMessage: fail,
          couponValid: success,
        });
      })
      .catch((err) => console.error(err));
  }

  onCouponChange(e) {
    this.setState({
      couponFieldValue: e.target.value,
    });
  }
  onCouponClick() {
    this.setState({
      couponOpen: !this.state.couponOpen,
    });
  }
  handlePhoneNumberChange(event) {
    this.setState({ phoneNumber: event });
    if (event && isValidPhoneNumber(event)) {
      this.setState({ phoneNumberValid: true });
    } else {
      this.setState({ phoneNumberValid: false });
    }
  }
  render() {
    const {
      scrollingDisabled,
      speculateTransactionInProgress,
      speculateTransactionError,
      speculatedTransaction: speculatedTransactionMaybe,
      initiateOrderError,
      confirmPaymentError,
      intl,
      params,
      currentUser,
      locationName,
      handleCardPaymentError,
      paymentIntent,
      retrievePaymentIntentError,
      stripeCustomerFetched,
      history,
    } = this.props;

    // Since the listing data is already given from the ListingPage
    // and stored to handle refreshes, it might not have the possible
    // deleted or closed information in it. If the transaction
    // initiate or the speculative initiate fail due to the listing
    // being deleted or closec, we should dig the information from the
    // errors and not the listing data.
    const listingNotFound =
      isTransactionInitiateListingNotFoundError(speculateTransactionError) ||
      isTransactionInitiateListingNotFoundError(initiateOrderError);

    const isLoading = !this.state.dataLoaded || speculateTransactionInProgress;

    const { listing, bookingDates, transaction, bookingData = {} } = this.state.pageData;
    const { bookingType, additionalServices, seats, units } = bookingData;
    const existingTransaction = ensureTransaction(transaction);
    const speculatedTransaction = ensureTransaction(speculatedTransactionMaybe, {}, null);
    const currentListing = ensureListing(listing);
    const currentAuthor = ensureUser(currentListing.author);

    const isRequest = !!currentListing.attributes.publicData.acceptBookingOnRequest;
    const digitalKeyProps = currentListing.attributes.publicData.digitalKey;
    const supportsDigitalAccess = !!digitalKeyProps && digitalKeyProps.digitalKeyAccess;
    const accessProvider = supportsDigitalAccess ? digitalKeyProps.accessProvider : null;

    const listingTitle = currentListing.attributes.title;
    const title = intl.formatMessage(
      { id: 'CheckoutPage.title' },
      { listingTitle: locationName || listingTitle }
    );

    const pageProps = { title, scrollingDisabled };
    const topbar = (
      <div className={css.topbar}>
        <NamedLink className={css.home} name="LandingPage">
          <Logo
            className={css.logoDesktop}
            alt={intl.formatMessage({ id: 'CheckoutPage.goToLandingPage' })}
            format="desktop"
          />
        </NamedLink>
      </div>
    );

    if (isLoading) {
      return <Page {...pageProps}>{topbar}</Page>;
    }

    const isOwnListing =
      currentUser &&
      currentUser.id &&
      currentAuthor &&
      currentAuthor.id &&
      currentAuthor.id.uuid === currentUser.id.uuid;

    const hasListingAndAuthor = !!(currentListing.id && currentAuthor.id);
    const hasBookingDates = !!(
      bookingDates &&
      bookingDates.bookingStart &&
      bookingDates.bookingEnd
    );
    const hasRequiredData = hasListingAndAuthor && hasBookingDates;
    const canShowPage = hasRequiredData && !isOwnListing;
    const shouldRedirect = !isLoading && !canShowPage;

    // Redirect back to ListingPage if data is missing.
    // Redirection must happen before any data format error is thrown (e.g. wrong currency)
    if (shouldRedirect) {
      // eslint-disable-next-line no-console
      console.error('Missing or invalid data for checkout, redirecting back to listing page.', {
        transaction: speculatedTransaction,
        bookingDates,
        listing,
      });
      return <NamedRedirect name="ListingPage" params={params} />;
    }

    // Show breakdown only when speculated transaction and booking are loaded
    // (i.e. have an id)
    const tx = existingTransaction.booking ? existingTransaction : speculatedTransaction;
    const txBooking = ensureBooking(tx.booking);
    const timeZone = currentListing.attributes.availabilityPlan
      ? currentListing.attributes.availabilityPlan.timezone
      : 'Etc/UTC';
    const hasAdditionalServices =
      Array.isArray(additionalServices) && additionalServices.length > 0;
    const isCreditTx = get(tx, 'attributes.protectedData.isCreditTx', false);

    const creditsBookingWithAddons = !isCreditTx || (isCreditTx && hasAdditionalServices);

    const breakdown =
      tx.id && txBooking.id ? (
        <BookingBreakdown
          className={css.bookingBreakdown}
          userRole="customer"
          locationName={locationName}
          unitType={config.bookingUnitType}
          transaction={tx}
          booking={txBooking}
          dateType={DATE_TYPE_DATETIME}
          timeZone={timeZone}
          bookingType={bookingType}
          currentUser={currentUser}
          hasAdditionalServices={hasAdditionalServices}
          units={units}
          seats={seats}
        />
      ) : null;

    const isPaymentExpired = checkIsPaymentExpired(existingTransaction);
    const hasDefaultPaymentMethod = !!(
      stripeCustomerFetched &&
      ensureStripeCustomer(currentUser.stripeCustomer).attributes.stripeCustomerId &&
      ensurePaymentMethodCard(currentUser.stripeCustomer.defaultPaymentMethod).id
    );

    // Allow showing page when currentUser is still being downloaded,
    // but show payment form only when user info is loaded.
    const showPaymentForm = !!(
      currentUser &&
      hasRequiredData &&
      !listingNotFound &&
      !initiateOrderError &&
      !speculateTransactionError &&
      !retrievePaymentIntentError &&
      !isPaymentExpired &&
      creditsBookingWithAddons
    );

    const firstImage =
      currentListing.images && currentListing.images.length > 0 ? currentListing.images[0] : null;

    const listingLink = (
      <NamedLink
        name="ListingPage"
        params={{ id: currentListing.id.uuid, slug: createSlug(listingTitle) }}
      >
        <FormattedMessage id="CheckoutPage.errorlistingLinkText" />
      </NamedLink>
    );

    const isAmountTooLowError = isTransactionInitiateAmountTooLowError(initiateOrderError);
    const isChargeDisabledError = isTransactionChargeDisabledError(initiateOrderError);
    const isBookingTimeNotAvailableError =
      isTransactionInitiateBookingTimeNotAvailableError(initiateOrderError);
    const stripeErrors = transactionInitiateOrderStripeErrors(initiateOrderError);

    let initiateOrderErrorMessage = null;
    let listingNotFoundErrorMessage = null;

    if (listingNotFound) {
      listingNotFoundErrorMessage = (
        <p className={css.notFoundError}>
          <FormattedMessage id="CheckoutPage.listingNotFoundError" />
        </p>
      );
    } else if (isAmountTooLowError) {
      initiateOrderErrorMessage = (
        <p className={css.orderError}>
          <FormattedMessage id="CheckoutPage.initiateOrderAmountTooLow" />
        </p>
      );
    } else if (isBookingTimeNotAvailableError) {
      initiateOrderErrorMessage = (
        <p className={css.orderError}>
          <FormattedMessage id="CheckoutPage.bookingTimeNotAvailableMessage" />
        </p>
      );
    } else if (isChargeDisabledError) {
      initiateOrderErrorMessage = (
        <p className={css.orderError}>
          <FormattedMessage id="CheckoutPage.chargeDisabledMessage" />
        </p>
      );
    } else if (stripeErrors && stripeErrors.length > 0) {
      // NOTE: Error messages from Stripes are not part of translations.
      // By default they are in English.
      const stripeErrorsAsString = stripeErrors.join(', ');
      initiateOrderErrorMessage = (
        <p className={css.orderError}>
          <FormattedMessage
            id="CheckoutPage.initiateOrderStripeError"
            values={{ stripeErrors: stripeErrorsAsString }}
          />
        </p>
      );
    } else if (initiateOrderError) {
      // Generic initiate order error
      initiateOrderErrorMessage = (
        <p className={css.orderError}>
          <FormattedMessage id="CheckoutPage.initiateOrderError" values={{ listingLink }} />
        </p>
      );
    }

    const speculateTransactionErrorMessage = speculateTransactionError ? (
      <p className={css.speculateError}>
        <FormattedMessage id="CheckoutPage.speculateTransactionError" />
      </p>
    ) : null;
    let speculateErrorMessage = null;

    if (isTransactionInitiateMissingStripeAccountError(speculateTransactionError)) {
      speculateErrorMessage = (
        <p className={css.orderError}>
          <FormattedMessage id="CheckoutPage.providerStripeAccountMissingError" />
        </p>
      );
    } else if (isTransactionInitiateBookingTimeNotAvailableError(speculateTransactionError)) {
      speculateErrorMessage = (
        <p className={css.orderError}>
          <FormattedMessage id="CheckoutPage.bookingTimeNotAvailableMessage" />
        </p>
      );
    } else if (isTransactionZeroPaymentError(speculateTransactionError)) {
      speculateErrorMessage = (
        <p className={css.orderError}>
          <FormattedMessage id="CheckoutPage.initiateOrderAmountTooLow" />
        </p>
      );
    } else if (speculateTransactionError) {
      speculateErrorMessage = (
        <p className={css.orderError}>
          <FormattedMessage id="CheckoutPage.speculateFailedMessage" />
        </p>
      );
    }

    const showInitialMessageInput = false; // Disable the "send message to provider"-field

    // Get first and last name of the current user and use it in the StripePaymentForm to autofill the name field
    const userName =
      currentUser && currentUser.attributes
        ? `${currentUser.attributes.profile.firstName} ${currentUser.attributes.profile.lastName}`
        : null;

    // If paymentIntent status is not waiting user action,
    // handleCardPayment has been called previously.
    const hasPaymentIntentUserActionsDone =
      paymentIntent && STRIPE_PI_USER_ACTIONS_DONE_STATUSES.includes(paymentIntent.status);

    // If your marketplace works mostly in one country you can use initial values to select country automatically
    // e.g. {country: 'FI'}

    const initalValuesForStripePayment = { name: userName };

    const onBackButton = () => {
      history.goBack();
    };

    const changeBooking = (
      <FormattedMessage id="CheckoutPage.changeBookingButton">
        {(id) => (
          <ButtonNew
            className={css.changeBookingButton}
            type="inline"
            linkColor={LINK_COLORS.BLUE}
            textLight
            onClick={() => {
              history.goBack();
            }}
          >
            {id}
          </ButtonNew>
        )}
      </FormattedMessage>
    );

    const phoneNumberPlaceholder = intl.formatMessage({
      id: 'CheckoutPage.phoneNumberPlaceholder',
    });
    const digitalKeyHeader =
      accessProvider === 'accessy'
        ? intl.formatMessage(
            {
              id: 'CheckoutPage.accessyHeader',
            },
            {
              bold: (val) => <span className={css.bold}>{val}</span>,
            }
          )
        : accessProvider === 'parakey'
        ? intl.formatMessage(
            {
              id: 'CheckoutPage.parakeyHeader',
            },
            {
              bold: (val) => <span className={css.bold}>{val}</span>,
            }
          )
        : '';
    const digitalKeyMessage =
      accessProvider === 'accessy'
        ? intl.formatMessage({
            id: 'CheckoutPage.accessyMessage',
          })
        : accessProvider === 'parakey'
        ? intl.formatMessage({
            id: 'CheckoutPage.parakeyMessage',
          })
        : '';
    const couponFieldLabel = intl.formatMessage({
      id: 'StripePaymentForm.couponFieldLabel',
    });
    const couponFieldPlaceholder = intl.formatMessage({
      id: 'StripePaymentForm.couponFieldPlaceholder',
    });
    const couponButtonText = intl.formatMessage({
      id: 'StripePaymentForm.couponButtonText',
    });
    const couponApplyText = intl.formatMessage({
      id: 'StripePaymentForm.couponApplyText',
    });
    const couponFailMessage = this.state.couponFailMessage
      ? intl.formatMessage(
          { id: 'StripePaymentForm.couponFailMessage' },
          { reason: this.state.couponFailMessage }
        )
      : null;
    const couponSuccessMessage = this.state.couponValid
      ? intl.formatMessage({
          id: 'StripePaymentForm.couponSuccessMessage',
        })
      : null;

    const couponClass = this.state.couponOpen;

    const couponField = creditsBookingWithAddons && (
      <div className={css.couponField}>
        <div>
          <span
            className={`${css.couponTitle} ${couponClass ? css.open : ''}`}
            onClick={this.onCouponClick}
          >
            {couponButtonText}
          </span>
          <span>{couponClass ? ' -' : ' +'}</span>
        </div>
        <div className={`${css.couponContent} ${couponClass ? css.couponOpen : css.couponClosed}`}>
          <input
            type="textarea"
            onChange={this.onCouponChange}
            placeholder={couponFieldPlaceholder}
            label={couponFieldLabel}
            className={css.message}
          ></input>
          <div className={css.couponFailMessage} id="couponMessage">
            {couponFailMessage}
          </div>
          <Button
            className={`${css.submitButton} ${css.couponButton}`}
            type="button"
            value={this.state.couponFieldValue}
            onClick={this.handleCoupon}
          >
            {couponApplyText}
          </Button>
        </div>
        <div className={css.couponSuccessMessage} id="couponMessage">
          {couponSuccessMessage}
        </div>
      </div>
    );

    const FAQs = [
      {
        question: intl.formatMessage({ id: 'CheckoutPage.faqQuestion1' }),
        answer: intl.formatMessage({ id: 'CheckoutPage.faqAnswer1' }),
      },
      {
        question: intl.formatMessage({ id: 'CheckoutPage.faqQuestion2' }),
        answer: intl.formatMessage({ id: 'CheckoutPage.faqAnswer2' }),
      },
      {
        question: intl.formatMessage({ id: 'CheckoutPage.faqQuestion3' }),
        answer: intl.formatMessage({ id: 'CheckoutPage.faqAnswer3' }),
      },
    ];

    const isEmployeeUser = checkEmployeeUser(currentUser);
    const isCompanyUser = checkCompanyUser(currentUser);

    return (
      <Page {...pageProps}>
        {topbar}
        <div className={css.contentContainer}>
          <div className={css.bookListingContainer}>
            <div className={css.progressBorder}></div>
            {currentUser && !isPaymentExpired && (
              <section className={css.loginContainer}>
                <FormattedMessage id="CheckoutPage.loginHeader">
                  {(id) => <h3 className={css.loginHeader}>{id}</h3>}
                </FormattedMessage>
                <FormattedMessage
                  id="CheckoutPage.userHeader"
                  values={{ name: currentUser.attributes.profile.firstName }}
                >
                  {(id) => <p className={`${css.loginSubheader} ${css.name}`}>{id}</p>}
                </FormattedMessage>
                {!(isEmployeeUser || isCompanyUser) && (
                  <FormattedMessage id="CheckoutPage.userSubHeader">
                    {(id) => <p className={css.loginSubheader}>{id}</p>}
                  </FormattedMessage>
                )}
                {supportsDigitalAccess && (
                  <div className={css.accessyBookingContainer}>
                    <div className={css.digitalKey}>
                      <p>{digitalKeyHeader}</p>
                      <p>{digitalKeyMessage}</p>
                    </div>
                    {accessProvider === 'accessy' && (
                      <PhoneInput
                        id={'accessyPhoneNumber'}
                        name="accessyPhoneNumber"
                        placeholder={phoneNumberPlaceholder}
                        value={this.state.phoneNumber}
                        defaultCountry="SE"
                        international
                        countryCallingCodeEditable={false}
                        onChange={this.handlePhoneNumberChange}
                      />
                    )}
                  </div>
                )}
              </section>
            )}
            {(isEmployeeUser || isCompanyUser) && (
              <section className={css.headerContainer}>
                <FormattedMessage id="CheckoutPage.summaryTitle">
                  {(id) => <p className={css.summaryTitle}>{id}</p>}
                </FormattedMessage>
                <FormattedMessage
                  id={
                    isRequest ? 'CheckoutPage.summaryContentRequest' : 'CheckoutPage.summaryContent'
                  }
                  values={{ name: currentUser?.attributes.profile.firstName || '' }}
                >
                  {(id) => <p className={css.summaryContent}>{id}</p>}
                </FormattedMessage>
              </section>
            )}
            <div className={css.priceBreakdownContainer}>
              {speculateTransactionErrorMessage}
              {breakdown}
              {changeBooking}
              {couponField}
            </div>
            <section className={css.paymentContainer}>
              {initiateOrderErrorMessage}
              {listingNotFoundErrorMessage}
              {speculateErrorMessage}
              {retrievePaymentIntentError ? (
                <p className={css.orderError}>
                  <FormattedMessage
                    id="CheckoutPage.retrievingStripePaymentIntentFailed"
                    values={{ listingLink }}
                  />
                </p>
              ) : null}
              {showPaymentForm ? (
                <StripePaymentForm
                  className={css.paymentForm}
                  onSubmit={this.handleSubmit}
                  inProgress={this.state.submitting}
                  formId="CheckoutPagePaymentForm"
                  paymentInfo={intl.formatMessage({ id: 'CheckoutPage.paymentInfo' })}
                  authorDisplayName={currentAuthor.attributes.profile.displayName}
                  showInitialMessageInput={showInitialMessageInput}
                  initialValues={initalValuesForStripePayment}
                  initiateOrderError={initiateOrderError}
                  handleCardPaymentError={handleCardPaymentError}
                  confirmPaymentError={confirmPaymentError}
                  hasHandledCardPayment={hasPaymentIntentUserActionsDone}
                  loadingData={!stripeCustomerFetched}
                  defaultPaymentMethod={
                    hasDefaultPaymentMethod ? currentUser.stripeCustomer.defaultPaymentMethod : null
                  }
                  paymentIntent={paymentIntent}
                  onStripeInitialized={this.onStripeInitialized}
                  currentUser={currentUser}
                  creditsBookingWithAddons={creditsBookingWithAddons}
                  isAccessyBooking={accessProvider === 'accessy'}
                  phoneNumberValid={this.state.phoneNumberValid}
                  isRequest={isRequest}
                />
              ) : null}
              {isPaymentExpired ? (
                <p className={css.orderError}>
                  <FormattedMessage
                    id="CheckoutPage.paymentExpiredMessage"
                    values={{ listingLink }}
                  />
                </p>
              ) : null}
            </section>
            {isRequest && !showPaymentForm && (
              <section className={css.headerContainer}>
                <FormattedMessage id="StripePaymentForm.confirmationTitle">
                  {(id) => <p className={css.summaryTitle}>{id}</p>}
                </FormattedMessage>
                <FormattedMessage
                  id="StripePaymentForm.confirmationContentRequest"
                  values={{ name: currentUser?.attributes.profile.firstName || '' }}
                >
                  {(id) => <p className={css.summaryContent}>{id}</p>}
                </FormattedMessage>
              </section>
            )}
            {isCreditTx && !hasAdditionalServices ? (
              <div className={css.submitContainer}>
                <div className={css.submitButtonContainer}>
                  <ButtonNew
                    className={css.backButton}
                    type="inline"
                    size="m"
                    textLight
                    onClick={onBackButton}
                  >
                    <FormattedMessage id="CheckoutPage.backButton" />
                  </ButtonNew>
                  <PrimaryButton
                    className={css.submitButton}
                    type="button"
                    onClick={this.handleCreditsTxConfirm}
                    inProgress={this.state.submitting}
                    disabled={
                      this.state.submitting ||
                      (accessProvider === 'accessy' && !this.state.phoneNumberValid)
                    }
                  >
                    <FormattedMessage
                      id={!isRequest ? 'CheckoutPage.confirmButton' : 'CheckoutPage.requestButton'}
                    />
                  </PrimaryButton>
                </div>
              </div>
            ) : null}
            <section className={css.faqSection}>
              <FormattedMessage id="CheckoutPage.faq">
                {(id) => <h3 className={css.faqHeader}>{id}</h3>}
              </FormattedMessage>
              <DropDownList
                menuList={FAQs.map((faq) => ({
                  header: faq.question,
                  content: faq.answer,
                }))}
                indent
              />
            </section>
          </div>

          <div className={css.detailsContainerDesktop}>
            <div className={css.detailsAspectWrapper}>
              <ResponsiveImage
                rootClassName={css.rootForImage}
                alt={listingTitle}
                image={firstImage}
                variants={['landscape-crop', 'landscape-crop2x']}
              />
            </div>
            <FormattedMessage id="CheckoutPage.breakdownTitle">
              {(id) => <h2 className={css.detailsTitle}>{id}</h2>}
            </FormattedMessage>
            {speculateTransactionErrorMessage}
            {breakdown}
            {changeBooking}
            {couponField}
          </div>
        </div>
      </Page>
    );
  }
}

CheckoutPageComponent.defaultProps = {
  initiateOrderError: null,
  confirmPaymentError: null,
  listing: null,
  bookingData: {},
  bookingDates: null,
  speculateTransactionError: null,
  speculatedTransaction: null,
  transaction: null,
  currentUser: null,
  paymentIntent: null,
  coupon: null,
};

CheckoutPageComponent.propTypes = {
  scrollingDisabled: bool.isRequired,
  listing: propTypes.listing,
  bookingData: object,
  bookingDates: shape({
    bookingStart: instanceOf(Date).isRequired,
    bookingEnd: instanceOf(Date).isRequired,
  }),
  coupon: string,
  fetchStripeCustomer: func.isRequired,
  stripeCustomerFetched: bool.isRequired,
  fetchSpeculatedTransaction: func.isRequired,
  speculateTransactionInProgress: bool.isRequired,
  speculateTransactionError: propTypes.error,
  speculatedTransaction: propTypes.transaction,
  transaction: propTypes.transaction,
  currentUser: propTypes.currentUser,
  params: shape({
    id: string,
    slug: string,
  }).isRequired,
  onConfirmPayment: func.isRequired,
  onInitiateOrder: func.isRequired,
  onHandleCardPayment: func.isRequired,
  onRetrievePaymentIntent: func.isRequired,
  onSavePaymentMethod: func.isRequired,
  onSendMessage: func.isRequired,
  onSetCoupon: func.isRequired,
  initiateOrderError: propTypes.error,
  confirmPaymentError: propTypes.error,
  // handleCardPaymentError comes from Stripe so that's why we can't expect it to be in a specific form
  handleCardPaymentError: oneOfType([propTypes.error, object]),
  paymentIntent: object,

  // from connect
  dispatch: func.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,

  // from withRouter
  history: shape({
    push: func.isRequired,
  }).isRequired,
};

const mapStateToProps = (state) => {
  const {
    locationName,
    locationId,
    listing,
    bookingData,
    bookingDates,
    coupon,
    stripeCustomerFetched,
    speculateTransactionInProgress,
    speculateTransactionError,
    speculatedTransaction,
    transaction,
    initiateOrderError,
    confirmPaymentError,
  } = state.CheckoutPage;
  const { currentUser } = state.user;
  const { handleCardPaymentError, paymentIntent, retrievePaymentIntentError } = state.stripe;
  return {
    locationName,
    locationId,
    scrollingDisabled: isScrollingDisabled(state),
    currentUser,
    stripeCustomerFetched,
    bookingData,
    bookingDates,
    coupon,
    speculateTransactionInProgress,
    speculateTransactionError,
    speculatedTransaction,
    transaction,
    listing,
    initiateOrderError,
    handleCardPaymentError,
    confirmPaymentError,
    paymentIntent,
    retrievePaymentIntentError,
  };
};

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  fetchSpeculatedTransaction: (params, transactionId) =>
    dispatch(speculateTransaction(params, transactionId)),
  fetchStripeCustomer: () => dispatch(stripeCustomer()),
  onInitiateOrder: (params, transactionId) => dispatch(initiateOrder(params, transactionId)),
  onRetrievePaymentIntent: (params) => dispatch(retrievePaymentIntent(params)),
  onHandleCardPayment: (params) => dispatch(handleCardPayment(params)),
  onConfirmPayment: (params) => dispatch(confirmPayment(params)),
  onSendEnquiry: (params) => dispatch(enquire(params)),
  onSendMessage: (params) => dispatch(sendMessage(params)),
  onSavePaymentMethod: (stripeCustomer, stripePaymentMethodId) =>
    dispatch(savePaymentMethod(stripeCustomer, stripePaymentMethodId)),
  onSetCoupon: (params) => dispatch(setCoupon(params)),
  onUpdateB2BBookingsMetadata: (params) => dispatch(updateB2BBookingsMetadata(params)),
});

const CheckoutPage = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl
)(CheckoutPageComponent);

CheckoutPage.setInitialValues = (initialValues, saveToSessionStorage = false) => {
  if (saveToSessionStorage) {
    const { listing, bookingData, bookingDates } = initialValues;
    storeData(bookingData, bookingDates, listing, null, STORAGE_KEY);
  }

  return setInitialValues(initialValues);
};

CheckoutPage.displayName = 'CheckoutPage';

export default CheckoutPage;
