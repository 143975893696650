import React, { useEffect, useState } from 'react';
import css from './EditListingAvailabilityPanel.module.scss';
import { FormattedMessage } from 'react-intl';
import {
  FieldSelect,
  Form,
  SaveAndExitButton,
  FieldCheckbox,
  FieldNumberInput,
  FixedBottomButtons,
  PopupModal,
  OutsideClickHandler,
} from '..';
import { ButtonNew, DatePickerNew } from '../../components_new';
import { BUTTON_TYPES } from '../../components_new/ButtonNew/ButtonNew';

import config from '../../config';
import * as validators from '../../util/validators';
import { func } from 'prop-types';
import { bool } from 'prop-types';
import { object } from 'prop-types';
import { FormSpy } from 'react-final-form';
import classNames from 'classnames';

const SectionSubletting = ({
  intl,
  disabled,
  ready,
  errors,
  isPublished,
  handleSubmit,
  updateInProgress,
  values,
  onSaveAndExitHandlers,
  submittedValues,
  form,
}) => {
  // const [priceVAT, setPriceVAT] = useState(0);
  const [offerModalOpen, setOfferModalOpen] = useState(false);
  const [priceIncVAT, setPriceIncVAT] = useState(0);
  const [priceRespacesComission, setPriceRespacesComission] = useState(0);
  const [noContractLengthRequired, setNoContractLengthRequired] = useState(false);
  const [isDatepickerOpen, setDatepickerOpen] = useState(false);
  useEffect(() => {
    setNoContractLengthRequired(!!values.noReqForContractLength);
  }, [values]);
  useEffect(() => {
    // Assign filters that are set if subleasing through Respaces
    values.contractType = 'subleasing';
    values.priceUnit = 'month';
    values.paymentPeriod = 'month';
  }, []);

  const handleDateUpdate = ({ startDate }) => {
    form.change('startDate', startDate.format('DD/MM/YYYY'));
  };

  const vatRequiredMessage = intl.formatMessage({
    id: 'EditListingPricingForm.vatRequiredMessage',
  });
  const comission = 5; //Respaces comission for subleasing

  const rightBtnText = isPublished
    ? intl.formatMessage({ id: 'EditListing.saveButton' })
    : intl.formatMessage({ id: 'EditListing.submitButton' });

  const checkIcon = (
    <svg width="16" height="13" viewBox="0 0 16 13" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1 6.25851L5.66667 11.517L15 1" stroke="black" stroke-width="1.5" />
    </svg>
  );

  return (
    <>
      <Form id={'SectionNew'} onSubmit={handleSubmit}>
        <FormSpy
          onChange={({ values }) => {
            values.pricePerUnit = values.priceSubletting * 1.05 * (1 + values.vatPercentage / 100);
            submittedValues.current = values;
            // Calculate prices for breakdown. Cannot use one variable from state to calculate the other
            // as it might not update before rendering the other.
            const price = values?.priceSubletting;
            // setPriceVAT((price * parseInt(values?.vatPercentage)) / 100);
            setPriceIncVAT(
              parseFloat((price + (price * parseInt(values?.vatPercentage)) / 100).toFixed(2))
            );
            setPriceRespacesComission(parseFloat((price * (comission / 100)).toFixed(2)));
          }}
        />

        <SaveAndExitButton onClick={onSaveAndExitHandlers(values)} />
        <>
          <fieldset className={css.fieldset}>
            <div className={css.mainInput}>
              <label className={css.label}>
                {intl.formatMessage({ id: 'EditListingAvailabilityPlanFormNew.startDate' })}
              </label>
              <button
                type="button"
                onClick={() => setDatepickerOpen(true)}
                className={css.openDatepickerBtn}
              >
                {values.startDate ||
                  intl.formatMessage({ id: 'EditListingAvailabilityPlanFormNew.datePlaceholder' })}
                <svg
                  width="14"
                  height="8"
                  viewBox="0 0 14 8"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M1 1L7 7L13 1" stroke="black" />
                </svg>
              </button>
              {isDatepickerOpen && (
                <OutsideClickHandler
                  className={css.datepickerWrapper}
                  onOutsideClick={() => setDatepickerOpen(false)}
                >
                  <DatePickerNew
                    value={values.startDate}
                    onDatesChange={handleDateUpdate}
                    selectMultipleDays={false}
                    onClose={() => setDatepickerOpen(false)}
                  />
                </OutsideClickHandler>
              )}
            </div>
          </fieldset>
          <fieldset className={css.fieldset}>
            <div className={css.mainInput}>
              <label className={css.label}>
                {intl.formatMessage({ id: 'EditListingAvailabilityPlanFormNew.sublettingPrice' })}
              </label>
              <div className={css.priceAndUnitInput}>
                <FieldNumberInput
                  className={css.pricePerUnitInput}
                  id="priceSubletting"
                  name="priceSubletting"
                  placeholder="0"
                  inline={true}
                />
                {intl.formatMessage({ id: 'SectionNew.breakdown.currency' })}/
                {intl.formatMessage({ id: `EditListingAvailabilityPlanFormNew.unit.month` })}
              </div>
            </div>
            <div className={css.subInput}>
              <FieldCheckbox
                textClassName={css.checkboxLabel}
                id="requestPrice"
                name="requestPrice"
                label={intl.formatMessage({
                  id: 'EditListingAvailabilityPlanFormNew.requestPrice',
                })}
              />
            </div>
            {!!values?.requestPrice && !Number(values?.pricePerUnit) && (
              <div className={css.tip}>
                {intl.formatMessage(
                  { id: 'EditListingAvailabilityPlanFormNew.tip' },
                  { bold: (text) => <span>{text}</span> }
                )}
              </div>
            )}
            <section className={css.vatPercentageWrapper}>
              <FieldSelect
                id="vatPercentage"
                name="vatPercentage"
                selectClassName={css.fieldSelect}
                className={css.fieldWrapper}
                label={intl.formatMessage({ id: 'SectionNew.vat' })}
                inline
                validate={validators.required(vatRequiredMessage)}
              >
                <option disabled value="">
                  {intl.formatMessage({ id: 'SectionNew.vatPlaceholder' })}
                </option>
                {config.custom.VAT_VALUES.map((opt) => (
                  <option value={opt.key} key={`vat_${opt.key}`}>
                    {opt.label}
                  </option>
                ))}
              </FieldSelect>
            </section>
          </fieldset>
          <div className={css.pricingWrapper}>
            <div className={css.priceExplainerBox} onClick={() => setOfferModalOpen(true)}>
              <p className={css.calcRow}>
                <span className={css.boldText}>
                  {intl.formatMessage({ id: 'SectionSubletting.priceExplainer.title' })}
                </span>
              </p>
              <p className={css.calcRow}>
                {intl.formatMessage({ id: 'SectionSubletting.priceExplainer.subtitle' })}
              </p>
              <p className={css.calcRow}>
                <span className={css.icon}>{checkIcon}</span>
                {intl.formatMessage({ id: 'SectionSubletting.priceExplainer.bullet1' })}
              </p>
              <p className={css.calcRow}>
                <span className={css.icon}>{checkIcon}</span>
                {intl.formatMessage({ id: 'SectionSubletting.priceExplainer.bullet2' })}
              </p>
              <p className={css.calcRow}>
                <span className={css.icon}>{checkIcon}</span>
                {intl.formatMessage({ id: 'SectionSubletting.priceExplainer.bullet3' })}
              </p>
            </div>
            <section className={css.priceCalculation}>
              <div>
                {/* <p className={css.calcRow}>
              <span>{intl.formatMessage({ id: 'SectionNew.breakdown.vat' })}</span>
              <span>
                {values?.vatPercentage === '0' ? '0' : priceVAT.toFixed(2) || 'X'}
                {intl.formatMessage({ id: 'SectionNew.breakdown.currency' })}
              </span>
            </p> */}
                <p className={css.calcRow}>
                  <span>
                    {intl.formatMessage({ id: 'SectionNew.breakdown.priceIncVatSubletting' })}
                  </span>
                  <span>
                    {priceIncVAT || 'X'}
                    {intl.formatMessage({ id: 'SectionNew.breakdown.currency' })}
                  </span>
                </p>
                <p className={css.calcRow}>
                  <span>
                    {intl.formatMessage({ id: 'SectionNew.breakdown.respacesFee' })}({comission}%)
                  </span>
                  <span>
                    {priceRespacesComission || 'X'}
                    {intl.formatMessage({ id: 'SectionNew.breakdown.currency' })}
                  </span>
                </p>
                {/* <p className={css.calcRow}>
              <span>{intl.formatMessage({ id: 'SectionNew.breakdown.vatFee' })}(25%)</span>
              <span>
                {(priceRespacesComission * 0.25).toFixed(2) || 'X'}
                {intl.formatMessage({ id: 'SectionNew.breakdown.currency' })}
              </span>
            </p> */}
                <p className={classNames(css.calcRow, css.summaryRow)}>
                  <span className={css.boldText}>
                    {intl.formatMessage({ id: 'SectionNew.breakdown.sublettingPriceOnListing' })}
                  </span>
                  <span>
                    {values.priceSubletting && priceRespacesComission
                      ? (values.priceSubletting + priceRespacesComission).toFixed(2)
                      : 'X'}
                    {intl.formatMessage({ id: 'SectionNew.breakdown.currency' })}
                  </span>
                </p>
                {/* <p className={css.calcRow}>
              <span>
                {intl.formatMessage({ id: 'SectionNew.breakdown.sublettingPriceIncVAT' })}
              </span>
              <span>
                {(priceIncVAT + priceRespacesComission * 1.25).toFixed(2) || 'X'}
                {intl.formatMessage({ id: 'SectionNew.breakdown.currency' })}
              </span>
            </p> */}
              </div>
            </section>
          </div>
          <fieldset className={css.fieldset}>
            <div className={css.mainInput}>
              <label className={css.label}>
                {intl.formatMessage({ id: 'EditListingAvailabilityPlanFormNew.contractLength' })}
              </label>
              <div
                className={classNames(
                  css.priceAndUnitInput,
                  noContractLengthRequired && css.disabled
                )}
              >
                <FieldNumberInput
                  className={css.pricePerUnitInput}
                  id="contractLengthPerUnit"
                  name="contractLengthPerUnit"
                  placeholder="0"
                  disabled={noContractLengthRequired}
                  inline={true}
                />
                <FieldSelect
                  className={css.priceUnitSelect}
                  name="contractLengthUnit"
                  id="contractLengthUnit"
                  disabled={noContractLengthRequired}
                >
                  {['month', 'year'].map((o) => (
                    <option
                      value={o}
                      key={`contract_length_unit_${o}`}
                      selected={values?.contractLengthUnit === o}
                    >
                      {intl.formatMessage({
                        id: `EditListingAvailabilityPlanFormNew.unit.${o}.plural`,
                      })}
                    </option>
                  ))}
                </FieldSelect>
              </div>
            </div>
            <p className={css.contractDescription}>
              {intl.formatMessage({
                id: 'EditListingAvailabilityPlanFormNew.contractLength.caption',
              })}
            </p>
            <div className={css.subInput}>
              <FieldCheckbox
                textClassName={css.checkboxLabel}
                id="noReqForContractLength"
                name="noReqForContractLength"
                label={intl.formatMessage({
                  id: 'EditListingAvailabilityPlanFormNew.noReqForContractLength',
                })}
              />
            </div>
          </fieldset>
          <fieldset className={css.fieldset}>
            <div className={css.mainInput}>
              <label className={css.label}>
                {intl.formatMessage({ id: 'EditListingAvailabilityPlanFormNew.noticePeriod' })}
              </label>
              <div className={css.priceAndUnitInput}>
                <FieldNumberInput
                  className={css.pricePerUnitInput}
                  id="noticePeriodPerUnit"
                  name="noticePeriodPerUnit"
                  placeholder="0"
                  inline={true}
                />
                <FieldSelect
                  className={css.priceUnitSelect}
                  name="noticePeriodUnit"
                  id="noticePeriodUnit"
                >
                  {['month', 'year'].map((o) => (
                    <option
                      value={o}
                      key={`notice_period_unit_${o}`}
                      selected={values?.noticePeriodUnit === o}
                    >
                      {intl.formatMessage({
                        id: `EditListingAvailabilityPlanFormNew.unit.${o}.plural`,
                      })}
                    </option>
                  ))}
                </FieldSelect>
              </div>
            </div>
          </fieldset>
          <fieldset className={css.fieldset}>
            <label className={css.label}>
              {intl.formatMessage({
                id: 'EditListingAvailabilityPlanFormNew.allowVatExemptCompanies',
              })}
            </label>
            <FieldCheckbox
              textClassName={css.checkboxLabel}
              id="allowVatExemptCompanies"
              name="allowVatExemptCompanies"
              label={intl.formatMessage({ id: 'FieldBoolean.yes' })}
            />
          </fieldset>
        </>

        {errors.showListingsError ? (
          <p className={css.error}>
            <FormattedMessage id="EditListingAvailabilityPanel.showListingFailed" />
          </p>
        ) : null}
        <FixedBottomButtons
          leftBtnText={intl.formatMessage({ id: 'EditListing.backButton' })}
          rightBtnText={rightBtnText}
          rightBtnType="submit"
          rightBtnInProgress={updateInProgress}
          rightBtnReady={ready}
          rightBtnDisabled={disabled}
        />
      </Form>
      <PopupModal
        id="ServiceFeeExplainerModal"
        isOpen={offerModalOpen}
        onClose={() => setOfferModalOpen(false)}
        modalContainer={css.modalContainer}
        hideCloseButton
        usePortal
      >
        <OutsideClickHandler onOutsideClick={() => setOfferModalOpen(false)}>
          <div className={css.serviceFeeModal}>
            <h3 className={css.modalTitle}>
              {intl.formatMessage({ id: 'SectionSubletting.ServiceFeeExplainerModal.title' })}
            </h3>
            <p>
              {intl.formatMessage({ id: 'SectionSubletting.ServiceFeeExplainerModal.subtitle' })}
            </p>
            <h4 className={css.boldText}>
              {intl.formatMessage({
                id: 'SectionSubletting.ServiceFeeExplainerModal.sectionTitle1',
              })}
            </h4>
            <p>
              {intl.formatMessage({
                id: 'SectionSubletting.ServiceFeeExplainerModal.sectionText1',
              })}
            </p>
            <h4 className={css.boldText}>
              {intl.formatMessage({
                id: 'SectionSubletting.ServiceFeeExplainerModal.sectionTitle2',
              })}
            </h4>
            <p>
              {intl.formatMessage({
                id: 'SectionSubletting.ServiceFeeExplainerModal.sectionText2',
              })}
            </p>
            <h4 className={css.boldText}>
              {intl.formatMessage({
                id: 'SectionSubletting.ServiceFeeExplainerModal.sectionTitle3',
              })}
            </h4>
            <p>
              {intl.formatMessage({
                id: 'SectionSubletting.ServiceFeeExplainerModal.sectionText3',
              })}
            </p>
            <h4 className={css.boldText}>
              {intl.formatMessage({
                id: 'SectionSubletting.ServiceFeeExplainerModal.sectionTitle4',
              })}
            </h4>
            <p>
              {intl.formatMessage({
                id: 'SectionSubletting.ServiceFeeExplainerModal.sectionText4',
              })}
            </p>
            <ButtonNew
              className={css.modalButton}
              type={BUTTON_TYPES.BLUE}
              onClick={() => setOfferModalOpen(false)}
            >
              {intl.formatMessage({ id: 'SectionSubletting.ServiceFeeExplainerModal.button' })}
            </ButtonNew>
          </div>
        </OutsideClickHandler>
      </PopupModal>
    </>
  );
};

SectionSubletting.defaultProps = {
  disabled: false,
  ready: false,
  errors: null,
  handleSubmit: null,
  updateInProgress: false,
};

SectionSubletting.propTypes = {
  disabled: bool.isRequired,
  ready: bool.isRequired,
  errors: object.isRequired,
  handleSubmit: func.isRequired,
  updateInProgress: bool.isRequired,
};

export default SectionSubletting;
