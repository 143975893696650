import React, { useState } from 'react';
import classNames from 'classnames';
import { array, func, string, bool } from 'prop-types';
import { Link } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Autoplay, Navigation, Pagination } from 'swiper';
import config from '../../config';

import { ListingCardNew } from '../index';
import { isMobile } from '../../util/device';
import { DEFAULT_SEARCH_PARAMS } from '../../util/search';
import { intlShape } from '../../util/reactIntl';
import { BottomSheet, LocationCard } from '../../components_new';
import { BUTTON_TYPES } from '../ButtonNew/ButtonNew';
import { LISTING_STATES } from '../../ducks/user.duck';

import ContentLoader from './ContentLoader';

import css from './FavoriteListingsSlider.module.scss';
import left from './assets/left.svg';
import right from './assets/right.svg';

SwiperCore.use([Autoplay, Navigation, Pagination]);

const PlaceholderCard = ({ intl }) => {
  return (
    <Link className={css.placeholderCard} to={DEFAULT_SEARCH_PARAMS}>
      <svg
        className={css.placeholderIcon}
        width="49"
        height="46"
        viewBox="0 0 49 46"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M42.7471 21.2104C50.9478 7.23113 38.6731 0 33.0047 0C25.1628 0 22.7495 5.2501 22.7495 5.2501C22.7495 5.2501 20.3344 0 12.496 0C4.65065 0 -15.8615 13.8358 22.7495 40.2508C23.3778 39.8203 23.9483 39.4037 24.545 38.9802C26.8218 42.8758 31.0377 45.5009 35.8747 45.5009C43.1251 45.5009 49 39.626 49 32.3756C48.9982 27.6505 46.4922 23.5204 42.7471 21.2104ZM22.7477 38.1244C1.71584 23.4679 0.807569 13.9355 2.17259 9.5219C3.72137 4.51855 9.25849 1.75003 12.4925 1.75003C19.0254 1.75003 21.08 5.82407 21.164 5.99908L22.7827 9.32771L24.3368 5.98167C24.4138 5.82416 26.4666 1.75003 33.0029 1.75003C36.2388 1.75003 41.7741 4.51855 43.3211 9.5219C44.0229 11.7882 44.1157 15.4108 41.1931 20.3844C39.5656 19.6599 37.7683 19.2504 35.8713 19.2504C28.6209 19.2504 22.746 25.1252 22.746 32.3756C22.746 34.1607 23.1047 35.86 23.7505 37.4105C23.418 37.6502 23.0925 37.8829 22.7477 38.1244ZM35.873 43.7508C29.5904 43.7508 24.4978 38.6582 24.4978 32.3756C24.4978 26.093 29.5904 21.0004 35.873 21.0004C42.1556 21.0004 47.2482 26.093 47.2482 32.3756C47.2482 38.6582 42.1556 43.7508 35.873 43.7508Z" />
        <path d="M36.7482 24.5H34.9982V31.5001H27.998V33.2502H34.9982V40.2503H36.7482V33.2502H43.7483V31.5001H36.7482V24.5Z" />
      </svg>
      <p className={css.placeholderText}>
        {intl.formatMessage({ id: 'LandingPage.favoriteListings.placeholderText' })}
      </p>
      <span className={css.placeholderLink}>
        {intl.formatMessage({ id: 'LandingPage.favoriteListings.placeholderLink' })}
      </span>
    </Link>
  );
};

const FavoriteListingsSlider = ({
  intl,
  title,
  caption,
  listings = {},
  favoriteListingsIds,
  companyFavoriteListingsIds,
  onToggleFavoriteListing,
  onToggleCompanyFavoriteListing,
  showCompanyFavoriteButton,
  isCompanyAdmin,
  isCompanyFavoriteList,
  hideFavoriteIcon,
  id,
  isControlledByRespaces,
  isShowCredits,
  isLoading,
}) => {
  const [isMobileOpen, setIsMobileOpen] = useState(false);
  const [focusListing, setFocusListing] = useState(null);

  /* Prepare data */
  const slides =
    listings &&
    listings
      .filter(
        (l) =>
          l?.attributes?.state === LISTING_STATES.PUBLISHED ||
          l?.status === LISTING_STATES.PUBLISHED
      )
      .map((l, index) => {
        return (
          <SwiperSlide className={css['swiper-slide']} key={`Listing_${index}`}>
            {l?.attributes?.state ? (
              <ListingCardNew
                listing={l}
                setActiveListing={() => null}
                onToggleFavoriteListing={onToggleFavoriteListing}
                onToggleCompanyFavoriteListing={onToggleCompanyFavoriteListing}
                isFavorite={favoriteListingsIds?.includes(l.attributes?.publicData?.locationId)}
                isCompanyFavorite={companyFavoriteListingsIds?.includes(l.id?.uuid)}
                showCompanyFavoriteButton={showCompanyFavoriteButton}
                isFavoriteCard
                hideFavoriteIcon={hideFavoriteIcon}
                isShowCredits={isShowCredits}
                isMobile={isMobile}
                onClick={(e) => {
                  if (isMobile) {
                    e.preventDefault();
                    setFocusListing(l);
                    setIsMobileOpen(true);
                  }
                }}
              />
            ) : (
              <div key={l.id}>
                <LocationCard
                  stateCategories={null}
                  location={l}
                  isFavorite={favoriteListingsIds?.includes(l.id)}
                  isCompanyFavorite={false}
                  isShowCredits={isShowCredits}
                  showCompanyFavoriteButton={false}
                  isFavoriteCard
                  search={''}
                  isMobile={isMobile}
                />
              </div>
            )}
          </SwiperSlide>
        );
      });

  const isCompanyFavorite =
    ((isCompanyAdmin && isCompanyFavoriteList) || !isCompanyFavoriteList) &&
    !isControlledByRespaces;

  if (isCompanyFavorite) {
    slides.push(
      <SwiperSlide className={css['swiper-slide']} key="add_favorite_card_1">
        <PlaceholderCard intl={intl} />
      </SwiperSlide>
    );

    slides.length &&
      slides.length < 4 &&
      slides.push(
        <SwiperSlide className={css['swiper-slide']} key="add_favorite_card_2">
          <PlaceholderCard intl={intl} />
        </SwiperSlide>
      );
  }

  return isLoading && slides.length === 0 ? (
    <section className={css.section}>
      <ContentLoader />
    </section>
  ) : !isLoading ? (
    <section className={css.section}>
      <h5 className={css.title}>{title}</h5>
      {caption && <p className={css.caption}>{caption}</p>}
      <div className={css.navigation}>
        <img
          width="40"
          height="40"
          className={classNames(`swiper-button-prev-${id}`, css.swiperButton)}
          alt="slider button"
          src={left}
        />
        <img
          width="40"
          height="40"
          className={classNames(`swiper-button-next-${id}`, css.swiperButton)}
          alt="slider button"
          src={right}
        />
      </div>
      <Swiper
        className={classNames(css['swiper-container'], isMobile && css.mobile)}
        freeMode={true}
        slidesPerView={2}
        spaceBetween={8}
        breakpoints={{
          576: {
            slidesPerView: 2,
            spaceBetween: 8,
          },
          768: {
            slidesPerView: isMobile ? 5 : 3,
            spaceBetween: 8,
          },
          1024: {
            slidesPerView: 5,
            spaceBetween: 8,
          },
        }}
        navigation={{
          nextEl: `.swiper-button-next-${id}`,
          prevEl: `.swiper-button-prev-${id}`,
        }}
      >
        {slides}
      </Swiper>
      {isMobileOpen && isMobile && (
        <BottomSheet
          className={css.bottomSheet}
          title={focusListing?.attributes.title}
          isOpen={isMobileOpen}
          onClose={() => setIsMobileOpen(false)}
          buttonList={[
            {
              buttonType: BUTTON_TYPES.GREEN,
              size: 'l',
              withIcon: true,
              text: intl.formatMessage({ id: 'FavoriteListingsSlider.goToListing' }),
              // TODO - when new button component is added, change this to use NamedLink
              // name: 'ListingPage',
              // params: {
              //   id: focusListing?.id?.uuid,
              //   slug: createSlug(focusListing?.attributes.title),
              // },
              onClick: () => {
                window.open(
                  `${config.canonicalRootURL}/location/${focusListing?.attributes.title}/${focusListing?.attributes.publicData?.locationId}`,
                  '_blank'
                );
              },
            },
          ]}
          showBottomSheet={true}
        >
          <ListingCardNew
            className={css.listing}
            listing={focusListing}
            setActiveListing={() => null}
            onToggleFavoriteListing={onToggleFavoriteListing}
            onToggleCompanyFavoriteListing={onToggleCompanyFavoriteListing}
            isFavorite={favoriteListingsIds?.includes(focusListing?.id?.uuid)}
            isCompanyFavorite={companyFavoriteListingsIds?.includes(focusListing?.id?.uuid)}
            showCompanyFavoriteButton={showCompanyFavoriteButton}
            isFavoriteCard
            isShowCredits={isShowCredits}
            isActiveOnBottomSheet={isMobileOpen}
          />
        </BottomSheet>
      )}
    </section>
  ) : null;
};

FavoriteListingsSlider.propTypes = {
  intl: intlShape,
  title: string,
  listings: array,
  onToggleFavoriteListing: func,
  isCompanyFavorite: bool,
  id: string.isRequired,
  isControlledByRespaces: bool,
};

export default injectIntl(FavoriteListingsSlider);
