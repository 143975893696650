import React from 'react';
import classNames from 'classnames';

import { getLocale, setLocale } from '../../util/localeHelper';
import { LANGUAGE_CODES } from '../../util/modernmt';

import css from './LanguageSelector.module.scss';

const LanguageSelector = (props) => {
  const { currentPage, isScrolled = true } = props;
  const selectedLocale = getLocale();

  const capitalize = (str) => `${str.charAt(0).toUpperCase()}${str.substr(1)}`;

  return (
    <div className={css.selector}>
      {LANGUAGE_CODES.LIST.map((lang) => (
        <div
          key={`language_selector_${lang}`}
          onClick={() => {
            setLocale(lang);
          }}
          className={classNames(css.language, {
            [css.active]: selectedLocale === lang,
            [css.white]: currentPage === 'LandingPage' && !isScrolled,
          })}
        >
          {capitalize(lang)}
        </div>
      ))}
    </div>
  );
};

export default LanguageSelector;
