import React, { useMemo } from 'react';
import css from './DropdownSelect.module.scss';
import classNames from 'classnames';
import { useBoolean } from '../../util/hooks';
import { IconCheronDown } from '../../components';
import { string, arrayOf, shape, func } from 'prop-types';

const DropdownSelect = (props) => {
  const { className, placeholder, options, selectedOption, onSelect } = props;
  const [isOptionsOpen, setOptionsOpen] = useBoolean();
  const classes = classNames(css.container, className);
  const headerClasses = classNames(css.headerWrapper, {
    [css.selected]: selectedOption,
    [css.focus]: isOptionsOpen,
  });
  const displaySelectedOption = useMemo(() => {
    return options.find((option) => option.value === selectedOption)?.label;
  }, [options, selectedOption]);
  const onOptionClick = (value) => () => {
    onSelect(value);
    setOptionsOpen.off();
  };

  return (
    <div className={classes}>
      <div className={headerClasses} onClick={setOptionsOpen.toggle}>
        <span>
          {selectedOption ? displaySelectedOption : placeholder ? placeholder : options[0].label}
        </span>
        <div className={css.cheronDown}>
          <IconCheronDown />
        </div>
      </div>
      {isOptionsOpen && (
        <div className={css.selectOptionsWrapper}>
          {options.map((option, index) => (
            <div key={index} className={css.option} onClick={onOptionClick(option.value)}>
              {option.label}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

DropdownSelect.defaultProps = {};

DropdownSelect.propTypes = {
  classNames: string,
  placeholder: string,
  options: arrayOf(
    shape({
      label: string.isRequired,
      value: string.isRequired,
    })
  ).isRequired,
  selectedOption: string,
  onSelect: func.isRequired,
};
export default DropdownSelect;
