import React from 'react';
import ContentLoader from 'react-content-loader';

import css from './PageLoading.module.scss';

const PageLoading = () => (
  <div className={css.wrapper}>
    <ContentLoader className={css.loader} viewBox="0 0 1345 1812" width="1345" height="1812">
      <rect x="48" width="1345" height="490" />
      <rect y="565" width="350" height="36" rx="4" />
      <rect y="733" width="350" height="30" rx="4" />
      <rect y="1317" width="350" height="30" rx="4" />
      <rect y="1464" width="350" height="30" rx="4" />
      <rect y="1365" width="300" height="24" rx="4" />
      <rect y="901" width="350" height="30" rx="4" />
      <rect y="781" width="750" height="60" rx="4" />
      <rect y="1512" width="640" height="300" />
      <rect y="625" width="420" height="16" rx="4" />
      <rect y="657" width="280" height="16" rx="4" />
      <path d="M26 974H122V1070H26V974Z" />
      <rect x="141" y="997" width="140" height="16" rx="4" />
      <rect x="620" y="997" width="130" height="16" rx="4" />
      <rect x="650" y="1047" width="100" height="16" rx="4" />
      <rect x="141" y="1023" width="330" height="24" rx="4" />
      <rect x="296" y="997" width="90" height="16" rx="4" />
      <path d="M26 1136H122V1232H26V1136Z" />
      <rect x="141" y="1159" width="140" height="16" rx="4" />
      <rect x="620" y="1159" width="130" height="16" rx="4" />
      <rect x="650" y="1209" width="100" height="16" rx="4" />
      <rect x="141" y="1185" width="330" height="24" rx="4" />
      <rect x="296" y="1159" width="90" height="16" rx="4" />
    </ContentLoader>
  </div>
);

export default PageLoading;
