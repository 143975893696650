import React, { useState } from 'react';
import { StaticPage, TopbarContainer } from '../../containers';
import {
  LayoutSingleColumn,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  Footer,
} from '../../components';

import css from './FAQPage.module.scss';
import { FormattedMessage, useIntl } from 'react-intl';

// ------------- TODO -----------------
// sort secondary accordion functionality
// add arrows + transitions for them, in css
//  investigate why the current transitions are jumpy

const FaqQuestion = ({ quest, index, hIndex }) => {
  return (
    <div className={css.faq + ' ' + (quest.open ? css.open : '')} key={index}>
      <div className={css.faqQuestion}>{quest.question}</div>
      <div className={css.faqAnswer}>{quest.answer}</div>
    </div>
  );
};

const FaqHeader = ({ faq, index, toggleHeader }) => {
  return (
    <div className={css.header + ' ' + (faq.open ? css.open : '')} key={index}>
      <div className={css.headerTitle} onClick={() => toggleHeader(index)}>
        {faq.heading}
      </div>
      <div className={css.headerQuestions}>
        {faq.questions.map((quest, i) => (
          <FaqQuestion quest={quest} index={i} hIndex={index} />
        ))}
      </div>
    </div>
  );
};

const FAQPage = () => {
  const intl = useIntl();
  const [faqs, setFaqs] = useState([
    {
      heading: <FormattedMessage id="FAQPage.guests.heading" />,
      questions: [
        {
          question: <FormattedMessage id="FAQPage.guests.questions.first" />,
          answer: (
            <FormattedMessage
              id="FAQPage.guests.answers.first"
              values={{
                a: (link) => <a href="/login">{link}</a>,
                first: (msg) => (
                  <>
                    <br />
                    {msg}
                  </>
                ),
                second: (msg) => (
                  <>
                    <br />
                    {msg}
                  </>
                ),
              }}
            />
          ),
          open: true,
        },
        {
          question: <FormattedMessage id="FAQPage.guests.questions.second" />,
          answer: (
            <FormattedMessage
              id="FAQPage.guests.answers.second"
              values={{
                strong: (msg) => (
                  <strong>
                    <br />
                    <br />
                    {msg}
                  </strong>
                ),
                search: (link) => <a href="/s">{link}</a>,
                mail: (link) => <a href="mailto:hello@respaces.co">{link}</a>,
                coworking: (msg) => <span>{msg}</span>,
                meeting: (msg) => <span>{msg}</span>,
                private: (msg) => <span>{msg}</span>,
              }}
            />
          ),
          open: true,
        },
        {
          question: <FormattedMessage id="FAQPage.guests.questions.third" />,
          answer: (
            <FormattedMessage
              id="FAQPage.guests.answers.third"
              values={{
                bullet: (msg) => (
                  <>
                    <br />
                    {msg}
                  </>
                ),
              }}
            />
          ),
          open: true,
        },
      ],
      open: false,
    },
    {
      heading: <FormattedMessage id="FAQPage.partners.heading" />,
      questions: [
        {
          question: <FormattedMessage id="FAQPage.partners.questions.first" />,
          answer: (
            <FormattedMessage
              id="FAQPage.partners.answers.first"
              values={{
                a: (link) => <a href="/listings">{link}</a>,
                p: (msg) => (
                  <>
                    <br />
                    <br />
                    <span>{msg}</span>
                  </>
                ),
              }}
            />
          ),
          open: true,
        },
        {
          question: <FormattedMessage id="FAQPage.partners.questions.second" />,
          answer: (
            <FormattedMessage
              id="FAQPage.partners.answers.second"
              values={{
                bullet: (msg) => (
                  <>
                    <br />
                    {msg}
                  </>
                ),
                p: (msg) => (
                  <>
                    <br />
                    <br />
                    {msg}
                  </>
                ),
              }}
            />
          ),
          open: true,
        },
      ],
      open: false,
    },
    {
      heading: <FormattedMessage id="FAQPage.contact.heading" />,
      questions: [
        {
          question: <FormattedMessage id="FAQPage.contact.questions.first" />,
          answer: (
            <FormattedMessage
              id="FAQPage.contact.answers.first"
              values={{
                a: (link) => <a href="mailto:hello@respaces.co">{link}</a>,
              }}
            />
          ),
          open: true,
        },
      ],
      open: false,
    },
  ]);

  const toggleHeader = (index) => {
    setFaqs(
      faqs.map((faq, i) => {
        if (i === index) {
          faq.open = !faq.open;
        } else {
          faq.open = false;
        }
        return faq;
      })
    );
  };

  let faqHeaders = faqs.map((faq, i) => (
    <FaqHeader faq={faq} index={i} toggleHeader={toggleHeader} />
  ));

  const faqData = [
    {
      question: intl.formatMessage({ id: 'FAQPage.guests.questions.first' }),
      answer: intl.formatMessage({ id: 'FAQPage.guests.answers.first' }),
    },
    {
      question: intl.formatMessage({ id: 'FAQPage.guests.questions.second' }),
      answer: intl.formatMessage({ id: 'FAQPage.guests.answers.second' }),
    },
    {
      question: intl.formatMessage({ id: 'FAQPage.guests.questions.third' }),
      answer: intl.formatMessage({ id: 'FAQPage.guests.answers.third' }),
    },
    {
      question: intl.formatMessage({ id: 'FAQPage.partners.questions.first' }),
      answer: intl.formatMessage({ id: 'FAQPage.partners.answers.first' }),
    },
    {
      question: intl.formatMessage({ id: 'FAQPage.partners.questions.second' }),
      answer: intl.formatMessage({ id: 'FAQPage.partners.answers.second' }),
    },
    {
      question: intl.formatMessage({ id: 'FAQPage.contact.questions.first' }),
      answer: intl.formatMessage({ id: 'FAQPage.contact.answers.first' }),
    },
  ];
  const schemaFAQData = faqData.map((x) => {
    return {
      '@type': 'Question',
      name: x.question,
      acceptedAnswer: {
        '@type': 'Answer',
        text: x.answer,
      },
    };
  });

  return (
    <StaticPage
      className={css.root}
      title="Frequently Asked Questions"
      description={intl.formatMessage({ id: 'FAQPage.schemaDescription' })}
      schema={{
        '@context': 'http://schema.org',
        '@type': 'FAQPage',
        description: 'Frequently asked questions at Respaces',
        name: 'Frequently asked questions',
        mainEntity: schemaFAQData,
      }}
    >
      <LayoutSingleColumn>
        <LayoutWrapperTopbar>
          <TopbarContainer currentPage="FAQPage" />
        </LayoutWrapperTopbar>
        <LayoutWrapperMain className={css.staticPageWrapper}>
          <h1 className={css.pageTitle}>Frequently Asked Questions</h1>

          {/* create expandable list -- then extract it as a separate component to reuse */}
          {/* create a list of expandable objects from an array of FAQ topics, then each has
          an expandable list from array of FAQs in that topic */}
          {/* make onclick for headings, and onclick function which gets elements, and if style.display == block,
          changes to none, vice versa. */}
          <div className={css.faqs}>{faqHeaders}</div>
        </LayoutWrapperMain>
        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </LayoutSingleColumn>
    </StaticPage>
  );
};

export default FAQPage;
