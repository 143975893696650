import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import classNames from 'classnames';
import { bool, func, node, oneOf, shape, string } from 'prop-types';
import { CSSTransition } from 'react-transition-group';

import { Avatars, BottomSheet } from '..';
import { isMobile } from '../../util/device';
import { AVATAR_SIZES } from '../Avatars/Avatars';

import './animation.scss';
import css from './InfoModal.module.scss';

export const INFO_MODAL_COLORS = {
  RED: 'red',
  GREEN: 'green',
  BLUE: 'blue',
};

INFO_MODAL_COLORS.LIST = [INFO_MODAL_COLORS.RED, INFO_MODAL_COLORS.GREEN, INFO_MODAL_COLORS.BLUE];

const CloseButton = ({ onClick }) => (
  <button onClick={onClick} className={css['modal-close-button']}>
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1 1L15 15" stroke="black" />
      <path d="M15 1L1 15" stroke="black" />
    </svg>
  </button>
);

const InfoModalBody = ({ onClose, color, left, right }) => (
  <>
    {!isMobile && <div className={css['modal-overlay']} onClick={onClose} />}
    <div className={css['modal-wrapper']}>
      <div className={classNames(css['modal-main'], css[color])}>
        <CloseButton onClick={onClose} />
        <div className={css['modal-left']}>
          {!isMobile && (
            <div className={css['modal-images']}>
              <Avatars
                avatars={left.images}
                size={AVATAR_SIZES.LARGE}
                avatarClassName={css.avatar}
              />
            </div>
          )}
          <h3 className={css['modal-title']}>{left.title}</h3>
          <p className={css['modal-text']}>{left.text}</p>
          <div className={css['modal-button-wrapper']}>{left.buttons}</div>
        </div>
        <div className={css['modal-right']}>
          {!isMobile && (
            <div className={css['modal-images']}>
              <Avatars
                avatars={right.images}
                size={AVATAR_SIZES.LARGE}
                avatarClassName={css.avatar}
              />
            </div>
          )}
          <h3 className={css['modal-title']}>{right.title}</h3>
          <p className={css['modal-text']}>{right.text}</p>
          <div className={css['modal-button-wrapper']}>{right.buttons}</div>
        </div>
      </div>
    </div>
  </>
);

const InfoModal = ({ isOpen, onClose, left, right, color }) => {
  useEffect(() => {
    if (isOpen && !isMobile && typeof document !== 'undefined') {
      document?.documentElement?.classList?.add('blockScroll');
    } else if (typeof document !== 'undefined') {
      document?.documentElement?.classList?.remove('blockScroll');
    }
  }, [isOpen, isMobile]);

  return isMobile ? (
    <BottomSheet isOpen={isOpen} onClose={onClose} buttonList={[]} showFooter={false} noPadding>
      <InfoModalBody onClose={onClose} color={color} left={left} right={right} />
    </BottomSheet>
  ) : (
    typeof document !== 'undefined' &&
      ReactDOM.createPortal(
        <CSSTransition in={isOpen} timeout={300} classNames="modal" unmountOnExit>
          <div className={css['modal-body']}>
            <InfoModalBody onClose={onClose} color={color} left={left} right={right} />
          </div>
        </CSSTransition>,
        document?.getElementById('portal-root')
      )
  );
};

InfoModal.defaultProps = {
  color: INFO_MODAL_COLORS.GREEN,
};

InfoModal.propTypes = {
  isOpen: bool.isRequired,
  onClose: func.isRequired,
  left: shape({
    title: string,
    text: string,
    buttons: node,
  }).isRequired,
  right: shape({
    title: string,
    text: string,
    buttons: node,
  }).isRequired,
  color: oneOf(INFO_MODAL_COLORS.LIST),
};

export default InfoModal;
