import { types as sdkTypes } from './util/sdkLoader';

const { LatLng, LatLngBounds } = sdkTypes;

// An array of locations to show in the LocationAutocompleteInput when
// the input is in focus but the user hasn't typed in any search yet.
//
// Each item in the array should be an object with a unique `id` (String) and a
// `predictionPlace` (util.types.place) properties.
export const defaultLocationSearches = [
  {
    id: 'default-stockholm',
    predictionPlace: {
      address: 'Stockholm',
      bounds: new LatLngBounds(new LatLng(59.663376, 18.19981), new LatLng(59.232464, 17.933788)),
      origin: new LatLng(59.329323, 18.068581),
    },
  },
  {
    id: 'default-gothenburg',
    predictionPlace: {
      address: 'Gothenburg',
      bounds: new LatLngBounds(new LatLng(58.055237, 12.776376), new LatLng(57.246697, 11.605508)),
      origin: new LatLng(57.70887, 11.97456),
    },
  },
  {
    id: 'default-malmo',
    predictionPlace: {
      address: 'Malmö',
      bounds: new LatLngBounds(new LatLng(55.639033, 13.152245), new LatLng(55.547049, 12.944725)),
      origin: new LatLng(55.60498, 13.003822),
    },
  },
  {
    id: 'default-uppsala',
    predictionPlace: {
      address: 'Uppsala',
      bounds: new LatLngBounds(new LatLng(59.915817, 17.699794), new LatLng(59.80615, 17.571979)),
      origin: new LatLng(59.85833, 17.65),
    },
  },
];

export const defaultLocationBounds = new LatLngBounds(
  new LatLng(66.07380721, 23.11029511),
  new LatLng(55.11320652, 7.66319966)
);

export const cityAreas = [
  {
    id: 'innanforTullarna',
    parent: 'Stockholm',
    predictionPlace: {
      address: 'Innanför tullarna',
      bounds: new LatLngBounds(
        new LatLng(59.36534683, 18.11389368),
        new LatLng(59.30292188, 18.00042551)
      ),
      origin: new LatLng(59.342675, 18.0385799),
    },
  },
  {
    id: 'stockholmCity',
    parent: 'Stockholm',
    predictionPlace: {
      address: 'Stockholm city',
      bounds: new LatLngBounds(
        new LatLng(59.34014175, 18.07370072),
        new LatLng(59.33290820000001, 18.0646885)
      ),
      origin: new LatLng(59.33290820000001, 18.0646885),
    },
  },
  {
    id: 'norrmalm',
    parent: 'Stockholm',
    predictionPlace: {
      address: 'Norrmalm',
      bounds: new LatLngBounds(
        new LatLng(59.34901747, 18.0815319),
        new LatLng(59.32060724, 18.03827324)
      ),
      origin: new LatLng(59.3346342, 18.060526),
    },
  },
  {
    id: 'vasastadenSthlm',
    parent: 'Stockholm',
    predictionPlace: {
      address: 'Vasastaden',
      bounds: new LatLngBounds(
        new LatLng(59.35599797569959, 18.06531980683016),
        new LatLng(59.33545001494941, 18.02410990783225)
      ),
      origin: new LatLng(59.342675, 18.0385799),
    },
  },
  {
    id: 'ostermalm',
    parent: 'Stockholm',
    predictionPlace: {
      address: 'Östermalm',
      bounds: new LatLngBounds(
        new LatLng(59.35376244, 18.11175365),
        new LatLng(59.32535617, 18.06849498)
      ),
      origin: new LatLng(59.3376963, 18.0900385),
    },
  },
  {
    id: 'kungsholmen',
    parent: 'Stockholm',
    predictionPlace: {
      address: 'Kungsholmen',
      bounds: new LatLngBounds(
        new LatLng(59.34106808, 18.05584157),
        new LatLng(59.32373179, 17.98915112)
      ),
      origin: new LatLng(59.329323, 18.068581),
    },
  },
  {
    id: 'sodermalm',
    parent: 'Stockholm',
    predictionPlace: {
      address: 'Södermalm',
      bounds: new LatLngBounds(
        new LatLng(59.32152153729898, 18.10715306072207),
        new LatLng(59.30309202921768, 18.02587132215621)
      ),
      origin: new LatLng(59.31252679999999, 18.0616188),
    },
  },
  {
    id: 'kista',
    parent: 'Stockholm',
    predictionPlace: {
      address: 'Kista',
      bounds: new LatLngBounds(
        new LatLng(59.41453793019608, 17.96962599433441),
        new LatLng(59.39109594927741, 17.91618008849471)
      ),
      origin: new LatLng(59.4024341, 17.9464824),
    },
  },
  {
    id: 'solna',
    parent: 'Stockholm',
    predictionPlace: {
      address: 'Solna',
      bounds: new LatLngBounds(
        new LatLng(59.39785085, 18.05397617),
        new LatLng(59.34108837, 17.96745884)
      ),
      origin: new LatLng(59.36887909999999, 18.0084334),
    },
  },
];
