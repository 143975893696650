import config from '../config';
import { queryAllPage } from '../containers/CompanyBookingsPage/CompanyBookingsPage.helper';
import StatusModel from '../models/StatusModel';
import { assignListingHazura, getLocationsHazura, unassignListingHazura } from '../util/api';
import { denormalisedResponseEntities } from '../util/data';
import { locationBounds } from '../util/googleMaps';
import { TRANSITIONS } from '../util/transaction';
import { addMarketplaceEntities } from './marketplaceData.duck';

export const SET_LOCATIONS = 'app/Dashboard/SET_LOCATIONS';
export const SET_UNASSIGNED_LISTINGS = 'app/Dashboard/SET_UNASSIGNED_LISTINGS';
export const TOGGLE_LOCATIONS_LOADING = 'app/Dashboard/TOGGLE_LOCATIONS_LOADING';
export const UPDATE_CREATE_LOCATION_FIELDS = 'app/Dashboard/UPDATE_CREATE_LOCATION_FIELDS';
export const TOGGLE_CREATE_LOCATION_LOADING = 'app/Dashboard/TOGGLE_CREATE_LOCATION_LOADING';
export const UPDATE_SELECTED_LOCATION = 'app/Dashboard/UPDATE_SELECTED_LOCATION';
export const UPDATE_SELECTED_LOCATION_INDEX = 'app/Dashboard/UPDATE_SELECTED_LOCATION_INDEX';
export const UPDATE_CURRENT_TAB = 'app/Dashboard/UPDATE_CURRENT_TAB';
export const TOGGLE_ROW_LOADING = 'app/Dashboard/TOGGLE_ROW_LOADING';
export const TOGGLE_PARTNER_BOOKINGS_LOADING = 'app/Dashboard/TOGGLE_PARTNER_BOOKINGS_LOADING';
export const SET_PARTNER_BOOKINGS = 'app/Dashboard/SET_PARTNER_BOOKINGS';
export const SET_PARTNER_PAGINATION_INFO = 'app/Dashboard/SET_PARTNER_PAGINATION_INFO';

export const TABS = {
  LOCATIONS: 'overview',
  LISTINGS: 'listings',
  CREATE: 'create',
};

const initialState = {
  currentTab: TABS.LOCATIONS,
  locations: [],
  locationsLoading: true,
  createLocationFields: {
    id: '',
    address: '',
    geolocation: {},
    name: '',
    host: '1',
    description_en: '',
    description_sv: '',
    opening_hours: {},
    images: [],
  },
  rowLoading: {
    listing: null,
    unassignedListing: null,
  },
  unassignedListings: [],
  selectedLocation: {},
  selectedLocationIndex: -1,
  createLocationLoading: false,
  partnerBookingsLoading: false,
  partnerBookings: [],
  partnerBookingsInfo: {},
};

export const setLocations = (payload) => ({ type: SET_LOCATIONS, payload });
export const setUnassignedListings = (payload) => ({ type: SET_UNASSIGNED_LISTINGS, payload });
export const toggleLocationsLoading = (payload) => ({ type: TOGGLE_LOCATIONS_LOADING, payload });
export const toggleRowLoading = (payload) => ({ type: TOGGLE_ROW_LOADING, payload });
export const updateCreateLocationFields = (payload) => ({
  type: UPDATE_CREATE_LOCATION_FIELDS,
  payload,
});
export const toggleCreateLocationLoading = (payload) => ({
  type: TOGGLE_CREATE_LOCATION_LOADING,
  payload,
});
export const updateSelectedLocation = (payload) => ({ type: UPDATE_SELECTED_LOCATION, payload });
export const updateSelectedLocationIndex = (payload) => ({
  type: UPDATE_SELECTED_LOCATION_INDEX,
  payload,
});
export const updateCurrentTab = (payload) => ({ type: UPDATE_CURRENT_TAB, payload });

export const togglePartnerBookingsLoading = (payload) => ({
  type: TOGGLE_PARTNER_BOOKINGS_LOADING,
  payload,
});

export const setPartnerBookings = (payload) => ({ type: SET_PARTNER_BOOKINGS, payload });
export const setPartnerPaginationInfo = (payload) => ({
  type: SET_PARTNER_PAGINATION_INFO,
  payload,
});

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;

  switch (type) {
    case SET_LOCATIONS:
      return {
        ...state,
        locations: [...payload],
      };
    case TOGGLE_ROW_LOADING:
      return {
        ...state,
        rowLoading: {
          ...state.rowLoading,
          ...payload,
        },
      };
    case SET_UNASSIGNED_LISTINGS:
      return {
        ...state,
        unassignedListings: [...payload],
      };
    case TOGGLE_LOCATIONS_LOADING:
      return {
        ...state,
        locationsLoading: payload,
      };
    case TOGGLE_CREATE_LOCATION_LOADING:
      return {
        ...state,
        createLocationLoading: payload,
      };
    case UPDATE_CREATE_LOCATION_FIELDS:
      return {
        ...state,
        createLocationFields: { ...state.createLocationFields, ...payload },
      };
    case UPDATE_SELECTED_LOCATION:
      return {
        ...state,
        selectedLocation: payload,
      };
    case UPDATE_SELECTED_LOCATION_INDEX:
      return {
        ...state,
        selectedLocationIndex: payload,
      };
    case UPDATE_CURRENT_TAB:
      return {
        ...state,
        currentTab: payload,
      };
    case SET_PARTNER_BOOKINGS:
      return {
        ...state,
        partnerBookings: payload,
      };
    case SET_PARTNER_PAGINATION_INFO:
      return {
        ...state,
        partnerBookingsInfo: payload,
      };
    case TOGGLE_PARTNER_BOOKINGS_LOADING:
      return {
        ...state,
        partnerBookingsLoading: payload,
      };
    default:
      return state;
  }
}

export const getLocations =
  ({ ownerId, locationId }) =>
  async (dispatch, getState, sdk) => {
    try {
      dispatch(toggleLocationsLoading(true));
      const { locations, unassignedListings } = await getLocationsHazura({
        ...(!!ownerId && { ownerIds: [ownerId] }),
        ...(!!locationId && { locationIds: [locationId] }),
        locationStatuses: ['published', 'draft', 'closed'],
        listingStatuses: ['published', 'draft', 'closed', 'pendingApproval'],
      });
      dispatch(setLocations(locations));
      dispatch(setUnassignedListings(unassignedListings));
      dispatch(toggleLocationsLoading(false));
    } catch (error) {
      console.error(error);
    }
  };

export const assignListing =
  ({ locationId, listingId }) =>
  async (dispatch, getState, sdk) => {
    dispatch(toggleRowLoading({ unassignedListing: listingId }));
    const {
      Dashboard: { locations, unassignedListings },
    } = getState();
    const locationIndex = locations.findIndex((l) => l.id === locationId);

    const response = await assignListingHazura({
      locationId,
      listingId,
      locationData: {
        address: locations[locationIndex].address,
        geolocation: locations[locationIndex].geolocation,
        bounds: locationBounds(
          locations[locationIndex].geolocation,
          config.maps.search.currentLocationBoundsDistance
        ),
        host: locations[locationIndex].host,
      },
    });

    if ('insert_locations_listings_one' in response?.data) {
      const listingIndex = unassignedListings.findIndex((l) => l.id.uuid === listingId);
      locations[locationIndex].listings_rel.push({ sharetribe_id: listingId });
      const [newAssignedListing] = unassignedListings.splice(listingIndex, 1);
      locations[locationIndex].listings.push(newAssignedListing);

      await dispatch(updateLocationStatus(locationIndex));
      dispatch(setLocations(locations));
      dispatch(setUnassignedListings(unassignedListings));
    }

    dispatch(toggleRowLoading({ unassignedListing: null }));
  };

/**
 * @param listingId {String}
 * */
export const unassignListing =
  ({ listingId }) =>
  async (dispatch, getState, sdk) => {
    dispatch(toggleRowLoading({ listing: listingId }));
    const {
      Dashboard: { locations, selectedLocationIndex, unassignedListings },
    } = getState();

    const listingIndex = locations[selectedLocationIndex].listings.findIndex(
      (l) => l.id.uuid === listingId
    );

    const response = await unassignListingHazura({
      locationId: locations[selectedLocationIndex].id,
      listingId,
    });

    if ('delete_locations_listings' in response?.data) {
      const [unassignedListing] = locations[selectedLocationIndex].listings.splice(listingIndex, 1);
      locations[selectedLocationIndex].listings_rel = locations[
        selectedLocationIndex
      ].listings_rel.filter((l) => l.sharetribe_id !== listingId);
      unassignedListings.push(unassignedListing);

      await dispatch(updateLocationStatus(selectedLocationIndex));
      dispatch(setLocations(locations));
      dispatch(setUnassignedListings(unassignedListings));
    }

    dispatch(toggleRowLoading({ listing: null }));
  };

/**
 * @param listingId {String}
 * */
export const openListing = (listingId) => async (dispatch, getState, sdk) => {
  const inLocation = getState().Dashboard.selectedLocationIndex >= 0;
  const rowLoading = inLocation ? 'listing' : 'unassignedListing';
  dispatch(toggleRowLoading({ [rowLoading]: listingId }));

  const response = await sdk.ownListings.open({ id: listingId }, { expand: true });
  await dispatch(
    updateListingStatus({ listingId, newStatus: response.data.data.attributes.state })
  );

  dispatch(toggleRowLoading({ [rowLoading]: null }));
};

/**
 * @param listingId {String}
 * */
export const closeListing = (listingId) => async (dispatch, getState, sdk) => {
  const inLocation = getState().Dashboard.selectedLocationIndex >= 0;
  const rowLoading = inLocation ? 'listing' : 'unassignedListing';
  dispatch(toggleRowLoading({ [rowLoading]: listingId }));

  const response = await sdk.ownListings.close({ id: listingId }, { expand: true });
  await dispatch(
    updateListingStatus({ listingId, newStatus: response.data.data.attributes.state })
  );

  dispatch(toggleRowLoading({ [rowLoading]: null }));
};

/**
 * @param index {Number}
 * */
const updateLocationStatus = (index) => async (dispatch, getState, sdk) => {
  const {
    Dashboard: { locations },
  } = getState();

  const noListings =
    locations[index].listings.length === 0 && locations[index].listings_rel.length === 0;
  const noActiveListings = locations[index].listings.every(
    (l) => l.attributes.state !== StatusModel.PUBLISHED.id
  );

  locations[index].status =
    noListings || noActiveListings ? StatusModel.CLOSED.id : StatusModel.PUBLISHED.id;
  dispatch(setLocations(locations));
};

/**
 * @param listingId {String}
 * @param newStatus {String | Null}
 * */
const updateListingStatus =
  ({ listingId, newStatus }) =>
  async (dispatch, getState, sdk) => {
    const {
      Dashboard: { locations, selectedLocationIndex, unassignedListings },
    } = getState();

    const inLocation = selectedLocationIndex >= 0;

    if (inLocation) {
      const listingIndex = locations[selectedLocationIndex].listings.findIndex(
        (l) => l.id.uuid === listingId
      );

      locations[selectedLocationIndex].listings[listingIndex].attributes.state = newStatus;
      await dispatch(updateLocationStatus(selectedLocationIndex));
      await dispatch(setLocations(locations));
    } else {
      const listingIndex = unassignedListings.findIndex((l) => l.id.uuid === listingId);
      unassignedListings[listingIndex].attributes.state = newStatus;
      dispatch(setUnassignedListings(unassignedListings));
    }
  };

const splitToChunks = (array) => {
  const CHUNK_SIZE = 25;

  const result = [];

  for (let i = 0; i < array.length; i += CHUNK_SIZE) {
    const chunk = array.slice(i, i + CHUNK_SIZE);
    result.push(chunk);
  }

  return result;
};

export const getPartnerBookings = () => async (dispatch, getState, sdk) => {
  dispatch(togglePartnerBookingsLoading(true));
  const apiParams = {
    onlyFilter: 'sale',
    lastTransitions: TRANSITIONS,
    include: [
      'provider',
      'provider.profileImage',
      'customer',
      'customer.profileImage',
      'booking',
      'listing',
    ],
    'fields.transaction': [
      'lastTransition',
      'lastTransitionedAt',
      'transitions',
      'payinTotal',
      'payoutTotal',
      'protectedData',
    ],
    'fields.user': ['profile.displayName', 'profile.abbreviatedName'],
    'fields.image': ['variants.square-small', 'variants.square-small2x'],
  };

  try {
    const response = await queryAllPage({
      sdk: sdk.transactions,
      query: apiParams,
      perPage: 100,
    });

    const txList = [];

    response.forEach((chunk) => {
      dispatch(addMarketplaceEntities(chunk));
      const denormalizedChunk = [...splitToChunks(denormalisedResponseEntities(chunk))];
      denormalizedChunk.forEach((c) => txList.push(c));
    });

    const pagination = {
      totalItems: txList.length * 25,
      totalPages: txList.length,
      page: 1,
      perPage: 25,
    };

    dispatch(setPartnerBookings(txList));
    dispatch(setPartnerPaginationInfo(pagination));
    dispatch(togglePartnerBookingsLoading(false));
  } catch (e) {
    console.log(e);
    dispatch(togglePartnerBookingsLoading(false));
  }
};
