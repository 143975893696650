import classNames from 'classnames';
import { array, arrayOf, bool, shape, string } from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import { priceWithoutVAT } from '../../../util/currency';
import { getUnitTranslationKey } from '../../../util/data';
import { injectIntl } from '../../../util/reactIntl';

import { Badge, FavoriteListingToggle } from '../../../components_new';
import { BADGE_COLORS } from '../../../components_new/Badge/Badge';

import commonCss from '../Common.module.scss';
import css from './SectionHeader.module.scss';

const SectionHeader = ({
  intl,
  listing: {
    attributes: { title, price },
  },
  listing,
  category: propsCategory,
  categoryOptions,
  descriptionKeywords,
  isShowCredits,
  credits,
  isFavorite,
  isCompanyFavorite,
  isCompanyAdmin,
  currentUser,
  requireShowing,
  isFixedOffice,
}) => {
  const category = categoryOptions.find((c) => c.key === propsCategory);

  const {
    minSeats,
    maxSeats,
    priceMax,
    requestPrice,
    vatPercentage = 25,
  } = listing.attributes.publicData || {};

  const priceMinusVAT = priceWithoutVAT(price, vatPercentage, intl);
  const priceMaxMinusVAT = priceMax ? priceMax / (1 + vatPercentage / 100) : null;

  const seatsInfo = () => {
    const numberOfGuests = `${minSeats ? `${minSeats} - ` : ''}${maxSeats}`;
    if (maxSeats && category.key !== 'coworking') {
      return (
        <span className={css.seatsText}>
          {category.key === 'fixed' &&
            !minSeats &&
            intl.formatMessage({ id: 'ListingPage.header.seats.upTo' })}
          <FormattedMessage id="ListingPage.header.seats" values={{ seats: numberOfGuests }} />
        </span>
      );
    } else {
      return '';
    }
  };

  return (
    <section className={css.header}>
      <span className={css.hiddenOnMobile}>
        <ul className={commonCss.keywords}>
          {descriptionKeywords[0] &&
            descriptionKeywords.map((keyword, i) => (
              <li key={`${i}keyword_${keyword}`} className={commonCss.keywords}>
                <FormattedMessage id={keyword.labelId}>
                  {(id) => (
                    <span className={css.keyword} key={`keyword_${keyword}`}>
                      {id}
                    </span>
                  )}
                </FormattedMessage>
                {i < descriptionKeywords.length - 1 && i < 2 && <span className={css.separator} />}
              </li>
            ))}
        </ul>
      </span>
      {/* TODO: Uncomment when rating system will be implemented */}
      {/*<div className={css.rating}>
        <span className={css.number}>4,75 </span> (7
        <span className={css.hiddenOnMobile}> omdomen</span>)
      </div>*/}
      {title && category && (
        <div className={css.bottom}>
          <h2 className={css.title}>{title}</h2>
          <p>
            <FormattedMessage id={category.labelId}>
              {(id) => <span className={css.category}>{id}</span>}
            </FormattedMessage>
            {seatsInfo()}
          </p>
          {isCompanyFavorite && (
            <Badge
              text={intl.formatMessage({ id: 'ListingCard.companyFavoriteBadge' })}
              color={BADGE_COLORS.BLACK}
              uppercase
            />
          )}
        </div>
      )}
      {currentUser && (
        <div className={css.favoriteButton}>
          <FavoriteListingToggle
            listingId={listing.id.uuid}
            showCompanyFavoriteButton={isCompanyAdmin}
            isFavorite={isFavorite}
            isCompanyFavorite={isCompanyFavorite}
            isOnListingPage
          />
        </div>
      )}
      <div className={classNames(css.priceInfoContainer, requireShowing && css.requireShowing)}>
        {!isFixedOffice && (
          <span>
            {isShowCredits ? (
              <FormattedMessage id="ListingPage.header.value" />
            ) : (
              <FormattedMessage id="ListingPage.header.price" />
            )}
          </span>
        )}
        {price && !requestPrice ? (
          <span>
            {isShowCredits && !isFixedOffice
              ? credits
              : `${
                  !priceMax ? intl.formatMessage({ id: 'ListingPage.header.priceFromLabel' }) : ''
                }${priceMinusVAT.amount / 100} ${
                  priceMaxMinusVAT ? `- ${priceMaxMinusVAT / 100}` : ''
                } `}
            <span className={css.priceUnit}>
              <FormattedMessage id={getUnitTranslationKey(listing)} />
            </span>
            {isFixedOffice && (
              <span className={css.vat}>
                {intl.formatMessage({ id: 'ListingPage.header.excludingVAT' })}
              </span>
            )}
          </span>
        ) : (
          <span>{intl.formatMessage({ id: 'ListingPage.header.priceOnRequestMessage' })}</span>
        )}
      </div>
    </section>
  );
};

SectionHeader.propTypes = {
  categoryOptions: arrayOf(
    shape({
      hideFromFilters: bool,
      hideFromListingsInfo: bool,
      key: string,
      label: string,
      labelId: string,
    })
  ),
  listing: shape({
    attributes: shape({
      title: string,
    }),
  }),
  category: string,
  descriptionKeywords: array,
  requireShowing: bool,
};

export default injectIntl(SectionHeader);
