import React, { useCallback } from 'react';
import {
  BookingDateRangeFilter,
  BookingDateRangeLengthFilter,
  PriceFilter,
  KeywordFilter,
  SelectSingleFilter,
  SelectMultipleFilter,
  RangeFilter,
} from '../../components';
import { updateFilters } from '../../ducks/SearchPageNew.duck';
import { useDispatch } from 'react-redux';

/**
 * FilterComponent is used to map configured filter types
 * to actual filter components
 */
/** Currently we only use selectsingle and multiple filters, the others are, for now, unused  */
const FilterComponent = (props) => {
  const { idPrefix, filterConfig, fixedOfficeSubFilter, initialValues, stateCategories, ...rest } =
    props;
  const { id, type, queryParamNames, label, config } = filterConfig;
  const { liveEdit, showAsPopup } = rest;
  const dispatch = useDispatch();

  const fixedOfficeInitialValues =
    initialValues && initialValues.category === 'fixed' && initialValues.fixedOfficeType
      ? initialValues.fixedOfficeType
      : null;

  const useHistoryPush = liveEdit || showAsPopup;
  const prefix = idPrefix || 'SearchPage';
  const componentId = `${prefix}.${id.toLowerCase()}`;
  const name = id.replace(/\s+/g, '-').toLowerCase();

  const handleSubmit = useCallback(
    (params) => {
      dispatch(updateFilters(params));
    },
    [dispatch]
  );

  const searchFilterClass = 'searchFilters';
  switch (type) {
    case 'SelectSingleFilter': {
      return (
        <SelectSingleFilter
          id={componentId}
          label={label}
          queryParamNames={queryParamNames}
          initialValues={initialValues}
          fixedOfficeInitialValues={fixedOfficeInitialValues}
          onSelect={handleSubmit}
          searchFilterClass={searchFilterClass}
          {...config}
          {...rest}
        />
      );
    }
    case 'SelectMultipleFilter': {
      return (
        <SelectMultipleFilter
          id={componentId}
          label={label}
          name={name}
          queryParamNames={queryParamNames}
          initialValues={initialValues}
          onSubmit={handleSubmit}
          searchFilterClass={searchFilterClass}
          stateCategories={stateCategories}
          {...config}
          {...rest}
        />
      );
    }
    case 'BookingDateRangeFilter': {
      return (
        <BookingDateRangeFilter
          id={componentId}
          label={label}
          queryParamNames={queryParamNames}
          initialValues={initialValues}
          onSubmit={handleSubmit}
          searchFilterClass={searchFilterClass}
          {...config}
          {...rest}
        />
      );
    }
    case 'BookingDateRangeLengthFilter': {
      return (
        <BookingDateRangeLengthFilter
          id={componentId}
          label={label}
          queryParamNames={queryParamNames}
          initialValues={initialValues}
          onSubmit={handleSubmit}
          dateRangeLengthFilter={filterConfig}
          searchFilterClass={searchFilterClass}
          {...rest}
        />
      );
    }
    case 'PriceFilter': {
      return (
        <PriceFilter
          id={componentId}
          label={label}
          queryParamNames={queryParamNames}
          initialValues={initialValues}
          onSubmit={handleSubmit}
          searchFilterClass={searchFilterClass}
          {...config}
          {...rest}
          isSearchPage
        />
      );
    }
    case 'RangeFilter': {
      return (
        <RangeFilter
          id={componentId}
          label={label}
          queryParamNames={queryParamNames}
          initialValues={initialValues}
          onSubmit={handleSubmit}
          searchFilterClass={searchFilterClass}
          {...config}
          {...rest}
        />
      );
    }
    case 'KeywordFilter':
      return (
        <KeywordFilter
          id={componentId}
          label={label}
          name={name}
          queryParamNames={queryParamNames}
          initialValues={initialValues}
          onSubmit={handleSubmit}
          searchFilterClass={searchFilterClass}
          {...config}
          {...rest}
        />
      );
    default:
      return null;
  }
};

export default FilterComponent;
