import React from 'react';
import moment from 'moment';
import classNames from 'classnames';
import { injectIntl } from 'react-intl';
import { arrayOf, bool, shape, string } from 'prop-types';

import { Avatars, ButtonNew } from '../../../index';
import { BUTTON_SIZES, BUTTON_TYPES, LINK_COLORS } from '../../../ButtonNew/ButtonNew';

import BookingCardTitle from './BookingCardTitle';

import css from './BookingCard.module.scss';

const BookingSummaryCard = ({
  address,
  transactionId,
  listingId,
  listingName,
  listingCategory,
  locationId,
  locationName,
  times,
  users,
  isFilled,
  onJoin,
  searchParams,
  intl,
}) => {
  const formattedStartTime =
    typeof times.startTime === 'string'
      ? times.startTime
      : moment(new Date(times.startTime * 1000)).format('HH:mm');
  const formattedEndTime =
    typeof times.endTime === 'string'
      ? times.endTime
      : moment(new Date(times.endTime * 1000)).format('HH:mm');

  const goToOrderPage = () => {
    const routeString = `/order/${transactionId}/details`;
    window.open(routeString, '_blank').focus();
  };

  return (
    <div className={classNames(css.summaryCard, isFilled && css.filled)}>
      <div onClick={onJoin} className={css.overlayForClick} />
      <div className={css.bookingInfo}>
        <BookingCardTitle
          listingId={listingId}
          listingName={listingName}
          locationId={locationId}
          locationName={locationName}
          searchParams={searchParams}
          isNotSchedule={listingCategory !== 'schedule'}
        />
        <div className={css.flex}>
          {isFilled ? (
            <p className={css.listingType}>{address}</p>
          ) : (
            listingCategory !== 'schedule' && (
              <>
                <p className={css.listingType}>
                  {intl.formatMessage({
                    id: `MarketplaceConfig.filters.category.${listingCategory}`,
                  })}
                </p>
                {listingCategory === 'meeting' && (
                  <span className={css.times}>
                    {formattedStartTime}-{formattedEndTime}
                  </span>
                )}
              </>
            )
          )}
        </div>
      </div>
      <div className={css.avatarsWrapper}>
        <Avatars avatars={users} avatarClassName={css.avatar} />
      </div>
      <div className={classNames(css.btnWrapper, css.aboveOverlayForClick)}>
        {isFilled && listingCategory !== 'schedule' ? (
          <ButtonNew
            type={BUTTON_TYPES.INLINE}
            size={BUTTON_SIZES.X_SMALL}
            linkColor={LINK_COLORS.WHITE}
            onClick={goToOrderPage}
            textLight
            underlined
          >
            {intl.formatMessage({ id: 'TeamSchedule.sidebar.editBooking' })}
          </ButtonNew>
        ) : (
          listingCategory !== 'schedule' && (
            <ButtonNew type={BUTTON_TYPES.INLINE} linkColor={LINK_COLORS.GREEN} onClick={onJoin}>
              {intl.formatMessage({ id: 'TeamSchedule.moreButton' })}
            </ButtonNew>
          )
        )}
      </div>
      {isFilled && listingCategory !== 'schedule' && (
        <div className={css.badgeWrapper}>
          <div className={css.badge}>
            {intl.formatMessage({ id: `MarketplaceConfig.filters.category.${listingCategory}` })}
          </div>
        </div>
      )}
    </div>
  );
};

BookingSummaryCard.propTypes = {
  id: string.isRequired,
  listingName: string.isRequired,
  listingCategory: string.isRequired,
  users: arrayOf(
    shape({
      abbreviatedName: string,
      avatarURL: string,
      firstName: string,
      surname: string,
      userId: string,
    })
  ).isRequired,
  isFilled: bool.isRequired,
};

export default injectIntl(BookingSummaryCard);
