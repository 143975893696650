import React from 'react';
import classNames from 'classnames';
import { bool } from 'prop-types';

import css from './Separator.module.scss';

const Separator = ({ isHidden }) => (
  <div className={classNames(css.separator, isHidden && css.hidden)} />
);

Separator.propTypes = {
  isHidden: bool.isRequired,
};

export default Separator;
