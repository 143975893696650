import axios from 'axios';
import { updateFilters } from '../../ducks/SearchPageNew.duck';

// ================ Action types ================ //

export const FETCH_DATA_REQUEST = 'app/SEOLandingPage/FETCH_DATA_REQUEST';
export const FETCH_DATA_SUCCESS = 'app/SEOLandingPage/FETCH_DATA_SUCCESS';
export const FETCH_DATA_ERROR = 'app/SEOLandingPage/FETCH_DATA_ERROR';

// ================ Reducer ================ //

const initialState = {
  fetchInProgress: false,
  fetchDataError: null,
  pageData: null,
};

export default function seoLandingPageReducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case FETCH_DATA_REQUEST:
      return { ...state, fetchInProgress: true, fetchDataError: null };
    case FETCH_DATA_SUCCESS: {
      return {
        ...state,
        fetchInProgress: false,
        pageData: payload,
      };
    }
    case FETCH_DATA_ERROR:
      console.error(payload); // eslint-disable-line
      return { ...state, fetchInProgress: false, fetchDataError: payload };

    default:
      return state;
  }
}

// ================ Action creators ================ //

const fetchDataRequest = () => ({ type: FETCH_DATA_REQUEST });
const fetchDataSuccess = (response) => ({
  type: FETCH_DATA_SUCCESS,
  payload: response,
});
const fetchDataError = (response) => ({
  type: FETCH_DATA_ERROR,
  payload: response,
});

// ================ Thunks ================ //

export const loadData = (params) => async (dispatch, getState, sdk) => {
  const { id: slug } = params;
  dispatch(fetchDataRequest());
  try {
    const strapiResponse = await axios.get(
      `https://cms.respaces.co/seo-landing-pages?slug=${slug}`
    );
    const data = strapiResponse.data[0];
    dispatch(fetchDataSuccess(data));

    const title = data?.page_title_en?.toLowerCase();
    let parsedCategory;
    if (title && title.includes('meeting')) {
      parsedCategory = 'meeting';
    } else if (title && (title.includes('coworking') || title.includes('co-working'))) {
      parsedCategory = 'coworking';
    } else if (title && title.includes('office')) {
      parsedCategory = 'fixed';
    }
    if (!!parsedCategory) {
      dispatch(updateFilters({ category: parsedCategory }));
    }

    return data;
  } catch (error) {
    dispatch(fetchDataError(error));
  }
};
