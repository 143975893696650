import classNames from 'classnames';
import React, { useRef, useState } from 'react';
import css from './Tooltip.module.scss';

const DEFAULT_DELAY = 300;
const DEFAULT_DIRECTION = 'bottom';

const Tooltip = (props) => {
  const {
    className,
    children,
    content,
    delay = DEFAULT_DELAY,
    direction = DEFAULT_DIRECTION,
    toolTipClassName,
  } = props;
  const [active, setActive] = useState(false);
  const timeout = useRef();

  const showTip = () => {
    timeout.current = setTimeout(() => {
      setActive(true);
    }, delay);
  };

  const hideTip = () => {
    clearTimeout(timeout.current);
    setActive(false);
  };

  const tooltipContentClasses = classNames(css.tooltipContent, toolTipClassName, css[direction]);
  return (
    <div
      className={className}
      onClick={() => setActive(!active)}
      onMouseEnter={showTip}
      onMouseLeave={hideTip}
    >
      {children}
      {active && <div className={tooltipContentClasses}>{content}</div>}
    </div>
  );
};

export default Tooltip;
