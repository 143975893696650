import isEmpty from 'lodash/isEmpty';
import {
  monthIdStringInTimeZone,
  printHourStrings,
  resetToStartOfDay,
  timeToValue,
} from '../../util/dates';

export const TODAY = new Date();
export const MAX_AVAILABILITY_EXCEPTIONS_RANGE = 365;

export const sortEntries =
  (defaultCompareReturn = 0) =>
  (a, b) => {
    if (a.startTime && b.startTime) {
      const aStart = timeToValue(a.startTime);
      const bStart = timeToValue(b.startTime);
      return aStart - bStart;
    }
    return defaultCompareReturn;
  };

export const findEntryFn = (entry) => (e) =>
  e.startTime === entry.startTime && e.endTime === entry.endTime;

export const filterStartHours = (availableStartHours, values, index) => {
  if (isEmpty(values)) return [];
  const entries = values['exceptionHourBlock'];
  const currentEntry = entries[index];

  // If there is no end time selected, return all the available start times
  if (!currentEntry.endTime) {
    return availableStartHours;
  }

  // By default the entries are not in order so we need to sort the entries by startTime
  // in order to find out the previous entry
  const sortedEntries = [...entries].sort(sortEntries());

  // Find the index of the current entry from sorted entries
  const currentIndex = sortedEntries.findIndex(findEntryFn(currentEntry));

  // If there is no next entry or the previous entry does not have endTime,
  // return all the available times before current selected end time.
  // Otherwise return all the available start times that are after the previous entry or entries.
  const prevEntry = sortedEntries[currentIndex - 1];
  const pickBefore = (time) => (h) => timeToValue(h) < timeToValue(time);
  const pickBetween = (start, end) => (h) =>
    timeToValue(h) >= timeToValue(start) && timeToValue(h) < timeToValue(end);

  return !prevEntry || !prevEntry.endTime
    ? availableStartHours.filter(pickBefore(currentEntry.endTime))
    : availableStartHours.filter(pickBetween(prevEntry.endTime, currentEntry.endTime));
};

export const filterEndHours = (availableEndHours, values, index) => {
  if (isEmpty(values)) return [];
  const entries = values['exceptionHourBlock'];
  const currentEntry = entries[index];

  // If there is no start time selected, return an empty array;
  if (!currentEntry.startTime) {
    return [];
  }

  // By default the entries are not in order so we need to sort the entries by startTime
  // in order to find out the allowed start times
  const sortedEntries = [...entries].sort(sortEntries(-1));

  // Find the index of the current entry from sorted entries
  const currentIndex = sortedEntries.findIndex(findEntryFn(currentEntry));

  // If there is no next entry,
  // return all the available end times that are after the start of current entry.
  // Otherwise return all the available end hours between current start time and next entry.
  const nextEntry = sortedEntries[currentIndex + 1];
  const pickAfter = (time) => (h) => timeToValue(h) > timeToValue(time);
  const pickBetween = (start, end) => (h) =>
    timeToValue(h) > timeToValue(start) && timeToValue(h) <= timeToValue(end);

  return !nextEntry || !nextEntry.startTime
    ? availableEndHours.filter(pickAfter(currentEntry.startTime))
    : availableEndHours.filter(pickBetween(currentEntry.startTime, nextEntry.startTime));
};

export const getEntryBoundaries = (values, findStartHours) => (index) => {
  if (isEmpty(values)) return [];
  const entries = values['exceptionHourBlock'];
  const boundaryDiff = findStartHours ? 0 : 1;

  return entries.reduce((allHours, entry, i) => {
    const { startTime, endTime } = entry || {};

    if (i !== index && startTime && endTime) {
      const startHour = timeToValue(startTime);
      const endHour = timeToValue(endTime);
      const hoursBetween = Array(endHour - startHour)
        .fill()
        .map((v, i) => printHourStrings(startHour + i + boundaryDiff));

      return allHours.concat(hoursBetween);
    }

    return allHours;
  }, []);
};

export const endOfAvailabilityExceptionRange = (timeZone, date) => {
  return resetToStartOfDay(date, timeZone, MAX_AVAILABILITY_EXCEPTIONS_RANGE - 1);
};

// Update current month and call callback function.
export const onMonthClick =
  (currentMonth, setCurrentMonth, timeZone, onMonthChanged) => (monthFn) => {
    const updatedMonth = monthFn(currentMonth, timeZone);
    setCurrentMonth(updatedMonth);

    if (onMonthChanged) {
      const monthId = monthIdStringInTimeZone(updatedMonth, timeZone);
      onMonthChanged(monthId);
    }
  };
