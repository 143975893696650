import { bool, func, object, string } from 'prop-types';
import React from 'react';
import config from '../../config';
import { convertPriceToCredit, priceData } from '../../util/currency';
import { ensureListing, formatLocation, getUnitTranslationKey } from '../../util/data';
import { injectIntl, intlShape } from '../../util/reactIntl';
import { findOptionsForSelectFilter } from '../../util/search';
import { propTypes } from '../../util/types';
import ListingCardNewComponent from './ListingCardNewComponent';

export const ListingCardComponent = ({
  intl,
  listing,
  setActiveListing,
  className,
  isFavorite,
  isFavoriteCard,
  isCompanyFavorite,
  hideFavoriteIcon,
  isShowCredits,
  showCompanyFavoriteButton,
  isActiveOnBottomSheet,
  onClick,
  isMobile,
  search,
}) => {
  const currentListing = ensureListing(listing);
  const { filters } = config.custom;

  if (!!currentListing.id) {
    const {
      id: { uuid: id },
      attributes: {
        title,
        price,
        publicData: {
          ameneties,
          location: { address },
          category: type,
          minSeats,
          maxSeats,
          priceMax,
          requestPrice,
          vatPercentage = 25,
          locationId,
        },
      },
    } = currentListing;

    /*
     * Transform [{ key: 'one', labelId: 'one' }, { key: 'two', labelId: 'two' }]
     * to 'one, two'
     * */
    const features = findOptionsForSelectFilter('ameneties', filters)
      .filter((a) => ameneties?.includes(a.key))
      .reduce(
        (acc, curr, i) =>
          (acc +=
            i === 0
              ? intl.formatMessage({ id: curr.labelId })
              : `, ${[intl.formatMessage({ id: curr.labelId })]}`),
        ''
      );

    /* Workaround for ImageSlider, because for now it takes
     * attributes.variants.square-small2x */
    const images = currentListing.images.map((img) => ({
      attributes: {
        variants: {
          'square-small2x':
            img.attributes.variants['landscape-crop'] || img.attributes.variants['square-small2x'],
        },
      },
    }));

    const unitTranslationKey = getUnitTranslationKey(listing);

    const location = formatLocation(address);

    const categories = filters.find((item) => item.id === 'category');
    const { options } = categories.config;
    const category = options.find((option) => option.key === type);
    const credits = intl.formatMessage(
      { id: 'ListingCardNew.credits' },
      { credits: convertPriceToCredit(price) }
    );

    const priceMaxMinusVAT = priceMax / (1 + vatPercentage / 100);

    return (
      <ListingCardNewComponent
        onMouseEnter={() => setActiveListing(currentListing.id)}
        onMouseLeave={() => setActiveListing(null)}
        id={id}
        intl={intl}
        title={title}
        price={priceData(price, vatPercentage, intl)}
        credits={credits}
        type={!!category ? intl.formatMessage({ id: category.labelId }) : ''}
        images={images}
        location={location}
        features={features}
        unitTranslationKey={unitTranslationKey}
        className={className}
        isFavorite={isFavorite}
        isFavoriteCard={isFavoriteCard}
        isCompanyFavorite={isCompanyFavorite}
        hideFavoriteIcon={hideFavoriteIcon}
        isShowCredits={isShowCredits}
        minSeats={minSeats}
        seats={maxSeats}
        category={category}
        showCompanyFavoriteButton={showCompanyFavoriteButton}
        isActiveOnBottomSheet={isActiveOnBottomSheet}
        onClick={onClick}
        isMobile={isMobile}
        search={search}
        priceMax={priceMaxMinusVAT}
        requestPrice={requestPrice}
        locationId={locationId}
      />
    );
  } else {
    return null;
  }
};

ListingCardComponent.defaultProps = {
  setActiveListing: null,
  className: null,
  isFavorite: false,
  isFavoriteCard: false,
  showCompanyFavoriteButton: false,
  isActiveOnBottomSheet: false,
  search: null,
};

ListingCardComponent.propTypes = {
  intl: intlShape.isRequired,
  listing: propTypes.listing.isRequired,
  setActiveListing: func,
  className: string,
  isFavorite: bool,
  isFavoriteCard: bool,
  isCompanyFavorite: bool,
  showCompanyFavoriteButton: bool,
  isActiveOnBottomSheet: bool,
  search: object,
};

export default injectIntl(ListingCardComponent);
