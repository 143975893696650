import {
  COWORKING_CATEGORY,
  MEETINGROOMS_CATEGORY,
  PRIVATE_OFFICE_CATEGORY,
  STUDIO_CATEGORY,
  FIXED_OFFICE_CATEGORY,
} from './util/types';

/*
 * Marketplace specific configuration.
 *
 * Every filter needs to have following keys:
 * - id:     Unique id of the filter.
 * - label:  The default label of the filter.
 * - type:   String that represents one of the existing filter components:
 *           BookingDateRangeFilter, KeywordFilter, PriceFilter,
 *           SelectSingleFilter, SelectMultipleFilter.
 * - group:  Is this 'primary' or 'secondary' filter?
 *           Primary filters are visible on desktop layout by default.
 *           Secondary filters are behind "More filters" button.
 *           Read more from src/containers/SearchPage/README.md
 * - queryParamNames: Describes parameters to be used with queries
 *                    (e.g. 'price' or 'pub_yogaStyles'). Most of these are
 *                    the same between webapp URLs and API query params.
 *                    You can't change 'dates', 'price', or 'keywords'
 *                    since those filters are fixed to a specific attribute.
 * - config: Extra configuration that the filter component needs.
 *
 * Note 1: Labels could be tied to translation file
 *         by importing FormattedMessage:
 *         <FormattedMessage id="some.translation.key.here" />
 *
 * Note 2: If you need to add new custom filter components,
 *         you need to take those into use in:
 *         src/containers/SearchPage/FilterComponent.js
 *
 * Note 3: If you just want to create more enum filters
 *         (i.e. SelectSingleFilter, SelectMultipleFilter),
 *         you can just add more configurations with those filter types
 *         and tie them with correct extended data key
 *         (i.e. pub_<key> or meta_<key>).
 */
const MAX_SEATS = 999;
const ALL_CATEGORIES = [
  FIXED_OFFICE_CATEGORY,
  COWORKING_CATEGORY,
  MEETINGROOMS_CATEGORY,
  PRIVATE_OFFICE_CATEGORY,
  STUDIO_CATEGORY,
];

export const filters = [
  // this was a primary filter
  {
    id: 'dates-length',
    label: 'MarketplaceConfig.filters.dates',
    type: 'BookingDateRangeLengthFilter',
    group: 'secondary',
    relevantFor: MEETINGROOMS_CATEGORY,
    // Note: BookingDateRangeFilter is fixed filter,
    // you can't change "queryParamNames: ['dates'],"
    queryParamNames: ['dates', 'minDuration', 'time'],
    config: {
      // A global time zone to use in availability searches. As listings
      // can be in various time zones, we must decide what time zone we
      // use in search when looking for available listings within a
      // certain time interval.
      //
      // If you have all/most listings in a certain time zone, change this
      // config value to that.
      //
      // See: https://en.wikipedia.org/wiki/List_of_tz_database_time_zones
      searchTimeZone: 'Etc/UTC',

      // Options for the minimum duration of the booking
      options: [
        {
          key: '0',
          label: 'Any length',
          labelId: 'MarketplaceConfig.filters.datesLength.anyLength',
        },
        {
          key: '60',
          label: '1 hour',
          shortLabel: '1h',
          labelId: 'MarketplaceConfig.filters.datesLength.1Hour',
        },
        {
          key: '120',
          label: '2 hours',
          shortLabel: '2h',
          labelId: 'MarketplaceConfig.filters.datesLength.2Hours',
        },
      ],
    },
  },
  // this was a primary filter
  {
    id: 'keyword',
    label: 'Name',
    type: 'KeywordFilter',
    group: 'old',
    // Note: KeywordFilter is fixed filter,
    // you can't change "queryParamNames: ['keywords'],"
    queryParamNames: ['keywords'],
    // NOTE: If you are ordering search results by distance
    // the keyword search can't be used at the same time.
    // You can turn on/off ordering by distance from config.js file.
    config: {},
  },
  {
    id: 'category',
    label: 'MarketplaceConfig.filters.category',
    type: 'SelectSingleFilter',
    group: 'secondary',
    for: [],
    queryParamNames: ['category'],
    config: {
      // Optional modes: 'has_all', 'has_any'
      // https://www.sharetribe.com/api-reference/marketplace.html#extended-data-filtering
      // searchMode: 'has_any',
      // "key" is the option you see in Flex Console.
      // "label" is set here for this web app's UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [
        {
          key: 'fixed',
          label: 'Fixed office',
          labelId: 'MarketplaceConfig.filters.category.fixed',
        },
        {
          key: 'coworking',
          label: 'Coworking',
          labelId: 'MarketplaceConfig.filters.category.coworking',
        },
        {
          key: 'meeting',
          label: 'Meeting room',
          labelId: 'MarketplaceConfig.filters.category.meeting',
        },
        {
          key: 'private',
          label: 'Office for the day',
          labelId: 'MarketplaceConfig.filters.category.private',
        },
        {
          key: 'studio',
          label: 'Studio',
          labelId: 'MarketplaceConfig.filters.category.studio',
        },
      ],
    },
  },
  {
    id: 'contractLengthPerUnit',
    label: 'MarketplaceConfig.filters.contractLength',
    type: 'SelectSingleFilter',
    group: 'primary',
    for: [FIXED_OFFICE_CATEGORY],
    queryParamNames: ['contractLengthPerUnit'],
    config: {
      // Optional modes: 'has_all', 'has_any'
      // https://www.sharetribe.com/api-reference/marketplace.html#extended-data-filtering
      // searchMode: 'has_any',
      // "key" is the option you see in Flex Console.
      // "label" is set here for this web app's UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [
        {
          key: 1,
          label: '1 month',
          labelId: 'MarketplaceConfig.filters.contractLength.one',
          fixed: true,
        },
        {
          key: 3,
          label: '3',
          labelId: 'MarketplaceConfig.filters.contractLength.two',
          fixed: true,
        },
        {
          key: 6,
          label: '6',
          labelId: 'MarketplaceConfig.filters.contractLength.three',
          fixed: true,
        },
        {
          key: 12,
          label: '12',
          labelId: 'MarketplaceConfig.filters.contractLength.four',
          fixed: true,
        },
      ],
    },
  },
  {
    id: 'contractType',
    label: 'MarketplaceConfig.filters.contractType',
    type: 'SelectMultipleFilter',
    group: 'primary',
    for: [FIXED_OFFICE_CATEGORY],
    queryParamNames: ['contractType'],
    config: {
      options: [
        {
          key: 'first_hand',
          label: 'First hand',
          labelId: 'MarketplaceConfig.filters.contractType.firstHand',
          fixed: true,
        },
        {
          key: 'subleasing',
          label: 'Second hand',
          labelId: 'MarketplaceConfig.filters.contractType.secondHand',
          fixed: true,
        },
      ],
    },
  },
  {
    id: 'allowVatExemptCompanies',
    label: 'MarketplaceConfig.filters.allowVatExemptCompanies',
    type: 'SelectMultipleFilter',
    group: 'primary',
    for: [FIXED_OFFICE_CATEGORY],
    queryParamNames: ['allowVatExemptCompanies'],
    config: {
      options: [
        {
          key: 'allowVAT',
          label: 'Allow VAT exempt companies?',
          labelId: 'MarketplaceConfig.filters.allowVatExemptCompanies.label',
          fixed: true,
        },
      ],
    },
  },
  {
    id: 'fixedOfficeType',
    label: 'MarketplaceConfig.filters.fixedOfficeType',
    type: 'SelectMultipleFilter',
    group: 'primary',
    for: [FIXED_OFFICE_CATEGORY],
    relevantFor: [FIXED_OFFICE_CATEGORY],
    queryParamNames: ['fixedOfficeType'],
    config: {
      options: [
        {
          key: 'office',
          label: 'Office space',
          labelId: 'MarketplaceConfig.filters.fixedOfficeType.office',
          fixed: true,
        },
        {
          key: 'room',
          label: 'Private room',
          labelId: 'MarketplaceConfig.filters.fixedOfficeType.room',
          fixed: true,
        },
        {
          key: 'fixedDesk',
          label: 'Fixed desks',
          labelId: 'MarketplaceConfig.filters.fixedOfficeType.fixedDesk',
          fixed: true,
        },
        {
          key: 'flexibleDesk',
          label: 'Flexible desks',
          labelId: 'MarketplaceConfig.filters.fixedOfficeType.flexibleDesk',
          fixed: true,
        },
      ],
    },
  },
  {
    id: 'host',
    label: 'MarketplaceConfig.filters.host',
    type: 'SelectMultipleFilter',
    group: 'primary',
    for: [FIXED_OFFICE_CATEGORY],
    relevantFor: [FIXED_OFFICE_CATEGORY],
    queryParamNames: ['host'],
    config: {
      options: [
        {
          key: 'propertyOwner',
          label: 'Property owner',
          labelId: 'MarketplaceConfig.filters.host.propertyOwner',
          fixed: true,
        },
        {
          key: 'officeHotel',
          label: 'Office hotel',
          labelId: 'MarketplaceConfig.filters.host.officeHotel',
          fixed: true,
        },
        {
          key: 'otherCompany',
          label: 'Other company',
          labelId: 'MarketplaceConfig.filters.host.otherCompany',
          fixed: true,
        },
        {
          key: 'hotel',
          label: 'Hotel',
          labelId: 'MarketplaceConfig.filters.host.hotel',
          fixed: true,
        },
      ],
    },
  },
  {
    id: 'maxSeats',
    label: 'MarketplaceConfig.filters.seats',
    type: 'SelectSingleFilter',
    group: 'secondary',
    for: [],
    relevantFor: [COWORKING_CATEGORY],
    queryParamNames: ['maxSeats'],
    config: {
      // Optional modes: 'has_all', 'has_any'
      // https://www.sharetribe.com/api-reference/marketplace.html#extended-data-filtering
      // searchMode: 'has_any',
      // "key" is the option you see in Flex Console.
      // "label" is set here for this web app's UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [
        {
          key: `1,${MAX_SEATS}`,
          label: '1-5',
          labelId: 'MarketplaceConfig.filters.seats.one',
        },
        {
          key: `6,${MAX_SEATS}`,
          label: '6-10',
          labelId: 'MarketplaceConfig.filters.seats.two',
        },
        {
          key: `11,${MAX_SEATS}`,
          label: '11-20',
          labelId: 'MarketplaceConfig.filters.seats.three',
        },
        {
          key: `21,${MAX_SEATS}`,
          label: '21+',
          labelId: 'MarketplaceConfig.filters.seats.four',
        },
      ],
    },
  },
  {
    id: 'ameneties',
    label: 'MarketplaceConfig.filters.ameneties',
    type: 'SelectMultipleFilter',
    group: 'primary',
    for: ALL_CATEGORIES,
    queryParamNames: ['ameneties'],
    config: {
      // Optional modes: 'has_all', 'has_any'
      // https://www.sharetribe.com/api-reference/marketplace.html#extended-data-filtering
      searchMode: 'has_all',
      // "key" is the option you see in Flex Console.
      // "label" is set here for this web app's UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [
        {
          key: 'freeCoffee',
          label: 'All day coffee/tea/water',
          labelId: 'MarketplaceConfig.filters.ameneties.freeCoffee',
          iconSrc: '/static/icons/filterIcons/coffeeTeaWater.svg',
          faIcon: 'mug-hot',
          meeting: true,
          coworking: true,
          studio: true,
          private: true,
          fixed: true,
        },
        {
          key: 'wifi',
          label: 'Fast WiFi',
          labelId: 'MarketplaceConfig.filters.ameneties.wifi',
          iconSrc: '/static/icons/filterIcons/wifi.svg',
          faIcon: 'wifi',
          meeting: true,
          coworking: true,
          studio: true,
          private: true,
          fixed: true,
        },
        {
          key: 'internet',
          label: 'Internet',
          labelId: 'MarketplaceConfig.filters.ameneties.internet',
          iconSrc: '/static/icons/filterIcons/internet.svg',
          faIcon: 'globe',
          fixed: true,
        },
        {
          key: 'meetingRoom',
          label: 'Drop-in meeting rooms',
          labelId: 'MarketplaceConfig.filters.ameneties.meetingRoom',
          iconSrc: '/static/icons/filterIcons/meeting-room.svg',
          faIcon: 'screen-users',
          meeting: true,
          coworking: true,
          studio: true,
          private: true,
        },
        {
          key: 'phoneBooth',
          label: 'Drop-in phone booths',
          labelId: 'MarketplaceConfig.filters.ameneties.phoneBooth',
          iconSrc: '/static/icons/filterIcons/phonebooth.svg',
          faIcon: 'person-booth',
          meeting: true,
          coworking: true,
          studio: true,
          private: true,
        },
        {
          key: 'parking',
          label: 'Parking',
          labelId: 'MarketplaceConfig.filters.ameneties.parking',
          iconSrc: '/static/icons/filterIcons/parking.svg',
          faIcon: 'square-parking',
          meeting: true,
          coworking: true,
          studio: true,
          private: true,
          fixed: true,
        },
        {
          key: 'bicycleParking',
          label: 'Bicycle parking',
          labelId: 'MarketplaceConfig.filters.ameneties.bicycleParking',
          iconSrc: '/static/icons/filterIcons/bicycle.svg',
          faIcon: 'bicycle',
          meeting: true,
          coworking: true,
          studio: true,
          private: true,
          fixed: true,
        },
        {
          key: 'pets',
          label: 'Pets allowed',
          labelId: 'MarketplaceConfig.filters.ameneties.pets',
          iconSrc: '/static/icons/filterIcons/pets.svg',
          faIcon: 'dog',
          meeting: true,
          coworking: true,
          studio: true,
          private: true,
          fixed: true,
        },
        {
          key: 'foodSales',
          label: 'Food and drinks available to buy',
          labelId: 'MarketplaceConfig.filters.ameneties.foodSales',
          iconSrc: '/static/icons/filterIcons/restaurant.svg',
          faIcon: 'utensils',
          meeting: true,
          coworking: true,
          studio: true,
          private: true,
          fixed: true,
        },
        {
          key: 'quietArea',
          label: 'Quiet area',
          labelId: 'MarketplaceConfig.filters.ameneties.quietArea',
          iconSrc: '/static/icons/filterIcons/quiet.svg',
          faIcon: 'volume-off',
          coworking: true,
        },
        {
          key: 'digitalKey',
          label: 'Digital key',
          labelId: 'MarketplaceConfig.filters.ameneties.digitalKey',
          iconSrc: '/static/icons/filterIcons/digitalKey.svg',
          faIcon: 'mobile-signal-out',
          meeting: true,
          coworking: true,
          studio: true,
          private: true,
          fixed: true,
        },
      ],
    },
  },
  {
    id: 'price',
    label: 'MarketplaceConfig.filters.price',
    type: 'PriceFilter',
    group: 'primary',
    for: [MEETINGROOMS_CATEGORY],
    relevantFor: [FIXED_OFFICE_CATEGORY, COWORKING_CATEGORY, MEETINGROOMS_CATEGORY],
    // Note: PriceFilter is fixed filter,
    // you can't change "queryParamNames: ['price'],"
    queryParamNames: ['price'],
    // Price filter configuration
    // Note: unlike most prices this is not handled in subunits
    config: {
      min: 0,
      max: 9999999,
      step: 5,
    },
  },
  {
    id: 'offer',
    label: 'MarketplaceConfig.filters.offer',
    type: 'SelectMultipleFilter',
    group: 'primary',
    for: ALL_CATEGORIES,
    relevantFor: [MEETINGROOMS_CATEGORY],
    queryParamNames: ['offer'],
    config: {
      searchMode: 'has_all',
      options: [
        {
          key: 'monitor',
          label: 'Computer screens',
          labelId: 'MarketplaceConfig.filters.offer.monitor',
          iconSrc: '/static/icons/filterIcons/screen.svg',
          faIcon: 'desktop',
          coworking: true,
          studio: true,
          fixed: true,
        },
        {
          key: 'dedicatedDesk',
          label: 'Desk',
          labelId: 'MarketplaceConfig.filters.offer.dedicatedDesk',
          iconSrc: '/static/icons/filterIcons/desk.svg',
          faIcon: 'desk',
          coworking: true,
          studio: true,
          fixed: true,
        },
        {
          key: 'chair',
          label: 'Ergonomic chair',
          labelId: 'MarketplaceConfig.filters.offer.chair',
          iconSrc: '/static/icons/filterIcons/chair.svg',
          faIcon: 'chair-office',
          coworking: true,
          fixed: true,
        },
        {
          key: 'sitStandDesk',
          label: 'Sit stand desk',
          labelId: 'MarketplaceConfig.filters.offer.sitStandDesk',
          iconSrc: '/static/icons/filterIcons/desk-adjustable.svg',
          faIcon: 'sit-stand-desk',
          studio: true,
          coworking: true,
          fixed: true,
        },
        {
          key: 'tv',
          label: 'Big screen display',
          labelId: 'MarketplaceConfig.filters.offer.tv',
          iconSrc: '/static/icons/filterIcons/tv.svg',
          faIcon: 'tv',
          meeting: true,
          important: true,
        },
        {
          key: 'whiteboard',
          label: 'White board',
          labelId: 'MarketplaceConfig.filters.offer.whiteboard',
          iconSrc: '/static/icons/filterIcons/whiteboard.svg',
          faIcon: 'chalkboard',
          meeting: true,
          important: true,
        },
        {
          key: 'apple',
          label: 'Apple TV',
          labelId: 'MarketplaceConfig.filters.offer.apple',
          iconSrc: '/static/icons/filterIcons/apple-tv.svg',
          faIcon: 'airplay',
          meeting: true,
        },
        {
          key: 'phone',
          label: 'Conference phone',
          labelId: 'MarketplaceConfig.filters.offer.phone',
          iconSrc: '/static/icons/filterIcons/conference_telephone.svg',
          faIcon: 'phone-office',
          meeting: true,
        },
        {
          key: 'chromecast',
          label: 'Chromecast',
          labelId: 'MarketplaceConfig.filters.offer.chromecast',
          iconSrc: '/static/icons/filterIcons/chromecast.svg',
          faIcon: 'screencast',
          meeting: true,
        },
        {
          key: 'screencast',
          label: 'Screencast',
          labelId: 'MarketplaceConfig.filters.offer.screencast',
          iconSrc: '/static/icons/filterIcons/screencast.svg',
          faIcon: 'screencast',
          meeting: true,
        },
        {
          key: 'fridge',
          label: 'Fridge',
          labelId: 'MarketplaceConfig.filters.offer.fridge',
          iconSrc: '/static/icons/filterIcons/fridge.svg',
          faIcon: 'refrigerator',
          studio: true,
          coworking: true,
          meeting: true,
          office: true,
        },
        {
          key: 'micro',
          label: 'Microwave oven',
          labelId: 'MarketplaceConfig.filters.offer.micro',
          iconSrc: '/static/icons/filterIcons/micro.svg',
          faIcon: 'microwave',
          studio: true,
          coworking: true,
          meeting: true,
          office: true,
          fixed: true,
        },
        {
          key: 'flexibleDesk',
          label: 'Flexible desk',
          labelId: 'MarketplaceConfig.filters.offer.flexibleDesk',
          // iconSrc: '/static/icons/filterIcons/flexibleDesk.svg',
          faIcon: '',
          meeting: true,
        },
        {
          key: 'printer',
          label: 'Printer',
          labelId: 'MarketplaceConfig.filters.offer.printer',
          iconSrc: '/static/icons/filterIcons/printer.svg',
          faIcon: 'print',
          studio: true,
          coworking: true,
          meeting: true,
          office: true,
          fixed: true,
        },
        {
          key: 'shower',
          label: 'Shower',
          labelId: 'MarketplaceConfig.filters.offer.shower',
          iconSrc: '/static/icons/filterIcons/shower.svg',
          faIcon: 'shower',
          coworking: true,
          office: true,
          fixed: true,
        },
        {
          key: 'podcast',
          label: 'Podcast equipment',
          labelId: 'MarketplaceConfig.filters.offer.podcast',
          iconSrc: '/static/icons/filterIcons/podcast.svg',
          faIcon: 'microphone',
          studio: true,
        },
        {
          key: 'cleaning',
          label: 'Cleaning',
          labelId: 'MarketplaceConfig.filters.offer.cleaning',
          iconSrc: '/static/icons/filterIcons/cleaning.svg',
          faIcon: 'vacuum',
          fixed: true,
        },
        {
          key: 'kitchen',
          label: 'Kitchen access',
          labelId: 'MarketplaceConfig.filters.offer.kitchen',
          iconSrc: '/static/icons/filterIcons/kitchen.svg',
          faIcon: 'refrigerator',
          fixed: true,
        },
        {
          key: 'gym',
          label: 'Gym access',
          labelId: 'MarketplaceConfig.filters.offer.gym',
          iconSrc: '/static/icons/filterIcons/gym.svg',
          faIcon: 'dumbell',
          fixed: true,
        },
        {
          key: 'lockers',
          label: 'Lockers',
          labelId: 'MarketplaceConfig.filters.offer.lockers',
          iconSrc: '/static/icons/filterIcons/lockers.svg',
          faIcon: 'lock',
          fixed: true,
        },
        {
          key: 'inventory',
          label: 'Inventory space',
          labelId: 'MarketplaceConfig.filters.offer.inventory',
          iconSrc: '/static/icons/filterIcons/inventory.svg',
          faIcon: 'boxes-stacked',
          fixed: true,
        },
        {
          key: 'roofTerrace',
          label: 'Roof terrace',
          labelId: 'MarketplaceConfig.filters.offer.roofTerrace',
          iconSrc: '/static/icons/filterIcons/roofTerrace.svg',
          faIcon: 'bench-tree',
          fixed: true,
        },
        {
          key: 'alarm',
          label: 'Alarm',
          labelId: 'MarketplaceConfig.filters.offer.alarm',
          iconSrc: '/static/icons/filterIcons/alarm.svg',
          faIcon: 'shield-check',
          fixed: true,
        },
        {
          key: 'flexibleTerms',
          label: 'Flexible terms',
          labelId: 'MarketplaceConfig.filters.offer.flexibleTerms',
          iconSrc: '/static/icons/filterIcons/flexibleTerms.svg',
          faIcon: 'file-contract',
          fixed: true,
        },
        {
          key: 'tax',
          label: 'Real estate tax included',
          labelId: 'MarketplaceConfig.filters.offer.tax',
          iconSrc: '/static/icons/filterIcons/tax.svg',
          faIcon: 'building-circle-check',
          fixed: true,
        },
        {
          key: 'officeAddress',
          label: 'Regestred office address',
          labelId: 'MarketplaceConfig.filters.offer.officeAddress',
          iconSrc: '/static/icons/filterIcons/officeAddress.svg',
          faIcon: 'location-dot',
          fixed: true,
        },
        {
          key: 'storageSpace',
          label: 'Storage space',
          labelId: 'MarketplaceConfig.filters.offer.storageSpace',
          iconSrc: '/static/icons/filterIcons/storageSpace.svg',
          faIcon: 'warehouse-full',
          fixed: true,
        },
        {
          key: 'wheelchair',
          label: 'Wheelchair accessible',
          labelId: 'MarketplaceConfig.filters.offer.wheelchair',
          iconSrc: '/static/icons/filterIcons/wheelchair.svg',
          faIcon: 'wheelchair',
          fixed: true,
        },
        {
          key: 'furnished',
          label: 'Furnished',
          labelId: 'MarketplaceConfig.filters.offer.furnished',
          iconSrc: '/static/icons/filterIcons/furnished.svg',
          faIcon: 'chair-office',
          fixed: true,
        },
        {
          key: 'electricity',
          label: 'Electricity included',
          labelId: 'MarketplaceConfig.filters.offer.electricity',
          iconSrc: '/static/icons/filterIcons/electricity.svg',
          faIcon: 'plug',
          fixed: true,
        },
        {
          key: 'heating',
          label: 'Heating included',
          labelId: 'MarketplaceConfig.filters.offer.heating',
          iconSrc: '/static/icons/filterIcons/heating.svg',
          faIcon: 'temperature-high',
          fixed: true,
        },
        {
          key: '24hAccess',
          label: '24h access',
          labelId: 'MarketplaceConfig.filters.offer.24hAccess',
          iconSrc: '/static/icons/filterIcons/24hAccess.svg',
          faIcon: 'user-clock',
          fixed: true,
        },
        {
          key: 'reception',
          label: 'Reception service',
          labelId: 'MarketplaceConfig.filters.offer.reception',
          iconSrc: '/static/icons/filterIcons/reception.svg',
          faIcon: 'bell-concierge',
          fixed: true,
        },
        {
          key: 'postage',
          label: 'Post handling',
          labelId: 'MarketplaceConfig.filters.offer.postage',
          iconSrc: '/static/icons/filterIcons/postage.svg',
          faIcon: 'mailbox-flag-up',
          fixed: true,
        },
      ],
    },
  },
  {
    id: 'environment',
    label: 'Environment',
    type: 'SelectSingleFilter',
    group: 'old',
    queryParamNames: ['environment'],
    config: {
      // Optional modes: 'has_all', 'has_any'
      // https://www.sharetribe.com/api-reference/marketplace.html#extended-data-filtering
      searchMode: 'has_all',
      // "key" is the option you see in Flex Console.
      // "label" is set here for this web app's UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [
        {
          key: 'undisturbed',
          label: 'Work undisturbed',
          labelId: 'MarketplaceConfig.filters.environment.undisturbed',
        },
        {
          key: 'vibrant',
          label: 'Work in a vibrant environment',
          labelId: 'MarketplaceConfig.filters.environment.vibrant',
        },
        {
          key: 'inspiring',
          label: 'Work in an inspiring environment',
          labelId: 'MarketplaceConfig.filters.environment.inspiring',
        },
      ],
    },
  },
  {
    id: 'noiselevel',
    label: 'Noise level',
    type: 'SelectSingleFilter',
    group: 'old',
    queryParamNames: ['noiselevel'],
    config: {
      // Optional modes: 'has_all', 'has_any'
      // https://www.sharetribe.com/api-reference/marketplace.html#extended-data-filtering
      searchMode: 'has_all',
      // "key" is the option you see in Flex Console.
      // "label" is set here for this web app's UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [
        {
          key: 'quiet',
          label: 'Quiet noise level',
          labelId: 'MarketplaceConfig.filters.noiselevel.quiet',
        },
        {
          key: 'social',
          label: 'Social noise level',
          labelId: 'MarketplaceConfig.filters.noiselevel.social',
        },
        {
          key: 'mixed',
          label: 'Mixed noise level',
          labelId: 'MarketplaceConfig.filters.noiselevel.mixed',
        },
      ],
    },
  },
  {
    id: 'vibe',
    label: 'Vibe',
    type: 'SelectSingleFilter',
    group: 'old',
    queryParamNames: ['vibe'],
    config: {
      // Optional modes: 'has_all', 'has_any'
      // https://www.sharetribe.com/api-reference/marketplace.html#extended-data-filtering
      searchMode: 'has_all',
      // "key" is the option you see in Flex Console.
      // "label" is set here for this web app's UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [
        {
          key: 'startup',
          label: 'Start-up vibe',
          labelId: 'MarketplaceConfig.filters.vibe.startup',
        },
        {
          key: 'professional',
          label: 'Professional vibe',
          labelId: 'MarketplaceConfig.filters.vibe.professional',
        },
        {
          key: 'creative',
          label: 'Creative vibe',
          labelId: 'MarketplaceConfig.filters.vibe.creative',
        },
      ],
    },
  },
];

export const sortConfig = {
  // Enable/disable the sorting control in the SearchPage
  active: true,

  // Note: queryParamName 'sort' is fixed,
  // you can't change it since Flex API expects it to be named as 'sort'
  queryParamName: 'sort',

  // Keyword filter is sorting the results already by relevance.
  // If keyword filter is active, we need to disable sorting.
  conflictingFilters: ['keyword'],

  options: [
    // { key: '-createdAt', label: 'Oldest', labelId: 'MarketplaceConfig.sortConfig.oldest' },
    { key: '-price', label: 'Lowest price', labelId: 'MarketplaceConfig.sortConfig.lowestPrice' },
    { key: 'price', label: 'Highest price', labelId: 'MarketplaceConfig.sortConfig.highestPrice' },
    { key: 'createdAt', label: 'Recently added', labelId: 'MarketplaceConfig.sortConfig.newest' },
    {
      key: 'meta_rating',
      label: 'Popularity',
      labelId: 'MarketplaceConfig.sortConfig.popularity',
    },
  ],
};

export const bookingTypes = [
  { key: 'hourly', label: 'Hourly', labelId: 'BookingTypes.hourly' },
  { key: 'daily', label: 'Daily', labelId: 'BookingTypes.daily' },
  { key: 'monthly', label: 'Monthly', labelId: 'BookingTypes.monthly' },
];

export const notShowSeatsCategories = ['private', 'meeting'];

export const VAT_VALUES = [
  { key: '0', label: '0%' },
  { key: '6', label: '6%' },
  { key: '12', label: '12%' },
  { key: '25', label: '25%' },
];

export const HOUR_OPTIONS = [
  { key: '0', label: '00:00' },
  { key: '30', label: '00:30' },
  { key: '60', label: '01:00' },
  { key: '90', label: '01:30' },
  { key: '120', label: '02:00' },
  { key: '150', label: '02:30' },
  { key: '180', label: '03:00' },
  { key: '210', label: '03:30' },
  { key: '240', label: '04:00' },
  { key: '270', label: '04:30' },
  { key: '300', label: '05:00' },
  { key: '330', label: '05:30' },
  { key: '360', label: '06:00' },
  { key: '390', label: '06:30' },
  { key: '420', label: '07:00' },
  { key: '450', label: '07:30' },
  { key: '480', label: '08:00' },
  { key: '510', label: '08:30' },
  { key: '540', label: '09:00' },
  { key: '570', label: '09:30' },
  { key: '600', label: '10:00' },
  { key: '630', label: '10:30' },
  { key: '660', label: '11:00' },
  { key: '690', label: '11:30' },
  { key: '720', label: '12:00' },
  { key: '750', label: '12:30' },
  { key: '780', label: '13:00' },
  { key: '810', label: '13:30' },
  { key: '840', label: '14:00' },
  { key: '870', label: '14:30' },
  { key: '900', label: '15:00' },
  { key: '930', label: '15:30' },
  { key: '960', label: '16:00' },
  { key: '990', label: '16:30' },
  { key: '1020', label: '17:00' },
  { key: '1050', label: '17:30' },
  { key: '1080', label: '18:00' },
  { key: '1110', label: '18:30' },
  { key: '1140', label: '19:00' },
  { key: '1170', label: '19:30' },
  { key: '1200', label: '20:00' },
  { key: '1230', label: '20:30' },
  { key: '1260', label: '21:00' },
  { key: '1290', label: '21:30' },
  { key: '1320', label: '22:00' },
  { key: '1350', label: '22:30' },
  { key: '1380', label: '23:00' },
  { key: '1410', label: '23:30' },
];

export const addonsList = [
  {
    id: 'breakfast',
    labelId: 'Listing.addons.breakfast',
    iconSrc: '/static/icons/addons/breakfast.svg',
  },
  {
    id: 'breakfastBuffet',
    labelId: 'Listing.addons.breakfastBuffet',
    iconSrc: '/static/icons/addons/breakfastBuffet.svg',
  },
  {
    id: 'lunch',
    labelId: 'Listing.addons.lunch',
    iconSrc: '/static/icons/addons/lunch.svg',
  },
  {
    id: 'carte',
    labelId: 'Listing.addons.carte',
    iconSrc: '/static/icons/addons/lunch.svg',
  },
  {
    id: 'lunchBuffet',
    labelId: 'Listing.addons.lunchBuffet',
    iconSrc: '/static/icons/addons/lunch.svg',
  },
  {
    id: 'fika',
    labelId: 'Listing.addons.fika',
    iconSrc: '/static/icons/addons/fika.svg',
  },
  {
    id: 'parking',
    labelId: 'Listing.addons.parking',
    iconSrc: '/static/icons/addons/parking.svg',
    multiplySeats: false,
  },
  {
    id: 'gym',
    labelId: 'Listing.addons.gym',
    iconSrc: '/static/icons/addons/gym.svg',
  },
  {
    id: 'spa',
    labelId: 'Listing.addons.spa',
    iconSrc: '/static/icons/addons/spa.svg',
  },
  {
    id: 'gymAndSpa',
    labelId: 'Listing.addons.gymAndSpa',
    iconSrc: '/static/icons/addons/gymAndSpa.svg',
  },
  {
    id: 'sauna',
    labelId: 'Listing.addons.sauna',
    iconSrc: '/static/icons/addons/sauna.svg',
  },
];

export const sortingList = [
  {
    id: 'email',
    label: 'Email',
  },
  {
    id: 'name',
    label: 'Name',
  },
  {
    id: 'access',
    label: 'Access',
  },
  {
    id: 'credits_used',
    label: 'Credits used',
  },
];
