import classNames from 'classnames';
import { func, number, string } from 'prop-types';
import React from 'react';
import { useIntl } from 'react-intl';
import { NamedLink, PopupModal } from '..';
import { formatMoneyWithSpace, SUBSCRIPTION_VAT } from '../../util/currency';
import { MY_SUBSCRIPTION } from '../../util/types';
import css from './ReactivateSubscriptionSuccessModal.module.scss';
import { IconResumeSubscriptionSuccessModal } from '..';
import { bool } from 'prop-types';

const ReactivateSubscriptionSuccessModal = (props) => {
  const { isOpen, onClose, creditsNumber, totalPrice, renewalDate, isResumeSuccess } = props;
  const intl = useIntl();

  const totalPriceWithVat = (totalPrice * (1 + SUBSCRIPTION_VAT)).toFixed(2);
  return (
    <PopupModal
      id="ReactivateSubscriptionSuccessModal"
      isOpen={isOpen}
      onClose={onClose}
      modalContainer={css.modalContainer}
      hideCloseButton
    >
      <div className={css.container}>
        {isResumeSuccess && <IconResumeSubscriptionSuccessModal className={css.iconSuccess} />}
        <h2 className={css.modalTitle}>
          {intl.formatMessage({ id: 'ReactivateSubscriptionSuccessModal.title' })}
        </h2>
        {isResumeSuccess ? (
          <p className={css.modalDesc}>
            {intl.formatMessage({ id: 'ReactivateSubscriptionSuccessModal.resume.description' })}
          </p>
        ) : (
          <p className={css.modalDesc}>
            {intl.formatMessage({ id: 'ReactivateSubscriptionSuccessModal.activate.description' })}
          </p>
        )}
        {!isResumeSuccess && (
          <>
            <div className={css.row}>
              <div className={classNames(css.rowLabel, css.boldText)}>
                {intl.formatMessage({ id: 'ReactivateSubscriptionSuccessModal.orderDetailLabel' })}
              </div>
              <div className={css.rowValue}>
                {intl.formatMessage(
                  { id: 'ReactivateSubscriptionSuccessModal.renewalDate' },
                  { renewalDate }
                )}
              </div>
            </div>
            <div className={css.row}>
              <div className={css.rowLabel}>
                {intl.formatMessage({ id: 'ReactivateSubscriptionSuccessModal.creditsLabel' })}
              </div>
              <div className={css.rowValue}>{creditsNumber}</div>
            </div>
            <div className={css.row}>
              <div className={css.rowLabel}>
                {intl.formatMessage({ id: 'ReactivateSubscriptionSuccessModal.subTotalLabel' })}
              </div>
              <div className={css.rowValue}>
                {intl.formatMessage(
                  { id: 'ReactivateSubscriptionSuccessModal.subTotal' },
                  {
                    totalPrice: formatMoneyWithSpace(totalPrice),
                  }
                )}
              </div>
            </div>
            <div className={css.row}>
              <div className={css.rowLabel}>
                {intl.formatMessage({ id: 'ReactivateSubscriptionSuccessModal.vatLabel' })}
              </div>
              <div className={css.rowValue}>
                {intl.formatMessage({ id: 'ReactivateSubscriptionSuccessModal.vat' })}
              </div>
            </div>
            <div className={css.horizontalLine}></div>
            <div className={classNames(css.row, css.totalRow)}>
              <div className={classNames(css.rowLabel, css.boldText)}>
                {intl.formatMessage({ id: 'ReactivateSubscriptionSuccessModal.totalLabel' })}
              </div>
              <div className={classNames(css.rowValue, css.boldText)}>
                {intl.formatMessage(
                  { id: 'ReactivateSubscriptionSuccessModal.total' },
                  {
                    totalPrice: formatMoneyWithSpace(totalPriceWithVat),
                  }
                )}
              </div>
            </div>
          </>
        )}
        <div className={css.horizontalLineAbsolute}></div>
        <div className={css.bottomButtons}>
          {isResumeSuccess ? (
            <div className={css.closeBtn} onClick={onClose}>
              {intl.formatMessage({ id: 'ReactivateSubscriptionSuccessModal.close' })}
            </div>
          ) : (
            <NamedLink
              name="SubscriptionManagementPage"
              params={{ tab: MY_SUBSCRIPTION }}
              className={css.closeBtn}
            >
              {intl.formatMessage({ id: 'ReactivateSubscriptionSuccessModal.close' })}
            </NamedLink>
          )}
          <NamedLink name="SearchPage" className={css.searchBtn}>
            {intl.formatMessage({ id: 'ReactivateSubscriptionSuccessModal.search' })}
          </NamedLink>
        </div>
      </div>
    </PopupModal>
  );
};

ReactivateSubscriptionSuccessModal.defaultProps = {};

ReactivateSubscriptionSuccessModal.propTypes = {
  isOpen: bool.isRequired,
  onClose: func.isRequired,
  creditsNumber: number,
  totalPrice: number,
  renewalDate: string,
  isResumeSuccess: bool,
};
export default ReactivateSubscriptionSuccessModal;
