import classNames from 'classnames';
import { bool, oneOf, string } from 'prop-types';
import React, { memo } from 'react';
import { useIntl } from 'react-intl';
import { NamedLink } from '../../components';
import ButtonNew, { BUTTON_SIZES, BUTTON_TYPES, LINK_COLORS } from '../ButtonNew/ButtonNew';

import css from './AdvertisingCard.module.scss';
import matchImage from './match.png';
import passImage from './pass.png';

export const PRODUCT_TYPES = {
  PASS: 'pass',
  MATCH: 'match',
};
PRODUCT_TYPES.LIST = [PRODUCT_TYPES.PASS, PRODUCT_TYPES.MATCH];

const AdvertisingCard = ({ className, isMobile = true, product }) => {
  const intl = useIntl();
  // const isFindAMatch = product === PRODUCT_TYPES.MATCH;
  const isFindAMatch = true;
  return (
    <div className={css.listingCardContainer}>
      <div className={classNames(className, css.listingCard, isMobile && css.mobile)}>
        <NamedLink
          className={css.listingLink}
          name={isFindAMatch ? 'OnboardingPage' : 'RespacesPass'}
          target="_blank"
          rel="noopener noreferrer"
        >
          <div className={classNames(css.imgWrapper, css.green)}>
            {!isFindAMatch && (
              <img className={css.img} src={isFindAMatch ? matchImage : passImage} alt={''} />
            )}
            {isFindAMatch ? (
              <>
                <p className={css.matchCaption}>
                  {intl.formatMessage({
                    id: `AdvertisingCard.titleTop.match`,
                  })}
                </p>
                <h2 className={classNames(css.matchCaption, css.bold)}>
                  {intl.formatMessage({
                    id: `AdvertisingCard.title.match`,
                  })}
                </h2>
              </>
            ) : (
              <>
                <p className={css.caption}>
                  {intl.formatMessage({
                    id: `AdvertisingCard.title.pass`,
                  })}
                </p>
              </>
            )}

            <ButtonNew
              className={css.cta}
              type={BUTTON_TYPES.WHITE}
              size={BUTTON_SIZES.SMALL}
              linkColor={LINK_COLORS.GREEN}
            >
              {intl.formatMessage({
                id: `AdvertisingCard.button.${isFindAMatch ? 'match' : 'pass'}`,
              })}
            </ButtonNew>
          </div>
          <div className={classNames(css.content, isMobile && css.mobile)}>
            <div className={css.top}>
              <h3 className={css.title}>
                {intl.formatMessage({
                  id: `AdvertisingCard.header.${isFindAMatch ? 'match' : 'pass'}`,
                })}
              </h3>
              <span className={css.subheader}>
                {intl.formatMessage({
                  id: `AdvertisingCard.subheader.${isFindAMatch ? 'match' : 'pass'}`,
                })}
              </span>
            </div>
            {isFindAMatch ? (
              <ol className={css.list}>
                <li>{intl.formatMessage({ id: `AdvertisingCard.description.match1` })}</li>
                <li>{intl.formatMessage({ id: `AdvertisingCard.description.match2` })}</li>
                <li>{intl.formatMessage({ id: `AdvertisingCard.description.match3` })}</li>
              </ol>
            ) : (
              <p className={css.bottom}>
                {intl.formatMessage({
                  id: `AdvertisingCard.description.pass`,
                })}
              </p>
            )}
          </div>
        </NamedLink>
      </div>
    </div>
  );
};

AdvertisingCard.defaultProps = {
  className: null,
  isMobile: false,
  product: PRODUCT_TYPES.PASS,
};

AdvertisingCard.propTypes = {
  className: string,
  isMobile: bool,
  product: oneOf(PRODUCT_TYPES.LIST).isRequired,
};

export default memo(AdvertisingCard);
