import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconLocation.module.scss';

const IconPeople = (props) => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);
  return (
    <svg
      className={classes}
      width="14"
      height="20"
      viewBox="0 0 14 20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 7C0 3.13 3.13 0 7 0C10.87 0 14 3.13 14 7C14 12.25 7 20 7 20C7 20 0 12.25 0 7ZM4.5 7C4.5 8.38071 5.61929 9.5 7 9.5C8.38071 9.5 9.5 8.38071 9.5 7C9.5 5.61929 8.38071 4.5 7 4.5C5.61929 4.5 4.5 5.61929 4.5 7Z"
      />
    </svg>
  );
};

IconPeople.defaultProps = { rootClassName: null, className: null };

const { string } = PropTypes;

IconPeople.propTypes = { rootClassName: string, className: string };

export default IconPeople;
