import React, { PureComponent } from 'react';
import { string, bool, arrayOf, array, func, object } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm, FormSpy } from 'react-final-form';
import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import classNames from 'classnames';
import moment from 'moment';
import { required } from '../../util/validators';
import { START_DATE, END_DATE, timeOfDayFromLocalToTimeZone, daysBetween } from '../../util/dates';
import { propTypes, LINE_ITEM_DAY, DAILY_BOOKING } from '../../util/types';
import config from '../../config';
import { Form, IconSpinner, FieldNumberInput, OutsideClickHandler } from '../../components';
import EstimatedBreakdownMaybe from './EstimatedBreakdownMaybe';
import css from './BookingDatesForm.module.scss';
import { BUTTON_TYPES, BUTTON_SIZES } from '../../components_new/ButtonNew/ButtonNew';
import { ButtonNew, DatePickerControl, DatePickerNew } from '../../components_new';
import get from 'lodash/get';
import { convertPriceToCredit } from '../../util/currency';
import { isMobile } from '../../util/device';
import { isDayBlockedFn } from '../../components/FieldDateRangeInput/DateRangeInput.helpers';
import { parse } from '../../util/urlHelpers';

const identity = (v) => v;

const filterTimeSlotBySeats = (timeSlots = [], seats = 1) => {
  return timeSlots.filter((slot) => slot.attributes.seats >= seats);
};

export class BookingDatesFormComponent extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      focusedInput: null,
      datepickerOpen: false,
      selectMultipleDates: false,
      timeSlotFetched: false,
      defaultDateSet: false,
      datesUnavailable: false,
      initialData: typeof window !== 'undefined' ? parse(window.location.search) : {},
    };
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    this.onFocusedInputChange = this.onFocusedInputChange.bind(this);
    this.handleOnChange = this.handleOnChange.bind(this);
    this.updateDateAvailbility = this.updateDateAvailbility.bind(this);
  }

  componentDidUpdate(prevProps) {
    // detect if time slot has changed in order to update the form with current date and/or date from search
    if (prevProps.timeSlots !== this.props.timeSlots) {
      this.setState({ timeSlotFetched: true });
    }
  }

  // Function that can be passed to nested components
  // so that they can notify this component when the
  // focused input changes.
  onFocusedInputChange(focusedInput) {
    this.setState({ focusedInput });
  }

  // In case start or end date for the booking is missing
  // focus on that input, otherwise continue with the
  // default handleSubmit function.
  handleFormSubmit(e) {
    e.endDate = e && e.endDate && moment(e.endDate).add(1, 'd'); //adds one day to end date
    this.props.onSubmit(e);
  }

  updateDateAvailbility(start, end, seats) {
    const startDateDefault = new Date(start);
    let endDateDefault = new Date(end);
    let localizedEndDateDefault = timeOfDayFromLocalToTimeZone(endDateDefault, this.props.timeZone);

    let tempDate = new Date();
    tempDate.setTime(startDateDefault.getTime());
    let localizedTempDate = timeOfDayFromLocalToTimeZone(tempDate, this.props.timeZone);

    const filteredTimeSlotsDefault = filterTimeSlotBySeats(this.props.timeSlots, seats);
    let allDatesAvailable = false;

    // Go through all available time slots and check if the days that we searched for are available so that we can fill those.
    filteredTimeSlotsDefault.forEach((timeSlot) => {
      if (
        timeSlot.attributes.start.toString().substring(0, 15) ===
        localizedTempDate.toString().substring(0, 15)
      ) {
        if (
          localizedTempDate.toString().substring(0, 15) ===
          localizedEndDateDefault.toString().substring(0, 15)
        ) {
          // if we get here, all days that was searched for are available and we set the variable to true.
          allDatesAvailable = true;
        } else {
          // check if next day in search is available
          localizedTempDate.setDate(localizedTempDate.getDate() + 1);
        }
      }
    });

    // if all dates were available, fill in the values.
    if (allDatesAvailable) {
      this.setState({ datesUnavailable: false });
    } else {
      this.setState({ datesUnavailable: true });
    }
    return {
      startDate: startDateDefault,
      endDate: endDateDefault,
    };
  }

  // When the values of the form are updated we need to fetch
  // lineItems from FTW backend for the EstimatedTransactionMaybe
  // In case you add more fields to the form, make sure you add
  // the values here to the bookingData object.
  handleOnChange({ values, valid }) {
    this.updateDateAvailbility(values.startDate, values.endDate, values.seats);
    const startDate = values && values.startDate && moment(values.startDate).toDate();
    const endDate = values && values.endDate && moment(values.endDate).add(1, 'd').toDate();
    const { seats, additionalServices = [] } = values || {};
    const listingId = this.props.listingId;
    const isOwnListing = this.props.isOwnListing;
    const { isEmployeeUser, isCompanyUser } = this.props;
    const isB2B = isEmployeeUser || isCompanyUser;
    if (startDate && endDate && seats && !this.props.fetchLineItemsInProgress && valid) {
      this.props.onFetchTransactionLineItems({
        bookingData: { startDate, endDate, seats: seats, bookingType: 'daily', additionalServices },
        listingId,
        isOwnListing,
        isB2B,
      });

      // Update URL with new booking parameters
      if (typeof window !== 'undefined') {
        const url = new URL(window.location);
        url.searchParams.set('maxSeats', `${values.seats},999`);
        url.searchParams.set('dates', `${startDate.toISOString()},${values.endDate.toISOString()}`);
        window.history.replaceState(null, '', url.toString());
      }
    }
  }

  render() {
    const { rootClassName, className, price: unitPrice, bookingData, ...rest } = this.props;
    const classes = classNames(rootClassName || css.root, className);

    if (!unitPrice) {
      return (
        <div className={classes}>
          <p className={css.error}>
            <FormattedMessage id="BookingDatesForm.listingPriceMissing" />
          </p>
        </div>
      );
    }
    if (unitPrice.currency !== config.currency) {
      return (
        <div className={classes}>
          <p className={css.error}>
            <FormattedMessage id="BookingDatesForm.listingCurrencyInvalid" />
          </p>
        </div>
      );
    }
    return (
      <FinalForm
        {...rest}
        unitPrice={unitPrice}
        onSubmit={this.handleFormSubmit}
        // initialValues={initialData}
        key={this.props.listingId}
        render={(fieldRenderProps) => {
          const {
            endDatePlaceholder,
            startDatePlaceholder,
            formId,
            pristine,
            handleSubmit,
            intl,
            isOwnListing,
            submitButtonWrapperClassName,
            values,
            timeSlots,
            timeZone,
            fetchTimeSlotsError,
            lineItems,
            fetchLineItemsInProgress,
            fetchLineItemsError,
            invalid,
            form,
            additionalServices,
            listing,
            additionalServicesSection,
            isEmployeeUser,
            isEnoughCreditsForBooking,
            isCompanyUser,
            canBookWithCredits,
            listingCredits,
            isRequestBooking,
          } = fieldRenderProps;

          const initialData = this.state.initialData;

          // Get seats from URL
          if (pristine && !values.seats && initialData && initialData.minSeats) {
            values.seats = initialData.minSeats;
          } else if (pristine && !values.seats) {
            values.seats = 1;
          }

          // Get dates from URL
          if (pristine && !values.startDate && initialData && initialData.dates) {
            const [startDate, endDate] = initialData.dates.split(',');
            initialData.startDate = new Date(startDate.replace(' ', 'T')); // formats string to work in safari
            initialData.endDate = new Date(endDate.replace(' ', 'T'));
          }

          const { seats } = values;
          const startDate = values && values.startDate && moment(values.startDate).toDate();
          const endDate = values && values.endDate && moment(values.endDate).add(1, 'd').toDate();
          const filteredTimeSlots = filterTimeSlotBySeats(timeSlots, seats);

          // Fill in date from URL or todays date when start date is missing
          if (
            (this.state.timeSlotFetched &&
              values &&
              !values.startDate &&
              !this.state.defaultDateSet) ||
            (this.state.timeSlotFetched &&
              values &&
              this.prevProps?.listingId?.uuid &&
              this.prevProps?.listingId?.uuid !== this.props.listingId.uuid) // when switching between same category on location page
          ) {
            const dates =
              initialData && initialData.startDate && initialData.endDate
                ? this.updateDateAvailbility(
                    initialData.startDate,
                    initialData.endDate,
                    values.seats ? values.seats : 1
                  )
                : this.updateDateAvailbility(
                    new Date(),
                    new Date(),
                    values.seats ? values.seats : 1
                  );

            if (dates) {
              values.startDate = dates.startDate;
              values.endDate = dates.endDate;
            }
            this.setState({ defaultDateSet: true });
            //When navigating back from checkout we also need to set the default date and remove the extra day from end date
          } else if (
            this.state.timeSlotFetched &&
            values &&
            values.startDate &&
            values.endDate &&
            values.endDate > values.startDate &&
            !this.state.defaultDateSet
          ) {
            const tempEndDate = new Date(values.endDate);
            tempEndDate.setDate(tempEndDate.getDate() - 1);
            const dates = this.updateDateAvailbility(
              values.startDate,
              tempEndDate,
              values.seats ? values.seats : 1
            );
            if (dates) {
              values.startDate = dates.startDate;
              values.endDate = dates.endDate;
            }
            this.setState({ defaultDateSet: true });
          }

          const bookingDateLabel = intl.formatMessage({
            id: 'BookingDatesForm.bookingDateLabel',
          });
          const requiredMessage = intl.formatMessage({
            id: 'BookingDatesForm.requiredDate',
          });
          const startDateErrorMessage = intl.formatMessage({
            id: 'FieldDateRangeInput.invalidStartDate',
          });
          const endDateErrorMessage = intl.formatMessage({
            id: 'FieldDateRangeInput.invalidEndDate',
          });
          const dateErrorMessage = this.state.datesUnavailable ? (
            <p className={css.sideBarError}>
              <FormattedMessage id="BookingDatesForm.invalidDate" />
            </p>
          ) : null;
          const timeSlotsError = fetchTimeSlotsError ? (
            <p className={css.sideBarError}>
              <FormattedMessage id="BookingDatesForm.timeSlotsError" />
            </p>
          ) : null;

          // This is the place to collect breakdown estimation data.
          // Note: lineItems are calculated and fetched from FTW backend
          // so we need to pass only booking data that is needed otherwise
          // If you have added new fields to the form that will affect to pricing,
          // you need to add the values to handleOnChange function
          const bookingData =
            startDate && endDate
              ? {
                  startDate,
                  endDate,
                }
              : null;

          const showEstimatedBreakdown =
            bookingData && lineItems && !fetchLineItemsInProgress && !fetchLineItemsError;

          const bookingInfoMaybe = showEstimatedBreakdown ? (
            <div className={css.priceBreakdownContainer}>
              <EstimatedBreakdownMaybe
                bookingData={bookingData}
                lineItems={lineItems}
                bookingType={DAILY_BOOKING}
                listing={listing}
                hasAdditionalServices={
                  values.additionalServices && values.additionalServices.length > 0
                }
                isCreditTx={isEmployeeUser || isCompanyUser}
              />
            </div>
          ) : null;

          const loadingSpinnerMaybe = fetchLineItemsInProgress ? (
            <IconSpinner className={css.spinner} />
          ) : null;

          const bookingInfoErrorMaybe = fetchLineItemsError ? (
            fetchLineItemsError === 'insufficient credit' ? (
              <span className={css.sideBarError}>
                <FormattedMessage id="BookingDatesForm.fetchLineItemsInsufficientCreditsError" />
              </span>
            ) : (
              <span className={css.sideBarError}>
                <FormattedMessage id="BookingDatesForm.fetchLineItemsError" />
              </span>
            )
          ) : null;

          const dateFormatOptions = {
            weekday: 'short',
            month: 'short',
            day: 'numeric',
          };

          const now = moment();
          const today = now.startOf('day').toDate();
          const tomorrow = now.startOf('day').add(1, 'days').toDate();
          const startDatePlaceholderText =
            startDatePlaceholder || intl.formatDate(today, dateFormatOptions);
          const endDatePlaceholderText =
            endDatePlaceholder || intl.formatDate(tomorrow, dateFormatOptions);
          const submitButtonClasses = classNames(
            submitButtonWrapperClassName || css.submitButtonWrapper
          );

          const seatsLabel = intl.formatMessage({
            id: 'BookingDatesForm.seatsLabel',
          });

          const seatsRequiredMessage = intl.formatMessage({
            id: 'BookingDatesForm.seatsRequiredMessage',
          });

          // const listingTitle = get(listing, 'attributes.title');
          const listingType = get(listing, 'attributes.publicData.category');

          const totalDays = startDate && endDate ? daysBetween(startDate, endDate) : 1;

          const totalCreditsValue =
            listingCredits && listingType === 'meeting'
              ? listingCredits * (totalDays || 1)
              : listingCredits * (values.seats || 1) * (totalDays || 1);

          const submitButtonDisabled =
            invalid ||
            !!((isEmployeeUser || isCompanyUser) && fetchLineItemsError) ||
            this.state.datesUnavailable ||
            !canBookWithCredits ||
            ((isEmployeeUser || isCompanyUser) && !isEnoughCreditsForBooking(seats));

          const formattedStartDate = `${moment(values?.startDate).format('ddd')}, ${moment(
            values?.startDate
          ).format('MMM D')}`;

          const formattedEndDate = `${moment(values?.endDate).format('ddd')}, ${moment(
            values?.endDate
          ).format('MMM D')}`;

          const isSameDate = moment(values?.startDate).isSame(moment(values?.endDate));

          const formattedDate = isSameDate
            ? formattedStartDate
            : `${formattedStartDate} - ${formattedEndDate}`;

          const isDayBlocked = isDayBlockedFn(
            filteredTimeSlots,
            values.startDate,
            values.endDate,
            null,
            LINE_ITEM_DAY
          );

          return (
            <Form onSubmit={handleSubmit} className={classes}>
              {timeSlotsError}
              <FormSpy
                subscription={{ values: true, valid: true }}
                onChange={({ valid, values }) => {
                  if (valid) this.handleOnChange({ valid, values });
                }}
              />

              <div className={css.borderFieldsWrapper}>
                <FieldNumberInput
                  name="seats"
                  id="seats"
                  autoComplete="off"
                  label={seatsLabel}
                  validate={required(seatsRequiredMessage)}
                  className={css.seatsNumberField}
                  inputRootClass={css.seatsNumberInput}
                />
                <div className={css.datepickerContainer}>
                  <label
                    className={classNames(
                      css.datepickerTrigger,
                      fetchLineItemsInProgress && css.disabled
                    )}
                    htmlFor="datepicker_booking_dates_form"
                  >
                    <span className={css.label}>{bookingDateLabel}</span>
                    <input
                      className={css.value}
                      value={values.endDate ? formattedDate : ''}
                      placeholder={startDatePlaceholderText}
                      onFocus={() => {
                        this.setState({ datepickerOpen: true });
                      }}
                      type="text"
                      name="datepicker_booking_dates_form"
                      id="datepicker_booking_dates_form"
                      readOnly
                    />
                  </label>
                  {this.state.datepickerOpen && (
                    <OutsideClickHandler
                      className={css.datepickerPopup}
                      onOutsideClick={() => this.setState({ datepickerOpen: false })}
                    >
                      <DatePickerNew
                        value={{ ...values }}
                        onDatesChange={(val) => {
                          form.batch(() => {
                            form.change('startDate', val.startDate);
                            form.change('endDate', val.endDate);
                          });
                        }}
                        onClose={() => this.setState({ datepickerOpen: false })}
                        selectMultipleDays={this.state.selectMultipleDates}
                        isDayBlocked={isDayBlocked}
                      />
                      <div className={css.datepickerControlWrapper}>
                        <DatePickerControl
                          onChange={() =>
                            this.setState({
                              selectMultipleDates: !this.state.selectMultipleDates,
                            })
                          }
                          isChecked={this.state.selectMultipleDates}
                          isMobile={isMobile}
                          id="booking_dates_form_toggle_multiple_dates"
                          label="Add end date"
                        />
                      </div>
                    </OutsideClickHandler>
                  )}
                </div>
                {/*{seats > 0 && (
                  <FieldDateRangeInput
                    className={css.bookingDates}
                    name="bookingDates"
                    unitType={LINE_ITEM_DAY}
                    startDateId={`${formId}.bookingStartDate`}
                    startDateLabel={bookingStartLabel}
                    startDatePlaceholderText={startDatePlaceholderText}
                    endDateId={`${formId}.bookingEndDate`}
                    endDateLabel={bookingEndLabel}
                    endDatePlaceholderText={endDatePlaceholderText}
                    focusedInput={this.state.focusedInput}
                    onFocusedInputChange={this.onFocusedInputChange}
                    format={identity}
                    timeSlots={filteredTimeSlots}
                    // useMobileMargins
                    hideInputBottomBorder
                    validate={composeValidators(
                      required(requiredMessage),
                      bookingDatesRequired(startDateErrorMessage, endDateErrorMessage)
                    )}
                    disabled={fetchLineItemsInProgress}
                    inputRootClassName={css.dateInput}
                  />
                )}*/}
              </div>
              {dateErrorMessage}

              {(isEmployeeUser || isCompanyUser) && (
                <div className={css.listingCreditInforWrapper}>
                  {/* <div className={css.row}>
                    <div>
                      <FormattedMessage id="BookingDatesForm.location" />
                    </div>
                    <div>
                      <strong>{listingTitle}</strong>
                    </div>
                  </div> */}
                  {/* <div className={css.row}>
                    <div>
                      <FormattedMessage id="BookingDatesForm.typeOfSpace" />
                    </div>
                    <div>
                      <strong>{listingType}</strong>
                    </div>
                  </div> */}
                  <div className={css.row}>
                    <div>
                      <FormattedMessage id="BookingDatesForm.valuePerDay" />
                    </div>
                    <div>
                      <strong>
                        <FormattedMessage
                          id="BookingDatesForm.creditsValue"
                          values={{ credits: totalCreditsValue }}
                        />
                      </strong>
                    </div>
                  </div>
                </div>
              )}

              {seats > 0 && additionalServices && additionalServices.length > 0
                ? additionalServicesSection()
                : null}

              {loadingSpinnerMaybe}
              {bookingInfoErrorMaybe}
              <div className={css.openBookingFormWrapper}>
                <p className={`${css.smallPrint}, ${css.smallPrintMobile}`}>
                  <FormattedMessage
                    id={
                      isOwnListing
                        ? 'BookingDatesForm.ownListing'
                        : isRequestBooking
                        ? 'BookingDatesForm.youWontBeChargedInfoRequest'
                        : 'BookingDatesForm.youWontBeChargedInfo'
                    }
                  />
                </p>

                <div className={css.openBookingForm}>
                  <div className={css.backButton} onClick={this.props.onBackPress}>
                    <svg
                      width="16"
                      height="13"
                      viewBox="0 0 16 13"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M16 6.5L0.999999 6.5M0.999999 6.5L6.45454 12M0.999999 6.5L6.45455 1"
                        stroke="#222222"
                      />
                    </svg>{' '}
                    <FormattedMessage id="BookingPanel.back" />
                  </div>
                  <div className={submitButtonClasses}>
                    <ButtonNew
                      disabled={submitButtonDisabled}
                      type={BUTTON_TYPES.GREEN}
                      size={BUTTON_SIZES.LARGE}
                      block
                    >
                      <FormattedMessage
                        id={
                          isRequestBooking
                            ? 'BookingDatesForm.sendRequest'
                            : 'BookingDatesForm.proceed'
                        }
                      />
                    </ButtonNew>
                  </div>
                </div>
              </div>
              <ButtonNew
                className={css.desktopButton}
                disabled={submitButtonDisabled}
                type={BUTTON_TYPES.GREEN}
                size={BUTTON_SIZES.LARGE}
                block
              >
                <FormattedMessage
                  id={
                    isRequestBooking ? 'BookingDatesForm.sendRequest' : 'BookingDatesForm.proceed'
                  }
                />
              </ButtonNew>
              <p className={css.smallPrint}>
                <FormattedMessage
                  id={
                    isOwnListing
                      ? 'BookingDatesForm.ownListing'
                      : isRequestBooking
                      ? 'BookingDatesForm.youWontBeChargedInfoRequest'
                      : 'BookingDatesForm.youWontBeChargedInfo'
                  }
                />
              </p>
              {bookingInfoMaybe}
            </Form>
          );
        }}
      />
    );
  }
}

BookingDatesFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  submitButtonWrapperClassName: null,
  price: null,
  isOwnListing: false,
  startDatePlaceholder: null,
  endDatePlaceholder: null,
  timeSlots: null,
  lineItems: null,
  fetchLineItemsError: null,
  defaultSearch: null,
  bookingData: null,
};

BookingDatesFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  submitButtonWrapperClassName: string,

  unitType: propTypes.bookingUnitType.isRequired,
  price: propTypes.money,
  isOwnListing: bool,
  timeSlots: arrayOf(propTypes.timeSlot),

  onFetchTransactionLineItems: func.isRequired,
  lineItems: array,
  fetchLineItemsInProgress: bool.isRequired,
  // fetchLineItemsError: propTypes.error,
  defaultSearch: object,
  additionalServicesSection: func.isRequired,
  bookingData: object,

  // from injectIntl
  intl: intlShape.isRequired,

  // for tests
  startDatePlaceholder: string,
  endDatePlaceholder: string,
};

const BookingDatesForm = compose(injectIntl)(BookingDatesFormComponent);
BookingDatesForm.displayName = 'BookingDatesForm';

export default BookingDatesForm;
