import React from 'react';
import { Link } from 'react-router-dom';
import { string, bool, object, any, shape, oneOf } from 'prop-types';
import classNames from 'classnames';

import { DEFAULT_SEARCH_PARAMS } from '../../util/search';
import { IconSpinner, IconCheckmark, NamedLink } from '../../components';

import css from './ButtonNew.module.scss';

export const BUTTON_TYPES = {
  PRIMARY: 'primary',
  SECONDARY: 'secondary',
  RED: 'red',
  GREEN: 'green',
  GREENWITHWHITEBORDER: 'greenWithWhiteBorder',
  BLUE: 'blue',
  WHITE: 'white',
  INLINE: 'inline',
  TRANSPARENT: 'transparent',
};

BUTTON_TYPES.LIST = [
  BUTTON_TYPES.PRIMARY,
  BUTTON_TYPES.SECONDARY,
  BUTTON_TYPES.RED,
  BUTTON_TYPES.GREEN,
  BUTTON_TYPES.GREENWITHWHITEBORDER,
  BUTTON_TYPES.BLUE,
  BUTTON_TYPES.WHITE,
  BUTTON_TYPES.INLINE,
  BUTTON_TYPES.TRANSPARENT,
];

export const BUTTON_SIZES = {
  X_SMALL: 'xs',
  SMALL: 's',
  MEDIUM: 'm',
  LARGE: 'l',
};

BUTTON_SIZES.LIST = [
  BUTTON_SIZES.X_SMALL,
  BUTTON_SIZES.SMALL,
  BUTTON_SIZES.MEDIUM,
  BUTTON_SIZES.LARGE,
];

export const LINK_COLORS = {
  BLACK: 'blackText',
  BLUE: 'blueText',
  WHITE: 'whiteText',
  GREEN: 'greenText',
};

LINK_COLORS.LIST = [LINK_COLORS.BLACK, LINK_COLORS.BLUE, LINK_COLORS.WHITE, LINK_COLORS.GREEN];

export const MOBILE_POSITION = {
  LEFT: 'left',
  RIGHT: 'right',
  CENTER: 'center',
  FULL_WIDTH: 'fullWidth',
};

MOBILE_POSITION.LIST = [
  MOBILE_POSITION.LEFT,
  MOBILE_POSITION.RIGHT,
  MOBILE_POSITION.CENTER,
  MOBILE_POSITION.FULL_WIDTH,
];

const Arrow = ({ className }) => (
  <svg
    className={className}
    width="18"
    height="14"
    viewBox="0 0 18 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M0 7H16M16 7L10.1818 1M16 7L10.1818 13" strokeWidth="2" />
  </svg>
);

class ButtonNew extends React.Component {
  render() {
    const {
      style,
      className,
      spinnerClassName,
      checkmarkClassName,
      type,
      size,
      linkColor,
      mobileButtonPosition,
      textLight,
      withIcon,
      iconReversed,
      block,
      underlined,
      name,
      params,
      to,
      children,
      match,
      inProgress,
      ready,
      disabled,
      nativeType,
      onClick,
      externalLink,
    } = this.props;

    const buttonClassList = classNames(
      css.button,
      className,
      css[type],
      css[size],
      css[linkColor],
      css[mobileButtonPosition],
      block && css.block,
      textLight && css.textLight,
      underlined && css.underlined
    );

    const linkClassList = classNames(
      buttonClassList,
      css[linkColor],
      underlined && css.underlined,
      disabled && css.disabled
    );

    const arrowClassList = classNames(css.arrow, css[linkColor], iconReversed && css.arrowReversed);

    let content;

    if (inProgress) {
      content = <IconSpinner rootClassName={spinnerClassName || css.spinner} />;
    } else if (ready) {
      content = <IconCheckmark rootClassName={checkmarkClassName || css.checkmark} />;
    } else {
      content = (
        <>
          {withIcon && iconReversed && <Arrow className={arrowClassList} />}
          {children}
          {withIcon && !iconReversed && <Arrow className={arrowClassList} />}
        </>
      );
    }

    if (!!externalLink) {
      return (
        <a className={linkClassList} href={externalLink} target="_blank" rel="noreferrer noopener">
          {content}
        </a>
      );
    }
    /* Name is sufficient prop to render a link to other route. If there is no name provided and there is a text (children),
     * button is rendered */
    if (name === 'SearchPage') {
      return (
        <Link
          style={style}
          params={params}
          to={DEFAULT_SEARCH_PARAMS}
          match={match}
          name={name}
          className={linkClassList}
        >
          {content}
        </Link>
      );
    }
    return name ? (
      <NamedLink
        style={style}
        params={params}
        to={to}
        match={match}
        name={name}
        state={to?.state} /* TODO: Refactor! */
        className={linkClassList}
      >
        {content}
      </NamedLink>
    ) : children ? (
      <button
        type={nativeType}
        style={style}
        onClick={onClick}
        className={buttonClassList}
        disabled={disabled}
      >
        {content}
      </button>
    ) : null;
  }
}

ButtonNew.defaultProps = {
  className: null,
  spinnerClassName: null,
  checkmarkClassName: null,
  block: false,
  type: BUTTON_TYPES.PRIMARY,
  size: BUTTON_SIZES.SMALL,
  linkColor: LINK_COLORS.BLACK,
  mobileButtonPosition: MOBILE_POSITION.LEFT,
  textLight: false,
  name: null,
  params: null,
  to: null,
  children: null,
  match: null,
  inProgress: false,
  ready: false,
  disabled: false,
  withIcon: false,
};

ButtonNew.propTypes = {
  /* Styles */
  className: string,
  spinnerClassName: string,
  checkmarkClassName: string,
  block: bool,
  underlined: bool,
  type: oneOf(BUTTON_TYPES.LIST),
  size: oneOf(BUTTON_SIZES.LIST),
  linkColor: oneOf(LINK_COLORS.LIST),
  mobileButtonPosition: oneOf(MOBILE_POSITION.LIST),
  withIcon: bool,
  iconReversed: bool,
  textLight: bool,
  style: object,

  /* Navigation props */
  externalLink: string,
  name: string,
  params: object, // params object for the named route
  to: shape({ search: string, hash: string, state: object }), // Link component props
  children: any,
  match: object, // from withRouter

  /* State */
  inProgress: bool,
  ready: bool,
  disabled: bool,
  nativeType: oneOf(['button', 'submit', 'reset']),
};

export default ButtonNew;
