import React from 'react';

import css from './LocationAutocompleteInput.module.scss';

const IconCurrentLocation = () => (
  <svg
    className={css.currentLocationIcon}
    width="14"
    height="14"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.86846 4.09607C8.38579 4.09607 9.61583 5.31865 9.61583 6.82678C9.61583 8.33491 8.38579 9.5575 6.86846 9.5575C5.35113 9.5575 4.12109 8.33491 4.12109 6.82678C4.12109 5.31865 5.35113 4.09607 6.86846 4.09607Z"
      fill="black"
    />
    <path
      d="M7.55526 12.2411V13.6536H6.18158V12.2411C4.97294 12.0877 3.84973 11.5399 2.98815 10.6835C2.12658 9.82719 1.5754 8.71079 1.42108 7.50949H0V6.14414H1.42108C1.57517 4.94274 2.12628 3.82622 2.9879 2.96983C3.84951 2.11344 4.97285 1.56568 6.18158 1.41252V6.10352e-05H7.55526V1.41252C8.76403 1.56556 9.88743 2.11329 10.7491 2.9697C11.6107 3.82611 12.1618 4.9427 12.3158 6.14414H13.7368V7.50949H12.3158C12.1617 8.71088 11.6106 9.8274 10.7489 10.6838C9.88733 11.5402 8.76399 12.0879 7.55526 12.2411ZM6.86842 2.73076C4.59566 2.73076 2.74737 4.56784 2.74737 6.82681C2.74737 9.08578 4.59566 10.9229 6.86842 10.9229C9.14118 10.9229 10.9895 9.08578 10.9895 6.82681C10.9895 4.56784 9.14118 2.73076 6.86842 2.73076Z"
      fill="black"
    />
  </svg>
);

export default IconCurrentLocation;
