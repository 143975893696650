import get from 'lodash/get';
import React from 'react';
import { useIntl } from 'react-intl';
import { formatMoney } from '../../util/currency';
import { FormattedMessage } from '../../util/reactIntl';
import { types } from '../../util/sdkLoader';
import { propTypes } from '../../util/types';
import css from './BookingBreakdown.module.scss';
const { Money } = types;

const calculateTotalVAT = (lineItems, isCreditTx) => {
  const total = lineItems
    .filter((i) => (isCreditTx ? i.code.includes('-fee') : i))
    .reduce((prev, cur) => {
      if (cur.vatPercentage) {
        prev +=
          cur.lineTotal.amount - cur.lineTotal.amount / (1 + parseFloat(cur.vatPercentage) / 100);
      }
      return prev;
    }, 0);

  return new Money(total, lineItems[0].lineTotal.currency);
};
const LineItemVAT = (props) => {
  const { isCreditTx, transaction, isProvider } = props;
  const intl = useIntl();
  const txTotalVAT = get(transaction, 'attributes.protectedData.totalVAT', null);
  const txServicesVAT = get(transaction, 'attributes.protectedData.totalServicesVAT', null);
  // total vat for services is created in server lineitems for credit transactions
  // for estimated breakdown(used listing page) we calculateTotalVAT on additional services only if isCreditTx
  const totalVAT =
    txTotalVAT && !isCreditTx
      ? new Money(txTotalVAT.amount * 100, txTotalVAT.currency)
      : isCreditTx && txTotalVAT && isProvider
      ? new Money(txTotalVAT.amount * 100, txTotalVAT.currency)
      : isCreditTx && txServicesVAT
      ? new Money(txServicesVAT.amount * 100, txTotalVAT.currency)
      : calculateTotalVAT(transaction.attributes.lineItems, isCreditTx);

  const formattedMoney = formatMoney(intl, totalVAT);

  return (
    <div className={css.lineItemVat}>
      <span className={css.itemLabel}>
        <FormattedMessage id="BookingBreakdown.vat" />
      </span>
      <span className={css.itemValueVAT}>{formattedMoney}</span>
    </div>
  );
};

LineItemVAT.propTypes = {
  transaction: propTypes.transaction.isRequired,
};

export default LineItemVAT;
