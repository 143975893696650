import React, { useEffect, useMemo, useState } from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import {
  SubscriptionManagementIcons as Icons,
  PaginationLinks,
  IconSpinner,
  NamedLink,
} from '../../components';
import css from './UsageLogTab.module.scss';
import moment from 'moment';
import { calcRenewalDayLeft } from '../../util/b2bHelper';
import { Tooltip } from '../../components_new';
import { useToggleCompoment } from '../../util/hooks';
import sortBy from 'lodash/sortBy';
import reverse from 'lodash/reverse';
import classNames from 'classnames';
import { useLocation } from 'react-router';
import { parse } from '../../util/urlHelpers';
import get from 'lodash/get';
import { calcTotalUsedCreditsFromBooking } from './utils/credits';
import {
  MY_SUBSCRIPTION,
  MY_SUBSCRIPTION_EDIT_ACTION,
  MY_SUBSCRIPTION_ADD_ONE_TIME_CREDITS_ACTION,
  PACKAGE_FREE,
  PACKAGE_CREDITS,
} from '../../util/types';

const PER_PAGE = 10;

const SORTING_BY_EMAIL = 'email';
const SORTING_BY_NAME = 'name';
const SORTING_BY_TYPE = 'type';
const SORTING_BY_DATE = 'date';
const SORTING_BY_LOCATION = 'location';
const SORTING_BY_CREDITS = 'credits';
const sortingList = [
  {
    id: SORTING_BY_EMAIL,
    label: 'Email',
  },
  {
    id: SORTING_BY_NAME,
    label: 'Name',
  },
  {
    id: SORTING_BY_TYPE,
    label: 'Type',
  },
  {
    id: SORTING_BY_DATE,
    label: 'Date',
  },
  {
    id: SORTING_BY_LOCATION,
    label: 'Location',
  },
  {
    id: SORTING_BY_CREDITS,
    label: 'Credits',
  },
];

const UsageLogTab = (props) => {
  const {
    isMobileLayout,
    intl,
    fetchInProgress,
    totalCredits,
    usedCredits,
    usageLogBooking,
    teamMembersNumber,
    params,
    companyAccount,
    nextTotalCredits,
    previousUnusedCredits,
    isMonthlySubscription,
    expiryDate,
  } = props;
  const [bookingLogInPage, setBookingLogInPage] = useState([]);
  const [sortingBoardOpen, setSortingBoardOpen] = useState(false);
  const [sortingType, setSortingType] = useState(SORTING_BY_DATE);
  const [sortingIncreasing, setSortingIncreasing] = useState(false);

  const { componentRef: sortingBoardRef, parentComponentRef: tabContainerRef } = useToggleCompoment(
    () => setSortingBoardOpen(false)
  );
  const totalCreditsWithPreviousCredits = totalCredits + previousUnusedCredits;

  const unusedCredits = totalCreditsWithPreviousCredits - usedCredits;

  const remainingCredits = totalCreditsWithPreviousCredits - usedCredits;

  const remainingCreditsText = (
    <span>
      <b>{`${usedCredits}/${totalCreditsWithPreviousCredits}`}</b>
      &nbsp;
      {intl.formatMessage({ id: 'UsageLogTab.credit.remaining' })}
    </span>
  );

  const previousUnusedCreditsText = (
    <FormattedMessage
      values={{ previousUnusedCredits }}
      id="UsageLogTab.credit.previousUnusedCreditsMessage"
    />
  );

  const { freeTrialExpired, selectedPackage } = get(
    companyAccount,
    'attributes.profile.protectedData',
    {}
  );

  const location = useLocation();
  const { search } = location;
  const { page = 1 } = parse(search);

  const totalPages =
    usageLogBooking.length % PER_PAGE > 0
      ? parseInt(usageLogBooking.length / PER_PAGE) + 1
      : parseInt(usageLogBooking.length / PER_PAGE);
  const pagination = {
    totalPages: totalPages,
    totalItems: usageLogBooking.length,
    page: page,
    perPage: PER_PAGE,
  };
  const usedCreditsBarSize = {
    width: `${Math.min(parseInt((usedCredits / totalCreditsWithPreviousCredits) * 100), 100)}%`,
  };
  const shouldShowUsedCreditText =
    parseInt((usedCredits / totalCreditsWithPreviousCredits) * 100) > 20;

  const unusedCreditsBarSize = {
    width: `${parseInt(100 - (usedCredits / totalCreditsWithPreviousCredits) * 100)}%`,
  };
  const shouldShowUnusedCreditText =
    parseInt(100 - (usedCredits / totalCreditsWithPreviousCredits) * 100) > 20;

  const sortingProcess = useMemo(() => {
    switch (sortingType) {
      case SORTING_BY_EMAIL:
        return sortingIncreasing
          ? sortBy(usageLogBooking, ['customer.attributes.email'])
          : reverse(sortBy(usageLogBooking, ['customer.attributes.email']));
      case SORTING_BY_NAME: {
        return sortingIncreasing
          ? sortBy(usageLogBooking, [
              'customer.attributes.profile.firstName',
              'customer.attributes.profile.lastName',
            ])
          : reverse(
              sortBy(usageLogBooking, [
                'customer.attributes.profile.firstName',
                'customer.attributes.profile.lastName',
              ])
            );
      }
      case SORTING_BY_TYPE: {
        return sortingIncreasing
          ? sortBy(usageLogBooking, ['listing.attributes.publicData.category'])
          : reverse(sortBy(usageLogBooking, ['listing.attributes.publicData.category']));
      }
      case SORTING_BY_LOCATION: {
        return sortingIncreasing
          ? sortBy(usageLogBooking, ['listing.attributes.title'])
          : reverse(sortBy(usageLogBooking, ['listing.attributes.title']));
      }
      case SORTING_BY_DATE: {
        return sortingIncreasing
          ? sortBy(usageLogBooking, (o) => moment(o.booking.attributes.start).format('YYYYMMDD'))
          : reverse(
              sortBy(usageLogBooking, (o) => moment(o.booking.attributes.start).format('YYYYMMDD'))
            );
      }
      case SORTING_BY_CREDITS: {
        return sortingIncreasing
          ? sortBy(usageLogBooking, (o) => calcTotalUsedCreditsFromBooking(o))
          : reverse(sortBy(usageLogBooking, (o) => calcTotalUsedCreditsFromBooking(o)));
      }
      default:
        break;
    }
  }, [sortingIncreasing, sortingType, usageLogBooking]);

  useEffect(() => {
    const newBookingLog = sortingProcess;
    setBookingLogInPage([...newBookingLog.slice((page - 1) * PER_PAGE, page * PER_PAGE)]);
  }, [usageLogBooking, page, sortingProcess]);

  const pagingLinks =
    !isMobileLayout && pagination && pagination.totalPages > 1 ? (
      <PaginationLinks
        className={css.pagination}
        rootClassName={css.paginationWrapper}
        pageName="SubscriptionManagementPage"
        pagePathParams={params}
        pagination={pagination}
      />
    ) : null;

  const renderBookingLogItem = (bookingLog) => {
    const { id, customer, booking, listing } = bookingLog;
    const email = customer && customer.attributes.email;
    const { firstName, lastName } = customer && customer.attributes.profile;
    const fullName = `${firstName} ${lastName}`;
    const displayStart = get(booking, 'attributes.displayStart', '');
    const formattedStartDate = moment(displayStart).format('MMM D');
    const location = listing && listing.attributes.title;
    const type = get(listing, 'attributes.publicData.category', '');
    const usedCreditsForBooking = calcTotalUsedCreditsFromBooking(bookingLog);
    return isMobileLayout ? (
      <div className={css.row} key={id.uuid}>
        <div className={css.member}>
          <div className={css.memberUserWrapper}>
            <span className={css.memberUserName}>{fullName}</span>
            <span className={css.memberEmail}>{formattedStartDate}</span>
          </div>
        </div>
        <div className={css.locationWrapper}>
          <div className={css.location}>{location}</div>
          <div className={css.memberEmail}>{type}</div>
        </div>
      </div>
    ) : (
      <div className={css.row} key={id.uuid}>
        <div className={css.member}>
          <div className={css.memberIcon}>M</div>
          <div className={css.memberUserWrapper}>
            <span className={css.memberUserName}>{fullName}</span>
            <span className={css.memberEmail}>{email}</span>
          </div>
        </div>
        <div className={css.type}>{type}</div>
        <div className={css.credits}>{usedCreditsForBooking}</div>
        <div className={css.date}>{formattedStartDate}</div>
        <Tooltip content={location} className={css.location}>
          <div className={css.overflow}>{location}</div>
        </Tooltip>
      </div>
    );
  };

  const sortByText = intl.formatMessage({
    id: 'TeamAccessTab.sortBy',
  });

  const memberColumnName = intl.formatMessage({
    id: 'UsageLogTab.memberColumnName',
  });

  const locationColumnName = intl.formatMessage({
    id: 'UsageLogTab.locationColumnName',
  });

  const dateColumnName = intl.formatMessage({
    id: 'UsageLogTab.dateColumnName',
  });

  const typeColumnName = intl.formatMessage({
    id: 'UsageLogTab.typeColumnName',
  });

  const creditsColumName = intl.formatMessage({
    id: 'UsageLogTab.creditsColumName',
  });

  const getSortingType = () => {
    return sortingList.find((item) => item.id === sortingType).label;
  };

  const handleOpenSortingBoard = () => {
    setSortingBoardOpen(true);
  };

  const handleChangeSortingType = (type) => () => {
    setSortingType(type);
    setSortingBoardOpen(false);
  };

  const handleChangeSortingOrder = () => {
    setSortingIncreasing(!sortingIncreasing);
  };

  const renderBoldText = (text) => <span className={css.boldText}>{text}</span>;

  const upcomingTotalCredits = nextTotalCredits ? nextTotalCredits : totalCredits;

  const subscriptionRenewalDayLeft = calcRenewalDayLeft(companyAccount);

  //Used credits amount on totalCredits and previousCredits
  const usedCreditsInCurrentCycle =
    !previousUnusedCredits || usedCredits >= previousUnusedCredits
      ? usedCredits - previousUnusedCredits
      : 0;
  const usedCreditsInPreviousCycle =
    !previousUnusedCredits || usedCredits >= previousUnusedCredits
      ? previousUnusedCredits
      : usedCredits;

  //Available credits amount on totalCredits and previousCredits
  const unusedCreditsInCurrentCycle =
    !previousUnusedCredits || usedCredits >= previousUnusedCredits
      ? previousUnusedCredits - (usedCredits - totalCredits)
      : totalCredits;
  const unusedCreditsInPreviousCycle =
    !previousUnusedCredits || usedCredits >= previousUnusedCredits
      ? 0
      : previousUnusedCredits - usedCredits;

  const usedCreditsToolTipContent = (
    <div className={css.usageToolTipWrapper}>
      <div className={css.usageToolTipLabels}>
        <span className={css.usageToolTipLabel}>
          <FormattedMessage id="UsageLogTab.currentCycle" />
        </span>
        <span className={css.usageToolTipLabel}>
          <FormattedMessage id="UsageLogTab.rollOver" />
        </span>
      </div>
      <div className={css.usageToolTipLabels}>
        <span className={css.usageToolTipLabel}>{usedCreditsInCurrentCycle}</span>
        <span className={css.usageToolTipLabel}>{usedCreditsInPreviousCycle}</span>
      </div>
    </div>
  );

  const unsedCreditsToolTipContent = (
    <div className={css.usageToolTipWrapper}>
      <div className={css.usageToolTipLabels}>
        <span className={css.usageToolTipLabel}>
          <FormattedMessage id="UsageLogTab.currentCycle" />
        </span>
        <span className={css.usageToolTipLabel}>
          <FormattedMessage id="UsageLogTab.rollOver" />
        </span>
      </div>
      <div className={css.usageToolTipLabels}>
        <span className={css.usageToolTipLabel}>{unusedCreditsInCurrentCycle}</span>
        <span className={css.usageToolTipLabel}>{unusedCreditsInPreviousCycle}</span>
      </div>
    </div>
  );

  const creditsAsPercentLeft = (remainingCredits / totalCredits) * 100;

  const shouldShowUpgradeNotification =
    selectedPackage !== PACKAGE_CREDITS &&
    ((subscriptionRenewalDayLeft >= 10 && creditsAsPercentLeft <= 20) || !!freeTrialExpired);

  const oneTimeSubscriptionLink = (
    <NamedLink
      className={css.notifyLink}
      name="SubscriptionManagementPageMySubscription"
      params={{ tab: MY_SUBSCRIPTION, action: MY_SUBSCRIPTION_ADD_ONE_TIME_CREDITS_ACTION }}
    >
      <FormattedMessage id="UsageLogTab.oneTimeSubText" />
    </NamedLink>
  );
  const upgradeSubscriptionLink = (
    <NamedLink
      className={css.notifyLink}
      name="SubscriptionManagementPageMySubscription"
      params={{ tab: MY_SUBSCRIPTION, action: MY_SUBSCRIPTION_EDIT_ACTION }}
    >
      <FormattedMessage id="UsageLogTab.upgradeSubText" />
    </NamedLink>
  );

  const upgradeSubscriptionNotifyBoxMaybe = shouldShowUpgradeNotification && (
    <div className={css.upgradeNotifyWrapper}>
      <span className={css.upgradeNotifyContent}>
        <FormattedMessage
          id={
            freeTrialExpired && selectedPackage === PACKAGE_FREE
              ? 'UsageLogTab.upgradeContentAfterExpiredFreeTrial'
              : 'UsageLogTab.upgradeContent'
          }
          values={{
            creditLeft: remainingCredits,
            dayLeft: subscriptionRenewalDayLeft,
            oneTimeSubscriptionLink: oneTimeSubscriptionLink,
            upgradeSubscriptionLink: upgradeSubscriptionLink,
          }}
        />
      </span>
    </div>
  );

  return (
    <div className={css.tabContainer} ref={tabContainerRef}>
      {upgradeSubscriptionNotifyBoxMaybe}
      <div className={css.availableCreditsWrapper}>
        <div className={css.availableCredits}>
          <div className={css.title}>
            <FormattedMessage id="UsageLogTab.credit" />
          </div>
          {isMonthlySubscription && (
            <div className={css.renewalDate}>
              <FormattedMessage
                id="UsageLogTab.credit.renewal"
                values={{
                  upcomingTotalCredits: upcomingTotalCredits,
                  dayLeft: renderBoldText(subscriptionRenewalDayLeft),
                }}
              />
            </div>
          )}
        </div>
        {!freeTrialExpired && selectedPackage === PACKAGE_FREE && expiryDate && (
          <div className={css.remaining}>
            <div>{remainingCreditsText}</div>
            <div>
              {intl.formatMessage({ id: 'UsageLogTab.expiryDate' })}
              {expiryDate}
            </div>
            {isMonthlySubscription && <div>{previousUnusedCreditsText}</div>}
          </div>
        )}
      </div>
      <div className={css.creditsDisplaybar}>
        <div style={usedCreditsBarSize} className={css.usedCreditsBar}>
          <Tooltip
            toolTipClassName={css.usedCreditToolTip}
            direction="top"
            className={css.toolTipWrapper}
            content={usedCreditsToolTipContent}
          >
            {!isMobileLayout && shouldShowUsedCreditText ? (
              <FormattedMessage
                id="UsageLogTab.usedCredits"
                values={{
                  credits: usedCredits,
                }}
              />
            ) : (
              <div className={css.blank}>A</div>
            )}
          </Tooltip>
        </div>
        <div style={unusedCreditsBarSize} className={css.unusedCredits}>
          <Tooltip
            toolTipClassName={css.unusedCreditToolTip}
            direction="top"
            className={css.toolTipWrapper}
            content={unsedCreditsToolTipContent}
          >
            {!isMobileLayout && shouldShowUnusedCreditText ? (
              <FormattedMessage
                id="UsageLogTab.unusedCredits"
                values={{
                  credits: unusedCredits,
                }}
              />
            ) : (
              <div className={css.blank}>A</div>
            )}
          </Tooltip>
        </div>
      </div>

      {fetchInProgress ? (
        <div className={css.pageLoading}>
          <IconSpinner />
        </div>
      ) : (
        <>
          <div className={css.inforWrapper}>
            <span className={css.memberNumber}>
              <strong>{teamMembersNumber}</strong>&nbsp;
              <FormattedMessage
                id="UsageLogTab.membersCount"
                values={{
                  count: teamMembersNumber,
                }}
              />
            </span>
            <div className={css.sortingWrapper}>
              {!isMobileLayout && (
                <Icons.ArrowDownIcon
                  className={classNames(css.sortingIcon, {
                    [css.reverse]: sortingIncreasing,
                  })}
                  onClick={handleChangeSortingOrder}
                />
              )}
              <span className={css.sortingBy} onClick={handleOpenSortingBoard}>
                {sortByText}&nbsp;<strong>{getSortingType()}</strong>
              </span>
              {sortingBoardOpen && (
                <div className={css.sortingBoardWrapper} ref={sortingBoardRef}>
                  {sortingList.map((item) => (
                    <div
                      className={classNames(css.sortingItem, {
                        [css.active]: item.id === sortingType,
                      })}
                      onClick={handleChangeSortingType(item.id)}
                    >
                      {item.label}
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>

          <div className={css.bookingTableWrapper}>
            <div className={css.tableHeader}>
              <span>{memberColumnName}</span>
              <span>{locationColumnName}</span>
            </div>

            <div className={css.tableBody}>{sortingProcess.map(renderBookingLogItem)}</div>
          </div>
          <div className={css.bookingTableDesktopWrapper}>
            <div className={css.tableHeader}>
              <span>{memberColumnName}</span>
              <span>{typeColumnName}</span>
              <span>{creditsColumName}</span>
              <span>{dateColumnName}</span>
              <span>{locationColumnName}</span>
            </div>
            <div className={css.tableBody}>{bookingLogInPage.map(renderBookingLogItem)}</div>
          </div>
          {pagingLinks}
        </>
      )}
    </div>
  );
};

export default UsageLogTab;
