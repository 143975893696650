import React, { useState, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { string, objectOf, shape } from 'prop-types';

import config from '../../config';
import { smartOpeningHours } from '../../lib/smartOpeningHours';

import css from './OpeningHours.module.scss';

const Item = ({ days, openTime, closeTime }) => {
  const isMultiple = !!days.match(/-/);
  const dayNames = isMultiple ? days.split('-') : days;

  const start = config.custom.HOUR_OPTIONS.find((o) => o.key === openTime)?.label;
  const end = config.custom.HOUR_OPTIONS.find((o) => o.key === closeTime)?.label;

  if (start && end) {
    // exists for locations
    return (
      <li key={`opening_hours_grouped_${days}`} className={css.openTime}>
        <p className={css.weekday}>
          {Array.isArray(dayNames) ? (
            dayNames.map((day, i) => (
              <React.Fragment key={`opening_hours_${day}`}>
                <FormattedMessage id={`MarketplaceConfig.day.short.${day}`} />
                {i === 0 && ' - '}
              </React.Fragment>
            ))
          ) : (
            <FormattedMessage id={`MarketplaceConfig.day.short.${dayNames}`} />
          )}
        </p>
        <p>
          {start} - {end}
        </p>
      </li>
    );
  } else {
    return (
      // will get here for listings
      <li key={`opening_hours_grouped_${days}`} className={css.openTime}>
        <p className={css.weekday}>
          {Array.isArray(dayNames) ? (
            dayNames.map((day, i) => (
              <React.Fragment key={`opening_hours_${day}`}>
                <FormattedMessage id={`MarketplaceConfig.day.short.${day}`} />
                {i === 0 && ' - '}
              </React.Fragment>
            ))
          ) : (
            <FormattedMessage id={`MarketplaceConfig.day.short.${dayNames}`} />
          )}
        </p>
        <p>
          {openTime} - {closeTime}
        </p>
      </li>
    );
  }
};

const OpeningHours = ({ daysList }) => {
  const [formattedData, setFormattedData] = useState([]);

  useEffect(() => {
    setFormattedData(smartOpeningHours(daysList));
  }, [daysList]);

  return <ul className={css.daysList}>{formattedData.map(Item)}</ul>;
};

OpeningHours.propTypes = {
  daysList: objectOf(
    shape({
      closeTime: string,
      openTime: string,
    })
  ).isRequired,
};

export default OpeningHours;
