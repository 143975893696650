import React from 'react';
import classNames from 'classnames';

const SortByIcon = ({ className, rotated, rotatedClassName }) => (
  <svg
    className={classNames(className, rotated && rotatedClassName)}
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M6 1.33301V10.6663" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M10.6666 6L5.99998 10.6667L1.33331 6" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

export default SortByIcon;
