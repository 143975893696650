import React, { useState } from 'react';
import {
  Footer,
  LayoutSingleColumn,
  LayoutWrapperFooter,
  LayoutWrapperMain,
  LayoutWrapperTopbar,
} from '../../components';
import { ButtonNew, InfoModal, DropDownList, MediaAndTextSection } from '../../components_new';
import { BUTTON_SIZES, BUTTON_TYPES, LINK_COLORS } from '../../components_new/ButtonNew/ButtonNew';
import { StaticPage, TopbarContainer } from '..';
import { injectIntl, intlShape } from '../../util/reactIntl';

import { isMobile } from '../../util/device';

import FAQImage from './assets/faq.svg';
import SectionContact from './SectionContact';
import SectionGetStarted from './SectionGetStarted';
import SectionForPropertyOwners from './SectionForPropertyOwners';
import SectionPartners from './SectionPartners';
import SectionHero from './SectionHero';
import SectionTesimonials from './SectionTestimonials';
import SectionWhyYouShouldListYourSpace from './SectionWhyYouShouldListYourSpace';
import SectionYourTerms from './SectionYourTerms';
import css from './PartnerProffessional.module.scss';

const name = 'support';
const domain = 'respaces.co';

const PartnerProffessional = ({ intl }) => {
  const [isModalOpen, setModalOpen] = useState(false);

  const FAQs = [
    {
      question: intl.formatMessage({ id: 'PartnerProffessional.faq.question1' }),
      answer: intl.formatMessage({ id: 'PartnerProffessional.faq.answer1' }),
    },
    {
      question: intl.formatMessage({ id: 'PartnerProffessional.faq.question2' }),
      answer: intl.formatMessage({ id: 'PartnerProffessional.faq.answer2' }),
    },
    {
      question: intl.formatMessage({ id: 'PartnerProffessional.faq.question3' }),
      answer: intl.formatMessage({ id: 'PartnerProffessional.faq.answer3' }),
    },
    {
      question: intl.formatMessage({ id: 'PartnerProffessional.faq.question4' }),
      answer: intl.formatMessage({ id: 'PartnerProffessional.faq.answer4' }),
    },
    {
      question: intl.formatMessage({ id: 'PartnerProffessional.faq.question5' }),
      answer: intl.formatMessage({ id: 'PartnerProffessional.faq.answer5' }),
    },
  ];

  const onAPIClick = () => {
    if (typeof window !== 'undefined') {
      window.location.href = `mailto: support@respaces.co`;
    }
  };

  return (
    <StaticPage
      title={intl.locale === 'sv' ? 'Proffessionell uthyrare' : 'Professional Space Supplier'}
      description={intl.formatMessage({ id: 'PartnerProffessional.schemaDescription' })}
      schema={{
        '@context': 'http://schema.org',
        '@type': 'PartnerProffessional',
        description: 'Informaton for professional space suppliers on Respaces',
        name: 'Space Supplier',
      }}
    >
      <LayoutSingleColumn>
        <LayoutWrapperTopbar>
          <TopbarContainer showBookADemoButton={false} currentPage="Partner" />
        </LayoutWrapperTopbar>

        <LayoutWrapperMain className={css.root}>
          <SectionHero
            intl={intl}
            isMobile={isMobile}
            onHeroButtonClick={() => setModalOpen(true)}
          />
          <SectionPartners intl={intl} />
          <SectionTesimonials intl={intl} />
          <SectionWhyYouShouldListYourSpace intl={intl} onButtonClick={() => setModalOpen(true)} />
          <SectionGetStarted intl={intl} />
          <SectionYourTerms intl={intl} />
          <SectionForPropertyOwners intl={intl} isMobile={isMobile} onAPIClick={onAPIClick} />

          <section className={css.faqSection}>
            <div className={css.faqWrapper}>
              <img src={FAQImage} alt="FAQ" className={css.faqImage} />
              <div className={css.faqQuestions}>
                <h2 className={css.faqTitle}>
                  {intl.formatMessage({ id: 'PartnerProffessional.faq.title' })}
                </h2>
                <DropDownList
                  menuList={FAQs.map((faq) => ({
                    header: faq.question,
                    content: faq.answer,
                  }))}
                  bold
                  border
                />
                <p className={css.faqContact}>
                  {intl.formatMessage({ id: 'PartnerProffessional.faq.contact' })}
                  <a className={css.mailLink} href={`mailto:${name}@${domain}`}>
                    hello@respaces.co
                  </a>
                </p>
              </div>
            </div>
          </section>

          <SectionContact intl={intl} />
          <InfoModal
            isOpen={isModalOpen}
            onClose={() => setModalOpen(false)}
            color="blue"
            left={{
              title: intl.formatMessage({ id: 'PartnerModal.left.title' }),
              text: intl.formatMessage({ id: 'PartnerModal.left.text' }),
              buttons: (
                <>
                  <ButtonNew
                    type={BUTTON_TYPES.SECONDARY}
                    size={isMobile ? BUTTON_SIZES.SMALL : BUTTON_SIZES.MEDIUM}
                    linkColor={LINK_COLORS.BLUE}
                    onClick={onAPIClick}
                  >
                    {intl.formatMessage({ id: 'PartnerModal.left.buttonText' })}
                  </ButtonNew>
                  <span>
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g>
                        <path
                          d="M10.0002 16.8C6.2402 16.8 3.2002 13.76 3.2002 9.99995C3.2002 6.23995 6.2402 3.19995 10.0002 3.19995C13.7602 3.19995 16.8002 6.23995 16.8002 9.99995C16.8002 13.76 13.7602 16.8 10.0002 16.8ZM10.0002 3.99995C6.6802 3.99995 4.0002 6.67995 4.0002 9.99995C4.0002 13.32 6.6802 16 10.0002 16C13.3202 16 16.0002 13.32 16.0002 9.99995C16.0002 6.67995 13.3202 3.99995 10.0002 3.99995Z"
                          fill="white"
                        />
                        <path
                          d="M12.1196 13.4799L9.59961 10.9599V6.3999H10.3996V10.6399L12.6796 12.9199L12.1196 13.4799Z"
                          fill="white"
                        />
                      </g>
                    </svg>
                    {'<'} 2 min
                  </span>
                </>
              ),
              images: [
                {
                  avatarURL: '/static/info_modal/left_1.png',
                },
                {
                  avatarURL: '/static/info_modal/oskar.jpg',
                },
                {
                  avatarURL: '/static/info_modal/left_3.png',
                },
              ],
            }}
            right={{
              title: intl.formatMessage({ id: 'PartnerModal.right.title' }),
              text: intl.formatMessage({ id: 'PartnerModal.right.text' }),
              buttons: (
                <ButtonNew
                  name="DashboardPage"
                  to={{
                    state: { from: 'PartnerProffessional' },
                  }}
                  params={{ view: 'locations', tab: 'create' }}
                  type={BUTTON_TYPES.BLUE}
                  size={isMobile ? BUTTON_SIZES.SMALL : BUTTON_SIZES.MEDIUM}
                >
                  {intl.formatMessage({ id: 'PartnerModal.right.buttonText' })}
                </ButtonNew>
              ),
              images: [
                {
                  avatarURL: '/static/info_modal/right_1.png',
                },
                {
                  avatarURL: '/static/info_modal/right_2.png',
                },
                {
                  avatarURL: '/static/info_modal/right_3.png',
                },
              ],
            }}
          />

          <div className={css.sticyBottomBar}>
            <div className={css.sticyBottomBarButtonWrapper}>
              <ButtonNew
                type={BUTTON_TYPES.INLINE}
                size={BUTTON_SIZES.MEDIUM}
                linkColor={LINK_COLORS.BLACK}
                withIcon={false}
                block={isMobile}
                name="Partner"
                mobileButtonPosition="left"
              >
                {intl.formatMessage({ id: 'PartnerSubletting.sticyBottomBar.buttonBack' })}
              </ButtonNew>
              <ButtonNew
                type={BUTTON_TYPES.BLUE}
                size={BUTTON_SIZES.MEDIUM}
                linkColor={LINK_COLORS.WHITE}
                withIcon={false}
                block={isMobile}
                onClick={() => setModalOpen(true)}
              >
                {intl.formatMessage({ id: 'PartnerSubletting.sticyBottomBar.button' })}
              </ButtonNew>
            </div>
          </div>
        </LayoutWrapperMain>

        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </LayoutSingleColumn>
    </StaticPage>
  );
};

PartnerProffessional.propTypes = {
  intl: intlShape.isRequired,
};
export default injectIntl(PartnerProffessional);
