import axios from 'axios';

// ================ Action types ================ //

export const FETCH_DATA_REQUEST = 'app/BlogPage/FETCH_DATA_REQUEST';
export const FETCH_DATA_SUCCESS = 'app/BlogPage/FETCH_DATA_SUCCESS';
export const FETCH_POST_DATA_SUCCESS = 'app/BlogPage/FETCH_POST_DATA_SUCCESS';
export const FETCH_DATA_ERROR = 'app/BlogPage/FETCH_DATA_ERROR';

// ================ Reducer ================ //

const initialState = {
  fetchInProgress: false,
  fetchDataError: null,
  blogData: null,
  postData: null,
};

export default function blogPageReducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case FETCH_DATA_REQUEST:
      return { ...state, fetchInProgress: true, fetchDataError: null };
    case FETCH_DATA_SUCCESS: {
      return {
        ...state,
        fetchInProgress: false,
        blogData: payload,
        postData: null,
      };
    }
    case FETCH_POST_DATA_SUCCESS: {
      return {
        ...state,
        fetchInProgress: false,
        postData: payload,
      };
    }
    case FETCH_DATA_ERROR:
      console.error(payload); // eslint-disable-line
      return { ...state, fetchInProgress: false, fetchDataError: payload };

    default:
      return state;
  }
}

// ================ Action creators ================ //

const fetchDataRequest = () => ({ type: FETCH_DATA_REQUEST });
const fetchDataSuccess = (response) => ({
  type: FETCH_DATA_SUCCESS,
  payload: response,
});
const fetchPostDataSuccess = (response) => ({
  type: FETCH_POST_DATA_SUCCESS,
  payload: response,
});
const fetchDataError = (response) => ({
  type: FETCH_DATA_ERROR,
  payload: response,
});

// ================ Thunks ================ //

export const loadData = () => async (dispatch, getState, sdk) => {
  dispatch(fetchDataRequest());
  try {
    const strapiResponse = await axios.get(`https://cms.respaces.co/posts?_sort=published_at:DESC`);
    dispatch(fetchDataSuccess(strapiResponse.data));
    return strapiResponse;
  } catch (error) {
    dispatch(fetchDataError(error));
  }
};

export const loadDataSinglePost = (params) => async (dispatch, getState, sdk) => {
  const { slug } = params;
  dispatch(fetchDataRequest());
  try {
    const strapiResponse = await axios.get(`https://cms.respaces.co/posts?slug=${slug}`);
    dispatch(fetchPostDataSuccess(strapiResponse.data[0]));
    return strapiResponse;
  } catch (error) {
    dispatch(fetchDataError(error));
  }
};
