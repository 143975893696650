import React from 'react';
import { MediaAndTextSection } from '../../components_new';
import config from '../../config';
import Image from './assets/sectionGoodImages.png';
import css from './PartnerSubletting.module.scss';

const SectionGoodImages = ({ intl, isMobile, linkProps, onButtonClick }) => {
  return (
    <section className={css.sectionHero}>
      <MediaAndTextSection
        title={{
          content: intl.formatMessage({ id: 'PartnerSubletting.SectionGoodImages.title' }),
          showMobile: true,
        }}
        subtitle={{
          content: intl.formatMessage({ id: 'PartnerSubletting.SectionGoodImages.subtitle' }),
          showMobile: true,
        }}
        caption={{
          text: intl.formatMessage({ id: 'PartnerSubletting.SectionGoodImages.caption' }),
          showMobile: true,
        }}
        bulletType={'check'}
        checkColor={'green'}
        media={{ content: Image, showMobile: true }}
        wideMedia
        textAlign={'left'}
        textAlignMobile={'top'}
        buttonsList={[
          {
            linkText: intl.formatMessage({ id: 'PartnerSubletting.SectionGoodImages.link' }),
            callback: onButtonClick,
            ...linkProps,
            block: isMobile,
          },
        ]}
      />
    </section>
  );
};

export default SectionGoodImages;
