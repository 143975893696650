import React from 'react';
import image1 from './assets/Bemz.png';
import image2 from './assets/Tengbom.png';
import image3 from './assets/CDLP.png';
import image4 from './assets/parakey.png';
import image5 from './assets/apollo.png';
import css from './PartnerSubletting.module.scss';

const SectionCompanyLogos = ({ intl }) => {
  return (
    <section className={css.SectionCompanyLogos}>
      <h2>
        {intl.formatMessage({
          id: 'PartnerSubletting.SectionCompanyLogos.title',
        })}
      </h2>
      <div className={css.logoWrapper}>
        <img src={image1} alt="Benz" />
        <img src={image2} alt="Tengbom" />
        <img src={image3} alt="CDLP" />
        <img src={image4} alt="Parakey" />
        <img src={image5} alt="apollo" />
      </div>
    </section>
  );
};

export default SectionCompanyLogos;
